import { useState } from "react";
import * as yup from "yup";
import { DateTime } from "luxon";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
  CreateGroupInput,
  DeleteGroupWithAnimalsInput,
  UpdateGroupInput,
  useCreateGroupMutation,
  useDeleteGroupWithAnimalsMutation,
  useGroupLazyQuery,
  useUpdateGroupMutation,
} from "generated/graphql";
import { removeNothings } from "helpers/general";
import { useGetCurrentBusinessUnit, useStateSpecies } from "hooks";
import { Breadcrumbs, LoadingOverlay, PageContent, PageHeader, Spacer, Text, Header, Modal } from "components";
import { GROUPS, LIVESTOCK } from "constants/Routes";
import { GROUP_CATEGORY_OPTIONS } from "constants/Groups";
import { GroupAnimalsList } from "groups/components/GroupAnimalsList";
import { isTwoDigits } from "financial/components/TransactionForm/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";
import { FormInput } from "components/FormHookComponents/FormInput";
import { FormDatePicker } from "components/FormHookComponents/FormDatePicker";
import { FormSelect } from "components/FormHookComponents/FormSelect";
import { Button } from "twComponents/Button";

export const CreateEditGroup = () => {
  const [addingAnimalsToGroup, setAddingAnimalsToGroup] = useState<boolean>(false);
  const { activeSpecies } = useStateSpecies();
  const { handleError, handleMutationResult } = useHandleMutationResult();
  const { id: businessUnitId, weightUnits } = useGetCurrentBusinessUnit();
  const [groupSpecies, setGroupSpecies] = useState<string | undefined>();

  const { groupId } = useParams<LivestockGroupPageParams>();

  const [fetchGroup, { loading: isLoadingGroupDetails }] = useGroupLazyQuery();

  const [createGroup, { loading: createGroupLoading }] = useCreateGroupMutation();
  const [updateGroup, { loading: updateGroupLoading }] = useUpdateGroupMutation();
  const [deleteGroup, { loading: deleteGroupLoading }] = useDeleteGroupWithAnimalsMutation();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

  const history = useHistory();

  const onSubmit = async (values: Schema) => {
    try {
      if (groupId) {
        const input: UpdateGroupInput = {
          id: groupId,
          name: values.name,
          targetWeight: values.targetWeight,
          targetDate: values.targetDate ? DateTime.fromJSDate(values.targetDate).toISODate() : null,
          category: values.category,
        };

        const result = await updateGroup({
          variables: {
            input,
          },
        });

        const errors = removeNothings(result.data?.updateGroup?.errors || []);

        handleMutationResult({
          successMessage: "Group successfully updated.",
          errors,
          onSuccess: () => {
            history.push(GROUPS);
          },
        });
      } else {
        const input: CreateGroupInput = {
          id: groupId,
          businessUnit: +businessUnitId,
          name: values.name,
          targetWeight: values.targetWeight,
          targetDate: values.targetDate ? DateTime.fromJSDate(values.targetDate).toISODate() : null,
          category: values.category,
          animalType: Number(activeSpecies?.id),
        };

        const result = await createGroup({
          variables: {
            input,
          },
        });

        const errors = removeNothings(result.data?.createGroup?.errors || []);

        handleMutationResult({
          successMessage: "Group successfully created.",
          errors,
          onSuccess: () => {
            history.push(`${LIVESTOCK}/group/${result?.data?.createGroup?.group?.id}`);
          },
        });
      }
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const onDelete = async () => {
    try {
      const input: DeleteGroupWithAnimalsInput = {
        id: groupId,
      };

      const result = await deleteGroup({
        variables: {
          input,
        },
      });

      handleMutationResult({
        successMessage: "Successfully deleted group.",
        errors: removeNothings(result?.data?.deleteGroupWithAnimals?.errors || []),
        onSuccess: () => {
          history.push(GROUPS);
        },
      });
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const methods = useForm<Schema>({
    mode: "onBlur",
    shouldFocusError: false,
    resolver: yupResolver(validationSchema),
    defaultValues: async () => {
      const defaultGroupValues: Schema = {
        name: "",
        targetWeight: 0,
        targetDate: undefined,
        category: GROUP_CATEGORY_OPTIONS[3].value,
      };

      try {
        if (groupId) {
          const { data } = await fetchGroup({
            variables: { group: groupId, businessUnit: +businessUnitId, isBatch: false },
          });

          setGroupSpecies(data?.group?.animalType?.id);

          return {
            name: data?.group?.name || "",
            targetWeight: data?.group?.targetWeight || 0,
            targetDate: data?.group?.targetDate ? DateTime.fromISO(data?.group?.targetDate).toJSDate() : undefined,
            category: data?.group?.category || GROUP_CATEGORY_OPTIONS[3].value,
          };
        } else {
          return defaultGroupValues;
        }
      } catch (error: unknown) {
        handleError(error);

        return defaultGroupValues;
      }
    },
  });

  if (activeSpecies) {
    const isGroupDifferentSpecies = groupSpecies && groupSpecies !== activeSpecies.id;
    if (isGroupDifferentSpecies) {
      return <Redirect to={GROUPS} />;
    }
  }

  return (
    <>
      {isLoadingGroupDetails ? <LoadingOverlay /> : null}
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Groups",
              route: GROUPS,
            },
          ]}
          currentPath={groupId ? "Update Group" : "Create Group"}
        />
        <Header
          title={groupId ? "Edit Group" : "Add a New Group"}
          subTitle={<Text>Add livestock to your group.</Text>}
        />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={2} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="max-w-[100%] px-2">
            <div className="flex justify-between items-center">
              <Text className="font-semibold text-xl">Group Details</Text>

              <div>
                {groupId ? (
                  <Button
                    type="button"
                    disabled={deleteGroupLoading}
                    variant="ghost"
                    onClick={() => setShowDeleteConfirmationModal(true)}
                    className="text-red-500 mr-2"
                  >
                    Delete
                  </Button>
                ) : null}
                <Button
                  className="mx-2 h-1/1"
                  onClick={() => {
                    history.goBack();
                  }}
                  variant="outline"
                  disabled={createGroupLoading || updateGroupLoading || deleteGroupLoading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="h-1/1"
                  disabled={createGroupLoading || updateGroupLoading || deleteGroupLoading}
                  loading={createGroupLoading || updateGroupLoading}
                >
                  {groupId ? "Save updates" : "Create group"}
                </Button>
              </div>
            </div>
            <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              <FormInput<Schema> label="Group name *" name="name" />
              <FormInput<Schema>
                name="targetWeight"
                label="Target Weight *"
                type="number"
                step={0.01}
                adornmentLeft={{ label: weightUnits, labelFor: "targetWeight" }}
              />
              <FormDatePicker<Schema> label="Target Date" name="targetDate" minDate={new Date()} />
              <FormSelect<Schema>
                name="category"
                label="Type *"
                options={GROUP_CATEGORY_OPTIONS}
                placeholder="Select type"
              />
            </div>
            {groupId ? (
              <GroupAnimalsList
                groupId={groupId}
                isBatch={false}
                addingAnimalsToGroup={addingAnimalsToGroup}
                setAddingAnimalsToGroup={setAddingAnimalsToGroup}
              />
            ) : null}
          </form>
        </FormProvider>
      </PageContent>

      <Modal
        active={showDeleteConfirmationModal}
        title="Delete Group"
        subTitle="Are you sure you want to delete this group?"
        actions={{
          primary: {
            caption: "Confirm",
            disabled: deleteGroupLoading,
            requesting: deleteGroupLoading,
            onClick: onDelete,
          },
          secondary: {
            caption: "Cancel",
            onClick: () => setShowDeleteConfirmationModal(false),
          },
        }}
      />
    </>
  );
};

export type Schema = yup.InferType<typeof validationSchema>;

export const validationSchema = yup.object({
  name: yup.string().required("Group name is required!"),
  targetWeight: yup
    .number()
    .transform((value: number) => (Number.isNaN(value) ? 0 : value))
    .min(0, "Target weight must be between 0 and 5000.")
    .max(5000, "Target weight must be between 0 and 5000.")
    .test(...isTwoDigits)
    .required("Target weight name is required!"),
  targetDate: yup.date(),
  category: yup.string().required("Type is required!"),
});
