// React
import React from "react";
// Libraries
import pure from "recompose/pure";
// Types
import { ImportResult_importResult } from "api/Import/CustomImport/types/ImportResult";
// Utils
import { CorrectIcon, ErrorIcon } from "constants/SvgIcons";
import { ELEMENTS_IDS } from "constants/ElementsIds";
// Components
import { Button, Flex } from "components";
// Resources
import styles from "./ImportResultBlock.module.scss";

interface ImportResultBlockProps {
  importResult: ImportResult_importResult;
}

const ImportResultBlock: React.FC<ImportResultBlockProps> = ({ importResult }) => (
  <Flex
    className={styles.contentCenterWrapper}
    container
    containerAlignItems="center"
    containerDirection="column"
    item
    itemGutter
    xs={12}
  >
    <p className={styles.label}>Import Complete</p>

    {importResult && importResult.animals && !!importResult.animals.length ? (
      <div className={styles.successImportedBlock}>
        <div className={styles.titleWithIcon}>
          {CorrectIcon}
          <p className={styles.whiteText}>{`${importResult.importedAnimalsCount} Animal(s) imported`}</p>
        </div>
      </div>
    ) : null}

    {importResult && importResult.updatedAnimals && !!importResult.updatedAnimals.length ? (
      <div className={styles.warningImportedBlock}>
        <div className={styles.titleWithIcon}>
          {CorrectIcon}

          <p className={styles.whiteText}>
            {`${importResult.updatedAnimals.length}
                    Animal(s) Already exists have been updated with additional information where blank`}
          </p>
        </div>

        <div className={styles.viewItems}>
          <span className={styles.whiteText}>Rows:</span>

          {importResult.updatedAnimals.map((item) => (
            <div key={item.rowIndex}>
              <span className={styles.errorTitle}>
                {`${item.rowIndex} (Passport Number ${item.animal.passportNumber})`}
              </span>
            </div>
          ))}
        </div>
      </div>
    ) : null}

    {importResult && Number(importResult.importedActivitiesCount) > 0 ? (
      <div className={styles.successImportedBlock}>
        <p className={styles.whiteText}>{importResult.importedActivitiesCount} Activities imported.</p>
      </div>
    ) : null}

    {importResult && importResult.errorRows && !!importResult.errorRows.length ? (
      <div className={styles.errorImportedBlock}>
        <div className={styles.titleWithIcon}>
          {ErrorIcon}

          <p className={styles.whiteText}>Errors:</p>
        </div>

        {importResult.errorRows.map((item) => (
          <div className={styles.viewError} key={item.rowIndex}>
            <p className={styles.errorTitle}>{`In Row ${item.rowIndex}: `}</p>

            {item.errors.map((err) => (
              <p key={err.code} className={styles.errorTitle}>
                {`${err.code !== "not_found" ? ` ${err.field} -` : " "} ${err.message}`}
              </p>
            ))}
          </div>
        ))}

        <div className={styles.viewError}>
          {importResult && importResult.failedAnimalsUrl ? (
            <Button
              className={styles.downloadTemplButton}
              caption="Download un imported animals"
              colour="grey"
              variant="hollow"
              id={ELEMENTS_IDS.IMPORT_RESULT_DOWNLOAD_IMPORTED_ANIMALS_BUTTON}
              onClick={(): void => {
                if (importResult.failedAnimalsUrl) {
                  window.open(importResult.failedAnimalsUrl, "Download");
                }
              }}
            />
          ) : null}

          {importResult && importResult.failedActivitiesUrl ? (
            <Button
              className={styles.downloadTemplButton}
              caption="Download un imported activities"
              colour="grey"
              variant="hollow"
              id={ELEMENTS_IDS.IMPORT_RESULT_DOWNLOAD_IMPORTED_ACTIVITIES_BUTTON}
              onClick={(): void => {
                if (importResult.failedActivitiesUrl) {
                  window.open(importResult.failedActivitiesUrl, "Download");
                }
              }}
            />
          ) : null}
        </div>
      </div>
    ) : null}
  </Flex>
);

export default pure(ImportResultBlock);
