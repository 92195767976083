import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { CommonContext } from "config/commonProvider";
import { ANIMALS_TABLE_ACTION_TYPES } from "constants/Animals";
import { TABLE_IDS } from "constants/Interface";
import { getBUFromLocalStorage } from "helpers/storage";
import { useHasFeature } from "hooks";
import { GetGroup_group } from "api/MyLivestock/Groups/types/GetGroup";
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { ConfirmModalWithOutInput as RemoveAnimalConfirmModal, EllipsisMenu } from "components";
import { ConfirmAddPreviousKeeperModal } from "components/MyLivestock/Animals/AnimalBulkActions/ConfirmAddPreviousKeeperModal";
import { LogActivityModal } from "components/MyLivestock/Animals/AnimalBulkActions/LogActivityModal";
import { MarkAsDeadModal } from "animals/MarkAsDeadModal";
import { MarkOffFarmModal } from "components/MyLivestock/Animals/AnimalBulkActions/MarkOffFarm/MarkOffFarmModal";
import { GET_GROUP } from "groups/api/queries";
import { useAssignAnimalsToGroupMutation } from "generated/graphql";
import { MoveToFieldModal } from "fields/MoveToFieldModal";
import { AssignToGroupModal } from "groups/components/AssignToGroupModal";

interface Props {
  group?: GetGroup_group;
  animal: GetAnimalsList_animalsExtended_animals;
  error?: string;
  isFarmWithGroups: boolean;
}

const TableActionColumn: React.FC<Props> = ({ animal, group, isFarmWithGroups }) => {
  const { showNotification } = useContext(CommonContext);
  const { groupId } = useParams<{ groupId: string }>();
  const isShowLogActivity: boolean = useHasFeature("SHOW_LOG_ACTIVITY");

  const [error, setError] = useState<string | null>(null);
  const [showModal, setModalShow] = useState<string | undefined>();

  const isMovementsEnabled = useHasFeature("OUTWARD_MOVEMENTS");
  const isCreateOnMovementEnabled = useHasFeature("CREATE_INWARD_MOVEMENTS");
  const previousKeeperLabel = isCreateOnMovementEnabled ? "source address" : "previous keeper";

  const [assignAnimals, { loading: requesting }] = useAssignAnimalsToGroupMutation();

  const ANIMALS_TABLE_ACTIONS = isMovementsEnabled
    ? [
        {
          key: "add_to_group",
          title: "Add to group",
        },
        {
          key: "move_to_field",
          title: "Move to location",
        },
        {
          key: "mark_as_dead",
          title: "Mark as dead",
        },
        {
          key: "add_previous_keeper",
          title: `Add ${previousKeeperLabel}`,
        },
        {
          key: "log_activity",
          title: "Log activity",
        },
      ]
    : [
        {
          key: "add_to_group",
          title: "Add to group",
        },
        {
          key: "move_to_field",
          title: "Move to location",
        },
        {
          key: "mark_as_dead",
          title: "Mark as dead",
        },

        {
          key: "mark_off_farm",
          title: "Mark off-farm",
        },
        {
          key: "add_previous_keeper",
          title: "Add previous keeper",
        },
        {
          key: "log_activity",
          title: "Log activity",
        },
      ];

  const selectedAnimal = group
    ? {
        ...animal,
        group,
      }
    : animal;

  const removeAnimalFromGroup = async (): Promise<void> => {
    setError(null);

    try {
      const { data } = await assignAnimals({
        variables: {
          input: {
            animals: [animal.id],
            date: new Date(),
          },
        },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              group: groupId,
              businessUnit: getBUFromLocalStorage(),
            },
          },
        ],
      });

      if (data?.moveAnimalsToGroup?.errors) {
        setError(data?.moveAnimalsToGroup?.errors[0]?.message || "Error");
        showNotification({
          variant: "error",
          message: "Error removing animal",
        });
      } else {
        showNotification({
          message: "Animal removed successfully",
        });
        setModalShow(undefined);
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Error removing animal",
      });
    }
  };

  const handleRemoveAnimalFromGroup = (): void => {
    removeAnimalFromGroup();
  };

  const handleHideModal = (): void => {
    setModalShow(undefined);
  };

  const handleCloseModal = (): void => {
    handleHideModal();
    setError(null);
  };

  const handleTooltipPress = (e: React.MouseEvent, key: string): void => {
    e.stopPropagation();
    setModalShow(key);
  };

  const isAnimalInGroup = group || animal.group;

  const actions = ANIMALS_TABLE_ACTIONS.map((action) => ({
    ...action,
    caption: isAnimalInGroup && action.key === ANIMALS_TABLE_ACTION_TYPES.ADD_TO_GROUP ? "Change group" : action.title,
    disabled: !isFarmWithGroups && action.key === ANIMALS_TABLE_ACTION_TYPES.ADD_TO_GROUP,
    onClick: (event): void => handleTooltipPress(event, action.key),
  }));
  const filteredActions = actions.filter(
    (item) =>
      !(
        (item.key === ANIMALS_TABLE_ACTION_TYPES.MARK_OFF_FARM && animal.rflId) ||
        (item.key === ANIMALS_TABLE_ACTION_TYPES.ADD_PREVIOUS_KEEPER && animal.hasPreviousKeeperInfo) ||
        (item.key === ANIMALS_TABLE_ACTION_TYPES.LOG_ACTIVITY && !isShowLogActivity)
      ),
  );

  return (
    <div id={animal.id}>
      <EllipsisMenu actions={filteredActions} />
      {showModal === ANIMALS_TABLE_ACTION_TYPES.MARK_OFF_FARM ? (
        <MarkOffFarmModal
          animalIds={[animal.id]}
          groupId={group?.id}
          isShowModal={showModal === ANIMALS_TABLE_ACTION_TYPES.MARK_OFF_FARM}
          isSingleAnimal
          onClose={handleHideModal}
          tableId={TABLE_IDS.ANIMALS}
        />
      ) : null}
      {showModal === ANIMALS_TABLE_ACTION_TYPES.MARK_AS_DEAD ? (
        <MarkAsDeadModal
          animalIds={[animal.id]}
          showModal={showModal === ANIMALS_TABLE_ACTION_TYPES.MARK_AS_DEAD}
          setShowModal={handleHideModal}
        />
      ) : null}
      {showModal === ANIMALS_TABLE_ACTION_TYPES.ADD_PREVIOUS_KEEPER ? (
        <ConfirmAddPreviousKeeperModal
          animalIds={[animal.id]}
          isShowModal={showModal === ANIMALS_TABLE_ACTION_TYPES.ADD_PREVIOUS_KEEPER}
          isSingleAnimal
          onClose={handleHideModal}
        />
      ) : null}
      <RemoveAnimalConfirmModal
        error={error}
        requesting={requesting}
        title={`Are you sure you want to remove ${animal.passportNumber} from the group? This will not remove the animal from your inventory`}
        showModal={showModal === ANIMALS_TABLE_ACTION_TYPES.REMOVE_FROM_GROUP}
        onClose={handleCloseModal}
        onConfirm={handleRemoveAnimalFromGroup}
      />
      {showModal === ANIMALS_TABLE_ACTION_TYPES.ADD_TO_GROUP ? (
        <AssignToGroupModal
          animalIds={[selectedAnimal.id]}
          showModal={showModal === ANIMALS_TABLE_ACTION_TYPES.ADD_TO_GROUP}
          setShowModal={handleHideModal}
        />
      ) : null}
      {showModal === ANIMALS_TABLE_ACTION_TYPES.MOVE_TO_FIELD ? (
        <MoveToFieldModal
          entityToMove={{ type: "animal", ids: [selectedAnimal.id] }}
          showModal={showModal === ANIMALS_TABLE_ACTION_TYPES.MOVE_TO_FIELD}
          setShowModal={handleHideModal}
        />
      ) : null}
      {showModal === ANIMALS_TABLE_ACTION_TYPES.LOG_ACTIVITY ? (
        <LogActivityModal
          animals={[animal]}
          isShowModal={showModal === ANIMALS_TABLE_ACTION_TYPES.LOG_ACTIVITY}
          isSingleAnimal
          onClose={handleHideModal}
        />
      ) : null}
    </div>
  );
};

export default TableActionColumn;
