import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// Utils
import {
  DASHBOARD,
  BU_ID,
  SETTINGS,
  ID_PREFERENCES,
  MANAGEMENT_SETTINGS,
  EMAIL_SETTINGS,
  SECURITY_SETTINGS,
  USER_SETTINGS,
  ADDRESS_BOOK,
  BANK_DETAILS,
  CREATE_BANK_DETAILS,
  EDIT_BANK_DETAILS,
  SUBSCRIPTION_DETAILS,
  INTEGRATIONS,
} from "constants/Routes";
// Hooks
import { useGetBusinessUnitUserRole, useHasFeature } from "hooks";
// Pages
import { UserManagement } from "pages/Settings/UserManagement";
import EmailSettings from "pages/Settings/EmailSettings";
import UserSettings from "pages/Settings/UserSettings";
import SecuritySettings from "pages/Settings/SecuritySettings";
import BankDetails from "pages/Settings/BankDetails";
import CreateEditBankDetails from "pages/Settings/BankDetails/CreateEditBankDetails";
import IdPreferences from "pages/Settings/IdPreferences";

import { SubscriptionDetails } from "subscriptions/components/SubscriptionDetails";
import Integrations from "pages/Settings/Integrations";
import { ContactCreateEdit } from "settings/pages/ContactCreateEdit";
import { ContactsList } from "settings/pages/ContactsList";

const ROUTES_PATHS = {
  UserManagement: `${BU_ID}${SETTINGS}${MANAGEMENT_SETTINGS}`,
  EmailSettings: `${BU_ID}${SETTINGS}${EMAIL_SETTINGS}`,
  SecuritySettings: `${BU_ID}${SETTINGS}${SECURITY_SETTINGS}`,
  UserSettings: `${BU_ID}${SETTINGS}${USER_SETTINGS}`,
  AddressBook: `${BU_ID}${SETTINGS}${ADDRESS_BOOK}`,
  CreateEditContactAddress: `${BU_ID}${SETTINGS}${ADDRESS_BOOK}/:id`,
  BankDetails: `${BU_ID}${SETTINGS}${BANK_DETAILS}`,
  CreateBankDetails: `${BU_ID}${SETTINGS}${CREATE_BANK_DETAILS}`,
  EditBankDetails: `${BU_ID}${SETTINGS}${EDIT_BANK_DETAILS}`,
  SubscriptionDetails: `${BU_ID}${SETTINGS}${SUBSCRIPTION_DETAILS}`,
  IdPreferences: `${BU_ID}${SETTINGS}${ID_PREFERENCES}`,
  Integrations: `${BU_ID}${SETTINGS}${INTEGRATIONS}`,
  auth: "/auth",
};

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

const SettingsRoutes: React.FC = () => {
  const { isAdmin, isManager, role } = useGetBusinessUnitUserRole();
  const isBankDetailsFoggleEnabled = useHasFeature("BANK_DETAILS");
  const isShowBankDetails: boolean = (isAdmin || isManager) && isBankDetailsFoggleEnabled;

  const addressBookRoutes = [
    <Route exact path={ROUTES_PATHS.AddressBook} key={ROUTES_PATHS.AddressBook} component={ContactsList} />,
    <Route
      exact
      path={ROUTES_PATHS.CreateEditContactAddress}
      key={ROUTES_PATHS.CreateEditContactAddress}
      component={ContactCreateEdit}
    />,
  ];

  const bankDetailsRoutes = [
    <Route exact path={ROUTES_PATHS.BankDetails} key={ROUTES_PATHS.BankDetails} component={BankDetails} />,
    <Route
      exact
      path={[ROUTES_PATHS.CreateBankDetails, ROUTES_PATHS.EditBankDetails]}
      key={ROUTES_PATHS.CreateBankDetails}
      component={CreateEditBankDetails}
    />,
  ];

  const subscriptionRoute = <Route exact path={ROUTES_PATHS.SubscriptionDetails} component={SubscriptionDetails} />;

  return (
    <Route exact path={PATHS}>
      <Switch>
        {isAdmin ? <Route exact path={ROUTES_PATHS.UserManagement} component={UserManagement} /> : null}
        <Route
          exact
          path={ROUTES_PATHS.EmailSettings}
          render={(): JSX.Element => <EmailSettings role={role || ""} />}
        />
        <Route exact path={ROUTES_PATHS.SecuritySettings} component={SecuritySettings} />
        <Route exact path={ROUTES_PATHS.Integrations} component={Integrations} />
        <Route exact path={ROUTES_PATHS.UserSettings} component={UserSettings} />
        {isAdmin ? subscriptionRoute : null}
        {addressBookRoutes}
        {isShowBankDetails ? bankDetailsRoutes : null}
        <Route exact path={ROUTES_PATHS.IdPreferences} component={IdPreferences} />
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};

export default SettingsRoutes;
