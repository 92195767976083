import { useQuery } from "@apollo/client";
// Api
import { GET_BANK_DETAILS } from "api/Settings/queries";
import { GetBankDetails, GetBankDetailsVariables } from "api/Settings/types/GetBankDetails";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";

type Params = {
  skip?: boolean;
};

export const useGetBankDetails = (params?: Params) => {
  const data = useQuery<GetBankDetails, GetBankDetailsVariables>(GET_BANK_DETAILS, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    skip: Boolean(params?.skip),
  });

  return data;
};
