import { CustomColumn } from "components/Common/Table/types";
import { AnimalExportColumnInput, AnimalExportFieldEnum, AnimalFragment, AnimalSorting } from "generated/graphql";
import { capitaliseFirstLetter, capitaliseFirstLetterOfEachWord } from "helpers/translations/src";
import { Column, SortingRule } from "react-table";
import { COLUMN_IDS } from "./constants";
import { ICON_NAMES } from "constants/Icons";

export const camelToSnakeCase = (input: string) => input.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const parseSortRule = <T>(sortRule: SortingRule<T>[]): Array<string> => {
  if (sortRule[0] != undefined) {
    const sortingRule = sortRule[0];
    const sortingRuleId = camelToSnakeCase(sortingRule.id);
    const parsedSortingRule = `${sortingRule.desc ? "-" : ""}${sortingRuleId}`;
    return [parsedSortingRule];
  }

  return [];
};

export const areAllRowsSelected = (animalIds: Array<string>, selectedAnimalIds: Set<string> | undefined) => {
  if (!selectedAnimalIds) {
    return false;
  }
  return animalIds.every((id) => selectedAnimalIds.has(id));
};

const getColumnLabel = <T extends object>(column: Column<T>, isExport = false) => {
  if (!column.id) {
    return "No Header";
  } else if (column.id === "isBcmsSynced" && isExport) {
    return "In Withdrawal";
  } else if (typeof column.Header === "string") {
    return capitaliseFirstLetterOfEachWord(column.Header);
  } else if (typeof column.Header === "function" || typeof column.Header === "object") {
    const header = column.Header as React.ReactElement;
    return `${header.props?.title} - ${header.props?.subtitle}`;
  } else {
    return capitaliseFirstLetter(column.id);
  }
};

export const createColumnObject = (
  //this can be any type of column, not just AnimalFragment (Group, Field, etc.)
  columns: Column<any>[],
  localStorageColumns: CustomColumn[],
  hiddenColumns: string[],
  defaultColumnIds: string[],
  primaryId: string | undefined,
): CustomColumn[] => {
  return columns
    .map((column, index): CustomColumn | null => {
      const columnId = column.id || "";
      const localStorageColumn = localStorageColumns.find((c) => c.id === columnId);
      if (hiddenColumns.includes(columnId)) {
        return null;
      }
      return {
        id: columnId,
        header: getColumnLabel(column) || "No Header",
        checked:
          localStorageColumn && localStorageColumn.checked
            ? localStorageColumn.checked
            : defaultColumnIds.includes(columnId),
        index: index,
      };
    })
    .filter(
      (column): column is CustomColumn =>
        column !== null &&
        column.id !== "table__row_select" &&
        column.id !== "table__row_actions" &&
        column.id !== primaryId,
    );
};

export const reorderColumns = (
  columns: Column<AnimalFragment>[],
  columnOrder: { id: string; index: number; checked: boolean }[],
  primaryId: string,
): Column<AnimalFragment>[] => {
  const sortedColumnOrder = columnOrder.sort((a, b) => a.index - b.index);
  const columnMap: { [id: string]: Column<AnimalFragment> } = {};
  columns.map((column) => {
    columnMap[column.id || ""] = column;
  });
  const reorderedColumns: Column<AnimalFragment>[] = [];
  const selectColumn = columnMap["table__row_select"];
  if (selectColumn) {
    reorderedColumns.push(selectColumn);
  }
  const primaryIdColumn = columnMap[primaryId];
  if (primaryIdColumn) {
    // @ts-ignore
    reorderedColumns.push({ ...primaryIdColumn, sticky: "left" });
  }
  sortedColumnOrder.map((column) => {
    if (
      column.checked &&
      column.id !== "table__row_select" &&
      column.id !== "table__row_actions" &&
      column.id !== primaryId
    ) {
      const columnObject = columnMap[column.id];
      if (columnObject) {
        reorderedColumns.push(columnObject);
      }
    }
  });
  const actionsColumn = columnMap["table__row_actions"];
  if (actionsColumn) {
    reorderedColumns.push(actionsColumn);
  }
  return reorderedColumns;
};

const sortRulesByColumnId = {
  [COLUMN_IDS.ANIMAL_ID]: {
    asc: AnimalSorting.PassportNumberAsc,
    desc: AnimalSorting.PassportNumberDesc,
  },
  [COLUMN_IDS.E_ID]: {
    asc: AnimalSorting.EIdAsc,
    desc: AnimalSorting.EIdDesc,
  },
  // [COLUMN_IDS.BREEDR_ID]: {},
  // [COLUMN_IDS.BRUCELLOSIS_ID]: {},
  // [COLUMN_IDS.TRICH_ID]: {},
  // [COLUMN_IDS.TSU_BARCODE]: {},
  // [COLUMN_IDS.HERDDOGG_ID]: {},
  // [COLUMN_IDS.PEDIGREE_ID]: {},
  // [COLUMN_IDS.NAME]: {},
  // [COLUMN_IDS.TATTOO_ID]: {},
  // [COLUMN_IDS.UHF_ID]: {},
  [COLUMN_IDS.VISUAL_ID]: {
    asc: AnimalSorting.VisualIdAsc,
    desc: AnimalSorting.VisualIdDesc,
  },
  [COLUMN_IDS.DATE_OF_BIRTH]: {
    asc: AnimalSorting.DobAsc,
    desc: AnimalSorting.DobDesc,
  },
  [COLUMN_IDS.AGE]: {
    asc: AnimalSorting.AgeAsc,
    desc: AnimalSorting.AgeDesc,
  },
  [COLUMN_IDS.GROUP]: {
    asc: AnimalSorting.GroupNameAsc,
    desc: AnimalSorting.GroupNameDesc,
  },
  [COLUMN_IDS.FIELD]: {
    asc: AnimalSorting.FieldNameAsc,
    desc: AnimalSorting.FieldNameDesc,
  },
  [COLUMN_IDS.CURRENT_WEIGHT]: {
    asc: AnimalSorting.CurrentWeight,
    desc: AnimalSorting.CurrentWeightDesc,
  },
  [COLUMN_IDS.LAST_WEIGHT]: {
    asc: AnimalSorting.LatestMeasuredWeightAsc,
    desc: AnimalSorting.LatestMeasuredWeightDesc,
  },
  [COLUMN_IDS.DLWG]: {
    asc: AnimalSorting.DailyLiveWeightGainAsc,
    desc: AnimalSorting.DailyLiveWeightGainDesc,
  },
  [COLUMN_IDS.PREGNANCY_DUE_DATE]: {
    asc: AnimalSorting.DeliveryDateAsc,
    desc: AnimalSorting.DeliveryDateDesc,
  },
  [COLUMN_IDS.DATE_ON_FARM]: {
    asc: AnimalSorting.DofAsc,
    desc: AnimalSorting.DofDesc,
  },
  [COLUMN_IDS.DATE_LEFT_FARM]: {
    asc: AnimalSorting.DateLeftFarmAsc,
    desc: AnimalSorting.DateLeftFarmDesc,
  },
  [COLUMN_IDS.DEAD_AT]: {
    asc: AnimalSorting.DeadAtAsc,
    desc: AnimalSorting.DeadAtDesc,
  },
  [COLUMN_IDS.UPDATED_AT]: {
    asc: AnimalSorting.UpdatedAt,
    desc: AnimalSorting.UpdatedAtDesc,
  },
  [COLUMN_IDS.LAST_SYNCED]: {
    asc: AnimalSorting.LastRegulatorySyncDateAsc,
    desc: AnimalSorting.LastRegulatorySyncDateDesc,
  },
} as const;

export const sortRuleToAnimalSorting = (sortRule: { id: string; desc: boolean }): AnimalSorting => {
  const sortingRule = sortRulesByColumnId[sortRule?.id];
  return sortingRule?.[sortRule.desc ? "desc" : "asc"] || AnimalSorting.UpdatedAtDesc;
};

export const exportFieldEnumMapping = {
  passportNumber: AnimalExportFieldEnum.AnimalId,
  eId: AnimalExportFieldEnum.Eid,
  breedrId: AnimalExportFieldEnum.BreedrId,
  brucellosisId: AnimalExportFieldEnum.BrucellosisId,
  trichId: AnimalExportFieldEnum.TrichId,
  tsuBarcode: AnimalExportFieldEnum.TsuBarcode,
  herdDoggId: AnimalExportFieldEnum.HerdDoggId,
  pedigreeId: AnimalExportFieldEnum.PedigreeId,
  name: AnimalExportFieldEnum.Name,
  tattoo: AnimalExportFieldEnum.TattooId,
  uhfId: AnimalExportFieldEnum.UhfId,
  alternativeId: AnimalExportFieldEnum.AlternativeId,
  visualId: AnimalExportFieldEnum.Vid,
  dob: AnimalExportFieldEnum.Dob,
  age: AnimalExportFieldEnum.Age,
  isMale: AnimalExportFieldEnum.Sex,
  sexClassification: AnimalExportFieldEnum.SexClassification,
  animalBreeds: AnimalExportFieldEnum.BreedName,
  animalBreedCodes: AnimalExportFieldEnum.BreedCode,
  group: AnimalExportFieldEnum.GroupName,
  field: AnimalExportFieldEnum.FieldName,
  tags: AnimalExportFieldEnum.Tags,
  sire: AnimalExportFieldEnum.SireAnimalId,
  dam: AnimalExportFieldEnum.DamAnimalId,
  currentWeight: AnimalExportFieldEnum.CurrentEstimatedWeight,
  lastWeight: AnimalExportFieldEnum.LatestMeasuredWeight,
  daysSinceLastWeight: AnimalExportFieldEnum.DaysSinceWeighed,
  growthRate: AnimalExportFieldEnum.DailyLiveWeightGain,
  isPregnant: AnimalExportFieldEnum.IsPregnant,
  pregnancyDueDate: AnimalExportFieldEnum.PregnancyDueDate,
  dateOnFarm: AnimalExportFieldEnum.Dof,
  daysOnFarm: AnimalExportFieldEnum.DaysOnFarm,
  dateLeftFarm: AnimalExportFieldEnum.DateLeftFarm,
  deadAt: AnimalExportFieldEnum.DeadAt,
  killWeight: AnimalExportFieldEnum.KillWeight,
  killQuality: AnimalExportFieldEnum.KillQuality,
  killFatScore: AnimalExportFieldEnum.KillFatScore,
  serviceType: AnimalExportFieldEnum.ServiceType,
  calvingEaseScore: AnimalExportFieldEnum.CalvingEase,
  previousKeeper: AnimalExportFieldEnum.PreviousKeeperName,
  updatedAt: AnimalExportFieldEnum.UpdatedAt,
  lastRegulatorySyncedDate: AnimalExportFieldEnum.LastSynced,
  isBcmsSynced: AnimalExportFieldEnum.WithdrawalStatus,
};

export const getAnimalExportColumn = (column: Column<AnimalFragment>): AnimalExportColumnInput | undefined => {
  const columnId = column.id;
  if (!columnId || !exportFieldEnumMapping[columnId]) {
    return;
  }
  const exportFieldEnum = exportFieldEnumMapping[columnId];

  return { column: exportFieldEnum, label: getColumnLabel(column, true) };
};

export const getBooleanFilter = (value: string, trueCondition: string, falseCondition: string): boolean | undefined => {
  if (value === trueCondition) {
    return true;
  } else if (value === falseCondition) {
    return false;
  } else {
    return undefined;
  }
};

export const getDnaStatus = ({
  parentageTrioVerified,
  hasDnaResults,
}: {
  parentageTrioVerified: boolean | null | undefined;
  hasDnaResults: boolean | null | undefined;
}) => {
  const status = (() => {
    if (hasDnaResults == null && parentageTrioVerified == null) {
      return "notTested";
    } else if (hasDnaResults === true && parentageTrioVerified == null) {
      return "dnaTestSuccess";
    } else if (hasDnaResults === false && parentageTrioVerified == null) {
      return "dnaTestFail";
    } else if (hasDnaResults === true && parentageTrioVerified === true) {
      return "parentageSuccess";
    } else if (hasDnaResults === true && parentageTrioVerified === false) {
      return "parentageFail";
    } else if ((hasDnaResults == null || hasDnaResults === false) && parentageTrioVerified === true) {
      return "parentageSuccessWithoutDna";
    } else if ((hasDnaResults == null || hasDnaResults === false) && parentageTrioVerified === false) {
      return "parentageFailWithoutDna";
    } else {
      return "notTested";
    }
  })();
  const dnaTestedIconMap: Record<string, keyof typeof ICON_NAMES> = {
    notTested: "dnaGrey",
    dnaTestSuccess: "dnaBlue",
    dnaTestFail: "dnaAmber",
    parentageSuccess: "dnaGreen",
    parentageFail: "dnaRed",
    parentageSuccessWithoutDna: "dnaAmber",
    parentageFailWithoutDna: "dnaAmber",
  };

  const dnaTestedMessageMap = {
    notTested: "Animal has not been DNA tested.",
    dnaTestSuccess: "Animal has been DNA tested, but parentage not yet verified.",
    dnaTestFail: "Animal's DNA test was unsuccessful.",
    parentageSuccess: "Animal has been DNA tested and parentage has been verified.",
    parentageFail: "Animal has been DNA tested but parentage could not be verified.",
    parentageSuccessWithoutDna: "Animal has not been DNA tested but parentage has been verified.",
    parentageFailWithoutDna: "Animal has not been DNA tested and parentage could not be verified.",
  } as const;
  return {
    status: status,
    icon: dnaTestedIconMap[status],
    message: dnaTestedMessageMap[status],
  };
};

export const getAnimalStatus = (animal: AnimalFragment | undefined | null) => {
  if (!animal) {
    return "Not Found";
  }
  if (animal?.deadAt !== null) {
    return "Died";
  }
  if (animal?.dateLeftFarm !== null) {
    return "Off Farm";
  }
  return "On Farm";
};
