import { useQuery } from "@apollo/client";

import { getBUFromLocalStorage } from "helpers/storage";

import { GET_DISTRIBUTION_LISTS_COUNT } from "trading/api/queries";

import {
  GetDistributionListsCount,
  GetDistributionListsCountVariables,
} from "trading/api/types/GetDistributionListsCount";

export const useDistributionListsCount = () => {
  const { data, ...rest } = useQuery<GetDistributionListsCount, GetDistributionListsCountVariables>(
    GET_DISTRIBUTION_LISTS_COUNT,
    {
      variables: {
        businessUnit: getBUFromLocalStorage(),
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    },
  );

  const listsCount = data?.distributionLists?.count || 0;

  return {
    data: listsCount,
    ...rest,
  };
};
