// Libraries
import { Duration } from "luxon";
// Types
import { MyLots_myLots_edges_node } from "trading/api/Liveweight/types/MyLots";
import { BuyingLotNode } from "trading/components/containers/liveweight/BuyerListings";

type Listing = BuyingLotNode | MyLots_myLots_edges_node;

export const getListingAnimalsFormattedAge = (listing: Listing): string | undefined => {
  if (!listing.animalsAverageAge) return;

  const { days, months } = Duration.fromObject({ days: listing.animalsAverageAge })
    .shiftTo("months", "days")
    .toObject();

  let formattedAge = `${months} months`;
  if (days) formattedAge += ` ${Math.trunc(days)} days`;

  return formattedAge;
};
