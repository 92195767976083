import React from "react";
// Components
import { Pill } from "components";

export interface AnimalsPerformancePillProps {
  label: string;
  performanceCategory: string | null;
}

export const AnimalsPerformancePill: React.FC<AnimalsPerformancePillProps> = ({
  label,
  performanceCategory = null,
}) => {
  const getPillColour = () => {
    switch (performanceCategory) {
      case "high":
        return "green";
      case "average":
        return "blue";
      case "low":
        return "red";
      default:
        return "grey";
    }
  };

  return <Pill colour={getPillColour()} caption={label} />;
};
