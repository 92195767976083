import { useEffect, useState, VFC } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

const GoogleTagManagerWatcher: VFC = () => {
  const location = useLocation();
  const [isInitialized, setInitialization] = useState<boolean>();

  useEffect(() => {
    const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

    if (GTM_ID) {
      TagManager.initialize({ gtmId: GTM_ID });

      setInitialization(true);
    }
  }, []);

  useEffect(() => {
    if (isInitialized) {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageView",
          pathname: location.pathname,
        },
      });
    }
  }, [isInitialized, location.pathname]);

  return null;
};

export default GoogleTagManagerWatcher;
