import { ChangeEvent, useContext, FC } from "react";
import { CommonContext } from "config/commonProvider";
import { InputSelect } from "components";
import { BatchUploadFormat, useGetBatchUploadFormats } from "hooks/useGetBatchUploadFormats";

interface Props {
  batchUploadFormat: BatchUploadFormat | undefined;
  onChangeBatchUploadFormat: (batchUploadFormat: BatchUploadFormat) => void;
}

export const ImportWizardActivityTemplateSelect: FC<Props> = ({ batchUploadFormat, onChangeBatchUploadFormat }) => {
  const { batchUploadFormats, uploadFormatOptions } = useGetBatchUploadFormats();

  const { showNotification } = useContext(CommonContext);
  const handleChangeBatchUploadFormat = (event: ChangeEvent<HTMLSelectElement>) => {
    const newBatchUploadFormat = batchUploadFormats.find((uploadFormat) => uploadFormat.slug === event.target.value);

    if (!newBatchUploadFormat) return;

    onChangeBatchUploadFormat(newBatchUploadFormat);

    showNotification({
      message: `Successfully selected ${newBatchUploadFormat.name}`,
    });
  };

  return (
    <InputSelect
      onChange={handleChangeBatchUploadFormat}
      options={uploadFormatOptions}
      value={batchUploadFormat?.slug || ""}
    />
  );
};
