import { cloneElement, ReactElement, VFC } from "react";
// Components
import { Text } from "components";
// Resources
import styles from "./ImportSystem.module.scss";

interface ImportSystemWithChildren {
  children: ReactElement;
  name?: never;
}

interface ImportSystemWithoutChildren {
  children?: never;
  name: string;
}

type ImportSystemProps = ImportSystemWithChildren | ImportSystemWithoutChildren;

export const ImportSystem: VFC<ImportSystemProps> = ({ children, name }) => (
  <div className={styles.import_system}>
    {children ? (
      cloneElement(children, {
        className: styles.import_system__logo,
      })
    ) : (
      <Text smaller>{name}</Text>
    )}
  </div>
);
