import { FC } from "react";
// Utils
import { TRADING, VIEW_PUBLIC_LISTING_BASE } from "constants/Routes";
import { getBuyerListingCardLocation } from "./helpers/getBuyerListingCardLocation";
import { useListingCardPublicProps } from "hooks";
// Components
import { ListingCard } from "trading/components";
import { BuyingLotNode } from "..";

interface BuyerListingCardProps {
  listing: BuyingLotNode;
}

export const BuyerListingCard: FC<BuyerListingCardProps> = ({ listing }) => {
  const listingCardPublicProps = useListingCardPublicProps(listing);

  const location = getBuyerListingCardLocation(listing);

  const url = `${TRADING}${VIEW_PUBLIC_LISTING_BASE}/${listing.id}`;

  return <ListingCard {...listingCardPublicProps} location={location} url={url} />;
};
