import React from "react";

import pure from "recompose/pure";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { TableColumn } from ".";

import styles from "./styles.module.css";

export interface TableHeaderProps {
  columns: TableColumn[];
}

const Header: React.FC<TableHeaderProps> = ({ columns }) => (
  <TableHead className={styles.head}>
    <TableRow>
      {columns.map(({ label }, i) => {
        const key = `col${i}`;
        return <TableCell key={key}>{typeof label === "function" ? label() : label}</TableCell>;
      })}
    </TableRow>
  </TableHead>
);

export default pure(Header);
