import { graphql } from "@apollo/client/react/hoc";
import { withRouter } from "react-router-dom";

import { compose, withStateHandlers, withHandlers } from "recompose";

import { GET_GROUPS_LIST } from "groups/api/queries";
import { GET_FIELDS_LIST } from "api/MyLivestock/Fields/queries";
import { DELETE_FIELD } from "api/MyLivestock/Fields/mutations";

import { FIELDS, GROUPS } from "constants/Routes";
import { HEADER_TYPES } from "constants/General";

import { getBUFromLocalStorage } from "helpers/storage";

import { ShowNotification } from "config/commonProvider";

import GroupFieldHeader from "./GroupFieldHeader";
import { DELETE_GROUP_WITH_ANIMALS } from "groups/api/mutations";

interface Props {
  item: Group | FieldData;
  history: Record<string, unknown>;
  type: string;
  showConfirmModal: boolean;
  toggleModal: (showModal: boolean) => void;
  setShowConfirmModal: (showConfirmModal: boolean) => void;
  setError: (error?: string | null | undefined) => void;
  deleteField: (p: {
    variables: {
      input: {
        id: string;
      };
    };
  }) => Promise<any>;
  deleteGroup: (p: {
    variables: {
      input: {
        id: string;
      };
    };
  }) => Promise<any>;
  setRequesting: (requesting: boolean) => void;
}

export const removeElement =
  ({ item, type, history, setRequesting, toggleModal, setError, deleteField, deleteGroup }: Props) =>
  async (showNotification: ShowNotification): Promise<void> => {
    setError(null);
    setRequesting(true);

    let err = null;

    try {
      if (type === HEADER_TYPES.FIELD) {
        const { data } = await deleteField({
          variables: {
            input: {
              id: item.id,
            },
          },
        });
        const { errors } = data["deleteField"];
        err = errors;
      } else {
        const { data } = await deleteGroup({
          variables: {
            input: {
              id: item.id,
            },
          },
        });

        const { errors } = data["deleteGroupWithAnimals"];
        err = errors;
      }

      setRequesting(false);

      if (err) {
        // @ts-expect-error
        setError(err[0].message);
        showNotification({
          variant: "error",
          message: `Error deleting ${type}.`,
        });
      } else {
        showNotification({
          message: `${type === HEADER_TYPES.FIELD ? "Location" : "Group"} successfully deleted.`,
        });
        toggleModal(false);
        // @ts-expect-error
        history.push(type === HEADER_TYPES.FIELD ? FIELDS : GROUPS);
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: `Error deleting ${type}.`,
      });
      setError(`Error deleting ${type}.`);
      setRequesting(false);
    }
  };

export const handleConfirmAction =
  ({ history, item, type }: Props) =>
  (): void => {
    if (item) {
      // @ts-expect-error
      history.goBack();
    } else {
      // @ts-expect-error
      history.push(type === HEADER_TYPES.FIELD ? FIELDS : GROUPS);
    }
  };

export default compose(
  withRouter,
  graphql(DELETE_GROUP_WITH_ANIMALS, {
    name: "deleteGroup",
    options: () => ({
      refetchQueries: [
        {
          query: GET_GROUPS_LIST,
          variables: {
            businessUnitId: getBUFromLocalStorage(),
          },
        },
      ],
    }),
  }),
  graphql(DELETE_FIELD, {
    name: "deleteField",
    options: () => ({
      refetchQueries: [
        {
          query: GET_FIELDS_LIST,
          variables: {
            businessUnitId: getBUFromLocalStorage(),
          },
        },
      ],
    }),
  }),
  withStateHandlers(
    {
      requesting: false,
      showRemoveModal: false,
      showConfirmModal: false,
      error: null,
    },
    {
      setRequesting:
        () =>
        (requesting): Record<string, unknown> => ({
          requesting,
        }),
      setError:
        () =>
        (error): Record<string, unknown> => ({
          error,
        }),
      toggleModal:
        () =>
        (showRemoveModal): Record<string, unknown> => ({
          showRemoveModal,
        }),
      setShowConfirmModal:
        () =>
        (showConfirmModal): Record<string, unknown> => ({
          showConfirmModal,
        }),
    },
  ),
  withHandlers({
    removeElement,
    handleConfirmAction,
  }),
)(GroupFieldHeader);
