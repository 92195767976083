import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GetInventoryInfo,
  GetInventoryInfo_inventoryInfo,
  GetInventoryInfoVariables,
} from "api/Medicine/types/GetInventoryInfo";
import { GetStockItem, GetStockItemVariables, GetStockItem_stockItem } from "api/Medicine/types/GetStockItem";
import { getBUFromLocalStorage } from "helpers/storage";
import { GET_INVENTORY_INFO, GET_STOCK_MEDICINE_ITEM } from "medicines/api/queries";

interface UseMedicinesValues {
  batch: GetInventoryInfo_inventoryInfo | null;
  batches: (GetInventoryInfo_inventoryInfo | null)[];
  medicineDetails: GetStockItem_stockItem | null;
  medicines: string[];
  refreshMedicines: () => void;
  setMedicine: (medicineName: string | null) => void;
  setBatch: (batchId: string | null) => void;
}

export const useAdministerMedicine = (): UseMedicinesValues => {
  const [medicine, updateMedicine] = useState<string | null>(null);
  const [medicineDetails, updateMedicineDetails] = useState<GetStockItem_stockItem | null>(null);
  const [batches, updateBatches] = useState<(GetInventoryInfo_inventoryInfo | null)[]>([]);
  const [batch, updateBatch] = useState<GetInventoryInfo_inventoryInfo | null>(null);

  const businessUnitId = getBUFromLocalStorage();

  // get medicine stock items from BE
  const { data: medicinesData, refetch } = useQuery<GetInventoryInfo, GetInventoryInfoVariables>(GET_INVENTORY_INFO, {
    variables: {
      businessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  // get medicine details
  const [getMedicineDetails] = useLazyQuery<GetStockItem, GetStockItemVariables>(GET_STOCK_MEDICINE_ITEM, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    onCompleted: (data) => {
      updateMedicineDetails(data?.stockItem);
    },
  });

  const refreshMedicines = (): void => {
    refetch();
  };

  // set currently selected medicine to state
  const setMedicine = (medicine: string | null): void => updateMedicine(medicine);

  // set currently selected batch to state
  const setBatch = (batchId: string | null): void => {
    const filteredBatch = batches.find((item) => item?.id === batchId) || null;

    updateBatch(batchId ? filteredBatch : null);
  };

  // extract medicine names from batch data array (not ideal but here we are)
  const medicines = useMemo(() => {
    if (!medicinesData?.inventoryInfo) return [];

    return medicinesData?.inventoryInfo
      ?.reduce((acc: string[], medicine) => {
        // hide batches with no name, volume remaining or original volume
        if (!medicine?.name || !medicine.totalOriginalVolume || !medicine?.totalRemainingVolume) return acc;

        return !acc.includes(medicine?.name) ? [...acc, medicine.name] : acc;
      }, [])
      .sort();
  }, [medicinesData]);

  // update batch list when new medicine is selected
  useEffect(() => {
    if (!medicinesData?.inventoryInfo) return;

    const selectedMedicine = medicinesData.inventoryInfo.find((med) => med?.name === medicine);
    if (selectedMedicine && selectedMedicine?.stockItemId) {
      getMedicineDetails({
        variables: {
          businessUnitId,
          id: selectedMedicine?.stockItemId,
        },
      });
    }

    // get batches with a name, remaining volume and original volume
    const filteredBatches = medicinesData.inventoryInfo.filter(
      (item) => item?.totalOriginalVolume && item?.totalRemainingVolume && item?.name === medicine,
    );
    updateBatches(filteredBatches);
  }, [medicine]);

  return {
    batch,
    batches,
    medicineDetails,
    medicines,
    refreshMedicines,
    setMedicine,
    setBatch,
  };
};
