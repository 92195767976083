// API
import { MyLotQuery } from "generated/graphql";
import { OfferStatus } from "api/graphql-global-types";
// Utils
import { LOT_STATUSES } from "trading/constants";
import { getOfferStatuses, OfferStatuses } from "../getOfferStatuses";
// Types
import { StageProps, StageWithStatus } from "components/Common/Stages/Stage";

type StageStatus = Pick<StageWithStatus, "status" | "statusLabel"> | Record<string, never>;

export type ListingStatuses = keyof typeof LOT_STATUSES;

export const getSellerListingOfferStatus = (
  offerStatuses: OfferStatuses,
  listingStatus: ListingStatuses,
): StageStatus => {
  if (
    listingStatus !== "CREATED" &&
    listingStatus !== "AWAITING_PAYMENT" &&
    listingStatus !== "BUYER_MADE_PAYMENT_TO_BREEDR"
  ) {
    return {
      status: "complete",
    };
  }

  if (offerStatuses[OfferStatus.ACCEPTED]) {
    return {
      status: "complete",
      statusLabel: "Offer Accepted",
    };
  }

  if (offerStatuses[OfferStatus.PENDING]) {
    return {
      status: "pending",
      statusLabel: "Offer Received",
    };
  }

  return {
    status: "action",
    statusLabel: "Awaiting Offers",
  };
};

export const getSellerListingPaymentStatus = (listingStatus: ListingStatuses): StageStatus => {
  switch (listingStatus) {
    case "AWAITING_PAYMENT":
    case "BUYER_MADE_PAYMENT_TO_BREEDR": {
      return {
        status: "action",
        statusLabel: "Awaiting Payment",
      };
    }
    case "BREEDR_RECEIVED_PAYMENT":
    case "SELLER_SENT_ANIMALS": {
      return {
        status: "complete",
        statusLabel: "Payment Secured",
      };
    }
    case "BUYER_RECEIVED_ANIMALS":
    case "BREEDR_MADE_PAYMENT": {
      return {
        status: "complete",
      };
    }
    default: {
      return {};
    }
  }
};

export const getSellerListingDeliverStatus = (listingStatus: ListingStatuses): StageStatus => {
  switch (listingStatus) {
    case "BREEDR_RECEIVED_PAYMENT": {
      return {
        status: "action",
        statusLabel: "Ready to Deliver",
      };
    }
    case "SELLER_SENT_ANIMALS": {
      return {
        status: "pending",
        statusLabel: "Livestock Shipped",
      };
    }
    case "BUYER_RECEIVED_ANIMALS":
    case "BREEDR_MADE_PAYMENT": {
      return {
        status: "complete",
        statusLabel: "Livestock Received",
      };
    }
    default: {
      return {};
    }
  }
};

export const getSellerListingCompleteStatus = (listingStatus: ListingStatuses): StageStatus => {
  switch (listingStatus) {
    case "BREEDR_MADE_PAYMENT": {
      return {
        status: "complete",
        statusLabel: "Payment Released",
      };
    }
    default: {
      return {};
    }
  }
};

export const getSellerListingStages = (listing: MyLotQuery["myLot"]): StageProps[] => {
  if (!listing) {
    return [];
  }

  // get offer status
  const offerStatuses = getOfferStatuses(listing.lotOffers);
  const listingOfferStatus = getSellerListingOfferStatus(offerStatuses, listing.status as ListingStatuses);
  // get secure payment status
  const listingPaymentStatus = getSellerListingPaymentStatus(listing.status as ListingStatuses);
  // get deliver status
  const listingDeliverStatus = getSellerListingDeliverStatus(listing.status as ListingStatuses);
  // get complete status
  const listingCompleteStatus = getSellerListingCompleteStatus(listing.status as ListingStatuses);

  return [
    {
      icon: "transactionHandshake",
      iconSize: "large",
      title: "Offer",
      ...listingOfferStatus,
    },
    {
      icon: "securePayment",
      iconSize: "large",
      title: "Secure Payment",
      ...listingPaymentStatus,
    },
    {
      icon: "delivery",
      iconSize: "large",
      title: "Deliver",
      ...listingDeliverStatus,
    },
    {
      icon: "check",
      title: "Complete",
      ...listingCompleteStatus,
    },
  ];
};
