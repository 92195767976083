import { useHistory } from "react-router-dom";
import { useDeleteFinancialTransactionMutation } from "generated/graphql";
import { useEffect, useRef, useState } from "react";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";

export type ConfirmNavigationModalProps = ReturnType<typeof useConfirmNavigationModal>;

export const useConfirmNavigationModal = (isNewTransaction: boolean) => {
  const { id: businessUnitId } = useGetCurrentBusinessUnit();
  const history = useHistory();
  const [shouldDeleteTransactionOnRouteChange, setShouldDeleteTransactionOnRouteChange] = useState(isNewTransaction);
  const { handleError } = useHandleMutationResult();
  const [deleteFinancialTransaction, { loading }] = useDeleteFinancialTransactionMutation();

  const [blockedPath, setBlockedPath] = useState<string>();
  const [showNavigationModal, setShowNavigationModal] = useState<boolean>(false);

  const unblockHandle = useRef<() => void>();

  const handleConfirmNavigation = async (transactionId: string | undefined) => {
    try {
      if (unblockHandle && unblockHandle.current) {
        unblockHandle.current();
      }
      if (transactionId) {
        await deleteFinancialTransaction({
          variables: {
            input: {
              id: transactionId,
              businessUnit: +businessUnitId,
            },
          },
        });
      }
    } catch (error: unknown) {
      handleError(error);
    } finally {
      if (blockedPath) {
        history.push(blockedPath);
      }
    }
  };

  useEffect(() => {
    unblockHandle.current = history.block(({ pathname }) => {
      if (shouldDeleteTransactionOnRouteChange) {
        setBlockedPath(pathname);
        setShowNavigationModal(true);
        return false;
      }
    });
    return () => {
      unblockHandle.current && unblockHandle.current();
    };
  }, [history, shouldDeleteTransactionOnRouteChange]);

  return {
    setShouldDeleteTransactionOnRouteChange,
    setShowNavigationModal,
    showNavigationModal,
    handleConfirmNavigation,
    loading,
  };
};
