import { Flex, FieldDate } from "components";
import { FC, useState } from "react";

import { TextChart } from "reports/components/TextChart";
import { BarChart } from "reports/components/BarChart";
import { PieChart } from "reports/components/PieChart";
import { ColSpan } from "components/Structure/Flex";
import { TableChart } from "reports/components/TableChart";
import { useDashboardQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage/storage";
import { useLocale } from "helpers/translations/src";

interface IProps {
  reportType: string;
}

type Filters = { [key: string]: string };

export const BreedrReports: FC<IProps> = ({ reportType }) => {
  //Get details of report from query...
  const bu = getBUFromLocalStorage();
  const { terms } = useLocale();
  const [selectedFilters, setSelectedFilters] = useState<Filters>({});
  const { data } = useDashboardQuery({
    variables: {
      dashboardSlug: reportType,
    },
    fetchPolicy: "network-only",
  });

  const filters = data?.dashboard?.filters ?? [];

  return (
    <>
      {filters.length > 0 ? (
        <Flex
          container
          containerDirection="column"
          containerJustifyContent="center"
          containerAlignContent="center"
          containerAlignItems="center"
        >
          <Flex item m={3 as ColSpan} key={`on_farm_date`}>
            <FieldDate
              label={`On ${terms.farm} date`}
              inputProps={{
                name: "on_farm_date",
                onChange: (date): void => {
                  const formattedDate = date.toISOString().split("T")[0];
                  // TODO replace hardcoded `on_farm_date` key with the filterName
                  const updatedFilters = { ...selectedFilters, on_farm_date: formattedDate };
                  setSelectedFilters(updatedFilters);
                },
                value: selectedFilters?.on_farm_date ? new Date(selectedFilters?.on_farm_date) : new Date(),
                maxDate: new Date(),
              }}
            />
          </Flex>
        </Flex>
      ) : null}
      <Flex container containerJustifyContent="center" containerAlignContent="center" containerAlignItems="center">
        {data?.dashboard?.charts?.map((chart) => {
          let reportComponent: null | JSX.Element = null;
          switch (chart?.reportType) {
            case "pie":
              reportComponent = (
                <PieChart
                  dataQuery={chart?.dataParam ?? ""}
                  chartTitle={data?.dashboard?.slug ?? ""}
                  businessUnitId={bu}
                />
              );
              break;
            case "text":
              reportComponent = (
                <TextChart
                  dataQuery={chart?.dataParam ?? ""}
                  chartTitle={data?.dashboard?.slug ?? ""}
                  businessUnitId={bu}
                  filters={selectedFilters}
                />
              );
              break;
            case "bar":
              reportComponent = (
                <BarChart
                  dataQuery={chart?.dataParam ?? ""}
                  chartTitle={data?.dashboard?.slug ?? ""}
                  businessUnitId={bu}
                />
              );
              break;
            case "table":
              reportComponent = (
                <TableChart
                  dataQuery={chart?.dataParam ?? ""}
                  chartTitle={data?.dashboard?.slug ?? ""}
                  businessUnitId={bu}
                  filters={selectedFilters}
                />
              );
              break;
          }
          return (
            <Flex item m={chart?.layoutSize as ColSpan} key={`${chart?.reportType}-${chart?.dataParam}`}>
              {reportComponent}
            </Flex>
          );
        })}
      </Flex>
    </>
  );
};
