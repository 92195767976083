import { ChangeEvent, useState, VFC } from "react";
// Libraries
import { useMutation } from "@apollo/client";
// API
import { START_FARM_SYSTEM_TASK } from "api/Import/OtherSystems/mutations";
import { GetSupportedSystems_integrationSystems } from "api/Import/OtherSystems/types/GetSupportedSystems";
import { GetTaskInfo_farmSystemTask } from "api/Import/OtherSystems/types/GetTaskInfo";
import { StartFarmSystemTask, StartFarmSystemTaskVariables } from "api/Import/OtherSystems/types/StartFarmSystemTask";
// Utils
import { APHIS_SLUG, BCMS_SLUG } from "constants/Import";
import { setTaskIdToLocalStorage, removeTaskIdFromLocalStorage } from "helpers/storage";
import { useDispatch } from "react-redux";
// Components
import { ImportWeightForm, ImportWeightFile, BreedAssociationSystem } from "./ImportWeightForm";
import { ImportWeightCreateAnimals } from "./ImportWeightCreateAnimals";
import { ImportWeightResult } from "./ImportWeightResult";
import { setAvailableColumns, setUnmatchedColumns, setImportMenuState } from "state/asyncImportJobSlice";

export const ImportWeight: VFC = () => {
  const [taskId, setTaskId] = useState<string | number>("");

  const [error, setError] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const [selectedSystem, setSelectedSystem] = useState<
    GetSupportedSystems_integrationSystems | BreedAssociationSystem | null
  >(null);

  const [file, setFile] = useState<ImportWeightFile | null>(null);
  const handleSetFile = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      setFile({
        fileName: selectedFile.name,
        file: selectedFile,
      });

      return;
    }

    setFile(null);
  };
  const handleRemoveFile = (): void => {
    setFile(null);
    setError("");
    dispatch(setImportMenuState("closed"));
    dispatch(setUnmatchedColumns([]));
    dispatch(setAvailableColumns([]));
  };

  const [farmSystemTask, setFarmSystemTask] = useState<GetTaskInfo_farmSystemTask | null>(null);
  const [startFarmSystemTask] = useMutation<StartFarmSystemTask, StartFarmSystemTaskVariables>(START_FARM_SYSTEM_TASK);
  const createAnimals = async (farmSystemTask): Promise<void> => {
    const { data } = await startFarmSystemTask({
      variables: {
        input: {
          farmSystemTask,
        },
      },
    });

    const id = `${data?.startFarmSystemTask?.id || ""}`;
    if (id) {
      setTaskIdToLocalStorage(id);
      setTaskId(id);
      setFarmSystemTask(null);
    } else {
      setError(data?.startFarmSystemTask?.errors?.map((item) => item?.message).join("; ") || "");
    }
  };

  const clearAll = (): void => {
    removeTaskIdFromLocalStorage();
    handleRemoveFile();

    setTaskId("");
    setFarmSystemTask(null);
  };

  if (!taskId) {
    return (
      <ImportWeightForm
        error={error}
        file={file}
        handleRemoveFile={handleRemoveFile}
        handleSetFile={handleSetFile}
        setError={setError}
        setTaskId={setTaskId}
        setSelectedSystem={setSelectedSystem}
      />
    );
  }

  if (farmSystemTask) {
    return (
      <ImportWeightCreateAnimals
        clearAll={clearAll}
        createAnimals={createAnimals}
        farmSystemTask={farmSystemTask}
        fileName={file?.fileName || ""}
      />
    );
  }

  return (
    <ImportWeightResult
      changeTaskId={setTaskId}
      fileName={file?.fileName || ""}
      removeFile={handleRemoveFile}
      setCreateAnimals={setFarmSystemTask}
      isAphisIntegrationSystem={selectedSystem?.slug == APHIS_SLUG || false}
      isBcmsIntegrationSystem={selectedSystem?.slug == BCMS_SLUG || false}
    />
  );
};
