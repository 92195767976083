import { useQuery } from "@apollo/client";

import { GET_ANIMAL_TYPES } from "api/MyLivestock/Animal/queries";
import { GetAnimalTypes } from "api/MyLivestock/Animal/types/GetAnimalTypes";

export const useGetAnimalTypes = () => {
  const data = useQuery<GetAnimalTypes>(GET_ANIMAL_TYPES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  return data;
};
