// Utils
import { getPostcodeObscured } from "helpers/strings";

interface Params {
  countryName: string | undefined;
  postcode: string | undefined | null;
}

export const getListingAddressPublic = ({ countryName, postcode }: Params): string | undefined => {
  const obscuredPostcode = getPostcodeObscured(postcode || "");

  if (countryName && obscuredPostcode) return `${countryName}, ${obscuredPostcode}`;

  if (countryName) return countryName;

  if (obscuredPostcode) return obscuredPostcode;

  return;
};
