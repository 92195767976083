import { memo, useRef } from "react";
import { Field } from "..";
import inputStyles from "../Input/Input.module.scss";
import { BaseInputProps } from "../BaseInput";
import { InputNumber } from "components";

type NumberRangeProps = { from: BaseInputProps; to: BaseInputProps };

export const InputNumberRange = ({ from, to }: NumberRangeProps) => {
  const toRef = useRef(null);

  return (
    <div className={inputStyles.range}>
      <InputNumber {...from} />
      <span className={inputStyles.input__spacer}>to</span>
      <InputNumber ref={toRef} min={from.value as string | number | undefined} {...to} />
    </div>
  );
};
InputNumberRange.displayName = "InputNumberRange";

export const FieldNumberRange = memo<{
  errors?: {
    from: string | false;
    to: string | false;
  };
  helper?: string;
  label: string;
  inputProps: NumberRangeProps;
}>(({ errors: { from: errorFrom, to: errorTo } = { from: false, to: false }, inputProps, ...props }) => {
  let message = "";
  if (errorFrom) {
    message = errorFrom;
  }
  if (errorTo) {
    message = message ? `${message} ${errorTo}` : errorTo;
  }

  return (
    <Field error={message} labelFor={inputProps.from.name} {...props}>
      <InputNumberRange
        from={{
          ...inputProps.from,
          hasError: !!errorFrom,
        }}
        to={{
          ...inputProps.to,
          hasError: !!errorTo,
        }}
      />
    </Field>
  );
});

FieldNumberRange.displayName = "FieldNumberRange";
