import { TransactionType, ColumnsType, StatusesType } from "components/Regulatory/Regulatory";

export const computeTitlePart = (transactionType: TransactionType, transactionsCount: number): string => {
  const isMoreThanOne: boolean = transactionsCount > 1;

  switch (transactionType) {
    case "REGISTER_BIRTH": {
      return isMoreThanOne ? "these births" : "this birth";
    }
    case "MOVE_ON":
    case "MOVE_OFF": {
      return isMoreThanOne ? "these movements" : "this movement";
    }
    case "REGISTER_DEATH": {
      return isMoreThanOne ? "these deaths" : "this death";
    }
    default: {
      return "";
    }
  }
};

export const computeTextPart = (transactionType: TransactionType, transactionsCount: number): string => {
  const isMoreThanOne: boolean = transactionsCount > 1;

  switch (transactionType) {
    case "REGISTER_BIRTH": {
      return isMoreThanOne ? `these ${transactionsCount} births` : "this birth";
    }
    case "MOVE_ON":
    case "MOVE_OFF": {
      return isMoreThanOne ? `these ${transactionsCount} movements` : "this movement";
    }
    case "REGISTER_DEATH": {
      return isMoreThanOne ? `these ${transactionsCount} deaths` : "this death";
    }
    default: {
      return "";
    }
  }
};

export const transactionColumnToKey = (column: ColumnsType): StatusesType => {
  switch (column) {
    case "pending": {
      return ["PENDING"];
    }
    case "inProgress": {
      return ["IN_PROGRESS"];
    }
    case "failed": {
      return ["FAILED"];
    }
    case "successful": {
      return ["SUCCESS"];
    }
    default:
      return null;
  }
};

export const transactionTypeKeyToLabel = (type: TransactionType): string => {
  switch (type) {
    case "REGISTER_BIRTH": {
      return "Registration";
    }
    case "MOVE_ON": {
      return "Move ON";
    }
    case "MOVE_OFF": {
      return "Move OFF";
    }
    case "REGISTER_DEATH": {
      return "Deaths";
    }
    default: {
      return "";
    }
  }
};

export const transactionColumnKeyToLabel = (column: ColumnsType): string => {
  switch (column) {
    case "pending": {
      return "awaiting confirmation";
    }
    case "inProgress": {
      return "pending BCMS";
    }
    case "failed": {
      return "problems";
    }
    case "successful": {
      return "successful (past 60 days)";
    }
    default:
      return "warnings";
  }
};

export const transactionColumnKeyToShortLabel = (status: string | null): string => {
  switch (status) {
    case "PENDING": {
      return "Pending";
    }
    case "IN_PROGRESS": {
      return "In progress";
    }
    case "CANCELED": {
      return "Canceled";
    }
    case "FAILED": {
      return "Failed";
    }
    case "SUCCESS": {
      return "Success";
    }
    default:
      return status || "\u2014";
  }
};
