import { IdentifierEnumType } from "generated/graphql";
import { capitaliseFirstLetterOfEachWord } from "helpers/translations/src/format";
import { Terms } from "helpers/translations/src/useLocale/types";

export function upperCaseFirstLetter(str: string | undefined) {
  if (!str) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const convertSnakeToCamelCase = (input: IdentifierEnumType | string | null | undefined) => {
  if (!input) {
    return "";
  }
  const words = input.toLowerCase().split("_");
  const converted = words
    .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join("");
  return converted;
};

export const identifierNameLookup = (terms: Terms, item: string) => {
  switch (item) {
    case "name":
    case IdentifierEnumType.Name:
      return "Name";
    case "breedrId":
    case IdentifierEnumType.BreedrId:
      return "Breedr ID";
    case "visualId":
    case IdentifierEnumType.VisualId:
      return capitaliseFirstLetterOfEachWord(terms.vid);
    case "eId":
    case IdentifierEnumType.EId:
      return capitaliseFirstLetterOfEachWord(terms.eid);
    case "brucellosisId":
    case IdentifierEnumType.BrucellosisId:
      return "Brucellosis ID";
    case "pedigreeId":
    case IdentifierEnumType.PedigreeId:
      return capitaliseFirstLetterOfEachWord(terms.pedigreeId);
    case "tattoo":
    case IdentifierEnumType.Tattoo:
      return "Tattoo ID";
    case "trichId":
    case IdentifierEnumType.TrichId:
      return "Trich ID";
    case "tsuBarcode":
    case IdentifierEnumType.TsuBarcode:
      return "TSU Barcode";
    case "herdDoggId":
    case IdentifierEnumType.HerdDoggId:
      return "HerdDogg ID";
    case "passportNumber":
    case IdentifierEnumType.PassportNumber:
      return capitaliseFirstLetterOfEachWord(terms.passportNumber);
    case "uhfId":
    case IdentifierEnumType.UhfId:
      return "UHF ID";
    case "alternativeId":
    case IdentifierEnumType.AlternativeId:
      return "Alternative ID";
    default:
      return "";
  }
};
