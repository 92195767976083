import React from "react";
import { Field } from "components";
import { BaseInput, BaseInputProps } from "components/Common/Field/BaseInput";
import { FieldProps } from "..";

type Props = { inputProps: BaseInputProps } & FieldProps;

export const InputPassword: React.FC<BaseInputProps> = (props) => <BaseInput {...props} type="password" />;

export const FieldPassword: React.FC<Props> = ({ error, inputProps, ...props }) => (
  <Field error={error} labelFor={inputProps.name} {...props}>
    <InputPassword hasError={!!error} {...inputProps} />
  </Field>
);
