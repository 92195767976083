import React, { CSSProperties, useContext } from "react";
import { Column, Row } from "react-table";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { TABLE_IDS } from "constants/Interface";
import { Table } from "components";
import { AnimalFragment } from "generated/graphql";
import { animal } from "../api/fragments";
import { useApolloClient } from "@apollo/client";
import { removeNothings } from "helpers/general";

interface SelectedAnimalsTableProps {
  columns: Column<AnimalFragment>[];
  hiddenColumns?: string[];
  sourceTableId: typeof TABLE_IDS[keyof typeof TABLE_IDS];
  fromPage?: "field" | "group" | "livestock" | "tags";
  rowStyles?: (row: Row<AnimalFragment>) => CSSProperties;
}

export const SelectedAnimalsTable: React.FC<SelectedAnimalsTableProps> = ({
  columns,
  sourceTableId,
  fromPage,
  rowStyles,
  hiddenColumns,
}) => {
  const tableId = TABLE_IDS.ANIMAL_LIST_SERVER_SELECTED;

  const { getTableOptions } = useContext(TableOptionsContext);
  const client = useApolloClient();

  const sourceTableOptions = getTableOptions<AnimalFragment>(sourceTableId);

  const animalIds = React.useMemo(
    () => Array.from(sourceTableOptions?.selectedRowsIds ?? []),
    [sourceTableOptions?.selectedRowsIds],
  );

  const [data, setData] = React.useState<Array<AnimalFragment>>([]);

  React.useEffect(() => {
    const animals = animalIds.map((id) => {
      return client.readFragment<AnimalFragment>({
        id: `Animal:${id}`,
        fragment: animal,
      });
    });

    setData(removeNothings(animals));
  }, [animalIds, client, sourceTableId]);

  return (
    <Table<AnimalFragment>
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
      pagination
      manualSortBy={true}
      manualPagination={true}
      initialPageSize={50}
      isAnimals={!(fromPage === "field")}
      tableId={tableId}
      rowStyles={rowStyles}
    />
  );
};
