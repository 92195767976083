import { Breadcrumbs } from "components/Common/Breadcrumbs";
import { Header } from "components/Common/Header";
import { PageHeader } from "components/Structure/PageHeader";
import { TransactionForm } from "financial/components/TransactionForm";
import { PageContent, Spacer, Text } from "components";
import { FINANCIAL_TRANSACTIONS } from "constants/Routes";
import { useParams } from "react-router-dom";

export const Transaction = () => {
  const { id } = useParams<FinancialTransactionFormParams>();

  return (
    <>
      <PageHeader>
        <Breadcrumbs rootPaths={[{ path: "Transactions", route: FINANCIAL_TRANSACTIONS }]} currentPath="Transaction" />
        <Header title="Financial" subTitle={<Text>Keep your sale and purchase transactions in one place.</Text>} />
      </PageHeader>
      <PageContent>
        <Spacer baselineHeight={2} />
        <TransactionForm transactionId={id} />
      </PageContent>
    </>
  );
};
