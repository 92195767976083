import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { BreedrLogoSpinner } from "twComponents/BreedrLogoSpinner";
import { cn } from "utils/misc";
import { Slot } from "@radix-ui/react-slot";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded text-xs font-semibold outline-none disabled:pointer-events-none disabled:opacity-50 transition-colors",
  {
    variants: {
      variant: {
        default:
          "bg-breedr-yellow-500 hover:bg-breedr-yellow-500/80 hover:bg-breedr-yellow-500/80 focus:bg-breedr-yellow-400 border-b border-breedr-yellow-700 border-solid",
        secondary: "bg-green-500 hover:bg-green-500/80 focus:bg-green-500/60 border-b border-green-700 border-solid",
        destructive:
          "bg-red-500 hover:bg-red-500/90 focus:bg-red-500/80 border-b border-red-700 border-solid text-white",
        outline: "bg-white border border-gray-300 border-solid hover:border-gray-400 focus:border-gray-500",
        ghost: "focus:text-black/70",
        text: "text-primary cursor-pointer text-center",
      },
      size: {
        xs: "h-8 p-1 min-w-[40px]",
        sm: "h-8 p-2 min-w-[90px]",
        md: "h-10 p4 min-w-[90px]",
        lg: "h-12 p-5 min-w-[90px]",
        square: "h-8 w-8",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "sm",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean;
  asChild?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, loading, children, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), `${loading ? "opacity-50" : "opacity-100"}`)}
        ref={ref}
        type={props.type || "button"}
        {...props}
      >
        {loading ? <BreedrLogoSpinner variant={"dark"} /> : children}
      </Comp>
    );
  },
);

Button.displayName = "Button";
