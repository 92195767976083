import { Field } from "components";
import { BaseInput } from "components/Common/Field/BaseInput";
import { FieldText } from "components/Common/Field/Text";
import { Control, Controller } from "react-hook-form";
import { AnimalSchema } from "../AnimalForm/validation";
import MaskedInput from "react-text-mask";

export type InputLookupProps = {
  [key in keyof typeof InputLookup]: Parameters<typeof InputLookup[key]>[0];
};

export const InputLookup = {
  breedrId: () => null,
  passportNumber: ({
    isCattle,
    isGB,
    disabled,
    onChange,
    control,
  }: {
    isCattle: boolean;
    isGB: boolean;
    disabled: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, fieldName: keyof AnimalSchema) => void;
    control: Control<AnimalSchema>;
  }) =>
    isCattle && isGB ? (
      <Controller
        control={control}
        name="passportNumber"
        render={({ formState: { errors }, field: { value } }) => {
          return (
            <FieldText
              key="passportNumber"
              label="Passport Number *"
              error={errors.passportNumber?.message}
              inputProps={{
                name: "passportNumber",
                disabled,
                placeholder: "Enter",
                value,
                onChange: (event) => onChange(event, "passportNumber"),
              }}
            />
          );
        }}
      />
    ) : null,
  visualId: ({ control }: { control: Control<AnimalSchema> }) => (
    <Controller
      key="visualId"
      control={control}
      name="visualId"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label="VID"
            error={errors.visualId?.message}
            inputProps={{
              name: "visualId",
              placeholder: "Enter",
              value,
              onChange,
            }}
          />
        );
      }}
    />
  ),
  eId: ({
    onChange,
    control,
    adornmentLeft,
  }: {
    onChange: (event: React.ChangeEvent<HTMLInputElement>, fieldName: keyof AnimalSchema) => void;
    control: Control<AnimalSchema>;
    adornmentLeft?: {
      labelFor: string;
      label: string;
    };
  }) => (
    <Controller
      key="eId"
      control={control}
      name="eId"
      render={({ formState: { errors }, field: { value } }) => {
        return (
          <FieldText
            label="EID"
            error={errors.eId?.message}
            inputProps={{
              name: "eId",
              placeholder: "Enter",
              value,
              onChange: (event) => onChange(event, "eId"),
              adornmentLeft,
            }}
          />
        );
      }}
    />
  ),
  brucellosisId: ({ control, disabled }: { control: Control<AnimalSchema>; disabled: boolean }) => (
    <Controller
      key="brucellosisId"
      control={control}
      name="brucellosisId"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label="Brucellosis ID"
            error={errors.brucellosisId?.message}
            inputProps={{
              name: "brucellosisId",
              placeholder: "Enter",
              value,
              onChange,
              disabled,
            }}
          />
        );
      }}
    />
  ),
  pedigreeId: ({ label, control }: { label: string; control: Control<AnimalSchema> }) => (
    <Controller
      key="pedigreeId"
      control={control}
      name="pedigreeId"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label={label}
            error={errors.pedigreeId?.message}
            inputProps={{
              name: "pedigreeId",
              placeholder: "Enter",
              value,
              onChange,
            }}
          />
        );
      }}
    />
  ),
  tattoo: ({ control }: { control: Control<AnimalSchema> }) => (
    <Controller
      key="tattoo"
      control={control}
      name="tattoo"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label="Tattoo ID"
            error={errors.tattoo?.message}
            inputProps={{
              name: "tattoo",
              placeholder: "Enter",
              value,
              onChange,
            }}
          />
        );
      }}
    />
  ),
  trichId: ({ control, disabled }: { control: Control<AnimalSchema>; disabled: boolean }) => (
    <Controller
      key="trichId"
      control={control}
      name="trichId"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label="Trich ID"
            error={errors.trichId?.message}
            inputProps={{
              name: "trichId",
              placeholder: "Enter",
              value,
              onChange,
              disabled,
            }}
          />
        );
      }}
    />
  ),
  tsuBarcode: ({ control }: { control: Control<AnimalSchema> }) => (
    <Controller
      key="tsuBarcode"
      control={control}
      name="tsuBarcode"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label="TSU Barcode"
            error={errors.tsuBarcode?.message}
            inputProps={{
              name: "tsuBarcode",
              placeholder: "Enter",
              value,
              onChange,
            }}
          />
        );
      }}
    />
  ),
  uhfId: ({ control }: { control: Control<AnimalSchema> }) => (
    <Controller
      key="uhfId"
      control={control}
      name="uhfId"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label="UHF ID"
            error={errors.uhfId?.message}
            inputProps={{
              name: "uhfId",
              placeholder: "Enter",
              value,
              onChange,
            }}
          />
        );
      }}
    />
  ),
  herdDoggId: ({
    control,
    forwardRef,
  }: {
    control: Control<AnimalSchema>;
    forwardRef: React.ForwardedRef<JSX.Element>;
  }) => (
    <Controller
      key="herdDoggId"
      control={control}
      name="herdDoggId"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <MaskedInput
            label="Herddog ID"
            mask={herdDoggIdMask}
            name="herdDoggId"
            ref={forwardRef}
            placeholder="__:__:__:__:__:__"
            value={value}
            onChange={onChange}
            render={(ref, props): JSX.Element => {
              return (
                <Field error={errors.herdDoggId?.message} labelFor="herdDoggId" {...props}>
                  <BaseInput ref={ref} hasError={!!errors.herdDoggId} {...props} />
                </Field>
              );
            }}
          />
        );
      }}
    />
  ),
  alternativeId: ({ control }: { control: Control<AnimalSchema> }) => (
    <Controller
      key="alternativeId"
      control={control}
      name="alternativeId"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label="Alternative ID"
            error={errors.alternativeId?.message}
            inputProps={{
              name: "alternativeId",
              placeholder: "Enter",
              value,
              onChange,
            }}
          />
        );
      }}
    />
  ),
  name: ({ control }: { control: Control<AnimalSchema> }) => (
    <Controller
      key="name"
      control={control}
      name="name"
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <FieldText
            label="Name"
            error={errors.name?.message}
            inputProps={{
              name: "name",
              placeholder: "Enter",
              value,
              onChange,
            }}
          />
        );
      }}
    />
  ),
};

export const herdDoggIdMask = [
  /\S/,
  /\S/,
  ":",
  /\S/,
  /\S/,
  ":",
  /\S/,
  /\S/,
  ":",
  /\S/,
  /\S/,
  ":",
  /\S/,
  /\S/,
  ":",
  /\S/,
  /\S/,
];
