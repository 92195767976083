import { useHistory } from "react-router";
import { Header, PageContent, PageHeader, Spacer } from "components";
import { ADDRESS_BOOK, CREATE_ADDRESS, SETTINGS } from "constants/Routes";
import { ContactsTable } from "settings/components/ContactsTable";

export const ContactsList = () => {
  const history = useHistory();

  const handleGoToAddAddress = (): void => {
    history.push(`${SETTINGS}${ADDRESS_BOOK}${CREATE_ADDRESS}`);
  };

  return (
    <>
      <PageHeader>
        <Header
          title="Address Book"
          subTitle="A quick way to capture details of useful contacts to include Previous Keepers, Vets, etc"
        />
      </PageHeader>

      <PageContent
        floatingIconButton={{
          iconName: "plus",
          onClick: handleGoToAddAddress,
        }}
      >
        <Spacer baselineHeight={2} />
        <ContactsTable />
      </PageContent>
    </>
  );
};
