import { compose, withHandlers, withStateHandlers } from "recompose";
import { DELETE_SIRE } from "api/Sires/mutations";
import { graphql } from "@apollo/client/react/hoc";
import { withRouter } from "react-router-dom";
import { GET_MY_SIRES_LIST } from "api/Sires/queries";
import { getBUFromLocalStorage } from "helpers/storage";
import TableActionColumn from "./TableActionColumn";

const sireDelete =
  ({ sire, setError, setRequesting, deleteSire, setModalShow }) =>
  async (showNotification): Promise<void> => {
    setError(null);
    setRequesting(true);
    const BU = getBUFromLocalStorage();

    try {
      const {
        data: {
          deleteSire: { success },
        },
      } = await deleteSire({
        variables: {
          input: {
            id: sire.id,
          },
        },
        update: (cache) => {
          const siresQuery = {
            query: GET_MY_SIRES_LIST,
            variables: {
              businessUnitId: BU,
            },
          };
          const { sires: cachedSires = [] } = cache.readQuery(siresQuery);
          cache.writeQuery({
            ...siresQuery,
            data: {
              sires: cachedSires.filter((cachedSire) => sire.id !== cachedSire.id),
            },
          });
        },
      });

      if (success) {
        showNotification({
          message: "Sire successfully deleted",
        });
      } else {
        showNotification({
          variant: "error",
          message: "Error deleting sire",
        });
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Error deleting sire",
      });
    } finally {
      setModalShow(false);
    }
  };

export default compose(
  withRouter,
  graphql(DELETE_SIRE, {
    name: "deleteSire",
  }),
  withStateHandlers(
    {
      showModal: false,
      requesting: false,
      error: null,
    },
    {
      setModalShow:
        () =>
        (showModal): Record<string, unknown> => ({
          showModal,
        }),
      setRequesting:
        () =>
        (requesting): Record<string, unknown> => ({
          requesting,
        }),
      setError:
        () =>
        (error): Record<string, unknown> => ({
          error,
        }),
    },
  ),
  withHandlers({
    sireDelete,
  }),
)(TableActionColumn);
