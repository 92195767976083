// @ts-ignore
import { IntercomAPI } from "react-intercom";
import { NetworkStatus } from "@apollo/client";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { LOT_STATUSES } from "trading/constants";
import {
  MARKETPLACE,
  MY_LISTINGS,
  SELLING,
  TRADING,
  VIEW_LISTING_BASE,
  VIEW_PUBLIC_LISTING_BASE,
} from "constants/Routes";
import { getIntercomMessage } from "trading/components/StatusPill/helpers";
import { getBUFromLocalStorage } from "helpers/storage";
import { isListingSold, isListingTradeAgreed } from "trading/util";
import { BuyerListing, SellerListing, ListingProgress } from "trading/components/containers";
import { StatusPill } from "trading/components";
import { Breadcrumbs, Button, Header, LoadingOverlay, PageContent, PageHeader, Spacer, Pill } from "components";
import { useBuyingLotQuery, useMyLotQuery } from "generated/graphql";
import { useHubSpot } from "hooks/useHubSpot";

export const ViewListing: React.FC = () => {
  const { id, publicId } = useParams<LiveweightListingPageParams>();
  const history = useHistory();
  const businesUnitId = getBUFromLocalStorage();

  const {
    data: myLotData,
    loading: myLotLoading,
    error: myLotError,
    refetch: refetchMyLot,
    networkStatus,
  } = useMyLotQuery({
    skip: id == undefined,
    fetchPolicy: "cache-and-network",
    variables: {
      id: parseInt(id as string),
      businessUnit: businesUnitId,
    },
  });
  const {
    data: buyingLotData,
    loading: buyingLotLoading,
    refetch: refetchPublicLot,
    error: buyingLotError,
  } = useBuyingLotQuery({
    skip: publicId == undefined,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    variables: { id: parseInt(publicId as string), businessUnitId: businesUnitId },
  });
  const isMyPublicLot = buyingLotData?.buyingLot?.businessUnit?.id === getBUFromLocalStorage();
  const isMyLotWithoutOffers = !myLotData?.myLot?.lotOffers?.length && !myLotData?.myLot?.isExpired;

  const isLoading = myLotLoading || buyingLotLoading;
  const isRequestError = myLotError || buyingLotError;
  const data = id ? myLotData?.myLot : buyingLotData?.buyingLot;
  const isPrivate = !data?.isPublic;
  const rootTitle = id ? "My Listings" : "Liveweight";
  const rootRoute = id ? `${TRADING}${MY_LISTINGS}` : `${TRADING}${MARKETPLACE}`;
  const lotStatus = data?.status as keyof typeof LOT_STATUSES;
  const isOfferAccepted = isListingTradeAgreed(lotStatus);
  const isLotSold = isListingSold(lotStatus);
  const isAcceptedMyOffer = !!buyingLotData?.buyingLot?.isAcceptedMyOffer;
  const isOfferAcceptedByAnotherBuyer = !isAcceptedMyOffer && (isOfferAccepted || isLotSold) && !!publicId;
  const { isHubSpotChatEnabled, openHubSpotChat } = useHubSpot();

  const pageTitle = data?.name || "Listing";
  const isError: boolean = !!isRequestError || data === null;

  const handleClickEditListing = (): void => history.push(`${TRADING}${SELLING}/edit-listing/${id}`);

  if (isLoading || networkStatus === NetworkStatus.refetch) return <LoadingOverlay />;

  if (isError) return <Redirect to={rootRoute} />;

  const handleUpdatePublicLot = (): void => {
    refetchPublicLot().catch((error) => {
      // eslint-disable-next-line
      console.log(error);
    });
  };

  const handleUpdateMyLot = (): void => {
    refetchMyLot().catch((error) => {
      // eslint-disable-next-line
      console.log(error);
    });
  };

  const handleClickPrivateView = (): void => {
    history.push(`${TRADING}${VIEW_LISTING_BASE}/${publicId}`);
  };

  const handleClickPublicView = (): void => {
    history.push(`${TRADING}${VIEW_PUBLIC_LISTING_BASE}/${id}`);
  };

  const handleOpenIntercom = (): void => {
    if (isHubSpotChatEnabled) {
      openHubSpotChat("?chat=true");
    } else {
      const message = getIntercomMessage({
        listingTitle: data?.name,
        referenceCode: data?.referenceCode,
        messageVariant: "seller",
      });
      IntercomAPI("showNewMessage", message);
    }
  };

  const expiresAtSubTitle = data?.expiresAt ? (
    <StatusPill
      startDate={data?.startDate}
      expiryDate={data?.expiresAt}
      chatMessageProps={{
        listingTitle: data?.name,
        referenceCode: data?.referenceCode,
        messageVariant: isMyPublicLot || id ? "seller" : "buyer",
      }}
    />
  ) : undefined;
  const lotStatusSubTitle = (
    <Pill
      caption={isOfferAccepted ? "Trade Agreed" : "Sold"}
      colour={isOfferAccepted ? "green" : "red"}
      colourInverted
    />
  );
  const headerSubTitle = (
    <>
      {isOfferAcceptedByAnotherBuyer || isOfferAccepted || isLotSold ? lotStatusSubTitle : expiresAtSubTitle}

      {isPrivate ? <Pill caption="Shared privately" icon="people" /> : null}
    </>
  );

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: rootTitle,
              route: rootRoute,
            },
          ]}
          currentPath={pageTitle}
        />

        <Header backButton title={pageTitle} subTitle={headerSubTitle}>
          <>
            {publicId && isMyPublicLot ? (
              <Button caption="See seller view" colour="grey" onClick={handleClickPrivateView} variant="hollow" />
            ) : null}

            {id ? (
              <>
                <Button caption="See buyer view" colour="grey" onClick={handleClickPublicView} variant="hollow" />

                <Button caption="Ask a question" colour="grey" onClick={handleOpenIntercom} variant="hollow" />

                {isMyLotWithoutOffers ? <Button caption="Edit listing" onClick={handleClickEditListing} /> : null}
              </>
            ) : null}
          </>
        </Header>
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={4} />

        {!isMyPublicLot && !isOfferAcceptedByAnotherBuyer ? (
          <>
            <ListingProgress isBuyerListing={!!publicId} listing={data} />

            <Spacer baselineHeight={4} />
          </>
        ) : null}

        {publicId && buyingLotData?.buyingLot ? (
          <BuyerListing listing={buyingLotData.buyingLot} onUpdateListing={handleUpdatePublicLot} />
        ) : null}

        {id && myLotData?.myLot ? (
          <SellerListing listing={myLotData.myLot} onAfterMutateListing={handleUpdateMyLot} />
        ) : null}
      </PageContent>
    </>
  );
};
