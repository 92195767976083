import { FC, useState } from "react";
import { Button, Flex, Icon, InputCheckbox, Modal, Spacer } from "components";
import styles from "./ColumnOptionsModal.module.scss";
import { Column } from "react-table";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { getTableSettingsFromLocalStorage, setTableSettingsToLocalStorage } from "helpers/storage";

import { TABLE_IDS } from "constants/Interface";
import { createColumnObject } from "animals/AnimalsList/helpers";
import { CustomColumn } from "../types";
interface ColumnOptionsProps {
  isShowModal: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<Column<any>>;
  hiddenColumns?: Array<string>;
  onClose: () => void;
  setCustomColumnnTrigger: (value: number) => void;
  defaultColumnIds?: Array<string>;
  tableId: typeof TABLE_IDS[keyof typeof TABLE_IDS];
  primaryId?: string;
  secondaryId?: string;
  lockedColumn?: string;
}

export const ColumnOptionsModal: FC<ColumnOptionsProps> = ({
  columns,
  hiddenColumns = [],
  isShowModal = false,
  onClose,
  setCustomColumnnTrigger,
  defaultColumnIds = [],
  tableId,
  primaryId,
  secondaryId,
  lockedColumn,
}) => {
  const localStorageTableSettings = getTableSettingsFromLocalStorage(tableId);
  const localStorageColumns = localStorageTableSettings?.columns || [];
  const localStoragePageSize = localStorageTableSettings?.pageSize || 10;

  const initialColumns = createColumnObject(columns, localStorageColumns, hiddenColumns, defaultColumnIds, primaryId);
  const [customColumns, setCustomColumns] = useState<Array<CustomColumn>>(initialColumns);

  const handleModalToggle = () => {
    isShowModal = !isShowModal;
    onClose && onClose();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedColumns = customColumns.map((column) => {
      if (column.id === name) {
        return { ...column, checked };
      }
      return column;
    });
    setCustomColumns(updatedColumns);
    setSelectAll(updatedColumns.every((column) => column.checked));
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const columns = Array.from(customColumns);
    const [reorderedColumn] = columns.splice(result.source.index, 1);
    columns.splice(result.destination.index, 0, reorderedColumn);
    const updatedColumns = columns.map((column, index) => {
      return { ...column, index: index };
    });
    setCustomColumns(updatedColumns);
  };

  const [selectAll, setSelectAll] = useState(customColumns.every((column) => column.checked));

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedColumns = customColumns.map((column) => ({
      ...column,
      checked: selectAll ? false : true,
    }));
    setCustomColumns(updatedColumns);
  };

  const handleSetDefault = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const defaultColumns = initialColumns.map((column, index) => ({
      ...column,
      checked: defaultColumnIds.includes(column.id) || column.id === secondaryId,
      index: index,
    }));
    setCustomColumns(defaultColumns);
    setTableSettingsToLocalStorage(tableId, defaultColumns, localStoragePageSize);
    handleModalToggle();
    setCustomColumnnTrigger ? setCustomColumnnTrigger(Math.random()) : 1;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setTableSettingsToLocalStorage(tableId, customColumns, localStoragePageSize);
    handleModalToggle();
    setCustomColumnnTrigger ? setCustomColumnnTrigger(Math.random()) : 1;
  };
  return (
    <Modal
      active={isShowModal}
      handleClose={handleModalToggle}
      title="Edit Columns"
      subTitle="Select the data you would like to see in the table, you can reorder the list by dragging a row."
      size="small"
    >
      <form onSubmit={handleSubmit}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`${styles.options_container} ${snapshot.isDraggingOver ? styles.is_dragging_over : ""}`}
              >
                <li className={styles.select_all}>
                  <InputCheckbox
                    name="select-all"
                    label={selectAll ? "Unselect all" : "Select all"}
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </li>
                {customColumns.map((column, index) => (
                  <Draggable key={column.id} draggableId={column.id} index={index}>
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps} className={styles.option}>
                        <Flex
                          container
                          containerDirection="row"
                          containerJustifyContent="space-between"
                          containerAlignItems="center"
                        >
                          <InputCheckbox
                            name={column.id}
                            label={column.header}
                            checked={column.checked}
                            onChange={handleCheckboxChange}
                            disabled={column.id === lockedColumn}
                          />
                          <Flex item {...provided.dragHandleProps}>
                            <Icon name="move" size="small" />
                          </Flex>
                        </Flex>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <Spacer border={"bottom"} baselineHeight={1} />
        <Spacer baselineHeight={1} />
        <Flex container containerJustifyContent="space-between">
          <Flex item itemAlignSelf="flex-start">
            <Button
              caption="Reset to default"
              type="button"
              colour="grey"
              variant="hollow"
              onClick={handleSetDefault}
            />
          </Flex>
          <Flex item>
            <Flex container containerJustifyContent="flex-end">
              <Flex item>
                <Button caption="Cancel" type="button" colour="grey" variant="hollow" onClick={handleModalToggle} />
              </Flex>
              <Flex item itemGutter>
                <Button caption="Save" type="submit" colour="yellow" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
