import React from "react";
// Libraries
import { useQuery } from "@apollo/client";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";
// API
import { GetSiresList, GetSiresList_sires, GetSiresListVariables } from "api/Sires/types/GetSiresList";
import { GET_MY_SIRES_LIST } from "api/Sires/queries";
//Components
import { FieldSelect } from "components/Common/Field/Select";

type Props = {
  value: string;
  name: string;
  label: string;
  error: string;
  onSelect: (e) => void;
};

export const SireSelect: React.FC<Props> = ({ value, onSelect, name = "", label = "", error = "" }) => {
  const { data } = useQuery<GetSiresList, GetSiresListVariables>(GET_MY_SIRES_LIST, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const options = (data?.sires || []) as GetSiresList_sires[];

  return (
    <FieldSelect
      label={label}
      error={error}
      inputProps={{
        name,
        value: value || "",
        options: options,
        labelKey: "name",
        valueKey: "id",
        onChange: onSelect,
        placeholder: "No selected sire",
        isClearable: true,
      }}
    />
  );
};
