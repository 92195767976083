import React, { useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { CREATE_FIELD } from "api/MyLivestock/Fields/mutations";
import { CreateField, CreateFieldVariables } from "api/MyLivestock/Fields/types/CreateField";
import { OLD_UPDATE_FIELD_WITH_FRAGMENT } from "api/MyLivestock/Fields/mutations";
import { UpdateField, UpdateFieldVariables } from "api/MyLivestock/Fields/types/UpdateField";
import { CommonContext } from "config/commonProvider";
import { HEADER_TYPES } from "constants/General";
import { FIELD_VIEW, FIELDS } from "constants/Routes";
import { validationSchemaCreateField } from "helpers/myLivestock";
import { useGetAnimalsFromField } from "hooks";
import CreateFieldForm from "pages/MyLivestock/Fields/CreateEditField/CreateFieldForm";
import AnimalsInField from "pages/MyLivestock/Fields/AnimalsInField";
import FieldHeader from "pages/MyLivestock/General/GroupFieldHeader";
import { Breadcrumbs, LoadingOverlay, FormBuilder, PageHeader, PageContent, Spacer } from "components";
import styles from "./CreateEditField.module.scss";
import { useGetFieldLazyQuery } from "generated/graphql";

///This isn't used for editing fields anymore, use EditField instead
///TODO tidy this up or remove and change other EditField to CreateEditField

const CreateEditField: React.FC = () => {
  const { fieldId } = useParams<LivestockFieldPageParams>();
  const history = useHistory();
  const { showNotification } = useContext(CommonContext);

  const { data: dataAnimals } = useGetAnimalsFromField(fieldId);

  const [getField, { data, loading }] = useGetFieldLazyQuery();
  const [createField, { loading: createFieldLoading }] = useMutation<CreateField, CreateFieldVariables>(CREATE_FIELD);
  const [updateField, { loading: updateFieldLoading }] = useMutation<UpdateField, UpdateFieldVariables>(
    OLD_UPDATE_FIELD_WITH_FRAGMENT,
  );

  useEffect(() => {
    if (fieldId) {
      getField({ variables: { id: fieldId } });
    }
  }, [fieldId]);

  const { field: editField } = {
    ...data,
  };

  const { name, size, isYard, isOrganic, description, pastureTypes, location, isHoldingPen } = {
    ...editField,
  };

  const handleSaveField = async ({
    name,
    size,
    isYard,
    location,
    isOrganic,
    description,
    pastureTypes,
  }: FieldForm): Promise<void> => {
    const input = {
      name,
      size,
      isYard,
      location: +location,
      isOrganic,
      description,
      pastureTypes: pastureTypes.map((pastureType) => +pastureType),
    };

    try {
      let err;
      let fieldId;

      if (editField) {
        const { data } = await updateField({
          variables: {
            input: {
              ...input,
              id: editField.id,
            },
          },
        });

        err = data?.updateField?.errors;
        fieldId = editField.id;
      } else {
        const { data } = await createField({
          variables: {
            input,
          },
        });
        err = data?.createField?.errors;
        fieldId = data && data.createField && data.createField.field ? data.createField.field.id : null;
      }

      if (err && err.length > 0) {
        showNotification({
          variant: "error",
          message: err.map((item) => item?.message).join("; "),
        });
      } else {
        showNotification({
          message: `${capitaliseFirstLetter("location")} successfully ${editField ? "updated" : "created"}`,
        });
        history.push(`${FIELD_VIEW}/${fieldId}`);
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: `Error ${editField ? "updating" : "creating"} field`,
      });
    }
  };

  const initialValues = {
    name: name || "",
    description: description || "",
    size: size || 0,
    location: location ? location.id : "",
    isOrganic: isOrganic || false,
    isYard: isYard || false,
    pastureTypes: pastureTypes ? pastureTypes.map((el) => el.id) : ([] as string[]),
    isHoldingPen: isHoldingPen || false,
  };

  return (
    <>
      {createFieldLoading || updateFieldLoading || loading ? (
        <LoadingOverlay customStyles={styles.loadingOverlay} />
      ) : null}

      <FormBuilder
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchemaCreateField}
        onSubmit={handleSaveField}
      >
        {({ values, errors, handleChange, handleSubmit }): React.ReactNode => (
          <>
            <PageHeader>
              <Breadcrumbs
                rootPaths={[
                  {
                    path: "Locations",
                    route: FIELDS,
                  },
                ]}
                currentPath={
                  editField
                    ? `Update ${capitaliseFirstLetter("location")}`
                    : `Create ${capitaliseFirstLetter("location")}`
                }
              />

              <FieldHeader type={HEADER_TYPES.FIELD} item={editField} handleSubmit={handleSubmit} />
            </PageHeader>

            <PageContent>
              <Spacer baselineHeight={3} />

              <CreateFieldForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                canEditAddress={true}
              />

              {editField && dataAnimals ? (
                <>
                  <Spacer baselineHeight={2} />
                  <Spacer baselineHeight={3} border="top" gutter />

                  <AnimalsInField animals={dataAnimals?.field?.animals || []} />
                </>
              ) : null}
            </PageContent>
          </>
        )}
      </FormBuilder>
    </>
  );
};

export default CreateEditField;
