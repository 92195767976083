import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { isMobile } from "react-device-detect";
import { Formik, FormikProps, FormikActions } from "formik";
import * as Yup from "yup";
import { SignUpInput } from "components/Auth";
import { SignUpInputProps, HONEY_TRAP_FIELD_NAME } from "components/Auth/SignUp/SignUpInput";
import { USER_EXISTS } from "api/Auth/queries";
import { UserExists, UserExistsVariables } from "api/Auth/types/UserExists";
import { SIGN_UP_DETAILS } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { CommonContext } from "config/commonProvider";
import { Button } from "components";
import styles from "./styles.module.scss";

export const PASSWORD_VALIDATION_RULES = {
  LENGTH: /^.{8,}$/,
  LETTER: /^.*[A-Za-z]{1,}.*$/,
  UPPERCASE: /^.*[A-Z]{1,}.*$/,
  LOWERCASE: /^.*[a-z]{1,}.*$/,
  SYMBOL: /^.*[^A-Za-z0-9 ]{1,}.*$/,
  NUMBER: /^.*[0-9]{1,}.*$/,
};

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repeatEmail: string;
}

export type FormProps = FormikProps<FormValues>;

type GetFieldProps = Pick<SignUpInputProps, "name" | "onChange" | "onBlur" | "setFieldValue" | "error" | "value">;

enum FeildNames {
  firstName,
  lastName,
  email,
  repeatEmail,
  password,
}

type Field = keyof typeof FeildNames;

const signupSchema = Yup.object().shape({
  firstName: Yup.string().trim().max(30, " ").required(" "),
  lastName: Yup.string().trim().max(150, " ").required(" "),
  email: Yup.string().email("please enter a valid email address").required("please enter a valid email address"),
  password: Yup.string()
    .trim()
    .matches(PASSWORD_VALIDATION_RULES.LENGTH, " ")
    .matches(PASSWORD_VALIDATION_RULES.LETTER, " ")
    .matches(PASSWORD_VALIDATION_RULES.NUMBER, " ")
    .required(" "),
});

export const CreateAccountForm: React.FC = () => {
  const {
    mainCommonState: { tempSignUpData },
    setTempSignUpData,
  } = useContext(CommonContext);
  const history = useHistory();
  const client = useApolloClient();
  const [isLoading, setLoading] = useState<boolean>(false);
  const initialTemSignUpData = useRef(tempSignUpData);

  useEffect(() => {
    if (initialTemSignUpData.current) {
      setTempSignUpData(null);
      initialTemSignUpData.current = null;
    }
  }, [initialTemSignUpData.current, setTempSignUpData]);

  const handleFormSubmit = async (values: FormValues, props: FormikActions<FormValues>): Promise<void> => {
    const { repeatEmail, ...rest } = values;
    const { setFieldError } = props;

    if (repeatEmail) {
      return;
    }

    try {
      setLoading(true);
      const details = { ...values, password: values.password.replace(/./gi, "x") };
      // @ts-ignore
      delete details["repeatEmail"];

      // useLazyQuery is not an options because of handler lexical environment
      const { data } = await client.query<UserExists, UserExistsVariables>({
        query: USER_EXISTS,
        variables: {
          email: values.email,
        },
        fetchPolicy: "network-only",
      });

      const isUserNotExist: boolean = data?.userExists === false;

      if (isUserNotExist) {
        setTempSignUpData(rest);
        history.push(SIGN_UP_DETAILS);
      } else {
        setFieldError("email", "An account with this email address already exists");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line
      console.log("error", error);
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    [HONEY_TRAP_FIELD_NAME]: "",
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={signupSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, handleChange, handleBlur, setFieldValue, handleSubmit }: FormProps): JSX.Element => {
        const getFieldProps = (fieldName: Field): GetFieldProps => {
          return {
            name: fieldName,
            onChange: handleChange,
            onBlur: handleBlur,
            error: errors[fieldName],
            value: values[fieldName],
            setFieldValue,
          };
        };

        return (
          <form onSubmit={handleSubmit} className={styles.form} autoComplete="off">
            {!isMobile ? <h2 className={styles.title}>Step 1 of 2</h2> : null}
            <p className={styles.preamble}>
              {"Thanks for choosing the free Breedr app. You're moments away from transforming the way you farm."}
            </p>
            <h2 className={styles.title}>Create an account</h2>
            <div className={styles.fieldset}>
              <SignUpInput label="First Name *" {...getFieldProps("firstName")} size="small" maxLength={30} />
              <SignUpInput label="Second Name *" {...getFieldProps("lastName")} size="medium" maxLength={150} />
            </div>
            <SignUpInput label="Email *" {...getFieldProps("email")} />
            <SignUpInput label="Repeate Email *" {...getFieldProps(HONEY_TRAP_FIELD_NAME)} />
            <SignUpInput label="Password *" type="password" {...getFieldProps("password")} />

            <div className={styles.buttonWrapper}>
              <p className={styles.description}>
                <a
                  id={ELEMENTS_IDS.TERMS_OF_SERVICE_LINK}
                  href="https://www.breedr.co/terms-and-conditions/"
                  target="__blank"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a id={ELEMENTS_IDS.PRIVACY_POLICY_LINK} href="https://www.breedr.co/privacy-policy/" target="__blank">
                  Privacy Policy
                </a>
                .
              </p>
              <Button
                id={ELEMENTS_IDS.CREATE_ACCOUNT_FORM_BUTTON_NEXT_STEP}
                caption="Next step"
                requesting={isLoading}
                disabled={isLoading}
                className={styles.submitButton}
                height="medium"
                type="submit"
              />
            </div>
            <p className={styles.preamble}>
              If you are outside the UK and interested in Breedr please email{" "}
              <a
                id={ELEMENTS_IDS.EMAIL_LINK_CONTACT_BREEDR}
                href="mailto:support@breedr.co"
                className={styles.emailLink}
              >
                support@breedr.co
              </a>
            </p>
          </form>
        );
      }}
    </Formik>
  );
};
