import React from "react";

import pure from "recompose/pure";

import styles from "./styles.module.css";

interface SelectProps {
  error: string | null;
}

const ErrorBlock: React.FC<SelectProps> = ({ error }) => (
  <div className={styles.wrapperBlock}>
    <span className={styles.errorMessage}>{error}</span>
  </div>
);

export default pure(ErrorBlock);
