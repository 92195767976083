/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum ActivityTemplateFieldFieldType {
  ANIMAL_ID = "ANIMAL_ID",
  ANIMAL_IDS = "ANIMAL_IDS",
  BOOLEAN = "BOOLEAN",
  BUSINESS_UNIT_ID = "BUSINESS_UNIT_ID",
  CONTACT_ID = "CONTACT_ID",
  CURRENCY_ID = "CURRENCY_ID",
  DATE = "DATE",
  FAT_SCORE = "FAT_SCORE",
  FIELD_ID = "FIELD_ID",
  GROUP_ID = "GROUP_ID",
  MULTI_SELECT = "MULTI_SELECT",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
  SIRE_ID = "SIRE_ID",
  STRING = "STRING",
}

/**
 * An enumeration.
 */
export enum ActivityTemplateFieldUnits {
  ACRES = "ACRES",
  ANIMAL = "ANIMAL",
  ANIMALS = "ANIMALS",
  AREA = "AREA",
  BOOLEAN = "BOOLEAN",
  BUSINESS_UNIT = "BUSINESS_UNIT",
  CM = "CM",
  CONTACT = "CONTACT",
  COUNT = "COUNT",
  CURRENCY = "CURRENCY",
  DATE = "DATE",
  DAYS = "DAYS",
  DEGREES_CENTIGRADE = "DEGREES_CENTIGRADE",
  DISTANCE_LARGE = "DISTANCE_LARGE",
  DISTANCE_SMALL = "DISTANCE_SMALL",
  DISTANCE_SMALLER = "DISTANCE_SMALLER",
  FAT_SCORE = "FAT_SCORE",
  FIELD = "FIELD",
  FL_OZ = "FL_OZ",
  G = "G",
  GALLONS = "GALLONS",
  GROUP = "GROUP",
  KG = "KG",
  KG_PER_DAY = "KG_PER_DAY",
  KG_PER_HA = "KG_PER_HA",
  LBS = "LBS",
  MICRON = "MICRON",
  ML = "ML",
  MM = "MM",
  MULTI_SELECT = "MULTI_SELECT",
  NUMBER = "NUMBER",
  OZ = "OZ",
  PERCENTAGE = "PERCENTAGE",
  SELECT = "SELECT",
  SIRE = "SIRE",
  TEXT = "TEXT",
  VOLUME_LARGE = "VOLUME_LARGE",
  VOLUME_SMALL = "VOLUME_SMALL",
  WEIGHT_LARGE = "WEIGHT_LARGE",
  WEIGHT_SMALL = "WEIGHT_SMALL",
  WEIGHT_VERY_LARGE = "WEIGHT_VERY_LARGE",
}

/**
 * An enumeration.
 */
export enum AnimalFilterColor {
  AMBER = "AMBER",
  GREEN = "GREEN",
  RED = "RED",
}

export enum BcmsRequestReasonType {
  PASSWORD = "PASSWORD",
  USER_ID = "USER_ID",
  USER_ID_AND_PASSWORD = "USER_ID_AND_PASSWORD",
}

/**
 * An enumeration.
 */
export enum BusinessUnitSettingsSizeUnit {
  ACRE = "ACRE",
  HA = "HA",
}

/**
 * An enumeration.
 */
export enum BusinessUnitTbStatus {
  ENGLAND_EDGE_AREA_ANNUAL = "ENGLAND_EDGE_AREA_ANNUAL",
  ENGLAND_EDGE_AREA_SIX_MONTH = "ENGLAND_EDGE_AREA_SIX_MONTH",
  ENGLAND_HIGH_RISK_AREA = "ENGLAND_HIGH_RISK_AREA",
  ENGLAND_LOW_RISK_AREA = "ENGLAND_LOW_RISK_AREA",
  SCOTLAND_ISLE_OF_MAN_OTF = "SCOTLAND_ISLE_OF_MAN_OTF",
  WALES_HIGH_TB = "WALES_HIGH_TB",
  WALES_INTERMEDIATE_TB = "WALES_INTERMEDIATE_TB",
  WALES_LOW_TB = "WALES_LOW_TB",
}

/**
 * An enumeration.
 */
export enum BusinessUnitUserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  INVITED = "INVITED",
}

/**
 * An enumeration.
 */
export enum ContactContactType {
  FARM = "FARM",
  OTHER = "OTHER",
}

/**
 * An enumeration.
 */
export enum DeliveryAddressLocationType {
  ABATTOIR = "ABATTOIR",
  FARM = "FARM",
}

/**
 * An enumeration.
 */
export enum EmptyStateButton {
  ADD_CONNECTED_SYSTEM = "ADD_CONNECTED_SYSTEM",
  ANIMAL_LIST_VIEW = "ANIMAL_LIST_VIEW",
  BCMS_REQUEST_CREDENTIALS = "BCMS_REQUEST_CREDENTIALS",
  BLUETOOTH = "BLUETOOTH",
  CONNECTED_SYSTEMS = "CONNECTED_SYSTEMS",
  CREATE_GROUP = "CREATE_GROUP",
  CREATE_MESSAGE = "CREATE_MESSAGE",
  CRUSH_MODE = "CRUSH_MODE",
  EMAIL = "EMAIL",
  FARM_ADDRESSES = "FARM_ADDRESSES",
  FIELD_CREATE = "FIELD_CREATE",
  FIELD_LIST = "FIELD_LIST",
  GROUP_LIST_VIEW = "GROUP_LIST_VIEW",
  HELP_CENTRE = "HELP_CENTRE",
  HOMEPAGE = "HOMEPAGE",
  LOG_BIRTH = "LOG_BIRTH",
  MEDICINE_CABINET_ADD = "MEDICINE_CABINET_ADD",
  MEDICINE_CABINET_VIEW = "MEDICINE_CABINET_VIEW",
  MESSAGES = "MESSAGES",
  SCAN_PASSPORT = "SCAN_PASSPORT",
  SETTINGS = "SETTINGS",
  URL = "URL",
  USER_MANAGEMENT = "USER_MANAGEMENT",
  TAGS_LIST_VIEW = "TAGS_LIST_VIEW",
  TAG_VIEW = "TAG_VIEW",
}

/**
 * An enumeration.
 */
export enum EmptyStatePage {
  ADDRESSES = "ADDRESSES",
  ADDRESS_BOOK = "ADDRESS_BOOK",
  ANIMALS = "ANIMALS",
  CONNECTIONS = "CONNECTIONS",
  DEAD_WEIGHT = "DEAD_WEIGHT",
  FIELDS = "FIELDS",
  GROUPS = "GROUPS",
  MEDICINES = "MEDICINES",
  TRADING_LIVEWEIGHT = "TRADING_LIVEWEIGHT",
  TAGS = "TAGS",
  TAG = "TAG",
  DELIVERIES = "DELIVERIES",
}

/**
 * An enumeration.
 */
export enum FarmSystemTaskAction {
  IMPORT_ACTIVITIES = "IMPORT_ACTIVITIES",
  IMPORT_ANIMALS = "IMPORT_ANIMALS",
}

/**
 * An enumeration.
 */
export enum FarmSystemTaskStatus {
  DONE = "DONE",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  MANUAL_START = "MANUAL_START",
  PENDING = "PENDING",
}

/**
 * An enumeration.
 */
export enum FieldSizeUnit {
  ACRE = "ACRE",
  HA = "HA",
}

/**
 * An enumeration.
 */
export enum GroupCategory {
  BREEDING_STOCK = "BREEDING_STOCK",
  CULLS = "CULLS",
  PETS = "PETS",
  TERMINAL = "TERMINAL",
  YOUNGSTOCK = "YOUNGSTOCK",
}

/**
 * An enumeration.
 */
export enum InvoicePriceUnit {
  HEAD = "HEAD",
  KG = "KG",
  TOTAL = "TOTAL",
}

/**
 * An enumeration.
 */
export enum OfferPriceUnit {
  HEAD = "HEAD",
  KG = "KG",
  TOTAL = "TOTAL",
}

/**
 * An enumeration.
 */
export enum OfferStatus {
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  PAID = "PAID",
  PENDING = "PENDING",
}

/**
 * An enumeration.
 */
export enum ProducerGroupMemberStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/**
 * An enumeration.
 */
export enum RegulatoryTransactionApprovalApprovalStatus {
  ABORTED = "ABORTED",
  APPROVED = "APPROVED",
  CANCELED = "CANCELED",
  PENDING = "PENDING",
}

/**
 * An enumeration.
 */
export enum SystemCredentialFieldFieldType {
  EMAIL = "EMAIL",
  LOCATION = "LOCATION",
  PASSWORD = "PASSWORD",
  TEXT = "TEXT",
}

/**
 * An enumeration.
 */
export enum TradingStartUpMessageStartUpType {
  BUYER = "BUYER",
  SELLER = "SELLER",
}

/**
 * An enumeration.
 */
export enum UnitOfMeasurementBusinessUnitMeasurementType {
  AREA = "AREA",
  DISTANCE_LARGE = "DISTANCE_LARGE",
  DISTANCE_SMALL = "DISTANCE_SMALL",
  DISTANCE_SMALLER = "DISTANCE_SMALLER",
  VOLUME_LARGE = "VOLUME_LARGE",
  VOLUME_SMALL = "VOLUME_SMALL",
  WEIGHT_LARGE = "WEIGHT_LARGE",
  WEIGHT_SMALL = "WEIGHT_SMALL",
  WEIGHT_VERY_LARGE = "WEIGHT_VERY_LARGE",
}

/**
 * An enumeration.
 */
export enum WantTemplateShippingTerms {
  PAID_BY_BUYER = "PAID_BY_BUYER",
  PAID_BY_SELLER = "PAID_BY_SELLER",
}

export interface AddDeliveryAddressInput {
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  clientMutationId?: string | null;
  country: number;
  farm: number;
  locationId?: string | null;
  locationType?: string | null;
  postcode?: string | null;
  primary?: boolean | null;
  unitName: string;
}

// export interface AaaImportCsvInput {
//   clientMutationId?: string | null;
//   csvFile?: any | null;
//   farm?: number | null;
// }

export interface AphisImportCsvInput {
  clientMutationId?: string | null;
  csvFile?: any | null;
  farm?: number | null;
}

export interface BcmsImportCsvInput {
  clientMutationId?: string | null;
  csvFile?: any | null;
  farm?: number | null;
}

export interface BcmsServiceRequestGeneratePdfInput {
  addressOne: string;
  addressTwo: string;
  businessName: string;
  businessUnit: number;
  city: string;
  clientMutationId?: string | null;
  contactName: string;
  email: string;
  holdingId: string;
  phoneNumber: string;
  postcode: string;
  requestReason: BcmsRequestReasonType;
  submitDate?: any | null;
}

export interface CancelFarmSystemTaskInput {
  clientMutationId?: string | null;
  farmSystemTask?: number | null;
}

export interface CancelOfferForWantInput {
  businessUnit: number;
  clientMutationId?: string | null;
  offer: number;
}

export interface CompleteInvitationInput {
  clientMutationId?: string | null;
  hashCode: string;
  password: string;
}

export interface CreateAnimalFilterInput {
  ageFrom?: any | null;
  ageTo?: any | null;
  animalType?: number | null;
  breeds?: (any | null)[] | null;
  businessUnit: number;
  clientMutationId?: string | null;
  color?: string | null;
  currentWeightFrom?: any | null;
  currentWeightTo?: any | null;
  daysSinceLastWeightFrom?: any | null;
  daysSinceLastWeightTo?: any | null;
  deliveryFrom?: any | null;
  deliveryTo?: any | null;
  dobFrom?: any | null;
  dobTo?: any | null;
  dofFrom?: any | null;
  dofTo?: any | null;
  fields?: (any | null)[] | null;
  groups?: (any | null)[] | null;
  growthRateFrom?: any | null;
  growthRateTo?: any | null;
  inWithdrawal?: boolean | null;
  isAlert?: boolean | null;
  isBcmsSynced?: boolean | null;
  isMale?: boolean | null;
  isPregnant?: boolean | null;
  isRegulatorySynced?: boolean | null;
  lastRegulatorySyncedDate?: any | null;
  lastRegulatorySyncedDateFrom?: any | null;
  lastRegulatorySyncedDateTo?: any | null;
  lastWeightFrom?: any | null;
  lastWeightTo?: any | null;
  name: string;
  pregnancyDueDateFrom?: any | null;
  pregnancyDueDateTo?: any | null;
  sexClassifications?: (string | null)[] | null;
}

export interface CreateBankDetailsInput {
  accountName: string;
  accountNo: string;
  businessUnit: number;
  clientMutationId?: string | null;
  isBusinessAccount: boolean;
  sortCode: string;
}

export interface CreateContactInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  businessUnit: number;
  city?: string | null;
  clientMutationId?: string | null;
  contactType: string;
  country?: number | null;
  email?: string | null;
  farmId?: string | null;
  name: string;
  organisationName?: string | null;
  phoneNumber?: string | null;
  postcode?: string | null;
}

export interface CreateDistributionListInput {
  businessUnit: number;
  clientMutationId?: string | null;
  name: string;
  suppliers: number[];
}

export interface CreateFarmSystemInput {
  clientMutationId?: string | null;
  credentials?: any | null;
  farm: number;
  integrationSystem: number;
}

export interface CreateFieldInput {
  clientMutationId?: string | null;
  description?: string | null;
  groups?: (any | null)[] | null;
  id?: any | null;
  imageFiles?: (any | null)[] | null;
  isOrganic?: boolean | null;
  isYard?: boolean | null;
  location: number;
  name: string;
  pastureTypes?: (number | null)[] | null;
  size?: number | null;
}

export interface CreateGroupInput {
  animalBreeds?: (any | null)[] | null;
  animalType: number;
  businessUnit: number;
  businessUnitTarget?: number | null;
  category?: string | null;
  clientMutationId?: string | null;
  description?: string | null;
  field?: any | null;
  id?: any | null;
  imageFiles?: (any | null)[] | null;
  name: string;
  targetDate?: any | null;
  targetWeight?: number | null;
}

export interface CreateLotInput {
  animalType?: number | null;
  animals: (any | null)[];
  businessUnit: number;
  categoryOfSale?: number | null;
  clientMutationId?: string | null;
  deliveryAddress?: number | null;
  description?: string | null;
  distributionLists?: number[] | null;
  images?: (any | null)[] | null;
  isTacAccepted?: boolean | null;
  lotImages?: (LotImageInput | null)[] | null;
  name?: string | null;
  preMovementTestDate?: any | null;
  price: number;
  priceUnit: string;
  tbRequirements: string;
  tradingType: number;
}

export interface CreateOfferForLotInput {
  businessUnit: number;
  clientMutationId?: string | null;
  collectionDate?: any | null;
  comment?: string | null;
  hasAcceptedListing?: boolean | null;
  isTacAccepted?: boolean | null;
  lot: number;
  price: number;
  priceUnit?: string | null;
  totalPriceOnCreation?: number | null;
}

export interface CreatePreferredSuppliersInput {
  animalTypes?: number[] | null;
  businessUnit: number;
  clientMutationId?: string | null;
  emails?: string[] | null;
  fieldsman?: number | null;
  priceAdjustment?: any | null;
  suppliers?: number[] | null;
}

export interface CreateSireInput {
  businessUnit: number;
  clientMutationId?: string | null;
  name: string;
  passportNumber: string;
  pedigree?: boolean | null;
  registrationNumber?: string | null;
  sire?: string | null;
}

export interface DeleteActivityBulkInput {
  activities?: any[] | null;
  animals?: any[] | null;
  businessUnit?: number | null;
  clientMutationId?: string | null;
}

export interface DeleteAnimalFilterInput {
  businessUnit: number;
  clientMutationId?: string | null;
  id: number;
}

export interface DeleteDeliveryAddressInput {
  clientMutationId?: string | null;
  id: number;
}

export interface DeleteDistributionListInput {
  clientMutationId?: string | null;
  id: number;
}

export interface DeleteFarmSystemInput {
  clientMutationId?: string | null;
  farmSystem: number;
}

export interface DeleteFieldInput {
  clientMutationId?: string | null;
  id: any;
}

export interface DeleteGroupWithAnimalsInput {
  clientMutationId?: string | null;
  id?: any | null;
}

export interface DeleteSireInput {
  clientMutationId?: string | null;
  id: any;
}

export interface DeleteUserFromFarmInput {
  businessUnitUser: number;
  clientMutationId?: string | null;
}

export interface EditDeliveryAddressInput {
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  clientMutationId?: string | null;
  country: number;
  farm: number;
  id: number;
  locationId?: string | null;
  locationType?: string | null;
  postcode?: string | null;
  primary?: boolean | null;
  unitName: string;
}

export interface ExportCsvInput {
  animalTypeIds?: (number | null)[] | null;
  animals?: any[] | null;
  businessUnit: number;
  clientMutationId?: string | null;
  isArchived?: boolean | null;
  orderBy?: (OrderBy | null)[] | null;
}

export interface FarmPlanImportXLSInput {
  clientMutationId?: string | null;
  farm?: number | null;
  xlsFile?: any | null;
}

export interface ImportCsvInput {
  activityTemplate?: number | null;
  animalType?: number | null;
  clientMutationId?: string | null;
  fields: string[];
  group?: any | null;
  id: number;
  isFirstRowColumnNames?: boolean | null;
}

export interface InviteUserInput {
  businessUnit: number;
  clientMutationId?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  role?: number | null;
}

export interface LogActivityBulkInput {
  activityTemplate: number;
  animals?: (any | null)[] | null;
  businessUnit: number;
  clientMutationId?: string | null;
  date?: any | null;
  groups?: (any | null)[] | null;
  id?: any | null;
  payload: any;
}

export interface LotBuyerReceivedAnimalsInput {
  businessUnit: number;
  clientMutationId?: string | null;
  id: number;
}

export interface LotImageInput {
  image: any;
  position: number;
}

export interface LotImageOrderInput {
  id: number;
  position: number;
}

export interface LotSellerSentAnimalsInput {
  businessUnit: number;
  clientMutationId?: string | null;
  id: number;
}

export interface MarkAsPaidLotOfferInput {
  businessUnitId: number;
  clientMutationId?: string | null;
  offer: number;
}

export interface MarkAsPaidWantOfferInput {
  clientMutationId?: string | null;
  offer: number;
}

export interface MoveAnimalsToFieldInput {
  animals: (any | null)[];
  businessUnit: number;
  clientMutationId?: string | null;
  date?: any | null;
  field?: any | null;
}

export interface MoveAnimalsToGroupInput {
  animals: (any | null)[];
  clientMutationId?: string | null;
  date?: any | null;
  group?: any | null;
}
export interface MoveGroupsToFieldInput {
  businessUnit: number;
  clientMutationId?: string | null;
  date?: any | null;
  field: any;
  groups: (any | null)[];
}

export interface OfferMarkAsReadInput {
  businessUnit: number;
  clientMutationId?: string | null;
}

export interface OrderBy {
  desc?: boolean | null;
  id?: string | null;
}

export interface ReInviteUserInput {
  businessUnitUser: number;
  clientMutationId?: string | null;
}

export interface RegulatoryTransactionApprovalsAbortInput {
  businessUnit?: number | null;
  clientMutationId?: string | null;
  regulatoryTransactionApprovals?: (any | null)[] | null;
  transactionType?: string | null;
}

export interface RegulatoryTransactionApprovalsChangeDateInput {
  businessUnit?: number | null;
  clientMutationId?: string | null;
  regulatoryTransactionApprovals?: (any | null)[] | null;
  transactionDate?: any | null;
  transactionType?: string | null;
}

export interface RegulatoryTransactionApprovalsResponseInput {
  businessUnit?: number | null;
  clientMutationId?: string | null;
  regulatoryTransactionApprovals?: (any | null)[] | null;
  response?: string | null;
  transactionType?: string | null;
}

export interface RegulatoryTransactionApprovalsRetryInput {
  businessUnit?: number | null;
  clientMutationId?: string | null;
  regulatoryTransactionApprovals?: (any | null)[] | null;
  transactionType?: string | null;
}

export interface ResponseToAssignedAnimalsInput {
  animals?: any[] | null;
  clientMutationId?: string | null;
  offer: number;
  response: string;
}

export interface ResponseToLotOfferInput {
  amount?: number | null;
  clientMutationId?: string | null;
  offer: number;
  status: string;
}

export interface SendResetPasswordEmailInput {
  clientMutationId?: string | null;
}

export interface SignUpInput {
  addressLine1: string;
  addressLine2?: string | null;
  animalsCount?: string | null;
  breedrUseCase?: string[] | null;
  businessName?: string | null;
  businessSpecialization?: string[] | null;
  city: string;
  clientMutationId?: string | null;
  country: number;
  email: string;
  farmName: string;
  firstName: string;
  holdingId?: string | null;
  howDidYouHearAboutBreedr: number;
  howDidYouHearAboutBreedrOther?: string | null;
  lastName: string;
  password: string;
  phone: string;
  postcode: string;
  referralCode?: string | null;
  species?: string[] | null;
}

export interface StartFarmSystemTaskInput {
  clientMutationId?: string | null;
  farmSystemTask?: number | null;
}

export interface TermsAndConditionsAcceptInput {
  businessUnitId: number;
  clientMutationId?: string | null;
  id: number;
}

export interface TransferOwnershipInput {
  businessUnit: number;
  businessUnitUser: number;
  clientMutationId?: string | null;
}

export interface TruTestImportXlsInput {
  clientMutationId?: string | null;
  farm?: number | null;
  xlsFile?: any | null;
}

export interface UpdateAnimalFilterInput {
  businessUnit: number;
  clientMutationId?: string | null;
  color?: string | null;
  id: number;
  isAlert?: boolean | null;
  name: string;
}

export interface UpdateBankDetailsInput {
  accountName: string;
  accountNo: string;
  businessUnit: number;
  clientMutationId?: string | null;
  id: number;
  isBusinessAccount: boolean;
  sortCode: string;
}

export interface UpdateBusinessUnitSettingsInput {
  businessUnit: number;
  clientMutationId?: string | null;
  enableBcmsPushTransactions?: boolean | null;
  killOut?: number | null;
  tbStatus?: string | null;
}

export interface UpdateBusinessUnitUserInput {
  businessUnitUser: number;
  clientMutationId?: string | null;
  role: number;
  status: string;
}

export interface UpdateContactInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  businessUnit: number;
  city?: string | null;
  clientMutationId?: string | null;
  contactType: string;
  country?: number | null;
  email?: string | null;
  farmId?: string | null;
  id: number;
  name: string;
  organisationName?: string | null;
  phoneNumber?: string | null;
  postcode?: string | null;
}

export interface UpdateDistributionListInput {
  businessUnit: number;
  clientMutationId?: string | null;
  id: number;
  name: string;
  suppliers: number[];
}

export interface UpdateEmailInput {
  clientMutationId?: string | null;
  email: string;
  password: string;
}

export interface UpdateFarmSystemInput {
  clientMutationId?: string | null;
  credentials?: any | null;
  farm: number;
  farmSystem: number;
  integrationSystem: number;
}

export interface UpdateFieldInput {
  clientMutationId?: string | null;
  deleteImages?: (any | null)[] | null;
  description?: string | null;
  groups?: (any | null)[] | null;
  id: any;
  imageFiles?: (any | null)[] | null;
  isOrganic?: boolean | null;
  isYard?: boolean | null;
  location: number;
  name: string;
  pastureTypes?: (number | null)[] | null;
  size?: number | null;
}

export interface UpdateGroupInput {
  animalBreeds?: (any | null)[] | null;
  animalType: number;
  businessUnitTarget?: number | null;
  category?: string | null;
  clientMutationId?: string | null;
  deleteImages?: (any | null)[] | null;
  description?: string | null;
  field?: any | null;
  id: any;
  imageFiles?: (any | null)[] | null;
  name: string;
  targetDate?: any | null;
  targetWeight?: number | null;
}

export interface UpdateLotInput {
  animals: (any | null)[];
  categoryOfSale?: number | null;
  clientMutationId?: string | null;
  deleteImages?: (number | null)[] | null;
  deliveryAddress?: number | null;
  description?: string | null;
  id: number;
  images?: (any | null)[] | null;
  lotImages?: (LotImageInput | null)[] | null;
  lotImagesOrder?: (LotImageOrderInput | null)[] | null;
  name?: string | null;
  preMovementTestDate?: any | null;
  price: number;
  priceUnit: string;
  tbRequirements: string;
  tradingType: number;
}

export interface UpdateOfferForWantInput {
  clientMutationId?: string | null;
  comment?: string | null;
  offer: number;
  offeredNumber: number;
}

export interface UpdatePasswordInput {
  clientMutationId?: string | null;
  confirmPassword: string;
  oldPassword: string;
  password: string;
}

export interface UpdatePreferredBusinessUnitInput {
  businessUnit: number;
  clientMutationId?: string | null;
  fieldsman?: number | null;
  id: number;
  priceAdjustment?: any | null;
}

export interface UpdatePreferredBusinessUnitStatusInput {
  businessUnit: number;
  clientMutationId?: string | null;
  id: number;
  status: string;
}

export interface UpdateSireInput {
  businessUnit?: number | null;
  clientMutationId?: string | null;
  id: any;
  name?: string | null;
  passportNumber?: string | null;
  pedigree?: boolean | null;
  registrationNumber?: string | null;
  sire?: string | null;
}

export interface UpdateUserInput {
  about?: string | null;
  clientMutationId?: string | null;
  firstName?: string | null;
  imageFile?: any | null;
  jobTitle?: string | null;
  lastName?: string | null;
  marketingEmails?: boolean | null;
  metaData?: any | null;
  newsEmails?: boolean | null;
  phone?: string | null;
  productEmails?: boolean | null;
  researchEmails?: boolean | null;
  systemEmails?: boolean | null;
}

export interface UploadCsvInput {
  businessUnit: number;
  clientMutationId?: string | null;
  csvFile?: any | null;
  entityType: string;
  id?: number | null;
}

export interface UploadDiscountGridInput {
  businessUnit: number;
  clientMutationId?: string | null;
  file: any;
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
