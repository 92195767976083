import { useQuery, QueryResult } from "@apollo/client";
// Api
import { GET_REPORTS_LIST } from "api/Reports/queries";
import { GetReportsList, GetReportsListVariables } from "api/Reports/types/GetReportsList";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";
import { useStateSpecies } from "hooks";

export const useGetReportsList = (): QueryResult<GetReportsList, GetReportsListVariables> => {
  const { activeSpecies } = useStateSpecies();

  return useQuery<GetReportsList, GetReportsListVariables>(GET_REPORTS_LIST, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
      species: activeSpecies?.id ? +activeSpecies.id : undefined,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
};
