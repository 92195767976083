// Utils
import { ICON_COLOURS, ICON_NAMES } from "constants/Icons";

type AnimalSexClassDetails = {
  colour: keyof typeof ICON_COLOURS;
  icon: keyof typeof ICON_NAMES;
};

export const getSexClassDetails = (isCastrated?: boolean, isMale?: boolean): AnimalSexClassDetails => {
  // all females have same icon and colour
  if (!isMale) {
    return {
      colour: "red_light",
      icon: "animalSexFemale",
    };
  }

  if (isCastrated) {
    return {
      colour: "green_light",
      icon: "animalSexSteer",
    };
  }

  return {
    colour: "blue_light",
    icon: "animalSexBull",
  };
};
