// React
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useMutation } from "@apollo/client";
// Utils
import { DELETE_DISTRIBUTION_LIST } from "trading/api/mutations";
import { DeleteDistributionList, DeleteDistributionListVariables } from "trading/api/types/DeleteDistributionList";
import { CommonContext } from "config/commonProvider";
import { DISTRIBUTION_LISTS, TRADING } from "constants/Routes";
// Components
import {
  PageHeader,
  Breadcrumbs,
  Header,
  TextButton,
  Button,
  ConfirmModalWithOutInput as ActionsConfirmModal,
} from "components";

interface Props {
  loading: boolean;
  handleSubmit: () => void;
  name?: string;
  isDisableSaveButton: boolean;
}

export const DistributionListHeader: React.FC<Props> = ({ loading, handleSubmit, name, isDisableSaveButton }) => {
  const history = useHistory();
  const { id } = useParams<DistributionListEditPageParams>();
  const { showNotification } = useContext(CommonContext);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showConfirmModalDelete, setShowConfirmModalDelete] = useState<boolean>(false);

  const [deleteDistributionList, { loading: deleteLoading }] = useMutation<
    DeleteDistributionList,
    DeleteDistributionListVariables
  >(DELETE_DISTRIBUTION_LIST);

  const goToDistributionLists = (): void => {
    history.push(`${TRADING}${DISTRIBUTION_LISTS}`);
  };

  const handleCancelClick = (): void => {
    setShowConfirmModal(true);
  };

  const handleConfirmAction = (): void => {
    setShowConfirmModal(false);
    goToDistributionLists();
  };

  const handleDeleteListClick = (): void => {
    setShowConfirmModalDelete(true);
  };

  const handleConfirmDeleteAction = async (): Promise<void> => {
    try {
      if (id) {
        const { data } = await deleteDistributionList({
          variables: {
            input: {
              id: Number(id),
            },
          },
        });
        setShowConfirmModalDelete(false);
        if (data?.deleteDistributionList?.success) {
          showNotification({
            message: "List successfully deleted",
          });
          goToDistributionLists();
        } else {
          showNotification({
            variant: "error",
            message: "Error deleting list",
          });
        }
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error deleting list",
      });
    }
  };

  const headerTitle: string = !id ? "Add a new list" : name || "";
  const headerSubtitle: string = !id ? "Create a new distribution list" : "";
  const buttonLabel: string = !id ? "Create List" : "Save updates";
  const currentPath: string = !id ? "Create List" : "Update List";

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Lists",
              route: `${TRADING}${DISTRIBUTION_LISTS}`,
            },
          ]}
          currentPath={currentPath}
        />

        <Header backButton title={headerTitle} subTitle={headerSubtitle}>
          {!!id ? <TextButton caption={`Delete list`} onClick={handleDeleteListClick} warning /> : null}
          <Button colour="grey" variant="hollow" caption="Cancel" onClick={handleCancelClick} />
          <Button
            disabled={loading || isDisableSaveButton}
            requesting={loading}
            caption={buttonLabel}
            onClick={handleSubmit}
          />
        </Header>
      </PageHeader>
      <ActionsConfirmModal
        subTitle="Are you sure you want to delete this list?"
        title="Delete list"
        showModal={showConfirmModalDelete}
        onClose={(): void => setShowConfirmModalDelete(false)}
        onConfirm={handleConfirmDeleteAction}
        confirmLabel="Yes"
        cancelLabel="No"
        loading={deleteLoading}
      />
      <ActionsConfirmModal
        subTitle={`Are you sure you want to cancel ${id ? `updating list` : "this new list"}?`}
        title={`Cancel ${id ? "updating" : "creating"} list`}
        showModal={showConfirmModal}
        onClose={(): void => setShowConfirmModal(false)}
        onConfirm={handleConfirmAction}
        confirmLabel="Yes"
        cancelLabel="No"
      />
    </>
  );
};
