import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFieldQuery, useUpdateFieldMutation } from "generated/graphql";
import { CommonContext } from "config/commonProvider";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { getAnimalCountFromField, validationSchemaCreateField } from "helpers/myLivestock";
import CreateFieldForm from "pages/MyLivestock/Fields/CreateEditField/CreateFieldForm";
import FieldHeader from "pages/MyLivestock/General/GroupFieldHeader";
import { AnimalsList } from "animals/AnimalsList";
import { Breadcrumbs, FormBuilder, LoadingOverlay, PageContent, PageHeader, Spacer } from "components";
import { FIELDS, FIELD_VIEW } from "constants/Routes";
import { HEADER_TYPES } from "constants/General";
import styles from "./EditField.module.scss";
import { TABLE_IDS } from "constants/Interface";

export const EditField: React.FC = () => {
  const { fieldId } = useParams<LivestockFieldPageParams>();
  const history = useHistory();
  const { showNotification } = useContext(CommonContext);

  const { data, loading } = useFieldQuery({
    variables: {
      id: fieldId,
    },
  });

  const { id, name, size, isYard, isOrganic, description, pastureTypes, location, isHoldingPen } = { ...data?.field };

  const [updateField, { loading: updateFieldLoading }] = useUpdateFieldMutation();

  const handleSaveField = async ({
    name,
    size,
    isYard,
    location,
    isOrganic,
    description,
    pastureTypes,
  }: FieldForm): Promise<void> => {
    const input = {
      name,
      size,
      isYard,
      location: +location,
      isOrganic,
      description,
      pastureTypes: pastureTypes.map((pastureType) => +pastureType),
    };

    try {
      const { data } = await updateField({
        variables: {
          input: {
            ...input,
            id,
          },
        },
      });

      const errors = data?.updateField?.errors;

      if (errors && errors.length > 0) {
        showNotification({
          variant: "error",
          message: errors.map((item) => item?.message).join("; "),
        });
      } else {
        showNotification({
          message: `${capitaliseFirstLetter("location")} successfully updated.`,
        });
        history.push(`${FIELD_VIEW}/${fieldId}`);
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: `Error updating field.`,
      });
    }
  };
  const animalCount = getAnimalCountFromField(data?.field?.summaryMetrics);

  const initialValues: FieldForm = {
    name: name || "",
    description: description || "",
    size: size || 0,
    location: location ? location.id : "",
    isOrganic: isOrganic || false,
    isYard: isYard || false,
    pastureTypes: pastureTypes ? pastureTypes.map((el) => el.id) : ([] as string[]),
    isHoldingPen: isHoldingPen || false,
  };

  return (
    <>
      {updateFieldLoading || loading ? <LoadingOverlay customStyles={styles.loadingOverlay} /> : null}

      <FormBuilder
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchemaCreateField}
        onSubmit={handleSaveField}
      >
        {({ values, errors, handleChange, handleSubmit }): React.ReactNode => (
          <>
            <PageHeader>
              <Breadcrumbs
                rootPaths={[
                  {
                    path: "Locations",
                    route: FIELDS,
                  },
                ]}
                currentPath={`Update ${capitaliseFirstLetter("location")}`}
              />

              <FieldHeader type={HEADER_TYPES.FIELD} item={data?.field} handleSubmit={handleSubmit} />
            </PageHeader>

            <PageContent>
              <Spacer baselineHeight={3} />

              <CreateFieldForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                canEditAddress={animalCount === 0}
              />

              <>
                <Spacer baselineHeight={2} />
                <Spacer baselineHeight={3} border="top" gutter />
                <AnimalsList
                  tableFilterIdentifier="fieldsList"
                  excludedFilters={{ fieldId: true }}
                  showAnimalActionButtons={false}
                  showSelectColumn={false}
                  fieldId={fieldId}
                  fromPage="field"
                  tableId={TABLE_IDS["FIELD_ANIMALS"]}
                />
              </>
            </PageContent>
          </>
        )}
      </FormBuilder>
    </>
  );
};
