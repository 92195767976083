import { VFC } from "react";
// Components
import { Flex, Spacer, Text } from "components";
// Resources
import styles from "./ImportWizardLoading.module.scss";
import spinnerDark from "assets/img/Auth/trimmed-logo-dark.png";

interface ImportWizardLoadingProps {
  text: string;
}

export const ImportWizardLoading: VFC<ImportWizardLoadingProps> = ({ text }) => (
  <Flex container containerAlignItems="center" containerDirection="column">
    <img alt="Spinning loading icon" className={styles.import_wizard_loading} src={spinnerDark} />

    <Spacer baselineHeight={1} />

    <Text>
      <strong>{text}</strong>
    </Text>
  </Flex>
);
