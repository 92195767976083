import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { TABLE_IDS } from "constants/Interface";
import { useGetCurrentBusinessUnit } from "hooks";
import { useLocale } from "helpers/translations/src/useLocale";
import { Flex } from "components/Structure/Flex";
import { Table } from "components/Common/Table";
import { Column } from "react-table";
import { GroupType } from "hooks/useGetGroups";
import { IconLabel } from "components/Common/IconLabel";
import { GROUP_CATEGORY_OPTIONS } from "constants/Groups";
import { ColumnOptionsModal } from "components/Common/Table/ColumnOptionsModal";
import { getTableSettingsFromLocalStorage } from "helpers/storage";
import { Button, Spacer, TablePaginationTarget } from "components";
import styles from "./styles.module.scss";
import { BaseInput } from "components/Common/Field/BaseInput";
import { LIVESTOCK } from "constants/Routes";

const COLUMN_IDS = {
  NAME: "name",
  ANIMALS_NUMBER: "animalsNumberCache",
  MALES_NUMBER: "malesNumber",
  FEMALES_NUMBER: "femalesNumber",
  TYPE: "animalType",
  TARGET_WEIGHT: "targetWeight",
  AVG_DLGW: "avgDlwg",
  AVG_ESTIMATED_WEIGHT: "avgEstimatedWeight",
  AVG_RECORDED_WEIGHT: "avgRecordedWeight",
  ALERTS: "alerts",
};

interface Props {
  groups: Array<GroupType>;
  loading?: boolean;
}

const filterGroupsData = (data: GroupType[], searchTerm: string): GroupType[] => {
  const isMatch = (value: string): boolean => value?.toLowerCase().includes(searchTerm.toLowerCase());

  return data.filter((group) => isMatch(group.name));
};

export const GroupsTable: React.FC<Props> = ({ groups, loading }) => {
  const { weightUnits, countryCode } = useGetCurrentBusinessUnit();
  const history = useHistory();
  const { terms } = useLocale();
  const tableId = TABLE_IDS.GROUPS;
  const [columnOptionsModalOpen, setColumnOptionsModalOpen] = useState(false);
  const localStorageTableSettings = getTableSettingsFromLocalStorage(tableId);
  const currentLocalStorageColumns = localStorageTableSettings.columns || [];

  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const filteredGroups: GroupType[] = useMemo(() => filterGroupsData(groups, searchTerm), [groups, searchTerm]);
  const tableData: GroupType[] = useMemo(() => filteredGroups, [filteredGroups]);

  const handleClickCell = useCallback(
    (group: GroupType) => {
      const url = `${LIVESTOCK}/${group.isBatch ? "batch" : "group"}/${group?.id}`;
      history.push(url);
    },
    [history],
  );

  const columns: Column<GroupType>[] = useMemo(
    () => [
      {
        id: COLUMN_IDS.NAME,
        Header: "Group Name",
        accessor: "name",
        onCellClick: handleClickCell,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.ANIMALS_NUMBER,
        Header: "No. of Livestock",
        accessor: ({ identifiedAnimalsCount, numberOfUnidentifiedAnimalsInBatch }) =>
          identifiedAnimalsCount && numberOfUnidentifiedAnimalsInBatch
            ? identifiedAnimalsCount + numberOfUnidentifiedAnimalsInBatch
            : identifiedAnimalsCount || numberOfUnidentifiedAnimalsInBatch || 0,
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.MALES_NUMBER,
        Header: "Male",
        accessor: ({ identifiedMaleAnimalsCount }): number | null => {
          return identifiedMaleAnimalsCount || 0;
        },
        Cell: ({ row }): React.ReactElement => {
          return (
            <IconLabel
              id={`animal_sex_label__${row.index}`}
              colour={"blue_light"}
              icon={"animalSexBull"}
              label={row.values[COLUMN_IDS.MALES_NUMBER]}
            />
          );
        },
        onCellClick: handleClickCell,
        minWidth: 80,
        width: 90,
      },
      {
        id: COLUMN_IDS.FEMALES_NUMBER,
        Header: "Female",
        accessor: ({ identifiedFemaleAnimalsCount }): number | null => {
          return identifiedFemaleAnimalsCount || 0;
        },
        Cell: ({ row }): React.ReactElement => {
          return (
            <IconLabel
              id={`animal_sex_label__${row.index}`}
              colour={"red_light"}
              icon={"animalSexFemale"}
              label={row.values[COLUMN_IDS.FEMALES_NUMBER]}
            />
          );
        },
        onCellClick: handleClickCell,
        minWidth: 80,
        width: 90,
      },
      {
        id: COLUMN_IDS.TYPE,
        Header: "Animal Type",
        accessor: ({ category }): string | undefined =>
          GROUP_CATEGORY_OPTIONS.find((item) => item?.value === category)?.label || "\u2014",
        onCellClick: handleClickCell,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.TARGET_WEIGHT,
        Header: "Target Weight",
        accessor: ({ targetWeight }): string | null =>
          (targetWeight ? Number(targetWeight).toFixed(1) : 0) + weightUnits || "\u2014",
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.AVG_DLGW,
        Header: terms.avgDlwg,
        accessor: ({ avgDlwg }): string | null => (avgDlwg ? Number(avgDlwg).toFixed(1) : 0) + weightUnits || "\u2014",
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.AVG_ESTIMATED_WEIGHT,
        Header: "Avg. Estimated Weight",
        accessor: ({ avgEstimatedWeight }): string | null =>
          (avgEstimatedWeight ? Number(avgEstimatedWeight)?.toFixed(1) : 0) + weightUnits || "\u2014",
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 145,
      },
      {
        id: COLUMN_IDS.AVG_RECORDED_WEIGHT,
        Header: "Avg. Recorded Weight",
        accessor: ({ avgRecordedWeight }): string | null =>
          (avgRecordedWeight ? Number(avgRecordedWeight).toFixed(1) : 0) + weightUnits || "\u2014",
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 140,
      },
      {
        id: COLUMN_IDS.ALERTS,
        Header: "Alerts",
        accessor: ({ animalAlertsCount }): number => animalAlertsCount || 0,
        Cell: ({ row }): React.ReactElement => {
          const alertsCount = row.values[COLUMN_IDS.ALERTS];
          return (
            <IconLabel
              id={`group_alert_icon__${row.index}`}
              colour={alertsCount > 0 ? "warning" : "grey_light"}
              icon={alertsCount > 0 ? "alertsHigh" : "alertsNone"}
              label={alertsCount}
            />
          );
        },
        minWidth: 100,
        width: 100,
      },
    ],
    [handleClickCell, terms, weightUnits],
  );
  const reorderColumns = (
    columns: Column<GroupType>[],
    columnOrder: { id: string; index: number; checked: boolean }[],
  ): Column<GroupType>[] => {
    const sortedColumnOrder = columnOrder.sort((a, b) => a.index - b.index);
    const columnMap: { [id: string]: Column<GroupType> } = {};
    columns.map((column) => {
      columnMap[column.id || 0] = column;
    });
    const reorderedColumns: Column<GroupType>[] = [];
    sortedColumnOrder.map((column) => {
      if (column.checked) {
        const columnObject = columnMap[column.id];
        if (columnObject) {
          reorderedColumns.push(columnObject);
        }
      }
    });
    return reorderedColumns;
  };

  const [customColumns, setCustomColumns] = useState<Column<GroupType>[]>(
    currentLocalStorageColumns ? currentLocalStorageColumns : columns,
  );
  const [customColumnTrigger, setCustomColumnTrigger] = useState<number>(0);

  useEffect(() => {
    setCustomColumns(
      currentLocalStorageColumns.length > 0 ? reorderColumns(columns, currentLocalStorageColumns) : columns,
    );
  }, [customColumnTrigger]);

  const hiddenColumnsByCountry = {
    us: [COLUMN_IDS.AVG_ESTIMATED_WEIGHT],
  };

  const hiddenColumns = countryCode && hiddenColumnsByCountry[countryCode] ? hiddenColumnsByCountry[countryCode] : [];

  const defaultColumnIds = Object.values(COLUMN_IDS).filter((id) => !hiddenColumns.includes(id));

  return (
    <>
      <Flex container>
        <Flex item itemGutter xs={12} m={6} l={3} className={styles.search_group_input}>
          <BaseInput
            name="groups_search"
            onChange={handleSearchInputChange}
            placeholder="Search by group name"
            type="search"
            value={searchTerm}
          />
        </Flex>
        <div className="flex justify-end flex-1 flex-wrap mb-2">
          <Flex item itemGutter>
            <TablePaginationTarget tableId={tableId} />
          </Flex>
          <Flex item itemGutter>
            <Button
              caption="Columns"
              onClick={() => setColumnOptionsModalOpen(true)}
              colour="grey"
              variant="hollow"
              icon={{
                position: "right",
                props: {
                  name: "column",
                  size: "small",
                },
              }}
            />
          </Flex>
        </div>
      </Flex>
      <Spacer baselineHeight={1} />
      <Flex container containerDirection="column">
        <Flex item itemGutter>
          <Table
            loading={loading}
            columns={customColumns}
            hiddenColumns={hiddenColumns}
            data={tableData}
            tableId={tableId}
            pagination
            initialPageSize={10}
          />
        </Flex>
      </Flex>
      <ColumnOptionsModal
        columns={columns}
        hiddenColumns={hiddenColumns}
        isShowModal={columnOptionsModalOpen}
        onClose={() => setColumnOptionsModalOpen(false)}
        setCustomColumnnTrigger={setCustomColumnTrigger}
        defaultColumnIds={defaultColumnIds}
        tableId={tableId}
      />
    </>
  );
};
