import { FC } from "react";
import { Header, FieldSelect, Flex, PageContent, PageHeader } from "components";
import { AWSQuickSightDashboard } from "components/Reports/AWSQuickSightDashboard";
import styles from "./styles.module.scss";
import { BreedrReports } from "reports/pages/FarmerReports";
import { ReportEngine } from "generated/graphql";

type ReportListItem = {
  id: string;
  title: string;
  slug?: string | null | undefined;
  applyProducerGroup: boolean;
  url: string;
  position: number;
  needUpgrade?: boolean | null | undefined;
  reportType: ReportEngine | null | undefined;
  businessUnit?: { id: string } | null | undefined;
  group?: { id: string; title: string; position: number } | null | undefined;
  subscriptionPlans: Array<{
    id: string;
    name: string;
    price: number;
    description: string;
  }>;
  species: Array<{ id: string }>;
};

interface ReportContentProps {
  currentReport: ReportListItem;
}

export const ReportContent: FC<ReportContentProps> = ({ currentReport }) => {
  const renderCorrectReportType = () => {
    if (currentReport.reportType == "QUICKSIGHT") {
      return <AWSQuickSightDashboard quickSightUrl={currentReport.url} />;
    } else if (currentReport.reportType == "SISENSE") {
      return <iframe src={currentReport.url} width="100%" height="850px" title="Report" frameBorder="0" />;
    } else {
      return <BreedrReports reportType={currentReport.url}></BreedrReports>;
    }
  };
  return (
    <>
      <PageHeader>
        <Header title={currentReport.title} />
      </PageHeader>

      <PageContent>
        <Flex container>
          <Flex item itemGutter xs={12}>
            {currentReport.applyProducerGroup && false ? (
              <div className={styles.selectInsideWrapper}>
                <FieldSelect
                  label="Group"
                  inputProps={{
                    name: "group",
                    options: [],
                    onChange: (): void => {
                      // do nothing
                    },
                  }}
                />
              </div>
            ) : null}
            {currentReport.needUpgrade ? (
              <div className={styles.noPermissionMessage}>You do not have permission to view this report</div>
            ) : (
              renderCorrectReportType()
            )}
          </Flex>
        </Flex>
      </PageContent>
    </>
  );
};
