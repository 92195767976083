import { DateTime } from "luxon";
import * as yup from "yup";

export const getMoveOnValidationSchema = () => {
  return yup.object({
    name: yup.string().required("Name is required.").max(30, "Movement name cannot be longer than 50 characters"),
    arrivedAt: yup
      .date()
      .required()
      .max(DateTime.local().endOf("day"), "This cannot be submitted as the date is in the future."),
    animalCount: yup.number().min(1, "Animals are required."),
    reasonId: yup.string().required("Reason is a required field."),
    sourceContactId: yup.string(),
  });
};
