import { FormEvent, VFC } from "react";
// Libraries
import pure from "recompose/pure";
import cn from "classnames";
// Resources
import buttonStyles from "components/Common/Button/Button.module.scss";
import styles from "./styles.module.css";

interface SelectProps {
  acceptedFormats?: string;
  className?: string;
  hasFile?: boolean;
  id: string;
  isMultiple?: boolean;
  label: string;
  name?: string;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
}

const FileInput: VFC<SelectProps> = ({
  acceptedFormats,
  className,
  hasFile,
  id,
  isMultiple,
  label,
  name,
  onChange,
}) => {
  return (
    <div className={styles.fileInputWrapper}>
      {/* eslint-disable-next-line */}
      <label
        htmlFor={id}
        className={cn(
          buttonStyles.button,
          buttonStyles["button--width__full"],
          buttonStyles["button--height__small"],
          buttonStyles[`button--colour__${hasFile ? "grey" : "yellow"}`],
          buttonStyles[`button--variant__${hasFile ? "hollow" : "solid"}`],
          className,
        )}
      >
        <span className={buttonStyles.button__button_caption}>{label}</span>
      </label>
      <input
        id={id}
        type="file"
        multiple={isMultiple}
        onChange={onChange}
        className={styles.fileInput}
        accept={acceptedFormats}
        name={name}
        value=""
      />
    </div>
  );
};

export default pure(FileInput);
