import { gql } from "@apollo/client";

export const UPDATE_REGULATORY_TRANSACTION = gql`
  mutation RegulatoryTransactionApprovalsResponse($input: RegulatoryTransactionApprovalsResponseInput!) {
    regulatoryTransactionApprovalsResponse(input: $input) {
      regulatoryTransactionApprovals {
        id
        actualStatus
      }
      errors {
        field
        code
      }
    }
  }
`;

export const RETRY_REGULATORY_TRANSACTIONS = gql`
  mutation RegulatoryTransactionApprovalsRetry($input: RegulatoryTransactionApprovalsRetryInput!) {
    regulatoryTransactionApprovalsRetry(input: $input) {
      regulatoryTransactionApprovals {
        id
        actualStatus
      }
      errors {
        field
        code
      }
    }
  }
`;

export const UPDATE_REGULATORY_TRANSACTIONS_DATE = gql`
  mutation RegulatoryTransactionApprovalsChangeDate($input: RegulatoryTransactionApprovalsChangeDateInput!) {
    regulatoryTransactionApprovalsChangeDate(input: $input) {
      regulatoryTransactionApprovals {
        id
        actualStatus
        sourceActivity {
          date
        }
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

export const ABORT_REGULATORY_TRANSACTIONS = gql`
  mutation RegulatoryTransactionApprovalsAbort($input: RegulatoryTransactionApprovalsAbortInput!) {
    regulatoryTransactionApprovalsAbort(input: $input) {
      regulatoryTransactionApprovals {
        id
        actualStatus
        reasonOfFailure
      }
      errors {
        field
        code
      }
    }
  }
`;
