import { useMemo, useState, FC } from "react";
import { getAnimalInfoItems } from "./helpers";
import { getFieldName } from "helpers/myLivestock";
import { KeyValuePair, Pill } from "components";
import { Tag } from "tags/components/Tag";
import { useGetCurrentBusinessUnit } from "hooks";
import { GQLAnimal } from "pages/MyLivestock/Animals/AnimalView/AnimalView";
import { ActiveIdentifierPreferencesType } from "generated/graphql";
import { Terms } from "helpers/translations/src/useLocale/types";
import { useLocale } from "helpers/translations/src";
import { MoveToFieldModal } from "fields/MoveToFieldModal";
import { Button as TwButton } from "twComponents/Button";
import { AssignToGroupModal } from "groups/components/AssignToGroupModal";
import { useDates } from "feShared/utils/hooks/useDates";

interface Props {
  animal: GQLAnimal;
  isArchived: boolean;
  canAddToGroup: boolean;
  activeIdPreferences: ActiveIdentifierPreferencesType | null | undefined;
  terms: Terms;
}

export const AnimalInfoBlock: FC<Props> = ({ animal, isArchived = false, canAddToGroup, activeIdPreferences }) => {
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showFieldModal, setShowFieldModal] = useState(false);
  const { countryCode } = useGetCurrentBusinessUnit();
  const { terms } = useLocale();
  const { formatDateToShortDate } = useDates();
  const animalInfo = useMemo(
    () => getAnimalInfoItems(animal, isArchived, formatDateToShortDate, terms, activeIdPreferences, countryCode),
    [activeIdPreferences, animal, countryCode, formatDateToShortDate, isArchived, terms],
  );

  return (
    <div className="px-2">
      <div className="flex flex-wrap">
        <ul className="w-full md:columns-2 gap-x-6 xl:w-[50%]">
          {animalInfo[0].map((item) => {
            if (!item.isHidden) {
              return (
                <li key={item.label}>
                  <KeyValuePair label={item.label} unit={item.unit} value={item.value} canTruncate={true} />
                </li>
              );
            }
            return null;
          })}
        </ul>
        <ul className="w-full md:columns-2 gap-x-6 xl:w-[50%]">
          {animalInfo[1].map((item) => {
            if (!item.isHidden && item.value != null) {
              return (
                <li key={item.label}>
                  <KeyValuePair label={item.label} unit={item.unit} value={item.value} />
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full md:w-1/2 xl:w-1/4">
          <label className="text-xs uppercase font-bold">Group: </label>
          <div>
            <Pill
              caption={animal?.group ? animal?.group.name : "No group"}
              colour={animal?.group ? "yellow" : "grey"}
              wrap
            />
            {!isArchived ? (
              <TwButton
                className="ml-2"
                variant="ghost"
                onClick={(): void | false => canAddToGroup && setShowGroupModal(true)}
              >
                {animal?.group ? "Change group" : "Add to group"}
              </TwButton>
            ) : null}
          </div>
        </div>
        <div className="w-full md:w-1/2 md:px-3 xl:w-1/4">
          <label className="text-xs uppercase font-bold">Location: </label>
          <div>
            <Pill
              caption={
                animal?.field
                  ? getFieldName({ fieldName: animal.field.name, unitName: animal.field.location?.unitName })
                  : "No location"
              }
              colour={animal?.field ? "yellow" : "grey"}
              wrap
            />
            {!isArchived ? (
              <TwButton className="ml-2" variant="ghost" onClick={() => setShowFieldModal(true)}>
                Move
              </TwButton>
            ) : null}
          </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/4">
          <label className="text-xs uppercase font-bold">Tags: </label>
          <div className="flex">
            {(animal?.tags || []).map((tag) => {
              return (
                <Tag
                  tag={{ tagId: tag.tagId, name: tag.name, color: tag.color, ownership: tag.schema.ownership }}
                  key={tag.tagId}
                />
              );
            })}
          </div>
        </div>
      </div>

      {!isArchived ? (
        <AssignToGroupModal animalIds={[animal?.id]} showModal={showGroupModal} setShowModal={setShowGroupModal} />
      ) : null}

      {!isArchived ? (
        <MoveToFieldModal
          entityToMove={{ type: "animal", ids: [animal?.id] }}
          showModal={showFieldModal}
          setShowModal={setShowFieldModal}
        />
      ) : null}
    </div>
  );
};
