import { VFC } from "react";
// Components
import { Stages } from "components";

interface ImportWizardStagesProps {
  activeStepNumber: number;
}

export const ImportWizardStages: VFC<ImportWizardStagesProps> = ({ activeStepNumber }) => {
  const getStageStatus = (stepNumber) => {
    if (activeStepNumber < stepNumber) return;

    if (activeStepNumber === stepNumber) return "action";

    return "complete";
  };

  return (
    <Stages>
      <Stages.Stage icon="numberedList" status={getStageStatus(1)} title="Choose type" />

      <Stages.Stage icon="checklist" status={getStageStatus(2)} title="Choose activity" />

      <Stages.Stage icon="upload" status={getStageStatus(3)} title="Upload file" />

      <Stages.Stage icon="check" status={getStageStatus(4)} title="Match and import" />
    </Stages>
  );
};
