// React
import React, { useEffect, useState } from "react";
// Libraries
import { useLazyQuery } from "@apollo/client";
// Utils
import { GET_DELIVERY_ADDRESSES } from "trading/api/queries";
import { deliveryAddresses, deliveryAddressesVariables } from "trading/api/types/deliveryAddresses";
import { BUSINESS_LOCATIONS_TABLE_COLUMNS } from "constants/Settings";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { getBUFromLocalStorage } from "helpers/storage";
// Components
import ModifyDeliveryAddressForm from "pages/Settings/BusinessSettings/ModifyDeliveryAddressForm";
import { CustomTable } from "components/Settings";
import { Button, Flex, Header, LoadingOverlay, PageContent, PageHeader, Spacer } from "components";
// Resources
import styles from "./styles.module.scss";

const BusinessSettings: React.FC = () => {
  const [showForm, toggleForm] = useState<boolean>(false);
  const [editMode, toggleEditMode] = useState<boolean>(false);
  const [editData, changeEditData] = useState<CreateAddressData | null>(null);

  const [getDeliveryAddresses, { loading, data }] = useLazyQuery<deliveryAddresses, deliveryAddressesVariables>(
    GET_DELIVERY_ADDRESSES,
    {
      variables: {
        businessUnit: getBUFromLocalStorage(),
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    },
  );

  const handleToggleForm = (): void => {
    toggleForm(!showForm);
  };

  const handleCloseForm = (): void => {
    getDeliveryAddresses();
    toggleForm(!showForm);

    if (editMode) {
      toggleEditMode(!editMode);
      changeEditData(null);
    }
  };

  const handleSetEditData = (data: CreateAddressData): void => {
    changeEditData(data);
    toggleEditMode(!editMode);
    handleToggleForm();
  };

  useEffect(() => {
    getDeliveryAddresses();
  }, [getDeliveryAddresses]);
  return (
    <>
      {loading ? <LoadingOverlay customStyles={styles.loadingOverlay} /> : null}

      <PageHeader>
        <Header title="Business settings" subTitle="Manage your business locations and information">
          {!(editMode || showForm) ? (
            <Button id={ELEMENTS_IDS.ADD_NEW_LOCATION_BUTTON} caption="Add new location" onClick={handleToggleForm} />
          ) : null}
        </Header>
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />

        {!showForm && data?.deliveryAddresses ? (
          <Flex container>
            <Flex item itemGutter xs={12}>
              <CustomTable
                dataList={data?.deliveryAddresses || []}
                columns={BUSINESS_LOCATIONS_TABLE_COLUMNS}
                onEditView={handleSetEditData}
              />
            </Flex>
          </Flex>
        ) : null}

        {showForm ? (
          <ModifyDeliveryAddressForm editData={editData} editMode={editMode} onCloseForm={handleCloseForm} />
        ) : null}
      </PageContent>
    </>
  );
};

export default BusinessSettings;
