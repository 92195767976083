import { ChangeEvent, useState, FC, useEffect } from "react";
import config from "api/config";
import { ANIMALS_IMPORT } from "constants/Import";
import { CSV_FILE_FORMAT } from "constants/Settings";
import { getBUFromLocalStorage } from "helpers/storage";
import { useEffectSpeciesActive, useGetCurrentBusinessUnit } from "hooks";
import { ImportWizardLoading } from "components/Import";
import { Button, FieldSelect, FileInput, Flex, Paper, Pill, Spacer, Text } from "components";
import { UploadCsvDateHintEnumType, UploadCsvMutation, useUploadCsvMutation } from "generated/graphql";
import { capitaliseFirstLetter } from "helpers/translations/src/";
import { CsvImportType } from "import/types";

interface ImportWizardChooseFileProps {
  importType: CsvImportType;
  onClickBack: () => void;
  onClickNext: () => void;
  onResetFile: () => void;
  onSetFile: (uploadedFile: NonNullable<UploadCsvMutation["uploadCsv"]>, uploadedFileName: string) => void;
}

export const ImportWizardChooseFile: FC<ImportWizardChooseFileProps> = ({
  importType,
  onClickBack,
  onClickNext,
  onResetFile,
  onSetFile,
}) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const isNextButtonDisabled = !fileName || !!error;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadCsvMutation] = useUploadCsvMutation();

  const isDeliveryImportType = importType === "movement";

  // Timezone - Add back in when BE is fixed and supports
  // const [timezoneOptions, setTimezoneOptions] = useState([]);
  // const [selectedTimezone, setSelectedTimeZone] = useState<string | null>(null);

  // useEffect(() => {
  //   const aryIannaTimeZones = (Intl as any).supportedValuesOf("timeZone");
  //   setSelectedTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  //   const formattedOptions =
  //     aryIannaTimeZones.map((aryIannaTimeZone) => ({
  //       key: aryIannaTimeZone,
  //       value: aryIannaTimeZone,
  //       label: aryIannaTimeZone,
  //     })) ?? [];
  //   setTimezoneOptions(formattedOptions);
  // }, []);

  //Date format
  const { countryIsoCode } = useGetCurrentBusinessUnit();
  const [selectedDateFormat, setSelectedDateFormat] = useState<UploadCsvDateHintEnumType>(
    UploadCsvDateHintEnumType.International,
  );
  const dateFormatOptions = [
    { key: 1, value: UploadCsvDateHintEnumType.International, label: "dd/mm/yyyy" },
    { key: 2, value: UploadCsvDateHintEnumType.Usa, label: "mm/dd/yyyy" },
  ];
  useEffect(() => {
    if (countryIsoCode === "us") {
      setSelectedDateFormat(UploadCsvDateHintEnumType.Usa);
    }
  }, [countryIsoCode]);

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) throw new Error("Expected file.");

    setIsLoading(true);

    try {
      const { data } = await uploadCsvMutation({
        variables: {
          input: {
            businessUnit: getBUFromLocalStorage(),
            csvFile: file,
            dateHint: selectedDateFormat,
          },
        },
      });

      const errors = data?.uploadCsv?.errors;
      if (errors) {
        onResetFile();
        setError(errors[0]?.message ? capitaliseFirstLetter(errors[0].message) : "Something went wrong");
      } else if (data?.uploadCsv) {
        onSetFile(data?.uploadCsv, file.name);
        if (error) setError(null);
      }
    } catch (error: any) {
      if (error.graphQLErrors) {
        onResetFile();
        setError(error.graphQLErrors[0].message);
      }
    } finally {
      setFileName(file.name);

      setIsLoading(false);
    }
  };

  const paperColour = isLoading ? undefined : error ? "red" : fileName ? "blue" : undefined;
  const buttonLabel = error ? "Try again" : fileName ? "Change file" : "Upload file";
  const fileNamePillColour = error ? "red" : "navy";

  const handleClickDownloadTemplate = () => {
    if (importType === ANIMALS_IMPORT) {
      return window.open(
        `${config.secureProtocol}${config.staticHost}/animals/csv_templates/Example%20Animal%20Import.csv`,
        "Download",
      );
    }
  };

  const handleEffectSpeciesActive = () => {
    if (importType !== ANIMALS_IMPORT) return;

    onResetFile();
    setFileName(null);
  };
  useEffectSpeciesActive(handleEffectSpeciesActive);

  return (
    <>
      <Paper colour={paperColour} dark>
        <Flex container containerAlignItems="center" containerDirection="column">
          <Spacer baselineHeight={2} />
          <Paper dark>
            <Flex container containerAlignItems="center" containerJustifyContent="space-between">
              {isDeliveryImportType ? (
                <div style={{ textAlign: "center" }}>
                  <p>This import will create a draft movement for list of animal IDs.</p>
                  <Spacer baselineHeight={1} />
                  <p>You can optionally provide a movement name.</p>
                  <Spacer baselineHeight={3} />
                </div>
              ) : (
                <>
                  <Flex item itemGutter>
                    Date format used in the csv file:
                  </Flex>
                  <Flex item itemGutter>
                    <FieldSelect
                      inputProps={{
                        name: "dateFormat",
                        value: selectedDateFormat,
                        options: dateFormatOptions,
                        onChange: (selectedOption: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedDateFormat(selectedOption.target.value as UploadCsvDateHintEnumType),
                      }}
                    />
                  </Flex>
                </>
              )}
              {/* <Flex item itemGutter>
                    <FieldSelect
                      label="Timezone"
                      inputProps={{
                        name: "timezone",
                        value: selectedTimezone,
                        placeholder: "No preference",
                        options: timezoneOptions,
                        onChange: (selectedOption) => setSelectedTimeZone(selectedOption.target.value),
                        isClearable: true,
                      }}
                    />
                  </Flex> */}
            </Flex>
          </Paper>
          <Spacer baselineHeight={1} />

          {isLoading ? (
            <ImportWizardLoading text="Uploading file" />
          ) : (
            <>
              <FileInput
                acceptedFormats={CSV_FILE_FORMAT}
                id="customImport"
                hasFile={!!fileName}
                label={buttonLabel}
                onChange={handleChangeFile}
                request
                uppercase
              />

              <Spacer baselineHeight={1} />

              {fileName ? (
                <Pill caption={`File: ${fileName}`} colour={fileNamePillColour} colourInverted />
              ) : (
                <Text smallest>
                  <strong>File should be type .csv</strong>
                </Text>
              )}

              {error ? (
                <>
                  <Spacer baselineHeight={1} />

                  <Text colour="red900" smallest>
                    <strong>{error}</strong>
                  </Text>
                </>
              ) : null}
            </>
          )}

          <Spacer baselineHeight={5} />
        </Flex>
      </Paper>

      {importType === ANIMALS_IMPORT ? (
        <>
          <Spacer baselineHeight={4} />

          <Flex container containerJustifyContent="center">
            <Button
              caption="Download Animals template"
              colour="grey"
              onClick={handleClickDownloadTemplate}
              variant="hollow"
            />
          </Flex>
        </>
      ) : null}

      <Spacer baselineHeight={4} />

      <Flex container containerJustifyContent="space-between">
        <Button caption="Back" colour="grey" onClick={onClickBack} variant="hollow" />

        <Button caption="Next" disabled={isNextButtonDisabled} onClick={onClickNext} />
      </Flex>
    </>
  );
};
