import { VFC } from "react";
// Utils
import { BUSINESS_UNIT_TB_STATUSES } from "constants/Settings";
import { useGetCurrentBusinessUnit } from "hooks";
// Types
import { FormValues, LotTbRequirements } from "../types";
// Components
import { Spacer, Flex, Text, CheckBox, FieldDate } from "components";
// Resources
import styles from "./TBStatus.module.scss";

type MarketplaceValues = Pick<FormValues, "preMovementTestDate" | "tbRequirements">;

interface TBStatusProps extends MarketplaceValues {
  isError: boolean;
  onPreMovementTestDateChange: (date: Date) => void;
  onTBRequirementChange: (status: LotTbRequirements) => void;
}

export const TBStatus: VFC<TBStatusProps> = ({
  isError,
  onPreMovementTestDateChange,
  onTBRequirementChange,
  preMovementTestDate,
  tbRequirements,
}) => {
  const { tbStatus } = useGetCurrentBusinessUnit();
  const tbStatusLabel = tbStatus ? BUSINESS_UNIT_TB_STATUSES[tbStatus] : null;

  const handleCheckboxChange = (status: LotTbRequirements) => (): void => {
    onTBRequirementChange(status);
  };

  const preMovementTestDatePicker = (
    <FieldDate
      error={isError}
      inputProps={{
        name: "preMovementTestDate",
        value: preMovementTestDate || "",
        onChange: onPreMovementTestDateChange,
        minDate: new Date(),
      }}
    />
  );

  const isStatusAlreadyHad: boolean = tbRequirements === LotTbRequirements.HadPreMovement;
  const isStatusRequire: boolean = tbRequirements === LotTbRequirements.RequirePreMovement;

  return (
    <Flex container>
      <Flex container item itemGrow>
        <Flex item itemGutter xs={12}>
          <Text>
            <strong>Farm TB Risk Status:</strong>{" "}
            {tbStatusLabel ? (
              <Text>{tbStatusLabel}</Text>
            ) : (
              <Text warning>Please set your farm TB status in Farm Settings</Text>
            )}
          </Text>
        </Flex>
        <Spacer baselineHeight={2} />
        <Flex container item itemGutter containerDirection="column">
          <Text>
            <strong>TB Requirements for this listing:</strong>
          </Text>
          <CheckBox
            className={styles.checkbox}
            label="These animals do not require pre-movement testing"
            checked={tbRequirements === LotTbRequirements.DoNotRequirePreMovement}
            onChange={handleCheckboxChange(LotTbRequirements.DoNotRequirePreMovement)}
          />
          <Flex container containerAlignItems="flex-start" containerWrap="wrap" containerDirection="column">
            <Flex item>
              <CheckBox
                className={styles.checkbox}
                label="These animals had a pre-movement test and must be moved by:"
                checked={isStatusAlreadyHad}
                onChange={handleCheckboxChange(LotTbRequirements.HadPreMovement)}
              />
            </Flex>
            <Flex item>{isStatusAlreadyHad ? preMovementTestDatePicker : null}</Flex>
          </Flex>
          <Flex container containerAlignItems="flex-start" containerWrap="wrap" containerDirection="column">
            <Flex item>
              <CheckBox
                className={styles.checkbox}
                label="These animals require a pre-movement test and must be moved by:"
                checked={isStatusRequire}
                onChange={handleCheckboxChange(LotTbRequirements.RequirePreMovement)}
              />
            </Flex>
            <Flex item>{isStatusRequire ? preMovementTestDatePicker : null}</Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
