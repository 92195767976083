interface Option {
  key?: string;
  label: string;
  value: string;
}

const unkeyedDeathOptions: Array<Option> = [
  { label: "Abortion", value: "abortion" },
  { label: "Acidosis", value: "acidosis" },
  { label: "Bacterial (Clostridia, E.Coli, Pasteurellosis, Salmonella, Mycoplasma)", value: "bacterial" },
  { label: "Bladder", value: "bladder" },
  { label: "Bloat", value: "bloat" },
  { label: "Brain / nervous condition", value: "brain-nervous-condition" },
  { label: "BVD", value: "BVD" },
  { label: "Castration", value: "castration" },
  { label: "Cardiovascular condition", value: "cardiovascular-condition" },
  { label: "Coccidiosis", value: "coccidiosis" },
  { label: "Displaced Abomasum", value: "displaced-abomasum" },
  { label: "Dystocia", value: "dystocia" },
  { label: "Fluke", value: "fluke" },
  { label: "Fly strike", value: "fly-strike" },
  { label: "Grass staggers / hypomagnesaemia", value: "hypomagnesaemia" },
  { label: "IBR", value: "IBR" },
  { label: "Johnes", value: "johnes" },
  { label: "Ketosis", value: "ketosis" },
  { label: "Lameness", value: "lameness" },
  { label: "Leptospirosis", value: "leptospirosis" },
  { label: "Limb fracture", value: "limb-fracture" },
  { label: "Listeria", value: "listeria" },
  { label: "Lungworm", value: "lungworm" },
  { label: "Mastitis", value: "mastitis" },
  { label: "Metritis", value: "metritis" },
  { label: "Milk fever / hypocalcaemia", value: "hypocalcaemia" },
  { label: "Mineral / vitamin imbalance", value: "mineral-vitamin-imbalance" },
  { label: "Orf", value: "orf" },
  { label: "Pneumonia - acute", value: "pneumonia-acute" },
  { label: "Pneumonia - chronic", value: "pneumonia-chronic" },
  { label: "Poisoning", value: "poisoning" },
  { label: "Predation", value: "predation" },
  { label: "Prolapse", value: "prolapse" },
  { label: "Respiratory", value: "respiratory" },
  { label: "Rotavirus", value: "rotavirus" },
  { label: "Scour / diarrhoea", value: "diarrhoea" },
  { label: "Septicaemia", value: "septicaemia" },
  { label: "Spinal disease", value: "spinal-disease" },
  { label: "TB", value: "tuberculosis" },
  { label: "Teat / udder condition", value: "udder-condition" },
  { label: "Twin lamb disease", value: "twin-lamb-disease" },
  { label: "Twisted gut", value: "twisted-gut" },
  { label: "Unknown", value: "unknown" },
  { label: "Wire", value: "wire" },
  { label: "Wound / injury", value: "injury" },
];

export const DEATH_OPTIONS: Option[] = unkeyedDeathOptions.map((option, index) => ({
  ...option,
  key: `${option.value}-${index}`,
}));
