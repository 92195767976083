import { DropdownMenu, IconButton, KeyValuePair } from "components";
import { Icons } from "../icons";

export const FamilyTreeKey: React.FC = () => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <IconButton caption="Key" iconName={"rectangle"} colour="grey" square size="x-small" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        <div className="flex flex-col p-2">
          <KeyValuePair label={"Sire"} value={Icons.maleIcon} />
          <KeyValuePair label={"Dam"} value={Icons.femaleIcon} />
          <KeyValuePair label={"Registered"} value={Icons.pedigreeIcon} />
          <KeyValuePair label={"Off farm"} value={Icons.truckIcon} />
          <KeyValuePair label={"Inbreeding"} value={Icons.alertIcon} />
          <KeyValuePair label={"View ancestor"} value={Icons.externalLinkIcon} />
        </div>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};
