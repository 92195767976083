import { FieldSelect } from "components";
import { InputSelectOption } from "components/Common/Field/Select";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  label: string;
  className?: string;
  options: InputSelectOption[];
  disabled?: boolean;
  placeholder?: string;
};

export const FormSelect = <TFieldValues extends FieldValues>({
  options,
  name,
  label,
  disabled,
  className,
  placeholder,
}: Props<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  if (!methods) {
    throw new Error("FormInput must be used inside a <Form /> or <FormProvider />");
  }

  return (
    <FieldSelect
      label={label}
      error={methods.formState.errors?.[name]?.message as string}
      className={className}
      inputProps={{
        name,
        placeholder,
        value: methods.watch(name),
        options,
        onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
          methods.setValue(name, event.target.value as PathValue<TFieldValues, Path<TFieldValues>>);
          methods.trigger(name);
        },
        isClearable: true,
        disabled,
      }}
    />
  );
};
