import React from "react";
import { useHistory } from "react-router";
// Utils
import { TRADING, DISTRIBUTION_LIST_CREATE } from "constants/Routes";
// Components
import { PageContent, Spacer } from "components";
import { DistributionListsTable } from "trading/components";
/// Resources

export const DistributionLists: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <PageContent
        floatingIconButton={{
          iconName: "plus",
          onClick: (): void => history.push(`${TRADING}${DISTRIBUTION_LIST_CREATE}`),
        }}
      >
        <Spacer baselineHeight={2} />
        <DistributionListsTable />
      </PageContent>
    </>
  );
};
