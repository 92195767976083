import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { postRequest } from "api/restAPI";
import api from "api/config";
import { DASHBOARD, TRADING } from "constants/Routes";
import { FEATURES } from "constants/Features";

import { setAppVersionToLocalStorage, setFeaturesListToLocalStorage, setTokenToLocalStorage } from "helpers/storage";

import { Modal } from "components";

import styles from "./styles.module.scss";
import { stringifyJsonSafely } from "helpers/general";
import { persistVersion } from "auth/hooks/useAuth";

interface Props {
  title: string;
  text: string;
  email: string;
  password: string;
  showModal: boolean;
  hideModal: () => void;
}

const TermsAndConditionsModal: React.FC<Props> = ({ text, title, email, password, showModal, hideModal }) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const handleAcceptTermsAndConditions = async (): Promise<void> => {
    setLoading(true);

    try {
      const { data } = await postRequest(
        api.endpoints.logIn,
        stringifyJsonSafely({
          email,
          password,
          accept: true,
        }),
      );

      const featuresList = data.features.slice(1, -1).split("$");
      const isShowHomePage = featuresList.includes(FEATURES.SHOW_HOME_PAGE);
      const pushRoute = isShowHomePage ? DASHBOARD : TRADING;

      setLoading(false);
      hideModal();

      setTokenToLocalStorage(data.token);
      setAppVersionToLocalStorage(persistVersion);
      setFeaturesListToLocalStorage(featuresList);

      history.push(pushRoute);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Modal
      showCloseButton
      active={showModal}
      handleClose={hideModal}
      title={title}
      actions={{
        primary: {
          caption: "Accept",
          disabled: loading,
          requesting: loading,
          onClick: handleAcceptTermsAndConditions,
        },
      }}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.viewTermsAndConditions}>
          <h3
            className={styles.termsAndConditionsText}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;
