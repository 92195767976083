import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router";
import * as qs from "query-string";

import logo from "assets/img/Common/trimmed-logo.png";
import { Header } from "components";
import { SetPasswordForm } from "components/Settings/";

import { COMPLETE_INVITE } from "api/User/mutations";
import { CompleteInviteVariables, CompleteInvite } from "api/User/types/CompleteInvite";

import { validationSchemaSetPassword } from "helpers/general";
import { setTokenToLocalStorage, setAppVersionToLocalStorage } from "helpers/storage";

import styles from "./InvitedUserScreen.module.scss";
import { persistVersion } from "auth/hooks/useAuth";

const InvitedUserScreen: React.FC = () => {
  const [passwordError, setPasswordError] = useState<string | null | undefined>(null);

  const location = useLocation();

  const invitedEmail = decodeURIComponent(qs.parse(location.search).email as string);
  const hash = decodeURIComponent(qs.parse(location.search).hash_code as string);
  const invitedName = decodeURIComponent(qs.parse(location.search).user_name as string);
  const businessName = decodeURIComponent(qs.parse(location.search).business_name as string);

  const [completeInvite, { loading }] = useMutation<CompleteInvite, CompleteInviteVariables>(COMPLETE_INVITE);

  const registerInvitedUser = async ({ password }): Promise<void> => {
    setPasswordError(null);
    try {
      const { data } = await completeInvite({
        variables: {
          input: {
            hashCode: hash,
            password,
          },
        },
      });

      if (data?.completeInvite?.errors) {
        setPasswordError(data?.completeInvite?.errors[0]?.message);
      } else {
        const token = data?.completeInvite?.token;
        setTokenToLocalStorage(token);
        setAppVersionToLocalStorage(persistVersion);
      }
    } catch (e) {
      setPasswordError("Something went wrong! Please try again later.");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.sideBlock}>
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.content}>
        <Header
          title={`Welcome ${invitedName}`}
          subTitle={`You have been invited by ${businessName} to Breedr platform`}
        />
        <SetPasswordForm
          email={invitedEmail}
          requesting={loading}
          setPassword={registerInvitedUser}
          passwordError={passwordError}
          validationSchema={validationSchemaSetPassword}
        />
      </div>
    </div>
  );
};

export default InvitedUserScreen;
