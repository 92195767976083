// Utils
import { USER_ROLES } from "constants/Settings";

import { getBusinessUnitUserRole } from "helpers/general";
// Hooks
import { useGetCurrentUserInfo } from "hooks";
import { useGetCurrentUserInfo as useGetBusinessUnitUsers } from "hooks/useGetBusinessUnitUsers";

type ReturnType = {
  role: string | null;
  isAdmin: boolean;
  isManager: boolean;
};

export const useGetBusinessUnitUserRole = (): ReturnType => {
  const { data: userInfoData } = useGetCurrentUserInfo();
  const { data: businessUnitUsersData } = useGetBusinessUnitUsers();

  const businessUnitUsers = businessUnitUsersData?.businessUnitUsers;
  const userInfo = userInfoData?.userInfo;
  const role = getBusinessUnitUserRole(businessUnitUsers, userInfo);
  const isAdmin = Boolean((role && role === USER_ROLES.ADMIN) || userInfoData?.userInfo?.isStaff);
  const isManager = Boolean(role && role === USER_ROLES.MANAGER);

  return { role, isAdmin, isManager };
};
