import { gql } from "@apollo/client";
import { errorFragment } from "trading/api/fragment";
import { taskDetailsFragment } from "api/Import/OtherSystems/fragment";

export const BCMS_IMPORT_CSV = gql`
  mutation BcmsImportCsv($input: BcmsImportCsvInput!) {
    bcmsImportCsv(input: $input) {
      task {
        ...taskDetailsFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${taskDetailsFragment}
`;

export const TRU_TEST_IMPORT_XLS = gql`
  mutation TruTestImportXls($input: TruTestImportXlsInput!) {
    truTestImportXls(input: $input) {
      task {
        ...taskDetailsFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${taskDetailsFragment}
`;

export const FARMPLAN_IMPORT_XLS = gql`
  mutation FarmPlanImportXls($input: FarmPlanImportXLSInput!) {
    farmPlanImportXls(input: $input) {
      task {
        ...taskDetailsFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${taskDetailsFragment}
`;

export const APHIS_IMPORT_CSV = gql`
  mutation AphisImportCsv($input: AphisImportCsvInput!) {
    aphisImportCsv(input: $input) {
      task {
        ...taskDetailsFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${taskDetailsFragment}
`;

// export const AAA_IMPORT_CSV = gql`
//   mutation AaaImportCsv($input: AaaImportCsvInput!) {
//     aaaImportCsv(input: $input) {
//       task {
//         ...taskDetailsFragment
//       }
//       errors {
//         ...errorFragment
//       }
//     }
//   }
//   ${errorFragment}
//   ${taskDetailsFragment}
// `;

export const START_FARM_SYSTEM_TASK = gql`
  mutation StartFarmSystemTask($input: StartFarmSystemTaskInput!) {
    startFarmSystemTask(input: $input) {
      id
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const CANCEL_IMPORT_TASK = gql`
  mutation CancelFarmSystemTask($input: CancelFarmSystemTaskInput!) {
    cancelFarmSystemTask(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
