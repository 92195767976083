import React, { FC, useContext, useEffect } from "react";

import styles from "./styles.module.scss";
import debounce from "lodash/debounce";
import { Button, Table } from "components";
import { Column } from "react-table";
import { useCreateDownloadMutation, useDownloadsLazyQuery, useTableDataLazyQuery } from "generated/graphql";
import { DownloadButton } from "../DownloadButton";
import { Spacer, Flex } from "components";
import tablePaginationStyles from "components/Common/Table/TablePagination/TablePagination.module.scss";
import tableChartStyles from "./styles.module.scss";
import { useFormatDate } from "hooks";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { parseSortRule } from "components/Common/CommonTable/helpers";
import InputSelect from "components/Common/InputSelect";
import { TABLE_PAGINATION_DEFAULT_OPTIONS } from "components/Common/Table/TablePagination";
import { BaseInput } from "components/Common/Field/BaseInput";

const pageSizeOptions = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
];

interface IProps {
  chartTitle: string;
  dataQuery: string; //Use this to make a query asking for chart data
  businessUnitId: number;
  filters?: {
    [key: string]: string;
  };
}

export const TableChart: FC<IProps> = ({ chartTitle, dataQuery, businessUnitId, filters }) => {
  const [searchKey, changeSearchKey] = React.useState<string>("");
  const [showSearch, setShowSearch] = React.useState<boolean | null>(null);
  const [tableFilters, setTableFilters] = React.useState<{ [key: string]: string }>(filters || {});
  const { getTableOptions } = useContext(TableOptionsContext);
  const [firstCursor, setFirstCursor] = React.useState<null | string>(null);
  const [hasNext, setHasNext] = React.useState<boolean>(false);
  const [sortBy, setSortBy] = React.useState<string | null>(null);
  const [pageSize, setPageSize] = React.useState<typeof TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES[number]>(10);
  const [downloadId, setDownloadId] = React.useState<string | null | undefined>(null);
  const [downloadsLoading, setDownloadsLoading] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<{
    last?: number;
    before?: string | null;
    first?: number;
    after?: string;
  }>({
    first: pageSize,
  });
  const { getFormatDate } = useFormatDate();
  // Using dataQuery string as id
  const tableId = dataQuery;
  const tableOptions = getTableOptions(tableId);
  const sortByValue = tableOptions?.sortBy;

  const [getTableData, { data, loading, refetch }] = useTableDataLazyQuery({ fetchPolicy: "network-only" });
  const [getDownloads, { data: downloadsData, stopPolling }] = useDownloadsLazyQuery({
    variables: {
      businessUnitId,
    },
    pollInterval: 5000,
  });

  // make the filters safe
  useEffect(() => {
    const variables = {
      chartSlug: dataQuery,
      dashboardSlug: chartTitle,
      businessUnitId: businessUnitId,
      pagination,
      orderBy: sortBy,
      filters: {
        ...tableFilters,
      },
    };

    getTableData({ variables });
  }, [getTableData, sortBy, pageSize, tableFilters, dataQuery, chartTitle, businessUnitId, pagination]);

  useEffect(() => {
    if (sortByValue) {
      setSortBy(parseSortRule(sortByValue)[0]);
    }
  }, [sortByValue]);

  useEffect(() => {
    if (downloadId != null) {
      const currentDownload = downloadsData?.downloads?.edges.filter((download) => download?.node?.id === downloadId);
      if (
        currentDownload &&
        currentDownload[0]?.node?.status === "COMPLETED" &&
        currentDownload[0]?.node?.downloadedFile
      ) {
        if (typeof stopPolling === "function") {
          stopPolling();
        }

        setDownloadsLoading(false);
        window.location.assign(currentDownload[0].node.downloadedFile);
      }
    }
  }, [downloadsData, downloadId, stopPolling]);

  const [createDownloadMutation, { loading: createDownloadLoading }] = useCreateDownloadMutation();

  const handleExport = async (downloadSlug, variables) => {
    try {
      const result = await createDownloadMutation({
        variables: {
          input: {
            businessUnitId: businessUnitId,
            dashboardSlug: chartTitle,
            downloadSlug: downloadSlug,
            variables: variables,
          },
        },
      });

      const downloadId = result.data?.createDownload?.downloadId;
      setDownloadId(downloadId);
      getDownloads();
      setDownloadsLoading(true);
    } catch (error) {
      console.log("Download error", error);
    }
  };

  const download = data?.tableData?.download ?? undefined;
  const pageInfo = data?.tableData?.pageInfo ?? undefined;
  const searchableField = data?.tableData?.searchableField ?? undefined;
  const field = searchableField?.field;
  const showControls = download != null || pageInfo != null;
  const columnNames = data?.tableData?.columns ?? [];
  const tableRows = React.useMemo(() => data?.tableData?.data ?? [], [data]);

  React.useEffect(() => {
    // Not sure this is the best thing to do here but trying to
    // avoid the input diappearing when the data is requested
    // Because the table and search are dynamically generated
    // the fields we use to deteermin if we should show come from the
    // query and are undefined at points even if we have data
    if (showSearch === null || typeof field === "string") {
      setShowSearch(true);
    }
  }, [field, showSearch]);

  React.useEffect(() => {
    if (pageInfo?.startCursor) {
      if (firstCursor == null && firstCursor != pageInfo.startCursor) {
        setFirstCursor(pageInfo.startCursor);
      }
    }
  }, [firstCursor, pageInfo]);

  interface Data {
    [key: string]: string;
  }

  // INFO: This smells but is this only current way to fix
  // the broken relay pagination. Currently hasNextPage comes
  // back from the api but does not always give the correct value
  // so we are having to do it on the frontend
  React.useEffect(() => {
    if (tableRows.length === 0) {
      setHasNext(false);
    } else {
      setHasNext(true);
    }
  }, [tableRows]);

  //@ts-ignore
  const buildColumns = (columns) => {
    return columns.map((names) => {
      // INFO: this is a problem
      if (names.accessor === "dob") {
        return {
          id: names.accessor,
          Header: names.displayName,
          accessor: (d) => {
            return getFormatDate(d.dob);
          },
          disableSortBy: !names.sortable,
        };
      } else if (names.accessor === "created_at") {
        return {
          id: names.accessor,
          Header: names.displayName,
          accessor: (d) => {
            return getFormatDate(d.created_at);
          },
          disableSortBy: !names.sortable,
        };
      } else {
        return {
          id: names.accessor,
          Header: names.displayName,
          accessor: names.accessor,
          disableSortBy: !names.sortable,
        };
      }
    });
  };

  const columns: Column<Data>[] = buildColumns(columnNames);

  const canPrevPage = () => {
    if (firstCursor === pageInfo?.startCursor || firstCursor == null) {
      return false;
    }

    return true;
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (searchableField?.field != null && typeof searchableField.field === "string") {
      changeSearchKey(event.target.value);
      const field = searchableField.field;
      const delayedChange = debounce((e) => {
        setTableFilters({ ...tableFilters, [field]: e.target.value });
      }, 1000);

      delayedChange(event);
    }
  };

  return (
    <figure>
      {showSearch ? (
        <>
          <Flex item xs={12} m={6} l={3} className={styles.search_input}>
            <Spacer baselineHeight={2} />
            <BaseInput
              name="table_search"
              onChange={handleSearchInputChange}
              placeholder={`Search ${searchableField?.label}`}
              type="search"
              value={searchKey}
            />
          </Flex>
          <Spacer baselineHeight={1} />
        </>
      ) : null}
      {showControls ? (
        <>
          <Spacer baselineHeight={2} />
          <Flex
            className={tablePaginationStyles.table_pagination_target}
            container
            containerAlignItems="center"
            containerJustifyContent="flex-end"
            containerWrap="wrap"
          >
            <Flex item itemGrow />
            {pageInfo != null && typeof refetch == "function" ? (
              <>
                <Flex item itemGutter>
                  <Button
                    disabled={!canPrevPage()}
                    caption="Previous page"
                    onClick={() => {
                      setHasNext(true);
                      refetch({
                        pagination: {
                          last: pageSize,
                          before: pageInfo.startCursor,
                          first: undefined,
                          after: undefined,
                        },
                        chartSlug: dataQuery,
                        dashboardSlug: chartTitle,
                        businessUnitId: businessUnitId,
                      });
                    }}
                  />
                </Flex>
                <div className={tableChartStyles.table_number_per_page}>
                  <InputSelect
                    defaultValue={{ value: 10, label: "10" }}
                    onOptionSelect={({ value }) => {
                      setPageSize(value);
                      setPagination({
                        first: value,
                      });
                    }}
                    options={pageSizeOptions}
                  />
                </div>
                <Flex item>
                  <Button
                    disabled={!hasNext}
                    caption="Next page"
                    onClick={() => {
                      refetch({
                        pagination: {
                          first: pageSize,
                          after: pageInfo.endCursor,
                          last: undefined,
                          before: undefined,
                        },
                        chartSlug: dataQuery,
                        dashboardSlug: chartTitle,
                        businessUnitId: businessUnitId,
                      });
                    }}
                  />
                </Flex>
              </>
            ) : null}
            {download != null ? (
              <Flex item itemGutter className={styles.exportButton}>
                <DownloadButton
                  disabled={createDownloadLoading}
                  downloadsLoading={downloadsLoading}
                  onClick={() => handleExport(download.slug, download.variables)}
                />
              </Flex>
            ) : null}
          </Flex>
        </>
      ) : (
        <Spacer baselineHeight={1} />
      )}
      <Flex container item itemGrow>
        <Flex item itemGutter xs="fill"></Flex>
        <Table<Data>
          loading={loading}
          columns={columns}
          data={tableRows}
          tableId={tableId}
          pagination={true}
          manualSortBy={true}
          manualPagination={true}
          initialPageSize={pageSize}
        />
      </Flex>
    </figure>
  );
};
