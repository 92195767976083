import { DateTime, DateTimeOptions } from "luxon";

export type DateType = string | number | Date | DateTime;

export const transformToDate = (date?: DateType | string | null | undefined, zone?: DateTimeOptions["zone"]) => {
  let tzName = zone;
  if (zone === "" || zone === null || zone === undefined) {
    tzName = undefined;
  }
  if (typeof date === "string") {
    const isoDate = DateTime.fromISO(date, { zone: tzName });
    return isoDate.isValid ? isoDate : null;
  }
  if (typeof date === "number") {
    const msDate = DateTime.fromMillis(date, { zone: tzName });
    return msDate.isValid ? msDate : null;
  }
  if (date instanceof Date) {
    const jsDate = DateTime.fromJSDate(date, { zone: tzName });
    return jsDate;
  }
  if (date instanceof DateTime && date.isValid) {
    return date;
  }
  return null;
};

// @deprecated Use transformToDate instead
export const transformToJSDate = (
  date?: DateType | string | null | undefined,
  zone?: DateTimeOptions["zone"],
): Date | null => {
  if (typeof date === "string") {
    const isoDate = DateTime.fromISO(date, { zone });
    return isoDate.isValid ? isoDate.toJSDate() : null;
  }
  if (typeof date === "number") {
    const msDate = DateTime.fromMillis(date, { zone });
    return msDate.isValid ? msDate.toJSDate() : null;
  }
  if (date instanceof Date) {
    return date;
  }
  if (date instanceof DateTime && date.isValid) {
    return date.toJSDate();
  }
  return null;
};

// @deprecated, you should be using the bu timezone
export const formatDate = (date?: DateType | null, format = "dd/MM/yyyy") => {
  const jsDate = transformToJSDate(date);

  if (!jsDate) {
    return "";
  }

  return DateTime.fromJSDate(jsDate).toUTC().toFormat(format, { locale: "utc" });
};

// @deprecated - Get the string date including locale data
export const formatDateIncLocale = (date?: DateType | null, format = "dd/MM/yyyy") => {
  const jsDate = transformToJSDate(date);

  if (!jsDate) {
    return "";
  }

  return DateTime.fromJSDate(jsDate).toFormat(format);
};

export const getInternationalDateFormat = (countryCode: string | undefined) => {
  switch (countryCode?.toLowerCase()) {
    case "us":
      return {
        shortDateFormat: "MM/dd/yyyy",
        longDateFormat: "LL-dd-y HH:mm",
        locale: "en-US",
        fullDateFormat: "LL-dd-y HH:mm z",
      };
    default:
      return {
        shortDateFormat: "dd/MM/yyyy",
        longDateFormat: "dd-LL-y HH:mm",
        locale: "en-GB",
        fullDateFormat: "dd-LL-y HH:mm z",
      };
  }
};
