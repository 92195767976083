import { gql } from "@apollo/client";

import { errorFragment } from "trading/api/fragment";
import { fieldFragment } from "api/MyLivestock/Fields/fragment";

export const CREATE_FIELD = gql`
  mutation CreateField($input: CreateFieldInput!) {
    createField(input: $input) {
      errors {
        ...errorFragment
      }
      field {
        ...fieldFragment
      }
    }
  }
  ${errorFragment}
  ${fieldFragment}
`;

export const OLD_UPDATE_FIELD_WITH_FRAGMENT = gql`
  mutation UpdateFieldOld($input: UpdateFieldInput!) {
    updateField(input: $input) {
      errors {
        ...errorFragment
      }
      field {
        ...fieldFragment
      }
    }
  }
  ${errorFragment}
  ${fieldFragment}
`;

export const DELETE_FIELD = gql`
  mutation DeleteField($input: DeleteFieldInput!) {
    deleteField(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const MOVE_ANIMALS_TO_FIELDS = gql`
  mutation MoveAnimalsToField($input: MoveAnimalsToFieldInput!) {
    moveAnimalsToField(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
