// React
import React from "react";
// Libraries
import pure from "recompose/pure";
import cn from "classnames";
// Custom
import { ICON_NAMES, ICON_SIZES } from "constants/Icons";
import { Icon } from "components";
import styles from "./IconButton.module.scss";

export interface IconButtonProps {
  className?: string;
  colour?: "yellow" | "grey";
  disabled?: boolean;
  iconName: keyof typeof ICON_NAMES;
  iconColour?: "white" | "black" | "grey";
  id?: string;
  onClick: (event?: React.SyntheticEvent<HTMLButtonElement>) => void;
  size?: "x-small" | "small" | "large";
  square?: boolean;
  caption?: string;
}
export const IconButton = pure(
  ({
    className,
    colour = "yellow",
    iconName,
    iconColour,
    size = "large",
    square = false,
    caption,
    ...props
  }: IconButtonProps) => {
    let iconSize: keyof typeof ICON_SIZES = "large";
    if (size === "x-small") {
      iconSize = "tiny";
    } else if (size === "small") {
      iconSize = "small";
    }

    return (
      <button
        data-testid="create-button"
        className={cn(
          styles.icon_button,
          {
            [styles[`icon_button--colour__${colour}`]]: true,
            [styles[`icon_button--size__${size}`]]: true,
            [styles[`icon_button--icon_colour__${iconColour}`]]: iconColour,
            [styles[`icon_button--square`]]: square,
            [styles[`icon_button--with-caption`]]: caption,
          },
          className,
        )}
        type="button"
        {...props}
      >
        {caption ? <span className="mr-2">{caption}</span> : null}
        <Icon colour={colour === "grey" ? "black" : "white"} name={iconName} size={iconSize} />
      </button>
    );
  },
);

IconButton.displayName = "IconButton";
