import React, { useState, useContext } from "react";
import { CONFIRMATION_MODALS } from "constants/Settings";
import { getPageHeaderTitle, getPageHeaderSubTitle, getConfirmModalTitle } from "helpers/settings";
import { getBUFromLocalStorage } from "helpers/storage";
import { useGetCurrentUserInfo } from "hooks";
import { UserForm } from "components/Settings";
import {
  ConfirmModalWithOutInput as Modal,
  LoadingOverlay,
  Button,
  Flex,
  Header,
  PageContent,
  PageHeader,
  ConfirmDeletionModal,
  Spacer,
  Table,
  Badge,
  TablePaginationTarget,
} from "components";
import { Column } from "react-table";
import {
  BusinessUnitUserStatus,
  InviteUserInput,
  useDeleteUserFromFarmMutation,
  useGetBusinessUnitsQuery,
  useGetBusinessUnitUsersQuery,
  useInviteUserMutation,
  useResentInviteMutation,
  useSentResetPasswordEmailMutation,
  useUpdateBusinessUnitUserMutation,
} from "generated/graphql";
import { removeNothings } from "helpers/general";
import { useFormatDate } from "hooks";
import { TABLE_IDS } from "constants/Interface";
import { CommonContext } from "config/commonProvider";
import styles from "./UserManagement.module.scss";
import { UpdateBusinessUnitUserVariables } from "api/User/types/UpdateBusinessUnitUser";
import { UserFormInput } from "components/Settings/UserManagement/UserForm";

export interface UserData {
  email: string;
  firstName: string;
  businessUnitUserId: string;
  userId: string;
  lastLoggedIn: string;
  lastName: string;
  name: string;
  roleName: string;
  roleId: string;
  status: string;
}

const tableId = TABLE_IDS["USER_MANAGEMENT_TABLE"];

export const UserManagement: React.FC = () => {
  const businessUnitId = getBUFromLocalStorage();

  const { showNotification } = useContext(CommonContext);
  const { getFormatDate } = useFormatDate();
  const currentUserData = useGetCurrentUserInfo();

  const [showUserForm, setShowUserForm] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | undefined>();
  const [showModal, setShowModal] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();

  const updateUserSuccessMessage = "User account successfully updated";
  const updateUserErrorMessage = "Error updating user account";
  const createUserErrorMessage = "Error creating user account";
  const defaultErrorMessage = "Something went wrong! Please try again.";

  const [inviteUser, { loading: loadingInviteUser }] = useInviteUserMutation();
  const [reinviteUser, { loading: loadingReinviteUser }] = useResentInviteMutation();
  const [deleteUserFromFarm, { loading: loadingDeleteUserFromFarm }] = useDeleteUserFromFarmMutation();
  const [sendResetPasswordEmail, { loading: loadingSendResetPasswordEmail }] = useSentResetPasswordEmailMutation();
  const [updateBusinessUnitUser, { loading: loadingUpdateBusinessUnitUser }] = useUpdateBusinessUnitUserMutation();

  const {
    data: BUUsersData,
    loading,
    refetch,
  } = useGetBusinessUnitUsersQuery({
    variables: {
      businessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const { data: BUData, loading: BULoading } = useGetBusinessUnitsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const businessUnits = BUData?.businessUnits;
  const businessUnitName = (businessUnits && businessUnits.find((bu) => Number(bu?.id) === businessUnitId)?.name) || "";

  const handleShowModal = (type: string): void => setShowModal(type);
  const handleShowDeleteUserModal = (): void => handleShowModal(CONFIRMATION_MODALS.DELETE_USER);
  const handleCloseModal = (): void => {
    setError(undefined);
    setShowModal(undefined);
  };

  const resetUserData = (): void => {
    setUserData(undefined);
    setShowModal(undefined);
    setShowUserForm(false);
  };

  const handleCreateUser = async (input: UserFormInput) => {
    try {
      setError(undefined);
      const inviteUserInput: InviteUserInput = {
        email: input.email,
        businessUnit: businessUnitId,
        firstName: input.firstName,
        lastName: input.lastName,
        role: input.roleId ? Number(input.roleId) : undefined,
      };

      const { data } = await inviteUser({
        variables: {
          input: inviteUserInput,
        },
      });

      if (data?.inviteUser?.errors) {
        setError(data?.inviteUser?.errors[0]?.message || defaultErrorMessage);

        showNotification({
          message: `${createUserErrorMessage} - ${data?.inviteUser?.errors[0]?.message || defaultErrorMessage}`,
          variant: "error",
        });
      } else {
        setShowUserForm(false);
        await refetch();
      }
    } catch (e: unknown) {
      setError(defaultErrorMessage);
      showNotification({
        message: defaultErrorMessage,
        variant: "error",
      });
    }
  };

  const handleUpdateUser = async (input: UserFormInput) => {
    try {
      setError(undefined);
      const variables: UpdateBusinessUnitUserVariables = {
        input: {
          status: input.status,
          role: +input.roleId,
          businessUnitUser: +input.businessUnitUserId,
        },
      };

      const { data } = await updateBusinessUnitUser({
        variables,
      });

      if (data?.updateBusinessUnitUser?.errors) {
        setError(data?.updateBusinessUnitUser?.errors[0]?.message || defaultErrorMessage);
        showNotification({
          message: `${updateUserErrorMessage} - ${
            data?.updateBusinessUnitUser?.errors[0]?.message || defaultErrorMessage
          }`,
          variant: "error",
        });
      } else {
        await refetch();
        resetUserData();
        showNotification({
          message: updateUserSuccessMessage,
        });
      }
    } catch (e: unknown) {
      setError(defaultErrorMessage);
      showNotification({
        message: defaultErrorMessage,
        variant: "error",
      });
    }
  };

  const handleResendInvite = async (): Promise<void> => {
    setError(undefined);
    try {
      await reinviteUser({
        variables: {
          input: {
            businessUnitUser: userData?.businessUnitUserId ? +userData.businessUnitUserId : 0,
          },
        },
      });
      setUserData(undefined);
      setShowModal(undefined);
      setShowUserForm(false);
      showNotification({
        message: "Successfully sent new invite",
      });
    } catch (e: unknown) {
      setError(defaultErrorMessage);
    }
  };

  const handleResetPassword = async (): Promise<void> => {
    setError(undefined);

    try {
      await sendResetPasswordEmail({
        variables: {
          input: {},
        },
      });
      setShowModal(undefined);
    } catch (e) {
      setError(defaultErrorMessage);
    }
  };

  const handleDeleteBusinessUnitUser = async (): Promise<void> => {
    setError(undefined);

    try {
      const { data } = await deleteUserFromFarm({
        variables: {
          input: {
            businessUnitUser: userData?.businessUnitUserId ? +userData.businessUnitUserId : 0,
          },
        },
      });

      if (data?.deleteUserFromFarm?.errors) {
        setError(data?.deleteUserFromFarm?.errors[0]?.message || defaultErrorMessage);
      } else {
        resetUserData();
        await refetch();
      }
    } catch (e) {
      setError(defaultErrorMessage);
    }
  };

  const businessUnitUsers = removeNothings(BUUsersData?.businessUnitUsers || []);

  const columnData: Array<UserData> = businessUnitUsers.map((businessUnitUser) => {
    return {
      name: `${businessUnitUser.user.firstName} ${businessUnitUser.user.lastName}`,
      email: businessUnitUser.user.email,
      roleName: businessUnitUser.role.name,
      roleId: businessUnitUser.role.id,
      status: businessUnitUser.status,
      lastLoggedIn: getFormatDate(businessUnitUser.user.lastLogin),
      firstName: businessUnitUser.user.firstName,
      lastName: businessUnitUser.user.lastName,
      userId: businessUnitUser.user.id,
      businessUnitUserId: businessUnitUser.id,
    };
  });

  const handleClickCell = React.useCallback((data: UserData) => {
    setUserData({
      businessUnitUserId: data.businessUnitUserId,
      userId: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roleName: data.roleName,
      roleId: data.roleId,
      status: data.status,
      name: data.name,
      lastLoggedIn: data.lastLoggedIn,
    });

    setShowUserForm(true);
  }, []);

  const columns: Column<UserData>[] = React.useMemo(() => {
    return [
      {
        Header: "User",
        accessor: "name",
        minWidth: 150,
        width: 150,
        onCellClick: handleClickCell,
      },
      {
        Header: "Email",
        accessor: "email",
        minWidth: 150,
        width: 150,
        onCellClick: handleClickCell,
      },
      {
        Header: "Role",
        accessor: "roleName",
        minWidth: 150,
        width: 150,
        onCellClick: handleClickCell,
      },
      {
        Header: "Status",
        accessor: "status",
        minWidth: 150,
        width: 150,
        onCellClick: handleClickCell,
        Cell: function StatusColumns({ row }) {
          const { status } = row?.original;

          return <StatusBadge status={status} />;
        },
      },
      {
        Header: "Last Logged In",
        accessor: "lastLoggedIn",
        minWidth: 150,
        width: 150,
        onCellClick: handleClickCell,
      },
    ];
  }, [handleClickCell]);

  const loadingUpdates: boolean =
    loadingReinviteUser || loadingDeleteUserFromFarm || loadingSendResetPasswordEmail || loadingUpdateBusinessUnitUser;
  const loggedInUserId = currentUserData?.data?.userInfo?.id || null;
  const isCurrentLoggedInUser = loggedInUserId === userData?.userId;

  if (loading || BULoading) {
    return <LoadingOverlay customStyles={styles.loadingOverlay} />;
  }

  return (
    <>
      <PageHeader>
        <Header
          title={getPageHeaderTitle(showUserForm, userData !== undefined, userData?.name || "")}
          subTitle={getPageHeaderSubTitle(showUserForm, userData != undefined, userData?.name || "")}
        >
          {!showUserForm ? <Button caption="Invite user" onClick={(): void => setShowUserForm(true)} /> : null}

          {showUserForm && userData?.status === BusinessUnitUserStatus.Invited ? (
            <Button
              caption="Resend invite"
              colour="grey"
              onClick={() => handleShowModal(CONFIRMATION_MODALS.RESENT_INVITE)}
            />
          ) : null}

          {showUserForm && Boolean(userData) ? (
            <Button
              caption="Delete user"
              colour="red"
              disabled={isCurrentLoggedInUser}
              onClick={handleShowDeleteUserModal}
            />
          ) : null}
        </Header>
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />

        {businessUnitUsers && !showUserForm ? (
          <Flex container>
            <Flex item itemGutter xs={12}>
              <Flex container containerJustifyContent="flex-end">
                <TablePaginationTarget tableId={tableId} />
              </Flex>
              <Spacer baselineHeight={2} />
              <Table<UserData> pagination initialPageSize={10} data={columnData} columns={columns} tableId={tableId} />
            </Flex>
          </Flex>
        ) : null}

        {showUserForm ? (
          <UserForm
            onCloseForm={resetUserData}
            editMode={userData !== undefined}
            isLoading={loadingInviteUser || loadingUpdateBusinessUnitUser}
            onSubmit={userData !== undefined ? handleUpdateUser : handleCreateUser}
            formData={{
              businessUnitName,
              email: userData?.email || "",
              status: userData?.status || "",
              firstName: userData?.firstName || "",
              lastName: userData?.lastName || "",
              roleId: userData?.roleId || "",
              userId: userData?.userId || "",
              businessUnitUserId: userData?.businessUnitUserId || "",
            }}
            isCurrentLoggedInUser={isCurrentLoggedInUser}
            handleShowConfirmModal={handleShowModal}
          />
        ) : null}

        {showModal === CONFIRMATION_MODALS.DELETE_USER ? (
          <ConfirmDeletionModal
            showModal={!!showModal}
            errorMessage={error || undefined}
            onClose={handleCloseModal}
            entityName={userData?.name || ""}
            subtitle="Type DELETE  and click confirm to remove this user"
            onSubmit={handleDeleteBusinessUnitUser}
          />
        ) : null}

        {showModal && showModal !== CONFIRMATION_MODALS.DELETE_USER ? (
          <Modal
            error={error}
            loading={loadingUpdates}
            title={getConfirmModalTitle(showModal, userData?.name || "")}
            showModal={!!showModal}
            onClose={handleCloseModal}
            onConfirm={showModal === CONFIRMATION_MODALS.RESENT_INVITE ? handleResendInvite : handleResetPassword}
          />
        ) : null}
      </PageContent>
    </>
  );
};

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  switch (status) {
    case BusinessUnitUserStatus.Inactive:
      return <Badge text="Disabled" color="red" />;

    case BusinessUnitUserStatus.Active:
      return <Badge text="Enabled" color="green" />;

    case BusinessUnitUserStatus.Invited:
      return <Badge text="Invited" color="yellow" />;

    default:
      return <Badge text="Unknown Status" color="grey" />;
  }
};
