import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesTagsTab: FC = () => {
  return (
    <EmptyStates title="You haven't created any tags yet." animation={"tags"}>
      <p>
        Boost livestock traceability, streamline management, and enhance productivity by creating your own custom tags.
        <br />
        Click on the ‘+ Create’ button to start creating your tags.
      </p>
    </EmptyStates>
  );
};
