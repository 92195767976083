import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesArchiveTab: FC = () => {
  return (
    <EmptyStates
      animation={"searching"}
      title="You haven't archived any animals."
      // buttonCaption="Guide to ‘Mark off-farm’"
      // buttonCaptionTwo="Visit our help guides"
    >
      <p>
        To archive an animal, navigate to your animal list under the My Livestock tab, select the checkbox next to the
        animal(s) in the table and click the ‘Mark off-farm’ or ‘Mark as dead’.
      </p>
    </EmptyStates>
  );
};
