import { useContext, useEffect, useRef, FC } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { useCreateBatchMutation, useGroupLazyQuery, useUpdateBatchMutation } from "generated/graphql";
import { GROUPS, LIVESTOCK } from "constants/Routes";
import { BATCH_GENDER_OPTIONS, GROUP_CATEGORY_OPTIONS } from "constants/Groups";
import { useStateSpecies, useGetBreeds, useGetCurrentBusinessUnit } from "hooks";
import GroupHeader from "pages/MyLivestock/General/GroupFieldHeader";

import {
  PageHeader,
  PageContent,
  Spacer,
  LoadingOverlay,
  Breadcrumbs,
  FieldNumber,
  FieldDate,
  FieldText,
  Flex,
  Title,
  FieldSelect,
} from "components";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { CommonContext } from "config/commonProvider";
import { HEADER_TYPES } from "constants/General";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";

type FormValues = {
  name: string;
  targetWeight: number;
  targetDate: Date | string;
  category: string;
  numberOfUnidentifiedAnimalsInBatch: number;
  animalBreeds: string[];
  gendersInBatch: string;
  averageDobInBatch: Date | string;
  batchDateOnFarm: Date | string;
};

type FormProps = FormikProps<FormValues>;

const validationSchemaCreateGroup = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  targetWeight: Yup.number().min(0, "Minimum value is 0"),
  targetDate: Yup.date().min(DateTime.local().toJSDate(), "Target date must be in the future"),
  category: Yup.string().required("Type is required!"),
  numberOfUnidentifiedAnimalsInBatch: Yup.number()
    .min(0, "Minimum value is 0")
    .max(10000, "Maximum value is 10,000")
    .required("Number of animals is required!"),
  animalBreeds: Yup.array().of(Yup.string()).required("Breeds are required!"),
  gendersInBatch: Yup.string()
    .matches(/(MALE_AND_FEMALE|FEMALE|MALE)/)
    .required("Gender is required!"),
  averageDobInBatch: Yup.date().required("Estimated Date of Birth is required!"),
  batchDateOnFarm: Yup.date()
    .min(Yup.ref("averageDobInBatch"), "Cannot be before Estimated Date of Birth")
    .required("Estimated Date on Farm is required!"),
});

export const CreateEditBatch: FC = () => {
  const { breedOptions } = useGetBreeds();
  const { activeSpecies } = useStateSpecies();
  const { id: businessUnit, unitsOfMeasurement } = useGetCurrentBusinessUnit();
  const { groupId } = useParams<LivestockGroupPageParams>();
  const { showNotification } = useContext(CommonContext);
  const history = useHistory();
  const { terms } = useLocale();

  const [getBatch, { data: batchData, loading: getBatchLoading }] = useGroupLazyQuery();

  useEffect(() => {
    if (groupId) {
      getBatch({
        variables: {
          group: groupId,
          businessUnit: +businessUnit,
          isBatch: true,
        },
      });
    }
  }, [groupId]);

  const [createBatch, { loading: createBatchLoading }] = useCreateBatchMutation();
  const [updateBatch, { loading: updateBatchLoading }] = useUpdateBatchMutation();

  const weightUnit =
    unitsOfMeasurement
      ?.find((unitOfMeasurement) => unitOfMeasurement?.measurementType === "WEIGHT_LARGE")
      ?.code.toLowerCase() || "";

  const previousBatch = batchData?.group;
  const targetDate = previousBatch?.targetDate ? new Date(previousBatch?.targetDate) : "";
  const averageDobInBatch = previousBatch?.averageDobInBatch ? new Date(previousBatch?.averageDobInBatch) : "";
  const animalBreeds = previousBatch?.animalBreeds.map((animalBreed) => animalBreed.id) ?? ([] as string[]);
  const batchDateOnFarm = previousBatch?.batchDateOnFarm ? new Date(previousBatch?.batchDateOnFarm) : "";
  const initialValues = {
    name: previousBatch?.name || "",
    targetWeight: previousBatch?.targetWeight || 0,
    category: previousBatch?.category || "",
    numberOfUnidentifiedAnimalsInBatch: previousBatch?.numberOfUnidentifiedAnimalsInBatch || 0,
    gendersInBatch: previousBatch?.gendersInBatch || "",
    animalBreeds,
    averageDobInBatch,
    targetDate,
    batchDateOnFarm,
  };

  const handleSaveGroup = async (values: FormValues): Promise<void> => {
    const { targetWeight, animalBreeds, category, gendersInBatch, name, numberOfUnidentifiedAnimalsInBatch } = values;
    const animalType = (activeSpecies && +activeSpecies?.id) || 3;
    const targetDate =
      typeof values.targetDate !== "string" ? DateTime.fromJSDate(values.targetDate).toISODate() : null;
    const averageDobInBatch =
      typeof values.averageDobInBatch !== "string" ? DateTime.fromJSDate(values.averageDobInBatch).toISODate() : null;
    const batchDateOnFarm =
      typeof values.batchDateOnFarm !== "string" ? DateTime.fromJSDate(values.batchDateOnFarm).toISODate() : null;

    try {
      if (previousBatch) {
        const updateInput = {
          targetWeight,
          animalBreeds,
          category,
          gendersInBatch,
          name,
          targetDate,
          averageDobInBatch,
          animalType,
          numberOfUnidentifiedAnimalsInBatch,
          batchDateOnFarm,
          id: previousBatch.id,
          businessUnitTarget: +businessUnit,
        };

        const { data } = await updateBatch({ variables: { input: { ...updateInput } } });

        const errors = data?.updateBatch?.errors;

        if (errors) {
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; "),
          });
        } else {
          showNotification({
            message: "Group successfully updated",
          });
          history.push(`${LIVESTOCK}/batch/${data?.updateBatch?.group?.id}`);
        }
      } else {
        const createInput = {
          targetWeight,
          animalBreeds,
          category,
          gendersInBatch,
          name,
          numberOfUnidentifiedAnimalsInBatch,
          targetDate,
          animalType,
          averageDobInBatch,
          batchDateOnFarm,
          businessUnit: +businessUnit,
        };

        const { data } = await createBatch({ variables: { input: { ...createInput } } });

        const errors = data?.createBatch?.errors;

        if (errors) {
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; "),
          });
        } else {
          history.push(`${LIVESTOCK}/batch/${data?.createBatch?.group?.id}`);
          showNotification({
            message: "Group successfully created",
          });
        }
      }
    } catch (errors) {
      showNotification({
        variant: "error",
        message: `Error ${previousBatch ? "updating" : "creating"} Group`,
      });
    }
  };

  const currentSpecies = useRef(activeSpecies?.id);
  const hasSpeciesChanged = activeSpecies?.id !== currentSpecies.current && currentSpecies.current && activeSpecies?.id;

  if (hasSpeciesChanged) {
    return <Redirect to={GROUPS} />;
  }
  return (
    <>
      {updateBatchLoading || createBatchLoading || getBatchLoading ? <LoadingOverlay /> : null}

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchemaCreateGroup}
        onSubmit={handleSaveGroup}
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit, touched }: FormProps): React.ReactNode => {
          const handleDateChange = (fieldKey: string, value: Date): void => {
            setFieldValue(fieldKey, value);
          };

          return (
            <>
              <PageHeader>
                <Breadcrumbs
                  rootPaths={[
                    {
                      path: "Groups",
                      route: GROUPS,
                    },
                  ]}
                  currentPath={"Create Group"}
                />
                <GroupHeader type={HEADER_TYPES.GROUP} handleSubmit={handleSubmit} item={previousBatch} />
              </PageHeader>

              <PageContent>
                <Spacer baselineHeight={3} />

                <Flex container>
                  <Flex item itemGutter xs={12}>
                    <Title secondary>{"Group details"}</Title>
                  </Flex>
                </Flex>
                <Spacer baselineHeight={1} />
                <Flex container>
                  <Flex item itemGutter xs={12} m={3}>
                    <FieldText
                      label={"Group Name *"}
                      error={touched.name ? errors.name : ""}
                      inputProps={{
                        name: "name",
                        defaultValue: values.name,
                        onChange: handleChange,
                      }}
                    />
                  </Flex>
                  <Flex item itemGutter xs={12} m={3}>
                    <FieldSelect
                      label="Type *"
                      error={touched.category ? errors.category : ""}
                      inputProps={{
                        name: "category",
                        value: values.category,
                        placeholder: "Select type",
                        options: GROUP_CATEGORY_OPTIONS,
                        onChange: handleChange,
                        valueKey: "value",
                        labelKey: "label",
                      }}
                    />
                  </Flex>

                  <Flex item itemGutter xs={12} m={3}>
                    <FieldNumber
                      label={`Target Weight ${weightUnit && "(" + weightUnit + ")"}`}
                      error={touched.targetWeight ? errors.targetWeight : ""}
                      inputProps={{
                        name: "targetWeight",
                        onChange: handleChange,
                        value: values.targetWeight,
                      }}
                    />
                  </Flex>
                  <Flex item itemGutter xs={12} m={3}>
                    <FieldDate
                      label="Target Date"
                      error={touched.targetDate ? errors.targetDate : ""}
                      inputProps={{
                        name: "targetDate",
                        value: values.targetDate,
                        onChange: handleDateChange.bind(this, "targetDate"),
                        minDate: new Date(),
                      }}
                    />
                  </Flex>

                  <Flex item itemGutter xs={12} m={3}>
                    <FieldNumber
                      label="Number of Animals *"
                      error={
                        touched.numberOfUnidentifiedAnimalsInBatch ? errors.numberOfUnidentifiedAnimalsInBatch : ""
                      }
                      inputProps={{
                        name: "numberOfUnidentifiedAnimalsInBatch",
                        onChange: handleChange,
                        value: values.numberOfUnidentifiedAnimalsInBatch,
                      }}
                    />
                  </Flex>
                  <Flex item itemGutter xs={12} m={3}>
                    <FieldSelect
                      label="Sex *"
                      error={touched.gendersInBatch ? errors.gendersInBatch : ""}
                      inputProps={{
                        name: "gendersInBatch",
                        value: values.gendersInBatch,
                        options: BATCH_GENDER_OPTIONS,
                        onChange: handleChange,
                        valueKey: "value",
                        labelKey: "label",
                        placeholder: "Select gender",
                      }}
                    />
                  </Flex>

                  <Flex item itemGutter xs={12} m={3}>
                    <FieldDate
                      label="Estimated Date of Birth *"
                      error={touched.averageDobInBatch ? errors.averageDobInBatch : ""}
                      inputProps={{
                        name: "averageDobInBatch",
                        value: values.averageDobInBatch,
                        onChange: handleDateChange.bind(this, "averageDobInBatch"),
                      }}
                    />
                  </Flex>

                  <Flex item itemGutter xs={12} m={3}>
                    <FieldDate
                      label={`Estimated Date on ${capitaliseFirstLetter(terms.farm)} *`}
                      error={touched.batchDateOnFarm ? errors.batchDateOnFarm : ""}
                      inputProps={{
                        name: "batchDateOnFarm",
                        value: values.batchDateOnFarm,
                        onChange: handleDateChange.bind(this, "batchDateOnFarm"),
                        minDate: values.averageDobInBatch,
                      }}
                    />
                  </Flex>
                  {/* This needs looking at, initial value shows value instead of label */}
                  <Flex item itemGutter xs={12} m={6}>
                    <FieldSelect
                      label="Breed(s) *"
                      error={touched.animalBreeds ? errors.animalBreeds : ""}
                      inputProps={{
                        name: "animalBreeds",
                        value: values.animalBreeds,
                        options: breedOptions,
                        multiple: true,
                        valueKey: "value",
                        labelKey: "label",
                        onChange: handleChange,
                        placeholder: "Select Breed(s)",
                      }}
                    />
                  </Flex>
                </Flex>
                <Spacer baselineHeight={3} />
              </PageContent>
            </>
          );
        }}
      </Formik>
    </>
  );
};
