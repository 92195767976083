// react
import React, { useState } from "react";
import { useParams } from "react-router-dom";
// Utils
import { BU_TYPES } from "trading/constants";
import { getBUTypeFromLocalStorage } from "helpers/storage";
// Components
import { Flex, Header, PageContent, PageHeader, Spacer } from "components";
import { AddPreferredSuppliersModal, PreferredSuppliersTabs, SuppliersTable } from "components/Settings";
import { SUPPLIER_TYPES } from "components/Settings/SupplierManagement/PreferredSuppliersTabs";

const SupplierManagement: React.FC = () => {
  const { supplierType } = useParams<SettingsSuppliersPageParams>();
  const currentBUType: string | null = getBUTypeFromLocalStorage();
  const isProcessor: boolean = currentBUType === BU_TYPES.processor;
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false);

  const handleModalToggle = (): void => {
    setIsModalShowing(!isModalShowing);
  };

  const handleModalClose = (): void => {
    setIsModalShowing(false);
  };
  const isAvailableToAdd = supplierType === SUPPLIER_TYPES.SELLERS || isProcessor;

  return (
    <>
      <PageHeader>
        <Header title="Supplier and Partner Management" subTitle="Manage your suppliers and partners" />
        <PreferredSuppliersTabs />
      </PageHeader>

      <PageContent
        floatingIconButton={
          isAvailableToAdd
            ? {
                iconName: "plus",
                onClick: handleModalToggle,
              }
            : undefined
        }
      >
        <Spacer baselineHeight={3} />

        <Flex container>
          <Flex item itemGutter xs={12}>
            <SuppliersTable />
          </Flex>
        </Flex>

        {isAvailableToAdd ? <AddPreferredSuppliersModal showModal={isModalShowing} onClose={handleModalClose} /> : null}
      </PageContent>
    </>
  );
};

export default SupplierManagement;
