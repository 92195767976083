import { FC, useEffect, useState } from "react";
import cn from "classnames";

import { LoadingOverlay } from "components";
import { FieldSelect } from "components/Common/Field/Select";

import styles from "./styles.module.scss";
import { useTags } from "tags/hooks/useTagsHook";
import { useGetCurrentBusinessUnit } from "hooks";
import { useGetAnimalsWithTagsLazyQuery } from "generated/graphql";
import { removeNothings } from "helpers/general";
import { AnimalsWithTags } from "tags/types";
import { animalsWithoutTags, animalsWithTags } from "tags/helpers";

interface Props {
  value: string;
  animalIds: Array<string>;
  onSelect: (value: string) => void;
  removing?: boolean;
}

export const TagSelect: FC<Props> = ({ value, onSelect, animalIds, removing = false }) => {
  const [getAnimals, { data }] = useGetAnimalsWithTagsLazyQuery();
  const [selectedAnimals, setSelectedAnimals] = useState<AnimalsWithTags>([]);
  const { id } = useGetCurrentBusinessUnit();

  useEffect(() => {
    if (animalIds.length > 0) {
      getAnimals({ variables: { businessUnitId: Number(id), animalIds } });
    }
  }, [animalIds, id, getAnimals]);

  useEffect(() => {
    if (data?.animals?.edges != null) {
      const animals = removeNothings(data?.animals?.edges);
      setSelectedAnimals(animals);
    }
  }, [data]);

  const { animalTags, isLoading: loading } = useTags();
  const isLoading = loading && animalTags.length === 0;

  const tagsOptions = removeNothings(
    animalTags.map((tag) => {
      const count = removing
        ? animalsWithoutTags(selectedAnimals, tag?.node?.id).length
        : animalsWithTags(selectedAnimals, tag?.node?.id).length;
      if (count === selectedAnimals.length) {
        return;
      }

      return {
        value: tag?.node?.id,
        key: tag?.node?.id,
        label: tag?.node?.name,
      };
    }),
  );

  const handleSelectChange = (e): void => {
    e.stopPropagation();

    onSelect(e.target.value);
  };

  const containerClassNames = cn({
    [styles.container]: true,
    [styles.emptyList]: isLoading,
  });

  return (
    <div className={containerClassNames}>
      {isLoading ? (
        <LoadingOverlay customStyles={styles.loadingOverlay} />
      ) : (
        <FieldSelect
          inputProps={{
            name: "tag-select-field",
            value,
            options: tagsOptions,
            onChange: handleSelectChange,
            multiple: false,
            placeholder: "No tag",
            "data-testid": "tag-select-field",
            "data-testvalue": tagsOptions.length,
            isClearable: true,
          }}
        />
      )}
    </div>
  );
};
