import { gql } from "@apollo/client";
import {
  activityTypeFragment,
  activityTemplateFragment,
  activityLogFragment,
  historyAnimalFragment,
} from "api/MyLivestock/Activity/fragment";
import { pageInfoFragment } from "trading/api/fragment";

export const GET_ACTIVITY_TYPES = gql`
  query GetActivityTypes($businessUnit: Int!) {
    activityTypes(businessUnit: $businessUnit) {
      ...activityTypeFragment
    }
  }
  ${activityTypeFragment}
`;

export const GET_ACTIVITY_TEMPLATES = gql`
  query GetActivityTemplates($businessUnit: Int, $species: Int) {
    activityTemplates(businessUnit: $businessUnit, species: $species) {
      ...activityTemplateFragment
    }
  }
  ${activityTemplateFragment}
`;

export const GET_ANIMAL_HISTORY_BY_ID = gql`
  query GetActivityLog(
    $animal: UUID
    $first: Int
    $after: String
    $activityTemplateSlugs: [String]
    $activityTypeSlugs: [String]
  ) {
    activities(
      animal: $animal
      first: $first
      after: $after
      activityTemplateSlugs: $activityTemplateSlugs
      activityTypeSlugs: $activityTypeSlugs
    ) {
      edges {
        node {
          ...activityLogFragment
        }
      }
      pageInfo {
        ...pageInfoFragment
      }
    }
  }
  ${activityLogFragment}
  ${pageInfoFragment}
`;

export const GET_ANIMAL_HISTORY_BY_ID_OLD = gql`
  query GetActivityLogOld(
    $animal: UUID
    $first: Int
    $after: String
    $activityTemplateSlugs: [String]
    $activityTypeSlugs: [String]
  ) {
    activities(
      animal: $animal
      first: $first
      after: $after
      activityTemplateSlugs: $activityTemplateSlugs
      activityTypeSlugs: $activityTypeSlugs
    ) {
      edges {
        node {
          ...historyAnimalFragment
        }
      }
      pageInfo {
        ...pageInfoFragment
      }
    }
  }
  ${historyAnimalFragment}
  ${pageInfoFragment}
`;

export const GET_ANIMAL_HISTORY_BY_ID_NEW = gql`
  query GetActivityLogNew(
    $animal: UUID
    $first: Int
    $after: String
    $activityTemplateSlugs: [String]
    $activityTypeSlugs: [String]
    $isChartable: Boolean
  ) {
    activities(
      animal: $animal
      first: $first
      after: $after
      activityTemplateSlugs: $activityTemplateSlugs
      activityTypeSlugs: $activityTypeSlugs
      isChartable: $isChartable
    ) {
      edges {
        node {
          ...activityLogFragment
        }
      }
      pageInfo {
        ...pageInfoFragment
      }
    }
  }
  ${pageInfoFragment}
  ${activityLogFragment}
`;
