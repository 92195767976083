import { cloneElement, FC, ReactElement, useContext } from "react";
// Utils
import { DropdownMenuContext } from "../";

interface DropdownMenuTriggerProps {
  children: ReactElement;
}

export const DropdownMenuTrigger: FC<DropdownMenuTriggerProps> = ({ children }) => {
  const { handleToggle } = useContext(DropdownMenuContext);

  return cloneElement(children, {
    onClick: handleToggle,
  });
};
