// Components
import { InputSelect } from "components";
import React, { ChangeEvent } from "react";
import { useRecoilState } from "recoil";
import { liveweightSortServerSide } from "state";
// Hooks
import { ListingSortInstance } from "trading/hooks/useListingSort";
// Types
import { LiveweightSortValue, LIVEWEIGHT_SORT_OPTIONS } from "./constants";

interface ListingSortProps {
  instance: ListingSortInstance;
}

export const ListingSortServerSide: React.FC<ListingSortProps> = ({ instance }) => {
  const [sort, setSort] = useRecoilState(liveweightSortServerSide(instance));

  const handleChangeSort = (event: ChangeEvent<HTMLSelectElement>): void =>
    setSort(event.target.value as LiveweightSortValue);

  return (
    <InputSelect
      labelKey="label"
      name="liveweightSort"
      onChange={handleChangeSort}
      options={LIVEWEIGHT_SORT_OPTIONS}
      value={sort}
      valueKey="value"
    />
  );
};
