import * as React from "react";

import { Column } from "react-virtualized";

import pure from "recompose/pure";

import VirtualizedTable from "components/Common/VirtualizedTable";

import { getFormattedDate } from "helpers/general";

interface Props {
  data: Record<string, unknown>[];
}

const AnimalsWeightTable: React.FC<Props> = ({ data }) => (
  // tslint:disable-next-line: no-empty
  <VirtualizedTable data={data}>
    <Column
      label="Animal ID"
      dataKey="animal"
      width={3500}
      cellRenderer={({ cellData }): string => cellData.passportNumber || "\u2014"}
    />
    <Column
      label="Weight"
      dataKey="payload"
      width={3000}
      cellRenderer={({ cellData }): string => `${cellData.data[0].value || "\u2014"} ${cellData.data[0].unitName}`}
    />
    <Column
      label="Date recorded"
      dataKey="date"
      width={1500}
      cellRenderer={({ cellData }): string => getFormattedDate(cellData) || "\u2014"}
    />
  </VirtualizedTable>
);

export default pure(AnimalsWeightTable);
