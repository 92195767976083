import { gql } from "@apollo/client";
import { farmSystemFragment } from "api/Settings/fragment";
import { errorFragment } from "trading/api/fragment";

export const CREATE_FARM_SYSTEM = gql`
  mutation CreateFarmSystem($input: CreateFarmSystemInput!) {
    createFarmSystem(input: $input) {
      farmSystem {
        ...farmSystemFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${farmSystemFragment}
`;

export const UPDATE_FARM_SYSTEM = gql`
  mutation UpdateFarmSystem($input: UpdateFarmSystemInput!) {
    updateFarmSystem(input: $input) {
      farmSystem {
        ...farmSystemFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${farmSystemFragment}
`;

export const DELETE_FARM_SYSTEM = gql`
  mutation DeleteFarmSystem($input: DeleteFarmSystemInput!) {
    deleteFarmSystem(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const REQUEST_FOR_CTS_GENERATE_PDF = gql`
  mutation BcmsServiceRequestGeneratePdf($input: BcmsServiceRequestGeneratePdfInput!) {
    bcmsServiceRequestGeneratePdf(input: $input) {
      url
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const CREATE_BANK_DETAILS = gql`
  mutation CreateBankDetails($input: CreateBankDetailsInput!) {
    createBankDetails(input: $input) {
      bankDetails {
        id
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_BANK_DETAILS = gql`
  mutation UpdateBankDetails($input: UpdateBankDetailsInput!) {
    updateBankDetails(input: $input) {
      bankDetails {
        id
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_IDENTIFIER_PREFERENCES = gql`
  mutation UpdateIdentifierPreferences($input: UpdateIdentifierPreferencesInput!) {
    updateIdentifierPreferences(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
