import React, { MouseEvent } from "react";
import cn from "classnames";
// Styles
import styles from "./QuickActions.module.scss";

export type QuickActionItem = {
  label: string;
  onClick: () => void;
  type?: "standard" | "danger";
};

export interface QuickActionsProps {
  items: QuickActionItem[];
}

export const QuickActions: React.FC<QuickActionsProps> = ({ items = [] }) => {
  if (!items.length) return null;

  const handleItemClick = (event: MouseEvent<HTMLAnchorElement>, fn: () => void): void => {
    event.preventDefault();
    fn();
  };

  return (
    <ul className={styles.quickActions}>
      {items.map(({ label, onClick, type = "standard" }) => (
        <li key={label} className={styles.quickActions__item}>
          <a
            className={cn(styles.quickActions__link, styles[`quickActions__link--${type}`])}
            href="#"
            onClick={(event): void => handleItemClick(event, onClick)}
          >
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
};
