// React
import React from "react";
// Resources
import styles from "./RootMenu.module.scss";

interface IProps {
  children?: React.ReactNode;
}

export const RootMenu: React.FC<IProps> = ({ children }) => (
  <div className={styles.root_menu}>
    <div className={styles.root_menu__inner}>{children}</div>
  </div>
);
