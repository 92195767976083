// React
import React from "react";
// Libraries
import pure from "recompose/pure";
// Utils
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { handleEnterPress, handleErrorCheck } from "helpers/general";
// Components
import { FieldText, FormBuilder, Modal, Spacer, Text } from "components";

interface ConfirmAnimalActionModalProps {
  showModal: boolean;
  onClose: () => void;
  onConfirm: () => void;
  requesting: boolean;
  title: string;
  subTitle: string;
  error?: string;
  validationSchema: Record<string, unknown>;
}

const ConfirmAnimalActionModal: React.FC<ConfirmAnimalActionModalProps> = ({
  error,
  title,
  subTitle,
  showModal,
  onClose,
  requesting,
  onConfirm,
  validationSchema,
}) => (
  <FormBuilder
    validateOnChange={false}
    initialValues={{
      confirmation: "",
    }}
    onSubmit={onConfirm}
    validationSchema={validationSchema}
  >
    {({ values, errors, handleChange, handleSubmit }): React.ReactNode => (
      <Modal
        actions={{
          primary: {
            id: ELEMENTS_IDS.SIRE_ACTION_MODAL_CONFIRM_BUTTON,
            caption: "Confirm",
            disabled: requesting,
            requesting: requesting,
            onClick: handleSubmit,
          },
          secondary: {
            id: ELEMENTS_IDS.SIRE_ACTION_MODAL_CANCEL_BUTTON,
            caption: "Cancel",
            colour: "grey",
            variant: "hollow",
            onClick: onClose,
          },
        }}
        active={showModal}
        handleClose={onClose}
        subTitle={subTitle}
        title={title}
      >
        <FieldText
          error={handleErrorCheck(errors.confirmation)}
          inputProps={{
            name: "confirmation",
            defaultValue: values.confirmation,
            autoFocus: true,
            onChange: handleChange,
            onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
          }}
        />

        {!!error ? (
          <>
            <Spacer baselineHeight={2} />

            <Text smallest warning>
              {error}
            </Text>
          </>
        ) : null}
      </Modal>
    )}
  </FormBuilder>
);

export default pure(ConfirmAnimalActionModal);
