import React from "react";

import pure from "recompose/pure";

import { Title } from "components";
import Notification from "./Notification";

import styles from "./Notifications.module.scss";

interface NotificationsProps {
  notifications: Array<{
    avatar?: string;
    title: string;
    description: string;
    date: string;
    read: boolean;
  }>;
}
const Notifications = pure(({ notifications }: NotificationsProps) => (
  <div className={styles.notifications}>
    <Title className={styles.notifications__title}>Notifications</Title>
    {notifications.map((notification) => (
      <Notification {...notification} key={notification.title} />
    ))}
  </div>
));
Notifications.displayName = "Notifications";

export default Notifications;
