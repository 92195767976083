import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import {
  GetMedicineActivities_itemTransactions_edges,
  GetMedicineActivities,
  GetMedicineActivitiesVariables,
} from "api/Medicine/types/GetMedicineActivities";
import { getBUFromLocalStorage } from "helpers/storage";
import { BatchActivity } from "./BatchActivity";
import { Button, Flex, Spacer, Spinner, Text } from "components";
import { useFormatDate } from "hooks";
import { GET_MEDICINE_ACTIVITIES } from "medicines/api/queries";

interface BatchActivitiesProps {
  batchNumber?: string;
}

export const BatchActivities: React.FC<BatchActivitiesProps> = ({ batchNumber }) => {
  const [activities, setActivities] = useState<(GetMedicineActivities_itemTransactions_edges | null)[]>([]);

  const { id: medicineId } = useParams<MedicinePageParams>();

  const defaultQueryVariables = {
    businessUnitId: getBUFromLocalStorage(),
    stockItemId: medicineId,
    batchNumber: batchNumber,
    first: 10,
    after: "",
  };
  const [getMedicineActivities, { data, loading }] = useLazyQuery<
    GetMedicineActivities,
    GetMedicineActivitiesVariables
  >(GET_MEDICINE_ACTIVITIES, {
    variables: defaultQueryVariables,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    onCompleted: (data) => {
      const newActivities = data?.itemTransactions?.edges || [];

      setActivities([...activities, ...newActivities]);
    },
  });

  const pageInfo = data?.itemTransactions?.pageInfo;
  const { getFormatDate } = useFormatDate();

  useEffect(() => {
    setActivities([]);
    getMedicineActivities();
  }, [batchNumber, getMedicineActivities]);

  const handleLoadMore = (): void => {
    getMedicineActivities({
      variables: {
        ...defaultQueryVariables,
        after: pageInfo?.endCursor,
      },
    });
  };

  let previousDate = DateTime.local();

  const activitiesWithDates = activities.map((activity) => {
    let newDate = null;

    if (!DateTime.fromISO(activity?.node?.date).hasSame(previousDate, "day")) {
      newDate = activity?.node?.date;
      previousDate = activity?.node?.date;
    }

    return (
      <React.Fragment key={`medicine_activity_${activity?.node?.id}`}>
        {newDate ? (
          <>
            <Text>
              <strong>{getFormatDate(newDate, DateTime.DATE_HUGE)}</strong>
            </Text>

            <Spacer baselineHeight={2} />
          </>
        ) : null}

        <BatchActivity data={activity?.node} />

        <Spacer baselineHeight={2} />
      </React.Fragment>
    );
  });

  if (!batchNumber) return null;

  if (loading && activities.length === 0) {
    return (
      <Flex container containerJustifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (activities.length === 0) {
    return <Text>There is no activity to display for batch {batchNumber}.</Text>;
  }

  return (
    <>
      {activitiesWithDates}

      {pageInfo?.hasNextPage ? (
        <Flex container containerJustifyContent="center">
          <Spacer baselineHeight={2} />

          {loading ? (
            <Spinner />
          ) : (
            <Button caption="Load more activities" colour="grey" onClick={handleLoadMore} variant="hollow" />
          )}
        </Flex>
      ) : null}
    </>
  );
};
