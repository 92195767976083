import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
// Helpers
import { FORGOT_PASSWORD } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
// Resources
import styles from "./LinkForgottenPassword.module.scss";

interface LinkForgottenPasswordProps {
  customTextStyle?: string;
}

export const LinkForgottenPassword: React.FC<LinkForgottenPasswordProps> = ({ customTextStyle }) => (
  <div className={styles.text}>
    <Link id={ELEMENTS_IDS.FORGOT_PASSWORD_LINK} to={FORGOT_PASSWORD} className={cn(styles.link, customTextStyle)}>
      Forgotten your password?
    </Link>
  </div>
);
