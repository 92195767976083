import { FieldSelect } from "components/Common/Field/Select";
import { ContactFragmentFragment } from "generated/graphql";
import { useContacts } from "settings/hooks/useContacts";

type Props = {
  value: string;
  onContactChange: (contact: ContactFragmentFragment | undefined) => void;
  label?: string;
  errorMessage?: string;
  disabled?: boolean;
  contactType?: string;
  onCreateNewContact?: () => void;
  className?: string;
  placeholder?: string;
};

const CREATE_NEW_CONTACT = "createNewContact";

export const AddressBookSelect: React.FC<Props> = ({
  onCreateNewContact,
  onContactChange,
  value,
  label,
  errorMessage,
  disabled,
  contactType,
  className,
  placeholder,
}) => {
  const { contactOptions, contacts, loading } = useContacts(contactType);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    if (onCreateNewContact && e.target.value === CREATE_NEW_CONTACT) {
      onCreateNewContact();
    }

    const selectedContact = contacts.find((item) => item.id === e.target.value);
    onContactChange(selectedContact);
  };

  return (
    <FieldSelect
      label={label}
      error={errorMessage}
      className={className}
      inputProps={{
        name: "contact",
        placeholder: placeholder || "Select from Address Book",
        value: value,
        options: onCreateNewContact
          ? [{ key: CREATE_NEW_CONTACT, value: CREATE_NEW_CONTACT, label: "Create New Contact" }, ...contactOptions]
          : contactOptions,
        onChange: handleChange,
        isClearable: true,
        disabled: loading || disabled,
      }}
    />
  );
};
