import { getTokenFromLocalStorage } from "helpers/storage";
import { useGetUserQuery } from "generated/graphql";

export const useGetCurrentUserInfo = () => {
  const token = getTokenFromLocalStorage();
  const { data, ...rest } = useGetUserQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    skip: !token,
  });

  return {
    data,
    ...rest,
  };
};
