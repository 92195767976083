import { BU_ID, CREATE_MOVE_OFF, CREATE_MOVE_ON, DASHBOARD, DELIVERIES, DELIVERY } from "constants/Routes";
import { CreateDelivery } from "deliveries/pages/CreateDelivery";
import { Deliveries } from "deliveries/pages/Deliveries";
import { Delivery } from "deliveries/pages/Delivery";
import { useHasFeature } from "hooks/useHasFeature";
import { Redirect, Route, Switch } from "react-router-dom";

const ROUTES_PATHS = {
  Deliveries: `${BU_ID}${DELIVERIES}/:deliveriesTabType(move-on|move-off)?/:deliveriesSubTabType?`,
  Delivery: `${BU_ID}${DELIVERIES}${DELIVERY}`,
  CreateMoveOff: `${BU_ID}${DELIVERIES}${CREATE_MOVE_OFF}/:deliveryId?`,
  CreateMoveOn: `${BU_ID}${DELIVERIES}${CREATE_MOVE_ON}/:deliveryId?`,
} as const;

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

export const DeliveriesRoutes = () => {
  const showMoveOn = useHasFeature("CREATE_INWARD_MOVEMENTS");

  return (
    <Route exact path={PATHS}>
      <Switch>
        {showMoveOn ? <Route exact path={ROUTES_PATHS.CreateMoveOn} component={CreateDelivery} /> : null},
        <Route exact path={ROUTES_PATHS.CreateMoveOff} component={CreateDelivery} />,
        <Route exact path={ROUTES_PATHS.Delivery} component={Delivery} />,
        <Route exact path={ROUTES_PATHS.Deliveries} component={Deliveries} />,
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};
