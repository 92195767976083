import { useEffect, FC } from "react";
import { useHistory } from "react-router-dom";
import { UserInfoFragmentFragment, useGetLandingReportLazyQuery, useHasUnsyncedAnimalsQuery } from "generated/graphql";
import { ANIMALS } from "constants/Routes";
import { BU_TYPES } from "trading/constants";
import { getBUFromLocalStorage, getBUTypeFromLocalStorage } from "helpers/storage";
import { useHasFeature, useStateSpecies } from "hooks";
import { FilterAlerts } from "containers/home";
import { Alert, Flex, LoadingOverlay, Header, PageContent, PageHeader, Spacer } from "components";
import { AWSQuickSightDashboard } from "components/Reports/AWSQuickSightDashboard";
import styles from "./Home.module.scss";
import { BreedrReports } from "reports/pages/FarmerReports";
import { useAnimalFilters } from "hooks/useAnimalFilters";
import { useShouldUseServerPaginatedAnimalsTable } from "hooks/useShouldUseServerPaginatedAnimalsTable";

interface HomeProps {
  user?: UserInfoFragmentFragment | null;
}

export const Home: FC<HomeProps> = ({ user }) => {
  const { activeSpecies, speciesList, handleSetActiveSpecies } = useStateSpecies();

  const isShowReportsOnHomePage: boolean = useHasFeature("SHOW_REPORT_ON_HOME_PAGE");
  const isFilterAlertsVisible: boolean = useHasFeature("SAVE_ANIMAL_FILTER_AS_ALERT");
  const isShowOfflineReportPage: boolean = useHasFeature("SHOW_OFFLINE_PAGE_FOR_REPORTS");

  const userName: string = user?.firstName || "user";
  const isFarmer: boolean = getBUTypeFromLocalStorage() === BU_TYPES.farmer;
  const history = useHistory();
  const currentBU = getBUFromLocalStorage();

  const [getReport, { loading: reportLoading, data: reportData }] = useGetLandingReportLazyQuery({
    variables: {
      businessUnit: currentBU,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  useEffect(() => {
    if (currentBU && !isShowOfflineReportPage) {
      getReport();
    }
  }, [currentBU, isShowOfflineReportPage]);

  const { data, loading } = useHasUnsyncedAnimalsQuery({
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    skip: !isFarmer,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const { shouldUseServerPaginatedAnimalsTable } = useShouldUseServerPaginatedAnimalsTable();
  const { handleShowAllFilters, handleApplyFilter } = useAnimalFilters("animalsList");

  const handleUnsyncedAnimalsView = (): void => {
    if (activeSpecies && activeSpecies.slug !== "cattle") {
      const cattleSpecies = speciesList?.find((species) => species.slug === "cattle");
      if (cattleSpecies) handleSetActiveSpecies(cattleSpecies);
    }

    if (shouldUseServerPaginatedAnimalsTable) {
      handleShowAllFilters();
      handleApplyFilter({ isRegulatorySynced: false });

      history.push(ANIMALS);
      return;
    }

    // when we call `setSpeciesActive` is triggers the animals filters to reset
    // if we navigate too quickly this means that any animals filters that we set here are immediately reset.
    // to avoid this we use this hacky timeout
    setTimeout(() => {
      history.push(ANIMALS, {
        animalsFilter: { isRegulatorySynced: "notSynced" },
      });
    }, 50);
  };

  const renderCorrectReportType = () => {
    if (isShowReportsOnHomePage && reportData?.mainReport?.url && !reportLoading && !isShowOfflineReportPage) {
      if (reportData?.mainReport?.reportType == "QUICKSIGHT") {
        return <AWSQuickSightDashboard quickSightUrl={reportData?.mainReport?.url} />;
      } else if (reportData?.mainReport?.reportType == "SISENSE") {
        return <iframe src={reportData?.mainReport.url} width="100%" height="850px" title="Report" frameBorder="0" />;
      } else {
        return <BreedrReports reportType={reportData?.mainReport?.url}></BreedrReports>;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <PageHeader>
        <Header title="Dashboard" subTitle={`Hello ${userName}, welcome to your dashboard.`} />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={2} />

        {renderCorrectReportType()}
        {data?.hasUnsyncedAnimals && isFarmer ? (
          <>
            <Spacer baselineHeight={1} />

            <Flex container>
              <Flex item itemGutter xs={12}>
                <Alert
                  buttonText="View animals"
                  categoryPillText="Cattle"
                  color="RED"
                  onClick={handleUnsyncedAnimalsView}
                  text="Your Herdbook needs updating - "
                />
              </Flex>
            </Flex>
          </>
        ) : null}
        {isFilterAlertsVisible ? <FilterAlerts /> : null}
        {isShowOfflineReportPage ? (
          <Flex container>
            <Flex item itemGutter xs={12}>
              <Spacer baselineHeight={2} />
              <h3>Report is temporarily unavailable. We are working to resolve the issue</h3>
            </Flex>
          </Flex>
        ) : null}
        {loading || reportLoading ? <LoadingOverlay customStyles={styles.loadingOverlay} /> : null}
      </PageContent>
    </>
  );
};
