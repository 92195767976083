import React from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { getBUFromLocalStorage } from "helpers/storage";
import { MEDICINE_CABINET } from "constants/Routes";
import { MedicineInfoBlock } from "medicines/components/MedicineInfoBlock";
import { MedicineTabsBlock } from "medicines/components/MedicineTabsBlock";
import { Breadcrumbs, LoadingOverlay, Header, PageHeader, PageContent, Spacer } from "components";
import { useGetStockItemQuery } from "generated/graphql";

export const MedicineDetails: React.FC = () => {
  const { id } = useParams<MedicinePageParams>();
  const { data, error, loading } = useGetStockItemQuery({
    variables: { id, businessUnitId: getBUFromLocalStorage() },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  if (error) {
    return <Redirect to={MEDICINE_CABINET} />;
  }

  return loading ? (
    <LoadingOverlay />
  ) : (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Medicine Cabinet",
              route: MEDICINE_CABINET,
            },
          ]}
          currentPath="Medicine details"
        />
        <Header backButton title={data?.stockItem?.name ?? ""} />
        {data?.stockItem ? <MedicineInfoBlock medicine={data?.stockItem} /> : null}
        <Spacer baselineHeight={1} />
      </PageHeader>
      {data?.stockItem?.lineItems && data?.stockItem?.lineItems?.length > 0 ? (
        <PageContent>
          <Spacer baselineHeight={3} />
          <MedicineTabsBlock lineItems={data?.stockItem?.lineItems} />
          <Spacer baselineHeight={2} />
        </PageContent>
      ) : null}
    </>
  );
};
