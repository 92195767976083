import { gql } from "@apollo/client";
import { reportsFragment } from "api/Reports/fragment";

export const GET_REPORTS_LIST = gql`
  query GetReportsList($businessUnit: Int, $species: Int) {
    reports(businessUnit: $businessUnit, species: $species) {
      ...reportsFragment
    }
  }
  ${reportsFragment}
`;

export const GET_REPORT_URL = gql`
  query GetReportUrl($embedUrl: String!) {
    sisenseEmbedUrl(embedUrl: $embedUrl) {
      url
    }
  }
`;

export const GET_MAIN_REPORT = gql`
  query GetMainReport($businessUnit: Int!) {
    mainReport(businessUnit: $businessUnit) {
      id
      title
      url
    }
  }
`;

export const GET_QUICK_SIGHT_REPORTS = gql`
  query getQuicksightEmbedUrl($businessUnit: Int!, $embedUrl: String!) {
    quicksightEmbedUrl(businessUnit: $businessUnit, embedUrl: $embedUrl) {
      url
      parameters {
        code
        value
      }
    }
  }
`;
