// API
import { MyLots_myLots_edges_node } from "trading/api/Liveweight/types/MyLots";
import { BuyingLots_buyingLots_edges_node } from "trading/api/Liveweight/types/BuyingLots";

const LIVEWEIGHT_SORT_VALUES = [
  "startDateDesc",
  "animalsCountDesc",
  "animalsCountAsc",
  "totalPriceDesc",
  "totalPriceAsc",
  "distanceAsc",
] as const;

export type LiveweightSortValue = typeof LIVEWEIGHT_SORT_VALUES[number];

export interface LiveweightSortOption {
  label: string;
  order: string;
  property: keyof MyLots_myLots_edges_node | keyof BuyingLots_buyingLots_edges_node;
  value: LiveweightSortValue;
}

export const LIVEWEIGHT_SORT_OPTIONS: LiveweightSortOption[] = [
  {
    label: "Newest to oldest",
    order: "DESC",
    property: "startDate",
    value: "startDateDesc",
  },
  {
    label: "Closest to me",
    order: "ASC",
    property: "distance",
    value: "distanceAsc",
  },
  {
    label: "Number of animals (high to low)",
    order: "DESC",
    property: "animalsCount",
    value: "animalsCountDesc",
  },
  {
    label: "Number of animals (low to high)",
    order: "ASC",
    property: "animalsCount",
    value: "animalsCountAsc",
  },
  {
    label: "Total asking price (high to low)",
    order: "DESC",
    property: "totalPrice",
    value: "totalPriceDesc",
  },
  {
    label: "Total asking price (low to high)",
    order: "ASC",
    property: "totalPrice",
    value: "totalPriceAsc",
  },
];
