import { useMemo } from "react";
// API
import { MyLots_myLots_edges_node } from "trading/api/Liveweight/types/MyLots";
// Hooks
import { useGetMyLots, useListingFilters, useListingSort } from "hooks";

interface UseFilteredSellerListingsReturn {
  listings: MyLots_myLots_edges_node[] | null;
  loading: boolean;
  quantity: number;
}

export const useFilteredSellerListings = (): UseFilteredSellerListingsReturn => {
  const { apply: applyFilter } = useListingFilters<MyLots_myLots_edges_node>("seller");
  const { apply: applySort } = useListingSort<MyLots_myLots_edges_node>("seller");

  const { data: listings, loading } = useGetMyLots();

  const narrowedListings = useMemo(() => {
    if (!listings) return null;

    return listings.filter(applyFilter).sort(applySort);
  }, [applyFilter, applySort, listings]);

  const quantity = narrowedListings?.length || 0;

  return {
    listings: narrowedListings,
    loading,
    quantity,
  };
};
