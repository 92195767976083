import { PageHeader } from "components/Structure/PageHeader";
import { PageContent } from "components/Structure/PageContent";
import { Header } from "components/Common/Header";
import { Spacer, Text } from "components";
import { Breadcrumbs } from "components/Common/Breadcrumbs";
import { MoveOffDeliveryForm } from "deliveries/components/MoveOffDeliveryForm";
import { MoveOnDeliveryForm } from "deliveries/components/MoveOnDeliveryForm";
import { useHistory, useParams } from "react-router-dom";
import { CREATE_MOVE_ON } from "constants/Routes";

export const CreateDelivery = ({ location }) => {
  const { deliveryId } = useParams<CreateDeliveryPageParams>();
  const history = useHistory();

  const isMoveOn = history.location.pathname.includes(`${CREATE_MOVE_ON}`);

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[{ path: "Movement Dashboard", route: location?.state?.prevUrl || "" }]}
          currentPath={isMoveOn ? "Move On" : "Move Off"}
        />
        <Header
          title={isMoveOn ? "Create Move On" : "Create Move Off"}
          subTitle={
            <div style={{ width: "max-content" }}>
              <Text>Make edits and save your changes until you click on the submit button.</Text>
            </div>
          }
        />
      </PageHeader>
      <PageContent>
        <Spacer baselineHeight={2} />
        {isMoveOn ? <MoveOnDeliveryForm deliveryId={deliveryId} /> : <MoveOffDeliveryForm deliveryId={deliveryId} />}
      </PageContent>
    </>
  );
};
