export const BUSINESS_LOCATIONS_TABLE_COLUMNS = [
  {
    key: 0,
    name: "Location name",
    value: "unitName",
  },
  {
    key: 1,
    name: "Location type",
    value: "locationType",
  },
  {
    key: 2,
    name: "Address",
    value: "addressLine1",
  },
  {
    key: 3,
    name: "Action",
  },
];

export const LOCATION_TYPES = [
  {
    key: 0,
    label: "Farm",
    value: "FARM",
  },
  {
    key: 1,
    label: "Abattoir",
    value: "ABATTOIR",
  },
];

export const BUSINESS_UNIT_TB_STATUSES = {
  ENGLAND_EDGE_AREA_SIX_MONTH: "England - Edge Area (six month)",
  ENGLAND_EDGE_AREA_ANNUAL: "England - Edge Area (annual)",
  ENGLAND_HIGH_RISK_AREA: "England - High Risk Area",
  ENGLAND_LOW_RISK_AREA: "England - Low Risk Area",
  SCOTLAND_ISLE_OF_MAN_OTF: "Scotland & Isle of Man - OTF (Officially TB Free)",
  WALES_HIGH_TB: "Wales - High TB",
  WALES_INTERMEDIATE_TB: "Wales - Intermediate TB",
  WALES_LOW_TB: "Wales - Low TB",
};

export const CONFIRMATION_MODALS = {
  RESET_PASSWORD: "reset_password",
  SUSPEND_ACCOUNT: "suspend_account",
  DELETE_USER: "delete_user",
  RESENT_INVITE: "resent_invite",
};

export const INTEGRATION_SYSTEMS_CONNECTING_STATUSES = {
  PENDING: "PENDING",
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  CONNECT_FAILED: "CONNECT_FAILED",
};

export const UPLOAD_GRID_FILE_FORMATS = [".pdf", ".doc", ".docx", ".jpg", ".xls", ".xlsx"];

export const USER_ROLES = {
  ADMIN: "Admin",
  MANAGER: "Manager",
};

export const CSV_FILE_FORMAT = ".csv";
export const XLS_FILE_FORMAT = ".xls";
export const PDF_FILE_FORMAT = ".pdf";
