import React, { useEffect, useState } from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./Image.module.scss";

interface ImageAspectRatio {
  horizontal: number;
  vertical: number;
}

export interface ImageProps {
  // Chris @ 23 Nov 2020: "alt" should be required, omitting for now for ease of implementation
  alt?: string;
  aspectRatio?: ImageAspectRatio;
  src: string;
  // Chris @ 23 Nov 2020: "title" should be required, omitting for now for ease of implementation
  title?: string;
  style?: React.CSSProperties | undefined;
}

export const Image: React.FC<ImageProps> = ({ alt, aspectRatio, src, title, style }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleImageLoaded = (): void => setIsLoading(false);

  useEffect(() => {
    setIsLoading(true);
  }, [src]);

  const aspectRatioStyle = aspectRatio
    ? {
        paddingTop: `${(aspectRatio.vertical / aspectRatio.horizontal) * 100}%`,
      }
    : undefined;

  return (
    <figure className={cn(styles.image, { [styles["image--aspect_ratio"]]: aspectRatio })} style={aspectRatioStyle}>
      <img alt={alt} onLoad={handleImageLoaded} src={src} title={title} style={style} />

      {isLoading ? <span className={styles.image__fake} title="Image loading" /> : null}
    </figure>
  );
};
