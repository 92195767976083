import { useGetBusinessUnitsQuery } from "generated/graphql";

export const useGetBusinessUnits = () => {
  const { data, loading, ...rest } = useGetBusinessUnitsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const businessUnits = data?.businessUnits || [];

  return {
    data: businessUnits,
    loading,
    ...rest,
  };
};
