import * as React from "react";

import debounce from "lodash/debounce";

import pure from "recompose/pure";

import { AutoSizer, Table } from "react-virtualized";

import styles from "./VirtualizedTable.module.scss";

interface Props {
  data: Record<string, unknown>[];
  children: React.ReactNode;
  selectedAll?: boolean;
  hasNextPage?: boolean;
  tableDisabled?: boolean;
  selectedAnimals?: string[];
  loadMore?: () => void;
  onTableScroll?: () => void;
  onRowClick?: (id: string) => void;
  sortBy?: string;
  sortDirection?: string;
  sort?: (sortBy: string, sortDirection: string) => void;
  height?: number;
}

const rowClassName = (index, data, selectedAll, selectedAnimals, tableDisabled): string => {
  if (index < 0) {
    return styles.headerRow;
  }

  if ((selectedAnimals && selectedAnimals.includes(data[index].id)) || selectedAll || tableDisabled) {
    return styles.selectedRow;
  }

  return styles.evenRow;
};

export const TABLE_HEADER_HEIGHT = 55;
export const ROW_HEIGHT = 40;

const VirtualizedTable: React.FC<Props> = ({
  data,
  children,
  selectedAll,
  loadMore,
  selectedAnimals,
  hasNextPage,
  sortBy,
  sort,
  height: customHeight,
  sortDirection,
  onRowClick,
  onTableScroll,
  tableDisabled,
}) => (
  <AutoSizer>
    {({ height, width }): React.ReactNode => (
      <Table
        // tslint:disable-next-line: no-empty
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onHeaderClick={(): void => {}}
        onScroll={onTableScroll}
        sort={(val): void => sort && sort(val.sortBy, val.sortDirection)}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onRowClick={({ rowData }): void => onRowClick && onRowClick(rowData)}
        headerHeight={TABLE_HEADER_HEIGHT}
        height={customHeight || height}
        rowHeight={ROW_HEIGHT}
        rowGetter={({ index }): Record<string, unknown> => data[index]}
        rowCount={data.length}
        width={width}
        onRowsRendered={debounce(({ stopIndex }) => {
          if (stopIndex === data.length - 1 && loadMore && hasNextPage) {
            loadMore();
          }
        }, 100)}
        headerClassName={styles.headerColumn}
        rowClassName={({ index }): string => rowClassName(index, data, selectedAll, selectedAnimals, tableDisabled)}
      >
        {children}
      </Table>
    )}
  </AutoSizer>
);

export default pure(VirtualizedTable);
