import { ImportTypes } from "import/containers";
import { Header, PageContent, PageHeader, Spacer } from "components";

export const Import = () => (
  <>
    <PageHeader>
      <Header title="Import livestock data" />
    </PageHeader>

    <PageContent>
      <Spacer baselineHeight={3} />

      <ImportTypes />
    </PageContent>
  </>
);
