import { gql } from "@apollo/client";

export const reportsFragment = gql`
  fragment reportsFragment on ReportType {
    id
    businessUnit {
      id
    }
    title
    slug
    applyProducerGroup
    url
    position
    needUpgrade
    group {
      id
      title
      position
    }
    subscriptionPlans {
      id
      name
      price
      description
    }
    species {
      id
    }
    reportType
  }
`;
