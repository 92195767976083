// helpers
import { normaliseUnit } from "../utils";
import { getFormattedNumber } from "helpers/general";

export interface UnitConversion {
  fromQty: string;
  fromUnit: string;
  toQty: string;
  toUnit: string;
}

/**
 * @description does some conversion jiggery pokery
 * @param unit the unit selected in UI
 * @param dosage the amount being administered
 * @returns a conversion object for use in UI
 */
export const getConversion = (unit: string, dosage: string | number): UnitConversion | null => {
  if (!unit || !dosage) return null;

  const dosageNum = Number(dosage);

  switch (normaliseUnit(unit)) {
    case "l":
    case "litre":
    case "liter":
    case "litres":
    case "liters":
      return {
        fromQty: getFormattedNumber(dosageNum),
        fromUnit: "litre",
        toQty: getFormattedNumber(dosageNum * 1000),
        toUnit: "ml",
      };

    case "ml":
      return {
        fromQty: getFormattedNumber(dosageNum),
        fromUnit: "ml",
        toQty: getFormattedNumber(dosageNum / 1000),
        toUnit: "litre",
      };

    case "kg":
    case "kilogram":
    case "kilograms":
      return {
        fromQty: getFormattedNumber(dosageNum),
        fromUnit: "kg",
        toQty: getFormattedNumber(dosageNum * 1000),
        toUnit: "g",
      };

    case "g":
      return {
        fromQty: getFormattedNumber(dosageNum),
        fromUnit: "g",
        toQty: getFormattedNumber(dosageNum / 1000),
        toUnit: "kg",
      };

    default:
      return null;
  }
};
