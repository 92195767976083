import React from "react";

import pure from "recompose/pure";

import Select, { components } from "react-select";

import { SelectArrow } from "constants/SvgIcons";

import style from "./styles.module.css";

interface Props {
  placeholder: string;
  inputValue: string;
  onOptionSelect: () => void;
  options: Array<{
    label: string;
    value: string;
  }>;
  onInputChange: (value: string) => void;
  error?: string;
}

const colourStyles = {
  container: (styles): Record<string, unknown> => ({
    ...styles,
    backgroundColor: "#fafafa",
    borderColor: "#DADADA",
    zIndex: 2,
  }),
  control: (styles, props): Record<string, unknown> => ({
    ...styles,
    backgroundColor: "#fafafa",
    borderColor: props.selectProps.error ? "red" : "#DADADA",
    boxShadow: "none",
    ":hover": {
      borderColor: "#F2C434",
      borderRadius: 4,
      boxShadow: "0 0 0 2px rgba(242, 196, 52, 0.25)",
      transition: "box-shadow 50ms ease-out",
      cursor: "pointer",
    },
    ":focus": {
      borderColor: "#F2C434",
      borderRadius: 4,
      boxShadow: "0 0 0 2px rgba(242, 196, 52, 0.25)",
      outline: "0px",
    },
  }),
  multiValue: (styles): Record<string, unknown> => ({
    ...styles,
    backgroundColor: "#F2C434",
    borderRadius: "2px",
  }),
  multiValueLabel: (styles): Record<string, unknown> => ({
    ...styles,
    color: "black",
  }),
  multiValueRemove: (styles, { data: { isFixed } }): Record<string, unknown> => {
    return {
      ...styles,
      ...(isFixed && {
        display: "none",
      }),
      ":hover": {
        backgroundColor: "#F2C434",
        color: "white",
        borderRadius: "2px",
      },
    };
  },
  option: (styles, { isFocused, isSelected }): Record<string, unknown> => ({
    ...styles,
    backgroundColor: isFocused || isSelected ? "#F2C434" : null,
    ":active": {
      ...styles[":active"],
      backgroundColor: "#F2C434",
    },
  }),
  indicatorSeparator: (styles): Record<string, unknown> => ({
    ...styles,
    margin: 0,
  }),
  dropdownIndicator: (styles): Record<string, unknown> => ({
    ...styles,
    color: "#23354B",
    ":hover": {
      ...styles[":hover"],
      color: "#23354B",
    },
  }),
};

const DropdownIndicator = (props): React.ReactNode => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>{SelectArrow}</components.DropdownIndicator>
    )
  );
};

const InputSelect: React.FC<Props> = ({
  placeholder,
  options,
  inputValue,
  onInputChange,
  onOptionSelect,
  error,
  ...props
}) => (
  <div>
    <Select
      options={options}
      inputValue={inputValue}
      placeholder={placeholder}
      onChange={onOptionSelect}
      onInputChange={onInputChange}
      styles={colourStyles}
      error={error}
      components={{
        DropdownIndicator,
      }}
      {...props}
    />
    {error && error.length > 0 ? <div className={style.errorText}>{error}</div> : null}
  </div>
);

export default pure(InputSelect);
