import { useDispatch, useSelector } from "react-redux";
import { resetSpeciesList, setSpeciesList, setActiveSpecies, Species } from "state/speciesSlice";
import { RootState } from "state/store";

export const useStateSpecies = () => {
  const dispatch = useDispatch();

  const { speciesList, activeSpecies } = useSelector((state: RootState) => state.species);

  const handleResetSpeciesList = () => dispatch(resetSpeciesList);
  const handleSetSpeciesList = (speciesList: Species[] | undefined) => dispatch(setSpeciesList(speciesList));
  const handleSetActiveSpecies = (species: Species | undefined) => dispatch(setActiveSpecies(species));

  const speciesCount = speciesList?.length || 0;

  return {
    activeSpecies,
    speciesCount,
    speciesList,
    handleResetSpeciesList,
    handleSetActiveSpecies,
    handleSetSpeciesList,
  };
};
