import React from "react";
// Libraries
import cn from "classnames";
import { isFirefox } from "react-device-detect";
// Resources
import styles from "./Root.module.scss";

interface RootProps {
  menu?: JSX.Element;
  children?: React.ReactNode;
}

export const Root: React.FC<RootProps> = ({ children, menu }) => (
  <div
    className={cn(styles.root, {
      [styles["root--has_menu"]]: menu,
      firefox: isFirefox,
    })}
  >
    {menu}
    <div className={styles.root__inner}>{children}</div>
  </div>
);
Root.displayName = "Root";
