import { ContactContactType } from "generated/graphql";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import * as yup from "yup";

const contactTypes = Object.values(ContactContactType);

export const contactValidationSchema = yup.object({
  countryCode: yup.string(),
  contactType: yup.string().oneOf(contactTypes).required(),
  businessName: yup.string().max(60, "Business name must be max 60 characters.").required("Business name is required."),
  farmId: yup
    .string()
    .max(30, "Max 30 characters")
    .when(["countryCode", "contactType"], {
      is: (countryCode: string, contactType: string) => {
        return contactType === ContactContactType.Farm && countryCode === CountryISOCode.GB;
      },
      then: yup.string().matches(/[0-9]{2}\/[0-9]{3}\/[0-9]{4}/, {
        message: "Must be format 11/111/1111",
        excludeEmptyString: true,
      }),
    }),
  addressLine1: yup
    .string()
    .max(300, "Address must be max 300 characters.")
    .when("countryCode", {
      is: (countryCode: string) => {
        return countryCode.toUpperCase() === CountryISOCode.GB;
      },
      then: yup.string().required("Address line 1 is required."),
    }),
  addressLine2: yup.string().max(300, "Address must be max 300 characters."),
  city: yup.string().max(40, "City must be max 40 characters."),
  postcode: yup.string().max(20, "Post code must be max 20 characters."),
  county: yup.string().max(40, "Post code must be max 40 characters."),
  state: yup.string().when("countryCode", {
    is: (countryCode: string) => {
      return countryCode.toUpperCase() === CountryISOCode.US || countryCode.toUpperCase() === CountryISOCode.AU;
    },
    then: yup.string().required("State is required."),
  }),
  countryId: yup.string().required("Country is required"),
  contactName: yup.string().max(300, "Contact name must be max 300 characters"),
  phoneNumber: yup.string().when(["countryCode"], (code: string) => {
    // @ts-expect-error
    return yup.string().phone({ countryCode: code.toUpperCase(), isRequired: false });
  }),
  email: yup.string().email("Email is invalid").max(100, "Email must be max 100 characters"),
  code: yup.string().max(20, "Code must be max 20 characters"),
});
