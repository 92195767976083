import { AnimalFragment } from "generated/graphql";
import { CATTLE_ID } from ".";
import { AnimalSchema } from "./validation";

export const generateDefaultAnimalFormValues = ({
  animal,
  isoPrefix,
}: {
  animal?: AnimalFragment | null;
  isoPrefix: string;
}): AnimalSchema => ({
  breeds: animal?.animalBreeds?.[0]?.id || "",
  damId: animal?.damId || undefined,
  dateMovedToFarm: animal?.dateMovedToFarm ? new Date(animal?.dateMovedToFarm) : new Date(),
  dob: animal?.dob ? new Date(animal?.dob) : new Date(),
  eId: animal?.animalType?.id !== CATTLE_ID ? removeIsoPrefixFromEid(animal?.eId || "", isoPrefix) : animal?.eId || "",
  fieldId: animal?.field?.id || "",
  groupId: animal?.group?.id || "",
  animalSex: animal?.isMale === true ? "male" : animal?.isMale === false ? "female" : "male",
  sireId: animal?.linkToMySire?.id || undefined,
  passportNumber: animal?.passportNumber || "",
  visualId: animal?.visualId || "",
  purchasePrice: animal?.latestPurchasePrice ? `${animal.latestPurchasePrice}` : "",
  isCastrated: animal?.isCastrated || false,
  birthWeight: animal?.birthInformation?.birthWeight ? `${animal?.birthInformation?.birthWeight}` : "",
  isDead: false,
  animalConditions: [],
  tagIds: animal?.tags.map((tag) => tag.tagId) || [],
  // NOTE: SourceContactId is not exposed on the animal.
  // We fall back to the contact ID on the previous keeper field
  // for now.
  sourceContactId: animal?.previousKeeper?.contact?.id || "",
  // INFO: Extended animal IDs
  brucellosisId: animal?.brucellosisId || "",
  pedigreeId: animal?.pedigreeId || "",
  tattoo: animal?.tattoo || "",
  trichId: animal?.trichId || "",
  tsuBarcode: animal?.tsuBarcode || "",
  herdDoggId: animal?.herdDoggId || "",
  uhfId: animal?.uhfId || "",
  alternativeId: animal?.alternativeId || "",
  name: animal?.name || "",
});

export const sanitiseAnimalId = (animalId: string | undefined) => {
  if (!animalId) {
    return undefined;
  }

  return animalId.replace(/\s+/g, "").toUpperCase();
};

const removeIsoPrefixFromEid = (eid: string, prefix: string) => {
  return eid.slice(prefix.length);
};

// INFO:
// ISO number + 0.
// 8260 for UK, 8400 for US
export const createPrefix = (isoNumber: number | undefined) => {
  if (!isoNumber) {
    return "";
  }

  return `${isoNumber}0`;
};

export const addIsoPrefixToEid = (eId: string, isoNumber: number | undefined) => {
  if (!isoNumber) {
    return eId;
  }

  return `${isoNumber}0${eId}`;
};

export const getSexClassification = ({
  id,
  isMale,
  isCastrated,
}: Pick<AnimalFragment, "id" | "isMale" | "isCastrated">) => {
  if (!id) {
    return undefined;
  }

  if (!isMale) {
    return "cow";
  }
  if (isCastrated) {
    return "steer";
  }
  return "bull";
};
