import { FC } from "react";
// Components
import { Spacer, Text } from "components";
// Resources
import styles from "./WizardOption.module.scss";

interface WizardOptionProps {
  name: string;
  onClick: () => void;
  children?: React.ReactNode;
}

export const WizardOption: FC<WizardOptionProps> = ({ children, name, onClick }) => (
  <div className={styles.wizard_option}>
    <button className={styles.wizard_option__button} onClick={onClick}>
      {children ? (
        <>
          {children}

          <Spacer baselineHeight={1} />
        </>
      ) : null}

      <Text colour="blue" smaller>
        <strong>{name}</strong>
      </Text>
    </button>
  </div>
);
