import { useQuery } from "@apollo/client";
import { ANIMAL_FILTER_ALERTS } from "api/MyLivestock/Animal/queries";
import {
  AnimalFilterAlerts,
  AnimalFilterAlerts_animalFilterAlerts,
  AnimalFilterAlertsVariables,
} from "api/MyLivestock/Animal/types/AnimalFilterAlerts";
import { getBUFromLocalStorage } from "helpers/storage";

export const useGetAnimalFilterAlerts = () => {
  const businessUnitId: number = getBUFromLocalStorage();

  const { data, ...rest } = useQuery<AnimalFilterAlerts, AnimalFilterAlertsVariables>(ANIMAL_FILTER_ALERTS, {
    variables: {
      businessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const breeds = (data?.animalFilterAlerts || []) as AnimalFilterAlerts_animalFilterAlerts[];

  return {
    data: breeds,
    ...rest,
  };
};
