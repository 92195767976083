import { SyntheticEvent, FC } from "react";
// Libraries
import pure from "recompose/pure";
// Components
import { FileInput, LoadingOverlay, Button, Flex, Spacer, Text } from "components";
// Utils
import config from "api/config";
import { InfoIcon, TrashIcon } from "constants/SvgIcons";
import { CSV_FILE_FORMAT } from "constants/Settings";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useStateSpecies } from "hooks";
// Resources
import styles from "./ChooseFileBlock.module.scss";

interface ChooseFileBlockProps {
  isAnimalsImport: boolean;
  isLoading: boolean;
  fileName: string;
  selectedImportType: () => void;
  changeFile: (event: SyntheticEvent<HTMLInputElement>, selectedImportType: () => void) => void;
  changeFileName: (name: string | null | undefined) => void;
}

const ChooseFileBlock: FC<ChooseFileBlockProps> = ({
  isAnimalsImport,
  isLoading,
  changeFile,
  fileName,
  changeFileName,
  selectedImportType,
}) => {
  const { activeSpecies } = useStateSpecies();

  const link = isAnimalsImport
    ? `${config.secureProtocol}${config.staticHost}/animals/csv_templates/Example%20Animal%20Import.csv`
    : `${config.secureProtocol}${config.staticHost}/animals/csv_templates/Activity%20import%20template.csv`;

  return (
    <Flex container>
      <Flex
        className={styles.choose_file_block}
        container
        containerAlignItems="center"
        containerDirection="column"
        item
        xs={12}
      >
        <div className={styles.wrapperForHintBlock}>
          <div className={styles.hintBlock}>
            <div className={styles.hintIconBlock}>{InfoIcon}</div>

            <p className={styles.hintTitle}>File design should be of type *.csv</p>

            <Button
              id={ELEMENTS_IDS.DOWNLOAD_EXAMPLE_TEMPLATE_BUTTON}
              caption="Download example template"
              colour="grey"
              variant="hollow"
              onClick={(): Window | null => window.open(link, "Download")}
            />

            <p className={styles.hintText}>
              NB: if you have an existing File exported out of a system we can make it work with the system.
            </p>

            <p className={styles.hintText}>Please send to support@breedr.co</p>
          </div>
        </div>

        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <>
            <Flex container>
              <Flex container containerAlignItems="center" containerDirection="column" item itemGutter xs="fill">
                {activeSpecies ? (
                  <>
                    <Text>
                      Your current selection is: <strong>{activeSpecies.name}</strong>
                    </Text>

                    <Spacer baselineHeight={2} />
                  </>
                ) : null}

                <Text>Choose your file to import</Text>

                <Spacer baselineHeight={2} />

                <FileInput
                  uppercase
                  onChange={(e): void => changeFile(e, selectedImportType)}
                  label={fileName ? "Change file" : "Upload file"}
                  acceptedFormats={CSV_FILE_FORMAT}
                  id="customImport"
                  request
                />
              </Flex>
            </Flex>

            {fileName ? (
              <>
                <Spacer baselineHeight={3} />

                <Flex container containerAlignItems="center" containerDirection="column">
                  <Flex item itemGutter>
                    <Text>{fileName}</Text>
                  </Flex>

                  <Flex item itemGutter>
                    <Button
                      colour="grey"
                      variant="hollow"
                      id={ELEMENTS_IDS.IMPORT_DELETE_FILE_BUTTON}
                      onClick={(): void => changeFileName(null)}
                      icon={{
                        image: TrashIcon,
                        position: "left",
                      }}
                    />
                  </Flex>
                </Flex>
              </>
            ) : null}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default pure(ChooseFileBlock);
