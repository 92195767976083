// Libraries
import { QueryResult, useQuery } from "@apollo/client";
// API
import { GET_BREEDR_BANK_DETAILS } from "api/Breedr/queries";
import { GetBreedrBankDetails, GetBreedrBankDetails_breedrBankDetails } from "api/Breedr/types/GetBreedrBankDetails";

interface UseGetBreedrBankDetailsReturn extends QueryResult {
  data: GetBreedrBankDetails_breedrBankDetails | null;
}

export const useGetBreedrBankDetails = (): UseGetBreedrBankDetailsReturn => {
  const { data, ...rest } = useQuery<GetBreedrBankDetails>(GET_BREEDR_BANK_DETAILS);

  const bankDetails = data?.breedrBankDetails || null;

  return {
    data: bankDetails,
    ...rest,
  };
};
