import { gql } from "@apollo/client";

export const regulatoryTransactionApprovalFragment = gql`
  fragment regulatoryTransactionApprovalFragment on RegulatoryTransactionApprovalType {
    id
    reasonOfFailure
    createdAt
    actualStatus
    approvalStatus
    destination {
      regulatoryId
      description
    }
    source {
      regulatoryId
      description
    }
    destinationLocation {
      id
      unitName
      locationId
    }
    sourceActivity {
      date
      animal {
        id
        dob
        visualId
        damPassportNumber
        animalBreeds {
          id
          name
        }
        passportNumber
        isMale
        isCastrated
        sexClassification {
          slug
          title
        }
      }
    }
  }
`;
