import React, { ChangeEvent, useEffect, useState } from "react";
// Libraries
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
// Utils
import { GetSupportedSystems_integrationSystems } from "api/Import/OtherSystems/types/GetSupportedSystems";
import {
  BCMS_IMPORT_CSV,
  FARMPLAN_IMPORT_XLS,
  TRU_TEST_IMPORT_XLS,
  APHIS_IMPORT_CSV,
} from "api/Import/OtherSystems/mutations";
import { BcmsImportCsv, BcmsImportCsvVariables } from "api/Import/OtherSystems/types/BcmsImportCsv";
import { TruTestImportXls, TruTestImportXlsVariables } from "api/Import/OtherSystems/types/TruTestImportXls";
import { FarmPlanImportXls, FarmPlanImportXlsVariables } from "api/Import/OtherSystems/types/FarmPlanImportXls";
import { AphisImportCsv, AphisImportCsvVariables } from "api/Import/OtherSystems/types/AphisImportCsv";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import {
  BCMS_SLUG,
  FARMPLAN_SLUG,
  TRU_TEST_SLUG,
  APHIS_SLUG,
  AA_SLUG,
  NEOGEN_SLUG,
  BREEDSEEK_SLUG,
  BEEF_X_DAIRY_SLUG,
} from "constants/Import";
import { IMPORT } from "constants/Routes";
import { XLS_FILE_FORMAT, CSV_FILE_FORMAT } from "constants/Settings";
import { getBUFromLocalStorage, setTaskIdToLocalStorage } from "helpers/storage";
import { useGetCurrentBusinessUnit, useGetSupportedSystems, useHasFeature } from "hooks";
import { SystemSelect } from "components/Import";
import {
  Button,
  FieldSelect,
  FileInput,
  Flex,
  InfoTooltip,
  LoadingOverlay,
  Spacer,
  Text,
  TextAnchor,
} from "components";
import styles from "./ImportWeightForm.module.scss";
import LogoAngus from "assets/img/Epds/logo-Angus.png";
import LogoNeogen from "assets/img/Epds/logo-Neogen.png";
import {
  useUploadCsvMutation,
  useImportCsvMutation,
  BatchUploadFormatColumn,
  BatchUploadFormatOption,
  useGetBatchUploadFormatQuery,
  IdentifierEnumType,
} from "generated/graphql";
import { AsyncJobStatusMenuImport } from "animals/AsyncJobStatusMenuImport";
import {
  setImportMenuState,
  setImportTaskId,
  setUnmatchedColumns,
  setAvailableColumns,
} from "state/asyncImportJobSlice";
import { useDispatch } from "react-redux";
import { Modal } from "components/Common/Modal";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { EventName, logAnalyticsEvent } from "utils/analytics";

export interface ImportWeightFile {
  fileName: string;
  file: File;
}

interface ImportWeightFormProps {
  error: string | undefined;
  file: ImportWeightFile | null;
  handleRemoveFile: () => void;
  handleSetFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setError: (error: string | undefined) => void;
  setTaskId: (id: string) => void;
  setSelectedSystem: (system: GetSupportedSystems_integrationSystems | BreedAssociationSystem) => void;
}

export type BreedAssociationSystem = {
  id: string;
  name: string;
  logo: string;
  slug: string;
  groupName?: string;
  helpLink?: string;
};

export const cleanColumnLabel = (label: string) => {
  return label
    .replace(/\s+/g, "_")
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .toLowerCase();
};

export const handleMatchColumns = (
  columnLabelRow: string[] | undefined,
  fieldOptions: Pick<BatchUploadFormatColumn, "slug" | "label" | "isRequired">[] | undefined,
) => {
  if (!columnLabelRow) {
    return { columnFields: [], error: "Invalid file structure. No column labels found in file.", unmatchedColumns: [] };
  }

  const requiredColumns = fieldOptions?.filter((fieldOption) => fieldOption.isRequired);
  const requiredColumnLabels = requiredColumns?.map((column) => column.label) || [];

  const result = columnLabelRow.reduce(
    (acc, columnLabel) => {
      const cleanedLabel = cleanColumnLabel(columnLabel);
      const match = fieldOptions?.find(
        (fieldOption) =>
          fieldOption.slug === cleanedLabel || fieldOption.label.toLowerCase() === columnLabel.toLowerCase(),
      );
      if (!match) {
        acc.unmatchedColumns.push(columnLabel);
        acc.columnFields.push("__skip__");
      } else {
        acc.columnFields.push(match.slug);
      }

      return acc;
    },
    {
      columnFields: [] as string[],
      unmatchedColumns: [] as string[],
    },
  );
  const missingRequiredColumns = requiredColumnLabels.filter(
    (requiredColumnLabel) =>
      !columnLabelRow.some(
        (columnLabel: string) => cleanColumnLabel(columnLabel) === cleanColumnLabel(requiredColumnLabel),
      ),
  );

  if (missingRequiredColumns.length > 0) {
    return {
      columnFields: [],
      error: `Missing required columns: ${missingRequiredColumns.join(", ")}`,
      unmatchedColumns: result.unmatchedColumns,
    };
  }

  if (result.columnFields.every((column: string) => column === "__skip__")) {
    return { columnFields: [], error: "Could not match any columns.", unmatchedColumns: result.unmatchedColumns };
  }

  return { columnFields: result.columnFields, error: null, unmatchedColumns: result.unmatchedColumns };
};

export const ImportWeightForm: React.FC<ImportWeightFormProps> = ({
  error,
  file,
  handleRemoveFile,
  handleSetFile,
  setError,
  setTaskId,
  setSelectedSystem: parentSetSelectedSystem,
}) => {
  const dispatch = useDispatch();
  const [importCsvMutation] = useImportCsvMutation();
  const { countryIsoCodeUpperCase } = useGetCurrentBusinessUnit();
  const [showNeogenModal, setShowNeogenModal] = useState(false);
  const [selectedAnimalIdentifier, setSelectedIdentifierOption] = useState<string>(IdentifierEnumType.EId);
  const [animalIdentifierOptions, setAnimalIdentifierOptions] = useState<
    Pick<BatchUploadFormatOption, "choices" | "slug" | "default" | "helpText" | "label"> | undefined
  >();
  const handleChangeAnimalIdentifier = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedIdentifierOption(event.target.value);
  };
  const history = useHistory();
  const handleClickBack = () => history.push(IMPORT);

  const useGenetics = useHasFeature("GENETICS");

  const { data } = useGetSupportedSystems();

  const breedingAssociationSystems: BreedAssociationSystem[] = [
    {
      id: "1",
      name: "American Angus",
      logo: LogoAngus,
      slug: AA_SLUG,
    },
    {
      id: "2",
      name: "EPD Results",
      logo: LogoNeogen,
      slug: NEOGEN_SLUG,
      groupName: "Neogen",
    },
    {
      id: "3",
      name: "Breedseek",
      logo: LogoNeogen,
      slug: BREEDSEEK_SLUG,
      groupName: "Neogen",
    },
    {
      id: "4",
      name: "Igenity BeefxDairy",
      logo: LogoNeogen,
      slug: BEEF_X_DAIRY_SLUG,
      groupName: "Neogen",
    },
  ];
  const integrationSystems = useGenetics ? breedingAssociationSystems : data;

  const neogenGroup = breedingAssociationSystems.filter((system) => system.groupName === "Neogen");

  const [selectedSystem, setSelectedSystem] = useState<
    GetSupportedSystems_integrationSystems | BreedAssociationSystem | null
  >(integrationSystems?.[0] || null);

  useEffect(() => {
    handleRemoveFile();
    if (selectedSystem) parentSetSelectedSystem(selectedSystem);
  }, [selectedSystem]);

  const [uploadBcmsFile] = useMutation<BcmsImportCsv, BcmsImportCsvVariables>(BCMS_IMPORT_CSV);
  const [uploadTruTestFile] = useMutation<TruTestImportXls, TruTestImportXlsVariables>(TRU_TEST_IMPORT_XLS);
  const [uploadFarmPlanFile] = useMutation<FarmPlanImportXls, FarmPlanImportXlsVariables>(FARMPLAN_IMPORT_XLS);
  const [uploadAphisFile] = useMutation<AphisImportCsv, AphisImportCsvVariables>(APHIS_IMPORT_CSV);
  const [uploadCsvMutation] = useUploadCsvMutation();

  // step 1 get batch upload format
  const {
    data: batchUploadFormatData,
    loading,
    error: batchUploadError,
  } = useGetBatchUploadFormatQuery({
    variables: {
      businessUnitId: String(getBUFromLocalStorage()),
      slug: selectedSystem?.slug || "",
    },
    skip: !selectedSystem,
    fetchPolicy: "cache-and-network",
  });

  if (batchUploadError) {
    setError(batchUploadError.message);
  }
  useEffect(() => {
    if (batchUploadFormatData) {
      const animalMatchingColumn = batchUploadFormatData?.batchUploadFormat?.options.find(
        (option) => option.slug === "animal-matching-column",
      );
      ///This should be a temp fix to hide animal ID while BE is working on cleaning up Animal/passport identifier
      if (animalMatchingColumn) {
        const filteredChoices = animalMatchingColumn.choices.filter((choice) => choice?.value !== "PASSPORT_NUMBER");
        setAnimalIdentifierOptions(() => ({
          ...animalMatchingColumn,
          choices: countryIsoCodeUpperCase !== CountryISOCode.US ? animalMatchingColumn.choices : filteredChoices,
        }));
      }
    }
  }, [batchUploadFormatData, countryIsoCodeUpperCase]);
  if (loading) return <LoadingOverlay />;
  if (batchUploadFormatData?.batchUploadFormat?.columns) {
    const availableColumns = batchUploadFormatData?.batchUploadFormat?.columns.map((col) => {
      return { slug: col.slug, label: col.label };
    });
    dispatch(setAvailableColumns(availableColumns));
  }
  const handleUploadBatchFile = async () => {
    try {
      // step 2 upload csv
      const { data: uploadCSVData } = await uploadCsvMutation({
        variables: {
          input: {
            businessUnit: getBUFromLocalStorage(),
            csvFile: file?.file,
          },
        },
      });

      if (uploadCSVData?.uploadCsv?.errors) {
        setError(uploadCSVData?.uploadCsv?.errors.map((item) => item?.message).join("; "));
        return;
      }

      const matchResult = handleMatchColumns(
        uploadCSVData?.uploadCsv?.rows[0] || undefined,
        batchUploadFormatData?.batchUploadFormat?.columns,
      );
      if (matchResult?.unmatchedColumns?.length > 0) {
        dispatch(setUnmatchedColumns(matchResult?.unmatchedColumns));
      }
      if (matchResult.error) {
        setError(matchResult.error);
        return;
      }
      // Step 3 - import csv
      const { data: importData } = await importCsvMutation({
        variables: {
          input: {
            id: Number(uploadCSVData?.uploadCsv?.id),
            fields: matchResult.columnFields,
            isFirstRowColumnNames: true,
            formatSlug: batchUploadFormatData?.batchUploadFormat?.slug,
            options: [
              //this is currently the only option we are using for now
              {
                slug: "animal-matching-column",
                value: selectedAnimalIdentifier,
              },
            ],
          },
        },
      });

      if (importData?.importCsv?.errors) {
        setError(importData?.importCsv?.errors.map((item) => item?.message).join("; "));
        return;
      }

      if (importData?.importCsv?.taskId) {
        dispatch(setImportMenuState("expanded"));
        dispatch(setImportTaskId(importData?.importCsv?.taskId));
        setError(undefined);
      }
    } catch (e) {
      setError("An unexpected error occurred. Please try again");
    }
  };

  const handleUploadXlsMutation = async (systemMutation, mutationName: string): Promise<void> => {
    const { data } = await systemMutation({
      variables: {
        input: {
          farm: getBUFromLocalStorage(),
          xlsFile: file?.file,
        },
      },
    });

    if (data?.[mutationName]?.task) {
      setTaskIdToLocalStorage(data?.[mutationName]?.task.id);
      setTaskId(data?.[mutationName]?.task.id);
    }

    if (data?.[mutationName]?.errors) {
      setError(data?.[mutationName]?.errors.map((item) => item?.message).join("; "));
    }
  };

  const handleUploadCsvMutation = async (systemMutation, mutationName: string): Promise<void> => {
    const { data } = await systemMutation({
      variables: {
        input: {
          farm: getBUFromLocalStorage(),
          csvFile: file?.file,
        },
      },
    });

    if (data?.[mutationName]?.task) {
      setTaskIdToLocalStorage(data?.[mutationName]?.task.id);
      setTaskId(data?.[mutationName]?.task.id);
    }

    if (data?.[mutationName]?.errors) {
      setError(data?.[mutationName]?.errors.map((item) => item?.message).join("; "));
    }
  };

  const handleUploadFile = (): void => {
    switch (selectedSystem?.slug) {
      case BCMS_SLUG:
        handleUploadCsvMutation(uploadBcmsFile, "bcmsImportCsv");
        logAnalyticsEvent(EventName.import, { name: "import-bcms" });
        break;

      case APHIS_SLUG:
        handleUploadCsvMutation(uploadAphisFile, "aphisImportCsv");
        logAnalyticsEvent(EventName.import, { name: "import-aphis" });
        break;

      case TRU_TEST_SLUG:
        handleUploadXlsMutation(uploadTruTestFile, "truTestImportXls");
        logAnalyticsEvent(EventName.import, { name: "import-trutest" });
        break;

      case FARMPLAN_SLUG:
        handleUploadXlsMutation(uploadFarmPlanFile, "farmPlanImportXls");
        logAnalyticsEvent(EventName.import, { name: "import-farmplan" });
        break;

      case AA_SLUG:
      case NEOGEN_SLUG:
      case BREEDSEEK_SLUG:
      case BEEF_X_DAIRY_SLUG:
        handleUploadBatchFile();
        break;
    }
  };

  const handleNeogenSystemSelect = (selectedSlug: string) => {
    setSelectedSystem(breedingAssociationSystems.find((system) => system.slug === selectedSlug) || null);
    setShowNeogenModal(false);
  };

  const acceptedFormats = [XLS_FILE_FORMAT, CSV_FILE_FORMAT];

  return (
    <>
      <Flex container>
        <Flex item itemGutter xs={12}>
          <Flex className={styles.import_weight_form} container containerJustifyContent="center">
            <Spacer baselineHeight={3} />

            <Flex item itemGutter>
              <Text smaller>
                <strong>{useGenetics ? "Genetic Partners" : "Supported systems"}</strong>
              </Text>
            </Flex>
            <Spacer baselineHeight={2} />

            {integrationSystems?.map((integrationSystem) => (
              <Flex item itemGutter key={`supported_system_${integrationSystem.id}`}>
                {integrationSystem.groupName === "Neogen" ? (
                  integrationSystem.slug === NEOGEN_SLUG ? (
                    <SystemSelect
                      logo={integrationSystem.logo as string}
                      name={integrationSystem.groupName}
                      onClick={(): void => {
                        setShowNeogenModal(true);
                        dispatch(setUnmatchedColumns([]));
                        dispatch(setImportMenuState("closed"));
                      }}
                      selected={neogenGroup && neogenGroup.some((item) => item.slug === selectedSystem?.slug)}
                    />
                  ) : null
                ) : (
                  <SystemSelect
                    logo={integrationSystem.logo as string}
                    name={integrationSystem.name}
                    onClick={(): void => {
                      setSelectedSystem(integrationSystem);
                      dispatch(setUnmatchedColumns([]));
                      dispatch(setImportMenuState("closed"));
                    }}
                    selected={integrationSystem.id === selectedSystem?.id}
                  />
                )}
              </Flex>
            ))}

            {selectedSystem ? (
              <>
                {animalIdentifierOptions ? (
                  <>
                    <Spacer baselineHeight={2} />
                    <Flex key={animalIdentifierOptions.slug} container containerAlignItems="center">
                      <Flex>
                        <FieldSelect
                          label={animalIdentifierOptions.label}
                          className={""}
                          inputProps={{
                            name: animalIdentifierOptions.slug,
                            options: animalIdentifierOptions.choices,
                            value: selectedAnimalIdentifier,
                            onChange: handleChangeAnimalIdentifier,
                          }}
                        />
                      </Flex>
                      <InfoTooltip text={animalIdentifierOptions?.helpText as string} inline />
                    </Flex>
                  </>
                ) : null}
                <Spacer baselineHeight={2} />

                <Flex item itemGutter>
                  <Text smaller>
                    <strong>Upload your {selectedSystem.name} file:</strong>
                  </Text>
                </Flex>

                {selectedSystem.helpLink ? (
                  <>
                    <Spacer baselineHeight={2} />

                    <TextAnchor
                      caption="Not sure how to get one? Click here for instructions"
                      href={selectedSystem.helpLink}
                      id={ELEMENTS_IDS.IMPORT_BLOCK_INSTRUCTIONS_LINK}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  </>
                ) : null}

                <Spacer baselineHeight={2} />

                <FileInput
                  uppercase
                  onChange={handleSetFile}
                  label={file?.fileName || "Choose file..."}
                  acceptedFormats={acceptedFormats}
                  id="weightFileUpload"
                />

                {error ? (
                  <>
                    <Spacer baselineHeight={2} />

                    <Text smaller warning className="px-2">
                      {error}
                    </Text>
                  </>
                ) : null}

                {file?.fileName ? (
                  <>
                    <Spacer baselineHeight={3} />

                    <Flex container>
                      <Flex item itemGutter>
                        <Button
                          id={ELEMENTS_IDS.IMPORT_BLOCK_CANCEL_BUTTON}
                          caption="Cancel"
                          colour="grey"
                          variant="hollow"
                          onClick={handleRemoveFile}
                        />
                      </Flex>

                      <Flex item itemGutter>
                        <Button
                          id={ELEMENTS_IDS.IMPORT_BLOCK_IMPORT_BUTTON}
                          caption="Import"
                          onClick={handleUploadFile}
                        />
                      </Flex>
                    </Flex>
                  </>
                ) : null}

                <Spacer baselineHeight={4} />
              </>
            ) : null}
          </Flex>

          <Spacer baselineHeight={4} />

          <Flex container containerJustifyContent="space-between">
            <Button caption="Back" colour="grey" onClick={handleClickBack} variant="hollow" />
          </Flex>
        </Flex>
        <AsyncJobStatusMenuImport />
      </Flex>
      <Modal active={showNeogenModal} handleClose={() => setShowNeogenModal(false)}>
        <>
          <div className="flex flex-col items-center">
            <figure className={"w-32 h-auto"}>
              {LogoNeogen ? <img src={LogoNeogen} alt={`Neogen logo`} /> : null}
            </figure>
            <h3 className="font-semibold pb-6">Choose your Neogen upload</h3>
          </div>
          <div className="flex align-middle justify-center">
            {integrationSystems.map((integrationSystem) =>
              integrationSystem.groupName === "Neogen" ? (
                <Button
                  key={integrationSystem.id}
                  caption={integrationSystem.name}
                  onClick={() => handleNeogenSystemSelect(integrationSystem.slug)}
                  variant="solid"
                  className="m-1"
                />
              ) : null,
            )}
          </div>
        </>
      </Modal>
    </>
  );
};
