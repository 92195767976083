import { LoadingOverlay, PageContent, Spacer } from "components";
import { ICON_NAMES } from "constants/Icons";
import { useSupplyChainsQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { SupplyChainSummary } from "./SupplyChainSummary";
import { EmptyStatesSupplyChains } from "components/EmptyStates/SupplyChains";

export const SupplyChains: React.FC = () => {
  const activeBusinessUnitId = getBUFromLocalStorage();
  const { data, loading, error } = useSupplyChainsQuery({
    variables: { buId: activeBusinessUnitId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const supplyChains = data?.supplyChainPages?.map((item, index) => ({
    id: item?.id || index.toString(),
    title: item?.title || "",
    imgSrc: item?.banner || "",
    metrics: [
      { text: item?.accreditation || "", icon: "check" as keyof typeof ICON_NAMES },
      { text: item?.subregion || "", icon: "mapMarker" as keyof typeof ICON_NAMES },
    ],
    bullets: item?.bullets || [],
    text: item?.text || "",
  }));

  if (error) {
    return <EmptyStatesSupplyChains />;
  }

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <PageContent>
        <Spacer baselineHeight={2} />
        {!supplyChains || supplyChains.length === 0 ? (
          <EmptyStatesSupplyChains />
        ) : (
          supplyChains?.map((item) => {
            return (
              <div className={"pb-4"} key={item.id}>
                <SupplyChainSummary item={item} />
              </div>
            );
          })
        )}
      </PageContent>
    </>
  );
};
export default SupplyChains;
