import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesSupplierSellers: FC = () => {
  return (
    <EmptyStates animation={"users"} title="No suppliers added.">
      <p>
        Click the ‘+ Add’ button to start adding you preferred Suppliers. We will send the Supplier an invitation to
        connect to you.
      </p>
    </EmptyStates>
  );
};
