import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// Utils
import {
  DASHBOARD,
  BU_ID,
  SETTINGS,
  BUSINESS_SETTINGS,
  MEDICINE_DETAILS,
  MEDICINE_CABINET,
  REGULATORY,
} from "constants/Routes";
// Libs
import { isEmpty } from "lodash";
// Hooks
import { useHasFeature, useGetBusinessUnitUserRole, useGetCurrentBusinessUnit } from "hooks";
// Pages
import FarmSettings from "pages/Settings/FarmSettings";
import Sires from "pages/Settings/SireSettings";
import SireCreateEdit from "pages/Settings/SireSettings/SireCreateEdit";
import MedicineCabinet from "medicines/pages/MedicineCabinet";
import { MedicineDetails } from "medicines/pages/MedicineDetails";
import Regulatory from "pages/Regulatory";

const ROUTES_PATHS = {
  Sires: `${BU_ID}${SETTINGS}/sires`,
  SireCreateEdit: `${BU_ID}${SETTINGS}/sires/:id`,
  FarmSettings: `${BU_ID}${SETTINGS}${BUSINESS_SETTINGS}`,
  MedicineCabinet: `${BU_ID}${MEDICINE_CABINET}`,
  MedicineDetails: `${BU_ID}${MEDICINE_DETAILS}/:id`,
  Regulatory: `${BU_ID}${REGULATORY}`,
};

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

const FarmerRoutes: React.FC = () => {
  const { isAdmin } = useGetBusinessUnitUserRole();

  const isShowSire = useHasFeature("SIRES");

  const { regulatorySystem } = useGetCurrentBusinessUnit();

  const isShowRegulatory = !isEmpty(regulatorySystem);

  const sireRoutes = [
    <Route exact path={ROUTES_PATHS.Sires} key={ROUTES_PATHS.Sires} component={Sires} />,
    <Route exact path={ROUTES_PATHS.SireCreateEdit} key={ROUTES_PATHS.SireCreateEdit} component={SireCreateEdit} />,
  ];

  const medicineRoutes = [
    <Route exact path={ROUTES_PATHS.MedicineCabinet} key={ROUTES_PATHS.MedicineCabinet} component={MedicineCabinet} />,
    <Route exact path={ROUTES_PATHS.MedicineDetails} key={ROUTES_PATHS.MedicineDetails} component={MedicineDetails} />,
  ];

  return (
    <Route path={PATHS} exact>
      <Switch>
        {isShowSire ? sireRoutes : null}
        {isAdmin ? <Route exact path={ROUTES_PATHS.FarmSettings} component={FarmSettings} /> : null}
        {medicineRoutes}
        {isShowRegulatory ? <Route exact path={ROUTES_PATHS.Regulatory} component={Regulatory} /> : null}
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};

export default FarmerRoutes;
