import { VFC } from "react";
// API
import { BuyingLotQuery, MyLotQuery } from "generated/graphql";
// Utils
import { getSellerListingStages, getBuyerListingStages } from "./helpers";
// Types
import { StageProps } from "components/Common/Stages/Stage";
// Components
import { Paper, Flex, Spacer, Stages, Title } from "components";
// Resources
import styles from "./ListingProgress.module.scss";

interface ListingProgressProps {
  isBuyerListing?: boolean;
  listing?: BuyingLotQuery["buyingLot"] | MyLotQuery["myLot"] | null;
}
const DEFAULT_STAGES: StageProps[] = [
  {
    icon: "transactionHandshake",
    iconSize: "large",
    title: "Offer",
  },
  {
    icon: "securePayment",
    iconSize: "large",
    title: "Secure Payment",
  },
  {
    icon: "delivery",
    iconSize: "large",
    title: "Deliver",
  },
  {
    icon: "check",
    title: "Complete",
  },
];

export const ListingProgress: VFC<ListingProgressProps> = ({ isBuyerListing, listing }) => {
  let stages = DEFAULT_STAGES;
  const isBuyerListingExists = Boolean(isBuyerListing && listing);
  const isSellerListingExists = Boolean(!isBuyerListing && listing);

  if (isBuyerListingExists) {
    stages = getBuyerListingStages(listing as BuyingLotQuery["buyingLot"]);
  } else if (isSellerListingExists) {
    stages = getSellerListingStages(listing as MyLotQuery["myLot"]);
  }

  return (
    <Flex container>
      <Flex item itemGutter xs={12}>
        <Title tertiary>Trade status</Title>
      </Flex>
      <Spacer allowDecimal baselineHeight={1.6} />
      <Flex item itemGutter xs={12}>
        <Paper className={styles.stages_paper}>
          <Stages>
            {stages.map(({ title, ...rest }) => (
              <Stages.Stage key={`stage_${title}`} title={title} {...rest} />
            ))}
          </Stages>
        </Paper>
      </Flex>
    </Flex>
  );
};
