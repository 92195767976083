// React
import React, { useContext } from "react";
// Libraries
import cn from "classnames";
// Utils
import { useBreakpoints } from "hooks";
// Components
import { ColumnContext, FlexProps } from ".";
// Resources
import styles from "./Flex.module.scss";

export const Flex: React.FC<FlexProps> = ({
  children,
  className,
  container,
  containerAlignContent,
  containerAlignItems,
  containerDirection,
  containerJustifyContent,
  containerWrap = "wrap",
  containerResetL,
  containerResetM,
  containerResetS,
  containerResetXL,
  containerResetXS,
  item,
  itemAlignSelf,
  itemBasisFill,
  itemGrow,
  itemGutter,
  itemOrder,
  itemNoShrink,
  l,
  m,
  s,
  xl,
  xs,
  role,
  ...props
}) => {
  const contextCols = useContext(ColumnContext);

  const { isBreakpointXL, isBreakpointL, isBreakpointM, isBreakpointS, isBreakpointXS } = useBreakpoints();

  const containerCols =
    isBreakpointXL && containerResetXL
      ? containerResetXL
      : isBreakpointL && containerResetL
      ? containerResetL
      : isBreakpointM && containerResetM
      ? containerResetM
      : isBreakpointS && containerResetS
      ? containerResetS
      : isBreakpointXS && containerResetXS
      ? containerResetXS
      : undefined;

  let itemCols =
    isBreakpointXL && xl
      ? xl
      : isBreakpointL && l
      ? l
      : isBreakpointM && m
      ? m
      : isBreakpointS && s
      ? s
      : isBreakpointXS && xs
      ? xs
      : undefined;

  itemCols = itemCols === "fill" ? contextCols : itemCols;

  return (
    <div
      className={cn(
        {
          [styles["flex_container"]]: container,
          [styles[`flex_container--align-content__${containerAlignContent}`]]: container && containerAlignContent,
          [styles[`flex_container--align-items__${containerAlignItems}`]]: container && containerAlignItems,
          [styles[`flex_container--flex-direction__${containerDirection}`]]: container && containerDirection,
          [styles[`flex_container--justify-content__${containerJustifyContent}`]]: container && containerJustifyContent,
          [styles[`flex_container--flex-wrap__${containerWrap}`]]: container && containerWrap,
          [styles["flex_item"]]: item,
          [styles[`flex_item--align-self__${itemAlignSelf}`]]: item && itemAlignSelf,
          [styles[`flex_item--basis__fill`]]: item && itemBasisFill,
          [styles["flex_item--grow"]]: item && itemGrow,
          [styles["flex_item--gutter"]]: item && itemGutter,
          [styles[`flex_item--order__${itemOrder}`]]: item && itemOrder,
          [styles["flex_item--noShrink"]]: item && itemNoShrink,
          [styles[`flex_item--span__${itemCols}_of_${contextCols}`]]: item && itemCols && contextCols,
        },
        className,
      )}
      role={role}
      {...props}
    >
      <ColumnContext.Provider value={containerCols || itemCols || contextCols}>{children}</ColumnContext.Provider>
    </div>
  );
};
