import { AnimalsList } from "animals/AnimalsList";
import { LogActivityModal } from "animals/LogActivityModal";
import { AssignAnimalsToGroupVariables } from "api/MyLivestock/Groups/types/AssignAnimalsToGroup";
import { Modal, Text } from "components";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { TABLE_IDS } from "constants/Interface";
import { AnimalFragment, useAssignAnimalsToGroupMutation, useGroupQuery } from "generated/graphql";
import { removeNothings } from "helpers/general";
import { useGetCurrentBusinessUnit } from "hooks";
import React, { useContext, useEffect, useState } from "react";
import { EmptyStatesGroupDetailPage } from "components/EmptyStates/GroupDetailPage";
import { MoveToFieldModal } from "fields/MoveToFieldModal";
import { Button } from "twComponents/Button";
import { Form } from "components/FormHookComponents/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DateTime } from "luxon";
import { FormDatePicker } from "components/FormHookComponents/FormDatePicker";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";

interface Props {
  groupId: string;
  isBatch: boolean;
  addingAnimalsToGroup: boolean;
  setAddingAnimalsToGroup: (isAddingAnimals: boolean) => void;
}

export const GroupAnimalsList: React.FC<Props> = ({
  groupId,
  isBatch,
  addingAnimalsToGroup,
  setAddingAnimalsToGroup,
}) => {
  const { id: businessUnitId } = useGetCurrentBusinessUnit();
  const { data } = useGroupQuery({
    variables: { group: groupId, businessUnit: Number(businessUnitId), isBatch },
    fetchPolicy: "cache-only",
    skip: !groupId,
  });
  const [groupAnimalsIds, setGroupAnimalsIds] = useState<string[]>([]);
  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);
  const tableOptions = getTableOptions<AnimalFragment>(TABLE_IDS["GROUP_ANIMALS"]);
  const selectedRowIds = tableOptions?.selectedRowsIds;
  const selectedAnimalIds = selectedRowIds ? [...selectedRowIds] : [];
  const [moveAnimalsToGroup, { loading: isAddingAnimalsToGroup }] = useAssignAnimalsToGroupMutation();
  const [showMoveToFieldModal, setShowMoveToFieldModal] = useState(false);
  const [showConfirmAddAnimalsToGroupModal, setShowConfirmAddAnimalsToGroupModal] = useState(false);
  const { handleError, handleMutationResult } = useHandleMutationResult();

  useEffect(() => {
    if (data?.group?.animals) {
      setGroupAnimalsIds(removeNothings(data.group.animals.map((animal) => animal?.id)));
    }
  }, [data]);

  const onSubmit = async ({ date }: Schema) => {
    setAddingAnimalsToGroup(false);

    try {
      const variables: AssignAnimalsToGroupVariables = {
        input: {
          group: groupId,
          animals: selectedAnimalIds,
          date,
        },
      };
      const { data } = await moveAnimalsToGroup({
        variables,
        refetchQueries: ["GetAnimalsPage", "GetAnimalById"],
      });

      const errors = data?.moveAnimalsToGroup?.errors;

      handleMutationResult({
        successMessage: "Animal(s) successfully assigned to group",
        errors: removeNothings(errors || []),
        onSuccess: () => {
          setTableOptions({
            id: TABLE_IDS.GROUP_ANIMALS,
            selectedRowsIds: undefined,
          });

          setShowConfirmAddAnimalsToGroupModal(false);
        },
      });
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <>
      <Modal
        active={showConfirmAddAnimalsToGroupModal}
        title="Assign animals to group"
        subTitle={`${selectedAnimalIds.length} animal(s) will be added to the group.`}
      >
        <Form
          defaultValues={{ date: DateTime.local().toJSDate() }}
          resolver={yupResolver(validationSchema)}
          onSubmit={onSubmit}
        >
          <FormDatePicker<Schema> label="Date *" name="date" maxDate={new Date()} />
          <div className="flex justify-end mt-5">
            <Button
              type="button"
              disabled={isAddingAnimalsToGroup}
              onClick={() => setShowConfirmAddAnimalsToGroupModal(false)}
              variant="outline"
            >
              Cancel
            </Button>
            <Button type="submit" loading={isAddingAnimalsToGroup} className="ml-2">
              Confirm
            </Button>
          </div>
        </Form>
      </Modal>
      <Text className="font-semibold text-xl">
        {addingAnimalsToGroup
          ? `Select livestock to add to your group (${selectedAnimalIds.length} selected)`
          : "Animals in this group"}
      </Text>
      <div className="flex justify-end px-2 mb-2">
        {addingAnimalsToGroup ? (
          <div>
            <Button
              id={ELEMENTS_IDS.GROUPS_EDIT_MODE_CANCEL_BUTTON}
              variant="outline"
              onClick={() => {
                setAddingAnimalsToGroup(false);
                setTableOptions({
                  id: TABLE_IDS.GROUP_ANIMALS,
                  selectedRowsIds: undefined,
                });
              }}
              className="mx-1"
            >
              Cancel
            </Button>
            <Button
              id={ELEMENTS_IDS.GROUPS_EDIT_MODE_ADD_TO_GROUP_BUTTON}
              disabled={selectedAnimalIds.length === 0}
              onClick={() => {
                setShowConfirmAddAnimalsToGroupModal(true);
              }}
              className="mx-1"
            >
              Add to group
            </Button>
          </div>
        ) : (
          <div className="flex justify-end">
            <Button onClick={() => setShowMoveToFieldModal(true)} className="mx-1">
              Move group to location
            </Button>
            <MoveToFieldModal
              entityToMove={{ type: "group", groupId }}
              showModal={showMoveToFieldModal}
              setShowModal={setShowMoveToFieldModal}
            />

            <Button
              id={ELEMENTS_IDS.GROUP_ADD_ANIMALS_TO_GROUP_BUTTON}
              onClick={() => {
                setAddingAnimalsToGroup(true);
              }}
              className="mx-1"
            >
              Add animals to group
            </Button>
            {groupAnimalsIds.length > 0 ? (
              <div className="ml-1">
                <LogActivityModal animalIds={groupAnimalsIds} />
              </div>
            ) : null}
          </div>
        )}
      </div>
      <AnimalsList
        tableFilterIdentifier="groupsList"
        excludedFilters={{ groupId: true }}
        showAnimalActionButtons
        showSelectColumn
        groupId={addingAnimalsToGroup ? undefined : groupId}
        fromPage="group"
        noResults={<EmptyStatesGroupDetailPage />}
        tableId={TABLE_IDS["GROUP_ANIMALS"]}
      />
    </>
  );
};

type Schema = yup.InferType<typeof validationSchema>;

const validationSchema = yup.object({
  date: yup.date().required("Date is required."),
});
