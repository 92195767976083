import { animal } from "animals/api/fragments";
import { PAGE_INFO_FRAGMENT } from "deliveries/api/fragments";
import gql from "graphql-tag";

export const FINANCIAL_TRANSACTION_CATEGORY = gql`
  query FinancialTransactionCategories($businessUnit: ID!) {
    financialTransactionCategories(businessUnit: $businessUnit) {
      id
      label
      recordType
    }
  }
`;

export const GET_FINANCIAL_TRANSACTIONS_LIST = gql`
  query FinancialTransactions(
    $businessUnit: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: FinancialTransactionsSortByEnumType
    $desc: Boolean
  ) {
    financialTransactions(
      businessUnit: $businessUnit
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      desc: $desc
    ) {
      pageInfo {
        ...PageInfo
      }
      count
      edges {
        node {
          id
          label
          animalsCount
          amount
          notes
          date
          grossWeight
          recordType
          state
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const GET_FINANCIAL_TRANSACTION = gql`
  query FinancialTransaction($businessUnit: Int!, $id: UUID!) {
    financialTransaction(businessUnit: $businessUnit, id: $id) {
      id
      date
      endDate
      label
      animalsCount
      grossWeight
      payWeight
      shrinkage
      contact {
        id
      }
      category {
        id
        label
      }
      state
      unitPrice
      recordType
      notes
      amount
    }
  }
`;

export const GET_FINANCIAL_TRANSACTION_ANIMALS = gql`
  query FinancialTransactionAnimals(
    $businessUnit: Int!
    $id: UUID!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    financialTransaction(businessUnit: $businessUnit, id: $id) {
      id
      animalsCount
      transactionAnimals(before: $before, after: $after, first: $first, last: $last) {
        pageInfo {
          ...PageInfo
        }
        edges {
          node {
            animal {
              ...animal
            }
          }
        }
      }
    }
  }
  ${animal}
  ${PAGE_INFO_FRAGMENT}
`;
