import { ChangeEvent } from "react";
import { GetBusinessUnits_businessUnits_deliveryAddress } from "api/BusinessUnits/types/GetBusinessUnits";
import { BU_TYPES } from "trading/constants";
import { getBUFromLocalStorage } from "helpers/storage";
import { useGetOrganisationBusinessUnits } from "hooks";
import { MarkOffFarmValues } from "../../AnimalBulkActions/MarkOffFarm/MarkOffFarmForm";
import { Field, InputSelect, LoadingOverlay } from "components";
import styles from "./BUWithLocationsSelect.module.scss";

export interface LocationOptions extends GetBusinessUnits_businessUnits_deliveryAddress {
  farmName: string;
  farmId: string;
}

interface Props {
  disabled?: boolean;
  onLocationChange: (values: MarkOffFarmValues) => void;
  value: string;
}

export const BUWithLocationSelect = ({ disabled, onLocationChange, value }: Props) => {
  const { data: businessUnits, loading } = useGetOrganisationBusinessUnits();

  const currentBU = businessUnits.find((item) => (item ? +item.id === +getBUFromLocalStorage() : false));

  const busInOrganisation = businessUnits.filter((item) => {
    const isCurrentOrganisation = currentBU && item?.organisation?.id === currentBU.organisation?.id;
    const isFarmer = item?.businessType?.slug === BU_TYPES.farmer;
    const isCurrentBU = currentBU && item?.id === currentBU.id;

    return item ? isCurrentOrganisation && isFarmer && !isCurrentBU : false;
  });

  const placeholder = loading
    ? "Loading locations"
    : busInOrganisation.length < 1
    ? "No suitable locations"
    : "Select new location";
  const isDisabled = disabled || loading;

  const deliveryAddresses: {
    key: string;
    value: string;
    label: string;
    data: LocationOptions;
  }[] = busInOrganisation.reduce<{ key: string; value: string; label: string; data: LocationOptions }[]>(
    (accumulator, currentValue) => {
      const addresses =
        currentValue?.deliveryAddress.map((item) => ({
          key: item.id,
          value: item.id,
          label: `${currentValue?.name}, ${item.unitName}, CPH: ${item.locationId}`,
          data: { ...item, farmName: currentValue.name, farmId: currentValue.id },
        })) || [];
      return [...accumulator, ...addresses];
    },
    [],
  );

  const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const locationData = deliveryAddresses.find((item) => item.value === e.target.value)?.data;
    const values = {
      location: locationData?.id || "",
      destinationCPHNumber: locationData?.locationId || "",
      destinationPostcode: locationData?.postcode || "",
      destinationAddress: locationData?.addressLine1 || "",
      destinationName: locationData?.farmName || "",
      destinationId: locationData?.farmId || "",
    };
    onLocationChange(values);
  };

  return (
    <Field label="Move to one of your holdings or farms" labelFor="location">
      <div className={styles.organisation_business_unit_select}>
        <InputSelect
          disabled={isDisabled}
          isClearable={true}
          name="location"
          onChange={handleChange}
          options={deliveryAddresses}
          placeholder={placeholder}
          value={value}
        />

        {loading ? <LoadingOverlay /> : null}
      </div>
    </Field>
  );
};
