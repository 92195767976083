import { CONFIRMATION_MODALS, INTEGRATION_SYSTEMS_CONNECTING_STATUSES } from "constants/Settings";
import { GetFarmSystems_farmSystems } from "api/Settings/types/GetFarmSystems";
import { SelectedSystem } from "pages/Settings/FarmSettings/ConnectedSystems";

export const isLowSecure = (text: string): boolean =>
  Boolean(text) && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&~#^()+<>{}:"_]{6,}$/.test(text);

export const isMedSecure = (text: string): boolean =>
  Boolean(text) &&
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~#^()+<>{}:"_])[A-Za-z\d@$!%*?&~#^()+<>{}:"_]{8,}$/.test(text);

export const isHeightSecure = (text: string): boolean =>
  Boolean(text) &&
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~#^()+<>{}:"_])[A-Za-z\d@$!%*?&~#^()+<>{}:"_]{10,}$/.test(text);

export const sortSettingsData = (
  data: Record<string, Date | number | string>[],
  sortBy: string,
  isAsc: boolean,
): Record<string, unknown>[] => {
  return data.sort((a, b) => {
    let firstVal = a[sortBy];
    let secondVal = b[sortBy];

    if (sortBy === "lastLogin") {
      firstVal = new Date(firstVal);
      secondVal = new Date(secondVal);
    }

    if (firstVal > secondVal) {
      return isAsc ? 1 : -1;
    }

    if (firstVal < secondVal) {
      return isAsc ? -1 : 1;
    }

    return 0;
  });
};

export const getPageHeaderTitle = (showForm: boolean, editMode: boolean, userName: string): string => {
  if (showForm && !editMode) {
    return "Invite user";
  }

  if (showForm && editMode) {
    return `${userName}'s details`;
  }

  return "User Management";
};
export const getPageHeaderSubTitle = (showForm: boolean, editMode: boolean, userName: string): string => {
  if (showForm && !editMode) {
    return "Manage your new user’s access to the platform";
  }

  if (showForm && editMode) {
    return `Manage ${userName}'s access`;
  }

  return "Manage your workforce";
};
export const getConfirmModalTitle = (type: string | boolean, userName: string): string => {
  switch (type) {
    case CONFIRMATION_MODALS.RESET_PASSWORD:
      return `Are you sure you want to reset ${userName}'s password?`;

    case CONFIRMATION_MODALS.DELETE_USER:
      return `Are you sure you want to delete user ${userName}`;

    case CONFIRMATION_MODALS.RESENT_INVITE:
      return "Are you sure you want to resend invite to user?";

    default:
      return `Are you sure you want to suspend ${userName}'s account`;
  }
};

export const getConnectSystemInitialValues = (selectedSystem: SelectedSystem): Record<string, unknown> => ({
  system: selectedSystem.value,
  ...selectedSystem.fields?.reduce((acc, cur) => {
    acc[cur.fieldName] = "";

    return acc;
  }, {}),
});
export const getConnectSystemInitialValuesEdit = (
  selectedSystem: EditConnectedSystemData,
): Record<string, unknown> => ({
  ...selectedSystem.credentials,
  system: selectedSystem.value,
});
export const getFormCredentials = <T extends { system?: string }>(fields: T): Omit<T, "system"> => {
  const items = fields;
  delete items.system;
  return items;
};
export const getConnectedSystemStatusName = (status: string | null): string => {
  switch (status) {
    case INTEGRATION_SYSTEMS_CONNECTING_STATUSES.CONNECT_FAILED:
      return "Failed";

    case INTEGRATION_SYSTEMS_CONNECTING_STATUSES.CONNECTED:
      return "Connected";

    case INTEGRATION_SYSTEMS_CONNECTING_STATUSES.PENDING:
      return "Pending";

    default:
      return "Disconnected";
  }
};

export const hasBCMSIntegration = (farmSystems: (GetFarmSystems_farmSystems | null)[]): boolean =>
  (farmSystems || []).some(
    (item) => item?.integrationSystem?.slug === "BCMS_INTEGRATION" && item.status === "CONNECTED",
  );
