import { gql } from "@apollo/client";

export const pastureTypeFragment = gql`
  fragment pastureTypeFragment on PastureType {
    id
    name
  }
`;

export const fieldFragment = gql`
  fragment fieldFragment on Field {
    id
    name
    description
    size
    sizeUnit
    isOrganic
    isYard
    isHoldingPen
    animals {
      id
    }
    location {
      country {
        id
        code
        name
      }
      id
      locationId
      unitName
    }
    pastureTypes {
      ...pastureTypeFragment
    }
    fieldDensity
    avgDlwg
    avgEstimatedWeight
    avgRecordedWeight
    avgAge
    summaryMetrics {
      breedrCode
      regBreedCode
      animalsCount
      maleCount
      femaleCount
      animalTypeId
    }
  }
  ${pastureTypeFragment}
`;
