import gql from "graphql-tag";

export const animal = gql`
  fragment animal on Animal {
    id
    animalBreeds {
      id
      name
      breedrCode
      newClassification
    }
    breedPercentages {
      breed {
        id
        name
        breedrCode
      }
      percentage
    }
    currentWeight
    dateMovedToFarm
    dob
    eId
    brucellosisId
    tattoo
    tsuBarcode
    pedigreeId
    trichId
    herdDoggId
    name
    breedrId
    uhfId
    alternativeId
    growthRate
    isCastrated
    latestPurchasePrice
    isMale
    isPregnant
    lastWeight {
      date
      value
    }
    lastRegulatorySyncedDate
    group {
      id
      name
    }
    field {
      id
      name
      location {
        unitName
      }
    }
    passportNumber
    performanceCategory
    pregnancyDueDate
    sexClassification {
      slug
      title
    }
    targetLiveWeight
    visualId
    isRegulatorySynced
    deliveryDate
    sirePassportNumber
    damPassportNumber
    damId
    linkToMySire {
      id
      name
      pedigree
      passportNumber
    }
    birthInformation {
      birthWeight
    }
    serviceType
    calvingEaseScore
    animalType {
      id
    }
    withdrawalEnd
    dateLeftFarm
    deadAt
    tags {
      color
      name
      tagId
      schema {
        ownership
      }
    }
    previousKeeper {
      name
      contact {
        id
      }
    }
    updatedAt
    parentageTrioVerified
    hasDnaResults
  }
`;
