import React from "react";
import { Icon } from "components/Common/Icon";
import { ICON_NAMES } from "constants/Icons";

type PillColours = "blue" | "green" | "red" | "yellow" | "gray" | "white";

export interface PillProps {
  caption: string;
  className?: string;
  colour?: PillColours;
  colourInverted?: boolean;
  icon?: keyof typeof ICON_NAMES;
  width?: "auto" | "full";
  wrap?: boolean;
  textColour?: "grey" | "white" | "black" | "red";
  textSize?: "xs" | "sm" | "md";
  onClick?: () => void;
}

const textColours = {
  grey: "text-gray-800",
  white: "text-white",
  black: "text-black",
  red: "text-red-800",
};

export const Pill: React.FC<PillProps> = ({
  caption,
  className,
  colour = "gray",
  colourInverted,
  icon,
  width = "auto",
  wrap,
  textColour = "grey",
  textSize = "sm",
  onClick,
}) => {
  const colourClassName = `bg-${colour}-100`;
  const invertedColourClassName = `bg-${colour}-800`;
  const widthClassName = width === "full" ? "block text-center" : "inline-block";
  const nowrapClassName = wrap ? "" : "whitespace-nowrap";
  const textSizeClassName = `text-${textSize}`;
  const classNames = `relative inline-block align-text-bottom p-1 rounded-md text-sm font-bold 
  ${textColours[textColour]} 
  ${colourInverted ? invertedColourClassName : colourClassName} 
  ${widthClassName} ${nowrapClassName} ${textSizeClassName} ${className}`;

  return (
    <span onClick={onClick} className={classNames}>
      {icon ? <Icon name={icon} size="small" /> : null}
      <span>
        <span className="pr-1">{caption}</span>
      </span>
    </span>
  );
};
