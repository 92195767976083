import { forwardRef } from "react";
import cn from "classnames";
import { Field, Input } from "components";
import inputStyles from "../Input/Input.module.scss";
import { FieldProps } from "..";

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean;
  resize?: boolean;
}

export const InputTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ name, hasError, resize, rows = 4, ...props }, ref) => {
    return (
      <Input hasError={hasError}>
        <textarea
          className={cn(
            inputStyles.input__native,
            inputStyles["input__native--text"],
            inputStyles["input__native--textarea"],
            {
              [inputStyles["input__native--resize-disabled"]]: !resize,
            },
          )}
          id={name}
          name={name}
          rows={rows}
          ref={ref}
          {...props}
        />
      </Input>
    );
  },
);

InputTextArea.displayName = "InputTextArea";

type FieldTextAreaProps = { inputProps: TextAreaProps } & FieldProps;

export const FieldTextArea = ({ error, inputProps, ...props }: FieldTextAreaProps) => (
  <Field error={error} labelFor={inputProps.name} {...props}>
    <InputTextArea hasError={!!error} {...inputProps} />
  </Field>
);

FieldTextArea.displayName = "FieldTextArea";
