import { useContext } from "react";
import { removeNothings } from "helpers/general";
import { Modal } from "components";
import { useMoveAnimalsToFieldMutation, useMoveGroupsToFieldMutation } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { TABLE_IDS } from "constants/Interface";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect } from "components/FormHookComponents/FormSelect";
import { FormDatePicker } from "components/FormHookComponents/FormDatePicker";
import { Button } from "twComponents/Button";
import { Form } from "components/FormHookComponents/Form";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";
import { useGetFields } from "hooks/useGetFields";
import { DateTime } from "luxon";

type AnimalId = string;

interface Props {
  entityToMove: { type: "group"; groupId: string } | { type: "animal"; ids: Array<AnimalId> };
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export const MoveToFieldModal = ({ entityToMove, showModal, setShowModal }: Props) => {
  const businessUnitId = getBUFromLocalStorage();
  const { setTableOptions } = useContext(TableOptionsContext);
  const { handleError, handleMutationResult } = useHandleMutationResult();

  const { fieldOptions, loading: isLoadingFieldOptions } = useGetFields();

  const [moveAnimalsToField, { loading: isMovingAnimals }] = useMoveAnimalsToFieldMutation();
  const [moveGroupToField, { loading: isMovingGroup }] = useMoveGroupsToFieldMutation();

  const onSubmit = async ({ date, fieldId }: Schema) => {
    try {
      if (entityToMove.type === "animal") {
        const result = await moveAnimalsToField({
          variables: {
            input: {
              animals: entityToMove.ids,
              businessUnit: businessUnitId,
              field: fieldId,
              date,
            },
          },
          refetchQueries: ["GetAnimalsPage", "GetAnimalById"],
        });

        const errors = result?.data?.moveAnimalsToField?.errors;

        handleMutationResult({
          successMessage: "Animal(s) successfully moved to location",
          errors: removeNothings(errors || []),
          onSuccess: () => {
            setTableOptions({
              id: TABLE_IDS.ANIMAL_LIST_SERVER,
              selectedRows: [],
              selectedRowsIds: new Set(),
            });

            setShowModal(false);
          },
        });
      } else {
        const result = await moveGroupToField({
          variables: {
            input: {
              groups: [entityToMove.groupId],
              businessUnit: businessUnitId,
              field: fieldId,
              date,
            },
          },
          refetchQueries: ["GetAnimalsPage", "GetAnimalById"],
        });

        const errors = result?.data?.moveGroupsToField?.errors;

        handleMutationResult({
          successMessage: "Group successfully moved to location",
          errors: removeNothings(errors || []),
          onSuccess: () => {
            setTableOptions({
              id: TABLE_IDS.ANIMAL_LIST_SERVER,
              selectedRows: [],
              selectedRowsIds: new Set(),
            });

            setShowModal(false);
          },
        });
      }
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <>
      <Modal
        active={showModal}
        handleClose={() => setShowModal(false)}
        subTitle={`Please select a location to move these animal(s) to`}
        title="Move to location"
      >
        <Form
          defaultValues={{ fieldId: "", date: DateTime.local().toJSDate() }}
          resolver={yupResolver(validationSchema)}
          onSubmit={onSubmit}
        >
          <FormSelect<Schema> label="Location *" options={fieldOptions} name="fieldId" placeholder="Select location" />
          <FormDatePicker<Schema> label="Date *" name="date" maxDate={new Date()} />
          <div className="flex justify-end mt-5">
            <Button
              type="button"
              disabled={isMovingAnimals || isMovingGroup}
              onClick={() => setShowModal(false)}
              variant="outline"
            >
              Cancel
            </Button>
            <Button type="submit" loading={isMovingAnimals || isMovingGroup || isLoadingFieldOptions} className="ml-2">
              Confirm
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

type Schema = yup.InferType<typeof validationSchema>;

const validationSchema = yup.object({
  fieldId: yup.string().required("Location is a required."),
  date: yup.date().required("Date is required."),
});
