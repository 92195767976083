import { Field, Flex, InputCheckbox } from "components";
import { AnimalType } from "trading/hooks/useGetCategoryListings";
import { LiveweightFiltersState } from "trading/hooks/useListingFilters/config";
import { ChangeEvent } from "react";

interface CategortyFilterProps {
  animalTypes: AnimalType[];
  handleChangeCategoryCheckbox: ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>) => void;
  filters: LiveweightFiltersState;
}

export const CategoryFilters: React.FC<CategortyFilterProps> = ({
  handleChangeCategoryCheckbox,
  animalTypes,
  filters,
}) => {
  return (
    <>
      {animalTypes?.map((animalType) => {
        return (
          <Field key={animalType?.id} labelFor="listingCategory" label={animalType?.name}>
            <Flex container containerDirection="column" containerWrap="nowrap">
              {animalType?.categoriesOfSale?.map((category) => {
                const isChecked = filters.category.showAll
                  ? true
                  : category?.id
                  ? filters.category.checkBoxes[category?.id]
                  : false;

                return category?.id && category?.category ? (
                  <Flex key={category?.id}>
                    <InputCheckbox
                      name={category?.id?.toString()}
                      label={category?.category}
                      onChange={handleChangeCategoryCheckbox}
                      checked={isChecked}
                    />
                  </Flex>
                ) : null;
              })}
            </Flex>
          </Field>
        );
      })}
    </>
  );
};
