import { FC, useEffect } from "react";
import { useTranslation } from "../../../helpers/translations/src";
import { useGetCurrentBusinessUnit } from "hooks";

export const BusinessUnitHandler: FC = () => {
  const { i18n } = useTranslation();
  const { countryIsoCode } = useGetCurrentBusinessUnit();

  //At the moment we are being very simple setting the language based on the BU country code.
  useEffect(() => {
    i18n.changeLanguage(countryIsoCode || "en");
  }, [countryIsoCode, i18n]);
  return null;
};
