import { useState, VFC } from "react";
// Types
import { BuyerListingPaymentModalProps } from "./BuyerListingPaymentModal";
// Components
import { BuyerListingPaymentModal } from "./BuyerListingPaymentModal";
import { Button, ButtonGroup } from "components";

type BuyerListingPaymentProps = Pick<BuyerListingPaymentModalProps, "listingReferenceCode" | "onNoFurtherAction"> & {
  handleOpenChat: () => void;
  offerId: string;
  currencyCode: string;
  offerPriceTotal: number;
};

export const BuyerListingPayment: VFC<BuyerListingPaymentProps> = ({
  onNoFurtherAction,
  handleOpenChat,
  offerId,
  currencyCode,
  offerPriceTotal,
  ...modalProps
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleOpenModal = (): void => {
    setIsActive(true);
  };

  const handleCloseModal = (): void => {
    setIsActive(false);
  };

  const handleAfterPayment = (): void => {
    handleCloseModal();
    onNoFurtherAction();
  };

  return (
    <>
      <ButtonGroup>
        <Button caption="Complete payment" onClick={handleOpenModal} />
        <Button id="ask-a-question" caption="Ask a question" variant="hollow" colour="grey" onClick={handleOpenChat} />
      </ButtonGroup>
      <BuyerListingPaymentModal
        isActive={isActive}
        onClose={handleCloseModal}
        onNoFurtherAction={handleAfterPayment}
        offerPriceTotal={offerPriceTotal}
        currencyCode={currencyCode}
        offerId={offerId}
        {...modalProps}
      />
    </>
  );
};
