import React, { useState } from "react";
// Utils
import { getFormattedPrice } from "helpers/general";
import { useGetBreedrBankDetails, useGetTermsConditions, useMarkAsPaidLotOfferMutation } from "hooks";
// Components
import { Flex, InputCheckbox, Modal, Spacer, Text, Title, WYSIWYG } from "components";

export interface BuyerListingPaymentModalProps {
  isActive?: boolean;
  listingReferenceCode: string;
  offerId: string;
  currencyCode: string;
  offerPriceTotal: number;
  onNoFurtherAction: () => void;
  onClose: () => void;
}

export const BuyerListingPaymentModal: React.FC<BuyerListingPaymentModalProps> = ({
  isActive,
  listingReferenceCode,
  onClose,
  onNoFurtherAction,
  offerId,
  currencyCode,
  offerPriceTotal,
}) => {
  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);
  const handleChangeAcknowledgement = (): void => setIsAcknowledged(!isAcknowledged);

  const { data: bankDetails } = useGetBreedrBankDetails();

  const { loading, mutate: markAsPaidLotOffer } = useMarkAsPaidLotOfferMutation({ offerId: Number(offerId) });
  const handleMarkAsPaid = async (): Promise<void> => {
    try {
      await markAsPaidLotOffer();
      onNoFurtherAction();
    } catch (error) {
      // eslint-disable-next-line
      console.log("markAsPaidLotOffer() error: ", error);
    }
  };

  const actions = {
    primary: {
      caption: "Mark as paid",
      disabled: !isAcknowledged || loading,
      onClick: handleMarkAsPaid,
      requesting: loading,
    },
    secondary: {
      caption: "Cancel",
      onClick: onClose,
    },
  };

  const formattedOfferPriceTotal = getFormattedPrice(offerPriceTotal, currencyCode);

  const { data } = useGetTermsConditions("MARKETPLACE_BUYER_PAID");
  const termsAndConditions = data?.termsAndConditions?.description;

  return (
    <Modal
      actions={actions}
      active={isActive}
      isChildrenGutterManual
      size="medium"
      title="Complete secure payment to Breedr"
    >
      {bankDetails ? (
        <>
          <Flex container>
            <Flex item itemGutter xs={3}>
              <Text>Sort code:</Text>

              <Title secondary>{bankDetails.sortCode}</Title>
            </Flex>

            <Flex item itemGutter xs={3}>
              <Text>Account Number:</Text>

              <Title secondary>{bankDetails.accountNo}</Title>
            </Flex>
          </Flex>

          <Spacer baselineHeight={2} />

          <Flex container>
            <Flex item itemGutter xs="fill">
              <Text>Total:</Text>

              <Title secondary>{formattedOfferPriceTotal}</Title>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex container>
          <Flex item itemGutter xs="fill">
            <Text>Payment details are not currently available.</Text>
          </Flex>
        </Flex>
      )}

      <Spacer baselineHeight={2} />

      <Flex container>
        <Flex item itemGutter xs="fill">
          <Text>Reference:</Text>

          <Spacer />

          <Text smallest>{listingReferenceCode}</Text>
        </Flex>
      </Flex>

      <Spacer baselineHeight={2} />

      <Flex container>
        <Flex item itemGutter xs="fill">
          <InputCheckbox
            checked={isAcknowledged}
            label="I acknowledge that I have paid Breedr"
            name="acknowledgement"
            onChange={handleChangeAcknowledgement}
          />

          {termsAndConditions ? (
            <>
              <Spacer baselineHeight={2} />

              <WYSIWYG content={termsAndConditions} size="small" />
            </>
          ) : null}
        </Flex>
      </Flex>
    </Modal>
  );
};
