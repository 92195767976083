import { Route } from "react-router-dom";
import { Groups } from "groups/pages/Groups";
import { CreateEditBatch } from "groups/pages/CreateEditBatch";
import {
  BATCH_VIEW,
  BU_ID,
  CREATE_BATCH,
  CREATE_GROUP,
  EDIT_BATCH,
  EDIT_GROUP,
  GROUPS,
  GROUP_VIEW,
} from "constants/Routes";
import { CreateEditGroup } from "groups/pages/CreateEditGroup";
import { Group } from "groups/pages/Group";

export const GROUPS_ROUTES_PATHS = {
  Groups: `${BU_ID}${GROUPS}`,
  CreateGroup: `${BU_ID}${CREATE_GROUP}`,
  EditGroup: `${BU_ID}${EDIT_GROUP}`,
  CreateBatch: `${BU_ID}${CREATE_BATCH}`,
  EditBatch: `${BU_ID}${EDIT_BATCH}`,
  Group: `${BU_ID}${GROUP_VIEW}`,
  Batch: `${BU_ID}${BATCH_VIEW}`,
} as const;

export const groupsRoutes = [
  <Route exact path={GROUPS_ROUTES_PATHS.Groups} key={GROUPS_ROUTES_PATHS.Groups} component={Groups} />,
  <Route
    exact
    path={GROUPS_ROUTES_PATHS.CreateGroup}
    key={GROUPS_ROUTES_PATHS.CreateGroup}
    component={CreateEditGroup}
  />,
  <Route exact path={GROUPS_ROUTES_PATHS.EditGroup} key={GROUPS_ROUTES_PATHS.EditGroup} component={CreateEditGroup} />,
  <Route
    exact
    path={GROUPS_ROUTES_PATHS.CreateBatch}
    key={GROUPS_ROUTES_PATHS.CreateBatch}
    component={CreateEditBatch}
  />,
  <Route exact path={GROUPS_ROUTES_PATHS.Group} key={GROUPS_ROUTES_PATHS.Group} component={Group} />,
  <Route exact path={GROUPS_ROUTES_PATHS.Batch} key={GROUPS_ROUTES_PATHS.Batch} component={Group} />,
  <Route exact path={GROUPS_ROUTES_PATHS.EditBatch} key={GROUPS_ROUTES_PATHS.EditBatch} component={CreateEditBatch} />,
];
