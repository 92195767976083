import React from "react";
import { Button, Flex, Paper, Spacer, Title } from "components";
import { ButtonProps } from "components/Common/Button";
import styles from "./Panel.module.scss";
import { StatusPill } from "../StatusPill";

export interface PanelProps {
  actions?: ButtonProps[];
  title?: string;
  pillText?: string;
  children?: React.ReactNode;
}

export const Panel: React.FC<PanelProps> = ({ actions = [], children, title, pillText }) => {
  return (
    <Paper>
      <Flex container containerDirection="column">
        <Flex item itemGutter>
          <Flex item itemGutter>
            <Spacer baselineHeight={2} />
            {title ? (
              <>
                <div className={styles.pill}>
                  <Title secondary>{title}</Title>
                  {pillText ? <StatusPill caption={pillText} colour="green" /> : null}
                </div>
                <Spacer baselineHeight={1} />
              </>
            ) : null}
            {children}
          </Flex>
          <Spacer baselineHeight={2} />
        </Flex>

        {!!actions.length ? (
          <>
            <Spacer border="top" baselineHeight={2} />
            <Flex container item itemGutter xs="fill" containerJustifyContent="flex-end">
              {actions.map(({ caption, colour, onClick, ...rest }) => (
                <Flex item itemGutter key={caption}>
                  <Button caption={caption} colour={colour} onClick={onClick} {...rest} />
                </Flex>
              ))}
              <Spacer baselineHeight={2} />
            </Flex>
          </>
        ) : null}
      </Flex>
    </Paper>
  );
};
