import React from "react";
import { useLocation } from "react-router-dom";
// Constants
import { BANK_DETAILS, SETTINGS, EDIT_BANK_DETAILS } from "constants/Routes";
// Components
import { Breadcrumbs, Header, PageContent, PageHeader, Spacer } from "components";
import { CreateEditBankDetailsForm } from "components/Settings";

const CreateEditBankDetails: React.FC = () => {
  const location = useLocation();
  const isEditable = location.pathname.includes(EDIT_BANK_DETAILS);

  const title = isEditable ? "Edit a bank details" : "Add a bank details";

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Bank details",
              route: `${SETTINGS}${BANK_DETAILS}`,
            },
          ]}
          currentPath={title}
        />
        <Header backButton title={title} />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />
        <CreateEditBankDetailsForm isEditable={isEditable} />
      </PageContent>
    </>
  );
};

export default CreateEditBankDetails;
