import { DropdownMenu, IconButton } from "components";
import React from "react";
import { FamilyTreeDisplayProps, FamilyTreeDisplayState } from "../types";

export const defaultDisplayState: FamilyTreeDisplayState = {
  orientation: "vertical",
  lineStyles: "diagonal",
  collapsible: true,
  zoomable: true,
  draggable: true,
};

interface MenuItem {
  name: string;
  title: string;
  value: string | boolean;
  icon: string;
}

export const FamilyTreeDisplaySettings: React.FC<FamilyTreeDisplayProps> = ({ displayState, setDisplayState }) => {
  const menuItems = [
    {
      orientation: [
        { name: "horizontal", title: "Horizontal", value: "horizontal", icon: "settings" },
        { name: "vertical", title: "Vertical", value: "vertical", icon: "settings" },
      ],
    },
    {
      lineStyles: [
        { name: "diagonal", title: "Diagonal", value: "diagonal", icon: "settings" },
        { name: "step", title: "Step", value: "step", icon: "settings" },
        { name: "elbow", title: "Elbow", value: "elbow", icon: "settings" },
        { name: "straight", title: "Straight", value: "straight", icon: "settings" },
      ],
    },
    {
      movement: [
        { name: "collapsible", title: "Collapsible", value: !displayState.collapsible, icon: "settings" },
        { name: "zoomable", title: "Zoomable", value: !displayState.zoomable, icon: "zoom" },
        { name: "draggable", title: "Draggable", value: !displayState.draggable, icon: "drag" },
      ],
    },
  ] as const;

  const handleButtonClick = (event: React.ChangeEvent<HTMLButtonElement>): void => {
    const { name, value } = event.currentTarget;
    setDisplayState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetFilters = (): void => {
    setDisplayState(defaultDisplayState);
  };

  const isActive = (item: MenuItem, buttonGroup: string) => {
    return buttonGroup === "movement" ? displayState[item.name] : displayState[buttonGroup] === item.value;
  };

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <IconButton caption="Settings" iconName={"settings"} colour="grey" square size="x-small" />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {menuItems.flatMap((menuItem, index) => (
          <React.Fragment key={index}>
            {Object.keys(menuItem).flatMap((buttonGroup) =>
              menuItem[buttonGroup].map((item: MenuItem) => (
                <DropdownMenu.Action
                  bgColour={isActive(item, buttonGroup) ? "#C8DFFB" : ""}
                  key={item.name}
                  closeOnClick={false}
                  onClick={() =>
                    handleButtonClick({
                      currentTarget: {
                        name: buttonGroup === "movement" ? item.name : buttonGroup,
                        value: item.value,
                      },
                    } as React.ChangeEvent<HTMLButtonElement>)
                  }
                  text={item.title}
                />
              )),
            )}
            <DropdownMenu.Separator />
          </React.Fragment>
        ))}
        <DropdownMenu.Action onClick={handleResetFilters} text="Default" />
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};
