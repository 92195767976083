// API
import { MyLot_myLot_deliveryAddress } from "trading/api/Liveweight/types/MyLot";
import { MyLots_myLots_edges_node_deliveryAddress } from "trading/api/Liveweight/types/MyLots";

export const getListingAddressPrivate = (
  address: MyLot_myLot_deliveryAddress | MyLots_myLots_edges_node_deliveryAddress,
): string | undefined => {
  const businessUnitName = address?.unitName;
  const locationId = address?.locationId;

  if (businessUnitName && locationId) return `${businessUnitName}, ${locationId}`;

  if (businessUnitName) return businessUnitName;

  if (locationId) return locationId;

  return;
};
