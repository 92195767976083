import { useContext, useEffect, useMemo, useState } from "react";
// Libraries
import { sortBy, trimEnd } from "lodash";
// API
import { GetActivityTemplates_activityTemplates } from "api/MyLivestock/Activity/types/GetActivityTemplates";
// Utils
import { CommonContext } from "config/commonProvider";
// Types
import { Hacky_UploadCsv_uploadCsv } from "../../";
import { BatchUploadFormat } from "hooks/useGetBatchUploadFormats";

interface FieldOption {
  field: string;
  key: string;
  label: string;
}

interface UseColumnFieldMapParams {
  activityTemplate?: GetActivityTemplates_activityTemplates;
  batchUploadFormat: BatchUploadFormat | undefined;
  file: Hacky_UploadCsv_uploadCsv;
  isFirstRowColumnNames: boolean;
}

interface UseColumnFieldMapReturn {
  columnFieldMap: string[];
  fieldOptions: FieldOption[];
  isMatchColumnsDisabled: boolean;
  isRequiredFieldMissing: boolean;
  matchColumns: () => void;
  setColumn: (selectedColumnIndex: number, selectedFieldId: string) => void;
}

export const useColumnFieldMap = ({
  batchUploadFormat,
  file,
  isFirstRowColumnNames,
}: UseColumnFieldMapParams): UseColumnFieldMapReturn => {
  // Base state
  const defaultColumnField = {
    field: "__skip__",
    key: "__skip__",
    label: "--IGNORE--",
  };
  const initialColumnFields = file.rows[0].map(() => defaultColumnField.field);
  const [columnFieldMap, setColumnFieldMap] = useState<string[]>(initialColumnFields);

  useEffect(() => setColumnFieldMap(initialColumnFields), [batchUploadFormat]);

  const fieldOptions = useMemo(() => {
    const selectedFormatColumns =
      batchUploadFormat?.columns.map((field) => ({
        field: field.name,
        key: field.name,
        label: `${field.label}${field.isRequired ? "*" : ""}`,
      })) || [];

    // we only want to sort field options alphabetically for animal imports
    const sortedSelectedFormatColumns = batchUploadFormat
      ? selectedFormatColumns
      : sortBy(selectedFormatColumns, "label");

    return [defaultColumnField, ...sortedSelectedFormatColumns];
  }, [batchUploadFormat]);

  const fileRowsCount = file.totalRows || 0;
  const isEmptyFile = fileRowsCount === 0;
  const isFileHeadersOnly = isFirstRowColumnNames && fileRowsCount === 1;
  const requiredFieldNames = useMemo(
    () => batchUploadFormat?.columns.filter((field) => field.isRequired).map((field) => field.name) || [],
    [batchUploadFormat],
  );
  const isRequiredFieldMissing =
    isEmptyFile ||
    isFileHeadersOnly ||
    requiredFieldNames.length === 0 ||
    !requiredFieldNames.every((requiredFieldName) => columnFieldMap.includes(requiredFieldName));

  // Automatic column matching
  const { showNotification } = useContext(CommonContext);
  const [isMatchColumnsDisabled, setIsMatchColumnsDisabled] = useState(false);
  const handleMatchColumns = () => {
    const newColumnFieldMap = file.rows[0].map((columnLabel) => {
      const match = fieldOptions.find(
        (fieldOption) => trimEnd(fieldOption.label, "*").toLowerCase() === columnLabel.toLowerCase(),
      );

      return match?.field || defaultColumnField.field;
    });
    const matchedColumnCount = newColumnFieldMap.filter((fieldId) => fieldId !== defaultColumnField.field).length;

    if (matchedColumnCount) {
      setColumnFieldMap(newColumnFieldMap);
      setIsMatchColumnsDisabled(true);
      showNotification({ message: `Successfully matched ${matchedColumnCount} columns` });
    } else {
      showNotification({
        message: "We were unable to match any columns in your file",
        variant: "error",
      });
    }
  };

  useEffect(() => setIsMatchColumnsDisabled(false), [fieldOptions]);

  // Manual column changing
  const handleSetColumn = (selectedColumnIndex: number, selectedFieldName: string) => {
    setColumnFieldMap(
      columnFieldMap.map((fieldName, columnIndex) => {
        if (columnIndex === selectedColumnIndex) return selectedFieldName;

        if (selectedFieldName === fieldName) return defaultColumnField.field;

        return fieldName;
      }),
    );
    setIsMatchColumnsDisabled(false);
  };

  return {
    columnFieldMap,
    fieldOptions,
    isMatchColumnsDisabled,
    isRequiredFieldMissing,
    matchColumns: handleMatchColumns,
    setColumn: handleSetColumn,
  };
};
