import React from "react";
import api from "api/config";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import {
  ID_PREFERENCES,
  ADDRESS_BOOK,
  BUSINESS_SETTINGS,
  EMAIL_SETTINGS,
  MANAGEMENT_SETTINGS,
  SECURITY_SETTINGS,
  SETTINGS,
  SIRES,
  SUPPLIER_MANAGEMENT,
  USER_SETTINGS,
  BANK_DETAILS,
} from "constants/Routes";
import { useGetBusinessUnitUserRole, useHasFeature } from "hooks";
import { PageMenu, PageMenuLink } from "components";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";
import { getBUFromLocalStorage } from "helpers/storage";
import { useSubscriptionStatusQuery } from "generated/graphql";

interface SettingsMenuProps {
  farmer: boolean;
}
export const SettingsMenu: React.FC<SettingsMenuProps> = ({ farmer }) => {
  const { isAdmin, isManager } = useGetBusinessUnitUserRole();
  const { terms } = useLocale();
  const businessUnitId = getBUFromLocalStorage();

  const { data: subscriptionStatusData } = useSubscriptionStatusQuery({
    variables: { businessUnitId },
    fetchPolicy: "cache-and-network",
  });

  const isSubscribed = subscriptionStatusData?.subscriptionStatus?.isSubscribed;
  const hasStripeState = subscriptionStatusData?.subscriptionStatus?.hasStripeState;

  // INFO: Some users and enterprise clients have been signed up by Breedr admins
  // without going through the Stripe checkout process.
  // We don't expose Stripe content for them.
  const isNotSubscribedThroughStripe = isSubscribed && !hasStripeState;

  type SubMenuLink = {
    caption: string;
    path: string;
    visible?: boolean;
  };

  const subMenuItems: SubMenuLink[] = [
    {
      caption: "My Settings",
      path: `${SETTINGS}${USER_SETTINGS}`,
    },
    {
      caption: "Email Settings",
      path: `${SETTINGS}${EMAIL_SETTINGS}`,
    },
    {
      caption: "Security Settings",
      path: `${SETTINGS}${SECURITY_SETTINGS}`,
    },
    {
      caption: "Sire Information",
      path: SIRES,
      visible: farmer && useHasFeature("SIRES"),
    },
    {
      caption: farmer ? `${capitaliseFirstLetter(terms.farm)} Settings` : "Business Settings",
      path: `${SETTINGS}${BUSINESS_SETTINGS}`,
      visible: isAdmin,
    },
    {
      caption: "Animal IDs",
      path: `${SETTINGS}${ID_PREFERENCES}`,
      visible: isAdmin || isManager,
    },
    {
      caption: "User Management",
      path: `${SETTINGS}${MANAGEMENT_SETTINGS}`,
      visible: isAdmin,
    },
    {
      caption: "Supplier Management",
      path: SUPPLIER_MANAGEMENT,
      visible: isAdmin && useHasFeature("SUPPLIER_MANAGEMENT"),
    },
    {
      caption: "Address Book",
      path: `${SETTINGS}${ADDRESS_BOOK}`,
      visible: useHasFeature("SHOW_ADDRESS_BOOK"),
    },
    {
      caption: "Bank Details",
      path: `${SETTINGS}${BANK_DETAILS}`,
      visible: (isAdmin || isManager) && useHasFeature("BANK_DETAILS"),
    },
    {
      caption: "Subscription Details",
      path: `${SETTINGS}/subscription`,
      visible: isAdmin && useHasFeature("SHOW_PAYWALL") && !isNotSubscribedThroughStripe,
    },
    {
      caption: "Integrations",
      path: `${SETTINGS}/integrations`,
      visible: isAdmin && useHasFeature("SHOW_INTEGRATIONS"),
    },
  ];

  return (
    <PageMenu title="Settings" subtitle={`v${api.version}`}>
      {subMenuItems.map(
        ({ caption, path, visible = true }) =>
          visible && (
            <PageMenuLink caption={caption} id={`${ELEMENTS_IDS.PAGE_MENU_LINK}_${caption}`} key={path} path={path} />
          ),
      )}
    </PageMenu>
  );
};
