// Types
import { GetInventoryInfo_inventoryInfo } from "api/Medicine/types/GetInventoryInfo";
import { InputSelectOption } from "components/Common/Field/Select";
// Helpers
import { isKilos, isLitres, medicineUnits } from "../utils";
import { getBatchUnit } from "../getBatchUnit";

/**
 * @description get unit options for a batch based on default unit
 * @param batch the batch object
 * @returns array of unit list select options
 */
export const getUnitOptions = (batch: GetInventoryInfo_inventoryInfo): InputSelectOption[] | null => {
  const unit = getBatchUnit(batch);

  if (isLitres(unit)) return medicineUnits.litres;
  if (isKilos(unit)) return medicineUnits.kgs;

  return null;
};
