import { useQuery } from "@apollo/client";
// API
import { GET_ANIMAL_SEX_CLASSIFICATIONS } from "api/MyLivestock/Animal/queries";
import {
  AnimalSexClassifications,
  AnimalSexClassifications_sexClassifications,
  AnimalSexClassificationsVariables,
} from "api/MyLivestock/Animal/types/AnimalSexClassifications";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";
import { useStateSpecies } from "./useStateSpecies";

interface UseGetSexClassificationsParams {
  forAllSpecies?: boolean;
}

export const useGetSexClassifications = ({ forAllSpecies }: UseGetSexClassificationsParams = {}) => {
  const { activeSpecies } = useStateSpecies();

  const { data, ...rest } = useQuery<AnimalSexClassifications, AnimalSexClassificationsVariables>(
    GET_ANIMAL_SEX_CLASSIFICATIONS,
    {
      variables: {
        businessUnitId: getBUFromLocalStorage(),
        animalTypeIds: !forAllSpecies && activeSpecies ? [+activeSpecies.id] : null,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    },
  );

  const sexClasses = (data?.sexClassifications || []) as AnimalSexClassifications_sexClassifications[];

  return {
    data: sexClasses,
    ...rest,
  };
};
