import { useBreakpoints } from "hooks/useBreakpoints";
import styles from "./FormDivider.module.scss";

interface Props {
  label: string;
}

export const FormDivider = ({ label }: Props) => {
  const { isBreakpointL } = useBreakpoints();

  if (isBreakpointL) {
    return <span className={styles.label}>{label}</span>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.line} />
      <span className={styles.label}>{label}</span>
      <div className={styles.line} />
    </div>
  );
};
