export const STAGE_STATUSES = {
  ACTION: "action",
  COMPLETE: "complete",
  FAILED: "failed",
  PENDING: "pending",
} as const;

export const PRIVATE_STAGE_STATUSES = {
  NEUTRAL: "neutral",
};

export const STAGE_STATUS_PILL_COLOURS = {
  action: "yellow",
  complete: "green",
  failed: "red",
  neutral: "grey",
  pending: "blue",
};
