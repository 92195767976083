import { LOT_STATUSES } from "trading/constants";

export const isListingSold = (status: string): boolean => {
  return [
    LOT_STATUSES.BUYER_RECEIVED_ANIMALS,
    LOT_STATUSES.BREEDR_MADE_PAYMENT,
    LOT_STATUSES.SOLD,
    LOT_STATUSES.CLOSED,
  ].includes(status);
};
