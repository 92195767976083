import React from "react";
// Utils
import { getSexClassDetails } from "./helpers";
import { useHasFeature } from "hooks";
// Components
import { IconLabel } from "components";

export interface AnimalsSexClassLabelProps {
  id?: string;
  isCastrated?: boolean;
  isMale?: boolean;
  label: string | null;
}

export const AnimalsSexClassLabel: React.FC<AnimalsSexClassLabelProps> = ({ id, isCastrated, isMale, label }) => {
  const hasSexClassificationFeature = useHasFeature("SHOW_SEX_CLASSIFICATION");
  const basicSexClassLabel = isMale ? "M" : "F";

  if (!hasSexClassificationFeature) {
    return <>{basicSexClassLabel}</>;
  }

  const { colour, icon } = getSexClassDetails(isCastrated, isMale);
  const sexLabel = label ? label : basicSexClassLabel;

  return <IconLabel colour={colour} id={id} icon={icon} label={sexLabel} />;
};
