import React, { useState } from "react";
// Libraries
import { Formik, FormikProps } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
// API
import { CreateOfferForLot, CreateOfferForLotVariables } from "trading/api/Liveweight/types/CreateOfferForLot";
import { TermsAndConditions, TermsAndConditionsVariables } from "trading/api/Liveweight/types/TermsAndConditions";
import { GET_TERMS_AND_CONDITIONS } from "trading/api/Liveweight/queries";
import { CREATE_OFFER_FOR_LOT } from "trading/api/Liveweight/mutations";
// Components
import { Flex, Modal } from "components";
import { Type } from "./Type";
import { OfferPerKg, OfferPerHead } from "./Make";
import { Review } from "./Review";
import { OfferComplete } from "./Complete";
import { Terms } from "./Terms";
// Utils
import { getFormattedPrice } from "helpers/general";
import { getBUFromLocalStorage } from "helpers/storage";
import { twoDecimalPlaces } from "trading/util";
import { useGetCurrentBusinessUnit } from "hooks";
import {
  makeOfferCollectionDate,
  makeOfferPerKgTotal,
  makeOfferPrice,
  makeOfferPriceTotal,
  makeOfferType,
} from "state";
import { useRecoilValue } from "recoil";
import { OfferTypes } from "trading/constants";

const validationSchema = Yup.object().shape({
  offerPrice: Yup.number()
    .required("Offer price is required")
    .default(0)
    .min(0)
    .moreThan(-1, "Enter valid Amount")
    .typeError("Enter valid Amount")
    .test("2DP", "No more than 2 decimal places", (value) => value >= 0 && twoDecimalPlaces(value)),
});

export interface FormValues {
  offerPrice: number | string;
  collectionDate: Date | null;
  allAnimals: boolean;
  terms: boolean;
}

export enum OfferStatuses {
  Type = "Type",
  Make = "Make",
  Review = "Review",
  Completed = "Completed",
  Terms = "Terms",
}

const modalTitles = {
  [OfferStatuses.Type]: "Choose type of offer",
  [OfferStatuses.Make]: "Make offer",
  [OfferStatuses.Review]: "Review and confirm offer",
  [OfferStatuses.Completed]: "Thanks! We've sent your offer",
  [OfferStatuses.Terms]: "Terms and Conditions",
};

export type FormProps = FormikProps<FormValues>;

export interface BuyerListingOfferModalProps {
  isActive: boolean;
  listingId: number;
  listingName?: string;
  quantity: number;
  onClose: () => void;
  onNoFurtherAction: () => void;
  askingPricePerHead?: number | null;
  pricePerKgOnCreation?: number | null;
}

export const BuyerListingOfferModal: React.FC<BuyerListingOfferModalProps> = ({
  isActive,
  listingId,
  askingPricePerHead = 0,
  pricePerKgOnCreation = 0,
  quantity,
  onClose,
  onNoFurtherAction,
}) => {
  const [status, setStatus] = useState<OfferStatuses>(OfferStatuses.Type);

  const [disabled, setDisabled] = useState<boolean>(false);

  const offerType = useRecoilValue(makeOfferType);
  const offerPerKgTotal = useRecoilValue(makeOfferPerKgTotal);
  const offerCollectionDate = useRecoilValue(makeOfferCollectionDate);
  const offerPrice = useRecoilValue(makeOfferPrice);
  const offerPriceTotal = useRecoilValue(makeOfferPriceTotal);

  const askingPrice = offerType === OfferTypes.HEAD ? askingPricePerHead : pricePerKgOnCreation;
  const [apiErrors, setApiErrors] = useState<string>("");

  const { currencyCodeISO } = useGetCurrentBusinessUnit();
  const formattedOfferPrice = (offerPrice && getFormattedPrice(+offerPrice, currencyCodeISO)) || "";

  const formattedOfferPriceTotal =
    (offerPriceTotal && getFormattedPrice(+offerPriceTotal, currencyCodeISO)) || "\u2014";

  const initialValues: FormValues = {
    offerPrice: "",
    collectionDate: null,
    allAnimals: false,
    terms: false,
  };

  const [createOfferMutation, { loading: createOfferLoading }] = useMutation<
    CreateOfferForLot,
    CreateOfferForLotVariables
  >(CREATE_OFFER_FOR_LOT);

  const { data: termsData } = useQuery<TermsAndConditions, TermsAndConditionsVariables>(GET_TERMS_AND_CONDITIONS, {
    variables: {
      tacType: "MARKETPLACE_BUYER",
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const hasTerms = !!termsData?.termsAndConditions?.description;

  const handleSubmitOffer = async (): Promise<void> => {
    try {
      const { data } = await createOfferMutation({
        variables: {
          input: {
            businessUnit: getBUFromLocalStorage(),
            lot: listingId || 0,
            priceUnit: offerType,
            price: offerPrice,
            totalPriceOnCreation: offerType === OfferTypes.HEAD ? offerPriceTotal : offerPerKgTotal,
            collectionDate: offerCollectionDate,
            hasAcceptedListing: true,
          },
        },
      });
      if (data?.createOfferForLot?.errors) {
        setApiErrors(data?.createOfferForLot?.errors?.map((item) => item?.message).join("; ") || "");
      } else {
        setStatus(OfferStatuses.Completed);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  const modalActions = {
    [OfferStatuses.Type]: {
      primary: {
        caption: "Next",
        disabled: false,
        requesting: false,
        onClick: (): void => setStatus(OfferStatuses.Make),
      },
      secondary: {
        caption: "Cancel",
        onClick: onClose,
      },
    },
    [OfferStatuses.Make]: {
      primary: {
        caption: "Review",
        disabled: disabled,
        requesting: false,
        onClick: (): void => setStatus(OfferStatuses.Review),
      },
      secondary: {
        caption: "Back",
        onClick: (): void => setStatus(OfferStatuses.Type),
      },
    },
    [OfferStatuses.Review]: {
      primary: {
        caption: apiErrors ? "Back to listing" : "Submit offer",
        disabled: !hasTerms || disabled || createOfferLoading,
        requesting: createOfferLoading,
        onClick: apiErrors ? onNoFurtherAction : handleSubmitOffer,
      },
      secondary: {
        caption: hasTerms ? "Edit offer" : "Back to listing",
        onClick: hasTerms ? (): void => setStatus(OfferStatuses.Make) : onClose,
      },
    },
    [OfferStatuses.Completed]: {
      primary: {
        caption: "Close",
        disabled: false,
        onClick: onNoFurtherAction,
      },
    },
    [OfferStatuses.Terms]: {
      primary: {
        caption: "Close",
        onClick: (): void => setStatus(OfferStatuses.Review),
      },
    },
  };

  return (
    <Modal
      active={isActive}
      handleClose={onClose}
      isChildrenGutterManual
      title={modalTitles[OfferStatuses[status]]}
      actions={modalActions[OfferStatuses[status]]}
    >
      <Flex container>
        <Formik
          enableReinitialize
          onSubmit={onClose}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
        >
          {({ values, errors, handleChange, setFieldValue }: FormProps): JSX.Element => {
            if (status === "Review") {
              return (
                <Review
                  values={values}
                  apiError={apiErrors}
                  errors={errors}
                  handleChange={handleChange}
                  setDisabled={setDisabled}
                  setFieldValue={setFieldValue}
                  quantity={quantity}
                  handleModalToggle={(): void => setStatus(OfferStatuses.Terms)}
                  hasTerms={hasTerms}
                />
              );
            }
            if (status === "Completed") {
              return (
                <OfferComplete
                  quantity={quantity}
                  formattedOfferPrice={formattedOfferPrice}
                  formattedOfferPriceTotal={formattedOfferPriceTotal}
                />
              );
            }
            if (status === "Terms") {
              return <Terms termsData={termsData} />;
            }
            if (status === "Make") {
              return offerType === OfferTypes.HEAD ? (
                <OfferPerHead
                  errors={errors}
                  setFieldValue={setFieldValue}
                  quantity={quantity}
                  setDisabled={setDisabled}
                  handleChange={handleChange}
                  askingPrice={askingPrice}
                  askingPricePerHead={askingPricePerHead}
                  listingId={listingId}
                  values={values}
                />
              ) : (
                <OfferPerKg
                  errors={errors}
                  setFieldValue={setFieldValue}
                  quantity={quantity}
                  setDisabled={setDisabled}
                  askingPrice={askingPrice}
                  listingId={listingId}
                  values={values}
                  askingPricePerHead={askingPricePerHead}
                  status={status}
                />
              );
            }
            return (
              <Type
                quantity={quantity}
                askingPricePerHead={askingPricePerHead}
                pricePerKgOnCreation={pricePerKgOnCreation}
                setFieldValue={setFieldValue}
              />
            );
          }}
        </Formik>
      </Flex>
    </Modal>
  );
};
