import { InputSelectOptionWithTitles } from "components/Common/Field/Select";
import { GetBatchUploadFormatsQuery, useGetBatchUploadFormatsQuery } from "generated/graphql";
import { removeNothings } from "helpers/general";
import { getBUFromLocalStorage } from "helpers/storage";
import { useGetActivityTypes } from "hooks/useGetActivityTypes";
import { sortBy } from "lodash";
import { useMemo } from "react";

export type BatchUploadFormat = NonNullable<
  NonNullable<NonNullable<GetBatchUploadFormatsQuery>["batchUploadFormats"]>[0]
>;

export const useGetBatchUploadFormats = () => {
  const { data: activityTypes } = useGetActivityTypes();

  const { data, ...rest } = useGetBatchUploadFormatsQuery({
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
  });

  // Filter out animal upload formats for select dropdown
  const batchUploadFormatsWithoutAnimalUploads = removeNothings(data?.batchUploadFormats || []).filter(
    (uploadFormat) => {
      const isAnimalUploadFormat = !uploadFormat.activityType && uploadFormat.animalTypes.length > 0;

      return !isAnimalUploadFormat;
    },
  );

  const batchUploadFormats = useMemo(() => removeNothings(data?.batchUploadFormats || []), [data?.batchUploadFormats]);

  const uploadFormatOptions = activityTypes.reduce<InputSelectOptionWithTitles[]>((accumulator, activityType) => {
    const uploadFormatsWithinType = sortBy(
      batchUploadFormatsWithoutAnimalUploads.filter(
        (uploadFormat) => uploadFormat.activityType?.id === activityType.id,
      ),
      ["name"],
    );
    if (uploadFormatsWithinType.length) {
      accumulator.push({
        key: activityType.id,
        label: activityType.name,
      });
      uploadFormatsWithinType.forEach((uploadFormat) => {
        accumulator.push({
          key: uploadFormat.slug,
          value: uploadFormat.slug,
          label: uploadFormat.name,
        });
      });
    }
    return accumulator;
  }, []);

  return {
    data,
    batchUploadFormats,
    uploadFormatOptions,
    ...rest,
  };
};
