import { FieldValues, Path, useFormContext } from "react-hook-form";
import { cn } from "utils/misc";
import styles from "components/Common/Field/Radio/InputRadio.module.scss";

type Props<TFieldValues extends FieldValues> = {
  id: string;
  name: Path<TFieldValues>;
  label: string;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, "className" | "id" | "value" | "disabled">;

export const FormRadio = <TFieldValues extends FieldValues>({
  name,
  label,
  value,
  id,
  className,
  ...rest
}: Props<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  if (!methods) {
    throw new Error("FormRadio must be used inside a <Form /> or <FormProvider />");
  }

  return (
    <label htmlFor={id} className={cn(styles.input_radio__label)}>
      <input
        id={id}
        className={cn(styles.input_radio, className, "disabled:cursor-not-allowed")}
        type="radio"
        value={value}
        {...(methods?.register && methods.register(name))}
        {...rest}
      />
      {label}
    </label>
  );
};
