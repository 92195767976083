import { gql } from "@apollo/client";

export const BU_FULL_FIELDS = gql`
  fragment businessUnitFull on BusinessUnit {
    id
    name
    profilePhoto
    warnings
    tbStatus
    postcode
    settings {
      sizeUnit
    }
    growthStage
    phone
    postcode
    organisation {
      id
    }
    canCreateRfl
    tradingTypes {
      id
      slug
      name
    }
    businessType {
      id
      name
      slug
    }
    locationsCount
    foggles
    regulatorySystem {
      id
      slug
      name
    }
    birthDataForm {
      id
      label
      type
      required
      maxDigits
      editable
      minValue
      maxValue
      choices {
        value
        label
      }
    }
    address
    regulatorySystemAvailable
    requireBirthData
    deliveryAddress {
      addressLine1
      addressLine2
      city
      country {
        id
        name
      }
      id
      isDeleted
      locationId
      postcode
      primary
      unitName
      pointLocation
    }
    killOut
    statuses {
      id
      name
    }
    primaryLocation {
      id
      locationId
      addressLine1
      addressLine2
      city
      postcode
    }
    currency {
      id
      name
      code
    }
    description
    unitsOfMeasurement {
      id
      code
      measurementType
    }
    farmId
    lotExpiryInDays
    mobile
    country {
      id
      name
      code
      subCode
      isoNumber
      isoAlpha2
      isoAlpha3
      visualIdPrefix
      region {
        code
        name
      }
    }
    farmAnimalTypes {
      id
      name
      slug
      colour
      image
      position
      enabled
      batchName {
        name
        plural
      }
    }
    subscriptionPlan {
      subscriptionPlan {
        name
      }
    }
    effectiveSubscriptionPlan {
      name
    }
    subscriptionPlanFeatures {
      slug
      isActive
    }
    identifierPreferencesActiveIds {
      alternativeId
      passportNumber
      visualId
      eId
      name
      trichId
      tsuBarcode
      pedigreeId
      tattoo
      herdDoggId
      breedrId
      uhfId
      brucellosisId
      alternativeId
    }
    identifierPreferencesPrimaryId
    identifierPreferencesSecondaryId
    herdDoggIntegration {
      isConnected
    }
    tzName
  }
`;
