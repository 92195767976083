import React from "react";
import { Controller, PathValue } from "react-hook-form";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Field } from "components/Common/Field";
import { Input } from "components/Common/Field/Input";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import getSymbolFromCurrency from "currency-symbol-map";

type Props<TFieldValues extends FieldValues> = CurrencyInputProps & {
  name: Path<TFieldValues>;
  label: string;
  disabled?: boolean;
};

export const FormPriceInput = <TFieldValues extends FieldValues>({
  name,
  label,
  disabled,
  placeholder,
}: Props<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();
  const { currencyCodeISO } = useGetCurrentBusinessUnit();

  if (!methods) {
    throw new Error("FormPriceInput must be used inside a <Form /> or <FormProvider />");
  }

  const { control } = methods;

  const errorMessage = methods?.formState.errors[name]?.message;

  return (
    <Field error={(errorMessage as string) || ""} label={label} labelFor={name}>
      <Input
        hasError={!!errorMessage}
        adornmentLeft={{ label: getSymbolFromCurrency(currencyCodeISO) || "", labelFor: name }}
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { value } }) => (
            <CurrencyInput
              id={name}
              allowNegativeValue={false}
              step={0.01}
              className="w-full bg-transparent border-none h-8 py-0 px-3 text-black text-xs focus:outline-none disabled:hover:cursor-not-allowed disabled:opacity-50"
              min={0}
              maxLength={12}
              onValueChange={(value) => {
                methods.setValue(name, value as PathValue<TFieldValues, Path<TFieldValues>>);
              }}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
            />
          )}
        />
      </Input>
    </Field>
  );
};
