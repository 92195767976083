import React from "react";
import { useHistory } from "react-router";
import { CREATE_FIELD } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useLivestockTabs, useStateSpecies } from "hooks";
import { LoadingOverlay, NoItemsFound, Header, PageContent, PageHeader, Spacer, Tabs, EmptyState } from "components";
import { useFieldsQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { getBUFromLocalStorage } from "helpers/storage";
import styles from "./Fields.module.scss";
import { FieldsTable } from "components/MyLivestock/Fields/FieldTable";

const Fields: React.FC = () => {
  const { activeSpecies } = useStateSpecies();
  const LIVESTOCK_TABS = useLivestockTabs();
  const speciesActiveName = activeSpecies?.name || "Livestock";
  const title = `My ${speciesActiveName}`;
  const subTitle = `View and manage your ${speciesActiveName.toLowerCase()}`;
  const history = useHistory();
  const { data: fieldsData, loading } = useFieldsQuery({
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const fields = removeNothings(fieldsData?.fields || []);

  const isEmptyStateVisible = !loading && !fields.length;

  return (
    <>
      <PageHeader>
        <Header title={title} subTitle={subTitle} />

        <Tabs tabs={LIVESTOCK_TABS} selectedTab={2} />
      </PageHeader>

      <PageContent
        floatingIconTextButton={{
          id: ELEMENTS_IDS.ADD_FIELD_PLUS_BUTTON,
          iconName: "plus",
          text: "Create",
          onClick: (): void => history.push(CREATE_FIELD),
        }}
      >
        {loading && fields?.length === 0 ? <LoadingOverlay customStyles={styles.loadingOverlay} /> : null}

        <Spacer baselineHeight={2} />

        {isEmptyStateVisible ? (
          <EmptyState page="FIELDS" />
        ) : (
          <>
            {!loading && fields.length === 0 ? <NoItemsFound title="No fields found" /> : null}
            <FieldsTable fields={fields} />
          </>
        )}
      </PageContent>
    </>
  );
};

export default Fields;
