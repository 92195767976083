import * as Yup from "yup";
import { DateTime } from "luxon";

import { WEIGHT_ACTIVITY_SLUG, LIVESTOCK_SUBTABS, ANIMAL_FILTER_KEYS } from "constants/Animals";
import { GROUPS, TRADING, ANIMALS, FIELDS } from "constants/Routes";
import { EDIT_ANIMALS_IN_GROUP_TYPES } from "constants/Groups";

import { getFeaturesListFromLocalStorage, getLocationsCountToLocalStorage } from "helpers/storage";
import { FEATURES } from "constants/Features";

import { GetActivityTypes_activityTypes } from "api/MyLivestock/Activity/types/GetActivityTypes";
import { ActivityTemplateFieldUnits } from "api/graphql-global-types";
import { ActivityTypes } from "components/MyLivestock/Activities/AnimalHistory/AnimalHistoryFilters";
import { GetAnimalsList_animalsExtended_animals_animalBreeds } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { SummaryMetrics, BreedPercentage, AnimalBreed } from "generated/graphql";
import { GetFormatDate } from "hooks/useFormatDate";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { LivestckIcon } from "constants/SvgIcons";

export type LivestockTab = {
  key: number;
  name: string;
  route: string;
  feature: string;
};

export type LivestockTabs = LivestockTab[];

export const validationSchemaCreateField = Yup.object().shape({
  name: Yup.string().required("Location name is required!"),
  location: Yup.string().required("Holding Location is required!"),
});

export const getAnimalViewBreadCrumbs = (
  url: string,
): {
  path: string;
  route?: string;
}[] => {
  const fromPath = url.split("/");
  const listingPath = fromPath[2].split("-");

  if (listingPath.length > 0 && listingPath[0] === "listing") {
    return [
      {
        path: `Listing ${listingPath[1]}`,
      },
    ];
  }

  switch (fromPath[2]) {
    case "trading":
      return [
        {
          path: "Active trades",
          route: TRADING,
        },
        {
          path: "Request for Livestock",
        },
      ];

    case "group":
      return [
        {
          path: "Groups",
          route: GROUPS,
        },
        {
          path: "Group",
        },
      ];
    case "location":
      return [
        {
          path: "Locations",
          route: FIELDS,
        },
        {
          path: "Location",
        },
      ];

    case "create-listing":
      return [
        {
          path: "Create listing",
        },
      ];

    default:
      return [
        {
          path: "Livestock",
          route: ANIMALS,
        },
      ];
  }
};
export const getMonthsAge = (date, toDate?): number => {
  const ageMonth = (toDate ? DateTime.fromISO(toDate) : DateTime.local()).diff(DateTime.fromISO(date), [
    "months",
  ]).months;
  return Math.trunc(ageMonth);
};
export const getMillisecondAge = (date, toDate?): number => {
  const { milliseconds } = (toDate ? DateTime.fromISO(toDate) : DateTime.local()).diff(DateTime.fromISO(date));
  return milliseconds;
};

export const getDaysOnFarm = ({
  dateMovedToFarm,
  dateLeftFarm,
  deadAt,
}: {
  dateMovedToFarm: string;
  dateLeftFarm: string | undefined;
  deadAt: string | undefined;
}) => {
  const startDate = DateTime.fromISO(dateMovedToFarm).startOf("day");
  const endDate = (
    dateLeftFarm ? DateTime.fromISO(dateLeftFarm) : deadAt ? DateTime.fromISO(deadAt) : DateTime.local()
  ).startOf("day");

  return endDate.diff(startDate, ["days"]).days;
};

export const getGroupAnimalsTableTitle = (type, selectedAnimalsCount, identifier = "group"): string => {
  switch (type) {
    case EDIT_ANIMALS_IN_GROUP_TYPES.ADD_TO_GROUP:
      return `Select livestock to add to your ${identifier} (${selectedAnimalsCount} selected)`;

    default:
      return `Animals in this ${identifier}`;
  }
};
export const getSelectedAnimals = (selectAll, selectedAnimals, animals): string[] =>
  selectAll ? animals.map((a) => a.id) : selectedAnimals;

export const getDayLabel = (value: number): string => (value > 1 ? "days" : "day");
export const getYearLabel = (value: number): string => (value > 1 ? "yrs" : "yr");
export const getMonthLabel = (value: number): string => (value > 1 ? "mos" : "mo");

export const getAge = (currentDate, dob): string => {
  const today = DateTime.fromISO(currentDate);
  const ageDate = DateTime.fromISO(dob);
  const diffMonths = today.diff(ageDate, ["months"]);
  const diffDays = today.diff(ageDate, ["days"]);
  const diffMonthsWithoutDays = today.diff(ageDate, ["years", "months"]);

  const years = Math.trunc(diffMonthsWithoutDays.years);
  const months = diffMonthsWithoutDays.months.toFixed(1);
  const days = Math.trunc(diffDays.days);

  if (diffMonths.months >= 1) {
    if (diffMonths.months >= 30) {
      return `${years} ${getYearLabel(years)} ${months} ${getMonthLabel(+months)}`;
    }

    const monthsNumber = diffMonths.months.toFixed(1);

    return `${monthsNumber} ${getMonthLabel(+monthsNumber)}`;
  }

  return `${days} ${getDayLabel(days)}`;
};

export const getAgeByDaysNumber = (dob: DateTime, fromDate: number, days: number): string => {
  const startDate = DateTime.fromMillis(fromDate);
  const diffMonths = startDate.diff(dob, ["months"]);
  const diffMonthsWithoutDays = startDate.diff(dob, ["years", "months"]);

  const years = Math.trunc(diffMonthsWithoutDays.years);
  const months = Math.trunc(diffMonthsWithoutDays.months);

  if (diffMonths.months >= 1) {
    if (diffMonths.months >= 30) {
      return `${years} ${getYearLabel(years)} ${months} ${getMonthLabel(+months)}`;
    }

    const monthsNumber = diffMonths.months.toFixed(1);

    return `${monthsNumber} ${getMonthLabel(+monthsNumber)}`;
  }

  return `${days} ${getDayLabel(days)}`;
};

export const getTimeOnFarm = (date): string => {
  const today = DateTime.local();
  const endDate = DateTime.fromISO(date);
  const diffObject = today.diff(endDate, ["years", "days"]);
  const fullDays = Math.trunc(diffObject.days);

  if (diffObject.years < 1) {
    return `${fullDays} ${getDayLabel(fullDays)}`;
  }
  return `${diffObject.years} ${getYearLabel(diffObject.years)} ${fullDays} ${getDayLabel(fullDays)}`;
};

const findItemWithUnit = (unitsList, valuesList): { value: string; unitName: string } | null =>
  valuesList.find((el) => el.unitName && unitsList.includes(el.unitName.toUpperCase()));

const findByFieldSlug = (valuesList, fieldSlug): { value: string; unitName: string } | null =>
  valuesList.find((el) => el.fieldSlug === fieldSlug);

export const getActivityValue = (activity): string => {
  const data = activity.payload.data;
  let item = findItemWithUnit(["COST", "PRICE"], data);

  if (item && item.value) {
    return `${item.value}`;
  }

  item = findByFieldSlug(data, "from-field-name");

  if (item && item.value) {
    return `${item.value}`;
  }

  item = findByFieldSlug(data, "to-field-name");

  if (item && item.value) {
    return `${item.value}`;
  }

  item = findByFieldSlug(data, "to-group-name");

  if (item && item.value) {
    return `${item.value}`;
  }

  item = findByFieldSlug(data, "from-group-name");

  if (item && item.value) {
    return `${item.value}`;
  }

  item = findItemWithUnit(["TEXT"], data);

  if (item && item.value) {
    return `${item.value}`;
  }

  item = findItemWithUnit(["DAYS", "KG", "ML", "MM", "CM"], data);

  if (item && item.value) {
    const formattedValue = Math.round(parseFloat(item.value) * 100) / 100;
    return `${formattedValue}${item.unitName.toLowerCase()}`;
  }

  return "";
};

export const getAnimalActivitiesFilteredByDate = (
  activities,
  isShowNewActivityDescription: boolean,
  getFormatDate: GetFormatDate,
): [] => {
  if (!activities || !activities.length) return [];

  const dates = activities
    .map((activity) => {
      return getFormatDate(activity.node.date, DateTime.DATE_HUGE);
    })
    .reduce(
      (a, c) => {
        if (!a.hash[c]) {
          a.result.push(c);
        }

        return a;
      },
      {
        result: [],
        hash: {},
      },
    ).result;
  const items = dates.reduce((acc, cur) => {
    acc[cur] = [];

    return acc;
  }, {});
  return activities.reduce((result, item) => {
    const date = getFormatDate(item.node.date, DateTime.DATE_HUGE);
    const type =
      (item.node?.activityType?.slug
        ? item.node?.activityType?.slug
        : item.node?.activityTemplate?.activityType?.slug) ?? "performance";

    const payloadName = item.node.payload.name;
    const isWeightActivity = item.node.payload.data[0]?.fieldSlug === WEIGHT_ACTIVITY_SLUG;
    const icon = item.node.activityType?.image || <LivestckIcon />;

    if (result[date]) {
      result[date].push({
        id: item.node.id,
        date: getFormatDate(item.node.date, DateTime.TIME_SIMPLE),
        ...(isShowNewActivityDescription ? {} : { type: payloadName }),
        ...(isShowNewActivityDescription ? {} : { activityType: type }),
        ...(isShowNewActivityDescription ? {} : { unitName: item.node.payload.data[0]?.unitName }),
        icon,
        description: isShowNewActivityDescription ? item.node.payload.longDescription : item.node.payload.description,
        name: isShowNewActivityDescription ? item.node.payload.shortDescription : payloadName,
        value: isShowNewActivityDescription ? item.node.payload.valueDescription : getActivityValue(item.node),
        isWeightActivity,
        isDeletable: item.node.isDeletable,
      });
    }

    return result;
  }, items);
};

export const getLiveStockSubTabs = (): LivestockTabs => {
  const featuresList = getFeaturesListFromLocalStorage() || [];

  return LIVESTOCK_SUBTABS.filter((item) => {
    if (item.feature === FEATURES.ANIMALS_ARCHIVE) {
      // @ts-ignore
      return featuresList.indexOf(item.feature) > -1;
    }

    return true;
  });
};

export const getFieldName = ({
  fieldName,
  unitName,
}: {
  fieldName: string;
  unitName: string | null | undefined;
}): string => {
  const locationsCount = getLocationsCountToLocalStorage();
  const fieldLocation = unitName || "Empty location";

  return Number(locationsCount) > 1 ? `${fieldLocation}: ${fieldName}` : fieldName;
};

export const getActivityTypes = (
  weightActivity: ActivityTypes,
  activityTypes: GetActivityTypes_activityTypes[] = [],
): ActivityTypes[] => [
  weightActivity,
  ...activityTypes.map((activityType) => ({
    key: Number(activityType.id),
    value: activityType.slug || WEIGHT_ACTIVITY_SLUG,
    icon: activityType.image || null,
    tooltip: activityType?.name || capitaliseFirstLetter(activityType?.slug) || "",
  })),
];

export const isAnimalsFilterEmpty = (filterValues?: { [key: string]: any }): boolean => {
  const cloneValues = { ...filterValues };

  delete cloneValues[ANIMAL_FILTER_KEYS.SORT_BY];
  delete cloneValues[ANIMAL_FILTER_KEYS.SORT_DIRECTION];
  delete cloneValues[ANIMAL_FILTER_KEYS.SEARCH];

  const isFilterValueExist = Object.keys(cloneValues).some((key) => cloneValues[key]?.toString().length > 0);

  return !isFilterValueExist;
};

export const isAnimalsFilterMissingRequiredField = (
  filterValues?: Record<string, unknown>,
  requiredFieldIds?: ValuesOf<typeof ANIMAL_FILTER_KEYS>[],
): boolean => {
  if (!requiredFieldIds) return false;

  if (!filterValues) return true;

  return requiredFieldIds.some((requiredFieldId) => !filterValues[requiredFieldId]);
};

export const renderActivityUnits = (units: string, weightUnits: string): string => {
  const perString = "_PER_";
  if (units.includes(perString)) {
    return units.toLowerCase().replace(perString, "/");
  }
  if (units === ActivityTemplateFieldUnits.PERCENTAGE) {
    return "%";
  }
  if (units === ActivityTemplateFieldUnits.NUMBER) {
    return "";
  }
  if (units === ActivityTemplateFieldUnits.WEIGHT_LARGE) {
    return weightUnits;
  }
  return units.toLowerCase();
};

export const getAnimalBreedsAsString = (
  animalBreeds: Pick<GetAnimalsList_animalsExtended_animals_animalBreeds, "name">[] | undefined = [],
): string => {
  if (!animalBreeds || !animalBreeds.length) return "";

  return animalBreeds.map((breed) => breed?.name).join(", ");
};

export const getAnimalBreedsWithPercentages = (
  breedPercentages: (Pick<BreedPercentage, "percentage"> & { breed: Pick<AnimalBreed, "name"> })[] | undefined = [],
): { name: string; percentage: string }[] => {
  if (!breedPercentages || !breedPercentages.length) return [];
  return breedPercentages.map((breedAssignment) => {
    const percentage = breedAssignment?.percentage ? ` ${breedAssignment?.percentage}%` : "";
    return { name: breedAssignment?.breed.name, percentage };
  });
};

export const getAnimalBreedCodesAsString = (
  animalBreeds: Pick<GetAnimalsList_animalsExtended_animals_animalBreeds, "breedrCode">[] | undefined = [],
): string => {
  if (!animalBreeds || !animalBreeds.length) return "";

  return animalBreeds.map((breed) => breed?.breedrCode).join(", ");
};

export const getAnimalCountFromField = (metrics: (SummaryMetrics | null)[] | null | undefined): number =>
  metrics ? metrics?.map((x) => (x?.animalsCount ? +x?.animalsCount : 0)).reduce((a, b) => a + b, 0) : 0;

export const getShortBreedrId = (breedrId: string | null | undefined) => {
  if (!breedrId) {
    return "";
  }

  const parts = breedrId.split("-");
  if (parts.length < 4) {
    return "";
  }

  const buId = parts[2].substring(2);
  const animalCode = parts[3];

  return `${buId}-${animalCode}`;
};
