import React, { useState, useCallback } from "react";
// Utils
import { ANIMAL_FILTER_KEYS } from "constants/Animals";

export type SetTempSignUpData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  cphNumber?: string;
};

export type ShowNotification = ({
  autoHideDuration,
  variant,
  message,
}: {
  autoHideDuration?: number;
  variant?: string;
  message: string;
}) => void;

// Chris @ 1 Apr 2021:
// This doesn't provide us with any extra type safety for adding new or editing existing filters, but has been added to
// satisfy the TS compiler errors for many lines in FiltersInUse and SaveFilterTemplate components.
export type AnimalFiltersState = Record<ValuesOf<typeof ANIMAL_FILTER_KEYS>, any>;

type CommonContextStateValues = {
  notification: {
    autoHideDuration?: number;
    isShow: boolean;
    variant: string;
    message: string;
  } | null;
  animalsFilters: AnimalFiltersState;
  tempSignUpData: SetTempSignUpData | null;
  animalsFilterTemplateId: string;
};

type CommonContextState = {
  mainCommonState: CommonContextStateValues;
};

type CommonContextHandlers = {
  showNotification: ShowNotification;
  changeAnimalsFilter: (newValues: Record<string, unknown>) => void;
  resetAnimalsFilter: () => void;
  setTempSignUpData: (data: SetTempSignUpData | null) => void;
  setAnimalsFilterTemplateId: (id: string) => void;
  resetMainCommonState: () => void;
  changeMainCommonState: React.Dispatch<React.SetStateAction<CommonContextStateValues>>;
};

type CommonContext = CommonContextHandlers & CommonContextState;

export const CommonContext = React.createContext<CommonContext>({} as CommonContext);

interface IProps {
  children?: React.ReactNode;
}

const CommonProvider: React.FC<IProps> = ({ children }) => {
  const [mainCommonState, changeMainCommonState] = useState<CommonContextStateValues>({
    notification: null,
    animalsFilters: {},
    tempSignUpData: null,
    animalsFilterTemplateId: "",
  });

  const showNotification = useCallback(({ autoHideDuration, variant = "success", message = "Success!" }): void => {
    changeMainCommonState((state) => ({
      ...state,
      notification: {
        autoHideDuration,
        isShow: true,
        variant,
        message,
      },
    }));
  }, []);

  const changeAnimalsFilter = useCallback((newValues: AnimalFiltersState): void => {
    changeMainCommonState((state) => ({
      ...state,
      animalsFilters: {
        ...state.animalsFilters,
        ...newValues,
      },
    }));
  }, []);

  const resetAnimalsFilter = useCallback((): void => {
    changeMainCommonState((state) => ({
      ...state,
      animalsFilters: {},
      animalsFilterTemplateId: "",
    }));
  }, []);

  const setTempSignUpData = useCallback((data: SetTempSignUpData | null): void => {
    changeMainCommonState((state) => ({
      ...state,
      tempSignUpData: data ? { ...data } : null,
    }));
  }, []);

  const setAnimalsFilterTemplateId = useCallback((id: string): void => {
    changeMainCommonState((state) => ({
      ...state,
      animalsFilterTemplateId: id,
    }));
  }, []);

  const resetMainCommonState = useCallback((): void => {
    changeMainCommonState({
      notification: null,
      animalsFilters: {},
      tempSignUpData: null,
      animalsFilterTemplateId: "",
    });
  }, []);

  const values = {
    mainCommonState,
    changeMainCommonState,
  };

  const updaters = {
    showNotification,
    changeAnimalsFilter,
    resetAnimalsFilter,
    setTempSignUpData,
    setAnimalsFilterTemplateId,
    resetMainCommonState,
  };
  //@ts-expect-error
  return <CommonContext.Provider value={{ ...values, ...updaters }}>{children}</CommonContext.Provider>;
};

export default CommonProvider;
