import spinner from "assets/img/Auth/trimmed-logo.png";
import spinnerDark from "assets/img/Auth/trimmed-logo-dark.png";

export const BreedrLogoSpinner = ({ variant }: { variant: "dark" | "light" }) => {
  return (
    <img
      data-testid="loading-spinner"
      src={variant === "dark" ? spinnerDark : spinner}
      alt="Breedr logo spinner"
      className={"animate-spin max-h-4 max-w-4"}
    />
  );
};
