import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BatchUploadFormatColumn } from "generated/graphql";

type ImportMenuState = "collapsed" | "expanded" | "closed";
export type PartialBatchUploadFormatColumn = Pick<BatchUploadFormatColumn, "label" | "slug">;

export interface AsyncImportJobState {
  importMenuState: ImportMenuState;
  importTaskId: string | undefined;
  unmatchedColumns: string[];
  availableColumns: PartialBatchUploadFormatColumn[];
}

const initialState: AsyncImportJobState = {
  importMenuState: "closed",
  importTaskId: undefined,
  unmatchedColumns: [],
  availableColumns: [],
};

export const asyncImportJobSlice = createSlice({
  name: "asyncImportJob",
  initialState,
  reducers: {
    setImportMenuState: (state, action: PayloadAction<ImportMenuState>) => {
      return { ...state, importMenuState: action.payload };
    },
    setImportTaskId: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, importTaskId: action.payload };
    },
    setUnmatchedColumns: (state, action: PayloadAction<string[]>) => {
      return { ...state, unmatchedColumns: action.payload };
    },
    setAvailableColumns: (state, action: PayloadAction<PartialBatchUploadFormatColumn[]>) => {
      return { ...state, availableColumns: action.payload };
    },
  },
});

export const { setImportMenuState, setImportTaskId, setUnmatchedColumns, setAvailableColumns } =
  asyncImportJobSlice.actions;
export const asyncImportJobReducer = asyncImportJobSlice.reducer;
