import { useLinkHerdDoggAccountMutation } from "generated/graphql";
import { useGetCurrentBusinessUnit } from "hooks";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import LoadingOverlay from "components/Common/LoadingOverlay";

export const IntegrationAuth: React.FC = () => {
  const { id } = useGetCurrentBusinessUnit();
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");

  const [linkHerdDoggAccountMutation] = useLinkHerdDoggAccountMutation({});

  useEffect(() => {
    if (!code) return;
    const connect = async () => {
      await linkHerdDoggAccountMutation({
        variables: { input: { authenticationCode: code, businessUnit: id } },
      });
    };
    connect();
  }, [code, id, linkHerdDoggAccountMutation]);

  return <LoadingOverlay />;
};
