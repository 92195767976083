// React
import React from "react";
// Utils
import { useBreakpoints } from "hooks";
// Components
import { Flex, QuickActions, Spacer, Text } from "components";
// Types
import { QuickActionItem } from "components/Common/QuickActions";
import { useLocale } from "helpers/translations/src";

interface DeliveryAddressProps {
  deliveryAddress: DeliveryAddressData;
  onEditAddress: (props: DeliveryAddressInput) => void;
  onToggleRemoveModal: (props: DeliveryAddressInput) => void;
}

const DeliveryAddress: React.FC<DeliveryAddressProps> = ({
  deliveryAddress: {
    id,
    unitName,
    primary,
    addressLine1,
    addressLine2,
    city,
    postcode,
    locationId,
    country: { name: countryName, id: countryId, code },
  },
  onEditAddress,
  onToggleRemoveModal,
}) => {
  const { terms } = useLocale();
  const setEditData = (): void => {
    onEditAddress({
      id,
      unitName,
      primary,
      addressLine1,
      addressLine2,
      city,
      postcode,
      locationId,
      country: {
        id: countryId,
        code,
      },
    });
  };

  const onRemoveAddress = (): void => {
    onToggleRemoveModal({
      id,
      unitName,
      primary,
      addressLine1,
      addressLine2,
      city,
      postcode,
      locationId,
      country: {
        id: countryId,
        code,
      },
    });
  };

  const { isBreakpointL, isBreakpointXL } = useBreakpoints();

  const quickActionItems: QuickActionItem[] = [
    { label: "Edit", onClick: setEditData },
    { label: "Delete", onClick: onRemoveAddress, type: "danger" },
  ];

  return (
    <Flex container key={id} containerResetXS={12}>
      <Flex item xs={12} l={6} xl={6}>
        <Text smaller>
          <strong>{unitName}</strong>
        </Text>

        {locationId ? (
          <>
            <Spacer />
            <Text smallest>{`${terms.farmId} Number: ${locationId}`}</Text>
          </>
        ) : null}

        {primary ? (
          <>
            <Spacer />
            <Text smallest>Primary</Text>
            <Spacer />
          </>
        ) : null}

        <Spacer baselineHeight={1} />

        <QuickActions items={quickActionItems} />
      </Flex>
      {!isBreakpointL && !isBreakpointXL ? <Spacer baselineHeight={2} /> : null}
      <Flex item xs={12} l={6} xl={6}>
        <Text smaller>
          {addressLine1}
          <br />
          {addressLine2}
          <br />
          {city}
          <br />
          {countryName}
          <br />
          {postcode}
        </Text>
      </Flex>
    </Flex>
  );
};

export default DeliveryAddress;
