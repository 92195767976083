import React, { useState } from "react";
// components
import { Header, PageContent, PageHeader, Spacer } from "components";
import AssignOwnershipModal from "pages/Settings/UserSettings/AssignOwnershipModal";
import ModifyProfileForm from "pages/Settings/UserSettings/ModifyProfileForm";

const UserSettings: React.FC = () => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  const handleModalToggle = (): void => {
    setModalVisibility((prevIsModalVisible) => !prevIsModalVisible);
  };

  return (
    <>
      <PageHeader>
        <Header title="User Settings" subTitle="Manage your user information" />
      </PageHeader>
      <PageContent>
        <Spacer baselineHeight={3} />
        <ModifyProfileForm onAssignOwnerToAnotherUser={handleModalToggle} />
        <AssignOwnershipModal showModal={isModalVisible} onClose={handleModalToggle} />
      </PageContent>
    </>
  );
};

export default UserSettings;
