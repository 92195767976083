import { gql } from "@apollo/client";

import { errorFragment, offerFragment } from "trading/api/fragment";

export const CREATE_LOT = gql`
  mutation CreateLot($input: CreateLotInput!) {
    createLot(input: $input) {
      lot {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_LOT = gql`
  mutation UpdateLot($input: UpdateLotInput!) {
    updateLot(input: $input) {
      lot {
        id
        images {
          id
          position
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_OFFER_FOR_LOT = gql`
  mutation CreateOfferForLot($input: CreateOfferForLotInput!) {
    createOfferForLot(input: $input) {
      offer {
        ...offerFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${offerFragment}
`;

export const UPDATE_LOT_OFFER_STATUS = gql`
  mutation ResponseToLotOffer($input: ResponseToLotOfferInput!) {
    responseToLotOffer(input: $input) {
      offer {
        ...offerFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${offerFragment}
`;

export const MARK_LOT_OFFER_AS_PAID = gql`
  mutation MarkAsPaidLotOffer($input: MarkAsPaidLotOfferInput!) {
    markAsPaidLotOffer(input: $input) {
      offer {
        ...offerFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${offerFragment}
`;

export const MARK_LOT_LIVESTOCK_AS_SHIPPED = gql`
  mutation LotSellerSentAnimals($input: LotSellerSentAnimalsInput!) {
    lotSellerSentAnimals(input: $input) {
      lot {
        id
        status
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const MARK_LOT_LIVESTOCK_AS_RECEIVED = gql`
  mutation LotBuyerReceivedAnimals($input: LotBuyerReceivedAnimalsInput!) {
    lotBuyerReceivedAnimals(input: $input) {
      lot {
        id
        status
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
