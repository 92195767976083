import React from "react";
// Libraries
import { useQuery } from "@apollo/client";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";
// API
import {
  GetAnimalsList,
  GetAnimalsList_animalsExtended_animals,
  GetAnimalsListVariables,
} from "api/MyLivestock/Animal/types/GetAnimalsList";
import { GET_MY_ANIMALS_LIST } from "api/MyLivestock/Animal/queries";
//Components
import { FieldSelect } from "components/Common/Field/Select";

type Props = {
  value: string;
  name: string;
  label: string;
  error: string;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isMultiple: boolean;
};

export const AnimalSelect: React.FC<Props> = ({
  value,
  onSelect,
  name = "",
  label = "",
  error = "",
  isMultiple = false,
}) => {
  const { data } = useQuery<GetAnimalsList, GetAnimalsListVariables>(GET_MY_ANIMALS_LIST, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
      isOnFarm: true,
    },
  });

  const animals = (data?.animalsExtended?.animals || []) as GetAnimalsList_animalsExtended_animals[];
  const options = animals.map((item) => ({
    key: item.id,
    label: `${item.passportNumber || item.visualId}`,
    value: item.id,
  }));

  return (
    <FieldSelect
      label={label}
      error={error}
      inputProps={{
        name,
        value: value || "",
        options: options,
        onChange: onSelect,
        placeholder: "No selected animal",
        isClearable: true,
        multiple: isMultiple,
      }}
    />
  );
};
