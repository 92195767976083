import { Field, Input } from "components";
import { TextAreaProps } from "components/Common/Field/Textarea";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { cn } from "utils/misc";

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  label: string;
  resize?: boolean;
} & TextAreaProps;

export const FormTextArea = <TFieldValues extends FieldValues>({
  name,
  label,
  resize,
  ...rest
}: Props<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  if (!methods) {
    throw new Error("FormTextArea must be used inside a <Form /> or <FormProvider />");
  }

  const errorMessage = methods?.formState.errors[name]?.message;
  return (
    <Field error={errorMessage as string} label={label} labelFor={name}>
      <Input hasError={!!errorMessage}>
        <textarea
          id={name}
          className={cn(
            `w-full h-auto m-0 py-2 px-3 bg-transparent border-none text-black text-xs focus:outline-none disabled:hover:cursor-not-allowed disabled:opacity-50`,
            resize ? "" : "resize-none",
          )}
          {...(methods?.register && methods.register(name))}
          {...rest}
        />
      </Input>
    </Field>
  );
};
