import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type MenuState = "collapsed" | "expanded" | "closed";

export interface AsyncJobState {
  menuState: MenuState;
  taskId: string | undefined;
}

const initialState: AsyncJobState = {
  menuState: "closed",
  taskId: undefined,
};

export const asyncJobSlice = createSlice({
  name: "asyncJob",
  initialState,
  reducers: {
    setMenuState: (state, action: PayloadAction<MenuState>) => {
      return { ...state, menuState: action.payload };
    },
    setTaskId: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, taskId: action.payload };
    },
  },
});

export const { setMenuState, setTaskId } = asyncJobSlice.actions;
export const asyncJobReducer = asyncJobSlice.reducer;
