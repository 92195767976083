import { Action, combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import { animalFiltersReducer } from "./animalFiltersSlice";
import { asyncJobReducer } from "./asyncJobSlice";
import { asyncImportJobReducer } from "./asyncImportJobSlice";
import { speciesReducer } from "./speciesSlice";
import { rowSelectionReducer } from "./rowSelectionSlice";
import { medicinesReducer } from "medicines/state/reducer";
import { businessUnitsReducer } from "feShared/businessUnit/reducer";
import { persistReducer, PersistConfig, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistState } from "redux-persist";

// redux-persist can't handle Partial<RootState> as preloadedState.
// redux-persist is no longer maintain so probably won't get this update. Here is a workaround
declare module "redux-persist" {
  export function persistReducer<S, A extends Action = Action, P = S>(
    config: PersistConfig<S>,
    baseReducer: Reducer<S, A, P>,
    // eslint-disable-next-line @typescript-eslint/naming-convention
  ): Reducer<S & { _persist: PersistState }, A, P & { _persist?: PersistState }>;
}

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  version: 1,
  whitelist: ["businessUnits"],
};

export const rootReducer = combineReducers({
  businessUnits: businessUnitsReducer,
  animalFilters: animalFiltersReducer,
  asyncImportJob: asyncImportJobReducer,
  asyncJob: asyncJobReducer,
  rowSelection: rowSelectionReducer,
  species: speciesReducer,
  medicines: medicinesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
