import React from "react";
// Libraries
import { useQuery } from "@apollo/client";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";
// Components
import CheckBox from "components/Common/Checkbox";
import { FieldNumber, FieldSelect, FieldText, FieldTextArea, Icon, Spacer } from "components";
// Utils
import { GET_BUSINESS_UNIT_SIZE_UNIT } from "api/BusinessUnits/queries";
import {
  GetBusinessUnitSizeUnit,
  GetBusinessUnitSizeUnitVariables,
} from "api/BusinessUnits/types/GetBusinessUnitSizeUnit";
import { GET_PASTURE_TYPES } from "api/MyLivestock/Fields/queries";
import { GetPastureTypes } from "api/MyLivestock/Fields/types/GetPastureTypes";
import { GET_DELIVERY_ADDRESSES } from "trading/api/queries";
import { deliveryAddresses, deliveryAddressesVariables } from "trading/api/types/deliveryAddresses";
import { getBUFromLocalStorage } from "helpers/storage";
import { handleEnterPress, handleErrorCheck } from "helpers/general";
import { useBreakpoints, useGetCurrentBusinessUnit } from "hooks";

interface CreateFieldFormProps {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleChange: () => void;
  values: FieldForm;
  errors: FieldForm;
  canEditAddress?: boolean;
}

interface SelectorOption {
  label: string;
  value: string;
  key: string;
}

const getTradingLocation = ({ unitName, addressLine1, addressLine2, city, country, postcode }) =>
  (unitName ? `${unitName}: ` : "") +
  (addressLine1 ? `${addressLine1}, ` : "") +
  (addressLine2 ? `${addressLine2}, ` : "") +
  (city ? `${city}, ` : "") +
  (country && country.name ? `${country.name}, ` : "") +
  (postcode ? `${postcode}` : "");

const CreateFieldForm: React.FC<CreateFieldFormProps> = ({
  errors,
  values,
  handleChange,
  handleSubmit,
  canEditAddress,
}) => {
  const { countryIsoCode } = useGetCurrentBusinessUnit();
  const { terms } = useLocale();
  const { data: businessUnitData } = useQuery<GetBusinessUnitSizeUnit, GetBusinessUnitSizeUnitVariables>(
    GET_BUSINESS_UNIT_SIZE_UNIT,
    {
      variables: {
        businessUnit: getBUFromLocalStorage(),
      },
    },
  );
  const businessUnitSizeUnit = businessUnitData?.businessUnit?.settings?.sizeUnit;

  const { data } = useQuery<GetPastureTypes>(GET_PASTURE_TYPES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const { data: addressesData } = useQuery<deliveryAddresses, deliveryAddressesVariables>(GET_DELIVERY_ADDRESSES, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const types: SelectorOption[] | [] =
    data && data.pastureTypes
      ? data.pastureTypes.map((item) => ({
          label: item?.name || "",
          value: item?.id || "",
          key: item?.id || "",
        }))
      : [];

  const addressesWithLocationId = addressesData?.deliveryAddresses?.filter((item) => item?.locationId) || [];
  const addresses: SelectorOption[] | [] =
    countryIsoCode === "gb"
      ? addressesWithLocationId.map((address) => ({
          label: address ? `${getTradingLocation(address)}/${address.locationId || ""}` : "",
          value: address?.id || "",
          key: address?.id || "",
        }))
      : addressesData?.deliveryAddresses?.map((address) => ({
          label: address ? `${getTradingLocation(address)}${address.locationId ? `/${address.locationId}` : ""}` : "",
          value: address?.id || "",
          key: address?.id || "",
        })) ?? [];
  const noAddressesMessage =
    countryIsoCode === "gb"
      ? "No addresses found, please add an address first in farm settings or make sure address has CPH number"
      : `No addresses found, please add an address first in ${terms.farm} settings`;
  if (addresses.length === 0) {
    addresses.push({
      label: noAddressesMessage,
      value: "",
      key: "",
    });
  }

  const { isBreakpointM } = useBreakpoints();

  return (
    <>
      <div className="text-xl font-semibold">
        <span>{capitaliseFirstLetter(terms.location)} details</span>
      </div>

      <div className="flex flex-wrap justify-start w-full">
        <div className="p-2 w-1/3">
          <FieldText
            label={`${capitaliseFirstLetter(terms.location)} Name *`}
            error={handleErrorCheck(errors.name)}
            inputProps={{
              name: "name",
              defaultValue: values.name,
              disabled: values.isHoldingPen,
              onChange: handleChange,
              onKeyPress: (event): void => handleEnterPress(event, handleSubmit),
            }}
          />
        </div>
        <div className="p-2 w-1/3">
          <FieldSelect
            label={`${capitaliseFirstLetter(terms.location)} Address *`}
            error={handleErrorCheck(errors.location)}
            inputProps={{
              disabled: !canEditAddress,
              name: "location",
              value: values.location,
              options: addresses,
              onChange: handleChange,
            }}
          />
          {!canEditAddress ? (
            <span className="text-xs text-slate-500 align-super">
              <Icon name="info" size="atom" />
              <span> {"Location address cannot be edited if location has animals"}</span>
            </span>
          ) : null}
        </div>
        <div className="p-2 mt-6 w-1/6">
          <CheckBox
            type="text"
            label={`Is this a ${terms.pen}?`}
            name="isYard"
            checked={values.isYard}
            value={values.isYard}
            errorStatus={errors.isYard}
            onChange={handleChange}
            onKeyPress={(event): void => handleEnterPress(event, handleSubmit)}
            error={handleErrorCheck(errors.isYard)}
          />
        </div>

        {values.isYard ? null : (
          <>
            {" "}
            {isBreakpointM ? <Spacer /> : null}
            <div className="p-2 w-1/3">
              <FieldNumber
                label={`${capitaliseFirstLetter(terms.location)} Size`}
                error={handleErrorCheck(errors.size)}
                inputProps={{
                  name: "size",
                  onChange: handleChange,
                  onKeyPress: (event): void => handleEnterPress(event, handleSubmit),
                  value: values.size,
                  adornment: businessUnitSizeUnit
                    ? {
                        labelFor: "size",
                        label: businessUnitSizeUnit,
                      }
                    : undefined,
                }}
              />
            </div>
            <div className="p-2 w-1/3">
              <FieldSelect
                label="Field/Pasture Type"
                error={handleErrorCheck(errors.pastureTypes)}
                inputProps={{
                  name: "pastureTypes",
                  value: values.pastureTypes,
                  options: types,
                  multiple: true,
                  onChange: handleChange,
                }}
              />
            </div>
            <div className="p-2 w-1/6 flex">
              <CheckBox
                type="text"
                label={`Is the ${terms.location} organic?`}
                name="isOrganic"
                checked={values.isOrganic}
                value={values.isOrganic}
                errorStatus={errors.isOrganic}
                onChange={handleChange}
                onKeyPress={(event): void => handleEnterPress(event, handleSubmit)}
                error={handleErrorCheck(errors.isOrganic)}
              />
            </div>
          </>
        )}
      </div>
      <div className="container">
        <div className="p-2 w-1/2">
          <FieldTextArea
            label="Description"
            error={handleErrorCheck(errors.description)}
            inputProps={{
              name: "description",
              defaultValue: values.description,
              onChange: handleChange,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CreateFieldForm;
