import React, { useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
// Utils
import { BU_ID, DISTRIBUTION_LISTS, MARKETPLACE, MY_LISTINGS, SELLING, SUPPLY_CHAINS, TRADING } from "constants/Routes";
import { useHasFeature, useDistributionListsCount, useGetCurrentBusinessUnit } from "hooks";

import { DistributionLists } from "trading/pages/DistributionLists";
import { SubTab } from "components/Common/Tabs";
import { ViewBuyerListings } from "./Liveweight/ViewBuyerListings";
import { ViewSellerListings } from "./Liveweight/ViewSellerListings";
import { PageContent, Spacer, Header, Tabs, PageHeader } from "components";
import { EmptyStatesTradingSellingDeadweight } from "components/EmptyStates/TradingSellingDeadweight";
import SupplyChains from "supplyChains";

const Trading: React.FC = () => {
  const { countryIsoCode } = useGetCurrentBusinessUnit();
  const { tradingTabType, tradingSubTabType } = useParams<TradingRootPageParams>();
  const isShowDistributionLists = useHasFeature("SHOW_DISTRIBUTION_LISTS");
  const { data: listsCount } = useDistributionListsCount();
  const [currentSelectedTab, changeCurrentSelectedTab] = useState<number>(0);
  const [currentSelectedSubTab, changeCurrentSelectedSubTab] = useState<number>(0);

  const tradingTabs = useMemo(
    () => [
      {
        key: 0,
        name: "Buying",
        route: `${TRADING}${MARKETPLACE}`,
        value: "buying",
      },
      {
        key: 1,
        name: "Selling",
        route: `${TRADING}${SELLING}`,
        value: "selling",
      },
      {
        key: 2,
        name: "Supply Chains",
        route: `${SUPPLY_CHAINS}`,
        value: "supply-chains",
      },
      {
        key: 3,
        name: "Lists",
        route: `${TRADING}${DISTRIBUTION_LISTS}`,
        value: "lists",
        count: listsCount,
      },
    ],
    [listsCount],
  );

  const buyingTabs: SubTab[] = useMemo(
    () => [
      {
        key: 0,
        name: "Liveweight",
        route: `${TRADING}${MARKETPLACE}`,
        value: "liveweight",
      },
    ],
    [],
  );

  const sellingTabs: SubTab[] = useMemo(
    () => [
      {
        key: 0,
        name: "Deadweight",
        value: "deadweight",
        route: `${TRADING}${SELLING}`,
      },
      {
        key: 1,
        name: "My Listings",
        route: `${TRADING}${MY_LISTINGS}`,
        value: "my-listings",
      },
    ],
    [],
  );

  useEffect(() => {
    const selectedTabKey: number = tradingTabs.find((item) => item.value === tradingTabType)?.key || 0;
    changeCurrentSelectedTab(selectedTabKey);
    if (selectedTabKey === 0 || selectedTabKey === 1) {
      const selectedSubTabKey =
        (selectedTabKey === 0 ? buyingTabs : sellingTabs).find(
          (item) => item.value?.toLowerCase() === tradingSubTabType,
        )?.key || 0;

      changeCurrentSelectedSubTab(selectedSubTabKey);
    }
  }, [
    tradingTabType,
    tradingTabs,
    changeCurrentSelectedTab,
    sellingTabs,
    buyingTabs,
    tradingSubTabType,
    changeCurrentSelectedSubTab,
  ]);

  return (
    <>
      {countryIsoCode === "gb" ? (
        <PageHeader>
          <Header title="Trading" />
          <Tabs tabs={tradingTabs} selectedTab={currentSelectedTab} />
          {currentSelectedTab === 0 ? (
            <Tabs grayBackgroundTabs tabs={buyingTabs} selectedTab={currentSelectedSubTab} />
          ) : null}
          {currentSelectedTab === 1 ? (
            <Tabs grayBackgroundTabs tabs={sellingTabs} selectedTab={currentSelectedSubTab} />
          ) : null}
        </PageHeader>
      ) : (
        <PageHeader>
          <Header title="Supply Chains" />
        </PageHeader>
      )}

      <Switch>
        <Route
          exact
          path={`${BU_ID}${TRADING}${SELLING}`}
          component={() => (
            <PageContent>
              <Spacer baselineHeight={2} />
              <EmptyStatesTradingSellingDeadweight />
            </PageContent>
          )}
        />
        {isShowDistributionLists ? (
          <Route exact path={`${BU_ID}${TRADING}${DISTRIBUTION_LISTS}`} component={DistributionLists} />
        ) : null}
        <Route exact path={`${BU_ID}${TRADING}${MARKETPLACE}`} component={ViewBuyerListings} />
        <Route exact path={`${BU_ID}${TRADING}${MY_LISTINGS}`} component={ViewSellerListings} />
        <Route exact path={`${SUPPLY_CHAINS}`} key={SUPPLY_CHAINS} component={SupplyChains} />
        <Redirect to={`${BU_ID}${TRADING}${MARKETPLACE}`} />
      </Switch>
    </>
  );
};

export default Trading;
