interface GetFormattedListFromArrayOptions {
  oxfordComma?: boolean;
}

export const getFormattedListFromArray = (
  original: string[],
  { oxfordComma }: GetFormattedListFromArrayOptions = {},
): string | void => {
  if (original.length === 0) return;

  if (original.length === 1) return original[0];

  if (original.length === 2) return original.join(" and ");

  if (oxfordComma) return original.slice(0, -1).join(", ") + ", and " + original.slice(-1);

  return original.slice(0, -1).join(", ") + " and " + original.slice(-1);
};
