import "babel-polyfill";
import React from "react";
import JssProvider from "react-jss/lib/JssProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { create } from "jss";
import { withStyles } from "jss/lib/types";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import { ApolloProvider } from "@apollo/client";
import { Provider as ReduxProvider } from "react-redux";

import { RecoilRoot } from "recoil";
import CommonProvider from "config/commonProvider";
import TableOptionsProvider from "config/tableOptionsProvider";
import client from "api/graphQL";
import { Routes } from "./Routes";
import { setupStore } from "state/store";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components/ErrorFallback";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

const generateClassName = createGenerateClassName();
const jss: withStyles = create(jssPreset());

jss.options.insertionPoint = document.getElementById("jss-insertion-point");

const store = setupStore();
const persistor = persistStore(store);

const App: React.FC = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <CssBaseline>
        <ApolloProvider client={client}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <RecoilRoot>
                <CommonProvider>
                  <TableOptionsProvider>
                    <Routes />
                  </TableOptionsProvider>
                </CommonProvider>
              </RecoilRoot>
            </PersistGate>
          </ReduxProvider>
        </ApolloProvider>
      </CssBaseline>
    </JssProvider>
  </ErrorBoundary>
);

export default App;
