import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesTradingBuying: FC = () => {
  const handleButtonClick = () => {
    window.open("https://share.hsforms.com/1albrlIZ-SIqLBLxMUcTeqg4snpj", "_blank");
  };

  return (
    <EmptyStates
      animation={"handshake"}
      title="No listings available."
      buttonCaption="Chat to us"
      onClickButton={handleButtonClick}
    >
      <p>
        Do you have cattle to sell? Click the ‘+ Create Listing’ button above to get started. Or need some help? Get in
        contact with our support team.
      </p>
    </EmptyStates>
  );
};
