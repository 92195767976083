import React, { useEffect, useState } from "react";
// Libraries
import { DateTime } from "luxon";
import { FormikErrors, FormikProps } from "formik";
import getSymbolFromCurrency from "currency-symbol-map";
import { useLazyQuery } from "@apollo/client";
//API
import { GET_ESTIMATED_FUTURE_PRICE_BASED_ON_WEIGHT } from "trading/api/queries";
// Components
import { Button, Flex, FieldNumber, FieldDate, Spacer, Text, Title } from "components";
import { errorCodes, OfferCalculation } from "./OfferCalculation";
//Containers
import { OfferStatuses } from "trading/components/containers/liveweight/BuyerListing/BuyerListingOffer/BuyerListingOfferModal";
//Utils
import { formattedTotalPricePerHead, formattedPrice } from "trading/util/trading";
import { isPositiveInteger } from "trading/util";
import { makeOfferType } from "state";
import { makeOfferPerKgTotal, makeOfferCollectionDate, makeOfferPriceTotal, makeOfferPrice } from "state";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useGetCurrentBusinessUnit } from "hooks";
//Types
import {
  GetEstimatedFuturePriceBasedOnWeight,
  GetEstimatedFuturePriceBasedOnWeightVariables,
} from "trading/api/types/GetEstimatedFuturePriceBasedOnWeight";

export interface FormValues {
  offerPrice: number | string;
  collectionDate: Date | null;
}

export type FormProps = FormikProps<FormValues>;

interface MakeOfferPropTypes {
  values: FormValues;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  askingPrice: number | null;
  askingPricePerHead: number | null;
  errors: FormikErrors<FormValues>;
  listingId: number;
  setFieldValue: <T>(field: string, value: T, shouldValidate?: boolean) => void;
  quantity: number;
  status: OfferStatuses;
}
export const OfferPerKg = ({
  values,
  setDisabled,
  askingPrice,
  errors,
  listingId,
  setFieldValue,
  askingPricePerHead,
  quantity = 0,
}: MakeOfferPropTypes): JSX.Element => {
  const { currencyCodeISO } = useGetCurrentBusinessUnit();
  const currencySymbol = getSymbolFromCurrency(currencyCodeISO);
  const offerType = useRecoilValue(makeOfferType);

  const setCollectionDate = useSetRecoilState(makeOfferCollectionDate);

  const setOfferPerKgTotal = useSetRecoilState(makeOfferPerKgTotal);

  const setOfferPriceTotal = useSetRecoilState(makeOfferPriceTotal);

  const setOfferPrice = useSetRecoilState(makeOfferPrice);

  const [clearTotal, setClearTotal] = useState(true);

  const askingPriceFormatted = askingPrice ? formattedPrice(+askingPrice, currencyCodeISO) : "\u2014";

  const handleSetCollectionDate = (date: Date): void => {
    setClearTotal(true);
    setFieldValue("collectionDate", date);
  };

  const [getFutureEstimatedPrice, { data: futureEstimatedPrice, loading }] = useLazyQuery<
    GetEstimatedFuturePriceBasedOnWeight,
    GetEstimatedFuturePriceBasedOnWeightVariables
  >(GET_ESTIMATED_FUTURE_PRICE_BASED_ON_WEIGHT, {
    fetchPolicy: "network-only",
  });

  const GetEstimatedTotalPrice = (): void => {
    setClearTotal(false);

    getFutureEstimatedPrice({
      variables: {
        offerPricePerWeightUnit: (values.offerPrice && +values.offerPrice) || 0,
        collectionDate: values.collectionDate && DateTime.fromJSDate(values.collectionDate).toISODate(),
        lot: listingId,
      },
    });
  };

  const totalAskingPerHead = askingPricePerHead
    ? formattedTotalPricePerHead(+askingPricePerHead, quantity, currencyCodeISO)
    : "\u2014";

  const totalOffer = futureEstimatedPrice?.estimatedFuturePriceBasedOnWeight?.futurePrice;

  const total = !clearTotal && totalOffer ? formattedPrice(totalOffer, currencyCodeISO) : "\u2014";

  const collectionDateValidation = !(values.collectionDate instanceof Object) || errors.collectionDate;

  const offerPriceValidation =
    !isPositiveInteger(values.offerPrice) && (!!errors.offerPrice || !Boolean(values.offerPrice));

  const errorOnFetch = futureEstimatedPrice?.estimatedFuturePriceBasedOnWeight?.errors;

  const errorOrWarningCode = errorOnFetch && errorOnFetch[0];
  const isDisableSaveButton =
    offerPriceValidation ||
    collectionDateValidation ||
    totalOffer === "\u2014" ||
    !futureEstimatedPrice ||
    errorOrWarningCode?.code === errorCodes.NO_PREDICTED_WEIGHT;

  useEffect(() => {
    setClearTotal(true);
  }, [offerType]);

  useEffect(() => {
    setDisabled(Boolean(isDisableSaveButton));
  }, [isDisableSaveButton]);

  useEffect(() => {
    values.offerPrice && setOfferPrice(+values.offerPrice);
  }, [values.offerPrice]);

  useEffect(() => {
    setClearTotal(false);
    setOfferPriceTotal(totalOffer);
  }, [totalOffer]);

  useEffect(() => {
    values.collectionDate && setCollectionDate(DateTime.fromJSDate(values.collectionDate).toISODate());
  }, [values.collectionDate]);

  useEffect(() => {
    //Undefined was causing an infinite loop...
    if (!futureEstimatedPrice) {
      return;
    }
    setOfferPerKgTotal(+(+futureEstimatedPrice?.estimatedFuturePriceBasedOnWeight?.futurePrice).toFixed(2));
  }, [futureEstimatedPrice]);

  useEffect(() => {
    // If either collectionDate or offerPrice is changed, the user should be forced to
    // re-request an estimate total.
    setOfferPriceTotal(null), setDisabled(true);
  }, [values.collectionDate, values.offerPrice]);

  const handleChangeOfferPrice = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setClearTotal(true);
    setFieldValue("offerPrice", e.target.value, false);
  };

  return (
    <>
      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>Asking per kg</Text>
        <Title secondary>{askingPriceFormatted}</Title>
      </Flex>

      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>No of Animals:</Text>
        <Title secondary>{quantity}</Title>
      </Flex>

      <Spacer baselineHeight={2} />

      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>Total asking price</Text>
        <Title secondary>{totalAskingPerHead}</Title>
      </Flex>

      <Spacer baselineHeight={2} />

      <Flex item itemGutter xs={2}>
        <FieldNumber
          error={errors.offerPrice}
          label={`Offer per kg`}
          inputProps={{
            name: "offerPrice",
            autoFocus: true,
            step: 0.01,
            value: values.offerPrice,
            disabled: false,
            onChange: (e) => handleChangeOfferPrice(e),
            placeholder: currencySymbol,
          }}
        />
      </Flex>

      <Flex item itemGutter xs={2}>
        <FieldDate
          label={"Estimated collection date"}
          inputProps={{
            name: "collectionDate",
            minDate: new Date(),
            value: values.collectionDate,
            onChange: (date: Date): void => handleSetCollectionDate(date),
          }}
        />
      </Flex>

      <Flex item itemGutter xs={4}>
        <Button
          caption="Calculate estimated total price"
          width={"two-third"}
          colour="grey"
          variant="solid"
          disabled={!values.collectionDate || !values.offerPrice}
          onClick={GetEstimatedTotalPrice}
        />
      </Flex>
      <Spacer baselineHeight={3} />

      <Flex item itemGutter>
        <Text>Total offer</Text>

        <OfferCalculation
          total={total}
          errors={futureEstimatedPrice?.estimatedFuturePriceBasedOnWeight?.errors}
          loading={loading}
        />
      </Flex>
    </>
  );
};
