import React, { useContext } from "react";

import { isAnimalsFilterEmpty } from "helpers/myLivestock";

import { CommonContext } from "config/commonProvider";

import { Icon } from "components";

import styles from "./styles.module.scss";

export const ClearFiltersButton: React.FC = () => {
  const {
    mainCommonState: { animalsFilters },
    resetAnimalsFilter,
  } = useContext(CommonContext);

  const isClearFiltersButtonHidden: boolean = isAnimalsFilterEmpty(animalsFilters);

  const handleClearButtonClick = (): void => {
    resetAnimalsFilter();
  };

  if (isClearFiltersButtonHidden) {
    return null;
  }

  return (
    <button className={styles.clearButton} onClick={handleClearButtonClick}>
      <Icon className={styles.clearIcon} name="times" colour="black" size="tiny" />
      Clear filters
    </button>
  );
};
