import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesAddressBook: FC = () => {
  return (
    <EmptyStates animation={"users"} title="You haven’t setup any contacts yet.">
      <p>
        Capture useful contacts to include Previous Keepers, Vets, etc.
        <br />
        Click the ‘+ Add’ button to start adding to your address book.
      </p>
    </EmptyStates>
  );
};
