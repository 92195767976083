import { FEATURES } from "constants/Features";
import { ANIMALS, FIELDS, GROUPS, TAGS } from "constants/Routes";

export const useLivestockTabs = () => {
  const LIVESTOCK_TABS = [
    {
      key: 0,
      name: "My Livestock",
      route: ANIMALS,
      feature: "show-animals",
    },
    {
      key: 1,
      name: "Groups",
      route: GROUPS,
      feature: "show-groups",
    },
    {
      key: 2,
      name: "Locations",
      route: FIELDS,
      feature: FEATURES.FIELDS,
    },
    {
      key: 3,
      name: "Tags",
      route: TAGS,
      feature: FEATURES.SHOW_TAGS,
    },
  ];

  return LIVESTOCK_TABS;
};
