// @deprecated Use feShared/hooks/useFormatDate instead
import { useCallback } from "react";
import { getFormattedDate } from "helpers/general";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { DateTime, DateTimeFormatOptions } from "luxon";

export type GetFormatDate = (date: Date | string | undefined, formatOptions?: DateTimeFormatOptions) => string;

export const getInternationalDateFormat = (countryCode: string | undefined) => {
  switch (countryCode) {
    case "us":
      return { dateFormat: "MM/dd/yyyy", locale: "en-US" };
    default:
      return { dateFormat: "dd/MM/yyyy", locale: "en-GB" };
  }
};

export const useFormatDate = () => {
  const { countryCode } = useGetCurrentBusinessUnit(); //TODO: This is refetching the BU alot!
  const { locale, dateFormat } = getInternationalDateFormat(countryCode);

  const getFormatDate = useCallback(
    (date: Date | string | undefined, formatOptions: DateTimeFormatOptions = DateTime.DATE_SHORT) =>
      getFormattedDate(date, formatOptions, { locale }),
    [locale],
  );

  return { dateFormat, locale, getFormatDate };
};
