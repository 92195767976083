import React, { useRef, useState, useEffect } from "react";

type UseHoverResponse<T> = [React.RefObject<T>, boolean];

export const useHover = <T extends HTMLElement>(): UseHoverResponse<T> => {
  const [isHovered, setIsHovered] = useState(false);

  const hoverRef = useRef<T>(null);

  const handleMouseOver = (): void => setIsHovered(true);
  const handleMouseOut = (): void => setIsHovered(false);

  useEffect(() => {
    const node = hoverRef.current;

    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);

      return (): void => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [hoverRef.current]);

  return [hoverRef, isHovered];
};
