import React from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./SystemSelect.module.scss";

interface SystemSelectProps {
  logo?: string;
  name: string;
  onClick: () => void;
  selected?: boolean;
}

export const SystemSelect: React.FC<SystemSelectProps> = ({ logo, name, onClick, selected }) => (
  <button className={cn(styles.system_select, { [styles["system_select--selected"]]: selected })} onClick={onClick}>
    <span className={styles.system_select__name}>{name}</span>

    <figure className={styles.system_select__logo}>
      {logo ? <img src={logo} alt={`${logo} logo`} className={styles.system_select__logo_image} /> : null}

      <figcaption className={styles.system_select__logo_caption}>{name}</figcaption>
    </figure>
  </button>
);
