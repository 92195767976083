import React from "react";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { QuicksightParameter, useGetQuicksightEmbedUrlQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { LoadingOverlay } from "components";
import { removeNothings } from "helpers/general";

interface Props {
  quickSightUrl: string;
}

const AWSQuickSight: React.FC<Props> = ({ quickSightUrl }) => {
  const dashboardRef = React.createRef<HTMLDivElement>();
  const selectedBU = getBUFromLocalStorage();

  const { data, loading } = useGetQuicksightEmbedUrlQuery({
    fetchPolicy: "network-only",
    variables: { businessUnit: selectedBU, embedUrl: quickSightUrl },
  });

  const prepareParameters = (parameters: QuicksightParameter[]) => {
    const quicksightParameters = {};
    // const now = new Date();
    // const hoursOffset = now.getTimezoneOffset() / 60;

    parameters.forEach((obj) => {
      if (obj.code && obj.value) {
        quicksightParameters[obj.code] = obj.value;
      }
    });
    // needs to go in once we have worked out BE
    // quicksightParameters["tzoffset"] = hoursOffset;
    return quicksightParameters;
  };

  React.useEffect(() => {
    if (data?.quicksightEmbedUrl?.url && data?.quicksightEmbedUrl?.parameters != null) {
      const parameters = removeNothings(data?.quicksightEmbedUrl?.parameters);
      embedDashboardInScreen(data?.quicksightEmbedUrl?.url, parameters);
    }
  }, [data, removeNothings]);

  const embedDashboardInScreen = (url: string, parameters: QuicksightParameter[]) => {
    const options = {
      url,
      container: dashboardRef.current,
      scrolling: "no",
      height: "1000px",
      width: "100%",
      footerPaddingEnabled: true,
      parameters: prepareParameters(parameters),
    };
    embedDashboard(options);
  };

  return loading ? <LoadingOverlay /> : <div ref={dashboardRef} />;
};

export const AWSQuickSightDashboard = React.memo(AWSQuickSight);
