import React, { ReactNode } from "react";
import cn from "classnames";
// Assets
import styles from "./KeyValuePair.module.scss";
import { Tooltip } from "twComponents/Tooltip";

export interface KeyValuePairProps {
  boxed?: boolean;
  label: string;
  stacked?: boolean;
  unit?: string;
  value?: ReactNode;
  canTruncate?: boolean;
}

export const KeyValuePair: React.FC<KeyValuePairProps> = ({
  boxed,
  label,
  stacked,
  unit,
  value,
  canTruncate = false,
}) => {
  const classes = cn(styles.key_value_pair, {
    [styles["key_value_pair--boxed"]]: !!boxed,
    [styles["key_value_pair--stacked"]]: !!stacked,
  });
  const stringValue = String(value);
  const maxLength = 22;

  return (
    <span className={classes}>
      <span className={styles.key_value_pair__key}>{label}</span>

      {value ? (
        stringValue.length > maxLength && canTruncate ? (
          <Tooltip title={stringValue} position="right">
            <span className={styles.key_value_pair__value}>{stringValue.substring(0, maxLength) + "..."}</span>
            {unit ? <span className={styles.key_value_pair__unit}>{unit}</span> : null}
          </Tooltip>
        ) : (
          <>
            <span className={styles.key_value_pair__value}>{value}</span>
            {unit ? <span className={styles.key_value_pair__unit}>{unit}</span> : null}
          </>
        )
      ) : (
        <span className={styles.key_value_pair__value}>{"\u2014"}</span>
      )}
    </span>
  );
};
