import React from "react";
// Components
import { Flex, Spacer, Text } from "components";

interface BatchDetailProps {
  label: string;
  value?: string | number | null;
}

export const BatchDetail: React.FC<BatchDetailProps> = ({ label, value }) => (
  <Flex item>
    <Text>
      <strong>{label}</strong>
    </Text>

    <Spacer />

    <Text>{value ? value : "—"}</Text>
  </Flex>
);
