import React from "react";
// Components
import { Button, Tooltip } from "components";
// Resources
import { ExportIcon } from "constants/SvgIcons";

export interface DownloadButtonProps {
  tooltipMessage?: string | undefined;
  disabled: boolean;
  downloadsLoading: boolean;
  onClick: () => void;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  tooltipMessage,
  disabled,
  onClick,
  downloadsLoading = false,
}) => {
  if (disabled) return <Button disabled icon={{ image: ExportIcon, position: "left" }} />;

  const tooltipTitle = tooltipMessage ?? "Click to download";

  // We have to wrap button in div as material ui throws console error
  // regarding wrapping a tooltip around a (potentially) disabled button
  return (
    <Tooltip title={tooltipTitle} placement="top">
      <div>
        <Button
          icon={{
            image: ExportIcon,
            position: "left",
          }}
          disabled={downloadsLoading}
          requesting={downloadsLoading}
          onClick={onClick}
        />
      </div>
    </Tooltip>
  );
};
