import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { CREATE_BANK_DETAILS, UPDATE_BANK_DETAILS } from "api/Settings/mutations";
import { CreateBankDetails, CreateBankDetailsVariables } from "api/Settings/types/CreateBankDetails";
import { UpdateBankDetails, UpdateBankDetailsVariables } from "api/Settings/types/UpdateBankDetails";
import { useGetBankDetails } from "hooks";
import { getBUFromLocalStorage } from "helpers/storage";
import { SETTINGS, BANK_DETAILS, CREATE_BANK_DETAILS as CREATE_BANK_DETAILS_URL } from "constants/Routes";
import { CommonContext } from "config/commonProvider";
import { Button, FieldText, Flex, Spacer, CheckBox } from "components";

type Props = {
  isEditable: boolean;
};

type FormValues = {
  accountNo: string;
  accountName: string;
  sortCode: string;
  isBusinessAccount: boolean;
};

type FormProps = FormikProps<FormValues>;

const validationSchemaContactRequest = Yup.object().shape({
  sortCode: Yup.string()
    .matches(/^[0-9]{6}$/, "Must be 6 digits long. Enter a valid sort code like 309430")
    .required("Enter a sort code"),
  accountNo: Yup.string()
    .matches(/^\S*$/, "Enter a valid account number like 00733445")
    .matches(/^[0-9]{6,8}$/, "Account number must be between 6 and 8 digits")
    .required("Enter an account number"),
  accountName: Yup.string().max(140, "Max 140 char").required("Enter the name on the account"),
  isBusinessAccount: Yup.boolean(),
});

export const CreateEditBankDetailsForm: React.FC<Props> = ({ isEditable }) => {
  const history = useHistory();
  const { data } = useGetBankDetails({ skip: !isEditable });
  const { showNotification } = useContext(CommonContext);

  const [createBankDetails, { loading: createBankDetailsLoading }] = useMutation<
    CreateBankDetails,
    CreateBankDetailsVariables
  >(CREATE_BANK_DETAILS);

  const [updateBankDetails, { loading: updateBankDetailsLoading }] = useMutation<
    UpdateBankDetails,
    UpdateBankDetailsVariables
  >(UPDATE_BANK_DETAILS);

  const bankDetails = data?.bankDetails;
  const isLoading = createBankDetailsLoading || updateBankDetailsLoading;

  useEffect(() => {
    if (bankDetails === null) {
      history.push(`${SETTINGS}${CREATE_BANK_DETAILS_URL}`);
    }
  }, [bankDetails]);

  const goToBankDetails = (): void => {
    history.push(`${SETTINGS}${BANK_DETAILS}`);
  };

  const handleFormSubmit = async (values: FormValues): Promise<void> => {
    let isMutationWithError = false;
    const businessUnitId = getBUFromLocalStorage();
    const variables = {
      businessUnit: businessUnitId,
      ...values,
    };

    try {
      if (isEditable) {
        const { errors } = await updateBankDetails({
          variables: {
            input: {
              id: Number(bankDetails?.id),
              ...variables,
            },
          },
        });

        isMutationWithError = !!errors;
      } else {
        const { errors } = await createBankDetails({
          variables: {
            input: variables,
          },
        });

        isMutationWithError = !!errors;
      }

      if (isMutationWithError) {
        showNotification({
          variant: "error",
          message: `Error ${isEditable ? "updating" : "creating"} bank details`,
        });
      } else {
        showNotification({
          message: `Bank details successfully ${isEditable ? "updated" : "created"}`,
        });

        goToBankDetails();
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Something went wrong! Please try again later.",
      });
    }
  };

  const handleCancelClick = (): void => {
    goToBankDetails();
  };

  const initialValues = {
    accountNo: bankDetails?.accountNo || "",
    accountName: bankDetails?.accountName || "",
    sortCode: bankDetails?.sortCode || "",
    isBusinessAccount: bankDetails?.isBusinessAccount || false,
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchemaContactRequest}
    >
      {({ values, errors, handleChange }: FormProps): JSX.Element => {
        return (
          <Form>
            <Flex container>
              <Flex item xs={12} l={6} xl={4}>
                <Spacer baselineHeight={2} />
                <FieldText
                  label="Sort code"
                  error={errors.sortCode}
                  inputProps={{
                    name: "sortCode",
                    value: values.sortCode,
                    onChange: handleChange,
                  }}
                />
                <FieldText
                  label="Account number"
                  error={errors.accountNo}
                  inputProps={{
                    name: "accountNo",
                    value: values.accountNo,
                    onChange: handleChange,
                  }}
                />
                <FieldText
                  label="Name on account"
                  error={errors.accountName}
                  inputProps={{
                    name: "accountName",
                    value: values.accountName,
                    onChange: handleChange,
                  }}
                />
                <CheckBox
                  label="Business Account?"
                  error={errors.isBusinessAccount}
                  name="isBusinessAccount"
                  checked={values.isBusinessAccount}
                  onChange={handleChange}
                />
                <Spacer baselineHeight={1} />
                <Flex container>
                  <Flex item>
                    <Button caption="Cancel" colour="grey" variant="hollow" onClick={handleCancelClick} />
                  </Flex>
                  <Flex item itemGutter>
                    <Button
                      caption={isEditable ? "Update" : "Create"}
                      type="submit"
                      requesting={isLoading}
                      disabled={isLoading}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};
