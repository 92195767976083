import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  DASHBOARD,
  BU_ID,
  ANIMALS,
  LIVESTOCK,
  ANIMAL_VIEW,
  ANIMALS_ARCHIVE,
  FIELDS,
  CREATE_FIELD,
  EDIT_FIELD,
  FIELD_VIEW,
  TAGS,
  TAG_VIEW,
} from "constants/Routes";
import { BU_TYPES } from "trading/constants";
import { useHasFeature } from "hooks";
import { Animals } from "pages/MyLivestock/Animals";
import { ArchiveAnimals } from "pages/MyLivestock/Archive";
import Fields from "pages/MyLivestock/Fields";
import AnimalView from "pages/MyLivestock/Animals/AnimalView";
import FieldView from "pages/MyLivestock/Fields/FieldView";
import CreateEditField from "pages/MyLivestock/Fields/CreateEditField";
import { EditField } from "fields/EditField";
import { Field } from "fields/Field";
import Tags from "tags/pages";
import { TagView } from "tags/pages/TagView";
import { getBUTypeFromLocalStorage } from "helpers/storage";
import { groupsRoutes, GROUPS_ROUTES_PATHS } from "groups/routes";

const ROUTES_PATHS = {
  ...GROUPS_ROUTES_PATHS,
  Livestock: `${BU_ID}${LIVESTOCK}`,
  Animals: `${BU_ID}${ANIMALS}`,
  AnimalView: `${BU_ID}${ANIMAL_VIEW}`,
  AnimalArchive: `${BU_ID}${ANIMALS_ARCHIVE}`,
  Fields: `${BU_ID}${FIELDS}`,
  CreateField: `${BU_ID}${CREATE_FIELD}`,
  EditField: `${BU_ID}${EDIT_FIELD}`,
  FieldView: `${BU_ID}${FIELD_VIEW}/:fieldId`,
  Tags: `${BU_ID}${TAGS}`,
  TagView: `${BU_ID}${TAG_VIEW}/:tagId`,
} as const;

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

const LivestockRoutes: React.FC = () => {
  const selectedBUType = getBUTypeFromLocalStorage();
  const isShowFields: boolean = useHasFeature("FIELDS");

  const isShowAnimalsForBuyer: boolean = useHasFeature("SHOW_ANIMALS_FOR_BUYER");
  const isShowAnimalsArchive: boolean = useHasFeature("ANIMALS_ARCHIVE");
  const useServerPaginatedAnimalsList = useHasFeature("SERVER_SIDE_PAGINATION_FOR_ANIMALS_LIST");

  const isFarmer: boolean = selectedBUType !== BU_TYPES.processor;

  const isAnimalsEnable: boolean = isFarmer || isShowAnimalsForBuyer;
  const isGroupsEnable: boolean = isAnimalsEnable;
  const isFieldsEnable: boolean = isShowFields && isAnimalsEnable;

  const animalsRoutes = [
    <Route exact path={ROUTES_PATHS.Animals} key={ROUTES_PATHS.Animals} component={Animals} />,
    <Route exact path={ROUTES_PATHS.AnimalView} key={ROUTES_PATHS.AnimalView} component={AnimalView} />,
    isShowAnimalsArchive && (
      <Route exact path={ROUTES_PATHS.AnimalArchive} key={ROUTES_PATHS.AnimalArchive} component={ArchiveAnimals} />
    ),
  ];

  const fieldsRoutes = [
    <Route exact path={ROUTES_PATHS.Fields} key={ROUTES_PATHS.Fields} component={Fields} />,
    <Route exact path={ROUTES_PATHS.CreateField} key={ROUTES_PATHS.CreateField} component={CreateEditField} />,
    <Route
      exact
      path={ROUTES_PATHS.EditField}
      key={ROUTES_PATHS.EditField}
      component={useServerPaginatedAnimalsList ? EditField : CreateEditField}
    />,
    <Route
      exact
      path={ROUTES_PATHS.FieldView}
      key={ROUTES_PATHS.FieldView}
      component={useServerPaginatedAnimalsList ? Field : FieldView}
    />,
  ];

  const tagsRoutes = [
    <Route key={ROUTES_PATHS.Tags} path={ROUTES_PATHS.Tags} component={Tags} />,

    <Route exact path={ROUTES_PATHS.TagView} key={ROUTES_PATHS.TagView} component={TagView} />,
  ];

  const redirects = [<Redirect key={ROUTES_PATHS.Livestock} from={LIVESTOCK} to={ANIMALS} exact />];

  return (
    <Route exact path={PATHS}>
      <Switch>
        {redirects}
        {isAnimalsEnable ? animalsRoutes : null}
        {isGroupsEnable ? groupsRoutes : null}
        {isFieldsEnable ? fieldsRoutes : null}
        {tagsRoutes}
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};

export default LivestockRoutes;
