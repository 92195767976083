import { gql } from "@apollo/client";

export const animalTypeFragment = gql`
  fragment animalTypeFragment on AnimalType {
    id
    name
    slug
  }
`;

export const animalBreedsFragment = gql`
  fragment animalBreedsFragment on AnimalBreed {
    id
    name
    breedrCode
    regName
    regBreedCode
    animalType {
      ...animalTypeFragment
    }
  }
  ${animalTypeFragment}
`;

export const animalFragment = gql`
  fragment animalFragment on Animal {
    id
    passportNumber
    isMale
    isCastrated
    dob
    currentWeight
    rflId
    dateMovedToFarm
    eId
    visualId
    brucellosisId
    trichId
    tsuBarcode
    herdDoggId
    pedigreeId
    tattoo
    deliveryDate
    buyerName
    field {
      id
      name
      location {
        id
        unitName
      }
    }
    lastWeight {
      date
      value
    }
    animalType {
      id
      slug
    }
    animalBreeds {
      id
      name
    }
    lot {
      id
      status
      isExpired
    }
    group {
      id
      name
      daysTillTarget
    }
    location {
      id
      unitName
      locationId
    }
    sexClassification {
      slug
      title
    }
    withdrawalEnd
    growthRate
    isRegulatorySynced
    lastRegulatorySyncedDate
    deadAt
    dateLeftFarm
    killWeight
    killQuality
    killFatScore
    targetDailyLiveWeightGain
    performanceCategory
    hasPreviousKeeperInfo
    sirePassportNumber
    cphCount
    isPregnant
    pregnancyDueDate
    tags {
      tagId
      name
      color
      schema {
        ownership
      }
    }
  }
`;

export const publicAnimalFragment = gql`
  fragment publicAnimalFragment on PublicAnimal {
    id
    passportNumber
    isMale
    isCastrated
    dob
    currentWeight
    visualId
    lastWeight {
      date
      value
    }
    animalType {
      id
      slug
    }
    animalBreeds {
      id
      name
      breedrCode
    }
    isRegulatorySynced
    lastRegulatorySyncedDate
    sexClassification {
      slug
      title
    }
    sirePassportNumber
    dateMovedToFarm
    growthRate
    cphCount
    targetDailyLiveWeightGain
    withdrawalEnd
    isPregnant
    pregnancyDueDate
  }
`;

export const singleAnimalFragment = gql`
  fragment singleAnimalFragment on Animal {
    id
    passportNumber
    isMale
    isCastrated
    dob
    offerId
    currentWeight
    lastWeight {
      value
    }
    animalBreeds {
      id
      name
    }
    breedPercentages {
      breed {
        id
        name
        breedrCode
      }
      percentage
    }
    field {
      id
      name
      location {
        id
        unitName
      }
    }
    visualId
    eId
    growthRate
    performanceCategory
    sirePassportNumber
    damPassportNumber
    group {
      id
      name
      daysTillTarget
    }
    lot {
      id
      status
    }
    rflId
    deliveryDate
    buyerName
    dateMovedToFarm
    daysToTargetWeight
    withdrawalEnd
    isRegulatorySynced
    targetDailyLiveWeightGain
    lastRegulatorySyncedDate
    deadAt
    dateLeftFarm
    killWeight
    killQuality
    killFatScore
    animalColour {
      id
      code
      name
    }
    sexClassification {
      slug
      title
    }
    isPregnant
    pregnancyDueDate
    animalType {
      id
    }
    tags {
      tagId
      color
      name
      schema {
        ownership
      }
    }
    brucellosisId
    tattoo
    tsuBarcode
    pedigreeId
    trichId
    breedrId
    uhfId
    name
    herdDoggId
    alternativeId
    serviceType
    calvingEaseScore
  }
`;

export const animalFiltersFragment = gql`
  fragment animalFiltersFragment on AnimalFilterObjectType {
    id
    animalType {
      id
      image
      colour
    }
    name
    breeds {
      id
    }
    isMale
    sexClassifications {
      slug
      title
    }
    ageFrom
    ageTo
    currentWeightFrom
    currentWeightTo
    lastWeightFrom
    lastWeightTo
    daysSinceLastWeightFrom
    daysSinceLastWeightTo
    dobFrom
    dobTo
    dofFrom
    dofTo
    growthRateFrom
    growthRateTo
    deliveryFrom
    deliveryTo
    fields {
      id
    }
    groups {
      id
    }
    lastRegulatorySyncedDateFrom
    lastRegulatorySyncedDateTo
    isRegulatorySynced
    inWithdrawal
    color
    isAlert
    isPregnant
    pregnancyDueDateFrom
    pregnancyDueDateTo
  }
`;

export const unprocessedAnimalFragment = gql`
  fragment unprocessedAnimalFragment on UnprocessedAnimal {
    id
    passportNumber
    date
    weight
    breed
    eId
    dob
    sex
  }
`;

export const animalOffspringFragment = gql`
  fragment animalOffspringFragment on PublicAnimal {
    id
    birthInformation {
      birthAbnormalities
      birthingDifficulty
      birthPresentation
      birthSize
      birthVigor
      birthWeight
    }
    dob
    isArchived
    isMale
    ownerId
    passportNumber
    sirePassportNumber
    visualId
  }
`;
