import React from "react";
// Libraries
import { DateTime } from "luxon";
// Utils
import { GetMedicineActivities_itemTransactions_edges_node } from "api/Medicine/types/GetMedicineActivities";
// Components
import { Flex, Icon, Spacer, Text } from "components";
import { useFormatDate } from "hooks";

interface BatchActivityProps {
  data?: GetMedicineActivities_itemTransactions_edges_node | null;
}

export const BatchActivity: React.FC<BatchActivityProps> = ({ data }) => {
  const { getFormatDate } = useFormatDate();

  if (!data) {
    return null;
  }
  return (
    <Flex container containerWrap="nowrap">
      <Flex
        container
        containerAlignContent="center"
        containerJustifyContent="center"
        item
        itemAlignSelf="center"
        itemGutter
      >
        <Icon name="medicines" size="medium" />
      </Flex>

      <Flex item itemGrow itemGutter>
        <Text>{data.shortDescription}</Text>

        <Spacer />

        <Text secondary smallest>
          <strong>
            {getFormatDate(data.date, DateTime.TIME_SIMPLE)} - {data.longDescription}
          </strong>
        </Text>
      </Flex>

      <Flex item itemGutter>
        <Text isSingleLine>
          <strong>{data.valueDescription}</strong>
        </Text>
      </Flex>
    </Flex>
  );
};
