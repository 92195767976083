function TagIcon({ fill }: { fill: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M0.950847 6.29936L10.5949 0.473594C11.9664 -0.299622 12.8878 0.000687236
        14.023 0.47709L23.0492 6.29936C23.6568 6.89353 24 7.69739 24 8.53621L24 21.4558C24 23.2033
        22.5523 24.0019 20.765 24.0019L3.23503 24.0019C1.44773 24.0019 5.63747e-06 23.2033 4.8929e-06
        21.4558L0 8.53621C-3.19102e-07 7.69739 0.343163 6.89352 0.950847 6.29936ZM12 8.53467C13.738 8.53467
        15.1469 7.15709 15.1469 5.45775C15.1469 3.75841 13.738 2.38083 12 2.38083C10.262 2.38083
        8.85306 3.75841 8.85306 5.45775C8.85306 7.15709 10.262 8.53467 12 8.53467Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default TagIcon;
