import { FC } from "react";
// Libraries
import cn from "classnames";
import { useHistory } from "react-router-dom";
// Components
import { Flex } from "components";
// Resources
import styles from "./Breadcrumbs.module.scss";

interface RootPaths {
  path: string;
  route?: string;
}

interface BreadcrumbsProps {
  rootPaths: RootPaths[];
  currentPath: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ rootPaths, currentPath }) => {
  const history = useHistory();

  return (
    <Flex className={styles.breadcrumbs} container>
      <Flex item itemGutter>
        {rootPaths.map((path) => (
          <button
            key={`breadcrumbs_${path.route}`}
            className={cn(styles.breadcrumbs__page, styles["breadcrumbs__page--link"])}
            onClick={(): void => (path.route ? history.push(path.route) : history.goBack())}
            type="button"
          >
            <span className={styles.breadcrumbs__page_caption}>{path.path}</span>
          </button>
        ))}

        <span className={styles.breadcrumbs__page}>
          <span className={styles.breadcrumbs__page_caption}>{currentPath}</span>
        </span>
      </Flex>
    </Flex>
  );
};
