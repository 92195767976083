import { useQuery, QueryResult } from "@apollo/client";
// Hooks
import { useGetCurrentBusinessUnit } from "hooks";
// Api
import { EMPTY_STATE } from "api/BusinessUnits/queries";
// Types
import { EmptyState, EmptyStateVariables } from "api/BusinessUnits/types/EmptyState";
import { EmptyStatePage } from "api/graphql-global-types";

const PLATFORM = "WEB";

export const useEmptyState = (page: keyof typeof EmptyStatePage): QueryResult<EmptyState, EmptyStateVariables> => {
  const { id, animalType } = useGetCurrentBusinessUnit();
  const businessUnitId = Number(id);
  let buAnimalType = Number(animalType?.id);

  if (page === EmptyStatePage.DEAD_WEIGHT) {
    buAnimalType = 3;
  }

  const data = useQuery<EmptyState, EmptyStateVariables>(EMPTY_STATE, {
    variables: {
      page,
      businessUnitId,
      animalType: buAnimalType,
      platform: PLATFORM,
    },
    skip: !buAnimalType && !businessUnitId && !page,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  return data;
};
