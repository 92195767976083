import { EmptyStates } from "components";
import { DeliveryDirectionEnum } from "generated/graphql";
import { useLocale } from "helpers/translations/src/useLocale";

export const EmptyStatesMovementsPage = ({
  direction,
  isDraft,
}: {
  direction: DeliveryDirectionEnum;
  isDraft: boolean;
}) => {
  const { terms } = useLocale();

  const emptyStatesMovementsPageParagraphs = {
    moveOnDraft: {
      title: "You haven’t created any move ons yet.",
      paragraph1:
        "Use move ons to create new animal records from a known source. Check out the Inventory & Movements report to keep track of all your animal movements.",
      paragraph2:
        "Tap ‘+ Create Move On’ to setup your first draft movement, revisit the draft at anytime to fill in the information and submit when you’re ready.",
    },
    moveOnSubmitted: {
      title: "You haven’t submitted any move ons yet.",
      paragraph1: "Use move ons to create new animal records from a known source.",
      paragraph2: "Check out the Inventory & Movements report to keep track of all your animal movements.",
    },
    moveOffDraft: {
      title: "You haven’t created any move offs yet.",
      paragraph1: `Use move offs to transfer animals between other ${terms.farm}er’s accounts within your network. Check out the Inventory & Movements report to keep track of all your animal movements.`,
      paragraph2:
        "Tap ‘+ Create Move Off’ to setup your first draft movement, revisit the draft at anytime to fill in the information and submit when you’re ready.",
    },
    moveOffSubmitted: {
      title: "You haven’t submitted any move offs yet.",
      paragraph1: `Use move offs to transfer animals between other ${terms.farm}er’s accounts within your network.`,
      paragraph2: "Check out the Inventory & Movements report to keep track of all your animal movements.",
    },
  };

  const paragraphData =
    emptyStatesMovementsPageParagraphs[
      `${direction === DeliveryDirectionEnum.Outward ? "moveOff" : "moveOn"}${isDraft ? "Draft" : "Submitted"}`
    ];

  return (
    <EmptyStates title={paragraphData.title} animation={"locations"}>
      <p>{paragraphData.paragraph1}</p>
      <p>{paragraphData.paragraph2}</p>
    </EmptyStates>
  );
};
