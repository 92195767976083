import { Text, Icon, Spinner, Flex } from "components";
import { useGetExportResultLazyQuery } from "generated/graphql";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import styles from "./AsyncJobStatusMenuExport.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { setMenuState, setTaskId } from "state/asyncJobSlice";

export type AsyncJobStatus = "loading" | "error" | "complete" | "warning";

export const AsyncJobStatusMenuExport: React.FC = () => {
  const dispatch = useDispatch();
  const { menuState, taskId } = useSelector((state: RootState) => state.asyncJob);
  const [getExportResult, { data, stopPolling, error }] = useGetExportResultLazyQuery();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (error) {
      stopPolling();
      setIsLoading(false);
      dispatch(setTaskId(undefined));
    }
  }, [dispatch, error, stopPolling]);

  useEffect(() => {
    if (taskId) {
      setIsLoading(true);

      getExportResult({
        variables: {
          id: taskId,
        },
        pollInterval: 5000,
      });
    }
    if (data?.exportResult?.isReady) {
      stopPolling();
      setIsLoading(false);
    }

    if (taskId && data?.exportResult?.url && data?.exportResult?.url.includes(taskId)) {
      window.location.assign(data?.exportResult?.url);
      dispatch(setTaskId(undefined));
    }

    return () => {
      stopPolling();
    };
  }, [data?.exportResult?.isReady, data?.exportResult?.url, dispatch, getExportResult, stopPolling, taskId]);

  const handleClick = () => {
    if (menuState === "collapsed") {
      dispatch(setMenuState("expanded"));
    } else {
      dispatch(setMenuState("collapsed"));
    }
  };

  const asyncJobStatus: AsyncJobStatus = isLoading ? "loading" : Boolean(error) ? "error" : "complete";

  return (
    <motion.div
      layout
      className={cn(styles.statusMenu, {
        [styles["statusMenu--collapsed"]]: menuState === "collapsed",
        [styles["statusMenu--expanded"]]: menuState === "expanded",
      })}
    >
      <Flex container containerJustifyContent="space-between" containerAlignItems="center" className={styles.menuBar}>
        <Flex container containerAlignItems="center">
          <Spacer width={16} />
          <Text smaller colour="white" className={styles.activityText}>
            Activity
          </Text>
          <Spacer width={16} />
          <StatusBadge isLoading={isLoading} isError={Boolean(error)} />
        </Flex>

        <Flex container containerJustifyContent="flex-end" containerAlignItems="center">
          <div className={styles.pointer} onClick={handleClick}>
            <Icon name={menuState === "collapsed" ? "maximize" : "minimize"} />
          </div>
          <Spacer width={26} />
          <div className={styles.pointer} onClick={() => dispatch(setMenuState("closed"))}>
            <Icon size="tiny" name="cross" />
          </div>
          <Spacer width={16} />
        </Flex>
      </Flex>
      <ul className={styles.activityList}>
        <JobMenuItem status={asyncJobStatus} isUpload={false} />
      </ul>
    </motion.div>
  );
};

export const StatusBadge = ({
  isLoading,
  isError,
  isWarning,
}: {
  isLoading: boolean;
  isError: boolean;
  isWarning?: boolean;
}) => {
  return (
    <div
      className={cn(styles.badge, {
        [styles["badge--loading"]]: isLoading,
        [styles["badge--error"]]: isError,
        [styles["badge--warning"]]: isWarning,
      })}
    >
      <Text
        className={cn(styles.badgeText, {
          [styles["badgeText--loading"]]: isLoading,
          [styles["badgeText--error"]]: isError,
          [styles["badgeText--warning"]]: isWarning,
        })}
        smaller
      >
        {isLoading ? "In Progress" : isError ? "Error" : "Complete"}
      </Text>
    </div>
  );
};

export const JobMenuItem = ({ status, isUpload }: { status: AsyncJobStatus; isUpload: boolean }) => {
  const statusIcon = (status: AsyncJobStatus) => {
    if (status === "loading") {
      return <Spinner style={{ height: "70%", width: "70%" }} />;
    } else if (status === "error") {
      return <Icon colour="white" name="warning" />;
    } else if (status === "warning") {
      return <Icon colour="white" name="warning" />;
    } else {
      return <Icon colour="white" name="check" />;
    }
  };
  const jobName = isUpload ? "Upload" : "Download";
  return (
    <>
      <Spacer height={16} />
      <li className={styles.menuItem}>
        <Flex container containerAlignItems="center">
          <div className={styles.iconBackground}>
            <Icon name="downloadFile" size="medium" />
          </div>
          <Text colour="black" smallest style={{ fontWeight: 400 }}>
            {status === "loading"
              ? `${jobName}ing CSV`
              : status === "error"
              ? `CSV ${jobName} Failed`
              : `CSV ${jobName} Complete`}
          </Text>
        </Flex>

        <div
          className={cn(styles.iconBackground, styles["iconBackground--status-color"], {
            [styles["iconBackground--status-color--loading"]]: status === "loading",
            [styles["iconBackground--status-color--error"]]: status === "error",
            [styles["iconBackground--status-color--warning"]]: status === "warning",
          })}
        >
          {statusIcon(status)}
        </div>
      </li>
      <Spacer height={16} />
    </>
  );
};

const Spacer = ({ width, height }: { width?: number; height?: number }) => {
  return <div style={{ width, height }} />;
};
