// React
import React, { useContext } from "react";
// Libraries
import { useMutation } from "@apollo/client";
// Utils
import { UPDATE_PASSWORD } from "api/User/mutations";
import { updatePassword as UpdatePassword, updatePasswordVariables } from "api/User/types/updatePassword";
import { CommonContext } from "config/commonProvider";

import { validationSchemaUpdatePassword, handleEnterPress, handleErrorCheck } from "helpers/general";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useBreakpoints } from "hooks";
// Components
import { PasswordStrength } from "components/Settings";
import { Button, FormBuilder, Flex, FieldPassword, Spacer } from "components";

const ModifyPasswordForm: React.FC = () => {
  const [updatePassword, { loading }] = useMutation<UpdatePassword, updatePasswordVariables>(UPDATE_PASSWORD);

  const { showNotification } = useContext(CommonContext);

  const handleChangePassword = async ({ prePassword, password, confirmPassword }, { resetForm }): Promise<void> => {
    try {
      const { data } = await updatePassword({
        variables: {
          input: {
            oldPassword: prePassword,
            password,
            confirmPassword,
          },
        },
      });

      const errors = data?.updatePassword?.errors;

      if (errors && errors.length) {
        showNotification({
          variant: "error",
          message: (errors && errors[0] && errors[0].message) || "Something went wrong",
        });
      } else {
        showNotification({
          message: "Password was successfully updated",
        });
        resetForm();
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Something went wrong! Please try again later.",
      });
    }
  };

  const { isBreakpointL } = useBreakpoints();

  return (
    <FormBuilder
      validateOnChange={false}
      initialValues={{
        prePassword: "",
        password: "",
        confirmPassword: "",
      }}
      onSubmit={handleChangePassword}
      validationSchema={validationSchemaUpdatePassword}
    >
      {({ values, errors, handleChange, handleSubmit }): JSX.Element => {
        const handleKeyPress = (e): void => {
          handleEnterPress(e, handleSubmit);
        };

        return (
          <>
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldPassword
                  label="Current password"
                  error={handleErrorCheck(errors.prePassword)}
                  inputProps={{
                    autoComplete: "new-password",
                    defaultValue: values.prePassword,
                    value: values.prePassword,
                    name: "prePassword",
                    onChange: handleChange,
                    onKeyPress: handleKeyPress,
                  }}
                />
              </Flex>
            </Flex>
            {!isBreakpointL ? <Spacer baselineHeight={2} /> : null}
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldPassword
                  label="New password"
                  error={handleErrorCheck(errors.password)}
                  inputProps={{
                    autoComplete: "new-password",
                    defaultValue: values.password,
                    value: values.password,
                    name: "password",
                    onChange: handleChange,
                    onKeyPress: handleKeyPress,
                  }}
                />
              </Flex>
              <Flex item itemGutter>
                {isBreakpointL ? <Spacer baselineHeight={4} /> : null}
                <PasswordStrength password={values.password} />
              </Flex>
            </Flex>
            {!isBreakpointL ? <Spacer baselineHeight={2} /> : null}
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldPassword
                  label="Confirm password"
                  error={handleErrorCheck(errors.confirmPassword)}
                  inputProps={{
                    autoComplete: "new-password",
                    defaultValue: values.confirmPassword,
                    value: values.confirmPassword,
                    name: "confirmPassword",
                    onChange: handleChange,
                    onKeyPress: handleKeyPress,
                  }}
                />
              </Flex>
            </Flex>
            <Spacer baselineHeight={2} />
            <Flex container>
              <Flex item itemGutter>
                <Button
                  id={ELEMENTS_IDS.MODIFY_PASSWORD_FORM_CHANGE_PASSWORD_BUTTON}
                  disabled={loading}
                  requesting={loading}
                  caption="Change password"
                  onClick={handleSubmit}
                />
              </Flex>
            </Flex>
          </>
        );
      }}
    </FormBuilder>
  );
};

export default ModifyPasswordForm;
