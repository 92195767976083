import { gql } from "@apollo/client";

export const pageInfoFragment = gql`
  fragment pageInfoFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const offeredAnimalWantFragment = gql`
  fragment offeredAnimalWantFragment on PublicAnimal {
    id
    eId
    visualId
    passportNumber
    isMale
    dob
    currentWeight
    lastWeight {
      value
    }
    animalType {
      id
      slug
    }
    animalBreeds {
      id
      name
    }
    sexClassification {
      slug
      title
    }
  }
`;

export const offeredAnimalFragment = gql`
  fragment offeredAnimalFragment on Animal {
    id
    passportNumber
    isMale
    withdrawalEnd
    isRegulatorySynced
    lastRegulatorySyncedDate
    dob
    eId
    visualId
    dateMovedToFarm
    currentWeight
    lastWeight {
      value
    }
    group {
      id
      name
    }
    animalType {
      id
      slug
    }
    animalBreeds {
      id
      name
    }
    field {
      id
      name
      location {
        id
        unitName
      }
    }
    sexClassification {
      slug
      title
    }
  }
`;

export const offeredAnimalGroupFragment = gql`
  fragment offeredAnimalGroupFragment on Animal {
    id
    passportNumber
    isMale
    withdrawalEnd
    isRegulatorySynced
    lastRegulatorySyncedDate
    dob
    isCastrated
    rflId
    eId
    visualId
    isPregnant
    pregnancyDueDate
    deliveryDate
    buyerName
    currentWeight
    growthRate
    dateMovedToFarm
    tags {
      tagId
      name
      color
      schema {
        ownership
      }
    }
    lot {
      id
      status
      isExpired
    }
    location {
      id
      unitName
      locationId
    }
    lastWeight {
      date
      value
    }
    animalType {
      id
      slug
    }
    animalBreeds {
      id
      name
    }
    field {
      id
      name
      location {
        id
        unitName
      }
    }
    sexClassification {
      slug
      title
    }
  }
`;

export const errorFragment = gql`
  fragment errorFragment on ValidationError {
    field
    message
  }
`;

export const offerFragment = gql`
  fragment offerFragment on Offer {
    id
    status
    offeredNumber
    actualStatus
    invoice {
      id
      finalPrice
      priceUnit
      currency {
        id
        code
      }
    }
    createdBy {
      id
      email
      phone
      firstName
      lastName
    }
    businessUnit {
      id
      name
      locationData
      city
      primaryLocation {
        id
        postcode
      }
    }
  }
`;

export const wantOfferDetailsDataFragment = gql`
  fragment wantOfferDetailsDataFragment on Offer {
    id
    actualStatus
    offeredNumber
    createdBy {
      id
      email
      phone
      firstName
      lastName
    }
    businessUnit {
      id
      name
      locationData
      city
    }
    invoice {
      id
      price
      totalPrice
      finalPrice
      priceUnit
      currency {
        id
        code
      }
    }
    producedLot {
      id
      ... on PublicLot {
        gradingFile
        animalsCount
        animals {
          ...offeredAnimalWantFragment
        }
      }
    }
    producedLotInterface {
      ... on OwnLot {
        id
        deliveredAnimals {
          ...offeredAnimalFragment
        }
        animals {
          ...offeredAnimalFragment
        }
        animalsCount
        gradingFile
      }
    }
  }
  ${offeredAnimalWantFragment}
  ${offeredAnimalFragment}
`;

export const deliveryAddressFragment = gql`
  fragment deliveryAddressFragment on DeliveryAddressType {
    id
    unitName
    addressLine1
    addressLine2
    city
    country {
      id
      code
      name
    }
    postcode
    primary
    locationType
    locationId
  }
`;
