import React, { ChangeEvent } from "react";
import IntercomComponent from "pages/Intercom/IntercomComponent";
import { Page } from "components";
import { SubscriptionContent } from "subscriptions/components/SubscriptionContent";
import { SubscriptionHeader } from "subscriptions/components/SubscriptionHeader";
import { SubscribeToPro } from "subscriptions/components/SubscribeToPro";
import { ProFeatures, useSubscriptionPlanFeatures } from "feShared/subscriptions/hooks/useSubscriptionPlanFeatures";
import { useAuth } from "auth/hooks/useAuth";

export const Subscription: React.FC = () => {
  const { changeBusinessUnit, activeBUId } = useAuth();

  const { isProFeatureEnabledForBU } = useSubscriptionPlanFeatures(activeBUId ?? 0, true);
  const hasAnimalManagementSubscriptionFeature = isProFeatureEnabledForBU(ProFeatures.AnimalManagement);

  const handleChangeBusinessUnit = (event: ChangeEvent<HTMLSelectElement>): void => {
    changeBusinessUnit(event.target.value);
  };

  return (
    <Page>
      <SubscriptionHeader handleChangeBusinessUnit={handleChangeBusinessUnit} currentBU={activeBUId} />
      {hasAnimalManagementSubscriptionFeature ? (
        <SubscriptionContent currentBU={activeBUId} />
      ) : (
        <div className="bg-white mt-4 py-4 px-5 rounded">
          <SubscribeToPro showInvoiceHistoryButton={false} />
        </div>
      )}
      <IntercomComponent />
    </Page>
  );
};
