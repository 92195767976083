import React, { useEffect } from "react";
import { Tabs, SubTab } from "components/Common/Tabs";
import { useHistory, useParams } from "react-router-dom";
import { useHasFeature } from "hooks";
import { getBUTypeFromLocalStorage } from "helpers/storage";
import { BU_TYPES } from "trading/constants";
import { SUPPLIER_MANAGEMENT } from "constants/Routes";

export enum SUPPLIER_TYPES {
  SELLERS = "sellers",
  BUYERS = "buyers",
}

export const PreferredSuppliersTabs: React.FC = () => {
  const { supplierType } = useParams<SettingsSuppliersPageParams>();
  const history = useHistory();
  const isShowBuyersTabForFarmers: boolean = useHasFeature("SHOW_BUYERS_TAB_TO_FARMERS");
  const isShowSellersTabForFarmers: boolean = useHasFeature("SHOW_SELLERS_TAB_TO_FARMERS");
  const currentBUType: string | null = getBUTypeFromLocalStorage();
  const isProcessor: boolean = currentBUType === BU_TYPES.processor;
  const supplierTabs = [
    {
      key: 0,
      name: SUPPLIER_TYPES.BUYERS[0].toUpperCase() + SUPPLIER_TYPES.BUYERS.slice(1),
      route: `${SUPPLIER_MANAGEMENT}/${SUPPLIER_TYPES.BUYERS}`,
      skip: isProcessor || (!isProcessor && !isShowBuyersTabForFarmers),
    },
    {
      key: 1,
      name: SUPPLIER_TYPES.SELLERS[0].toUpperCase() + SUPPLIER_TYPES.SELLERS.slice(1),
      route: `${SUPPLIER_MANAGEMENT}/${SUPPLIER_TYPES.SELLERS}`,
      skip: !isProcessor && !isShowSellersTabForFarmers,
    },
  ];

  const selectedTab: SubTab | undefined = supplierTabs.find((item) =>
    supplierType ? item.name.toLowerCase() === supplierType : !item.skip,
  );
  const selectedTabKey: number = selectedTab?.key || 0;
  const selectedTabName: string = selectedTab?.name || "";

  useEffect(() => {
    if (selectedTabName && supplierType !== selectedTabName.toLowerCase()) {
      history.push(`${SUPPLIER_MANAGEMENT}/${selectedTabName.toLowerCase()}`);
    }
  }, [supplierType, selectedTabName]);

  return <Tabs tabs={supplierTabs} selectedTab={selectedTabKey} />;
};
