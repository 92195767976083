/* eslint-disable react/display-name */
import React, { useMemo, useCallback, useState, CSSProperties } from "react";
import { useQuery } from "@apollo/client";
import { Column, Row } from "react-table";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import Tooltip from "@material-ui/core/Tooltip";
// Api
import { GET_GROUPS_COUNT } from "groups/api/queries";
import { GetGroupsCount, GetGroupsCountVariables } from "api/MyLivestock/Groups/types/GetGroupsCount";
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { GetGroup_group } from "api/MyLivestock/Groups/types/GetGroup";
// Utils
import { ICON_COLOURS } from "constants/Icons";
import { TABLE_IDS } from "constants/Interface";
import { BCMSNotSync, BCMSSync } from "constants/SvgIcons";
import { TABLE_PAGINATION_DEFAULT_OPTIONS } from "components/Common/Table/TablePagination";
import { getBUFromLocalStorage } from "helpers/storage";
import { getFormattedNumber } from "helpers/general";
import { getAnimalBreedsAsString, getMonthsAge, getDaysOnFarm, getFieldName } from "helpers/myLivestock";
import { useFormatDate, useGetCurrentBusinessUnit, useHasFeature } from "hooks";
// Components
import {
  AnimalsPerformancePill,
  AnimalPregnancyLabel,
  AnimalsSexClassLabel,
  AnimalsTablePreviewModal,
} from "components/MyLivestock";
import { Icon, Pill, Table, TableHeaderCaptions } from "components";
import { getActionsColumn, getRowSelectColumn, sortExcludeFalsy } from "components/Common/Table";
import TableActionColumn from "pages/MyLivestock/Animals/TableActionColumn";
import { SelectedAnimalsTable } from "animals/SelectedAnimalsList";
// Resources
import styles from "./styles.module.scss";
import { GetFormatDate } from "hooks/useFormatDate";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { Tag } from "tags/components/Tag";
import { useLocale } from "helpers/translations/src/useLocale";
import { COLUMN_IDS } from "animals/AnimalsList/constants";
import { AnimalFragment } from "generated/graphql";

export interface AnimalsTableProps {
  animals: GetAnimalsList_animalsExtended_animals[]; // todo convert this to TS Gene0ric to remove some @ts-nocheck
  fromPage?: string;
  group?: GetGroup_group;
  onPressEditAnimal?: (animal: AnimalFragment) => void;
  isArchive?: boolean;
  isCarcass?: boolean;
  isProcessorView?: boolean;
  isShowActionButtons?: boolean;
  selectedRowIds?: Set<string> | null;
  showCheckboxes?: boolean;
  showSelectedAnimals?: boolean;
  tableId: string;
  isShowQuickView?: boolean;
  preventAnimalClick?: boolean;
  tablePageSize?: typeof TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES[number];
  rowStyles?: (row: Row<GetAnimalsList_animalsExtended_animals>) => CSSProperties;
  emptyTableText?: string;
}

// TODO- move these functions into AnimalList helpers and destroy this piece of shitake

export const renderStatus = (
  {
    isRegulatorySynced,
    withdrawalEnd,
    deadAt,
    dateLeftFarm,
  }: { isRegulatorySynced: boolean | null | undefined; withdrawalEnd: any; deadAt?: string; dateLeftFarm?: string },
  options: {
    isArchive: boolean;
    isCarcass: boolean | undefined;
    isGb: boolean;
  },
  getFormatDate: GetFormatDate,
): JSX.Element => {
  const colour: keyof typeof ICON_COLOURS = withdrawalEnd ? "red500" : "grey_dark";
  const status: string = withdrawalEnd ? `Withdrawal ends ${getFormatDate(withdrawalEnd)}` : "Not in withdrawal";

  if (!options.isArchive && (Boolean(deadAt) || Boolean(dateLeftFarm))) {
    return (
      <Tooltip
        title={"Animal is no longer on farm"}
        classes={{
          tooltip: styles.tooltip,
          popper: styles.tooltipPopper,
        }}
      >
        <Icon colour="red500" name="warning" size="medium" />
      </Tooltip>
    );
  }

  return (
    <>
      {!options.isCarcass && options.isGb ? (
        <div className={styles.isBcmsSyncedIcon}>
          {isRegulatorySynced ? <BCMSSync data-testid="syncedIcon" /> : <BCMSNotSync data-testid="unSyncedIcon" />}
        </div>
      ) : null}

      {!options.isArchive ? (
        <Tooltip
          title={status}
          classes={{
            tooltip: styles.tooltip,
            popper: styles.tooltipPopper,
          }}
        >
          <Icon name="needle" size="medium" colour={colour} />
        </Tooltip>
      ) : null}
    </>
  );
};

// TODO: Rework after Angus convention
export const renderPedigreeStatus = (
  {
    pedigreeId,
    withdrawalEnd,
    deadAt,
    dateLeftFarm,
  }: { pedigreeId: string | null | undefined; withdrawalEnd: any; deadAt?: string; dateLeftFarm?: string },
  options: {
    isArchive: boolean;
    isCarcass: boolean | undefined;
  },
  getFormatDate: GetFormatDate,
): JSX.Element => {
  const isPedigree = Boolean(pedigreeId);
  const pedigreeColour: keyof typeof ICON_COLOURS = isPedigree ? "green_white" : "grey_dark";
  const pedigreeStatus: string = isPedigree ? "Pedigree" : "Not Pedigree";

  const withdrawalColour: keyof typeof ICON_COLOURS = withdrawalEnd ? "red500" : "grey_dark";
  const withdrawalStatus: string = withdrawalEnd
    ? `Withdrawal ends ${getFormatDate(withdrawalEnd)}`
    : "Not in withdrawal";

  if (!options.isArchive && (Boolean(deadAt) || Boolean(dateLeftFarm))) {
    return (
      <Tooltip
        title={"Animal is no longer on farm"}
        classes={{
          tooltip: styles.tooltip,
          popper: styles.tooltipPopper,
        }}
      >
        <Icon colour="red500" name="warning" size="medium" />
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip
        title={pedigreeStatus}
        classes={{
          tooltip: styles.tooltip,
          popper: styles.tooltipPopper,
        }}
      >
        <Icon name="breeding" size={isPedigree ? "large" : "medium"} colour={pedigreeColour} circled={isPedigree} />
      </Tooltip>

      {!options.isArchive ? (
        <Tooltip
          title={withdrawalStatus}
          classes={{
            tooltip: styles.tooltip,
            popper: styles.tooltipPopper,
          }}
        >
          <div style={{ marginLeft: 8 }}>
            <Icon
              name="needle"
              size={withdrawalEnd ? "large" : "medium"}
              circled={withdrawalEnd}
              colour={withdrawalColour}
            />
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};

export const AnimalsTable: React.FC<AnimalsTableProps> = ({
  animals,
  fromPage,
  group,
  isArchive = false,
  isCarcass,
  onPressEditAnimal,
  isProcessorView,
  isShowActionButtons,
  selectedRowIds,
  showCheckboxes,
  showSelectedAnimals,
  tableId,
  isShowQuickView = false,
  preventAnimalClick = false,
  tablePageSize = TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES[0],
  rowStyles,
  emptyTableText,
}) => {
  const [animalQuickView, setAnimalQuickView] = useState<GetAnimalsList_animalsExtended_animals | null>(null);

  const memoizedSortExcludeFalsy = useMemo(() => sortExcludeFalsy, [sortExcludeFalsy]);
  const history = useHistory();
  const showPregnancyStatus = useHasFeature("SHOW_PREGNANCY_STATUS");
  const { weightUnits, countryIsoCode } = useGetCurrentBusinessUnit();
  const countryCode = useMemo(
    () => ({ us: countryIsoCode === "us", gb: countryIsoCode === "gb", au: countryIsoCode === "au" }),
    [countryIsoCode],
  );
  const hasExtendedAnimalIds = useHasFeature("HAS_EXTENDED_ANIMAL_IDS");
  const { getFormatDate } = useFormatDate();
  const { terms } = useLocale();
  const { data: groupsCountData } = useQuery<GetGroupsCount, GetGroupsCountVariables>(GET_GROUPS_COUNT, {
    skip: !isShowActionButtons,
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const numberOfGroups = groupsCountData?.groupsExtended?.groupsCount;

  const data = useMemo(() => animals, [animals]);

  const clickCellCallback = useCallback(
    (animal: GetAnimalsList_animalsExtended_animals) => {
      if (isShowQuickView) {
        setAnimalQuickView(animal);
      } else {
        history.push(`/system/${fromPage || "livestock"}/animal/${animal.id}`);
      }
    },
    [fromPage, history, isShowQuickView],
  );

  const handleClickCell = !preventAnimalClick ? clickCellCallback : null;

  const handleModalClose = (): void => {
    setAnimalQuickView(null);
  };

  // NOTE FOR FUTURE REF
  // the column configuration is shared with selected animals table component in particular
  // the selection column which shares the same ID as the 'source' table this (accidental
  // but useful) behaviour ensures selectedRowsIds property in table context is shared
  // across the two tables
  //@ts-expect-error
  const columns: Column<GetAnimalsList_animalsExtended_animals>[] = React.useMemo(
    () => [
      {
        ...getRowSelectColumn<GetAnimalsList_animalsExtended_animals>({
          isRowSelectAll: true,
          tableId,
        }),
        sticky: "left",
      },
      {
        id: COLUMN_IDS.ANIMAL_ID,
        Header: "Animal ID",
        sticky: "left",
        onCellClick: handleClickCell,
        accessor: "passportNumber",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.E_ID,
        Header: "EID",
        onCellClick: handleClickCell,
        accessor: "eId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.E_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.VISUAL_ID,
        Header: "VID",
        onCellClick: handleClickCell,
        accessor: "visualId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.VISUAL_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.BRUCELLOSIS_ID,
        Header: "Brucellosis ID",
        onCellClick: handleClickCell,
        accessor: "brucellosisId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.BRUCELLOSIS_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.TRICH_ID,
        Header: "Trich ID",
        onCellClick: handleClickCell,
        accessor: "trichId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.TRICH_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.TSU_BARCODE,
        Header: "TSU Barcode",
        onCellClick: handleClickCell,
        accessor: "tsuBarcode",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.TSU_BARCODE] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.HERDDOGG_ID,
        Header: "HerdDogg ID",
        onCellClick: handleClickCell,
        accessor: "herdDoggId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.HERDDOGG_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.PEDIGREE_ID,
        Header: "Pedigree ID",
        onCellClick: handleClickCell,
        accessor: "pedigreeId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.PEDIGREE_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.TATTOO_ID,
        Header: "Tattoo ID",
        onCellClick: handleClickCell,
        accessor: "tattoo",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.TATTOO_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.BREED,
        Header: "Breed",
        onCellClick: handleClickCell,
        accessor: (row): string => getAnimalBreedsAsString(row.animalBreeds),
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.BREED] || "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.TAGS,
        Header: "Tags",
        accessor: "tags",
        onCellClick: handleClickCell,
        Cell: function TagsCell({ row }) {
          const { tags } = row?.original;

          return tags?.map((tag) => {
            const t = {
              tagId: tag.tagId,
              name: tag.name,
              color: tag.color,
              ownership: tag?.schema?.ownership,
            };
            return <Tag key={tag.tagId} tag={t} />;
          });
        },
        minWidth: 100,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.SEX,
        Header: "Sex",
        onCellClick: handleClickCell,
        accessor: ({ isMale, sexClassification }): string => sexClassification?.slug || (isMale ? "M" : "F"),
        Cell: ({ row }): React.ReactNode => {
          const { id, isCastrated, isMale, sexClassification } = row?.original;

          return (
            <AnimalsSexClassLabel
              id={`animal_sex_label__${id}`}
              isCastrated={isCastrated}
              isMale={isMale}
              label={sexClassification?.title || null}
            />
          );
        },
        minWidth: 100,
        width: 100,
      },
      {
        id: COLUMN_IDS.AGE,
        Header: <TableHeaderCaptions title="Age" subtitle="Months" />,
        onCellClick: handleClickCell,
        accessor: ({ dateLeftFarm, deadAt, dob }): number | null => {
          // if dead, calculate age at death
          if (deadAt) return getMonthsAge(dob, deadAt);

          // if off farm, calculate age at move off farm
          if (dateLeftFarm) return getMonthsAge(dob, dateLeftFarm);

          // otherwise just calculate current age
          return getMonthsAge(dob);
        },
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.AGE] || "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 100,
        width: 100,
      },
      {
        id: COLUMN_IDS.DATE_OF_BIRTH,
        Header: "DoB",
        onCellClick: handleClickCell,
        accessor: "dob",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DATE_OF_BIRTH] ? getFormatDate(row.values[COLUMN_IDS.DATE_OF_BIRTH]) : "\u2014",
        minWidth: 120,
        width: 120,
      },
      {
        id: COLUMN_IDS.DATE_ON_FARM,
        Header: "Date on farm",
        onCellClick: handleClickCell,
        accessor: "dateMovedToFarm",
        Cell: ({ value }): React.ReactNode => (value ? getFormatDate(value) : "\u2014"),
        minWidth: 120,
        width: 120,
      },
      {
        id: COLUMN_IDS.DAYS_ON_FARM,
        Header: `Days on ${terms.farm}`,
        onCellClick: handleClickCell,
        accessor: (row): number | null =>
          row?.dateMovedToFarm
            ? getDaysOnFarm({
                dateMovedToFarm: row.dateMovedToFarm,
                dateLeftFarm: row?.dateLeftFarm,
                deadAt: row?.deadAt,
              })
            : null,
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DAYS_ON_FARM] ? `${row.values[COLUMN_IDS.DAYS_ON_FARM]} days` : "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 100,
        width: 100,
      },
      {
        id: COLUMN_IDS.CURRENT_WEIGHT,
        Header: <TableHeaderCaptions title="Current weight" subtitle="Estimated" />,
        onCellClick: handleClickCell,
        accessor: ({ currentWeight }): number | null => (currentWeight ? +currentWeight?.toFixed(1) : null),
        Cell: ({ row }): React.ReactNode =>
          !!row.values[COLUMN_IDS.CURRENT_WEIGHT] ? (
            <AnimalsPerformancePill
              label={`${row.values[COLUMN_IDS.CURRENT_WEIGHT]} ${weightUnits}`}
              performanceCategory={row?.original?.performanceCategory}
            />
          ) : (
            "\u2014"
          ),
        sortType: memoizedSortExcludeFalsy,
        minWidth: 160,
        width: 160,
      },
      {
        id: COLUMN_IDS.LAST_WEIGHT,
        Header: "Last Weight",
        onCellClick: handleClickCell,
        accessor: ({ lastWeight }): number | null => lastWeight?.value || null,
        Cell: ({ row }): React.ReactNode =>
          !!row.values[COLUMN_IDS.LAST_WEIGHT] ? (
            <Pill caption={`${getFormattedNumber(row.values[COLUMN_IDS.LAST_WEIGHT])} ${weightUnits}`} colour="grey" />
          ) : (
            "\u2014"
          ),
        sortType: memoizedSortExcludeFalsy,
        minWidth: 120,
        width: 120,
      },
      {
        id: COLUMN_IDS.DAYS_SINCE_LAST_WEIGHT,
        Header: "Days since weighed",
        onCellClick: handleClickCell,
        accessor: ({ lastWeight }): number | null =>
          lastWeight?.date ? Math.trunc(DateTime.local().diff(DateTime.fromISO(lastWeight.date), ["days"]).days) : null,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.DAYS_SINCE_LAST_WEIGHT] || "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 120,
        width: 120,
      },
      {
        id: COLUMN_IDS.DLWG,
        Header: terms.avgDlwg,
        onCellClick: handleClickCell,
        accessor: ({ growthRate }): string | null => growthRate?.toFixed(1) || null,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.DLWG] || "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 100,
        width: 100,
      },
      {
        id: COLUMN_IDS.PREGNANCY_STATUS,
        Header: <TableHeaderCaptions title="Pregnancy" subtitle="Status" />,
        onCellClick: handleClickCell,
        accessor: "isPregnant",
        Cell: ({ row: { original } }): React.ReactNode =>
          !original?.isMale && original?.isPregnant ? (
            <AnimalPregnancyLabel
              dueDate={original?.pregnancyDueDate}
              id={`animal_pregnancy_label__${original?.id}`}
              isMale={original?.isMale}
              isPregnant={!!original?.isPregnant}
            />
          ) : (
            "\u2014"
          ),
        sortType: memoizedSortExcludeFalsy,
        minWidth: 120,
        width: 120,
      },
      {
        id: COLUMN_IDS.DELIVERY_DATE,
        Header: "Delivery date",
        onCellClick: handleClickCell,
        accessor: "deliveryDate",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DELIVERY_DATE] ? getFormatDate(row.values[COLUMN_IDS.DELIVERY_DATE]) : "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.GROUP,
        Header: "Group",
        onCellClick: handleClickCell,
        accessor: ({ group }): string | null => group?.name || null,
        Cell: ({ row }): React.ReactElement => row.values[COLUMN_IDS.GROUP] || "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.FIELD,
        Header: capitaliseFirstLetter("location") as string,
        onCellClick: handleClickCell,
        accessor: ({ field }): string | null =>
          field ? getFieldName({ fieldName: field.name, unitName: field.location?.unitName }) : null,
        Cell: ({ row }): React.ReactElement => row.values[COLUMN_IDS.FIELD] || "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.LAST_SYNCED,
        Header: "Last Synced",
        onCellClick: handleClickCell,
        accessor: "lastRegulatorySyncedDate",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.LAST_SYNCED] ? getFormatDate(row.values[COLUMN_IDS.LAST_SYNCED]) : "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.DEAD_AT,
        Header: "Dead At Date",
        onCellClick: handleClickCell,
        accessor: "deadAt",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DEAD_AT] ? getFormatDate(row.values[COLUMN_IDS.DEAD_AT]) : "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.DATE_LEFT_FARM,
        Header: "Date Left Farm",
        onCellClick: handleClickCell,
        accessor: "dateLeftFarm",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DATE_LEFT_FARM] ? getFormatDate(row.values[COLUMN_IDS.DATE_LEFT_FARM]) : "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.KILL_WEIGHT,
        Header: "Kill Weight",
        onCellClick: handleClickCell,
        accessor: "killWeight",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.KILL_WEIGHT]
            ? `${getFormattedNumber(row.values[COLUMN_IDS.KILL_WEIGHT])} ${weightUnits}`
            : "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.KILL_QUALITY,
        Header: "Kill Quality",
        onCellClick: handleClickCell,
        accessor: "killQuality",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.KILL_QUALITY] ? row.values[COLUMN_IDS.KILL_QUALITY] : "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.KILL_FAT_SCORE,
        Header: "Kill Fat Score",
        onCellClick: handleClickCell,
        accessor: "killFatScore",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.KILL_FAT_SCORE] ? row.values[COLUMN_IDS.KILL_FAT_SCORE] : "\u2014",
        sortType: memoizedSortExcludeFalsy,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.STATUS,
        Header: "Status",
        sticky: "right",
        onCellClick: handleClickCell,
        accessor: (animal): JSX.Element => {
          return renderStatus(
            {
              withdrawalEnd: animal.withdrawalEnd,
              isRegulatorySynced: animal.isRegulatorySynced,
              dateLeftFarm: animal.dateLeftFarm,
              deadAt: animal.deadAt,
            },
            {
              isArchive,
              isCarcass,
              isGb: countryCode.gb,
            },
            getFormatDate,
          );
        },
        disableSortBy: true,
        minWidth: isArchive ? 75 : 100,
        width: isArchive ? 75 : 100,
      },
      {
        ...getActionsColumn<GetAnimalsList_animalsExtended_animals>(!!onPressEditAnimal),
        Cell: ({ row: { original } }): JSX.Element | null => {
          if (onPressEditAnimal) {
            return (
              <button className="flex" onClick={() => onPressEditAnimal(original)}>
                <Icon name="pencil" size="small" colour="blue" />
                <p className="pl-2">Edit</p>
              </button>
            );
          }

          return (
            <TableActionColumn
              animal={original}
              group={group}
              isFarmWithGroups={numberOfGroups ? numberOfGroups > 0 : false}
            />
          );
        },
        sticky: "right",
      },
    ],
    [
      tableId,
      handleClickCell,
      memoizedSortExcludeFalsy,
      terms.farm,
      terms.avgDlwg,
      isArchive,
      onPressEditAnimal,
      getFormatDate,
      weightUnits,
      isCarcass,
      countryCode,
      group,
      numberOfGroups,
    ],
  );

  const columnVisibilityRules = {
    [COLUMN_IDS.SELECTION]: showCheckboxes,
    [COLUMN_IDS.DAYS_ON_FARM]: !isCarcass && !isProcessorView,
    [COLUMN_IDS.CURRENT_WEIGHT]: !isArchive && !isCarcass,
    [COLUMN_IDS.LAST_WEIGHT]: !isCarcass,
    [COLUMN_IDS.DAYS_SINCE_LAST_WEIGHT]: !isArchive && !isCarcass,
    [COLUMN_IDS.DLWG]: !isCarcass,
    [COLUMN_IDS.GROUP]: !group && !isProcessorView,
    [COLUMN_IDS.FIELD]: !isArchive && !isProcessorView,
    [COLUMN_IDS.DEAD_AT]: isArchive,
    [COLUMN_IDS.DATE_LEFT_FARM]: isArchive,
    [COLUMN_IDS.KILL_WEIGHT]: isArchive,
    [COLUMN_IDS.KILL_QUALITY]: isArchive,
    [COLUMN_IDS.KILL_FAT_SCORE]: isArchive,
    [COLUMN_IDS.LAST_SYNCED]: !isProcessorView && countryCode.gb,
    [COLUMN_IDS.STATUS]: !isProcessorView,
    [COLUMN_IDS.ACTION_BUTTONS]: isShowActionButtons || onPressEditAnimal,
    [COLUMN_IDS.PREGNANCY_STATUS]: showPregnancyStatus,
    [COLUMN_IDS.TAGS]: !fromPage?.includes("listing"),
    [COLUMN_IDS.BRUCELLOSIS_ID]: hasExtendedAnimalIds,
    [COLUMN_IDS.HERDDOGG_ID]: hasExtendedAnimalIds,
    [COLUMN_IDS.PEDIGREE_ID]: hasExtendedAnimalIds,
    [COLUMN_IDS.TATTOO_ID]: hasExtendedAnimalIds,
    [COLUMN_IDS.TRICH_ID]: hasExtendedAnimalIds,
    [COLUMN_IDS.TSU_BARCODE]: hasExtendedAnimalIds,
  };

  const hiddenColumns = Object.keys(columnVisibilityRules).filter((key) => {
    return !columnVisibilityRules[key];
  });

  const isBrandedForActiveSpecies = tableId !== TABLE_IDS.FIELD_ANIMALS;

  return (
    <>
      {showSelectedAnimals ? (
        <SelectedAnimalsTable
          // @ts-expect-error
          columns={columns}
          hiddenColumns={hiddenColumns}
          // @ts-expect-error
          sourceTableId={tableId}
          // @ts-expect-error
          rowStyles={rowStyles}
        />
      ) : (
        <Table<GetAnimalsList_animalsExtended_animals>
          data={data}
          emptyTableText={emptyTableText}
          columns={columns}
          hiddenColumns={hiddenColumns}
          pagination
          initialPageSize={tablePageSize}
          isAnimals={isBrandedForActiveSpecies}
          selectedRowIds={showCheckboxes && selectedRowIds ? selectedRowIds : null}
          tableId={tableId}
          rowStyles={rowStyles}
        />
      )}

      {isShowQuickView ? <AnimalsTablePreviewModal animal={animalQuickView} onClose={handleModalClose} /> : null}
    </>
  );
};
