// React
import React, { memo } from "react";
// Libraries
import cn from "classnames";
// Utils
import { BASELINE } from "constants/Interface";
// Resources
import styles from "./Spacer.module.scss";

interface SpacerProps {
  allowDecimal?: boolean;
  baselineHeight?: number;
  border?: "top" | "middle" | "bottom";
  className?: string;
  gutter?: boolean;
}

export const Spacer: React.FC<SpacerProps> = memo(({ allowDecimal, baselineHeight = 0, border, className, gutter }) => (
  <hr
    className={cn(
      styles.spacer,
      {
        [styles["spacer--border"]]: border,
        [styles[`spacer--border__${border}`]]: border,
        [styles["spacer--gutter"]]: gutter,
      },
      className,
    )}
    style={{ height: BASELINE * (allowDecimal ? baselineHeight : Math.round(baselineHeight)) }}
  />
));
Spacer.displayName = "Spacer";
