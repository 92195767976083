import { VFC } from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./WYSIWYG.module.scss";

interface WYSIWYGProps {
  content: string;
  size?: "small";
}

export const WYSIWYG: VFC<WYSIWYGProps> = ({ content, size = "default" }) => (
  <div className={cn(styles.wysiwyg, styles[`wysiwyg--size__${size}`])} dangerouslySetInnerHTML={{ __html: content }} />
);
