import React from "react";
// Libraries
import cn from "classnames";
// Components
import { Badge, Flex, Spacer, Text } from "components";
// Utils
import { getCurrencyCode, getFormattedPrice } from "helpers/general";
// Resources
import styles from "./TradeTile.module.scss";

export const TEST_IDS = {
  ACTION_ITEM: "action_item",
  ACTION_ITEMS: "action_items",
  FULFILLMENT_STATUSES: "fulfillment_statuses",
  ROOT: "root",
};

export enum TradeTileStatuses {
  ACTION = "action",
  COMPLETE = "complete",
  NOTIFICATION = "notification",
  PENDING = "pending",
}

interface TradeTileProps {
  actionItems?: string[];
  deadlineDate: string;
  deliveryDate: string;
  estimatedOrTotalSpend: number;
  fulfillmentStatus?: string[];
  hasInvoice?: boolean;
  hasWantPrice?: boolean;
  isSeller?: boolean;
  isWantCarcass?: boolean;
  onClick: () => void;
  pricePerUnit: string;
  reference?: string;
  statusType: TradeTileStatuses;
  statusLabel: string;
  subtitle: string;
  title: string;
  tradeId: string;
  currencyCode?: string;
}

export const TradeTile: React.FC<TradeTileProps> = ({
  actionItems,
  deadlineDate,
  deliveryDate,
  estimatedOrTotalSpend,
  fulfillmentStatus,
  hasInvoice,
  hasWantPrice,
  isSeller,
  isWantCarcass,
  onClick,
  pricePerUnit,
  reference,
  statusType,
  statusLabel,
  subtitle,
  title,
  tradeId,
  currencyCode,
}) => {
  const tooltipWeightType = isWantCarcass ? "Dead Weight" : "Animal Weight";
  const currencyCodeISO = getCurrencyCode(currencyCode);

  return (
    <div
      className={cn(styles.trade, styles[`trade--status__${statusType}`])}
      data-testid={TEST_IDS.ROOT}
      onClick={onClick}
    >
      <Flex
        className={styles.trade__title_row}
        container
        containerAlignItems="flex-end"
        containerJustifyContent="space-between"
        item
        itemGutter
        xs="fill"
      >
        <Text className={styles.trade__title}>
          <strong>{title}</strong>
        </Text>

        {actionItems && actionItems.length > 0 ? (
          <span className={cn(styles.explain, styles.explain_position_top)} data-testid={TEST_IDS.ACTION_ITEMS}>
            <Badge text={actionItems.length} />

            <span className={cn(styles.explain__tooltip, styles.explain__tooltip_position_top)}>
              <ul>
                {actionItems.map((actionItem) => (
                  <li
                    className={cn(styles.trade__detail, styles.trade__detail_small_text)}
                    data-testid={TEST_IDS.ACTION_ITEM}
                    key={actionItem}
                  >
                    {actionItem}
                  </li>
                ))}
              </ul>
            </span>
          </span>
        ) : null}
      </Flex>

      <Flex container containerAlignItems="flex-end" containerJustifyContent="space-between">
        <Flex item itemGutter>
          <Text className={styles.trade__title}>
            <strong>{subtitle}</strong>
          </Text>
        </Flex>

        <Flex item itemGutter>
          <Text className={styles.trade__id} isSingleLine smallest>
            ID: {tradeId}
          </Text>
        </Flex>
      </Flex>

      <Spacer baselineHeight={1} border="bottom" gutter />

      <Spacer baselineHeight={0.75} allowDecimal />

      <Flex item itemGutter xs="fill">
        <Text>Reference: {reference ? reference : "\u2014"}</Text>
      </Flex>

      <Flex item itemGutter xs="fill">
        <Text>Delivery: {deliveryDate}</Text>
      </Flex>

      <Flex item itemGutter xs="fill">
        <Text>Offer deadline: {deadlineDate}</Text>
      </Flex>

      <Flex item itemGutter xs="fill">
        <Text>
          {hasInvoice ? "Final price:" : "Price offered:"} {hasWantPrice ? pricePerUnit : "TBD"}
        </Text>
      </Flex>

      <Flex container containerJustifyContent="space-between" item itemGutter xs="fill">
        <Text>
          {hasInvoice ? "Final total" : `Estimated ${isSeller ? "revenue" : "spend"}`}:{" "}
          {hasWantPrice ? getFormattedPrice(estimatedOrTotalSpend, currencyCodeISO) : "TBD"}
        </Text>

        <span className={styles.explain}>
          <span className={styles.explain__icon}>?</span>

          <span className={styles.explain__tooltip}>
            This is an estimate only, based on <strong>{tooltipWeightType} Price</strong> {pricePerUnit}.
          </span>
        </span>
      </Flex>

      {fulfillmentStatus && fulfillmentStatus.length > 0 ? (
        <>
          <Flex item itemGutter>
            <Text>Fulfilment status (no. of animals):</Text>
          </Flex>

          <Flex
            container
            containerJustifyContent="space-between"
            containerWrap="nowrap"
            data-testid={TEST_IDS.FULFILLMENT_STATUSES}
            item
            itemGutter
            xs="fill"
          >
            {fulfillmentStatus.map((status) => (
              <Text key={`fulfillment_status__${status}`}>{status}</Text>
            ))}
          </Flex>
        </>
      ) : null}

      <Spacer baselineHeight={0.875} allowDecimal />

      <p className={styles.trade__status}>{statusLabel}</p>
    </div>
  );
};
