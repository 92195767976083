import { Breadcrumbs, Header, PageContent, PageHeader } from "components";
import { SUPPLY_CHAINS } from "constants/Routes";
import { useGetCurrentBusinessUnit } from "hooks";

export const SupplyChainForm = () => {
  const { countryIsoCode } = useGetCurrentBusinessUnit();
  const formLink =
    countryIsoCode === "gb"
      ? "https://share.hsforms.com/1DOx4C8a6SkG1mGzdUURTYQ4snpj"
      : "https://share.hsforms.com/1mhQ82q-XSEKl79PdtZOpGw4snpj";
  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Supply chains",
              route: SUPPLY_CHAINS,
            },
          ]}
          currentPath={"Learn more"}
        />

        <Header backButton title={"Get in touch"} />
      </PageHeader>
      <PageContent>
        <div className="h-[78vh]">
          <span className="w-full h-full">
            <iframe className="w-full h-full" src={formLink} />
          </span>
        </div>
      </PageContent>
    </>
  );
};
