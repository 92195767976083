import { gql } from "@apollo/client";

import { errorFragment } from "api/User/fragment";

export const SIGN_UP = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      success
      clientMutationId
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
