import React, { FC } from "react";
import MaskedInput from "react-text-mask";
import { useHoldingInfo } from "helpers/translations/src";
// Components
import { Field } from "components";
import { BaseInput } from "components/Common/Field/BaseInput";

interface Props {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: { [field: string]: any };
  errors: Record<string, unknown>;
  locationIdError: string;
}

export const MaskedHoldingIdInput: FC<Props> = ({ handleChange, values, errors, locationIdError }) => {
  const { HoldingIDNumberMaskWeb, HoldingIDName } = useHoldingInfo(values.countryCode);
  return (
    <MaskedInput
      label={HoldingIDName}
      mask={HoldingIDNumberMaskWeb}
      name="locationId"
      value={values.locationId}
      onChange={handleChange}
      render={(ref, props): JSX.Element => (
        <Field error={errors.locationId || locationIdError} labelFor="locationId" {...props}>
          <BaseInput hasError={!!errors.locationId} ref={ref} {...props} />
        </Field>
      )}
    />
  );
};
