import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
// Helpers
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";
import { computeTitlePart, computeTextPart } from "helpers/regulatory";
// API
import { RETRY_REGULATORY_TRANSACTIONS } from "api/Regulatory/mutations";
// Types
import {
  RegulatoryTransactionApprovalsRetry,
  RegulatoryTransactionApprovalsRetryVariables,
} from "api/Regulatory/types/RegulatoryTransactionApprovalsRetry";
// Components
import { Button, Modal } from "components";
import { TransactionType } from "components/Regulatory/Regulatory";

interface Props {
  selectedTransactions: string[];
  transactionType: TransactionType;
  onSuccessSubmit: () => void;
}

export const ResendProblem: React.FC<Props> = ({ selectedTransactions, transactionType, onSuccessSubmit }) => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  const { showNotification } = useContext(CommonContext);

  const [retryTransactions, { loading }] = useMutation<
    RegulatoryTransactionApprovalsRetry,
    RegulatoryTransactionApprovalsRetryVariables
  >(RETRY_REGULATORY_TRANSACTIONS, {
    variables: {
      input: {
        businessUnit: getBUFromLocalStorage(),
        transactionType,
        regulatoryTransactionApprovals: selectedTransactions,
      },
    },
  });

  const transactionsCount: number = selectedTransactions.length;
  const isButtonsDisabled = !transactionsCount;
  const title = `Resend ${computeTitlePart(transactionType, transactionsCount)}?`;
  const text = `Confirm you want to resend ${computeTextPart(transactionType, transactionsCount)}.`;

  const handleResendClick = (): void => {
    setModalVisibility(true);
  };

  const handleModalClose = (): void => {
    setModalVisibility(false);
  };

  const handleConfirmClick = async (): Promise<void> => {
    const successMovementWord: string = transactionsCount > 1 ? "Movements" : "Movement";
    const errorMovementWord: string = transactionsCount > 1 ? "movements" : "movement";

    const successMessage = `${successMovementWord} successfully added to queue`;
    const errorMessage = `Error queuing ${errorMovementWord}`;

    try {
      const { data } = await retryTransactions();
      const errors = data?.regulatoryTransactionApprovalsRetry?.errors;

      if (errors) {
        showNotification({
          variant: "error",
          message: errorMessage,
        });
      } else {
        showNotification({
          message: successMessage,
        });
        setModalVisibility(false);
        onSuccessSubmit();
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: errorMessage,
      });
    }
  };

  return (
    <>
      <Button caption="Resend" disabled={isButtonsDisabled} onClick={handleResendClick} />
      <Modal
        showCloseButton
        active={isModalVisible}
        handleClose={handleModalClose}
        title={title}
        subTitle={text}
        actions={{
          secondary: {
            onClick: handleModalClose,
            caption: "Cancel",
          },
          primary: {
            onClick: handleConfirmClick,
            caption: "Resend",
            disabled: loading,
            requesting: loading,
          },
        }}
      />
    </>
  );
};
