import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesSupplierBuyers: FC = () => {
  return (
    <EmptyStates animation={"users"} title="No buyers found.">
      <p>You currently don’t have any buyers.</p>
    </EmptyStates>
  );
};
