import { FC } from "react";
import { EmptyStates } from "components";
import image from "assets/img/Performance/400width-Performance@large.png";

export const EmptyStatesPerformanceTab: FC = () => {
  return (
    <EmptyStates
      title="No weight data found."
      image={image}
      imageAriaLabel="Chart showing improved weight gain over time for an animal."
    >
      <p>Log a &quot;Live Weight&quot; activity record against this animal to start tracking its growth performance.</p>
    </EmptyStates>
  );
};
