import { capitaliseFirstLetter, useTranslation } from "../";
import { useMemo } from "react";

export const useLocale = () => {
  const { t } = useTranslation();

  const localeText = (term: string, fallback: string, capitalise = false): string => {
    const translatedTerm = t(term, fallback);
    if (capitalise) {
      const capitaliseValue = capitaliseFirstLetter(translatedTerm);
      return capitaliseValue == null ? translatedTerm : capitaliseValue;
    }
    return translatedTerm;
  };

  const terms = useMemo(() => {
    // Actions Section
    const addAnimal = t("actions.addAnimal", "add animal");
    const addPreviousKeeper = t("actions.addPreviousKeeper", "add previous keeper");
    const administerMedicine = t("actions.administerMedicine", "administer medicine");
    const cancel = t("actions.cancel", "cancel");
    const create = t("actions.create", "create");
    const createAlert = t("actions.createAlert", "create alert");
    const createCattle = t("actions.createCattle", "create cattle");
    const createListing = t("actions.createListing", "create listing");
    const logActivity = t("actions.logActivity", "log activity");
    const logBirth = t("actions.logBirth", "log birth");
    const logWeight = t("actions.logWeight", "log weight");
    const manage = t("actions.manage", "manage");
    const moveAnimalToGroup = t("actions.moveAnimalToGroup", "move animal to group");
    const moveToPasture = t("actions.moveToPasture", "move to pasture");
    const quickActions = t("actions.quickActions", "quick actions");
    const quickScan = t("actions.quickScan", "quick scan");
    const quickWeigh = t("actions.quickWeigh", "quick weigh");
    const removeGroupMembership = t("actions.removeGroupMembership", "remove group membership");
    const saveAndContinue = t("actions.saveAndContinue", "save and continue");
    const saveAndCreateAnother = t("actions.saveAndCreateAnother", "save and create another");
    const saveAndFinish = t("actions.saveAndFinish", "save and finish");
    const scan = t("actions.scan", "scan");
    const scanPassport = t("actions.scanPassport", "scan passport");
    const setUpSession = t("actions.setUpSession", "setup session");
    const weigh = t("actions.weigh", "weigh");

    // Animal Section
    const animalCondition = t("animal.animalCondition", "animal condition");
    const avgDlwg = t("animal.avgDlwg", "ADG");
    const dlwg = t("animal.dlwg", "ADG");
    const dlwgFull = t("animal.dlwgFull", "average daily gain");
    const birthWeight = t("animal.birthWeight", "birth weight");
    const breed = t("animal.breed", "breed");
    const dam = t("animal.dam", "dam");
    const dof = t("animal.dof", "DOR");
    const eid = t("animal.eid", "EID");
    const dob = t("animal.dob", "DOB");
    const estimatedDob = t("animal.estimatedDob", "estimated DOB");
    const female = t("animal.female", "female");
    const male = t("animal.male", "male");
    const offspringVigor = t("animal.offspringVigor", "offspring vigor");
    const passport = t("animal.passport", "passport");
    const passportID = t("animal.passportID", "animal ID");
    const passportNumber = t("animal.passportNumber", "animal number");
    const pedigreeId = t("animal.pedigreeId", "registered ID");
    const sex = t("animal.sex", "sex");
    const sire = t("animal.sire", "sire");
    const vid = t("animal.vid", "VID");

    // Business Unit Section
    const farmId = t("businessUnit.farmId", "Location ID");
    const postcode = t("businessUnit.postcode", "postcode");

    // Crush Section
    const crush = t("crush.crush", "chute");
    const draft = t("crush.draft", "sort");
    const manageCrush = t("crush.manageCrush", "manage chute");
    const manageCrushSession = t("crush.manageCrushSession", "manage chute session");

    // General Section
    const surname = t("general.surname", "surname");
    const firstName = t("general.firstName", "first name");
    const abort = t("general.abort", "abort");
    const activityDate = t("general.activityDate", "activity date");
    const castrated = t("general.castrated", "castrated");
    const castration = t("general.castration", "castration");
    const colour = t("general.colour", "color");
    const colours = t("general.colours", "colors");
    const deadOnArrival = t("general.deadOnArrival", "dead on arrival");
    const farm = t("general.farm", "ranch");
    const field = t("general.field", "pasture");
    const fields = t("general.fields", "pasture");
    const followingFieldsAreAdditional = t(
      "general.followingFieldsAreAdditional",
      "the following fields are additional",
    );
    const group = t("general.group", "group");
    const groups = t("general.groups", "groups");
    const help = t("general.help", "help");
    const herd = t("general.herd", "herd");
    const herds = t("general.herds", "herds");
    const location = t("general.location", "location");
    const locations = t("general.locations", "locations");
    const note = t("general.note", "note");
    const notes = t("general.notes", "notes");
    const pasture = t("general.pasture", "pasture");
    const pen = t("general.pen", "pen");
    const performance = t("general.performance", "performance");
    const previousKeeper = t("general.previousKeeper", "previous keeper");
    const purchasePrice = t("general.purchasePrice", "purchase price");
    const route = t("general.route", "route");
    const stillBirth = t("general.stillBirth", "still birth");
    const supplyChain = t("general.supplyChain", "supply chain");
    const tagNotRecognised = t("general.tagNotRecognised", "tag not recognised");

    // Homepage Section
    const updatesAndAlerts = t("homepage.updatesAndAlerts", "updates and alerts");

    // Navigation Section
    const activeSessions = t("navigation.activeSessions", "active sessions");
    const addressBook = t("navigation.addressBook", "address book");
    const bankDetails = t("navigation.bankDetails", "bank details");
    const connectedSystems = t("navigation.connectedSystems", "connected systems");
    const finishedSessions = t("navigation.finishedSessions", "finished sessions");
    const helpAndSupport = t("navigation.helpAndSupport", "help and support");
    const home = t("navigation.home", "home");
    const logOut = t("navigation.logOut", "log out");
    const maps = t("navigation.maps", "maps");
    const medicineCabinet = t("navigation.medicineCabinet", "medicine cabinet");
    const menu = t("navigation.menu", "menu");
    const movement = t("navigation.movement", "movement");
    const movements = t("navigation.movements", "movements");
    const myCattle = t("navigation.myCattle", "my cattle");
    const notificationSettings = t("navigation.notificationSettings", "notification settings");
    const performanceTab = t("navigation.performanceTab", "performance");
    const seeTemplates = t("navigation.seeTemplates", "see templates");
    const sessions = t("navigation.sessions", "sessions");
    const settings = t("navigation.settings", "settings");
    const sireInformation = t("navigation.sireInformation", "sire information");
    const trades = t("navigation.trades", "trades");
    const userManagement = t("navigation.userManagement", "user management");
    const vetAndMed = t("navigation.vetAndMed", "vet and med");

    return {
      addAnimal,
      addPreviousKeeper,
      administerMedicine,
      cancel,
      create,
      createAlert,
      createCattle,
      createListing,
      logActivity,
      logBirth,
      logWeight,
      manage,
      moveAnimalToGroup,
      moveToPasture,
      quickActions,
      quickScan,
      quickWeigh,
      removeGroupMembership,
      saveAndContinue,
      saveAndCreateAnother,
      saveAndFinish,
      scan,
      scanPassport,
      setUpSession,
      weigh,
      animalCondition,
      avgDlwg,
      dlwg,
      dlwgFull,
      birthWeight,
      breed,
      dam,
      dof,
      eid,
      dob,
      estimatedDob,
      female,
      male,
      offspringVigor,
      passport,
      passportID,
      passportNumber,
      pedigreeId,
      sex,
      sire,
      vid,
      farmId,
      postcode,
      crush,
      draft,
      manageCrush,
      manageCrushSession,
      abort,
      activityDate,
      castrated,
      castration,
      colour,
      colours,
      deadOnArrival,
      farm,
      field,
      fields,
      followingFieldsAreAdditional,
      group,
      groups,
      help,
      herd,
      herds,
      location,
      locations,
      note,
      notes,
      pasture,
      pen,
      performance,
      previousKeeper,
      purchasePrice,
      route,
      stillBirth,
      supplyChain,
      tagNotRecognised,
      updatesAndAlerts,
      activeSessions,
      addressBook,
      bankDetails,
      connectedSystems,
      finishedSessions,
      helpAndSupport,
      home,
      logOut,
      maps,
      medicineCabinet,
      menu,
      movement,
      movements,
      myCattle,
      notificationSettings,
      performanceTab,
      seeTemplates,
      sessions,
      settings,
      sireInformation,
      trades,
      userManagement,
      vetAndMed,
      surname,
      firstName,
    };
  }, [t]);

  return { terms, localeText };
};
