import { AnimalFragment, IdentifierType, useCheckIfAnimalExistsLazyQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { capitaliseFirstLetter, useTranslation } from "helpers/translations/src";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { useStateSpecies } from "hooks/useStateSpecies";
import * as yup from "yup";
import { CATTLE_ID } from ".";
import { sanitiseAnimalId } from "./helpers";

const alphanumericWithSpacesRegex = /^[A-Za-z0-9\s]+$/;

export type AnimalSchema = yup.InferType<ReturnType<typeof useCreateAnimalSchema>>;

export function useCreateAnimalSchema(animalToEdit?: AnimalFragment) {
  const { activeSpecies } = useStateSpecies();
  const { t } = useTranslation();
  const { countryIsoCode, id: businessUnitId } = useGetCurrentBusinessUnit();
  const [executeCheckAnimalQuery] = useCheckIfAnimalExistsLazyQuery();

  const isCattleActive =
    animalToEdit?.animalType?.id === CATTLE_ID || (!animalToEdit && activeSpecies?.id === CATTLE_ID);
  const buCountryIsoCode = countryIsoCode.toUpperCase();

  const isPassportIdRequired = isCattleActive && buCountryIsoCode === CountryISOCode.GB;

  const translationEid = t("addAnimal.eid", "EID");
  const translationBreed = capitaliseFirstLetter(t("addAnimal.breed", "Breed"));

  const EID = isCattleActive ? `${translationEid}` : "Animal ID";
  const EID_NOT_MATCH_MESSAGE = `${EID} can only include alphanumeric characters and spaces.`;
  const EID_EXISTS_MESSAGE = `${EID} already exists. Please enter a unique ${EID}.`;
  const PASSPORT_ID_EXISTS = "Passport ID already exists. Please enter a unique value";

  // NOTE: Validate that IDs are unique.
  // On the FE there is currently only validation for whether EID is unique.
  // There seem to currently be a bug in either React Hook Form or Yup.
  // When calling methods.trigger("brucellosisId") with React hook form
  // it should only trigger the yup test for brucellosisId. However, it seems
  // to trigger every single test in the yup schema. This means that the
  // uniqueness validation potentially fires an async request for each
  // ID. I've currently left the validation for EID in place.
  // The remaining IDs are validated on the BE.
  //
  // Mobile app has similar validation to the WEB, but the validation for
  // the extended animal IDs doesn't work there either.

  // I've raised an issue with React Hook Form here:
  // https://github.com/orgs/react-hook-form/discussions/11205

  const baseSchema = yup.object({
    breeds: yup.string().required(`${translationBreed} is a required field`),
    dateMovedToFarm: yup
      .date()
      .required("Date moved to farm is required.")
      .max(new Date(), "Date cannot be in the future")
      .when("dob", (dob, schema) => dob && schema.min(dob, "Cannot be earlier than DOB")),
    dob: yup
      .date()
      .required("Date of birth is required.")
      .label("Date Of Birth")
      .max(new Date(), "Date cannot be in the future"),
    sourceContactId: yup.string().required("Source address is a required field."),
    groupId: yup.string(),
    fieldId: yup.string().required("Location is required."),
    animalSex: yup.string().oneOf(["male", "female"]).required("Sex is a required field."),
    sireId: yup.string(),
    damId: yup.string(),
    isCastrated: yup.boolean(),
    birthWeight: yup.string(),
    purchasePrice: yup.string(),
    animalConditions: yup.array().of(yup.string().required()),
    tagIds: yup.array().of(yup.string().required()),
    isDead: yup.boolean(),
  });

  const extraAnimalIdSchema = yup.object({
    brucellosisId: yup.string().max(40, "The ID must be 40 characters or less."),
    pedigreeId: yup.string().max(40, "The ID must be 40 characters or less."),
    tattoo: yup.string().max(40, "The ID must be 40 characters or less."),
    trichId: yup.string().max(40, "The ID must be 40 characters or less."),
    tsuBarcode: yup.string().max(40, "The ID must be 40 characters or less."),
    herdDoggId: yup.string(),
    uhfId: yup.string().max(40, "The ID must be 40 characters or less."),
    alternativeId: yup.string().max(40, "The ID must be 40 characters or less."),
    name: yup.string().max(40, "The ID must be 40 characters or less."),
  });

  const cattleSchema = yup.object({
    ...(isPassportIdRequired
      ? {
          passportNumber: yup
            .string()
            .test("already-exists", PASSPORT_ID_EXISTS, async (passportNumber) => {
              const sanitisedPassportNumber = sanitiseAnimalId(passportNumber);

              if (animalToEdit && sanitisedPassportNumber === animalToEdit.passportNumber) {
                return true;
              }

              return (
                (
                  await executeCheckAnimalQuery({
                    variables: {
                      businessUnit: +businessUnitId,
                      passportNumber: sanitisedPassportNumber,
                      first: 1,
                    },
                    fetchPolicy: "network-only",
                  })
                ).data?.animals?.edges.length === 0
              );
            })
            .required()
            .label("Passport ID"),
        }
      : { passportNumber: yup.string() }),
    ...(buCountryIsoCode === CountryISOCode.US
      ? {
          eId: yup
            .string()
            .test("incorrect-format", EID_NOT_MATCH_MESSAGE, (val: string | undefined) => {
              if (val == null || val === "") {
                return true;
              } else if (val.match(alphanumericWithSpacesRegex) == null) {
                return false;
              }

              return true;
            })
            .test("at-least-one-id", "Please enter either EID or Visual ID", function (value) {
              return !!value || !!this.parent.visualId;
            })
            .test("already-exists", "An animal with ID already exists. Please enter a unique value.", async (eId) => {
              if (!eId) {
                return true;
              }

              if (animalToEdit && sanitiseAnimalId(eId) === animalToEdit.eId) {
                return true;
              }

              const result = await executeCheckAnimalQuery({
                variables: {
                  businessUnit: +businessUnitId,
                  search: sanitiseAnimalId(eId),
                  searchIds: [IdentifierType.EId],
                },
                fetchPolicy: "network-only",
              });

              const existingEids = removeNothings(
                result.data?.animals?.edges?.map((edge) => sanitiseAnimalId(edge?.node?.eId || "")) || [],
              );

              return existingEids.indexOf(sanitiseAnimalId(eId) || "") === -1;
            }),
          visualId: yup.string().test("at-least-one-id", "Please enter either EID or Visual ID", function (value) {
            return !!value || !!this.parent.eId;
          }),
        }
      : { eId: yup.string(), visualId: yup.string() }),
  });

  const nonCattleSchema = yup.object({
    eId: yup.string().when(["passportNumber"], (passportNumber: string, schema: yup.StringSchema) =>
      schema.required("Animal ID is required.").test("already-exists", EID_EXISTS_MESSAGE, async () => {
        if (!passportNumber) {
          return true;
        }

        const sanitisedPassportNumber = sanitiseAnimalId(passportNumber);

        if (animalToEdit && sanitisedPassportNumber === animalToEdit.passportNumber) {
          return true;
        }

        return (
          (
            await executeCheckAnimalQuery({
              variables: {
                businessUnit: +businessUnitId,
                passportNumber: sanitisedPassportNumber,
                first: 1,
              },
              fetchPolicy: "network-only",
            })
          ).data?.animals?.edges.length === 0
        );
      }),
    ),
    passportNumber: yup.string(),
    visualId: yup.string(),
  });

  if (isCattleActive) {
    return baseSchema.concat(cattleSchema).concat(extraAnimalIdSchema);
  } else {
    return baseSchema.concat(nonCattleSchema).concat(extraAnimalIdSchema);
  }
}
