import { AnimalFragment } from "generated/graphql";
import { DateTime } from "luxon";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { useMemo } from "react";
import { getFormattedNumber } from "helpers/general/all";
import { createColumnHelper } from "@tanstack/react-table";
import { capitaliseFirstLetter, capitaliseFirstLetterOfEachWord, useLocale } from "helpers/translations/src";
import { useFormatDate } from "hooks/useFormatDate";
import { getDaysOnFarm, getMonthsAge } from "helpers/myLivestock";
import { useHasFeature } from "hooks/useHasFeature";
import { useGetAnimalIdPreferences } from "hooks/useGetAnimalIdPreferences";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { getTableSettingsFromLocalStorage } from "helpers/storage";
import { TABLE_IDS } from "constants/Interface";
import { AnimalsSexClassLabel } from "components/MyLivestock/Animals/AnimalsSexClassLabel";
import { Pill } from "components";
import { AnimalsPerformancePill, AnimalPregnancyLabel } from "components/MyLivestock";
import { AnimalPedigreeLabel } from "components/MyLivestock/Animals/AnimalPedigreeLabel";
import { AnimalsBreedPercentages } from "components/MyLivestock/Animals/AnimalsBreedPercentages";
import { renderPedigreeStatus, renderStatus } from "components/MyLivestock/Animals/AnimalsTable";
import { Tag } from "tags/components/Tag";
import { TableHeaderCaptions } from "twComponents/TableHeaderCaptions";

export const useAnimalsColumns = ({ isArchive }: { isArchive?: boolean }) => {
  const { accessor } = createColumnHelper<AnimalFragment>();
  const { getFormatDate } = useFormatDate();
  const { terms } = useLocale();
  const { weightUnits, countryIsoCodeUpperCase, countryIsoCode } = useGetCurrentBusinessUnit();
  const useEpds = useHasFeature("GENETICS");
  const { activeIdPreferences } = useGetAnimalIdPreferences();

  const currentLocalStorageSettings = getTableSettingsFromLocalStorage(TABLE_IDS.ANIMAL_LIST_SERVER) || [];
  const currentLocalStorageColumns = currentLocalStorageSettings?.columns || [];

  const isColumnEnabledInLocalStorage = (columnId: string) => {
    if (!currentLocalStorageColumns || currentLocalStorageColumns.length === 0) {
      return true;
    }
    return currentLocalStorageColumns.filter((column) => column.id === columnId && column.checked).length === 1;
  };

  // NOTE: I think ideally the visibility rules should
  // be stored as an object like this in locale storage.
  // As it currenlty stands, this object might need to be memoized
  // because the locale storage columns have been stored as an array
  // instead of an object.
  const visibilityRules: Record<string, boolean> = {
    [TABLE_COLUMN_IDS.PASSPORT_NUMBER]:
      !!activeIdPreferences?.passportNumber && countryIsoCodeUpperCase !== CountryISOCode.US,
    [TABLE_COLUMN_IDS.EID]: !!activeIdPreferences?.eId && isColumnEnabledInLocalStorage("eId"),
    [TABLE_COLUMN_IDS.VISUAL_ID]: !!activeIdPreferences?.visualId && isColumnEnabledInLocalStorage("visualId"),
    [TABLE_COLUMN_IDS.BRUCELLOSIS_ID]:
      !!activeIdPreferences?.brucellosisId &&
      countryIsoCodeUpperCase === CountryISOCode.US &&
      isColumnEnabledInLocalStorage("brucellosisId"),
    [TABLE_COLUMN_IDS.HERD_DOGG_ID]: !!activeIdPreferences?.herdDoggId && countryIsoCodeUpperCase === CountryISOCode.US,
    [TABLE_COLUMN_IDS.PEDIGREE_ID]: !!activeIdPreferences?.pedigreeId,
    [TABLE_COLUMN_IDS.TATTOO]: !!activeIdPreferences?.tattoo && countryIsoCodeUpperCase === CountryISOCode.US,
    [TABLE_COLUMN_IDS.TRICH_ID]: !!activeIdPreferences?.trichId && countryIsoCodeUpperCase === CountryISOCode.US,
    [TABLE_COLUMN_IDS.TSU_BARCODE]: !!activeIdPreferences?.tsuBarcode && countryIsoCodeUpperCase === CountryISOCode.US,
    [TABLE_COLUMN_IDS.NAME]: !!activeIdPreferences?.name && countryIsoCodeUpperCase === CountryISOCode.US,
    [TABLE_COLUMN_IDS.UHF_ID]: !!activeIdPreferences?.uhfId && countryIsoCodeUpperCase === CountryISOCode.US,
    [TABLE_COLUMN_IDS.BREEDR_ID]: !!activeIdPreferences?.breedrId && countryIsoCodeUpperCase === CountryISOCode.US,
    [TABLE_COLUMN_IDS.ALTERNATIVE_ID]:
      !!activeIdPreferences?.alternativeId && countryIsoCodeUpperCase === CountryISOCode.US,
    [TABLE_COLUMN_IDS.DOB]: isColumnEnabledInLocalStorage("dob"),
    [TABLE_COLUMN_IDS.AGE]: isColumnEnabledInLocalStorage("age"),
    [TABLE_COLUMN_IDS.SEX_CLASSIFICATION]: isColumnEnabledInLocalStorage("sexClassification"),
    [TABLE_COLUMN_IDS.IS_MALE]: isColumnEnabledInLocalStorage("isMale"),
    [TABLE_COLUMN_IDS.ANIMAL_BREEDS]: isColumnEnabledInLocalStorage("animalBreeds"),
    [TABLE_COLUMN_IDS.TAGS]: isColumnEnabledInLocalStorage("tags"),
    [TABLE_COLUMN_IDS.GROUP]: isColumnEnabledInLocalStorage("group"),
    [TABLE_COLUMN_IDS.FIELD]: isColumnEnabledInLocalStorage("field") && !isArchive,
    [TABLE_COLUMN_IDS.PREVIOUS_KEEPER]: isColumnEnabledInLocalStorage("previousKeeper"),
    [TABLE_COLUMN_IDS.UPDATED_AT]: isColumnEnabledInLocalStorage("updatedAt"),
    [TABLE_COLUMN_IDS.DATE_ON_FARM]: isColumnEnabledInLocalStorage("dateOnFarm"),
    [TABLE_COLUMN_IDS.DAYS_ON_FARM]: isColumnEnabledInLocalStorage("daysOnFarm"),
    [TABLE_COLUMN_IDS.CURRENT_WEIGHT]: isColumnEnabledInLocalStorage("currentWeight") && !isArchive,
    [TABLE_COLUMN_IDS.LAST_WEIGHT]: isColumnEnabledInLocalStorage("lastWeight"),
    [TABLE_COLUMN_IDS.DAYS_SINCE_LAST_WEIGHT]: isColumnEnabledInLocalStorage("daysSinceLastWeight") && !isArchive,
    [TABLE_COLUMN_IDS.GROWTH_RATE]: isColumnEnabledInLocalStorage("growthRate"),
    [TABLE_COLUMN_IDS.SIRE]: isColumnEnabledInLocalStorage("sire"),
    [TABLE_COLUMN_IDS.DAM]: isColumnEnabledInLocalStorage("dam"),
    [TABLE_COLUMN_IDS.IS_PREGNANT]: isColumnEnabledInLocalStorage("isPregnant") && !isArchive,
    [TABLE_COLUMN_IDS.PREGNANCY_DUE_DATE]: isColumnEnabledInLocalStorage("pregnancyDueDate") && !isArchive,
    [TABLE_COLUMN_IDS.LAST_REGULATORY_SYNCED_DATE]:
      countryIsoCodeUpperCase === CountryISOCode.GB && isColumnEnabledInLocalStorage("lastRegulatorySyncedDate"),
    isBcmsSynced:
      ((countryIsoCodeUpperCase !== CountryISOCode.GB && !isArchive) ||
        countryIsoCodeUpperCase === CountryISOCode.GB) &&
      isColumnEnabledInLocalStorage("isBcmsSynced"),
    [TABLE_COLUMN_IDS.DEAD_AT]: isArchive || false,
    [TABLE_COLUMN_IDS.DATE_LEFT_FARM]: isArchive || false,
    [TABLE_COLUMN_IDS.ANIMAL_BREED_CODES]: isColumnEnabledInLocalStorage("animalBreeds"),
  };

  const columns = useMemo(
    () => [
      accessor(TABLE_COLUMN_IDS.PASSPORT_NUMBER, {
        header: capitaliseFirstLetterOfEachWord(terms.passportNumber),
        size: 170,
      }),
      accessor("eId", {
        id: TABLE_COLUMN_IDS.EID,
        header: "eid",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("visualId", {
        id: TABLE_COLUMN_IDS.VISUAL_ID,
        header: "vid",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("breedrId", {
        id: TABLE_COLUMN_IDS.BREEDR_ID,
        header: "breedr id",
        cell: (info) => info.getValue() || "—",
        size: 230,
      }),
      accessor("name", {
        id: TABLE_COLUMN_IDS.NAME,
        header: "name",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("brucellosisId", {
        id: TABLE_COLUMN_IDS.BRUCELLOSIS_ID,
        header: "brucellosis id",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("trichId", {
        id: TABLE_COLUMN_IDS.TRICH_ID,
        header: "trich id",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("tsuBarcode", {
        id: TABLE_COLUMN_IDS.TSU_BARCODE,
        header: "tsu barcode",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("herdDoggId", {
        id: TABLE_COLUMN_IDS.HERD_DOGG_ID,
        header: "herddogg id",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("pedigreeId", {
        id: TABLE_COLUMN_IDS.PEDIGREE_ID,
        header: capitaliseFirstLetterOfEachWord(terms.pedigreeId),
        cell: (info) => info.getValue(),
      }),
      accessor("tattoo", {
        id: TABLE_COLUMN_IDS.TATTOO,
        header: "tattoo id",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("uhfId", {
        id: TABLE_COLUMN_IDS.UHF_ID,
        header: "uhf id",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("alternativeId", {
        id: TABLE_COLUMN_IDS.ALTERNATIVE_ID,
        header: "alternative id",
        cell: (info) => info.getValue() || "—",
      }),
      accessor("dob", {
        id: TABLE_COLUMN_IDS.DOB,
        header: "dob",
        cell: ({ row }) => <span>{row.original?.dob ? getFormatDate(row.original.dob) : "—"}</span>,
      }),
      accessor((row) => row, {
        id: TABLE_COLUMN_IDS.AGE,
        header: () => <TableHeaderCaptions title="Age" subtitle="Months" />,
        cell: ({ row }) => {
          const { deadAt, dateLeftFarm, dob } = row.original;

          // if dead, calculate age at death
          if (deadAt) return getMonthsAge(dob, deadAt);

          // if off farm, calculate age at move off farm
          if (dateLeftFarm) return getMonthsAge(dob, dateLeftFarm);

          // otherwise just calculate current age
          return getMonthsAge(dob);
        },
      }),
      accessor("sexClassification", {
        id: TABLE_COLUMN_IDS.SEX_CLASSIFICATION,
        header: () => <TableHeaderCaptions title="Sex" subtitle="Classification" />,
        cell: ({ row: { original } }) => {
          return (
            <AnimalsSexClassLabel
              id={`animal_sex_label__${original.id}`}
              isCastrated={original.isCastrated}
              isMale={original.isMale}
              label={original.sexClassification?.title || null}
            />
          );
        },
      }),
      accessor("isMale", {
        id: TABLE_COLUMN_IDS.IS_MALE,
        header: "sex",
        cell: ({ row: { original } }) => {
          return (
            <AnimalsSexClassLabel
              id={`animal_sex_label__${original.id}`}
              isCastrated={original.isCastrated}
              isMale={original.isMale}
              label={original.isMale ? "M" : "F"}
            />
          );
        },
      }),
      accessor("breedPercentages", {
        id: TABLE_COLUMN_IDS.ANIMAL_BREEDS,
        header: "breed",
        size: 200,
        cell: ({ row }) => {
          const { breedPercentages } = row.original;
          return <AnimalsBreedPercentages breedPercentages={breedPercentages} orientation="col" showFullName />;
        },
      }),
      accessor("breedPercentages", {
        id: TABLE_COLUMN_IDS.ANIMAL_BREED_CODES,
        header: "breed code",
        cell: ({ row }) => {
          const { breedPercentages } = row.original;
          return <AnimalsBreedPercentages breedPercentages={breedPercentages} orientation="row" showFullName={false} />;
        },
      }),
      accessor("tags", {
        id: TABLE_COLUMN_IDS.TAGS,
        header: "tags",
        cell: ({ row }) => {
          const { tags } = row?.original;

          return (
            <div className="flex">
              {tags.map((tag) => {
                const t = {
                  tagId: tag.tagId,
                  name: tag.name,
                  color: tag.color || "grey",
                  ownership: tag?.schema?.ownership,
                };
                return <Tag key={tag.tagId} tag={t} className="ml-1" />;
              })}
            </div>
          );
        },
      }),
      accessor("group", {
        id: TABLE_COLUMN_IDS.GROUP,
        header: "group",
        cell: ({
          row: {
            original: { group },
          },
        }) => group?.name || "—",
      }),
      accessor("field", {
        id: TABLE_COLUMN_IDS.FIELD,
        header: "location",
        cell: ({
          row: {
            original: { field },
          },
        }) => field?.name || "—",
      }),
      accessor("previousKeeper", {
        id: TABLE_COLUMN_IDS.PREVIOUS_KEEPER,
        header: "previous keeper",
        cell: ({ row: { original } }) => original.previousKeeper?.name || "—",
      }),
      accessor("updatedAt", {
        id: TABLE_COLUMN_IDS.UPDATED_AT,
        header: "last updated",
        cell: ({ row }) => <span>{row.original.updatedAt ? getFormatDate(row.original.updatedAt) : "—"}</span>,
      }),
      accessor("dateMovedToFarm", {
        id: TABLE_COLUMN_IDS.DATE_ON_FARM,
        header: `Date on ${capitaliseFirstLetter(terms.farm)}`,
        cell: ({ row }) => (
          <span>{row.original.dateMovedToFarm ? getFormatDate(row.original.dateMovedToFarm) : "—"}</span>
        ),
      }),
      accessor((row) => row, {
        id: TABLE_COLUMN_IDS.DAYS_ON_FARM,
        header: `Days on ${capitaliseFirstLetter(terms.farm)}`,
        cell: ({ row: { original } }) => (
          <span>
            {original.dateMovedToFarm
              ? getDaysOnFarm({
                  dateMovedToFarm: original.dateMovedToFarm,
                  dateLeftFarm: original?.dateLeftFarm,
                  deadAt: original?.deadAt,
                })
              : "—"}
          </span>
        ),
      }),
      accessor("dateLeftFarm", {
        id: TABLE_COLUMN_IDS.DATE_LEFT_FARM,
        header: `Date Left ${capitaliseFirstLetter(terms.farm)}`,
        cell: (info) => <span>{info.getValue() ? getFormatDate(info.getValue()) : "—"}</span>,
      }),
      accessor("deadAt", {
        id: TABLE_COLUMN_IDS.DEAD_AT,
        header: "dead at date",
        cell: (info) => <span>{info.getValue() ? getFormatDate(info.getValue()) : "—"}</span>,
      }),
      accessor("currentWeight", {
        id: TABLE_COLUMN_IDS.CURRENT_WEIGHT,
        header: () => <TableHeaderCaptions title="Current Weight" subtitle="Estimated" />,
        cell: ({ row }) => {
          return row.original.currentWeight ? (
            <AnimalsPerformancePill
              label={`${row.original.currentWeight} ${weightUnits}`}
              performanceCategory={row?.original?.performanceCategory || null}
            />
          ) : (
            "—"
          );
        },
      }),
      accessor("lastWeight", {
        id: TABLE_COLUMN_IDS.LAST_WEIGHT,
        header: "last weight",
        cell: ({
          row: {
            original: { lastWeight },
          },
        }) => {
          return lastWeight?.value ? (
            <Pill caption={`${getFormattedNumber(lastWeight.value)} ${weightUnits}`} colour="grey" />
          ) : (
            "—"
          );
        },
      }),
      accessor("lastWeight", {
        id: TABLE_COLUMN_IDS.DAYS_SINCE_LAST_WEIGHT,
        header: "days since weighed",
        cell: ({
          row: {
            original: { lastWeight },
          },
        }) => {
          return lastWeight?.date
            ? Math.trunc(DateTime.local().diff(DateTime.fromISO(lastWeight.date), ["days"]).days)
            : null;
        },
      }),
      accessor("growthRate", {
        id: TABLE_COLUMN_IDS.GROWTH_RATE,
        header: capitaliseFirstLetterOfEachWord(terms.avgDlwg),
        cell: (info) => {
          return info.getValue()?.toFixed(1) || "—";
        },
      }),
      accessor((row) => row, {
        id: TABLE_COLUMN_IDS.SIRE,
        header: "sire",
        cell: ({ row: { original } }) => {
          const sire = original?.linkToMySire;
          const isPedigree = sire?.pedigree;

          return sire ? (
            <AnimalPedigreeLabel
              id={`animal_sire_label__${original?.id}`}
              isPedigree={!!isPedigree}
              passportNumber={sire?.passportNumber}
            />
          ) : (
            original?.sirePassportNumber || "\u2014"
          );
        },
      }),
      accessor("damPassportNumber", {
        id: TABLE_COLUMN_IDS.DAM,
        header: "dam",
        cell: (info) => info.getValue(),
      }),
      accessor("isPregnant", {
        id: TABLE_COLUMN_IDS.IS_PREGNANT,
        header: () => <TableHeaderCaptions title="Pregnancy" subtitle="Status" />,
        cell: ({ row: { original } }) => {
          return !original?.isMale && original?.isPregnant ? (
            <AnimalPregnancyLabel
              dueDate={original?.pregnancyDueDate}
              id={`animal_pregnancy_label__${original?.id}`}
              isMale={original?.isMale}
              isPregnant={!!original?.isPregnant}
            />
          ) : (
            "—"
          );
        },
      }),
      accessor("pregnancyDueDate", {
        id: TABLE_COLUMN_IDS.PREGNANCY_DUE_DATE,
        header: () => <TableHeaderCaptions title="Pregnancy" subtitle="Due Date" />,
        cell: (info) => <span>{info.getValue() ? getFormatDate(info.getValue()) : "—"}</span>,
      }),
      accessor("lastRegulatorySyncedDate", {
        id: TABLE_COLUMN_IDS.LAST_REGULATORY_SYNCED_DATE,
        header: "last synced",
        cell: (info) => <span>{info.getValue() ? getFormatDate(info.getValue()) : "—"}</span>,
      }),
      accessor((row) => row, {
        id: TABLE_COLUMN_IDS.IS_BCMS_SYNCED,
        header: "status",
        cell: ({ row: { original } }) => {
          if (useEpds) {
            return (
              <div>
                {renderPedigreeStatus(
                  {
                    pedigreeId: original.pedigreeId,
                    withdrawalEnd: original.withdrawalEnd,
                    deadAt: original.deadAt,
                    dateLeftFarm: original.dateLeftFarm,
                  },
                  {
                    isArchive: false,
                    isCarcass: undefined,
                  },
                  getFormatDate,
                )}
              </div>
            );
          }

          return (
            <div className="flex">
              {renderStatus(
                {
                  isRegulatorySynced: original.isRegulatorySynced,
                  withdrawalEnd: original.withdrawalEnd,
                  deadAt: original.deadAt,
                  dateLeftFarm: original.dateLeftFarm,
                },
                {
                  isArchive: false,
                  isCarcass: undefined,
                  isGb: countryIsoCode === CountryISOCode.GB,
                },
                getFormatDate,
              )}
            </div>
          );
        },
      }),
    ],

    [
      accessor,
      countryIsoCode,
      getFormatDate,
      terms.avgDlwg,
      terms.farm,
      terms.passportNumber,
      terms.pedigreeId,
      useEpds,
      weightUnits,
    ],
  );

  return { columns, visibilityRules };
};

const TABLE_COLUMN_IDS = {
  AGE: "age",
  ALTERNATIVE_ID: "alternativeId",
  ANIMAL_BREEDS: "animalBreeds",
  ANIMAL_BREED_CODES: "animalBreedCodes",
  BREEDR_ID: "breedrId",
  BRUCELLOSIS_ID: "brucellosisId",
  CURRENT_WEIGHT: "currentWeight",
  DAM: "dam",
  DATE_LEFT_FARM: "dateLeftFarm",
  DATE_ON_FARM: "dateOnFarm",
  DAYS_ON_FARM: "daysOnFarm",
  DAYS_SINCE_LAST_WEIGHT: "daysSinceLastWeight",
  DEAD_AT: "deadAt",
  DOB: "dob",
  EID: "eId",
  FIELD: "field",
  GROUP: "group",
  GROWTH_RATE: "growthRate",
  HERD_DOGG_ID: "herdDoggId",
  IS_BCMS_SYNCED: "isBcmsSynced",
  IS_MALE: "isMale",
  IS_PREGNANT: "isPregnant",
  LAST_REGULATORY_SYNCED_DATE: "lastRegulatorySyncedDate",
  LAST_WEIGHT: "lastWeight",
  NAME: "name",
  PASSPORT_NUMBER: "passportNumber",
  PEDIGREE_ID: "pedigreeId",
  PREGNANCY_DUE_DATE: "pregnancyDueDate",
  PREVIOUS_KEEPER: "previousKeeper",
  SEX_CLASSIFICATION: "sexClassification",
  SIRE: "sire",
  TAGS: "tags",
  TATTOO: "tattoo",
  TRICH_ID: "trichId",
  TSU_BARCODE: "tsuBarcode",
  UHF_ID: "uhfId",
  UPDATED_AT: "updatedAt",
  VISUAL_ID: "visualId",
} as const;
