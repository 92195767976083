import { useQuery } from "@apollo/client";

import { GET_ORGANISATIONS_BUSINESS_UNITS } from "api/Organisations/queries";
import {
  GetOrganisationBusinessUnits,
  GetOrganisationBusinessUnitsVariables,
} from "api/Organisations/types/GetOrganisationBusinessUnits";

import { getBUFromLocalStorage } from "helpers/storage";

export const useGetOrganisationBusinessUnits = (buId?: number) => {
  const businessUnitId = buId ? buId : +getBUFromLocalStorage();
  const { data, loading, ...rest } = useQuery<GetOrganisationBusinessUnits, GetOrganisationBusinessUnitsVariables>(
    GET_ORGANISATIONS_BUSINESS_UNITS,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
      variables: {
        businessUnitId,
      },
    },
  );

  return {
    data: data?.organisationBusinessUnits || [],
    loading,
    ...rest,
  };
};
