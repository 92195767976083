import { isKilos, isLitres } from "../utils";

export interface ValidateDosageParams {
  defaultUnits: string;
  dosage: string | number;
  dosageUnits: string;
  numOfAnimals: string | number;
  remaining: string | number;
}

/**
 * @description ensures dosage does not exceed amount remaining equalising units to ensure fair calculation
 * @param options calculation options object (felt better to do it this way then have 4 func params)
 * @param options.defaultUnits the default unit for a batch (sent from BE)
 * @param options.dosage the dosage to be administered
 * @param options.dosageUnits the units specified by user to be administered in
 * @param options.numAnimals how many animals dosage is being applied to
 * @param options.remaining how much medicine is remaining
 * @returns true or false if dosage is allowed
 */
export const validateDosage = (options: ValidateDosageParams): boolean => {
  const { defaultUnits, dosageUnits } = options;

  // lets do some number conversions
  const dosage = Number(options.dosage);
  const numOfAnimals = Number(options.numOfAnimals);
  const remaining = Number(options.remaining);

  // check we have all valid numbers
  if (!dosage || !numOfAnimals || !remaining) return false;

  // calculate total dosage based on whether we're applying to one or more animals at once
  const totalDosage = dosage * numOfAnimals;

  // first work out if we're dealing with litre (or it's variants) or kilograms (or it's variants) as a default unit
  if (isLitres(defaultUnits) || isKilos(defaultUnits)) {
    // if the user has selected litres or kilograms, convert both the remaining and dosage to the
    // smallest denominator (i.e. ml or g)
    if (isLitres(dosageUnits) || isKilos(dosageUnits)) {
      return remaining * 1000 - totalDosage * 1000 >= 0;
    }

    // if the user has chosen ml or g, convert the remaining to smallest denominator and calculate
    return remaining * 1000 - totalDosage >= 0;
  }

  // all is well with the world and default unit and dosage unit is NOT litres or kg
  // this doesn't mean it's g or ml though, it could be 'capsules', 'pills', or any other
  // unit the medicine is prescribed in. We don't care at this point, just work out if there's enough
  return remaining - totalDosage >= 0;
};
