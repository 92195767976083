import { FC } from "react";
// Utils
import { useGetCurrentUserInfo } from "hooks";
// Components
import { ImageButton } from "components";

interface DashboardAvatarButtonProps {
  onClick?: () => void;
}

export const DashboardAvatarButton: FC<DashboardAvatarButtonProps> = ({ onClick }) => {
  const { data } = useGetCurrentUserInfo();

  const avatarURL = data?.userInfo?.image || undefined;
  const firstName = data?.userInfo?.firstName || "";

  return <ImageButton fallbackText={firstName} image={avatarURL} onClick={onClick} />;
};
