import { useQuery } from "@apollo/client";
import { GET_ACTIVITY_TYPES } from "api/MyLivestock/Activity/queries";
import {
  GetActivityTypes,
  GetActivityTypes_activityTypes,
  GetActivityTypesVariables,
} from "api/MyLivestock/Activity/types/GetActivityTypes";
import { getBUFromLocalStorage } from "helpers/storage";

export const useGetActivityTypes = () => {
  const { data, ...rest } = useQuery<GetActivityTypes, GetActivityTypesVariables>(GET_ACTIVITY_TYPES, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
  });

  const breeds = (data?.activityTypes || []) as GetActivityTypes_activityTypes[];

  return {
    data: breeds,
    ...rest,
  };
};
