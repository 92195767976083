import { ImportCustomWizard } from "import/containers";
import { Header, PageContent, PageHeader, Spacer } from "components";
import { useParams } from "react-router-dom";

export const ImportCustom = () => {
  const { batchUploadFormatSlug } = useParams<CustomImportParam>();

  return (
    <>
      <PageHeader>
        <Header title="Import livestock data" />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />

        <ImportCustomWizard batchUploadFormatSlug={batchUploadFormatSlug} />
      </PageContent>
    </>
  );
};
