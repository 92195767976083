import { Button, FieldText, Flex, Text, Modal, Spacer } from "components";
import { FC, useContext } from "react";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { ChromePicker } from "react-color";
import { useCreateTagMutation, useUpdateTagMutation } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { CommonContext } from "config/commonProvider";
import { ANIMAL_TAGS } from "tags/api/queries";
import { useTags } from "tags/hooks/useTagsHook";
import gql from "graphql-tag";
import { useLocale } from "helpers/translations/src/useLocale";

interface Props {
  showModal: boolean;
  handleShowModal: () => void;
  tagId?: string;
}

interface FormValues {
  name: string;
  color: string;
}

type FormProps = FormikProps<FormValues>;

export const CreateEditTagModal: FC<Props> = ({ showModal, handleShowModal, tagId }) => {
  const { showNotification } = useContext(CommonContext);
  const { getTagById } = useTags();
  const tagToEdit = getTagById(tagId ?? "");

  const [createTag] = useCreateTagMutation();
  const [updateTag] = useUpdateTagMutation();
  const { terms } = useLocale();

  const businessUnitId = getBUFromLocalStorage();
  const defaultFormValues: FormValues = {
    name: tagToEdit?.node?.name ?? "",
    color: tagToEdit?.node?.color ?? "#ff0400",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().max(20, "Tag name can be no longer than 20 characters"),
    colour: Yup.string(),
  });

  const handleShowError = (errorMessage: string): void => {
    showNotification({
      variant: "error",
      message: errorMessage,
    });

    handleShowModal();
  };

  const handleCreateTag = async (values: FormValues): Promise<void> => {
    if (!tagToEdit) {
      await createTag({
        variables: { input: { ...values, businessUnit: businessUnitId } },
        refetchQueries: [{ query: ANIMAL_TAGS, variables: { buId: businessUnitId } }],
        update: (_, { data: createLocalTagData }) => {
          if (createLocalTagData?.createTag?.errors == null) {
            showNotification({
              message: "Tag created successfully",
            });

            handleShowModal();
          } else {
            const errorMessages = createLocalTagData?.createTag?.errors.reduce<string[]>((accumulator, error) => {
              if (error?.message) accumulator.push(error.message);
              return accumulator;
            }, []);

            handleShowError(errorMessages.join("; "));
          }
        },
      });
    } else {
      const id = tagToEdit?.node?.id ?? "";
      await updateTag({
        variables: { input: { ...values, tag: id } },
        refetchQueries: [{ query: ANIMAL_TAGS, variables: { buId: businessUnitId } }],
        update: (cache, result) => {
          if (result.data?.updateTag?.errors == null) {
            const tag = result.data?.updateTag?.animalTag;

            if (tag) {
              cache.writeFragment({
                id: `AnimalTagOverviewType:${tag?.id}`,
                fragment: gql`
                  fragment MyTag on AnimalTagOverviewType {
                    color
                    name
                  }
                `,
                data: {
                  color: tag.color,
                  name: tag.name,
                },
              });
            }
            showNotification({
              message: "Tag created successfully",
            });

            handleShowModal();
          } else {
            handleShowError("Error updating tag");
          }
        },
      });
    }
  };

  return (
    <Modal
      active={showModal}
      title={`${tagToEdit != null ? "Edit" : "Create"} a new local tag:`}
      handleClose={handleShowModal}
      showCloseButton
    >
      <Formik
        enableReinitialize
        initialValues={defaultFormValues}
        validationSchema={validationSchema}
        onSubmit={handleCreateTag}
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit }: FormProps): React.ReactNode => {
          return (
            <>
              <Flex container>
                <Flex item itemGutter xs={12} s={6} m={4} l={3} xl={2}>
                  <FieldText
                    label="Tag name"
                    error={errors.name}
                    inputProps={{
                      name: "name",
                      defaultValue: values.name,
                      onChange: handleChange,
                    }}
                  />
                </Flex>
                <Flex item itemGutter xs={12} s={6} m={4} l={3} xl={2}>
                  <Text>{`Tag ${terms.colour}`}</Text>
                  <Spacer baselineHeight={1} />
                  <ChromePicker
                    color={values.color}
                    onChange={(color) => {
                      setFieldValue("color", color.hex);
                    }}
                  />
                </Flex>
              </Flex>
              <Spacer baselineHeight={2} />
              <Button caption={`${tagToEdit != null ? "Edit" : "Create"} tag`} onClick={handleSubmit} />
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
