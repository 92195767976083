import React, { useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import objectHash from "object-hash";
import { FormBlockWithLogo, LinkForgottenPassword } from "components/Auth";
import TermsAndConditionsModal from "pages/Auth/TermsAndConditionsModal";
import { Button } from "components";
import Input from "components/Common/Input";
import { LOGIN, DASHBOARD, ANIMALS, SUBSCRIPTION_DETAILS } from "constants/Routes";
import { FEATURES } from "constants/Features";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { validationSchema, handleEnterPress, stringifyJsonSafely } from "helpers/general";
import { setFeaturesListToLocalStorage } from "helpers/storage";
import { postRequest } from "api/restAPI";
import api from "api/config";
import styles from "./styles.module.css";
import { useAuth } from "auth/hooks/useAuth";

const SignIn: React.FC = () => {
  const { sso } = useParams<AuthSignInPageParams>();
  const location = useLocation();
  const history = useHistory();
  const { setUserAsAuthenticated } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [termsTitle, setTermsTitle] = useState<string>("");
  const [termsText, setTermsText] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleHideModal = (): void => {
    setShowModal(false);
  };

  const login = async ({ email, password }: LoginData): Promise<void> => {
    setError(null);
    setLoading(true);

    try {
      const { data } = await postRequest(
        api.endpoints.logIn,
        stringifyJsonSafely({
          email,
          password,
        }),
      );

      if (!data.is_terms_accepted) {
        setTermsTitle(data.terms_title);
        setTermsText(data.terms_text);
        setEmail(email);
        setPassword(password);

        setShowModal(true);
        setLoading(false);
      } else {
        ReactGA.set({ userId: objectHash.sha1(email) });
        const featuresList = data.features.slice(1, -1).split("$");

        await setUserAsAuthenticated(data.token);

        setFeaturesListToLocalStorage(featuresList);

        if (location.search && sso) {
          history.push(`${LOGIN}/sso/${location.search}`);
        } else {
          const isShowHomePage = featuresList.indexOf(FEATURES.SHOW_HOME_PAGE) > -1;
          const goToSubscription = history.location.pathname.includes(SUBSCRIPTION_DETAILS);

          if (goToSubscription) {
            history.push(history.location.pathname);
          } else {
            history.push(isShowHomePage ? DASHBOARD : ANIMALS);
          }
        }
      }
    } catch (e: any) {
      setLoading(false);
      setError(e.response ? e.response.data.error : "Error");
    }
  };

  return (
    <FormBlockWithLogo
      // @ts-expect-error
      onSubmit={login}
      // @ts-expect-error
      customStyle={styles.viewForm}
      validationSchema={validationSchema}
      initialValues={{
        email: "",
        password: "",
      }}
    >
      {({ values, errors, handleChange, handleSubmit }): JSX.Element => {
        const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>): void => {
          handleEnterPress(e, handleSubmit);
        };
        return (
          <div className={styles.container}>
            <Input
              id={ELEMENTS_IDS.SIGN_IN_FORM_EMAIL_INPUT}
              autoFocus
              type="email"
              label="Email"
              name="email"
              value={values.email}
              stylesClasses={styles.inputStyle}
              handleChange={handleChange}
              errorStatus={errors.email}
              onKeyPress={handleKeyPress}
              error={errors.email || null}
            />
            <Input
              id={ELEMENTS_IDS.SIGN_IN_FORM_PASSWORD_INPUT}
              type="password"
              label="Password"
              name="password"
              value={values.password}
              stylesClasses={styles.inputStyle}
              handleChange={handleChange}
              errorStatus={errors.password}
              onKeyPress={handleKeyPress}
              error={errors.password || null}
            />
            {error ? <div className={styles.errorText}>{error}</div> : null}
            <div className={styles.buttonsWrapper}>
              <Button
                id={ELEMENTS_IDS.SIGN_IN_FORM_LOG_IN_BUTTON}
                caption="Login"
                width="full"
                height="large"
                disabled={loading}
                requesting={loading}
                onClick={handleSubmit}
              />
              <LinkForgottenPassword customTextStyle={styles.linkText} />
              {/*
              We want users to only sign up through mobile. We have
              therefore removed the web sign-up form.

              I have left the sign-up code commented out for now
              in case we want to reimplement it at a later point.
              I think web sign up might be useful in the future
              considering we only can do payments through Stripe
              on the web app.

              Ben Busch 07-12-2022.

              <NavLink id={ELEMENTS_IDS.SIGN_IN_SIGN_UP_LINK} to={SIGN_UP} className={styles.signUpLink}>
                Don’t have Breedr? Sign-up now.
              </NavLink>
              */}
            </div>
            <TermsAndConditionsModal
              email={email}
              password={password}
              title={termsTitle}
              text={termsText}
              showModal={showModal}
              hideModal={handleHideModal}
            />
          </div>
        );
      }}
    </FormBlockWithLogo>
  );
};

export default SignIn;
