/* eslint-disable @typescript-eslint/naming-convention */

//TODO: Move to feShared in future and sync with Mobile
export enum EventName {
  bluetoothConnection = "bluetooth-connection",
  onboardingUserDetailsSubmit = "onboarding-user-details-submit",
  // Potentially deprecated....
  appStartup = "app-startup",
  userLogin = "user-log-in",
  contactCreate = "contact-create",
  contactEdit = "contact-edit",
  crushTypeEid = "crush-type-eid",
  crushScanEid = "crush-scan-eid",
  crushSearchAnimal = "crush-search-animal",
  crushWeightViaWh = "crush-weight-via-wh",
  crushWeightManual = "crush-weight-manual",
  medCabAddByScan = "med-cab-add-by-scan",
  medCabAddManual = "med-cab-add-manual",
  medCabVeiw = "med-cab-view",
  medCabBatchView = "med-cab-batch-view",
  medCabViewMedDetails = "med-cab-view-med-details",
  entersSettings = "enters-settings",
  activityLogAnimal = "activity-log-animal",
  groupShow = "group-show",
  groupCreate = "group-create",
  groupView = "group-view",
  fieldShow = "field-show",
  fieldView = "field-view",
  msgShow = "msg-show",
  msgView = "msg-view",
  msgSendNew = "msg-send-new",
  msgReply = "msg-reply",
  debugInfo = "debug-info",
  fieldCreate = "field-create",
  fieldUpdate = "field-update",
  videoPlay = "video-play",
  regulatoryRequestDownload = "regulatory-request-download",
  regulatoryRequestEmail = "regulatory-request-email",
  saveConnection = "reg-save-credentials",
  quickWeigh = "quick-weigh",
  settingsUserListView = "settings-userlist-view",
  settingsUserInvite = "settings-user-invite",
  settingsAddresslistView = "settings-addresslist-view",
  settingsAddressCreate = "settings-address-create",
  createAnimalBirth = "create-animal-birth",
  createAnimalNonbirth = "create-animal-nonbirth",
  saveFilter = "save-filter",
  deeplinkScreen = "deeplink-screen",
  createOffer = "create-offer",
  acceptOffer = "accept-offer",
  rejectOffer = "reject-offer",
  emptyStateButtonPress = "empty-state-button-press",
  sireCreate = "sire-create",
  sireEdit = "sire-edit",
  sireDelete = "sire-delete",
  bankDetailsEdited = "bank-details-edit",
  bankDetailsAdded = "bank-details-added",
  listingCreate = "listing-create",
  listingStartedHomepage = "listing-started-homepage",
  listingUpdate = "listing-update",
  listingView = "listing-view",
  offerDecision = "offer-decision",
  listingLivestockShipped = "listing-livestock-shipped",
  listingLivestockReceived = "listing-livestock-received",
  appActivate = "app-activate",
  startRegulatoryRequest = "start-regulatory-request",
  regulatoryRequestStep0 = "regulatory-request-step1",
  regulatoryRequestStep1 = "regulatory-request-step2",
  regulatoryRequestStep2 = "regulatory-request-step3",
  listingStartedBuying = "listing-started-buying,",
  viewBuyingLiveweight = "view-buying-liveweight",
  listingStartedSelling = "listing-started-selling",
  listingPhotoAdded = "listing-photo-added",
  speciesSwitch = "species-switch",
  selectFavoriteActivityTemplate = "select-favorite-activity-template",
  addActivityTemplateToFavorites = "add-activity-template-to-favorites",
  removeActivityTemplateFromFavorites = "remove-activity-template-from-favorites",
  listingStartedMycattle = "listing-started-mycattle",
  listingStartedGroups = "listing-started-groups",
  listingStartedCrush = "listing-started-crush",
  sortAnimalsList = "sort-animals-list",
  viewAnimalOffspring = "animal-offspring-view",
  viewAnimalFromOffspringList = "view-animal-from-offspring-list",
  listingFilterSmallDistance = "listing-filter-small-distance",
  listingFilterMedDistance = "listing-filter-med-distance",
  listingFilterLrgDistance = "listing-filter-lrg-distance",
  listingFilterAnyDistance = "listing-filter-any-distance",
  bluetoothIssue = "bluetooth-issue",
  listingFilterCategory = "listing-filter-category",
  contactUsListingBadgePress = "contact-us-listing-badge-press",
  loqateAddressLookup = "loqate-address-lookup",
  mapsIssue = "maps-issue",
  identifierPreferenceManagement = "identifier-preference-management",
  import = "import",
}
