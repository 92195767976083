// React
import React, { useContext } from "react";
// Utils
import { CommonContext, ShowNotification } from "config/commonProvider";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { validationSchemaPassword, handleEnterPress, handleErrorCheck } from "helpers/general";
// Components
import FormBuilder from "components/Common/FormBuilder";
import { FieldPassword, Modal, Spacer, Text } from "components";

interface ConfirmWithPasswordModalProps {
  emailUpdateError: string;
  showModal: boolean;
  requesting: boolean;
  onModalClose: () => void;
  changeUserEmail: (
    props: {
      password: string;
    },
    showNotification: ShowNotification,
  ) => void;
}

const ConfirmWithPasswordModal: React.FC<ConfirmWithPasswordModalProps> = ({
  showModal,
  onModalClose,
  requesting,
  changeUserEmail,
  emailUpdateError,
}) => {
  const { showNotification } = useContext(CommonContext);

  const handleChangeEmail = (props): void => changeUserEmail(props, showNotification);

  return (
    <FormBuilder
      validateOnChange={false}
      initialValues={{
        password: "",
      }}
      onSubmit={handleChangeEmail}
      validationSchema={validationSchemaPassword}
    >
      {({ values, errors, handleChange, handleSubmit }): JSX.Element => (
        <Modal
          actions={{
            primary: {
              id: ELEMENTS_IDS.CONFIRM_WITH_PASSWORD_MODAL_CONFIRM_BUTTON,
              disabled: requesting,
              requesting: requesting,
              caption: "Confirm",
              onClick: handleSubmit,
            },
            secondary: {
              id: ELEMENTS_IDS.CONFIRM_WITH_PASSWORD_MODAL_CANCEL_BUTTON,
              caption: "Cancel",
              colour: "grey",
              variant: "hollow",
              onClick: onModalClose,
            },
          }}
          active={showModal}
          handleClose={onModalClose}
          title="Confirm change"
        >
          <FieldPassword
            error={handleErrorCheck(errors.password)}
            helper="Enter your password to update this information"
            inputProps={{
              autoComplete: "new-password",
              autoFocus: true,
              id: ELEMENTS_IDS.CONFIRM_WITH_PASSWORD_MODAL_PASSWORD_INPUT,
              name: "password",
              onChange: handleChange,
              onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
              value: values.password,
            }}
            label="Your password"
          />

          {!!emailUpdateError ? (
            <>
              <Spacer baselineHeight={2} />

              <Text smallest warning>
                {emailUpdateError}
              </Text>
            </>
          ) : null}
        </Modal>
      )}
    </FormBuilder>
  );
};

export default ConfirmWithPasswordModal;
