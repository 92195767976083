// API
import { BuyingLotQuery } from "generated/graphql";

interface GetListingBreedsOptions {
  truncateAfter?: number;
}

export const getListingBreedsString = (
  listing: BuyingLotQuery["buyingLot"],
  { truncateAfter = 3 }: GetListingBreedsOptions = {},
): string | undefined => {
  const listingBreeds: string[] = [];

  listing?.animals?.forEach((animal) => {
    animal?.animalBreeds?.forEach((animalBreed) => {
      if (animalBreed?.breedrCode) listingBreeds.push(animalBreed?.breedrCode);
    });
  });

  if (!listingBreeds.length) return;

  const uniqueListingBreeds = Array.from(new Set(listingBreeds));

  return uniqueListingBreeds.reduce((accumulator, breed, index) => {
    if (index === 0) return breed;

    if (index < truncateAfter) return `${accumulator}, ${breed}`;

    if (index === truncateAfter) return `${accumulator}, +${uniqueListingBreeds.length - truncateAfter}`;

    return accumulator;
  }, "");
};
