import { Dispatch, SetStateAction, useEffect, VFC } from "react";
// Libraries
import { useRecoilValue } from "recoil";
// Utils
import { OfferTypes } from "trading/constants";
import { formattedPrice } from "trading/util/trading";
import { useFormatDate, useGetCurrentBusinessUnit } from "hooks";
// State
import { makeOfferCollectionDate, makeOfferType, makeOfferPrice, makeOfferPriceTotal } from "state";
// Components
import { CheckBox, Flex, Spacer, Text, Title } from "components";
// Resources
import styles from "../BuyerListingOfferModal.module.scss";

export interface ReviewFormProps {
  offerPrice: number | string | null;
  collectionDate: Date | null;
  allAnimals: boolean;
  terms: boolean;
}

interface ReviewModalPropTypes {
  values: ReviewFormProps;
  errors: Record<string, unknown>;
  handleModalToggle: () => void;
  handleChange: (props: Record<string, unknown>) => void;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setFieldValue: <T>(field: string, value: T, shouldValidate?: boolean) => void;
  quantity: number;
  apiError: string;
  hasTerms: boolean;
}

export const Review: VFC<ReviewModalPropTypes> = ({
  values,
  errors,
  handleModalToggle,
  handleChange,
  setDisabled,
  setFieldValue,
  quantity = 0,
  apiError,
  hasTerms,
}) => {
  const isDisableSaveButton =
    !!errors.allAnimals || !!errors.terms || !Boolean(values.allAnimals) || !Boolean(values.terms);

  const { currencyCodeISO } = useGetCurrentBusinessUnit();
  const { getFormatDate } = useFormatDate();
  const offerType = useRecoilValue(makeOfferType);
  const collectionDate = useRecoilValue(makeOfferCollectionDate);

  const offerPrice = useRecoilValue(makeOfferPrice);
  const offerPriceTotal = useRecoilValue(makeOfferPriceTotal);

  const formattedOfferPriceTotal = offerPriceTotal ? formattedPrice(offerPriceTotal, currencyCodeISO) : "\u2014";

  useEffect(() => {
    setDisabled(isDisableSaveButton);
  }, [isDisableSaveButton]);

  useEffect(() => {
    setFieldValue("allAnimals", "", false);
    setFieldValue("terms", "", false);
  }, [offerType]);

  const formattedOfferPrice = (offerPrice && formattedPrice(offerPrice, currencyCodeISO)) || "";

  const offerTypeLabel = offerType === OfferTypes.HEAD ? "per head" : "per kg";

  return (
    <>
      <Flex item itemGutter xs={2}>
        <Text>Offer {offerTypeLabel}:</Text>
        <Title secondary>{formattedOfferPrice}</Title>
      </Flex>

      <Flex item>
        <Text>No of Animals:</Text>
        <Title secondary>{quantity}</Title>
      </Flex>

      <Spacer baselineHeight={3} />

      <Flex item itemGutter xs={2}>
        <Text>Total offer value:</Text>
        <Title secondary>{formattedOfferPriceTotal}</Title>
      </Flex>

      {offerType === OfferTypes.KG ? (
        <Flex item>
          <Text>Estimated Collection Date</Text>
          <Title secondary>{getFormatDate(collectionDate ?? undefined)}</Title>
        </Flex>
      ) : null}

      {hasTerms ? (
        <>
          <Spacer baselineHeight={1} />

          <Flex item itemGutter className={styles.checkbox_agreements}>
            <CheckBox
              type="text"
              label="I agree to purchase all animals in this listing"
              name="allAnimals"
              checked={values.allAnimals || false}
              value={values.allAnimals}
              errorStatus={errors.allAnimals}
              onChange={handleChange}
              className={styles.checkboxLabel}
            />

            <Spacer baselineHeight={1} />

            <CheckBox
              type="text"
              label={
                <>
                  I agree to the{" "}
                  <span className={styles.terms_link} onClick={(): void => handleModalToggle()}>
                    terms and conditions
                  </span>{" "}
                  of making an offer
                </>
              }
              name="terms"
              checked={values.terms || false}
              value={values.terms}
              errorStatus={errors.terms}
              onChange={handleChange}
              className={styles.checkboxLabel}
            />
          </Flex>
        </>
      ) : null}

      <Spacer baselineHeight={2} />

      <Flex item itemGutter>
        {hasTerms ? (
          <Text smallest>
            By selecting Submit offer, you are agreeing to and entering into a legally binding contract to purchase the
            item from the seller if they accept your offer.
          </Text>
        ) : (
          <Text smallest warning>
            There was an error loading our terms and conditions for making an offer, please contact Breedr trading
            support directly.
          </Text>
        )}
      </Flex>

      <Spacer baselineHeight={1} />

      {apiError ? (
        <Flex item itemGutter>
          <Text colour="red">{`Error: ${apiError.toString()}`}</Text>
        </Flex>
      ) : null}
    </>
  );
};
