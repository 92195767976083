import { VFC } from "react";
// API
import { GetEstimatedFuturePriceBasedOnWeight_estimatedFuturePriceBasedOnWeight_errors } from "trading/api/types/GetEstimatedFuturePriceBasedOnWeight";
// Utils
import { FALLBACK_MESSAGE, NO_WEIGHT_MESSAGE } from "trading/constants";
// Components
import { Flex, Spinner, Title, Text } from "components";

export enum errorCodes {
  FALLBACK_WEIGHT = "fallback_weight",
  NO_PREDICTED_WEIGHT = "no_predicted_weight",
}

export const errorCodeMessages = {
  fallback_weight: FALLBACK_MESSAGE,
  no_predicted_weight: NO_WEIGHT_MESSAGE,
};
interface OfferCalculationProps {
  total: string | number | null;
  errors?: (GetEstimatedFuturePriceBasedOnWeight_estimatedFuturePriceBasedOnWeight_errors | null)[] | null | undefined;
  loading?: boolean;
}
export const OfferCalculation: VFC<OfferCalculationProps> = ({ total, errors = [], loading }) => {
  const error = errors && errors[0]?.code;

  const errorMessage = error && errorCodeMessages[error];
  return (
    <Flex item xs={4}>
      {loading ? (
        <Flex item itemGutter xs={1}>
          <Spinner />
        </Flex>
      ) : (
        <Flex item>
          <Title secondary>{total}</Title>
          <Text colour="red">{errorMessage}</Text>
        </Flex>
      )}
    </Flex>
  );
};
