import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import SVG from "react-inlinesvg";
import { DASHBOARD, TRADING, ANIMALS } from "constants/Routes";
import { BU_TYPES } from "trading/constants";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import logoSVG from "assets/img/brand/logo.svg";
import styles from "./RootMenuLogo.module.scss";
import { getBUTypeFromLocalStorage } from "helpers/storage";
import { useHasFeature } from "hooks";

interface RootMenuLogoProps {
  className?: string;
}

export const RootMenuLogo: React.FC<RootMenuLogoProps> = forwardRef<HTMLAnchorElement, RootMenuLogoProps>(
  ({ className, ...props }, forwardedRef) => {
    const selectedBUType = getBUTypeFromLocalStorage();
    const isShowHomePage = useHasFeature("SHOW_HOME_PAGE");
    const isProcessor = selectedBUType === BU_TYPES.processor;
    const pathToRedirect = isProcessor ? TRADING : ANIMALS;

    return (
      <NavLink
        id={ELEMENTS_IDS.ROOT_MENU_LOGO}
        to={isShowHomePage ? DASHBOARD : pathToRedirect}
        className={cn(styles.root_menu_logo, className)}
        ref={forwardedRef}
        {...props}
      >
        <SVG src={logoSVG} />
      </NavLink>
    );
  },
);
RootMenuLogo.displayName = "RootMenuLogo";
