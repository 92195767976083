import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Checkbox } from "twComponents/Checkbox";
import { cn } from "utils/misc";

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const FormCheckbox = <TFieldValues extends FieldValues>({ name, className, ...rest }: Props<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  if (!methods) {
    throw new Error("FormInput must be used inside a <Form /> or <FormProvider />");
  }

  return (
    <Checkbox
      type="checkbox"
      id={name}
      className={cn(className, "disabled:cursor-not-allowed")}
      {...rest}
      {...(methods?.register && methods.register(name))}
    />
  );
};
