import { ApolloError } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { DateTime } from "luxon";

import { getBUFromLocalStorage } from "helpers/storage";

import { LOG_ACTIVITY_BULK_MUTATION } from "api/MyLivestock/Activity/mutations";
import { GET_MY_ANIMALS_LIST } from "api/MyLivestock/Animal/queries";
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { GetGroup_group } from "api/MyLivestock/Groups/types/GetGroup";
import { activityTemplateFragment } from "api/MyLivestock/Activity/types/activityTemplateFragment";
import { LogActivityBulkMutation } from "api/MyLivestock/Activity/types/logActivityBulkMutation";

import { ACTIVITY_TEMPLATE_MOVE_OFF_FARM } from "constants/Animals";
import { useStateSpecies } from "./useStateSpecies";
import { GET_GROUP } from "groups/api/queries";

interface Props {
  animalIds: Array<string>;
  activityTemplate: activityTemplateFragment | null;
  groupId?: string;
}

interface UseBulkMarkAnimalsOffFarmMutation {
  loading: boolean;
  error: ApolloError | undefined;
  data: LogActivityBulkMutation;
  mutate: (data) => Promise<void>;
}

export const useBulkMarkAnimalsOffFarmMutation = ({
  animalIds,
  activityTemplate,
  groupId,
}: Props): UseBulkMarkAnimalsOffFarmMutation => {
  const [logActivityBulk, { data, loading, error, ...rest }] = useMutation(LOG_ACTIVITY_BULK_MUTATION);

  const businessUnitId = Number(getBUFromLocalStorage());
  const { activeSpecies } = useStateSpecies();

  const animalsIds = animalIds;
  const fromBusinessUnitId = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.fromBUId,
  );
  const fromBusinessUnitName = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.fromBUName,
  );
  const destinationCPHNumberField = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.destinationCPHNumber,
  );
  const destinationNameField = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.destinationName,
  );
  const destinationIdField = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.destinationId,
  );
  const destinationPostcodeField = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.destinationPostcode,
  );
  const destinationAddressField = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.destinationAddress,
  );
  const destinationEmailField = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.destinationEmail,
  );
  const notesField = activityTemplate?.fields.find(
    (field) => field.slug === ACTIVITY_TEMPLATE_MOVE_OFF_FARM.notesField,
  );

  const mutate = async ({
    deliveryDate,
    destinationCPHNumber,
    destinationName,
    destinationPostcode,
    destinationAddress,
    destinationEmail,
    destinationId,
    notes,
  }): Promise<void> => {
    await logActivityBulk({
      variables: {
        input: {
          date: DateTime.fromJSDate(deliveryDate).toISO(),
          activityTemplate: Number(activityTemplate?.id),
          animals: animalsIds,
          businessUnit: businessUnitId,
          payload: {
            data: [
              {
                id: Number(fromBusinessUnitId?.id),
                value: businessUnitId,
              },
              { id: Number(fromBusinessUnitName?.id), value: "" },
              { id: Number(destinationIdField?.id), value: destinationId },
              { id: Number(destinationCPHNumberField?.id), value: destinationCPHNumber },
              { id: Number(destinationNameField?.id), value: destinationName },
              { id: Number(destinationPostcodeField?.id), value: destinationPostcode },
              { id: Number(destinationAddressField?.id), value: destinationAddress },
              { id: Number(destinationEmailField?.id), value: destinationEmail },
              { id: Number(notesField?.id), value: notes },
            ],
            name: "Move animals off farm",
          },
        },
      },
      refetchQueries: ["GetAnimalsPage", "AnimalTags"],
      update: (cache) => {
        const updatedIds = new Set(animalsIds);
        const previousVariables = {
          businessUnitId,
          animalTypeIds: activeSpecies?.id ? [Number(activeSpecies?.id)] : undefined,
        };
        const previousData = cache.readQuery({
          query: GET_MY_ANIMALS_LIST,
          variables: { ...previousVariables },
        }) as {
          animalsExtended: {
            animals: GetAnimalsList_animalsExtended_animals[];
            animalsCount: number;
          };
        };

        const animalsExtended = previousData?.animalsExtended;

        if (animalsExtended) {
          const updatedAnimalsData = animalsExtended.animals.filter((item) => !updatedIds.has(item.id));

          cache.writeQuery({
            query: GET_MY_ANIMALS_LIST,
            variables: {
              ...previousVariables,
            },
            data: {
              ...previousData,
              animalsExtended: {
                ...previousData.animalsExtended,
                animals: updatedAnimalsData,
                animalsCount: updatedAnimalsData.length,
              },
            },
          });
        }

        if (groupId) {
          const previousGroupData = cache.readQuery({
            query: GET_GROUP,
            variables: {
              ...previousVariables,
              group: Number(groupId),
            },
          }) as {
            group: GetGroup_group;
          };

          const { group } = previousGroupData;

          if (group) {
            const updatedAnimalsInGroupData = group?.animals?.filter((item) => !updatedIds.has(item?.id));

            cache.writeQuery({
              query: GET_GROUP,
              variables: {
                ...previousVariables,
                group: groupId,
              },
              data: {
                ...previousGroupData,
                group: {
                  ...previousGroupData.group,
                  animals: updatedAnimalsInGroupData,
                },
              },
            });
          }
        }
      },
    });
  };

  return {
    data,
    loading,
    error,
    mutate,
    ...rest,
  };
};
