import { VFC } from "react";
// API
import { MyLots_myLots_edges_node } from "trading/api/Liveweight/types/MyLots";
// Utils
import { TRADING, VIEW_LISTING_BASE } from "constants/Routes";
import { getListingAddressPrivate } from "trading/util";
import { useListingCardPublicProps } from "hooks";
// Components
import { ListingCard } from "trading/components";

interface SellerListingCardProps {
  listing: MyLots_myLots_edges_node;
}

export const SellerListingCard: VFC<SellerListingCardProps> = ({ listing }) => {
  const listingCardProps = useListingCardPublicProps(listing);

  const location = listing?.deliveryAddress ? getListingAddressPrivate(listing.deliveryAddress) : undefined;

  const url = `${TRADING}${VIEW_LISTING_BASE}/${listing.id}`;

  return <ListingCard {...listingCardProps} location={location} url={url} />;
};
