import { Field, Input as Container } from "components";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { createPortal } from "react-dom";

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  label: string;
  disabled?: boolean;
} & Omit<ReactDatePickerProps, "onChange">;

export const FormDatePicker = <TFieldValues extends FieldValues>({ name, label, ...rest }: Props<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  if (!methods) {
    throw new Error("FormDatePicker must be used inside a <Form /> or <FormProvider />");
  }

  return (
    <Controller
      control={methods?.control}
      name={name}
      render={({ formState: { errors }, field: { value, onChange } }) => {
        return (
          <Field error={errors[name]?.message as string} labelFor={name} label={label}>
            <Container hasError={!!errors[name]?.message} icon={{ name: "calendar", labelFor: name }}>
              <DatePicker
                autoComplete="off"
                id={name}
                name={name}
                selected={value}
                onChange={onChange}
                onKeyDown={(event) => event.preventDefault()}
                className="w-full bg-transparent border-none h-8 py-0 px-3 text-black text-xs focus:outline-none disabled:hover:cursor-not-allowed disabled:opacity-50"
                popperContainer={({ children }) => (children ? createPortal(children, document.body) : null)}
                {...rest}
              />
            </Container>
          </Field>
        );
      }}
    />
  );
};
