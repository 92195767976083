import { gql } from "@apollo/client";
import { GROUP_FRAGMENT } from "./fragment";

export const GET_GROUPS_LIST = gql`
  query GetGroupsList($businessUnitId: Int, $animalTypeIds: [Int]) {
    groupsExtended(businessUnitId: $businessUnitId, animalTypeIds: $animalTypeIds) {
      groups {
        id
        name
        numberOfUnidentifiedAnimalsInBatch
        identifiedAnimalsCount
        identifiedMaleAnimalsCount
        identifiedFemaleAnimalsCount
        category
        targetWeight
        avgDlwg
        avgEstimatedWeight
        avgRecordedWeight
        animalAlertsCount
        isBatch
        animalType {
          id
        }
      }
    }
  }
`;

export const GET_GROUP = gql`
  query Group($group: UUID!, $businessUnit: Int!, $isBatch: Boolean!) {
    group(group: $group, businessUnit: $businessUnit) {
      ...GroupFragment
    }
  }
  ${GROUP_FRAGMENT}
`;

export const GET_GROUPS_INFO = gql`
  query GroupsInfo($businessUnitId: Int, $animalTypeIds: [Int]) {
    groupsExtended(businessUnitId: $businessUnitId, animalTypeIds: $animalTypeIds) {
      groupsCount
      groups {
        id
        name
        isBatch
      }
    }
  }
`;

export const GET_GROUPS_COUNT = gql`
  query GetGroupsCount($businessUnitId: Int) {
    groupsExtended(businessUnitId: $businessUnitId) {
      groupsCount
    }
  }
`;
