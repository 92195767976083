import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Hooks
import { useGetBusinessUnitSettings, useBreakpoints } from "hooks";
// Components
import { Warnings, SummaryTable, TransactionsTable } from "components/Regulatory";
import { Alert, Flex, Panel, Spacer, Title } from "components";
// Constants
import { SETTINGS, BUSINESS_SETTINGS } from "constants/Routes";
import { EmptyStatesBcms } from "components/EmptyStates/BCMS";

export enum TableDataKeys {
  type = "type",
  pending = "pending",
  inProgress = "inProgress",
  failed = "failed",
  successful = "successful",
}
export type TransactionType = "MOVE_OFF" | "MOVE_ON" | "REGISTER_BIRTH" | "REGISTER_DEATH" | "";
export type ColumnsType = keyof typeof TableDataKeys | "";
export type StatusesType = ("PENDING" | "IN_PROGRESS" | "FAILED" | "SUCCESS")[] | null;
export type SelectedCellType = {
  transactionType: TransactionType;
  statuses: StatusesType;
  column: ColumnsType;
  refreshId?: number | null;
};
export type SetSelectedCellType = (value: SelectedCellType | ((prevVar: SelectedCellType) => SelectedCellType)) => void;
export type SelectedCellProps = {
  selectedCell: SelectedCellType;
  onSetSelectedCell: SetSelectedCellType;
};

export const Regulatory: React.FC = () => {
  const history = useHistory();
  const { isBreakpointL } = useBreakpoints();
  const [selectedCell, setSelectedCell] = useState<SelectedCellType>({
    transactionType: "",
    statuses: null,
    column: "",
    refreshId: null,
  });
  const { data } = useGetBusinessUnitSettings();

  const isPushToBCMS = !!data?.businessUnit?.enableBcmsPushTransactions;
  const commonProps = {
    selectedCell,
    onSetSelectedCell: setSelectedCell,
  };

  if (!isPushToBCMS) {
    return (
      <>
        <Alert
          buttonText="Farm Settings"
          color="RED"
          onButtonTextClick={(): void => history.push(`${SETTINGS}${BUSINESS_SETTINGS}`)}
          text="You are not using Breedr to push movements and registrations. You can change this setting in "
        />
        <EmptyStatesBcms />
      </>
    );
  }

  return (
    <>
      <Flex container containerJustifyContent="space-between">
        <Flex item itemGutter xs="fill" l={7}>
          <Spacer baselineHeight={1} />
          <Title secondary>Summary</Title>
          <Spacer baselineHeight={1} />
          <SummaryTable {...commonProps} />
        </Flex>
        <Flex item itemGutter xs="fill" l={4}>
          {!isBreakpointL ? <Spacer baselineHeight={2} /> : null}
          <Panel title="Warnings">
            <Warnings {...commonProps} />
          </Panel>
        </Flex>
      </Flex>
      <Spacer baselineHeight={5} />
      <TransactionsTable {...commonProps} />
    </>
  );
};
