import React from "react";
// Components
import { Flex, Spacer, WYSIWYG } from "components";

export const Terms = ({ termsData }): JSX.Element => {
  const description = termsData?.termsAndConditions?.description || "";

  return (
    <>
      <Flex item itemGutter>
        <WYSIWYG content={description} size="small" />
      </Flex>
      <Spacer baselineHeight={1} />
    </>
  );
};
