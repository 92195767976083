import { AncestorType } from "generated/graphql";
import { getShortBreedrId } from "helpers/myLivestock";
import { RawNodeDatum } from "react-d3-tree";

export const buildTreeData = (data: any, generations: number): RawNodeDatum | undefined => {
  const rootNodeData = data?.find((datum: AncestorType | undefined) => datum?.depth === 0);

  const buildNode = (datum: AncestorType): RawNodeDatum => {
    const { ancestorIdentifier, animalData, depth } = datum;

    const node: RawNodeDatum = {
      name: ancestorIdentifier ?? "Unknown",
      attributes: {
        pedigreeId: animalData?.pedigreeId ?? "-",
        passportNumber: animalData?.passportNumber ?? "-",
        eId: animalData?.eId ?? "-",
        visualId: animalData?.visualId ?? "-",
        brucellosisId: animalData?.brucellosisId ?? "-",
        trichId: animalData?.trichId ?? "-",
        herdDoggId: animalData?.herdDoggId ?? "-",
        name: animalData?.name ?? "-",
        breedrId: getShortBreedrId(animalData?.breedrId) ?? "-",
        uhfId: animalData?.uhfId ?? "-",
        tattoo: animalData?.tattoo ?? "-",
        alternativeId: animalData?.alternativeId ?? "-",
        isMale: animalData?.isMale ?? false,
        isDead: animalData?.deadAt !== null ?? false,
        offFarm: animalData?.dateLeftFarm !== null ?? false,
        id: animalData?.id ?? null,
        generation: depth ? depth + 1 : 0,
      },
      children: [],
    };

    if (generations > (depth ?? 0) + 1) {
      const children = data
        .filter((d: { ancestorIdentifier: string }) => {
          const sireMatch = animalData?.sirePassportNumber
            ? animalData?.sirePassportNumber === d.ancestorIdentifier
            : false;
          const damMatch = animalData?.damPassportNumber
            ? animalData?.damPassportNumber === d.ancestorIdentifier
            : false;
          return sireMatch || damMatch;
        })
        .filter((d) => d.depth === (depth ?? 0) + 1);

      const sortedChildren = children.sort(
        (a: { animalData: { isMale: boolean } }, b: { animalData: { isMale: boolean } }) => {
          if (a?.animalData?.isMale === b?.animalData?.isMale) {
            return 0;
          }
          if (a?.animalData?.isMale) {
            return -1;
          }
          return 1;
        },
      );
      for (const child of sortedChildren) {
        const childNode = buildNode(child);
        node?.children?.push(childNode);
      }
    }
    return node;
  };

  const treeData = rootNodeData ? buildNode(rootNodeData) : undefined;

  return treeData;
};

export const getMaxGeneration = (treeData: any): number => {
  let maxGeneration = 0;

  const traverse = (node: RawNodeDatum, generation: number) => {
    if (node.attributes && node.attributes.generation) {
      maxGeneration = Math.max(maxGeneration, node.attributes.generation as number);
    }

    if (node.children) {
      for (const child of node.children) {
        traverse(child, generation + 1);
      }
    }
  };

  traverse(treeData, 0);

  return maxGeneration;
};

export const filterNodesByName = (treeData: RawNodeDatum, name: string): RawNodeDatum[] => {
  const matchingNodes: RawNodeDatum[] = [];

  const traverseTree = (node: RawNodeDatum) => {
    if (node.name === name) {
      matchingNodes.push(node);
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach((child: RawNodeDatum) => {
        traverseTree(child);
      });
    }
  };

  traverseTree(treeData);

  return matchingNodes;
};

export const epdNameLookup = {
  calvingEase: "CED",
  birthWeight: "BW",
  weaningWeight: "WW",
  yearlingWeight: "YW",
  dryMatterIntake: "DMI",
  yearlingHeight: "YH",
  scrotalCircumference: "SC",
  docility: "DOC",
  clawAngle: "CA",
  footAngle: "FA",
  heiferPregnancy: "HP",
  calvingEaseMaternal: "CEM",
  milk: "MILK",
  matureWeight: "MW",
  matureHeight: "MH",
  carcassWeight: "CW",
  carcassMarbling: "MARB",
};
