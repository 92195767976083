// React
import React, { useContext } from "react";
// Utils
import { CommonContext, ShowNotification } from "config/commonProvider";
import { HEADER_TYPES } from "constants/General";
import { ELEMENTS_IDS } from "constants/ElementsIds";
// Components
import {
  ConfirmModalWithOutInput as ActionsConfirmModal,
  ConfirmDeletionModal,
  Button,
  Header,
  TextButton,
} from "components";
import { GetFieldQuery, GroupQuery } from "generated/graphql";

interface GroupFieldHeaderProps {
  item?: GroupQuery["group"] | GetFieldQuery["field"] | null | undefined;
  error?: string;
  type: string;
  requesting: boolean;
  showRemoveModal: boolean;
  removeElement: (showNotification: ShowNotification) => void;
  handleSubmit: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  showConfirmModal: boolean;
  setShowConfirmModal: (showConfirmModal: boolean) => void;
  toggleModal: (show: boolean) => void;
  setError: (error?: string | null | undefined) => void;
  handleConfirmAction: () => void;
}

const GroupFieldHeader: React.FC<GroupFieldHeaderProps> = ({
  item,
  error,
  type,
  requesting,
  removeElement,
  handleSubmit,
  toggleModal,
  setError,
  showConfirmModal,
  showRemoveModal,
  handleConfirmAction,
  setShowConfirmModal,
}) => {
  const { showNotification } = useContext(CommonContext);

  const handleRemoveElement = (): void => removeElement(showNotification);

  const handleCancelModal = (): void => {
    toggleModal(false);
    setError(null);
  };
  const handleRemoveGroupField = (): void => {
    if (item && item.animals && item.animals.length > 0) {
      showNotification({
        variant: "error",
        message: `This ${type} has animal(s) assigned to it and cannot be deleted.`,
      });
    } else {
      toggleModal(true);
    }
  };

  const showDeleteButton = item?.__typename === "Group" || (item?.__typename === "Field" && !item?.isHoldingPen);

  const groupDeleteMessage = `Type DELETE  to delete this ${
    type === "field" ? "location" : type
  }, this action cannot be undone. Your animals will not be deleted.`;
  const batchDeleteMessage = `Type DELETE to delete this ${type === "field" ? "location" : type}, by deleting this ${
    type === "field" ? "location" : type
  } you will also lose any record of the unidentified animals.`;
  return (
    <>
      <Header
        backButton
        title={item ? item.name : `Add a new ${type === "field" ? "location" : type}`}
        subTitle={type !== HEADER_TYPES.FIELD && `Add livestock to your ${type}`}
      >
        {showDeleteButton ? (
          <TextButton
            id={ELEMENTS_IDS.GROUP_FIELD_HEADER_DELETE_BUTTON}
            caption={`Delete ${type === "field" ? "location" : type}`}
            onClick={handleRemoveGroupField}
            warning
          />
        ) : null}
        <Button
          id={ELEMENTS_IDS.GROUP_FIELD_HEADER_CANCEL_BUTTON}
          caption="Cancel"
          colour="grey"
          onClick={(): void => (type === HEADER_TYPES.FIELD ? setShowConfirmModal(true) : handleConfirmAction())}
          variant="hollow"
        />
        <Button
          id={ELEMENTS_IDS.GROUP_FIELD_HEADER_CREATE_UPDATE_BUTTON}
          caption={item ? "Save updates" : `Create ${type === "field" ? "location" : type}`}
          onClick={handleSubmit}
        />
      </Header>
      {item ? (
        <ConfirmDeletionModal
          errorMessage={error}
          isLoading={requesting}
          showModal={showRemoveModal}
          onClose={handleCancelModal}
          entityName={item.name}
          onSubmit={handleRemoveElement}
          subtitle={type !== "group" ? batchDeleteMessage : groupDeleteMessage}
        />
      ) : null}
      {type === HEADER_TYPES.FIELD ? (
        <ActionsConfirmModal
          subTitle={`Are you sure you want to cancel ${item ? `updating location ${item.name}` : `this new location`}?`}
          title={`Cancel ${item ? "updating" : "creating"} location`}
          showModal={showConfirmModal}
          onClose={(): void => setShowConfirmModal(false)}
          onConfirm={handleConfirmAction}
        />
      ) : null}
    </>
  );
};

export default GroupFieldHeader;
