import { FC, useState, useContext, useEffect } from "react";
// Utils
import { CommonContext } from "config/commonProvider";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useGetCurrentBusinessUnit, useHasFeature, useStateSpecies } from "hooks";
// Components
import { Button, Modal, Spacer, Text } from "components";
import { TagSelect } from "components/Common/TagSelect";
import { MergeAnimalTagsInput, useGetAnimalsWithTagsLazyQuery, useMergeAnimalTagsMutation } from "generated/graphql";
import { ANIMAL_TAGS } from "tags/api/queries";
import { animalsWithTags } from "tags/helpers";
import { removeNothings } from "helpers/general";
import { AnimalsWithTags } from "tags/types";
import { GET_MY_ANIMALS_LIST } from "api/MyLivestock/Animal/queries";
import { DocumentNode } from "graphql";
import { OperationVariables } from "@apollo/client";
import { useTags } from "tags/hooks/useTagsHook";
import styles from "./styles.module.scss";

interface RemoveSelectedAnimalsToGroupProps {
  animalIds: string[];
}

type TagId = string;

export const RemoveSelectedAnimalsToTag: FC<RemoveSelectedAnimalsToGroupProps> = ({ animalIds }) => {
  const [mergeAnimalTags, { loading }] = useMergeAnimalTagsMutation();
  const { id: businessUnitId } = useGetCurrentBusinessUnit();
  const { activeSpecies } = useStateSpecies();
  const [getAnimals, { data }] = useGetAnimalsWithTagsLazyQuery();
  const [selectedAnimals, setSelectedAnimals] = useState<AnimalsWithTags>([]);
  const isServerPaginatedAnimalsList = useHasFeature("SERVER_SIDE_PAGINATION_FOR_ANIMALS_LIST");
  const { getTagById } = useTags();
  const [isTagRemovable, setIsTagRemovable] = useState<boolean>(true);

  useEffect(() => {
    if (animalIds.length > 0) {
      getAnimals({ variables: { businessUnitId: Number(businessUnitId), animalIds } });
    }
  }, [animalIds, businessUnitId, getAnimals]);

  useEffect(() => {
    if (data?.animals?.edges != null) {
      const animals = removeNothings(data?.animals?.edges);
      setSelectedAnimals(animals);
    }
  }, [data]);

  const [showModal, setShowModal] = useState(false);
  const [tagId, setTagId] = useState<TagId>("");
  const { showNotification } = useContext(CommonContext);

  const handleModalToggle = (): void => {
    setShowModal(!showModal);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();

    handleModalToggle();
  };

  const handleShowError = (errorMessage: string) => {
    showNotification({
      variant: "error",
      message: errorMessage,
    });

    setShowModal(false);
    setTagId("");
  };

  const handleConfirmClick = async (): Promise<void> => {
    const animalIdsToRemove = animalsWithTags(selectedAnimals, tagId)?.map((animal) => animal?.node?.id);

    const previousVariables = {
      businessUnitId,
      animalTypeIds: activeSpecies?.id ? [Number(activeSpecies?.id)] : undefined,
    };
    try {
      const input: MergeAnimalTagsInput = {
        animals: animalIdsToRemove,
        tagValues: [{ tag: tagId, isPresent: false }],
      };
      const queriesToRefetch: [{ query: DocumentNode; variables: OperationVariables }] = [
        { query: ANIMAL_TAGS, variables: { buId: businessUnitId } },
      ];
      if (!isServerPaginatedAnimalsList) {
        queriesToRefetch.push({ query: GET_MY_ANIMALS_LIST, variables: { ...previousVariables } });
      }

      await mergeAnimalTags({
        variables: { input },
        refetchQueries: queriesToRefetch,
        update: (_, { data: mergeAnimalTagsData }) => {
          if (mergeAnimalTagsData?.mergeAnimalTags?.errors == null) {
            showNotification({
              message: "Tag removed successfully",
            });

            setTagId("");
            setShowModal(false);
          } else {
            const errorMessages = mergeAnimalTagsData?.mergeAnimalTags?.errors.reduce<string[]>(
              (accumulator, error) => {
                if (error?.message) accumulator.push(error.message);
                return accumulator;
              },
              [],
            );

            handleShowError(errorMessages.join("; "));
          }
        },
      });
    } catch (error) {
      handleShowError("Error removing tag");
    }
  };

  const handleSetTags = (tagId: TagId) => {
    setTagId(tagId);
  };

  useEffect(() => {
    const tag = getTagById(tagId);

    if (tag?.node?.schema?.isRemovable === false) {
      setIsTagRemovable(false);
    } else {
      setIsTagRemovable(true);
    }
  }, [tagId, getTagById]);

  return (
    <>
      <Button
        id={ELEMENTS_IDS.ANIMALS_ADD_TO_GROUP_BUTTON}
        caption="Remove tag"
        onClick={handleModalToggle}
        data-testid="remove-tag-button"
      />
      <Modal
        showCloseButton
        active={showModal}
        handleClose={handleModalToggle}
        title="Remove tag"
        subTitle={`Please select a tag to remove for these ${animalIds.length} animals. This will remove the tag from all of the selected animals.`}
        actions={{
          secondary: {
            onClick: handleCancelClick,
            caption: "Cancel",
            "data-testid": "add-tag-cancel-btn",
          },
          primary: {
            onClick: handleConfirmClick,
            caption: "Confirm",
            requesting: loading,
            disabled: loading || !isTagRemovable || !tagId,
            "data-testid": "add-tag-confirm-btn",
          },
        }}
      >
        <TagSelect value={tagId} animalIds={animalIds} onSelect={handleSetTags} removing />
        {!isTagRemovable ? (
          <aside className={styles.error}>
            <Text smallest className={styles.errorText}>
              This tag can only be removed by Breedr.
            </Text>
            <Spacer baselineHeight={1} />
            <Text className={styles.errorText} smallest>
              Please contact customer support to have the tag removed.
            </Text>
          </aside>
        ) : null}
      </Modal>
    </>
  );
};
