import getSymbolFromCurrency from "currency-symbol-map";
import { GetBusinessUnit_businessUnit_regulatorySystem } from "api/BusinessUnits/types/GetBusinessUnit";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";
import { getCurrencyCode, getWeightUnitName } from "helpers/general";
import { useStateSpecies } from "hooks";
import { useGetBusinessUnitQuery, BusinessUnit } from "generated/graphql";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";

export enum CountriesCodes {
  GB = "gb",
  US = "us",
  AU = "au",
}

export const useGetCurrentBusinessUnit = () => {
  const { activeSpecies } = useStateSpecies();
  const businessUnit = getBUFromLocalStorage();
  const { data, refetch } = useGetBusinessUnitQuery({
    variables: {
      businessUnit,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const currentBU = data?.businessUnit || ({} as BusinessUnit);
  //@ts-expect-error
  const weightUnits: string = getWeightUnitName(currentBU?.unitsOfMeasurement || []);
  const currencyCodeISO: string = getCurrencyCode(currentBU?.currency?.code);
  const currencySymbol: string = getSymbolFromCurrency(currencyCodeISO) || "£";
  const countryCode: string = data?.businessUnit?.country?.code?.toLowerCase() || CountriesCodes.GB;
  const countryIsoCode = data?.businessUnit?.country?.isoAlpha2?.toLowerCase() || "gb";
  const countryIsoCodeUpperCase = data?.businessUnit?.country?.isoAlpha2 || CountryISOCode.US;
  const animalType = activeSpecies || currentBU?.farmAnimalTypes?.find((item) => item.slug === "cattle") || null;
  const regulator = data?.businessUnit?.regulatorySystem as GetBusinessUnit_businessUnit_regulatorySystem | null;
  const identifierPreferencesActiveIds = data?.businessUnit?.identifierPreferencesActiveIds || [];
  const identifierPreferencesPrimaryId = data?.businessUnit?.identifierPreferencesPrimaryId || null;
  const identifierPreferencesSecondaryId = data?.businessUnit?.identifierPreferencesSecondaryId || null;

  return {
    ...currentBU,
    weightUnits,
    regulator,
    currencyCodeISO,
    currencySymbol,
    countryCode,
    animalType,
    countryIsoCode,
    identifierPreferencesActiveIds,
    identifierPreferencesPrimaryId,
    identifierPreferencesSecondaryId,
    countryIsoCodeUpperCase,
    refetch,
  };
};
