import { useQuery } from "@apollo/client";

import { getBUFromLocalStorage } from "helpers/storage";
import { GET_FARM_SYSTEMS } from "api/Settings/queries";
import { GetFarmSystems, GetFarmSystems_farmSystems, GetFarmSystemsVariables } from "api/Settings/types/GetFarmSystems";

export const useGetFarmSystems = () => {
  const { data, ...rest } = useQuery<GetFarmSystems, GetFarmSystemsVariables>(GET_FARM_SYSTEMS, {
    variables: {
      farm: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const farmSystems = (data?.farmSystems || []) as GetFarmSystems_farmSystems[];

  return {
    data: farmSystems,
    ...rest,
  };
};
