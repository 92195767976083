import { GroupCategory } from "generated/graphql";

export const EDIT_ANIMALS_IN_GROUP_TYPES = {
  ADD_TO_GROUP: "add_to_group",
};

export const GROUP_CATEGORY_OPTIONS = [
  {
    label: "Breeding stock",
    value: GroupCategory.BreedingStock,
    key: "1",
  },
  {
    label: "Youngstock",
    value: GroupCategory.Youngstock,
    key: "2",
  },
  {
    label: "Culls",
    value: GroupCategory.Culls,
    key: "3",
  },
  {
    label: "Pets",
    value: GroupCategory.Pets,
    key: "4",
  },
  {
    label: "Terminal",
    value: GroupCategory.Terminal,
    key: "5",
  },
  {
    label: "Dairy",
    value: GroupCategory.Dairy,
    key: "6",
  },
];

export const BATCH_GENDER_OPTIONS = [
  {
    label: "Male and Female",
    value: "MALE_AND_FEMALE",
  },
  {
    label: "Male",
    value: "MALE",
  },
  {
    label: "Female",
    value: "FEMALE",
  },
];
