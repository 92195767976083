import { VFC } from "react";
// API
import { MyLotQuery } from "generated/graphql";
// Utils
import { TABLE_IDS } from "constants/Interface";
// Components
import { Spacer, Flex, TablePaginationTarget, Title, Text } from "components";
import { AnimalsTable } from "components/MyLivestock";
// Styles
import tablePaginationStyles from "components/Common/Table/TablePagination/TablePagination.module.scss";

interface SellerListingAnimalsProps {
  animals: NonNullable<MyLotQuery["myLot"]>["animals"];
  listingId?: number | null;
}

export const SellerListingAnimals: VFC<SellerListingAnimalsProps> = ({ animals, listingId }) => {
  const hasAnimals = animals && !!animals.length;

  return (
    <>
      <Flex container>
        <Flex
          className={tablePaginationStyles.table_pagination_target}
          container
          containerAlignItems="flex-end"
          containerJustifyContent="space-between"
          item
          xs="fill"
        >
          <Flex item itemGutter>
            <Title tertiary>Animals in the listing</Title>
          </Flex>

          {hasAnimals ? (
            <Flex item itemGutter>
              <TablePaginationTarget tableId={TABLE_IDS.LISTING_ANIMALS} />
            </Flex>
          ) : null}
        </Flex>

        <Spacer baselineHeight={2} />

        {hasAnimals ? (
          <Flex item itemGutter xs="fill">
            <AnimalsTable
              // @ts-ignore
              animals={animals}
              fromPage={`listing-${listingId}`}
              tableId={TABLE_IDS.LISTING_ANIMALS}
              isShowQuickView
            />
          </Flex>
        ) : (
          <Text>No livestock</Text>
        )}
      </Flex>
    </>
  );
};
