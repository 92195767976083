import React, { useState, useEffect, useCallback, useMemo } from "react";
// Libraries
import { useLazyQuery } from "@apollo/client";
import debounce from "lodash/debounce";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";
import { SEARCH_PREFERRED_BUSINESS_UNITS } from "api/BusinessUnits/queries";
import {
  SearchPreferredBusinessUnits,
  SearchPreferredBusinessUnitsVariables,
  SearchPreferredBusinessUnits_searchBusinessUnits,
} from "api/BusinessUnits/types/SearchPreferredBusinessUnits";
import { GetPreferredBusinessUnits_preferredBusinessUnits } from "api/BusinessUnits/types/GetPreferredBusinessUnits";
import { GetDistributionListById_distributionList_suppliers } from "trading/api/types/GetDistributionListById";
//Components
import InputSelect from "components/Common/InputSelect";

type PreferredSuppliersSelectProps = {
  includePreferredSuppliers?: boolean;
  enableIndividualSharing?: boolean;
  preferredBusinessUnits: (GetPreferredBusinessUnits_preferredBusinessUnits | null)[];
  setSuppliers: (value: SearchPreferredBusinessUnits_searchBusinessUnits[] | null) => void;
  showModal?: boolean;
  suppliers:
    | SearchPreferredBusinessUnits_searchBusinessUnits[]
    | (GetDistributionListById_distributionList_suppliers | null)[]
    | null;
};

const getOptionLabel = (option: SearchPreferredBusinessUnits_searchBusinessUnits): string => {
  if (option) {
    const { name, location } = option;
    const { city, unitName } = { ...location };

    let label = name;
    if (unitName) {
      label += `: ${unitName}`;
    }
    if (city) {
      label += `, ${city}`;
    }

    return label as string;
  }

  return "";
};

const getOptionValue = (option: SearchPreferredBusinessUnits_searchBusinessUnits): string => {
  if (option.id) {
    return `${option.id}`;
  }

  return "";
};

export const PreferredSuppliersSelect: React.FC<PreferredSuppliersSelectProps> = ({
  includePreferredSuppliers,
  enableIndividualSharing,
  preferredBusinessUnits,
  setSuppliers,
  showModal,
  suppliers,
}) => {
  const [searchInput, changeSearchInput] = useState<string>("");
  const [searchResults, setSearchResults] = useState<
    (SearchPreferredBusinessUnits_searchBusinessUnits | null)[] | null | undefined
  >([]);
  const [searchBU, { loading: searchBuLoading, data }] = useLazyQuery<
    SearchPreferredBusinessUnits,
    SearchPreferredBusinessUnitsVariables
  >(SEARCH_PREFERRED_BUSINESS_UNITS);

  const currentBU = Number(getBUFromLocalStorage());

  const excludeBusinessUnitIds: number[] = useMemo(
    () =>
      preferredBusinessUnits.reduce(
        (accumulator, currentValue) => {
          const { supplier, buyer } = { ...currentValue };

          if (supplier && buyer) {
            if (+supplier.id !== currentBU) {
              return [...accumulator, +supplier.id];
            }

            // TODO: Temporary comment, check and remove in future
            // if (+buyer.id !== currentBU) {
            //   return [...accumulator, +buyer.id];
            // }
          }

          return accumulator;
        },
        [currentBU] as number[],
      ),
    [currentBU, preferredBusinessUnits],
  );

  const delayedSearch = useCallback(
    debounce((query: string) => {
      const searchBUVariables = {
        query,
        roles: ["Admin"],
      };

      searchBU({
        variables: Object.assign(
          searchBUVariables,
          includePreferredSuppliers
            ? false
            : {
                excludeBusinessUnitIds,
              },
          enableIndividualSharing
            ? {
                preferredSuppliersBuyerId: currentBU,
              }
            : {},
        ),
      });
    }, 500),
    [excludeBusinessUnitIds],
  );

  useEffect(() => {
    setSearchResults(data?.searchBusinessUnits);
  }, [data]);

  useEffect(() => {
    if (searchInput.length >= 3) {
      delayedSearch(searchInput);
    } else {
      setSearchResults([]);
    }
  }, [searchInput, delayedSearch]);

  useEffect(() => {
    if (!showModal) {
      setSuppliers([]);
      changeSearchInput("");
    }
  }, [showModal]);

  const options: (SearchPreferredBusinessUnits_searchBusinessUnits | null)[] = searchResults || [];

  const handleSearchInputChange = (value: string): void => {
    changeSearchInput(value);
  };

  const handleSelectChange = (selectedValue: SearchPreferredBusinessUnits_searchBusinessUnits[]): void => {
    setSuppliers(selectedValue);
  };

  return (
    <InputSelect
      options={options}
      inputValue={searchInput}
      placeholder="Search supplier..."
      onInputChange={handleSearchInputChange}
      onOptionSelect={handleSelectChange}
      isMulti
      isLoading={searchBuLoading}
      isClearable={true}
      backspaceRemovesValue={true}
      value={suppliers}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
    />
  );
};
