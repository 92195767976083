import React from "react";
import { Input } from "components";
import { cn } from "utils/misc";

type Adornment = {
  labelFor: string;
  label: string;
};

export type BaseInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean;
  adornment?: Adornment;
  adornmentLeft?: Adornment;
  validation?: RegExp;
  "data-testid"?: string | number;
};

export const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(
  ({ adornment, adornmentLeft, hasError, validation, onChange, className, ...props }, ref) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const value = e.target.value;

      if (validation && value !== "") {
        const isValidValue = validation.test(value);

        if (isValidValue) {
          onChange && onChange(e);
        }
      } else {
        onChange && onChange(e);
      }
    };

    return (
      <Input hasError={hasError} adornment={adornment} adornmentLeft={adornmentLeft}>
        <input
          className={cn(
            "w-full bg-transparent border-none h-8 py-0 px-3 text-black text-xs focus:outline-none disabled:text-gray-400 disabled:hover:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          ref={ref}
          id={props.name}
          name={props.name}
          onChange={handleInputChange}
          {...props}
        />
      </Input>
    );
  },
);

BaseInput.displayName = "BaseInput";
