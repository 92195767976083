// @ts-nocheck
// Libraries
import compose from "recompose/compose";
import { DateTime } from "luxon";
import withProps from "recompose/withProps";
import pure from "recompose/pure";
import _ from "lodash";
import { getAgeByDaysNumber } from "helpers/myLivestock";
// types
import { GetPerformanceChartData_performanceChartData_references } from "api/MyLivestock/Animal/types/GetPerformanceChartData";
// Custom
import AnimalChart from "./AnimalChart";
// Methods
const chartColours = {
  brand: {
    yellow: "#F2C434",
    blue: "#23354B",
  },
  low: {
    stroke: "#751010",
    // eslint-disable-next-line
    fill_highlight: "rgba(255, 128, 147, 0.8)",
    fill: "rgba(255, 128, 147, 0.45)",
  },
  avg: {
    stroke: "#23354B",
    // eslint-disable-next-line
    fill_highlight: "rgba(106, 173, 255, 0.8)",
    fill: "rgba(106, 173, 255, 0.45)",
  },
  high: {
    stroke: "#116A2C",
    // eslint-disable-next-line
    fill_highlight: "rgba(121, 249, 159, 0.8)",
    fill: "rgba(121, 249, 159, 0.45)",
  },
  reference: "#F0F0F0",
  filler: "rgba(230, 71, 71, 0.12)",
  growthLine: "#04a2e8",
};

const convertTimestamps = (data): Record<string, unknown> => {
  if (!data) {
    return undefined;
  }
  return data.map((point) => ({
    ...point,
    timestamp: Number(point.timestamp),
  }));
};

const getPointBand = (point): string => {
  if (point.value < point.low) {
    return "low";
  }
  if (point.value < point.avg) {
    return "avg";
  }
  return point.value < point.high ? "high" : "avg";
};

const generateDLWG = (measurements, targetWeights): Record<string, unknown> => {
  const dob = DateTime.fromMillis(measurements[0].timestamp).minus({ days: measurements[0].age });
  const isGroupTargetVisible = targetWeights && targetWeights.length > 0;

  return measurements.map((measurement) => {
    const age = getAgeByDaysNumber(dob, measurement.timestamp, measurement.age);

    return {
      ...measurement,
      isGroupTargetVisible,
      age,
    };
  });
};

const generateYAxisTicks = (highBand, measurements, predictions): number[] => {
  let highestValue = 0;

  if (typeof highBand !== "undefined") {
    highBand.forEach(({ value }) => {
      highestValue = value[1] > highestValue ? value[1] : highestValue;
    });
  }

  measurements.forEach(({ value }) => {
    highestValue = value > highestValue ? value : highestValue;
  });

  if (typeof predictions !== "undefined") {
    predictions.forEach(({ value }) => {
      highestValue = value > highestValue ? value : highestValue;
    });
  }

  const interval = Math.ceil(highestValue / 12 / 10) * 10;
  const yAxisTicks: number[] = [];

  let i = interval;

  do {
    yAxisTicks.push(i);
    i += interval;
  } while (i <= highestValue);

  yAxisTicks.push(i);
  return yAxisTicks;
};

const filterReferencesByContext = (
  references = [],
  filters = [],
): GetPerformanceChartData_performanceChartData_references =>
  references.filter(({ context = [] }) => context.some((item) => filters.includes(item)));

export default compose(
  withProps(({ data, filters = [] }) => {
    const lowBand = convertTimestamps(_.get(data, "low", undefined));
    const avgBand = convertTimestamps(_.get(data, "avg", undefined));
    const highBand = convertTimestamps(_.get(data, "high", undefined));
    const targetWeights = convertTimestamps(_.get(data, "targetWeights", undefined));
    const measurements = generateDLWG(convertTimestamps(_.get(data, "measurements", undefined)), targetWeights);
    const performance = getPointBand(measurements[measurements.length - 1]);
    const predictions =
      data.predictions && filters.includes("PREDICTIONS")
        ? convertTimestamps(_.get(data, "predictions", undefined))
        : [];
    const measurementsArea = !predictions
      ? measurements.map((measurement) => ({
          ...measurement,
          value: [0, measurement.value],
        }))
      : undefined;

    const references = convertTimestamps(_.get(data, "references", undefined));
    const filteredReferences = filterReferencesByContext(references, filters);
    const dates = convertTimestamps(_.get(data, "dates", []));
    const xAxisTicks = dates.reduce((accumulator, currentValue, index) => {
      if (index % 2 === 0 || index === dates.length - 1) {
        accumulator.push(currentValue.timestamp);
      }
      return accumulator;
    }, []);
    const yAxisTicks = generateYAxisTicks(highBand, measurements, predictions);

    return {
      dates,
      lowBand,
      avgBand,
      highBand,
      measurements,
      measurementsArea,
      performance,
      predictions,
      references: filteredReferences,
      xAxisTicks,
      yAxisTicks,
      chartColours,
      targetWeights,
    };
  }),
  pure,
)(AnimalChart);
