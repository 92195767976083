import { Field } from "components/Common/Field";
import { FieldValues, Path, useFormContext } from "react-hook-form";

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  label: string;
  children: React.ReactNode;
};

export const FormRadioGroup = <TFieldValues extends FieldValues>({ name, label, children }: Props<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  if (!methods) {
    throw new Error("FormRadioGroup must be used inside a <Form /> or <FormProvider />");
  }

  const errorMessage = methods?.formState.errors[name]?.message;

  return (
    <fieldset>
      {/* TODO: This should be a legend instead of a Field */}
      <Field error={(errorMessage as string) || ""} label={label}>
        {children}
      </Field>
    </fieldset>
  );
};
