// @ts-nocheck
// React
import React, { useState, useRef } from "react";
// Libraries
import cn from "classnames";
import { CSSTransition } from "react-transition-group";
// Utils
import { ANIMATION_DURATION } from "constants/Interface";
// Components
import { Icon } from "components";
// Resources
import styles from "./ManageItems.module.scss";

/**
 * Shared types
 */
interface ManageItemShared {
  onDelete: (id, callback) => Promise<void> | void;
}
interface ManageItemSharedRename {
  onRename: (id, name, callback) => Promise<void> | void;
}
interface ManageItemSharedEdit {
  onEdit: (id) => Promise<void> | void;
}
type ManageItemSharedProps = ManageItemShared & (ManageItemSharedRename | ManageItemSharedEdit);

/**
 * Private component to render each item
 */
interface ManageItemProps {
  id: number | string;
  name: string;
}
const ManageItem: React.FC<ManageItemSharedProps & ManageItemProps> = ({
  onDelete,
  onEdit,
  onRename,
  id,
  name: originalName,
}) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [name, setName] = useState(originalName);
  const nameRef = useRef(null);
  return (
    <li className={styles.manage_item}>
      <CSSTransition
        timeout={ANIMATION_DURATION * 1.5}
        in={!confirmVisible}
        classNames={{
          appear: "",
          appearActive: "",
          enter: styles["manage_item__details--enter"],
          enterActive: styles["manage_item__details--enter_active"],
          enterDone: styles["manage_item__details--enter_done"],
          exit: styles["manage_item__details--exit"],
          exitActive: styles["manage_item__details--exit_active"],
          exitDone: styles["manage_item__details--exit_done"],
        }}
      >
        <div className={cn(styles.manage_item__details, styles["manage_item__details--enter_done"])}>
          <div
            className={cn(styles.manage_item__title, {
              [styles["manage_item__title--edit"]]: editVisible,
            })}
          >
            <input
              type="text"
              className={styles.manage_item__title_input}
              value={name || ""}
              readOnly={!editVisible}
              onChange={(event): void => setName(event.target.value)}
              ref={nameRef}
            />
            <button
              type="button"
              className={cn(styles.manage_item__title_action, styles["manage_item__title_action--edit"])}
              onClick={
                onEdit
                  ? (): void => {
                      onEdit(id);
                    }
                  : (): void => {
                      setEditVisible(true);

                      if (nameRef && nameRef.current) {
                        nameRef.current.select();
                      }
                    }
              }
            >
              <Icon name="edit" size="tiny" />
            </button>
            <button
              type="button"
              className={cn(styles.manage_item__title_action, styles["manage_item__title_action--edit_cancel"])}
              onClick={(): void => {
                setName(originalName);
                setEditVisible(false);
              }}
            >
              <Icon name="times" size="tiny" />
            </button>
            <button
              type="button"
              className={cn(styles.manage_item__title_action, styles["manage_item__title_action--edit_confirm"])}
              onClick={(): void => {
                if (name !== originalName && name.length > 0) {
                  onRename(id, name, setEditVisible);
                }
              }}
            >
              <Icon name="check" size="tiny" />
            </button>
          </div>
          <button type="button" className={styles.manage_item__action} onClick={(): void => setConfirmVisible(true)}>
            <Icon name="trash" size="tiny" />
          </button>
        </div>
      </CSSTransition>
      <div
        className={cn(styles.manage_item__confirmation, {
          [styles["manage_item__confirmation--active"]]: confirmVisible,
        })}
      >
        <p className={styles.manage_item__prompt}>Are you sure you want to delete {`"${name}"`}?</p>
        <button
          type="button"
          className={styles.manage_item__prompt_action}
          onClick={(): void => setConfirmVisible(false)}
        >
          cancel
        </button>
        <button
          type="button"
          className={cn(styles.manage_item__prompt_action, styles["manage_item__prompt_action--warning"])}
          onClick={(): void => onDelete(id, setConfirmVisible)}
        >
          delete
        </button>
      </div>
    </li>
  );
};
ManageItem.displayName = "ManageItem";

/**
 * Exported component to handle the full list
 */
interface ManageItemsProps {
  items: ManageItemProps[];
}
export const ManageItems: React.FC<ManageItemSharedProps & ManageItemsProps> = ({
  onDelete,
  onEdit,
  onRename,
  items,
}) => (
  <ul className={styles.manage_items}>
    {items.map((item) => (
      <ManageItem key={item.id} onDelete={onDelete} onEdit={onEdit} onRename={onRename} {...item} />
    ))}
  </ul>
);
ManageItems.displayName = "ManageItems";
