import { createContext, FC, useMemo, useRef, useState } from "react";
// Utils
import { useOutsideClick } from "hooks";
// Subcomponents
import { DropdownMenuAction } from "./DropdownMenuAction";
import { DropdownMenuContent } from "./DropdownMenuContent";
import { DropdownMenuLink } from "./DropdownMenuLink";
import { DropdownMenuSeparator } from "./DropdownMenuSeparator";
import { DropdownMenuTrigger } from "./DropdownMenuTrigger";
// Resources
import styles from "./DropdownMenu.module.scss";

interface DropdownMenuContextValue {
  handleClose?: () => void;
  handleToggle?: () => void;
  isOpen?: boolean;
}
export const DropdownMenuContext = createContext<DropdownMenuContextValue>({});

interface DropdownMenuProps {
  defaultOpen?: boolean;
  children?: React.ReactNode;
}

interface DropdownMenuSubcomponents {
  Action: typeof DropdownMenuAction;
  Content: typeof DropdownMenuContent;
  Link: typeof DropdownMenuLink;
  Separator: typeof DropdownMenuSeparator;
  Trigger: typeof DropdownMenuTrigger;
}

export const DropdownMenu: FC<DropdownMenuProps> & DropdownMenuSubcomponents = ({ children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const handleClose = (): void => setIsOpen(false);
  const handleToggle = (): void => setIsOpen(!isOpen);

  const ref = useRef(null);
  useOutsideClick(ref, (): void => setIsOpen(false));

  const dropdownMenuContextValue = useMemo(
    () => ({
      handleClose,
      handleToggle,
      isOpen,
    }),
    [isOpen],
  );

  return (
    <DropdownMenuContext.Provider value={dropdownMenuContextValue}>
      <div className={styles.dropdown_menu} ref={ref}>
        {children}
      </div>
    </DropdownMenuContext.Provider>
  );
};
DropdownMenu.Action = DropdownMenuAction;
DropdownMenu.Content = DropdownMenuContent;
DropdownMenu.Link = DropdownMenuLink;
DropdownMenu.Separator = DropdownMenuSeparator;
DropdownMenu.Trigger = DropdownMenuTrigger;
