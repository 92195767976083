import React, { CSSProperties } from "react";
import Select, { components } from "react-select";
import SVG from "react-inlinesvg";

// Images
import dropdownIndicatorIcon from "assets/img/Auth/dropdown-indicator.svg";

// Constants
import { FARM_TYPES } from "constants/FarmTypes";

interface Props {
  onChange: <T>(field: string, value: T) => void;
  name: string;
  error?: string;
}

interface SelectOption {
  value: string;
  label: string;
}

const DropdownIndicator: React.FC = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SVG src={dropdownIndicatorIcon} />
    </components.DropdownIndicator>
  );
};

const selectStyles = {
  container: (provided): CSSProperties => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, state): CSSProperties => ({
    ...provided,
    borderRadius: "2px",
    borderWidth: "2px",
    borderColor: state.selectProps.error ? "#FF8093" : state.isFocused ? "#6AADFF" : "#FFFFFF",
    minHeight: 40,
    boxShadow: "0 0 0 0",
    ":hover": {
      ...provided[":hover"],
      borderColor: state.selectProps.error ? "#FF8093" : state.isFocused ? "#6AADFF" : "#FFFFFF",
    },
  }),
  indicatorSeparator: (): CSSProperties => ({
    display: "none",
  }),
  option: (provided, state): CSSProperties => ({
    ...provided,
    backgroundColor: state.isSelected ? "#6AADFF" : provided.backgroundColor,
  }),
  singleValue: (provided): CSSProperties => ({
    ...provided,
    color: "#000000",
    fontWeight: 500,
    fontSize: "1.125rem",
    lineHeight: "1.375rem",
  }),
};

const options = Object.keys(FARM_TYPES).map((type) => ({ value: type, label: FARM_TYPES[type] }));

export const BusinessTypeField: React.FC<Props> = ({ onChange, name, error }): JSX.Element => {
  const handleSelectChange = (selectedOptions: SelectOption[]): void => {
    const joinOptions = selectedOptions
      .reduce((str: string[], option: SelectOption): string[] => {
        return [...str, option.value];
      }, [])
      .join(",");

    onChange(name, joinOptions);
  };

  return (
    <Select
      components={{ DropdownIndicator }}
      options={options}
      onChange={handleSelectChange}
      styles={selectStyles}
      name={name}
      error={error}
      isMulti={true}
      placeholder="Please select all that apply."
      classNamePrefix="businessSpecialization"
    />
  );
};
