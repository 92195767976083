export const WANT_STATUSES = {
  CREATED: "CREATED",
  OFFER_ACCEPTED: "OFFER_ACCEPTED",
  MOVED_ANIMALS: "MOVED_ANIMALS",
  AWAITING_ANIMALS: "AWAITING_ANIMALS",
  ASSIGNED_ANIMALS: "ASSIGNED_ANIMALS",
  AWAITING_GRADING: "AWAITING_GRADING",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
  PENDING: "PENDING",
  FULL_FILLED: "FULL_FILLED",
  DECLINED: "DECLINED",
  CLOSED: "CLOSED",
  COMPLETED: "COMPLETED",
  SOLD: "SOLD",
  CANCELED: "CANCELED",
};

export const OFFER_STATUSES = {
  CREATED: "CREATED",
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  CANCELED: "CANCELED",
};

export const LOT_STATUSES = {
  ASSIGNED_ANIMALS: "ASSIGNED_ANIMALS",
  AWAITING_ANIMALS: "AWAITING_ANIMALS",
  AWAITING_GRADING: "AWAITING_GRADING",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
  AWAITING_ACCEPTANCE: "AWAITING_ACCEPTANCE",
  BUYER_MADE_PAYMENT_TO_BREEDR: "BUYER_MADE_PAYMENT_TO_BREEDR",
  BREEDR_RECEIVED_PAYMENT: "BREEDR_RECEIVED_PAYMENT",
  SELLER_SENT_ANIMALS: "SELLER_SENT_ANIMALS",
  BUYER_RECEIVED_ANIMALS: "BUYER_RECEIVED_ANIMALS",
  BREEDR_MADE_PAYMENT: "BREEDR_MADE_PAYMENT",
  CANCELED: "CANCELED",
  CLOSED: "CLOSED",
  COMPLETED: "COMPLETED",
  CREATED: "CREATED",
  FULL_FILLED: "FULL_FILLED",
  MOVED_ANIMALS: "MOVED_ANIMALS",
  SALE_AGREED: "SALE_AGREED",
  SOLD: "SOLD",
};

export const BU_TYPES = {
  farmer: "farmer",
  processor: "buyer",
};

export const TRADING_TYPES = {
  LTL: "live_to_live",
  LTD: "live_to_dead",
};

export const HAULAGE_TYPES = {
  byBuyer: "PAID_BY_BUYER",
  bySeller: "PAID_BY_SELLER",
};

export const HAULAGE = [
  {
    value: HAULAGE_TYPES.byBuyer,
    label: "Buyer",
  },
  {
    value: HAULAGE_TYPES.bySeller,
    label: "Seller",
  },
];

export const CONFIRMATION_MODALS_TYPES = {
  COMMIT_ANIMALS: "commit_animals",
  REJECT_OFFER: "reject_offer",
};

export const TRADING_OPTIONS = [
  {
    key: "SELLING",
    label: "Selling",
    value: "SELLING",
  },
  {
    key: "BUYING",
    label: "Buying",
    value: "BUYING",
  },
];

export const TRADING_OPTIONS_VALUES = {
  SELLING: "SELLING",
  BUYING: "BUYING",
};

export enum OfferTypes {
  HEAD = "HEAD",
  KG = "KG",
  TOTAL = "TOTAL",
}

const phone = "0330 043 6327";

export const FALLBACK_MESSAGE =
  `Not all of the animals on the listing have an estimated weight for the selected collection date, ` +
  `so the last known estimated weight before the specified date has been used to calculate the approximate ` +
  `price of these animals.  You can proceed on this basis, or recalculate using a different date. ` +
  `Call the trading team on ` +
  phone +
  ` for more options.`;

export const NO_WEIGHT_MESSAGE =
  `There is not enough data to estimate the cost of these animals on a price per kg basis.  ` +
  `Please make a per head offer or call the trading team on ${phone} for more options.`;
