import { DeliveryPartnerAddressSortingEnum, useDeliveryPartnerAddressesQuery } from "generated/graphql";
import React from "react";
import { removeNothings } from "helpers/general/all";
import { getBusinessUnitIdFromLocalStorage } from "helpers/storage";
import { InputSelectOption } from "components/Common/Field/Select";
import { uniqBy } from "lodash";

export const useDeliveryPartners = () => {
  const businessUnitId = getBusinessUnitIdFromLocalStorage();

  const { data: deliveryPartnerData } = useDeliveryPartnerAddressesQuery({
    variables: { businessUnit: businessUnitId, orderBy: DeliveryPartnerAddressSortingEnum.AddressNameAsc },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const partnerAddresses = removeNothings(
    uniqBy(
      (deliveryPartnerData?.deliveryPartnerAddresses?.edges || []).map((edge) => {
        return edge?.node;
      }),
      (address) => address?.name,
    ),
  );

  const getPartnerAddress = (addressId: string | undefined) => {
    return partnerAddresses.find((address) => address.id === addressId);
  };

  const partnerAddressOptions: InputSelectOption[] = React.useMemo(() => {
    return partnerAddresses.map((address) => {
      return {
        value: address.id,
        label: address.name,
        key: address.id,
      };
    });
  }, [partnerAddresses]);

  return { partnerAddressOptions, partnerAddresses, getPartnerAddress };
};
