import { FC } from "react";
import { useHistory } from "react-router-dom";
import { GetActivityTypes_activityTypes } from "api/MyLivestock/Activity/types/GetActivityTypes";
import { useGetActivityTypes, useHasFeature, useStateSpecies } from "hooks";
import { ActivityTemplateIcon } from "components/Activity";
import { Button, Flex, Icon, Spacer } from "components";
import { WizardOptions } from "import/components/WizardOptions";
import { BatchUploadFormat } from "hooks/useGetBatchUploadFormats";

interface Props {
  onSelectActivityType: (activityType: GetActivityTypes_activityTypes) => void;
  onSelectAnimalImport: (batchUploadFormat: BatchUploadFormat) => void;
  batchUploadFormats: BatchUploadFormat[];
}

export const ImportWizardChooseType: FC<Props> = ({
  onSelectActivityType,
  onSelectAnimalImport,
  batchUploadFormats,
}) => {
  const { data: activityTypes } = useGetActivityTypes();
  const isOshkosh = useHasFeature("OSHKOSH");

  const filteredActivityTypes = activityTypes.filter(
    (activityType) =>
      activityType.activityTemplatesCount ||
      batchUploadFormats.filter((batchUploadFormat) => batchUploadFormat.activityType?.id === activityType.id).length >
        0,
  );

  const { activeSpecies } = useStateSpecies();

  const visibilityRules: Record<string, boolean> = {
    "animal-cattle-birthremote": isOshkosh,
    "animal-sheep-birthremote": isOshkosh,
    "animal-pigs-birthremote": isOshkosh,
  };

  const animalUploadFormats = batchUploadFormats.filter((batchUploadFormat) => {
    const isUploadFormatVisible =
      visibilityRules[batchUploadFormat.slug] !== undefined ? visibilityRules[batchUploadFormat.slug] : true;

    return (
      ["other", "animal"].includes(batchUploadFormat.categorySlug?.toLowerCase() || "") &&
      batchUploadFormat.animalTypes.some((animalType) => animalType?.id && animalType.id === activeSpecies?.id) &&
      batchUploadFormat.activityType === null &&
      isUploadFormatVisible
    );
  });

  const history = useHistory();
  const handleClickBack = () => history.goBack();

  return (
    <>
      <WizardOptions>
        {filteredActivityTypes.map((activityType) => {
          const handleClick = () => onSelectActivityType(activityType);

          return (
            <WizardOptions.Option key={activityType.id} name={activityType.name} onClick={handleClick}>
              {activityType.image ? (
                <ActivityTemplateIcon activityTemplateName={activityType.name} src={activityType.image} />
              ) : null}
            </WizardOptions.Option>
          );
        })}

        {activeSpecies ? (
          <>
            <WizardOptions.Separator>or</WizardOptions.Separator>
            {animalUploadFormats.map((animalUploadFormat) => {
              const handleClick = () => onSelectAnimalImport(animalUploadFormat);
              return (
                <WizardOptions.Option
                  key={animalUploadFormat.slug}
                  name={String(animalUploadFormat.name)}
                  onClick={handleClick}
                >
                  {activeSpecies?.image ? (
                    <Icon circled colourOverride={activeSpecies.colour} size="large" src={activeSpecies.image} />
                  ) : null}
                </WizardOptions.Option>
              );
            })}
          </>
        ) : null}
      </WizardOptions>

      <Spacer baselineHeight={4} />

      <Flex container containerJustifyContent="space-between">
        <Button caption="Back" colour="grey" onClick={handleClickBack} variant="hollow" />
      </Flex>
    </>
  );
};
