// Libraries
import { useMediaQuery } from "react-responsive";
// Constants
import { V_BREAKPOINT_S, V_BREAKPOINT_M } from "constants/Interface";

interface Breakpoints {
  isVBreakpointL: boolean;
  isVBreakpointM: boolean;
  isVBreakpointS: boolean;
}

export const useVerticalBreakpoints = (): Breakpoints => ({
  isVBreakpointL: useMediaQuery({ minHeight: V_BREAKPOINT_M }),
  isVBreakpointM: useMediaQuery({ minHeight: V_BREAKPOINT_S + 1, maxHeight: V_BREAKPOINT_M }),
  isVBreakpointS: useMediaQuery({ maxHeight: V_BREAKPOINT_S }),
});
