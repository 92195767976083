import React from "react";

import pure from "recompose/pure";

import FormBuilder from "components/Common/FormBuilder";
import Input from "components/Common/Input";
import { Button, Flex, Spacer } from "components";
import { PasswordStrength } from "components/Settings";

import { handleEnterPress, handleErrorCheck } from "helpers/general";

import styles from "./slyles.module.css";
import { ELEMENTS_IDS } from "constants/ElementsIds";

interface Props {
  email: string;
  requesting: boolean;
  setPassword: (p: { password: string; confirmPassword: string }) => void;
  validationSchema: Record<string, unknown>;
  passwordError: string;
}

const SetPasswordForm: React.FC<Props> = ({ email, setPassword, validationSchema, passwordError, requesting }) => (
  <FormBuilder
    validateOnChange={false}
    initialValues={{
      password: "",
      confirmPassword: "",
    }}
    onSubmit={(props): void => setPassword(props)}
    validationSchema={validationSchema}
  >
    {({ values, errors, handleChange, handleSubmit, resetForm }): JSX.Element => (
      <>
        <Flex container>
          <Flex item itemGutter xs={12}>
            <span className={styles.emailLabel}>Your email</span>
          </Flex>
          <Flex item itemGutter xs={12}>
            <span className={styles.emailValue}>{email}</span>
          </Flex>
          <Spacer baselineHeight={2} />
          <Flex container>
            <Flex item itemGutter>
              <Input
                type="password"
                label="Set password"
                autoComplete="new-password"
                name="password"
                value={values.password}
                stylesClasses={styles.inputWrapper}
                errorTextStyle={styles.errorLabel}
                handleChange={handleChange}
                errorStatus={errors.password}
                id={ELEMENTS_IDS.SET_PASSWORD_FORM_SET_PASSWORD_INPUT}
                onKeyPress={(e): void => handleEnterPress(e, handleSubmit)}
                error={handleErrorCheck(errors.password)}
              />
            </Flex>
            <Flex container containerAlignContent="center">
              <PasswordStrength password={values.password} customStyles={styles.passwordSecureIndicator} />
            </Flex>
          </Flex>
          <Spacer baselineHeight={2} />
          <Flex container>
            <Flex item itemGutter>
              <Input
                type="password"
                label="Confirm password"
                autoComplete="new-password"
                name="confirmPassword"
                value={values.confirmPassword}
                stylesClasses={styles.inputWrapper}
                errorTextStyle={styles.errorLabel}
                handleChange={handleChange}
                id={ELEMENTS_IDS.SET_PASSWORD_FORM_CONFIRM_PASSWORD_INPUT}
                errorStatus={errors.confirmPassword}
                onKeyPress={(e): void => handleEnterPress(e, handleSubmit)}
                error={handleErrorCheck(errors.confirmPassword)}
              />
            </Flex>
          </Flex>
          {passwordError ? <p className={styles.error}>{passwordError}</p> : null}
          <Spacer baselineHeight={2} />
          <Flex item itemGutter>
            <div className={styles.buttonsHolder}>
              <div className={styles.buttonsHolderCol}>
                <Button
                  caption="Cancel"
                  colour="grey"
                  variant="hollow"
                  onClick={(): void => resetForm()}
                  id={ELEMENTS_IDS.SET_PASSWORD_FORM_CANCEL_BUTTON}
                />
              </div>
              <div className={styles.buttonsHolderCol}>
                <Button
                  disabled={requesting}
                  requesting={requesting}
                  caption="Save"
                  onClick={handleSubmit}
                  id={ELEMENTS_IDS.SET_PASSWORD_FORM_SAVE_BUTTON}
                />
              </div>
            </div>
          </Flex>
        </Flex>
      </>
    )}
  </FormBuilder>
);

export default pure(SetPasswordForm);
