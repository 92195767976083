// React
import React, { useEffect } from "react";
// libraries
import { Portal } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import cn from "classnames";
// Utils
import { ANIMATION_DURATION } from "constants/Interface";
// Components
import { Cols } from "components/Structure/Flex";
import { ButtonProps } from "components/Common/Button";
import { Button, Flex, Icon, Title } from "components";
// Resources
import styles from "./Modal.module.scss";

interface ModalProps {
  actions?: {
    primary: ButtonProps;
    secondary?: ButtonProps;
  };
  active?: boolean;
  buttonsLeftSide?: boolean;
  children?: React.ReactNode;
  handleClose?: () => void;
  isChildrenGutterManual?: boolean;
  offsetLeft?: boolean;
  showCloseButton?: boolean;
  size?: "small" | "medium" | "large";
  subTitle?: string;
  title?: string;
  testId?: string;
  backgroundColour?: string;
}

export const Modal: React.FC<ModalProps> = ({
  buttonsLeftSide = false,
  actions,
  active = false,
  children,
  handleClose,
  isChildrenGutterManual = false,
  offsetLeft = false,
  showCloseButton = false,
  size = "small",
  subTitle,
  title,
  testId = "modal",
  backgroundColour = "bg-white",
}) => {
  useEffect(() => {
    const onKeyUp = (event: KeyboardEvent): void => {
      if (event.keyCode === 27 && handleClose) {
        handleClose();
      }
    };

    if (active) {
      document.addEventListener("keyup", onKeyUp);
    }

    return (): void => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [active]);

  let resetCols: Cols = 4;

  if (size === "medium") {
    resetCols = 6;
  } else if (size === "large") {
    resetCols = 8;
  }

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    const target = event.target as HTMLElement;

    if (handleClose && target.classList?.contains(styles.modal)) {
      handleClose();
    }
  };

  return (
    <Portal>
      <CSSTransition
        appear={active}
        in={active}
        timeout={ANIMATION_DURATION * 1.5}
        classNames={{
          appear: styles["modal--enter"],
          appearActive: styles["modal--enter_active"],
          enter: styles["modal--enter"],
          enterActive: styles["modal--enter_active"],
          enterDone: styles["modal--enter_done"],
          exit: styles["modal--exit"],
          exitActive: styles["modal--exit_active"],
          exitDone: styles["modal--exit_done"],
        }}
        mountOnEnter
        unmountOnExit
      >
        <div
          className={cn(styles.modal, {
            [styles["modal--offset"]]: offsetLeft,
            [styles[`modal--size_${size}`]]: true,
          })}
          onClick={handleModalClick}
          data-testid={testId}
        >
          <div className={cn(styles.modal__inner, backgroundColour)}>
            {showCloseButton ? (
              <button type="button" className={styles.modal__close} onClick={handleClose}>
                <Icon colour="blue" name="times" size="medium" />
              </button>
            ) : null}

            {title || subTitle ? (
              <Flex className={styles.modal__header} container containerResetXS={resetCols}>
                <Flex item itemGutter xs="fill">
                  {title ? <Title secondary>{title}</Title> : null}
                  {subTitle ? <p className={styles.modal__subtitle}>{subTitle}</p> : null}
                </Flex>
              </Flex>
            ) : null}

            {children ? (
              <Flex className={styles.modal__content} container containerResetXS={resetCols}>
                <Flex item itemGrow itemGutter={!isChildrenGutterManual} xs="fill">
                  {children}
                </Flex>
              </Flex>
            ) : null}

            {actions ? (
              <Flex
                className={styles.modal__actions}
                container
                containerJustifyContent={buttonsLeftSide ? "flex-start" : "flex-end"}
                containerResetXS={resetCols}
              >
                <Flex item itemGutter>
                  {actions.secondary ? <Button colour="grey" variant="hollow" {...actions.secondary} /> : null}
                </Flex>

                <Flex item itemGutter>
                  {actions.primary ? <Button colour="yellow" variant="solid" {...actions.primary} /> : null}
                </Flex>
              </Flex>
            ) : /**
             * We're allowing all ButtonProps here so just in case
             * someone uses the colour or variant props we have to
             * specify the following values despite them being default:
             *
             * colour="yellow"
             * variant="solid"
             */
            null}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

Modal.displayName = "Modal";
