import React from "react";
// Libraries
import Intercom from "react-intercom";
// API
import { useGetCurrentBusinessUnit, useGetCurrentUserInfo } from "hooks";
import { useHubSpot } from "hooks/useHubSpot";

const IntercomComponent: React.FC = () => {
  const { data, loading } = useGetCurrentUserInfo();
  const { country } = useGetCurrentBusinessUnit();
  const { isHubSpotChatEnabled } = useHubSpot();
  const userInfo = data?.userInfo;
  return !loading && userInfo && !isHubSpotChatEnabled ? (
    <Intercom
      user_id={userInfo.email}
      country={country?.isoAlpha2 != null ? country.isoAlpha2 : "GB"}
      email={userInfo.email}
      name={`${userInfo.firstName} ${userInfo.lastName}`}
      user_hash={userInfo.intercomUserHash}
      appID={process.env.REACT_APP_INTERCOM_APP_ID || "on6boq3h"}
    />
  ) : null;
};

export default IntercomComponent;
