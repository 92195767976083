import { gql } from "@apollo/client";

export const GET_BREEDR_BANK_DETAILS = gql`
  query GetBreedrBankDetails {
    breedrBankDetails {
      accountNo
      accountName
      sortCode
    }
  }
`;
