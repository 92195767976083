import { SyntheticEvent, VFC } from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./TextButton.module.scss";

interface TextButtonProps {
  id?: string;
  caption?: string;
  className?: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void | Promise<void>;
  secondary?: boolean;
  warning?: boolean;
  type?: "submit" | "reset" | "button";
}

export const TextButton: VFC<TextButtonProps> = ({ caption, className, secondary, warning, ...props }) => (
  <button
    className={cn(
      styles.text_button,
      {
        [styles["text_button--primary"]]: !secondary,
        [styles["text_button--secondary"]]: secondary,
        [styles["text_button--warning"]]: warning,
      },
      className,
    )}
    {...props}
  >
    {caption}
  </button>
);
