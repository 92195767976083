// React
import React from "react";
import pure from "recompose/pure";
// Resources
import styles from "./SelectPill.module.scss";

interface SelectPillProps {
  caption: string;
}

const SelectPill = ({ caption }: SelectPillProps): JSX.Element => <span className={styles.select_pill}>{caption}</span>;

export default pure(SelectPill);
