// @ts-nocheck
// React
import React from "react";
// Components
import { Header, LoadingOverlay, PageHeader, PageContent, Flex, Spacer } from "components";
import { IdPreferencesForm } from "components/Settings/IdPreferencesForm";
// Utils
import { useGetAnimalIdPreferences } from "hooks/useGetAnimalIdPreferences";
// Resources
import styles from "./IdPreferences.module.scss";

interface IdentifierPrefrencesProps {
  toggleForm: () => void;
  showForm: boolean;
  editMode: boolean;
  error: string | null;
  showModal: boolean;
  showSystemForm: boolean;
  toggleSystemForm: () => void;
  handleCloseForm: () => void;
  removeDeliveryAddress: () => Promise<void>;
  handleCloseRemoveModal: () => void;
  handleOpenRemoveModal: (props: Record<string, unknown>) => void;
  handleSetEditData: (props: Record<string, unknown>) => void;
  editData: Record<string, unknown>;
}

const IdPreferences: React.FC<IdentifierPrefrencesProps> = () => {
  const animalIdPreferences = useGetAnimalIdPreferences();
  return (
    <>
      <PageHeader>
        <Header title="Animal IDs" subTitle="Manage your preferred animal IDs" />
      </PageHeader>

      <PageContent>
        <Spacer allowDecimal baselineHeight={2.5} />
        <Flex container>
          {animalIdPreferences.loading ? (
            <LoadingOverlay customStyles={styles.loadingOverlay} />
          ) : (
            <Flex item itemGutter xs={12} xl={7}>
              <IdPreferencesForm animalIdPreferences={animalIdPreferences} />
            </Flex>
          )}
        </Flex>
      </PageContent>
    </>
  );
};

export default IdPreferences;
