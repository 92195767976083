import { TablePaginationSizes } from "components/Common/Table/TablePagination";
import { TABLE_IDS } from "constants/Interface";
import { PageInfoFragment } from "generated/graphql";
import { getTableSettingsFromLocalStorage, setTableSettingsToLocalStorage } from "helpers/storage";
import { useOutsideClick } from "hooks";
import { useEffect, useRef, useState } from "react";

export const getPageFrom = ({
  totalNumberOfItems,
  pageIndex,
  pageSize,
}: {
  totalNumberOfItems: number;
  pageIndex: number;
  pageSize: number;
}) => {
  if (totalNumberOfItems === 0) {
    return 0;
  }

  if (pageIndex + 1 === 1) {
    return 1;
  }

  return pageIndex * pageSize + 1;
};

export const getPageTo = ({
  pageSize,
  pageFrom,
  totalNumberOfItems,
}: {
  pageSize: number;
  pageFrom: number;
  totalNumberOfItems: number;
}) => {
  const pageTo = pageFrom - 1 + pageSize;

  if (pageTo > totalNumberOfItems) {
    return totalNumberOfItems;
  }

  return pageTo;
};

export type PaginationProperties = ReturnType<typeof usePagination>;

export const usePagination = ({
  totalNumberOfItems,
  pageInfo,
  loading,
  tableId,
}: {
  totalNumberOfItems: number;
  pageInfo: PageInfoFragment | undefined;
  loading: boolean;
  tableId: typeof TABLE_IDS[keyof typeof TABLE_IDS];
}) => {
  const localStorageTableSettings = getTableSettingsFromLocalStorage(tableId);
  const localStorageColumns = localStorageTableSettings?.columns;
  const localStoragePageSize = localStorageTableSettings?.pageSize;
  const [pageNumber, setPageNumber] = useState(1);
  const [firstCursor, setFirstCursor] = useState<null | string>(null);
  const [pageSize, setPageSize] = useState<TablePaginationSizes>(localStoragePageSize || 10);
  const [paginationQueryVariables, setPaginationQueryVariables] = useState<{
    last?: number;
    before?: string | null;
    first?: number;
    after?: string;
  }>({
    first: pageSize,
  });
  const [showPageSizeOptionsMenu, setShowPageSizeOptionsMenu] = useState<boolean>(false);

  const handleGoToFirstPage = () => {
    setPageNumber(1);
    setPaginationQueryVariables({
      first: pageSize,
    });
  };
  const handleGoToNextPage = () => {
    setPaginationQueryVariables({
      first: pageSize,
      after: pageInfo?.endCursor || undefined,
      last: undefined,
      before: undefined,
    });
    setPageNumber((pageNumber) => pageNumber + 1);
  };

  const handleGoToPreviousPage = () => {
    setPaginationQueryVariables({
      first: undefined,
      after: undefined,
      last: pageSize,
      before: pageInfo?.startCursor,
    });
    setPageNumber((pageNumber) => pageNumber - 1);
  };

  const handleGoToLastPage = () => {
    setPaginationQueryVariables({
      first: undefined,
      after: undefined,
      last: totalNumberOfItems % pageSize || pageSize,
      before: undefined,
    });
    setPageNumber(Math.ceil(totalNumberOfItems / pageSize));
  };

  const pageIndex = pageNumber - 1;
  const pageFrom = getPageFrom({ totalNumberOfItems: totalNumberOfItems, pageSize, pageIndex });
  const pageTo = getPageTo({ totalNumberOfItems: totalNumberOfItems, pageSize, pageFrom });
  const togglePageSizeOptionsMenu = () => {
    setShowPageSizeOptionsMenu((state) => !state);
  };
  const hidePageSizeOptionsMenu = () => {
    setShowPageSizeOptionsMenu(false);
  };

  const pageSizeMenuRef = useRef(null);
  useOutsideClick(pageSizeMenuRef, hidePageSizeOptionsMenu);

  const canPrevPage = () => {
    if ((firstCursor === pageInfo?.startCursor || firstCursor == null) && !loading) {
      return false;
    }

    return true;
  };

  const handleChangePageSize = (size: TablePaginationSizes) => {
    setPageNumber(1);
    setPageSize(size);
    hidePageSizeOptionsMenu();
    setPaginationQueryVariables({
      first: size,
    });
    setTableSettingsToLocalStorage(tableId, localStorageColumns, size);
  };

  useEffect(() => {
    if (pageInfo?.startCursor) {
      if (firstCursor == null && firstCursor != pageInfo?.startCursor) {
        setFirstCursor(pageInfo?.startCursor);
      }
    }
  }, [firstCursor, pageInfo?.startCursor]);

  const isPreviousPageDisabled = !canPrevPage() || pageNumber === 1 || loading;
  const isNextPageDisabled = !(pageInfo?.hasNextPage || pageTo < totalNumberOfItems);

  return {
    paginationQueryVariables,
    paginationInfo: {
      pageFrom,
      pageTo,
      pageSize,
      totalNumberOfItems,
    },
    controls: {
      handleChangePageSize,
      handleGoToFirstPage,
      handleGoToLastPage,
      handleGoToNextPage,
      handleGoToPreviousPage,
      togglePageSizeOptionsMenu,
      showPageSizeOptionsMenu,
      setPageNumber,
    },
    flags: {
      isNextPageDisabled,
      isPreviousPageDisabled,
    },
    refs: {
      pageSizeMenuRef,
    },
  };
};
