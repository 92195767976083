import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { FormBlockWithLogo } from "components/Auth";
import Input from "components/Common/Input";
import { Button } from "components";

import { RESET_PASSWORD } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";

import { validationSchemaReset, handleEnterPress, stringifyJsonSafely } from "helpers/general";

import { postRequest } from "api/restAPI";
import api from "api/config";

import styles from "./styles.module.css";

const ForgotPass: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handResetPassword = async ({ email }): Promise<void> => {
    setLoading(true);

    try {
      const { data } = await postRequest(
        api.endpoints.forgotPass,
        stringifyJsonSafely({
          email,
        }),
      );

      setLoading(false);

      if (data && data.success) {
        history.push(RESET_PASSWORD);
      } else {
        setError("Something went wrong! Please try again later.");
      }
    } catch (e: any) {
      setError(e.response ? e.response.data.errors.email[0].message : "Error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormBlockWithLogo
      // @ts-ignore
      onSubmit={handResetPassword}
      // @ts-ignore
      customStyle={styles.viewForm}
      validationSchema={validationSchemaReset}
      initialValues={{ email: "" }}
    >
      {({ values, errors, handleChange, handleSubmit }): JSX.Element => {
        const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>): void => {
          handleEnterPress(e, handleSubmit);
        };
        return (
          <div className={styles.container}>
            <div className={styles.viewInfoText}>
              <span className={styles.infoText}>
                Enter your email and we will send you a link to reset your password
              </span>
            </div>
            <Input
              id={ELEMENTS_IDS.FORGOT_PASSWORD_EMAIL_INPUT}
              autoFocus
              type="email"
              label="Email"
              name="email"
              value={values.email}
              stylesClasses={styles.inputStyle}
              handleChange={handleChange}
              errorStatus={errors.email}
              onKeyPress={handleKeyPress}
              error={errors.email || null}
            />
            {error ? <div className={styles.errorText}>{error}</div> : null}
            <div className={styles.buttonsWrapper}>
              <Button
                id={ELEMENTS_IDS.FORGOT_PASSWORD_RESET_PASSWORD_BUTTON}
                caption="Reset password"
                width="full"
                height="large"
                disabled={loading}
                requesting={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        );
      }}
    </FormBlockWithLogo>
  );
};

export default ForgotPass;
