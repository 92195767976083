import {
  flexRender,
  getCoreRowModel,
  Row,
  RowData,
  TableOptions,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { TableId } from "constants/Interface";
import { LoadingOverlay } from "twComponents/LoadingOverlay";
import { PaginationControl } from "twComponents/PaginationControl";
import { PaginationProperties } from "twComponents/PaginationControl/helpers";
import { useRowSelection } from "hooks/useRowSelection";
import { cn } from "utils/misc";
import { TableHeaderSortIcon } from "components/Common/Table/TableHeaderSortIcon";
import { Fragment } from "react";

type Props<TData extends RowData> = {
  loading: boolean;
  options: Omit<TableOptions<TData>, "getCoreRowModel">;
  tableId: TableId;
  title?: string;
  onRowClick?: (row: Row<TData>["original"]) => void;
  columnVisibility?: VisibilityState | undefined;
  showSelectColumn?: boolean;
  emptyStateComponent: React.ReactElement;
  paginationProps?: PaginationProperties;
  renderSubRow?: (props: { row: Row<TData> }) => React.ReactElement;
};

export const Table = <TData extends RowData>({
  title,
  options,
  tableId,
  loading,
  onRowClick,
  columnVisibility,
  showSelectColumn,
  emptyStateComponent,
  paginationProps,
  renderSubRow,
}: Props<TData>) => {
  const { selectColumn, rowSelection } = useRowSelection<TData>({ tableId });

  const table = useReactTable<TData>({
    ...options,
    columns: showSelectColumn ? [selectColumn, ...options.columns] : options.columns,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: renderSubRow ? () => true : undefined,
    manualPagination: Boolean(paginationProps),
    state: {
      ...options.state,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="max-w-[100%]">
      {paginationProps ? (
        <div className="mb-2 flex justify-end">
          <PaginationControl paginationProps={paginationProps} />
        </div>
      ) : null}
      {loading ? <LoadingOverlay /> : null}
      <div>
        <h2 className="font-bold text-lg text-gray-900">{title}</h2>
      </div>
      <div className="overflow-x-auto rounded-lg">
        {!options.data || (options.data.length === 0 && !loading) ? (
          emptyStateComponent
        ) : (
          <table
            {...{
              style: {
                width: table.getCenterTotalSize(),
                minWidth: "100%",
              },
            }}
          >
            <thead className="h-12">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className={cn(
                        header.column.getIsSorted() ? "text-breedr-yellow-500" : "",
                        "text-white px-4 py-1 bg-breedr-blue-500 text-left text-[11px] uppercase border-r-gray-600 border-solid border-r align-middle",
                      )}
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div
                        className={cn(
                          "flex justify-between items-center",
                          table.options.enableSorting ? "cursor-pointer" : "",
                        )}
                      >
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                        <div>
                          {
                            {
                              asc: <TableHeaderSortIcon sorted={true} sortedDesc={false} />,
                              desc: <TableHeaderSortIcon sorted={true} sortedDesc={true} />,
                            }[header.column.getIsSorted() as string]
                          }
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {table.getRowModel().rows.map((row) => {
                return (
                  <Fragment key={row.id}>
                    <tr
                      className={onRowClick ? "cursor-pointer" : "cursor-auto"}
                      onClick={onRowClick ? () => onRowClick(row.original) : undefined}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            className="px-4 py-2 bg-white text-[13px] text-ellipsis overflow-hidden border-b-gray-200 border-solid border-b align-middle h-12"
                            key={cell.id}
                            style={{
                              width: cell.column.getSize(),
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })}
                    </tr>
                    {row.getIsExpanded() && renderSubRow ? (
                      <tr>
                        <td colSpan={row.getVisibleCells().length}>{renderSubRow({ row })}</td>
                      </tr>
                    ) : null}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
