import { Button } from "twComponents/Button";
import { TransactionValidationSchema as S } from "financial/components/TransactionForm/validation";
import { FormCheckbox } from "components/FormHookComponents/FormCheckbox";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FINANCIAL_TRANSACTIONS } from "constants/Routes";

interface Props {
  isNewTransaction: boolean;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDeleteConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmittingTransaction: boolean;
}

export const FormActions = ({
  isSubmittingTransaction,
  setShowDeleteConfirmationModal,
  isNewTransaction,
  setIsEditMode,
  isEditMode,
}: Props) => {
  const history = useHistory();
  const methods = useFormContext<S>();

  if (!methods) {
    throw new Error("FormActions must be used inside a <Form /> or <FormProvider />");
  }

  const onCancel = () => {
    history.push(`${FINANCIAL_TRANSACTIONS}`);
  };

  return (
    <div className="flex-1 flex justify-end">
      <div className="flex items-center mr-4">
        <FormCheckbox<S> name="isSubmitted" disabled={!isEditMode} className="bg-white" />
        <label className="text-xs ml-2" htmlFor="isSubmitted">
          Mark transaction as complete
        </label>
      </div>
      <Button
        type="button"
        variant="ghost"
        onClick={() => setShowDeleteConfirmationModal(true)}
        className="text-red-500 mr-2"
      >
        Delete
      </Button>
      {isNewTransaction ? null : (
        <Button
          type="button"
          variant="outline"
          className="mr-2"
          onClick={() => {
            if (isEditMode) {
              methods.reset();
            }
            setIsEditMode((prevState) => !prevState);
          }}
        >
          {isEditMode ? "Cancel" : "Edit"}
        </Button>
      )}
      {isEditMode ? (
        <Button type="submit" disabled={isSubmittingTransaction} loading={isSubmittingTransaction} className="ml-2">
          Save
        </Button>
      ) : (
        <Button type="button" disabled={isSubmittingTransaction} variant="outline" onClick={onCancel}>
          Back
        </Button>
      )}
    </div>
  );
};
