import { VFC } from "react";
// Types
import { GetFarmSystems_farmSystems } from "api/Settings/types/GetFarmSystems";
// Components
import { Flex, Pill, QuickActions, Spacer, Text } from "components";
// Utils
import { INTEGRATION_SYSTEMS_CONNECTING_STATUSES } from "constants/Settings";
import { getConnectedSystemStatusName } from "helpers/settings";
import { useBreakpoints } from "hooks";
// Types
import { QuickActionItem } from "components/Common/QuickActions";

interface Props {
  system: GetFarmSystems_farmSystems;
  setEditData: (system: GetFarmSystems_farmSystems) => void;
}

export const ConnectedSystemItemView: VFC<Props> = ({ system, setEditData }) => {
  const { isBreakpointL } = useBreakpoints();
  const { integrationSystem, errorMessage = null, status, location = {} } = { ...system };
  const { unitName = "", locationId = "please enter" } = { ...location };
  //@ts-ignore
  const { name: systemName } = { ...integrationSystem };

  const renderStatusBadge = (status: string | null): JSX.Element | null => {
    const badgeText = getConnectedSystemStatusName(status);

    switch (status) {
      case INTEGRATION_SYSTEMS_CONNECTING_STATUSES.PENDING:
        return <Pill caption={badgeText} colour="blue" />;

      case INTEGRATION_SYSTEMS_CONNECTING_STATUSES.DISCONNECTED:
      case INTEGRATION_SYSTEMS_CONNECTING_STATUSES.CONNECT_FAILED:
        return <Pill caption={badgeText} colour="red" />;

      case INTEGRATION_SYSTEMS_CONNECTING_STATUSES.CONNECTED:
        return <Pill caption={badgeText} colour="green" />;

      default:
        return null;
    }
  };

  const quickActionItems: QuickActionItem[] = [{ label: "Edit", onClick: (): void => setEditData(system) }];

  return (
    <>
      <Flex container containerResetXS={12}>
        <Flex item xs={12} l={6}>
          <Text smaller>
            <strong>{unitName}</strong>
          </Text>
          <Spacer />
          <Text smallest>CPH Number: {locationId}</Text>
          <Spacer />
          <Text smallest>{systemName}</Text>

          <Spacer baselineHeight={1} />
          <QuickActions items={quickActionItems} />
        </Flex>

        {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}

        <Flex item xs={12} l={6}>
          {renderStatusBadge(status)}
          <Spacer baselineHeight={1} />
          {errorMessage ? (
            <Text smallest warning>
              {errorMessage}
            </Text>
          ) : null}
        </Flex>
      </Flex>
      <Spacer baselineHeight={2} />
    </>
  );
};
