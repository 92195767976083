import { useGetAnimalCategoriesQuery, GetAnimalCategoriesQuery } from "generated/graphql";
import { getCategoryFilterStates, getCategorySelectOptions } from "trading/util";
import { useStateSpecies } from "hooks";
import { InputSelectOption } from "components/Common/Field/Select";
import { CheckBoxState } from "../useListingFilters/config";

export type AnimalType = NonNullable<NonNullable<NonNullable<GetAnimalCategoriesQuery>["animalTypes"]>[0]>;

export type CategoryOfSale = NonNullable<NonNullable<AnimalType["categoriesOfSale"]>[0]>;

export interface UseGetCategoryListingsReturn {
  animalTypes: AnimalType[];
  fieldSelectOptions: InputSelectOption[];
  categoriesFalse: CheckBoxState;
  categoriesTrue: CheckBoxState;
}

export const useGetCategoryListings = (): UseGetCategoryListingsReturn => {
  const { activeSpecies } = useStateSpecies();

  const activeId = activeSpecies?.id ? +activeSpecies.id : 3;

  const { data } = useGetAnimalCategoriesQuery();

  const animalTypes =
    data?.animalTypes?.reduce((acc: AnimalType[], animalType) => {
      if (animalType?.name && animalType.id && animalType.categoriesOfSale && animalType.categoriesOfSale?.length > 0) {
        const categories = animalType.categoriesOfSale
          .filter((category): category is NonNullable<typeof category> => category != null)
          .sort((a, b) => a.position - b.position);

        acc.unshift({ name: animalType.name, id: animalType.id, categoriesOfSale: categories });
      }

      return acc;
    }, []) ?? [];

  const activeCategories = animalTypes?.find((animalType) => animalType.id === activeId.toString())
    ?.categoriesOfSale as CategoryOfSale[];

  const fieldSelectOptions = getCategorySelectOptions(activeCategories);

  const { categoriesFalse, categoriesTrue } = getCategoryFilterStates(animalTypes);
  return {
    animalTypes,
    fieldSelectOptions,
    categoriesFalse,
    categoriesTrue,
  };
};
