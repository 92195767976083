import * as React from "react";

import { Dots } from "constants/SvgIcons";

import styles from "./styles.module.scss";

export const TableActionButton: React.FC = () => (
  <div className={styles.dots}>
    <Dots />
  </div>
);
