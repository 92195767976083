import { useContext, useEffect } from "react";
import { useMachine } from "@xstate/react";
import { GetActivityTypes_activityTypes } from "api/MyLivestock/Activity/types/GetActivityTypes";
import { importCustomWizardMachine } from "./state/importCustomWizardMachine";
import { CommonContext } from "config/commonProvider";
import { ACTIVITIES_IMPORT, ANIMALS_IMPORT, MOVEMENT_IMPORT } from "constants/Import";
import { Flex, Paper, Spacer, Title, Text } from "components";
import { ActivityTemplateIcon } from "components/Activity";
import { ImportWizardChooseColumns } from "./ImportWizardChooseColumns";
import { ImportWizardChooseFile } from "./ImportWizardChooseFile";
import { ImportWizardChooseTemplate } from "./ImportWizardChooseTemplate";
import { ImportWizardChooseType } from "./ImportWizardChooseType";
import { ImportWizardStages } from "./ImportWizardStages";
import { ImportWizardResult } from "./ImportWizardResult";
import styles from "./ImportCustomWizard.module.scss";
import { UploadCsvMutation } from "generated/graphql";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { useHistory } from "react-router-dom";
import { ANIMALS, DELIVERIES, DRAFTS, MOVE_OFF } from "constants/Routes";
import { BatchUploadFormat, useGetBatchUploadFormats } from "hooks/useGetBatchUploadFormats";
import { useCallback } from "react";

export const ImportCustomWizard = ({ batchUploadFormatSlug }: { batchUploadFormatSlug?: string }) => {
  const [state, send] = useMachine(importCustomWizardMachine);
  const history = useHistory();

  // 1. Choose type
  const handleSelectActivityType = (activityType: GetActivityTypes_activityTypes) =>
    send("SELECT_ACTIVITY_TYPE", { activityType });

  const format = state.context.batchUploadFormat;

  const importType = format?.animalTypes?.length
    ? ANIMALS_IMPORT
    : format?.slug === "cmd-deliver-out"
    ? MOVEMENT_IMPORT
    : ACTIVITIES_IMPORT;

  // 2. Choose template
  const handleResetActivityType = () => send("RESET_ACTIVITY_TYPE");

  const handleSelectBatchFormatUpload = useCallback(
    (batchUploadFormat: BatchUploadFormat) => {
      send("SELECT_BATCH_UPLOAD_FORMAT", { batchUploadFormat });
    },
    [send],
  );

  const { batchUploadFormats } = useGetBatchUploadFormats();

  useEffect(() => {
    if (!batchUploadFormatSlug || !batchUploadFormats) {
      return;
    }
    const uploadFormat = (batchUploadFormats || []).find((format) => format.slug === batchUploadFormatSlug);
    if (uploadFormat) {
      handleSelectBatchFormatUpload(uploadFormat);
    }
  }, [batchUploadFormatSlug, batchUploadFormats, handleSelectBatchFormatUpload]);

  // 3. Choose file
  const handleClickBackFromChooseFile = () =>
    state.context.batchUploadFormat?.animalTypes.length ? send("RESET_UPLOAD_TYPE") : send("RESET_ACTIVITY_TEMPLATE");

  const { showNotification } = useContext(CommonContext);

  const handleSetUploadedFile = (
    uploadedFile: NonNullable<UploadCsvMutation["uploadCsv"]>,
    uploadedFileName: string,
  ) => {
    const errors = uploadedFile?.errors;

    if (errors) {
      const errorMessage = capitaliseFirstLetter(errors[0]?.message || null) || "Something went wrong";
      showNotification({
        variant: "error",
        message: errorMessage,
      });
    } else {
      send("SET_UPLOADED_FILE", { uploadedFile, uploadedFileName });
    }
  };

  const handleResetUploadedFile = () => send("RESET_UPLOADED_FILE");

  const handleProceedWithUploadedFile = () => send("PROCEED_WITH_UPLOADED_FILE");

  // 4. Choose columns
  const handleSetTaskId = (taskId: string) => send("SET_TASK_ID", { taskId });

  // 5. Result
  const handleRestart = () => send("RESTART");

  const handleSetTaskSuccess = () => send("SET_TASK_SUCCESS");

  const handleSuccessRedirect = () => {
    return importType === "movement" ? history.push(`${DELIVERIES}${MOVE_OFF}${DRAFTS}`) : history.push(ANIMALS);
  };

  return (
    <Flex item itemGutter>
      <Paper className={styles.import_custom_wizard}>
        <Spacer baselineHeight={5} />

        <Flex container containerJustifyContent="center">
          <Flex container containerAlignItems="center">
            {state.value === "chooseTemplate" && state.context.activityType?.image ? (
              <ActivityTemplateIcon
                activityTemplateName={state.context.activityType.name}
                className={styles.import_custom_wizard__title_icon}
                size="medium"
                src={state.context.activityType?.image}
              />
            ) : null}

            <Title tertiary>{state.context.title}</Title>
          </Flex>

          <Spacer baselineHeight={1} />

          <Text>{state.context.subtitle}</Text>
        </Flex>

        <Spacer baselineHeight={5} />

        <ImportWizardStages activeStepNumber={state.context.stageNumber} />

        <Spacer baselineHeight={4} />

        {state.value === "chooseType" ? (
          <ImportWizardChooseType
            onSelectActivityType={handleSelectActivityType}
            onSelectAnimalImport={handleSelectBatchFormatUpload}
            batchUploadFormats={batchUploadFormats}
          />
        ) : null}

        {state.value === "chooseTemplate" && state.context.activityType ? (
          <ImportWizardChooseTemplate
            activityTypeId={state.context.activityType.id}
            onClickBack={handleResetActivityType}
            onSelect={handleSelectBatchFormatUpload}
            batchUploadFormats={batchUploadFormats}
          />
        ) : null}

        {state.value === "chooseFile" ? (
          <ImportWizardChooseFile
            onClickBack={batchUploadFormatSlug ? () => history.goBack() : handleClickBackFromChooseFile}
            onClickNext={handleProceedWithUploadedFile}
            onResetFile={handleResetUploadedFile}
            onSetFile={handleSetUploadedFile}
            importType={importType}
          />
        ) : null}

        {state.value === "chooseColumns" && state.context.uploadedFile ? (
          <ImportWizardChooseColumns
            file={state.context.uploadedFile}
            importType={importType}
            onChangeBatchUploadFormat={handleSelectBatchFormatUpload}
            batchUploadFormat={state.context.batchUploadFormat}
            onClickBack={handleResetUploadedFile}
            onRestart={handleRestart}
            onSuccess={handleSetTaskId}
          />
        ) : null}

        {state.value === "result" && state.context.uploadedFileName && state.context.taskId ? (
          <ImportWizardResult
            fileName={state.context.uploadedFileName}
            importType={importType}
            onClickRestart={handleRestart}
            onSuccess={handleSetTaskSuccess}
            taskId={state.context.taskId}
            redirectButtonLabel={importType === "movement" ? "View Draft Movements" : "View All Animals"}
            handleSuccessRedirect={handleSuccessRedirect}
          />
        ) : null}

        <Spacer baselineHeight={4} />
      </Paper>
    </Flex>
  );
};
