// Libraries
import { QueryResult, useQuery } from "@apollo/client";
// API
import { GET_ACTIVITY_TEMPLATES } from "api/MyLivestock/Activity/queries";
import {
  GetActivityTemplates_activityTemplates,
  GetActivityTemplates,
} from "api/MyLivestock/Activity/types/GetActivityTemplates";
import { GetActivityTemplatesQueryVariables } from "generated/graphql";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";
import { useStateSpecies } from "hooks";

interface UseGetActivityTemplatesReturn extends QueryResult {
  data: GetActivityTemplates_activityTemplates[];
}

export const useGetActivityTemplates = (): UseGetActivityTemplatesReturn => {
  const { activeSpecies } = useStateSpecies();

  const { data, ...rest } = useQuery<GetActivityTemplates, GetActivityTemplatesQueryVariables>(GET_ACTIVITY_TEMPLATES, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
      species: activeSpecies?.id ? Number(activeSpecies.id) : undefined,
    },
    fetchPolicy: "cache-and-network",
  });

  const breeds = (data?.activityTemplates || []) as GetActivityTemplates_activityTemplates[];

  return {
    data: breeds,
    ...rest,
  };
};
