import { SortingRule } from "react-table";

const camelToSnakeCase = (input: string) => input.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const parseSortRule = <T>(sortRule: SortingRule<T>[]): Array<string> => {
  if (sortRule[0] != undefined) {
    const sortingRule = sortRule[0];
    const sortingRuleId = camelToSnakeCase(sortingRule.id);
    const parsedSortingRule = `${sortingRule.desc ? "-" : ""}${sortingRuleId}`;
    return [parsedSortingRule];
  }

  return [];
};
