import { ChangeEvent, FC, useContext } from "react";
// Libraries
import cn from "classnames";
import debounce from "lodash/debounce";
import { useRouteMatch } from "react-router-dom";
// Utils
import { SYNCR_OF_ANIMALS, DRAWAL_OF_ANIMALS, PREGNANCY_STATUS_OPTIONS, SEX_OF_ANIMALS } from "constants/Animals";
import { FIELD_VIEW } from "constants/Routes";
import { CommonContext } from "config/commonProvider";
import { positiveFloatNumberWithZero } from "helpers/regex";
import {
  useGetBreeds,
  useGetFields,
  useGetGroups,
  useGetSexClassifications,
  useBreakpoints,
  useGetCurrentBusinessUnit,
  useHasFeature,
  useStateSpecies,
} from "hooks";
// Components
import { SaveFilterTemplate } from "components/MyLivestock";
import { InputSelectOption } from "components/Common/Field/Select";
import { Flex, FormBuilder, FieldDateRange, FieldNumberRange, FieldSelect, FieldText, Spacer } from "components";
// Resources
import styles from "./AnimalsFilter.module.scss";
import { useAnimalTagsQuery } from "generated/graphql";
import { notNothing } from "helpers/general";
import { useLocale } from "helpers/translations/src";

export type AnimalsFilterProps = {
  className?: string;
  excludedFields?: {
    animalTypeId?: boolean;
    fields?: boolean;
    groups?: boolean;
    deliveryDate?: boolean;
    ageAtDelivery?: boolean;
    weightAtDelivery?: boolean;
    isRegulatorySynced?: boolean;
  };
  isSavedFiltersAvailable?: boolean;
  isArchived?: boolean;
};

const AnimalsFilter: FC<AnimalsFilterProps> = ({
  className,
  excludedFields,
  isSavedFiltersAvailable,
  isArchived = false,
}) => {
  const { weightUnits, id } = useGetCurrentBusinessUnit();
  const isFieldView = useRouteMatch(`${FIELD_VIEW}/:fieldId`);
  const canViewAllSpecies = !!isFieldView;
  const { terms } = useLocale();

  const { breeds } = useGetBreeds({ forAllSpecies: canViewAllSpecies });
  const { groups } = useGetGroups({ forAllSpecies: canViewAllSpecies });
  const { data: sexClassificationData } = useGetSexClassifications({ forAllSpecies: canViewAllSpecies });
  const { data: tagsData } = useAnimalTagsQuery({
    variables: { buId: Number(id) },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  const animalTags =
    tagsData?.animalTags?.edges
      ?.map((edge) => {
        return edge?.node;
      })
      .filter(notNothing) || [];

  const { isBreakpointXL } = useBreakpoints();
  const hasSexClassificationFeature = useHasFeature("SHOW_SEX_CLASSIFICATION");
  const hasPregnancyStatusFeature = useHasFeature("SHOW_PREGNANCY_STATUS");

  const { mainCommonState, changeAnimalsFilter, setAnimalsFilterTemplateId } = useContext(CommonContext);

  const { speciesList } = useStateSpecies();
  const animalTypes = speciesList?.map((animalType) => ({
    key: animalType.id,
    value: animalType.id,
    label: animalType.name,
  }));

  const {
    animalTypeId: animalTypeIdInitValue,
    breeds: breedsInitValue,
    sex,
    minAge,
    maxAge,
    maxDeliveryAge,
    minDeliveryAge,
    minCurrentEstWeight,
    maxCurrentEstWeight,
    minWeight,
    maxWeight,
    daysSinceLastWeightFrom,
    daysSinceLastWeightTo,
    growthRateFrom,
    growthRateTo,
    minDeliveryWeight,
    maxDeliveryWeight,
    deliveryDateFrom,
    deliveryDateTo,
    tags,
    groups: groupsInitValue,
    fields: fieldsInitValue,
    lastSyncedDateFrom,
    lastSyncedDateTo,
    isRegulatorySynced,
    isWithdrawal,
    pregnancyStatus,
    pregnancyDueDateFrom,
    pregnancyDueDateTo,
    minKillWeight,
    maxKillWeight,
    killQuality,
    killFatScore,
    dobDateFrom,
    dobDateTo,
    dateMovedToFarmFrom,
    dateMovedToFarmTo,
    minDateLeftFarm,
    maxDateLeftFarm,
    minDeadAtDate,
    maxDeadAtDate,
    sexClassifications,
  } = mainCommonState.animalsFilters || {};

  const { fieldOptions } = useGetFields();

  return (
    <FormBuilder
      enableReinitialize
      validateOnChange={false}
      initialValues={{
        animalTypeId: animalTypeIdInitValue || null,
        breeds: breedsInitValue || [],
        sex: sex || "",
        minAge: minAge || "",
        maxAge: maxAge || "",
        maxDeliveryAge: maxDeliveryAge || "",
        minDeliveryAge: minDeliveryAge || "",
        minCurrentEstWeight: minCurrentEstWeight || "",
        maxCurrentEstWeight: maxCurrentEstWeight || "",
        minWeight: minWeight || "",
        maxWeight: maxWeight || "",
        daysSinceLastWeightFrom: daysSinceLastWeightFrom || "",
        daysSinceLastWeightTo: daysSinceLastWeightTo || "",
        growthRateFrom: growthRateFrom || "",
        growthRateTo: growthRateTo || "",
        minDeliveryWeight: minDeliveryWeight || "",
        maxDeliveryWeight: maxDeliveryWeight || "",
        deliveryDateFrom: deliveryDateFrom || null,
        deliveryDateTo: deliveryDateTo || null,
        groups: groupsInitValue || [],
        tags: tags || [],
        fields: fieldsInitValue || [],
        lastSyncedDateFrom: lastSyncedDateFrom || null,
        lastSyncedDateTo: lastSyncedDateTo || null,
        isRegulatorySynced: isRegulatorySynced || "",
        isWithdrawal: isWithdrawal || "",
        pregnancyStatus: pregnancyStatus || "",
        pregnancyDueDateFrom: pregnancyDueDateFrom || null,
        pregnancyDueDateTo: pregnancyDueDateTo || null,
        minKillWeight: minKillWeight || "",
        maxKillWeight: maxKillWeight || "",
        dobDateFrom: dobDateFrom || null,
        dobDateTo: dobDateTo || null,
        dateMovedToFarmFrom: dateMovedToFarmFrom || null,
        dateMovedToFarmTo: dateMovedToFarmTo || null,
        killQuality: killQuality || "",
        killFatScore: killFatScore || "",
        minDateLeftFarm: minDateLeftFarm || null,
        maxDateLeftFarm: maxDateLeftFarm || null,
        minDeadAtDate: minDeadAtDate || null,
        maxDeadAtDate: maxDeadAtDate || null,
        sexClassifications: sexClassifications || [],
      }}
    >
      {({ values, handleChange, setFieldValue }): JSX.Element => {
        const delayedChange = debounce((e) => {
          changeAnimalsFilter({ [e.target.name]: e.target.value });
          setAnimalsFilterTemplateId("");
        }, 500);

        const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
          handleChange(e);
          if (e.target.type) {
            delayedChange(e);
          } else {
            changeAnimalsFilter({ [e.target.name]: e.target.value });
          }

          setAnimalsFilterTemplateId("");
        };

        const onDateChange = (value: Date, name: string): void => {
          setFieldValue(name, value);
          changeAnimalsFilter({ [name]: value });
          setAnimalsFilterTemplateId("");
        };

        const onChangeAnimalTypeId = (event: ChangeEvent<HTMLSelectElement>) => {
          handleChange(event);

          const breedsFilterValuesMatchingNewAnimalTypeId = breedsInitValue?.filter(
            (breedFilterValue) =>
              breeds.find((breed) => breed.id === breedFilterValue)?.animalType?.id === event.target.value,
          );

          const sexClassificationsFilterValuesMatchingNewAnimalTypeId = sexClassifications?.filter(
            (sexClassificationFilterValue) =>
              sexClassificationData.find((sexClassification) => sexClassification.slug === sexClassificationFilterValue)
                ?.animalType.id === event.target.value,
          );

          const groupsFilterValuesMatchingNewAnimalTypeId = groupsInitValue?.filter(
            (groupFilterValue) =>
              groups.find((group) => group.id === groupFilterValue)?.animalType?.id === event.target.value,
          );

          changeAnimalsFilter({
            breeds: breedsFilterValuesMatchingNewAnimalTypeId,
            groups: groupsFilterValuesMatchingNewAnimalTypeId,
            sexClassifications: sexClassificationsFilterValuesMatchingNewAnimalTypeId,
            [event.target.name]: event.target.value,
          });

          setAnimalsFilterTemplateId("");
        };

        const metaFilteredBreedData = values.animalTypeId
          ? breeds.filter((breed) => breed.animalType?.id === values.animalTypeId)
          : breeds;
        const breedsOptions = metaFilteredBreedData.map(
          (breed): InputSelectOption => ({
            key: breed.id,
            value: breed.id,
            label: `${breed.regName || ""} (${breed.regBreedCode || ""})`,
          }),
        );

        const metaFilteredSexClassificationData = values.animalTypeId
          ? sexClassificationData.filter((sexClassification) => sexClassification.animalType.id === values.animalTypeId)
          : sexClassificationData;
        const sexClassificationsOptions = metaFilteredSexClassificationData.map(
          (sexClassification): InputSelectOption => ({
            key: sexClassification.slug,
            label: sexClassification.title,
            value: sexClassification.slug,
          }),
        );

        const metaFilteredGroupsOptions = values.animalTypeId
          ? groups.filter((group) => group.animalType?.id === values.animalTypeId)
          : groups;

        const isAnimalTypeIdIncluded = !excludedFields?.animalTypeId;

        return (
          <Flex className={cn(styles.animals_filter, className)} container>
            {isAnimalTypeIdIncluded ? (
              <FieldSelect
                label="Species"
                inputProps={{
                  name: "animalTypeId",
                  value: values.animalTypeId,
                  options: animalTypes,
                  onChange: onChangeAnimalTypeId,
                  placeholder: "No preference",
                }}
              />
            ) : null}

            <FieldSelect
              label="Breeds"
              inputProps={{
                name: "breeds",
                value: values.breeds,
                options: breedsOptions,
                multiple: true,
                onChange,
                placeholder: "No preference",
              }}
            />

            <FieldSelect
              label="Sex"
              inputProps={{
                name: "sex",
                value: values.sex,
                placeholder: "No preference",
                options: SEX_OF_ANIMALS,
                onChange,
                isClearable: true,
              }}
            />

            {hasSexClassificationFeature ? (
              <FieldSelect
                label="Sex classifications"
                inputProps={{
                  name: "sexClassifications",
                  value: values.sexClassifications,
                  options: sexClassificationsOptions,
                  multiple: true,
                  onChange,
                  placeholder: "No preference",
                }}
              />
            ) : null}

            <FieldNumberRange
              label="Age range (Months)"
              inputProps={{
                from: {
                  value: values.minAge,
                  name: "minAge",
                  placeholder: "No preference",
                  onChange,
                  validation: positiveFloatNumberWithZero,
                },
                to: {
                  value: values.maxAge,
                  name: "maxAge",
                  onChange,
                  placeholder: "No preference",
                  min: values.minAge,
                  validation: positiveFloatNumberWithZero,
                },
              }}
            />

            {!excludedFields?.ageAtDelivery ? (
              <FieldNumberRange
                label="Age (at delivery)"
                inputProps={{
                  from: {
                    value: values.minDeliveryAge,
                    name: "minDeliveryAge",
                    placeholder: "No preference",
                    onChange,
                    validation: positiveFloatNumberWithZero,
                  },
                  to: {
                    value: values.maxDeliveryAge,
                    name: "maxDeliveryAge",
                    onChange,
                    placeholder: "No preference",
                    min: values.minDeliveryAge,
                    validation: positiveFloatNumberWithZero,
                  },
                }}
              />
            ) : null}

            <FieldDateRange
              label="DOB range"
              inputProps={{
                from: {
                  value: values.dobDateFrom,
                  name: "dobDateFrom",
                  onChange: (date: Date): void => onDateChange(date, "dobDateFrom"),
                  placeholderText: "No preference",
                  isClearable: true,
                },
                to: {
                  value: values.dobDateTo,
                  name: "dobDateTo",
                  onChange: (date: Date): void => onDateChange(date, "dobDateTo"),
                  placeholderText: "No preference",
                  isClearable: true,
                },
              }}
            />

            <FieldDateRange
              label={`Date on ${terms.farm} range`}
              inputProps={{
                from: {
                  value: values.dateMovedToFarmFrom,
                  name: "dateMovedToFarmFrom",
                  onChange: (date: Date): void => onDateChange(date, "dateMovedToFarmFrom"),
                  placeholderText: "No preference",
                  isClearable: true,
                },
                to: {
                  value: values.dateMovedToFarmTo,
                  name: "dateMovedToFarmTo",
                  onChange: (date: Date): void => onDateChange(date, "dateMovedToFarmTo"),
                  placeholderText: "No preference",
                  isClearable: true,
                },
              }}
            />

            <FieldNumberRange
              label={`Current weight range (${weightUnits}s)`}
              inputProps={{
                from: {
                  value: values.minCurrentEstWeight,
                  name: "minCurrentEstWeight",
                  placeholder: "No preference",
                  onChange,
                  validation: positiveFloatNumberWithZero,
                },
                to: {
                  value: values.maxCurrentEstWeight,
                  name: "maxCurrentEstWeight",
                  onChange,
                  placeholder: "No preference",
                  min: values.minCurrentEstWeight,
                  validation: positiveFloatNumberWithZero,
                },
              }}
            />

            <FieldNumberRange
              label={`Last weight range (${weightUnits}s)`}
              inputProps={{
                from: {
                  value: values.minWeight,
                  name: "minWeight",
                  placeholder: "No preference",
                  onChange,
                  validation: positiveFloatNumberWithZero,
                },
                to: {
                  value: values.maxWeight,
                  name: "maxWeight",
                  onChange,
                  placeholder: "No preference",
                  min: values.minWeight,
                  validation: positiveFloatNumberWithZero,
                },
              }}
            />

            {!isArchived ? (
              <FieldNumberRange
                label="Days since weighed range"
                inputProps={{
                  from: {
                    value: values.daysSinceLastWeightFrom,
                    name: "daysSinceLastWeightFrom",
                    placeholder: "No preference",
                    onChange,
                    validation: positiveFloatNumberWithZero,
                  },
                  to: {
                    value: values.daysSinceLastWeightTo,
                    name: "daysSinceLastWeightTo",
                    onChange,
                    placeholder: "No preference",
                    min: values.daysSinceLastWeightFrom,
                    validation: positiveFloatNumberWithZero,
                  },
                }}
              />
            ) : null}

            <FieldNumberRange
              label={`${terms.dlwg} range (${weightUnits}s)`}
              inputProps={{
                from: {
                  value: values.growthRateFrom,
                  name: "growthRateFrom",
                  placeholder: "No preference",
                  onChange,
                  validation: positiveFloatNumberWithZero,
                },
                to: {
                  value: values.growthRateTo,
                  name: "growthRateTo",
                  onChange,
                  placeholder: "No preference",
                  min: values.growthRateFrom,
                  validation: positiveFloatNumberWithZero,
                },
              }}
            />

            {hasPregnancyStatusFeature ? (
              <>
                <FieldSelect
                  label="Pregnancy status"
                  inputProps={{
                    name: "pregnancyStatus",
                    value: values.pregnancyStatus,
                    placeholder: "No preference",
                    options: PREGNANCY_STATUS_OPTIONS,
                    onChange,
                    isClearable: true,
                  }}
                />

                <FieldDateRange
                  label="Pregnancy due date range"
                  inputProps={{
                    from: {
                      value: values.pregnancyDueDateFrom,
                      name: "pregnancyDueDateFrom",
                      onChange: (date: Date): void => onDateChange(date, "pregnancyDueDateFrom"),
                      placeholderText: "No preference",
                      isClearable: true,
                    },
                    to: {
                      value: values.pregnancyDueDateTo,
                      name: "pregnancyDueDateTo",
                      onChange: (date: Date): void => onDateChange(date, "pregnancyDueDateTo"),
                      placeholderText: "No preference",
                      isClearable: true,
                    },
                  }}
                />
              </>
            ) : null}

            {!excludedFields?.weightAtDelivery ? (
              <FieldNumberRange
                label="Estimated weight range (at delivery)"
                inputProps={{
                  from: {
                    value: values.minDeliveryWeight,
                    name: "minDeliveryWeight",
                    placeholder: "No preference",
                    onChange,
                    validation: positiveFloatNumberWithZero,
                  },
                  to: {
                    value: values.maxDeliveryWeight,
                    name: "maxDeliveryWeight",
                    onChange,
                    placeholder: "No preference",
                    min: values.minDeliveryWeight,
                    validation: positiveFloatNumberWithZero,
                  },
                }}
              />
            ) : null}

            {!excludedFields?.deliveryDate ? (
              <FieldDateRange
                label="Delivery date range"
                inputProps={{
                  from: {
                    value: values.deliveryDateFrom,
                    name: "deliveryDateFrom",
                    onChange: (date: Date): void => onDateChange(date, "deliveryDateFrom"),
                    maxDate: values.deliveryDateTo,
                    placeholderText: "No preference",
                    isClearable: true,
                  },
                  to: {
                    value: values.deliveryDateTo,
                    name: "deliveryDateTo",
                    onChange: (date: Date): void => onDateChange(date, "deliveryDateTo"),
                    minDate: values.deliveryDateFrom,
                    placeholderText: "No preference",
                    isClearable: true,
                  },
                }}
              />
            ) : null}

            {isArchived ? (
              <FieldDateRange
                label={`Date left ${terms.farm} range`}
                inputProps={{
                  from: {
                    value: values.minDateLeftFarm,
                    name: "minDateLeftFarm",
                    onChange: (date: Date): void => onDateChange(date, "minDateLeftFarm"),
                    placeholderText: "No preference",
                    isClearable: true,
                  },
                  to: {
                    value: values.maxDateLeftFarm,
                    name: "maxDateLeftFarm",
                    onChange: (date: Date): void => onDateChange(date, "maxDateLeftFarm"),
                    minDate: values.maxDateLeftFarm,
                    placeholderText: "No preference",
                    isClearable: true,
                  },
                }}
              />
            ) : null}

            {isArchived ? (
              <FieldDateRange
                label="Dead at date range"
                inputProps={{
                  from: {
                    value: values.minDeadAtDate,
                    name: "minDeadAtDate",
                    onChange: (date: Date): void => onDateChange(date, "minDeadAtDate"),
                    placeholderText: "No preference",
                    isClearable: true,
                  },
                  to: {
                    value: values.maxDeadAtDate,
                    name: "maxDeadAtDate",
                    onChange: (date: Date): void => onDateChange(date, "maxDeadAtDate"),
                    minDate: values.maxDeadAtDate,
                    placeholderText: "No preference",
                    isClearable: true,
                  },
                }}
              />
            ) : null}

            {
              <FieldSelect
                label="Tags"
                inputProps={{
                  name: "tags",
                  value: values.tags,
                  multiple: true,
                  options: animalTags,
                  placeholder: "No preference",
                  onChange,
                  labelKey: "name",
                  valueKey: "id",
                }}
              />
            }

            {!excludedFields?.groups ? (
              <FieldSelect
                label="Groups"
                inputProps={{
                  name: "groups",
                  value: values.groups,
                  multiple: true,
                  options: metaFilteredGroupsOptions,
                  placeholder: "No preference",
                  onChange,
                  labelKey: "name",
                  valueKey: "id",
                }}
              />
            ) : null}

            {!isArchived && !excludedFields?.fields ? (
              <FieldSelect
                label={"Locations"}
                inputProps={{
                  name: "fields",
                  value: values.fields,
                  multiple: true,
                  options: fieldOptions,
                  placeholder: "No preference",
                  onChange,
                }}
              />
            ) : null}
            {!excludedFields?.isRegulatorySynced ? (
              <>
                <FieldDateRange
                  label="Last synced date range"
                  inputProps={{
                    from: {
                      value: values.lastSyncedDateFrom,
                      name: "lastSyncedDateFrom",
                      onChange: (date: Date): void => onDateChange(date, "lastSyncedDateFrom"),
                      maxDate: values.deliveryDateTo,
                      placeholderText: "No preference",
                      isClearable: true,
                    },
                    to: {
                      value: values.lastSyncedDateTo,
                      name: "lastSyncedDateTo",
                      onChange: (date: Date): void => onDateChange(date, "lastSyncedDateTo"),
                      minDate: values.lastSyncedDateFrom,
                      placeholderText: "No preference",
                      isClearable: true,
                    },
                  }}
                />
                <FieldSelect
                  label="Is Synced"
                  inputProps={{
                    name: "isRegulatorySynced",
                    value: values.isRegulatorySynced,
                    placeholder: "No preference",
                    options: SYNCR_OF_ANIMALS,
                    onChange,
                    isClearable: true,
                  }}
                />
              </>
            ) : null}

            {!isArchived ? (
              <FieldSelect
                label="In Withdrawal"
                inputProps={{
                  name: "isWithdrawal",
                  value: values.isWithdrawal,
                  placeholder: "No preference",
                  options: DRAWAL_OF_ANIMALS,
                  onChange,
                  isClearable: true,
                }}
              />
            ) : null}

            {isArchived ? (
              <FieldNumberRange
                label={`Kill weight range (${weightUnits}s)`}
                inputProps={{
                  from: {
                    value: values.minKillWeight,
                    name: "minKillWeight",
                    placeholder: "No preference",
                    onChange,
                    validation: positiveFloatNumberWithZero,
                  },
                  to: {
                    value: values.maxKillWeight,
                    name: "maxKillWeight",
                    onChange,
                    placeholder: "No preference",
                    min: values.minKillWeight,
                    validation: positiveFloatNumberWithZero,
                  },
                }}
              />
            ) : null}

            {isArchived ? (
              <FieldText
                label="Kill quality"
                inputProps={{
                  name: "killQuality",
                  value: values.killQuality,
                  placeholder: "No preference",
                  onChange,
                }}
              />
            ) : null}

            {isArchived ? (
              <FieldText
                label="Kill fat score"
                inputProps={{
                  name: "killFatScore",
                  value: values.killFatScore,
                  placeholder: "No preference",
                  onChange,
                }}
              />
            ) : null}

            {isSavedFiltersAvailable ? (
              <>
                <Spacer baselineHeight={isBreakpointXL ? 5 : 1} />

                <SaveFilterTemplate requiredFieldIds={isAnimalTypeIdIncluded ? ["animalTypeId"] : undefined} />

                <Spacer baselineHeight={1} />
              </>
            ) : null}
          </Flex>
        );
      }}
    </FormBuilder>
  );
};

export default AnimalsFilter;
