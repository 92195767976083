import { forwardRef, InputHTMLAttributes } from "react";
import { cn } from "utils/misc";

type Props = InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef<HTMLInputElement, Props>(({ className = "", ...rest }, ref) => {
  return (
    <div className="flex justify-center items-center">
      <input
        type="checkbox"
        className={cn(
          "appearance-none border border-solid border-gray-300 bg-gray-100 shadow-sm relative peer w-4 h-4 cursor-pointer checked:border-0 checked:bg-green-700 disabled:opacity-50 hover:opacity-50 rounded-sm",
          className,
        )}
        {...rest}
        ref={ref}
      />
      <svg
        className="absolute w-3 h-3 hidden peer-checked:block pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </div>
  );
});

Checkbox.displayName = "Checkbox";
