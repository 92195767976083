import { useEffect, VFC } from "react";
// Libraries
import { useHistory, useParams } from "react-router-dom";
// Utils
import { REPORTS } from "constants/Routes";
import { useStateSpecies } from "hooks";
// Containers
import { ReportContent } from "containers/reports";
import { useGetReportsListQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage/storage";

export const Reports: VFC = () => {
  const { activeSpecies } = useStateSpecies();

  const history = useHistory();

  const { data: reports } = useGetReportsListQuery({
    variables: {
      businessUnit: getBUFromLocalStorage(),
      species: activeSpecies?.id ? +activeSpecies.id : undefined,
    },
  });

  const { reportType } = useParams<ReportPageParams>();
  const currentReport = (reports?.reports || []).find((item) => item?.slug === reportType);
  useEffect(() => {
    if (!currentReport) return history.push(REPORTS);

    const isReportForDifferentSpecies = !currentReport.species.find(
      (animalType) => animalType.id === activeSpecies?.id,
    );
    if (isReportForDifferentSpecies) history.push(REPORTS);
  }, [activeSpecies]);

  //@ts-ignore
  return currentReport ? <ReportContent currentReport={currentReport} /> : null;
};
