import React, { useContext, useState } from "react";
import { Button, FieldDate, Modal } from "components";
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";
import { TransactionType } from "components/Regulatory/Regulatory";
import { useRegulatoryTransactionApprovalsChangeDateMutation } from "generated/graphql";
import { getErrorMessage } from "helpers/general";

interface Props {
  selectedTransactions: string[];
  transactionType: TransactionType;
  onSuccessSubmit: () => void;
}

export const ChangeDate: React.FC<Props> = ({ selectedTransactions = [], onSuccessSubmit, transactionType }) => {
  const [modalVisible, changeModalVisibility] = useState<boolean>(false);
  const [date, changeDate] = useState<Date>(new Date());
  const { showNotification } = useContext(CommonContext);
  const businessUnit = getBUFromLocalStorage();

  const [updateTransactionsDate, { loading }] = useRegulatoryTransactionApprovalsChangeDateMutation({
    variables: {
      input: {
        businessUnit,
        transactionDate: date || new Date(),
        transactionType,
        regulatoryTransactionApprovals: selectedTransactions,
      },
    },
  });

  const { length: transactionCount } = selectedTransactions;
  const thisOrThese: string = transactionCount === 1 ? "this" : "these";
  const sOrNot: string = transactionCount !== 1 ? "s" : "";
  const totalOrNot: string = transactionCount !== 1 ? `${transactionCount} ` : "";
  const dateToday = new Date();

  const handleCloseModal = () => changeModalVisibility(false);

  const handleConfirmClick = async () => {
    try {
      const { data } = await updateTransactionsDate();
      const errors = data?.regulatoryTransactionApprovalsChangeDate?.errors;

      if (errors) {
        showNotification({
          variant: "error",
          message: errors.map((item) => item?.message).join("; "),
        });
      } else {
        showNotification({
          message: `Movement date successfully updated.`,
        });
        onSuccessSubmit();
        handleCloseModal();
      }
    } catch (error: unknown) {
      showNotification({
        variant: "error",
        message: getErrorMessage(error),
      });
    }
  };

  return (
    <>
      <Button
        caption="Change date"
        colour="grey"
        disabled={!transactionCount}
        onClick={() => changeModalVisibility(true)}
        variant="hollow"
      />
      <Modal
        showCloseButton
        active={modalVisible}
        handleClose={handleCloseModal}
        title={`Change movement date of ${thisOrThese} pending movement${sOrNot}?`}
        subTitle={`Confirm you want to change the movement date of ${thisOrThese} ${totalOrNot}pending movement${sOrNot}.`}
        actions={{
          secondary: {
            onClick: handleCloseModal,
            caption: "Cancel",
          },
          primary: {
            onClick: handleConfirmClick,
            caption: "Confirm",
            disabled: loading,
            requesting: loading,
          },
        }}
      >
        <FieldDate
          label="New movement date"
          inputProps={{
            name: "transactionDate",
            value: date,
            maxDate: dateToday,
            onChange: (newDate: Date) => changeDate(newDate),
          }}
        />
      </Modal>
    </>
  );
};
