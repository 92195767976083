import { gql } from "@apollo/client";

export const GROUP_FRAGMENT = gql`
  fragment GroupFragment on Group {
    id
    name
    isBatch
    targetWeight
    targetDate
    timeOnFarm
    category
    daysTillTarget
    avgDlwg
    animals {
      id
    }
    ...BatchDetails @include(if: $isBatch)
    ...NonBatchDetails @skip(if: $isBatch)
    animalType {
      id
      image
      colour
      name
    }
    summaryMetrics {
      breedrCode
      animalsCount
      femaleCount
      maleCount
    }
  }
`;

export const batchDetails = gql`
  fragment BatchDetails on Group {
    gendersInBatch
    numberOfUnidentifiedAnimalsInBatch
    batchDateOnFarm
    averageDobInBatch
    identifiedAnimalsCount
    animalBreeds {
      id
    }
  }
`;

export const nonBatchDetails = gql`
  fragment NonBatchDetails on Group {
    minAge
    maxAge
    weightInfo {
      maxWeight
      minWeight
    }
    minDaysOnFarm
    maxDaysOnFarm
    avgEstimatedWeight
    avgRecordedWeight
  }
`;
