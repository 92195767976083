import { gql } from "@apollo/client";

import { animalFiltersFragment } from "api/MyLivestock/Animal/fragment";
import { errorFragment } from "../../User/fragment";

export const CREATE_ANIMAL_FILTER = gql`
  mutation CreateAnimalFilter($input: CreateAnimalFilterInput!) {
    createAnimalFilter(input: $input) {
      animalFilter {
        ...animalFiltersFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${animalFiltersFragment}
  ${errorFragment}
`;

export const UPDATE_ANIMAL_FILTER = gql`
  mutation UpdateAnimalFilter($input: UpdateAnimalFilterInput!) {
    updateAnimalFilter(input: $input) {
      animalFilter {
        id
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const DELETE_ANIMAL_FILTER = gql`
  mutation DeleteAnimalFilter($input: DeleteAnimalFilterInput!) {
    deleteAnimalFilter(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
