import React, { useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { getBUFromLocalStorage } from "helpers/storage";

import { GET_CURRENT_USER_INFO } from "api/User/queries";
import { CREATE_PREFERRED_SUPPLIERS } from "api/BusinessUnits/mutations";

import { GetUser } from "api/User/types/GetUser";

import { SearchPreferredBusinessUnits_searchBusinessUnits } from "api/BusinessUnits/types/SearchPreferredBusinessUnits";

import { useGetPreferredBusinessUnits } from "hooks";

import { CommonContext } from "config/commonProvider";

import { Modal } from "components";
import { PreferredSuppliersSelect } from "components/Settings";

type Props = {
  showModal: boolean;
  onClose: () => void;
};

export const TEST_IDS = {
  MODAL: "add-supplier-modal",
  CANCEL_BUTTON: "cancel-button",
  CONFIRM_BUTTON: "confirm-button",
};

export const AddPreferredSuppliersModal: React.FC<Props> = ({ showModal, onClose }) => {
  const [suppliers, setSuppliers] = useState<SearchPreferredBusinessUnits_searchBusinessUnits[] | null>(null);
  const { showNotification } = useContext(CommonContext);
  const { data: currentUseData } = useQuery<GetUser>(GET_CURRENT_USER_INFO);
  const [createPreferredSuppliers, { loading: createPreferredSuppliersLoading }] =
    useMutation(CREATE_PREFERRED_SUPPLIERS);
  const { data: preferredBusinessUnits, refetch: refetchPreferredBU } = useGetPreferredBusinessUnits();

  const selectedSuppliers: SearchPreferredBusinessUnits_searchBusinessUnits[] | [] = suppliers ? suppliers : [];
  const currentBU = Number(getBUFromLocalStorage());
  const currentUserId = Number(currentUseData?.userInfo?.id);

  const isConfirmButtonDisable: boolean = createPreferredSuppliersLoading || selectedSuppliers.length === 0;

  const handleCancelClick = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    onClose();
  };

  const handleConfirmClick = async (): Promise<void> => {
    const sLetter: string = selectedSuppliers.length > 1 ? "s" : "";
    const suppliersIds: number[] = selectedSuppliers.map((item) => Number(item.id));

    try {
      const {
        data: {
          createPreferredSuppliers: { errors },
        },
      } = await createPreferredSuppliers({
        variables: {
          input: {
            businessUnit: currentBU,
            suppliers: suppliersIds,
            fieldsman: currentUserId,
          },
        },
      });

      if (errors) {
        showNotification({
          variant: "error",
          message: `Error inviting supplier${sLetter}`,
        });
      } else {
        await refetchPreferredBU();

        onClose();
        showNotification({
          message: `Supplier${sLetter} successfully invited`,
        });
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: `Error inviting supplier${sLetter}`,
      });
    }
  };

  return (
    <Modal
      showCloseButton
      active={showModal}
      handleClose={onClose}
      title="Search for suppliers"
      actions={{
        secondary: {
          onClick: handleCancelClick,
          caption: "Cancel",
          "data-testid": TEST_IDS.CANCEL_BUTTON,
        },
        primary: {
          onClick: handleConfirmClick,
          caption: "Confirm",
          disabled: isConfirmButtonDisable,
          requesting: createPreferredSuppliersLoading,
          "data-testid": TEST_IDS.CONFIRM_BUTTON,
        },
      }}
      testId={TEST_IDS.MODAL}
    >
      <PreferredSuppliersSelect
        showModal={showModal}
        suppliers={suppliers}
        setSuppliers={setSuppliers}
        preferredBusinessUnits={preferredBusinessUnits}
      />
    </Modal>
  );
};
