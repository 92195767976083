import { AnimalFragment } from "generated/graphql";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { Terms } from "helpers/translations/src/useLocale/types";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import * as yup from "yup";

export const getMoveOffDeliveryValidationSchema = (
  passportOfInvalidAnimal: string | null | undefined,
  countryCode: string,
  terms: Terms,
) => {
  return yup.object({
    minimumValidSentAtDate: yup.date(),
    name: yup.string().required("Name is required.").max(30, "Movement name cannot be longer than 50 characters"),
    sentAt: yup
      .date()
      .required()
      .max(DateTime.local().endOf("day"), "This cannot be submitted as the date is in the future.")
      .when("minimumValidSentAtDate", (minimumValidSentAtDate, schema) => {
        const parsedDate = minimumValidSentAtDate
          ? DateTime.fromJSDate(minimumValidSentAtDate).toFormat(
              countryCode === CountryISOCode.US ? "MM/dd/yyyy" : "dd/MM/yyyy",
            )
          : undefined;

        return (
          minimumValidSentAtDate &&
          schema.min(
            minimumValidSentAtDate,
            `Invalid date: Animal with passport number ${passportOfInvalidAnimal} was added to ${terms.farm} on ${parsedDate}.`,
          )
        );
      }),

    animalCount: yup.number().min(1, "Animals are required.").max(220, "Max 220 animals can be moved at a time."),
    reasonId: yup.string().required("Reason is a required field."),
    destinationPartnerId: yup.string(),
    destinationPartnerAddressId: yup.string(),
    destinationContactId: yup.string(),
    isDestinationIdSet: yup
      .bool()
      .required()
      .when(["destinationContactId", "destinationPartnerId"], {
        is: (destinationContactId: string, destinationPartnerId: string) =>
          (!destinationContactId && !destinationPartnerId) || (!!destinationContactId && !!destinationPartnerId),
        then: yup.bool().isTrue("Destination is a required field."),
      }),
  });
};

export const calculateMinimumValidSentAtDate = (animals: Array<AnimalFragment>) => {
  const filteredAnimals = animals.filter((a) => a?.dob != null || a?.dateMovedToFarm != null);

  const animalsSortedByDoB = orderBy(
    filteredAnimals,
    function (a) {
      return a.dob;
    },
    ["desc"],
  );
  const animalsSortedByDoF = orderBy(
    filteredAnimals,
    function (a) {
      return a.dateMovedToFarm;
    },
    ["desc"],
  );

  const mostRecentDoB = animalsSortedByDoB?.[0]?.dob;
  const mostRecentDoF = animalsSortedByDoF?.[0]?.dateMovedToFarm;

  if (mostRecentDoB && mostRecentDoF) {
    const parsedDoB = DateTime.fromISO(mostRecentDoB);
    const parsedDoF = DateTime.fromISO(mostRecentDoF);

    return {
      minimumValidSentAtDate: parsedDoB > parsedDoF ? parsedDoB.toJSDate() : parsedDoF.toJSDate(),
      passportNumber:
        parsedDoB > parsedDoF ? animalsSortedByDoB[0]?.passportNumber : animalsSortedByDoF[0]?.passportNumber,
    };
  } else if (mostRecentDoF) {
    return {
      minimumValidSentAtDate: DateTime.fromISO(mostRecentDoF).toJSDate(),
      passportNumber: animalsSortedByDoF[0]?.passportNumber,
    };
  } else if (mostRecentDoB) {
    return {
      minimumValidSentAtDate: DateTime.fromISO(mostRecentDoB).toJSDate(),
      passportNumber: animalsSortedByDoB[0]?.passportNumber,
    };
  } else {
    return {
      minimumValidSentAtDate: undefined,
      passportNumber: undefined,
    };
  }
};
