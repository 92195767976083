import { useQuery, QueryResult } from "@apollo/client";

import { getBUFromLocalStorage } from "helpers/storage";

import { GET_BUSINESS_UNIT_SETTINGS } from "api/BusinessUnits/queries";
import {
  GetBusinessUnitSettings,
  GetBusinessUnitSettingsVariables,
} from "api/BusinessUnits/types/GetBusinessUnitSettings";

export const useGetBusinessUnitSettings = (): QueryResult<
  GetBusinessUnitSettings,
  GetBusinessUnitSettingsVariables
> => {
  const { data, ...rest } = useQuery<GetBusinessUnitSettings, GetBusinessUnitSettingsVariables>(
    GET_BUSINESS_UNIT_SETTINGS,
    {
      variables: {
        businessUnit: getBUFromLocalStorage(),
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    },
  );

  return {
    data,
    ...rest,
  };
};
