import { memo, useContext, useState, FC } from "react";
// Libraries
import cn from "classnames";
// API
import { useExportAnimalsAsyncMutation } from "generated/graphql";
// Utils
import { CommonContext } from "config/commonProvider";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { isAnimalsFilterEmpty } from "helpers/myLivestock";
import { getBUFromLocalStorage } from "helpers/storage";
import { useBreakpoints, useHasFeature, useStateSpecies } from "hooks";
// Components
import AnimalsFilter, { AnimalsFilterProps } from "pages/MyLivestock/Animals/AnimalsFilter";
import { ExportAnimalsButton } from "./ExportAnimalsButton";
import { SearchAnimalInput, ClearFiltersButton, FiltersInUse, SavedFiltersSelect } from "components/MyLivestock";
import { Button, Flex, Spacer, Text, TablePaginationTarget, Icon } from "components";
// Resources
import styles from "./AnimalFiltersBlock.module.scss";
import tablePaginationStyles from "components/Common/Table/TablePagination/TablePagination.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setMenuState, setTaskId } from "state/asyncJobSlice";
import { RootState } from "state/store";

interface AnimalFiltersBlockProps extends AnimalsFilterProps {
  animalsCountLabel?: string;
  selectedAnimalIds?: string[];
  selectedCountLabel?: string | null;
  animalsToExport?: string[];
  countAnimalsToExport?: number;
  tableId: string;
  isSavedFiltersAvailable?: boolean;
  isSelectedAnimalsView?: boolean;
  isArchived?: boolean;
  onViewSelectionClick?: (flag: boolean) => void;
}

export type AnimalFilterBlockLabels = Pick<AnimalFiltersBlockProps, "animalsCountLabel" | "selectedCountLabel">;

const AnimalFiltersBlock: FC<AnimalFiltersBlockProps> = ({
  selectedCountLabel,
  selectedAnimalIds = [],
  excludedFields,
  animalsToExport,
  countAnimalsToExport = 0,
  isSavedFiltersAvailable = true,
  isSelectedAnimalsView,
  tableId,
  isArchived = false,
  onViewSelectionClick,
}) => {
  const { activeSpecies } = useStateSpecies();

  const [showFilters, setFiltersVisibility] = useState<boolean>(false);
  const {
    mainCommonState: { animalsFilters },
  } = useContext(CommonContext);
  const { getTableOptions } = useContext(TableOptionsContext);
  const [exportCsv] = useExportAnimalsAsyncMutation();
  const hasArchiveExportFeature = useHasFeature("ANIMALS_ARCHIVE_EXPORT");
  const { isBreakpointL } = useBreakpoints();

  const tableOptions = getTableOptions(tableId);
  const isAnimalFiltersEmpty = isAnimalsFilterEmpty(animalsFilters);
  const isHeaderHidden = isAnimalFiltersEmpty && !showFilters;
  const exportOrderBy = !!tableOptions?.sortBy?.length ? tableOptions?.sortBy : null;
  const dispatch = useDispatch();
  const { taskId } = useSelector((state: RootState) => state.asyncJob);

  const handleAnimalsExport = async (animals?: string[]): Promise<void> => {
    const { data } = await exportCsv({
      variables: {
        input: {
          animals,
          businessUnit: getBUFromLocalStorage(),
          isArchived,
          orderBy: exportOrderBy,
          animalTypeIds: activeSpecies ? [+activeSpecies.id] : null,
        },
      },
    });
    dispatch(setMenuState("expanded"));
    dispatch(setTaskId(data?.exportAnimalsAsync?.export?.id));
  };

  const handleFiltersVisibilityToggle = (): void => {
    setFiltersVisibility((filters) => !filters);
  };

  const handleShowSelectedAnimalsClick = (): void => {
    if (onViewSelectionClick) {
      onViewSelectionClick(true);
    }
  };

  const handleUnshowSelectedAnimalsClick = (): void => {
    if (onViewSelectionClick) {
      onViewSelectionClick(false);
    }
  };

  const showExportButton = !isArchived || (isArchived && hasArchiveExportFeature);
  const hasViewSelectedAnimalsFeature = isSelectedAnimalsView !== undefined;
  const caption = isSelectedAnimalsView
    ? `${selectedAnimalIds?.length} Selected - View animals`
    : `${selectedAnimalIds?.length} Selected - View selection`;

  if (isSelectedAnimalsView) {
    return (
      <Flex
        className={tablePaginationStyles.table_pagination_target}
        container
        containerAlignItems="flex-end"
        containerJustifyContent="flex-end"
        containerWrap="wrap"
      >
        <Flex item itemGutter itemAlignSelf="center">
          <Spacer allowDecimal baselineHeight={0.5} />
          <Button caption="Back to list" colour="grey" variant="hollow" onClick={handleUnshowSelectedAnimalsClick} />
          <Spacer allowDecimal baselineHeight={0.5} />
        </Flex>
        <Flex item itemGrow />
        <Flex item>
          <TablePaginationTarget tableId={`${tableId}_selected`} />

          <Spacer baselineHeight={1} />
        </Flex>
        <Flex item itemGutter className={styles.exportButton}>
          <ExportAnimalsButton
            isLoading={!!taskId}
            numAnimals={selectedAnimalIds.length}
            onClick={() => handleAnimalsExport(selectedAnimalIds)}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        className={tablePaginationStyles.table_pagination_target}
        container
        containerJustifyContent="flex-end"
        containerWrap="wrap"
      >
        <Flex
          container
          containerAlignItems="center"
          containerJustifyContent="space-between"
          item
          itemAlignSelf="center"
        >
          <Flex container item itemGutter>
            <SearchAnimalInput />
          </Flex>

          {hasViewSelectedAnimalsFeature && !!selectedAnimalIds.length && !isArchived ? (
            <Flex item itemGutter>
              <Button caption={caption} onClick={handleShowSelectedAnimalsClick} colour="green" />
            </Flex>
          ) : null}
        </Flex>

        <Flex item itemGrow />

        {selectedCountLabel && !hasViewSelectedAnimalsFeature ? (
          <Flex item itemGutter>
            <Text smaller>{selectedCountLabel}</Text>

            <Spacer baselineHeight={1} />
          </Flex>
        ) : null}

        <Flex item itemGutter>
          <TablePaginationTarget tableId={tableId} />

          <Spacer baselineHeight={1} />
        </Flex>

        {showExportButton ? (
          <Flex item className={styles.exportButton}>
            <ExportAnimalsButton
              isLoading={!!taskId}
              numAnimals={countAnimalsToExport}
              onClick={() => handleAnimalsExport(animalsToExport)}
            />
          </Flex>
        ) : null}

        <Flex item itemGutter>
          <button
            className={cn(styles.toggle_button, {
              [styles["toggle_button--active"]]: !isHeaderHidden || showFilters,
            })}
            onClick={handleFiltersVisibilityToggle}
          >
            <Text smaller>Filters</Text>

            <Icon className={styles.toggle_button__icon} name="filter" colour="black" size="small" />
          </button>
        </Flex>
      </Flex>

      <Flex className={styles.filters} item itemGutter>
        <Flex
          container
          containerAlignItems="center"
          containerJustifyContent="space-between"
          containerWrap={isBreakpointL ? "nowrap" : "wrap"}
          className={cn(styles.filters_header, {
            [styles["filters_header--header_hidden"]]: isHeaderHidden,
            [styles["filters_header--filters_hidden"]]: !showFilters,
          })}
        >
          <Flex className={styles.filters_header__active} item itemGutter itemOrder={isBreakpointL ? 1 : 3} xs="fill">
            <FiltersInUse />
          </Flex>

          <Flex className={styles.filters_header__clear_active} item itemGutter itemOrder={2}>
            <ClearFiltersButton />
          </Flex>

          {isSavedFiltersAvailable ? (
            <Flex
              className={styles.filters_header__saved}
              item
              itemGutter
              itemOrder={isBreakpointL ? 3 : 1}
              xs={12}
              m={6}
              l={undefined}
            >
              <Spacer baselineHeight={1} />

              <SavedFiltersSelect excludedFields={excludedFields} />

              <Spacer baselineHeight={1} />
            </Flex>
          ) : null}
        </Flex>

        <AnimalsFilter
          className={!showFilters ? styles["filters_fields--hidden"] : undefined}
          excludedFields={excludedFields}
          isSavedFiltersAvailable={isSavedFiltersAvailable}
          isArchived={isArchived}
        />
      </Flex>
    </>
  );
};

export default memo(AnimalFiltersBlock);
