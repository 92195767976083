import React, { useEffect, useRef, useState } from "react";
// Libraries
import cn from "classnames";
import Slider, { Settings as SliderSettings } from "react-slick";
// Types
import { ImageProps } from "components/Common/Image";
// Components
import { Icon, Image } from "components";
// Resources
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Carousel.module.scss";

export type CarouselImages = Omit<ImageProps, "aspectRatio">[];

interface CarouselProps {
  images: CarouselImages;
}

const maximumSlidesToShow = 4;

export const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [topSlider, setTopSlider] = useState<Slider | undefined>();
  const [bottomSlider, setBottomSlider] = useState<Slider | undefined>();
  const topSliderRef = useRef(null);
  const bottomSliderRef = useRef(null);

  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const totalImages = images.length;

  const isPreviousButtonDisabled = currentSlide === 1;
  const isNextButtonDisabled = currentSlide === totalImages;

  useEffect(() => {
    setTopSlider(topSliderRef.current || undefined);
    setBottomSlider(bottomSliderRef.current || undefined);
  }, []);

  const topSliderSettings: SliderSettings = {
    arrows: false,
    asNavFor: bottomSlider,
    beforeChange: (currentIndex, newIndex): void => setCurrentSlide(newIndex + 1),
    className: styles.carousel__highlight,
    infinite: false,
  };

  const bottomSliderSettings: SliderSettings = {
    arrows: false,
    asNavFor: topSlider,
    className: styles.carousel__navigation,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: maximumSlidesToShow,
    swipeToSlide: true,
  };
  const handleSliderPrev = (): void => bottomSlider?.slickPrev();
  const handleSliderNext = (): void => bottomSlider?.slickNext();

  return (
    <div className={styles.carousel}>
      <Slider {...topSliderSettings} ref={topSliderRef}>
        {images.map((image, index) => (
          <Image aspectRatio={{ horizontal: 16, vertical: 9 }} key={index} {...image} />
        ))}
      </Slider>

      {/* @todo: Replace entire div with `Pagination` component once ready */}
      <div className={styles.carousel__actions}>
        <button
          className={cn(styles.carousel__button, styles["navigation__button--previous"])}
          disabled={isPreviousButtonDisabled}
          onClick={handleSliderPrev}
          title="View previous image"
          type="button"
        >
          <Icon name="chevron" rotate={180} size="atom" />
        </button>

        <span className={styles.carousel__current_slide}>
          {currentSlide} of {images?.length}
        </span>

        <button
          className={cn(styles.carousel__button, styles["navigation__button--next"])}
          disabled={isNextButtonDisabled}
          onClick={handleSliderNext}
          title="View next image"
          type="button"
        >
          <Icon name="chevron" size="atom" />
        </button>
      </div>

      <Slider {...bottomSliderSettings} ref={bottomSliderRef}>
        {images.map((image, index) => (
          <Image aspectRatio={{ horizontal: 1, vertical: 1 }} key={index} {...image} />
        ))}
      </Slider>
    </div>
  );
};
