import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesLocationsPage: FC = () => {
  return (
    <EmptyStates
      animation={"locations"}
      title="Add animals to this location."
      // buttonCaption="Add animals"
    >
      <p>
        Use locations to log activities, administer medicines, move animals.
        <br />
        An animal can only be a member of 1 location.
        <br />
        <br />
        Click ‘Add Animals’, select the checkbox next to the animals in the table and click ‘Move to location’.
      </p>
    </EmptyStates>
  );
};
