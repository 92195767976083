import { FC, useEffect, useRef, useState } from "react";
import Tree from "react-d3-tree";
import { IconButton, LoadingOverlay } from "components";
import { buildTreeData, getMaxGeneration } from "./helpers";
import { FamilyTreeFilters, defaultFilterState } from "./components/FamilyTreeFilters";
import { FamilyTreeDisplaySettings, defaultDisplayState } from "./components/FamilyTreeDisplaySettings";
import { DetailsNode } from "./components/DetailsNode";
import { FamilyTreeDisplayState, FamilyTreeFiltersState } from "./types";
import { useGetFamilyTreeQuery } from "generated/graphql";
import { EmptyStatesFamilyTree } from "components/EmptyStates/FamilyTree";
import { FamilyTreeKey } from "./components/FamilyTreeKey";

interface AnimalFamilyTreeProps {
  animalId: string;
}
const containerStyles = {
  width: "100%",
  height: "100%",
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#fff",
};

export const AnimalFamilyTree: FC<AnimalFamilyTreeProps> = ({ animalId }) => {
  const { data, loading, error } = useGetFamilyTreeQuery({
    variables: { animalID: animalId, depth: 4 },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    console.error(error);
  }

  const animalData = data?.ancestorTree;
  const [filterState, setFilterState] = useState<FamilyTreeFiltersState>(defaultFilterState);
  const [displayState, setDisplayState] = useState<FamilyTreeDisplayState>(defaultDisplayState);
  const { generations } = filterState;
  const treeData = buildTreeData(animalData, generations);
  const maxGenerations = treeData ? getMaxGeneration(treeData) : 0;

  const { orientation, draggable, zoomable, collapsible, lineStyles } = displayState;
  const treeWrapperRef = useRef<HTMLDivElement>(null);
  const initialHeight = treeWrapperRef?.current?.offsetHeight || 350;
  const containerWidth = treeWrapperRef?.current?.offsetWidth || 1000;
  const initialWidth = containerWidth / 2;
  const [translate, setTranslate] = useState({ x: initialWidth + 90, y: initialHeight });
  const zoomLevel: number =
    {
      1: 2,
      2: 1,
      3: 0.7,
      4: orientation === "horizontal" ? 0.4 : 0.52,
      5: orientation === "horizontal" ? 0.3 : 0.45,
    }[generations >= maxGenerations ? maxGenerations : generations] || 0.7;

  const [zoom, setZoom] = useState(zoomLevel);

  const handleResize = () => {
    if (treeWrapperRef.current) {
      const containerWidth = treeWrapperRef.current.offsetWidth;
      const containerHeight = treeWrapperRef.current.offsetHeight;
      const centerWidth = containerWidth / 2;
      const centerHeight = containerHeight / 2;
      setTranslate({
        x: orientation === "horizontal" ? 260 : centerWidth,
        y: orientation === "horizontal" ? centerHeight : 450,
      });
      setZoom(zoomLevel);
    }
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => prevZoom - 0.1);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation, generations]);

  const renderForeignObjectNode = ({ nodeDatum, ...rdt3Props }) => {
    return (
      <DetailsNode
        nodeData={nodeDatum}
        toggleNode={rdt3Props.toggleNode}
        foreignObjectProps={{
          width: 200,
          height: 200,
          x: 15,
          y: orientation === "vertical" ? -75 : -50,
        }}
        filterState={filterState}
        treeData={treeData || { name: "", children: [] }}
      />
    );
  };

  if (!treeData) {
    return <EmptyStatesFamilyTree />;
  }

  return (
    <>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div className="flex flex-row h-[76vh]">
          <span className="mt-4 ml-4 rounded-md absolute w-56">
            <FamilyTreeFilters filterState={filterState} setFilterState={setFilterState} />
          </span>
          <div style={containerStyles} id="treeWrapper" ref={treeWrapperRef}>
            <Tree
              data={treeData}
              orientation={orientation}
              draggable={draggable}
              zoomable={zoomable}
              collapsible={collapsible}
              pathFunc={lineStyles}
              zoom={zoom}
              translate={translate}
              separation={
                orientation === "vertical" ? { siblings: 2, nonSiblings: 2 } : { siblings: 1, nonSiblings: 1 }
              }
              renderCustomNodeElement={renderForeignObjectNode}
              depthFactor={orientation === "vertical" ? -150 : maxGenerations < 4 ? 500 : 700}
            />
          </div>
          <span className="mt-4 absolute top-5 right-0 mr-36">
            <FamilyTreeKey />
          </span>
          <span className="mt-4 mr-4 absolute top-5 right-0">
            <FamilyTreeDisplaySettings displayState={displayState} setDisplayState={setDisplayState} />
          </span>
          <div className="absolute bottom-5 right-0 mr-4 flex flex-col gap-1 p-2 pb-6">
            <IconButton
              square
              disabled={!zoomable}
              iconName="plus"
              colour="grey"
              size="x-small"
              onClick={handleZoomIn}
            />
            <IconButton
              square
              disabled={!zoomable}
              iconName="minus"
              colour="grey"
              size="x-small"
              onClick={handleZoomOut}
            />
          </div>
        </div>
      )}
    </>
  );
};
