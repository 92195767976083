import React, { useEffect, useRef, useState } from "react";
import { Rectangle } from "recharts";

import styles from "./AnimalChartLabel.module.scss";

type AnimalChartRectangle = {
  height: number | undefined;
  left: number | undefined;
  top: number | undefined;
  width: number | undefined;
};

interface AnimalChartLabelProps {
  offset: number;
  position: string;
  rectFill: string | undefined;
  textFill: string | undefined;
  value: string;
  viewBox: { x: number; y: number };
}

const rectanglePadding = {
  top: 3,
  right: 5,
  bottom: 3,
  left: 5,
};

const AnimalChartLabel: React.FC<AnimalChartLabelProps> = ({
  value,
  rectFill,
  textFill,
  viewBox,
  offset,
  position,
}) => {
  const textRef = useRef<SVGTextElement | null>(null);
  const [rectangle, updateRectangle] = useState<AnimalChartRectangle | null>(null);

  const getRectangleDimensions = (): void => {
    const textNodeBCR = textRef?.current?.getBoundingClientRect();
    const chartNode = document.querySelector(".recharts-surface");
    const chartNodeBCR = chartNode?.getBoundingClientRect();

    if (textNodeBCR && chartNodeBCR) {
      updateRectangle({
        top: textNodeBCR.top - chartNodeBCR.top - rectanglePadding.top,
        left: textNodeBCR.left - chartNodeBCR.left - rectanglePadding.left,
        width: textNodeBCR.width + rectanglePadding.left + rectanglePadding.right,
        height: textNodeBCR.height + rectanglePadding.top + rectanglePadding.bottom,
      });
    }
  };

  useEffect(() => {
    getRectangleDimensions();
  }, [viewBox]);

  return (
    <g>
      {rectangle ? (
        <Rectangle
          x={rectangle.left}
          y={rectangle.top}
          width={rectangle.width}
          height={rectangle.height}
          fill={rectFill}
          radius={2}
        />
      ) : null}
      <text
        x={position === "top" ? viewBox.x : viewBox.x + offset}
        y={position === "top" ? viewBox.y - offset + 5 : viewBox.y + 5}
        fill={textFill}
        className={styles.labelText}
        textAnchor={position === "top" ? "middle" : "start"}
        ref={textRef}
      >
        {value}
      </text>
    </g>
  );
};

export default AnimalChartLabel;
