import React, { useEffect } from "react";
// Libraries
import NP from "number-precision";
import { FormikErrors, FormikProps } from "formik";
import getSymbolFromCurrency from "currency-symbol-map";
import { useRecoilValue, useSetRecoilState } from "recoil";
// Components
import { Flex, FieldNumber, Spacer, Text, Title } from "components";
import { OfferCalculation } from "./OfferCalculation";
// Utils
import { isPositiveInteger } from "trading/util";
import { formattedTotalPricePerHead, formattedPrice } from "trading/util/trading";
import { makeOfferType } from "state";
import { makeOfferPriceTotal, makeOfferPrice } from "state";
import { useGetCurrentBusinessUnit } from "hooks";

export interface FormValues {
  offerPrice: number | string;
  collectionDate: Date | null;
}

export type FormProps = FormikProps<FormValues>;

interface MakeOfferPropTypes {
  values: FormValues;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  askingPrice: number | null;
  askingPricePerHead: number | null;
  errors: FormikErrors<FormValues>;
  listingId: number;
  setFieldValue: <T>(field: string, value: T, shouldValidate?: boolean) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  quantity: number;
}
export const OfferPerHead = ({
  values,
  setDisabled,
  askingPrice,
  askingPricePerHead,
  errors,
  setFieldValue,
  handleChange,
  quantity = 0,
}: MakeOfferPropTypes): JSX.Element => {
  const { currencyCodeISO } = useGetCurrentBusinessUnit();
  const currencySymbol = getSymbolFromCurrency(currencyCodeISO);
  const offerType = useRecoilValue(makeOfferType);

  const setOfferPriceTotal = useSetRecoilState(makeOfferPriceTotal);

  const setOfferPrice = useSetRecoilState(makeOfferPrice);

  const askingPriceFormatted = askingPrice ? formattedPrice(+askingPrice, currencyCodeISO) : "\u2014";

  const totalAskingPerHead = askingPricePerHead
    ? formattedTotalPricePerHead(+askingPricePerHead, quantity, currencyCodeISO)
    : "\u2014";

  const totalOffer = values?.offerPrice ? +(+values?.offerPrice * quantity) : null;

  const total = totalOffer && formattedPrice(+totalOffer, currencyCodeISO);

  const offerPriceValidation =
    !isPositiveInteger(values.offerPrice) && (!!errors.offerPrice || !Boolean(values.offerPrice));

  const isDisableSaveButton = offerPriceValidation;

  useEffect(() => {
    setFieldValue("offerPrice", "", false);
  }, [offerType]);

  useEffect(() => {
    setDisabled(Boolean(isDisableSaveButton));
  }, [isDisableSaveButton]);

  useEffect(() => {
    values.offerPrice && setOfferPrice(+values.offerPrice);
  }, [values.offerPrice]);

  useEffect(() => {
    const offerTo2DP = NP.round(Number(totalOffer), 2);
    setOfferPriceTotal(offerTo2DP);
  }, [totalOffer]);

  return (
    <>
      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>Asking per head</Text>
        <Title secondary>{askingPriceFormatted}</Title>
      </Flex>

      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>No of Animals:</Text>
        <Title secondary>{quantity}</Title>
      </Flex>

      <Spacer baselineHeight={2} />

      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>Total asking price</Text>
        <Title secondary>{totalAskingPerHead}</Title>
      </Flex>

      <Spacer baselineHeight={2} />

      <Flex item itemGutter xs={2}>
        <FieldNumber
          error={errors.offerPrice}
          label={`Offer per head`}
          inputProps={{
            name: "offerPrice",
            autoFocus: true,
            step: 0.01,
            value: values.offerPrice,
            disabled: false,
            onChange: handleChange,
            placeholder: currencySymbol,
          }}
        />
      </Flex>

      <Spacer baselineHeight={3} />

      <Flex item itemGutter>
        <Text>Total offer</Text>
        <OfferCalculation total={total} />
      </Flex>
    </>
  );
};
