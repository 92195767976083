// React
import React from "react";
// Utils
import { SUPPLIER_MANAGEMENT } from "constants/Routes";
// Components
import EditSupplierForm from "pages/Settings/SupplierManagement/EditSupplier/EditSupplierForm/EditSupplierForm";
import { Breadcrumbs, Header, PageContent, PageHeader, Spacer } from "components";

const EditSupplier: React.FC = () => {
  const title = "Edit supplier";

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "My Suppliers",
              route: SUPPLIER_MANAGEMENT,
            },
          ]}
          currentPath={title}
        />

        <Header backButton title={title} />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />

        <EditSupplierForm />
      </PageContent>
    </>
  );
};

export default EditSupplier;
