import { FC } from "react";
import { getLiveStockSubTabs } from "helpers/myLivestock";
import { useHasFeature, useLivestockTabs, useStateSpecies, useGetBusinessUnitSettings } from "hooks";
import { AnimalList as AnimalsListOld } from "components/MyLivestock";
import { AnimalsList } from "animals/AnimalsList";
import { Header, PageHeader, PageContent, Spacer, Tabs } from "components";
import { EmptyStatesArchiveTab } from "components/EmptyStates/ArchiveTab";
import { TABLE_IDS } from "constants/Interface";

export const ArchiveAnimals: FC = () => {
  const { activeSpecies } = useStateSpecies();
  const LIVESTOCK_TABS = useLivestockTabs();
  const speciesActiveName = activeSpecies?.name || "Livestock";
  const title = `My ${speciesActiveName}`;
  const subTitle = `View and manage your ${speciesActiveName.toLowerCase()}`;
  let useServerPaginatedAnimalsList = useHasFeature("SERVER_SIDE_PAGINATION_FOR_ARCHIVE_LIST");
  const businessUnitSettings = useGetBusinessUnitSettings();

  const dateJoined = businessUnitSettings?.data?.businessUnit?.dateJoined
    ? new Date(businessUnitSettings.data.businessUnit.dateJoined)
    : null;

  const switchDate = new Date("2023-08-18");

  useServerPaginatedAnimalsList = (dateJoined && dateJoined > switchDate) || useServerPaginatedAnimalsList;

  return (
    <>
      <PageHeader>
        <Header title={title} subTitle={subTitle} />
        <Tabs tabs={LIVESTOCK_TABS} selectedTab={0} />
        <Tabs tabs={getLiveStockSubTabs()} selectedTab={1} grayBackgroundTabs />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={1} />
        {useServerPaginatedAnimalsList ? (
          <AnimalsList
            tableFilterIdentifier="animalsList"
            showSelectColumn={false}
            fromPage="livestock"
            showAnimalActionButtons={false}
            showIndividualAnimalActionButtons={false}
            isArchive={true}
            noResults={<EmptyStatesArchiveTab />}
            tableId={TABLE_IDS["ANIMAL_LIST_SERVER_ARCHIVE"]}
          />
        ) : (
          <AnimalsListOld isArchive noResults={<EmptyStatesArchiveTab />} />
        )}
      </PageContent>
    </>
  );
};
