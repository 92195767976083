// Utils
import { getFormattedNumber, getWeightUnitName } from "helpers/general";
// Types
import { MyLots_myLots_edges_node } from "trading/api/Liveweight/types/MyLots";
import { BuyingLotNode } from "trading/components/containers/liveweight/BuyerListings";

type Listing = BuyingLotNode | MyLots_myLots_edges_node;

export const getListingAnimalsFormattedGrowthRate = (listing: Listing, buWeightUnit: string): string | undefined => {
  if (listing == null) {
    return;
  }
  const listingBusinessUnit = "businessUnit" in listing ? listing?.businessUnit : null;
  // @ts-ignore
  const weightUnit = listingBusinessUnit ? getWeightUnitName(listingBusinessUnit.unitsOfMeasurement) : buWeightUnit;

  const formattedGrowthRateAverage = Number(listing.animalsAverageGrowthRate);

  if (!formattedGrowthRateAverage) return;

  return `${getFormattedNumber(formattedGrowthRateAverage)} ${weightUnit}`;
};
