import { useChartDataQuery } from "generated/graphql";
import React, { FC } from "react";

import styles from "./styles.module.scss";

interface IProps {
  chartTitle: string;
  dataQuery: string; //Use this to make a query asking for chart data
  businessUnitId: number;
  filters?: {
    [key: string]: string;
  };
}

export const TextChart: FC<IProps> = ({ chartTitle, dataQuery, businessUnitId, filters }) => {
  const { data } = useChartDataQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      chartSlug: dataQuery,
      businessUnitId: businessUnitId,
      dashboardSlug: chartTitle,
      filters: filters,
    },
  });
  return (
    <figure className={styles.container}>
      <div>{data?.chartData?.title}</div>
      <div className={styles.data}>{data?.chartData?.value}</div>
    </figure>
  );
};
