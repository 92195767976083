import { FC } from "react";
import { EmptyStates } from "components";
import { Link } from "react-router-dom";
import { useStateSpecies } from "hooks";
import { useHelp } from "hooks/useHelp";

export const EmptyStatesMyCattleTab: FC = () => {
  const { activeSpecies } = useStateSpecies();
  const { baseURL } = useHelp();

  if (!activeSpecies) {
    return null;
  }

  return (
    <EmptyStates animation={"searching"} title={`You haven't added any ${activeSpecies?.slug}.`}>
      <p>
        Add livestock by importing a csv file via our <Link to="/system/import">import page</Link>. <br />
        Alternatively, you can upload and manage them on your{" "}
        <a href={baseURL} target="_blank" rel="noopener noreferrer">
          mobile device
        </a>
        .
      </p>
    </EmptyStates>
  );
};
