import { FC, useContext, useEffect } from "react";
import { isEqual, isEqualWith } from "lodash";
import { CommonContext } from "config/commonProvider";
import { usePrevious, useStateSpecies } from "hooks";
import { DropdownMenu, ImageButton, LoadingOverlay } from "components";
import styles from "./DashboardSpeciesSelect.module.scss";
import { Species } from "state/speciesSlice";

interface DashboardSpeciesSelectProps {
  species: Species[];
}

// The `species.image` property returned by S3 always has a unique `expires` GET variable in the URL
const ignoreSpeciesImageURL = (objValue, otherValue, index): boolean | undefined => {
  if (index === "image") return true;

  return;
};

export const DashboardSpeciesSelect: FC<DashboardSpeciesSelectProps> = ({ species: speciesFromBU }) => {
  const { showNotification } = useContext(CommonContext);
  const { activeSpecies, speciesList, handleSetActiveSpecies, handleSetSpeciesList } = useStateSpecies();

  const previousSpeciesFromBU = usePrevious(speciesFromBU);

  useEffect(() => {
    if (!isEqualWith(previousSpeciesFromBU, speciesFromBU, ignoreSpeciesImageURL)) {
      handleSetSpeciesList(speciesFromBU);
    }
  }, [previousSpeciesFromBU, speciesFromBU]);

  useEffect(() => {
    if (speciesList != undefined && speciesList.length > 0 && activeSpecies === undefined) {
      handleSetActiveSpecies(speciesList[0]);
    }
  }, [activeSpecies, speciesList]);

  const previousSpeciesActive = usePrevious(activeSpecies);

  useEffect(() => {
    if (previousSpeciesActive && activeSpecies && !isEqual(previousSpeciesActive, activeSpecies)) {
      showNotification({
        autoHideDuration: 2000,
        message: `Successfully switched to ${activeSpecies.name}`,
      });
    }
  }, [activeSpecies]);

  const speciesCSS = activeSpecies ? `:root { --species-colour: ${activeSpecies.colour}; }` : null;

  return (
    <>
      {speciesCSS ? <style>{speciesCSS}</style> : null}

      <DropdownMenu>
        {activeSpecies ? (
          <DropdownMenu.Trigger>
            <ImageButton
              backgroundColour={activeSpecies.colour}
              fallbackText={activeSpecies.name.charAt(0)}
              icon={activeSpecies.image || undefined}
            />
          </DropdownMenu.Trigger>
        ) : (
          <LoadingOverlay customStyles={styles.dashboard_species_select__loading} />
        )}

        <DropdownMenu.Content>
          {speciesList?.map((species) => {
            const isCurrentSpecies = species.slug === activeSpecies?.slug;

            const handleClickSpecies = () => {
              handleSetActiveSpecies(species);
            };

            return (
              <DropdownMenu.Action
                colour={species.colour}
                icon={species.image || undefined}
                isActive={isCurrentSpecies}
                key={species.slug}
                onClick={handleClickSpecies}
                text={species.name}
              />
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu>
    </>
  );
};
