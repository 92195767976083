import { VFC } from "react";
// Utils
import { MY_LISTINGS, TRADING } from "constants/Routes";
// Containers
import { CreateEditListingForm } from "trading/components/containers";
// Components
import { Breadcrumbs, Header, PageHeader, PageContent, Spacer } from "components";

export const CreateListing: VFC = () => {
  const pageTitle = "Create a listing";

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Marketplace",
              route: `${TRADING}${MY_LISTINGS}`,
            },
          ]}
          currentPath={pageTitle}
        />
        <Header backButton title={pageTitle} />
        <Spacer baselineHeight={1} />
      </PageHeader>
      <PageContent>
        <Spacer baselineHeight={3} />
        <CreateEditListingForm />
      </PageContent>
    </>
  );
};
