import { FC } from "react";
import { EmptyStates } from "components";

const handleEmailLink = () => {
  const email = "support@breedr.co";
  const subject = "Supply Chain Enquiry";

  const mailtoUrl = `mailto:${email}?subject=${subject}`;
  window.open(mailtoUrl, "_blank");
};

export const EmptyStatesSupplyChains: FC = () => {
  return (
    <EmptyStates
      animation={"handshake"}
      title="Are you looking for a Supply Chain?"
      onClickButton={handleEmailLink}
      buttonCaption="Email us"
    >
      <div className={"text-md"}>
        We can help! Get in touch with us today to see how we can help you move your animals
      </div>
    </EmptyStates>
  );
};
