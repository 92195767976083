// API
import { BuyingLotQuery } from "generated/graphql";
import { OfferStatus } from "api/graphql-global-types";
// Utils
import { LOT_STATUSES } from "trading/constants";
import { getOfferStatuses, OfferStatuses } from "../getOfferStatuses";
// Types
import { StageProps, StageWithStatus } from "components/Common/Stages/Stage";

type StageStatus = Pick<StageWithStatus, "status" | "statusLabel"> | Record<string, never>;

export type ListingStatuses = keyof typeof LOT_STATUSES;

export const getBuyerListingOfferStatus = (
  offerStatuses: OfferStatuses,
  listingStatus: ListingStatuses,
): StageStatus => {
  if (listingStatus !== "CREATED" && listingStatus !== "AWAITING_PAYMENT") {
    return {
      status: "complete",
    };
  }

  if (offerStatuses[OfferStatus.ACCEPTED]) {
    return {
      status: "complete",
      statusLabel: "Offer Sent",
    };
  }

  if (offerStatuses[OfferStatus.PENDING]) {
    return {
      status: "pending",
      statusLabel: "Offer Sent",
    };
  }

  if (offerStatuses[OfferStatus.DECLINED]) {
    return {
      status: "failed",
      statusLabel: "Offer Rejected",
    };
  }

  return {
    status: "action",
    statusLabel: "Make an Offer",
  };
};

export const getBuyerListingPaymentStatus = (listingStatus: ListingStatuses): StageStatus => {
  switch (listingStatus) {
    case "AWAITING_PAYMENT": {
      return {
        status: "action",
        statusLabel: "Make Payment",
      };
    }
    case "BUYER_MADE_PAYMENT_TO_BREEDR": {
      return {
        status: "pending",
        statusLabel: "Payment Sent",
      };
    }
    case "BREEDR_RECEIVED_PAYMENT":
    case "SELLER_SENT_ANIMALS": {
      return {
        status: "complete",
        statusLabel: "Payment Secured",
      };
    }
    case "BUYER_RECEIVED_ANIMALS":
    case "BREEDR_MADE_PAYMENT": {
      return {
        status: "complete",
      };
    }
    default: {
      return {};
    }
  }
};

export const getBuyerListingDeliverStatus = (listingStatus: ListingStatuses): StageStatus => {
  switch (listingStatus) {
    case "BREEDR_RECEIVED_PAYMENT": {
      return {
        status: "action",
        statusLabel: "Awaiting Delivery",
      };
    }
    case "SELLER_SENT_ANIMALS": {
      return {
        status: "pending",
        statusLabel: "Livestock Sent",
      };
    }
    case "BUYER_RECEIVED_ANIMALS":
    case "BREEDR_MADE_PAYMENT": {
      return {
        status: "complete",
        statusLabel: "Livestock Received",
      };
    }
    default: {
      return {};
    }
  }
};

export const getBuyerListingCompleteStatus = (listingStatus: ListingStatuses): StageStatus => {
  switch (listingStatus) {
    case "BUYER_RECEIVED_ANIMALS":
    case "BREEDR_MADE_PAYMENT": {
      return {
        status: "complete",
        statusLabel: "Trade Complete",
      };
    }
    default: {
      return {};
    }
  }
};

export const getBuyerListingStages = (listing: BuyingLotQuery["buyingLot"]): StageProps[] => {
  const offers = listing?.offers || [];
  // get offer status
  const offerStatuses = getOfferStatuses(offers);
  const listingOfferStatus = getBuyerListingOfferStatus(offerStatuses, listing?.status as ListingStatuses);
  // get secure payment status
  const listingPaymentStatus = getBuyerListingPaymentStatus(listing?.status as ListingStatuses);
  // get deliver status
  const listingDeliverStatus = getBuyerListingDeliverStatus(listing?.status as ListingStatuses);
  // get complete status
  const listingCompleteStatus = getBuyerListingCompleteStatus(listing?.status as ListingStatuses);

  return [
    {
      icon: "transactionHandshake",
      iconSize: "large",
      title: "Offer",
      ...listingOfferStatus,
    },
    {
      icon: "securePayment",
      iconSize: "large",
      title: "Secure Payment",
      ...listingPaymentStatus,
    },
    {
      icon: "delivery",
      iconSize: "large",
      title: "Deliver",
      ...listingDeliverStatus,
    },
    {
      icon: "check",
      title: "Complete",
      ...listingCompleteStatus,
    },
  ];
};
