import { useContext, useState, useEffect } from "react";
import { useQuery, QueryResult } from "@apollo/client";
import { getBUFromLocalStorage } from "helpers/storage";
import { GET_MY_ANIMALS_LIST } from "api/MyLivestock/Animal/queries";
import { CommonContext } from "config/commonProvider";
import { animalFilters as filter } from "components/MyLivestock/Animals/AnimalFilters";
import {
  GetAnimalsList,
  GetAnimalsList_animalsExtended_animals,
  GetAnimalsListVariables,
} from "api/MyLivestock/Animal/types/GetAnimalsList";
import { GetAnimalsFromField_field_animals } from "api/MyLivestock/Fields/types/GetAnimalsFromField";
export interface AnimalsList extends QueryResult {
  data: GetAnimalsList_animalsExtended_animals[] | undefined;
  unfilteredAnimals: (GetAnimalsList_animalsExtended_animals | GetAnimalsFromField_field_animals | null)[];
  loading: boolean;
  animalsCount: number;
}

export const useAnimals = (
  defaultAnimals?:
    | (GetAnimalsList_animalsExtended_animals | GetAnimalsFromField_field_animals | null)[]
    | undefined
    | null,
  variables?: GetAnimalsListVariables,
): AnimalsList => {
  const { mainCommonState } = useContext(CommonContext);
  const { animalsFilters = {} } = { ...mainCommonState };
  const [filteredAnimals, setFilteredAnimals] = useState<(GetAnimalsList_animalsExtended_animals | null)[]>([]);

  const { data, loading, ...rest } = useQuery<GetAnimalsList, GetAnimalsListVariables>(GET_MY_ANIMALS_LIST, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
      ...variables,
    },
    skip: Boolean(defaultAnimals),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const animals = defaultAnimals ? defaultAnimals : data?.animalsExtended?.animals;
  const animalsCount = defaultAnimals ? defaultAnimals.length : data?.animalsExtended?.animalsCount;

  useEffect(() => {
    let filteredAnimals = [];

    if (defaultAnimals && defaultAnimals.length > 0) {
      // @ts-expect-error
      filteredAnimals = filter(defaultAnimals, animalsFilters);
    } else if (animals && animals?.length > 0) {
      // @ts-expect-error
      filteredAnimals = filter(animals, animalsFilters);
    }

    setFilteredAnimals(filteredAnimals);
  }, [defaultAnimals, animals, animalsFilters]);

  return {
    data: filteredAnimals as GetAnimalsList_animalsExtended_animals[],
    loading,
    animalsCount: animalsCount || 0,
    unfilteredAnimals: animals || [],
    ...rest,
  };
};
