import React, { FC } from "react";
import { PageMenu, PageMenuLink } from "components";
import { useDashboardsQuery } from "generated/graphql";

export const FarmerReportsMenu: FC = () => {
  //Fetch reports list...
  const { data: data } = useDashboardsQuery({
    fetchPolicy: "network-only",
  });
  return (
    <PageMenu title="Farmer Reports" loading={false}>
      {data?.dashboards?.map((d) => {
        return (
          <PageMenuLink id={d?.slug} key={d?.slug} path={"/system/reports-new/" + d?.slug} caption={d?.title || ""} />
        );
      })}
    </PageMenu>
  );
};
