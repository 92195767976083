import { useContext, useMemo, useState, VFC } from "react";
import { useMutation } from "@apollo/client";
import { Column } from "react-table";
import { MARK_LOT_LIVESTOCK_AS_SHIPPED } from "trading/api/Liveweight/mutations";
import { LotSellerSentAnimals, LotSellerSentAnimalsVariables } from "trading/api/Liveweight/types/LotSellerSentAnimals";
import { MyLotQuery, OfferPriceUnit, OfferStatus } from "generated/graphql";
import { CommonContext } from "config/commonProvider";
import { TABLE_IDS } from "constants/Interface";
import { LOT_STATUSES } from "trading/constants";
import { getFormattedPrice } from "helpers/general";
import { getBUFromLocalStorage } from "helpers/storage";
import { SellerListingOffersProps } from "..";
import { SellerListingOfferModal } from "./SellerListingOfferModal";
import { Button, Flex, Pill, Table, TableHeaderCaptions } from "components";
import styles from "./SellerListingOffersTable.module.scss";
import { useFormatDate } from "hooks";

const COLUMN_IDS = {
  BUYER: "buyer",
  CREATED_BY: "createdBy",
  PRICE: "price",
  PRICE_PER_KG: "pricePerKg",
  BUSINESS_UNIT: "businessUnit",
  STATUS: "status",
  COLLECTION_DATE: "collectionDate",
  TOTAL_PRICE: "totalPrice",
};

const INITIAL_SORT_BY = [
  {
    id: "price",
    desc: true,
  },
];

type Offers = NonNullable<MyLotQuery["myLot"]>["lotOffers"];
type Offer = Offers[number];

export const SellerListingOffersTable: VFC<SellerListingOffersProps> = ({
  listingId,
  listingStatus,
  lotConfigurableSellerMessage,
  offers,
  onAfterMutateListing,
}) => {
  const { showNotification } = useContext(CommonContext);
  const { getFormatDate } = useFormatDate();
  const [isModalActive, setIsModalActive] = useState<boolean>(false);

  const [offer, setOffer] = useState<Offer | null>(null);
  const [isOfferDecisionAccept, setIsOfferDecisionAccept] = useState<boolean | null>(null);
  const hasOfferDecision = isOfferDecisionAccept !== null;

  const memoizedData = useMemo(() => offers, [offers]);

  const handleCloseModal = (): void => {
    setIsModalActive(false);
  };

  const handleOpenModal = (offer: Offer, newIsOfferDecisionAccept: boolean): void => {
    setOffer(offer);
    setIsOfferDecisionAccept(newIsOfferDecisionAccept);
    setIsModalActive(true);
  };

  const [markLotLivestockAsShipped] = useMutation<LotSellerSentAnimals, LotSellerSentAnimalsVariables>(
    MARK_LOT_LIVESTOCK_AS_SHIPPED,
  );

  const handleMarkAsShipped = async (): Promise<void> => {
    try {
      const { data } = await markLotLivestockAsShipped({
        variables: {
          input: {
            businessUnit: getBUFromLocalStorage(),
            id: listingId,
          },
        },
      });

      if (data?.lotSellerSentAnimals?.errors) {
        showNotification({
          message: "We were unable to mark animals as shipped, please try again.",
          variant: "error",
        });
      } else {
        onAfterMutateListing();
      }
    } catch (error) {
      showNotification({
        message: "Error marking animals as shipped.",
        variant: "error",
      });
    }
  };
  //@ts-expect-error
  const columns: Column<Offer>[] = useMemo(
    () => [
      {
        id: COLUMN_IDS.BUYER,
        Header: "Buyer",
        accessor: ({ createdBy }): string | null =>
          createdBy?.firstName || createdBy?.lastName ? `${createdBy.firstName} ${createdBy?.lastName}` : null,
        Cell: ({ row }): string => row.values[COLUMN_IDS.BUYER] || "\u2014",
      },
      {
        id: COLUMN_IDS.BUSINESS_UNIT,
        Header: "Farm name",
        accessor: ({ businessUnit }): string | null => (businessUnit?.name ? `${businessUnit.name}` : null),
        Cell: ({ row }): string => row.values[COLUMN_IDS.BUSINESS_UNIT] || "\u2014",
      },
      {
        id: COLUMN_IDS.PRICE,
        Header: <TableHeaderCaptions title="Amount" subtitle="Per head" />,
        accessor: ({ currency, offeredNumber, price, priceUnit }): string | null => {
          if (priceUnit === OfferPriceUnit.Total) {
            return price ? `${getFormattedPrice(price / offeredNumber, currency?.code)}` : null;
          } else if (priceUnit === OfferPriceUnit.Head) {
            return price ? `${getFormattedPrice(price, currency?.code)}` : null;
          }
          return null;
        },
        Cell: ({ row }): string => row.values[COLUMN_IDS.PRICE] || "\u2014",
      },
      {
        id: COLUMN_IDS.PRICE_PER_KG,
        Header: <TableHeaderCaptions title="Amount" subtitle="Per kg" />,
        accessor: ({ currency, price, priceUnit }): string | null =>
          price && priceUnit === OfferPriceUnit.Kg ? `${getFormattedPrice(price, currency?.code)}` : null,
        Cell: ({ row }): string => row.values[COLUMN_IDS.PRICE_PER_KG] || "\u2014",
      },
      {
        id: COLUMN_IDS.COLLECTION_DATE,
        Header: <TableHeaderCaptions title="Collection Date" />,
        accessor: ({ collectionDate, priceUnit }): string | null =>
          collectionDate && priceUnit === OfferPriceUnit.Kg ? getFormatDate(collectionDate) : null,
        Cell: ({ row }): string => row.values[COLUMN_IDS.COLLECTION_DATE] || "\u2014",
      },
      {
        id: COLUMN_IDS.TOTAL_PRICE,
        Header: <TableHeaderCaptions title="Total Price" />,
        accessor: ({ currency, totalPriceOnCreation }): string | null =>
          totalPriceOnCreation ? `${getFormattedPrice(totalPriceOnCreation, currency?.code)}` : null,
        Cell: ({ row }): string => row.values[COLUMN_IDS.TOTAL_PRICE] || "\u2014",
      },
      {
        id: COLUMN_IDS.STATUS,
        sticky: "right",
        minWidth: 250,
        Cell: function renderDecisionCells({ row }): JSX.Element | null {
          const status = row.original.status;
          const isPending = status === OfferStatus.Pending;
          const isAccepted = status === OfferStatus.Accepted;
          const isPaid = status === OfferStatus.Paid;

          const hasBreedrReceivedPayment = listingStatus === LOT_STATUSES.BREEDR_RECEIVED_PAYMENT;

          // We need to confirm the intended offer status updates as offers never update further than "ACCEPTED"
          const canMarkAsShipped = (isPaid || isAccepted) && hasBreedrReceivedPayment;

          const decisionPillColour = isAccepted ? "green" : "yellow";

          const handleRejectOffer = (): void => handleOpenModal(row.original, false);
          const handleAcceptOffer = (): void => handleOpenModal(row.original, true);

          return (
            <Flex container containerJustifyContent="flex-end" item itemBasisFill>
              {isPending ? (
                <>
                  <Button caption="Reject offer" colour="grey" variant="hollow" onClick={handleRejectOffer} />

                  <Button caption="Accept offer" className={styles.accept_offer_button} onClick={handleAcceptOffer} />
                </>
              ) : canMarkAsShipped ? (
                <Button caption="Mark as shipped" onClick={handleMarkAsShipped} />
              ) : (
                <Pill caption={`OFFER ${status}`} colour={decisionPillColour} width="auto" />
              )}
            </Flex>
          );
        },
        accessor: "status",
        disableSortBy: true,
      },
    ],
    [],
  );

  return (
    <>
      <Table<Offer>
        columns={columns}
        data={memoizedData}
        tableId={TABLE_IDS.BUYER_OFFERS_TABLE}
        initialSortBy={INITIAL_SORT_BY}
      />

      {hasOfferDecision && offer != null ? (
        <SellerListingOfferModal
          buyerName={{ firstName: offer.createdBy?.firstName, lastName: offer.createdBy?.lastName }}
          isActive={isModalActive}
          isOfferDecisionAccept={!!isOfferDecisionAccept}
          lotConfigurableSellerMessage={lotConfigurableSellerMessage}
          offerAnimalsQuantity={offer.offeredNumber || 0}
          offerId={offer.id}
          offerPrice={offer.price || 0}
          offerPriceUnit={offer.priceUnit}
          offerTotalPriceOnCreation={offer.totalPriceOnCreation}
          onAfterMutateListing={onAfterMutateListing}
          onCloseModal={handleCloseModal}
        />
      ) : null}
    </>
  );
};
