import { Header, PageContent, PageHeader, Spacer, Tabs } from "components";
import { SubTab } from "components/Common/Tabs";
import {
  BU_ID,
  CREATE_MOVE_OFF,
  CREATE_MOVE_ON,
  DELIVERIES,
  DRAFTS,
  MOVE_OFF,
  MOVE_ON,
  SUBMITTED,
} from "constants/Routes";
import { DeliveriesTable } from "deliveries/components/DeliveriesTable";
import { DeliveryDirectionEnum, DeliveryStatusEnum } from "generated/graphql";
import { useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { useHasFeature } from "hooks/useHasFeature";

export const Deliveries = () => {
  const { deliveriesTabType, deliveriesSubTabType } = useParams<DeliveriesRootPageParams>();
  const [currentSelectedTab, setCurrentSelectedTab] = useState<number>(0);
  const [currentSelectedSubTab, setCurrentSelectedSubTab] = useState<number>(0);

  const showMoveOnTab = useHasFeature("INWARD_MOVEMENTS");
  const showCreateMoveOnButton = useHasFeature("CREATE_INWARD_MOVEMENTS");

  const history = useHistory();

  const isMoveOn = history.location.pathname.includes(`${MOVE_ON}`);

  const deliveriesTabs: SubTab[] = useMemo(
    () => [
      {
        key: 0,
        name: "Move Off",
        route: `${DELIVERIES}${MOVE_OFF}${DRAFTS}`,
        value: "move-off",
      },
      {
        key: 1,
        name: "Move On",
        route: `${DELIVERIES}${MOVE_ON}${DRAFTS}`,
        value: "move-on",
        skip: !showMoveOnTab,
      },
    ],
    [showMoveOnTab],
  );

  const subTabs: SubTab[] = useMemo(
    () => [
      {
        key: 0,
        name: "Drafts",
        route: `${DELIVERIES}/${deliveriesTabs[currentSelectedTab]?.value}${DRAFTS}`,
        value: "drafts",
      },
      {
        key: 1,
        name: "Submitted",
        route: `${DELIVERIES}/${deliveriesTabs[currentSelectedTab]?.value}${SUBMITTED}`,
        value: "submitted",
      },
    ],
    [currentSelectedTab, deliveriesTabs],
  );

  useEffect(() => {
    const selectedTabKey: number = deliveriesTabs.find((item) => item.value === deliveriesTabType)?.key || 0;

    setCurrentSelectedTab(selectedTabKey);
  }, [deliveriesTabs, deliveriesTabType]);

  useEffect(() => {
    const selectedSubTabKey = subTabs.find((item) => item.value === deliveriesSubTabType)?.key || 0;

    setCurrentSelectedSubTab(selectedSubTabKey);
  }, [deliveriesSubTabType, subTabs]);

  return (
    <>
      <PageHeader>
        <Header title="Movement Dashboard" subTitle="View and manage your movements." />
        <Tabs tabs={deliveriesTabs} selectedTab={currentSelectedTab} />
        <Tabs tabs={subTabs} selectedTab={currentSelectedSubTab} grayBackgroundTabs />
      </PageHeader>

      <PageContent
        floatingIconTextButton={
          history.location.pathname.includes(`${DELIVERIES}${MOVE_ON}`) && !showCreateMoveOnButton
            ? undefined
            : {
                iconName: "plus",
                onClick: () => {
                  history.push(`${DELIVERIES}${isMoveOn ? CREATE_MOVE_ON : CREATE_MOVE_OFF}`, {
                    prevUrl: location.pathname,
                  });
                },
                text: isMoveOn ? "Create Move On" : "Create Move Off",
              }
        }
      >
        <Spacer baselineHeight={3} />
        <Switch>
          <Route exact path={`${BU_ID}${DELIVERIES}${MOVE_OFF}${DRAFTS}`}>
            <DeliveriesTable direction={DeliveryDirectionEnum.Outward} status={DeliveryStatusEnum.Draft} />
          </Route>
          <Route exact path={`${BU_ID}${DELIVERIES}${MOVE_OFF}${SUBMITTED}`}>
            <DeliveriesTable direction={DeliveryDirectionEnum.Outward} status={DeliveryStatusEnum.Committed} />
          </Route>
          {showMoveOnTab ? (
            <>
              <Route exact path={`${BU_ID}${DELIVERIES}${MOVE_ON}${DRAFTS}`}>
                <DeliveriesTable direction={DeliveryDirectionEnum.Inward} status={DeliveryStatusEnum.Draft} />
              </Route>
              <Route exact path={`${BU_ID}${DELIVERIES}${MOVE_ON}${SUBMITTED}`}>
                <DeliveriesTable direction={DeliveryDirectionEnum.Inward} status={DeliveryStatusEnum.Committed} />
              </Route>
            </>
          ) : null}
          <Redirect to={`${BU_ID}${DELIVERIES}${MOVE_OFF}${DRAFTS}`} />
        </Switch>
      </PageContent>
    </>
  );
};
