import React from "react";

import { Header, PageContent, PageHeader, Spacer } from "components";
import { Integration } from "./components/Integration";

const Integrations: React.FC = () => (
  <>
    <PageHeader>
      <Header title="Integrations" subTitle="Add third party tools to your Breedr account" />
    </PageHeader>

    <PageContent>
      <Spacer baselineHeight={3} />
      <Integration />
    </PageContent>
  </>
);

export default Integrations;
