// Types
import { BuyingLotQuery, MyLotQuery, OfferStatus } from "generated/graphql";

export type OfferStatuses = Partial<Record<OfferStatus, boolean>> | Record<string, never>;

type Offers = NonNullable<MyLotQuery["myLot"]>["lotOffers"] | NonNullable<BuyingLotQuery["buyingLot"]>["offers"];

export const getOfferStatuses = (offers: Offers = []): OfferStatuses => {
  const offerStatuses: OfferStatuses = {};

  offers?.forEach((item) => {
    if (item?.status) {
      offerStatuses[item.status] = true;
    }
  });

  return offerStatuses;
};
