import React from "react";
import { useHistory } from "react-router-dom";
// Constants
import {
  DASHBOARD,
  SETTINGS,
  BUSINESS_SETTINGS,
  MEDICINE_CABINET,
  ANIMALS,
  CREATE_GROUP,
  FIELDS,
  CREATE_FIELD,
  GROUPS,
  USER_SETTINGS,
  MANAGEMENT_SETTINGS,
  TAGS,
  TAG_VIEW,
} from "constants/Routes";
// Hooks
import { useEmptyState } from "hooks";
// Components
import { Button, Flex, Spacer, WYSIWYG, Paper } from "components";
// Types
import { EmptyStateButton, EmptyStatePage } from "api/graphql-global-types";

import styles from "./styles.module.scss";
import { EmptyStatesTagsPage } from "components/EmptyStates/TagsPage";

const BUTTON_PATHS = {
  [EmptyStateButton.ADD_CONNECTED_SYSTEM]: `${SETTINGS}${BUSINESS_SETTINGS}`,
  [EmptyStateButton.ANIMAL_LIST_VIEW]: ANIMALS,
  [EmptyStateButton.BCMS_REQUEST_CREDENTIALS]: `${SETTINGS}${BUSINESS_SETTINGS}`,
  [EmptyStateButton.CONNECTED_SYSTEMS]: `${SETTINGS}${BUSINESS_SETTINGS}`,
  [EmptyStateButton.CREATE_GROUP]: CREATE_GROUP,
  [EmptyStateButton.FARM_ADDRESSES]: `${SETTINGS}${BUSINESS_SETTINGS}`,
  [EmptyStateButton.FIELD_CREATE]: CREATE_FIELD,
  [EmptyStateButton.FIELD_LIST]: FIELDS,
  [EmptyStateButton.GROUP_LIST_VIEW]: GROUPS,
  [EmptyStateButton.HOMEPAGE]: DASHBOARD,
  [EmptyStateButton.MEDICINE_CABINET_VIEW]: MEDICINE_CABINET,
  [EmptyStateButton.SETTINGS]: `${SETTINGS}${USER_SETTINGS}`,
  [EmptyStateButton.USER_MANAGEMENT]: `${SETTINGS}${MANAGEMENT_SETTINGS}`,
  [EmptyStateButton.TAGS_LIST_VIEW]: TAGS,
  [EmptyStateButton.TAG_VIEW]: TAG_VIEW,
};

type Props = {
  page: keyof typeof EmptyStatePage;
};

export const EmptyState: React.FC<Props> = ({ page: emptyStatePage }) => {
  const history = useHistory();
  const { data } = useEmptyState(emptyStatePage);

  // This is a temporary fix until I can add BE code for tags empty state
  if (emptyStatePage === EmptyStatePage.TAG) {
    return <EmptyStatesTagsPage />;
  }

  if (!data?.emptyState) {
    return null;
  }

  const { button, showButton, buttonLabel, buttonLink, text, image } = data.emptyState;

  const handleButtonClick = (): void => {
    if (button === EmptyStateButton.EMAIL) {
      window.open(`mailto:${buttonLink}`);
    } else if (button === EmptyStateButton.URL) {
      window.open(buttonLink);
    } else if (button) {
      history.push(BUTTON_PATHS[button]);
    }
  };

  const isEmptyInfoVisible = Boolean(text || image);
  const isButtonVisible = Boolean(button && showButton && buttonLabel);

  return (
    <Flex item itemGutter container containerDirection="column" containerAlignItems="center">
      {isEmptyInfoVisible ? (
        <Paper className={styles.empty_state}>
          <Spacer baselineHeight={2} />
          {text ? (
            <>
              <WYSIWYG content={text} />
              <Spacer baselineHeight={2} />
            </>
          ) : null}
          {image ? (
            <>
              <img src={image} alt={buttonLabel || "empty state"} className={styles.empty_state__image} />
              <Spacer baselineHeight={3} />
            </>
          ) : null}
        </Paper>
      ) : null}
      {isButtonVisible ? (
        <>
          <Spacer baselineHeight={2} />
          <Button caption={buttonLabel} onClick={handleButtonClick} />
        </>
      ) : null}
    </Flex>
  );
};
