import { Field, Flex, InputRadio } from "components";
import { AnimalType } from "trading/hooks/useGetCategoryListings";
import { ChangeEvent } from "react";
import { LiveweightFiltersState } from "trading/hooks/useListingFiltersServerSide/config";

interface CategortyFilterProps {
  animalTypes: AnimalType[];
  handleChangeCategoryCheckbox: ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>) => void;
  filters: LiveweightFiltersState;
}

export const CategoryFilters: React.FC<CategortyFilterProps> = ({
  handleChangeCategoryCheckbox,
  animalTypes,
  filters,
}) => {
  return (
    <>
      {animalTypes?.map((animalType) => {
        return (
          <Field key={animalType?.id} labelFor="listingCategory" label={animalType?.name}>
            <Flex container containerDirection="column" containerWrap="nowrap">
              {animalType?.categoriesOfSale?.map((category, index) => {
                return category?.id && category?.category ? (
                  <Flex key={"listingCategory" + index}>
                    <InputRadio
                      checked={filters.categoryOfSale === category.id}
                      name={category?.id?.toString()}
                      label={category?.category}
                      onChange={handleChangeCategoryCheckbox}
                      value={String(category?.id)}
                    />
                  </Flex>
                ) : null;
              })}
            </Flex>
          </Field>
        );
      })}
    </>
  );
};
