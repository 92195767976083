import React from "react";

import pure from "recompose/pure";

import cn from "classnames";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Error from "@material-ui/icons/Error";

import InfoTooltip from "components/Common/InfoTooltip";

import styles from "./styles.module.css";

interface Props {
  type: string;
  label: string;
  value?: string;
  error?: string;
  id?: string;
  autoComplete?: string;
  disabledText?: string;
  shrinkType?: boolean;
  disabled?: boolean;
  errorTextStyle?: string;
  labelStyles?: Record<string, unknown>;
  errorStatus?: boolean;
  stylesClasses?: Record<string, unknown>;
  inputProps: Record<string, unknown>;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const errorNotifications = (errorStatus): React.ReactNode | null =>
  errorStatus ? (
    <InputAdornment position="end">
      <Error />
    </InputAdornment>
  ) : null;

const Input: React.FC<Props> = ({
  label,
  type,
  value,
  error,
  shrinkType,
  errorStatus,
  errorTextStyle,
  stylesClasses,
  handleChange,
  inputProps,
  autoComplete,
  labelStyles,
  disabled,
  disabledText,
  ...rest
}) => {
  const errorStyle = error || error === "" ? styles.error : null;
  const errorStatusStyles = errorStatus ? styles.errorStatus : null;
  const cssStyle = cn(styles.inputStyles, stylesClasses, errorStyle, errorStatusStyles);

  return (
    <div className={styles.inputWrapper}>
      {disabled ? <InfoTooltip text={disabledText || "This field is set by admin"} /> : null}
      <div className={styles.textFieldWrapper}>
        <TextField
          autoComplete={autoComplete || "off"}
          label={label}
          type={type}
          value={value}
          disabled={disabled}
          className={cssStyle}
          InputLabelProps={{
            shrink: shrinkType,
            style: {
              fontSize: 12,
              color: "#747474",
              marginTop: 2,
              marginLeft: 16,
              ...labelStyles,
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: errorNotifications(errorStatus),
            ...inputProps,
          }}
          onChange={handleChange}
          {...rest}
        />
        <p className={cn(styles.wrapperTextRequired, errorTextStyle)}>{error}</p>
      </div>
    </div>
  );
};

export default pure(Input);
