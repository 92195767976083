import { FC } from "react";
import { sortBy } from "lodash";
import { NavLink } from "react-router-dom";
import { BUSINESS_SETTINGS, SETTINGS } from "constants/Routes";
import { BU_TYPES } from "trading/constants";
import { useGetBusinessUnitUserRole, useHasFeature } from "hooks";
import { DashboardBusinessUnitSelect, DashboardSpeciesSelect, DashboardUser } from "containers/dashboard";
import { LoadingOverlay, Flex, SnackBarNotification } from "components";
import styles from "./DashboardHeader.module.scss";
import { useGetBusinessUnitsQuery } from "generated/graphql";
import { Species } from "state/speciesSlice";
import { getBUFromLocalStorage, getBUTypeFromLocalStorage } from "helpers/storage";

export const DashboardHeader: FC = () => {
  const { isAdmin } = useGetBusinessUnitUserRole();
  const selectedBUType = getBUTypeFromLocalStorage();
  const selectedBU = getBUFromLocalStorage();
  const currentBusinessUnitID = selectedBU.toString();
  const isProcessor = selectedBUType === BU_TYPES.processor;
  const isShowNagBCMSMessage: boolean = useHasFeature("SHOW_NAG_MESSAGE_ABOUT_BCMS");

  const { data, loading: isLoadingGetBusinessUnits } = useGetBusinessUnitsQuery({ fetchPolicy: "network-only" });

  const businessUnits = data?.businessUnits || [];
  const currentBusinessUnit = businessUnits.find((item) => item?.id === currentBusinessUnitID);

  const isBusinessUnitedIntegratedWithBCMS = !currentBusinessUnit?.warnings.IS_NOT_BCMS_INTEGRATED;

  const currentBusinessUnitSpecies = sortBy(currentBusinessUnit?.farmAnimalTypes, "position")
    .filter(({ enabled }) => enabled)
    .map(
      (animalType): Species => ({
        ...animalType,
      }),
    );

  const hasSpecies = currentBusinessUnitSpecies.length > 0;

  return (
    <>
      {!isLoadingGetBusinessUnits && !isBusinessUnitedIntegratedWithBCMS && !isProcessor && isShowNagBCMSMessage ? (
        <SnackBarNotification
          open={true}
          variant={"error"}
          message={
            isAdmin ? (
              <span>
                This farm is not connected to a regulatory system. To complete setup, go to{" "}
                <NavLink className={styles.link} to={`${SETTINGS}${BUSINESS_SETTINGS}`}>
                  Farm Settings
                </NavLink>
              </span>
            ) : (
              `This farm is not connected to a regulatory system. ` +
              `Please see your farm's administrator to complete the setup.`
            )
          }
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        />
      ) : null}

      <Flex className={styles.dashboard_header} container containerJustifyContent="flex-end">
        <Flex
          className={styles.dashboard_header__inner}
          container
          containerWrap="nowrap"
          item
          xs={12}
          m={6}
          l={4}
          xl={3}
        >
          <Flex className={styles.dashboard_header__business_unit_select} item itemGrow itemGutter>
            {isLoadingGetBusinessUnits ? (
              <LoadingOverlay customStyles={styles.dashboard_header__business_unit_select_loading} />
            ) : (
              <DashboardBusinessUnitSelect businessUnits={businessUnits} />
            )}
          </Flex>

          {hasSpecies ? (
            <Flex className={styles.dashboard_header__species_select} item>
              {isLoadingGetBusinessUnits ? (
                <LoadingOverlay customStyles={styles.dashboard_header__species_select_loading} />
              ) : (
                <DashboardSpeciesSelect species={currentBusinessUnitSpecies} />
              )}
            </Flex>
          ) : null}

          <Flex item itemGutter itemNoShrink>
            <DashboardUser />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
