import { Flex, Header, InputSelect, PageHeader, Spacer, Spinner } from "components";
import { useBreakpoints } from "hooks";
import { ChangeEvent, FC } from "react";
import { InputSelectOptionWithTitles } from "components/Common/Field/Select";
import { useGetStrippedBusinessUnitsQuery } from "generated/graphql";

interface SubscriptionHeaderProps {
  handleChangeBusinessUnit: (event: ChangeEvent<HTMLSelectElement>) => void;
  currentBU: number | null;
  title?: string;
}

export const SubscriptionHeader: FC<SubscriptionHeaderProps> = ({
  handleChangeBusinessUnit,
  currentBU,
  title = "Subscription Details",
}) => {
  const { isBreakpointM, isBreakpointS } = useBreakpoints();
  const { data: strippedBuData, loading } = useGetStrippedBusinessUnitsQuery();

  const businessUnits =
    strippedBuData?.businessUnits?.filter((category): category is NonNullable<typeof category> => category != null) ||
    [];

  const options = (businessUnits || []).reduce((acc: InputSelectOptionWithTitles[], cur, index) => {
    acc.push({ value: cur.id || "", label: cur.name, key: "businessUnit" + index });
    return acc;
  }, []);

  const value = currentBU ? options.find((el) => el.value === currentBU.toString())?.value : options[0]?.label;

  return (
    <PageHeader>
      {!isBreakpointM ? <Spacer baselineHeight={2} /> : null}
      <Flex container itemGutter containerJustifyContent="space-between" containerAlignItems="center">
        <Flex item xs={12} m={4} xl={3} itemGutter itemOrder={isBreakpointM ? 2 : 1}>
          {loading ? (
            <Spinner />
          ) : (
            <InputSelect
              name="businessUnitSwitcher"
              onChange={handleChangeBusinessUnit}
              options={options}
              value={value}
            />
          )}
          <Spacer baselineHeight={2} />
        </Flex>
        {isBreakpointS ? (
          <>
            <Flex item xs={12} m={8} itemOrder={isBreakpointM ? 1 : 2}>
              <Header title={title} />
            </Flex>
          </>
        ) : null}
      </Flex>
    </PageHeader>
  );
};
