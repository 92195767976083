import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";

type AnimalsType = GetAnimalsList_animalsExtended_animals[];

export interface RejectedAnimalsType extends GetAnimalsList_animalsExtended_animals {
  error?: string | null;
}

interface GroupedAnimalsForListing {
  locationsWithSalableAnimals: { [key: string]: GetAnimalsList_animalsExtended_animals[] };
  nonSalableAnimals: RejectedAnimalsType[];
}

const isAnimalCommittedToAnyLot = (animal: GetAnimalsList_animalsExtended_animals) => {
  return Boolean(animal.lot && !animal?.lot?.isExpired);
};

const validateAnimalForListing = (animal: GetAnimalsList_animalsExtended_animals) => {
  const error = isAnimalCommittedToAnyLot(animal)
    ? "Already on a listing"
    : !animal.location?.id
    ? "Location not set"
    : null;

  return { error, isValid: !error };
};

export const groupAnimalsForListing = (animals: AnimalsType): GroupedAnimalsForListing => {
  return animals.reduce<GroupedAnimalsForListing>(
    (acc, val) => ({
      ...acc,
      ...(validateAnimalForListing(val).isValid
        ? {
            locationsWithSalableAnimals: {
              ...acc.locationsWithSalableAnimals,
              [val.location?.id as string]: [
                ...(acc.locationsWithSalableAnimals[val.location?.id as string] || []),
                val,
              ],
            },
          }
        : { nonSalableAnimals: [...acc.nonSalableAnimals, { ...val, error: validateAnimalForListing(val).error }] }),
    }),
    {
      locationsWithSalableAnimals: {},
      nonSalableAnimals: [],
    },
  );
};
