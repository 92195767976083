import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { MyLot_myLot_animals } from "trading/api/Liveweight/types/MyLot";

export enum LotTbRequirements {
  DoNotRequirePreMovement = "DO_NOT_REQUIRE_PRE_MOVEMENT",
  HadPreMovement = "HAD_PRE_MOVEMENT",
  RequirePreMovement = "REQUIRE_PRE_MOVEMENT",
}
export enum PriceUnits {
  Each = "HEAD",
  Weight = "KG",
}

export type FormValues = {
  name: string;
  price: number | string;
  priceUnit: PriceUnits;
  description: string;
  tbRequirements: LotTbRequirements;
  preMovementTestDate: Date | string;
  terms: boolean;
  lotAnimals: (GetAnimalsList_animalsExtended_animals | MyLot_myLot_animals)[];
  location: string;
  categoryOfSale: string | number;
};
