import { Fragment, VFC, useEffect, useState } from "react";
// Utils
import { useFilteredSellerListings, useInfinitePagination } from "hooks";
// Components
import { SellerListingCard } from "./SellerListingCard";
import { Button, Flex, Spacer, Text } from "components";
import { EmptyStatesTradingSelling } from "components/EmptyStates/TradingSelling";
import { EmptyStatesNoResults } from "components/EmptyStates/NoResults";

export const SellerListings: VFC = () => {
  const { listings, loading, quantity } = useFilteredSellerListings();
  const isOriginalLoading = !quantity && loading;
  const [isInitialFetch, setIsInitialFetch] = useState(true);

  const { visible, revealMore } = useInfinitePagination();

  useEffect(() => {
    setIsInitialFetch(quantity === 0 && !loading);
  }, [quantity, loading]);

  return (
    <>
      {isOriginalLoading && !quantity ? <Text>Loading</Text> : null}

      {!isOriginalLoading && !quantity ? (
        <Flex container containerJustifyContent="center">
          {isInitialFetch ? <EmptyStatesTradingSelling /> : <EmptyStatesNoResults />}
        </Flex>
      ) : null}

      {listings?.map((listing, listingIndex) => {
        if (listingIndex >= visible) return;

        if (!listing) return;

        return (
          <Fragment key={`listing_${listing.id}`}>
            <SellerListingCard listing={listing} />

            <Spacer baselineHeight={1} />
          </Fragment>
        );
      })}

      {quantity > visible ? (
        <>
          <Spacer baselineHeight={2} />

          <Flex container containerJustifyContent="center">
            <Button caption="Load more" onClick={revealMore} />
          </Flex>
        </>
      ) : null}
    </>
  );
};
