import * as React from "react";

import pure from "recompose/pure";

import { Modal } from "components";

import styles from "./slyles.module.scss";

interface Props {
  loading?: boolean;
  error?: string | null;
  title: string;
  subTitle?: string;
  showModal: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

const ConfirmModalWithOutInput: React.FC<Props> = ({
  title,
  showModal,
  onClose,
  onConfirm,
  loading,
  error,
  subTitle,
  confirmLabel,
  cancelLabel,
}) => (
  <Modal
    active={showModal}
    handleClose={onClose}
    title={title}
    subTitle={subTitle}
    actions={{
      primary: {
        caption: confirmLabel || "Confirm",
        disabled: loading,
        requesting: loading,
        onClick: onConfirm,
      },
      secondary: {
        caption: cancelLabel || "Cancel",
        onClick: onClose,
      },
    }}
  >
    {error ? <p className={styles.error}>{error}</p> : null}
  </Modal>
);

export default pure(ConfirmModalWithOutInput);
