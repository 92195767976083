import { useContext, useState } from "react";
import {
  useExchangeNonceTokenMutation,
  useGetBusinessUnitLazyQuery,
  useGetBusinessUnitsLazyQuery,
  useGetUserLazyQuery,
} from "generated/graphql";
import {
  clearLocalStorage,
  getAppVersionFromLocalStorage,
  getBUFromLocalStorage,
  getTokenFromLocalStorage,
  getUserIdFromLocalStorage,
  setAppVersionToLocalStorage,
  setBUToLocalStorage,
  setBUTypeToLocalStorage,
  setFeaturesListToLocalStorage,
  setTokenToLocalStorage,
  setUserIdToLocalStorage,
} from "helpers/storage";
// @ts-ignore
import { IntercomAPI } from "react-intercom";
import { clearApolloStorage } from "api/graphQL";
import { CommonContext } from "config/commonProvider";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import { setActiveBusinessUnit } from "feShared/businessUnit/reducer";

export const persistVersion = "4.0";

export const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { resetMainCommonState } = useContext(CommonContext);
  const [exchangeToken] = useExchangeNonceTokenMutation();
  const [getBUsData] = useGetBusinessUnitsLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const [getBUData] = useGetBusinessUnitLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [getUserInfo] = useGetUserLazyQuery({
    fetchPolicy: "network-only",
  });

  const [activeBUId, setActiveBUId] = useState(getBUFromLocalStorage());

  const isAuthenticated = () => {
    const tokenFromLocalStorage = getTokenFromLocalStorage();
    if (
      tokenFromLocalStorage &&
      tokenFromLocalStorage.length > 0 &&
      persistVersion === getAppVersionFromLocalStorage()
    ) {
      return true;
    }
    return false;
  };

  const isUserIdAlreadyLoggedIn = async (userId: string | null) => {
    if (!isAuthenticated()) {
      return false;
    }
    const storedUserId = getUserIdFromLocalStorage();
    if (!storedUserId) {
      return false;
    }
    if (userId === storedUserId) {
      return true;
    }
    return false;
  };

  const changeBusinessUnit = async (buId: string | null) => {
    if (!buId) {
      return;
    }
    const buData = await getBUData({ variables: { businessUnit: parseInt(buId) } });
    const bu = buData.data?.businessUnit;
    if (!bu) return;
    setBUToLocalStorage(bu.id.toString());
    setActiveBUId(Number(bu?.id));
    dispatch(setActiveBusinessUnit(bu));
    if (bu?.businessType?.slug) {
      setBUTypeToLocalStorage(bu.businessType.slug);
    }
    if (bu.foggles) {
      setFeaturesListToLocalStorage(bu.foggles.toString());
    }
  };

  const changeBUToDefault = async () => {
    const buData = await getBUsData({ variables: {} });
    const firstBu = buData.data?.businessUnits?.[0];
    if (!firstBu) {
      return;
    }
    setBUToLocalStorage(firstBu.id.toString());
    setActiveBUId(Number(firstBu.id));
    dispatch(setActiveBusinessUnit(firstBu));
    if (firstBu.businessType?.slug) {
      setBUTypeToLocalStorage(firstBu.businessType.slug);
    }
    if (firstBu.foggles) {
      setFeaturesListToLocalStorage(firstBu.foggles.toString());
    }
  };

  const setUserAsAuthenticated = async (token: string) => {
    setTokenToLocalStorage(token);
    setAppVersionToLocalStorage(persistVersion);
    const userData = await getUserInfo();
    if (!userData.data?.userInfo?.id) {
      return;
    }
    setUserIdToLocalStorage(userData.data?.userInfo?.id);
    await changeBUToDefault();
  };

  const exchangeTokenForJWT = async (token: string | null, buId?: string) => {
    if (!token) {
      return false;
    }
    const result = await exchangeToken({ variables: { input: { token: token } } });
    if (result.data?.exchangeNonceToken?.token) {
      await setUserAsAuthenticated(result.data?.exchangeNonceToken?.token);
      if (buId) {
        await changeBusinessUnit(buId);
      }
      return true;
    }
    return false;
  };

  const logout = () => {
    history.push("/login");
    try {
      IntercomAPI("shutdown");
    } catch (error) {
      console.log("error shutting down intercom: ", error);
    }
    clearLocalStorage();
    resetMainCommonState();
    clearApolloStorage();
    Sentry.configureScope((scope) => scope.setUser(null));
  };

  return {
    setUserAsAuthenticated,
    exchangeTokenForJWT,
    changeBusinessUnit,
    changeBUToDefault,
    isUserIdAlreadyLoggedIn,
    isAuthenticated,
    activeBUId,
    logout,
  };
};
