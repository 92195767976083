import { ANIMALS, ANIMALS_ARCHIVE } from "constants/Routes";

export const ANIMAL_FILTER_KEYS = {
  ANIMAL_TYPE_ID: "animalTypeId",
  SORT_BY: "sortBy",
  SORT_DIRECTION: "sortDirection",
  SEARCH: "search",
  BREEDS: "breeds",
  SEX: "sex",
  SEX_CLASSIFICATIONS: "sexClassifications",
  MIN_AGE: "minAge",
  MAX_AGE: "maxAge",
  MIN_DELIVERY_AGE: "minDeliveryAge",
  MAX_DELIVERY_AGE: "maxDeliveryAge",
  MIN_CURRENT_EST_WEIGHT: "minCurrentEstWeight",
  MAX_CURRENT_EST_WEIGHT: "maxCurrentEstWeight",
  MIN_WEIGHT: "minWeight",
  MAX_WEIGHT: "maxWeight",
  DAYS_SINCE_LAST_WEIGHT_FROM: "daysSinceLastWeightFrom",
  DAYS_SINCE_LAST_WEIGHT_TO: "daysSinceLastWeightTo",
  GROWTH_RATE_FROM: "growthRateFrom",
  GROWTH_RATE_TO: "growthRateTo",
  MIN_DELIVERY_WEIGHT: "minDeliveryWeight",
  MAX_DELIVERY_WEIGHT: "maxDeliveryWeight",
  DELIVERY_DATE_FROM: "deliveryDateFrom",
  DELIVERY_DATE_TO: "deliveryDateTo",
  GROUPS: "groups",
  FIELDS: "fields",
  TAGS: "tags",
  LAST_SYNCED_DATE_FROM: "lastSyncedDateFrom",
  LAST_SYNCED_DATE_TO: "lastSyncedDateTo",
  IS_BCMS_SYNCED: "isRegulatorySynced",
  IS_WITHDRAWAL: "isWithdrawal",
  MIN_DOB: "dobDateFrom",
  MAX_DOB: "dobDateTo",
  MIN_DATE_MOVED_TO_FARM: "dateMovedToFarmFrom",
  MAX_DATE_MOVED_TO_FARM: "dateMovedToFarmTo",
  MIN_KILL_WEIGHT: "minKillWeight",
  MAX_KILL_WEIGHT: "maxKillWeight",
  KILL_QUALITY: "killQuality",
  KILL_FAT_SCORE: "killFatScore",
  MIN_DATE_LEFT_FARM: "minDateLeftFarm",
  MAX_DATE_LEFT_FARM: "maxDateLeftFarm",
  MIN_DEAD_AT_DATE: "minDeadAtDate",
  MAX_DEAD_AT_DATE: "maxDeadAtDate",
  PREGNANCY_STATUS: "pregnancyStatus",
  PREGNANCY_DUE_FROM: "pregnancyDueDateFrom",
  PREGNANCY_DUE_TO: "pregnancyDueDateTo",
};

export const ANIMAL_FILTER_VALUES = {
  SEX: {
    M: "M",
    F: "F",
  },
  IS_BCMS_SYNCED: {
    SYNCED: "synced",
    NOT_SYNCED: "notSynced",
  },
  IS_WITHDRAWAL: {
    DRAWAL: "drawal",
    NOT_DRAWAL: "notDrawal",
  },
  IS_PREGNANT: {
    PREGNANT: "pregnant",
    NOT_PREGNANT: "notPregnant",
  },
};

export const ANIMAL_FILTER_COLORS = {
  RED: "RED",
  AMBER: "AMBER",
  GREEN: "GREEN",
};

export const ANIMAL_FILTER_COLOR_OPTIONS = [
  {
    label: "Green",
    value: ANIMAL_FILTER_COLORS.GREEN,
  },
  {
    label: "Amber",
    value: ANIMAL_FILTER_COLORS.AMBER,
  },
  {
    label: "Red",
    value: ANIMAL_FILTER_COLORS.RED,
  },
];

export const IMPORT_ACTION_TYPE = {
  ANIMALS: "IMPORT_ANIMALS",
  ACTIONS: "IMPORT_ACTIVITIES",
};

export const ANIMALS_TABLE_ACTION_TYPES = {
  ADD_TO_GROUP: "add_to_group",
  MOVE_TO_FIELD: "move_to_field",
  REMOVE_FROM_GROUP: "remove_from_group",
  MARK_OFF_FARM: "mark_off_farm",
  MARK_AS_DEAD: "mark_as_dead",
  ADD_PREVIOUS_KEEPER: "add_previous_keeper",
  LOG_ACTIVITY: "log_activity",
};

export const ANIMAL_CHART_FILTERS = {
  GROUP: "Group",
  PREDICTIONS: "Predictions",
};

export const LIVESTOCK_SUBTABS = [
  {
    key: 0,
    name: "On-farm",
    route: ANIMALS,
    feature: "show-animals",
  },
  {
    key: 1,
    name: "Archive",
    route: ANIMALS_ARCHIVE,
    feature: "web-archived-animals",
  },
];

export const SEX_OF_ANIMALS = [
  {
    key: 1,
    value: ANIMAL_FILTER_VALUES.SEX.M,
    label: "Male",
  },
  {
    key: 2,
    value: ANIMAL_FILTER_VALUES.SEX.F,
    label: "Female",
  },
];

export const SYNCR_OF_ANIMALS = [
  {
    key: 1,
    value: ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.SYNCED,
    label: "Synced",
  },
  {
    key: 2,
    value: ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.NOT_SYNCED,
    label: "Not synced",
  },
];

export const DRAWAL_OF_ANIMALS = [
  {
    key: 1,
    value: ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.DRAWAL,
    label: "Yes",
  },
  {
    key: 2,
    value: ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.NOT_DRAWAL,
    label: "No",
  },
];

export const PREGNANCY_STATUS_OPTIONS = [
  {
    key: 1,
    value: ANIMAL_FILTER_VALUES.IS_PREGNANT.PREGNANT,
    label: "Pregnant",
  },
  {
    key: 2,
    value: ANIMAL_FILTER_VALUES.IS_PREGNANT.NOT_PREGNANT,
    label: "Not pregnant",
  },
];

export const MOVE_OFF_FARM_TEMPLATE = "move-off-farm";
export const ADD_PREVIOUS_KEEPER_TEMPLATE = "add-previous-keeper";
export const VACCINATION = "vaccination";

export const ACTIVITY_TEMPLATE_PREVIOUS_KEEPER = {
  fromBUName: "from-business-unit-name",
  fromBUAddress: "from-business-unit-address",
  sourceHoldingId: "source-holding-id",
};

export const ACTIVITY_TEMPLATE_MOVE_OFF_FARM = {
  fromBUId: "from-business-unit-id",
  fromBUName: "from-business-unit-name",
  destinationCPHNumber: "holding-id",
  destinationName: "to-business-unit-name",
  destinationId: "to-business-unit-id",
  destinationPostcode: "new-keeper-postcode",
  destinationAddress: "new-keeper-address",
  destinationEmail: "new-keeper-email",
  notesField: "notes",
};

export const WEIGHT_ACTIVITY_SLUG = "live-weight";

export const WEIGHT_ACTIVITY = {
  key: 0,
  value: WEIGHT_ACTIVITY_SLUG,
  icon: null,
  tooltip: "Weight activities",
};

export const ANIMAL_BIRTH_PRESENTATION = {
  NORMAL: "Normal",
  ABNORMAL: "Abnormal",
};

export const ANIMAL_BIRTH_VIGOUR = {
  STOOD_WITHIN_30_MINUTES: "Stood within 30 minutes of birth, suckled within 1 hour",
  SUCKED_WITH_1_HOUR: "Took more than 1 hour to suck but suckled unaided",
  SUCKED_WITH_3_HOURS: "Calf didn't suck within 2-3 hours of birth and needed assistance",
  DIED_WITHIN_3_HOURS: "Weak calf which died within the first 3 hours of birth",
};

export const ANIMAL_BIRTH_SIZE = {
  AVERAGE: "Average",
  SMALL: "Small",
  LARGE: "Large",
};

export const ANIMAL_BIRTH_DIFFICULTY = {
  NORMAL: "Normal/No Assistance",
  MODERATE_BY_FARMER: "Moderate assistance by farmer",
  MODERATE_VETERINARY: "Moderate veterinary assistance",
  DIFFICULT: "Difficult calving by farmer",
  CESAREAN: "Cesarean",
};

export const ANIMAL_BIRTH_ABNORMALITIES = {
  YES: "Yes",
  NO: "No",
};
