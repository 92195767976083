import { AddressBookSelect } from "components/Settings/AddressBook/AddressBookSelect";
import { FieldValues, useFormContext } from "react-hook-form";
import { Modal } from "components";
import { ContactForm } from "components/ContactForm";
import { useState } from "react";
import { ContactFragmentFragment } from "generated/graphql";

type ContactFieldValues = FieldValues & Record<"contactId", string>;

type Props = {
  label: string;
  disabled?: boolean;
};

export const FormAddressBookSelect = ({ label, disabled }: Props) => {
  const methods = useFormContext<ContactFieldValues>();
  const [showCreateContactModal, setShowCreateContactModal] = useState<boolean>(false);

  if (!methods) {
    throw new Error("FormAddressBookSelect must be used inside a <Form /> or <FormProvider />");
  }

  return (
    <>
      <AddressBookSelect
        label={label}
        value={methods.watch("contactId") || ""}
        errorMessage={methods.formState.errors.contactId?.message as string}
        onCreateNewContact={() => {
          setShowCreateContactModal(true);
        }}
        onContactChange={(contact: ContactFragmentFragment | undefined) => {
          methods.setValue("contactId", contact?.id || "");
        }}
        disabled={disabled}
      />
      <Modal active={showCreateContactModal} title="Create New Contact">
        <ContactForm
          contactId={undefined}
          onCancel={() => setShowCreateContactModal(false)}
          onConfirm={() => setShowCreateContactModal(false)}
          isModal
        />
      </Modal>
    </>
  );
};
