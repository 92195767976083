import { useLocation } from "react-router-dom";
import { isProRoute } from "subscriptions/helpers";

import { useEffect } from "react";
import { ProFeatures, useSubscriptionPlanFeatures } from "feShared/subscriptions/hooks/useSubscriptionPlanFeatures";
import { useHasFeature } from "hooks";
import { getBUFromLocalStorage } from "helpers/storage";
import { SubscribeToPro } from "../SubscribeToPro";

export const Paywall = () => {
  const businessUnitId = getBUFromLocalStorage();
  const { pathname } = useLocation();
  const { isProFeatureEnabledForBU } = useSubscriptionPlanFeatures(businessUnitId, isProRoute(pathname));
  const hasAnimalManagementSubscriptionFeature = isProFeatureEnabledForBU(ProFeatures.AnimalManagement);

  const isPaywallFoggleActive = useHasFeature("SHOW_PAYWALL");

  const showPaywall = isPaywallFoggleActive && !hasAnimalManagementSubscriptionFeature && isProRoute(pathname);

  useEffect(() => {
    if (showPaywall) {
      // Ensure the content behind the paywall isn't scrollable.
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showPaywall]);

  if (!showPaywall) {
    return null;
  }

  return (
    <div className="z-[9999] w-full h-full absolute bg-black bg-opacity-70 flex justify-center">
      <div className="fixed px-6 py-8 bg-white max-w-[1100px]  overflow-y-auto h-screen rounded sm:my-8 sm:mx-6 sm:h-[calc(100vh-64px)] lg:top-[50px] lg:h-auto lg:py-8 lg:px-6">
        <SubscribeToPro showInvoiceHistoryButton={false} />
      </div>
    </div>
  );
};
