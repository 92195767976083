import { Text, Title, Spacer, Icon } from "components";
import { Button } from "twComponents/Button";
import { useLocale } from "helpers/translations/src/useLocale";
import { InputSelect, InputSelectOption } from "components/Common/Field/Select";
import { ChangeEvent, useState } from "react";
import { isMobile } from "react-device-detect";
import { useGetCurrentBusinessUnit } from "hooks";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";

type Props = {
  onSubscribe: (tier: SubscriptionTierId | SubscriptionTierIdUS) => void;
  onContactUs: () => void;
  hasError: boolean;
};

export const SubscriptionTiers = ({ onSubscribe, onContactUs, hasError }: Props) => {
  const { countryCode } = useGetCurrentBusinessUnit();
  const isUS = countryCode.toUpperCase() === CountryISOCode.US;
  const [selectedTier, setSelectedTier] = useState<SubscriptionTierId | SubscriptionTierIdUS>(
    isUS ? "usa-tier1" : "tier1",
  );

  const countrySubscriptionTiers = isUS ? subscriptionTiersUS : subscriptionTiersUK;

  const subscriptionTierOptions: InputSelectOption[] = countrySubscriptionTiers.map((tier) => {
    return {
      value: tier.id,
      label:
        tier.id === "enterprise"
          ? `${tier.animalRanges} animals`
          : `${tier.animalRanges} animals, ${tier.monthlyPrice}`,
      key: tier.id,
    };
  });

  const subscriptionContent = isUS ? <USSubscriptionContent /> : <UKSubscriptionContent />;

  const onContactUsUS = () => {
    window.location.href = "mailto:supportusa@breedr.co";
  };

  return (
    <div className="bg-gray-100 rounded-lg p-4">
      {subscriptionContent}
      <Spacer baselineHeight={1} />

      <div className="flex flex-col xl:flex-row">
        <InputSelect
          name="tier"
          placeholder="Choose your tier"
          value={selectedTier}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setSelectedTier(event.target.value as SubscriptionTierId);
          }}
          options={subscriptionTierOptions}
        />
        <div className="w-5 h-2" />
        {selectedTier === "enterprise" ? (
          <Button variant="outline" onClick={isUS ? onContactUsUS : onContactUs} size={isMobile ? "lg" : "sm"}>
            Contact us
          </Button>
        ) : (
          <Button onClick={() => onSubscribe(selectedTier)} disabled={hasError} size={isMobile ? "lg" : "sm"}>
            Subscribe
          </Button>
        )}
      </div>
      <Spacer baselineHeight={1} />
      <div className="flex">
        <Icon name="info" size="tiny" className="mr-2" />
        <Text smallest>{isUS ? "7" : "30"}-day free trial included for first time subscribers</Text>
      </div>
    </div>
  );
};

export type SubscriptionTierId = "tier1" | "tier2" | "tier3" | "tier4" | "tier5" | "tier6" | "enterprise";
export type SubscriptionTierIdUS = "usa-tier1" | "usa-tier2" | "usa-tier3" | "enterprise";

// animalRanges: `${Starter | Pro | Plus | undefined} Up to ${number}` | "2000+"; -
// this is the type we want to use, but with optional name
type SubscriptionTier = {
  animalRanges: string;
  monthlyPrice?: `£${number}.${number} / month` | `$${number}.${number} / month`;
  id: SubscriptionTierId | SubscriptionTierIdUS;
};

const subscriptionTiersUK: Array<SubscriptionTier> = [
  { animalRanges: "Up to 120", monthlyPrice: "£16.49 / month", id: "tier1" },
  { animalRanges: "Up to 220", monthlyPrice: "£23.49 / month", id: "tier2" },
  { animalRanges: "Up to 330", monthlyPrice: "£29.49 / month", id: "tier3" },
  { animalRanges: "Up to 400", monthlyPrice: "£33.99 / month", id: "tier4" },
  { animalRanges: "Up to 1000", monthlyPrice: "£37.99 / month", id: "tier5" },
  { animalRanges: "Up to 2000", monthlyPrice: "£41.99 / month", id: "tier6" },
  { animalRanges: "2000+", id: "enterprise" },
];

const subscriptionTiersUS: Array<SubscriptionTier> = [
  { animalRanges: "Starter - Up to 50", monthlyPrice: "$2.00 / month", id: "usa-tier1" },
  { animalRanges: "Plus - Up to 300", monthlyPrice: "$35.00 / month", id: "usa-tier2" },
  { animalRanges: "Pro - Up to 2000", monthlyPrice: "$235.00 / month", id: "usa-tier3" },
  { animalRanges: "2000+", id: "enterprise" },
];

const USSubscriptionContent = () => {
  const { terms } = useLocale();
  return (
    <div>
      <div className="bg-gray-100 rounded-lg p-4">
        <Title tertiary>Choose Your Subscription</Title>
        <Spacer baselineHeight={1} />
        <Text smaller>{`Please choose the tier that best suits your ${terms.farm}.`}</Text>
      </div>
    </div>
  );
};

const UKSubscriptionContent = () => {
  const { terms } = useLocale();
  return (
    <div className="bg-gray-100 rounded-lg p-4">
      <Title tertiary>Choose Your Subscription</Title>
      <Spacer baselineHeight={1} />
      <Text smaller>{`Please choose the tier that best suits your ${terms.farm}. (prices exclude VAT)`}</Text>
      <Spacer baselineHeight={1} />
      <Text smaller className="leading-tight">
        Get 15% discount on a yearly subscription, email us at{" "}
        <a href="mailto: subscriptions@breedr.co">subscriptions@breedr.co</a>.
      </Text>
    </div>
  );
};
