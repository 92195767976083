import * as React from "react";
import { useQuery } from "@apollo/client";
import cn from "classnames";
// api
import { GET_ACTIVITY_TYPES } from "api/MyLivestock/Activity/queries";
import {
  GetActivityTypesVariables,
  GetActivityTypes_activityTypes,
} from "api/MyLivestock/Activity/types/GetActivityTypes";
// constants
import { WEIGHT_ACTIVITY } from "constants/Animals";
// utils
import { getBUFromLocalStorage } from "helpers/storage";
import { getActivityTypes } from "helpers/myLivestock";
// components
import { Flex, Icon, Paper, Spacer, Text, Tooltip } from "components";
// styles
import styles from "./styles.module.scss";

export interface ActivityTypes {
  key: number;
  value: string;
  icon: string | null;
  tooltip: string;
}

interface Props {
  selected: string[];
  selectFilter: (id: string) => void;
}

interface GetActivityTypes {
  activityTypes: GetActivityTypes_activityTypes[];
}

export const AnimalHistoryFilters: React.FC<Props> = ({ selected, selectFilter }) => {
  const { data } = useQuery<GetActivityTypes, GetActivityTypesVariables>(GET_ACTIVITY_TYPES, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const filters = getActivityTypes(WEIGHT_ACTIVITY, data?.activityTypes || []);

  return (
    <Paper>
      <Spacer baselineHeight={1} />
      <Flex container>
        <Flex item>
          <Text smaller>
            <strong>Filters</strong>
          </Text>
        </Flex>
        <Flex item itemGutter container>
          <>
            {filters.map((filter, index) => (
              <Flex item itemGutter={index % 2 !== 0} key={`filter_${filter.key}`}>
                <Tooltip title={filter.tooltip || ""}>
                  <span
                    onClick={(): void => selectFilter(filter.value)}
                    className={cn(styles.filter_item, {
                      [styles["filter_item--weight-icon"]]: filter.value === "live-weight",
                      [styles["filter_item--active"]]: selected.includes(filter.value),
                    })}
                    role="button"
                  >
                    {filter.icon ? (
                      <img src={filter.icon} alt={filter.value} />
                    ) : filter.value === "live-weight" ? (
                      <Icon name="weight" size="small" />
                    ) : (
                      <Icon name="info" size="small" />
                    )}
                  </span>
                </Tooltip>
              </Flex>
            ))}
          </>
        </Flex>
      </Flex>
      <Spacer baselineHeight={1} />
    </Paper>
  );
};
