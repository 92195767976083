import { FC } from "react";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { Redirect, useParams } from "react-router-dom";
import { History } from "history";
import { FIELDS } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useGetAnimalsFromField } from "hooks";
import AnimalsInField from "pages/MyLivestock/Fields/AnimalsInField/AnimalsInField";
import { FieldInfoBlock } from "components/MyLivestock";
import { LoadingOverlay, Breadcrumbs, Button, Header, PageContent, PageHeader, Spacer } from "components";
import styles from "./styles.module.scss";
import { GetFieldQuery, useGetFieldQuery } from "generated/graphql";

interface FieldViewProps {
  history: History;
}

const FieldView: FC<FieldViewProps> = ({ history }) => {
  const { fieldId } = useParams<LivestockFieldPageParams>();

  const { data, loading, error } = useGetFieldQuery({ variables: { id: fieldId } });
  const { data: dataAnimals } = useGetAnimalsFromField(fieldId);

  const { field } = { ...data } as GetFieldQuery;

  if (error) {
    return <Redirect to={FIELDS} />;
  }

  return (
    <>
      {loading ? (
        <LoadingOverlay customStyles={styles.loadingOverlay} />
      ) : (
        <>
          <PageHeader>
            <Breadcrumbs
              rootPaths={[
                {
                  path: "Locations",
                  route: FIELDS,
                },
              ]}
              currentPath={`${capitaliseFirstLetter("location")} Information`}
            />

            <Header backButton title={field?.name || ""} subTitle="" />

            <FieldInfoBlock field={field}>
              <Button
                id={ELEMENTS_IDS.EDIT_FIELD_BUTTON}
                caption={"Edit location"}
                onClick={(): void => history.push(`${FIELDS}/edit/${field?.id}`)}
              />
            </FieldInfoBlock>

            <Spacer baselineHeight={3} />
          </PageHeader>

          <PageContent>
            <Spacer baselineHeight={3} />

            <AnimalsInField animals={dataAnimals?.field?.animals} />
          </PageContent>
        </>
      )}
    </>
  );
};

export default FieldView;
