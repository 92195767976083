import React from "react";
import { useHistory } from "react-router";
// Hooks
import { useGetBankDetails } from "hooks";
// Constants
import { CREATE_BANK_DETAILS, EDIT_BANK_DETAILS, SETTINGS } from "constants/Routes";
// Components
import { Spacer, Flex, NoItemsFound, Panel, LoadingOverlay, Text } from "components";

export const BankDetailsList: React.FC = () => {
  const history = useHistory();
  const { data, loading } = useGetBankDetails();

  const bankDetails = data?.bankDetails;

  const handlePanelButtonClick = (): void => {
    if (bankDetails) {
      history.push(`${SETTINGS}${EDIT_BANK_DETAILS}`);
    } else {
      history.push(`${SETTINGS}${CREATE_BANK_DETAILS}`);
    }
  };

  return (
    <Flex container>
      <Flex item itemGutter xs="fill">
        {loading ? <LoadingOverlay /> : null}
        <Panel
          title="Your Bank Details"
          actions={[
            {
              colour: "yellow",
              onClick: handlePanelButtonClick,
              caption: `${bankDetails ? "Edit" : "Add"} bank details`,
            },
          ]}
        >
          <Text smaller>
            Your bank details are encrypted and stored securely solely for the purposes of executing trades as set out
            in our{" "}
            <a href="https://www.breedr.co/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </Text>
          <Spacer baselineHeight={1} />

          {bankDetails ? (
            <>
              <Text>
                Sort code: <strong>{bankDetails.sortCode}</strong>
              </Text>
              <Spacer baselineHeight={1} />
              <Text>
                Account number: <strong>{bankDetails.accountNo}</strong>
              </Text>
              <Spacer baselineHeight={1} />
              <Text>
                Name on account: <strong>{bankDetails.accountName}</strong>
              </Text>
              <Spacer baselineHeight={1} />
            </>
          ) : (
            <NoItemsFound title="No bank details added" />
          )}
        </Panel>
      </Flex>
    </Flex>
  );
};
