import React, { useMemo } from "react";
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { ConfirmAddPreviousKeeperModal } from "./ConfirmAddPreviousKeeperModal";
import { CreateListingFromSelection } from "./CreateListingFromSelection";
import { LogActivityModal } from "./LogActivityModal";
import { Flex } from "components";
import { AddSelectedAnimalsToTag } from "./AddSelectedAnimalsToTag";
import { RemoveSelectedAnimalsToTag } from "./RemoveSelectedAnimalsToTag";
import { useHasFeature } from "hooks";
import { MarkOffFarmModal } from "./MarkOffFarm/MarkOffFarmModal";

interface AnimalBulkActionsProps {
  animals: GetAnimalsList_animalsExtended_animals[];
  tableId: string;
}

// LEGACY COMPONENT: Can be deleted when the server paginated list is enabled in UK
export const AnimalBulkActions: React.FC<AnimalBulkActionsProps> = ({ animals = [], tableId }) => {
  const animalIds = useMemo(() => animals.map((animal) => animal.id), [animals]);

  const isMovementsEnabled = useHasFeature("OUTWARD_MOVEMENTS");

  return (
    <Flex container containerJustifyContent="flex-end" xs="fill">
      <Flex item>
        <ConfirmAddPreviousKeeperModal animalIds={animalIds} />
      </Flex>

      <Flex item itemGutter>
        <CreateListingFromSelection animals={animals} />
      </Flex>

      <Flex item>
        <AddSelectedAnimalsToTag animalIds={animalIds} />
      </Flex>

      <Flex item itemGutter>
        <RemoveSelectedAnimalsToTag animalIds={animalIds} />
      </Flex>

      {isMovementsEnabled ? null : (
        <>
          <div style={{ width: 10 }} />
          <Flex item>
            <MarkOffFarmModal animalIds={animalIds} tableId={tableId} />
          </Flex>
        </>
      )}

      <Flex item itemGutter>
        <LogActivityModal animals={animals} />
      </Flex>
    </Flex>
  );
};
