// React
import React, { forwardRef } from "react";
// Libraries
import cn from "classnames";
// Utils
import { ICON_NAMES } from "constants/Icons";
import { ELEMENTS_IDS } from "constants/ElementsIds";
// Components
import { Badge, Icon } from "components";
// Resources
import styles from "../RootMenuLink/RootMenuLink.module.scss";

interface RootMenuToggleProps {
  active: boolean;
  badge?: BadgeProps;
  className?: string;
  icon: keyof typeof ICON_NAMES;
  onClick?: () => void;
}

export const RootMenuToggle: React.FC<RootMenuToggleProps> = forwardRef<HTMLButtonElement, RootMenuToggleProps>(
  ({ active, badge, className, icon, ...props }, forwardedRef) => (
    <button
      id={ELEMENTS_IDS.ROOT_MENU_TOGGLE_BUTTON}
      type="button"
      className={cn(
        styles.root_menu_link,
        {
          [styles["root_menu_link--active"]]: active,
        },
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      <Icon className={styles.root_menu_link__icon} colour="white" name={icon} size="large" />
      {badge ? <Badge className={cn(styles.root_menu_link__badge, badge.className)} text={badge.text} /> : null}
    </button>
  ),
);
RootMenuToggle.displayName = "RootMenuToggle";
