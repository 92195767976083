import { useEffect, VFC } from "react";
// API
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
// Utils
import { Checked } from "constants/SvgIcons";
import { RejectedAnimalsType } from "helpers/myLivestock/animals/groupAnimalsForListing";
// Components
import { Pill, Button } from "components";
// Resources
import styles from "./styles.module.scss";

type AnimalsType = GetAnimalsList_animalsExtended_animals[];

interface BulkAddToListingProps {
  allAnimalsCount: number;
  rejectedAnimals: RejectedAnimalsType[];
  animalsForListing: Array<{ data: GetAnimalsList_animalsExtended_animals[]; title: string }>;
  selectedLocation?: string;
  setChosenAnimals: (animals: AnimalsType) => void;
}

export const BulkAddToListing: VFC<BulkAddToListingProps> = ({
  allAnimalsCount,
  rejectedAnimals,
  animalsForListing,
  selectedLocation,
  setChosenAnimals,
}) => {
  const totalAnimalsAvailable = animalsForListing.reduce(function (accumulator, item) {
    return accumulator + item.data.length;
  }, 0);

  useEffect(() => {
    if (animalsForListing.length === 1) {
      setChosenAnimals(animalsForListing[0].data);
    }
  }, []);

  return (
    <div className={styles.container}>
      <span className={styles.infoText}>{`${allAnimalsCount} animals were selected to be added to the listing`}</span>
      <span
        className={styles.infoSuccessText}
      >{`${totalAnimalsAvailable} animals in ${animalsForListing.length} location available for sale`}</span>
      <span className={styles.infoDangerText}>{`${rejectedAnimals.length} animals are unable to be added`}</span>
      <div className={styles.listContainer}>
        {animalsForListing
          ? animalsForListing.map((location) => {
              const animalLocationId = location.data[0].location?.id;
              return (
                <div key={`animalLocationId`}>
                  <div className={styles.listTitleContainer}>
                    <div>
                      <p className={styles.listTitle}>{location.title}</p>
                    </div>
                    {selectedLocation == null ||
                    (animalsForListing.length > 1 && selectedLocation != animalLocationId) ? (
                      <div>
                        <Button
                          caption="Add animals from this location"
                          onClick={() => {
                            setChosenAnimals(location.data);
                          }}
                        />
                      </div>
                    ) : (
                      <div className={styles.checkMark}>
                        <Checked nativeColor="#51A667" />
                      </div>
                    )}
                  </div>
                  {location.data.map((animal) => (
                    <div key={animal.id} className={styles.itemBlock}>
                      <div className={styles.passportNumberBlock}>
                        <span className={styles.animalDataText}>
                          {animal.passportNumber ? animal.passportNumber : ""}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })
          : null}
        {rejectedAnimals.length > 0 ? (
          <>
            <div className={styles.listTitleContainer}>
              <div>
                <p className={styles.listTitle}>Rejected Animals</p>
              </div>
            </div>
            {rejectedAnimals.map((animal) => {
              const { error } = animal;

              return (
                <>
                  <div key={animal.id} className={styles.itemBlock}>
                    <div className={styles.passportNumberBlock}>
                      <span className={styles.animalDataText}>
                        {animal.passportNumber ? animal.passportNumber : ""}
                      </span>
                    </div>

                    {error ? (
                      <div className={styles.pillItem}>
                        <Pill caption={error} colour="red" />
                      </div>
                    ) : null}
                  </div>
                </>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};
