// Libraries
import { useQuery, QueryResult } from "@apollo/client";
// API
import { GET_ANIMALS_FROM_FIELD } from "api/MyLivestock/Fields/queries";
import { GetAnimalsFromField, GetAnimalsFromFieldVariables } from "api/MyLivestock/Fields/types/GetAnimalsFromField";

export const useGetAnimalsFromField = (
  fieldId: string,
): QueryResult<GetAnimalsFromField, GetAnimalsFromFieldVariables> =>
  useQuery<GetAnimalsFromField, GetAnimalsFromFieldVariables>(GET_ANIMALS_FROM_FIELD, {
    variables: {
      id: fieldId,
    },
    skip: !fieldId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
