import React, { ChangeEvent } from "react";
// Hooks
import { ListingSortInstance } from "trading/hooks/useListingSort";
import { useListingSort } from "hooks";
// Types
import { LIVEWEIGHT_SORT_OPTIONS, LiveweightSortValue } from "trading/hooks/useListingSort/constants";
// Components
import { InputSelect } from "components";

interface ListingSortProps {
  instance: ListingSortInstance;
}

export const ListingSort: React.FC<ListingSortProps> = ({ instance }) => {
  const { set: setSort, value } = useListingSort(instance);

  const handleChangeSort = (event: ChangeEvent<HTMLSelectElement>): void =>
    setSort(event.target.value as LiveweightSortValue);

  return (
    <InputSelect
      labelKey="label"
      name="liveweightSort"
      onChange={handleChangeSort}
      options={LIVEWEIGHT_SORT_OPTIONS}
      value={value}
      valueKey="value"
    />
  );
};
