import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { MyLots_myLots_edges_node, MyLots, MyLotsVariables } from "trading/api/Liveweight/types/MyLots";
import { GET_MY_LOTS } from "trading/api/Liveweight/queries";
import { getBUFromLocalStorage } from "helpers/storage";

export const useGetMyLots = () => {
  const { data, ...rest } = useQuery<MyLots, MyLotsVariables>(GET_MY_LOTS, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const listings = useMemo(
    () =>
      data?.myLots?.edges?.reduce<MyLots_myLots_edges_node[]>((accumulator, listing) => {
        if (listing?.node) accumulator.push(listing.node);

        return accumulator;
      }, []) || [],
    [data],
  );

  return {
    data: listings,
    ...rest,
  };
};
