import { gql } from "@apollo/client";
import { userInfoFragment, ownerFragment } from "api/User/fragment";

export const GET_CURRENT_USER_INFO = gql`
  query GetUser {
    userInfo {
      ...UserInfoFragment
    }
  }
  ${userInfoFragment}
`;

export const SEARCH_USER = gql`
  query SearchUser($search: String) {
    searchUser(search: $search) {
      ...ownerFragment
    }
  }
  ${ownerFragment}
`;

export const ROLES_LIST = gql`
  query RolesList {
    roles {
      id
      name
    }
  }
`;
