import { VFC } from "react";
// Libraries
import { Route, Switch, Redirect } from "react-router-dom";
// Utils
import { BU_ID, REPORTS, DASHBOARD } from "constants/Routes";
import { useHasFeature, useGetCurrentUserInfo } from "hooks";
// Pages
import { Reports } from "pages/Reports";
import OfflineReports from "pages/Reports/OfflineReports";
import { Home } from "pages";

const ROUTES_PATHS = {
  Home: `${BU_ID}${DASHBOARD}`,
  Reports: `${BU_ID}${REPORTS}/:reportType?`,
};

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

export const CommonRoutes: VFC = () => {
  const { data: userInfoData } = useGetCurrentUserInfo();

  const userInfo = userInfoData?.userInfo;

  const isShowReports: boolean = useHasFeature("REPORTS");
  const isShowHomePage: boolean = useHasFeature("SHOW_HOME_PAGE");
  const isShowOfflineReportPage: boolean = useHasFeature("SHOW_OFFLINE_PAGE_FOR_REPORTS");

  return (
    <Route exact path={PATHS}>
      <Switch>
        {isShowHomePage ? (
          <Route exact path={ROUTES_PATHS.Home} render={(): JSX.Element => <Home user={userInfo} />} />
        ) : null}
        {isShowReports ? (
          <Route path={ROUTES_PATHS.Reports} component={isShowOfflineReportPage ? OfflineReports : Reports} />
        ) : null}
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};
