// React
import React, { useEffect, useState } from "react";
// Libraries
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
// Utils
import { GET_PERFORMANCE_CHART_DATA } from "api/MyLivestock/Animal/queries";
import { GetPerformanceChartData } from "api/MyLivestock/Animal/types/GetPerformanceChartData";
// Components
import { AnimalChartFilter } from "pages/MyLivestock/Animals/AnimalChartFilter";
import AnimalChart from "components/MyLivestock/Animals/AnimalChart";
import { LoadingOverlay, Paper, Spacer } from "components";
// Resources
import styles from "./AnimalChart.module.scss";

interface AnimalChartParentProps {
  isArchived: boolean;
}

const AnimalChartParent: React.FC<AnimalChartParentProps> = ({ isArchived = false }) => {
  const { id } = useParams<LivestockAnimalPageParams>();
  const { data, loading } = useQuery(GET_PERFORMANCE_CHART_DATA, {
    variables: {
      animal: id,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const [filters, updateFilters] = useState<(string | null)[]>([]);

  const { performanceChartData }: GetPerformanceChartData = {
    ...data,
  };
  const {
    dates,
    measurements,
    low,
    avg,
    high,
    references,
    contexts = [],
  } = {
    ...performanceChartData,
  };

  const isChartDataExist = dates && measurements && measurements.length && low && avg && high && references;

  useEffect(() => {
    if (performanceChartData?.contexts) {
      updateFilters(performanceChartData?.contexts);
    }
  }, [performanceChartData?.contexts]);

  if (!isChartDataExist) return null;
  return (
    <>
      {loading ? <LoadingOverlay /> : null}

      {!loading ? (
        <Paper className={styles.animal_chart_container}>
          <Spacer baselineHeight={1} />

          <AnimalChart filters={filters} data={performanceChartData} isArchived={isArchived} />

          <Spacer baselineHeight={1} />

          <AnimalChartFilter onChange={updateFilters} availableFilters={contexts} />

          <Spacer baselineHeight={1} />
        </Paper>
      ) : null}
    </>
  );
};

export default AnimalChartParent;
