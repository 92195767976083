import { InputSelectOption } from "components/Common/Field/Select";
import { SiresQuery, useSiresQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { getBUFromLocalStorage } from "helpers/storage";
import { useMemo } from "react";
import { sortBy } from "lodash";

export type Sire = NonNullable<NonNullable<SiresQuery>["sires"]>[0];

export const useGetSires = () => {
  const { data, ...rest } = useSiresQuery({
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const sires = removeNothings(data?.sires || []);

  const sireOptions: InputSelectOption[] = useMemo(() => {
    const options = sires.map((sire) => {
      return {
        value: sire.id,
        label: `${sire.name} (${sire.passportNumber})`,
        key: sire.id,
      };
    });

    return sortBy(options, [(option): string => option.label]);
  }, [sires]);

  return {
    sireOptions,
    data,
    ...rest,
  };
};
