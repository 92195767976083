import { InputSelectOption } from "components/Common/Field/Select";
import { AnimalSorting, useDamAnimalsLazyQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";
import { useGetCurrentBusinessUnit } from "./useGetCurrentBusinessUnit";
import { useStateSpecies } from "./useStateSpecies";

interface Input {
  forAllSpecies?: boolean;
  animalId?: string;
}

export const useGetDamAnimals = ({ forAllSpecies, animalId }: Input = {}) => {
  const { activeSpecies } = useStateSpecies();
  const { countryIsoCodeUpperCase, id: businessUnitId } = useGetCurrentBusinessUnit();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [getPickerOptionAnimals, { data, ...rest }] = useDamAnimalsLazyQuery();

  useEffect(() => {
    const fetchAnimals = async () => {
      await getPickerOptionAnimals({
        variables: {
          animalIds: animalId ? [animalId] : undefined,
          businessUnit: +businessUnitId,
          animalTypeIds: !forAllSpecies && activeSpecies ? [+activeSpecies.id] : null,
          isMale: false,
          orderBy:
            countryIsoCodeUpperCase === CountryISOCode.US ? AnimalSorting.EIdAsc : AnimalSorting.PassportNumberAsc,
          first: 10,
          search: searchTerm,
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-only",
      });
    };

    fetchAnimals();
  }, [
    activeSpecies,
    animalId,
    businessUnitId,
    countryIsoCodeUpperCase,
    forAllSpecies,
    getPickerOptionAnimals,
    searchTerm,
  ]);

  const dams = useMemo(
    () => removeNothings((data?.animals?.edges || []).map((edge) => edge?.node)),
    [data?.animals?.edges],
  );

  const damOptions: Array<InputSelectOption> = dams.map((animal) => {
    return {
      value: animal.id,
      label: countryIsoCodeUpperCase === CountryISOCode.US ? animal.eId : animal.passportNumber,
      key: animal.id,
    };
  });

  const searchDam = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    return setSearchTerm(event.target.value);
  }, 500);

  return {
    damOptions,
    data,
    searchDam,
    ...rest,
  };
};
