import { ICON_NAMES } from "constants/Icons";
import SVG from "react-inlinesvg";
import bigFieldBg from "assets/img/bigFieldBg.png";
import { Image, Title, Text } from "components";
import { getErrorMessage } from "helpers/general";
import { Button } from "twComponents/Button";
import { DASHBOARD } from "constants/Routes";

export const ErrorFallback = ({ error }: { error: unknown }) => {
  return (
    <div className="bg-white min-h-screen flex flex-col justify-between">
      <div className="flex flex-col items-center">
        <SVG src={ICON_NAMES.ufoCow} className="w-3/4 h-1/2 md:h-80" />
        <div className="px-2 text-center">
          <Title className="text-2xl">We&apos;re sorry something went wrong.</Title>
          <Text>We have logged this error and we will investigate.</Text>
          <Text className="my-2 block">We may contact you to find out how you got this error.</Text>
        </div>
        <pre className="bg-red-200 text-red-800 p-2 mx-2 rounded whitespace-break-spaces">{getErrorMessage(error)}</pre>
        <Button className="mt-6 text-md" size={"lg"} onClick={() => window.location.replace(DASHBOARD)}>
          Open dashboard
        </Button>
      </div>
      <Image src={bigFieldBg} />
    </div>
  );
};
