import { graphql } from "@apollo/client/react/hoc";

import { compose, withHandlers, withStateHandlers } from "recompose";

import { UPDATE_EMAIL } from "api/User/mutations";

import ConfirmWithPasswordModal from "./ConfirmWithPasswordModal";

const changeUserEmail =
  ({ updateEmail, changeLoading, changeEmailError, onModalClose, email }) =>
  async ({ password }, showNotification): Promise<void> => {
    changeLoading(true);
    changeEmailError(null);

    try {
      const {
        data: {
          updateEmail: { errors },
        },
      } = await updateEmail({
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      if (errors && errors.length) {
        showNotification({
          variant: "error",
          message: errors[0].message,
        });
        changeLoading(false);
        changeEmailError(errors[0].message);
      } else {
        showNotification({
          message: "User email was successfully saved",
        });
        changeLoading(false);
        changeEmailError(null);
        onModalClose();
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Something went wrong! Please try again later.",
      });
      changeEmailError("Something went wrong");
      changeLoading(false);
    }
  };

export default compose(
  graphql(UPDATE_EMAIL, {
    name: "updateEmail",
  }),
  withStateHandlers(
    {
      requesting: false,
      emailUpdateError: null,
    },
    {
      changeLoading:
        () =>
        (requesting): Record<string, unknown> => ({
          requesting,
        }),
      changeEmailError:
        () =>
        (emailUpdateError): Record<string, unknown> => ({
          emailUpdateError,
        }),
    },
  ),
  withHandlers({
    changeUserEmail,
  }),
)(ConfirmWithPasswordModal);
