import { MyLots_myLots_edges_node } from "trading/api/Liveweight/types/MyLots";
// Types
import { AnimalsBreedPillProps } from "components/MyLivestock/Animals/AnimalsBreedPill";
import { BuyingLotNode } from "trading/components/containers/liveweight/BuyerListings";

export const getListingAnimalsBreedPills = (
  listing: BuyingLotNode | MyLots_myLots_edges_node,
): AnimalsBreedPillProps[] => {
  const unknownBreedName = "Unknown breed";
  const breedPills: AnimalsBreedPillProps[] = [];

  listing.animalsCountBreedAndGender?.forEach((listingBreed) => {
    if (!listingBreed) return;

    const breedName = listingBreed.breedName || unknownBreedName;

    const existingBreed = breedPills.find((breedPill) => breedPill.name === breedName);

    if (existingBreed) {
      existingBreed.quantity += listingBreed.count || 0;

      const breedSex = listingBreed.isMale ? "male" : "female";
      if (breedSex !== existingBreed.sex) existingBreed.sex = "mixed";
    } else {
      breedPills.push({
        name: breedName,
        quantity: listingBreed.count || 0,
        sex: listingBreed.isMale ? "male" : "female",
      });
    }
  });

  return breedPills;
};
