import React from "react";
// Libraries
import { useHistory } from "react-router-dom";
// Utils
import { ADDRESS_BOOK, SETTINGS } from "constants/Routes";
// Components
import { EllipsisMenu } from "components";

interface Props {
  addressId: string;
}

export const TableActionColumn: React.FC<Props> = ({ addressId }) => {
  const history = useHistory();

  const handleEditContactAddress = (): void => {
    history.push(`${SETTINGS}${ADDRESS_BOOK}/${addressId}`);
  };

  const actions = [
    {
      caption: "Edit",
      onClick: handleEditContactAddress,
    },
  ];

  return (
    <>
      <EllipsisMenu actions={actions} />
    </>
  );
};

TableActionColumn.displayName = "TableActionColumn";
