import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesHistoryTab: FC = () => {
  return (
    <EmptyStates animation={"searching"} title="No history found.">
      <p>Log activities against an animal and come back here to see the history.</p>
    </EmptyStates>
  );
};
