import * as React from "react";

import pure from "recompose/pure";

import styles from "./styles.module.css";

interface Props {
  blockName: string;
  children?: React.ReactNode;
  replaceMessage?: boolean;
}

const EmptyPanelContent: React.FC<Props> = ({ replaceMessage = false, blockName, children }) => (
  <div className={styles.mainWrapper}>
    <div className={styles.textWrapper}>
      <p className={styles.text}>{replaceMessage ? blockName : `You currently do not have any ${blockName}.`}</p>
      {children ? <p className={styles.text}>Add your first one below.</p> : null}
    </div>
    {children}
  </div>
);

export default pure(EmptyPanelContent);
