import { gql } from "@apollo/client";

import { regulatoryTransactionApprovalFragment } from "./fragments";

export const GET_REGULATORY_TRANSACTION_APPROVAL_SUMMARY = gql`
  query RegulatoryTransactionApprovalSummary($businessUnitId: Int!) {
    regulatoryTransactionApprovalSummary(businessUnitId: $businessUnitId) {
      id
      type
      pending
      inProgress
      failed
      successful
    }
  }
`;

export const GET_REGULATORY_TRANSACTION_APPROVALS = gql`
  query RegulatoryTransactionApprovals($businessUnitId: Int!, $transactionType: String!, $statuses: [String!]) {
    regulatoryTransactionApprovals(
      businessUnitId: $businessUnitId
      transactionType: $transactionType
      statuses: $statuses
    ) {
      ...regulatoryTransactionApprovalFragment
    }
  }
  ${regulatoryTransactionApprovalFragment}
`;

export const GET_REGULATORY_TRANSACTION_APPROVALS_WITH_WARNINGS = gql`
  query RegulatoryTransactionApprovalsWithWarning($businessUnitId: Int!, $transactionType: String!) {
    regulatoryTransactionApprovalsWithWarning(businessUnitId: $businessUnitId, transactionType: $transactionType) {
      ...regulatoryTransactionApprovalFragment
    }
  }
  ${regulatoryTransactionApprovalFragment}
`;

export const GET_REGULATORY_TRANSACTION_WARNINGS = gql`
  query RegulatoryTransactionWarnings($businessUnitId: Int!) {
    regulatoryTransactionWarnings(businessUnitId: $businessUnitId) {
      type
      count
      lapseOfTime
    }
  }
`;
