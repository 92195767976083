// React
import React from "react";
// Libraries
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
// Utils
import { GetSireByIdVariables, GetSireById_sire } from "api/Sires/types/GetSireById";
import { GET_SIRE_BY_ID } from "api/Sires/queries";
import { SIRES } from "constants/Routes";
import { getBUFromLocalStorage } from "helpers/storage";
// Components
import SireEditForm from "./SireEditForm";
import { Breadcrumbs, Header, PageContent, PageHeader, Spacer } from "components";

interface SireData {
  sire: GetSireById_sire;
}

const SireEditView: React.FC = () => {
  const { id } = useParams<SettingsSireEditPageParams>();
  const createMode = id === "create";

  const { data, error } = useQuery<SireData, GetSireByIdVariables>(GET_SIRE_BY_ID, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
      id,
    },
    skip: createMode,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const sire: GetSireById_sire | undefined = data?.sire;

  const title: string = createMode ? "Add a sire" : "Edit sire";

  if (error) {
    return <Redirect to={SIRES} />;
  }

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Sire information",
              route: SIRES,
            },
          ]}
          currentPath={title}
        />
        <Header backButton title={title} />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />

        <SireEditForm sire={sire} createMode={createMode} />
      </PageContent>
    </>
  );
};

export default SireEditView;
