export const ANIMATION_DURATION = 250;

export const BASELINE = 8;
export const COLUMN_GUTTER = 10;

// Desktop
export const BREAKPOINT_XL = 1280;
// Tablet
export const BREAKPOINT_L = 1024;
export const BREAKPOINT_M = 768;
// Mobile
export const BREAKPOINT_S = 512;
export const BREAKPOINT_XS = 0;

// Vertical Small
export const V_BREAKPOINT_S = 768;
export const V_BREAKPOINT_M = 850;

export type TableId = typeof TABLE_IDS[keyof typeof TABLE_IDS];

export const TABLE_IDS = {
  ANIMALS: "animals_table",
  ANIMALS_ARCHIVE: "animals_archive",
  ANIMALS_FOR_SELECTION: "animals_for_selection",
  ANIMALS_SELECTED: "animals_table_selected",
  ANIMALS_TO_COMMIT: "animals_to_commit_table",
  ANIMAL_LIST_SERVER: "animals_list_server",
  ANIMAL_LIST_SERVER_ARCHIVE: "animals_list_server_archive",
  ANIMAL_LIST_TAGS: "animals_list_tags",
  ANIMAL_LIST_SERVER_SELECTED: "animals_list_server_selected",
  BUYER_OFFERS_TABLE: "trading_buyer_offers_table",
  CUSTOM_IMPORT_COLUMN_MATCH: "custom_import_column_match",
  CSV_EXPORT_ERROR: "csv_export_error",
  EPD_RESULTS: "epd_results_table",
  FIELDS: "fields_table",
  FIELD_ANIMALS: "field_animals_table",
  GENETIC_RESULTS: "genetic_results_table",
  GROUPS: "groups_table",
  GROUP_ANIMALS: "group_animals_table",
  IMPORTED_ANIMALS: "imported_animals_table",
  L2L_ANIMALS: "l2l_offer_animals_table",
  LISTING_ANIMALS: "listing_animals_table",
  MARKETPLACE_ANIMALS: "marketplace_animals_table",
  MARKETPLACE_ANIMALS_TO_COMMIT: "marketplace_animals_to_commit_table",
  MEDICINES_TABLE: "medicines_table",
  PROCESSOR_ACCEPTANCE: "processor_acceptance_table",
  PROCESSOR_L2D_DELIVERY_CONFIRMATION: "processor_l2d_delivery_confirmation_table",
  PROCESSOR_L2L_DELIVERY_CONFIRMATION: "processor_l2l_delivery_confirmation_table",
  TAGS: "tags_table",
  USER_MANAGEMENT_TABLE: "user_management_table",
  DELIVERIES_TABLE: "deliveries_table",
  CREATE_DELIVERY_ANIMALS_TABLE: "create_delivery_animals_table",
  DELIVERED_ANIMALS_TABLE: "delivered_animals_table",
  FINANCIAL_TRANSACTIONS_TABLE: "financial_transactions_table",
  FINANCIAL_TRANSACTION_ANIMALS: "financial_transaction_animals_table",
  FINANCIAL_TRANSACTIONS_ALL_ANIMALS: "financial_transaction_all_animals_table",
} as const;
