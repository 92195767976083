// @ts-nocheck
// React
import React from "react";
// Components
import { DeliveryAddress } from "components/Settings";
import ModifyDeliveryAddressForm from "pages/Settings/BusinessSettings/ModifyDeliveryAddressForm";
import {
  Flex,
  Header,
  LoadingOverlay,
  PageHeader,
  PageContent,
  Panel,
  ConfirmDeletionModal,
  Spacer,
  Text,
  EmptyState,
} from "components";
import ConnectedSystems from "./ConnectedSystems";
import Options from "./Options";
import FAQSettings from "./FAQSettings";
// Utils
import { useGetDeliveryAddresses, useBreakpoints, useGetBusinessUnitSettings } from "hooks";
// Resources
import styles from "./FarmSettings.module.scss";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";

interface FarmSettingsProps {
  deliveryAddresses: {
    deliveryAddresses: DeliveryAddressData[];
    loading: boolean;
  };
  toggleForm: () => void;
  showForm: boolean;
  editMode: boolean;
  error: string | null;
  showModal: boolean;
  showSystemForm: boolean;
  toggleSystemForm: () => void;
  handleCloseForm: () => void;
  removeDeliveryAddress: () => Promise<void>;
  handleCloseRemoveModal: () => void;
  handleOpenRemoveModal: (props: Record<string, unknown>) => void;
  handleSetEditData: (props: Record<string, unknown>) => void;
  editData: Record<string, unknown>;
}

const FarmSettings: React.FC<FarmSettingsProps> = ({
  error,
  toggleSystemForm,
  showSystemForm,
  toggleForm,
  showForm,
  handleOpenRemoveModal,
  editMode,
  editData,
  showModal,
  onRemoveDeliveryAddress,
  handleSetEditData,
  onCloseForm,
  handleCloseRemoveModal,
}) => {
  const { isBreakpointXL } = useBreakpoints();
  const {
    data: dataAddresses,
    loading: deliveryAddressesLoading,
    refetch: refetchDeliveryAddress,
  } = useGetDeliveryAddresses();
  const { data: optionsData, loading: optionsDataLoading } = useGetBusinessUnitSettings();
  const isDeliveryAddressesEmptyStateVisible = !dataAddresses?.deliveryAddresses.length;
  const { terms } = useLocale();

  const handleCloseForm = (): void => {
    onCloseForm(refetchDeliveryAddress);
  };
  const handleRemoveDeliveryAddress = (): void => {
    onRemoveDeliveryAddress(refetchDeliveryAddress);
  };

  const farmSettingsLoading = optionsDataLoading || deliveryAddressesLoading;

  return (
    <>
      <PageHeader>
        <Header title={`${capitaliseFirstLetter(terms.farm)} Settings`} subTitle="Manage your business information" />
      </PageHeader>

      <PageContent>
        <Spacer allowDecimal baselineHeight={2.5} />

        <Flex container>
          {/* Left column */}
          <Flex item itemGutter xs={12} xl={7}>
            {!showSystemForm ? (
              <>
                {!showForm ? (
                  <Panel
                    title="Location information"
                    actions={[
                      {
                        colour: "yellow",
                        onClick: toggleForm,
                        caption: "Add location",
                      },
                    ]}
                  >
                    <>
                      <Text smaller>Enter the details of all your holdings here:</Text>
                      {isDeliveryAddressesEmptyStateVisible ? (
                        <EmptyState page="ADDRESSES" />
                      ) : (
                        <>
                          <Spacer baselineHeight={2} />
                          {(dataAddresses?.deliveryAddresses || []).map((deliveryAddress, index) => (
                            <React.Fragment key={deliveryAddress?.id}>
                              <DeliveryAddress
                                deliveryAddress={deliveryAddress}
                                onEditAddress={handleSetEditData}
                                onToggleRemoveModal={handleOpenRemoveModal}
                              />
                              {index + 1 !== dataAddresses?.deliveryAddresses.length ? (
                                <Spacer baselineHeight={3} />
                              ) : null}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </>
                  </Panel>
                ) : null}

                {showForm ? (
                  <ModifyDeliveryAddressForm
                    farm
                    editData={editData}
                    editMode={editMode}
                    onCloseForm={handleCloseForm}
                  />
                ) : null}
              </>
            ) : null}

            <ConfirmDeletionModal
              errorMessage={error}
              showModal={showModal}
              onClose={handleCloseRemoveModal}
              entityName={editData.unitName}
              onSubmit={handleRemoveDeliveryAddress}
            />
            {!showForm ? (
              <>
                {!showSystemForm ? <Spacer allowDecimal baselineHeight={2.5} /> : null}

                <ConnectedSystems showSystemForm={showSystemForm} toggleSystemForm={toggleSystemForm} />
              </>
            ) : null}

            <Spacer baselineHeight={2.5} />

            {!showSystemForm && !showForm ? <Options optionValues={optionsData} /> : null}
          </Flex>

          {!isBreakpointXL ? <Spacer allowDecimal baselineHeight={2.5} /> : null}

          {/* Right column */}
          <Flex item itemGutter xs={12} xl={5}>
            <FAQSettings />
          </Flex>
        </Flex>
      </PageContent>

      {farmSettingsLoading ? <LoadingOverlay customStyles={styles.loadingOverlay} /> : null}
    </>
  );
};

export default FarmSettings;
