/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
// @ts-nocheck
import React, { useMemo, useCallback, useEffect } from "react";
import { useTable, useSortBy, useBlockLayout } from "react-table";
import { FixedSizeList } from "react-window";
import cn from "classnames";

import { LoadingOverlay } from "components";

import { Icon } from "components/Common/Icon";

import styles from "./styles.module.scss";

type AccessorFunction = (params: any, i: number) => string | number;
type CellFunction = (params: any) => string | JSX.Element | null;
type HeaderFunction = (params: any) => string | JSX.Element | null;

interface ColumnProps {
  accessor: string | AccessorFunction;
  id?: string;
  show?: boolean;
  Header: string | JSX.Element | HeaderFunction;
  Cell?: JSX.Element | CellFunction;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  columnSort?: boolean;
}

interface Props {
  columns: ColumnProps[];
  data: any[];
  rowHeight?: number;
  height?: number;
  loading?: boolean;
  virtualized?: boolean;
  defaultSortBy?: string;
  defaultSortByDesc?: boolean;
  // "show" is more flexible but does not work in current version of "react-table"
  // https://github.com/tannerlinsley/react-table/issues/1804
  initialHiddenColumns?: string[];
}

export const Table: React.FC<Props> = ({
  columns,
  data,
  rowHeight = 40,
  height = 400,
  loading,
  virtualized,
  defaultSortBy,
  defaultSortByDesc = false,
  initialHiddenColumns,
}) => {
  const defaultColumn = useMemo(
    () => ({
      width: 150,
    }),
    [],
  );

  const initialSortByState = useMemo(
    () => (defaultSortBy ? { sortBy: [{ id: defaultSortBy, desc: defaultSortByDesc }] } : {}),
    [defaultSortBy, defaultSortByDesc],
  );
  const initialHiddenColumnsState = useMemo(
    () => (initialHiddenColumns ? { hiddenColumns: initialHiddenColumns } : {}),
    [initialHiddenColumns],
  );

  const initialState = useMemo(() => {
    return {
      ...initialSortByState,
      ...initialHiddenColumnsState,
    };
  }, [initialSortByState, initialHiddenColumnsState]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow, setHiddenColumns } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState,
      },
      useBlockLayout,
      useSortBy,
    );

  useEffect(() => {
    if (initialHiddenColumns) {
      setHiddenColumns(initialHiddenColumns);
    }
  }, [initialHiddenColumns, setHiddenColumns]);

  const renderCells = (row): JSX.Element => {
    return row.cells.map((cell) => {
      return (
        <div {...cell.getCellProps()} className={styles.td}>
          {cell.render("Cell")}
        </div>
      );
    });
  };

  const RenderVirtualizadRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];

      prepareRow(row);

      return (
        <div {...row.getRowProps({ style })} className={styles.tr}>
          {renderCells(row)}
        </div>
      );
    },
    [prepareRow, rows],
  );

  const renderRows = (
    <>
      {rows.map((row) => {
        prepareRow(row);

        return (
          <div {...row.getRowProps()} className={styles.tr}>
            {renderCells(row)}
          </div>
        );
      })}
    </>
  );

  const tableStyles = useMemo(() => {
    return cn(styles.table, {
      [styles.loading]: loading,
      [styles.virtualized]: virtualized,
    });
  }, [loading, virtualized]);

  return (
    <div {...getTableProps()} className={tableStyles}>
      {loading ? <LoadingOverlay /> : null}
      <div>
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const columnWithSort = column.columnSort !== false;
              const headerProps = columnWithSort
                ? column.getHeaderProps(column.getSortByToggleProps())
                : column.getHeaderProps();

              return (
                <div {...headerProps} className={styles.th}>
                  {column.render("Header")}
                  {columnWithSort ? (
                    <span className={styles.sortIcon}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon size="atom" name="rectangle" />
                        ) : (
                          <Icon size="atom" name="rectangle" rotate={180} />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        {virtualized ? (
          <FixedSizeList height={height} itemCount={rows.length} itemSize={rowHeight} width={totalColumnsWidth}>
            {RenderVirtualizadRow}
          </FixedSizeList>
        ) : (
          renderRows
        )}
      </div>
    </div>
  );
};

export default Table;
