import { Icon } from "components/Common/Icon";

export default function RowToggleButton({ row }) {
  return (
    <button
      {...{
        onClick: row.getToggleExpandedHandler(),
        style: { cursor: "pointer" },
        className: "text-yellow-600 text-center w-full",
      }}
    >
      {row.getIsExpanded() ? (
        <Icon name="minus" size="small" colour="yellow" />
      ) : (
        <Icon name="plus" size="small" colour="yellow" />
      )}
    </button>
  );
}
