import { EmptyStates } from "components";
import { LottieAnimation } from "components/Common/EmptyStates";

export const EmptyState = ({
  title,
  description,
  animation = "searching",
}: {
  title: string;
  description: string;
  animation?: LottieAnimation;
}) => {
  return (
    <EmptyStates animation={animation} title={title}>
      <p>{description}</p>
    </EmptyStates>
  );
};
