import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "api/config";
import { useGetCurrentUserInfo } from "hooks";
import { useHubSpot } from "hooks/useHubSpot";

export const HubSpotWatcher: React.FC = () => {
  const location = useLocation();
  const { data } = useGetCurrentUserInfo();
  const { isHubSpotChatEnabled, openHubSpotChat } = useHubSpot();

  useEffect(() => {
    if (!isHubSpotChatEnabled) {
      return;
    }
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${config.hubspotTrackingCode}.js`;

    document.body.appendChild(script);
  }, [isHubSpotChatEnabled]);

  // Track Page views for hubspot
  React.useEffect(() => {
    if (!isHubSpotChatEnabled) {
      return;
    }
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", location.pathname]);
    _hsq.push(["trackPageView"]);

    // This allows a url to open a chat window
    if (location.search.includes("open_chat")) {
      setTimeout(() => {
        openHubSpotChat("chat"); // clear the query string so we don't open multiple times
      }, 3000);
    }
  }, [isHubSpotChatEnabled, location.pathname, location.search, openHubSpotChat]);

  // Tell hubspot the user email
  React.useEffect(() => {
    if (!isHubSpotChatEnabled) {
      return;
    }
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["identify", { email: data?.userInfo?.email }]);
  }, [data?.userInfo?.email, isHubSpotChatEnabled]);

  return null;
};
