import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesTradingLists: FC = () => {
  return (
    <EmptyStates animation={"lock"} title="Want to share your listings privately?">
      <p>
        Create your lists here. Then, when you create your listing you can select to share them privately so only your
        lists see them.
        <br />
        Tap ‘+ Create’ to create your first list.
      </p>
    </EmptyStates>
  );
};
