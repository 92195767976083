import { createColumnHelper } from "@tanstack/react-table";
import { AmericanAngusEpd, NeogenEpd } from "generated/graphql";
import { transformEpdData } from "../helpers";
import { Epd } from "../types";
import { Link } from "react-router-dom";
import { IMPORT, SUPPORTED_SYSTEM_IMPORT } from "constants/Routes";
import { useFormatDate } from "hooks";
import { Table } from "twComponents/Table";
import { TABLE_IDS } from "constants/Interface";
import { Button } from "twComponents/Button";

interface EpdTableProps {
  epdData:
    | {
        data: AmericanAngusEpd | NeogenEpd | null | undefined;
        testName: string;
      }
    | null
    | undefined;
  loading: boolean;
}

const emptyStateComponent = (
  <div className="pl-2 pt-2">
    No epd test results found. Upload genetic partner test results
    <Button className="text-yellow-600 text-md" variant="text" size="xs">
      <Link to={`${IMPORT}${SUPPORTED_SYSTEM_IMPORT}`}>here</Link>
    </Button>
  </div>
);

export const EpdDataTable: React.FC<EpdTableProps> = ({ epdData, loading }) => {
  const { data, testName } = epdData || {};
  const { getFormatDate } = useFormatDate();

  const columnHelper = createColumnHelper<Epd>();

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor((row) => row.val, {
      id: "epd",
      header: () => <span>Value</span>,
      cell: (info) => (info.getValue() != null ? info.getValue()?.toFixed(2) : "-"),
    }),
    columnHelper.accessor("pct", {
      id: "pct",
      header: () => <span>Percentile</span>,
      cell: (info) => (info.getValue() != null ? info.getValue() : "-"),
    }),
    columnHelper.accessor("acc", {
      id: "acc",
      header: () => <span>Accuracy</span>,
      cell: (info) => (info.getValue() != null ? info.getValue()?.toFixed(2) : "-"),
    }),
  ];

  const epdDataArray = epdData ? transformEpdData(data) : [];

  return (
    <>
      <Table
        tableId={TABLE_IDS.EPD_RESULTS}
        title="EPD Results"
        loading={loading}
        options={{ data: epdDataArray, columns, enableSorting: false }}
        emptyStateComponent={emptyStateComponent}
      />
      <span className="text-sm text-gray-700 pl-2 pt-2 flex justify-end ">Testing body: {testName ?? "-"}</span>
      <span className="text-sm text-gray-700 pl-2 pt-2 flex justify-end ">
        Last updated: {getFormatDate(data?.updatedAt ?? data?.createdAt) ?? "-"}
      </span>
    </>
  );
};
