import { useMutation } from "@apollo/client";
import { OfferPriceUnit, OfferStatus } from "generated/graphql";
import { ResponseToLotOffer, ResponseToLotOfferVariables } from "trading/api/Liveweight/types/ResponseToLotOffer";
import { UPDATE_LOT_OFFER_STATUS } from "trading/api/Liveweight/mutations";
import { getFormattedPrice } from "helpers/general";
import { useGetCurrentBusinessUnit } from "hooks";
import { SellerListingOffersDrillProps } from "../..";
import { Modal, Flex, Text, Spacer, Title } from "components";
import { EventName, logAnalyticsEvent } from "utils/analytics";

interface SellerListingOfferModalProps extends SellerListingOffersDrillProps {
  buyerName: {
    firstName: string | undefined;
    lastName: string | undefined;
  };
  isActive: boolean;
  isOfferDecisionAccept: boolean;
  offerAnimalsQuantity: number;
  offerId: string;
  offerPrice: number;
  offerPriceUnit: OfferPriceUnit | null | undefined;
  offerTotalPriceOnCreation: number | null | undefined;
  onCloseModal: () => void;
}

export const SellerListingOfferModal: React.FC<SellerListingOfferModalProps> = ({
  buyerName,
  isActive,
  isOfferDecisionAccept,
  lotConfigurableSellerMessage,
  offerAnimalsQuantity,
  offerId,
  offerPrice,
  offerPriceUnit,
  offerTotalPriceOnCreation,
  onAfterMutateListing,
  onCloseModal,
}) => {
  const [responseToLotOffer, { loading: responseToLotOfferLoading }] = useMutation<
    ResponseToLotOffer,
    ResponseToLotOfferVariables
  >(UPDATE_LOT_OFFER_STATUS);

  const handleSubmitDecision = async (offerId: string, offerDecision: OfferStatus): Promise<void> => {
    try {
      await responseToLotOffer({
        variables: {
          input: {
            offer: Number(offerId),
            status: offerDecision,
          },
        },
      });

      onCloseModal();
      onAfterMutateListing();
      logAnalyticsEvent(EventName.offerDecision);
    } catch (error) {
      // eslint-disable-next-line
      console.log("handleSubmitDecision() error:", error);
    }
  };

  const handleClickReject = (): void => {
    handleSubmitDecision(offerId, OfferStatus.Declined);
  };

  const handleClickAccept = (): void => {
    handleSubmitDecision(offerId, OfferStatus.Accepted);
  };

  const rejectActions = {
    primary: {
      caption: "Reject offer",
      colour: "red",
      disabled: responseToLotOfferLoading,
      requesting: responseToLotOfferLoading,
      onClick: handleClickReject,
    },
    secondary: {
      caption: "Back",
      onClick: onCloseModal,
    },
  };
  const acceptActions = {
    primary: {
      caption: "Accept offer",
      disabled: responseToLotOfferLoading,
      requesting: responseToLotOfferLoading,
      onClick: handleClickAccept,
    },
    secondary: {
      caption: "Back",
      onClick: onCloseModal,
    },
  };
  const actions = isOfferDecisionAccept ? acceptActions : rejectActions;

  const decision = isOfferDecisionAccept ? "Accept" : "Reject";
  const modalTitle = `${decision} offer confirmation`;

  const name = buyerName.firstName ? `${buyerName.firstName} ${buyerName.lastName}` : "\u2014";

  const { currencyCodeISO } = useGetCurrentBusinessUnit();

  const offerPricePerHead = offerPriceUnit === OfferPriceUnit.Head ? offerPrice : offerPrice / offerAnimalsQuantity;

  const formattedOfferPriceTotal =
    offerPriceUnit === OfferPriceUnit.Total
      ? getFormattedPrice(offerPrice, currencyCodeISO)
      : offerTotalPriceOnCreation && getFormattedPrice(offerTotalPriceOnCreation, currencyCodeISO);

  const formattedOfferPricePerHead = getFormattedPrice(offerPricePerHead, currencyCodeISO);
  const formattedOfferPricePerKg = getFormattedPrice(offerPrice, currencyCodeISO);

  const offerTypeLabel =
    offerPriceUnit === OfferPriceUnit.Head || offerPriceUnit === OfferPriceUnit.Total ? "per head" : "per kg";

  const formattedOffer =
    offerPriceUnit === OfferPriceUnit.Head || offerPriceUnit === OfferPriceUnit.Total
      ? formattedOfferPricePerHead
      : formattedOfferPricePerKg;

  return (
    <Modal actions={actions} active={isActive} handleClose={onCloseModal} isChildrenGutterManual title={modalTitle}>
      <Flex container>
        <Flex item itemGutter>
          <Text>
            You have selected that you wish to <strong>{decision}</strong> the offer from <strong>{name}</strong> which
            is:
          </Text>
        </Flex>

        <Spacer baselineHeight={3} />

        <Flex item itemGutter xs={2}>
          <Text>Offer {offerTypeLabel}</Text>

          <Title secondary>{formattedOffer}</Title>
        </Flex>

        <Flex item>
          <Text>No of Animals: </Text>

          <Title secondary>{offerAnimalsQuantity}</Title>
        </Flex>

        <Spacer baselineHeight={3} />

        <Flex item itemGutter xs={4}>
          <Text>Total offer value:</Text>

          <Title secondary>{formattedOfferPriceTotal}</Title>
        </Flex>

        <Spacer baselineHeight={3} />

        <Flex item itemGutter>
          <Text smallest>
            By selecting <strong>{decision}</strong> offer, {lotConfigurableSellerMessage}.
          </Text>
        </Flex>
      </Flex>
    </Modal>
  );
};
