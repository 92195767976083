const maleIcon = (
  <svg fill="lightBlue" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 393.739 393.739">
    <circle cx="50%" cy="50%" r="50%" fill="transparent" stroke="none" />
    <g>
      <path
        d="M370.907,0h-93.048c-9.091,0-16.455,7.365-16.455,16.45c0,9.085,7.364,16.453,16.455,16.453h43.19L217.25,136.704
		c-21.049-12.879-45.768-20.318-72.194-20.318c-76.468,0-138.679,62.208-138.679,138.676c0,76.474,62.211,138.678,138.679,138.678
		s138.678-62.204,138.678-138.678c0-33.07-11.655-63.455-31.037-87.314L354.462,65.985v49.231c0,9.085,7.365,16.452,16.444,16.452
		c9.09,0,16.455-7.367,16.455-16.452V16.45C387.362,7.365,379.997,0,370.907,0z M145.056,346.737
		c-50.546,0-91.673-41.127-91.673-91.676c0-50.543,41.121-91.667,91.673-91.667c50.546,0,91.664,41.124,91.664,91.667
		C236.72,305.61,195.602,346.737,145.056,346.737z"
      />
    </g>
  </svg>
);

const femaleIcon = (
  <svg fill="lightPink" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 477.141 477.141">
    <g>
      <path
        d="M326.711,341.99h-64.628v-64.634c0-0.686-0.142-1.323-0.213-1.997c65.409-11.119,115.378-68.161,115.378-136.681
		C377.249,62.208,315.044,0,238.571,0C162.103,0,99.892,62.208,99.892,138.678c0,68.521,49.973,125.562,115.375,136.681
		c-0.059,0.674-0.204,1.312-0.204,1.997v64.634h-64.631c-12.983,0-23.501,10.527-23.501,23.502c0,12.98,10.518,23.507,23.501,23.507
		h64.637v64.64c0,12.98,10.524,23.502,23.501,23.502c12.979,0,23.513-10.527,23.513-23.502v-64.64h64.628
		c12.986,0,23.508-10.526,23.508-23.507C350.219,352.518,339.698,341.99,326.711,341.99z M146.904,138.678
		c0-50.546,41.121-91.673,91.667-91.673c50.549,0,91.676,41.122,91.676,91.673s-41.127,91.667-91.676,91.667
		C188.031,230.346,146.904,189.224,146.904,138.678z"
      />
    </g>
  </svg>
);

const externalLinkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512" fill="#555">
    <path d="M448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-88 16H248c-21.3 0-32.1 25.9-17 41l32 32L67.5 364.5c-4.7 4.7-4.7 12.3 0 17l31 31c4.7 4.7 12.3 4.7 17 0l195.5-195.5 32 32C358.1 264 384 253.4 384 232V120c0-13.3-10.7-24-24-24z" />
  </svg>
);

const alertIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512" fill="#B60535">
    <path d="M569.5 440C588 472 564.8 512 527.9 512H48.1c-36.9 0-60-40.1-41.6-72L246.4 24c18.5-32 64.7-32 83.2 0l239.9 416zM288 354c-25.4 0-46 20.6-46 46s20.6 46 46 46 46-20.6 46-46-20.6-46-46-46zm-43.7-165.3l7.4 136c.3 6.4 5.6 11.3 12 11.3h48.5c6.4 0 11.6-5 12-11.3l7.4-136c.4-6.9-5.1-12.7-12-12.7h-63.4c-6.9 0-12.4 5.8-12 12.7z" />
  </svg>
);

const collapseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
    <path d="M200 288H88c-21.4 0-32.1 25.8-17 41l32.9 31-99.2 99.3c-6.2 6.2-6.2 16.4 0 22.6l25.4 25.4c6.2 6.2 16.4 6.2 22.6 0L152 408l31.1 33c15.1 15.1 40.9 4.4 40.9-17V312c0-13.3-10.7-24-24-24zm112-64h112c21.4 0 32.1-25.9 17-41l-33-31 99.3-99.3c6.2-6.2 6.2-16.4 0-22.6L481.9 4.7c-6.2-6.2-16.4-6.2-22.6 0L360 104l-31.1-33C313.8 55.9 288 66.6 288 88v112c0 13.3 10.7 24 24 24zm96 136l33-31.1c15.1-15.1 4.4-40.9-17-40.9H312c-13.3 0-24 10.7-24 24v112c0 21.4 25.9 32.1 41 17l31-32.9 99.3 99.3c6.2 6.2 16.4 6.2 22.6 0l25.4-25.4c6.2-6.2 6.2-16.4 0-22.6L408 360zM183 71.1L152 104 52.7 4.7c-6.2-6.2-16.4-6.2-22.6 0L4.7 30.1c-6.2 6.2-6.2 16.4 0 22.6L104 152l-33 31.1C55.9 198.2 66.6 224 88 224h112c13.3 0 24-10.7 24-24V88c0-21.3-25.9-32-41-16.9z" />
  </svg>
);

const truckIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 640 512" fill="#555">
    <path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z" />
  </svg>
);

const pedigreeIcon = (
  <svg width="16" height="16" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    {/* <circle cx="50%" cy="50%" r="50%" fill="green" /> */}
    <path
      d="M7.6 21.2H3.4C2.62694 21.2 2 21.827 2 22.6V26.8C2 27.5731 2.62694 28.2 3.4 28.2H7.6C8.37306 28.2 9 27.5731 9 26.8V22.6C9 21.827 8.37306 21.2 7.6 21.2ZM6.55 16.7H14.95V19.8H17.05V16.7H25.45V19.8H27.55V16.2805C27.55 15.3543 26.7962 14.6 25.8696 14.6H17.05V10.8H18.8C19.5731 10.8 20.2 10.1731 20.2 9.40005V5.20005C20.2 4.42699 19.5731 3.80005 18.8 3.80005H13.2C12.4269 3.80005 11.8 4.42699 11.8 5.20005V9.40005C11.8 10.1731 12.4269 10.8 13.2 10.8H14.95V14.6H6.13044C5.20381 14.6 4.45 15.3539 4.45 16.2805V19.8H6.55V16.7ZM18.1 21.2H13.9C13.1269 21.2 12.5 21.827 12.5 22.6V26.8C12.5 27.5731 13.1269 28.2 13.9 28.2H18.1C18.8731 28.2 19.5 27.5731 19.5 26.8V22.6C19.5 21.827 18.8731 21.2 18.1 21.2ZM28.6 21.2H24.4C23.6269 21.2 23 21.827 23 22.6V26.8C23 27.5731 23.6269 28.2 24.4 28.2H28.6C29.3731 28.2 30 27.5731 30 26.8V22.6C30 21.827 29.3731 21.2 28.6 21.2Z"
      fill="#555"
    />
  </svg>
);
export const Icons = {
  maleIcon,
  femaleIcon,
  externalLinkIcon,
  alertIcon,
  collapseIcon,
  truckIcon,
  pedigreeIcon,
};
