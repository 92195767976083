import { useQuery } from "@apollo/client";
import { getBUFromLocalStorage } from "helpers/storage";
import { GET_ANIMAL_COUNT } from "api/MyLivestock/Animal/queries";
import { GetAnimalCount, GetAnimalCountVariables } from "api/MyLivestock/Animal/types/GetAnimalCount";

export const useGetAnimalCount = () => {
  const { data, ...rest } = useQuery<GetAnimalCount, GetAnimalCountVariables>(GET_ANIMAL_COUNT, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const offersCount = data?.animalsCount?.totalAnimals || 0;

  return {
    data: offersCount,
    ...rest,
  };
};
