import { gql } from "@apollo/client";

export const lineItemFragment = gql`
  fragment lineItem on LineItemType {
    id
    name
    sku
    purchaseDate
    expiryDate
    batchNumber
    quantity
    totalVolume
    originalVolume
    quantityUnits
    isArchived
    volumeUnits
    cost
    supplierName
    stockItem {
      id
      name
    }
  }
`;

export const stockItemFragment = gql`
  fragment stockItem on StockItemListType {
    id
    name
    totalQuantity
    totalVolume
    species {
      id
      name
    }
    manufacturer
    manufacturerName
    applicationMethod
    activeIngredient
    withdrawal {
      id
      animalType {
        id
        name
        slug
      }
      withdrawalPeriodDays
      withdrawalType
    }
    lineItems {
      ...lineItem
    }
  }
  ${lineItemFragment}
`;
