import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { isEqual } from "lodash";

export type TableFilterIdentifier = "animalsList" | "groupsList" | "fieldsList" | "tagsList";

export interface AnimalFilters {
  fields: string[];
  groups: string[];
  tagIds: string[];
  sex: string;
  breeds: string[];
  pregnancyStatus: string;
  dateOfBirth: {
    from: string | undefined;
    to: string | undefined;
  };
  dateOnFarm: {
    from: string | undefined;
    to: string | undefined;
  };
  dateLeftFarm: {
    from: string | undefined;
    to: string | undefined;
  };
  isRegulatorySynced: boolean | undefined;
  isInWithdrawal: boolean | undefined;
  regulatorySyncDate: {
    from: string | undefined;
    to: string | undefined;
  };
  tradingDeliveryDate: {
    from: string | undefined;
    to: string | undefined;
  };
  pregnancyDueDate: {
    from: string | undefined;
    to: string | undefined;
  };
  growthRate: {
    from: string | undefined;
    to: string | undefined;
  };
  daysSinceWeighed: {
    from: string | undefined;
    to: string | undefined;
  };
  ageInMonths: {
    from: string | undefined;
    to: string | undefined;
  };
  lastWeight: {
    from: string | undefined;
    to: string | undefined;
  };
  estimatedWeight: {
    from: string | undefined;
    to: string | undefined;
  };
}

const INITIAL_FILTERS: AnimalFilters = {
  fields: [],
  groups: [],
  tagIds: [],
  sex: "",
  breeds: [],
  pregnancyStatus: "",
  dateOfBirth: {
    from: undefined,
    to: undefined,
  },
  dateOnFarm: {
    from: undefined,
    to: undefined,
  },
  dateLeftFarm: {
    from: undefined,
    to: undefined,
  },
  isRegulatorySynced: undefined,
  isInWithdrawal: undefined,
  regulatorySyncDate: {
    from: undefined,
    to: undefined,
  },
  tradingDeliveryDate: {
    from: undefined,
    to: undefined,
  },
  pregnancyDueDate: {
    from: undefined,
    to: undefined,
  },
  growthRate: {
    from: undefined,
    to: undefined,
  },
  daysSinceWeighed: {
    from: undefined,
    to: undefined,
  },
  ageInMonths: {
    from: undefined,
    to: undefined,
  },
  lastWeight: {
    from: undefined,
    to: undefined,
  },
  estimatedWeight: {
    from: undefined,
    to: undefined,
  },
};

interface InitialFilterState {
  filters: AnimalFilters;
  showFilters: boolean;
}

const initialFilterState: InitialFilterState = {
  filters: INITIAL_FILTERS,
  showFilters: false,
};

type State = Record<TableFilterIdentifier, InitialFilterState>;

const initialState: State = {
  animalsList: initialFilterState,
  tagsList: initialFilterState,
  fieldsList: initialFilterState,
  groupsList: initialFilterState,
};

type FiltersPayload = { tableIdentifier: TableFilterIdentifier; filters: AnimalFilters };

export const animalFiltersSlice = createSlice({
  name: "animalFilters",
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<FiltersPayload>) => {
      const { tableIdentifier } = action.payload;
      const updatedFilters = action.payload.filters;
      state[tableIdentifier].filters = updatedFilters;
    },
    toggleFilterVisibility: (state, action: PayloadAction<TableFilterIdentifier>) => {
      state[action.payload].showFilters = !state[action.payload].showFilters;
    },
    showAllFilters: (state, action: PayloadAction<TableFilterIdentifier>) => {
      state[action.payload].showFilters = true;
    },
    resetFilters: (state, action: PayloadAction<TableFilterIdentifier>) => {
      state[action.payload].filters = INITIAL_FILTERS;
    },
  },
});

const selectFiltersState = (state: RootState) => state.animalFilters;

export const areFiltersForTableDirty = (tableIdentifier: TableFilterIdentifier) =>
  createSelector(selectFiltersState, (state) => {
    return !isEqual(state[tableIdentifier].filters, INITIAL_FILTERS);
  });

export const { updateFilters, toggleFilterVisibility, resetFilters, showAllFilters } = animalFiltersSlice.actions;
export const animalFiltersReducer = animalFiltersSlice.reducer;
