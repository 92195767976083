import * as yup from "yup";
import gPhoneNumber from "google-libphonenumber";

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();
yup.addMethod(
  yup.string,
  "phone",
  function yupPhone({
    countryCode = "GB",
    errorMessage = "",
    isRequired,
  }: {
    countryCode: string;
    errorMessage?: string;
    isRequired: boolean;
  }) {
    const errMsg =
      typeof errorMessage === "string" && errorMessage ? errorMessage : `Must be a valid ${countryCode} phone number.`;
    // @ts-expect-error
    return this.test("phone", errMsg, (value: string) => {
      try {
        if (!isRequired && value.length === 0) {
          return true;
        }

        const phoneNumber = phoneUtil.parseAndKeepRawInput(value, countryCode);

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          return false;
        }
        return phoneUtil.isValidNumberForRegion(phoneNumber, countryCode);
      } catch {
        return false;
      }
    });
  },
);
