export const ELEMENTS_IDS = {
  FORGOT_PASSWORD_LINK: "forgot_password_link",
  EMAIL_LINK_CONTACT_BREEDR: "email_link_contact_breedr",
  TERMS_OF_SERVICE_LINK: "terms_of_service_link",
  PRIVACY_POLICY_LINK: "privacy_policy_link",
  CREATE_ACCOUNT_FORM_BUTTON_NEXT_STEP: "create_account_form_button_next_step",
  FARM_DETAILS_FORM_CREATE_ACCOUNT_BUTTON: "farm_details_form_create_account_button",
  INFO_MESSAGE_BUTTON: "info_message_button",
  SCREEN_WRAPPER_LOGO_LINK: "screen_ wrapper_logo_link",
  COUNTY_SELECT_FIELD: "county_select_field",
  AUTH_TOOLTIP: "auth_tooltip",
  DOWNLOAD_EXAMPLE_TEMPLATE_BUTTON: "download_example_template",
  IMPORT_DELETE_FILE_BUTTON: "import_delete_file_button",
  IMPORT_CREATE_ANIMAL_BLOCK_ADD_LATER_BUTTON: "import_create_animal_block_add_later_button",
  IMPORT_CREATE_ANIMAL_BLOCK_CREATE_BUTTON: "import_create_animal_block_create_button",
  IMPORT_HEADER_SKIP_STEP_LINK: "import_header_skip_step_link",
  IMPORT_RESULT_DOWNLOAD_IMPORTED_ANIMALS_BUTTON: "import_result_download_imported_animals_button",
  IMPORT_RESULT_DOWNLOAD_IMPORTED_ACTIVITIES_BUTTON: "import_result_download_imported_activities_button",
  IMPORT_SELECT_CUSTOM_TYPE_BUTTON: "import_select_custom_type_button",
  MESSAGING_IMAGE_MESSAGE_BLOCK: "messaging_image_message_block",
  MESSAGING_CHANNEL_CHANGE_BLOCK: "messaging_channel_change_block",
  ANIMALS_ADD_TO_GROUP_BUTTON: "animals_add_to_group_button",
  ANIMAL_INFO_BLOCK_LINK_TO_RFL: "animal_info_block_link_to_rfl",
  ANIMAL_INFO_BLOCK_CHANGE_GROUP_LINK: "animal_info_block_change_group_link",
  ANIMAL_INFO_BLOCK_ADD_TO_GROUP_LINK: "animal_info_block_add_to_group_link",
  ANIMAL_INFO_BLOCK_MOVE_TO_FIELD_LINK: "animal_info_block_move_to_field_link",
  ANIMALS_SEARCH_INPUT: "animals_search_input",
  ANIMALS_MOVE_TO_FIELD_BUTTON: "animals_move_to_field_button",
  ANIMAL_MOVE_OFF_FARM_MODAL_CANCEL: "animal_move_off_farm_modal_cancel",
  ANIMAL_MOVE_OFF_FARM_MODAL_CONFIRM: "animal_move_off_farm_modal_confirm",
  GROUPS_EDIT_MODE_CANCEL_BUTTON: "groups_edit_mode_cancel_button",
  GROUPS_EDIT_MODE_ADD_TO_GROUP_BUTTON: "groups_edit_mode_add_to_group_button",
  GROUP_ITEM_BLOCK: "group_item_block",
  MOVE_GROUP_OF_ANIMALS_TO_FIELD_BUTTON: "move_group_of_animals_to_field_button",
  GROUP_COMMIT_TO_RFL_BUTTON: "group_commit_to_rfl_button",
  GROUP_ADD_ANIMALS_TO_GROUP_BUTTON: "group_add_animals_to_group_button",
  CONNECTED_SYSTEM_ITEM_EDIT_BUTTON: "connected_system_item_edit_button",
  DELIVERY_ADDRESS_EDIT_BUTTON: "delivery_address_edit_button",
  DELIVERY_ADDRESS_DELETE_BUTTON: "delivery_address_delete_button",
  MODIFY_EMAIL_FORM_CANCEL_BUTTON: "modify_email_form_cancel_button",
  MODIFY_EMAIL_FORM_SAVE_CHANGES_BUTTON: "modify_email_form_save_changes_button",
  ADD_PREFERRED_SUPPLIERS_BUTTON: "add_preferred_suppliers_button",
  FORCE_PASSWORD_RESET_BUTTON: "force_password_reset_button",
  INVITE_USER_FORM_SAVE_CHANGES_BUTTON: "invite_user_form_save_changes_button",
  INVITE_USER_FORM_CANCEL_BUTTON: "invite_user_form_cancel_button",
  SET_PASSWORD_FORM_SET_PASSWORD_INPUT: "set_password_form_set_password_input",
  SET_PASSWORD_FORM_CONFIRM_PASSWORD_INPUT: "set_password_form_confirm_password_input",
  SET_PASSWORD_FORM_CANCEL_BUTTON: "set_password_form_cancel_button",
  SET_PASSWORD_FORM_SAVE_BUTTON: "set_password_form_save_button",
  PAGE_MENU_LINK: "page_menu_link",
  ROOT_MENU_LOGO: "root_menu_logo",
  ROOT_MENU_TOGGLE_BUTTON: "root_menu_toggle_button",
  LOAD_MORE_BUTTON: "load_more_button",
  ARCHIVE_TRADING_SEARCH_INPUT: "archive_trading_search_input",
  CONFIRM_ACCEPT_OFFER_MODAL_CONFIRM_BUTTON: "confirm_accept_offer_modal_confirm_button",
  CONFIRM_ACCEPT_OFFER_MODAL_CANCEL_BUTTON: "confirm_accept_offer_modal_cancel_button",
  OFFER_ITEM_BUTTON_REJECT: "offer_item_button_reject",
  OFFER_ITEM_BUTTON_ACCEPT: "offer_item_button_accept",
  OFFER_ITEM_STATUS_BUTTON: "offer_item_status_button",
  RFL_DOTS_ACTION_BUTTON: "rfl_dots_action_button",
  RFL_INFO_PANEL_CREATE_UPDATE_BUTTON: "rfl_info_panel_create_update_button",
  RFL_INFO_PANEL_SAVE_AS_TEMPLATE_BUTTON: "rfl_info_panel_save_as_template_button",
  RFL_INFO_PANEL_CANCEL_BUTTON: "rfl_info_panel_cancel_button",
  SAVE_RFL_TEMPLATE_MODAL_SAVE_BUTTON: "save_as_template_modal_save_button",
  SAVE_RFL_TEMPLATE_MODAL_CANCEL_BUTTON: "save_as_template_modal_save_button",
  UPLOAD_GRID_MODAL_SAVE_BUTTON: "upload_grid_modal_save_button",
  UPLOAD_GRID_MODAL_CANCEL_BUTTON: "upload_grid_modal_cancel_button",
  SOLD_LOT_VIEW_KILL_SHEET_BUTTON: "sold_lot_view_kill_sheet_button",
  TRADES_ITEM: "trades_item",
  DEFAULT_LOGIN_PAGE_LOGIN_BUTTON: "default_login_page_login_button",
  NEW_PASSWORD_PASSWORD_INPUT: "new_password_password_input",
  NEW_PASSWORD_SAVE_PASSWORD_BUTTON: "new_password_save_password_button",
  FORGOT_PASSWORD_EMAIL_INPUT: "forgot_password_email_input",
  FORGOT_PASSWORD_RESET_PASSWORD_BUTTON: "forgot_password_reset_password_button",
  SIGN_IN_FORM_EMAIL_INPUT: "sign_in_from_email_input",
  SIGN_IN_FORM_PASSWORD_INPUT: "sign_in_from_password_input",
  SIGN_IN_FORM_LOG_IN_BUTTON: "sign_in_from_log_in_button",
  SIGN_IN_SIGN_UP_LINK: "sign_in_sign_up_link",
  HOME_VIEW_AFFECTED_ANIMALS_BUTTON: "home_view_affected_animals_button",
  IMPORT_CLEAR_IMPORT_DATA_BUTTON: "import_clear_import_data_button",
  IMPORT_HIDE_SHOW_IMPORT_DATA_BUTTON: "import_hide_show_import_data_button",
  IMPORT_TRY_AGAIN_LINK: "import_try_again_link",
  IMPORT_CANCEL_BUTTON: "import_cancel_button",
  ANIMALS_BCMS_IMPORT_VIEW_MY_LIVESTOCK_BUTTON: "animals_bcms_import_view_my_livestock_button",
  ANIMALS_BCMS_IMPORT_GO_TO_SETTINGS_BUTTON: "animals_bcms_import_go_to_settings_button",
  CUSTOM_IMPORT_VIEW_MY_LIVESTOCK_BUTTON: "custom_import_view_my_livestock_button",
  CUSTOM_IMPORT_PREVIOUS_STEP_BUTTON: "custom_import_previous_step_button",
  CUSTOM_IMPORT_NEXT_STEP_BUTTON: "custom_import_next_step_button",
  CUSTOM_IMPORT_GO_TO_MY_ANIMALS_BUTTON: "custom_import_go_to_my_animals_button",
  CUSTOM_IMPORT_START_AGAIN_BUTTON: "custom_import_start_again_button",
  IMPORT_BLOCK_CANCEL_BUTTON: "import_block_cancel_button",
  IMPORT_BLOCK_IMPORT_BUTTON: "import_block_import_button",
  IMPORT_BLOCK_INSTRUCTIONS_LINK: "import_block_instructions_link",
  OTHER_SYSTEM_IMPORT_VIEW_MY_LIVESTOCK_BUTTON: "other_system_import_view_my_livestock_button",
  IMPORT_VIEW_MY_LIVESTOCK_BUTTON: "import_view_my_livestock_button",
  IMPORT_ADVANCED_IMPORT_BUTTON: "import_advanced_import_button",
  ICON_CLOSE_BUTTON: "icon_close_button",
  MESSAGE_TEXT_INPUT: "message_text_input",
  MESSAGE_SEND_BUTTON: "message_send_button",
  MESSAGING_UPLOAD_FILE_BUTTON: "messaging_upload_file_button",
  MESSAGING_NEW_MESSAGE_CANCEL_BUTTON: "messaging_new_message_cancel_button",
  MESSAGING_USER_SEARCH_SELECT: "messaging_user_search_select",
  ANIMAL_HISTORY_FILTERS_SHOW_ALL_LINK: "animal_history_filters_show_all_link",
  ADVANCED_IMPORT_BUTTON: "advanced_import_button",
  IMPORT_ANIMALS_BUTTON: "import_animals_button",
  IMPORT_WEIGHT_DATA_BUTTON: "import_weight_data_button",
  BULK_MARK_OFF_FARM_BUTTON: "BULK_MARK_OFF_FARM_BUTTON",
  BULK_CREATE_LISTING_SELECTION: "BULK_CREATE_LISTING_SELECTION",
  RESET_ANIMALS_FILTERS_BUTTON: "reset_animals_filters_button",
  MOVE_TO_FIELD_MODAL_CANCEL_BUTTON: "move_to_field_modal_cancel_button",
  MOVE_TO_FIELD_MODAL_CONFIRM_BUTTON: "move_to_field_modal_confirm_button",
  MOVE_TO_GROUP_MODAL_CONFIRM_BUTTON: "move_to_group_modal_confirm_button",
  MOVE_TO_GROUP_MODAL_CANCEL_BUTTON: "move_to_group_modal_cancel_button",
  ANIMALS_IN_FIELD_SEARCH_INPUT: "animals_in_field_search_input",
  EDIT_FIELD_BUTTON: "edit_field_button",
  ADD_FIELD_PLUS_BUTTON: "add_field_plus_button",
  GROUP_FIELD_HEADER_DELETE_BUTTON: "group_field_header_delete_button",
  GROUP_FIELD_HEADER_CANCEL_BUTTON: "group_field_header_cancel_button",
  GROUP_FIELD_HEADER_CREATE_UPDATE_BUTTON: "group_field_header_create_update_button",
  ASSIGN_ANIMALS_TO_GROUP_SEARCH_INPUT: "assign_animals_to_group_search_input",
  EDIT_GROUP_BUTTON: "edit_group_button",
  ADD_GROUP_PLUS_BUTTON: "add_group_plus_button",
  MODIFY_DELIVERY_ADDRESS_FORM_CANCEL_BUTTON: "modify_delivery_address_from_cancel_button",
  MODIFY_DELIVERY_ADDRESS_FORM_DELETE_BUTTON: "modify_delivery_address_from_delete_button",
  MODIFY_DELIVERY_ADDRESS_FORM_SAVE_PUBLISH_BUTTON: "modify_delivery_address_from_save_publish_button",
  ADD_NEW_LOCATION_BUTTON: "add_new_location_button",
  CONFIRM_WITH_PASSWORD_MODAL_PASSWORD_INPUT: "confirm_with_password_modal_password_input",
  CONFIRM_WITH_PASSWORD_MODAL_CANCEL_BUTTON: "confirm_with_password_modal_cancel_button",
  CONFIRM_WITH_PASSWORD_MODAL_CONFIRM_BUTTON: "confirm_with_password_modal_confirm_button",
  FARM_OPTIONS_CANCEL_BUTTON: "farm_options_cancel_button",
  FARM_OPTIONS_SAVE_BUTTON: "farm_options_save_button",
  CONNECT_TO_SYSTEM_FORM_SAVE_BUTTON: "connect_to_system_form_save_button",
  CONNECT_TO_SYSTEM_FORM_CANCEL_BUTTON: "connect_to_system_form_cancel_button",
  CONNECT_TO_SYSTEM_FORM_DELETE_BUTTON: "connect_to_system_form_delete_button",
  CONNECTED_SYSTEMS_ADD_NEW_CONNECTION: "connected_systems_add_new_connection",
  FARM_SETTINGS_ADD_NEW_ADDRESS_BUTTON: "farm_settings_add_new_address_button",
  MODIFY_PASSWORD_FORM_CHANGE_PASSWORD_BUTTON: "modify_password_form_change_password_button",
  SIRE_EDIT_FORM_CANCEL_BUTTON: "sire_edit_form_cancel_button",
  SIRE_EDIT_FORM_ADD_UPDATE_BUTTON: "sire_edit_form_add_update_button",
  SIRE_ACTION_MODAL_CANCEL_BUTTON: "sire_action_modal_cancel_button",
  SIRE_ACTION_MODAL_CONFIRM_BUTTON: "sire_action_modal_confirm_button",
  CREATE_SIRE_PLUS_BUTTON: "create_sire_plus_button",
  EDIT_SUPPLIER_FORM_CANCEL_BUTTON: "edit_supplier_from_cancel_button",
  EDIT_SUPPLIER_FORM_SAVE_BUTTON: "edit_supplier_from_save_button",
  SUSPEND_INVITE_USER_BUTTON: "suspend_invite_user_button",
  DELETE_INVITED_USER_BUTTON: "delete_invited_user",
  ASSIGN_OWNERSHIP_MODAL_CANCEL_BUTTON: "assign_ownership_modal_cancel_button",
  ASSIGN_OWNERSHIP_MODAL_CONFIRM_BUTTON: "assign_ownership_modal_confirm_button",
  ASSIGN_OWNER_TO_ANOTHER_USER: "assign_owner_to_another_user",
  MODIFY_PROFILE_FORM_CANCEL_BUTTON: "modify_profile_form_cancel_button",
  MODIFY_PROFILE_FORM_SAVE_BUTTON: "modify_profile_form_save_button",
  MODIFY_PROFILE_FORM_PROFILE_IMAGE_INPUT: "modify_profile_form_profile_image_input",
  CLOSE_RFL_MODAL_CANCEL_BUTTON: "close_rfl_modal_cancel_button",
  CLOSE_RFL_MODAL_CLOSE_RFL_BUTTON: "close_rfl_modal_close_rfl_button",
  DISCOUNT_GRID_UPLOAD_NEW_GRID_LINK: "discount_grid_upload_new_grid_link",
  CREATE_EDIT_RFL_MANAGE_TEMPLATE_LINK: "create_edit_rfl_manage_template_link",
  SHARING_USERS_SEARCH: "sharing_users_search",
  SHARE_RFL_WITH_MY_PREFERRED_SUPPLIERS: "share_rfl_with_my_preferred_suppliers",
  FARMER_OFFER_TABLE_SEARCH_INPUT: "farmer_offer_table_search_input",
  FARMER_OFFER_STATUS_BUTTON: "farmer_offer_status_button",
  MAKE_OFFER_BUTTON: "make_offer_button",
  BACK_TO_MY_TRADES_BUTTON: "back_to_my_trades_button",
  CANCEL_OFFER_ANIMALS_BUTTON: "cancel_offer_animals_button",
  OFFER_ANIMALS_STATUS_BUTTON: "offer_animals_status_button",
  OFFER_ANIMALS_CONFIRM_MOVEMENT_BUTTON: "offer_animals_confirm_movement_button",
  OFFER_ANIMALS_SEARCH_INPUT: "offer_animals_search_input",
  OFFER_ANIMALS_CLOSE_GROUP_LINK: "offer_animals_close_group_link",
  OFFER_ANIMALS_ADD_SELECTED_ANIMALS: "offer_animals_add_selected_animals",
  BUYING_TRADES_ADD_PLUS_BUTTON: "buying_trades_add_plus_button",
  PROCESSOR_TRADES_ADD_PLUS_BUTTON: "processor_trades_add_plus_button",
  VIEW_FARMER_RFL_VIEW_GRID_BUTTON: "view_farmer_rfl_view_grid_button",
  VIEW_FARMER_RFL_OFFER_LATER_BUTTON: "view_farmer_rfl_offer_later_button",
  VIEW_FARMER_RFL_OFFER_NOW_BUTTON: "view_farmer_rfl_offer_now_button",
  FARMER_BUYER_RFL_OFFER_SELECT_ANIMALS_TO_ACCEPT: "farmer_buyer_rfl_offer_select_animals_to_accept",
  FARMER_BUYER_RFL_OFFER_CONFIRM_LIVESTOCK_RECEIVED: "farmer_buyer_rfl_offer_confirm_livestock_received",
  FARMER_BUYER_RFL_OFFER_REJECT_OFFER: "farmer_buyer_rfl_offer_reject_offer",
  RFL_PAYMENT_UPDATE_BUTTON: "rfl_payment_update_button",
  RFL_PAYMENT_MARK_AS_PAID_CONFIRM_BUTTON: "rfl_payment_mark_as_paid_confirm_button",
  RFL_PAYMENT_EDIT_LINK: "rfl_payment_edit_link",
  PURCHASES_STATUS_BUTTON: "purchases_status_button",
  PURCHASES_STATUS_VIEW_KILL_SHEET_BUTTON: "purchases_status_view_kill_sheet_button",
  PURCHASES_STATUS_CLOSE_BUTTON: "purchases_status_close_button",
  VIEW_OFFERS_LIST_VIEW_ANIMALS_BUTTON: "view_offers_list_view_animals_button",
  PASS_AND_ARCHIVE_BUTTON: "pass_and_archive_button",
};
