import { gql } from "@apollo/client";
import { integrationSystemFragment } from "api/Settings/fragment";
import { taskDetailsFragment } from "./fragment";

export const GET_SUPPORTED_SYSTEMS = gql`
  query GetSupportedSystems($farm: Int!, $systemType: String, $isAutomatedSystem: Boolean) {
    integrationSystems(farm: $farm, systemType: $systemType, isAutomatedSystem: $isAutomatedSystem) {
      ...integrationSystemFragment
    }
  }
  ${integrationSystemFragment}
`;

export const GET_TASK_STATUS = gql`
  query GetTaskInfo($taskId: Int!) {
    farmSystemTask(taskId: $taskId) {
      ...taskDetailsFragment
    }
  }
  ${taskDetailsFragment}
`;
