import { useEffect, useState, VFC } from "react";
import { useLocation } from "react-router-dom";
// Utils
import { SIGN_UP, SIGN_UP_DETAILS, SIGN_UP_SUCCESS, SIGN_UP_FAILURE } from "constants/Routes";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fathom: any;
  }
}

const REGISTRATION_ROUTES = [SIGN_UP, SIGN_UP_DETAILS, SIGN_UP_SUCCESS, SIGN_UP_FAILURE];

const FathomWatcher: VFC = () => {
  const location = useLocation();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const siteId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

    if (siteId) {
      const script = document.createElement("script");

      script.src = "https://cdn.usefathom.com/script.js";
      script.defer = true;
      script.setAttribute("site", siteId);
      script.setAttribute("auto", "false");

      document.body.appendChild(script);

      script.onload = (): void => {
        setLoaded(true);
      };

      return (): void => {
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    const isRegistrationPage = REGISTRATION_ROUTES.includes(location.pathname);

    if (isRegistrationPage && window.fathom && loaded) {
      window.fathom.trackPageview();
    }
  }, [location, loaded]);

  return null;
};

export default FathomWatcher;
