import { gql } from "@apollo/client";
import { errorFragment } from "api/User/fragment";

export const CREATE_PREFERRED_SUPPLIERS = gql`
  mutation CreatePreferredSuppliers($input: CreatePreferredSuppliersInput!) {
    createPreferredSuppliers(input: $input) {
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_PREFERRED_BUSINESS_UNIT_STATUS = gql`
  mutation UpdatePreferredBusinessUnitStatus($input: UpdatePreferredBusinessUnitStatusInput!) {
    updatePreferredBusinessUnitStatus(input: $input) {
      preferredBusinessUnit {
        id
        supplier {
          id
        }
        commonStatus
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_PREFERRED_BUSINESS_UNIT = gql`
  mutation UpdatePreferredBusinessUnit($input: UpdatePreferredBusinessUnitInput!) {
    updatePreferredBusinessUnit(input: $input) {
      preferredBusinessUnit {
        id
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_BUSINESS_UNIT_SETTINGS = gql`
  mutation UpdateBusinessUnitSettings($input: UpdateBusinessUnitSettingsInput!) {
    updateBusinessUnitSettings(input: $input) {
      enableBcmsPushTransactions
      killOut
      tbStatus
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const TERMS_AND_CONDITIONS_ACCEPT = gql`
  mutation TermsAndConditionsAccept($input: TermsAndConditionsAcceptInput!) {
    termsAndConditionsAccept(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
