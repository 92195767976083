import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesMedicineCabinet: FC = () => {
  return (
    <EmptyStates
      animation={"medicine"}
      title="Your medicine cabinet is empty."
      // buttonCaption="View the guide"
    >
      <div>
        <p>Visit the mobile app to create your digital medicine cabinet.</p>
        <ul>
          <li>Scan the bottle barcode to quickly add your medicines.</li>
          <li>Log treatments against animals from your cabinet.</li>
          <li>Automatically update records, cabinet levels and Farm Assurance reports.</li>
        </ul>
      </div>
    </EmptyStates>
  );
};
