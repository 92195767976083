import { useGetCurrentBusinessUnit } from "./useGetCurrentBusinessUnit";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";

// Currently hubspot is setup with 2 completely different help centers for US and UK.
// This means articles can be labeled differently in each help center, which could cause issues....
export const useHelp = () => {
  const { countryCode } = useGetCurrentBusinessUnit();
  const isUS = countryCode.toUpperCase() === CountryISOCode.US;
  let baseURL = "https://cs.breedr.co/uk-help-centre";
  if (isUS) {
    baseURL = "https://cs.breedr.co/us_help_center";
  }
  const getHelpLink = (articlePath: string) => {
    return `${baseURL}/${articlePath}`;
  };
  return {
    baseURL,
    getHelpLink,
  };
};
