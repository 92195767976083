import { gql } from "@apollo/client";
import { errorFragment } from "trading/api/fragment";

export const ADD_DELIVERY_ADDRESS = gql`
  mutation addDeliveryAddress($input: AddDeliveryAddressInput!) {
    addDeliveryAddress(input: $input) {
      deliveryAddress {
        id
        unitName
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const EDIT_DELIVERY_ADDRESS = gql`
  mutation editDeliveryAddress($input: EditDeliveryAddressInput!) {
    editDeliveryAddress(input: $input) {
      deliveryAddress {
        id
        unitName
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const DELETE_DELIVERY_ADDRESS = gql`
  mutation deleteDeliveryAddress($input: DeleteDeliveryAddressInput!) {
    deleteDeliveryAddress(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPLOAD_DISCOUNT_GRID = gql`
  mutation UploadDiscountGrid($input: UploadDiscountGridInput!) {
    uploadDiscountGrid(input: $input) {
      discountGrid {
        id
        name
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const CREATE_DISTRIBUTION_LIST = gql`
  mutation CreateDistributionList($input: CreateDistributionListInput!) {
    createDistributionList(input: $input) {
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const DELETE_DISTRIBUTION_LIST = gql`
  mutation DeleteDistributionList($input: DeleteDistributionListInput!) {
    deleteDistributionList(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_DISTRIBUTION_LIST = gql`
  mutation UpdateDistributionList($input: UpdateDistributionListInput!) {
    updateDistributionList(input: $input) {
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const MARK_OFFERS_AS_READ = gql`
  mutation MarkOffersAsRead($input: OfferMarkAsReadInput!) {
    offerMarkAsRead(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
