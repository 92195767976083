import { gql } from "@apollo/client";
import { errorFragment, userInfoFragment, businessUnitUser } from "api/User/fragment";

export const UPDATE_EMAIL = gql`
  mutation updateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_USER_DATA = gql`
  mutation UpdateUserData($input: UpdateUserInput!) {
    updateUserInfo(input: $input) {
      user {
        ...UserInfoFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${userInfoFragment}
`;

export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      businessUnitUser {
        ...businessUnitUser
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${businessUnitUser}
`;

export const TRANSFER_USER_OWNERSHIP = gql`
  mutation TransferUserOwnership($input: TransferOwnershipInput!) {
    transferOwnership(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const SENT_RESET_PASSWORD_EMAIL = gql`
  mutation SentResetPasswordEmail($input: SendResetPasswordEmailInput!) {
    sendResetPasswordEmail(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const COMPLETE_INVITE = gql`
  mutation CompleteInvite($input: CompleteInvitationInput!) {
    completeInvite(input: $input) {
      token
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const RESENT_INVITE = gql`
  mutation ResentInvite($input: ReInviteUserInput!) {
    reInviteUser(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const DELETE_USER_FROM_FARM = gql`
  mutation DeleteUserFromFarm($input: DeleteUserFromFarmInput!) {
    deleteUserFromFarm(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_BUSINESS_UNIT_USER = gql`
  mutation UpdateBusinessUnitUser($input: UpdateBusinessUnitUserInput!) {
    updateBusinessUnitUser(input: $input) {
      businessUnitUser {
        ...businessUnitUser
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${businessUnitUser}
`;
