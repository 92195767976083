import { getFieldName } from "helpers/myLivestock";
import { ANIMAL_FILTER_VALUES } from "constants/Animals";
import { GroupType } from "hooks/useGetGroups";
import { AnimalSexClassifications_sexClassifications } from "api/MyLivestock/Animal/types/AnimalSexClassifications";
import { GetFormatDate } from "hooks/useFormatDate";
import { AnimalBreeds } from "hooks/useGetBreeds";
import { notNothing } from "helpers/general";
import { Fields } from "hooks/useGetFields";
import { Species } from "state/speciesSlice";

export const getFilterLabel = {
  animalTypeId: (value?: string, speciesList?: Species[]): string => {
    if (value && speciesList) {
      const speciesName = speciesList.find((species) => species.id === value)?.name;

      if (speciesName) return `Species: ${speciesName}`;
    }

    return "";
  },
  breeds: (value?: string[], breeds?: AnimalBreeds): string => {
    if (value?.length && breeds) {
      const filteredBreeds = breeds.filter(notNothing).filter((item) => value.includes(item.id));
      const breedsNames = filteredBreeds.map((item) => `${item.regName || ""} (${item.regBreedCode || ""})`);

      return `Breeds: ${breedsNames.join(", ")}`;
    }

    return "";
  },
  sex: (value?: string): string => {
    if (value) {
      return `Sex: ${value === ANIMAL_FILTER_VALUES.SEX.F ? "Female" : "Male"}`;
    }

    return "";
  },
  sexClassification: (value?: string[], sexClasses?: AnimalSexClassifications_sexClassifications[]): string => {
    if (value?.length && sexClasses?.length) {
      const sexClassNames = sexClasses
        .filter((item) => value.includes(item.slug))
        .map((item) => item.title)
        .join(", ");

      return `Sex classification: ${sexClassNames}`;
    }

    return "";
  },
  age: (from?: string, to?: string): string => {
    if (!from && to) {
      return `Age range: up to ${to} (months)`;
    }

    if (from && !to) {
      return `Age range: ${from} (months) or more`;
    }

    if (from && to) {
      return `Age range: ${from} to ${to} (months)`;
    }

    return "";
  },
  deliveryAge: (from?: string, to?: string): string => {
    if (!from && to) {
      return `Age at delivery range: up to ${to} (months)`;
    }

    if (from && !to) {
      return `Age at delivery range: ${from} (months) or more`;
    }

    if (from && to) {
      return `Age at delivery range: ${from} to ${to} (months)`;
    }

    return "";
  },
  currentEstWeight: (weightUnits: string, from?: string, to?: string): string => {
    if (!from && to) {
      return `Current weight range: up to ${to} (${weightUnits}s)`;
    }

    if (from && !to) {
      return `Current weight range: ${from} (${weightUnits}s) or more`;
    }

    if (from && to) {
      return `Current weight range: ${from} to ${to} (${weightUnits}s)`;
    }

    return "";
  },
  weight: (weightUnits: string, from?: string, to?: string): string => {
    if (!from && to) {
      return `Weight range: up to ${to} (${weightUnits}s)`;
    }

    if (from && !to) {
      return `Weight range: ${from} (${weightUnits}s) or more`;
    }

    if (from && to) {
      return `Weight range: ${from} to ${to} (${weightUnits}s)`;
    }

    return "";
  },
  daysSinceLastWeight: (from?: string, to?: string): string => {
    if (!from && to) {
      return `Days since last weighed range: up to ${to}`;
    }

    if (from && !to) {
      return `Days since last weighed range: ${from} or more`;
    }

    if (from && to) {
      return `Days since last weighed range: ${from} to ${to}`;
    }

    return "";
  },
  growthRate: (weightUnits: string, from?: string, to?: string): string => {
    if (!from && to) {
      return `Growth rate range: up to ${to} (${weightUnits}/day)`;
    }

    if (from && !to) {
      return `Growth rate range: ${from} (${weightUnits}/day) or more`;
    }

    if (from && to) {
      return `Growth rate range: ${from} to ${to} (${weightUnits}/day)`;
    }

    return "";
  },
  deliveryWeight: (weightUnits: string, from?: string, to?: string): string => {
    if (!from && to) {
      return `Weight at delivery range: up to ${to} (${weightUnits}s)`;
    }

    if (from && !to) {
      return `Weight at delivery range: ${from} (${weightUnits}s) or more`;
    }

    if (from && to) {
      return `Weight at delivery range: ${from} to ${to} (${weightUnits}s)`;
    }

    return "";
  },
  deliveryDate: (getFormatDate: GetFormatDate, from?: string | Date, to?: string | Date): string => {
    const fromValue = getFormatDate(from);
    const toValue = getFormatDate(to);

    if (!from && to) {
      return `Delivery date: ${toValue} and before`;
    }

    if (from && !to) {
      return `Delivery date: ${fromValue} and after`;
    }

    if (from && to) {
      return `Delivery date: ${fromValue} to ${toValue}`;
    }

    return "";
  },
  dob: (getFormatDate: GetFormatDate, from?: string | Date, to?: string | Date): string => {
    const fromValue = getFormatDate(from);
    const toValue = getFormatDate(to);

    if (!from && to) {
      return `Dob: ${toValue} and before`;
    }

    if (from && !to) {
      return `Dob: ${fromValue} and after`;
    }

    if (from && to) {
      return `Dob: ${fromValue} to ${toValue}`;
    }

    return "";
  },
  dateMovedToFarm: (getFormatDate: GetFormatDate, from?: string | Date, to?: string | Date): string => {
    const fromValue = getFormatDate(from);
    const toValue = getFormatDate(to);

    if (!from && to) {
      return `Date on farm: ${toValue} and before`;
    }

    if (from && !to) {
      return `Date on farm: ${fromValue} and after`;
    }

    if (from && to) {
      return `Date on farm: ${fromValue} to ${toValue}`;
    }

    return "";
  },
  groups: (value?: string[], groups?: GroupType[]): string => {
    if (value?.length && groups) {
      const filteredGroups = groups.filter((item) => value.includes(item.id));
      const groupNames = filteredGroups.map((item) => item.name);

      return `Groups: ${groupNames.join(", ")}`;
    }

    return "";
  },
  fields: (value?: string[], fields?: Fields): string => {
    if (value?.length && fields) {
      const filteredFields = fields.filter((item) => value.includes(item.id));
      const fieldNames = filteredFields.map((item) =>
        getFieldName({ fieldName: item.name, unitName: item.location?.unitName }),
      );

      return `Fields: ${fieldNames.join(", ")}`;
    }

    return "";
  },
  lastSyncedDate: (getFormatDate: GetFormatDate, from?: string | Date, to?: string | Date): string => {
    const fromValue = getFormatDate(from);
    const toValue = getFormatDate(to);

    if (!from && to) {
      return `Last synced date: ${toValue} and before`;
    }

    if (from && !to) {
      return `Last synced date: ${fromValue} and after`;
    }

    if (from && to) {
      return `Last synced date: ${fromValue} to ${toValue}`;
    }

    return "";
  },
  isRegulatorySynced: (value?: string): string => {
    if (value) {
      return `Is Synced: ${value === ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.SYNCED ? "Synced" : "Not synced"}`;
    }

    return "";
  },
  isWithdrawal: (value?: string): string => {
    if (value) {
      return `In Withdrawal: ${value === ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.DRAWAL ? "Yes" : "No"}`;
    }

    return "";
  },
  killWeight: (weightUnits: string, from?: string, to?: string): string => {
    if (!from && to) {
      return `Kill weight range: up to ${to} (${weightUnits}s)`;
    }

    if (from && !to) {
      return `Kill weight range: ${from} (${weightUnits}s) or more`;
    }

    if (from && to) {
      return `Kill weight range: ${from} to ${to} (${weightUnits}s)`;
    }

    return "";
  },
  killQuality: (value?: string): string => {
    return value ? `Kill quality: ${value}` : "";
  },
  killFatScore: (value?: string): string => {
    return value ? `Kill fat score: ${value}` : "";
  },
  dateLeftFarm: (getFormatDate: GetFormatDate, from?: string | Date, to?: string | Date): string => {
    const fromValue = getFormatDate(from);
    const toValue = getFormatDate(to);

    if (!from && to) {
      return `Date left farm: ${toValue} and before`;
    }

    if (from && !to) {
      return `Date left farm: ${fromValue} and after`;
    }

    if (from && to) {
      return `Date left farm: ${fromValue} to ${toValue}`;
    }

    return "";
  },
  deadAt: (getFormatDate: GetFormatDate, from?: string | Date, to?: string | Date): string => {
    const fromValue = getFormatDate(from);
    const toValue = getFormatDate(to);

    if (!from && to) {
      return `Dead at date: ${toValue} and before`;
    }

    if (from && !to) {
      return `Dead at date: ${fromValue} and after`;
    }

    if (from && to) {
      return `Dead at date: ${fromValue} to ${toValue}`;
    }

    return "";
  },
  pregnancyStatus: (status?: string): string => {
    if (status === ANIMAL_FILTER_VALUES.IS_PREGNANT.PREGNANT) return "Pregnant: Yes";
    if (status === ANIMAL_FILTER_VALUES.IS_PREGNANT.NOT_PREGNANT) return "Pregnant: No";

    return "";
  },
  pregnancyDueDate: (getFormatDate: GetFormatDate, from?: string | Date, to?: string | Date): string => {
    const fromValue = getFormatDate(from);
    const toValue = getFormatDate(to);

    if (!from && to) {
      return `Pregnancy due: ${toValue} and before`;
    }

    if (from && !to) {
      return `Pregnancy due: ${fromValue} and after`;
    }

    if (from && to) {
      return `Pregnancy due: ${fromValue} to ${toValue}`;
    }

    return "";
  },
};
