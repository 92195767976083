import { FC } from "react";
import { DateTime } from "luxon";
import { GroupQuery } from "generated/graphql";
import { getMonthsAge } from "helpers/myLivestock";
import { getFormattedNumber } from "helpers/general";
import { GROUP_CATEGORY_OPTIONS } from "constants/Groups";
import { useHasFeature, useBreakpoints, useGetCurrentBusinessUnit, useFormatDate } from "hooks";
import { Flex, KeyValuePair, Spacer, Text } from "components";
import { AnimalsBreedSummary } from "components/MyLivestock";
import { KeyValuePairProps } from "components/Common/KeyValuePair";
import styles from "./GroupInfoBlock.module.scss";
import { capitaliseFirstLetter, capitaliseFirstLetterOfEachWord } from "helpers/translations/src/format";
import { useLocale } from "helpers/translations/src";

interface GroupInfoBlockProps {
  group: NonNullable<GroupQuery["group"]>;
  children?: React.ReactNode;
}

interface GroupInfoItems extends KeyValuePairProps {
  isHidden?: boolean;
}

export const GroupInfoBlock: FC<GroupInfoBlockProps> = ({ children, group }) => {
  const {
    animalType,
    timeOnFarm,
    minAge,
    maxAge,
    weightInfo,
    targetWeight,
    category,
    minDaysOnFarm,
    maxDaysOnFarm,
    avgEstimatedWeight,
    avgRecordedWeight,
    avgDlwg,
    targetDate,
    daysTillTarget,
    summaryMetrics,
  } = {
    ...group,
  };
  const { weightUnits } = useGetCurrentBusinessUnit();
  const { getFormatDate } = useFormatDate();
  const { terms } = useLocale();

  const { isBreakpointL, isBreakpointXL } = useBreakpoints();
  const showGroupRedesign = useHasFeature("SHOW_GROUP_REDESIGN");

  const categoryLabel = GROUP_CATEGORY_OPTIONS.find((item) => item?.value === category)?.label;

  if (showGroupRedesign && group) {
    const groupInfo: GroupInfoItems[] = [
      {
        label: "Type",
        value: categoryLabel,
      },
      {
        label: "Weight range",
        unit: weightUnits,
        value:
          weightInfo?.minWeight && weightInfo?.maxWeight
            ? `${getFormattedNumber(weightInfo.minWeight)} - ${getFormattedNumber(weightInfo.maxWeight)}`
            : null,
      },
      {
        label: "Age range",
        unit: "months",
        value: maxAge && minAge ? `${getMonthsAge(maxAge)} - ${getMonthsAge(minAge)}` : null,
      },
      {
        label: `Min days on ${terms.farm}`,
        unit: "days",
        value: minDaysOnFarm ? getFormattedNumber(minDaysOnFarm) : null,
      },
      {
        label: `Max days on ${terms.farm}`,
        unit: "days",
        value: maxDaysOnFarm ? getFormattedNumber(maxDaysOnFarm) : null,
      },
      {
        label: capitaliseFirstLetterOfEachWord(terms.avgDlwg),
        unit: weightUnits,
        value: avgDlwg ? getFormattedNumber(avgDlwg) : null,
      },
      {
        label: `Average time on ${terms.farm}`,
        unit: "days",
        value: timeOnFarm
          ? getFormattedNumber(Math.trunc(DateTime.local().diff(DateTime.fromISO(timeOnFarm), ["days"]).days))
          : null,
      },
      {
        label: "Average estimated weight",
        unit: weightUnits,
        value: avgEstimatedWeight ? getFormattedNumber(avgEstimatedWeight) : null,
      },
      {
        label: "Average recorded weight",
        unit: weightUnits,
        value: avgRecordedWeight ? getFormattedNumber(avgRecordedWeight) : null,
      },
      {
        label: "Target weight",
        unit: weightUnits,
        value: targetWeight ? getFormattedNumber(targetWeight) : null,
      },
      {
        label: "Target date",
        value: targetDate ? getFormatDate(targetDate) : null,
      },
      {
        label: "Days till target date",
        unit: "days",
        value: daysTillTarget ? getFormattedNumber(daysTillTarget) : null,
      },
    ];

    return (
      <>
        {!!summaryMetrics?.length ? (
          <Flex container>
            {summaryMetrics.map((item, index) => (
              <Flex className={styles.group_info_block__item} item itemGutter key={index}>
                <AnimalsBreedSummary
                  breedCode={item?.breedrCode || "N/A"}
                  speciesColour={animalType?.colour}
                  speciesIconUrl={animalType?.image || undefined}
                  totalAnimals={item?.animalsCount || 0}
                  totalFemale={item?.femaleCount || 0}
                  totalMale={item?.maleCount || 0}
                />
              </Flex>
            ))}
          </Flex>
        ) : null}
        <Flex container>
          {groupInfo.map(
            ({ isHidden, ...item }) =>
              !isHidden && (
                <Flex
                  className={styles.group_info_block__item}
                  item
                  itemGutter
                  key={item.label}
                  xs={6}
                  s={4}
                  l={3}
                  xl={2}
                >
                  <KeyValuePair {...item} boxed />
                </Flex>
              ),
          )}
        </Flex>
        <Flex container containerDirection="row-reverse">
          <Flex item itemGutter>
            {children}
          </Flex>
        </Flex>
      </>
    );
  }

  return (
    <Flex container>
      <Flex item itemGutter xs={12} l={4} xl={3}>
        <Text smaller>
          Type: <strong>{categoryLabel ? categoryLabel : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Weight range:{" "}
          <strong>
            {weightInfo?.minWeight ? +weightInfo?.minWeight.toFixed(2) : null} -{" "}
            {weightInfo?.maxWeight ? +weightInfo?.maxWeight.toFixed(2) : null} {weightUnits}s
          </strong>
        </Text>

        <Spacer />

        <Text smaller>
          Age range:{" "}
          <strong>
            {maxAge ? getMonthsAge(maxAge) : 0} - {minAge ? getMonthsAge(minAge) : 0} months
          </strong>
        </Text>

        <Spacer />

        <Text smaller>
          Min days on {terms.farm}: <strong>{minDaysOnFarm ? minDaysOnFarm : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Max days on {terms.farm}: <strong>{maxDaysOnFarm ? maxDaysOnFarm : "\u2014"}</strong>
        </Text>
      </Flex>

      {!isBreakpointL ? <Spacer /> : null}

      <Flex item itemGutter xs={12} l={4} xl={3}>
        <Text smaller>
          {capitaliseFirstLetter(terms.avgDlwg)}: <strong>{avgDlwg ? +avgDlwg : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Average time on {terms.farm}:{" "}
          <strong>
            {timeOnFarm
              ? `${Math.trunc(DateTime.local().diff(DateTime.fromISO(timeOnFarm), ["days"]).days)} Days`
              : "\u2014"}
          </strong>
        </Text>

        <Spacer />

        <Text smaller>
          Average estimated weight: <strong>{avgEstimatedWeight ? +avgEstimatedWeight : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Average recorded weight: <strong>{avgRecordedWeight ? +avgRecordedWeight : "\u2014"}</strong>
        </Text>
      </Flex>

      {!isBreakpointL ? <Spacer /> : null}

      <Flex item itemGutter xs={12} l={4} xl={3}>
        <Text smaller>
          Target weight:{" "}
          <strong>{targetWeight ? `${targetWeight ? targetWeight : 0} ${weightUnits}s` : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Target date: <strong>{getFormatDate(targetDate)}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Days till target date: <strong>{daysTillTarget ? daysTillTarget : "\u2014"}</strong>
        </Text>
      </Flex>

      {children ? (
        <>
          {!isBreakpointXL ? <Spacer baselineHeight={2} /> : null}

          <Flex
            className={styles.group_info_block__buttons}
            container
            containerAlignItems="flex-end"
            containerJustifyContent="flex-end"
            item
            itemGutter
            xs={12}
            xl={3}
          >
            {children}
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};
