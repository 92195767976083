import React from "react";

import pure from "recompose/pure";

import TableBody from "@material-ui/core/TableBody";

import styles from "./styles.module.css";

export interface TableBodyProps {
  children?: React.ReactNode;
}

const CustomTableBody: React.FC<TableBodyProps> = ({ children, ...props }) => (
  <TableBody className={styles.body} {...props}>
    {children}
  </TableBody>
);

export default pure(CustomTableBody);
