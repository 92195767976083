import { ChangeEvent, useMemo, VFC } from "react";
// Libraries
import { Column } from "react-table";
// Utils
import { TABLE_IDS } from "constants/Interface";
// Types
import { InputSelectOptionWithTitles } from "components/Common/Field/Select";
import { Hacky_UploadCsv_uploadCsv } from "../";
// Components
import { InputSelect, Table, Text } from "components";

interface ImportWizardColumnsTableProps {
  columnFieldMap: string[];
  fieldOptions: InputSelectOptionWithTitles[];
  file: Hacky_UploadCsv_uploadCsv;
  isFirstRowColumnNames: boolean;
  onChangeColumnField: (columnIndex: number, fieldId: string) => void;
}

export const ImportWizardColumnsTable: VFC<ImportWizardColumnsTableProps> = ({
  columnFieldMap,
  fieldOptions,
  file,
  isFirstRowColumnNames,
  onChangeColumnField,
}) => {
  const data = useMemo(() => {
    // we only want to show the first 2 rows as the primary purpose of this table is to match columns to backend fields
    const fromIndexInclusive = isFirstRowColumnNames ? 1 : 0;
    const toIndexExclusive = isFirstRowColumnNames ? 3 : 2;

    return file.rows.slice(fromIndexInclusive, toIndexExclusive);
  }, [file, isFirstRowColumnNames]);

  const columns: Column[] = useMemo(
    () =>
      file.rows[0].map((columnName, columnIndex) => {
        const handleChangeField = (event: ChangeEvent<HTMLSelectElement>) =>
          onChangeColumnField(columnIndex, event.target.value);

        const subheaderProperties = isFirstRowColumnNames
          ? {
              columns: [
                {
                  accessor: `${columnIndex}`,
                  disableSortBy: true,
                  Header: columnName,
                  minWidth: 200,
                  width: 200,
                },
              ],
            }
          : {};

        return {
          accessor: `${columnIndex}`,
          disableSortBy: true,
          Header: (
            <InputSelect
              onChange={handleChangeField}
              options={fieldOptions}
              valueKey="field"
              value={columnFieldMap[columnIndex]}
            />
          ),
          id: `column${columnIndex}`,
          ...subheaderProperties,
        };
      }),
    [columnFieldMap, fieldOptions, file, isFirstRowColumnNames, onChangeColumnField],
  );

  if (data.length === 0)
    return (
      <Text smaller warning>
        File has no records to import
      </Text>
    );

  return <Table tableId={TABLE_IDS.CUSTOM_IMPORT_COLUMN_MATCH} columns={columns} data={data} />;
};
