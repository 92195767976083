import { useContext, useEffect, useState, FC } from "react";
// Libraries
import { isEqual } from "lodash";
// Api
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { MyLot_myLot_animals } from "trading/api/Liveweight/types/MyLot";
// Utils
import useUpdateEffect from "hooks/lifecycle/useUpdateEffect";
import { CommonContext } from "config/commonProvider";
import { TABLE_IDS } from "constants/Interface";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { useAnimals, usePrevious, useStateSpecies } from "hooks";
// Components
import {
  Spacer,
  Flex,
  Title,
  IconButton,
  Button,
  TablePaginationTarget,
  Text,
  TextButton,
  EmptyState,
} from "components";
import { AnimalsTable, AnimalFiltersBlock } from "components/MyLivestock";
// Resources
import tablePaginationStyles from "components/Common/Table/TablePagination/TablePagination.module.scss";

type AnimalTypes = GetAnimalsList_animalsExtended_animals | MyLot_myLot_animals;

interface AnimalsInLotProps {
  location?: string;
  lotId?: number | null | undefined;
  onLotAnimalsAdd: (animals: AnimalTypes[]) => void;
  lotAnimals: GetAnimalsList_animalsExtended_animals[];
  customAdditionOfAnimals: GetAnimalsList_animalsExtended_animals[];
}

export const AnimalsInLot: FC<AnimalsInLotProps> = ({
  location,
  lotAnimals,
  lotId,
  onLotAnimalsAdd,
  customAdditionOfAnimals,
}) => {
  const { resetAnimalsFilter } = useContext(CommonContext);

  const { activeSpecies } = useStateSpecies();

  const [stagedTableAnimals, setStagedTableAnimals] = useState(lotAnimals);
  useEffect(() => setStagedTableAnimals(lotAnimals), [lotAnimals]);
  const [stagedTableSelectedRowIds, setStagedTableSelectedRowIds] = useState<Set<string>>(new Set([]));

  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);
  const animalsTableOptions = getTableOptions<AnimalTypes>(TABLE_IDS.MARKETPLACE_ANIMALS);
  const animalsTableSelectedAnimalIds = animalsTableOptions?.selectedRows?.map((animal) => animal.id) || [];
  const stagedTableOptions = getTableOptions<AnimalTypes>(TABLE_IDS.MARKETPLACE_ANIMALS_TO_COMMIT);
  const stagedTableSelectedAnimalIds = stagedTableOptions?.selectedRows?.map((animal) => animal.id) || [];

  const [isEdited, setIsEdited] = useState<boolean>(false);

  const { data: allAnimals = [] } = useAnimals(null);
  const lotAnimalsIds: string[] = lotAnimals.map((item) => item.id);

  const filteredAnimals =
    allAnimals.filter((animal) => {
      const isAnimalCommittedToAnyRFL = !!animal?.lot;
      const isAnimalCommittedToThisLot = lotAnimalsIds.includes(animal?.id);
      const isAnimalAtSelectedLocation = location && animal.location?.id === location;
      const isAnimalCurrentActiveSpecies = animal.animalType?.id === activeSpecies?.id;

      return (
        !isAnimalCommittedToAnyRFL &&
        !isAnimalCommittedToThisLot &&
        isAnimalAtSelectedLocation &&
        isAnimalCurrentActiveSpecies
      );
    }) || [];
  const previousFilteredAnimals = usePrevious(filteredAnimals);

  const [animalsTableAnimals, setAnimalsTableAnimals] = useState(filteredAnimals);
  useEffect(() => {
    if (!isEqual(previousFilteredAnimals, filteredAnimals)) {
      setAnimalsTableAnimals(filteredAnimals);
    }
  }, [previousFilteredAnimals, filteredAnimals]);

  const handleAddButtonClick = (): void => {
    setIsEdited(true);
  };

  const handleEditClick = (): void => {
    setIsEdited(true);

    setStagedTableAnimals([...stagedTableAnimals]);

    const rowIdsToSelect = new Set<string>();

    lotAnimals.forEach((animal) => {
      rowIdsToSelect.add(animal.id);
    });

    setStagedTableSelectedRowIds(rowIdsToSelect);
  };

  const handleCancelClick = (): void => {
    setIsEdited(false);
    resetAnimalsFilter();
  };

  const handleUpdateClick = (): void => {
    const updatedAnimals = lotAnimals.filter((item) => stagedTableSelectedAnimalIds.includes(item.id));
    setIsEdited(false);
    onLotAnimalsAdd(updatedAnimals);
  };

  const handleAnimalsAddToSelected = (): void => {
    const animalsToMove = filteredAnimals.filter((item) =>
      animalsTableSelectedAnimalIds.includes(item?.id),
    ) as GetAnimalsList_animalsExtended_animals[];
    const animalsToAdd = lotAnimals.concat(animalsToMove);

    onLotAnimalsAdd(animalsToAdd);
  };

  const selectedCountLabel = `Selected: ${animalsTableSelectedAnimalIds.length}`;
  const isEmpty: boolean = !lotAnimals.length && !isEdited;
  const fromPage: string = lotId ? `listing-${lotId}` : "create-listing";

  useEffect(() => {
    if (customAdditionOfAnimals) {
      onLotAnimalsAdd(customAdditionOfAnimals);
    }
  }, [customAdditionOfAnimals]);

  /*
   * The Table component wasn't designed to change the data it is given on demand, so the selectedRows/selectedRowsIds
   * in context don't update as expected - so we force it to do it here instead.
   *
   * The eventual fix here is to remove the green "View selected rows" button functionality from Table and implement it
   * properly
   */
  useEffect(() => {
    setTableOptions({
      id: TABLE_IDS.MARKETPLACE_ANIMALS,
      selectedRows: [],
      selectedRowsIds: new Set(),
    });
  }, [location]);

  useUpdateEffect(() => {
    // Reset the animals added to the listing if the user changes active specie.
    onLotAnimalsAdd([]);
  }, [activeSpecies]);

  return (
    <>
      <Flex container>
        <Flex item itemGutter>
          <Title tertiary>Animals in the listing</Title>
        </Flex>
      </Flex>

      <Spacer baselineHeight={1} />

      {isEmpty ? (
        <Flex container item itemGrow>
          <Flex item itemGutter>
            {!!filteredAnimals.length ? (
              <Flex container containerDirection="column">
                {!location ? (
                  <Flex>
                    <Text warning>Please select a location before adding animals</Text>
                    <Spacer baselineHeight={1} />
                  </Flex>
                ) : null}
                <IconButton iconName="plus" onClick={handleAddButtonClick} disabled={!location} />
              </Flex>
            ) : (
              <EmptyState page="ANIMALS" />
            )}
          </Flex>
        </Flex>
      ) : null}

      {!isEmpty ? (
        <>
          <Flex container containerAlignItems="center" containerJustifyContent="flex-end">
            {isEdited ? (
              <>
                <Flex item itemGutter>
                  <TextButton caption="Cancel" onClick={handleCancelClick} />
                </Flex>
                <Flex item itemGutter>
                  <Button caption="Update" colour="yellow" variant="solid" onClick={handleUpdateClick} />
                </Flex>
              </>
            ) : (
              <Flex item itemGutter>
                <Button caption="Edit" colour="yellow" variant="solid" onClick={handleEditClick} />
              </Flex>
            )}
          </Flex>

          <Spacer baselineHeight={2} />

          <Flex
            container
            xs="fill"
            containerJustifyContent="flex-end"
            className={tablePaginationStyles.table_pagination_target}
          >
            <Flex item itemGutter>
              <TablePaginationTarget tableId={TABLE_IDS.MARKETPLACE_ANIMALS_TO_COMMIT} />
            </Flex>
          </Flex>

          <Spacer baselineHeight={1} />

          {/* Top Table with your chose selection */}
          <Flex container item>
            <Flex item itemGutter xs={12}>
              <AnimalsTable
                animals={stagedTableAnimals}
                fromPage={fromPage}
                selectedRowIds={stagedTableSelectedRowIds}
                showCheckboxes={isEdited}
                tableId={TABLE_IDS.MARKETPLACE_ANIMALS_TO_COMMIT}
                isShowQuickView
              />
            </Flex>
          </Flex>

          <Spacer baselineHeight={4} />

          {isEdited ? (
            <>
              <Spacer baselineHeight={3} border="top" gutter />

              <Flex container containerAlignItems="center" containerJustifyContent="flex-end">
                {!!filteredAnimals.length ? (
                  <Flex item itemGutter>
                    <Button
                      caption="Add selected"
                      colour="yellow"
                      variant="solid"
                      disabled={animalsTableSelectedAnimalIds.length === 0}
                      onClick={handleAnimalsAddToSelected}
                    />
                  </Flex>
                ) : null}
              </Flex>

              <Spacer baselineHeight={1} />

              <AnimalFiltersBlock
                excludedFields={{
                  animalTypeId: true,
                  ageAtDelivery: true,
                  weightAtDelivery: true,
                  isRegulatorySynced: true,
                }}
                selectedCountLabel={selectedCountLabel}
                isSavedFiltersAvailable={false}
                tableId={TABLE_IDS.MARKETPLACE_ANIMALS}
              />

              <Spacer baselineHeight={1} />

              {/* //Bottom Table (with all allowed animals possible) */}
              {!!filteredAnimals.length ? (
                <Flex container item itemGrow>
                  <Flex item itemGutter xs={12}>
                    <AnimalsTable
                      animals={animalsTableAnimals}
                      fromPage={fromPage}
                      showCheckboxes
                      tableId={TABLE_IDS.MARKETPLACE_ANIMALS}
                      isShowQuickView
                    />
                  </Flex>
                </Flex>
              ) : (
                <Flex item itemGutter xs={12}>
                  <Text>No matching livestock</Text>
                </Flex>
              )}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};
