import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import {
  selectedAnimalIdsSelector,
  toggleAllRowsSelection,
  toggleRowSelection,
  resetRowSelection,
} from "state/rowSelectionSlice";
import { ColumnDef, RowData } from "@tanstack/react-table";
import { Checkbox } from "twComponents/Checkbox";
import { TableId } from "constants/Interface";

export const useRowSelection = <TData extends RowData>({ tableId }: { tableId: TableId }) => {
  const dispatch = useDispatch();

  const toggleRow = (rowId: string) => {
    dispatch(toggleRowSelection({ tableId, rowId }));
  };

  const toggleAllRows = () => {
    dispatch(toggleAllRowsSelection({ tableId }));
  };

  const rowSelection = useSelector((state: RootState) => state.rowSelection)[tableId] || {};
  const selectedAnimalIds = useSelector((state: RootState) => selectedAnimalIdsSelector(state, tableId));
  const resetSelection = () => dispatch(resetRowSelection({ tableId }));

  const selectColumn: ColumnDef<TData> = {
    id: "select",
    size: 50,
    maxSize: 50,
    header: ({ table }) => (
      <Checkbox
        {...{
          id: "select-header-checkbox",
          name: "select-header-checkbox",
          checked: table.getIsAllRowsSelected(),
          onChange: toggleAllRows,
        }}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        {...{
          id: row.id,
          name: row.id,
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          onChange: () => toggleRow(row.id),
        }}
      />
    ),
  };

  return { resetSelection, selectColumn, toggleRow, toggleAllRows, rowSelection, selectedAnimalIds };
};
