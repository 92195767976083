import { ChangeEvent, ReactNode, VFC } from "react";
// Libraries
import cn from "classnames";
import { get } from "lodash";
import pure from "recompose/pure";
// Custom
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Field, Input } from "components";
import SelectPill from "./SelectPill";
// Resources
import styles from "./Select.module.scss";
import inputStyles from "../Input/Input.module.scss";
import { BaseInput } from "../BaseInput";

export interface InputSelectMultipleHTMLTarget {
  value: string[];
}

const Search = ({ onChange }: { onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }) => {
  return (
    <div>
      <BaseInput name="search" onChange={onChange} type="text" placeholder="Search" />
    </div>
  );
};

export interface InputSelectOption {
  key: string;
  value: string;
  label: string | ReactNode;
}
export type InputSelectOptionWithTitles = PartialBy<InputSelectOption, "value">;

interface InputSelectProps {
  components?: {
    BottomMenuItem?: VFC<{ inheritedStyles: string }>;
  };
  onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  hasError?: boolean;
  isClearable?: boolean;
  labelKey?: string;
  multiple?: boolean;
  name: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  options: InputSelectOption[];
  placeholder?: string;
  value: SelectProps["value"];
  valueKey?: string;
  placeholderColour?: "black" | "gray";
}

export const InputSelect = pure(
  ({
    name,
    value,
    placeholder,
    options,
    multiple,
    hasError,
    isClearable,
    labelKey,
    valueKey,
    disabled = false,
    components,
    placeholderColour,
    onSearch,
    ...props
  }: InputSelectProps) => {
    return (
      <Input hasError={hasError}>
        <Select
          value={value}
          displayEmpty={!!placeholder}
          disabled={disabled}
          renderValue={(selected): JSX.Element => {
            if (!selected || (Array.isArray(selected) && selected.length === 0)) {
              return (
                <span
                  className={`align-middle px-2 italic overflow-ellipsis whitespace-nowrap overflow-hidden text-xs ${
                    placeholderColour === "gray" ? "text-gray-400" : "text-black"
                  }`}
                >
                  {placeholder}
                </span>
              );
            }
            if (multiple && Array.isArray(selected)) {
              return (
                <span className={styles.breedr_select__value_pills}>
                  {selected.map((selectedValue) => {
                    const caption = get(
                      (options || []).find((o) => (valueKey ? o[valueKey] : o.value) === selectedValue),
                      labelKey || "label",
                      selectedValue,
                    );
                    const key = get(
                      (options || []).find((o) => (valueKey ? o[valueKey] : o.value) === selectedValue),
                      valueKey || "key",
                      selectedValue,
                    );
                    return <SelectPill caption={caption} key={key} />;
                  })}
                </span>
              );
            }
            const caption = get(
              (options || []).find((o) => (valueKey ? o[valueKey] : o.value) === selected),
              labelKey || "label",
              value,
            );
            // @ts-expect-error
            return <span className={styles.breedr_select__value_caption}>{caption}</span>;
          }}
          name={name}
          className={cn(styles.breedr_select, inputStyles.input__native, {
            [styles["breedr_select--disabled"]]: disabled,
          })}
          classes={{
            root: hasError ? styles["breedr_select__root--error"] : undefined,
            select: styles.breedr_select__value,
          }}
          inputProps={{
            name,
            id: name,
          }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            PopoverClasses: {
              paper: styles.popover,
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            MenuListProps: {
              className: styles.popover__list,
            },
          }}
          multiple={multiple}
          {...props}
        >
          {onSearch ? <Search onChange={onSearch} /> : null}
          {placeholder ? (
            <MenuItem
              {...(!isClearable
                ? {
                    disabled: true,
                  }
                : {})}
              selected
              value=""
              className={cn(styles.popover__list_item, styles["popover__list_item--placeholder"])}
            >
              {placeholder}
            </MenuItem>
          ) : null}
          {options
            ? options.map((option) => {
                const optionKey = valueKey ? option[valueKey] : option.key;
                const optionLabel = labelKey ? option[labelKey] : option.label;
                const optionValue = valueKey ? option[valueKey] : option.value;

                return optionValue ? (
                  <MenuItem
                    id={optionKey}
                    key={optionKey}
                    value={optionValue}
                    className={cn(styles.popover__list_item, {
                      [styles["popover__list_item--is_selected"]]: multiple
                        ? (value as Array<string | number | boolean | Record<string, unknown>>).find(
                            (v) => v === optionValue,
                          )
                        : value === optionValue,
                    })}
                  >
                    {optionLabel}
                  </MenuItem>
                ) : (
                  <MenuItem
                    className={cn(styles.popover__list_item, styles["popover__list_item--subtitle"])}
                    disabled
                    id={optionKey}
                    key={optionKey}
                    value=""
                  >
                    {optionLabel}
                  </MenuItem>
                );
              })
            : null}
          {components?.BottomMenuItem ? (
            <components.BottomMenuItem inheritedStyles={styles.popover__list_item} />
          ) : null}
        </Select>
      </Input>
    );
  },
);

export interface FieldSelectProps {
  error?: string;
  helper?: string;
  infoIcon?: () => void;
  inputProps: InputSelectProps;
  label: string;
  className?: string;
}

export const FieldSelect = pure(({ error, inputProps, ...props }: FieldSelectProps) => (
  <Field error={error} labelFor={inputProps.name} {...props}>
    <InputSelect hasError={!!error} id={inputProps.name} {...inputProps} />
  </Field>
));
