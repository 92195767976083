import * as React from "react";

import pure from "recompose/pure";

import { ELEMENTS_IDS } from "constants/ElementsIds";

import styles from "./styles.module.css";

interface Props {
  contentText: string;
  onClick?: () => void;
}

const Tooltip: React.FC<Props> = ({ contentText, onClick }) => (
  <div id={ELEMENTS_IDS.AUTH_TOOLTIP} className={styles.tooltip} onClick={onClick} role="tooltip">
    <div className={styles.tooltipContent}>
      <div className={styles.arrow} />
      {contentText}
    </div>
  </div>
);

export default pure(Tooltip);
