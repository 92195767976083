interface IntercomMessageParams {
  listingTitle: string | null | undefined;
  referenceCode: string | null | undefined;
  messageVariant: "seller" | "buyer";
}

export const getIntercomMessage = ({ listingTitle, referenceCode, messageVariant }: IntercomMessageParams): string => {
  return messageVariant === "buyer"
    ? getIntercomMessageForBuyer({ listingTitle, referenceCode })
    : getIntercomMessageForSeller({ listingTitle, referenceCode });
};

const getIntercomMessageForBuyer = ({
  listingTitle,
  referenceCode,
}: Omit<IntercomMessageParams, "messageVariant">): string => {
  if (listingTitle && referenceCode) {
    return `Hi, I want to know about liveweight trade listing '${listingTitle}' with reference '${referenceCode}'.`;
  }

  if (listingTitle && !referenceCode) {
    return `Hi, I want to know about liveweight trade listing '${listingTitle}'.`;
  }
  return "Hi, I want to know more about a liveweight trade listing.";
};

const getIntercomMessageForSeller = ({
  listingTitle,
  referenceCode,
}: Omit<IntercomMessageParams, "messageVariant">): string => {
  if (listingTitle && referenceCode) {
    return `Hi, could you give me an update about my liveweight trade listing '${listingTitle}' with reference '${referenceCode}'?`;
  }

  if (listingTitle && !referenceCode) {
    return `Hi, could you give me an update about my liveweight trade listing '${listingTitle}'?`;
  }
  return "Hi, could you give me an update about my liveweight trade listing?";
};
