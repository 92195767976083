import React from "react";
// Components
import { Button, Tooltip } from "components";
// Resources
import { ExportIcon } from "constants/SvgIcons";

export interface ExportAnimalsButtonProps {
  numAnimals: number;
  onClick: () => void;
  isLoading: boolean;
  isServerList?: boolean;
}

export const ExportAnimalsButton: React.FC<ExportAnimalsButtonProps> = ({
  numAnimals,
  onClick,
  isLoading,
  isServerList,
}) => {
  if (!numAnimals || isLoading) return <Button disabled icon={{ image: ExportIcon, position: "left" }} />;

  const tooltipTitle =
    numAnimals > 1 ? `Export these ${numAnimals} animals to csv` : `Export this ${numAnimals} animal to csv`;

  // We have to wrap button in div as material ui throws console error
  // regarding wrapping a tooltip around a (potentially) disabled button
  return (
    <Tooltip title={isServerList ? "Export selected animals to CSV" : tooltipTitle} placement="bottom">
      <div>
        <Button
          caption="Download"
          icon={{
            position: "right",
            props: {
              name: "download",
              size: "small",
            },
          }}
          onClick={onClick}
        />
      </div>
    </Tooltip>
  );
};
