import { VFC } from "react";
// Libraries
import cn from "classnames";
// Utils
import { Warning } from "constants/SvgIcons";
import { ANIMAL_FILTER_COLORS } from "constants/Animals";
// Components
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Icon } from "components";
// Resources
import styles from "./styles.module.scss";

export const TEST_IDS = {
  TEXT: "alert-text",
  ACTION_BUTTON: "action-button",
  CLOSE_BUTTON: "close-button",
};

interface AlertProps {
  buttonText?: string;
  categoryPillText?: string;
  color: keyof typeof ANIMAL_FILTER_COLORS;
  onButtonTextClick?: () => void;
  onClick?: () => void;
  onClose?: () => void;
  size?: "medium" | "large";
  text: string;
}

export const Alert: VFC<AlertProps> = ({
  buttonText,
  categoryPillText,
  color,
  onButtonTextClick,
  onClick,
  onClose,
  size = "large",
  text,
  ...rest
}) => {
  const action = onClose ? (
    <div
      key="close"
      aria-label="Close"
      data-testid={TEST_IDS.CLOSE_BUTTON}
      className={styles.closeIconWrapper}
      onClick={onClose}
    >
      <Icon name="times" size="tiny" />
    </div>
  ) : (
    []
  );

  return (
    <SnackbarContent
      className={cn(styles.alertWrapper, styles[color], styles[size], {
        [styles.pointer]: !!onClick,
      })}
      aria-describedby="client-snackbar"
      classes={{
        action: styles.actions,
        message: cn(styles.messageWrapper, { [styles.withCloseButtonMessage]: onClose }),
      }}
      {...(onClick ? { onClick, onKeyPress: onClick, tabIndex: 0 } : {})}
      message={
        <span id="client-snackbar">
          <div className={styles.textWrapper}>
            <Warning classes={{ root: styles.rootIcon }} />

            <p className={styles.text} data-testid={TEST_IDS.TEXT}>
              {text}
            </p>

            {buttonText ? (
              <button
                data-testid={TEST_IDS.ACTION_BUTTON}
                type="button"
                onClick={onButtonTextClick}
                className={styles.linkButton}
              >
                {buttonText}
              </button>
            ) : null}

            {categoryPillText ? <span className={styles.categoryPill}>{categoryPillText}</span> : null}
          </div>
        </span>
      }
      action={action}
      {...rest}
    />
  );
};
