// React
import React, { memo } from "react";

import { ELEMENTS_IDS } from "constants/ElementsIds";
// Components
import { Button, Flex } from "components";

interface LoadMoreButtonProps {
  loadMore: () => void;
  disabled?: boolean;
  requesting?: boolean;
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ loadMore, disabled, requesting }) => (
  <Flex container containerJustifyContent="center">
    <Flex item itemGutter>
      <Button
        id={ELEMENTS_IDS.LOAD_MORE_BUTTON}
        caption="Load more"
        onClick={loadMore}
        disabled={disabled || false}
        requesting={requesting || false}
      />
    </Flex>
  </Flex>
);

export default memo(LoadMoreButton);
