import React from "react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// Components
import { AnimalPregnancyLabel, AnimalsSexClassLabel } from "components/MyLivestock";
// Types
import { KeyValuePairProps } from "components/Common/KeyValuePair";
import { GQLAnimal } from "pages/MyLivestock/Animals/AnimalView/AnimalView";
// Helpers
import { getFormattedNumber, hasFeature, isValidNumberOption, prop } from "helpers/general";
import { getAge, getShortBreedrId } from "helpers/myLivestock";
import { FEATURES } from "constants/Features";
// Utils
import { BCMSNotSync, BCMSSync } from "constants/SvgIcons";
import { Terms } from "helpers/translations/src/useLocale/types";
import { ActiveIdentifierPreferencesType, ConceptionServiceType } from "generated/graphql";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { CountriesCodes } from "hooks/useGetCurrentBusinessUnit";
import { AnimalsBreedPercentages } from "../../AnimalsBreedPercentages";
interface AnimalInfoItems extends KeyValuePairProps {
  isHidden?: boolean;
}

export const getCalvingEaseScore = (score: number | null | undefined): string => {
  if (!score) {
    return "\u2014";
  }

  const scoreDescriptions = {
    1: "No assistance",
    2: "Simple Assistance",
    3: "Difficult Assistance",
    4: "Caesarean",
    5: "Abnormal presentation",
  } as const;

  const label = isValidNumberOption(score, [1, 2, 3, 4, 5]) ? scoreDescriptions[score] : "Invalid score";

  return score ? `${score} - ${label}` : "\u2014";
};

export const getServiceType = (serviceType: ConceptionServiceType | null | undefined) => {
  if (!serviceType) {
    return "\u2014";
  }

  const serviceTypes = {
    [ConceptionServiceType.Artificial]: "Artificial Insemination",
    [ConceptionServiceType.Embryo]: "Embryo Transfer",
    [ConceptionServiceType.Natural]: "Natural Service",
  } as const;

  return prop(serviceTypes, serviceType) || "\u2014";
};

export const getAnimalInfoItems = (
  animal: GQLAnimal,
  isArchived: boolean,
  formatDate: (date: Date | string | undefined) => string,
  terms: Terms,
  activeIdPreferences: ActiveIdentifierPreferencesType | null | undefined,
  countryCode: string,
): AnimalInfoItems[][] => {
  const saleStatus = animal?.rflId ? `Assigned ${animal?.rflId || ""}` : "Not Sold";
  const showPregnancyStatus: boolean = hasFeature(FEATURES.SHOW_PREGNANCY_STATUS);
  const notUS = countryCode !== CountriesCodes.US;

  const infoItems = [
    [
      {
        isHidden: !activeIdPreferences?.passportNumber || countryCode !== CountriesCodes.GB,
        label: capitaliseFirstLetter(terms.passportID),
        value: animal?.passportNumber,
        boxed: true,
      },
      {
        isHidden: !activeIdPreferences?.eId,
        label: "EID",
        value: animal?.eId,
      },
      {
        isHidden: !activeIdPreferences?.visualId,
        label: "VID",
        value: animal?.visualId,
      },
      {
        isHidden: !activeIdPreferences?.trichId || notUS || !animal?.isMale,
        label: "Trich ID",
        value: animal?.trichId,
      },
      {
        isHidden: !activeIdPreferences?.name || notUS,
        label: "Name",
        value: animal?.name || "",
      },
      {
        isHidden: !activeIdPreferences?.tsuBarcode || notUS,
        label: "TSU Barcode",
        value: animal?.tsuBarcode,
      },
      {
        isHidden: !activeIdPreferences?.brucellosisId || notUS || animal?.isMale,
        label: "Brucellosis ID",
        value: animal?.brucellosisId,
      },
      {
        isHidden: !activeIdPreferences?.pedigreeId,
        label: capitaliseFirstLetter(terms.pedigreeId),
        value: animal?.pedigreeId,
      },
      {
        isHidden: !activeIdPreferences?.tattoo || notUS,
        label: "Tattoo ID",
        value: animal?.tattoo,
      },
      {
        isHidden: !activeIdPreferences?.herdDoggId || notUS,
        label: "Herddogg ID",
        value: animal?.herdDoggId,
      },
      {
        isHidden: !activeIdPreferences?.breedrId || notUS,
        label: "Breedr ID",
        value: getShortBreedrId(animal?.breedrId),
      },
      {
        isHidden: !activeIdPreferences?.uhfId || notUS,
        label: "UHF ID",
        value: animal?.uhfId,
      },
      {
        isHidden: !activeIdPreferences?.alternativeId || notUS,
        label: "Alternative ID",
        value: animal?.alternativeId,
      },
      {
        label: "Sire ID",
        value: animal?.sirePassportNumber,
      },
      {
        label: "DAM ID",
        value: animal?.damPassportNumber,
      },
    ],
    [
      {
        label: animal?.breedPercentages && animal?.breedPercentages?.length > 1 ? "Breeds" : "Breed",
        value: <AnimalsBreedPercentages breedPercentages={animal?.breedPercentages} orientation="col" showFullName />,
      },
      {
        label: "Sex",
        value: (
          <AnimalsSexClassLabel
            isCastrated={animal?.isCastrated}
            isMale={animal?.isMale}
            label={animal?.sexClassification?.slug || null}
          />
        ),
      },
      {
        isHidden: !showPregnancyStatus || animal?.isMale,
        label: "Pregnancy status",
        value: <AnimalPregnancyLabel isMale={animal?.isMale} isPregnant={!!animal?.isPregnant} />,
      },
      {
        isHidden: !showPregnancyStatus || !animal?.pregnancyDueDate,
        label: "Pregnancy due",
        value: formatDate(animal?.pregnancyDueDate),
      },
      {
        label: "Age",
        value: animal?.dob ? getAge(DateTime.local(), animal?.dob) : null,
      },
      {
        label: "Date of birth",
        value: formatDate(animal?.dob),
      },
      {
        label: `Date on ${terms.farm}`,
        value: formatDate(animal?.dateMovedToFarm),
      },
      {
        isHidden: !isArchived,
        label: "Dead at date",
        value: formatDate(animal?.deadAt),
      },
      {
        isHidden: !isArchived,
        label: `Date left ${terms.farm}`,
        value: formatDate(animal?.dateLeftFarm),
      },
      {
        label: "Sale status",
        value: animal?.offerId ? <Link to={`/system/trading/offer/${animal?.offerId}`}>{saleStatus}</Link> : saleStatus,
      },
      {
        label: "Delivery date",
        value: formatDate(animal?.deliveryDate),
        isHidden: !animal?.deliveryDate,
      },
      {
        label: "Buyer",
        value: animal?.buyerName,
      },
      {
        isHidden: isArchived,
        label: "Withdrawal",
        value: animal?.withdrawalEnd ? `Ends ${formatDate(animal?.withdrawalEnd)}` : "No",
      },
      {
        isHidden: countryCode !== "gb",
        label: "Sync status",
        value: animal?.isRegulatorySynced ? <BCMSSync size="small" /> : <BCMSNotSync size="small" />,
      },
      {
        isHidden: !animal?.animalColour,
        label: "Colour",
        value: `[${animal?.animalColour?.code}] ${animal?.animalColour?.name}`,
      },
      {
        isHidden: !isArchived,
        label: "Kill weight",
        unit: "kg",
        value: animal?.killWeight ? getFormattedNumber(animal?.killWeight) : null,
      },
      {
        isHidden: !isArchived,
        label: "Kill quality",
        value: animal?.killQuality,
      },
      {
        isHidden: !isArchived,
        label: "Kill fat score",
        value: animal?.killFatScore,
      },
      {
        label: "Service type",
        value: getServiceType(animal?.serviceType),
      },
      {
        label: "Calving ease score",
        value: getCalvingEaseScore(animal?.calvingEaseScore),
      },
    ],
  ];

  return infoItems;
};
