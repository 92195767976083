import { useState, VFC } from "react";
//Libraries
import { useResetRecoilState } from "recoil";
//Utils
import { makeOfferCollectionDate, makeOfferPriceTotal } from "state";
// Types
import { BuyerListingOfferModalProps } from "./BuyerListingOfferModal";
// Components
import { Button, ButtonGroup } from "components";
import { BuyerListingOfferModal } from "./BuyerListingOfferModal";

type BuyerListingOfferProps = Omit<BuyerListingOfferModalProps, "onClose" | "isActive"> & {
  handleOpenChat: () => void;
};

export const BuyerListingOffer: VFC<BuyerListingOfferProps> = ({
  onNoFurtherAction,
  listingId,
  handleOpenChat,
  ...drillProps
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const resetOfferCollectionDate = useResetRecoilState(makeOfferCollectionDate);
  const resetOfferPriceTotal = useResetRecoilState(makeOfferPriceTotal);

  const handleOpenModal = (): void => {
    setIsActive(true);
  };

  const resetForms = () => {
    resetOfferCollectionDate();
    resetOfferPriceTotal();
  };

  const handleCloseModal = (): void => {
    setIsActive(false);
    resetForms();
  };

  const handleNoFurtherAction = (): void => {
    handleCloseModal();
    onNoFurtherAction();
  };

  return (
    <>
      <ButtonGroup>
        <Button id="make-offer" caption="Make offer" onClick={handleOpenModal} />
        <Button id="ask-a-question" caption="Ask a question" variant="hollow" colour="grey" onClick={handleOpenChat} />
      </ButtonGroup>

      <BuyerListingOfferModal
        isActive={isActive}
        onClose={handleCloseModal}
        onNoFurtherAction={handleNoFurtherAction}
        listingId={listingId}
        {...drillProps}
      />
    </>
  );
};
