import { Header, PageContent, PageHeader, Spacer } from "components";
import { SubscribeToPro } from "../SubscribeToPro";
import { ProFeatures, useSubscriptionPlanFeatures } from "feShared/subscriptions/hooks/useSubscriptionPlanFeatures";
import { getBUFromLocalStorage } from "helpers/storage";
import { SubscriptionContent } from "../SubscriptionContent";

export const SubscriptionDetails = () => {
  const businessUnitId = getBUFromLocalStorage();

  const { isProFeatureEnabledForBU } = useSubscriptionPlanFeatures(businessUnitId, true);
  const hasAnimalManagementSubscriptionFeature = isProFeatureEnabledForBU(ProFeatures.AnimalManagement);

  return (
    <>
      <PageHeader>
        <Spacer baselineHeight={2} />
        <Header title="Subscription Details" backButton />
      </PageHeader>
      {hasAnimalManagementSubscriptionFeature ? (
        <SubscriptionContent currentBU={businessUnitId} />
      ) : (
        <PageContent>
          <div className="bg-white mt-4 py-4 px-5 rounded">
            <SubscribeToPro showInvoiceHistoryButton />
          </div>
        </PageContent>
      )}
    </>
  );
};
