import React from "react";

import { LogoImage } from "components/Auth";

import styles from "./styles.module.css";

const ResetPasswordRequestSent: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.viewForm}>
      <LogoImage />
      <div className={styles.viewInfoBlock}>
        <span className={styles.infoText}>
          {`You should soon receive an email allowing you to reset your password.
          Please check your spam and trash if you can't find the email.`}
        </span>
      </div>
    </div>
  </div>
);

export default ResetPasswordRequestSent;
