// @ts-nocheck
import React, { useState } from "react";

import cn from "classnames";

import { SortAsc, SortDesc } from "constants/SvgIcons";

import { sortSettingsData } from "helpers/settings";

import { Badge } from "components";

import styles from "./slyles.module.css";

import { BusinessUnitUserStatus } from "api/graphql-global-types";
import { useFormatDate } from "hooks";

export interface BUUser {
  id: number;
  status: string;
  name: string;
  email: string;
  userRole: {
    id: string;
  };
  role: number;
  lastLogin: string;
  action: string;
  user: {
    firstName: string;
    lastName: string;
    id?: string;
  };
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataList: BUUser[] | any[];
  columns: Record<string, unknown>[];
  onEditView: (props: CreateAddressData) => void;
}

export const CustomTable: React.FC<Props> = ({ dataList, columns, onEditView }) => {
  const [sortDirection, setSortDirection] = useState("ASC");
  const [sortBy, setSortBy] = useState(columns[0].value);
  const { getFormatDate } = useFormatDate();
  const handleSortData = (by): void => {
    if (by === sortBy) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSortDirection("ASC");
      setSortBy(by);
    }
  };

  const handleActionButton = (action): void => action || "Edit location";

  const handleColumnValue = (value, name): void => (name === "lastLogin" && value ? getFormatDate(value) : value);

  const renderStatusBadge = (status: BusinessUnitUserStatus): JSX.Element | null => {
    switch (status) {
      case BusinessUnitUserStatus.INACTIVE:
        return <Badge text="Disabled" color="red" />;

      case BusinessUnitUserStatus.ACTIVE:
        return <Badge text="Enabled" color="green" />;

      case BusinessUnitUserStatus.INVITED:
        return <Badge text="Invited" color="yellow" />;

      default:
        return null;
    }
  };

  return (
    <div className={styles.viewTable}>
      <div className={styles.tableRow}>
        {columns.map((column, index) => (
          <div
            id={`settings_data_header_${column.key}`}
            key={`settings_data_header_${column.key}`}
            className={cn(styles.tableColumn, {
              [styles.clickable]: column.value,
              [styles.tableFirstColumn]: !index,
            })}
            onClick={(): void => column.value && handleSortData(column.value)}
          >
            <span className={styles.columnTitle}>{column.name}</span>
            {column.value ? (
              <div className={styles.viewSortIcons}>
                {sortDirection === "ASC" || (sortDirection === "DESC" && sortBy !== column.value) ? (
                  <SortAsc nativeColor="#000000" />
                ) : null}
                {sortDirection === "DESC" && sortBy === column.value ? <SortDesc nativeColor="#000000" /> : null}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      {sortSettingsData(dataList, sortBy, sortDirection === "ASC").map((value) => (
        <div key={`settings_data_values_rows_${value.id}`} className={styles.tableRow}>
          {columns.map((col, idx) => {
            const isStatusCol = col?.value === "status";
            const isColValue = !!col.value;

            return (
              <div
                key={`settings_data_row_values_${value.id}_${col.key}`}
                className={cn(styles.tableColumn, {
                  [styles.tableFirstColumn]: !idx,
                })}
              >
                {!idx ? (
                  <div className={styles.viewImage}>
                    {value.image ? (
                      <img alt="profile_image" src={value.image} className={styles.profileImage} />
                    ) : (
                      <span className={styles.nameLatter}>{value.name ? value.name.charAt(0) : "L"}</span>
                    )}
                  </div>
                ) : null}
                <div className={styles.infoColumn}>
                  <span
                    onClick={!col.value ? (): void => onEditView(value) : null}
                    className={cn(styles.columnText, {
                      [styles.actionText]: !col.value,
                    })}
                  >
                    {isStatusCol ? renderStatusBadge(value[col.value]) : null}
                    {isColValue && !isStatusCol ? handleColumnValue(value[col.value], col.value) : null}
                    {!isColValue ? handleActionButton(value.action) : null}
                  </span>
                  {col.email ? <span className={styles.columnText}>{value[col.email]}</span> : null}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
