import { useEffect } from "react";
import { FormInput } from "components/FormHookComponents/FormInput";
import { FormRadioGroup } from "components/FormHookComponents/FormRadioGroup";
import { FormRadio } from "components/FormHookComponents/FormRadio";
import { FormAddressBookSelect } from "components/FormHookComponents/FormAddressBookSelect";
import { TransactionRecordEnumType } from "generated/graphql";
import { FormTextArea } from "components/FormHookComponents/FormTextArea";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { useFormContext } from "react-hook-form";
import { FormSelect } from "components/FormHookComponents/FormSelect";
import { dateRange, singleDate, TransactionValidationSchema as S } from "../validation";
import { useFinancialTransactionCategories } from "../hooks/useFinancialTransactionCategories";
import { FormDatePicker } from "components/FormHookComponents/FormDatePicker";
import { FormPriceInput } from "components/FormHookComponents/FormPriceInput";

export const FormFields = ({ isEditMode }: { isEditMode: boolean }) => {
  const { weightUnits } = useGetCurrentBusinessUnit();
  const methods = useFormContext<S>();

  if (!methods) {
    throw new Error("FormFields must be used inside a <Form /> or <FormProvider />");
  }

  const { categoryOptions } = useFinancialTransactionCategories();

  useEffect(() => {
    const isNotExpense = methods.watch("transactionType") !== TransactionRecordEnumType.Expense;
    const isRangeDateType = methods.watch("dateType") === "dateRange";
    const shouldSwitchToSingleDateDateType = isNotExpense && isRangeDateType;

    if (shouldSwitchToSingleDateDateType) {
      methods.setValue("dateType", "singleDate");
    }
  }, [methods]);

  return (
    <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-[1fr_1fr_1fr_1fr] mt-3">
      <FormInput<S>
        name="name"
        label="Name *"
        type="text"
        disabled={!isEditMode}
        className="disabled:text-black"
        autoComplete="off"
      />
      <FormRadioGroup<S> name="transactionType" label="Transaction Type *">
        <FormRadio<S>
          name="transactionType"
          label="Sale"
          value={TransactionRecordEnumType.Sale}
          id="sale"
          disabled={!isEditMode}
        />
        <FormRadio<S>
          name="transactionType"
          label="Purchase"
          value={TransactionRecordEnumType.Purchase}
          id="purchase"
          disabled={!isEditMode}
          className="ml-3"
        />
        <FormRadio<S>
          name="transactionType"
          label="Expense"
          value={TransactionRecordEnumType.Expense}
          id="expense"
          className="ml-3"
          disabled={!isEditMode}
        />
      </FormRadioGroup>
      {methods.watch("transactionType") === TransactionRecordEnumType.Expense ? (
        <FormRadioGroup<S> name="dateType" label="Date Type *">
          <FormRadio<S> name="dateType" label="Single Date" value={singleDate} id={singleDate} disabled={!isEditMode} />
          <FormRadio<S>
            name="dateType"
            label="Date Range"
            value={dateRange}
            id={dateRange}
            disabled={!isEditMode}
            className="ml-3"
          />
        </FormRadioGroup>
      ) : null}
      {methods.watch("dateType") === singleDate ? (
        <FormDatePicker<S> label="Date *" name="date" disabled={!isEditMode} />
      ) : (
        <div className="flex gap-2">
          <FormDatePicker<S> label="Start Date *" name="date" disabled={!isEditMode} />
          <FormDatePicker<S> label="End Date" name="endDate" disabled={!isEditMode} />
        </div>
      )}
      <FormPriceInput<S> label="Total Price *" name="totalAmount" disabled={!isEditMode} placeholder="0" />
      <FormTextArea<S>
        label="Description"
        name="description"
        resize={false}
        disabled={!isEditMode}
        maxLength={50}
        rows={1}
        placeholder="Enter"
      />
      {methods.watch("transactionType") === TransactionRecordEnumType.Expense ? (
        <FormSelect<S>
          label="Category *"
          options={categoryOptions}
          name="transactionCategory"
          placeholder="Select Expense Type"
          disabled={!isEditMode}
        />
      ) : null}
      {methods.watch("transactionType") !== TransactionRecordEnumType.Expense ? (
        <>
          <FormPriceInput<S> label="Price per head" placeholder="0" name="perHeadAmount" disabled={!isEditMode} />
          <FormInput<S>
            name="grossWeight"
            label="Gross Weight"
            type="number"
            disabled={!isEditMode}
            className="disabled:text-black"
            adornmentLeft={{ label: weightUnits, labelFor: "grossWeight" }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            step={0.01}
            placeholder="0"
          />
          <FormInput<S>
            name="payWeight"
            label="Pay Weight"
            type="number"
            disabled={!isEditMode}
            className="disabled:text-black"
            adornmentLeft={{ label: weightUnits, labelFor: "payWeight" }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            step={0.01}
            placeholder="0"
          />
          <FormInput<S>
            name="shrinkage"
            label="Shrinkage"
            type="number"
            placeholder="0"
            adornmentLeft={{ label: "%", labelFor: "shrinkage" }}
            disabled={!isEditMode}
            className="disabled:text-black"
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            step={0.01}
          />
          <FormAddressBookSelect label="Party" disabled={!isEditMode} />
        </>
      ) : null}
    </div>
  );
};
