// Types
import { GetInventoryInfo_inventoryInfo } from "api/Medicine/types/GetInventoryInfo";
// Helpers
import { normaliseUnit } from "../utils";

/**
 * @description gets a 'normalized' unit string from a batch
 * @param batch the batch object
 * @returns normlized unit as string
 */
export const getBatchUnit = (batch: GetInventoryInfo_inventoryInfo): string => {
  return normaliseUnit(batch?.volumeUnits || "");
};
