import { VFC } from "react";
// Libraries
import { capitalize } from "lodash";
// Types
import { IconProps } from "components/Common/Icon";
// Components
import { Pill } from "components";

enum AnimalsBreedPillSexes {
  Female = "female",
  Male = "male",
  Mixed = "mixed",
}

export interface AnimalsBreedPillProps {
  className?: string;
  name: string;
  quantity: number;
  sex: `${AnimalsBreedPillSexes}`;
}

export const AnimalsBreedPill: VFC<AnimalsBreedPillProps> = ({ className, name, quantity, sex }) => {
  const pillCaption = `${quantity} ${name}`;

  const pillIcon = `animalSex${capitalize(sex)}` as IconProps["name"];

  return <Pill caption={pillCaption} className={className} icon={pillIcon} colour="navy" colourInverted />;
};
