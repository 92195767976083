import { graphql } from "@apollo/client/react/hoc";

import { compose, withHandlers, withStateHandlers } from "recompose";

import { CREATE_FARM_SYSTEM, UPDATE_FARM_SYSTEM, DELETE_FARM_SYSTEM } from "api/Settings/mutations";
import { getBUFromLocalStorage } from "helpers/storage";
import { getFormCredentials } from "helpers/settings";

import ConnectToSystemForm from "./ConnectToSystemForm";

const connectToSystem =
  ({ setError, editMode, setLoading, onCloseForm, createFarmSystem, updateFarmSystem, refetchFarmSystems }) =>
  async (data, showNotification, updateBuBCMSIntegration): Promise<void> => {
    setError(null);
    setLoading(true);

    const input = {
      integrationSystem: editMode ? editMode.integrationSystem : data.system,
      farm: getBUFromLocalStorage(),
      credentials: getFormCredentials(data),
    };

    let err = [];

    try {
      if (editMode) {
        const {
          data: {
            updateFarmSystem: { errors },
          },
        } = await updateFarmSystem({
          variables: {
            input: {
              ...input,
              farmSystem: editMode.id,
            },
          },
        });
        err = errors;
      } else {
        const {
          data: {
            createFarmSystem: { errors },
          },
        } = await createFarmSystem({
          variables: {
            input,
          },
        });
        err = errors;
      }

      const hasError = err?.length > 0;

      showNotification({
        // @ts-ignore
        message: hasError ? err[0].message : "Settings successfully saved.",
        variant: hasError ? "error" : "success",
      });
      const { data } = await refetchFarmSystems();
      updateBuBCMSIntegration(data.farmSystems);
      setLoading(false);
      onCloseForm();
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Error saving settings.",
      });
      setLoading(false);
      setError("Something went wrong!");
    }
  };

const removeFarmConnection =
  ({ editMode, setError, refetchFarmSystems, setLoading, toggleModal, deleteFarmSystem, onCloseForm }) =>
  async (showNotification, updateBuBCMSIntegration): Promise<void> => {
    setError(null);
    setLoading(true);

    try {
      await deleteFarmSystem({
        variables: {
          input: {
            farmSystem: editMode.id,
          },
        },
      });

      showNotification({
        message: "Settings successfully saved.",
      });
      const { data } = await refetchFarmSystems();
      updateBuBCMSIntegration(data.farmSystems);
      setLoading(false);
      toggleModal(false);
      onCloseForm();
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Error saving settings.",
      });
      setLoading(false);
      setError("Something went wrong!");
    }
  };

export default compose(
  graphql(CREATE_FARM_SYSTEM, {
    name: "createFarmSystem",
  }),
  graphql(UPDATE_FARM_SYSTEM, {
    name: "updateFarmSystem",
  }),
  graphql(DELETE_FARM_SYSTEM, {
    name: "deleteFarmSystem",
  }),
  withStateHandlers(
    {
      loading: false,
      error: null,
      showModal: false,
    },
    {
      setLoading:
        () =>
        (loading): Record<string, unknown> => ({
          loading,
        }),
      setError:
        () =>
        (error): Record<string, unknown> => ({
          error,
        }),
      toggleModal:
        () =>
        (showModal): Record<string, unknown> => ({
          showModal,
        }),
    },
  ),
  withHandlers({
    connectToSystem,
    removeFarmConnection,
  }),
)(ConnectToSystemForm);
