import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetBusinessUnitQueryResult } from "generated/graphql";

export type Species = NonNullable<
  Array<NonNullable<GetBusinessUnitQueryResult["data"]>["businessUnit"]>[number]
>["farmAnimalTypes"][number];

interface SpeciesState {
  speciesList: Species[] | undefined;
  activeSpecies: Species | undefined;
}

const initialState: SpeciesState = {
  speciesList: undefined,
  activeSpecies: undefined,
};

const speciesSlice = createSlice({
  name: "species",
  initialState,
  reducers: {
    setSpeciesList: (state, action: PayloadAction<Species[] | undefined>) => {
      state.speciesList = action.payload;
    },
    resetSpeciesList: (state) => {
      state.speciesList = undefined;
    },
    setActiveSpecies: (state, action: PayloadAction<Species | undefined>) => {
      state.activeSpecies = action.payload;
    },
  },
});

export const { setSpeciesList, resetSpeciesList, setActiveSpecies } = speciesSlice.actions;
export const speciesReducer = speciesSlice.reducer;
