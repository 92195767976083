import { useCallback, useMemo } from "react";
// Libraries
import { isEqual } from "lodash";
import { useRecoilState, useResetRecoilState } from "recoil";
// Utils
import { DEFAULT_LIVEWEIGHT_FILTERS, LiveweightFiltersState } from "./config";
import { liveweightFiltersServerSide } from "trading/state/liveweightFilters";

export type ListingFiltersInstance = "buyer" | "seller";

interface UseListingFiltersReturn {
  isDefault: boolean;
  reset: () => void;
  set: (newFilters: Partial<LiveweightFiltersState>) => void;
  values: LiveweightFiltersState;
}

export const useListingFiltersServerSide = (instance: ListingFiltersInstance): UseListingFiltersReturn => {
  const [filters, setFilters] = useRecoilState(liveweightFiltersServerSide(instance));
  const isDefault = isEqual(filters, DEFAULT_LIVEWEIGHT_FILTERS);
  const resetFilters = useResetRecoilState(liveweightFiltersServerSide(instance));

  const handleSetFilters = useCallback(
    (newFilters: Partial<LiveweightFiltersState>) => {
      setFilters({
        ...filters,
        ...newFilters,
      });
    },
    [filters],
  );

  return useMemo(
    () => ({
      isDefault,
      reset: resetFilters,
      set: handleSetFilters,
      values: filters,
    }),
    [filters],
  );
};

export { DEFAULT_LIVEWEIGHT_FILTERS };
