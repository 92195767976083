import React from "react";
// Libraries
import cn from "classnames";
// Types
import { Pill, PillProps } from "components/Common/Pill";
// Components
import { StatusPill } from "trading/components/StatusPill";
// Resources
import placeholderImage from "assets/img/Trading/Marketplace/listing-placeholder.jpg";
import styles from "./ListingCardPhoto.module.scss";

interface ListingCardPhotoProps {
  expiryDate?: Date;
  startDate?: Date;
  image?: string;
  imageAriaLabel?: string;
  isFaded?: boolean;
  offerStatus?: Pick<PillProps, "caption" | "colour">;
}

export const ListingCardPhoto: React.FC<ListingCardPhotoProps> = ({
  expiryDate,
  startDate,
  image,
  imageAriaLabel,
  isFaded,
  offerStatus,
}) => (
  <figure className={cn(styles.listing_card_photo, { [styles["listing_card_photo--is_faded"]]: isFaded })}>
    <img
      alt={image ? "A photograph of animals" : "A placeholder picture"}
      aria-label={imageAriaLabel}
      className={styles.listing_card_photo__image}
      src={image || placeholderImage}
    />

    {expiryDate && startDate ? (
      <StatusPill
        className={cn(styles.listing_card_photo__pill, styles["listing_card_photo__pill--expiry_date"])}
        expiryDate={expiryDate}
        startDate={startDate}
        wrap
      />
    ) : null}

    {offerStatus ? (
      <Pill
        caption={offerStatus.caption}
        className={cn(styles.listing_card_photo__pill, styles["listing_card_photo__pill--offer_status"])}
        colour={offerStatus.colour}
        colourInverted
      />
    ) : null}
  </figure>
);
