import { useMemo } from "react";

import { LoadingOverlay } from "components";
import { EmptyStatesGenetics } from "components/EmptyStates/Genetics";

import {
  useAngusEpdDataQuery,
  useBeefXDairyDataQuery,
  useBreedseekDataQuery,
  useNeogenEpdDataQuery,
} from "generated/graphql";

import { GeneticTestResultsTable } from "./GeneticTestResultsTable";
import { useFormatDate } from "hooks";
import { getTestName, transformTestResultsToTableRow } from "./helpers";
import { EpdDataTable } from "./EpdDataTable";

interface Props {
  animalId: string;
}

const excludedKeys = ["__typename", "test", "barcode", "updatedAt", "createdAt"];

export const Genetics: React.FC<Props> = ({ animalId }) => {
  const { getFormatDate } = useFormatDate();
  const queryHookOptions = {
    variables: {
      id: animalId,
    },
    skip: !animalId,
    fetchPolicy: "cache-and-network" as const,
    nextFetchPolicy: "cache-only" as const,
  };
  const { data: breedseekData, loading: breedseekLoading } = useBreedseekDataQuery(queryHookOptions);
  const { data: beefxdairyData, loading: beefXDairyLoading } = useBeefXDairyDataQuery(queryHookOptions);
  const { data: americanAngusEpdData, loading: americanAngusEpdLoading } = useAngusEpdDataQuery(queryHookOptions);
  const { data: neogenEpdData, loading: neogenEpdLoading } = useNeogenEpdDataQuery(queryHookOptions);

  const breedseekResults = breedseekData?.breedseek;
  const beefXDairyResults = beefxdairyData?.beefxdairy;
  const americanAngusEpdResults = americanAngusEpdData?.americanAngusEpd;
  const neogenEpdResults = neogenEpdData?.neogenEpd;

  const epdData = useMemo(() => {
    if (neogenEpdResults && americanAngusEpdResults) {
      const neogenUpdated = new Date(neogenEpdResults.updatedAt);
      const neogenCreated = new Date(neogenEpdResults.createdAt);
      const angusUpdated = new Date(americanAngusEpdResults.updatedAt);
      const angusCreated = new Date(americanAngusEpdResults.createdAt);

      const neogenMostRecent = neogenUpdated > neogenCreated ? neogenUpdated : neogenCreated;
      const angusMostRecent = angusUpdated > angusCreated ? angusUpdated : angusCreated;

      const data = neogenMostRecent > angusMostRecent ? neogenEpdResults : americanAngusEpdResults;
      const testName = getTestName(data);
      return { data, testName };
    } else if (neogenEpdResults) {
      const data = neogenEpdResults;
      const testName = getTestName(data);
      return { data, testName };
    } else if (americanAngusEpdResults) {
      const data = americanAngusEpdResults;
      const testName = getTestName(data);
      return { data, testName };
    }
    return { data: undefined, testName: "" };
  }, [americanAngusEpdResults, neogenEpdResults]);

  const geneticDataPresenceFlag = {
    isBreedseekDataPresent: !!breedseekResults,
    isBeefXDairyDataPresent: !!beefXDairyResults,
    isAmericanAngusEpdDataPresent: !!americanAngusEpdResults,
    isNeogenEpdDataPresent: !!neogenEpdResults,
  };

  const loading = breedseekLoading || beefXDairyLoading || americanAngusEpdLoading || neogenEpdLoading;
  if (loading) {
    return <LoadingOverlay />;
  }

  if (Object.values(geneticDataPresenceFlag).every((value) => !value)) {
    return <EmptyStatesGenetics />;
  }

  const nonEpdGeneticTestData = [breedseekResults, beefXDairyResults].filter(Boolean);

  const tableData = nonEpdGeneticTestData.map((item) => {
    return transformTestResultsToTableRow(item, excludedKeys, breedseekResults, getFormatDate);
  });

  return (
    <>
      <div className="pt-4">
        <GeneticTestResultsTable data={tableData} loading={loading} />
      </div>
      <div className="pt-4">
        <EpdDataTable epdData={epdData} loading={loading} />
      </div>
    </>
  );
};
