import React, { ChangeEvent } from "react";
import { Page } from "components";
import { Button } from "twComponents/Button";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "constants/Routes";
import { SubscriptionHeader } from "subscriptions/components/SubscriptionHeader";
import { useAuth } from "auth/hooks/useAuth";

export const Chat: React.FC = () => {
  const history = useHistory();
  const { changeBusinessUnit, activeBUId } = useAuth();
  const handleChangeBusinessUnit = (event: ChangeEvent<HTMLSelectElement>): void => {
    changeBusinessUnit(event.target.value);
  };
  return (
    <Page>
      <SubscriptionHeader handleChangeBusinessUnit={handleChangeBusinessUnit} currentBU={activeBUId} title={"Chat"} />
      <div className="flex grow flex-col content-center flex-wrap justify-center">
        <span className="text-6xl text-center mb-2">👋</span>
        <span className="mb-2">Thank you for contacting us</span>
        <Button className="mb-12" asChild type="button" onClick={() => history.push(DASHBOARD)} size="lg">
          <a href="https://breedr.page.link/?link=https://uk.breedr.co/link/home&apn=com.breedrmobile&isi=1435530409&ibi=co.breedr.breedrapp&efr=1">
            Open mobile app
          </a>
        </Button>
      </div>
    </Page>
  );
};
