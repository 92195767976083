// Helpers
import { isKilos, isLitres, normaliseUnit } from "../utils";

/**
 * @description calculates actual dosage to send to BE based on default unit and dosage unit
 * @param defaultUnit default unit of the batch
 * @param doseUnit the unit being administered
 * @param dose the dosage amount
 * @returns [dosage, unit]
 */
export const getCalculatedDose = (defaultUnit: string, doseUnit: string, dose: number | string): [number, string] => {
  const dosage = Number(dose);
  const fromUnit = normaliseUnit(defaultUnit);
  const toUnit = normaliseUnit(doseUnit);

  // if dosage unit and default unit don't match then we need to convert
  if (fromUnit !== toUnit) {
    if (isKilos(fromUnit)) {
      return [dosage / 1000, "kg"];
    }

    if (isLitres(fromUnit)) {
      return [dosage / 1000, "l"];
    }

    return [dosage / 1000, doseUnit];
  }

  return [dosage, doseUnit];
};
