import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// Utils
import { DASHBOARD, BU_ID, SETTINGS, BUSINESS_SETTINGS } from "constants/Routes";
// Hooks
import { useGetBusinessUnitUserRole } from "hooks";
// Pages
import BusinessSettings from "pages/Settings/BusinessSettings";

const ROUTES_PATHS = {
  BusinessSettings: `${BU_ID}${SETTINGS}${BUSINESS_SETTINGS}`,
};

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

const ProcessorRoutes: React.FC = () => {
  const { isAdmin } = useGetBusinessUnitUserRole();

  return (
    <Route exact path={PATHS}>
      <Switch>
        {isAdmin ? <Route exact path={ROUTES_PATHS.BusinessSettings} component={BusinessSettings} /> : null}
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};

export default ProcessorRoutes;
