import { type ClassValue, clsx } from "clsx";
import { TablePaginationSizes } from "components/Common/Table/TablePagination";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getLoadingStateRows = <T>(numberOfRows: TablePaginationSizes, generatePlaceholder: () => T) => {
  return Array.from({ length: numberOfRows }, generatePlaceholder);
};
