import { gql } from "@apollo/client";
import { farmSystemFragment } from "./fragment";

export const GET_FARM_SYSTEMS = gql`
  query GetFarmSystems($farm: Int!) {
    farmSystems(farm: $farm) {
      ...farmSystemFragment
    }
  }
  ${farmSystemFragment}
`;

export const GET_SETTINGS_FAQ = gql`
  query GetSettingsFaq($businessUnitId: Int!) {
    settingsFaq(businessUnitId: $businessUnitId) {
      id
      title
      description
    }
  }
`;

export const GET_TERMS = gql`
  query GetTerms($tacType: String!) {
    termsAndConditions(tacType: $tacType) {
      id
      title
      description
      type
    }
  }
`;

export const GET_BANK_DETAILS = gql`
  query GetBankDetails($businessUnitId: Int) {
    bankDetails(businessUnitId: $businessUnitId) {
      id
      accountNo
      accountName
      sortCode
      isBusinessAccount
    }
  }
`;

export const GET_IDENTIFIER_PREFERENCES = gql`
  query GetIdentifierPreferences($businessUnit: Int!) {
    businessUnit(businessUnit: $businessUnit) {
      guid
      identifierPreferencesActiveIds {
        passportNumber
        visualId
        eId
        name
        trichId
        tsuBarcode
        pedigreeId
        tattoo
        herdDoggId
        breedrId
        uhfId
        brucellosisId
        alternativeId
      }
      identifierPreferencesPrimaryId
      identifierPreferencesSecondaryId
    }
  }
`;
