// React
import React, { memo } from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./Title.module.scss";

interface TitleProps {
  children: React.ReactNode;
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  secondary?: boolean;
  tertiary?: boolean;
  theme?: "dark" | "light" | "breedr";
}
export const Title: React.FC<TitleProps> = memo(
  ({ children, className, level, secondary, tertiary, theme = "dark", ...props }) => {
    const TitleTag = `h${level || (secondary ? 2 : 1)}` as "h1" | "h2";

    return (
      <TitleTag
        className={cn(
          styles.title,
          styles[`title--theme__${theme}`],
          {
            [styles["title--ordinal__primary"]]: !secondary && !tertiary,
            [styles["title--ordinal__secondary"]]: secondary,
            [styles["title--ordinal__tertiary"]]: tertiary,
          },
          className,
        )}
        {...props}
      >
        {children}
      </TitleTag>
    );
  },
);
Title.displayName = "Title";
