import { Text, Icon, Flex } from "components";
import { ImportResultError, useImportResultLazyQuery } from "generated/graphql";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import styles from "./AsyncJobStatusMenuImport.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { setImportMenuState, setImportTaskId, PartialBatchUploadFormatColumn } from "state/asyncImportJobSlice";
import { AsyncJobStatus, JobMenuItem, StatusBadge } from "animals/AsyncJobStatusMenuExport";
import { fuzzyStringMatch } from "helpers/general";

export const AsyncJobStatusMenuImport: React.FC = () => {
  const dispatch = useDispatch();
  const { importMenuState, importTaskId, unmatchedColumns, availableColumns } = useSelector(
    (state: RootState) => state.asyncImportJob,
  );
  const [getImportResult, { data: importResultData, stopPolling }] = useImportResultLazyQuery();
  const [isLoading, setIsLoading] = useState(false);

  const importResultError =
    importResultData?.importResult?.errorRows && importResultData.importResult.errorRows.length > 0;
  const importResultWarning = unmatchedColumns.length > 0;

  useEffect(() => {
    if (importResultError) {
      stopPolling();
      setIsLoading(false);
      dispatch(setImportTaskId(undefined));
    }
  }, [dispatch, importResultError, stopPolling]);

  useEffect(() => {
    if (importTaskId) {
      setIsLoading(true);

      getImportResult({
        variables: {
          task: importTaskId,
        },
        pollInterval: 2000,
      });
    }

    if (importResultData?.importResult?.isDone) {
      setIsLoading(false);
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [
    importResultData?.importResult?.isDone,
    getImportResult,
    stopPolling,
    importTaskId,
    importResultError,
    importResultData,
  ]);

  const handleClick = () => {
    dispatch(setImportMenuState(importMenuState === "collapsed" ? "expanded" : "collapsed"));
  };

  const asyncJobStatus: AsyncJobStatus = isLoading
    ? "loading"
    : importResultError
    ? "error"
    : importResultWarning
    ? "warning"
    : "complete";

  return (
    <motion.div
      layout
      className={cn(styles.statusMenu, {
        [styles["statusMenu--collapsed"]]: importMenuState === "collapsed",
        [styles["statusMenu--expanded"]]: importMenuState === "expanded",
        [styles["statusMenu--closed"]]: importMenuState === "closed",
        [styles["statusMenu--has-errors"]]: importResultError,
        [styles["statusMenu--has-warnings"]]: importResultWarning,
      })}
    >
      <Flex container containerJustifyContent="space-between" containerAlignItems="center" className={styles.menuBar}>
        <Flex container containerAlignItems="center">
          <Spacer width={16} />
          <Text smaller colour="white" className={styles.activityText}>
            Activity
          </Text>
          <Spacer width={16} />
          <StatusBadge isLoading={isLoading} isError={Boolean(importResultError)} />
        </Flex>

        <Flex container containerJustifyContent="flex-end" containerAlignItems="center">
          <div className={styles.pointer} onClick={handleClick}>
            <Icon name={importMenuState === "collapsed" ? "maximize" : "minimize"} />
          </div>
          <Spacer width={26} />
          <div className={styles.pointer} onClick={() => dispatch(setImportMenuState("closed"))}>
            <Icon size="tiny" name="cross" />
          </div>
          <Spacer width={16} />
        </Flex>
      </Flex>
      <ul
        className={cn(styles.activityListImport, {
          [styles["activityListImport--has-warnings"]]: importResultWarning,
        })}
      >
        <JobMenuItem status={asyncJobStatus} isUpload={true} />
        {renderErrorElements(
          (importResultData?.importResult?.rowErrors as ImportResultError[]) || [],
          availableColumns,
        )}
        {renderUnmatchedColumns(
          unmatchedColumns || [],
          availableColumns.map((column) => column.label),
        )}
      </ul>
    </motion.div>
  );
};

const Spacer = ({ width, height }: { width?: number; height?: number }) => {
  return <div style={{ width, height }} />;
};

function renderErrorElements(
  errorRows: ImportResultError[],
  availableColumns: PartialBatchUploadFormatColumn[],
): JSX.Element[] {
  const errorElements: JSX.Element[] = [];
  const peak_length = 2;
  if (errorRows.length > 0) {
    let errorCount = 0;
    for (let rowIndex = 0; rowIndex < errorRows.length && errorCount < peak_length; rowIndex++) {
      const row = errorRows[rowIndex];
      if (!!!row.errors) {
        continue;
      }
      for (let errorIndex = 0; errorIndex < row.errors.length && errorCount < peak_length; errorIndex++) {
        const error = row.errors[errorIndex];
        const label = availableColumns.find((column) => column.slug === error.field)?.label ?? error.field;
        errorElements.push(
          <li key={`error-${rowIndex}-${errorIndex}`} className={cn(styles.errorListItem)}>
            <Text smallest colour="red">
              {label ? `${label}: ` : null}
              {error.message}
            </Text>
          </li>,
        );
        errorCount++;
      }
    }

    const remainingErrors = errorRows.reduce((acc, row) => acc + (row.errors ? row.errors.length : 0), 0) - peak_length;
    if (remainingErrors > 0) {
      errorElements.push(
        <li key="more-errors" className={cn(styles.errorListItem)}>
          <Text smallest colour="red">
            {`And ${remainingErrors} more error(s)...`}
          </Text>
        </li>,
      );
    }

    errorElements.push(<Spacer height={16} />);
  }
  return errorElements;
}

function renderUnmatchedColumns(unmatchedColumns: string[], availableColumns: string[] = []) {
  if (!unmatchedColumns || unmatchedColumns.length === 0) {
    return null;
  }
  return (
    <>
      {unmatchedColumns.map((unmatchedColumn, index) => {
        const possibleMatch = fuzzyStringMatch(unmatchedColumn, availableColumns, 0.5);
        return (
          <li key={index} className={cn(styles.errorListItem)}>
            <Text smallest className="text-orange-400">
              {possibleMatch
                ? `Column "${unmatchedColumn}" could not be matched. Did you mean: "${possibleMatch}"?`
                : `Column "${unmatchedColumn}" could not be matched and no similar columns were found.`}
            </Text>
            <Spacer height={10} />
          </li>
        );
      })}
    </>
  );
}
