export const FARM_TYPES = {
  CALF_REARING: "Calf rearing",
  SUCKLER_HERD: "Suckler herd",
  STORES: "Stores",
  FINISHING: "Finishing",
  INTEGRATED: "Integrated",
  DAIRY: "Dairy",
  SHEEP: "Sheep",
  PIGS: "Pigs",
};

export const FARM_SIZES = ["0", "1-49", "50-199", "200-499", "500+"];
