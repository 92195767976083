import { useQuery } from "@apollo/client";

import { GET_PREFERRED_BUSINESS_UNITS } from "api/BusinessUnits/queries";

import {
  GetPreferredBusinessUnits,
  GetPreferredBusinessUnitsVariables,
} from "api/BusinessUnits/types/GetPreferredBusinessUnits";

import { getBUFromLocalStorage } from "helpers/storage";

export const useGetPreferredBusinessUnits = () => {
  const { data, loading, ...rest } = useQuery<GetPreferredBusinessUnits, GetPreferredBusinessUnitsVariables>(
    GET_PREFERRED_BUSINESS_UNITS,
    {
      variables: {
        businessUnit: Number(getBUFromLocalStorage()),
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    },
  );

  const preferredUsers = data?.preferredBusinessUnits || [];

  return {
    data: preferredUsers,
    loading,
    ...rest,
  };
};
