import { FC, ReactNode } from "react";
// Libraries
import { Formik } from "formik";
import pure from "recompose/pure";

interface FormBlockProps {
  children: ReactNode;
  enableReinitialize?: boolean;
  initialValues: Record<string, unknown>;
  onSubmit: (data: Record<string, unknown>) => void;
  validationSchema: Record<string, unknown>;
}

const FormBlock: FC<FormBlockProps> = ({ children, enableReinitialize, initialValues, onSubmit, validationSchema }) => (
  <Formik
    enableReinitialize={enableReinitialize}
    validateOnChange={false}
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {children}
  </Formik>
);

export default pure(FormBlock);
