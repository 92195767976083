import { useHistory } from "react-router-dom";
import { CREATE_LISTING, TRADING } from "constants/Routes";
import { useGetAnimalCount } from "hooks";
import { SellerListings, SellerListingsTitle } from "trading/components/containers";
import { ListingFilters, ListingSort } from "trading/components";
import { Flex, PageContent, Spacer, Title } from "components";
import { EventName, logAnalyticsEvent } from "utils/analytics";

export const ViewSellerListings: React.FC = () => {
  const history = useHistory();
  const { data } = useGetAnimalCount();

  return (
    <PageContent
      floatingIconTextButton={{
        iconName: "plus",
        onClick: (): void => {
          logAnalyticsEvent(EventName.listingStartedSelling);
          history.push(`${TRADING}${CREATE_LISTING}`);
        },
        disabled: data === 0,
        text: "Create Listing",
      }}
    >
      <Spacer baselineHeight={3} />

      <Flex container containerAlignItems="flex-start">
        <Flex item itemGutter xs={3}>
          <Spacer allowDecimal baselineHeight={0.5} />

          <Title tertiary>Filter</Title>

          <Spacer allowDecimal baselineHeight={1.5} />

          <ListingFilters instance="seller" />
        </Flex>

        <Flex container containerDirection="column" item xs={9}>
          <Flex container containerAlignItems="center" item xs="fill">
            <Flex item itemGutter xs={6}>
              <SellerListingsTitle />
            </Flex>

            <Flex item itemGutter xs={3}>
              <ListingSort instance="seller" />
            </Flex>
          </Flex>

          <Spacer baselineHeight={1} />

          <Flex item itemGutter xs="fill">
            <SellerListings />
          </Flex>
        </Flex>
      </Flex>
    </PageContent>
  );
};
