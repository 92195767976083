import React, { FC } from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryStack, VictoryLegend } from "victory";

import { theme } from "reports/theme";
import styles from "./styles.module.scss";
import { useBarChartDataQuery } from "generated/graphql";
import { LoadingOverlay } from "components";

interface IProps {
  chartTitle: string;
  dataQuery: string; //Use this to make a query asking for chart data
  businessUnitId: number;
}

const barLabelStyles = {
  fontSize: 10,
  fill: "#ffffff",
  textAnchor: "middle",
};

const CenteredLabel = (props) => {
  const { datum, scale } = props;
  const centerPos = scale.y((datum._y1 - datum._y0) / 2 + datum._y0);

  return <VictoryLabel {...props} style={[barLabelStyles]} x={centerPos} dx={0} />;
};

export const BarChart: FC<IProps> = ({ chartTitle, dataQuery, businessUnitId }) => {
  const { data: data, loading } = useBarChartDataQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      chartSlug: dataQuery,
      dashboardSlug: chartTitle,
      businessUnitId: businessUnitId,
    },
  });
  const bars = data?.barChartData?.bars ?? [];
  const legendLabels = bars.map((d) => ({ name: d.label }));
  const labels = [...new Set(bars.flatMap((bar) => bar.data.map((d) => d.x)))];

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <figure className={styles.container}>
      <VictoryChart domainPadding={20} theme={theme} height={250} horizontal>
        <VictoryAxis dependentAxis tickFormat={(x) => `${x / 1}`} style={{ tickLabels: { fontSize: 10 } }} />
        <VictoryAxis
          tickValues={[1, 2]}
          tickFormat={labels}
          tickLabelComponent={<VictoryLabel verticalAnchor="middle" textAnchor="start" x={0} />}
          style={{ tickLabels: { fontSize: 10 } }}
        />
        <VictoryStack>
          {bars.map((d, i) => {
            return (
              <VictoryBar
                data={d.data}
                key={i}
                labelComponent={<CenteredLabel />}
                labels={({ datum }) => datum.y}
                barWidth={30}
              />
            );
          })}
        </VictoryStack>
        <VictoryLegend
          x={290}
          y={100}
          orientation="vertical"
          gutter={20}
          rowGutter={{ top: 0, bottom: 5 }}
          style={{
            labels: { fontSize: 8 },
          }}
          data={legendLabels}
        />
      </VictoryChart>
    </figure>
  );
};
