import { Route, Switch, Redirect } from "react-router-dom";
import { DASHBOARD, IMPORT, CUSTOM_IMPORT, SUPPORTED_SYSTEM_IMPORT } from "constants/Routes";
import { BU_TYPES } from "trading/constants";
import { useHasFeature } from "hooks";
import { Import, ImportCustom, ImportExternal } from "pages";
import { getBUTypeFromLocalStorage } from "helpers/storage";

const IMPORT_PATHS = {
  Root: IMPORT,
  Custom: `${IMPORT}${CUSTOM_IMPORT}/:batchUploadFormatSlug?`,
  External: `${IMPORT}${SUPPORTED_SYSTEM_IMPORT}`,
};

export const PATHS = Object.keys(IMPORT_PATHS).map((key) => IMPORT_PATHS[key]);

export const ImportsRoutes: React.FC = () => {
  const selectedBUType = getBUTypeFromLocalStorage();

  const isShowAnimalsForBuyer: boolean = useHasFeature("SHOW_ANIMALS_FOR_BUYER");

  const isFarmer: boolean = selectedBUType !== BU_TYPES.processor;

  const isImportsEnable: boolean = isFarmer || isShowAnimalsForBuyer;

  const importsRoutes = [
    <Route exact path={IMPORT_PATHS.Root} key={IMPORT_PATHS.Root} component={Import} />,
    <Route exact path={IMPORT_PATHS.External} key={IMPORT_PATHS.External} component={ImportExternal} />,
    <Route exact path={IMPORT_PATHS.Custom} key={IMPORT_PATHS.Custom} component={ImportCustom} />,
  ];

  return (
    <Route exact path={PATHS}>
      <Switch>
        {isImportsEnable ? importsRoutes : null}
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};
