import React from "react";

import { Header, PageContent, PageHeader, Spacer } from "components";
import ModifyPasswordForm from "pages/Settings/SecuritySettings/ModifyPasswordForm";

const SecuritySettings: React.FC = () => (
  <>
    <PageHeader>
      <Header title="Security Settings" subTitle="Manage your password and security information" />
    </PageHeader>

    <PageContent>
      <Spacer baselineHeight={3} />

      <ModifyPasswordForm />
    </PageContent>
  </>
);

export default SecuritySettings;
