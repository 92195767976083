import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useLocale } from "helpers/translations/src/useLocale";
import { useState } from "react";
import { Button, Modal } from "components";
import { ContactForm } from "components/ContactForm";
import { MarkOffFarmForm } from "../MarkOffFarmForm";

type Props = {
  tableId: string;
  isShowModal?: boolean;
  groupId?: string;
  isSingleAnimal?: boolean;
  onClose?: () => void;
  animalIds: Array<string>;
};

export const MarkOffFarmModal = ({ tableId, groupId, animalIds, isShowModal, onClose, isSingleAnimal }: Props) => {
  const [modalContent, setModalContent] = useState<"createNewContactForm" | "markOffFarmForm">("markOffFarmForm");
  const { terms } = useLocale();
  const [showModal, setShowModal] = useState(isShowModal);

  const handleCancelClick = (e) => {
    e.stopPropagation();
    handleModalToggle();
  };

  const handleModalToggle = () => {
    setShowModal((prevState) => !prevState);
    onClose && onClose();
  };

  return (
    <>
      {!isSingleAnimal ? (
        <Button
          id={ELEMENTS_IDS.BULK_MARK_OFF_FARM_BUTTON}
          caption={`Mark off-${terms.farm}`}
          onClick={handleModalToggle}
        />
      ) : null}
      <Modal
        buttonsLeftSide
        active={showModal}
        handleClose={handleModalToggle}
        title={modalContent === "markOffFarmForm" ? `Mark animal(s) off-${terms.farm}?` : "Create New Contact"}
        subTitle=""
      >
        {modalContent === "markOffFarmForm" ? (
          <MarkOffFarmForm
            tableId={tableId}
            animalIds={animalIds}
            handleCancelClick={handleCancelClick}
            changeModalContent={() => setModalContent("createNewContactForm")}
            handleModalToggle={handleModalToggle}
            groupId={groupId}
          />
        ) : (
          <ContactForm
            contactId={undefined}
            onCancel={() => setModalContent("markOffFarmForm")}
            onConfirm={() => setModalContent("markOffFarmForm")}
            isModal
          />
        )}
      </Modal>
    </>
  );
};
