import { Controller, FormProvider, useForm } from "react-hook-form";
import { AnimalSchema, useCreateAnimalSchema } from "../AnimalForm/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { addIsoPrefixToEid, createPrefix, generateDefaultAnimalFormValues, sanitiseAnimalId } from "./helpers";
import { useContext, useRef, useState } from "react";
import { Button, FieldDate, FieldNumber, FieldSelect, LoadingOverlay } from "components";
import { useGetBreeds } from "hooks/useGetBreeds";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { useStateSpecies } from "hooks/useStateSpecies";
import { InputSelectOption } from "components/Common/Field/Select";
import { useGetFields } from "hooks/useGetFields";
import { useGetGroups } from "hooks/useGetGroups";
import {
  AnimalFragment,
  AnimalTagAssignmentInputType,
  ContactFragmentFragment,
  ErrorFragmentFragment,
  useCreateAndAddAnimalToDeliveryMutation,
  useUpdateAnimalMutation,
} from "generated/graphql";
import { CommonContext } from "config/commonProvider";
import { getErrorMessage, removeNothings } from "helpers/general/all";
import { capitaliseFirstLetter, capitaliseFirstLetterOfEachWord } from "helpers/translations/src/format";
import { useGetDamAnimals } from "hooks/useGetDamAnimals";
import { useGetSires } from "hooks/useGetSires";
import { useGetAnimalConditions } from "hooks/useGetAnimalConditions";
import { useGetTags } from "hooks/useGetTags";
import { useLocale } from "helpers/translations/src/useLocale";
import { AnimalIdInputs } from "../AnimalIdInputs";
import { AddressBookSelect } from "components/Settings/AddressBook/AddressBookSelect";
import { DRAFT_DELIVERY, DRAFT_DELIVERY_ANIMALS } from "deliveries/api/queries";

type Props = {
  deliveryId: string;
  onCancel: () => void;
  onCreateAnimal: () => void;
  animal: AnimalFragment | undefined;
};

export const CATTLE_ID = "3";

export const AnimalForm = ({ deliveryId, onCancel, onCreateAnimal, animal }: Props) => {
  const { terms: t } = useLocale();
  const { countryIsoCodeUpperCase, id: businessUnitId, country } = useGetCurrentBusinessUnit();

  const isoNumber = country?.isoNumber || undefined;

  const { activeSpecies } = useStateSpecies();
  const { showNotification } = useContext(CommonContext);

  const isCattle = animal?.animalType?.id === CATTLE_ID || (!animal && activeSpecies?.id === CATTLE_ID);

  const [createAnimal] = useCreateAndAddAnimalToDeliveryMutation();
  const [updateAnimal] = useUpdateAnimalMutation();
  const [isLoadingCreateAndFinish, setIsLoadingCreateAndFinish] = useState<boolean>(false);
  const [isLoadingCreateAndAnother, setIsLoadingCreateAndAnother] = useState<boolean>(false);
  const [createAnother, setCreateAnother] = useState<boolean>(false);

  // The herd dogg ID is a masked input ref is fairly wild and there
  // are no types, so I use an any here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const herdDoggIdInputRef = useRef<any>(null);

  const validationSchema = useCreateAnimalSchema(animal);

  const defaultFormValues = generateDefaultAnimalFormValues({ animal, isoPrefix: createPrefix(isoNumber) });

  const methods = useForm<AnimalSchema>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  const { fieldOptions, loading: loadingFields } = useGetFields();
  const { groupOptions, loading: loadingGroups } = useGetGroups();
  const { breedOptions, loading: loadingBreeds } = useGetBreeds();
  const { tagOptions, loading: loadingTags } = useGetTags();
  const { sireOptions, loading: loadingSires } = useGetSires();
  const { animalConditionOptions, loading: loadingAnimalConditions } = useGetAnimalConditions();

  const {
    damOptions,
    searchDam,
    loading: loadingDams,
  } = useGetDamAnimals({
    animalId: animal?.damId || undefined,
    forAllSpecies: false,
  });

  const { handleSubmit, control } = methods;

  const isMale = methods.watch("animalSex") === "male";

  const onSubmit = async (formData: AnimalSchema) => {
    try {
      createAnother ? setIsLoadingCreateAndAnother(true) : setIsLoadingCreateAndFinish(true);

      let errors: Array<ErrorFragmentFragment> = [];

      const eId = isCattle
        ? sanitiseAnimalId(formData.eId)
        : sanitiseAnimalId(addIsoPrefixToEid(formData.eId || "", isoNumber));

      if (animal && animal.animalType?.id) {
        //INFO:  We allow updating the passport number if it is not cattle
        const passportNumber = isCattle ? animal.passportNumber : sanitiseAnimalId(formData.passportNumber) || "";

        const extendedAnimalIDs = [
          "pedigreeId",
          "tattoo",
          "trichId",
          "brucellosisId",
          "tsuBarcode",
          "herdDoggId",
          "uhfId",
          "alternativeId",
          "name",
        ];

        const updateFields = isCattle
          ? ["visualId", "eId", "isCastrated", "linkToMySire", "animalBreeds", ...extendedAnimalIDs]
          : ["passportNumber", "visualId", "eId", "isCastrated", "linkToMySire", "animalBreeds", ...extendedAnimalIDs];

        const result = await updateAnimal({
          variables: {
            input: {
              id: animal?.id,
              visualId: sanitiseAnimalId(formData.visualId),
              eId,
              isCastrated: formData.isCastrated,
              ...(formData.sireId ? { linkToMySire: formData.sireId } : {}),
              animalBreeds: [formData.breeds],
              passportNumber,
              updateFields,
              brucellosisId: !isMale ? sanitiseAnimalId(formData?.brucellosisId) : undefined,
              pedigreeId: sanitiseAnimalId(formData?.pedigreeId),
              tattoo: sanitiseAnimalId(formData?.tattoo),
              trichId: isMale ? sanitiseAnimalId(formData?.trichId) : undefined,
              tsuBarcode: sanitiseAnimalId(formData?.tsuBarcode),
              herdDoggId: sanitiseAnimalId(formData?.herdDoggId),
              uhfId: sanitiseAnimalId(formData?.uhfId),
              alternativeId: sanitiseAnimalId(formData?.alternativeId),
              name: sanitiseAnimalId(formData?.name),
            },
          },
          refetchQueries: [DRAFT_DELIVERY, DRAFT_DELIVERY_ANIMALS],
        });

        errors = removeNothings(result?.data?.updateAnimal?.errors || []);
      } else {
        const tags: AnimalTagAssignmentInputType[] | undefined = formData.tagIds
          ? formData.tagIds.map((tagId) => {
              return {
                isPresent: true,
                tag: tagId,
              };
            })
          : undefined;

        const result = await createAnimal({
          variables: {
            input: {
              delivery: deliveryId,
              businessUnit: businessUnitId,
              isDead: formData?.isDead || false,
              isMale: formData.animalSex === "male",
              breeds: [formData.breeds],
              dob: formData.dob,
              animalType: activeSpecies?.id || "3",
              field: formData.fieldId,
              group: formData.groupId,
              passportNumber: sanitiseAnimalId(formData.passportNumber) || "",
              visualId: sanitiseAnimalId(formData.visualId),
              eId,
              isCastrated: formData.isCastrated,
              dateMovedToFarm: formData.dateMovedToFarm,
              dam: formData.damId,
              birthWeight: formData.birthWeight || undefined,
              purchasePrice: formData.purchasePrice || undefined,
              conditions: formData.animalConditions,
              tagValues: tags,
              sourceContact: formData.sourceContactId,
              previousKeeper: formData.sourceContactId,
              // INFO: Extended animal IDs
              brucellosisId: !isMale ? sanitiseAnimalId(formData.brucellosisId) : undefined,
              trichId: isMale ? sanitiseAnimalId(formData.trichId) : undefined,
              herdDoggId: sanitiseAnimalId(formData.herdDoggId) || undefined,
              pedigreeId: sanitiseAnimalId(formData.pedigreeId) || undefined,
              tattoo: sanitiseAnimalId(formData.tattoo) || undefined,
              tsuBarcode: sanitiseAnimalId(formData.tsuBarcode) || undefined,
              uhfId: sanitiseAnimalId(formData.uhfId) || undefined,
              alternativeId: sanitiseAnimalId(formData.alternativeId) || undefined,
              name: sanitiseAnimalId(formData.name) || undefined,
            },
          },
          refetchQueries: [DRAFT_DELIVERY, DRAFT_DELIVERY_ANIMALS],
        });

        errors = result?.data?.deliverNewAnimal?.errors || [];
      }

      setIsLoadingCreateAndFinish(false);
      setIsLoadingCreateAndAnother(false);

      if (errors.length > 0) {
        showNotification({
          variant: "error",
          message: errors.map((item) => item?.message).join("; "),
        });
      } else {
        showNotification({
          variant: "success",
          message: animal ? "Animal successfully updated." : "Animal successfully created.",
        });

        if (createAnother) {
          methods.reset({
            fieldId: formData.fieldId,
            groupId: formData.groupId,
            damId: formData.damId,
            sireId: formData.sireId,
            dob: formData.dob,
            breeds: formData.breeds,
            dateMovedToFarm: formData.dateMovedToFarm,
            animalSex: formData.animalSex,
            eId: "",
            visualId: "",
            passportNumber: "",
            brucellosisId: "",
            pedigreeId: "",
            tattoo: "",
            trichId: "",
            tsuBarcode: "",
            uhfId: "",
            alternativeId: "",
            name: "",
          });

          if (herdDoggIdInputRef && herdDoggIdInputRef.current) {
            herdDoggIdInputRef.current.inputElement.value = "";
          }

          setCreateAnother(false);
        } else {
          onCreateAnimal();
        }
      }
    } catch (error: unknown) {
      setIsLoadingCreateAndFinish(false);
      setIsLoadingCreateAndAnother(false);

      showNotification({
        variant: "error",
        message: getErrorMessage(error),
      });
    }
  };

  const sexOptions: InputSelectOption[] = [
    { key: "1", label: capitaliseFirstLetter(t.male), value: "male" },
    { key: "2", label: capitaliseFirstLetter(t.female), value: "female" },
  ];

  const isLoading = loadingFields || loadingGroups || loadingBreeds || loadingSires || loadingDams;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading ? <LoadingOverlay /> : null}
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 ">
          <AnimalIdInputs
            animal={animal}
            showAdditionalAnimalIdsInitialState={Boolean(animal)}
            ref={herdDoggIdInputRef}
          />

          <div className="flex">
            <Controller
              control={control}
              name="animalSex"
              render={({ formState: { errors }, field: { value, onChange } }) => {
                return (
                  <FieldSelect
                    label={"Sex *"}
                    error={errors.animalSex?.message}
                    inputProps={{
                      placeholder: "Sex",
                      placeholderColour: "gray",
                      disabled: !!animal,
                      value,
                      options: sexOptions,
                      onChange,
                      isClearable: false,
                    }}
                  />
                );
              }}
            />
            <div>
              <label htmlFor="isCastrated" className="inline-flex items-center ml-4 mt-10">
                <input
                  id="isCastrated"
                  type="checkbox"
                  disabled={methods.watch("animalSex") === "female"}
                  {...methods.register("isCastrated")}
                  className={`${
                    methods.watch("animalSex") === "female" ? "cursor-default" : "cursor-pointer"
                  } h-4 w-4 rounded pt-2`}
                />
                <span className="pl-2 py-2 text-xs">{capitaliseFirstLetter(t.castration)}</span>
              </label>
            </div>
          </div>
          <Controller
            control={control}
            name="breeds"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label={`${capitaliseFirstLetter(t.breed)} *`}
                  error={errors.breeds?.message}
                  inputProps={{
                    placeholder: `Choose ${t.breed}`,
                    placeholderColour: "gray",
                    value: loadingBreeds ? "—" : value,
                    options: breedOptions,
                    onChange,
                    isClearable: true,
                  }}
                />
              );
            }}
          />
          <div className="flex">
            <Controller
              control={control}
              name="dob"
              render={({ formState: { errors }, field: { value, onChange } }) => {
                return (
                  <FieldDate
                    label={capitaliseFirstLetter(`${t.estimatedDob} *`)}
                    error={errors.dob?.message}
                    inputProps={{
                      name: "dob",
                      placeholderText: `Enter ${t.estimatedDob}`,
                      disabled: !!animal,
                      value,
                      onChange,
                    }}
                  />
                );
              }}
            />
            {countryIsoCodeUpperCase !== CountryISOCode.GB ? (
              <div>
                <label htmlFor="isDead" className="inline-flex items-center ml-4 mt-10 min-w-max">
                  <input
                    id="isDead"
                    type="checkbox"
                    disabled={!!animal}
                    {...methods.register("isDead")}
                    className={`${!!animal ? "cursor-default" : "cursor-pointer"} h-4 w-4 rounded pt-2`}
                  />
                  <span className="pl-2 py-2 text-xs">{capitaliseFirstLetterOfEachWord(t.deadOnArrival)}</span>
                </label>
              </div>
            ) : null}
          </div>
          <Controller
            control={control}
            name="dateMovedToFarm"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldDate
                  label={`Estimated ${t.dof} *`}
                  error={errors.dateMovedToFarm?.message}
                  inputProps={{
                    name: "dateMovedToFarm",
                    placeholderText: "Enter estimated DOF",
                    disabled: !!animal,
                    value,
                    onChange,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="sourceContactId"
            render={({ formState: { errors }, field: { value } }) => {
              return (
                <AddressBookSelect
                  label="Source Address *"
                  disabled={!!animal}
                  errorMessage={errors.sourceContactId?.message}
                  value={value || ""}
                  placeholder={animal ? animal.previousKeeper?.name : undefined}
                  onContactChange={(contact: ContactFragmentFragment | undefined) => {
                    methods.setValue("sourceContactId", contact?.id || "");
                    if (contact?.id) {
                      methods.trigger("sourceContactId");
                    }
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="tagIds"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label="Management Tags"
                  error={errors.tagIds?.message}
                  inputProps={{
                    placeholder: "No option selected",
                    placeholderColour: "gray",
                    name: "tagIds",
                    disabled: !!animal,
                    value: loadingTags ? [] : value,
                    options: tagOptions,
                    onChange,
                    isClearable: true,
                    multiple: true,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="animalConditions"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label={`${capitaliseFirstLetterOfEachWord(t.animalCondition)}`}
                  error={errors.animalConditions?.message}
                  inputProps={{
                    placeholder: "No option selected",
                    placeholderColour: "gray",
                    name: "animalConditions",
                    value: loadingAnimalConditions ? [] : value,
                    options: animalConditionOptions,
                    disabled: !!animal,
                    onChange,
                    isClearable: true,
                    multiple: true,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="fieldId"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label={`${capitaliseFirstLetter(t.location)} *`}
                  error={errors.fieldId?.message}
                  inputProps={{
                    placeholder: "No option selected",
                    placeholderColour: "gray",
                    name: "fieldId",
                    value: loadingFields ? "—" : value,
                    options: fieldOptions,
                    disabled: !!animal,
                    onChange,
                    isClearable: true,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="groupId"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label={capitaliseFirstLetter(t.group)}
                  error={errors.groupId?.message}
                  inputProps={{
                    placeholder: "No option selected",
                    placeholderColour: "gray",
                    name: "groupId",
                    value: loadingGroups ? "—" : value,
                    disabled: !!animal,
                    options: groupOptions,
                    onChange,
                    isClearable: true,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="damId"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label={capitaliseFirstLetter(t.dam)}
                  error={errors.damId?.message}
                  inputProps={{
                    placeholder: "No option selected",
                    placeholderColour: "gray",
                    disabled: !!animal,
                    onSearch: searchDam,
                    name: "damId",
                    value: loadingDams ? "—" : value,
                    options: damOptions,
                    onChange,
                    isClearable: true,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="sireId"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label={capitaliseFirstLetter(t.sire)}
                  error={errors.sireId?.message}
                  inputProps={{
                    placeholder: "No option selected",
                    placeholderColour: "gray",
                    name: "sireId",
                    value: loadingSires ? "—" : value,
                    options: sireOptions,
                    onChange,
                    isClearable: true,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="birthWeight"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldNumber
                  error={errors.birthWeight?.message}
                  label={`${capitaliseFirstLetterOfEachWord(t.birthWeight)}`}
                  inputProps={{
                    value: value || "",
                    disabled: !!animal,
                    name: "birthWeight",
                    onChange,
                    step: "0.1",
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="purchasePrice"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldNumber
                  error={errors.purchasePrice?.message}
                  label={`${capitaliseFirstLetterOfEachWord(t.purchasePrice)}`}
                  inputProps={{
                    value: value || "",
                    disabled: !!animal,
                    name: "purchasePrice",
                    onChange,
                    step: "0.01",
                  }}
                />
              );
            }}
          />
        </div>
        <div className="flex my-3 justify-end">
          <Button
            caption="Cancel"
            type="button"
            variant="hollow"
            colour="grey"
            onClick={onCancel}
            className="min-w-[90px]"
            disabled={isLoadingCreateAndFinish || isLoadingCreateAndAnother}
          />
          <Button
            caption="Save and finish"
            type="submit"
            variant="solid"
            className="ml-5 min-w-[90px]"
            disabled={isLoadingCreateAndFinish || isLoadingCreateAndAnother}
            requesting={isLoadingCreateAndFinish}
          />
          {!animal ? (
            <Button
              caption="Save and create another"
              type="submit"
              colour="green"
              variant="solid"
              className="ml-5"
              disabled={isLoadingCreateAndFinish || isLoadingCreateAndAnother}
              requesting={isLoadingCreateAndAnother}
              onClick={() => setCreateAnother(true)}
            />
          ) : null}
        </div>
      </form>
    </FormProvider>
  );
};
