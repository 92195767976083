import { VFC } from "react";
// Hooks
import { useFilteredSellerListings } from "hooks";
// Components
import { Title } from "components";

export const SellerListingsTitle: VFC = () => {
  const { loading, quantity } = useFilteredSellerListings();
  const isOriginalLoading = loading && !quantity;

  return <Title tertiary>{isOriginalLoading ? "Listings" : `${quantity} listings found`}</Title>;
};
