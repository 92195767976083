import styles from "./DeliveryStatusLabel.module.scss";
import cn from "classnames";

interface Props {
  isDeliveryLocationSet: boolean;
  animalCount: number;
  reasonId: string | undefined;
  movedAt: string | undefined;
}

export const DeliveryStatusLabel = ({ isDeliveryLocationSet, movedAt, reasonId, animalCount }: Props) => {
  const isDeliveryReadyToBeSubmitted = calculateIsDeliveryReadyForSubmission({
    isDeliveryLocationSet,
    movedAt,
    reasonId,
    animalCount,
  });

  return (
    <span
      className={cn(styles.delivery_status_label, {
        [styles["delivery_status_label--ready"]]: isDeliveryReadyToBeSubmitted,
      })}
    >
      {isDeliveryReadyToBeSubmitted ? "Ready" : "Draft"}
    </span>
  );
};

export const calculateIsDeliveryReadyForSubmission = ({
  isDeliveryLocationSet,
  movedAt,
  reasonId,
  animalCount,
}: {
  isDeliveryLocationSet: boolean;
  animalCount: number;
  reasonId: string | undefined;
  movedAt: string | undefined;
}) => {
  return reasonId && movedAt && isDeliveryLocationSet && animalCount > 0;
};
