import { useEffect, VFC } from "react";
// Libraries
import { useHistory } from "react-router-dom";
// Utils
import { MY_LISTINGS, TRADING, VIEW_LISTING_BASE } from "constants/Routes";
import { useGetMyLot } from "hooks";
// Containers
import { CreateEditListingForm } from "trading/components/containers";
// Components
import { Breadcrumbs, LoadingOverlay, Header, PageHeader, PageContent, Spacer } from "components";

export const EditListing: VFC = () => {
  const { data, loading } = useGetMyLot({});
  const history = useHistory();

  useEffect(() => {
    if (data?.myLot?.lotOffers.length) {
      history.push(`${TRADING}${VIEW_LISTING_BASE}/${data.myLot.id}`);
    }
  }, [data, history]);

  const pageTitle = "Edit a listing";

  if (loading) return <LoadingOverlay />;

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Marketplace",
              route: `${TRADING}${MY_LISTINGS}`,
            },
          ]}
          currentPath={pageTitle}
        />
        <Header backButton title={pageTitle} />
        <Spacer baselineHeight={1} />
      </PageHeader>
      <PageContent>
        <Spacer baselineHeight={3} />
        <CreateEditListingForm lot={data?.myLot} />
      </PageContent>
    </>
  );
};
