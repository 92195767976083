import React, { useState } from "react";
// Libraries
import { useMutation } from "@apollo/client";
// API
import { GetTaskInfo_farmSystemTask } from "api/Import/OtherSystems/types/GetTaskInfo";
import {
  CancelFarmSystemTask,
  CancelFarmSystemTaskVariables,
} from "api/Import/OtherSystems/types/CancelFarmSystemTask";
import {
  GetTaskInfo_farmSystemTask_createdItems,
  GetTaskInfo_farmSystemTask_producedFromTask_createdItems,
  GetTaskInfo_farmSystemTask_uncreatedItems,
  GetTaskInfo_farmSystemTask_updatedItems,
} from "api/Import/OtherSystems/types/GetTaskInfo";
import { CANCEL_IMPORT_TASK } from "api/Import/OtherSystems/mutations";
// Utils
import { IMPORT_ACTION_TYPE } from "constants/Animals";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { TABLE_IDS } from "constants/Interface";
import { getTaskIdFromLocalStorage, removeTaskIdFromLocalStorage } from "helpers/storage";
// Components
import { AnimalsTable, AnimalsWeightTable } from "components/MyLivestock";
import { Button, Flex, ProgressBar, Spacer, TablePaginationTarget, Text, Title } from "components";
// Resources
import styles from "../ImportWeightResult.module.css";
import tablePaginationStyles from "components/Common/Table/TablePagination/TablePagination.module.scss";

type AnimalsData = GetTaskInfo_farmSystemTask_createdItems | GetTaskInfo_farmSystemTask_updatedItems | null;
type WeightData =
  | GetTaskInfo_farmSystemTask_producedFromTask_createdItems
  | GetTaskInfo_farmSystemTask_createdItems
  | null;
type FailedWeightData = GetTaskInfo_farmSystemTask_uncreatedItems | null;

export interface ImportResultProps {
  changeTaskId: (task: string | number) => void;
  farmSystemTask: GetTaskInfo_farmSystemTask;
  fileName: string;
  percents: number;
  removeFile: () => void;
  isAphisIntegrationSystem?: boolean;
  isBcmsIntegrationSystem?: boolean;
}

export const ImportResult: React.FC<ImportResultProps> = ({
  changeTaskId,
  farmSystemTask,
  fileName,
  percents,
  removeFile,
  isAphisIntegrationSystem,
  isBcmsIntegrationSystem,
}) => {
  const [isShowAnimals, setIsShowAnimals] = useState<boolean>(false);

  const clearAll = (): void => {
    removeTaskIdFromLocalStorage();
    removeFile();
    changeTaskId("");
  };

  const [cancelFarmSystemTask] = useMutation<CancelFarmSystemTask, CancelFarmSystemTaskVariables>(CANCEL_IMPORT_TASK, {
    variables: {
      input: {
        farmSystemTask: Number(getTaskIdFromLocalStorage()),
      },
    },
  });
  const [isCancelLoading, setCancelLoading] = useState<boolean>(false);
  const handleCancelImport = async (): Promise<void> => {
    setCancelLoading(true);

    const { data } = await cancelFarmSystemTask();

    if (data?.cancelFarmSystemTask?.success) {
      clearAll();
    }
  };

  if (!farmSystemTask) {
    return null;
  }

  const getAnimalsDataFromTask = (): AnimalsData[] => {
    if (farmSystemTask.action === IMPORT_ACTION_TYPE.ANIMALS) {
      return [...(farmSystemTask.createdItems || []), ...(farmSystemTask.updatedItems || [])];
    }

    if (farmSystemTask.producedFromTask) {
      return [...(farmSystemTask.createdItems || [])];
    }

    return [];
  };

  const getCreatedAnimalsData = (): AnimalsData[] => {
    if (farmSystemTask.action === IMPORT_ACTION_TYPE.ANIMALS) {
      return [...(farmSystemTask.createdItems || [])];
    }

    return [];
  };

  const getUpdatedAnimalsData = (): AnimalsData[] => {
    if (farmSystemTask.action === IMPORT_ACTION_TYPE.ANIMALS) {
      return [...(farmSystemTask.updatedItems || [])];
    }

    return [];
  };

  const getWeightDataFromTask = (): WeightData[] => {
    if (farmSystemTask.action === IMPORT_ACTION_TYPE.ANIMALS) {
      if (farmSystemTask.producedFromTask) {
        return [...(farmSystemTask.producedFromTask.createdItems || [])];
      }

      return [];
    }

    return [...(farmSystemTask.createdItems || [])];
  };

  const getFailedWeightDataFromTask = (): FailedWeightData[] => [...(farmSystemTask.uncreatedItems || [])];

  const animalsData: AnimalsData[] = getAnimalsDataFromTask();
  const createdAnimalsData: AnimalsData[] = getCreatedAnimalsData();
  const updatedAnimalsData: AnimalsData[] = getUpdatedAnimalsData();
  const weightData: WeightData[] = getWeightDataFromTask();
  const failedWeightData: FailedWeightData[] = getFailedWeightDataFromTask();
  const failedWeightDataCount: number = failedWeightData.length;
  const skippedWeightDataCount: number =
    farmSystemTask.totalNumberItems - (farmSystemTask.createdItems?.length || 0) - failedWeightDataCount;

  const canShowData: boolean =
    animalsData?.length > 0 || weightData?.length > 0 || failedWeightDataCount > 0 || skippedWeightDataCount > 0;
  switch (farmSystemTask.status) {
    case "DONE":
      let successfulImportLabel = "";

      if (farmSystemTask.action === IMPORT_ACTION_TYPE.ANIMALS) {
        successfulImportLabel += `${farmSystemTask.processedNumberItems} animals imported`;
      } else {
        successfulImportLabel += `${
          failedWeightDataCount > 0 || skippedWeightDataCount > 0
            ? farmSystemTask.newNumberItems
            : farmSystemTask.processedNumberItems
        } weights imported`;
      }

      return (
        <>
          <Flex container containerAlignItems="center">
            <Flex item itemGutter>
              <span className={styles.completeTitle}>Complete</span>
            </Flex>

            <Flex item itemGutter>
              <div className={styles.successBlockInfo}>
                <div className={styles.fileNameWrapper}>
                  <span className={styles.fileName}>{fileName}</span>
                  <span
                    id={ELEMENTS_IDS.IMPORT_CLEAR_IMPORT_DATA_BUTTON}
                    className={styles.linkToStart}
                    onClick={clearAll}
                  >
                    Not the right one? Click here to update
                  </span>
                </div>
                <span className={styles.fileDataLabel}>{successfulImportLabel}</span>
              </div>
            </Flex>

            <Flex item itemGutter>
              {canShowData ? (
                <span
                  id={ELEMENTS_IDS.IMPORT_HIDE_SHOW_IMPORT_DATA_BUTTON}
                  className={styles.linkToStart}
                  onClick={(): void => setIsShowAnimals(!isShowAnimals)}
                >
                  {`${isShowAnimals ? "Hide" : "Show"} import data`}
                </span>
              ) : null}
            </Flex>
          </Flex>

          <Spacer baselineHeight={4} />

          {canShowData && isShowAnimals ? (
            <>
              {!isBcmsIntegrationSystem &&
              !isAphisIntegrationSystem &&
              failedWeightDataCount + skippedWeightDataCount > 0 ? (
                <>
                  <Flex item itemGutter>
                    <Title secondary>
                      {failedWeightDataCount + skippedWeightDataCount} weight
                      {failedWeightDataCount + skippedWeightDataCount > 1 ? "s" : ""} not imported
                    </Title>

                    {!!failedWeightDataCount ? (
                      <>
                        <Spacer baselineHeight={2} />

                        <Text>
                          {`Animal${
                            failedWeightDataCount > 1 ? "s were" : " was"
                          } not found for ${failedWeightDataCount}
                            weight record${failedWeightDataCount > 1 ? "s" : ""}.`}
                        </Text>
                      </>
                    ) : null}

                    {!!skippedWeightDataCount ? (
                      <>
                        <Spacer baselineHeight={2} />

                        <Text>
                          {skippedWeightDataCount} weight record
                          {skippedWeightDataCount > 1 ? "s were duplicates" : " was duplicate"}.
                        </Text>
                      </>
                    ) : null}
                  </Flex>

                  <Spacer baselineHeight={4} />
                </>
              ) : null}

              <Flex container containerDirection="column" item itemGrow itemGutter xs={12}>
                {!isBcmsIntegrationSystem && !isAphisIntegrationSystem && animalsData.length > 0 ? (
                  <>
                    <Title secondary>
                      {animalsData.length} animal{animalsData.length > 1 ? "s" : ""} imported
                    </Title>

                    <Spacer baselineHeight={2} />

                    <Flex
                      container
                      xs="fill"
                      containerJustifyContent="flex-end"
                      className={tablePaginationStyles.table_pagination_target}
                    >
                      <Flex item itemGutter>
                        <TablePaginationTarget tableId={TABLE_IDS.IMPORTED_ANIMALS} />
                      </Flex>
                    </Flex>

                    <Spacer baselineHeight={1} />

                    <AnimalsTable
                      // @ts-ignore
                      animals={animalsData}
                      tableId={TABLE_IDS.IMPORTED_ANIMALS}
                    />

                    <Spacer baselineHeight={5} />
                  </>
                ) : null}

                {(isAphisIntegrationSystem || isBcmsIntegrationSystem) && createdAnimalsData.length > 0 ? (
                  <>
                    <Title secondary>
                      {createdAnimalsData.length} animal{createdAnimalsData.length > 1 ? "s" : ""} created
                    </Title>

                    <Spacer baselineHeight={2} />

                    <Flex
                      className={tablePaginationStyles.table_pagination_target}
                      container
                      xs="fill"
                      containerJustifyContent="flex-end"
                    >
                      <Flex item itemGutter>
                        <TablePaginationTarget tableId={TABLE_IDS.IMPORTED_ANIMALS} />
                      </Flex>
                    </Flex>

                    <Spacer baselineHeight={1} />

                    <AnimalsTable
                      // @ts-ignore
                      animals={createdAnimalsData}
                      tableId={TABLE_IDS.IMPORTED_ANIMALS}
                    />

                    <Spacer baselineHeight={5} />
                  </>
                ) : null}

                {(isAphisIntegrationSystem || isBcmsIntegrationSystem) && updatedAnimalsData.length > 0 ? (
                  <>
                    <Title secondary>
                      {updatedAnimalsData.length} animal{updatedAnimalsData.length > 1 ? "s" : ""} updated
                    </Title>

                    <Spacer baselineHeight={2} />

                    <Flex
                      className={tablePaginationStyles.table_pagination_target}
                      container
                      xs="fill"
                      containerJustifyContent="flex-end"
                    >
                      <Flex item itemGutter>
                        <TablePaginationTarget tableId={TABLE_IDS.IMPORTED_ANIMALS} />
                      </Flex>
                    </Flex>

                    <Spacer baselineHeight={1} />

                    <AnimalsTable
                      // @ts-ignore
                      animals={updatedAnimalsData}
                      tableId={TABLE_IDS.IMPORTED_ANIMALS}
                    />

                    <Spacer baselineHeight={5} />
                  </>
                ) : null}

                {!isAphisIntegrationSystem && weightData.length > 0 ? (
                  <div className={styles.severalImportsTable}>
                    <Title secondary>
                      {weightData.length} weight record{weightData.length > 1 ? "s" : ""} updated
                    </Title>

                    <Spacer baselineHeight={2} />

                    <AnimalsWeightTable data={weightData} />
                  </div>
                ) : null}
              </Flex>
            </>
          ) : null}
        </>
      );

    case "FAILED":
      return (
        <Flex item itemGutter>
          <div className={styles.failedBlockWrapper}>
            <div className={styles.failedBlockInfo}>
              <p>{`Import failed! Please check you use correct file.`}</p>
              <div className={styles.fileNameWrapper}>
                <span className={styles.fileNameError}>{fileName}</span>
                <span id={ELEMENTS_IDS.IMPORT_TRY_AGAIN_LINK} className={styles.linkToStart} onClick={clearAll}>
                  Try again
                </span>
              </div>
            </div>
          </div>
        </Flex>
      );

    case "IN_PROGRESS":
      return (
        <Flex item itemGutter>
          <div className={styles.importBlockWrapper}>
            <div className={styles.progressWrapper}>
              <span className={styles.title}>Import processing</span>
              <ProgressBar percents={percents} />
              <span className={styles.statisticNumber}>
                {`${farmSystemTask.processedNumberItems}/${farmSystemTask.totalNumberItems} animal${
                  farmSystemTask.action === IMPORT_ACTION_TYPE.ANIMALS ? "s" : " weights"
                } imported`}
              </span>
              <div className={styles.buttonsBlock}>
                <Button
                  id={ELEMENTS_IDS.IMPORT_CANCEL_BUTTON}
                  caption="Cancel"
                  colour="grey"
                  variant="hollow"
                  disabled={isCancelLoading}
                  requesting={isCancelLoading}
                  onClick={handleCancelImport}
                />
              </div>
            </div>
          </div>
        </Flex>
      );

    case "PENDING":
    default:
      return (
        <Flex item itemGutter>
          <div className={styles.importBlockWrapper}>
            <div className={styles.progressWrapper}>
              <span className={styles.title}>Import processing</span>
              <ProgressBar percents={percents} />
              <span className={styles.statisticNumber}>Initialize...</span>
              <div className={styles.buttonsBlock}>
                <Button
                  id={ELEMENTS_IDS.IMPORT_CANCEL_BUTTON}
                  caption="Cancel"
                  colour="grey"
                  variant="hollow"
                  disabled={isCancelLoading}
                  requesting={isCancelLoading}
                  onClick={handleCancelImport}
                />
              </div>
            </div>
          </div>
        </Flex>
      );
  }
};
