// React
import React from "react";
// Libraries
import MaterialTooltip, { TooltipProps as MaterialTooltipProps } from "@material-ui/core/Tooltip";
import cn from "classnames";
// Resources
import styles from "./Tooltip.module.scss";

type TooltipProps = {
  children: React.ReactElement;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-start"
    | "top-end"
    | "top";
  title: string;
  visualIndicator?: boolean;
  maxWidth?: number | "none";
} & MaterialTooltipProps;

export const Tooltip = ({
  children,
  placement = "top",
  title,
  visualIndicator = false,
  maxWidth = "none",
  ...rest
}: TooltipProps): JSX.Element => {
  const isVertical = placement.indexOf("top") === 0 || placement.indexOf("bottom") === 0;
  const tooltipStyle = maxWidth === "none" ? {} : { maxWidth }; //

  return (
    <MaterialTooltip
      classes={{
        popper: cn(styles.tooltip, {
          [styles["tooltip--vertical"]]: isVertical,
          [styles["tooltip--horizontal"]]: !isVertical,
        }),
        tooltip: styles.tooltip__inner,
      }}
      interactive
      placement={placement}
      title={title}
      style={tooltipStyle}
      {...rest}
    >
      {visualIndicator ? (
        <span className={cn({ [styles["tooltip__visual-indicator"]]: visualIndicator })}>{children}</span>
      ) : (
        children
      )}
    </MaterialTooltip>
  );
};
Tooltip.displayName = "Tooltip";
