import { SyntheticEvent, useContext, FC } from "react";
// Libraries
import cn from "classnames";
import SVG from "react-inlinesvg";
// Utils
import { DropdownMenuContext } from "../";
// Resources
import styles from "../DropdownMenu.module.scss";

interface DropdownMenuActionProps {
  colour?: string;
  bgColour?: string;
  icon?: string;
  isActive?: boolean;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  text: string;
  closeOnClick?: boolean;
}

export const DropdownMenuAction: FC<DropdownMenuActionProps> = ({
  colour,
  bgColour,
  icon,
  isActive,
  onClick,
  text,
  closeOnClick = true,
}) => {
  const { handleClose } = useContext(DropdownMenuContext);

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>): void => {
    onClick(event);
    closeOnClick ? handleClose?.() : null;
  };

  return (
    <button
      className={cn(styles.dropdown_menu__item, { [styles["dropdown_menu__item--active"]]: isActive })}
      disabled={isActive}
      onClick={handleClick}
      style={{ color: `${colour}`, backgroundColor: `${bgColour}` }}
    >
      {icon ? (
        <span className={styles.dropdown_menu__item_icon}>
          <SVG src={icon} />
        </span>
      ) : null}

      <span className={styles.dropdown_menu__item_caption}>{text}</span>
    </button>
  );
};
