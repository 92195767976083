import { gql } from "@apollo/client";
import { singleAnimalFragment } from "api/MyLivestock/Animal/fragment";
import { errorFragment } from "api/User/fragment";

export const CREATE_GROUP = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      group {
        id
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      group {
        id
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const DELETE_GROUP_WITH_ANIMALS = gql`
  mutation DeleteGroupWithAnimals($input: DeleteGroupWithAnimalsInput!) {
    deleteGroupWithAnimals(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const ASSIGN_ANIMALS_TO_GROUP = gql`
  mutation AssignAnimalsToGroup($input: MoveAnimalsToGroupInput!) {
    moveAnimalsToGroup(input: $input) {
      success
      groups {
        id
        name
      }
      animals {
        ...singleAnimalFragment
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${singleAnimalFragment}
`;

export const MOVE_GROUPS_TO_FIELD = gql`
  mutation moveGroupsToField($input: MoveGroupsToFieldInput!) {
    moveGroupsToField(input: $input) {
      success
      groups {
        id
        name
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const CREATE_BATCH = gql`
  mutation createBatch($input: CreateBatchInput!) {
    createBatch(input: $input) {
      group {
        id
        name
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const UPDATE_BATCH = gql`
  mutation updateBatch($input: UpdateBatchInput!) {
    updateBatch(input: $input) {
      group {
        id
        name
      }
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
