import { getBUFromLocalStorage } from "helpers/storage";

import { GetFieldsListQuery, useGetFieldsListQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { InputSelectOption } from "components/Common/Field/Select";
import { useMemo } from "react";
import { getFieldName } from "helpers/myLivestock";
import { sortBy } from "lodash";

export type Fields = Array<NonNullable<NonNullable<GetFieldsListQuery["fields"]>[number]>>;

export const useGetFields = () => {
  const { data, ...rest } = useGetFieldsListQuery({
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const fields = removeNothings(data?.fields || []);

  const fieldOptions: InputSelectOption[] = useMemo(() => {
    const options = fields.map((field) => {
      return {
        value: field.id,
        label: getFieldName({ fieldName: field.name, unitName: field.location?.unitName }),
        key: field.id,
      };
    });

    return sortBy(options, [(option): string => option.label]);
  }, [fields]);

  return {
    fields,
    fieldOptions,
    data,
    ...rest,
  };
};
