import { useQuery } from "@apollo/client";
import { getBUFromLocalStorage } from "helpers/storage";
import { GET_DELIVERY_ADDRESSES } from "trading/api/queries";
import { deliveryAddresses, deliveryAddressesVariables } from "trading/api/types/deliveryAddresses";

export const useGetDeliveryAddresses = () => {
  const { data, ...rest } = useQuery<deliveryAddresses, deliveryAddressesVariables>(GET_DELIVERY_ADDRESSES, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  return {
    data,
    ...rest,
  };
};
