import { useMemo } from "react";
import { useQuery } from "@apollo/client";
// API
import { GET_ANIMAL_OFFSPRING } from "api/MyLivestock/Animal/queries";
// TYPES
import {
  GetAnimalOffspring,
  GetAnimalOffspringVariables,
  GetAnimalOffspring_animalOffspring_summary,
  GetAnimalOffspring_animalOffspring_animals,
} from "api/MyLivestock/Animal/types/GetAnimalOffspring";
import { ApolloError } from "@apollo/client";
// hooks
import { useGetCurrentBusinessUnit } from "hooks";

interface UseAnimalOffspringProps {
  offFarmAnimals: (GetAnimalOffspring_animalOffspring_animals | null)[];
  onFarmAnimals: (GetAnimalOffspring_animalOffspring_animals | null)[];
  error: ApolloError | null;
  loading: boolean;
  summary: GetAnimalOffspring_animalOffspring_summary | null;
}

export const useAnimalOffspring = (animalId: string): UseAnimalOffspringProps => {
  const { id: businessUnitId } = useGetCurrentBusinessUnit();

  const {
    data,
    error = null,
    loading,
  } = useQuery<GetAnimalOffspring, GetAnimalOffspringVariables>(GET_ANIMAL_OFFSPRING, {
    variables: {
      animalId,
      businessUnitId: +businessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const animals = data?.animalOffspring?.animals || [];
  const summary = data?.animalOffspring?.summary || null;
  const offFarmAnimals = useMemo(() => animals.filter((animal) => businessUnitId !== animal?.ownerId), [data]);
  const onFarmAnimals = useMemo(() => animals.filter((animal) => businessUnitId === animal?.ownerId), [data]);

  return {
    offFarmAnimals,
    onFarmAnimals,
    error,
    loading,
    summary,
  };
};
