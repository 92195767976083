// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
// Components
import AnimalChartBlock from "./AnimalChartBlock";
import AnimalActivitiesBlock from "./AnimalActivitiesBlock";
import { LoadingOverlay, Spacer } from "components";
// Resources
import styles from "./AnimalChartHistoryBlock.module.scss";
import { GET_ANIMAL_HISTORY_BY_ID_NEW } from "api/MyLivestock/Activity/queries";
import {
  GetActivityLogNew,
  GetActivityLogNew_activities,
  GetActivityLogNewVariables,
} from "api/MyLivestock/Activity/types/GetActivityLogNew";
import { WEIGHT_ACTIVITY_SLUG } from "constants/Animals";

const AnimalChartHistoryBlock: React.FC = () => {
  const { id } = useParams<LivestockAnimalPageParams>();

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const handleSelectFilter = (filter: string): void => {
    const filtersList: string[] = selectedFilters.includes(filter)
      ? selectedFilters.filter((el) => el !== filter)
      : [...selectedFilters, filter];
    setSelectedFilters(filtersList);
  };

  const handleShowAll = (): void => {
    setSelectedFilters([]);
  };

  const activityTemplateSlugs: string[] = selectedFilters.filter((item) => item === WEIGHT_ACTIVITY_SLUG);
  const activityTypeSlugs: string[] = selectedFilters.filter((item) => item !== WEIGHT_ACTIVITY_SLUG);

  const variablesToGetAnimals = {
    animal: id,
    after: "",
    activityTemplateSlugs,
    activityTypeSlugs,
    isChartable: true,
  };

  const { data, loading, refetch } = useQuery<GetActivityLogNew, GetActivityLogNewVariables>(
    GET_ANIMAL_HISTORY_BY_ID_NEW,
    {
      variables: variablesToGetAnimals,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    },
  );

  useEffect(() => {
    refetch(variablesToGetAnimals);
  }, [selectedFilters]);

  const activities = data?.activities as GetActivityLogNew_activities;

  return (
    <>
      {loading ? (
        <LoadingOverlay customStyles={styles.loadingOverlay} />
      ) : (
        <>
          <AnimalChartBlock />

          <Spacer baselineHeight={3} />

          <AnimalActivitiesBlock
            data={activities}
            selectedFilters={selectedFilters}
            onSelectFilter={handleSelectFilter}
            onShowAll={handleShowAll}
          />
        </>
      )}
    </>
  );
};

export default AnimalChartHistoryBlock;
