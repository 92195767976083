// Libraries
import { useQuery } from "@apollo/client";
// API
import { GetDistributionLists, GetDistributionListsVariables } from "trading/api/types/GetDistributionLists";
import { GET_DISTRIBUTION_LISTS } from "trading/api/queries";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";

export const useGetDistributionLists = () =>
  useQuery<GetDistributionLists, GetDistributionListsVariables>(GET_DISTRIBUTION_LISTS, {
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
