import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IMedicineState {
  showArchived: boolean;
  showExpired: boolean;
  showEmpty: boolean;
}

export const initialMedicineState: IMedicineState = {
  showArchived: false,
  showExpired: false,
  showEmpty: false,
};

export const medicinesSlice = createSlice({
  name: "medicines",
  initialState: initialMedicineState,
  reducers: {
    setArchivedFilter: (state, action: PayloadAction<IMedicineState["showArchived"]>) => {
      state.showArchived = action.payload;
    },
    setExpiredFilter: (state, action: PayloadAction<IMedicineState["showExpired"]>) => {
      state.showExpired = action.payload;
    },
    setEmptyFilter: (state, action: PayloadAction<IMedicineState["showEmpty"]>) => {
      state.showEmpty = action.payload;
    },
  },
});

export const { setArchivedFilter, setExpiredFilter, setEmptyFilter } = medicinesSlice.actions;
export const medicinesReducer = medicinesSlice.reducer;
