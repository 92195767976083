import React, { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Column } from "react-table";
// Helpers
import { getBUFromLocalStorage } from "helpers/storage";
import { transactionColumnToKey, transactionTypeKeyToLabel } from "helpers/regulatory";
// API
import { GET_REGULATORY_TRANSACTION_APPROVAL_SUMMARY } from "api/Regulatory/queries";
// Components
import { Button, LoadingOverlay, Table, Tooltip } from "components";
import { TableHeaderCaptions } from "components/Common/Table/TableHeaderCaptions";
// Types
import {
  RegulatoryTransactionApprovalSummary,
  RegulatoryTransactionApprovalSummaryVariables,
  RegulatoryTransactionApprovalSummary_regulatoryTransactionApprovalSummary as TransactionApproval,
} from "api/Regulatory/types/RegulatoryTransactionApprovalSummary";
import { SelectedCellProps, TransactionType, ColumnsType } from "components/Regulatory/Regulatory";

export const SummaryTable: React.FC<SelectedCellProps> = ({ selectedCell: { refreshId }, onSetSelectedCell }) => {
  const { data, loading, refetch } = useQuery<
    RegulatoryTransactionApprovalSummary,
    RegulatoryTransactionApprovalSummaryVariables
  >(GET_REGULATORY_TRANSACTION_APPROVAL_SUMMARY, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (refreshId) {
      refetch();
    }
  }, [refreshId, refetch]);

  const tableData = (data?.regulatoryTransactionApprovalSummary as TransactionApproval[]) || [];

  const handleCellClick = (type: ColumnsType, rowData: TransactionApproval): void => {
    onSetSelectedCell((prevState) => ({
      column: type,
      refreshId: type === "inProgress" ? Math.random() : prevState.refreshId,
      statuses: transactionColumnToKey(type),
      transactionType: (rowData.type || "") as TransactionType,
    }));
  };

  const renderCellTotal = (type: string, rowData?: TransactionApproval): JSX.Element | null => {
    if (!type || !rowData) return null;

    return !rowData[type] ? null : (
      <Button caption={rowData[type]} onClick={(): void => handleCellClick(type as ColumnsType, rowData)} />
    );
  };

  const renderTooltipHeader = (type: ColumnsType): JSX.Element => {
    let tooltipTitle = "";
    let headerLine1 = "";
    let headerLine2 = "";

    switch (type) {
      case "inProgress":
        tooltipTitle = `These approved movements have been sent to the regulator but are still being processed. While they are still being processed, movements may remain in a ‘Pending’ status for several days`;
        headerLine1 = "Pending regulator";
        break;

      case "pending":
        tooltipTitle = `These movements have not been sent to the regulator yet. They will be sent once you complete the ‘Approve and Send’ action for any of these movements.’`;
        headerLine1 = "Ready to approve";
        break;

      case "successful":
        tooltipTitle = `These movements have been successfully processed by the regulator. It is worth noting 
          that there can be a delay of up to 36 hours before any successfully processed 
          movements appear in the regulator portal.`;
        headerLine1 = "Successful";
        headerLine2 = "past 60 days";
        break;

      case "failed":
        tooltipTitle = `These are movements that the regulator was unable to successfully process. The 
          specific reason is shown in the Error column for these movements.`;
        headerLine1 = "Problems";
        break;
    }

    return (
      <Tooltip title={tooltipTitle} visualIndicator>
        <TableHeaderCaptions title={headerLine1} subtitle={headerLine2} />
      </Tooltip>
    );
  };

  const tableId = "regulatorySummaryTable";
  const memoizedData = useMemo(() => tableData, [tableData]);
  //@ts-expect-error
  const columns: Column<TransactionApproval>[] = useMemo(
    () => [
      {
        id: "type",
        Header: "Type",
        accessor: "type",
        Cell: ({ row }): string => transactionTypeKeyToLabel(row.values.type),
        disableSortBy: true,
        minWidth: 100,
        width: 100,
      },
      {
        id: "pending",
        Header: renderTooltipHeader("pending" as ColumnsType),
        accessor: "pending",
        Cell: ({ column, row }): JSX.Element | null => renderCellTotal(column.id, row?.values as TransactionApproval),
        disableSortBy: true,
        minWidth: 100,
        width: 100,
      },
      {
        id: "inProgress",
        Header: renderTooltipHeader("inProgress" as ColumnsType),
        accessor: "inProgress",
        Cell: ({ column, row }): JSX.Element | null => renderCellTotal(column.id, row?.values as TransactionApproval),
        disableSortBy: true,
        minWidth: 100,
        width: 100,
      },
      {
        id: "failed",
        Header: renderTooltipHeader("failed" as ColumnsType),
        accessor: "failed",
        Cell: ({ column, row }): JSX.Element | null => renderCellTotal(column.id, row?.values as TransactionApproval),
        disableSortBy: true,
        minWidth: 100,
        width: 100,
      },
      {
        id: "successful",
        Header: renderTooltipHeader("successful" as ColumnsType),
        accessor: "successful",
        Cell: ({ column, row }): JSX.Element | null => renderCellTotal(column.id, row?.values as TransactionApproval),
        disableSortBy: true,
        minWidth: 100,
        width: 100,
      },
    ],
    [],
  );

  if (loading) return <LoadingOverlay />;

  return <Table<TransactionApproval> columns={columns} data={memoizedData} tableId={tableId} />;
};
