import React from "react";
// Libraries
import cn from "classnames";
//Components
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Paper, Spacer, CheckBox } from "components";
// styles
import styles from "./styles.module.scss";

export type PictureManagerImage = {
  id: string;
  photo: string | ArrayBuffer;
  file?: File | null;
  isNew: boolean;
  isSelected: boolean;
  position: number;
};

type Props = {
  photos: PictureManagerImage[];
  isSelected: boolean;
  changeSelection?: (id: string) => void;
  changePhotos?: (a: PictureManagerImage[]) => void;
};

const SortableItem = SortableElement(({ item, isSelected, changeSelection }) => {
  const handleChangeSelection = (): void => {
    changeSelection && changeSelection(item.id);
  };
  return (
    <GridListTile key={item.id}>
      <img src={item.photo.toString()} alt="Image" />
      <GridListTileBar
        classes={{
          root: cn(styles.titleBar, { [styles["titleBar--isSelectable"]]: isSelected }),
          title: styles.title,
          actionIcon: styles.checkboxContainer,
        }}
        actionIcon={isSelected ? <CheckBox checked={item.isSelected} onChange={handleChangeSelection} /> : null}
      />
    </GridListTile>
  );
});

const SortableList = SortableContainer(({ items, changeSelection, isSelected, isDisabled }) => {
  return (
    <GridList className={styles.gridList} cols={5}>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id}`}
          //@ts-expect-error
          item={item}
          index={index}
          // @ts-ignore
          value={item.id}
          changeSelection={changeSelection}
          isSelected={isSelected}
          disabled={isDisabled}
        />
      ))}
    </GridList>
  );
});

export const PhotoManager: React.FC<Props> = ({ photos, isSelected, changeSelection, changePhotos }) => {
  const changeSorting = ({ oldIndex, newIndex }): void => {
    if (changePhotos) {
      const changedPhotos = arrayMove(photos, oldIndex, newIndex).map((item, index) => ({ ...item, position: index }));
      changePhotos(changedPhotos);
    }
  };
  return (
    <Paper>
      <Spacer baselineHeight={1} />

      <SortableList
        //@ts-expect-error
        items={photos}
        isSelected={isSelected}
        changeSelection={changeSelection}
        onSortEnd={changeSorting}
        axis="x"
        isDisabled={!changePhotos}
        helperClass={styles.sortableHelper}
      />

      <Spacer baselineHeight={1} />
    </Paper>
  );
};
