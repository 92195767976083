import { useHistory } from "react-router-dom";
import { FINANCIAL_TRANSACTIONS } from "constants/Routes";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";
import { useTransactionActions } from "financial/components/TransactionForm/hooks/useTransactionActions";
import { TransactionValidationSchema as S } from "../validation";

export const useHandleFormSubmission = (
  setShouldDeleteTransactionOnRouteChange: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { handleError, handleMutationResult } = useHandleMutationResult();
  const history = useHistory();
  const { handleUpdateFinancialTransaction, isUpdatingTransaction: isSubmittingTransaction } = useTransactionActions();

  const onSubmit = async (values: S) => {
    setShouldDeleteTransactionOnRouteChange(false);

    try {
      const result = await handleUpdateFinancialTransaction(values);
      const errors = result.data?.updateFinancialTransaction?.errors || [];

      handleMutationResult({
        successMessage: "Financial transaction successfully saved.",
        errors,
        onSuccess: () => {
          history.push(`${FINANCIAL_TRANSACTIONS}`);
        },
      });
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return { onSubmit, isSubmittingTransaction };
};
