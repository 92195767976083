import React from "react";
// Libraries
import SVG from "react-inlinesvg";
// Resources
import logoSVG from "assets/img/brand/logo.svg";
import styles from "./Spinner.module.scss";

export const Spinner: React.FC<{
  style?: React.CSSProperties | undefined;
}> = (style) => (
  <figure className={styles.spinner} {...style}>
    <SVG src={logoSVG} />
  </figure>
);
