import { ReactNode } from "react";
// Types
import { GetAnimalOffspring_animalOffspring_animals } from "api/MyLivestock/Animal/types/GetAnimalOffspring";
// Constants
import {
  ANIMAL_BIRTH_ABNORMALITIES,
  ANIMAL_BIRTH_DIFFICULTY,
  ANIMAL_BIRTH_PRESENTATION,
  ANIMAL_BIRTH_SIZE,
  ANIMAL_BIRTH_VIGOUR,
} from "constants/Animals";
// Components
import { Pill } from "components";
// Utils
import { getFormattedNumber } from "helpers/general";
import { GetFormatDate } from "hooks/useFormatDate";

export const getOffspringColumns = (
  weightUnits: string,
  getFormatDate: GetFormatDate,
  callback?: (animal: GetAnimalOffspring_animalOffspring_animals) => void,
) => [
  {
    id: "passportNumber",
    Header: "Animal ID",
    accessor: "passportNumber",
    onCellClick: callback,
    sticky: "left",
    minWidth: 150,
    width: 150,
  },
  {
    id: "visualId",
    Header: "VID",
    accessor: ({ visualId }): string => visualId || "\u2014",
    onCellClick: callback,
    minWidth: 150,
    width: 150,
  },
  {
    id: "dob",
    Header: "DoB",
    accessor: "dob",
    onCellClick: callback,
    Cell: ({ row }): ReactNode => (row.values["dob"] ? getFormatDate(row.values["dob"]) : "\u2014"),
    minWidth: 120,
    width: 120,
  },
  {
    id: "sex",
    Header: "Sex",
    accessor: ({ isMale }): string => (isMale ? "M" : "F"),
    onCellClick: callback,
    minWidth: 90,
    width: 90,
  },
  {
    id: "sireID",
    Header: "Sire ID",
    accessor: ({ sirePassportNumber }): string => sirePassportNumber || "\u2014",
    onCellClick: callback,
    minWidth: 150,
    width: 150,
  },
  {
    id: "birthSize",
    Header: "Birth size",
    accessor: ({ birthInformation }): string =>
      birthInformation?.birthSize ? ANIMAL_BIRTH_SIZE[birthInformation.birthSize] : "\u2014",
    onCellClick: callback,
    minWidth: 150,
    width: 150,
  },
  {
    id: "birthWeight",
    Header: "Birth weight",
    accessor: ({ birthInformation }): number | string => birthInformation?.birthWeight || "",
    onCellClick: callback,
    Cell: function renderBirthWeight({ row }): ReactNode {
      return row.values["birthWeight"] ? (
        <Pill caption={`${getFormattedNumber(row.values["birthWeight"])} ${weightUnits}`} colour="grey" />
      ) : (
        "\u2014"
      );
    },
    minWidth: 120,
    width: 120,
  },
  {
    id: "birthDifficulty",
    Header: "Birthing difficulty",
    accessor: ({ birthInformation }): string =>
      birthInformation?.birthingDifficulty ? ANIMAL_BIRTH_DIFFICULTY[birthInformation.birthingDifficulty] : "\u2014",
    onCellClick: callback,
    minWidth: 150,
  },
  {
    id: "birthAbnormalities",
    Header: "Birth abnormalities",
    accessor: ({ birthInformation }): string =>
      birthInformation?.birthAbnormalities ? ANIMAL_BIRTH_ABNORMALITIES[birthInformation.birthAbnormalities] : "\u2014",
    onCellClick: callback,
    minWidth: 150,
    width: 150,
  },
  {
    id: "birthPresentation",
    Header: "Birth presentation",
    accessor: ({ birthInformation }): string =>
      birthInformation?.birthPresentation ? ANIMAL_BIRTH_PRESENTATION[birthInformation.birthPresentation] : "\u2014",
    onCellClick: callback,
    minWidth: 150,
    width: 150,
  },
  {
    id: "birthVigor",
    Header: "Offspring vigour",
    accessor: ({ birthInformation }): string =>
      birthInformation?.birthVigor ? ANIMAL_BIRTH_VIGOUR[birthInformation.birthVigor] : "\u2014",
    onCellClick: callback,
    minWidth: 150,
    width: 400,
  },
];
