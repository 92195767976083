// Libraries
import React from "react";
import { useHistory } from "react-router";
// Utils
import { IMPORT, SUPPORTED_SYSTEM_IMPORT } from "constants/Routes";
// Components
import { Button, Flex, Spacer, Text, Title } from "components";

export const SuccessStep: React.FC = () => {
  const history = useHistory();
  const handleUploadAnimalsRedirect = (): void => {
    history.push(`${IMPORT}${SUPPORTED_SYSTEM_IMPORT}`);
  };
  return (
    <Flex container xs={7} containerJustifyContent="center">
      <Title theme="breedr">Success</Title>
      <Spacer baselineHeight={2} />
      <Flex item itemGutter xs={6}>
        <Text>You should have received a confirmation email from BCMS.</Text>
        <Spacer />
        <Text>Your login details for CTS Online may take up to 2 weeks to receive.</Text>
      </Flex>
      <Spacer baselineHeight={2} />
      <Flex item itemGutter xs={6}>
        <Text>
          <strong>Reap the benefits today! Happy Farming!</strong>
        </Text>
        <Spacer />
        <Text>Simply upload your animals manually and you’re ready to start using the Breedr app.</Text>
      </Flex>
      <Spacer baselineHeight={4} />
      <Flex item itemGutter>
        <Button caption="Upload My Animals" onClick={handleUploadAnimalsRedirect} />
      </Flex>
    </Flex>
  );
};
