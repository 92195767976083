import { FC } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  FORGOT_PASSWORD,
  INVITE,
  RESET_PASSWORD,
  NEW_PASSWORD,
  TRADING,
  SSO,
  BU_ID,
  DASHBOARD,
  ANIMALS,
  SUBSCRIPTION_DETAILS,
  AUTH,
  PAYMENT_SUCCESS,
  CHAT,
} from "constants/Routes";
import SignIn from "pages/Auth/SignIn";
import ForgotPass from "pages/Auth/ForgotPass";
import NewPassword from "pages/Auth/ForgotPass/NewPassword";
import ResetPasswordRequestSent from "pages/Auth/ForgotPass/ResetPasswordRequestSent";
import { Dashboard } from "pages/Dashboard";
import { Subscription } from "subscriptions/pages/Subscription";
import InvitedUserScreen from "pages/Settings/UserManagement/InvitedUserScreen";
import { BU_TYPES } from "trading/constants";
import GoogleTagManagerWatcher from "App/GoogleTagManagerWatcher";
import FathomWatcher from "App/FathomWatcher";
import { BusinessUnitHandler } from "businessUnit/components/BusinessUnitHandler";
import { IntegrationAuth } from "components/Auth/IntegrationAuth";
import { PaymentSuccess } from "subscriptions/pages/PaymentSuccess";
import { HubSpotWatcher } from "./HubSpotWatcher";
import { useHasFeature } from "hooks/useHasFeature";
import { PrivateRoute } from "./PrivateRoute";
import { getBUTypeFromLocalStorage } from "helpers/storage";
import { Chat } from "pages/Chat";

export const Routes: FC = () => {
  const selectedBUType = getBUTypeFromLocalStorage();

  const isShowHomePage = useHasFeature("SHOW_HOME_PAGE");

  const isProcessor: boolean = selectedBUType === BU_TYPES.processor;
  const pathToRedirect: string = isProcessor ? TRADING : ANIMALS;

  return (
    <Router>
      <GoogleTagManagerWatcher />
      <BusinessUnitHandler />
      <FathomWatcher />
      <HubSpotWatcher />
      <Switch>
        <Route exact path={`${LOGIN}${SSO}?`} component={SignIn} />
        <Route exact path={LOGIN} component={SignIn} />
        {/*
            We want users to only sign up through mobile. We have
            therefore removed the web sign-up form.

            I have left the sign-up code commented out for now
            in case we want to reimplement it at a later point.
            I think web sign up might be useful in the future
            considering we only can do payments through Stripe
            on the web app.

            Ben Busch 07-12-2022.

            <Route exact path={SIGN_UP} component={SignUp} />
            <Route exact path={SIGN_UP_DETAILS} component={SignUpDetails} />
            <Route exact path={SIGN_UP_SUCCESS} component={SignUpSuccess} />
            <Route exact path={SIGN_UP_FAILURE} component={SignUpFailure} />
            */}
        <Route exact path={FORGOT_PASSWORD} component={ForgotPass} />
        <Route exact path={INVITE} component={InvitedUserScreen} />
        <Route exact path={RESET_PASSWORD} component={ResetPasswordRequestSent} />
        <Route exact path={`${NEW_PASSWORD}/:token`} component={NewPassword} />
        <PrivateRoute path={SUBSCRIPTION_DETAILS}>
          <Subscription />
        </PrivateRoute>
        <PrivateRoute path={`${BU_ID}${PAYMENT_SUCCESS}`}>
          <PaymentSuccess />
        </PrivateRoute>
        <PrivateRoute path={`${BU_ID}/system/:sectionName/:subSectionName?`}>
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path={AUTH}>
          <IntegrationAuth />
        </PrivateRoute>
        <PrivateRoute path={CHAT}>
          <Chat />
        </PrivateRoute>
        <Redirect to={isShowHomePage ? DASHBOARD : pathToRedirect} />
      </Switch>
    </Router>
  );
};
