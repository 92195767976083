// Libraries
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { DateTime, DateTimeFormatOptions, LocaleOptions } from "luxon";
// Utils
import { getFeaturesListFromLocalStorage } from "helpers/storage";
// API
import { GetBusinessUnits_businessUnits_unitsOfMeasurement } from "api/BusinessUnits/types/GetBusinessUnits";
import { GetBusinessUnitUsers_businessUnitUsers } from "api/BusinessUnits/types/GetBusinessUnitUsers";
import { UnitOfMeasurementBusinessUnitMeasurementType } from "api/graphql-global-types";
// Types
import { INTERNATIONAL_CURRENCY_SUBUNITS_NAMES } from "constants/International";
import { PASSWORD_VALIDATION_RULES } from "components/Auth/SignUp/SignUpInput";
import { capitaliseFirstLetter } from "helpers/translations/src";
import gPhoneNumber, { PhoneNumberFormat } from "google-libphonenumber";
import { UserInfoFragmentFragment } from "generated/graphql";

export const validationSchema = Yup.object().shape({
  email: Yup.string().email("E-mail is not valid!").required("E-mail is required!"),
  password: Yup.string().required("Password is required!"),
});

export const validationSchemaPassword = Yup.object().shape({
  password: Yup.string().required("Password is required!"),
});

export const validationSchemaEmail = Yup.object().shape({
  email: Yup.string().email("E-mail is not valid!").required("E-mail is required!"),
});

export const validationSchemaReset = Yup.object().shape({
  email: Yup.string().email("E-mail is not valid!").required("E-mail is required!"),
});

export const maybeCropString = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const commonPasswordValidation = Yup.string()
  .trim()
  .matches(PASSWORD_VALIDATION_RULES.LENGTH, "Password should be at least 8 characters long")
  .matches(PASSWORD_VALIDATION_RULES.LETTER, "Password should include at least 1 letter [A-Z, a-z]")
  .matches(PASSWORD_VALIDATION_RULES.NUMBER, "Password should include at least 1 digit [0-9]");

export const validationSchemaNewPassword = Yup.object().shape({
  newPassword: commonPasswordValidation.required("New password is required!"),
});

export const validationSchemaUpdatePassword = Yup.object().shape({
  prePassword: Yup.string().required("Old password is required!"),
  password: commonPasswordValidation.required("New password is required!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required!"),
});

export const validationSchemaSetPassword = Yup.object().shape({
  password: commonPasswordValidation.required("Password is required!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required!"),
});

export const validationSchemaDeliveryAddressRequest = Yup.object().shape({
  unitName: Yup.string().required("Unit name is required!"),
  addressLine1: Yup.string().required("Address line 1 is required!"),
  city: Yup.string().required("City is required!"),
  country: Yup.string().required("Region is required!"),
  postcode: Yup.string().required("Required field!"),
});

export const validationSchemaInviteUser = Yup.object().shape({
  firstName: Yup.string().required("First name is required!"),
  lastName: Yup.string().required("Last name is required!"),
  email: Yup.string().email("E-mail is not valid!").required("E-mail is required!"),
  roleId: Yup.string().required("Role is required!"),
  businessUnitName: Yup.string().required("Access to is required!"),
});

export const validationSchemaConfirmDelete = Yup.object().shape({
  confirmation: Yup.string()
    .required("Confirmation is required!")
    .matches(/(delete|DELETE)/, "You must type DELETE!"),
});

type BusinessUnitUsers = (GetBusinessUnitUsers_businessUnitUsers | null)[] | null | undefined;
type UserInfo = UserInfoFragmentFragment | null | undefined;

export const getBusinessUnitUserRole = (businessUnitUsers: BusinessUnitUsers, userInfo: UserInfo): string | null => {
  if (businessUnitUsers && userInfo) {
    const businessUnitUser = businessUnitUsers.find((bu) => bu?.user?.id === userInfo?.id);
    return businessUnitUser ? businessUnitUser?.role?.name : null;
  }

  return null;
};
export const getBusinessUnitUser = (
  businessUnitUsers: GetBusinessUnitUsers_businessUnitUsers[],
  user: UserInfoFragmentFragment | null,
): GetBusinessUnitUsers_businessUnitUsers | null => {
  if (businessUnitUsers && user) {
    return businessUnitUsers.find((buu) => buu.user.id === user.id) ?? null;
  }

  return null;
};

export const getFormattedJSDate = (
  date: Date | undefined,
  formatOpts?: DateTimeFormatOptions,
  opts?: LocaleOptions,
): string => {
  if (!date) {
    return "\u2014";
  }

  const luxonDate = DateTime.fromJSDate(date);

  if (!luxonDate.isValid) {
    return "\u2014";
  }

  return luxonDate.toLocaleString(formatOpts, opts);
};

export const getFormattedDate = (
  date: Date | string | undefined,
  formatOpts?: DateTimeFormatOptions,
  opts?: LocaleOptions,
): string => {
  if (!date) {
    return "\u2014";
  }

  const luxonDate = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);

  if (!luxonDate.isValid) {
    return "\u2014";
  }

  return luxonDate.toLocaleString(formatOpts, opts);
};

export const getFormattedNumber = (number: number): string => {
  return number.toLocaleString();
};

export const getCurrencyCode = (code: string | undefined): string => {
  return code || "GBP";
};

export const getCurrencySubUnitName = (code: string | undefined): string => {
  return !code ? "pence" : INTERNATIONAL_CURRENCY_SUBUNITS_NAMES[code] || "pence";
};

export const getWeightUnitName = (
  unitsOfMeasurement: (GetBusinessUnits_businessUnits_unitsOfMeasurement | null)[] | null,
): string => {
  return (
    (unitsOfMeasurement || [])
      .find((item) => item?.measurementType === UnitOfMeasurementBusinessUnitMeasurementType.WEIGHT_LARGE)
      ?.code?.toLowerCase() || "kg"
  );
};

export const getDistanceUnitName = (
  unitsOfMeasurement: (GetBusinessUnits_businessUnits_unitsOfMeasurement | null)[] | null,
): string => {
  return (
    (unitsOfMeasurement || [])
      .find((item) => item?.measurementType === UnitOfMeasurementBusinessUnitMeasurementType.DISTANCE_LARGE)
      ?.code?.toLowerCase() || ""
  );
};

export const getFormattedPrice = (price?: number, currency?: string): string => {
  if (price == null) {
    return "";
  }
  const options = currency ? { style: "currency", currency, maximumFractionDigits: 2 } : undefined;
  return new Intl.NumberFormat("en", options).format(price);
};

type HandleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
type Event = React.KeyboardEvent<HTMLElement>;

export const handleEnterPress = (event: Event, handleSubmit: HandleSubmit): void => {
  if (event.key === "Enter") {
    handleSubmit();
  }
};

export const handleErrorCheck = (error): string => !isEmpty(error) && error;

export const hasFeature = (
  key: string,
  featuresListFromContext?: (string | null | undefined)[] | null | undefined,
): boolean => {
  const featuresList = featuresListFromContext || getFeaturesListFromLocalStorage() || [];
  return featuresList.indexOf(key) > -1;
};

export const getDaysRelativeToToday = (date: Date): number => {
  const parsedDate = DateTime.fromJSDate(date);
  const today = DateTime.local();
  const differenceInDays = parsedDate.diff(today, "days").get("days");

  return differenceInDays;
};

export function notNothing<T>(maybeNull: T | null | undefined): maybeNull is T {
  return maybeNull != null;
}

export function removeNothings<T>(arr: Array<T | null | undefined>): Array<T> {
  return arr.filter(notNothing);
}

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return capitaliseFirstLetter(error.message) || "";
  return String(error);
};

export const parseJsonSafely = (jsonString: string): Record<string, unknown> => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return {};
  }
};

export const stringifyJsonSafely = (data: unknown): string => {
  try {
    return JSON.stringify(data);
  } catch (error) {
    console.error("Error stringifying JSON:", error);
    return "";
  }
};

export const splitStrings = (input: string) => {
  return input.replace(/([A-Z])/g, " $1").trim();
};

export const formatPhoneNumber = ({
  phoneNumber,
  countryCode,
  format = PhoneNumberFormat.E164,
}: {
  phoneNumber: string | undefined;
  countryCode: string | undefined;
  format?: PhoneNumberFormat;
}) => {
  try {
    const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

    if (!phoneNumber || !countryCode) {
      return phoneNumber;
    }
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);

    if (!phoneUtil.isValidNumber(parsedNumber) || !phoneUtil.isPossibleNumber(parsedNumber)) {
      return phoneNumber;
    }

    return phoneUtil.format(parsedNumber, format);
  } catch (error: unknown) {
    console.error(error);
    // INFO: Return the phone number input unformatted
    // in case we were unable to parse and format it...
    return phoneNumber;
  }
};

export const convertToCamelCase = (str: string | undefined) => {
  if (!str) {
    return "";
  }
  const cleanedStr = str.replace(/[^a-zA-Z0-9]/g, " ");
  const words = cleanedStr.split(" ");
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  const camelCaseStr = camelCaseWords.join("");

  return camelCaseStr;
};

export const hyphenateString = (str: string | undefined) => {
  if (!str) {
    return "";
  }
  const cleanedStr = str.replace(/[^a-zA-Z0-9\s]/g, "");
  const hyphenatedStr = cleanedStr.replace(/\s+/g, "-");
  const lowercaseStr = hyphenatedStr.toLowerCase();

  return lowercaseStr;
};

function compareTwoStrings(first: string, second: string) {
  first = first.replace(/\s+/g, "");
  second = second.replace(/\s+/g, "");

  if (first === second) return 1; // identical or empty
  if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

  const firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  const result = (2.0 * intersectionSize) / (first.length + second.length - 2);
  return result;
}

export const fuzzyStringMatch = (needle: string, haystack: string[] | undefined, rating = 0.5) => {
  let bestMatch = { rating: 0, target: "" };
  haystack?.forEach((label) => {
    const match = compareTwoStrings(needle, label);
    if (match > bestMatch.rating) {
      bestMatch = { rating: match, target: label };
    }
  });

  return bestMatch.rating > rating ? bestMatch.target : null;
};

export function prop<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export function isValidNumberOption(num: number, options: number[]): num is typeof options[number] {
  return options.includes(num);
}
