import { useGetBusinessUnitSettings, useHasFeature } from "hooks";

const DATE_SWITCHED_TO_SERVER_PAGINATED_TABLE = new Date("2023-08-18");

export const useShouldUseServerPaginatedAnimalsTable = () => {
  const isServerPaginatedFoggleActiveForBU = useHasFeature("SERVER_SIDE_PAGINATION_FOR_ANIMALS_LIST");
  const businessUnitSettings = useGetBusinessUnitSettings();

  const dateJoinedBreedr = businessUnitSettings?.data?.businessUnit?.dateJoined
    ? new Date(businessUnitSettings.data.businessUnit.dateJoined)
    : null;

  const shouldUseServerPaginatedAnimalsTable =
    (dateJoinedBreedr && dateJoinedBreedr > DATE_SWITCHED_TO_SERVER_PAGINATED_TABLE) ||
    isServerPaginatedFoggleActiveForBU;

  return { shouldUseServerPaginatedAnimalsTable };
};
