import { useContext, useReducer, FC } from "react";
import { useMutation } from "@apollo/client";
import { DateTime } from "luxon";
import { ANIMAL_FILTERS } from "api/MyLivestock/Animal/queries";
import { CREATE_ANIMAL_FILTER } from "api/MyLivestock/Animal/mutations";
import {
  AnimalFilters,
  AnimalFiltersVariables,
  AnimalFilters_animalFilters,
} from "api/MyLivestock/Animal/types/AnimalFilters";
import { CreateAnimalFilter, CreateAnimalFilterVariables } from "api/MyLivestock/Animal/types/CreateAnimalFilter";
import { CommonContext } from "config/commonProvider";
import { ANIMAL_FILTER_KEYS, ANIMAL_FILTER_VALUES } from "constants/Animals";
import { isAnimalsFilterEmpty, isAnimalsFilterMissingRequiredField } from "helpers/myLivestock";
import { getBUFromLocalStorage } from "helpers/storage";
import { useStateSpecies } from "hooks";
import { FilterFormData } from "components/MyLivestock/Animals/AnimalFiltersBlock/CreateEditFilterForm";
import { CreateEditFilterForm } from "components/MyLivestock";
import { Modal, Icon } from "components";
import styles from "./styles.module.scss";
import { EventName, logAnalyticsEvent } from "utils/analytics";

type State = {
  isModalVisible: boolean;
};

type Action = { type: "setModalVisibility"; payload: boolean } | { type: "reset" };

const initialState = {
  isModalVisible: false,
};

const reducer = (state: Readonly<State>, action: Action): State => {
  switch (action.type) {
    case "setModalVisibility":
      return { ...state, isModalVisible: action.payload };
    case "reset":
      return initialState;
    default:
      return state;
  }
};

const computeDate = (date: Date): string => {
  return DateTime.fromJSDate(date).toISODate();
};

interface SaveFilterTemplate {
  requiredFieldIds?: ValuesOf<typeof ANIMAL_FILTER_KEYS>[];
}

export const SaveFilterTemplate: FC<SaveFilterTemplate> = ({ requiredFieldIds }) => {
  const { activeSpecies } = useStateSpecies();

  const {
    mainCommonState: { animalsFilters },
    showNotification,
    setAnimalsFilterTemplateId,
  } = useContext(CommonContext);
  const [{ isModalVisible }, dispatch] = useReducer(reducer, initialState);

  const [createAnimalFilter, { loading }] = useMutation<CreateAnimalFilter, CreateAnimalFilterVariables>(
    CREATE_ANIMAL_FILTER,
  );

  const isSaveButtonHidden: boolean =
    isAnimalsFilterEmpty(animalsFilters) || isAnimalsFilterMissingRequiredField(animalsFilters, requiredFieldIds);

  const handleModalOpen = (): void => {
    dispatch({ type: "setModalVisibility", payload: true });
  };

  const handleModalClose = (): void => {
    dispatch({ type: "reset" });
  };

  const computePregnancyStatus = (status: string | null): boolean | null => {
    if (status === ANIMAL_FILTER_VALUES.IS_PREGNANT.PREGNANT) return true;
    if (status === ANIMAL_FILTER_VALUES.IS_PREGNANT.NOT_PREGNANT) return false;

    return null;
  };

  const handleModalConfirmClick = async ({
    name,
    isHomepageAlert,
    homepageAlertColor,
  }: FilterFormData): Promise<void> => {
    const businessUnitId: number = getBUFromLocalStorage();

    const {
      [ANIMAL_FILTER_KEYS.ANIMAL_TYPE_ID]: filteredAnimalTypeId = activeSpecies?.id,
      [ANIMAL_FILTER_KEYS.BREEDS]: filteredBreeds = null,
      [ANIMAL_FILTER_KEYS.SEX]: filteredSex = null,
      [ANIMAL_FILTER_KEYS.SEX_CLASSIFICATIONS]: filteredSexClassifications = null,
      [ANIMAL_FILTER_KEYS.MIN_AGE]: filteredMinAge = null,
      [ANIMAL_FILTER_KEYS.MAX_AGE]: filteredMaxAge = null,
      [ANIMAL_FILTER_KEYS.MIN_CURRENT_EST_WEIGHT]: filteredMinCurrentEstWeight = null,
      [ANIMAL_FILTER_KEYS.MAX_CURRENT_EST_WEIGHT]: filteredMaxCurrentEstWeight = null,
      [ANIMAL_FILTER_KEYS.MIN_WEIGHT]: filteredMinWeight = null,
      [ANIMAL_FILTER_KEYS.MAX_WEIGHT]: filteredMaxWeight = null,
      [ANIMAL_FILTER_KEYS.DAYS_SINCE_LAST_WEIGHT_FROM]: filteredDaysSinceLastWeightFrom = null,
      [ANIMAL_FILTER_KEYS.DAYS_SINCE_LAST_WEIGHT_TO]: filteredDaysSinceLastWeightTo = null,
      [ANIMAL_FILTER_KEYS.GROWTH_RATE_FROM]: filteredGrowthRateFrom = null,
      [ANIMAL_FILTER_KEYS.GROWTH_RATE_TO]: filteredGrowthRateTo = null,
      [ANIMAL_FILTER_KEYS.DELIVERY_DATE_FROM]: filteredDeliveryFrom = null,
      [ANIMAL_FILTER_KEYS.DELIVERY_DATE_TO]: filteredDeliveryTo = null,
      [ANIMAL_FILTER_KEYS.GROUPS]: filteredGroups = null,
      [ANIMAL_FILTER_KEYS.FIELDS]: filteredFields = null,
      [ANIMAL_FILTER_KEYS.LAST_SYNCED_DATE_FROM]: filteredLastSyncedDateFrom = null,
      [ANIMAL_FILTER_KEYS.LAST_SYNCED_DATE_TO]: filteredLastSyncedDateTo = null,
      [ANIMAL_FILTER_KEYS.IS_BCMS_SYNCED]: filteredIsBcmsSynced = null,
      [ANIMAL_FILTER_KEYS.IS_WITHDRAWAL]: filteredIsWithdrawal = null,
      [ANIMAL_FILTER_KEYS.MIN_DATE_MOVED_TO_FARM]: filteredMinDateMovedToFarm = null,
      [ANIMAL_FILTER_KEYS.MAX_DATE_MOVED_TO_FARM]: filteredMaxDateMovedToFarm = null,
      [ANIMAL_FILTER_KEYS.MIN_DOB]: filteredMinDob = null,
      [ANIMAL_FILTER_KEYS.MAX_DOB]: filteredMaxDob = null,
      [ANIMAL_FILTER_KEYS.PREGNANCY_STATUS]: filteredPregnancyStatus = null,
      [ANIMAL_FILTER_KEYS.PREGNANCY_DUE_FROM]: filteredPregnancyDueDateFrom = null,
      [ANIMAL_FILTER_KEYS.PREGNANCY_DUE_TO]: filteredPregnancyDueDateTo = null,
    } = { ...animalsFilters };

    const MESSAGES = {
      duplicateFilterError: "Error saving filter: A filter with this name already exists",
      genericError: "Error saving filter",
      success: "Filter successfully saved",
    };

    try {
      const { data } = await createAnimalFilter({
        variables: {
          input: {
            businessUnit: businessUnitId,
            animalType: filteredAnimalTypeId,
            name: name,
            breeds: filteredBreeds,
            isMale: filteredSex ? filteredSex === ANIMAL_FILTER_VALUES.SEX.M : null,
            sexClassifications: filteredSexClassifications,
            ageFrom: filteredMinAge ? +filteredMinAge : null,
            ageTo: filteredMaxAge ? +filteredMaxAge : null,
            currentWeightFrom: filteredMinCurrentEstWeight ? +filteredMinCurrentEstWeight : null,
            currentWeightTo: filteredMaxCurrentEstWeight ? +filteredMaxCurrentEstWeight : null,
            lastWeightFrom: filteredMinWeight ? +filteredMinWeight : null,
            lastWeightTo: filteredMaxWeight ? +filteredMaxWeight : null,
            daysSinceLastWeightFrom: filteredDaysSinceLastWeightFrom ? +filteredDaysSinceLastWeightFrom : null,
            daysSinceLastWeightTo: filteredDaysSinceLastWeightTo ? +filteredDaysSinceLastWeightTo : null,
            growthRateFrom: filteredGrowthRateFrom ? +filteredGrowthRateFrom : null,
            growthRateTo: filteredGrowthRateTo ? +filteredGrowthRateTo : null,
            deliveryFrom: filteredDeliveryFrom ? computeDate(filteredDeliveryFrom) : null,
            deliveryTo: filteredDeliveryTo ? computeDate(filteredDeliveryTo) : null,
            groups: filteredGroups,
            fields: filteredFields,
            lastRegulatorySyncedDateFrom: filteredLastSyncedDateFrom ? computeDate(filteredLastSyncedDateFrom) : null,
            lastRegulatorySyncedDateTo: filteredLastSyncedDateTo ? computeDate(filteredLastSyncedDateTo) : null,
            isRegulatorySynced: filteredIsBcmsSynced
              ? filteredIsBcmsSynced === ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.SYNCED
              : null,
            inWithdrawal: filteredIsWithdrawal
              ? filteredIsWithdrawal === ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.DRAWAL
              : null,
            isAlert: isHomepageAlert,
            dofFrom: filteredMinDateMovedToFarm ? computeDate(filteredMinDateMovedToFarm) : null,
            dofTo: filteredMaxDateMovedToFarm ? computeDate(filteredMaxDateMovedToFarm) : null,
            dobFrom: filteredMinDob ? computeDate(filteredMinDob) : null,
            dobTo: filteredMaxDob ? computeDate(filteredMaxDob) : null,
            isPregnant: filteredPregnancyStatus ? computePregnancyStatus(filteredPregnancyStatus) : null,
            pregnancyDueDateFrom: filteredPregnancyDueDateFrom ? computeDate(filteredPregnancyDueDateFrom) : null,
            pregnancyDueDateTo: filteredPregnancyDueDateTo ? computeDate(filteredPregnancyDueDateTo) : null,
            ...(isHomepageAlert ? { color: homepageAlertColor } : {}),
          },
        },
        update(cache, { data }) {
          const newFilterValue = data?.createAnimalFilter?.animalFilter;
          const cacheData = cache.readQuery<AnimalFilters, AnimalFiltersVariables>({
            query: ANIMAL_FILTERS,
            variables: { businessUnitId },
          });

          if (cacheData?.animalFilters && newFilterValue) {
            const updatedFilters = cacheData.animalFilters.concat(newFilterValue as AnimalFilters_animalFilters);

            cache.writeQuery({
              query: ANIMAL_FILTERS,
              variables: {
                businessUnitId,
              },
              data: { animalFilters: updatedFilters },
            });
          }
        },
      });

      const isMutationSuccess = !data?.createAnimalFilter?.errors;

      if (isMutationSuccess) {
        if (data?.createAnimalFilter?.animalFilter?.id) {
          setAnimalsFilterTemplateId(data.createAnimalFilter.animalFilter.id);
        }
        showNotification({
          message: MESSAGES.success,
        });
        handleModalClose();
        logAnalyticsEvent(EventName.saveFilter, {
          filterId: data?.createAnimalFilter?.animalFilter?.id,
        });
      } else {
        showNotification({
          variant: "error",
          message: MESSAGES.duplicateFilterError,
        });
      }
    } catch (error: any) {
      // sorry for flakey error checking, no status codes from backend :-(
      showNotification({
        variant: "error",
        message: error?.message?.includes("already exists") ? MESSAGES.duplicateFilterError : MESSAGES.genericError,
      });
    }
  };

  if (isSaveButtonHidden) {
    return null;
  }

  return (
    <>
      <button className={styles.saveButton} onClick={handleModalOpen}>
        <Icon className={styles.saveIcon} name="save" size="tiny" />
        Save filter
      </button>
      <Modal
        showCloseButton
        active={isModalVisible}
        isChildrenGutterManual
        handleClose={handleModalClose}
        title="Save favourite filter"
      >
        <CreateEditFilterForm onCancel={handleModalClose} onSubmit={handleModalConfirmClick} isLoading={loading} />
      </Modal>
    </>
  );
};
