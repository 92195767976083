//Mobile Mask format is for https://github.com/benhurott/react-native-masked-text

export enum CountryISOCode {
  AU = "AU",
  GB = "GB",
  US = "US",
  NZ = "NZ",
  IL = "IL",
  IT = "IT",
  NO = "NO",
  CA = "CA",
  MX = "MX",
  ES = "ES",
  RO = "RO",
  FR = "FR",
  IE = "IE",
  GR = "GR",

}

// UK (GB)
const GB_HOLDIN_ID_NAME = "Country Parish Holding";
const GB_HOLDIN_ID_INITIALISM = "CPH";
const GB_HOLDIN_ID_REGEX = /^\d{2}\/\d{3}\/\d{4}$/;
const GB_HOLDIN_ID_MASK = "99/999/9999";
const GB_HOLDIN_ID_MASK_WEB = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

// Australia (AU)
const AU_HOLDIN_ID_NAME = "Property Identification Code";
const AU_HOLDIN_ID_INITIALISM = "PIC";
const AU_HOLDIN_ID_REGEX = /[A-Z]{2}[0-9]{6}/i;
const AU_HOLDIN_ID_MASK = "AA999999";
const AU_HOLDIN_ID_MASK_WEB = [/\D/, /\D/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

// New Zealand (NZ)
const NZ_HOLDIN_ID_NAME = "National Animal Identification and Tracing";
const NZ_HOLDIN_ID_INITIALISM = "NAIT";
const NZ_HOLDIN_ID_REGEX = /[0-9]{6,8}/;
const NZ_HOLDIN_ID_MASK = "99999999";
const NZ_HOLDIN_ID_MASK_WEB = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

// USA (US)
const US_HOLDIN_ID_NAME = "Premises Identification Number or Location ID";
const US_HOLDIN_ID_INITIALISM = "PIN or LID";
const US_HOLDIN_ID_REGEX = /[0-9A-Z]{0,20}/i;
const US_HOLDIN_ID_MASK = "SSSSSSSSSSSSSSSSSSSS";
const US_HOLDIN_ID_MASK_WEB = [
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
];

// Canada (CA)
const CA_HOLDIN_ID_NAME = "Premises Identification";
const CA_HOLDIN_ID_INITIALISM = "PID";
const CA_HOLDIN_ID_REGEX = /[0-9A-Z]{0,20}/i;
const CA_HOLDIN_ID_MASK = "SSSSSSSSSSSSSSSSSSSS";
const CA_HOLDIN_ID_MASK_WEB = [
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
];

// Mexico (MX)
const MX_HOLDIN_ID_NAME = "Premises Identification Number or Location ID";
const MX_HOLDIN_ID_INITIALISM = "PIN or LID";
const MX_HOLDIN_ID_REGEX = /[0-9A-Z]{0,20}/i;
const MX_HOLDIN_ID_MASK = "SSSSSSSSSSSSSSSSSSSS";
const MX_HOLDIN_ID_MASK_WEB = [
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
  /[0-9A-Z]/i,
];

const useHoldingInfo = (countryIsoCode?: CountryISOCode) => {
  let HoldingIDName = GB_HOLDIN_ID_NAME;
  let HoldingIDInitialism = GB_HOLDIN_ID_INITIALISM;
  let HoldingIDRegex = GB_HOLDIN_ID_REGEX;
  let HoldingIDNumberMask = GB_HOLDIN_ID_MASK;
  let HoldingIDNumberMaskWeb = GB_HOLDIN_ID_MASK_WEB;

  switch (countryIsoCode) {
    case CountryISOCode.AU:
      HoldingIDNumberMask = AU_HOLDIN_ID_MASK;
      HoldingIDNumberMaskWeb = AU_HOLDIN_ID_MASK_WEB;
      HoldingIDInitialism = AU_HOLDIN_ID_INITIALISM;
      HoldingIDName = AU_HOLDIN_ID_NAME;
      HoldingIDRegex = AU_HOLDIN_ID_REGEX;
      break;
    case CountryISOCode.NZ:
      HoldingIDNumberMask = NZ_HOLDIN_ID_MASK;
      HoldingIDNumberMaskWeb = NZ_HOLDIN_ID_MASK_WEB;
      HoldingIDInitialism = NZ_HOLDIN_ID_INITIALISM;
      HoldingIDName = NZ_HOLDIN_ID_NAME;
      HoldingIDRegex = NZ_HOLDIN_ID_REGEX;
      break;
    case CountryISOCode.US:
      HoldingIDNumberMask = US_HOLDIN_ID_MASK;
      HoldingIDNumberMaskWeb = US_HOLDIN_ID_MASK_WEB;
      HoldingIDInitialism = US_HOLDIN_ID_INITIALISM;
      HoldingIDName = US_HOLDIN_ID_NAME;
      HoldingIDRegex = US_HOLDIN_ID_REGEX;
      break;
    case CountryISOCode.CA:
      HoldingIDNumberMask = CA_HOLDIN_ID_MASK;
      HoldingIDNumberMaskWeb = CA_HOLDIN_ID_MASK_WEB;
      HoldingIDInitialism = CA_HOLDIN_ID_INITIALISM;
      HoldingIDName = CA_HOLDIN_ID_NAME;
      HoldingIDRegex = CA_HOLDIN_ID_REGEX;
      break;
    case CountryISOCode.MX:
      HoldingIDNumberMask = MX_HOLDIN_ID_MASK;
      HoldingIDNumberMaskWeb = MX_HOLDIN_ID_MASK_WEB;
      HoldingIDInitialism = MX_HOLDIN_ID_INITIALISM;
      HoldingIDName = MX_HOLDIN_ID_NAME;
      HoldingIDRegex = MX_HOLDIN_ID_REGEX;
      break;
  }

  return { HoldingIDName, HoldingIDInitialism, HoldingIDNumberMask, HoldingIDNumberMaskWeb, HoldingIDRegex };
};

export default useHoldingInfo;
