import { Tooltip } from "components";
import { AnimalTagOwnership } from "generated/graphql";
import { cn } from "utils/misc";
import TagIcon from "../TagIcon";
import TagLocalIcon from "../TagLocalIcon";

export interface TagItem {
  tagId: string | undefined;
  name: string;
  color: string | undefined | null;
  ownership?: AnimalTagOwnership | string;
}

export const Tag = ({ tag, className }: { tag: TagItem; className?: string }) => {
  const color = tag.color || "#000000";
  return (
    <div key={tag.tagId} className={cn("h-8 relative", className)}>
      <Tooltip visualIndicator={true} title={tag.name} placement="top">
        {tag.ownership === AnimalTagOwnership.System ? (
          <>
            <TagIcon fill={color} />
            <span className="block absolute text-sm font-bold top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              {tag.name.slice(0, 1).toUpperCase()}
            </span>
          </>
        ) : (
          <TagLocalIcon fill={color} />
        )}
      </Tooltip>
    </div>
  );
};
