import React, { KeyboardEventHandler } from "react";
// API
import { activityTemplateFragment_fields } from "api/MyLivestock/Activity/types/activityTemplateFragment";
import { ActivityTemplateFieldFieldType } from "api/graphql-global-types";
// Utils
import { useGetCurrentBusinessUnit } from "hooks";
// Components
import { CheckBox, FieldDate, FieldNumber, FieldSelect, FieldText } from "components";
import { AnimalSelect } from "components/MyLivestock/AnimalSelect";
import { renderActivityUnits } from "helpers/myLivestock";
import { SireSelect } from "components/MyLivestock/SireSelect";

interface Props {
  fieldInfo: activityTemplateFragment_fields;
  value: any;
  error: string;
  setFieldValue: (name: string, value: string | boolean) => void;
}

export const FormFieldBuilder: React.FC<Props> = ({ fieldInfo, value, error, setFieldValue }) => {
  const { currencySymbol, weightUnits } = useGetCurrentBusinessUnit();
  const fieldName = fieldInfo.id;
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(e.target.name, e.target.value);
  };
  switch (fieldInfo.fieldType) {
    case ActivityTemplateFieldFieldType.STRING:
      return (
        <FieldText
          label={fieldInfo.name}
          error={error}
          inputProps={{
            name: fieldName,
            value: value,
            onChange: handleChangeValue,
          }}
        />
      );
    case ActivityTemplateFieldFieldType.BOOLEAN:
      const handleCheckboxChange = (): void => {
        setFieldValue(fieldName, !value);
      };
      return (
        <CheckBox
          label={fieldInfo.name}
          error={error}
          name={fieldName}
          checked={value}
          onChange={handleCheckboxChange}
        />
      );
    case ActivityTemplateFieldFieldType.NUMBER:
      const fieldUnits = renderActivityUnits(fieldInfo.units, weightUnits);

      // Chris 2021-Oct-07
      //
      // Hacky hijacking of withdrawal field to prevent decimal places.
      //
      // Would rather this be generated from validation rules from the backend for each field in an activity template
      // but that would be a whole different piece of work.
      //
      // See: https://breedr.atlassian.net/browse/BRDRCL-6124
      const isFieldWithdrawalDays = fieldInfo.slug === "withdrawal";
      const handleKeyPressWithdrawalDays: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === ".") event.preventDefault();
      };

      return (
        <FieldNumber
          label={fieldInfo.name}
          error={error}
          inputProps={{
            name: fieldName,
            onChange: handleChangeValue,
            onKeyPress: isFieldWithdrawalDays ? handleKeyPressWithdrawalDays : undefined,
            value: value,
            adornment: fieldUnits
              ? {
                  labelFor: fieldInfo.name,
                  label: fieldUnits,
                }
              : undefined,
          }}
        />
      );
    case ActivityTemplateFieldFieldType.DATE:
      return (
        <FieldDate
          label={fieldInfo.name}
          error={error}
          inputProps={{
            name: fieldName,
            onChange: (date): void => setFieldValue(fieldName, date),
            value: value,
          }}
        />
      );
    case ActivityTemplateFieldFieldType.CURRENCY_ID:
      return (
        <FieldNumber
          label={`${fieldInfo.name} (${currencySymbol})`}
          error={error}
          inputProps={{
            name: fieldName,
            onChange: handleChangeValue,
            placeholder: currencySymbol,
            value,
          }}
        />
      );
    case ActivityTemplateFieldFieldType.SELECT:
    case ActivityTemplateFieldFieldType.MULTI_SELECT:
      const options = fieldInfo.options?.map((item) => ({
        key: item?.id,
        value: item?.value || "",
        label: item?.title || "",
      }));
      return (
        <FieldSelect
          label={fieldInfo.name}
          error={error}
          inputProps={{
            name: fieldName,
            placeholder: "",
            value: value,
            options: options,
            onChange: handleChangeValue,
            isClearable: !fieldInfo.isRequired,
            multiple: fieldInfo.fieldType === ActivityTemplateFieldFieldType.MULTI_SELECT,
          }}
        />
      );
    case ActivityTemplateFieldFieldType.ANIMAL_ID:
    case ActivityTemplateFieldFieldType.ANIMAL_IDS:
      return (
        <AnimalSelect
          value={value}
          onSelect={handleChangeValue}
          name={fieldName}
          label={fieldInfo.name}
          error={error}
          isMultiple={fieldInfo.fieldType === ActivityTemplateFieldFieldType.ANIMAL_IDS}
        />
      );
    case ActivityTemplateFieldFieldType.SIRE_ID:
      return (
        <SireSelect value={value} onSelect={handleChangeValue} name={fieldName} label={fieldInfo.name} error={error} />
      );
    default:
      return null;
  }
};
