import { VFC } from "react";
// Libraries
import { NavLink } from "react-router-dom";
// Utils
import { useHover } from "hooks";
// Helpers
import { isListingSold, isListingTradeAgreed } from "trading/util";
import { getListingOfferStatus } from "./helpers/getListingOfferStatus";
// Types
import { AnimalsBreedPillProps } from "components/MyLivestock/Animals/AnimalsBreedPill";
import { BuyingLots_buyingLots_edges_node_categoryOfSale } from "trading/api/Liveweight/types/BuyingLots";
// Components
import { Flex, Paper, Pill, Spacer, Text } from "components";
import { AnimalsBreedPill } from "components/MyLivestock";
import { ListingCardPhoto } from "./ListingCardPhoto";
// Resources
import styles from "./ListingCard.module.scss";

export interface ListingCardProps {
  animalsAgeAverage?: string;
  animalsGrowthRateAverage?: string;
  animalsInWithdrawalTotal?: number;
  animalsNamedSireTotal?: number;
  animalsTimesMovedMaximum?: number;
  animalsTimesWeighedAverage?: number;
  animalsTotal: number;
  animalsWeightAverage?: string;
  breeds?: AnimalsBreedPillProps[];
  categoryOfSale?: BuyingLots_buyingLots_edges_node_categoryOfSale | null;
  distance?: string | null;
  expiryDate: Date;
  startDate: Date;
  image?: string;
  imageAriaLabel?: string;
  isExpired?: boolean;
  isPrivate?: boolean;
  location?: string;
  name?: string;
  offersCount?: number;
  pricePerHead: string;
  priceTotal: string;
  status?: string;
  url: string;
}

export const ListingCard: VFC<ListingCardProps> = ({
  animalsAgeAverage = "Unknown",
  animalsGrowthRateAverage = "Unknown",
  animalsInWithdrawalTotal = 0,
  animalsNamedSireTotal = 0,
  animalsTimesMovedMaximum = 0,
  animalsTimesWeighedAverage = 0,
  animalsTotal,
  animalsWeightAverage = "Unknown",
  breeds,
  categoryOfSale,
  distance,
  expiryDate,
  startDate,
  image,
  imageAriaLabel,
  isExpired,
  isPrivate,
  location,
  name,
  offersCount,
  pricePerHead,
  priceTotal,
  status,
  url,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLAnchorElement>();

  const isSold = status ? isListingSold(status) : undefined;
  const isTradeAgreed = status ? isListingTradeAgreed(status) : undefined;
  const photoExpiryDate = !isSold && !isTradeAgreed ? expiryDate : undefined;
  const isPhotoFaded = isExpired || isSold;

  const offerStatus = status ? getListingOfferStatus(status, offersCount) : undefined;

  return (
    <NavLink className={styles.listing_card} innerRef={hoverRef} to={url}>
      <Paper>
        <Spacer baselineHeight={2} />

        <Flex className={styles.listing_card__details} container containerWrap="nowrap">
          <Flex item itemGutter>
            <ListingCardPhoto
              expiryDate={photoExpiryDate}
              startDate={startDate}
              image={image}
              isFaded={isPhotoFaded}
              imageAriaLabel={imageAriaLabel}
              offerStatus={offerStatus}
            />

            {isPrivate ? (
              <>
                <Spacer baselineHeight={1} />

                <Pill caption="Shared privately" icon="people" />
              </>
            ) : null}
          </Flex>

          <Flex container containerDirection="column" item itemBasisFill>
            <Flex container containerWrap="nowrap" item>
              <Flex item itemBasisFill itemGutter>
                <Text colour={isHovered ? "yellow" : "black"}>
                  <strong>{name}</strong>
                </Text>

                {location ? (
                  <>
                    <Spacer allowDecimal baselineHeight={0.5} />

                    <Text className={styles.listing_card__location} smaller>
                      {location}
                    </Text>
                  </>
                ) : null}
              </Flex>
            </Flex>

            {location ? <Spacer allowDecimal baselineHeight={1.5} /> : <Spacer baselineHeight={1} />}

            <Flex item itemGutter>
              {breeds ? (
                breeds.map(({ name: breedName, ...breed }) => (
                  <AnimalsBreedPill className={styles.listing_card__pill} key={breedName} name={breedName} {...breed} />
                ))
              ) : (
                <Pill caption="0 animals" className={styles.listing_card__pill} colour="red" icon="cattle" />
              )}

              <Spacer />
              {categoryOfSale ? (
                <Pill
                  caption={`Category: ${categoryOfSale.category || ""}`}
                  className={styles.listing_card__pill}
                  icon="folder"
                />
              ) : null}

              {distance ? (
                <Pill caption={`Distance: ${distance}`} className={styles.listing_card__pill} icon="delivery" />
              ) : null}

              <Pill
                caption={`Avg. weight: ${animalsWeightAverage}`}
                className={styles.listing_card__pill}
                icon="weight"
              />

              <Pill
                caption={`Avg. weigh-ins: ${animalsTimesWeighedAverage}`}
                className={styles.listing_card__pill}
                icon="digitalScale"
              />

              <Pill
                caption={`Avg. DLWG: ${animalsGrowthRateAverage}`}
                className={styles.listing_card__pill}
                icon="analogScale"
              />

              <Spacer />

              <Pill caption={`Avg. age: ${animalsAgeAverage}`} className={styles.listing_card__pill} icon="calendar" />

              <Pill
                caption={`Named sire:  ${animalsNamedSireTotal}/${animalsTotal}`}
                className={styles.listing_card__pill}
                icon="sire"
              />

              <Pill
                caption={`Max no. of moves: ${animalsTimesMovedMaximum}`}
                className={styles.listing_card__pill}
                icon="warehouse"
              />

              <Pill
                caption={`In withdrawal: ${animalsInWithdrawalTotal}/${animalsTotal}`}
                className={styles.listing_card__pill}
                icon="withdrawal"
              />

              <Spacer baselineHeight={1} />
              <Flex container>
                <Flex item>
                  <Text>
                    <strong>{pricePerHead} </strong>
                    <Text className={styles.listing_card__priceType}> per head</Text>
                  </Text>
                </Flex>
                <Flex item itemGutter>
                  <Text>
                    <strong>{priceTotal} </strong>
                    <Text className={styles.listing_card__priceType}> total</Text>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Spacer baselineHeight={2} />
      </Paper>
    </NavLink>
  );
};
