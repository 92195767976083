import packageJson from "../../package.json";

const api = {
  endpoints: {
    graphQL: "/graphql",
    logIn: "/sign-in/",
    signUp: "/sign-up/",
    resetPas: "/reset-password/",
    forgotPass: "/forgot-password/",
    events: "/events",
  },
  version: packageJson.version,
  // Bump the cache version if you need to reset the Apollo client-side
  // cache for some reason. Only do this if you really really need too, i.e.
  // because you suspect it's corrupted and causing problems for our users.
  apolloCacheKey: "v1__apollo-cache-persist",
  host: process.env.REACT_APP_API_DOMAIN || "backend.staging.breedr.tech",
  eventsHost: process.env.REACT_APP_EVENTS_DOMAIN || "events.staging.breedr.tech",
  appUrl: process.env.REACT_APP_APP_DOMAIN || "app.staging.breedr.tech",
  staticHost: process.env.REACT_APP_STATIC_DOMAIN
    ? process.env.REACT_APP_STATIC_DOMAIN
    : process.env.REACT_APP_API_DOMAIN
    ? process.env.REACT_APP_API_DOMAIN + "/static"
    : "backend.staging.breedr.tech/static",
  googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyA4nOKLw3i4JN9GEPxOL3y58hvW7yK0RsM",
  secureProtocol: process.env.REACT_APP_SECURE_PROTOCOL || "https://",
  hubspotTrackingCode: process.env.REACT_APP_HUBSPOT_TRACKING_CODE || "22250139",
};

export default api;
