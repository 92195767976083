//@ts-nocheck
import React, { useContext } from "react";
import { useApolloClient } from "@apollo/client";
import { GetFarmSystems_farmSystems } from "api/Settings/types/GetFarmSystems";
import { GET_BUSINESS_UNITS_LIST } from "api/BusinessUnits/queries";
import { CommonContext } from "config/commonProvider";
import { handleEnterPress, handleErrorCheck } from "helpers/general";
import { useGetDeliveryAddresses, useGetCurrentBusinessUnit } from "hooks";
import { hasBCMSIntegration } from "helpers/settings";
import { CountriesCodes } from "hooks/useGetCurrentBusinessUnit";
import SelectedSystemFields from "pages/Settings/FarmSettings/ConnectedSystems/ConnectToSystemForm/SelectedSystemFields/index";
import FormBuilder from "components/Common/FormBuilder";
import { Flex, FieldSelect, FieldText, Panel, QuickActions, Spacer, Text, ConfirmDeletionModal } from "components";
import { getValidationSchema } from "../helpers";
import { getBUFromLocalStorage } from "helpers/storage";

interface ConnectToSystemFormProps {
  onCloseForm: () => void;
  loading: boolean;
  editMode?: EditConnectedSystemData;
  showModal: boolean;
  error?: string;
  connectToSystem: (props: Record<string, unknown>, showNotification: () => void) => void;
  toggleModal: (show: boolean) => void;
  removeFarmConnection: (showNotification: () => void) => void;
  handleChangeSystem: (props: Record<string, unknown>, func: () => void) => void;
  initialValues: Record<string, unknown>;
  selectedSystem: EditConnectedSystemData;
  systems: Array<{
    key: string;
    label: string;
    value: string;
  }>;
  farmSystems: GetFarmSystems_farmSystems[];
}

interface FilteredAddressOption {
  key: string;
  value: string;
  label: string;
}

const FIELD_ADDED_PREFIX = "_prefix";

const ConnectToSystemForm: React.FC<ConnectToSystemFormProps> = ({
  error,
  showModal,
  loading,
  toggleModal,
  connectToSystem,
  editMode,
  systems,
  onCloseForm,
  initialValues,
  selectedSystem,
  handleChangeSystem,
  removeFarmConnection,
  farmSystems,
}) => {
  const { showNotification } = useContext(CommonContext);
  const selectedBU = getBUFromLocalStorage();
  const apolloClient = useApolloClient();
  const { data: deliveryAddresses } = useGetDeliveryAddresses();

  const { countryIsoCode } = useGetCurrentBusinessUnit();

  let showSystemType = true;
  let addNewConnectionText =
    "A successful connection also requires setting your correct CPH ID. This should be entered when setting up your locations.";

  if (countryIsoCode === CountriesCodes.US) {
    showSystemType = false;
    addNewConnectionText = "System integration coming soon.";
  }

  const updateBuBCMSIntegration = (farmSystems: (GetFarmSystems_farmSystems | null)[]): void => {
    const { businessUnits } = apolloClient.readQuery({
      query: GET_BUSINESS_UNITS_LIST,
    });
    const newBUData = (businessUnits || []).map((item) => {
      if (+item.id === selectedBU) {
        return {
          ...item,
          warnings: {
            ...item.warnings,
            IS_NOT_BCMS_INTEGRATED: !hasBCMSIntegration(farmSystems),
          },
        };
      }
      return item;
    });
    apolloClient.writeQuery({
      query: GET_BUSINESS_UNITS_LIST,
      data: { businessUnits: newBUData },
    });
  };

  const filteredDeliveryAddresses = (): FilteredAddressOption[] => {
    if (!farmSystems || !deliveryAddresses) return [];

    const { deliveryAddresses: addresses = [] }: { deliveryAddresses: FilteredAddressOption[] } = deliveryAddresses;
    const locationsWithBCMSIntegration = farmSystems.reduce(
      (acc: number[], farmSystem: GetFarmSystems_farmSystems): number[] => {
        const { location, integrationSystem, status } = farmSystem;

        return integrationSystem?.slug === "BCMS_INTEGRATION" && status === "CONNECTED" ? [...acc, +location?.id] : acc;
      },
      [],
    );

    return addresses.reduce((acc: FilteredAddressOption[], address: DeliveryAddressData) => {
      return !address.locationId || (locationsWithBCMSIntegration.includes(+address.id) && !editMode)
        ? acc
        : [
            ...acc,
            {
              key: address.id,
              value: address.id,
              label: `${address.unitName} (CPH: ${address.locationId})`,
            },
          ];
    }, []);
  };

  return (
    <FormBuilder
      enableReinitialize
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={(props): void => connectToSystem(props, showNotification, updateBuBCMSIntegration)}
      validationSchema={getValidationSchema(selectedSystem?.fields)}
      autoComplete="chrome-off"
    >
      {({ values, errors, handleChange, handleSubmit, setFieldValue }): JSX.Element => {
        const handleChangeWithReplacing = ({ target: { name, value } }): void => {
          const trimedName = name.replace(FIELD_ADDED_PREFIX, "");
          setFieldValue(trimedName, value);
        };
        return (
          <Panel
            title={`${editMode ? "Edit" : "Add new"} connection`}
            actions={[
              { caption: "Cancel", colour: "grey", onClick: onCloseForm, variant: "hollow" },
              { caption: "Save", colour: "yellow", onClick: handleSubmit, disabled: loading, requesting: loading },
            ]}
          >
            <Text smaller>{addNewConnectionText}</Text>
            <Spacer baselineHeight={2} />

            {showSystemType ? (
              <Flex container>
                <Flex item xs={12} l={6} xl={4}>
                  {editMode ? (
                    <FieldText
                      label="Select system"
                      helper="You can't change this field"
                      error={errors.system}
                      inputProps={{
                        name: "system",
                        defaultValue: values.system,
                        disabled: true,
                        autoComplete: "new-password",
                        onChange: handleChange,
                        onKeyPress: (event): void => handleEnterPress(event, handleSubmit),
                      }}
                    />
                  ) : (
                    <FieldSelect
                      label="System type"
                      error={errors.system}
                      inputProps={{
                        name: "system",
                        value: values.system,
                        options: systems,
                        onChange: (event): void => handleChangeSystem(event, handleChange),
                        placeholder: "Select system",
                      }}
                    />
                  )}

                  {selectedSystem
                    ? selectedSystem.fields.map((field) => (
                        <SelectedSystemFields
                          key={field.id}
                          field={field}
                          errors={errors}
                          values={values}
                          filteredAddress={filteredDeliveryAddresses()}
                          handleChange={handleChangeWithReplacing}
                          handleSubmit={handleSubmit}
                          handleErrorCheck={handleErrorCheck}
                          handleEnterPress={handleEnterPress}
                        />
                      ))
                    : null}
                </Flex>
              </Flex>
            ) : null}

            {!!error ? (
              <Flex container>
                <Flex item>
                  <Spacer baselineHeight={1} />
                  <Text warning>{error}</Text>
                </Flex>
              </Flex>
            ) : null}

            <Spacer baselineHeight={1} />

            {editMode ? (
              <Flex container>
                <Flex item>
                  <QuickActions items={[{ label: "Delete", type: "danger", onClick: (): void => toggleModal(true) }]} />
                </Flex>
              </Flex>
            ) : null}

            {editMode ? (
              <ConfirmDeletionModal
                showModal={!!showModal}
                onClose={(): void => toggleModal(false)}
                entityName={editMode.value}
                onSubmit={(): void => removeFarmConnection(showNotification, updateBuBCMSIntegration)}
              />
            ) : null}
          </Panel>
        );
      }}
    </FormBuilder>
  );
};

export default ConnectToSystemForm;
