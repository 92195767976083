import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router";
//Utils
import { getBUFromLocalStorage } from "helpers/storage";
import { GET_DISTRIBUTION_LISTS } from "trading/api/queries";
import {
  GetDistributionLists,
  GetDistributionLists_distributionLists_edges,
  GetDistributionListsVariables,
} from "trading/api/types/GetDistributionLists";
//Components
import Popover from "@material-ui/core/Popover";
import { Dots } from "constants/SvgIcons";
import { CommonTable, Flex, Tooltip } from "components";
import { TRADING } from "constants/Routes";

import styles from "./styles.module.scss";
import { EmptyStatesTradingLists } from "components/EmptyStates/TradingLists";

interface Cell {
  row: {
    original: GetDistributionLists_distributionLists_edges;
  };
}

export const DistributionListsTable: React.FC = () => {
  const history = useHistory();
  const businessUnit: number = getBUFromLocalStorage();
  const [selectedListId, setSelectedListId] = useState<string>();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);

  const { data, loading } = useQuery<GetDistributionLists, GetDistributionListsVariables>(GET_DISTRIBUTION_LISTS, {
    variables: {
      businessUnit,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const handleClick =
    (value: string) =>
    (e: React.MouseEvent<HTMLDivElement>): void => {
      setSelectedListId(value);
      setPopoverAnchorEl(e.currentTarget);
    };

  const handleEditClick = (): void => {
    history.push(`${TRADING}/list/edit/${selectedListId}`);
  };

  const handlePopoverClose = (): void => {
    setPopoverAnchorEl(null);
  };

  const sortingFunc = useCallback((rowA, rowB, columnId): number => {
    const firstValue = rowA.values[columnId].toLowerCase();
    const secondValue = rowB.values[columnId].toLowerCase();

    if (firstValue > secondValue) {
      return 1;
    }

    if (firstValue < secondValue) {
      return -1;
    }
    return 0;
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: ({ node }: GetDistributionLists_distributionLists_edges): string => {
          return node?.name || "";
        },
        id: "name",
        width: 300,
        sortType: sortingFunc,
      },
      {
        Header: "Suppliers",
        accessor: "suppliers",
        columnSort: false,
        Cell: function actionsCell({ row: { original } }: Cell): JSX.Element | null {
          const suppliersString = (original?.node?.suppliers || []).map((item) => item?.name).join(", ");
          return (
            <Tooltip placement="top-start" title={suppliersString}>
              <span>{suppliersString}</span>
            </Tooltip>
          );
        },
        width: 700,
      },
      {
        Header: "",
        accessor: "actions",
        columnSort: false,
        Cell: function actionsCell({ row: { original } }: Cell): JSX.Element | null {
          return (
            <div aria-describedby={original?.node?.id} onClick={handleClick(original?.node?.id || "")}>
              <Dots />
            </div>
          );
        },
      },
    ],
    [],
  );

  const lists = (data?.distributionLists?.edges || []) as (GetDistributionLists_distributionLists_edges | null)[];
  const tableData: (GetDistributionLists_distributionLists_edges | null)[] = useMemo(() => lists, [lists]);
  const isEmptyList: boolean = !loading && lists.length === 0;
  const open = Boolean(popoverAnchorEl);

  return (
    <>
      {isEmptyList ? (
        <Flex container>
          <Flex item itemGutter xs={12}>
            <EmptyStatesTradingLists />
          </Flex>
        </Flex>
      ) : (
        <Flex container>
          <Flex item itemGutter xs={12}>
            <CommonTable columns={columns} data={tableData} loading={loading} defaultSortBy="name" />
            <Popover
              id={selectedListId}
              open={open}
              anchorEl={popoverAnchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: 70,
              }}
            >
              <div className={styles.actions}>
                <p className={styles.actionButton} onClick={handleEditClick}>
                  Edit
                </p>
              </div>
            </Popover>
          </Flex>
        </Flex>
      )}
    </>
  );
};
