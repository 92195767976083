import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "components";
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";
import { ABORT_REGULATORY_TRANSACTIONS } from "api/Regulatory/mutations";
import { TransactionType } from "components/Regulatory/Regulatory";
import {
  RegulatoryTransactionApprovalsAbortVariables,
  RegulatoryTransactionApprovalsAbort,
} from "api/Regulatory/types/RegulatoryTransactionApprovalsAbort";

interface ChangeDateProps {
  selectedTransactions: string[];
  transactionType: TransactionType;
  onSuccessSubmit: () => void;
}

export const Abort: React.FC<ChangeDateProps> = ({ selectedTransactions = [], transactionType, onSuccessSubmit }) => {
  const [modalVisible, changeModalVisibility] = useState<boolean>(false);
  const { showNotification } = useContext(CommonContext);
  const businessUnit = getBUFromLocalStorage();

  const [abortTransactions, { loading }] = useMutation<
    RegulatoryTransactionApprovalsAbort,
    RegulatoryTransactionApprovalsAbortVariables
  >(ABORT_REGULATORY_TRANSACTIONS, {
    variables: {
      input: {
        businessUnit,
        transactionType,
        regulatoryTransactionApprovals: selectedTransactions,
      },
    },
  });

  const { length: transactionCount } = selectedTransactions;
  const thisOrThese: string = transactionCount === 1 ? "this" : "these";
  const sOrNot: string = transactionCount !== 1 ? "s" : "";
  const totalOrNot: string = transactionCount !== 1 ? `${transactionCount} ` : "";

  const handleCloseModal = (): void => changeModalVisibility(false);

  const handleConfirmClick = async (): Promise<void> => {
    const { data } = await abortTransactions();
    const errors = data?.regulatoryTransactionApprovalsAbort?.errors;

    if (errors) {
      showNotification({
        variant: "error",
        message: `Error aborting movement.`,
      });
    } else {
      showNotification({
        message: `Movement successfully aborted.`,
      });
      onSuccessSubmit();
      handleCloseModal();
    }
  };

  return (
    <>
      <Button
        caption="Abort (do not send)"
        colour="grey"
        disabled={!transactionCount}
        onClick={(): void => changeModalVisibility(true)}
        variant="hollow"
      />
      <Modal
        showCloseButton
        active={modalVisible}
        handleClose={handleCloseModal}
        title={`Abort sending ${thisOrThese} pending movement${sOrNot}?`}
        subTitle={`Confirm you want to abort ${thisOrThese} ${totalOrNot}pending movement${sOrNot}. Any aborted movements will not be sent.`}
        actions={{
          secondary: {
            onClick: handleCloseModal,
            caption: "Cancel",
          },
          primary: {
            onClick: handleConfirmClick,
            caption: "Confirm",
            disabled: loading,
            requesting: loading,
          },
        }}
      />
    </>
  );
};
