import React, { FC } from "react";
import { VictoryPie } from "victory";

import { theme } from "reports/theme";
import styles from "./styles.module.scss";
import { usePieChartDataQuery } from "generated/graphql";

interface IProps {
  chartTitle: string;
  dataQuery: string; //Use this to make a query asking for chart data
  businessUnitId: number;
}

export const PieChart: FC<IProps> = ({ chartTitle, dataQuery, businessUnitId }) => {
  const { data: data } = usePieChartDataQuery({
    fetchPolicy: "network-only",
    variables: {
      chartSlug: dataQuery,
      dashboardSlug: chartTitle,
      businessUnitId: businessUnitId,
    },
  });
  const dataset = data?.pieChartData?.data ?? [];
  return (
    <figure className={styles.container}>
      <VictoryPie
        // height={30}
        theme={theme}
        innerRadius={60}
        labelRadius={73}
        data={dataset}
        labels={({ datum }) => [datum.x, datum.y]}
        style={{ labels: { fontSize: 14, fill: "white" } }}
      />
    </figure>
  );
};
