import { gql } from "@apollo/client";
import { animal } from "animals/api/fragments";

export const ANIMAL_TAGS = gql`
  query AnimalTags($buId: Int!, $tagIds: [ID!]) {
    animalTags(businessUnitId: $buId, tagIds: $tagIds, first: 100) {
      edges {
        node {
          id
          name
          color
          numAliveAnimals
          ownership
          schema {
            id
            isRemovable
          }
        }
      }
    }
  }
`;

export const ANIMAL_TAG = gql`
  query AnimalTag($buId: Int!, $tagIds: [ID!]) {
    animalTags(businessUnitId: $buId, tagIds: $tagIds, first: 100) {
      edges {
        node {
          id
          name
          color
          numAliveAnimals
          ownership
          averageAge
          averageDaysOnFarm
          averageRecordedWeight
          averageEstimatedWeight
        }
      }
    }
  }
`;

export const GET_ANIMALS_WITH_TAGS = gql`
  query GetAnimalsWithTags(
    $businessUnitId: Int!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $isMale: Boolean
    $search: String
    $searchIds: [IdentifierType!]
    $isPregnant: Boolean
    $minDob: DateTime
    $maxDob: DateTime
    $minDof: DateTime
    $maxDof: DateTime
    $animalTags: [ID!]
    $animalIds: [ID!]
    $orderBy: [AnimalSorting!]
    $field: UUID
    $group: UUID
  ) {
    animals(
      businessUnitId: $businessUnitId
      search: $search
      searchIds: $searchIds
      first: $first
      last: $last
      before: $before
      after: $after
      animalTags: $animalTags
      isMale: $isMale
      isPregnant: $isPregnant
      minDob: $minDob
      maxDob: $maxDob
      minDof: $minDof
      maxDof: $maxDof
      animalIds: $animalIds
      orderBy: $orderBy
      field: $field
      group: $group
    ) {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ...animal
        }
      }
    }
  }
  ${animal}
`;
