import { LazyQueryExecFunction } from "@apollo/client";
import { TableId } from "constants/Interface";
import { InputMaybe, Scalars, PageInfoFragment } from "generated/graphql";
import { useState, useEffect } from "react";
import { usePagination } from "twComponents/PaginationControl/helpers";

type PaginationVariables = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

type HookProps<TQueryData, TQueryVariables extends PaginationVariables> = {
  initialQueryVariables: TQueryVariables | undefined;
  lazyQuery: LazyQueryExecFunction<TQueryData, TQueryVariables>;
  loading: boolean;
  pageInfo: PageInfoFragment | undefined;
  tableId: TableId;
  totalNumberOfItems: number;
};

export const useLazyPaginatedQuery = <TQueryData, TQueryVariables extends PaginationVariables>({
  totalNumberOfItems,
  tableId,
  pageInfo,
  loading,
  initialQueryVariables,
  lazyQuery,
}: HookProps<TQueryData, TQueryVariables>) => {
  const [queryVariables, setQueryVariables] = useState(initialQueryVariables);

  const paginationProps = usePagination({
    totalNumberOfItems,
    pageInfo,
    loading,
    tableId,
  });
  const { first, last, after, before } = paginationProps.paginationQueryVariables;

  useEffect(() => {
    if (queryVariables) {
      lazyQuery({ variables: queryVariables });
    }
  }, [lazyQuery, queryVariables]);

  useEffect(() => {
    setQueryVariables((prevVariables) => {
      if (prevVariables) {
        return {
          ...prevVariables,
          first,
          before,
          after,
          last,
        };
      }
    });
  }, [after, before, first, last]);

  return { paginationProps, queryVariables, setQueryVariables };
};
