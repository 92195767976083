import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { getConnectSystemInitialValues, getConnectSystemInitialValuesEdit } from "helpers/settings";
import { useLocale } from "helpers/translations/src/useLocale";
import {
  GetSupportedSystems,
  GetSupportedSystemsVariables,
  GetSupportedSystems_integrationSystems_fields,
} from "api/Import/OtherSystems/types/GetSupportedSystems";
import { GET_SUPPORTED_SYSTEMS } from "api/Import/OtherSystems/queries";
import { getBUFromLocalStorage } from "helpers/storage";
import { useHasFeature, useGetCurrentBusinessUnit, useGetFarmSystems } from "hooks";
import ConnectToSystemForm from "pages/Settings/FarmSettings/ConnectedSystems/ConnectToSystemForm";
import { RequestToCTSWorkflow, ConnectedSystemItemView } from "components/Settings";
import { Button, Modal, Panel, Spacer, Text, EmptyState } from "components";

interface ConnectedSystemsProps {
  showSystemForm: boolean;
  toggleSystemForm: () => void;
}

export type SelectedSystem = {
  key: string | undefined;
  label: string | undefined;
  value: string | undefined;
  fields: GetSupportedSystems_integrationSystems_fields[] | undefined;
};

const ConnectedSystems: React.FC<ConnectedSystemsProps> = ({ showSystemForm, toggleSystemForm }) => {
  const { terms } = useLocale();

  const [editMode, toggleEditMode] = useState<EditConnectedSystemData | null>(null);
  const [isShowCTSRequestModal, toggleCTSRequestModal] = useState<boolean>(false);
  const { regulatorySystem } = useGetCurrentBusinessUnit();
  const { data: farmSystems, refetch: refetchFarmSystems } = useGetFarmSystems();
  const isBCMS = farmSystems.some(
    (item) => item?.integrationSystem?.slug === "BCMS_INTEGRATION" && item.status === "CONNECTED",
  );
  const isShowLinkForRequestToCTS: boolean =
    useHasFeature("SHOW_LINK_FOR_REQUEST_TO_CTS_FOR_CREDS") && regulatorySystem?.slug === "BCMS_INTEGRATION" && !isBCMS;
  const isEmptyStateVisible = !farmSystems.length;

  const { data: integrationSystems } = useQuery<GetSupportedSystems, GetSupportedSystemsVariables>(
    GET_SUPPORTED_SYSTEMS,
    {
      variables: {
        farm: getBUFromLocalStorage(),
        isAutomatedSystem: true,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    },
  );
  const filteredSystems =
    integrationSystems?.integrationSystems?.map((integrationSystem) => ({
      key: integrationSystem?.id,
      label: integrationSystem?.name,
      value: integrationSystem?.id,
      fields: integrationSystem?.fields,
    })) || [];

  const setEditData = (data): void => {
    toggleEditMode({
      id: data.id,
      integrationSystem: data.integrationSystem.id,
      value: data.integrationSystem.name,
      fields: data.integrationSystem.fields,
      credentials: data.credentials || {},
    });
    toggleSystemForm();
  };

  const [selectedSystem, setSelectedSystem] = useState<SelectedSystem>({
    key: "",
    label: "",
    value: "",
    fields: [],
  });

  const handleChangeSystem = (e, handleChange): void => {
    handleChange(e);
    const selSystem = filteredSystems.find((s) => s.key === e.target.value);
    if (selSystem) {
      setSelectedSystem(selSystem);
    }
  };

  const handleAddNewConnection = (): void => {
    toggleEditMode(null);
    toggleSystemForm();
  };

  const handleOpenModalForCTSRequest = (): void => {
    toggleCTSRequestModal(true);
  };

  const handleCloseModalForCTSRequest = (): void => {
    toggleCTSRequestModal(false);
  };

  return (
    <>
      {showSystemForm ? (
        <ConnectToSystemForm
          refetchFarmSystems={refetchFarmSystems}
          selectedSystem={editMode || selectedSystem}
          editMode={editMode}
          systems={filteredSystems}
          farmSystems={farmSystems}
          onCloseForm={toggleSystemForm}
          handleChangeSystem={handleChangeSystem}
          initialValues={
            editMode ? getConnectSystemInitialValuesEdit(editMode) : getConnectSystemInitialValues(selectedSystem)
          }
        />
      ) : null}
      {!showSystemForm ? (
        <Panel
          title="Connected systems"
          actions={[{ colour: "yellow", caption: "Add new connection", onClick: handleAddNewConnection }]}
        >
          <Text smaller>Connect to {terms.farm} management platforms here.</Text>
          <Spacer baselineHeight={2} />

          {isShowLinkForRequestToCTS ? (
            <>
              <Button
                colour="green"
                caption="Click here to request your CTS credentials"
                onClick={handleOpenModalForCTSRequest}
                width="full"
              />
            </>
          ) : null}

          {isShowLinkForRequestToCTS && !showSystemForm && !!farmSystems.length ? <Spacer baselineHeight={3} /> : null}

          {!showSystemForm && isEmptyStateVisible ? <EmptyState page="CONNECTIONS" /> : null}

          {!showSystemForm
            ? farmSystems.map((system, index) => (
                <ConnectedSystemItemView key={system.id || index} system={system} setEditData={setEditData} />
              ))
            : null}
        </Panel>
      ) : null}
      <Modal active={isShowCTSRequestModal} handleClose={handleCloseModalForCTSRequest} size="large">
        <RequestToCTSWorkflow closeModal={handleCloseModalForCTSRequest} />
      </Modal>
    </>
  );
};

export default ConnectedSystems;
