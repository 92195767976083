import * as yup from "yup";
// Types
import { GetFarmSystems_farmSystems_BcmsConnectionType_integrationSystem_fields as SystemField } from "api/Settings/types/GetFarmSystems";
import { SystemCredentialFieldFieldType } from "api/graphql-global-types";

/**
 * @description takes field type from BE and converts it into yup schema type
 */
const getValidationFieldType = (type: string): string => {
  switch (type.toLocaleLowerCase()) {
    case "text":
    case "email":
    case "password":
    case "location":
      return "string";

    default:
      return type.toLocaleLowerCase();
  }
};

/**
 * @description takes input mask string from BE and parses it for use in text mask field
 */
const getRegExpFromMask = (mask: string): RegExp => {
  const reArr = mask.split(",").map((el) => el.trim().slice(1, -1));
  reArr.unshift("^");
  reArr.push("$");
  const reStr = reArr.join("");
  return new RegExp(reStr);
};

/**
 * @description builds and returns a yup schema object from field data
 */
export const getValidationSchema = (fields: SystemField[] = []) => {
  // inject system field data for more integrated validation
  const systemOptionRules: SystemField = {
    id: "system",
    fieldLabel: "",
    fieldName: "system",
    fieldType: "TEXT" as SystemCredentialFieldFieldType,
    inputMask: "",
    maxLength: null,
    minLength: null,
    placeholder: "",
    __typename: "SystemCredentialFieldType",
  };

  const validationSchema = [...fields, systemOptionRules].reduce((schema, field): Record<string, unknown> => {
    const { fieldName, fieldType, inputMask = "", maxLength = null, minLength = null } = field;
    const dataType = getValidationFieldType(fieldType);

    if (!yup[dataType]) return schema;

    let validator = yup[dataType]();

    if (minLength) {
      validator = validator["min"](minLength, `This field should be at least ${minLength} characters`);
    }

    if (maxLength) {
      validator = validator["max"](maxLength, `This field should be no longer than ${maxLength} characters`);
    }

    if (inputMask) {
      const inputMaskRegex = getRegExpFromMask(inputMask);
      validator = validator["matches"](inputMaskRegex, {
        message: `This doesn't match the required format`,
      });
    }

    validator = validator["required"]("This field is required");

    return {
      ...schema,
      [fieldName]: validator,
    };
  }, {});

  return yup.object().shape(validationSchema);
};
