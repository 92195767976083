import * as React from "react";

import pure from "recompose/pure";
import toClass from "recompose/toClass";
import withProps from "recompose/withProps";

import classNames from "classnames";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialSelect from "@material-ui/core/Select";

import { SelectChevron } from "constants/SvgIcons";

import styles from "./styles.module.css";

interface SelectProps {
  name: string;
  label?: string;
  value: string;
  className?: string;
  filledStyle?: string;
  rootStyle?: string;
  children?: React.ReactNode;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => void;
  labelProps?: Record<string, unknown>;
  readonly theme?: string;
  subscription?: boolean;
  classNameSelect?: string;
}

export const Select = pure(
  ({
    name,
    label,
    value = "",
    error,
    className,
    onChange,
    children,
    labelProps,
    theme,
    rootStyle,
    filledStyle,
    subscription,
    classNameSelect,
  }: SelectProps) => (
    <FormControl
      className={classNames([
        styles.formControl,
        {
          ...(theme ? { [styles[theme]]: true } : {}),
        },
        className,
      ])}
    >
      <FormLabel
        htmlFor={`select-${name}`}
        focused={false}
        error={!!error}
        filled={!!value || false}
        classes={{
          root: classNames([styles.label, rootStyle]),
          focused: styles.focused,
          filled: classNames([styles.filled, filledStyle]),
          error: styles.error,
        }}
        className={classNames(theme && styles.withLabel)}
        {...labelProps}
      >
        {label}
      </FormLabel>
      <MaterialSelect
        id={`select-${name}`}
        value={value}
        onChange={onChange}
        renderValue={subscription ? (): React.ReactNode | null => <span>{value}</span> : undefined}
        IconComponent={SelectChevron}
        input={<Input name={name} id={`select-${name}`} disableUnderline />}
        MenuProps={{
          classes: {
            paper: classNames([
              styles.menu,
              {
                ...(theme ? { [styles[theme]]: true } : {}),
              },
            ]),
          },
          MenuListProps: {
            classes: {
              root: styles.menuList,
            },
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        classes={{
          root: styles.selectRoot,
          select: classNames([styles.select, classNameSelect]),
          icon: styles.icon,
        }}
      >
        {children}
      </MaterialSelect>
    </FormControl>
  ),
);

export const SelectOption = toClass(
  withProps((props: Record<string, unknown>) => ({
    classes: {
      root: styles.option,
    },
    ...props,
  }))(MenuItem),
);
