import { useMemo } from "react";
import { getBUFromLocalStorage } from "helpers/storage";
import { LoadingOverlay, Flex, Spacer, Table, TablePaginationTarget } from "components";
import { TableActionColumn } from "settings/components/ContactsTable/TableActionColumn";
import { getActionsColumn, TABLE_ROW_ACTIONS_COLUMN_ID } from "components/Common/Table";
import tablePaginationStyles from "components/Common/Table/TablePagination/TablePagination.module.scss";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { formatPhoneNumber, removeNothings } from "helpers/general";
import { useContactsQuery, ContactFragmentFragment } from "generated/graphql";
import { useLocale } from "helpers/translations/src/useLocale";
import { useGetCurrentBusinessUnit } from "hooks";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { EmptyStatesAddressBook } from "components/EmptyStates/AddressBook";

const COLUMN_IDS = {
  FARM_ID: "farmId",
  ORGANISATION_NAME: "organisationName",
  CONTACT_NAME: "name",
  ADDRESS: "address",
  EMAIL: "email",
  PHONE: "phoneNumber",
  CONTACT_CODE: "code",
  CONTACT_TYPE: "contactType",
  ACTION_BUTTONS: TABLE_ROW_ACTIONS_COLUMN_ID,
};

export const ContactsTable: React.FC = () => {
  const businessUnit = getBUFromLocalStorage();
  const { countryIsoCode } = useGetCurrentBusinessUnit();
  const { terms } = useLocale();

  const upperCasedCountryCode = countryIsoCode.toUpperCase();

  const { data, loading } = useContactsQuery({
    variables: { businessUnit },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const formatContactTypeString = (contactType: string): string => {
    const contactTypeInLowerCase = contactType.toLowerCase();
    return contactTypeInLowerCase.charAt(0).toUpperCase() + contactTypeInLowerCase.slice(1);
  };

  const tableId = "addressBookContactsTable";
  const contacts = removeNothings(data?.contacts || []);
  const isEmptyStateVisible = !contacts.length;

  const columns = useMemo(
    () => [
      {
        id: COLUMN_IDS.FARM_ID,
        Header: terms.farmId,
        accessor: "farmId",
        Cell: function FarmId({ row }) {
          return row.values[COLUMN_IDS.FARM_ID] || "\u2014";
        },
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.ORGANISATION_NAME,
        Header: "Business Name",
        accessor: "organisationName",
        Cell: function BusinessName({ row }) {
          return row.values[COLUMN_IDS.ORGANISATION_NAME] || "\u2014";
        },
        minWidth: 250,
        width: 250,
      },
      {
        id: COLUMN_IDS.CONTACT_NAME,
        Header: "Contact Name",
        accessor: "name",
        Cell: function ContactName({ row }) {
          return row.values[COLUMN_IDS.CONTACT_NAME] || "\u2014";
        },
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.ADDRESS,
        Header: "Address",
        accessor: (data): string | null => formatAddressString(data) || null,
        Cell: function Address({ row }) {
          return row.values[COLUMN_IDS.ADDRESS] || "\u2014";
        },
        minWidth: 250,
        width: 250,
      },
      {
        id: COLUMN_IDS.EMAIL,
        Header: "Email",
        accessor: "email",
        Cell: function Email({ row }) {
          return row.values[COLUMN_IDS.EMAIL] || "\u2014";
        },
        minWidth: 250,
        width: 250,
      },
      {
        id: COLUMN_IDS.PHONE,
        Header: "Phone",
        accessor: "phoneNumber",
        Cell: function Phone({ row }) {
          return (
            formatPhoneNumber({
              phoneNumber: row.original?.phoneNumber,
              countryCode: row.original?.country?.isoAlpha2,
            }) || "\u2014"
          );
        },
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.CONTACT_CODE,
        Header: "Contact Code",
        accessor: "code",
        Cell: function ContactCode({ row }) {
          return row.values[COLUMN_IDS.CONTACT_CODE] || "\u2014";
        },
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.CONTACT_TYPE,
        Header: "Contact Type",
        accessor: ({ contactType }): string | null => (contactType ? formatContactTypeString(contactType) : null),
        Cell: function ContactType({ row }) {
          return row.values[COLUMN_IDS.CONTACT_TYPE] === "Farm"
            ? capitaliseFirstLetter(terms.farm)
            : row.values[COLUMN_IDS.CONTACT_TYPE] || "\u2014";
        },
        minWidth: 150,
        width: 150,
      },
      {
        ...getActionsColumn(),
        Cell: ({ row: { original } }): JSX.Element => (
          <>
            <TableActionColumn addressId={original.id} />
          </>
        ),
        // @ts=expect-error
        sticky: "right",
      },
    ],
    [terms],
  );

  const hiddenColumns =
    upperCasedCountryCode === CountryISOCode.US ||
    upperCasedCountryCode === CountryISOCode.AU ||
    upperCasedCountryCode === CountryISOCode.GB
      ? []
      : [COLUMN_IDS.FARM_ID];

  if (loading) return <LoadingOverlay />;

  if (isEmptyStateVisible) {
    return <EmptyStatesAddressBook />;
  }

  if (!contacts.length) {
    return <EmptyStatesAddressBook />;
  }

  return (
    <>
      <Flex container>
        <Flex
          item
          itemGutter
          xs="fill"
          className={tablePaginationStyles.table_pagination_target}
          container
          containerJustifyContent="flex-end"
        >
          <TablePaginationTarget tableId={tableId} />
        </Flex>

        <Spacer baselineHeight={1} />

        <Flex item itemGutter xs="fill">
          <Table<ContactFragmentFragment>
            // @ts-expect-error
            columns={columns}
            hiddenColumns={hiddenColumns}
            data={contacts}
            pagination
            tableId={tableId}
          />
          <Spacer baselineHeight={2} />
        </Flex>
      </Flex>
    </>
  );
};

export const formatAddressString = ({
  addressLine1,
  addressLine2,
  city,
  country,
  postcode,
}: ContactFragmentFragment): string => {
  return (
    (addressLine1 ? `${addressLine1}, ` : "") +
    (addressLine2 ? `${addressLine2}, ` : "") +
    (city ? `${city}, ` : "") +
    (country && country?.name ? `${country.name}, ` : "") +
    (postcode ? `${postcode}` : "")
  );
};
