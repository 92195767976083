import { atomFamily } from "recoil";
// Utils
import { DEFAULT_LIVEWEIGHT_FILTERS, LiveweightFiltersState } from "trading/hooks/useListingFilters/config";
import {
  DEFAULT_LIVEWEIGHT_FILTERS as DEFAULT_LIVEWEIGHT_FILTERS_SERVER_SIDE,
  LiveweightFiltersState as LiveweightFiltersStateServerSide,
} from "trading/hooks/useListingFiltersServerSide/config";

export const liveweightFilters = atomFamily<LiveweightFiltersState, string>({
  key: "liveweightFilters",
  default: DEFAULT_LIVEWEIGHT_FILTERS,
});

export const liveweightFiltersServerSide = atomFamily<LiveweightFiltersStateServerSide, string>({
  key: "liveweightFilters",
  default: DEFAULT_LIVEWEIGHT_FILTERS_SERVER_SIDE,
});
