import { AnimalBreed } from "generated/graphql";
import { FC } from "react";
import { Tooltip } from "components/Common/Tooltip";

export type CustomBreedPercentage = {
  breed: Pick<AnimalBreed, "id" | "name" | "breedrCode"> | null;
  percentage?: number | null;
};

export interface BreedPercentagesProps {
  breedPercentages: CustomBreedPercentage[] | undefined;
  orientation?: "row" | "col";
  showFullName?: boolean;
}

export const AnimalsBreedPercentages: FC<BreedPercentagesProps> = ({
  breedPercentages,
  orientation = "row",
  showFullName = false,
}) => {
  if (!breedPercentages || !breedPercentages.length) return null;
  return (
    <div className={`flex flex-${orientation}`}>
      {breedPercentages.map((b) => (
        <div className="flex items-center" key={b?.breed?.id}>
          {showFullName ? (
            <span className="pr-1">{`${b?.breed?.name}${b?.percentage ? `: ${b?.percentage}%` : ""}`}</span>
          ) : (
            <Tooltip title={b?.breed?.name || ""}>
              <span className="pr-1">{`${b?.breed?.breedrCode}${b?.percentage ? `:${b?.percentage}%` : ""}`}</span>
            </Tooltip>
          )}
        </div>
      ))}
    </div>
  );
};
