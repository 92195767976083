// React
import React from "react";
// Utils
import { GetAnimalsFromField_field_animals } from "api/MyLivestock/Fields/types/GetAnimalsFromField";
import { TABLE_IDS } from "constants/Interface";
import { useAnimals, useHasFeature } from "hooks";
// Components
import { AnimalsTable, AnimalFiltersBlock } from "components/MyLivestock";
import { Flex, NoItemsFound, Spacer } from "components";
// Resources
import styles from "./AnimalsInField.module.scss";

export interface AnimalsInFieldProps {
  animals?: (GetAnimalsFromField_field_animals | null)[] | null | undefined;
}

const AnimalsInField: React.FC<AnimalsInFieldProps> = ({ animals }) => {
  const { data: resultedAnimals, animalsCount } = useAnimals(animals);

  const animalsCountLabel = `Showing ${resultedAnimals?.length} of ${animalsCount}`;
  const animalsToExport: string[] | undefined = resultedAnimals?.map((item) => item?.id);

  const isShowAnimalsFilter = useHasFeature("ANIMALS_FILTER");
  if (!animals || animals.length === 0) {
    return (
      <>
        <NoItemsFound title={"No animals in the location"} />
      </>
    );
  }

  return (
    <>
      {isShowAnimalsFilter ? (
        <>
          <AnimalFiltersBlock
            excludedFields={{ fields: true, ageAtDelivery: true, weightAtDelivery: true }}
            animalsCountLabel={animalsCountLabel}
            animalsToExport={animalsToExport}
            countAnimalsToExport={resultedAnimals?.length}
            tableId={TABLE_IDS.FIELD_ANIMALS}
          />

          <Spacer baselineHeight={1} />
        </>
      ) : null}

      <Flex className={styles.prevent_tiny_table} container item itemGrow>
        <Flex item itemGutter xs={12}>
          <AnimalsTable
            // @ts-ignore
            animals={resultedAnimals}
            fromPage="field"
            tableId={TABLE_IDS.FIELD_ANIMALS}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default AnimalsInField;
