// API
import { MyLots_myLots_edges_node } from "trading/api/Liveweight/types/MyLots";
import { BuyingLotNode } from "trading/components/containers/liveweight/BuyerListings";

export const getListingFormattedDistance = (
  listing: BuyingLotNode | MyLots_myLots_edges_node,
  distanceUnit: string | undefined = "",
): string | null => {
  if (listing.__typename === "OwnLot") return null;

  if (listing.distance === null || listing.distance === undefined || listing.distance < 0) {
    return "Unknown";
  }

  return Math.round(listing.distance) + distanceUnit;
};
