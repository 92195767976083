// @ts-nocheck
import React from "react";

import pure from "recompose/pure";

import cn from "classnames";

import Popover from "@material-ui/core/Popover";

import { ANIMALS_TABLE_ACTION_TYPES } from "constants/Animals";

import styles from "./styles.module.css";

interface Props {
  open: boolean;
  hasGroup: boolean;
  isFarmWithGroups: boolean;
  anchorEl: string;
  id: string;
  onClose: () => void;
  placement: string;
  actions: Array<{
    key: string;
    title: string;
  }>;
  tableTooltipPress: (event: React.SyntheticEvent<HTMLInputElement>, id: string) => void;
}

const TableTooltip: React.FC<Props> = ({
  open,
  anchorEl,
  id,
  tableTooltipPress,
  placement,
  actions,
  hasGroup,
  onClose,
  isFarmWithGroups,
}) => (
  <Popover
    className={cn(styles.root, styles[`tooltip_${placement}`])}
    id={id}
    open={open}
    onClose={onClose}
    anchorEl={document.getElementById(anchorEl)}
    placement={placement}
    anchorOrigin={{
      vertical: "center",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: 90,
    }}
  >
    <div>
      <div className={styles[`talk_bubble_triangle_${placement}`]} />
      {/*<div className={styles[`menu_${placement}`]}>*/}
      {placement === "bottom" ? <span className={styles.actionsLabel}>Actions:</span> : null}
      {actions.map((action) => {
        const isActionDisabled = !isFarmWithGroups && action.key === ANIMALS_TABLE_ACTION_TYPES.ADD_TO_GROUP;
        return (
          <div
            id={`action_${action.key}`}
            key={`action_${action.key}`}
            className={cn(styles.tableActionItem, {
              [styles.itemDisabled]: isActionDisabled,
            })}
            onClick={(e): void => !isActionDisabled && tableTooltipPress(e, action.key)}
          >
            <span className={isActionDisabled ? styles.tableActionTitleDisabled : styles.tableActionTitle}>
              {action.key === ANIMALS_TABLE_ACTION_TYPES.ADD_TO_GROUP && hasGroup ? "Change group" : action.title}
            </span>
          </div>
        );
      })}
    </div>
    {/*</div>*/}
  </Popover>
);

export default pure(TableTooltip);
