export const FEATURES = {
  SIRES: "sires",
  ANIMAL_SELECTION: "show-animals-global-selection",
  TERMS_ADN_COND: "terms-and-conditions",
  REPORTS: "show-reports",
  FIELDS: "show-fields",
  TEST_POPUP_MESSAGE: "popup-message-at-sign-in",
  SUPPLIER_MANAGEMENT: "supplier-management",
  SUPPLIERS_TC: "supplier-management-terms-and-conditions",
  SUPPLIER_MANAGEMENT_EDIT_FIELDSMAN: "supplier-management-edit-fieldsman",
  ANIMALS_ARCHIVE: "web-archived-animals",
  ANIMALS_ARCHIVE_EXPORT: "web-archived-animals-export",
  ANIMALS_FILTER: "show-animals-filter",
  ANIMALS_FILTER_IN_COMMIT_TO_RFL: "show-animals-filter-on-the-commit-to-rfl-page",
  SHOW_HOME_PAGE: "home-page",
  SHOW_REPORT_ON_HOME_PAGE: "show-report-on-homepage",
  SHOW_NEW_ACTIVITY_DESCRIPTION: "show-new-activity-description",
  SAVE_ANIMAL_FILTER_AS_ALERT: "save-animal-filter-template-as-alert",
  USE_ACTIVITY_CHART_QUERY: "get-activity-list-with-chart",
  BCMS_PUSH: "bcms-push",
  SHOW_NAG_MESSAGE_ABOUT_BCMS: "business-unit-warnings",
  SHOW_MOVE_TO_BU_SELECTOR: "move-animals-to-business-unit",
  SHOW_ANIMALS_FOR_BUYER: "show-animals-for-buyer",
  SHOW_BUYERS_TAB_TO_FARMERS: "show-buyer-partners-to-farmerbu",
  SHOW_SELLERS_TAB_TO_FARMERS: "show-seller-partners-to-farmerbu",
  SHOW_DISTRIBUTION_LISTS: "show-distribution-lists",
  BUYER_CAN_SEE_FULFILLMENT_STATUS_ON_TRADES: "web-buyer-can-see-fulfillment-status-on-trades",
  SHOW_OFFLINE_PAGE_FOR_REPORTS: "show-offline-page-for-reports",
  SHOW_LINK_FOR_REQUEST_TO_CTS_FOR_CREDS: "show-link-for-request-to-CTS-for-creds",
  ENABLE_INDIVIDUAL_RFL_SHARING: "enable-rfl-private-sharing",
  TRADING_START_UP_MESSAGES: "trading-start-up-messages",
  SHOW_REGULATORY_CHANGE_DATE_ACTION: "enable-change-date-for-regulatory-transaction",
  SHOW_REGULATORY_ABORT_ACTION: "enable-abort-for-regulatory-transaction",
  IS_ENABLE_REGION_AWARENESS: "enable-region-awareness",
  SHOW_ADDRESS_BOOK: "address-book",
  SHOW_SEX_CLASSIFICATION: "web-sex-classification",
  BANK_DETAILS: "bank-details",
  SHOW_GROUP_REDESIGN: "web-enable-group-summary-card-redesigned",
  SHOW_FIELD_REDESIGN: "web-enable-field-summary-card-redesigned",
  SHOW_DELETE_ACTIVITY: "web-enable-delete-activity-feature",
  SHOW_LOG_ACTIVITY: "web-log-activities",
  SHOW_ADMINISTER_MEDICINE: "administer-medicine-web",
  SHOW_ANIMAL_OFFSPRING: "animal-offspring-web",
  SHOW_PREGNANCY_STATUS: "animal-pregnancy-status-web",
  SPECIES_SWITCH: "species-switch-web",
  SCOTMOVES: "scotmoves",
  SHOW_IMPORT_FLOW_2021: "show-new-import-flow",
  SHOW_SUBSCRIPTION: "web-subscription",
  QUICKSIGHT_REPORTING: "quicksight-reporting",
  SERVER_SIDE_PAGINATION_FOR_ANIMALS_LIST: "server-side-pagination-for-animals-list",
  SERVER_SIDE_PAGINATION_FOR_ARCHIVE_LIST: "server-side-pagination-for-archive-list",
  SHOW_PAYWALL: "show-paywall",
  SHOW_TAGS: "show-tags",
  SHOW_INTEGRATIONS: "show-integrations",
  HAS_EXTENDED_ANIMAL_IDS: "has-extended-animal-ids",
  FINANCIAL_TRANSACTIONS: "financial-transactions",
  GENETICS: "epds",
  PASSPORT_NUMBER_IS_OPTIONAL: "passport-number-is-optional",
  SHOW_FAMILY_TREE: "web-show-family-tree",
  OSHKOSH: "oshkosh",
  TRIO_VERIFIED: "trio-verification",
  // Deliveries
  OUTWARD_MOVEMENTS: "outward-movements",
  INWARD_MOVEMENTS: "inward-movements",
  CREATE_INWARD_MOVEMENTS: "create-inward-movements",
  NETWORK_MOVEMENTS: "network-movements",
  UNDO_MOVEMENT: "undo-movement",
  MOVE_OFF_DRAFT_CSV_IMPORT: "move-off-draft-csv-import",
  ENABLE_HUBSPOT_CHAT: "enable-hubspot-chat",
};
