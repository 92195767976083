import React from "react";
// Components
import { Header, PageContent, PageHeader, Spacer } from "components";
import { BankDetailsList } from "components/Settings";

const BankDetails: React.FC = () => {
  return (
    <>
      <PageHeader>
        <Header title="Bank Details" subTitle="Manage your bank details" />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={2} />
        <BankDetailsList />
      </PageContent>
    </>
  );
};

export default BankDetails;
