import React from "react";
import { IconLabel, Tooltip } from "components";

interface AnimalPedigreeLabelProps {
  id?: string;
  passportNumber: string;
  isPedigree: boolean;
}

export const AnimalPedigreeLabel: React.FC<AnimalPedigreeLabelProps> = ({ id, passportNumber, isPedigree }) => {
  return (
    <Tooltip title={isPedigree ? "Pedigree" : "Not Pedigree"}>
      <IconLabel
        icon="breeding"
        colour={isPedigree ? "green" : "white"}
        id={id}
        label={passportNumber}
        labelPosition="left"
      />
    </Tooltip>
  );
};
