import { RefObject, useEffect } from "react";

export const useOutsideClick = (ref: RefObject<HTMLElement>, action: () => void): void => {
  const handleOutsideClick = (event): void => {
    if (ref.current && !ref.current.contains(event.target)) {
      action();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return (): void => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });
};
