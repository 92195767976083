import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
// Helpers
import { getBUFromLocalStorage } from "helpers/storage";
// Api
import { GET_REGULATORY_TRANSACTION_WARNINGS } from "api/Regulatory/queries";
// Types
import {
  RegulatoryTransactionWarnings,
  RegulatoryTransactionWarningsVariables,
  RegulatoryTransactionWarnings_regulatoryTransactionWarnings as WarningItem,
} from "api/Regulatory/types/RegulatoryTransactionWarnings";
// Components
import { LoadingOverlay, Alert, Text } from "components";
import { SelectedCellProps, TransactionType } from "components/Regulatory/Regulatory";

const getWarningMessage = ({ count, type, lapseOfTime }: WarningItem): string => {
  const isCountExist = Boolean(count && count > 1);
  const movementHaveHasString: string = isCountExist ? "movements that have" : "movement that has";
  const timeInDays: string = lapseOfTime ? `${lapseOfTime / 24} days` : "24 days";
  const timeInHours: string = lapseOfTime ? `${lapseOfTime} hours` : "36 hours";

  switch (type as TransactionType) {
    case "REGISTER_BIRTH": {
      const haveHasString: string = isCountExist ? "registrations that have" : "registration that has";

      return `You have ${count} birth ${haveHasString} not completed in ${timeInDays}`;
    }
    case "MOVE_ON": {
      return `You have ${count} move-on ${movementHaveHasString} not completed in ${timeInHours}`;
    }
    case "MOVE_OFF": {
      return `You have ${count} move-off ${movementHaveHasString} not completed in ${timeInHours}`;
    }
    case "REGISTER_DEATH": {
      return `You have ${count} death ${movementHaveHasString} not completed in ${timeInDays}`;
    }
    default:
      return "";
  }
};

export const Warnings: React.FC<SelectedCellProps> = ({ selectedCell: { refreshId }, onSetSelectedCell }) => {
  const { data, loading, refetch } = useQuery<RegulatoryTransactionWarnings, RegulatoryTransactionWarningsVariables>(
    GET_REGULATORY_TRANSACTION_WARNINGS,
    {
      variables: {
        businessUnitId: getBUFromLocalStorage(),
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (refreshId) {
      refetch();
    }
  }, [refreshId, refetch]);

  const warnings = (data?.regulatoryTransactionWarnings || []) as WarningItem[];

  const handleAlertClick = (warningData: WarningItem) => (): void => {
    onSetSelectedCell({
      transactionType: warningData.type as TransactionType,
      statuses: ["PENDING", "IN_PROGRESS", "FAILED"],
      column: "",
    });
  };

  return (
    <>
      {loading ? <LoadingOverlay /> : null}

      {!!warnings.length
        ? warnings.map((item, index) => (
            <Alert
              key={item.type || index}
              text={getWarningMessage(item)}
              color="AMBER"
              size="medium"
              onClick={handleAlertClick(item)}
            />
          ))
        : null}

      {!warnings.length && !loading ? <Text secondary>Yippee!! You have no warnings</Text> : null}
    </>
  );
};
