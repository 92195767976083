import { useEffect, useState, FC } from "react";
import { ImportWizardLoading } from "components/Import";
import { Button, Icon, Paper, Pill } from "components";
import { CsvImportType } from "import/types";
import { IdentifierUsageErrorType, ImportResultQueryResult, useImportResultQuery } from "generated/graphql";
import { AnimalsList } from "animals/AnimalsList";
import { TABLE_IDS } from "constants/Interface";
import { identifierNameLookup } from "components/Settings/IdPreferencesForm/helpers";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";

interface ImportWizardResultProps {
  fileName: string;
  importType: CsvImportType;
  onClickRestart: () => void;
  onSuccess: () => void;
  handleSuccessRedirect: () => void;
  redirectButtonLabel: string;
  taskId: string;
}

export const ImportWizardResult: FC<ImportWizardResultProps> = ({
  fileName,
  importType,
  onClickRestart,
  onSuccess,
  taskId,
  handleSuccessRedirect,
  redirectButtonLabel,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<NonNullable<ImportResultQueryResult["data"]>["importResult"] | null>(null);
  const { terms } = useLocale();

  const { data } = useImportResultQuery({
    pollInterval: !isLoading ? 0 : 5000,
    skip: !isLoading,
    variables: {
      task: taskId,
    },
  });

  const isDone = data?.importResult?.isDone;

  useEffect(() => {
    if (isDone) {
      setIsLoading(false);
      setResult(data.importResult);
      onSuccess();
    }
  }, [isDone]);

  const fileNamePillCaption = `File: ${fileName}`;

  const hasFailedImports = result?.rejectedRowsUrl;

  const handleClickDownloadFailedAnimals = () => {
    if (result?.rejectedRowsUrl) {
      window.open(result?.rejectedRowsUrl, "Download");
    }
  };
  const handleClickDownloadFailedActivities = () => {
    if (result?.failedActivitiesUrl) {
      window.open(result?.failedActivitiesUrl, "Download");
    }
  };

  const numberOfRows = result?.processedRows || 0;
  const numberOfFailedRows = result?.rowErrors?.length || 0;
  const singleFailedRow = numberOfFailedRows === 1;
  const numSuccessfullyImportedRows = numberOfRows - numberOfFailedRows;
  const singleSuccessfulRow = numSuccessfullyImportedRows === 1;
  const hasImportFailed = numberOfRows === numberOfFailedRows;
  const hasAnimalOnSystemErrors = !!result?.rowErrors?.some((rowError) => rowError.identifierUsageError !== null);

  const allAnimalOnSystemIds = result?.rowErrors
    ?.flatMap((rowError) =>
      !!rowError.identifierUsageError ? rowError?.identifierUsageError?.animals?.map((animal) => animal.id) : [],
    )
    .filter(Boolean);

  return (
    <>
      {isLoading ? (
        <Paper dark>
          <div className="flex flex-col items-center">
            <div className="h-4" />
            <ImportWizardLoading text="Importing file" />
            <div className="h-4" />
          </div>
        </Paper>
      ) : !hasImportFailed ? (
        <Paper colour="green" dark>
          <div className="flex flex-col items-center">
            <div className="h-4" />

            <span className="text-green-900 font-bold">{`${numSuccessfullyImportedRows} ${
              singleSuccessfulRow ? "Row" : "Rows"
            } Imported`}</span>

            <div className="h-2" />

            <Pill caption={fileNamePillCaption} colour="green" colourInverted />

            <div className="h-2" />
            <span className="text-sm text-red-900">
              <strong>
                {importType === "movement"
                  ? "View and complete from the draft movements list"
                  : importType === "animal"
                  ? `${numSuccessfullyImportedRows} ${singleSuccessfulRow ? "Animal" : "Animals"} Successfully Imported`
                  : `${numSuccessfullyImportedRows} ${
                      singleSuccessfulRow ? "Activity" : "Activities"
                    } Successfully Imported`}
              </strong>
            </span>
            <div className="h-4" />
          </div>
        </Paper>
      ) : null}

      {!!result?.rowErrors?.length ? (
        <>
          <div className="h-2" />

          <Paper className="bg-red-50" key="rowErrors">
            <div className="flex flex-col items-center p-4 text-center">
              <div className="h-4" />

              <span className="text-lg text-red-800 font-bold">{`${numberOfFailedRows} ${
                singleFailedRow ? "Row" : "Rows"
              } Failed`}</span>

              {result?.rowErrors?.map(({ errors, identifierUsageError, rowIndex }, index) => (
                <>
                  <div className="pb-4">
                    {identifierUsageError ? (
                      <span
                        key={`${rowIndex}-${identifierUsageError?.idValue}`}
                        className="text-sm font-semibold text-red-900"
                      >
                        {identifierUsageError.errorType === IdentifierUsageErrorType.AmbiguousAnimal ? (
                          <>
                            <span>{`Row ${rowIndex ?? index}: The animals in the below table have been found within the
                              system using the ${identifierNameLookup(terms, identifierUsageError.idType)}: ${
                              identifierUsageError.idValue
                            }. Please try to update the animals again using the Breedr ID.`}</span>
                          </>
                        ) : identifierUsageError.errorType === IdentifierUsageErrorType.IdentifierInUse ? (
                          <>
                            <span>{`Row ${rowIndex ?? index}: The ${identifierNameLookup(
                              terms,
                              identifierUsageError.idType,
                            )}: ${identifierUsageError.idValue} is already in use. As this ID must be unique 
                              please use a different identifier or update the current animal with the same ID shown in the list below.`}</span>
                          </>
                        ) : null}
                      </span>
                    ) : (
                      errors?.map(({ message, field }) => (
                        <span key={`${rowIndex}-${message}`} className="text-sm font-semibold text-red-900">
                          Row {rowIndex}: {capitaliseFirstLetter(field)}- {message}
                        </span>
                      ))
                    )}
                  </div>
                </>
              ))}
              {hasAnimalOnSystemErrors ? (
                <>
                  <div className="pt-8 flex-col w-full">
                    <AnimalsList
                      tableFilterIdentifier="animalsList"
                      animalIds={allAnimalOnSystemIds}
                      showAnimalActionButtons={false}
                      showSelectColumn
                      defaultToSelectedAnimalsView
                      isCSVErrors={true}
                      tableId={TABLE_IDS.CSV_EXPORT_ERROR}
                      showFiltersButton={false}
                    />
                    <span className="text-xs text-red-900 flex justify-end p-4">
                      <Icon name="info" size="tiny" />
                      <span className="pl-2">You can download the above table as a CSV to obtain the Breedr IDs</span>
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </Paper>
        </>
      ) : null}

      <div className="h-4" />

      <div className="flex justify-between">
        <Button caption="Start again" colour="grey" onClick={onClickRestart} variant="hollow" />

        {hasFailedImports ? (
          <>
            {result?.rejectedRowsUrl ? (
              <Button
                caption="Download failed animals CSV"
                colour="grey"
                onClick={handleClickDownloadFailedAnimals}
                variant="hollow"
              />
            ) : null}
            {result?.failedActivitiesUrl ? (
              <Button
                caption="Download failed activities CSV"
                colour="grey"
                onClick={handleClickDownloadFailedActivities}
                variant="hollow"
              />
            ) : null}
          </>
        ) : null}
        <Button caption={redirectButtonLabel} onClick={handleSuccessRedirect} />
      </div>
    </>
  );
};
