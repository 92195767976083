import { gql } from "@apollo/client";
import { errorFragment, sireFragment } from "./fragment";

export const DELETE_SIRE = gql`
  mutation DeleteSire($input: DeleteSireInput!) {
    deleteSire(input: $input) {
      success
    }
  }
`;

export const CREATE_SIRE = gql`
  mutation CreateSire($input: CreateSireInput!) {
    createSire(input: $input) {
      sire {
        ...sireFragment
      }
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${sireFragment}
`;

export const UPDATE_SIRE = gql`
  mutation UpdateSire($input: UpdateSireInput!) {
    updateSire(input: $input) {
      sire {
        ...sireFragment
      }
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
  ${sireFragment}
`;
