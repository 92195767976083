import { useQuery, QueryResult } from "@apollo/client";
import { useParams } from "react-router-dom";
// Api
import { GET_MY_LOT } from "trading/api/Liveweight/queries";
import { MyLot, MyLotVariables } from "trading/api/Liveweight/types/MyLot";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";

type Params = {
  lotId?: string;
  skip?: boolean;
};

export const useGetMyLot = ({ lotId, skip }: Params): QueryResult<MyLot, MyLotVariables> => {
  const { id: idFromParams } = useParams<LiveweightListingPageParams>();

  const data = useQuery<MyLot, MyLotVariables>(GET_MY_LOT, {
    variables: {
      id: Number(lotId) || Number(idFromParams),
      businessUnit: getBUFromLocalStorage(),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    skip: skip || (!lotId && !idFromParams),
  });

  return data;
};
