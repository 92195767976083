import { gql } from "@apollo/client";

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on ContactObjectType {
    id
    contactType
    organisationName
    farmId
    name
    addressLine1
    addressLine2
    postcode
    city
    state
    email
    phoneNumber
    country {
      id
      name
      isoAlpha2
      code
    }
    code
  }
`;
