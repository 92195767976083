import { InputSelectOption } from "components/Common/Field/Select";
import { DeliveryDirectionEnum, useDeliveryReasonsQuery } from "generated/graphql";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";

export const useDeliveryReasons = (deliveryDirection: DeliveryDirectionEnum) => {
  const { id } = useGetCurrentBusinessUnit();

  const { data: deliveryReasonsData } = useDeliveryReasonsQuery({
    variables: { businessUnit: id, isActive: true, directions: deliveryDirection },
  });

  const deliveryReasons: InputSelectOption[] = (deliveryReasonsData?.deliveryReasons?.edges || []).map((reason) => {
    return {
      value: reason?.node?.id || "",
      label: reason?.node?.title || "",
      key: reason?.node?.id || "",
    };
  });

  return {
    deliveryReasons,
    deliveryReasonsData,
  };
};
