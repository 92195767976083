import { FC } from "react";
// Libraries
import cn from "classnames";
// Components
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// Resources
import styles from "./SnackBar.module.scss";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  default: null,
};

interface SnackBarNotificationProps {
  anchorOrigin?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "right" | "center";
  };
  autoHideDuration?: number;
  className?: string;
  message: string | JSX.Element;
  onClose?: () => void;
  open: boolean;
  variant: string;
}

export const SnackBarNotification: FC<SnackBarNotificationProps> = ({
  autoHideDuration = 6000,
  open,
  variant,
  message,
  className,
  onClose,
  anchorOrigin,
}) => {
  const Icon = variantIcon[variant];
  return (
    <div className={styles.snackbar}>
      <Snackbar
        anchorOrigin={
          anchorOrigin || {
            vertical: "top",
            horizontal: "right",
          }
        }
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        classes={{
          root: cn(styles.snackbar__inner, {
            [styles["snackbar__inner--central"]]: anchorOrigin?.horizontal,
          }),
        }}
      >
        <SnackbarContent
          className={cn(styles[variant], className)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={styles.message}>
              {Icon ? <Icon className={cn(styles.icon, styles.iconVariant)} /> : null}
              {message}
            </span>
          }
          action={
            onClose
              ? [
                  <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={styles.icon} />
                  </IconButton>,
                ]
              : null
          }
        />
      </Snackbar>
    </div>
  );
};
