import { FC } from "react";
import { Text, Icon, Button } from "components";
import { Paper } from "twComponents/Paper";
import { Pill } from "twComponents/Pill";
import { ICON_NAMES } from "constants/Icons";
import { useHover } from "hooks";
import { BulletObjectType } from "generated/graphql";
import { SUPPLY_CHAIN_FORM } from "constants/Routes";
import { useHistory } from "react-router-dom";

export interface MediaCardProps {
  id: string;
  title: string;
  text: string;
  imgSrc: string;
  metrics?: Array<Metrics> | undefined;
  bullets?: Array<BulletObjectType> | undefined;
}

interface Metrics {
  icon: keyof typeof ICON_NAMES;
  text: string;
}

export const SupplyChainSummary: FC<{ item: MediaCardProps }> = ({ item }) => {
  const { id, title, text, imgSrc, metrics, bullets } = item;
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const history = useHistory();
  const formRoute = SUPPLY_CHAIN_FORM;

  const handleLearnMoreClick = (): void => {
    history.push(formRoute);
  };

  return (
    <div id={id} className={"block no-underline"} ref={hoverRef}>
      <Paper className={`p-4 xl:w-[75%] cursor-pointer ${isHovered ? "shadow-lg" : "shadow-sm"}`}>
        <div className={`flex flex-nowrap`}>
          <div className="relative inline-block rounded-md overflow-hidden mr-4 w-60 min-h-64">
            <img
              className={`object-cover h-full w-full transition-transform duration-300 transform 
              ${isHovered ? "scale-110" : "scale-100"}`}
              src={imgSrc}
              alt={title}
            />
          </div>
          <div className="flex flex-col flex-1">
            <div className="flex">
              <Text className="font-bold text-2xl mb-2" colour={"grey800"}>
                {title}
              </Text>
            </div>
            <div className="flex">
              {metrics?.map((metric, index) => (
                <div className="flex" key={index}>
                  <Pill caption={metric.text} className={"mb-2 mr-4 min-w-36"} icon={metric.icon} />
                </div>
              ))}
            </div>
            <div className="flex pr-24 pb-2">
              <Text className="text-md text-gray-900">{text}</Text>
            </div>
            <div className="flex flex-col">
              {bullets?.map((bullet) => (
                <div className="flex flex-row pb-1 pr-24 text-gray-900 text-sm" key={bullet.order}>
                  <div>
                    <Icon name={"check"} size="small" colour="green" />
                  </div>
                  <span className="pl-2 text-justify">{bullet.text}</span>
                </div>
              ))}
            </div>
            <div className="flex justify-end">
              <Button caption="Learn more" className="shadow-sm" onClick={handleLearnMoreClick} />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};
