import { Modal } from "components";
import { useFormContext } from "react-hook-form";
import { TransactionValidationSchema as S } from "financial/components/TransactionForm/validation";
import { ConfirmNavigationModalProps } from "../hooks/useConfirmNavigationModal";

type Props = { loading: boolean } & ConfirmNavigationModalProps;

export const NavigationConfirmationModal = ({
  loading,
  showNavigationModal,
  setShowNavigationModal,
  handleConfirmNavigation,
}: Props) => {
  const methods = useFormContext<S>();

  if (!methods) {
    throw new Error("NavigationConfirmationModal must be used inside a <Form /> or <FormProvider />");
  }

  return (
    <>
      <Modal
        active={showNavigationModal}
        title="Are you sure?"
        subTitle="This transaction will be lost if you exit the page."
        actions={{
          primary: {
            caption: "Confirm",
            disabled: loading,
            requesting: loading,
            onClick: () => handleConfirmNavigation(methods.watch("transactionId")),
          },
          secondary: {
            caption: "Cancel",
            onClick: () => setShowNavigationModal(false),
          },
        }}
      />
    </>
  );
};
