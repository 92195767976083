import { InputSelectOption } from "components/Common/Field/Select";
import { AnimalConditionsQuery, useAnimalConditionsQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { getBUFromLocalStorage } from "helpers/storage";
import { useMemo } from "react";
import { sortBy } from "lodash";

export type AnimalCondition = NonNullable<NonNullable<NonNullable<AnimalConditionsQuery>["animalConditions"]>[0]>;

export const useGetAnimalConditions = () => {
  const { data, ...rest } = useAnimalConditionsQuery({
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const animalConditions = removeNothings(data?.animalConditions || []);

  const animalConditionOptions: InputSelectOption[] = useMemo(() => {
    const options = animalConditions.map((condition) => {
      return {
        value: condition.id,
        label: condition.title,
        key: condition.id,
      };
    });

    return sortBy(options, [(option): string => option.label]);
  }, [animalConditions]);

  return {
    animalConditionOptions,
    data,
    ...rest,
  };
};
