// @ts-nocheck
import React, { useContext } from "react";
import pure from "recompose/pure";
import { CommonContext } from "config/commonProvider";
import { TableActionButton } from "components";
import { SIRE_TABLE_ACTION_TYPES, SIRE_TABLE_ACTIONS } from "constants/Sires";
import { validationSchemaConfirmDelete } from "helpers/general";
import { SIRES } from "constants/Routes";
import ConfirmSireActionModal from "../ConfirmSireActionModal";
import TableTooltip from "../TableTooltip";

interface Props {
  sire: Record<string, unknown>;
  error?: string;
  selectedRow?: Sire;
  showModal: boolean;
  requesting: boolean;
  history: Record<string, unknown>;
  sireDelete: (showNotification: () => void) => void;
  setModalShow: (id: string | boolean) => void;
  setSelectedRow: (id: string | null) => void;
  setError: (error?: string | null) => void;
}

const TableActionColumn: React.FC<Props> = ({
  sire,
  error,
  setError,
  history,
  sireDelete,
  showModal,
  requesting,
  selectedRow,
  setSelectedRow,
  setModalShow,
}) => {
  const { showNotification } = useContext(CommonContext);

  const handleSireDelete = (): void => {
    sireDelete(showNotification);
  };

  const handleCloseModal = (): void => {
    setModalShow(false);
    setError(null);
  };

  return (
    <div
      id={sire.id}
      onClick={(e): void => {
        e.stopPropagation();

        if (selectedRow && sire.id === selectedRow.id) {
          setSelectedRow(null);
        } else {
          setSelectedRow(sire);
        }
      }}
    >
      <TableActionButton />
      {selectedRow && selectedRow.id === sire.id ? (
        <TableTooltip
          id={sire.id}
          open={selectedRow && selectedRow.id === sire.id}
          placement="right"
          onClose={(): void => setModalShow(false)}
          anchorEl={selectedRow ? selectedRow.id : null}
          actions={SIRE_TABLE_ACTIONS}
          tableTooltipPress={(e, key): void => {
            e.stopPropagation();
            setSelectedRow(null);

            if (key === SIRE_TABLE_ACTION_TYPES.SIRE_EDIT) {
              history.push(`${SIRES}/${sire.id}`);
              return;
            }
            setModalShow(key);
          }}
        />
      ) : null}
      <ConfirmSireActionModal
        error={error}
        requesting={requesting}
        showModal={showModal === SIRE_TABLE_ACTION_TYPES.SIRE_DELETE}
        title="Delete"
        validationSchema={validationSchemaConfirmDelete}
        onClose={handleCloseModal}
        onConfirm={handleSireDelete}
        subTitle={`Type 'DELETE' to remove ${sire.passportNumber} from the system`}
      />
    </div>
  );
};

export default pure(TableActionColumn);
