import { GetBusinessUnitBreedsQuery, useGetBusinessUnitBreedsQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { useStateSpecies } from "./useStateSpecies";
import { removeNothings } from "helpers/general/all";
import { InputSelectOption } from "components/Common/Field/Select";
import { useMemo } from "react";

interface Input {
  forAllSpecies?: boolean;
}

export type AnimalBreeds = Array<NonNullable<NonNullable<GetBusinessUnitBreedsQuery["businessUnitBreeds"]>[number]>>;

export const useGetBreeds = ({ forAllSpecies }: Input = {}) => {
  const { activeSpecies } = useStateSpecies();

  const { data, ...rest } = useGetBusinessUnitBreedsQuery({
    variables: {
      businessUnitId: getBUFromLocalStorage(),
      animalTypeIds: !forAllSpecies && activeSpecies ? [+activeSpecies.id] : null,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const breeds = removeNothings(data?.businessUnitBreeds || []);

  const breedOptions: InputSelectOption[] = useMemo(() => {
    return breeds.map((breed) => {
      return {
        value: breed.id,
        label: `[${breed?.breedrCode}] ${breed?.name}`,
        key: breed.id,
      };
    });
  }, [breeds]);

  return {
    breeds,
    breedOptions,
    data,
    ...rest,
  };
};
