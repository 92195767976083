import { useGetSubscriptionFeaturesQuery } from "generated/graphql";
import { useEffect } from "react";

export enum ProFeatures {
  AnimalManagement = "animal-management",
}

export const useSubscriptionPlanFeatures = (businessUnitId: number, shouldRefetchSubscriptionStatus: boolean) => {
  const { data, refetch, loading } = useGetSubscriptionFeaturesQuery({
    variables: {
      businessUnit: businessUnitId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (shouldRefetchSubscriptionStatus) {
      refetch();
    }
  }, [shouldRefetchSubscriptionStatus, refetch]);

  const subscriptionPlanFeatures = data?.businessUnit?.subscriptionPlanFeatures;

  const isProFeatureEnabledForBU = (feature: ProFeatures) => {
    if (!subscriptionPlanFeatures || loading) {
      return true;
    }

    if (subscriptionPlanFeatures.length === 0) {
      return false;
    }

    const hasAccessToFeature = subscriptionPlanFeatures?.some((f) => f.isActive && f.slug === feature);

    return hasAccessToFeature;
  };

  return {
    subscriptionPlanFeatures,
    isProFeatureEnabledForBU,
  };
};
