import React, { useMemo } from "react";
import { Flex, KeyValuePair, Spacer } from "components";
import { getMedicineSpeciesAsString, getMedicineWithdrawalsAsString } from "helpers/medicine";
import { KeyValuePairProps } from "components/Common/KeyValuePair";
import { StockItemFragment } from "generated/graphql";

type MedicineDetailsBlockProps = {
  medicine: StockItemFragment;
};

interface MedicineInfoItems extends KeyValuePairProps {
  isHidden?: boolean;
}

export const MedicineInfoBlock: React.FC<MedicineDetailsBlockProps> = ({
  medicine: { id, species, manufacturer, withdrawal, name, manufacturerName, activeIngredient, applicationMethod },
}) => {
  const medicineInfoItems: MedicineInfoItems[][] = useMemo(
    () => [
      [
        {
          label: "Manufacturer name",
          value: manufacturerName,
        },
        {
          label: "Common name",
          value: name,
        },
        {
          label: "Active ingredient",
          value: activeIngredient,
        },
        {
          label: "Manufacturer",
          value: manufacturer,
        },
      ],
      [
        {
          label: "For",
          value: getMedicineSpeciesAsString(species),
        },
        {
          label: "Via",
          value: applicationMethod,
        },
        {
          label: "Withdrawal period",
          value: getMedicineWithdrawalsAsString(withdrawal),
        },
      ],
    ],
    [id],
  );

  return (
    <>
      <Flex container>
        {medicineInfoItems.map((itemList, index) => (
          <Flex item itemGutter xs={12} m={6} xl={3} key={index}>
            {itemList.map(
              (item) =>
                !item?.isHidden && (
                  <KeyValuePair key={item.label} label={item.label} unit={item.unit} value={item.value} />
                ),
            )}
          </Flex>
        ))}
      </Flex>
      <Spacer baselineHeight={3} />
    </>
  );
};
