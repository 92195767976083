// React
import React from "react";
// Utils
import { useQuery } from "@apollo/client";
import { GetSettingsFaq, GetSettingsFaq_settingsFaq } from "api/Settings/types/GetSettingsFaq";
import { GET_SETTINGS_FAQ } from "api/Settings/queries";
import { getBUFromLocalStorage } from "helpers/storage";
// Components
import { Panel, WYSIWYG } from "components";

const FAQSettings: React.FC = () => {
  const { data } = useQuery<GetSettingsFaq>(GET_SETTINGS_FAQ, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const settingsFaq = data?.settingsFaq as GetSettingsFaq_settingsFaq;

  if (!settingsFaq) return null;

  return (
    <Panel title={settingsFaq.title}>
      <WYSIWYG content={settingsFaq.description} />
    </Panel>
  );
};

export default FAQSettings;
