export const capitaliseFirstLetter = (sentence: string | null) => {
  if (!sentence) {
    return "";
  }
  return sentence[0].toUpperCase() + sentence.slice(1);
};

export const capitaliseFirstLetterOfEachWord = (sentence: string | null) => {
  if (!sentence) {
    return "";
  }
  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
