import {
  AnimalFragment,
  useAddAnimalsToFinancialTransactionMutation,
  useRemoveAnimalsFromFinancialTransactionMutation,
} from "generated/graphql";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";
import { useRowSelection } from "hooks/useRowSelection";
import { useFormContext } from "react-hook-form";
import { TransactionValidationSchema as S } from "financial/components/TransactionForm/validation";
import { useContext } from "react";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { TABLE_IDS } from "constants/Interface";
import { SetTransactionAnimalsQueryVariables, TransactionAnimalsQueryVariables } from "../AnimalTableActions";
import { TableType } from "../TransactionAnimalsTable";
import { GET_FINANCIAL_TRANSACTION_ANIMALS } from "financial/api/queries";

export const useAnimalActions = ({
  isNewTransaction,
  queryVariables,
  setQueryVariables,
  setAnimalsTableType,
}: {
  isNewTransaction: boolean;
  queryVariables: TransactionAnimalsQueryVariables;
  setQueryVariables: SetTransactionAnimalsQueryVariables;
  setAnimalsTableType: React.Dispatch<React.SetStateAction<TableType>>;
}) => {
  const methods = useFormContext<S>();

  if (!methods) {
    throw new Error("AnimalTableActions must be used inside a <Form /> or <FormProvider />");
  }
  const { id: businessUnitId } = useGetCurrentBusinessUnit();
  const [addAnimalsToTransaction] = useAddAnimalsToFinancialTransactionMutation();
  const [removeAnimalsFromTransaction, { loading: isLoadingRemoveAnimals }] =
    useRemoveAnimalsFromFinancialTransactionMutation();
  const { handleError, handleMutationResult } = useHandleMutationResult();
  const { selectedAnimalIds: animalIdsToRemove, resetSelection } = useRowSelection({
    tableId: "financial_transaction_animals_table",
  });
  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);
  const tableOptions = getTableOptions<AnimalFragment>(TABLE_IDS.FINANCIAL_TRANSACTIONS_ALL_ANIMALS);

  const handleRemoveAnimals = async () => {
    try {
      const result = await removeAnimalsFromTransaction({
        variables: {
          input: {
            id: methods.watch("transactionId"),
            animals: animalIdsToRemove,
            businessUnit: +businessUnitId,
          },
        },
        refetchQueries: [
          {
            query: GET_FINANCIAL_TRANSACTION_ANIMALS,
            variables: queryVariables,
          },
        ],
      });

      handleMutationResult({
        successMessage: "Successfully removed animals from transaction.",
        errors: result?.data?.removeAnimalsFromFinancialTransaction?.errors || [],
        onSuccess: () => {
          const countAnimalsOnTransaction =
            result.data?.removeAnimalsFromFinancialTransaction?.financialTransaction?.animalsCount || 0;

          methods.setValue("animalsCount", countAnimalsOnTransaction);
          methods.trigger("animalsCount");

          resetSelection();
        },
      });
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const handleAddAnimals = async () => {
    try {
      setAnimalsTableType("transactionAnimalsTable");
      const selectedRowIds = tableOptions?.selectedRowsIds;
      const animalIdsToAdd = selectedRowIds && selectedRowIds?.size > 0 ? Array.from(selectedRowIds) : [];

      if (isNewTransaction) {
        setQueryVariables({ businessUnit: +businessUnitId, id: methods.watch("transactionId") });
      }

      const result = await addAnimalsToTransaction({
        variables: {
          input: {
            id: methods.watch("transactionId"),
            transactionAnimals: animalIdsToAdd.map((id) => {
              return { animal: id };
            }),
            businessUnit: +businessUnitId,
          },
        },
        refetchQueries: [
          {
            query: GET_FINANCIAL_TRANSACTION_ANIMALS,
            variables: isNewTransaction
              ? { id: methods.watch("transactionId"), businessUnit: +businessUnitId }
              : queryVariables,
          },
        ],
      });

      handleMutationResult({
        successMessage: "Successfully added animals to transaction.",
        errors: result?.data?.addAnimalsToFinancialTransaction?.errors || [],
        onSuccess: () => {
          const countAnimalsOnTransaction =
            result.data?.addAnimalsToFinancialTransaction?.financialTransaction?.animalsCount || 0;

          methods.setValue("animalsCount", countAnimalsOnTransaction);
          methods.trigger("animalsCount");

          setTableOptions({
            id: TABLE_IDS.FINANCIAL_TRANSACTIONS_ALL_ANIMALS,
            selectedRowsIds: new Set([]),
          });
        },
      });
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const handleCancel = () => {
    setAnimalsTableType("transactionAnimalsTable");
    setTableOptions({
      id: TABLE_IDS.FINANCIAL_TRANSACTIONS_ALL_ANIMALS,
      selectedRowsIds: new Set([]),
    });
  };
  return { handleRemoveAnimals, isLoadingRemoveAnimals, handleAddAnimals, handleCancel };
};
