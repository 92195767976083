import { Icon } from "components/Common/Icon";
import { forwardRef } from "react";
import { Button } from "twComponents/Button";
import { TABLE_PAGINATION_DEFAULT_OPTIONS } from "components/Common/Table/TablePagination";
import paginationStyles from "components/Common/Table/TablePagination/TablePagination.module.scss";
import { Flex } from "components";
import cn from "classnames";
import { PaginationProperties } from "./helpers";

type Props = {
  paginationProps: Pick<PaginationProperties, "paginationInfo" | "refs" | "flags" | "controls">;
};

export const PaginationControl = forwardRef<HTMLDivElement, Props>(
  (
    {
      paginationProps: {
        controls: {
          handleChangePageSize,
          handleGoToFirstPage,
          handleGoToLastPage,
          handleGoToNextPage,
          handleGoToPreviousPage,
          showPageSizeOptionsMenu,
          togglePageSizeOptionsMenu,
        },
        flags: { isNextPageDisabled, isPreviousPageDisabled },
        paginationInfo: { pageFrom, pageTo, pageSize, totalNumberOfItems },
      },
    },
    ref,
  ) => {
    return (
      <div className="flex min-w-max">
        <div>
          <Button
            variant="outline"
            size="square"
            disabled={isPreviousPageDisabled}
            onClick={handleGoToFirstPage}
            title="First page"
            className="mr-1"
          >
            <Icon name="doubleChevron" rotate={180} size="atom" />
          </Button>

          <Button
            variant="outline"
            size="square"
            title="Previous page"
            disabled={isPreviousPageDisabled}
            onClick={handleGoToPreviousPage}
            className="mr-1"
          >
            <Icon name="chevron" rotate={180} size="atom" />
          </Button>
        </div>

        <Flex item>
          <div className={paginationStyles.table_pagination__per_page} ref={ref}>
            <button
              className={cn(paginationStyles.table_pagination__current_page, {
                [paginationStyles["table_pagination__current_page--active"]]: showPageSizeOptionsMenu,
              })}
              type="button"
              onClick={togglePageSizeOptionsMenu}
              title="Rows per page"
            >
              <Flex container>
                <Flex item itemGutter>
                  {pageFrom} to {pageTo}
                </Flex>
                <Flex item>
                  <Icon name="rectangle" size="tiny" colour="grey_dark" />
                </Flex>
              </Flex>
            </button>

            {showPageSizeOptionsMenu ? (
              <div className={paginationStyles.table_pagination__page_sizes}>
                <span className={paginationStyles.table_pagination__page_sizes_title}>Rows:</span>

                {TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES.map((size) => (
                  <span
                    className={cn(paginationStyles.table_pagination__page_size, {
                      [paginationStyles["table_pagination__page_size--active"]]: size === pageSize,
                    })}
                    key={size}
                    onClick={() => handleChangePageSize(size)}
                    onKeyUp={() => handleChangePageSize(size)}
                    tabIndex={0}
                  >
                    {size}
                  </span>
                ))}
              </div>
            ) : null}
          </div>

          <span className={paginationStyles.table_pagination__total_items}>of {totalNumberOfItems}</span>
        </Flex>

        <div>
          <Button
            variant="outline"
            size="square"
            title="Next page"
            onClick={handleGoToNextPage}
            disabled={isNextPageDisabled}
          >
            <Icon name="chevron" size="atom" />
          </Button>
          <Button
            variant="outline"
            size="square"
            disabled={isNextPageDisabled}
            onClick={handleGoToLastPage}
            title="Last page"
            className="ml-1"
          >
            <Icon name="doubleChevron" size="atom" />
          </Button>
        </div>
      </div>
    );
  },
);

PaginationControl.displayName = "PaginationControl";
