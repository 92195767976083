import React, { useState } from "react";
import { LOCATION_TYPES } from "constants/Settings";
import { useBreakpoints, useCountries } from "hooks";
import { validationSchemaDeliveryAddressRequest, handleEnterPress, handleErrorCheck } from "helpers/general";
import {
  CheckBox,
  Flex,
  FormBuilder,
  ConfirmDeletionModal,
  FieldSelect,
  FieldText,
  Panel,
  QuickActions,
  Spacer,
} from "components";
import {
  useAddDeliveryAddressMutation,
  useDeleteDeliveryAddressMutation,
  useEditDeliveryAddressMutation,
} from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { GET_BUSINESS_UNITS_LIST } from "api/BusinessUnits/queries";
import { GET_FARM_SYSTEMS } from "api/Settings/queries";
import { MaskedHoldingIdInput } from "components/Settings/BusinessSettings/MaskedHoldingIdInput";

interface ModifyDeliveryAddressFormProps {
  editData: (CreateAddressData & { id?: number }) | null;
  farm?: boolean;
  editMode: boolean;
  onCloseForm: () => void;
}

const ModifyDeliveryAddressForm: React.FC<ModifyDeliveryAddressFormProps> = ({
  editData,
  editMode,
  onCloseForm,
  farm = false,
}) => {
  const { isBreakpointL } = useBreakpoints();
  const { countriesData } = useCountries();
  const [, setLoading] = useState<boolean>(false);
  const [showModal, setModal] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showHoldingIdInput, setShowHoldingIdInput] = useState<boolean>(true);
  const [locationIdError, setLocationIdError] = useState<string>("");

  const [addDeliveryAddress] = useAddDeliveryAddressMutation();
  const [editDeliveryAddress] = useEditDeliveryAddressMutation();
  const [deleteDeliveryAddress] = useDeleteDeliveryAddressMutation();

  const filteredCountries = countriesData?.countries?.map((country) => {
    return {
      key: country?.id,
      label: country?.name,
      value: country?.id,
      code: country?.code,
    };
  });

  const setInitialFormValues = ({ editMode, editData }): Record<string, unknown> => {
    if (editMode && editData) {
      return {
        ...editData,
        country: editData.country.id,
        countryCode: editData.country.code,
      };
    }

    return {
      unitName: "",
      addressLine1: "",
      addressLine2: "",
      locationId: "",
      locationType: "",
      city: "",
      country: "",
      postcode: "",
      primary: false,
      countryCode: "",
    };
  };

  const initialValues = setInitialFormValues({ editMode, editData });

  const handleRequestResponse = (success: boolean, message: string): void => {
    if (success) {
      setLoading(false);
      onCloseForm();
    } else {
      setLocationIdError(message);
    }
  };

  const removeDeliveryAddress = async (): Promise<void> => {
    if (editData?.id == null) {
      return;
    }
    setLoading(true);

    try {
      const { data } = await deleteDeliveryAddress({
        variables: {
          input: {
            id: editData.id,
          },
        },
        refetchQueries: [
          {
            query: GET_FARM_SYSTEMS,
            variables: {
              farm: getBUFromLocalStorage(),
            },
          },
        ],
      });
      // TODO: Smelly
      const errorMessage =
        data?.deleteDeliveryAddress?.errors != null && data?.deleteDeliveryAddress?.errors.length > 0
          ? data?.deleteDeliveryAddress?.errors[0]?.message
          : null;
      if (errorMessage == null) {
        setLoading(false);
        onCloseForm();
      } else {
        setError(errorMessage);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const saveDeliveryAddress = async ({
    id,
    unitName,
    addressLine1,
    addressLine2,
    city,
    country,
    postcode,
    primary,
    locationId,
    locationType,
  }): Promise<void> => {
    const input = {
      farm: getBUFromLocalStorage(),
      unitName,
      addressLine1,
      addressLine2,
      city,
      country,
      postcode,
      primary,
      locationId,
      locationType: locationType || "FARM",
    };

    setLoading(true);

    try {
      if (id) {
        const { data } = await editDeliveryAddress({
          variables: {
            input: {
              ...input,
              id: Number(id),
            },
          },
        });
        // TODO: Smelly
        const errors =
          data?.editDeliveryAddress?.errors != null && data?.editDeliveryAddress?.errors.length > 0
            ? data?.editDeliveryAddress?.errors[0]?.message
            : false;
        const message = !!errors ? errors : "Delivery Address updated successfully.";
        handleRequestResponse(!errors, message);
      } else {
        const { data } = await addDeliveryAddress({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: GET_BUSINESS_UNITS_LIST,
            },
          ],
        });
        const errors =
          data?.addDeliveryAddress?.errors != null && data?.addDeliveryAddress?.errors.length > 0
            ? data?.addDeliveryAddress?.errors[0]?.message
            : false;
        const message = !!errors ? errors : "Delivery Address created successfully.";
        handleRequestResponse(!errors, message);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const toggleModal = () => {
    setModal((prev) => !prev);
  };

  return (
    <>
      <FormBuilder
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(props): Promise<void> => saveDeliveryAddress(props)}
        validationSchema={validationSchemaDeliveryAddressRequest}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }): JSX.Element => {
          const handleRegionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
            const countryCode = countriesData?.countries?.find((item) => item?.id === e.target.value)?.code;
            const countryName = countriesData?.countries?.find((item) => item?.id === e.target.value)?.name;
            setFieldValue("country", e.target.value);
            setFieldValue("countryCode", countryCode);
            if (countryName === "Northern Ireland") {
              setShowHoldingIdInput(false);
            }
            setFieldValue("locationId", "");
          };
          return (
            <Panel
              title={`${editMode ? "Edit" : "Add"} location`}
              actions={[
                { caption: "Cancel", onClick: onCloseForm, colour: "grey", variant: "hollow" },
                { caption: editMode ? "Save" : "Publish", onClick: handleSubmit, colour: "yellow" },
              ]}
            >
              <Flex container>
                <Flex item xs={12} l={6} xl={4}>
                  <FieldText
                    label="Location Name"
                    error={handleErrorCheck(errors.unitName)}
                    inputProps={{
                      name: "unitName",
                      defaultValue: values.unitName,
                      autoFocus: true,
                      onChange: handleChange,
                      onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                    }}
                  />
                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                  {!farm ? (
                    <FieldSelect
                      label="Location type"
                      error={handleErrorCheck(errors.locationType)}
                      inputProps={{
                        name: "locationType",
                        value: values.locationType,
                        options: LOCATION_TYPES,
                        onChange: handleChange,
                      }}
                    />
                  ) : null}
                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                  <FieldSelect
                    label="Region"
                    error={handleErrorCheck(errors.country)}
                    inputProps={{
                      name: "country",
                      value: values.country,
                      options: filteredCountries,
                      onChange: handleRegionChange,
                    }}
                  />
                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}

                  {showHoldingIdInput ? (
                    <MaskedHoldingIdInput
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      locationIdError={locationIdError}
                    />
                  ) : null}

                  <FieldText
                    label="Enter address line 1"
                    error={handleErrorCheck(errors.addressLine1)}
                    inputProps={{
                      name: "addressLine1",
                      defaultValue: values.addressLine1,
                      onChange: handleChange,
                      onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                    }}
                  />
                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                  <FieldText
                    label="Enter address line 2"
                    error={handleErrorCheck(errors.addressLine2)}
                    inputProps={{
                      name: "addressLine2",
                      defaultValue: values.addressLine2,
                      onChange: handleChange,
                      onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                    }}
                  />
                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                  <FieldText
                    label="City"
                    error={handleErrorCheck(errors.city)}
                    inputProps={{
                      name: "city",
                      defaultValue: values.city,
                      onChange: handleChange,
                      onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                    }}
                  />
                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                  <FieldText
                    label="Postcode"
                    error={handleErrorCheck(errors.postcode)}
                    inputProps={{
                      name: "postcode",
                      defaultValue: values.postcode,
                      onChange: handleChange,
                      onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                    }}
                  />
                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                  <CheckBox
                    type="text"
                    label="Make primary"
                    name="primary"
                    checked={values.primary}
                    value={values.primary}
                    errorStatus={errors.primary}
                    onChange={handleChange}
                    onKeyPress={(e): void => handleEnterPress(e, handleSubmit)}
                    error={handleErrorCheck(errors.primary)}
                  />

                  {editMode ? (
                    <>
                      <Spacer baselineHeight={2} />
                      <QuickActions items={[{ label: "Delete", onClick: toggleModal, type: "danger" }]} />
                    </>
                  ) : null}
                </Flex>
              </Flex>
            </Panel>
          );
        }}
      </FormBuilder>

      <ConfirmDeletionModal
        showModal={showModal}
        onClose={toggleModal}
        entityName={editData?.unitName || ""}
        onSubmit={removeDeliveryAddress}
        errorMessage={error || undefined}
      />
    </>
  );
};

export default ModifyDeliveryAddressForm;
