// React
import React from "react";
// Libraries
import pure from "recompose/pure";
import { withStyles } from "@material-ui/core/styles";
// Components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
// Resources
import styles from "./Checkbox.module.scss";

interface Props {
  checked?: boolean;
  label?: string;
  name?: string;
  className?: string;
  onChange?: (event: React.SyntheticEvent<HTMLInputElement>, checked?: boolean) => void;
}

const GreenCheckbox = withStyles({
  root: {
    color: "#d2d2d2",
    "&$checked": {
      color: "#4ec74b",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CheckBox: React.FC<Props> = ({ className, checked, onChange, name, label }) => (
  <FormControlLabel
    classes={{
      label: styles.checkbox,
    }}
    className={className}
    control={<GreenCheckbox checked={checked} onChange={onChange} value={name} id={name} />}
    label={label}
  />
);

export default pure(CheckBox);
