import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesTradingSellingDeadweight: FC = () => {
  const handleButtonClick = () => {
    window.open("https://share.hsforms.com/1albrlIZ-SIqLBLxMUcTeqg4snpj", "_blank");
  };

  return (
    <EmptyStates
      animation={"handshake"}
      title="We can help you sell your cattle."
      buttonCaption="Express your interest"
      onClickButton={handleButtonClick}
    >
      <p>
        Interested in finding out what your sheep and cattle might be worth on our deadweight contracts? Fill in this
        form and your local account manager will be in touch for a confidential conversation.
      </p>
    </EmptyStates>
  );
};
