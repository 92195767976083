import React from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./Paper.module.scss";

enum PaperColours {
  Blue = "blue",
  Green = "green",
  Red = "red",
}

interface PaperProps {
  className?: string;
  colour?: `${PaperColours}`;
  dark?: boolean;
  shadow?: boolean;
  square?: boolean;
  children: React.ReactNode;
}

export const Paper: React.FC<PaperProps> = ({ children, className, colour, dark, shadow, square }) => (
  <div
    className={cn(
      styles.paper,
      {
        [styles[`paper--colour__${colour}`]]: colour,
        [styles["paper--dark"]]: dark,
        [styles["paper--white"]]: !dark,
        [styles["paper--square"]]: square,
        [styles["paper--shadow"]]: shadow,
      },
      className,
    )}
  >
    {children}
  </div>
);
