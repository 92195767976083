import { getBUFromLocalStorage } from "helpers/storage";
import { useGetBusinessUnitQuery } from "generated/graphql";
import { useGetCurrentBusinessUnit } from "./useGetCurrentBusinessUnit";

export type AnimalPreferencesReturnType = ReturnType<typeof useGetAnimalIdPreferences>;

export const useGetAnimalIdPreferences = () => {
  const { data, loading } = useGetBusinessUnitQuery({
    variables: {
      businessUnit: getBUFromLocalStorage(),
    },
    skip: !getBUFromLocalStorage(),
    fetchPolicy: "cache-only",
    nextFetchPolicy: "cache-only",
  });
  const { countryCode } = useGetCurrentBusinessUnit();
  const hiddenIdsByCountry = {
    us: ["passportNumber"],
    gb: [
      "trichId",
      "tsuBarcode",
      "herdDoggId",
      "uhfId",
      "breedrId",
      "name",
      "brucellosisId",
      "tattoo",
      "alternativeId",
    ],
    au: [
      "trichId",
      "tsuBarcode",
      "herdDoggId",
      "uhfId",
      "breedrId",
      "name",
      "brucellosisId",
      "tattoo",
      "alternativeId",
    ],
  };

  const hiddenIds: string[] = countryCode && hiddenIdsByCountry[countryCode] ? hiddenIdsByCountry[countryCode] : [];
  const activeIdPreferences = data?.businessUnit?.identifierPreferencesActiveIds;
  const primaryId = data?.businessUnit?.identifierPreferencesPrimaryId;
  const secondaryId = data?.businessUnit?.identifierPreferencesSecondaryId;
  const filteredActiveIdPreferences = activeIdPreferences
    ? Object.entries(activeIdPreferences).reduce((acc, [key, value]) => {
        if (!hiddenIds.includes(key)) {
          acc[key] = value;
        }
        return acc;
      }, {} as { [key: string]: boolean | "ActiveIdentifierPreferencesType" })
    : {};

  const activeIdPreferencesArray = filteredActiveIdPreferences
    ? Object.entries(filteredActiveIdPreferences).map(([key, value]) => ({ [key]: value }))
    : [];
  activeIdPreferencesArray.shift();

  const nameOfActiveIds = Object.keys(activeIdPreferences || {}).filter(
    (key) => activeIdPreferences && activeIdPreferences[key] === true,
  );

  return {
    nameOfActiveIds,
    activeIdPreferences: activeIdPreferences,
    primaryId: primaryId,
    secondaryId: secondaryId,
    activeIdPreferencesArray: activeIdPreferencesArray,
    loading,
    hiddenIds: hiddenIds,
  };
};
