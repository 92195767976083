import { useEffect } from "react";
// Libraries
import { isEqual } from "lodash";
// Utils
import { usePrevious, useStateSpecies } from "hooks";

export const useEffectSpeciesActive = (callback: () => void): void => {
  const { activeSpecies } = useStateSpecies();
  const previousSpeciesActive = usePrevious(activeSpecies);

  useEffect(() => {
    if (!previousSpeciesActive) return;

    if (isEqual(previousSpeciesActive, activeSpecies)) return;

    callback();
  }, [callback, previousSpeciesActive, activeSpecies]);
};
