import { atomFamily } from "recoil";
import { LIVEWEIGHT_SORT_OPTIONS, LiveweightSortValue } from "trading/hooks/useListingSort/constants";
import {
  LIVEWEIGHT_SORT_OPTIONS as LIVEWEIGHT_SORT_OPTIONS_SERVER_SIDE,
  LiveweightSortValue as LiveweightSortValueServerSide,
} from "trading/components/Liveweight/ListingSortServerSide/constants";

const DEFAULT_SORT_OPTION = LIVEWEIGHT_SORT_OPTIONS[0].value;
const DEFAULT_SORT_OPTION_SERVER_SIDE = LIVEWEIGHT_SORT_OPTIONS_SERVER_SIDE[0].value;

export const liveweightSort = atomFamily<LiveweightSortValue, string>({
  key: "liveweightSort",
  default: DEFAULT_SORT_OPTION,
});

export const liveweightSortServerSide = atomFamily<LiveweightSortValueServerSide, string>({
  key: "liveweightSort",
  default: DEFAULT_SORT_OPTION_SERVER_SIDE,
});
