import { ChangeEvent, VFC } from "react";
// Components
import { Spacer, Flex, Title, CheckBox, WYSIWYG } from "components";

interface TermsAndConditionsProps {
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  isAgreed: boolean;
  terms?: string;
}

export const TermsAndConditions: VFC<TermsAndConditionsProps> = ({ onChange, isAgreed, terms }) => (
  <Flex container>
    <Flex item itemGutter>
      <Title tertiary>Terms & Conditions</Title>
    </Flex>

    <Spacer baselineHeight={1} />

    <Flex item itemGutter containerDirection="column">
      <WYSIWYG content={terms || "No T&C found"} size="small" />

      {terms ? <CheckBox label="I agree" checked={isAgreed} onChange={onChange} /> : null}
    </Flex>
  </Flex>
);
