import { ChangeEvent, CSSProperties, FC } from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./InputRadio.module.scss";

interface InputRadioBaseProps {
  checked?: boolean;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  role?: string;
  style?: CSSProperties;
  title?: string;
  value: string;
}

interface InputRadioWithLabelProps extends InputRadioBaseProps {
  label: string;
  name: string;
}

interface InputRadioWithoutLabelProps extends InputRadioBaseProps {
  label?: never;
  name?: never;
}

type InputRadioProps = InputRadioWithLabelProps | InputRadioWithoutLabelProps;

export const InputRadio: FC<InputRadioProps> = ({ className, value, label, name = undefined, ...props }) => {
  return (
    <label htmlFor={value} className={cn(styles.input_radio__label, className)}>
      <input id={value} name={name} className={styles.input_radio} value={value} type="radio" {...props} />
      {label}
    </label>
  );
};
