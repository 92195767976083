import { FC } from "react";
// Libraries
import _ from "lodash";
import pure from "recompose/pure";
// Utils
import { ACTIVITIES_IMPORT, ANIMALS_IMPORT } from "constants/Import";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useStateSpecies } from "hooks";
// Components
import { Button, Flex, Spacer, Text } from "components";

interface SelectCustomTypeBlockProps {
  selectedImportType: string;
  changeSelectedImportType: (type: string) => void;
}

const SelectCustomTypeBlock: FC<SelectCustomTypeBlockProps> = ({ selectedImportType, changeSelectedImportType }) => {
  const { activeSpecies } = useStateSpecies();

  return (
    <>
      <Flex container>
        <Flex container containerJustifyContent="center" item itemGrow itemGutter>
          <Text>Please, choose if you are importing animals or activities.</Text>

          {activeSpecies ? (
            <>
              <Spacer baselineHeight={2} />

              <Text>
                Your current selection is: <strong>{activeSpecies.name}</strong>
              </Text>
            </>
          ) : null}
        </Flex>
      </Flex>

      <Spacer baselineHeight={2} />

      <Flex container containerJustifyContent="center">
        {[ANIMALS_IMPORT, ACTIVITIES_IMPORT].map((item) => (
          <Flex item itemGutter key={item}>
            <Button
              caption={_.capitalize(item)}
              id={`${ELEMENTS_IDS.IMPORT_SELECT_CUSTOM_TYPE_BUTTON}_${item}`}
              variant={item === selectedImportType ? "solid" : "hollow"}
              colour={item === selectedImportType ? "yellow" : "grey"}
              onClick={(): void => changeSelectedImportType(item)}
            />
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default pure(SelectCustomTypeBlock);
