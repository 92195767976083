import { gql } from "@apollo/client";

export const activityTypeFragment = gql`
  fragment activityTypeFragment on ActivityType {
    id
    image
    imageSvg
    slug
    name
    isPublic
    isSystem
    activityTemplatesCount
  }
`;

export const activityTemplateFragment = gql`
  fragment activityTemplateFragment on ActivityTemplate {
    id
    name
    slug
    description
    businessUnit {
      id
      name
    }
    fields {
      id
      name
      slug
      units
      fieldType
      position
      initialValue
      isRequired
      options {
        id
        title
        value
      }
    }
    activityType {
      id
      image
      imageSvg
      slug
      name
      isPublic
      isSystem
      position
      activityTemplatesCount
    }
  }
`;

export const activityTypeShortFragment = gql`
  fragment activityTypeShortFragment on ActivityType {
    id
    slug
    image
  }
`;

export const activityLogFragment = gql`
  fragment activityLogFragment on ActivityLog {
    id
    date
    dontShow
    isDeletable
    payload {
      valueDescription
      shortDescription
      longDescription
      data {
        fieldSlug
      }
    }
    activityType {
      ...activityTypeShortFragment
    }
    activityTemplate {
      id
      activityType {
        ...activityTypeShortFragment
      }
    }
  }
  ${activityTypeShortFragment}
`;

export const historyAnimalFragment = gql`
  fragment historyAnimalFragment on ActivityLog {
    id
    date
    dontShow
    isDeletable
    payload {
      name
      description
      data {
        value
        unitName
        fieldSlug
      }
    }
    activityType {
      ...activityTypeShortFragment
    }
    activityTemplate {
      id
      activityType {
        ...activityTypeShortFragment
      }
    }
  }
  ${activityTypeShortFragment}
`;

export const importActivityFragment = gql`
  fragment importActivityFragment on ActivityLog {
    animal {
      passportNumber
    }
    date
    payload {
      description
      data {
        name
        value
        unitName
      }
    }
  }
`;
