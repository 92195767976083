import React, { useContext, useMemo, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";
import { VACCINATION } from "constants/Animals";
import { LOG_ACTIVITY_BULK_MUTATION } from "api/MyLivestock/Activity/mutations";
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { ActivityTemplateFieldFieldType } from "api/graphql-global-types";
import { GetActivityTemplates_activityTemplates } from "api/MyLivestock/Activity/types/GetActivityTemplates";
import { GET_MY_ANIMALS_LIST } from "api/MyLivestock/Animal/queries";
import { FormFieldBuilder, Button, FieldDate, Flex, Spacer, Text } from "components";
import { AdministerMedicineForm } from "medicines/components/AdministerMedicineForm";
import { AdministerMedicineFields } from "medicines/components/AdministerMedicineForm";
import { InputSelectOption } from "components/Common/Field/Select";
import { EventName, logAnalyticsEvent } from "utils/analytics";

type Props = {
  isOneAnimal: boolean;
  animalsList: GetAnimalsList_animalsExtended_animals[];
  isIndividual: boolean;
  onChangeStep: (value: number) => void;
  selectedTemplateObject: GetActivityTemplates_activityTemplates | undefined;
  animalsIds: string[];
  selectedAnimalNumber: number;
  onChangeAnimalNumber: (value: number) => void;
  selectedTemplate: string;
  onCloseModal: () => void;
};

const ACTIVITY_DATE_NAME = "activityDate";

// maps ui field names to be field slugs
const MEDICINE_FIELD_MAPPING = {
  activeIngredients: "active-ingredients",
  batchId: "batch-number",
  calculatedDosage: "calculated-dosage",
  calculatedUnit: "calculated-unit",
  dosage: "dosage",
  units: "dosage-units",
  medicine: "medicine-name",
  notes: "notes",
  reason: "reason",
  withdrawalDays: "withdrawal",
};

export const SecondStep: React.FC<Props> = ({
  isOneAnimal,
  animalsList,
  isIndividual,
  onChangeStep,
  selectedTemplateObject,
  animalsIds,
  selectedAnimalNumber,
  onChangeAnimalNumber,
  selectedTemplate,
  onCloseModal,
}) => {
  const [clearMedicineForm, updateClearMedicineForm] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { showNotification } = useContext(CommonContext);

  const businessUnit = getBUFromLocalStorage();

  const [logActivityBulk, { loading }] = useMutation(LOG_ACTIVITY_BULK_MUTATION);

  const validationSchemaObject = selectedTemplateObject?.fields.reduce((result, item) => {
    return {
      ...result,
      [item.id]: item.isRequired ? Yup.mixed().required("This field is required") : Yup.mixed(),
    };
  }, {});

  //@ts-expect-error
  const validationSchema = Yup.object().shape(validationSchemaObject);
  const initialValueWithDate = { [ACTIVITY_DATE_NAME]: new Date() };

  const initialValues =
    selectedTemplateObject?.fields.reduce((result, item) => {
      if (
        item.fieldType === ActivityTemplateFieldFieldType.MULTI_SELECT ||
        item.fieldType === ActivityTemplateFieldFieldType.ANIMAL_IDS
      ) {
        return {
          ...result,
          [item.id]: item.initialValue || [],
        };
      } else if (item.fieldType === ActivityTemplateFieldFieldType.BOOLEAN) {
        return {
          ...result,
          [item.id]: item.initialValue || true,
        };
      } else {
        return {
          ...result,
          [item.id]: item.initialValue || "",
        };
      }
    }, initialValueWithDate) || initialValueWithDate;

  const handleModalCancelForm = (): void => {
    onChangeStep(0);
  };

  const handleConfirmActivityClick = async (values, { setValues }): Promise<void> => {
    setError("");
    try {
      const filterFields = [
        ACTIVITY_DATE_NAME,
        MEDICINE_FIELD_MAPPING.calculatedDosage,
        MEDICINE_FIELD_MAPPING.calculatedUnit,
      ];
      const payloadData = Object.keys(values)
        .filter((item) => !filterFields.includes(item))
        .map((item) => {
          if (selectedTemplateObject?.slug === VACCINATION) {
            const fullField = selectedTemplateObject?.fields?.find((field) => field.id === item);

            if (fullField?.slug === MEDICINE_FIELD_MAPPING.dosage) {
              return { id: Number(item), value: values["calculated-dosage"] };
            }

            if (fullField?.slug === MEDICINE_FIELD_MAPPING.units) {
              return { id: Number(item), value: values["calculated-unit"] };
            }
          }

          return { id: Number(item), value: values[item] };
        });

      const animalsToSend = isIndividual ? animalsIds[selectedAnimalNumber] : animalsIds;
      const { data } = await logActivityBulk({
        variables: {
          input: {
            date: values[ACTIVITY_DATE_NAME] || new Date(),
            activityTemplate: selectedTemplate,
            animals: animalsToSend,
            businessUnit,
            payload: {
              data: payloadData,
              name: selectedTemplateObject?.name || "",
            },
          },
        },
        refetchQueries: ({ data }) => {
          if (data?.logActivityBulk && !data?.logActivityBulk.errors) {
            return [
              {
                query: GET_MY_ANIMALS_LIST,
                variables: {
                  businessUnitId: businessUnit,
                  isOnFarm: true,
                },
              },
            ];
          }
          return [];
        },
      });

      const errors = data?.logActivityBulk?.errors;
      if (errors && errors.length > 0) {
        setError(errors[0].message);
      } else {
        showNotification({ message: `${selectedTemplateObject?.name || "Activity"} successfully logged` });

        if (isIndividual && selectedAnimalNumber + 1 !== animalsIds.length) {
          onChangeAnimalNumber(selectedAnimalNumber + 1);
          setValues(initialValues);
          updateClearMedicineForm(true);
        } else {
          logAnalyticsEvent(EventName.activityLogAnimal, {
            animals: animalsIds,
            activityTemplateName: selectedTemplateObject?.name,
            activityTemplateId: selectedTemplateObject?.id,
            logType: isIndividual ? "one by one" : "all",
          });
          onCloseModal();
        }
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Error logging the activity, please try again later",
      });
    }
  };

  const medicineReasons = useMemo((): (InputSelectOption | null)[] => {
    const { fields = [] } = { ...selectedTemplateObject };
    const { options } = { ...fields.find((field) => field.slug === "reason") };

    return !options?.length
      ? []
      : options.map((option, index) => ({
          key: index.toString(),
          label: option?.title || "",
          value: option?.value || "",
        }));
  }, [selectedTemplateObject?.fields]);

  return (
    <Flex container containerDirection="column">
      {isOneAnimal ? (
        <Text>{`Animal ID: ${animalsList[0].passportNumber || animalsList[0].visualId}`}</Text>
      ) : isIndividual ? (
        <Text>{`Animal ID: ${
          animalsList[selectedAnimalNumber].passportNumber || animalsList[selectedAnimalNumber].visualId
        }`}</Text>
      ) : (
        <Text>
          Logging activity against <strong>ALL</strong> selected animals
        </Text>
      )}
      <Spacer baselineHeight={1} />
      <Flex container containerDirection={"column"}>
        <Formik
          onSubmit={handleConfirmActivityClick}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          {({ values, setFieldValue, setValues, errors, handleSubmit }): JSX.Element => {
            const { fields: activityFields } = { ...selectedTemplateObject };

            const handleUpdateMedicine = (data: AdministerMedicineFields): void => {
              // build a value object of BE field ID's and values ready for submission
              const fieldMaps = Object.keys(MEDICINE_FIELD_MAPPING).reduce((fieldObj, uiFieldId) => {
                const slug = MEDICINE_FIELD_MAPPING[uiFieldId];
                const { id } = { ...activityFields?.find((field) => field.slug === slug) };

                // retain the calculated fields for later
                if (!id) return { ...fieldObj, [slug]: data[uiFieldId] };

                return { ...fieldObj, [id]: data[uiFieldId] };
              }, {});

              // update formik values to ensure medicine fields get included in validation
              setValues({ ...values, ...fieldMaps });
            };

            // take the horrid integer keyed object errors and formats it into something the
            // administer medicine component will understand
            const medicineErrorsIds = Object.keys(errors);
            const medicineErrors = Object.keys(MEDICINE_FIELD_MAPPING).reduce((acc, curr) => {
              const slug = MEDICINE_FIELD_MAPPING[curr];
              const { id } = { ...activityFields?.find((field) => field.slug === slug) };

              if (!id || !medicineErrorsIds.includes(id)) return acc;

              return { ...acc, [curr]: errors[id] };
            }, {});

            return (
              <>
                <FieldDate
                  label="Activity Date"
                  inputProps={{
                    maxDate: new Date(),
                    name: ACTIVITY_DATE_NAME,
                    onChange: (date): void => setFieldValue(ACTIVITY_DATE_NAME, date),
                    value: values[ACTIVITY_DATE_NAME],
                  }}
                />
                {selectedTemplateObject?.slug === VACCINATION ? (
                  <AdministerMedicineForm
                    onSubmitMedicine={clearMedicineForm ? (): void => updateClearMedicineForm(false) : null}
                    errors={medicineErrors}
                    numAnimals={isIndividual ? 1 : animalsList.length}
                    onChange={handleUpdateMedicine}
                    reasonOptions={medicineReasons}
                  />
                ) : (
                  selectedTemplateObject?.fields.map((item) => (
                    <Flex item key={item.id}>
                      <FormFieldBuilder
                        fieldInfo={item}
                        value={values[item.id] || ""}
                        error={errors[item.id] || ""}
                        setFieldValue={setFieldValue}
                      />
                    </Flex>
                  ))
                )}
                {error ? (
                  <>
                    <Spacer baselineHeight={1} />
                    <Text warning>{error}</Text>
                  </>
                ) : null}
                <Spacer baselineHeight={1} />
                <Flex container xs={12} containerJustifyContent="flex-end">
                  <Spacer baselineHeight={3} />
                  <Flex item itemGutter>
                    <Button
                      caption="Cancel"
                      variant="hollow"
                      colour="grey"
                      onClick={handleModalCancelForm}
                      disabled={loading}
                    />
                  </Flex>
                  <Flex item itemGutter>
                    <Button
                      caption={
                        isOneAnimal
                          ? "Complete"
                          : isIndividual && selectedAnimalNumber + 1 !== animalsIds.length
                          ? "Save & continue"
                          : "Finish"
                      }
                      onClick={handleSubmit}
                      disabled={!selectedTemplate || loading}
                      requesting={loading}
                    />
                  </Flex>
                </Flex>
              </>
            );
          }}
        </Formik>
      </Flex>
    </Flex>
  );
};
