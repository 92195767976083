import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import cn from "classnames";

import { FormBlockWithLogo } from "components/Auth";
import Input from "components/Common/Input";
import { Button } from "components";

import { validationSchemaNewPassword, handleEnterPress, stringifyJsonSafely } from "helpers/general";

import { LOGIN } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";

import { postRequest } from "api/restAPI";
import api from "api/config";

import styles from "./styles.module.css";

const NewPassword: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<AuthPasswordResetPageParams>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleChangePassword = async ({ newPassword }: NewPasswordData): Promise<void> => {
    setLoading(true);
    setError("");

    try {
      const { data } = await postRequest(
        `${api.endpoints.resetPas}${token}/`,
        stringifyJsonSafely({
          password: newPassword,
          confirm_password: newPassword,
        }),
      );

      setLoading(false);

      if (data && data.success) {
        history.push(LOGIN);
      } else {
        setError("Something went wrong! Please try again later.");
      }
    } catch (e: any) {
      setError(e.response ? e.response.data.errors[0].message : "Error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormBlockWithLogo
      // @ts-ignore
      onSubmit={handleChangePassword}
      // @ts-ignore
      customStyle={styles.viewForm}
      validationSchema={validationSchemaNewPassword}
      initialValues={{
        newPassword: "",
      }}
    >
      {({ values, errors, handleChange, handleSubmit }): JSX.Element => {
        const handleKeyPress = (e): void => {
          handleEnterPress(e, handleSubmit);
        };
        return (
          <div className={styles.container}>
            <div className={styles.viewInfoText}>
              <span className={styles.infoText}>Choose a new password</span>
            </div>
            <Input
              id={ELEMENTS_IDS.NEW_PASSWORD_PASSWORD_INPUT}
              autoFocus
              type="password"
              label="Password"
              name="newPassword"
              value={values.newPassword}
              stylesClasses={styles.passwordInput}
              handleChange={handleChange}
              errorStatus={errors.newPassword}
              onKeyPress={handleKeyPress}
              error={errors.newPassword || null}
            />
            {error ? <div className={styles.errorText}>{error}</div> : null}
            <div className={cn({ [styles.extraTopMargin]: errors.newPassword }, styles.buttonsWrapper)}>
              <Button
                id={ELEMENTS_IDS.NEW_PASSWORD_SAVE_PASSWORD_BUTTON}
                caption="Save password"
                width="full"
                height="large"
                disabled={loading}
                requesting={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        );
      }}
    </FormBlockWithLogo>
  );
};

export default NewPassword;
