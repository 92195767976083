import { InputSelectOption } from "components/Common/Field/Select";
import { CategoryOfSale } from "trading/hooks/useGetCategoryListings";

export const getCategorySelectOptions = (categoryOfSale: CategoryOfSale[] | null | undefined): InputSelectOption[] => {
  return (
    categoryOfSale?.reduce((acc: InputSelectOption[], category, index) => {
      if (category?.category && category?.id) {
        acc.push({
          label: category?.category,
          value: category?.id.toString(),
          key: `category-option--${index}`,
        });
      }
      return acc;
    }, []) ?? ([] as InputSelectOption[])
  );
};
