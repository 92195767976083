// Libraries
import { DateTime } from "luxon";
// API
import { BuyingLots_buyingLots_edges_node_images } from "trading/api/Liveweight/types/BuyingLots";
import { MyLots_myLots_edges_node, MyLots_myLots_edges_node_images } from "trading/api/Liveweight/types/MyLots";
// Utils
import { getCurrencyCode, getFormattedNumber, getFormattedPrice, getDistanceUnitName } from "helpers/general";
import {
  getListingAnimalsBreedPills,
  getListingAnimalsFormattedAge,
  getListingAnimalsFormattedGrowthRate,
  getListingFormattedDistance,
} from "trading/util";
import { useGetCurrentBusinessUnit } from "hooks";
// Types
import { ListingCardProps } from "trading/components/Liveweight/ListingCard";
import { BuyingLotNode } from "trading/components/containers/liveweight/BuyerListings";

type ListingImages = BuyingLots_buyingLots_edges_node_images | MyLots_myLots_edges_node_images;

type ListingCardPublicProps = Omit<ListingCardProps, "location" | "url">;

export const useListingCardPublicProps = (
  listing: BuyingLotNode | MyLots_myLots_edges_node,
): ListingCardPublicProps => {
  const animalsTotal = listing.animalsCount || 0;

  const { weightUnits: buWeightUnit, unitsOfMeasurement } = useGetCurrentBusinessUnit();
  const animalsAgeAverage = getListingAnimalsFormattedAge(listing);
  const animalsGrowthRateAverage = getListingAnimalsFormattedGrowthRate(listing, buWeightUnit);
  const animalsInWithdrawalTotal = listing.animalsNumberWithdrawals || undefined;
  const animalsNamedSireTotal = listing.animalsNumberSires || undefined;
  const animalsTimesMovedMaximum = listing.animalsMaxNumberMoves || undefined;
  const animalsTimesWeighedAverage = listing.animalsAverageTimesWeighed || undefined;
  const animalsWeightAverage = listing.animalsAverageCurrentWeight
    ? `${getFormattedNumber(listing.animalsAverageCurrentWeight)} ${buWeightUnit}`
    : undefined;

  //@ts-expect-error
  const distanceUnit = getDistanceUnitName(unitsOfMeasurement);
  const distance = getListingFormattedDistance(listing, distanceUnit);

  const breeds = animalsTotal ? getListingAnimalsBreedPills(listing) : undefined;
  const categoryOfSale = listing.categoryOfSale == null ? listing.categoryOfSale : null;

  const expiryDate = DateTime.fromISO(listing.expiresAt).toJSDate();
  const startDate = DateTime.fromISO(listing.startDate).toJSDate();

  const primaryImage = (listing.images as ListingImages[]).reduce((first, image) => {
    return Number(first?.position) <= Number(image?.position) ? first : image;
  }, listing.images?.[0]);
  const primaryImageSource = primaryImage?.thumbnailSmall || undefined;
  const primaryImageAriaLabel = primaryImage?.thumbnailSmall
    ? "Photograph for listing"
    : "Placeholder image for listing";

  const isExpired = !!listing.isExpired;

  const isPrivate = !listing.isPublic;

  const name = listing.name || undefined;

  const offersCount = listing.offersPendingCount || undefined;

  const status = listing.status || undefined;

  const currencyCodeISO = getCurrencyCode(listing.currency?.code);
  const pricePerHead = listing.price;
  const formattedPricePerHead = pricePerHead !== null ? getFormattedPrice(pricePerHead, currencyCodeISO) : "";
  const priceTotal = listing.totalPrice;
  const formattedPriceTotal = priceTotal !== null ? getFormattedPrice(priceTotal, currencyCodeISO) : "Price unknown";

  return {
    animalsAgeAverage,
    animalsGrowthRateAverage,
    animalsInWithdrawalTotal,
    animalsNamedSireTotal,
    animalsTimesMovedMaximum,
    animalsTimesWeighedAverage,
    animalsTotal,
    animalsWeightAverage,
    breeds,
    categoryOfSale,
    distance,
    expiryDate,
    startDate,
    image: primaryImageSource,
    imageAriaLabel: primaryImageAriaLabel,
    isExpired,
    isPrivate,
    name,
    offersCount,
    pricePerHead: formattedPricePerHead,
    priceTotal: formattedPriceTotal,
    status,
  };
};
