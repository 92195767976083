// React
import React, { memo } from "react";
// Libraries
import { NavLink } from "react-router-dom";
// Resources
import styles from "./PageMenuLink.module.scss";

interface PageMenuLinkProps {
  caption: string;
  path: string;
  id?: string;
}

export const PageMenuLink: React.FC<PageMenuLinkProps> = memo(({ caption, path, ...props }) => (
  <NavLink to={path} className={styles.page_menu_link} activeClassName={styles["page_menu_link--active"]} {...props}>
    <span className={styles.page_menu_link__caption}>{caption}</span>
  </NavLink>
));
PageMenuLink.displayName = "PageMenuLink";
