import {
  Text,
  Button,
  FieldDate,
  FieldSelect,
  FieldText,
  Flex,
  LoadingOverlay,
  Spacer,
  Title,
  Modal,
} from "components";
import { DeliveryStatusLabel } from "deliveries/components/DeliveryStatusLabel";
import { useContext, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { DateTime } from "luxon";
import { getBusinessUnitIdFromLocalStorage } from "helpers/storage";
import { AnimalsList } from "animals/AnimalsList";
import {
  AnimalFragment,
  ContactFragmentFragment,
  AnimalStatus,
  DeliveryDirectionEnum,
  UpdateDeliveryMutationVariables,
  useCommitDeliveryMutation,
  useCreateDeliveryMutation,
  useDeleteDeliveryMutation,
  useDraftDeliveryAnimalsQuery,
  useDraftDeliveryQuery,
  useUpdateDeliveryMutation,
} from "generated/graphql";
import { TABLE_IDS } from "constants/Interface";
import { useHistory } from "react-router-dom";
import { CommonContext } from "config/commonProvider";
import { getErrorMessage } from "helpers/general";
import { useDeliveryReasons } from "deliveries/hooks/useDeliveryReasons";
import { AnimalForm } from "../AnimalForm";
import { getMoveOnValidationSchema } from "./validation";
import { useStateSpecies } from "hooks/useStateSpecies";
import { AddressBookSelect } from "components/Settings/AddressBook/AddressBookSelect";
import { ContactForm } from "components/ContactForm";
import { EmptyStatesNoAnimalsAdded } from "components/EmptyStates/Movements/NoAnimals";
import { useDeliveriesLabels } from "deliveries/hooks/useDeliveriesLabels";

type FormData = yup.InferType<ReturnType<typeof getMoveOnValidationSchema>>;

const TABLE_ID = TABLE_IDS.CREATE_DELIVERY_ANIMALS_TABLE;

export const MoveOnDeliveryForm = ({ deliveryId: id }: { deliveryId?: string }) => {
  const [deliveryId, setDeliveryId] = useState<string | undefined>(id);
  const [showCreateContactModal, setShowCreateContactModal] = useState<boolean>(false);
  const businessUnitId = getBusinessUnitIdFromLocalStorage();
  const { activeSpecies, handleSetActiveSpecies, speciesList } = useStateSpecies();
  const [hasReset, setHasReset] = useState(!id ? true : false);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [deleteDraftDelivery, { loading: deleteDraftDeliveryLoading }] = useDeleteDeliveryMutation();

  const maybeChangeSpecies = (animalTypeId: string | undefined) => {
    if (!animalTypeId) {
      return;
    }

    if (animalTypeId !== activeSpecies?.id) {
      const species = speciesList?.find((species) => species.id === animalTypeId);
      if (species) {
        handleSetActiveSpecies(species);
      }
    }
  };

  const onPressEditAnimal = (animal: AnimalFragment) => {
    maybeChangeSpecies(animal.animalType?.id);

    setSelectedAnimal(animal);
    setShowCreateAnimalForm(true);
  };

  const onDeleteDelivery = async () => {
    try {
      if (deliveryId) {
        const result = await deleteDraftDelivery({
          variables: { input: { businessUnit: businessUnitId, delivery: deliveryId } },
        });

        const errors = result?.data?.deleteDelivery?.errors || [];

        if (errors.length > 0) {
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; "),
          });
          return;
        }
      }
      history.goBack();
      showNotification({
        message: "Draft movement successfully deleted.",
      });
    } catch (error: unknown) {
      showNotification({
        variant: "error",
        message: getErrorMessage(error) || "Something went wrong",
      });
    }
  };

  const [showCreateAnimalForm, setShowCreateAnimalForm] = useState<boolean>(false);
  const [selectedAnimal, setSelectedAnimal] = useState<AnimalFragment | undefined>(undefined);

  const [updateDraftDelivery] = useUpdateDeliveryMutation();
  const [createDraftDelivery] = useCreateDeliveryMutation();
  const [submitDeliveryLoading, setSubmitDeliveryLoading] = useState<boolean>(false);
  const [updateDraftDeliveryLoading, setUpdateDraftDeliveryLoading] = useState<boolean>(false);
  const [submitDelivery] = useCommitDeliveryMutation();

  const { getDeliveryNameLabel, getLocationsLabel } = useDeliveriesLabels();

  const history = useHistory();
  const { showNotification } = useContext(CommonContext);

  const [hasPressedAddAnimals, setHasPressedAddAnimals] = useState<boolean>(false);

  const { deliveryReasons } = useDeliveryReasons(DeliveryDirectionEnum.Inward);

  const { data: deliveryData } = useDraftDeliveryQuery({
    variables: { delivery: deliveryId || "", businessUnit: businessUnitId },
    skip: !deliveryId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const { data: animalsData, loading: animalsDataLoading } = useDraftDeliveryAnimalsQuery({
    variables: { delivery: deliveryId || "", businessUnit: businessUnitId },
    skip: !deliveryId,
  });

  const deliverySources = deliveryData?.delivery?.delivery.sourceSummary.locations || [];

  const deliveryAnimalTypeId = animalsData?.delivery?.animals?.edges
    ? animalsData?.delivery?.animals?.edges[0]?.node?.animalType?.id
    : undefined;

  const deliveryAnimals = useMemo(
    () => animalsData?.delivery?.animals?.edges?.map((edge) => edge?.node) || [],
    [animalsData?.delivery?.animals?.edges],
  );

  const animalIdsDelivery = useMemo(() => deliveryAnimals?.map((animal) => animal?.id) || [], [deliveryAnimals]);

  const validationSchema = useMemo(() => getMoveOnValidationSchema(), []);

  const delivery = deliveryData?.delivery?.delivery;

  const defaultFormValues: FormData = useMemo(() => {
    const defaultArrivedAt = delivery?.arrivedAt ? DateTime.fromISO(delivery.arrivedAt) : DateTime.local();

    return {
      arrivedAt: defaultArrivedAt.toJSDate(),
      name: getDeliveryNameLabel(deliveryData?.delivery?.delivery),
      animalCount: deliveryAnimals?.length || 0,
      reasonId: delivery?.reason?.id || "",
      sourceContactId: "",
    };
  }, [
    delivery?.arrivedAt,
    delivery?.reason?.id,
    deliveryAnimals?.length,
    deliveryData?.delivery?.delivery,
    getDeliveryNameLabel,
  ]);

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const animalCount = methods.watch("animalCount") || 0;
    if (animalCount !== deliveryAnimals?.length || 0) {
      methods.setValue("animalCount", deliveryAnimals?.length || 0);
    }
  }, [deliveryAnimals?.length, methods]);

  useEffect(() => {
    if (!deliveryId && hasPressedAddAnimals) {
      try {
        const createDelivery = async () => {
          const result = await createDraftDelivery({
            variables: {
              input: {
                businessUnit: businessUnitId,
                name: methods.watch("name"),
                direction: DeliveryDirectionEnum.Inward,
                arrivedAt: methods.watch("arrivedAt"),
                reason: methods.watch("reasonId") || undefined,
              },
            },
          });
          const errors = result?.data?.createDelivery?.errors || [];

          if (errors.length > 0) {
            showNotification({
              variant: "error",
              message: errors.map((item) => item?.message).join("; "),
            });
          } else {
            setDeliveryId(result?.data?.createDelivery?.delivery?.id);
          }
        };

        createDelivery();
      } catch (error: unknown) {
        showNotification({
          variant: "error",
          message: getErrorMessage(error),
        });
      }
    }
  }, [businessUnitId, createDraftDelivery, deliveryId, hasPressedAddAnimals, methods, showNotification]);

  useEffect(() => {
    if (deliveryData?.delivery?.delivery.id && !hasReset) {
      methods.reset(defaultFormValues);
      setHasReset(true);
    }
  }, [defaultFormValues, deliveryData?.delivery?.delivery.id, hasReset, methods]);

  const { handleSubmit, control } = methods;

  const onSubmit = async () => {
    try {
      setSubmitDeliveryLoading(true);
      if (deliveryId) {
        const variables: UpdateDeliveryMutationVariables = {
          input: {
            businessUnit: businessUnitId,
            delivery: deliveryId,
            arrivedAt: methods.watch("arrivedAt"),
            reason: methods.watch("reasonId") || undefined,
            name: methods.watch("name"),
          },
        };

        const updateDeliveryResult = await updateDraftDelivery({
          variables,
        });

        const updateDeliveryErrors = updateDeliveryResult?.data?.updateDelivery?.errors || [];

        if (updateDeliveryErrors.length > 0) {
          showNotification({
            variant: "error",
            message: updateDeliveryErrors.map((item) => item?.message).join("; "),
          });
          setSubmitDeliveryLoading(false);
          return;
        }

        const result = await submitDelivery({
          variables: {
            input: {
              delivery: deliveryId,
              businessUnit: businessUnitId,
            },
          },
        });

        const errors = result?.data?.commitDelivery?.errors || [];

        if (errors.length > 0) {
          setSubmitDeliveryLoading(false);
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; ") || "Failed to submit movement",
          });
        } else {
          setSubmitDeliveryLoading(false);
          history.goBack();
          showNotification({
            message: "Movement successfully submitted.",
          });
        }
      } else {
        setSubmitDeliveryLoading(false);
        showNotification({
          variant: "error",
          message: "Error: Missing movement ID.",
        });
      }
    } catch (error: unknown) {
      setSubmitDeliveryLoading(false);
      showNotification({
        variant: "error",
        message: getErrorMessage(error) ?? "Failed to submit movement.",
      });
    }
  };

  const onSaveDraft = async () => {
    try {
      if (methods.watch("name") === "") {
        methods.setValue("name", "", { shouldTouch: true, shouldValidate: true });
        return;
      }

      setUpdateDraftDeliveryLoading(true);
      if (deliveryId) {
        const variables: UpdateDeliveryMutationVariables = {
          input: {
            businessUnit: businessUnitId,
            delivery: deliveryId,
            arrivedAt: methods.watch("arrivedAt"),
            reason: methods.watch("reasonId") || undefined,
            name: methods.watch("name"),
          },
        };

        const result = await updateDraftDelivery({
          variables,
        });

        const errors = result?.data?.updateDelivery?.errors || [];

        if (errors.length > 0) {
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; "),
          });
          setUpdateDraftDeliveryLoading(false);
          return;
        }
      } else {
        const result = await createDraftDelivery({
          variables: {
            input: {
              businessUnit: businessUnitId,
              name: methods.watch("name"),
              direction: DeliveryDirectionEnum.Inward,
              arrivedAt: methods.watch("arrivedAt"),
              reason: methods.watch("reasonId") || undefined,
            },
          },
        });
        const errors = result?.data?.createDelivery?.errors || [];

        if (errors.length > 0) {
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; "),
          });
        }
      }

      setUpdateDraftDeliveryLoading(false);

      history.goBack();

      showNotification({
        message: "Draft movement successfully saved.",
      });
    } catch (error: unknown) {
      setUpdateDraftDeliveryLoading(false);
      showNotification({
        variant: "error",
        message: getErrorMessage(error) || "Failed to save draft.",
      });
    }
  };

  return (
    <>
      <Modal
        active={showDeleteConfirmationModal}
        title="Delete Draft"
        subTitle="Are you sure you want to delete this draft? This will delete all animals added to the movement."
        actions={{
          primary: {
            caption: "Confirm",
            disabled: deleteDraftDeliveryLoading,
            requesting: deleteDraftDeliveryLoading,
            onClick: onDeleteDelivery,
          },
          secondary: {
            caption: "Cancel",
            onClick: () => setShowDeleteConfirmationModal(false),
          },
        }}
      />
      <Modal
        active={showCreateAnimalForm}
        title={selectedAnimal ? `Edit ${activeSpecies?.name}` : `Create ${activeSpecies?.name}`}
        subTitle={selectedAnimal ? "Edit the details of this animal." : "Create a new animal to add to this movement."}
        size="large"
        handleClose={() => {
          setShowCreateAnimalForm(false);
        }}
      >
        <AnimalForm
          deliveryId={deliveryId || ""}
          animal={selectedAnimal}
          onCancel={() => {
            setShowCreateAnimalForm(false);
          }}
          onCreateAnimal={() => {
            setShowCreateAnimalForm(false);
          }}
        />
      </Modal>
      <Modal active={showCreateContactModal} title="Create New Contact">
        <ContactForm
          contactId={undefined}
          onCancel={() => setShowCreateContactModal(false)}
          onConfirm={() => setShowCreateContactModal(false)}
          isModal
        />
      </Modal>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center px-3">
          <DeliveryStatusLabel
            movedAt={methods.watch("arrivedAt").toISOString()}
            reasonId={methods.watch("reasonId")}
            // NOTE: The delivery location is set on the
            // animal level, so if there are animals on the delivery
            // we assume the location has been set.
            isDeliveryLocationSet={Boolean(methods.watch("animalCount"))}
            animalCount={methods.watch("animalCount") || 0}
          />
          <div>
            <Button
              caption="Delete draft"
              type="button"
              variant="ghost"
              className="ml-5 text-red-500 max-w-[90px]"
              onClick={() => setShowDeleteConfirmationModal(true)}
              disabled={updateDraftDeliveryLoading || submitDeliveryLoading || deleteDraftDeliveryLoading}
            />
            <Button
              caption="Cancel"
              type="button"
              variant="hollow"
              colour="grey"
              className="ml-5 min-w-[90px]"
              onClick={() => {
                history.goBack();
              }}
              disabled={updateDraftDeliveryLoading || submitDeliveryLoading}
            />
            <Button
              caption="Save draft"
              type="button"
              colour="green"
              className="ml-5 min-w-[90px]"
              onClick={onSaveDraft}
              requesting={updateDraftDeliveryLoading}
              disabled={updateDraftDeliveryLoading || submitDeliveryLoading}
            />
            <Button
              caption="Submit"
              type="submit"
              className="ml-5 min-w-[90px]"
              disabled={updateDraftDeliveryLoading || submitDeliveryLoading}
              requesting={submitDeliveryLoading}
            />
          </div>
        </div>
        <Spacer baselineHeight={2} />
        <div className="grid gap-6 grid-cols-1 px-3 md:grid-cols-2 xl:grid-cols-[1fr_1fr_1fr_1fr]">
          <Controller
            control={control}
            name="name"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldText
                  label="Name *"
                  error={errors.name?.message}
                  inputProps={{
                    name: "name",
                    placeholder: "Name",
                    value: value || "",
                    onChange,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="arrivedAt"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldDate
                  label={"Date moved on *"}
                  error={errors.arrivedAt?.message}
                  inputProps={{
                    name: "arrivedAt",
                    value,
                    onChange,
                  }}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="reasonId"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label="Reason *"
                  error={errors.reasonId?.message}
                  inputProps={{
                    placeholder: "Reason",
                    value,
                    options: deliveryReasons,
                    onChange,
                    isClearable: true,
                  }}
                />
              );
            }}
          />
          <div>
            <div className="pt-[8px] mb-[-8px]">
              <label>Source</label>
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="sourceContactId"
                render={({ formState: { errors }, field: { value } }) => {
                  return (
                    <AddressBookSelect
                      value={value || ""}
                      errorMessage={errors.sourceContactId?.message}
                      placeholder={deliverySources.length > 0 ? getLocationsLabel(delivery) : "Select from animal form"}
                      disabled
                      onCreateNewContact={() => {
                        methods.setValue("sourceContactId", "");
                        setShowCreateContactModal(true);
                      }}
                      onContactChange={(contact: ContactFragmentFragment | undefined) => {
                        methods.setValue("sourceContactId", contact?.id);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </form>

      <Spacer baselineHeight={2} />

      <Flex item itemGutter>
        <Title secondary>Animals in this movement ({deliveryAnimals?.length ?? 0})</Title>
        {!animalIdsDelivery || animalIdsDelivery.length == 0 ? (
          <>
            <Spacer baselineHeight={2} />
            <Text>You don’t have any animals in this movement, tap the ‘Add animals’ button below to start.</Text>
            <Spacer baselineHeight={2} />
          </>
        ) : (
          <Spacer baselineHeight={2} />
        )}
        <Flex container>
          <Button
            caption="Add animals"
            type="button"
            onClick={() => {
              maybeChangeSpecies(deliveryAnimalTypeId);

              setHasPressedAddAnimals(true);
              setShowCreateAnimalForm(true);
              setSelectedAnimal(undefined);
            }}
            disabled={updateDraftDeliveryLoading || animalsDataLoading || submitDeliveryLoading}
          />
        </Flex>
      </Flex>
      {animalsDataLoading ? <LoadingOverlay /> : null}
      <Spacer baselineHeight={2} />
      {animalIdsDelivery.length > 0 ? (
        <AnimalsList
          tableId={TABLE_ID}
          showSelectColumn={false}
          tableFilterIdentifier="animalsList"
          showAnimalActionButtons={false}
          showIndividualAnimalActionButtons
          rowStyles={(row) => {
            return row.original.dateLeftFarm != null || row.original.deadAt != null ? { opacity: 0.5 } : {};
          }}
          onPressEditAnimal={onPressEditAnimal}
          animalIds={animalIdsDelivery}
          animalStatus={AnimalStatus.Any}
          animalTypeIds={[2, 3]} // Get both sheep and cattle
        />
      ) : (
        <EmptyStatesNoAnimalsAdded />
      )}
    </>
  );
};
