import React from "react";
import { useHistory, Redirect, useParams, useLocation } from "react-router-dom";
import { GROUPS, LIVESTOCK } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useStateSpecies } from "hooks";
import { Button, Breadcrumbs, LoadingOverlay, Header, PageContent, PageHeader, Spacer } from "components";
import { useGroupQuery } from "generated/graphql";
import { GroupAnimalsList } from "groups/components/GroupAnimalsList";
import { getBUFromLocalStorage } from "helpers/storage";
import { BatchInfoBlock } from "groups/components/BatchInfoBlock";
import { GroupInfoBlock } from "groups/components/GroupInfoBlock";

export const Group = () => {
  const { activeSpecies } = useStateSpecies();
  const { groupId } = useParams<LivestockGroupPageParams>();
  const history = useHistory();
  const [addingAnimalsToGroup, setAddingAnimalsToGroup] = React.useState<boolean>(false);
  const businessUnitId = getBUFromLocalStorage();

  const location = useLocation();
  const isBatch = location.pathname.includes("batch");

  const { data, loading, error } = useGroupQuery({
    variables: {
      group: groupId,
      businessUnit: businessUnitId,
      isBatch,
    },
    skip: !groupId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const group = data?.group || undefined;

  if (error) return <Redirect to={GROUPS} />;

  if (!group) {
    if (loading) return <LoadingOverlay customStyles={{ top: 0, left: 0 }} />;

    return <Redirect to={GROUPS} />;
  }

  if (activeSpecies) {
    const isGroupDifferentSpecies = group.animalType?.id !== activeSpecies.id;
    if (isGroupDifferentSpecies) return <Redirect to={GROUPS} />;
  }

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Groups",
              route: GROUPS,
            },
          ]}
          currentPath="Group Information"
        />
        <Header backButton title={group.name || ""} />
        {group.isBatch ? (
          <BatchInfoBlock batch={group}>
            <Spacer baselineHeight={3} />
            <Button
              id={ELEMENTS_IDS.EDIT_GROUP_BUTTON}
              caption="Edit"
              onClick={(): void => history.push(`${LIVESTOCK}/batch/edit/${group.id}`)}
            />
          </BatchInfoBlock>
        ) : (
          <GroupInfoBlock group={group}>
            {!addingAnimalsToGroup ? (
              <Button
                id={ELEMENTS_IDS.EDIT_GROUP_BUTTON}
                caption="Edit group"
                onClick={(): void => history.push(`${LIVESTOCK}/group/edit/${group.id}`)}
              />
            ) : null}
          </GroupInfoBlock>
        )}

        <Spacer baselineHeight={3} />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />
        <GroupAnimalsList
          isBatch={isBatch}
          groupId={group.id}
          addingAnimalsToGroup={addingAnimalsToGroup}
          setAddingAnimalsToGroup={setAddingAnimalsToGroup}
        />
      </PageContent>
    </>
  );
};
