import { gql } from "@apollo/client";

export const sireFragment = gql`
  fragment sireFragment on Sire {
    id
    name
    passportNumber
    registrationNumber
    pedigree
    sire
  }
`;

export const errorFragment = gql`
  fragment errorFragment on ValidationError {
    field
    message
  }
`;
