import { WizardOptions } from "import/components/WizardOptions";
import { useHasFeature } from "hooks";
import { ImportSystem } from "import/containers/ImportTypes/ImportSystem";
import LogoEzFeed from "assets/img/logo-EZFeed.png";
import { useHistory } from "react-router-dom";
import { CUSTOM_IMPORT, IMPORT } from "constants/Routes";

export const FeedSystemsUploadButton = () => {
  const useOshkosh = useHasFeature("OSHKOSH");
  const history = useHistory();

  return (
    <>
      {useOshkosh ? (
        <WizardOptions.Option
          name="Feed Systems"
          onClick={() => {
            history.push(`${IMPORT}${CUSTOM_IMPORT}/ezfeed`);
          }}
        >
          <ImportSystem>
            <img alt="EZFeed Logo" src={LogoEzFeed} />
          </ImportSystem>
        </WizardOptions.Option>
      ) : null}
    </>
  );
};
