import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { MARK_LOT_LIVESTOCK_AS_RECEIVED } from "trading/api/Liveweight/mutations";
import {
  LotBuyerReceivedAnimals,
  LotBuyerReceivedAnimalsVariables,
} from "trading/api/Liveweight/types/LotBuyerReceivedAnimals";
import { Button } from "components";
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";

interface BuyerListingDeliveryProps {
  listingId: number;
  onNoFurtherAction: () => void;
}

export const BuyerListingDelivery: React.FC<BuyerListingDeliveryProps> = ({ listingId, onNoFurtherAction }) => {
  const { showNotification } = useContext(CommonContext);

  const [markLotLivestockAsReceived] = useMutation<LotBuyerReceivedAnimals, LotBuyerReceivedAnimalsVariables>(
    MARK_LOT_LIVESTOCK_AS_RECEIVED,
  );

  const handleMarkAsReceived = async (): Promise<void> => {
    try {
      const { data } = await markLotLivestockAsReceived({
        variables: {
          input: {
            businessUnit: getBUFromLocalStorage(),
            id: listingId,
          },
        },
      });

      if (data?.lotBuyerReceivedAnimals?.errors) {
        showNotification({
          message: "We were unable to mark animals as received, please try again.",
          variant: "error",
        });
      } else {
        onNoFurtherAction();
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error marking animals as received.",
      });
    }
  };

  return <Button caption="Mark as received" onClick={handleMarkAsReceived} />;
};
