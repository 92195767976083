import React, { FormEvent, useReducer } from "react";
import { AnimalFilters_animalFilters } from "api/MyLivestock/Animal/types/AnimalFilters";
import { ANIMAL_FILTER_COLORS, ANIMAL_FILTER_COLOR_OPTIONS } from "constants/Animals";
import { CheckBox, Button, FieldSelect, FieldText, Flex, Spacer, Text } from "components";
import { useHasFeature } from "hooks";

const MAX_TEMPLATE_NAME_LENGTH = 255;

export interface FilterFormData {
  id?: number;
  homepageAlertColor: string;
  isHomepageAlert: boolean;
  name: string;
}

interface CreateEditFilterFormProps {
  filterTemplate?: AnimalFilters_animalFilters;
  onCancel: () => void;
  onSubmit: (filterTemplate: FilterFormData) => void;
  isLoading: boolean;
}

type State = {
  name: string;
  isHomepageAlert: boolean;
  homepageAlertColor: string;
};

type Action =
  | { type: "setName"; payload: string }
  | { type: "setIsHomepageAlert"; payload: boolean }
  | { type: "setHomepageAlertColor"; payload: string };

const init = ({
  name,
  homepageAlertColor,
  isHomepageAlert,
}: Pick<State, "name" | "homepageAlertColor" | "isHomepageAlert">): State => {
  return {
    name,
    isHomepageAlert,
    homepageAlertColor,
  };
};

const reducer = (state: Readonly<State>, action: Action): State => {
  switch (action.type) {
    case "setName":
      return { ...state, name: action.payload };
    case "setIsHomepageAlert":
      return { ...state, isHomepageAlert: action.payload };
    case "setHomepageAlertColor":
      return { ...state, homepageAlertColor: action.payload };
    default:
      return state;
  }
};

export const CreateEditFilterForm: React.FC<CreateEditFilterFormProps> = ({
  filterTemplate,
  onCancel,
  onSubmit,
  isLoading,
}) => {
  const [{ name, isHomepageAlert, homepageAlertColor }, dispatch] = useReducer(
    reducer,
    {
      name: filterTemplate?.name || "",
      homepageAlertColor: filterTemplate?.color || ANIMAL_FILTER_COLORS.GREEN,
      isHomepageAlert: !!filterTemplate?.isAlert,
    },
    init,
  );

  const showColorOption = useHasFeature("SAVE_ANIMAL_FILTER_AS_ALERT");
  const isTemplateNameLengthInvalid: boolean = name.length > MAX_TEMPLATE_NAME_LENGTH;
  const isTemplateValueValid: boolean = name.length > 0 && !isTemplateNameLengthInvalid;
  const isSubmitDisabled: boolean = !isTemplateValueValid || isLoading;
  const nameError: string = isTemplateNameLengthInvalid
    ? `Name should be between 1-${MAX_TEMPLATE_NAME_LENGTH} characters`
    : "";

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({ type: "setName", payload: event.target.value });
  };

  const handleIsHomepageAlertChange = (): void => {
    dispatch({ type: "setIsHomepageAlert", payload: !isHomepageAlert });
  };

  const handleHomepageAlertColorChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({ type: "setHomepageAlertColor", payload: event.target.value });
  };

  const handleFormSubmit = (event: FormEvent): void => {
    event.preventDefault();

    onSubmit({
      ...(filterTemplate?.id ? { id: +filterTemplate.id } : {}),
      homepageAlertColor,
      isHomepageAlert,
      name,
    });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Flex container>
        <Flex item itemGutter xs="fill">
          <FieldText
            error={nameError}
            label="Saved filter name"
            inputProps={{
              name: "template",
              value: name,
              "data-testid": "filter-name",
              onChange: handleNameChange,
            }}
          />
        </Flex>
      </Flex>

      {showColorOption ? (
        <Flex container>
          <Flex item itemGutter xs="fill">
            <Spacer baselineHeight={1} />

            <CheckBox checked={isHomepageAlert} label="Save as homepage alert" onChange={handleIsHomepageAlertChange} />

            <Text smallest>
              If selected, this will show as a homepage alert when at least one animal meets the criteria
            </Text>

            {isHomepageAlert ? (
              <>
                <Spacer baselineHeight={2} />

                <FieldSelect
                  label="Alert type"
                  inputProps={{
                    value: homepageAlertColor,
                    options: ANIMAL_FILTER_COLOR_OPTIONS,
                    onChange: handleHomepageAlertColorChange,
                    labelKey: "label",
                    valueKey: "value",
                  }}
                />
              </>
            ) : null}
          </Flex>
        </Flex>
      ) : null}

      <Spacer baselineHeight={4} />

      <Flex container containerJustifyContent="flex-end">
        <Flex item itemGutter>
          <Button caption="Cancel" colour="grey" onClick={onCancel} variant="hollow" />
        </Flex>

        <Flex item itemGutter>
          <Button caption="Submit" disabled={isSubmitDisabled} onClick={handleFormSubmit} requesting={isLoading} />
        </Flex>
      </Flex>
    </form>
  );
};
