import { FC, SyntheticEvent, useState, useRef } from "react";
// Libraries
import cn from "classnames";
import { Popover } from "@material-ui/core";
// Utils
import { useOutsideClick } from "hooks";
// Components
import { Button } from "components";
// Resources
import styles from "./Ellipsis.module.scss";

export interface EllipsisAction {
  caption: string;
  disabled?: boolean;
  key?: string;
  onClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  warning?: boolean;
}

interface EllipsisMenuProps {
  actions: EllipsisAction[];
}

export const EllipsisMenu: FC<EllipsisMenuProps> = ({ actions }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const ref = useRef<HTMLSpanElement>(null);

  const hidePopover = (): void => setVisible(false);

  useOutsideClick(ref, hidePopover);

  return (
    <span ref={ref}>
      <Button
        height="small"
        icon={{
          position: "left",
          props: {
            name: "ellipsis",
            size: "small",
          },
        }}
        onClick={(): void => setVisible(!visible)}
        variant="ghost"
      />

      <Popover
        anchorEl={ref.current}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        classes={{ paper: styles.ellipsis_menu }}
        open={visible}
        transformOrigin={{ horizontal: "right", vertical: "center" }}
      >
        {actions.map(({ caption, disabled, key, onClick, warning }) => (
          <button
            className={cn(styles.ellipsis_menu__item, {
              [styles["ellipsis_menu__item--warning"]]: warning,
            })}
            disabled={disabled}
            key={key || caption}
            onClick={onClick}
            type="button"
          >
            {caption}
          </button>
        ))}
      </Popover>
    </span>
  );
};
