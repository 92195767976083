import React from "react";
// Libraries
import { DateTime } from "luxon";
import pure from "recompose/pure";
import cn from "classnames";
// Hooks
import { useFormatDate } from "hooks";
// Styles
import styles from "./Notification.module.scss";

interface NotificationProps {
  avatar?: string;
  title: string;
  description: string;
  date: string;
  read: boolean;
}
const Notification = pure(({ avatar, title, description, date, read = false }: NotificationProps) => {
  const { getFormatDate } = useFormatDate();
  return (
    <article
      className={cn(styles.notification, {
        [styles["notification--new"]]: !read,
      })}
    >
      <span className={styles.notification__avatar}>{avatar}</span>
      <div className={styles.notification__details}>
        <h1 className={styles.notification__title}>{title}</h1>
        <p className={styles.notification__description}>{description}</p>
        <p className={styles.notification__date}>
          <span className={styles.notification__date_since}>{DateTime.fromISO(date).toRelative()}</span>
          <span className={styles.notification__date_exact}>- {getFormatDate(date)}</span>
        </p>
      </div>
    </article>
  );
});
Notification.displayName = "Notification";

export default Notification;
