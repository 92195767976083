// https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk

import { useHistory } from "react-router-dom";
import { useHasFeature } from ".";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
    HubSpotConversations?: any;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _hsq: Array<Array<unknown>>;
  }
}

export const useHubSpot = () => {
  const history = useHistory();
  const isHubSpotChatEnabled = useHasFeature("ENABLE_HUBSPOT_CHAT");
  const openHubSpotChat = (chatAddress?: string) => {
    if (!isHubSpotChatEnabled) {
      return;
    }
    try {
      // chatAddress="chat" opens a specific chat window that is configured in HubSpot
      if (chatAddress) {
        history.push(chatAddress);
      }
      const status = window.HubSpotConversations.widget?.status();
      if (status.loaded) {
        window.HubSpotConversations.widget.open();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("HubSpot Chat is not available: ", error);
    }
  };

  return { openHubSpotChat, isHubSpotChatEnabled };
};
