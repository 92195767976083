import React from "react";
// Components
import { Header, PageContent, PageHeader, Spacer } from "components";

const OfflineReports: React.FC = () => {
  return (
    <>
      <PageHeader>
        <Header title="Temporarily Unavailable" />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />
        <p>
          Oops! Reporting is temporarily unavailable or is undergoing maintenance. We are working diligently to resolve
          the issue, please check back soon.
        </p>
      </PageContent>
    </>
  );
};

export default OfflineReports;
