import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";
import { computeTitlePart, computeTextPart } from "helpers/regulatory";
import { UPDATE_REGULATORY_TRANSACTION } from "api/Regulatory/mutations";
import {
  RegulatoryTransactionApprovalsResponse,
  RegulatoryTransactionApprovalsResponseVariables,
} from "api/Regulatory/types/RegulatoryTransactionApprovalsResponse";
import { Flex, Button, Modal } from "components";
import { TransactionType } from "components/Regulatory/Regulatory";

interface Props {
  selectedTransactions: string[];
  transactionType: TransactionType;
  onSuccessSubmit: () => void;
}

const computeTextTypePart = (transactionType: TransactionType): string => {
  switch (transactionType) {
    case "REGISTER_BIRTH": {
      return "births";
    }
    case "MOVE_ON":
    case "MOVE_OFF": {
      return "movements";
    }
    case "REGISTER_DEATH": {
      return "deaths";
    }
    default: {
      return "";
    }
  }
};

export const RejectApprove: React.FC<Props> = ({ selectedTransactions, transactionType, onSuccessSubmit }) => {
  const [visibleModal, setVisibleModal] = useState<"reject" | "approve" | "">("");

  const isRejectModal: boolean = visibleModal === "reject";
  const businessUnit = getBUFromLocalStorage();

  const { showNotification } = useContext(CommonContext);

  const [updateTransactions, { loading }] = useMutation<
    RegulatoryTransactionApprovalsResponse,
    RegulatoryTransactionApprovalsResponseVariables
  >(UPDATE_REGULATORY_TRANSACTION, {
    variables: {
      input: {
        businessUnit,
        transactionType,
        regulatoryTransactionApprovals: selectedTransactions,
        response: isRejectModal ? "CANCELED" : "APPROVED",
      },
    },
  });

  const transactionsCount: number = selectedTransactions.length;
  const isButtonsDisabled = !transactionsCount;
  const rejectTitle = `Reject ${computeTitlePart(transactionType, transactionsCount)}`;
  const approveTitle = `Approve ${computeTitlePart(transactionType, transactionsCount)}`;
  const rejectText = `Confirm you want to reject ${computeTextPart(
    transactionType,
    transactionsCount,
  )}. Any rejected ${computeTextTypePart(transactionType)} will not be transmitted and the activity will be reversed.`;
  const approveText = `Confirm you want to approve and send ${computeTextPart(transactionType, transactionsCount)}.`;
  const title: string = isRejectModal ? rejectTitle : approveTitle;
  const text: string = isRejectModal ? rejectText : approveText;

  const handleRejectClick = (): void => {
    setVisibleModal("reject");
  };

  const handleApproveClick = (): void => {
    setVisibleModal("approve");
  };

  const handleModalClose = (): void => {
    setVisibleModal("");
  };

  const handleConfirmClick = async (): Promise<void> => {
    const successMovementWord: string = transactionsCount > 1 ? "Movements" : "Movement";
    const errorMovementWord: string = transactionsCount > 1 ? "movements" : "movement";

    const successText = isRejectModal
      ? `${successMovementWord} successfully rejected`
      : `${successMovementWord} successfully approved`;
    const errorText = isRejectModal ? `Error rejecting ${errorMovementWord}` : `Error approving ${errorMovementWord}`;

    try {
      const { data } = await updateTransactions();
      const errors = data?.regulatoryTransactionApprovalsResponse?.errors;

      if (errors) {
        showNotification({
          variant: "error",
          message: errorText,
        });
      } else {
        showNotification({
          message: successText,
        });
        setVisibleModal("");
        onSuccessSubmit();
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: errorText,
      });
    }
  };

  return (
    <>
      <Flex container>
        <Flex item itemGutter>
          <Button
            caption="Reject"
            colour="grey"
            variant="hollow"
            disabled={isButtonsDisabled}
            onClick={handleRejectClick}
          />
        </Flex>
        <Flex item>
          <Button caption="Approve and send" disabled={isButtonsDisabled} onClick={handleApproveClick} />
        </Flex>
      </Flex>

      <Modal
        showCloseButton
        active={!!visibleModal}
        handleClose={handleModalClose}
        title={title}
        subTitle={text}
        actions={{
          secondary: {
            onClick: handleModalClose,
            caption: "Cancel",
          },
          primary: {
            onClick: handleConfirmClick,
            caption: "Confirm",
            disabled: loading,
            requesting: loading,
          },
        }}
      />
    </>
  );
};
