import { gql } from "@apollo/client";
import { businessUnitUser } from "api/User/fragment";
import { BU_FULL_FIELDS } from "feShared/businessUnit/fragments";

export const businessUnitTarget = gql`
  fragment businessUnitTarget on BusinessUnitTarget {
    id
    name
    details
    animalType {
      id
    }
  }
`;

export const GET_BUSINESS_UNITS_LIST = gql`
  query GetBusinessUnits {
    businessUnits {
      ...businessUnitFull
    }
  }
  ${BU_FULL_FIELDS}
`;

export const strippedBusinessUnit = gql`
  fragment BUStripped on BusinessUnit {
    id
    name
  }
`;

export const GET_STRIPPED_BUSINESS_UNITS_LIST = gql`
  query GetStrippedBusinessUnits {
    businessUnits {
      ...BUStripped
    }
  }
  ${strippedBusinessUnit}
`;

export const GET_BUSINESS_UNIT_BY_ID = gql`
  query GetBusinessUnit($businessUnit: Int!) {
    businessUnit(businessUnit: $businessUnit) {
      ...businessUnitFull
    }
  }
  ${BU_FULL_FIELDS}
`;

export const GET_BUSINESS_UNIT_USERS = gql`
  query GetBusinessUnitUsers($businessUnitId: Int) {
    businessUnitUsers(businessUnitId: $businessUnitId) {
      ...businessUnitUser
    }
  }
  ${businessUnitUser}
`;

export const GET_BUSINESS_UNIT_SETTINGS = gql`
  query GetBusinessUnitSettings($businessUnit: Int!) {
    businessUnit(businessUnit: $businessUnit) {
      id
      killOut
      enableBcmsPushTransactions
      tbStatus
      dateJoined
    }
  }
`;

export const GET_BUSINESS_UNIT_SIZE_UNIT = gql`
  query GetBusinessUnitSizeUnit($businessUnit: Int!) {
    businessUnit(businessUnit: $businessUnit) {
      id
      settings {
        sizeUnit
      }
    }
  }
`;

export const SEARCH_BU = gql`
  query SearchBusinessUnits(
    $query: String
    $roles: [String]
    $excludeBusinessUnitId: Int
    $preferredSuppliersBuyerId: Int
  ) {
    searchBusinessUnits(
      query: $query
      roles: $roles
      excludeBusinessUnitId: $excludeBusinessUnitId
      preferredSuppliersBuyerId: $preferredSuppliersBuyerId
    ) {
      id
      name
      location {
        city
        unitName
      }
    }
  }
`;

export const SEARCH_PREFERRED_BUSINESS_UNITS = gql`
  query SearchPreferredBusinessUnits(
    $query: String
    $roles: [String]
    $excludeBusinessUnitIds: [Int!]
    $preferredSuppliersBuyerId: Int
  ) {
    searchBusinessUnits(
      query: $query
      roles: $roles
      excludeBusinessUnitIds: $excludeBusinessUnitIds
      preferredSuppliersBuyerId: $preferredSuppliersBuyerId
    ) {
      id
      name
      location {
        city
        unitName
      }
    }
  }
`;

export const GET_PREFERRED_BUSINESS_UNITS = gql`
  query GetPreferredBusinessUnits($businessUnit: Int!) {
    preferredBusinessUnits(businessUnit: $businessUnit) {
      id
      supplier {
        id
        name
        primaryLocation {
          id
          city
          unitName
        }
      }
      buyer {
        id
        name
        primaryLocation {
          id
          city
          unitName
        }
      }
      fieldsman {
        id
        firstName
        lastName
      }
      priceAdjustment
      commonStatus
      tacTitle
      tacDescription
    }
  }
`;

export const GET_PREFERRED_BUSINESS_UNIT = gql`
  query GetPreferredBusinessUnit($businessUnit: Int!, $id: Int!) {
    preferredBusinessUnit(businessUnit: $businessUnit, id: $id) {
      id
      supplier {
        id
        name
      }
      fieldsman {
        id
      }
      priceAdjustment
    }
  }
`;

export const EMPTY_STATE = gql`
  query EmptyState($businessUnitId: Int!, $page: String!, $platform: String!, $animalType: Int!) {
    emptyState(businessUnitId: $businessUnitId, page: $page, platform: $platform, animalType: $animalType) {
      id
      text
      image
      page
      showButton
      button
      buttonLink
      buttonLabel
    }
  }
`;
