import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";
import React, { useCallback } from "react";
import { epdNameLookup, filterNodesByName } from "../helpers";
import { useGetAnimalIdPreferences } from "hooks";
import { convertSnakeToCamelCase, identifierNameLookup } from "components/Settings/IdPreferencesForm/helpers";
import { useHistory } from "react-router-dom";
import { ANIMAL_VIEW } from "constants/Routes";
import { Icons } from "../icons";
import { FamilyTreeFiltersState } from "../types";
import { RawNodeDatum } from "react-d3-tree";

interface DetailsNodeProps {
  nodeData: RawNodeDatum;
  toggleNode?: () => void;
  foreignObjectProps?: React.SVGProps<SVGForeignObjectElement>;
  filterState: FamilyTreeFiltersState;
  treeData: RawNodeDatum;
}

export const DetailsNode: React.FC<DetailsNodeProps> = ({
  nodeData,
  toggleNode,
  foreignObjectProps = {},
  filterState,
  treeData,
}) => {
  const { name, attributes } = nodeData;
  const { epd, minEpdTarget, maxEpdTarget, highlightInbreeding } = filterState;
  const { terms } = useLocale();
  const { primaryId, secondaryId } = useGetAnimalIdPreferences();

  const maleIcon = (
    <svg
      x={-16}
      y={-16}
      fill="lightBlue"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 393.739 393.739"
      onClick={toggleNode}
    >
      <circle cx="50%" cy="50%" r="50%" fill="white" stroke="none" />
      <g>
        <path
          d="M370.907,0h-93.048c-9.091,0-16.455,7.365-16.455,16.45c0,9.085,7.364,16.453,16.455,16.453h43.19L217.25,136.704
		c-21.049-12.879-45.768-20.318-72.194-20.318c-76.468,0-138.679,62.208-138.679,138.676c0,76.474,62.211,138.678,138.679,138.678
		s138.678-62.204,138.678-138.678c0-33.07-11.655-63.455-31.037-87.314L354.462,65.985v49.231c0,9.085,7.365,16.452,16.444,16.452
		c9.09,0,16.455-7.367,16.455-16.452V16.45C387.362,7.365,379.997,0,370.907,0z M145.056,346.737
		c-50.546,0-91.673-41.127-91.673-91.676c0-50.543,41.121-91.667,91.673-91.667c50.546,0,91.664,41.124,91.664,91.667
		C236.72,305.61,195.602,346.737,145.056,346.737z"
        />
      </g>
    </svg>
  );

  const femaleIcon = (
    <svg
      x={-16}
      y={-16}
      fill="lightPink"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 477.141 477.141"
      onClick={toggleNode}
    >
      <circle width="40" height="110%" cx="50%" cy="50%" r="50%" fill="white" stroke="none" />
      <g>
        <path
          d="M326.711,341.99h-64.628v-64.634c0-0.686-0.142-1.323-0.213-1.997c65.409-11.119,115.378-68.161,115.378-136.681
		C377.249,62.208,315.044,0,238.571,0C162.103,0,99.892,62.208,99.892,138.678c0,68.521,49.973,125.562,115.375,136.681
		c-0.059,0.674-0.204,1.312-0.204,1.997v64.634h-64.631c-12.983,0-23.501,10.527-23.501,23.502c0,12.98,10.518,23.507,23.501,23.507
		h64.637v64.64c0,12.98,10.524,23.502,23.501,23.502c12.979,0,23.513-10.527,23.513-23.502v-64.64h64.628
		c12.986,0,23.508-10.526,23.508-23.507C350.219,352.518,339.698,341.99,326.711,341.99z M146.904,138.678
		c0-50.546,41.121-91.673,91.667-91.673c50.549,0,91.676,41.122,91.676,91.673s-41.127,91.667-91.676,91.667
		C188.031,230.346,146.904,189.224,146.904,138.678z"
        />
      </g>
    </svg>
  );

  ///temporary random epds
  // const [epds, setEpds] = useState({});

  // useEffect(() => {
  //   const randomEpds = {
  //     calvingEase: Math.floor(Math.random() * (5 - 1 + 1) + 1),
  //     birthWeight: Math.floor(Math.random() * (120 - 40 + 1) + 40),
  //     weaningWeight: Math.floor(Math.random() * (450 - 180 + 1) + 180),
  //     yearlingWeight: Math.floor(Math.random() * (650 - 250 + 1) + 250),
  //     dryMatterIntake: Math.floor(Math.random() * (22 - 8 + 1) + 8),
  //     yearlingHeight: Math.floor(Math.random() * (80 - 40 + 1) + 40),
  //     scrotalCircumference: Math.floor(Math.random() * (50 - 20 + 1) + 20),
  //     docility: Math.floor(Math.random() * (32 - 14 + 1) + 1),
  //     clawAngle: Math.floor(Math.random() * (90 - 20 + 1) + 20),
  //     footAngle: Math.floor(Math.random() * (90 - 20 + 1) + 20),
  //     heiferPregnancy: Math.floor(Math.random() * (5 - 1 + 1) + 1),
  //     calvingEaseMaternal: Math.floor(Math.random() * (5 - 1 + 1) + 1),
  //     milk: Math.floor(Math.random() * (5 - 1 + 1) + 1),
  //     matureWeight: Math.floor(Math.random() * (1200 - 450 + 1) + 400),
  //     matureHeight: Math.floor(Math.random() * (220 - 80 + 1) + 100),
  //     carcassWeight: Math.floor(Math.random() * (500 - 160 + 1) + 160),
  //     carcassMarbling: Math.floor(Math.random() * (5 - 1 + 1) + 1),
  //   };

  //   setEpds(randomEpds);
  // }, []);

  // const epdIsInRange =
  //(attributes?.[epd] as number) >= minEpdTarget && (attributes?.[epd] as number) <= maxEpdTarget;

  const epds = {};
  const epdIsInRange = epds[epd] >= minEpdTarget && epds[epd] <= maxEpdTarget;

  const nodeColour = epdIsInRange ? "bg-green-100" : "bg-gray-50";
  const isInbreeding = filterNodesByName(treeData, name).length > 1;
  const history = useHistory();
  const handleClickLink = useCallback(
    (animalId: string) => {
      history.push(`${ANIMAL_VIEW.replace(":id", animalId)}`);
    },
    [history],
  );

  const nodeIcon = attributes?.isMale ? maleIcon : femaleIcon;

  const nameIsPrimaryOrSecondaryId = primaryId === "NAME" || secondaryId === "NAME";
  const pedigreeIsPrimaryOrSecondaryId = primaryId === "PEDIGREE_ID" || secondaryId === "PEDIGREE_ID";

  return (
    <>
      {nodeIcon}
      <foreignObject {...foreignObjectProps}>
        <div
          className={`flex p-2 flex-row ${nodeColour} shadow-sm rounded-lg cursor-grab border border-solid border-1 border-gray-300`}
        >
          <div className="w-[90%]">
            <p className="text-sm font-semibold text-gray-900">{attributes?.[convertSnakeToCamelCase(primaryId)]}</p>
            <p className="text-sm font-sm text-gray-900">
              {`${capitaliseFirstLetter(identifierNameLookup(terms, convertSnakeToCamelCase(secondaryId)))}: 
              ${attributes?.[convertSnakeToCamelCase(secondaryId)]}`}
            </p>
            {attributes?.pedigreeId !== "" && !pedigreeIsPrimaryOrSecondaryId ? (
              <p className="mb-1 text-sm text-gray-900">
                {capitaliseFirstLetter(terms.pedigreeId)}: {attributes?.pedigreeId}
              </p>
            ) : null}
            {attributes?.name !== "" && !nameIsPrimaryOrSecondaryId ? (
              <p className="mb-1 text-sm text-gray-900">{`Name: ${attributes?.name}`}</p>
            ) : null}
            {!!epd && epds[epd] ? (
              <p className="mb-1 text-sm text-gray-900">
                {epdNameLookup[epd]}: {epds[epd]}
              </p>
            ) : null}
          </div>
          <div className="w-[10%] flex-col">
            {attributes?.id && treeData.name !== name ? (
              <div className="pb-2">
                <a className="cursor-pointer" onClick={() => handleClickLink(attributes?.id as string)}>
                  {Icons.externalLinkIcon}
                </a>
              </div>
            ) : null}
            {attributes?.pedigreeId !== "" ? <div className="pb-2">{Icons.pedigreeIcon}</div> : null}
            {attributes?.offFarm ? <div className="pb-2">{Icons.truckIcon}</div> : null}
            {highlightInbreeding && isInbreeding ? <div className="pb-2">{Icons.alertIcon}</div> : null}
          </div>
        </div>
      </foreignObject>
    </>
  );
};
