import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AnimalFilters,
  areFiltersForTableDirty,
  resetFilters,
  showAllFilters,
  TableFilterIdentifier,
  toggleFilterVisibility,
  updateFilters,
} from "state/animalFiltersSlice";
import { RootState } from "state/store";

export const useAnimalFilters = (tableIdentifier: TableFilterIdentifier) => {
  const dispatch = useDispatch();

  const { filters, showFilters } = useSelector((state: RootState) => state.animalFilters[tableIdentifier]);
  const areFiltersDirty = useSelector((state: RootState) => areFiltersForTableDirty(tableIdentifier)(state));

  const handleResetFilters = () => dispatch(resetFilters(tableIdentifier));
  const handleToggleFilterVisibility = () => dispatch(toggleFilterVisibility(tableIdentifier));
  const handleShowAllFilters = () => dispatch(showAllFilters(tableIdentifier));
  const handleApplyFilter = useCallback(
    (filter: Partial<AnimalFilters>) =>
      dispatch(updateFilters({ tableIdentifier, filters: { ...filters, ...filter } })),
    [dispatch, filters, tableIdentifier],
  );

  return {
    areFiltersDirty,
    showFilters,
    filters,
    handleApplyFilter,
    handleResetFilters,
    handleToggleFilterVisibility,
    handleShowAllFilters,
  };
};
