// @ts-nocheck
import React from "react";
import MaskedInput from "react-text-mask";
// Components
import { Field, FieldSelect, FieldText } from "components";
import { BaseInput } from "components/Common/Field/BaseInput";
// Types
import { GetFarmSystems_farmSystems_BcmsConnectionType_integrationSystem_fields } from "api/Settings/types/GetFarmSystems";

interface Props {
  field: GetFarmSystems_farmSystems_BcmsConnectionType_integrationSystem_fields;
  errors: IntegrationSystemFieldsEdit;
  values: IntegrationSystemFieldsEdit;
  handleChange: () => void;
  handleSubmit: () => void;
  handleErrorCheck: (error: Record<string, unknown>) => void;
  handleEnterPress: (event: React.SyntheticEvent<HTMLInputElement>, handleSubmit: () => void) => void;
  filteredAddress: Array<{
    key: string;
    label: string;
    value: string;
  }>;
}

function parseInputMask(inputMask: string): string[] {
  return inputMask.split(",").map((str) => {
    const isRegEx = str.includes("/");
    const strippedPattern = str.trim().slice(1, -1);

    return isRegEx ? new RegExp(strippedPattern) : strippedPattern;
  });
}

const SelectedSystemFields: React.FC<Props> = ({
  field,
  errors,
  values,
  handleChange,
  handleSubmit,
  handleErrorCheck,
  handleEnterPress,
  filteredAddress,
}) => {
  if (field.fieldName === "location") {
    return (
      <FieldSelect
        label={field.fieldLabel}
        key={field.fieldName}
        error={handleErrorCheck(errors[field.fieldName])}
        inputProps={{
          name: field.fieldName,
          value: values[field.fieldName] || "",
          options: filteredAddress,
          onChange: handleChange,
          placeholder: field.placeholder,
        }}
      />
    );
  }

  if (field.inputMask) {
    return (
      <MaskedInput
        key={field.fieldName}
        label={field.fieldLabel}
        mask={parseInputMask(field.inputMask)}
        name={field.fieldName}
        onChange={handleChange}
        placeholder={field.placeholder}
        type={field.fieldType.toLowerCase()}
        render={(ref, props): JSX.Element => (
          <Field error={errors[field.fieldName]} labelFor={field.fieldName} {...props}>
            <BaseInput hasError={!!errors[field.fieldName]} ref={ref} {...props} />
          </Field>
        )}
        value={values[field.fieldName]}
      />
    );
  }

  return (
    <FieldText
      error={handleErrorCheck(errors[field.fieldName])}
      key={field.fieldName}
      label={field.fieldLabel}
      inputProps={{
        autoComplete: "new-password",
        value: values[field.fieldName] || "",
        maxLength: field.maxLength,
        minLength: field.minLength,
        name: field.fieldName,
        onChange: handleChange,
        onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
        placeholder: field.placeholder,
        type: field.fieldType.toLowerCase(),
      }}
    />
  );
};

export default SelectedSystemFields;
