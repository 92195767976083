// Utils
import { getListingAddressPublic } from "trading/util";
import { BuyingLotNode } from "../../..";

export const getBuyerListingCardLocation = (listing: BuyingLotNode): string | undefined => {
  const obscuredAddress = listing.deliveryAddress
    ? getListingAddressPublic({
        countryName: listing.deliveryAddress.country?.name,
        postcode: listing.deliveryAddress.postcode,
      })
    : null;

  if (obscuredAddress) return obscuredAddress;

  return;
};
