import { FC } from "react";
import { useBreakpoints, useGetCurrentBusinessUnit, useHasFeature, useStateSpecies } from "hooks";
import { getFormattedNumber } from "helpers/general";
import { Flex, KeyValuePair, Spacer, Text } from "components";
import { AnimalsBreedSummary } from "components/MyLivestock";
import { KeyValuePairProps } from "components/Common/KeyValuePair";
import styles from "./FieldInfoBlock.module.scss";
import { getAnimalCountFromField } from "helpers/myLivestock";
import { FieldQuery, SummaryMetrics } from "generated/graphql";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";

interface FieldInfoBlockProps {
  field: FieldQuery["field"] | null | undefined;
  children?: React.ReactNode;
}

interface FieldInfoItems extends KeyValuePairProps {
  isHidden?: boolean;
}

export const FieldInfoBlock: FC<FieldInfoBlockProps> = ({ children, field }) => {
  const { speciesList } = useStateSpecies();
  const { terms } = useLocale();

  const {
    description,
    isOrganic,
    isYard,
    location,
    pastureTypes,
    size,
    sizeUnit,
    fieldDensity,
    avgDlwg,
    avgEstimatedWeight,
    avgRecordedWeight,
    avgAge,
    summaryMetrics,
  } = {
    ...field,
  };

  const { country, locationId, unitName: locationName } = { ...location };
  const { name: locationCountry } = { ...country };

  const { isBreakpointL, isBreakpointXL } = useBreakpoints();
  const { weightUnits } = useGetCurrentBusinessUnit();
  const showFieldRedesign = useHasFeature("SHOW_FIELD_REDESIGN");

  const animalCount = getAnimalCountFromField(summaryMetrics as SummaryMetrics[]);

  if (showFieldRedesign && field) {
    const fieldInfo: FieldInfoItems[] = [
      {
        label: "Location",
        value: locationName,
      },
      {
        label: capitaliseFirstLetter(terms.farmId),
        value: locationId,
      },
      {
        label: "Country",
        value: locationCountry,
      },
      {
        label: "Size",
        unit: sizeUnit,
        value: size,
      },
      {
        label: "Animals",
        value: animalCount || "\u2014",
      },
      {
        label: "Density",
        unit: `per ${sizeUnit}`,
        value: fieldDensity ? getFormattedNumber(fieldDensity) : null,
      },
      {
        label: "Average age",
        unit: "months",
        value: avgAge ? getFormattedNumber(avgAge) : null,
      },
      {
        label: "Pasture type(s)",
        value: !!pastureTypes?.length ? pastureTypes.map((item) => item.name).join(", ") : null,
      },
      {
        label: "Organic",
        value: isOrganic ? "Yes" : "No",
      },
      {
        label: "Yard",
        value: isYard ? "Yes" : "No",
      },
      {
        label: capitaliseFirstLetter(terms.avgDlwg),
        unit: weightUnits,
        value: avgDlwg ? getFormattedNumber(avgDlwg) : null,
      },
      {
        label: "Avg. recorded weight",
        unit: weightUnits,
        value: avgRecordedWeight ? getFormattedNumber(avgRecordedWeight) : null,
      },
      {
        label: "Avg. estimated weight",
        unit: weightUnits,
        value: avgEstimatedWeight ? getFormattedNumber(avgEstimatedWeight) : null,
      },
    ];

    return (
      <>
        {description ? (
          <Flex container>
            <Flex item itemGutter>
              <Text smaller>{description}</Text>
              <Spacer baselineHeight={2} />
            </Flex>
          </Flex>
        ) : null}
        {!!summaryMetrics?.length ? (
          <Flex container>
            {summaryMetrics.map((item, index) => {
              const summarySpecies = speciesList?.find((species) => +species.id === item?.animalTypeId);

              return (
                <Flex className={styles.field_info_block__item} item itemGutter key={index}>
                  <AnimalsBreedSummary
                    breedCode={item?.breedrCode || "N/A"}
                    speciesColour={summarySpecies?.colour}
                    speciesIconUrl={summarySpecies?.image || undefined}
                    totalAnimals={item?.animalsCount || 0}
                    totalFemale={item?.femaleCount || 0}
                    totalMale={item?.maleCount || 0}
                  />
                </Flex>
              );
            })}
          </Flex>
        ) : null}
        <Flex container>
          {fieldInfo.map(
            ({ isHidden, ...item }) =>
              !isHidden && (
                <Flex
                  className={styles.field_info_block__item}
                  item
                  itemGutter
                  key={item.label}
                  xs={6}
                  s={4}
                  l={3}
                  xl={2}
                >
                  <KeyValuePair {...item} boxed />
                </Flex>
              ),
          )}
        </Flex>
        <Flex container containerDirection="row-reverse">
          <Flex item itemGutter>
            {children}
          </Flex>
        </Flex>
      </>
    );
  }

  return (
    <Flex container>
      {description ? (
        <>
          <Flex item itemGutter xs={12} l={4} xl={3}>
            <Text smaller>{description}</Text>
          </Flex>

          {!isBreakpointL ? <Spacer baselineHeight={2} /> : null}
        </>
      ) : null}

      <Flex item itemGutter xs={12} l={4} xl={3}>
        <Text smaller>
          Location: <strong>{locationName ? locationName : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          {capitaliseFirstLetter(terms.farmId)}: <strong>{locationId ? locationId : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Country: <strong>{locationCountry ? locationCountry : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Size: <strong>{size ? `${size} ${sizeUnit}` : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Animals: <strong>{animalCount ? animalCount : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Density: <strong>{+fieldDensity ? `${fieldDensity} per ${sizeUnit}` : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Average age: <strong>{avgAge ? +avgAge : "\u2014"}</strong>
        </Text>
      </Flex>

      {!isBreakpointL ? <Spacer /> : null}

      <Flex item itemGutter xs={12} l={4} xl={3}>
        <Text smaller>
          Pasture type(s):{" "}
          <strong>{!!pastureTypes?.length ? pastureTypes.map((item) => item.name).join(", ") : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Organic: <strong>{isOrganic ? "Yes" : "No"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Yard: <strong>{isYard ? "Yes" : "No"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          {capitaliseFirstLetter(terms.avgDlwg)}: <strong>{avgDlwg ? +avgDlwg : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Average recorded weight: <strong>{avgRecordedWeight ? +avgRecordedWeight : "\u2014"}</strong>
        </Text>

        <Spacer />

        <Text smaller>
          Average estimated weight: <strong>{avgEstimatedWeight ? +avgEstimatedWeight : "\u2014"}</strong>
        </Text>
      </Flex>

      {children ? (
        <>
          {!isBreakpointXL ? <Spacer baselineHeight={2} /> : null}

          <Flex
            container
            containerAlignItems="flex-end"
            containerJustifyContent="flex-end"
            item
            itemGutter
            xs={12}
            xl={description ? 3 : 6}
          >
            {children}
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};
