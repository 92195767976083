// Libraries
import _ from "lodash";
import { DateTime } from "luxon";
import { OperationVariables } from "@apollo/client";
// Utils
import { WANT_STATUSES, OFFER_STATUSES, LOT_STATUSES } from "trading/constants";
import { getFieldName } from "helpers/myLivestock";
import { getFormattedPrice } from "helpers/general";

// @ts-expect-error
export const getVariables = <T>(businessUnit, after, isActive, search): OperationVariables<T> => ({
  businessUnit,
  first: 20,
  after,
  isActive,
  search,
});

// @ts-expect-error
export const getOfferVariables = <T>(businessUnit, after, isActive, search): OperationVariables<T> => ({
  businessUnit,
  first: 20,
  after,
  isActive,
  search,
});

// @ts-expect-error
export const getWantStatus = (status): strings => {
  switch (status) {
    case WANT_STATUSES.CREATED:
      return "New Request";

    case WANT_STATUSES.MOVED_ANIMALS:
      return "Waiting for delivery to be approved";

    case WANT_STATUSES.OFFER_ACCEPTED:
      return "Offer accepted";

    case WANT_STATUSES.AWAITING_ANIMALS:
      return "Offer accepted - Commit livestock";

    case WANT_STATUSES.AWAITING_GRADING:
      return "Awaiting grading";

    case WANT_STATUSES.AWAITING_PAYMENT:
      return "Payment due";

    case WANT_STATUSES.ASSIGNED_ANIMALS:
      return "Animals assigned";

    case WANT_STATUSES.PENDING:
      return "Offer received";

    case WANT_STATUSES.FULL_FILLED:
      return "Fulfilled";

    case WANT_STATUSES.DECLINED:
      return "Declined";

    case WANT_STATUSES.CLOSED:
      return "Closed";

    case WANT_STATUSES.COMPLETED:
      return "Completed";

    case WANT_STATUSES.SOLD:
      return "Sold";

    case WANT_STATUSES.CANCELED:
      return "Passed";

    default:
      return "None";
  }
};
export const getFarmerTradesStatuses = (status): string => {
  switch (status) {
    case WANT_STATUSES.CREATED:
      return "Waiting for offers";

    case WANT_STATUSES.PENDING:
      return "Offer made - waiting for response";

    case WANT_STATUSES.DECLINED:
      return "Declined";

    case WANT_STATUSES.AWAITING_ANIMALS:

    case WANT_STATUSES.ASSIGNED_ANIMALS:
      return "Offer accepted - commit livestock";

    case WANT_STATUSES.MOVED_ANIMALS:
      return "Livestock received";

    case WANT_STATUSES.AWAITING_GRADING:
      return "Awaiting grading";

    case WANT_STATUSES.AWAITING_PAYMENT:
      return "Awaiting payment";

    case WANT_STATUSES.SOLD:
    case WANT_STATUSES.CLOSED:
    case WANT_STATUSES.COMPLETED:
      return "Complete";

    default:
      return "None";
  }
};
export const getProcessorTradesStatuses = (status): string => {
  switch (status) {
    case WANT_STATUSES.CREATED:
      return "Waiting for offers";

    case WANT_STATUSES.PENDING:
      return "Offer received - accept offer";

    case WANT_STATUSES.DECLINED:
      return "Declined";

    case WANT_STATUSES.ASSIGNED_ANIMALS:
      return "Livestock submitted";

    case WANT_STATUSES.MOVED_ANIMALS:
      return "Waiting for delivery to be approved";

    case WANT_STATUSES.AWAITING_ANIMALS:
      return "Offer accepted - waiting for delivery";

    case WANT_STATUSES.AWAITING_GRADING:
      return "Livestock being processed";

    case WANT_STATUSES.AWAITING_PAYMENT:
      return "Payment due";

    case WANT_STATUSES.SOLD:
    case WANT_STATUSES.CLOSED:
    case WANT_STATUSES.COMPLETED:
      return "Complete";

    default:
      return "None";
  }
};
export const getStatusBlockFarmerColor = (status) => {
  switch (status) {
    case WANT_STATUSES.CREATED:
      return "notification";

    case WANT_STATUSES.AWAITING_ANIMALS:
    case WANT_STATUSES.ASSIGNED_ANIMALS:
      return "action";

    case WANT_STATUSES.PENDING:
    case WANT_STATUSES.MOVED_ANIMALS:
    case WANT_STATUSES.AWAITING_GRADING:
    case WANT_STATUSES.AWAITING_PAYMENT:
      return "pending";

    default:
      return "complete";
  }
};

export const getStatusBlockProcessorColor = (status) => {
  switch (status) {
    case WANT_STATUSES.CREATED:
    case WANT_STATUSES.AWAITING_ANIMALS:
      return "pending";

    case WANT_STATUSES.PENDING:
    case WANT_STATUSES.ASSIGNED_ANIMALS:
    case WANT_STATUSES.MOVED_ANIMALS:
    case WANT_STATUSES.AWAITING_GRADING:
    case WANT_STATUSES.AWAITING_PAYMENT:
      return "action";

    default:
      return "complete";
  }
};

export const getFarmerOfferStatusTitle = (status): string => {
  switch (status) {
    case OFFER_STATUSES.ACCEPTED:
    case OFFER_STATUSES.CANCELED:
    case LOT_STATUSES.AWAITING_ANIMALS:
    case LOT_STATUSES.ASSIGNED_ANIMALS:
      return "Commit livestock";

    case LOT_STATUSES.AWAITING_GRADING:
      return "Status: Waiting for kill sheet";

    case LOT_STATUSES.AWAITING_PAYMENT:
      return "Status: Awaiting payment";

    case LOT_STATUSES.SOLD:
    case LOT_STATUSES.FULL_FILLED:
    case LOT_STATUSES.COMPLETED:
      return "Status: Trade completed";

    default:
      return "Status: Processor waiting for delivery";
  }
};
export const getFarmerSellerStatusTitle = (status: string, selected?: string): string => {
  switch (status) {
    case OFFER_STATUSES.ACCEPTED:
    case OFFER_STATUSES.CREATED:
    case OFFER_STATUSES.CANCELED:
    case LOT_STATUSES.AWAITING_ANIMALS:
      return selected ? `Waiting for offers - ${selected}` : "Waiting for offers";

    case LOT_STATUSES.ASSIGNED_ANIMALS:
      return "Offer accepted - waiting for collection";

    case LOT_STATUSES.AWAITING_PAYMENT:
      return "Livestock accepted";

    case LOT_STATUSES.SOLD:
    case LOT_STATUSES.FULL_FILLED:
    case LOT_STATUSES.COMPLETED:
      return "Sold";

    // @ts-expect-error
    case LOT_STATUSES.DECLINED:
      return "Declined";

    default:
      return "Sold";
  }
};

// const offeredNumber = _.get(props, ["offer", "offeredNumber"], 0);
// const animals = _.get(props, ["offer", "producedLot", "animals"], []);
export const getFarmerSellerStatusButtonTitle = (status): string => {
  // const isEqual = _.isEqual(
  //   selectedAnimals.sort(),
  //   animals.map(item => item.id).sort()
  // );
  // return isEqual || selectedAnimals.length !== offeredNumber || props.loading;
  switch (status) {
    case OFFER_STATUSES.ACCEPTED:

    case OFFER_STATUSES.CREATED:
    case OFFER_STATUSES.CANCELED:
    case LOT_STATUSES.AWAITING_ANIMALS:
      return "Confirm offer";

    case LOT_STATUSES.AWAITING_PAYMENT:
    case LOT_STATUSES.SOLD:
    case LOT_STATUSES.FULL_FILLED:
    case LOT_STATUSES.COMPLETED:
      return "View invoice";

    default:
      return "View invoice";
  }
};

export const showFarmerStatusButton = (status): boolean =>
  status === LOT_STATUSES.AWAITING_PAYMENT ||
  status === LOT_STATUSES.AWAITING_ANIMALS ||
  status === LOT_STATUSES.CANCELED ||
  status === LOT_STATUSES.ASSIGNED_ANIMALS;

export const isFarmerStatusButtonDisabled = (props): boolean => {
  const status = _.get(props, ["offer", "actualStatus"]);

  if (status === LOT_STATUSES.AWAITING_ANIMALS || status === LOT_STATUSES.ASSIGNED_ANIMALS) {
    const selectedAnimals = _.get(props, "selectedAnimals", []);
    return props.loading || (selectedAnimals.length === 0 && !props.selectedAll);
  }

  return false;
};

export const getSelectedAnimalsCount = (offer, selectedAnimals, isEdited, selectedAll, filteredAnimals): string => {
  const animalsSelected = selectedAll && filteredAnimals ? filteredAnimals.length : selectedAnimals.length;
  const isAwaitingOrCanceled =
    offer.actualStatus === LOT_STATUSES.AWAITING_ANIMALS || offer.actualStatus === LOT_STATUSES.CANCELED;
  return isAwaitingOrCanceled || offer.actualStatus === LOT_STATUSES.ASSIGNED_ANIMALS
    ? `- ${isEdited || isAwaitingOrCanceled ? animalsSelected : offer.producedLotInterface.animals.length}/${
        offer.offeredNumber
      } selected`
    : "";
};

export const sortAnimals = (animals, sortBy, isAsc): any[] => {
  return animals.sort((a, b) => {
    let firstVal = a[sortBy] || "";
    let secondVal = b[sortBy] || "";

    if (sortBy === "animalBreeds") {
      firstVal = a[sortBy] && a[sortBy].length > 0 ? a[sortBy][0].name : "";

      secondVal = b[sortBy] && b[sortBy].length > 0 ? b[sortBy][0].name : "";
    }

    if (sortBy === "lastWeight") {
      firstVal = a[sortBy] ? a[sortBy].value : "";

      secondVal = b[sortBy] ? b[sortBy].value : "";
    }

    if (sortBy === "group" || sortBy === "field") {
      firstVal = a[sortBy] ? a[sortBy].name : "";

      secondVal = b[sortBy] ? b[sortBy].name : "";
    }

    if (sortBy === "field") {
      const fieldA = a[sortBy];
      const fieldB = b[sortBy];
      firstVal = a[sortBy] ? getFieldName({ fieldName: fieldA?.fieldName, unitName: fieldA.location?.unitName }) : "";
      firstVal = a[sortBy] ? getFieldName({ fieldName: fieldA?.fieldName, unitName: fieldA.location?.unitName }) : "";

      secondVal = b[sortBy] ? getFieldName({ fieldName: fieldB?.fieldName, unitName: fieldB.location?.unitName }) : "";
    }

    if (firstVal > secondVal) {
      return isAsc ? 1 : -1;
    }

    if (firstVal < secondVal) {
      return isAsc ? -1 : 1;
    }

    return 0;
  });
};

export const getSellerTotalPrice = (item): number => {
  if (item.actualStatus === WANT_STATUSES.CREATED) {
    return item.want.totalAmount;
  }

  if (item.invoice) {
    return item.invoice.finalPrice;
  }

  return item.totalRevenue;
};

export const getBuyerTotalPrice = (item): number => {
  if (item.lastStatus === WANT_STATUSES.CREATED) {
    return item.totalAmount;
  }

  if (item.offer?.invoice) {
    return item.offer.invoice.finalPrice;
  }

  return item.totalAmount;
};

export const getButtonCaption = (data, isEdited): string => {
  switch (data.actualStatus) {
    case LOT_STATUSES.AWAITING_PAYMENT:
      return "View kill sheet";

    case LOT_STATUSES.ASSIGNED_ANIMALS:
      if (isEdited) {
        return "Update";
      }

      return "Edit selection";

    default:
      return "Commit livestock";
  }
};

export const getEndOfDate = (date: Date): string => {
  return DateTime.fromJSDate(date).endOf("day").toISO();
};

export const definedWeightWithKillOut = (deadWeight: number, killOut: number): string => {
  return deadWeight ? ((deadWeight * 100) / killOut).toFixed(1) : "";
};

export const computeExpiryDate = (
  expiryDate: string | Date,
): {
  label: string;
  color: string;
} => {
  // @ts-expect-error
  const expDate = DateTime.fromISO(expiryDate).startOf("day");

  if (expDate.isValid) {
    const difference = expDate.diff(DateTime.local().startOf("day"), ["days"]).days;

    if (difference < 0) {
      return {
        label: "Expired",
        color: "grey",
      };
    }

    if (difference < 1) {
      return {
        label: "Expires today",
        color: "red",
      };
    }

    if (difference < 2) {
      return {
        label: "Expires tomorrow",
        color: "red",
      };
    }

    if (difference < 8) {
      return {
        label: `Expires in ${Math.floor(difference)} days`,
        color: "yellow",
      };
    }

    if (difference < 14) {
      return {
        label: `Expires in ${Math.floor(difference)} days`,
        color: "green",
      };
    }

    if (difference >= 14) {
      return {
        label: "New",
        color: "blue",
      };
    }
  }

  return {
    label: "\u2014",
    color: "blue",
  };
};

// @ts-expect-error
export const twoDecimalPlaces = (value): boolean => {
  if (value.toString().includes("e")) return false;
  if (!Number.isInteger(value) && !value.toString().includes(".")) return false;

  if (!Number.isInteger(value) || !value.toString().includes(".")) {
    const numToString = value.toString();
    const getDecimals = numToString.includes(".") && numToString.split(".")[1];
    return getDecimals && getDecimals.length > 2 ? false : true;
  }
};

export const isPositiveInteger = (n): boolean => n >>> 0 === parseFloat(n);

export const formattedPrice = (price: number, currencyCodeISO: string): number | string =>
  price ? getFormattedPrice(+price, currencyCodeISO) : "\u2014";

export const formattedTotalPricePerHead = (price: number, quantity: number, currencyCodeISO: string): number | string =>
  price ? getFormattedPrice(+price * quantity, currencyCodeISO) : "\u2014";

export const formattedTotalPricePerKg = (price: number, currencyCodeISO: string): number | string =>
  price ? getFormattedPrice(+price, currencyCodeISO) : "\u2014";

export const formattedPricePerKgOnCreation = (pricePerKgOnCreation: number, currencyCodeISO: string): number | string =>
  pricePerKgOnCreation ? getFormattedPrice(pricePerKgOnCreation, currencyCodeISO) : "\u2014";
