import { getBUFromLocalStorage } from "helpers/storage";
import { useMemo } from "react";
import { useGetCurrentBusinessUnit } from "hooks";
import {
  FinancialTransactionsQuery,
  FinancialTransactionState,
  useFinancialTransactionsLazyQuery,
} from "generated/graphql";
import { removeNothings, getFormattedNumber, getFormattedPrice } from "helpers/general";
import { Table } from "twComponents/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate, getInternationalDateFormat } from "feShared/utils/dates";
import { FINANCIAL_TRANSACTIONS, TRANSACTIONS } from "constants/Routes";
import { useHistory } from "react-router-dom";
import { EmptyState } from "components/EmptyStates/Default";
import { useLazyPaginatedQuery } from "hooks/useLazyPaginatedQuery";

type FinancialTransaction = NonNullable<
  NonNullable<NonNullable<FinancialTransactionsQuery["financialTransactions"]>["edges"]>[number]
>["node"];

export const FinancialTransactionsTable = () => {
  const { countryCode, currencyCodeISO, weightUnits } = useGetCurrentBusinessUnit();
  const { shortDateFormat } = getInternationalDateFormat(countryCode);
  const businessUnitId = getBUFromLocalStorage();
  const history = useHistory();

  const [getFinancialTransactions, { loading, data }] = useFinancialTransactionsLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const onRowClick = (item: FinancialTransaction) => {
    history.push(`${FINANCIAL_TRANSACTIONS}${TRANSACTIONS}/${item?.id}`, { prevUrl: location.pathname });
  };

  const { accessor } = createColumnHelper<FinancialTransaction>();

  const { paginationProps } = useLazyPaginatedQuery({
    initialQueryVariables: { businessUnit: businessUnitId.toString() },
    lazyQuery: getFinancialTransactions,
    tableId: "financial_transactions_table",
    totalNumberOfItems: data?.financialTransactions?.count || 0,
    pageInfo: data?.financialTransactions?.pageInfo,
    loading,
  });

  const columns = useMemo(
    () => [
      accessor("label", {
        header: "Name",
        cell: (info) => info.getValue(),
      }),
      accessor("date", {
        cell: ({ row }) => (
          <span>{row.original?.date ? `${formatDate(row.original.date, shortDateFormat)}` : "—"}</span>
        ),
      }),
      accessor("notes", {
        cell: (info) => info.getValue(),
      }),
      accessor("animalsCount", {
        header: "No. of livestock",
        cell: (info) => info.getValue(),
      }),
      accessor("amount", {
        header: "Total price",
        cell: ({ row }) => (
          <span>{row.original?.amount ? `${getFormattedPrice(row.original?.amount, currencyCodeISO)}` : "—"}</span>
        ),
      }),
      accessor("grossWeight", {
        header: "Gross weight",
        cell: ({ row }) =>
          row.original?.grossWeight ? `${getFormattedNumber(row.original?.grossWeight)} ${weightUnits}` : "—",
      }),
      accessor("recordType", {
        header: "type",
        cell: ({ row }) => (
          <span className="bg-gray-600 px-4 rounded-full text-white font-semibold flex justify-center align-middle w-24 my-1">
            {row.original?.recordType}
          </span>
        ),
      }),
      accessor("state", {
        header: "status",
        cell: ({ row }) => {
          return (
            <span
              className={`${
                row.original?.state === FinancialTransactionState.Submitted ? "bg-blue-400" : "bg-gray-600"
              } px-4 rounded-full text-white font-semibold flex justify-center align-middle w-24 my-1`}
            >
              {row.original?.state}
            </span>
          );
        },
      }),
    ],
    [accessor, currencyCodeISO, shortDateFormat, weightUnits],
  );

  const financialTransactions = removeNothings(
    data?.financialTransactions?.edges.map((transaction) => transaction?.node) || [],
  );

  return (
    <Table
      paginationProps={paginationProps}
      tableId="financial_transactions_table"
      options={{ data: financialTransactions, columns, enableSorting: false }}
      loading={loading}
      onRowClick={onRowClick}
      emptyStateComponent={
        <EmptyState
          title="You haven't created any financial transactions"
          description="Get started by clicking 'Add Transaction' above."
        />
      }
    />
  );
};
