// @ts-nocheck
import sift from "sift";
import moment from "moment";

export const animalFilters = (animals: Animal[], filterOptions: FilterOptions): Animal[] => {
  return animals.filter(
    sift({
      $and: [
        {
          ...(filterOptions.animalTypeId
            ? {
                animalType: {
                  id: {
                    $where: (scope) => {
                      return filterOptions.animalTypeId === scope;
                    },
                  },
                },
              }
            : {}),
        },
        {
          ...(typeof filterOptions.isSynced !== "undefined"
            ? {
                isRegulatorySynced: {
                  $eq: filterOptions.isSynced,
                },
              }
            : {}),
        },
        {
          ...(filterOptions.sex
            ? {
                isMale: { $eq: filterOptions.sex === "M" },
              }
            : {}),
        },
        {
          ...(filterOptions.sexClassifications && filterOptions.sexClassifications.length > 0
            ? {
                sexClassification: {
                  slug: {
                    $where: (scope) => {
                      return filterOptions.sexClassifications.indexOf(scope) > -1;
                    },
                  },
                },
              }
            : {}),
        },
        {
          ...(filterOptions.isRegulatorySynced
            ? {
                isRegulatorySynced: {
                  $eq: filterOptions.isRegulatorySynced === "synced",
                },
              }
            : {}),
        },
        {
          ...(filterOptions.isWithdrawal
            ? {
                withdrawalEnd: {
                  $where: (scope) => {
                    return (
                      filterOptions.isWithdrawal?.length === 0 ||
                      (scope && filterOptions.isWithdrawal === "drawal") ||
                      (!scope && filterOptions.isWithdrawal === "notDrawal")
                    );
                  },
                },
              }
            : {}),
        },
        {
          ...(typeof filterOptions.isMale !== "undefined"
            ? {
                isMale: { $eq: filterOptions.isMale },
              }
            : {}),
        },
        {
          ...(filterOptions.groups && filterOptions.groups.length > 0
            ? {
                group: {
                  id: {
                    $where: (scope) => {
                      return filterOptions.groups.indexOf(scope) > -1;
                    },
                  },
                },
              }
            : {}),
        },
        {
          ...(filterOptions.fields && filterOptions.fields.length > 0
            ? {
                field: {
                  id: {
                    $where: (scope) => {
                      return filterOptions.fields.indexOf(scope) > -1;
                    },
                  },
                },
              }
            : {}),
        },
        {
          ...(filterOptions.breeds && filterOptions.breeds.length > 0
            ? {
                animalBreeds: {
                  $where: (breed) => {
                    const result = breed.filter(sift({ id: { $in: filterOptions.breeds } }));
                    return result.length > 0;
                  },
                },
              }
            : {}),
        },
        {
          ...(filterOptions.tags && filterOptions.tags.length > 0
            ? {
                tags: {
                  $where: (tag) => {
                    const result = tag.filter(sift({ tagId: { $in: filterOptions.tags } }));
                    return result.length > 0;
                  },
                },
              }
            : {}),
        },
        {
          lastWeight: {
            $where: function () {
              const minWeight = parseFloat(filterOptions.minWeight);
              if (!Number.isNaN(minWeight)) {
                return this && this.value >= minWeight;
              }
              return true;
            },
          },
        },
        {
          lastWeight: {
            $where: function () {
              const maxWeight = parseFloat(filterOptions.maxWeight);
              if (!Number.isNaN(maxWeight)) {
                return this && this.value <= maxWeight;
              }
              return true;
            },
          },
        },
        {
          ...(filterOptions.search
            ? {
                $or: [
                  {
                    eId: {
                      $where: (scope) => {
                        return scope?.toLowerCase().search(filterOptions.search?.toLowerCase()) > -1;
                      },
                    },
                  },
                  {
                    visualId: {
                      $where: (scope) => {
                        return scope?.toLowerCase().search(filterOptions.search?.toLowerCase()) > -1;
                      },
                    },
                  },
                  {
                    passportNumber: {
                      $where: (scope) => {
                        return scope?.toLowerCase().search(filterOptions.search?.toLowerCase()) > -1;
                      },
                    },
                  },
                ],
              }
            : {}),
        },
        {
          deliveryDate: {
            $where: function () {
              const dateTo = filterOptions.deliveryDateTo && moment(filterOptions.deliveryDateTo);
              const dateValue = this && moment(this);
              if (dateTo) {
                return dateValue && dateValue.isSameOrBefore(dateTo, "day");
              }
              return true;
            },
          },
        },
        {
          deliveryDate: {
            $where: function () {
              const dateFrom = filterOptions.deliveryDateFrom && moment(filterOptions.deliveryDateFrom);
              const dateValue = this && moment(this);
              if (dateFrom) {
                return dateValue && dateValue.isSameOrAfter(dateFrom, "day");
              }
              return true;
            },
          },
        },
        {
          dob: {
            $where: function () {
              const maxAge = parseFloat(filterOptions.maxAge);
              if (!Number.isNaN(maxAge)) {
                const ageMonthBirth = moment().diff(moment(this), "month", true);
                const roundedValue = parseFloat(ageMonthBirth.toFixed(1));
                return this && roundedValue <= maxAge;
              }
              return true;
            },
          },
        },
        {
          dob: {
            $where: function () {
              const minAge = parseFloat(filterOptions.minAge);
              if (!Number.isNaN(minAge)) {
                const ageMonthBirth = moment().diff(moment(this), "month", true);
                const roundedValue = parseFloat(ageMonthBirth.toFixed(1));
                return this && roundedValue >= minAge;
              }
              return true;
            },
          },
        },
        {
          dob: {
            $where: function () {
              const dateFrom = filterOptions.dobDateFrom && moment(filterOptions.dobDateFrom);
              const dateValue = this && moment(this);
              if (dateFrom) {
                return dateValue && dateValue.isSameOrAfter(dateFrom, "day");
              }
              return true;
            },
          },
        },
        {
          dob: {
            $where: function () {
              const dateTo = filterOptions.dobDateTo && moment(filterOptions.dobDateTo);
              const dateValue = this && moment(this);
              if (dateTo) {
                return dateValue && dateValue.isSameOrBefore(dateTo, "day");
              }
              return true;
            },
          },
        },
        {
          estimatedWeightOn: {
            $where: function () {
              const minDeliveryWeight = parseFloat(filterOptions.minDeliveryWeight);
              if (!Number.isNaN(minDeliveryWeight)) {
                return this >= minDeliveryWeight;
              }
              return true;
            },
          },
        },
        {
          estimatedWeightOn: {
            $where: function () {
              const maxDeliveryWeight = parseFloat(filterOptions.maxDeliveryWeight);
              if (!Number.isNaN(maxDeliveryWeight)) {
                return this <= maxDeliveryWeight;
              }
              return true;
            },
          },
        },
        {
          estimatedAgeOn: {
            $where: function () {
              const minDeliveryAge = parseFloat(filterOptions.minDeliveryAge);
              if (!Number.isNaN(minDeliveryAge)) {
                return this >= minDeliveryAge;
              }
              return true;
            },
          },
        },
        {
          estimatedAgeOn: {
            $where: function () {
              const maxDeliveryAge = parseFloat(filterOptions.maxDeliveryAge);
              if (!Number.isNaN(maxDeliveryAge)) {
                return this <= maxDeliveryAge;
              }
              return true;
            },
          },
        },
        {
          currentWeight: {
            $where: function () {
              const minWeight = parseFloat(filterOptions.minCurrentEstWeight || "");
              if (!Number.isNaN(minWeight)) {
                return this && this >= minWeight;
              }
              return true;
            },
          },
        },
        {
          currentWeight: {
            $where: function () {
              const maxWeight = parseFloat(filterOptions.maxCurrentEstWeight || "");
              if (!Number.isNaN(maxWeight)) {
                return this && this <= maxWeight;
              }
              return true;
            },
          },
        },
        {
          lastWeight: {
            $where: function () {
              const daysFromFilter = parseInt(filterOptions.daysSinceLastWeightFrom || "");
              if (!Number.isNaN(daysFromFilter)) {
                return this && moment().diff(moment(this.date), "days") >= daysFromFilter;
              }
              return true;
            },
          },
        },
        {
          lastWeight: {
            $where: function () {
              const daysToFilter = parseInt(filterOptions.daysSinceLastWeightTo || "");
              if (!Number.isNaN(daysToFilter)) {
                return this && moment().diff(moment(this.date), "days") <= daysToFilter;
              }
              return true;
            },
          },
        },
        {
          growthRate: {
            $where: function () {
              const minGrowthRate = parseFloat(filterOptions.growthRateFrom || "");
              if (!Number.isNaN(minGrowthRate)) {
                return this >= minGrowthRate;
              }
              return true;
            },
          },
        },
        {
          growthRate: {
            $where: function () {
              const maxGrowthRate = parseFloat(filterOptions.growthRateTo || "");
              if (!Number.isNaN(maxGrowthRate)) {
                return this <= maxGrowthRate;
              }
              return true;
            },
          },
        },
        {
          lastRegulatorySyncedDate: {
            $where: function () {
              const dateTo = filterOptions.lastSyncedDateTo && moment(filterOptions.lastSyncedDateTo);
              const dateValue = this && moment(this);
              if (dateTo) {
                return dateValue && dateValue.isSameOrBefore(dateTo, "day");
              }
              return true;
            },
          },
        },
        {
          lastRegulatorySyncedDate: {
            $where: function () {
              const dateFrom = filterOptions.lastSyncedDateFrom && moment(filterOptions.lastSyncedDateFrom);
              const dateValue = this && moment(this);
              if (dateFrom) {
                return dateValue && dateValue.isSameOrAfter(dateFrom, "day");
              }
              return true;
            },
          },
        },
        {
          killWeight: {
            $where: function () {
              const minKillWeight = parseFloat(filterOptions.minKillWeight || "");
              if (!Number.isNaN(minKillWeight)) {
                return this && this >= minKillWeight;
              }
              return true;
            },
          },
        },
        {
          killWeight: {
            $where: function () {
              const maxKillWeight = parseFloat(filterOptions.maxKillWeight || "");
              if (!Number.isNaN(maxKillWeight)) {
                return this && this <= maxKillWeight;
              }
              return true;
            },
          },
        },
        {
          killQuality: {
            $where: function () {
              const killQualitySearch: string = filterOptions?.killQuality?.toLowerCase() || "";
              const killQualityValue: string = this?.toLowerCase();

              if (killQualitySearch !== "") {
                return killQualityValue.includes(killQualitySearch);
              }
              return true;
            },
          },
        },
        {
          killFatScore: {
            $where: function () {
              const killFatScoreSearch: string = filterOptions?.killFatScore?.toLowerCase() || "";
              const killFatScoreValue: string = this?.toLowerCase();

              if (killFatScoreSearch !== "") {
                return killFatScoreValue.includes(killFatScoreSearch);
              }
              return true;
            },
          },
        },
        {
          dateLeftFarm: {
            $where: function () {
              const dateFrom = filterOptions.minDateLeftFarm && moment(filterOptions.minDateLeftFarm);
              const dateValue = this && moment(this);
              if (dateFrom) {
                return dateValue && dateValue.isSameOrAfter(dateFrom, "day");
              }
              return true;
            },
          },
        },
        {
          dateLeftFarm: {
            $where: function () {
              const dateTo = filterOptions.maxDateLeftFarm && moment(filterOptions.maxDateLeftFarm);
              const dateValue = this && moment(this);
              if (dateTo) {
                return dateValue && dateValue.isSameOrBefore(dateTo, "day");
              }
              return true;
            },
          },
        },
        {
          deadAt: {
            $where: function () {
              const dateFrom = filterOptions.minDeadAtDate && moment(filterOptions.minDeadAtDate);
              const dateValue = this && moment(this);
              if (dateFrom) {
                return dateValue && dateValue.isSameOrAfter(dateFrom, "day");
              }
              return true;
            },
          },
        },
        {
          deadAt: {
            $where: function () {
              const dateTo = filterOptions.maxDeadAtDate && moment(filterOptions.maxDeadAtDate);
              const dateValue = this && moment(this);
              if (dateTo) {
                return dateValue && dateValue.isSameOrBefore(dateTo, "day");
              }
              return true;
            },
          },
        },
        {
          dateMovedToFarm: {
            $where: function () {
              const dateFrom = filterOptions.dateMovedToFarmFrom && moment(filterOptions.dateMovedToFarmFrom);
              const dateValue = this && moment(this);
              if (dateFrom) {
                return dateValue && dateValue.isSameOrAfter(dateFrom, "day");
              }
              return true;
            },
          },
        },
        {
          dateMovedToFarm: {
            $where: function () {
              const dateTo = filterOptions.dateMovedToFarmTo && moment(filterOptions.dateMovedToFarmTo);
              const dateValue = this && moment(this);
              if (dateTo) {
                return dateValue && dateValue.isSameOrBefore(dateTo, "day");
              }
              return true;
            },
          },
        },
        {
          ...(filterOptions.pregnancyStatus
            ? {
                isPregnant: {
                  $where: (scope) => {
                    if (filterOptions.pregnancyStatus === "pregnant" && scope) return true;
                    if (filterOptions.pregnancyStatus === "notPregnant" && !scope) return true;

                    return false;
                  },
                },
                isMale: false,
              }
            : {}),
        },
        {
          pregnancyDueDate: {
            $where: function () {
              const dateTo = filterOptions.pregnancyDueDateTo && moment(filterOptions.pregnancyDueDateTo);
              const dateValue = this && moment(this);
              if (dateTo) {
                return dateValue && dateValue.isSameOrBefore(dateTo, "day");
              }
              return true;
            },
          },
        },
        {
          pregnancyDueDate: {
            $where: function () {
              const dateFrom = filterOptions.pregnancyDueDateFrom && moment(filterOptions.pregnancyDueDateFrom);
              const dateValue = this && moment(this);
              if (dateFrom) {
                return dateValue && dateValue.isSameOrAfter(dateFrom, "day");
              }
              return true;
            },
          },
        },
      ],
    }),
  );
};

type Animal = {
  animalType: {
    id: string;
  };
  passportNumber: string;
  eId?: string;
  visualId?: string;
  isRegulatorySynced: boolean;
  isWithdrawal?: boolean;
  isMale: boolean;
  isPregnant?: boolean;
  pregnancyDueDate?: string | null;
  group: {
    id: string;
  };
  field: {
    id: string;
  };
  animalBreeds: {
    id: string;
  }[];
  tags: {
    tagId: string;
  }[];
  lastWeight: {
    value: string;
    date: string;
  } | null;
  currentWeight: string | null;
  deliveryDate: string | null;
  dob: string | null;
  estimatedWeightOn: string | null;
  estimatedAgeOn: string | null;
  growthRate: number | null;
  lastRegulatorySyncedDate: string | null;
  killWeight: number | null;
  killQuality: string | null;
  dateLeftFarm: string | null;
  deadAt: string | null;
  killFatScore: string | null;
  dateMovedToFarm: string | null;
  sexClassification: {
    slug: string | null;
  };
};

type FilterOptions = {
  animalTypeId?: string;
  isSynced: boolean;
  sex: "" | "M" | "F";
  sexClassifications: string[];
  tags: string[];
  groups: string[];
  fields: string[];
  breeds: string[];
  minWeight: string;
  maxWeight: string;
  search?: string;
  deliveryDateTo: string;
  deliveryDateFrom: string;
  minAge: string;
  maxAge: string;
  isMale: boolean;
  isRegulatorySynced?: string;
  isWithdrawal?: string;
  minDeliveryWeight: string;
  maxDeliveryWeight: string;
  maxDeliveryAge: string;
  minDeliveryAge: string;
  minCurrentEstWeight?: string;
  maxCurrentEstWeight?: string;
  daysSinceLastWeightFrom?: string;
  daysSinceLastWeightTo?: string;
  growthRateFrom?: string;
  growthRateTo?: string;
  lastSyncedDateFrom?: string;
  lastSyncedDateTo?: string;
  minKillWeight?: string;
  maxKillWeight?: string;
  dobDateFrom?: string;
  dobDateTo?: string;
  killQuality?: string;
  killFatScore?: string;
  minDateLeftFarm?: string;
  maxDateLeftFarm?: string;
  minDeadAtDate?: string;
  maxDeadAtDate?: string;
  dateMovedToFarmFrom?: string;
  dateMovedToFarmTo?: string;
  pregnancyStatus?: string;
  pregnancyDueDateFrom?: string;
  pregnancyDueDateTo?: string;
};
