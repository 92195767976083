import React, { useContext, useEffect } from "react";
import { CommonContext } from "config/commonProvider";
import {
  ContactFragmentFragment,
  MarkAnimalsAsDeadInput,
  useGetAnimalsQuery,
  useMarkAnimalsAsDeadMutation,
} from "generated/graphql";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import cn from "classnames";
import inputStyles from "components/Common/Field/Input/Input.module.scss";
import { Button, Field, FieldDate, FieldSelect, Flex, Input, LoadingOverlay, Spacer } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import { getBUFromLocalStorage } from "helpers/storage";
import { AddressBookSelect } from "components/Settings/AddressBook/AddressBookSelect";
import { CountriesCodes } from "hooks/useGetCurrentBusinessUnit";
import { DEATH_OPTIONS } from "feShared/deathOptions";
import CurrencyInput from "react-currency-input-field";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { ModalContent } from "animals/MarkAsDeadModal";
import styles from "./MarkAsDeadForm.module.scss";
import { DateTime } from "luxon";
import { getErrorMessage, removeNothings } from "helpers/general/all";
import { useHasFeature } from "hooks";

type AnimalId = string;

interface FormValues {
  hasAnimalsOnActiveListing: boolean;
  hasDeadAnimals: boolean;
  dobOfYoungestAnimal: string | undefined;
  countryCode: string;
  date: Date;
  animalIds: Array<AnimalId>;
  causeOfDeath: string | undefined;
  destinationName: string | undefined;
  destinationAddress: string | undefined;
  destinationPostcode: string | undefined;
  costOfDisposal: string | undefined;
}

interface Props {
  animalIds: Array<AnimalId>;
  handleCloseModal: () => void;
  changeModalContent: (modalContent: ModalContent) => void;
  onCancel: () => void;
}

export const MarkAsDeadForm = ({ animalIds, handleCloseModal, changeModalContent, onCancel }: Props) => {
  const currentBusinessUnitId: number = getBUFromLocalStorage();
  const { countryCode } = useGetCurrentBusinessUnit();
  const [markAsDead, { loading }] = useMarkAnimalsAsDeadMutation();
  const { showNotification } = useContext(CommonContext);
  const useServerPaginatedAnimalsList = useHasFeature("SERVER_SIDE_PAGINATION_FOR_ANIMALS_LIST");

  const { data: animalsData, loading: loadingAnimalsData } = useGetAnimalsQuery({
    variables: { businessUnitId: currentBusinessUnitId, animalIds },
  });

  const animals = React.useMemo(() => removeNothings(animalsData?.animals?.edges || []), [animalsData?.animals?.edges]);
  const hasDeadAnimals = animals.some((animal) => Boolean(animal.node?.isDead));
  const hasAnimalsOnActiveListing = animals.some((animal) => animal.node?.lot?.id && !animal.node.lot.isExpired);

  const animalsYoungestToOldest = React.useMemo(
    () =>
      animals.sort((a, b) => {
        const firstDob = new Date(a?.node?.dob);
        const secondDob = new Date(b?.node?.dob);

        return secondDob.getTime() - firstDob.getTime();
      }),
    [animals],
  );

  const mostRecentDob = animalsYoungestToOldest[0]?.node?.dob;

  const defaultValues: FormValues = {
    hasAnimalsOnActiveListing: true,
    hasDeadAnimals: true,
    dobOfYoungestAnimal: mostRecentDob,
    countryCode,
    animalIds,
    date: new Date(),
    causeOfDeath: undefined,
    destinationName: undefined,
    destinationAddress: undefined,
    destinationPostcode: undefined,
    costOfDisposal: undefined,
  };

  const schema = yup.object({
    hasAnimalsOnActiveListing: yup
      .boolean()
      .oneOf(
        [false],
        "One or more of the animals are listed for sale. Cannot mark animals as dead which are on an active listing.",
      ),
    hasDeadAnimals: yup.boolean().oneOf([false], "One or more of your animals are already dead."),
    dobOfYoungestAnimal: yup.date().nullable(),
    countryCode: yup.string(),
    date: yup
      .date()
      .required()
      .max(DateTime.local().plus({ days: 1 }).toJSDate(), "Death date cannot be in the future")
      .when("dobOfYoungestAnimal", {
        is: (dobOfYoungestAnimal: string | undefined) => dobOfYoungestAnimal !== undefined,
        then: (schema) => {
          if (mostRecentDob) {
            return schema.min(mostRecentDob, "Death date cannot be before the animal was born.");
          }
          return schema;
        },
      }),
    causeOfDeath: yup.string().required("Cause of death is required."),
    destinationName: yup.string().when("countryCode", {
      is: CountriesCodes.GB,
      then: yup.string().required("Disposal destination is required."),
    }),
    destinationAddress: yup.string(),
    destinationPostcode: yup.string(),
    costOfDisposal: yup
      .number()
      .positive("Cost of disposal must be a positive number")
      .typeError("Cost of disposal must be a number")
      .nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    setValue,
    trigger,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("hasDeadAnimals", hasDeadAnimals);
    setValue("hasAnimalsOnActiveListing", hasAnimalsOnActiveListing);
  }, [hasAnimalsOnActiveListing, hasDeadAnimals, setValue]);

  const onSubmit = async (formValues: FormValues) => {
    try {
      const input: MarkAnimalsAsDeadInput = {
        date: formValues.date,
        deathReason: formValues.causeOfDeath,
        animals: formValues.animalIds,
        businessUnit: currentBusinessUnitId,
        destinationName: formValues.destinationName || undefined,
        destinationAddress: formValues.destinationAddress || undefined,
        destinationPostcode: formValues.destinationPostcode || undefined,
        disposalCost: formValues.costOfDisposal ? +formValues.costOfDisposal : undefined,
      };

      const result = await markAsDead({
        variables: { input },
        refetchQueries: useServerPaginatedAnimalsList ? ["GetAnimalsPage"] : ["GetAnimalsList"],
      });

      const errors = result.data?.markAnimalsAsDead?.errors;

      if (errors) {
        showNotification({
          variant: "error",
          message: errors.map((item) => item?.message).join("; "),
        });
      } else {
        handleCloseModal();
        showNotification({
          message: "Successfully marked animal as dead",
        });
      }
    } catch (e: unknown) {
      showNotification({
        variant: "error",
        message: getErrorMessage(e),
      });
    }
  };

  const numberOfAnimals = animalsData?.animals?.count || 0;

  // TO DO: refactor this to use primary ID
  const passportIds =
    numberOfAnimals > 1
      ? `Marking ${numberOfAnimals} animals as dead.`
      : `Animal ID: ${animalsData?.animals?.edges[0]?.node?.passportNumber || "-"}`;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loadingAnimalsData ? <LoadingOverlay /> : null}
      <p className={styles.animal_id}>{passportIds}</p>

      <p className={cn(styles.animal_id, styles.error)}>
        {errors.hasDeadAnimals
          ? errors.hasDeadAnimals.message
          : errors.hasAnimalsOnActiveListing
          ? errors.hasAnimalsOnActiveListing.message
          : ""}
      </p>

      <Controller
        control={control}
        name="date"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <FieldDate
              label="Date"
              error={error?.message}
              inputProps={{
                name: "date",
                value,
                onChange,
                minDate: new Date(mostRecentDob),
                maxDate: new Date(),
              }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="causeOfDeath"
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <FieldSelect
                label="Cause of Death"
                labelFor="causeOfDeath"
                inputProps={{
                  id: "causeOfDeath",
                  name: "causeOfDeath",
                  isClearable: true,
                  placeholder: "Cause of Death",
                  value: value || "",
                  onChange,
                  options: DEATH_OPTIONS,
                }}
                error={errors.causeOfDeath?.message}
              />
            </>
          );
        }}
      />

      <Controller
        control={control}
        name="destinationName"
        render={({ field: { value }, formState: { errors } }) => {
          return (
            <AddressBookSelect
              value={value || ""}
              errorMessage={errors.destinationName?.message}
              onCreateNewContact={() => {
                setValue("destinationName", "");
                changeModalContent("createNewContactForm");
              }}
              onContactChange={(contact: ContactFragmentFragment | undefined) => {
                setValue("destinationName", contact?.name || "");
                setValue("destinationAddress", contact?.addressLine1 || "");
                setValue("destinationPostcode", contact?.postcode || "");
                trigger("destinationName");
              }}
              label="Disposal Destination"
            />
          );
        }}
      />

      <Controller
        control={control}
        name="costOfDisposal"
        render={({ field: { value }, formState }) => {
          return (
            <>
              <Field
                error={formState.errors.costOfDisposal?.message}
                label="Cost of Disposal"
                labelFor="costOfDisposal"
              >
                <Input hasError={Boolean(formState.errors.costOfDisposal?.message)}>
                  <CurrencyInput
                    allowNegativeValue={false}
                    className={cn(inputStyles.input, inputStyles.input__native, inputStyles["input__native--text"])}
                    id="costOfDisposal"
                    name="costOfDisposal"
                    value={value}
                    onValueChange={(value) => setValue("costOfDisposal", value)}
                    min={0}
                    placeholder="Cost of Disposal"
                    maxLength={8}
                  />
                </Input>
              </Field>
            </>
          );
        }}
      />

      <Spacer baselineHeight={2} />

      <Flex container containerJustifyContent="flex-end">
        <Flex item itemGutter>
          <Button caption="Cancel" type="button" colour="grey" onClick={onCancel} disabled={loading || isSubmitting} />
        </Flex>
        <Button
          caption="Confirm"
          type="submit"
          disabled={loading || isSubmitting}
          requesting={loading || isSubmitting}
        />
      </Flex>
    </form>
  );
};
