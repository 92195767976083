import { FC } from "react";
// Libraries
import { useHistory } from "react-router-dom";
// Utils
import { ANIMALS } from "constants/Routes";
import { useGetAnimalFilterAlerts, useStateSpecies } from "hooks";
// Components
import { Alert, Flex } from "components";

export const FilterAlerts: FC = () => {
  const { speciesList, handleSetActiveSpecies } = useStateSpecies();

  const history = useHistory();

  const { data: filterTemplates } = useGetAnimalFilterAlerts();

  return (
    <Flex container>
      {filterTemplates.map((item) => {
        if (item.animalFilter) {
          const animalFilter = item.animalFilter;

          const buttonText = item.count === 1 ? "View this animal" : `View these ${item.count} animals`;

          const handleClickButtonText = (): void => {
            if (animalFilter.animalType?.id) {
              const species = speciesList?.find((species) => species.id === animalFilter.animalType?.id);
              if (species) handleSetActiveSpecies(species);
            }

            history.push(ANIMALS, {
              animalsFilterTemplateId: animalFilter.id,
            });
          };

          return (
            <Flex item itemGutter xs={12} key={item.animalFilter.id}>
              <Alert
                buttonText={buttonText}
                color={item.animalFilter.color}
                categoryPillText={item.animalFilter.animalType?.name}
                onButtonTextClick={handleClickButtonText}
                text={`Alert: ${item.animalFilter.name} -`}
              />
            </Flex>
          );
        }

        return null;
      })}
    </Flex>
  );
};
