import { gql } from "@apollo/client";
import { BU_FULL_FIELDS } from "feShared/businessUnit/fragments";

export const organisationFragment = gql`
  fragment Organisation on Organisation {
    id
    name
  }
`;

export const GET_ORGANISATIONS_LIST = gql`
  query GetOrganizations {
    organisations {
      organisation {
        ...Organisation
      }
      businessUnits {
        ...businessUnitFull
      }
    }
  }
  ${BU_FULL_FIELDS}
  ${organisationFragment}
`;

export const GET_ORGANISATIONS_BUSINESS_UNITS = gql`
  query GetOrganisationBusinessUnits($businessUnitId: Int!) {
    organisationBusinessUnits(businessUnit: $businessUnitId) {
      organisation {
        ...Organisation
      }
      ...businessUnitFull
    }
  }
  ${BU_FULL_FIELDS}
  ${organisationFragment}
`;
