import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { UPDATE_USER_DATA } from "api/User/mutations";
import { UpdateUserData, UpdateUserDataVariables } from "api/User/types/UpdateUserData";
import { GetBusinessUnitUsers_businessUnitUsers } from "api/BusinessUnits/types/GetBusinessUnitUsers";
import { CommonContext } from "config/commonProvider";
import { formatPhoneNumber, getBusinessUnitUser } from "helpers/general";
import { useGetCurrentUserInfo, useGetBusinessUnitUsers, useBreakpoints, useGetCurrentBusinessUnit } from "hooks";
import "utils/yup";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { Button, Flex, FieldText, Spacer, Text, TextButton } from "components";
import styles from "./styles.module.scss";

interface Props {
  onAssignOwnerToAnotherUser: () => void;
}

type FormValues = {
  firstName: string;
  lastName: string;
  accessTo: string;
  userType: string;
  phone: string;
  jobTitle: string;
  userImageUrl: string;
  userImageFile: File | null;
};
type FormProps = FormikProps<FormValues>;
type FormField = keyof Omit<FormValues, "userImageFile">;
type GetFieldProps = {
  error: string | undefined;
  inputProps: {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
  };
};

const ModifyProfileForm: React.FC<Props> = ({ onAssignOwnerToAnotherUser }) => {
  const { showNotification } = useContext(CommonContext);
  const [profileUpdateError, changeProfileError] = useState<string>("");
  const { countryIsoCode } = useGetCurrentBusinessUnit();

  const validationSchema = Yup.object().shape({
    accessTo: Yup.string().required("Access to is required!"),
    userType: Yup.string().required("User type is required!"),
    jobTitle: Yup.string().required("Job title is required!"),
    phone: Yup.string()
      // @ts-expect-error: Phone method added in src/utils/yup.ts
      .phone({ countryCode: countryIsoCode.toUpperCase(), isRequired: true })
      .required("Phone number cannot be left blank."),
    firstName: Yup.string().required("First name is required!"),
    lastName: Yup.string().required("Last name is required!"),
  });

  const { data: businessUnitUsersData, refetch: refetchBusinessUnitUsers } = useGetBusinessUnitUsers();
  const { data: currentUserInfoData, refetch: refetchCurrentUserInfo } = useGetCurrentUserInfo();

  const [updateUserInfo, { loading }] = useMutation<UpdateUserData, UpdateUserDataVariables>(UPDATE_USER_DATA);

  const businessUnitUsers = (businessUnitUsersData?.businessUnitUsers ||
    []) as GetBusinessUnitUsers_businessUnitUsers[];
  const userInfo = currentUserInfoData?.userInfo || null;
  const businessUnitUser = getBusinessUnitUser(businessUnitUsers, userInfo);
  const isOwner = Boolean(businessUnitUser?.isOwner);
  const roleName: string = businessUnitUser?.role.name || "";

  const handleFormSubmit = async ({
    firstName,
    lastName,
    phone,
    jobTitle,
    userImageFile,
  }: FormValues): Promise<void> => {
    try {
      const { errors } = await updateUserInfo({
        variables: {
          input: {
            firstName,
            lastName,
            imageFile: userImageFile,
            phone: formatPhoneNumber({ phoneNumber: phone, countryCode: countryIsoCode.toUpperCase() }),
            jobTitle,
          },
        },
      });

      if (errors && errors.length) {
        showNotification({
          variant: "error",
          message: errors[0].message,
        });
        changeProfileError(errors[0].message);
      } else {
        refetchBusinessUnitUsers();
        refetchCurrentUserInfo();
        showNotification({
          message: "User profile was successfully updated.",
        });
        changeProfileError("");
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Something went wrong! Please try again later.",
      });
      changeProfileError("Something went wrong! Please try again later.");
    }
  };

  const { isBreakpointL } = useBreakpoints();

  const initialValues = {
    firstName: businessUnitUser?.user.firstName || "",
    lastName: businessUnitUser?.user.lastName || "",
    accessTo: businessUnitUser?.businessUnit.name || "",
    userType: isOwner ? "Owner" : roleName || "",
    phone:
      formatPhoneNumber({
        phoneNumber: businessUnitUser?.user.phone || undefined,
        countryCode: countryIsoCode.toUpperCase(),
      }) || "",
    jobTitle: businessUnitUser?.user.jobTitle || "",
    userImageUrl: businessUnitUser?.user.image || "",
    userImageFile: null,
  };

  if (!businessUnitUser) {
    return null;
  }

  return (
    <Formik
      validateOnChange
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, handleSubmit, resetForm, setFieldValue }: FormProps): JSX.Element => {
        const getFieldProps = ({
          fieldName,
          disabled,
          autoFocus,
          required,
        }: {
          fieldName: FormField;
          disabled?: boolean;
          autoFocus?: boolean;
          required?: boolean;
        }): GetFieldProps => {
          return {
            error: errors[fieldName],
            inputProps: {
              value: values[fieldName],
              name: fieldName,
              onChange: handleChange,
              disabled: disabled ?? false,
              autoFocus: autoFocus ?? false,
              required: required ?? false,
            },
          };
        };

        const handleImageChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
          const file = target.files && target.files[0];
          const reader = new FileReader();

          reader.onloadend = (): void => {
            setFieldValue("userImageUrl", reader.result);
            setFieldValue("userImageFile", file);
          };

          if (file) {
            reader.readAsDataURL(file);
          } else {
            setFieldValue("userImageUrl", "");
          }
        };

        const handleFormReset = (): void => {
          resetForm();
        };

        return (
          <form onSubmit={handleSubmit}>
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldText
                  label="First Name"
                  {...getFieldProps({
                    fieldName: "firstName",
                    autoFocus: true,
                    disabled: roleName !== "Admin",
                  })}
                />
                {!isBreakpointL ? <Spacer baselineHeight={2} /> : null}
                <FieldText
                  label="Last Name"
                  {...getFieldProps({ fieldName: "lastName", disabled: roleName !== "Admin" })}
                />
                {!isBreakpointL ? <Spacer baselineHeight={2} /> : null}
                <FieldText
                  label="Access to"
                  helper="This field is set by admin."
                  {...getFieldProps({ fieldName: "accessTo", disabled: true })}
                />
              </Flex>
              {!isBreakpointL ? <Spacer baselineHeight={3} /> : null}
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <Text>Profile picture</Text>
                <Spacer baselineHeight={1} />
                <div className={styles.viewProfileImage}>
                  <input
                    id={ELEMENTS_IDS.MODIFY_PROFILE_FORM_PROFILE_IMAGE_INPUT}
                    className={styles.imageHoverInput}
                    data-testid={ELEMENTS_IDS.MODIFY_PROFILE_FORM_PROFILE_IMAGE_INPUT}
                    type="file"
                    accept={`image/*, .pdf, .txt, .doc, .docx, .csv, .xls, .xlsx, .gif, png, .jpg, .jpeg, .xml, .mp4, .avi, .mpg, .mp3, .wav`}
                    onChange={handleImageChange}
                  />
                  {initialValues.userImageUrl || values.userImageUrl ? (
                    <img alt="profile_image" src={values.userImageUrl} className={styles.profileImage} />
                  ) : (
                    <span className={styles.nameLatter}>
                      {initialValues.firstName ? initialValues.firstName.charAt(0) : null}
                    </span>
                  )}
                </div>
              </Flex>
            </Flex>
            {!isBreakpointL ? <Spacer baselineHeight={3} /> : null}
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldText
                  label="User type"
                  helper="This field is set by admin."
                  {...getFieldProps({ fieldName: "userType", disabled: true })}
                />
              </Flex>
              {isOwner ? (
                <Flex item itemGutter>
                  {isBreakpointL ? <Spacer baselineHeight={5} /> : null}
                  <TextButton
                    id={ELEMENTS_IDS.ASSIGN_OWNER_TO_ANOTHER_USER}
                    caption="Assign owner to another user"
                    onClick={onAssignOwnerToAnotherUser}
                    type="button"
                  />
                </Flex>
              ) : null}
            </Flex>
            {!isBreakpointL ? <Spacer baselineHeight={2} /> : null}
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldText label="Phone number" {...getFieldProps({ fieldName: "phone" })} />
                {!isBreakpointL ? <Spacer baselineHeight={2} /> : null}
                <FieldText label="Job title" {...getFieldProps({ fieldName: "jobTitle" })} />
              </Flex>
            </Flex>
            {profileUpdateError ? (
              <Flex container>
                <Flex item itemGutter>
                  <Text warning>{profileUpdateError}</Text>
                </Flex>
              </Flex>
            ) : null}
            <Spacer baselineHeight={!isBreakpointL ? 2 : 1} />
            <Flex container>
              <Flex item itemGutter>
                <Spacer baselineHeight={1} />
                <Button
                  id={ELEMENTS_IDS.MODIFY_PROFILE_FORM_CANCEL_BUTTON}
                  caption="Cancel"
                  colour="grey"
                  variant="hollow"
                  onClick={handleFormReset}
                />
              </Flex>
              <Flex item itemGutter>
                <Spacer baselineHeight={1} />
                <Button
                  id={ELEMENTS_IDS.MODIFY_PROFILE_FORM_SAVE_BUTTON}
                  disabled={loading}
                  requesting={loading}
                  type="submit"
                  caption="Save changes"
                />
              </Flex>
            </Flex>
          </form>
        );
      }}
    </Formik>
  );
};

export default ModifyProfileForm;
