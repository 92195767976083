import React, { useState } from "react";
import { BatchActivities } from "medicines/components/MedicineTabsBlock/BatchActivities";
import { BatchDetails } from "medicines/components/MedicineTabsBlock/BatchDetails";
import { Modal, Spacer, Title, VerticalTabs } from "components";
import { Button } from "twComponents/Button";
import { LineItemFragment, useArchiveMedicineLineItemMutation } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { GET_STOCK_MEDICINE_ITEM } from "medicines/api/queries";

type MedicineTabsBlockProps = {
  lineItems: LineItemFragment[];
};

export const MedicineTabsBlock: React.FC<MedicineTabsBlockProps> = ({ lineItems }) => {
  const [activeBatch, setActiveBatch] = useState<string>(lineItems[0].id);
  const currentBatch = lineItems.find((item) => item.id === activeBatch);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [archiveMedicineLineItem] = useArchiveMedicineLineItemMutation();
  const activeBusinessUnitId = getBUFromLocalStorage();

  const batchTabs = lineItems.map((item) => ({
    active: item.id === activeBatch,
    caption: item.batchNumber,
    id: item.id,
    onTabChange: setActiveBatch,
  }));

  const onArchivePress = async () => {
    setShowModal(false);
    if (currentBatch) {
      await archiveMedicineLineItem({
        variables: { input: { lineItem: currentBatch.id, businessUnit: activeBusinessUnitId.toString() } },
        refetchQueries: [
          {
            query: GET_STOCK_MEDICINE_ITEM,
            variables: {
              businessUnitId: activeBusinessUnitId,
              id: currentBatch.stockItem.id,
            },
          },
        ],
      });
    }
  };

  return (
    <>
      <VerticalTabs tabs={batchTabs} tabsTitle="Select batch:">
        <div className="flex flex-row">
          <section className="flex-1 mx-2 border-r border-gray-200 border-solid">
            <Spacer allowDecimal baselineHeight={2.5} />
            <Title tertiary>Usage history</Title>
            <Spacer allowDecimal baselineHeight={2.5} />
            <BatchActivities batchNumber={currentBatch?.batchNumber} />
            <Spacer baselineHeight={3} />
          </section>

          <div className="mx-2">
            <Spacer allowDecimal baselineHeight={2.5} />
            <div className="flex flex-row justify-between items-center">
              <Title tertiary>Batch details</Title>
              {currentBatch?.isArchived ? null : (
                <Button title="Filters" className="ml-2" onClick={() => setShowModal(true)} variant="destructive">
                  <span className="px-1">Archive</span>
                </Button>
              )}
            </div>
            <Spacer allowDecimal baselineHeight={2.5} />
            <BatchDetails batch={currentBatch} />
            <Spacer baselineHeight={3} />
          </div>
        </div>
      </VerticalTabs>
      <Modal
        active={showModal}
        handleClose={() => setShowModal(false)}
        title={`Archive batch ${currentBatch?.batchNumber}`}
        showCloseButton
      >
        <div className="flex flex-col">
          <span className="mb-3">Are you sure you want to archive this medicine? This cannot be undone.</span>
          <div className="flex flex-row items-center justify-end">
            <Button title="Close" className="ml-2" onClick={() => setShowModal(false)} variant="outline">
              <span className="px-1">Close</span>
            </Button>
            <Button title="Confirm" className="ml-2" onClick={onArchivePress} variant="destructive">
              <span className="px-1">Confirm</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
