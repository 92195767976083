// React
import React from "react";
// Utils
import { UploadCsv_uploadCsv } from "api/Import/CustomImport/types/UploadCsv";
import { GetActivityTemplates_activityTemplates } from "api/MyLivestock/Activity/types/GetActivityTemplates";
// Components
import { SimpleTable, withHeader } from "components/Import/Table";
import { Select, SelectOption } from "components/Import";
import { CheckBox, LoadingOverlay, Flex, Spacer, Text } from "components";
// Resources
import styles from "./TableCustomBlock.module.scss";

const Table = withHeader(SimpleTable);

type ActivityTemplate = GetActivityTemplates_activityTemplates;
type ActivityTemplates = ActivityTemplate[];

interface TableCustomBlockProps {
  isLoading: boolean;
  isAnimalsImport: boolean;
  isFirstRowColumnNames: boolean;
  fileId: string;
  fileInfo: UploadCsv_uploadCsv;
  selectedImportType: string;
  selectedActivityType: string;
  selectedFieldsArray: string[];
  activityTemplates: ActivityTemplates;
  selectedActivity: ActivityTemplate;
  onChangeSelectedActivity: (activityTemplate: ActivityTemplate) => void;
  onChangeActivityType: (activityTemplateId: string) => void;
  onCheckIfFirstLine: (isFirstRowColumnNames: boolean) => void;
  setSelectedFields: (selectedFields: string[]) => void;
}

type TableColumns = Array<{
  label: () => JSX.Element;
  render: (data: string[]) => string;
}>;

export const TableCustomBlock: React.FC<TableCustomBlockProps> = ({
  isLoading,
  fileId,
  isAnimalsImport,
  selectedImportType,
  onChangeSelectedActivity,
  activityTemplates,
  onChangeActivityType,
  selectedActivityType,
  isFirstRowColumnNames,
  onCheckIfFirstLine,
  fileInfo,
  selectedFieldsArray,
  selectedActivity,
  setSelectedFields,
}) => {
  const fileTotalRows: number = fileInfo.totalRows || 0;
  const fileTotalColumns: number = fileInfo?.totalColumns || 0;
  const importedRowsCount: number = isFirstRowColumnNames ? fileTotalRows - 1 : fileTotalRows;

  const addedFields: Array<{ field: string; label: string }> =
    selectedActivity && !isAnimalsImport
      ? selectedActivity.fields.map((item) => ({
          field: item.id,
          label: item.name,
        }))
      : [];

  const onSelect = ({ value, name }: { value: string; name: string }): void => {
    setSelectedFields(
      selectedFieldsArray
        .map((item) => (item === value ? "__skip__" : item))
        .map((item, idx) => (idx === +name ? value : item)),
    );
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onSelect({
      value: e.target.value,
      name: e.target.name,
    });
  };

  const columns = (): TableColumns => {
    const arr: TableColumns = [];

    for (let i = 0; i < fileTotalColumns; i += 1) {
      arr.push({
        label: function columnLabel() {
          return (
            <Select name={i.toString()} onChange={handleSelectChange} value={selectedFieldsArray[i]}>
              {fileInfo.fields.map((item) => (
                <SelectOption key={item.field} value={item.field}>
                  {item.label}
                </SelectOption>
              ))}
              {addedFields.map((item) => (
                <SelectOption key={item.field} value={item.field}>
                  {item.label}
                </SelectOption>
              ))}
            </Select>
          );
        },
        render: (data: string[]) => data[i],
      });
    }

    return arr;
  };

  return isLoading || !fileId ? (
    //@ts-expect-error
    <LoadingOverlay className={styles.loadingPage} />
  ) : (
    <Flex container>
      <Flex item itemGutter xs={12}>
        {!isAnimalsImport ? (
          <div className={styles.contentWrapper}>
            <div className={styles.centerContentWrapper}>
              <Text>Select {selectedImportType} information</Text>
            </div>

            <Spacer baselineHeight={2} />

            <div className={styles.selectActivityWrapper}>
              <Select
                className={styles.activityTypeSelect}
                onChange={({ target }): void => {
                  const template = activityTemplates.find((item) => item.id === target.value);

                  if (template) {
                    onChangeSelectedActivity(template);
                  }

                  onChangeActivityType(target.value);
                }}
                value={selectedActivityType}
                label="Activity"
                name="activityTemplate"
              >
                {activityTemplates.map((item) => (
                  <SelectOption key={item.id} value={item.id}>
                    <div className={styles.valueWrapper}>
                      <span>{item.name}</span>

                      <img alt="logo" src={item.activityType?.image || ""} className={styles.typeIcon} />
                    </div>
                  </SelectOption>
                ))}
              </Select>
            </div>
          </div>
        ) : null}

        {fileId !== null ? (
          <>
            <Spacer baselineHeight={4} />

            <div className={styles.tableCheckWrapper}>
              <CheckBox
                checked={isFirstRowColumnNames}
                onChange={(): void => onCheckIfFirstLine(!isFirstRowColumnNames)}
              />

              <Text>First row is column names</Text>

              <Spacer />

              <Text>Choose columns to assign to fields to complete your import.</Text>

              <Spacer baselineHeight={2} />

              <div className={styles.tableWrapper}>
                <Table className={styles.table} columns={columns()} data={fileInfo.rows} />
              </div>
            </div>

            <Spacer baselineHeight={2} />

            <Text>Total Rows to be imported: {importedRowsCount}</Text>
          </>
        ) : null}
      </Flex>
    </Flex>
  );
};
