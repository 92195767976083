export const LOGIN = "/login";
export const SIGN_UP = "/getbreedr";
export const SIGN_UP_DETAILS = "/getbreedr-details";
export const SIGN_UP_SUCCESS = "/getbreedr-success";
export const SIGN_UP_FAILURE = "/getbreedr-failure";
export const FORGOT_PASSWORD = "/forgot";
export const INVITE = "/invite";
export const RESET_PASSWORD = "/reset-password-sent";
export const NEW_PASSWORD = "/new-password";
export const TRADING = "/system/trading";
export const TAGS = "/system/livestock/tags";
export const SSO = "/:sso";
export const BU_ID = "/:buId?";
export const ARCHIVE = "/archive";

export const SUPPLY_CHAINS = "/system/trading/supply-chains";
export const SUPPLY_CHAIN_FORM = `${SUPPLY_CHAINS}/request-information`;
export const BUYING = "/buying";
export const SELLING = "/selling";
export const TRADING_ROOT = "/system/trading";
export const TAGS_ROOT = "/system/livestock/tags";
export const MARKETPLACE = `${BUYING}/liveweight`;
export const MY_LISTINGS = `${SELLING}/my-listings`;
export const CREATE_LISTING = `${SELLING}/create-listing`;
export const EDIT_LISTING = `${SELLING}/edit-listing/:id`;
export const VIEW_LISTING_BASE = `${SELLING}/listing`;
export const VIEW_LISTING = `${VIEW_LISTING_BASE}/:id`;
export const VIEW_PUBLIC_LISTING_BASE = `${BUYING}/public-listing`;
export const VIEW_PUBLIC_LISTING = `${VIEW_PUBLIC_LISTING_BASE}/:publicId`;
export const DISTRIBUTION_LISTS = "/lists";
export const DISTRIBUTION_LIST_CREATE = "/list/create";
export const DISTRIBUTION_LIST_EDIT = "/list/edit/:id";
export const SETTINGS = "/system/settings";
export const SIRES = "/system/settings/sires";
export const DASHBOARD = "/system/dashboard";
export const MEDICINE_CABINET = "/system/medicine";
export const MEDICINE_DETAILS = "/system/medicine/details";
export const AUTH = "/auth";
export const CHAT = "/chat";

// Settings types
export const ID_PREFERENCES = "/idPreferences";
export const BUSINESS_SETTINGS = "/business";
export const MANAGEMENT_SETTINGS = "/management";
export const EMAIL_SETTINGS = "/email";
export const SECURITY_SETTINGS = "/security";
export const USER_SETTINGS = "/user";
export const ADDRESS_BOOK = "/address-book";
export const CREATE_ADDRESS = "/create";
export const SUPPLIER_MANAGEMENT = `${SETTINGS}/supplier-management`;
export const EDIT_SUPPLIER = `${SUPPLIER_MANAGEMENT}/edit`;
export const BANK_DETAILS = "/bank-details";
export const CREATE_BANK_DETAILS = `/bank-details/create`;
export const EDIT_BANK_DETAILS = `/bank-details/edit`;
export const INTEGRATIONS = "/integrations";
export const REPORTS = "/system/reports";
export const VIEW_OFFER = "/offer/:id/:groupId?";
export const LIVESTOCK = "/system/livestock";
export const ANIMALS = "/system/livestock/animals";
export const ANIMALS_ARCHIVE = "/system/livestock/archive";
export const GROUPS = "/system/livestock/groups";
export const GROUP_VIEW = "/system/livestock/group/:groupId";
export const BATCH_VIEW = "/system/livestock/batch/:groupId";
export const CREATE_GROUP = "/system/livestock/group/create";
export const EDIT_GROUP = "/system/livestock/group/edit/:groupId";
export const CREATE_BATCH = "/system/livestock/batch/create";
export const EDIT_BATCH = "/system/livestock/batch/edit/:groupId";
export const FIELDS = "/system/livestock/locations";
export const FIELD_VIEW = "/system/livestock/location";
export const CREATE_FIELD = "/system/livestock/locations/create";
export const EDIT_FIELD = "/system/livestock/locations/edit/:fieldId";
export const ANIMAL_VIEW = "/system/:fromPage/animal/:id/:tab?";
export const ANIMAL_VIEW_PERFORMANCE = "/system/:fromPage/animal/:id/performance";
export const ANIMAL_VIEW_HISTORY = "/system/:fromPage/animal/:id/history";
export const ANIMAL_VIEW_OFFSPRING = "/system/:fromPage/animal/:id/offspring";
export const ANIMAL_GENETICS = "/system/:fromPage/animal/:id/genetics";
export const ANIMAL_FAMILY_TREE = "/system/:fromPage/animal/:id/family-tree";
export const IMPORT = "/system/import";
export const REGULATORY = "/system/regulatory";
export const TAG_VIEW = "/system/livestock/tag";

// Deliveries
export const DELIVERIES = "/system/movements";
export const DELIVERY = "/:id";
export const MOVE_OFF = "/move-off";
export const MOVE_ON = "/move-on";
export const SUBMITTED = "/submitted";
export const DRAFTS = "/drafts";
export const CREATE_MOVE_OFF = "/create-move-off";
export const CREATE_MOVE_ON = "/create-move-on";

// Subscriptions
export const SUBSCRIPTION_DETAILS = "/subscription";
export const PAYMENT_SUCCESS = "/payment-success";

// IMPORT TYPES
export const BCMS_IMPORT = "/BCMS";
export const SUPPORTED_SYSTEM_IMPORT = "/supported-system";
export const CUSTOM_IMPORT = "/custom";

// Financial Transactions
export const FINANCIAL_TRANSACTIONS = "/system/financial";
export const TRANSACTIONS = "/transactions";
export const TRANSACTION_ID = "/:id?";
