import { FC, memo } from "react";
// Resources
import styles from "./styles.module.scss";

interface Props {
  text: string;
  inline?: boolean;
}

const InfoTooltip: FC<Props> = ({ text, inline }) => (
  <span className={`${styles.explain} ${inline ? styles.explainInline : ""}`}>
    <span className={styles.explain__icon}>?</span>
    <span className={styles.explain__tooltip}>{text}</span>
  </span>
);

export default memo(InfoTooltip);
