// React
import React from "react";
import { Flex } from "components";
import { AnimalHistoryFilters, AnimalsHistoryDateItem } from "components/MyLivestock";

import { ELEMENTS_IDS } from "constants/ElementsIds";
import { getAnimalActivitiesFilteredByDate } from "helpers/myLivestock";
import { GetActivityLogNew_activities } from "api/MyLivestock/Activity/types/GetActivityLogNew";

import styles from "./AnimalActivitiesBlock.module.scss";
import { useFormatDate } from "hooks";

type AnimalActivitiesBlockProps = {
  data: GetActivityLogNew_activities;
  selectedFilters: string[];
  onSelectFilter: (filter: string) => void;
  onShowAll: () => void;
};

const AnimalActivitiesBlock: React.FC<AnimalActivitiesBlockProps> = ({
  data,
  selectedFilters,
  onSelectFilter,
  onShowAll,
}) => {
  const activitiesToShow = data?.edges?.filter((item) => !item?.node?.dontShow) || [];
  const { getFormatDate } = useFormatDate();
  return (
    <Flex container>
      <Flex item itemGutter xs={12}>
        <div className={styles.titleAndFilters}>
          <h3>Animal History</h3>
          <AnimalHistoryFilters selected={selectedFilters} selectFilter={onSelectFilter} />
          <span
            id={ELEMENTS_IDS.ANIMAL_HISTORY_FILTERS_SHOW_ALL_LINK}
            onClick={onShowAll}
            className={styles.showAllLink}
          >
            show all
          </span>
        </div>
        <div className={styles.container}>
          <div className={styles.viewActivities}>
            <div className={styles.timeline}>
              {activitiesToShow.length > 0 ? (
                <AnimalsHistoryDateItem
                  items={getAnimalActivitiesFilteredByDate(activitiesToShow, true, getFormatDate) || []}
                />
              ) : null}
              {activitiesToShow.length === 0 ? (
                <div className={styles.viewNoWeightActivitiesMessage}>
                  <p className={styles.noWeightActivitiesMessageText}>No activities logged</p>
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.viewActivities} />
        </div>
      </Flex>
    </Flex>
  );
};

export default AnimalActivitiesBlock;
