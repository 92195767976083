// React
import React, { memo, useEffect } from "react";
// Components
import { InputDate } from "components";
import { Field } from "..";
// Resources
import inputStyles from "../Input/Input.module.scss";

export const InputDateRange = ({ from, to }: InputDateRangeProps): JSX.Element => {
  useEffect(() => {
    if (!from.value || !to.value) return;

    if (from.value > to.value) to.onChange(from.value);
  });

  const toProps = {
    ...to,
    minDate: from.value,
  };

  return (
    <div className={inputStyles.range}>
      <InputDate {...from} />
      <span className={inputStyles.input__spacer}>to</span>
      <InputDate {...toProps} />
    </div>
  );
};
InputDateRange.displayName = "InputDateRange";

export const FieldDateRange = memo<FieldDateRangeProps>(
  ({
    errors: { from: errorFrom, to: errorTo } = { from: false, to: false },
    inputProps,
    ...props
  }: FieldDateRangeProps): JSX.Element => {
    let message = "";
    if (errorFrom) {
      message = errorFrom;
    }
    if (errorTo) {
      message = message ? `${message} ${errorTo}` : errorTo;
    }
    return (
      <Field error={message} labelFor={inputProps.from.name} {...props}>
        <InputDateRange
          from={{
            ...inputProps.from,
            hasError: !!errorFrom,
          }}
          to={{
            ...inputProps.to,
            hasError: !!errorTo,
          }}
        />
      </Field>
    );
  },
);
FieldDateRange.displayName = "FieldDateRange";
