import { DateType, getInternationalDateFormat, transformToDate as transformToDateUtil } from "../dates";
import { useSelector } from "react-redux";
import { IBusinessUnitsState } from "feShared/businessUnit/reducer";

/*
We need to use DateTime rather than just Dates in the BE as it allows more performant sorting and filtering.
We should be transforming any dates / datetimes to Luxon DateTime objects as soon as we receive them from the server.
This will allow us to use the Luxon API to manipulate dates and format them how we want.
Try to use this hook to format dates in the UI or the dates util functions if you cant use a hook.
We want to use the BU's timezone instead of the users current timezone.
*/

type RootState = {
  businessUnits: IBusinessUnitsState;
};

export const useDates = () => {
  const countryCode = useSelector((state: RootState) => state.businessUnits.activeBusinessUnit?.country?.code) ?? "UK";
  const tzName = useSelector((state: RootState) => state.businessUnits.activeBusinessUnit?.tzName) ?? undefined;

  const { locale, shortDateFormat, longDateFormat, fullDateFormat } = getInternationalDateFormat(countryCode);

  // Takes dates in a range of formats and returns a Luxon DateTime object
  const transformToDate = (date?: DateType | null) => transformToDateUtil(date, tzName);

  // Use this function to get the ISO date string which can be passed to the server
  const toISODatetime = (date?: DateType | null) => transformToDate(date)?.toISO()?.toString() ?? "";

  const formatDate = (date?: DateType | null, format = shortDateFormat) =>
    transformToDate(date)?.toFormat(format, { locale }) ?? "";

  const formatDateToShortDate = (date?: DateType | null) =>
    transformToDate(date)?.toFormat(shortDateFormat, { locale }) ?? "";

  const formatToDateTime = (date?: DateType | null) =>
    transformToDate(date)?.toFormat(longDateFormat, { locale }) ?? "";

  const formatToFullDateTime = (date?: DateType | null) =>
    transformToDate(date)?.toFormat(fullDateFormat, { locale }) ?? "";

  return {
    formatDate,
    transformToDate,
    toISODatetime,
    shortDateFormat,
    formatDateToShortDate,
    formatToDateTime,
    formatToFullDateTime,
  };
};
