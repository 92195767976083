// Types
import { InputSelectOption } from "components/Common/Field/Select";

interface UnitTypeSelectOptions {
  litres: InputSelectOption[];
  kgs: InputSelectOption[];
}

export function normaliseUnit(unit: string): string {
  return unit.trim().toLowerCase();
}

// list of unit exceptions to convert to something else (don't ask!)
export const fluidUnits: string[] = ["l", "litres", "liters", "litre", "liter"];
export const solidUnits: string[] = ["kg", "kilos", "kilogram", "kilograms"];

// is the unit specified litres
export const isLitres = (unit: string): boolean => {
  const formattedUnit = normaliseUnit(unit);

  return fluidUnits.includes(formattedUnit);
};

// is the unit specified kg
export const isKilos = (unit: string): boolean => {
  const formattedUnit = normaliseUnit(unit);

  return solidUnits.includes(formattedUnit);
};

export const medicineUnits: UnitTypeSelectOptions = {
  litres: [
    {
      key: "ml",
      label: "ml",
      value: "ml",
    },
    {
      key: "l",
      label: "litre",
      value: "l",
    },
  ],
  kgs: [
    {
      key: "g",
      label: "grams",
      value: "g",
    },
    {
      key: "kg",
      label: "kilograms",
      value: "kg",
    },
  ],
};
