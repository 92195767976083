import { useState, FC } from "react";
import { useHistory } from "react-router";
import { CREATE_BATCH, CREATE_GROUP } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { useGetGroups, useStateSpecies, useLivestockTabs } from "hooks";
import { LoadingOverlay, Header, PageContent, PageHeader, Spacer, Tabs, Modal, Button, KeyValuePair } from "components";
import styles from "./Groups.module.scss";
import { EmptyStatesGroupsTab } from "components/EmptyStates/GroupsTab";
import { GroupsTable } from "groups/components/GroupsTable";

export const Groups: FC = () => {
  const { activeSpecies } = useStateSpecies();
  const LIVESTOCK_TABS = useLivestockTabs();
  const [showModal, setShowModal] = useState(false);
  const speciesActiveName = activeSpecies?.name || "Livestock";
  const title = `My ${speciesActiveName}`;
  const subTitle = `View and manage your ${speciesActiveName.toLowerCase()}`;

  const history = useHistory();
  const { groups, loading } = useGetGroups();

  const isEmptyStateVisible = !loading && !groups.length;

  return (
    <>
      {loading && groups.length === 0 ? <LoadingOverlay customStyles={styles.loadingOverlay} /> : null}
      <Modal active={showModal} title="Create a new:" handleClose={() => setShowModal(false)} showCloseButton>
        {
          <>
            <KeyValuePair
              label={`Group of Animals`}
              stacked
              value="Allows quick creation of animals, IDs can be added at a later date."
            />
            <Spacer baselineHeight={1} />
            <Button caption={`Create Group`} onClick={(): void => history.push(CREATE_BATCH)} />
            <Spacer baselineHeight={3} border="middle" />
          </>
        }
        <KeyValuePair
          label="Group of Individuals"
          stacked
          value="You can then add animals you already have on Breedr with IDs."
        />
        <Spacer baselineHeight={1} />
        <Button caption="Create Group" onClick={(): void => history.push(CREATE_GROUP)} />
      </Modal>
      <PageHeader>
        <Header title={title} subTitle={subTitle} />

        <Tabs tabs={LIVESTOCK_TABS} selectedTab={1} />
      </PageHeader>

      <PageContent
        floatingIconTextButton={{
          id: ELEMENTS_IDS.ADD_GROUP_PLUS_BUTTON,
          iconName: "plus",
          text: "Create",
          onClick: (): void => setShowModal(true),
        }}
      >
        <Spacer baselineHeight={1} />

        {isEmptyStateVisible ? <EmptyStatesGroupsTab /> : <GroupsTable groups={groups} loading={loading} />}
      </PageContent>
    </>
  );
};
