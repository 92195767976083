// Libraries
import { assign, createMachine } from "xstate";
// API
import { UploadCsv_uploadCsv } from "api/Import/CustomImport/types/UploadCsv";
import { GetAnimalTypes_animalTypes } from "api/MyLivestock/Animal/types/GetAnimalTypes";
import { Species } from "state/speciesSlice";
import { BatchUploadFormat } from "hooks/useGetBatchUploadFormats";
import { ActivityTypeFieldsFragment } from "generated/graphql";

// Context
export type ImportCustomWizardAnimalType = Species | GetAnimalTypes_animalTypes;
interface ImportCustomWizardContext {
  activityType?: ActivityTypeFieldsFragment;
  animalType?: ImportCustomWizardAnimalType;
  batchUploadFormat: BatchUploadFormat | undefined;
  stageNumber: 1 | 2 | 3 | 4 | 5;
  subtitle: string;
  taskId?: string;
  title: string;
  uploadedFile?: UploadCsv_uploadCsv;
  uploadedFileName?: string;
}

// Events
interface SelectActivityTypeEvent {
  type: "SELECT_ACTIVITY_TYPE";
  activityType: ActivityTypeFieldsFragment;
}

interface ResetActivityTypeEvent {
  type: "RESET_ACTIVITY_TYPE";
}

interface ResetUploadTypeEvent {
  type: "RESET_UPLOAD_TYPE";
}

interface SelectBatchUploadFormatEvent {
  type: "SELECT_BATCH_UPLOAD_FORMAT";
  batchUploadFormat: BatchUploadFormat;
}

interface ResetActivityTemplateEvent {
  type: "RESET_ACTIVITY_TEMPLATE";
}

interface SetUploadedFileEvent {
  type: "SET_UPLOADED_FILE";
  uploadedFile: UploadCsv_uploadCsv;
  uploadedFileName: string;
}

interface ProceedWithUploadedFileEvent {
  type: "PROCEED_WITH_UPLOADED_FILE";
}

interface ResetUploadedFileEvent {
  type: "RESET_UPLOADED_FILE";
}

interface SetTaskIdEvent {
  type: "SET_TASK_ID";
  taskId: string;
}

interface RestartEvent {
  type: "RESTART";
}

interface SetTaskSuccessEvent {
  type: "SET_TASK_SUCCESS";
}

type ImportCustomWizardEvents =
  | SelectActivityTypeEvent
  | ResetActivityTypeEvent
  | ResetUploadTypeEvent
  | SelectBatchUploadFormatEvent
  | ResetActivityTemplateEvent
  | SetUploadedFileEvent
  | ProceedWithUploadedFileEvent
  | ResetUploadedFileEvent
  | SetTaskIdEvent
  | RestartEvent
  | SetTaskSuccessEvent;

const initialContext: ImportCustomWizardContext = {
  activityType: undefined,
  animalType: undefined,
  batchUploadFormat: undefined,
  stageNumber: 1,
  subtitle: "Step 1: Choose activity type",
  taskId: undefined,
  title: "Choose type",
  uploadedFile: undefined,
  uploadedFileName: undefined,
};

export const importCustomWizardMachine = createMachine<ImportCustomWizardContext, ImportCustomWizardEvents>(
  {
    id: "importCustom",
    context: initialContext,
    initial: "chooseType",
    states: {
      chooseType: {
        entry: assign((_context) => initialContext),
        on: {
          SELECT_ACTIVITY_TYPE: {
            target: "chooseTemplate",
            actions: ["selectActivityType"],
          },
          SELECT_BATCH_UPLOAD_FORMAT: {
            target: "chooseFile",
            actions: ["selectBatchUploadFormat"],
          },
        },
      },
      chooseTemplate: {
        entry: assign((context) => {
          if (!context.activityType) return {};

          return {
            title: context.activityType.name,
            stageNumber: 2,
            subtitle: `Step 2: Choose "${context.activityType.name}" type`,
          };
        }),
        on: {
          RESET_ACTIVITY_TYPE: {
            target: "chooseType",
            actions: ["resetActivityType"],
          },
          SELECT_BATCH_UPLOAD_FORMAT: {
            target: "chooseFile",
            actions: ["selectBatchUploadFormat"],
          },
        },
      },
      chooseFile: {
        entry: assign((context) => {
          if (context.batchUploadFormat) {
            return {
              title: `Upload file: ${context.batchUploadFormat.name}`,
              stageNumber: 3,
              subtitle: `Step 3: Choose your file to import`,
            };
          }

          if (context.animalType) {
            return {
              title: `Upload file: ${context.animalType.name}`,
              stageNumber: 3,
              subtitle: `Step 3: Choose your file to import`,
            };
          }

          return {};
        }),
        on: {
          RESET_UPLOAD_TYPE: {
            target: "chooseType",
            actions: ["resetContext"],
          },
          RESET_ACTIVITY_TEMPLATE: {
            target: "chooseTemplate",
            actions: ["resetContext"],
          },
          SET_UPLOADED_FILE: {
            actions: ["setUploadedFile"],
          },
          RESET_UPLOADED_FILE: {
            actions: ["resetUploadedFile"],
          },
          PROCEED_WITH_UPLOADED_FILE: {
            target: "chooseColumns",
          },
        },
      },
      chooseColumns: {
        entry: assign((_context) => ({
          title: "Match and import",
          stageNumber: 4,
          subtitle: "Step 4: Match the fields to the columns in your file",
        })),
        on: {
          RESTART: {
            target: "chooseType",
          },
          RESET_UPLOADED_FILE: {
            target: "chooseFile",
            actions: ["resetUploadedFile"],
          },
          SET_TASK_ID: {
            target: "result",
            actions: ["setTaskId"],
          },
          SELECT_BATCH_UPLOAD_FORMAT: {
            actions: ["selectBatchUploadFormat"],
          },
        },
      },
      result: {
        entry: assign((_context) => ({
          title: "Import in progress",
          stageNumber: 4,
          subtitle: "We're working on it",
        })),
        on: {
          RESTART: {
            target: "chooseType",
          },
          SET_TASK_SUCCESS: {
            actions: ["setTaskSuccess"],
          },
        },
      },
    },
  },
  {
    actions: {
      selectActivityType: assign((_context, event) => {
        if (event.type !== "SELECT_ACTIVITY_TYPE") return {};

        return {
          activityType: event.activityType,
        };
      }),
      resetActivityType: assign((_context) => ({
        activityType: undefined,
      })),
      resetContext: assign((_context) => ({
        animalType: undefined,
        batchUploadFormat: undefined,
      })),
      selectBatchUploadFormat: assign((_context, event) => {
        if (event.type !== "SELECT_BATCH_UPLOAD_FORMAT") return {};

        return {
          batchUploadFormat: event.batchUploadFormat,
          activityType: event.batchUploadFormat.activityType || undefined,
        };
      }),
      setUploadedFile: assign((_context, event) => {
        if (event.type !== "SET_UPLOADED_FILE") return {};

        return {
          uploadedFile: event.uploadedFile,
          uploadedFileName: event.uploadedFileName,
        };
      }),
      resetUploadedFile: assign((_context) => ({
        uploadedFile: undefined,
      })),
      setTaskId: assign((_context, event) => {
        if (event.type !== "SET_TASK_ID") return {};

        return {
          taskId: event.taskId,
        };
      }),
      setTaskSuccess: assign((context) => {
        const subtitle = context.batchUploadFormat
          ? `You have successfully imported your ${context.batchUploadFormat.name} file`
          : context.animalType
          ? `You have successfully imported your ${context.animalType.name} file`
          : "You have successfully imported your file";

        return {
          title: "Import Completed",
          stageNumber: 5,
          subtitle,
        };
      }),
    },
  },
);
