import { Field } from "components";
import { BaseInput, BaseInputProps } from "components/Common/Field/BaseInput";
import { forwardRef } from "react";
import { FieldProps } from "..";

type Props = { inputProps: BaseInputProps } & FieldProps;
export const InputNumber = forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => (
  <BaseInput ref={ref} {...props} type="number" />
));
InputNumber.displayName = "InputNumber";

export const FieldNumber = ({ error, inputProps, ...props }: Props) => {
  return (
    <Field error={error} labelFor={props.labelFor || inputProps.name} {...props}>
      <BaseInput type="number" hasError={!!error} {...inputProps} />
    </Field>
  );
};

FieldNumber.displayName = "FieldNumber";
