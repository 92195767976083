import * as React from "react";

import pure from "recompose/pure";

import classNames from "classnames";

import Table from "@material-ui/core/Table";

import styles from "./styles.module.css";

export interface TableProps {
  children?: React.ReactNode;
  className?: string;
}

const CustomTable: React.FC<TableProps> = ({ children, className, ...props }) => (
  <Table className={classNames(styles.table, className)} {...props}>
    {children}
  </Table>
);

export default pure(CustomTable);
