export const STORAGE_ITEMS = {
  TOKEN: "token",
  SELECTED_BU: "selectedBU",
  SELECTED_BU_TYPE: "selectedBUType",
  PERSIST_VERSION: "persistVersion",
  FEATURES_LIST: "featuresList",
  TASK_ID: "taskId",
  LOCATIONS_COUNT: "locationsCount",
  TABLE_SETTINGS: "tableSettings",
  USER_ID: "userId",
};
