import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesNoAnimalsAdded: FC = () => {
  return (
    <EmptyStates animation={"searching"} title="No animals in the movement.">
      <p>Add animals to the movement by clicking the ‘Add animals’ button above.</p>
    </EmptyStates>
  );
};
