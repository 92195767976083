// @ts-nocheck
import { PageMenu, PageMenuGroup, PageMenuLink } from "components";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { REPORTS } from "constants/Routes";
import { useGetReportsList } from "hooks";
import React, { useMemo } from "react";

export const ReportsMenu: React.FC = () => {
  const { data, loading } = useGetReportsList();

  const reports = data?.reports || [];

  const menuItems = useMemo(() => {
    if (loading || data?.reports == null) {
      return [];
    }
    if (data.reports.length == 0) {
      return [];
    }

    const buildMenu = [];

    reports
      .slice()
      // Not sure why this is null...
      .filter((val) => val != null)
      .sort((a, b) => {
        if (a.group == null && b.group == null) {
          return 0;
        }
        if (a.group == null && b.group != null) return 1;
        if (b.group == null && a.group !== null) return 0;

        return a.group.position - b.group.position;
      })
      .forEach(({ group: reportGroup }) => {
        if (!buildMenu.find((menu) => menu.caption === reportGroup.title)) {
          buildMenu.push({
            id: reportGroup.id,
            caption: reportGroup.title,
            items: [],
          });
        }
      });

    buildMenu.forEach(({ id: menuGroupID, items: menuGroupItems }) => {
      reports
        .filter(({ group }) => group.id === menuGroupID)
        .forEach(({ slug, title }) => {
          menuGroupItems.push({
            caption: title,
            path: `${REPORTS}/${slug}`,
          });
        });
    });

    return buildMenu;
  }, [data, loading]);

  return (
    <PageMenu title="Reports" loading={loading}>
      {menuItems.map((menuItem) =>
        menuItem.items ? (
          <PageMenuGroup key={menuItem.caption} title={menuItem.caption}>
            {menuItem.items.map((item) => (
              <PageMenuLink id={`${ELEMENTS_IDS.PAGE_MENU_LINK}_${item.caption}`} key={item.caption} {...item} />
            ))}
          </PageMenuGroup>
        ) : (
          <PageMenuLink id={`${ELEMENTS_IDS.PAGE_MENU_LINK}_${item.caption}`} key={menuItem.caption} {...menuItem} />
        ),
      )}
    </PageMenu>
  );
};
ReportsMenu.displayName = "ReportsMenu";
