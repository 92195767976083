import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";
import "react-virtualized/styles.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import "typeface-inter";
import "./global.scss";
import "helpers/translations/src";
import api from "api/config";
import "index.css";

Sentry.init({
  dsn: "https://963f53c3b4e5404f9041e381862f6f9e@o366744.ingest.sentry.io/5838828",
  environment: process.env.REACT_APP_BUILD_ENV,
  enabled: process.env.REACT_APP_BUILD_ENV !== "local" || process.env.REACT_APP_BUILD_ENV !== undefined,
  tracesSampleRate: 1.0,
});

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize([{ trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID }]);
  ReactGA.set({ appVersion: api.version, environment: process.env.REACT_APP_BUILD_ENV });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
