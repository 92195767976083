import { Modal } from "components";
import { useState } from "react";
import { MarkAsDeadForm } from "animals/MarkAsDeadForm";
import { ContactForm } from "components/ContactForm";

type AnimalId = string;

interface Props {
  animalIds: Array<AnimalId>;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export type ModalContent = "markAsDeadForm" | "createNewContactForm";

export const MarkAsDeadModal: React.FC<Props> = ({ animalIds, showModal, setShowModal }) => {
  const [modalContent, setModalContent] = useState<ModalContent>("markAsDeadForm");

  return (
    <>
      <Modal
        active={showModal}
        title={modalContent === "markAsDeadForm" ? `Mark Animal as Dead` : `Create New Contact`}
      >
        {modalContent === "markAsDeadForm" ? (
          <MarkAsDeadForm
            animalIds={animalIds}
            handleCloseModal={() => setShowModal(false)}
            changeModalContent={() => setModalContent("createNewContactForm")}
            onCancel={() => setShowModal(false)}
          />
        ) : (
          <ContactForm
            contactId={undefined}
            onCancel={() => setModalContent("markAsDeadForm")}
            onConfirm={() => setModalContent("markAsDeadForm")}
            isModal
          />
        )}
      </Modal>
    </>
  );
};
