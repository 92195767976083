// Resources
import ufoCowSvg from "assets/img/icons/ufo_cow.svg";
import alertsHighSVG from "assets/img/icons/alerts_high.svg";
import alertsLowSVG from "assets/img/icons/alerts_low.svg";
import alertsMediumSVG from "assets/img/icons/alerts_medium.svg";
import alertsNoneSVG from "assets/img/icons/alerts_none.svg";
import analogScaleSVG from "assets/img/icons/analog_scale.svg";
import animalSexBullSVG from "assets/img/icons/animal_sex_bull.svg";
import animalSexCowSVG from "assets/img/icons/animal_sex_cow.svg";
import animalSexFemaleSVG from "assets/img/icons/animal_sex_female.svg";
import animalSexHeiferSVG from "assets/img/icons/animal_sex_heifer.svg";
import animalSexMaleSVG from "assets/img/icons/animal_sex_male.svg";
import animalSexMixedSVG from "assets/img/icons/animal_sex_mixed.svg";
import animalSexSteerSVG from "assets/img/icons/animal_sex_steer.svg";
import bellSVG from "assets/img/icons/bell.svg";
import breedingSVG from "assets/img/icons/breeding.svg";
import calendarSVG from "assets/img/icons/calendar.svg";
import cattleSVG from "assets/img/icons/cattle.svg";
import chartSVG from "assets/img/icons/chart.svg";
import checkSVG from "assets/img/icons/check.svg";
import checklistSVG from "assets/img/icons/checklist.svg";
import chevronSVG from "assets/img/icons/chevron.svg";
import columnSVG from "assets/img/icons/column.svg";
import crossSVG from "assets/img/icons/cross.svg";
import deliverySVG from "assets/img/icons/delivery.svg";
import digitalScaleSVG from "assets/img/icons/digital_scale.svg";
import dnaAmberSVG from "assets/img/icons/dna-amber.svg";
import dnaBlueSVG from "assets/img/icons/dna-blue.svg";
import dnaGreenSVG from "assets/img/icons/dna-green.svg";
import dnaGreySVG from "assets/img/icons/dna-grey.svg";
import dnaRedSVG from "assets/img/icons/dna-red.svg";
import dollarSignSVG from "assets/img/icons/dollar_sign.svg";
import doubleChevronSVG from "assets/img/icons/double_chevron.svg";
import downloadFileSVG from "assets/img/icons/download_file.svg";
import downloadSVG from "assets/img/icons/download.svg";
import draggableHandleSVG from "assets/img/icons/draggable_handle.svg";
import editSVG from "assets/img/icons/edit.svg";
import ellipsisSVG from "assets/img/icons/ellipsis.svg";
import emailAttachmentSVG from "assets/img/icons/email_attachment.svg";
import envelopeSVG from "assets/img/icons/envelope.svg";
import fieldSVG from "assets/img/icons/field.svg";
import fileCsvSVG from "assets/img/icons/file_csv.svg";
import filterFunnelSVG from "assets/img/icons/filter_funnel.svg";
import filterSVG from "assets/img/icons/filter.svg";
import financialSVG from "assets/img/icons/financial.svg";
import folderSVG from "assets/img/icons/folder.svg";
import geneticSVG from "assets/img/icons/icon-genetic.svg";
import handshakeSVG from "assets/img/icons/handshake.svg";
import homeSVG from "assets/img/icons/home.svg";
import infoSVG from "assets/img/icons/info.svg";
import mapMarkerSVG from "assets/img/icons/map_marker.svg";
import maximizeSVG from "assets/img/icons/maximize.svg";
import medicinesSVG from "assets/img/icons/medicines.svg";
import minimizeSVG from "assets/img/icons/minimize.svg";
import minusSVG from "assets/img/icons/minus.svg";
import moveSVG from "assets/img/icons/move.svg";
import needleSVG from "assets/img/icons/needle.svg";
import numberedListSVG from "assets/img/icons/numbered_list.svg";
import organicSVG from "assets/img/icons/organic.svg";
import pencilSVG from "assets/img/icons/pencil.svg";
import peopleSVG from "assets/img/icons/people.svg";
import plusSVG from "assets/img/icons/plus.svg";
import pregnantSVG from "assets/img/icons/pregnant.svg";
import rectangleSGV from "assets/img/icons/rectangle.svg";
import regulatorySVG from "assets/img/icons/regulatory.svg";
import saveSVG from "assets/img/icons/save.svg";
import searchSVG from "assets/img/icons/save.svg";
import securePaymentSVG from "assets/img/icons/secure_payment.svg";
import settingsSVG from "assets/img/icons/settings.svg";
import sireSVG from "assets/img/icons/sire.svg";
import tableSVG from "assets/img/icons/table.svg";
import tagsSVG from "assets/img/icons/tag.svg";
import timesSVG from "assets/img/icons/times.svg";
import transactionHandshakeSVG from "assets/img/icons/transaction_handshake.svg";
import trashSVG from "assets/img/icons/trash.svg";
import uploadSVG from "assets/img/icons/upload.svg";
import warehouseSVG from "assets/img/icons/warehouse.svg";
import warningSVG from "assets/img/icons/warning.svg";
import weightKGSVG from "assets/img/icons/weight_kg.svg";
import weightSVG from "assets/img/icons/weight.svg";
import withdrawalSVG from "assets/img/icons/withdrawal.svg";
import yardSVG from "assets/img/icons/yard2.svg";

/*
 * Intention is to replace this with `export const ICON_COLOURS = { PROPERTY_NAMES: "property_value" } as const;` and
 * use ValuesOf<typeof ICON_COLOURS> for prop types which will bring this in alignment with naming-convention.
 */
export const ICON_COLOURS = {
  black: "black",
  grey800: "grey800",
  grey600: "grey600",
  // eslint-disable-next-line
  grey_dark: "grey_dark",
  // eslint-disable-next-line
  grey_light: "grey_light",
  grey100: "grey100",
  white: "white",
  blue: "blue",
  // eslint-disable-next-line
  blue_light: "blue_light",
  blue500: "blue500",
  brown: "brown500",
  green: "green",
  // eslint-disable-next-line
  green_white: "green_white",
  // eslint-disable-next-line
  green_dark: "green_dark",
  // eslint-disable-next-line
  green_light: "green_light",
  red: "red",
  red500: "red500",
  // eslint-disable-next-line
  red_light: "red_light",
  warning: "warning",
  yellow: "yellow",
} as const;

export const ICON_NAMES = {
  ufoCow: ufoCowSvg,
  alertsHigh: alertsHighSVG,
  alertsLow: alertsLowSVG,
  alertsMedium: alertsMediumSVG,
  alertsNone: alertsNoneSVG,
  analogScale: analogScaleSVG,
  animalSexBull: animalSexBullSVG,
  animalSexCow: animalSexCowSVG,
  animalSexFemale: animalSexFemaleSVG,
  animalSexHeifer: animalSexHeiferSVG,
  animalSexMale: animalSexMaleSVG,
  animalSexMixed: animalSexMixedSVG,
  animalSexSteer: animalSexSteerSVG,
  bell: bellSVG,
  breeding: breedingSVG,
  calendar: calendarSVG,
  cattle: cattleSVG,
  chart: chartSVG,
  check: checkSVG,
  checklist: checklistSVG,
  chevron: chevronSVG,
  column: columnSVG,
  cross: crossSVG,
  delivery: deliverySVG,
  digitalScale: digitalScaleSVG,
  dollarSign: dollarSignSVG,
  dnaAmber: dnaAmberSVG,
  dnaBlue: dnaBlueSVG,
  dnaGreen: dnaGreenSVG,
  dnaGrey: dnaGreySVG,
  dnaRed: dnaRedSVG,
  doubleChevron: doubleChevronSVG,
  download: downloadSVG,
  downloadFile: downloadFileSVG,
  draggableHandle: draggableHandleSVG,
  edit: editSVG,
  ellipsis: ellipsisSVG,
  emailAttachment: emailAttachmentSVG,
  envelope: envelopeSVG,
  field: fieldSVG,
  fileCsv: fileCsvSVG,
  filter: filterSVG,
  filterFunnel: filterFunnelSVG,
  financial: financialSVG,
  folder: folderSVG,
  genetic: geneticSVG,
  handshake: handshakeSVG,
  home: homeSVG,
  info: infoSVG,
  mapMarker: mapMarkerSVG,
  maximize: maximizeSVG,
  medicines: medicinesSVG,
  minimize: minimizeSVG,
  minus: minusSVG,
  move: moveSVG,
  needle: needleSVG,
  numberedList: numberedListSVG,
  organic: organicSVG,
  pencil: pencilSVG,
  people: peopleSVG,
  plus: plusSVG,
  pregnant: pregnantSVG,
  rectangle: rectangleSGV,
  regulatory: regulatorySVG,
  save: saveSVG,
  search: searchSVG,
  securePayment: securePaymentSVG,
  settings: settingsSVG,
  sire: sireSVG,
  table: tableSVG,
  tags: tagsSVG,
  times: timesSVG,
  transactionHandshake: transactionHandshakeSVG,
  trash: trashSVG,
  upload: uploadSVG,
  warehouse: warehouseSVG,
  warning: warningSVG,
  weight: weightSVG,
  weightKG: weightKGSVG,
  withdrawal: withdrawalSVG,
  yard: yardSVG,
};

export const ICON_SIZES = {
  atom: "atom",
  tiny: "tiny",
  small: "small",
  medium: "medium",
  large: "large",
  extraLarge: "extraLarge",
};
