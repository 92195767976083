import { gql } from "@apollo/client";

export const userInfoFragment = gql`
  fragment UserInfoFragment on User {
    id
    email
    firstName
    lastName
    image
    phone
    lastLogin
    jobTitle
    intercomUserHash
    isStaff
  }
`;

export const errorFragment = gql`
  fragment errorFragment on ValidationError {
    field
    message
  }
`;

export const ownerFragment = gql`
  fragment ownerFragment on UserPublicInfo {
    id
    email
    image
    firstName
    lastName
    businessUnitUserId
  }
`;

export const businessUnitUser = gql`
  fragment businessUnitUser on BusinessUnitUser {
    id
    role {
      id
      name
    }
    status
    businessUnit {
      id
      name
    }
    user {
      ...UserInfoFragment
    }
    isOwner
  }
  ${userInfoFragment}
`;
