import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesTagsPage: FC = () => {
  return (
    <EmptyStates title="Add animals to this tag." animation={"tags"}>
      <p>
        Tags are visually identified throughout the app.
        <br />
        1 animal can be assigned multiple tags.
        <br />
        Click ‘Add Animals’, select the checkbox next to the animals in the table and click ‘Add Tag’.
      </p>
    </EmptyStates>
  );
};
