import React, { useEffect, useState } from "react";
import { sortBy } from "lodash";
// Components
import { Flex, InputSelect, Text } from "components";
// Types
import { InputSelectOption } from "components/Common/Field/Select";
// Constants
import { ANIMAL_CHART_FILTERS } from "constants/Animals";
// Styles
import styles from "./AnimalChartFilter.module.scss";

interface AnimalChartFilterProps {
  onChange: (selectedFilters: (string | null)[]) => void;
  availableFilters: (string | null)[] | null;
}

interface AnimalChartFilterEvent {
  target: {
    value: string[];
  };
}

export const AnimalChartFilter: React.FC<AnimalChartFilterProps> = ({ availableFilters = [], onChange }) => {
  const [filters, updateFilters] = useState<(string | null)[]>([]);

  const setSelectedFilters = (selectedFilters: (string | null)[]): void => {
    updateFilters(sortBy(selectedFilters));
  };

  const handleFilterChange = (event: AnimalChartFilterEvent): void => {
    const selectedFilters = event.target.value;

    setSelectedFilters(selectedFilters);
    onChange(selectedFilters);
  };

  const options: InputSelectOption[] = !!availableFilters?.length
    ? availableFilters.reduce((acc: InputSelectOption[], filterType) => {
        if (!filterType) return acc;

        return [
          ...acc,
          {
            key: filterType,
            value: filterType,
            label: ANIMAL_CHART_FILTERS[filterType],
          },
        ];
      }, [])
    : [];

  useEffect(() => {
    // this presets filter field with available filters from BE
    if (availableFilters) {
      setSelectedFilters(availableFilters);
    }
  }, [availableFilters]);

  // don't render anything if no filters are available
  if (!availableFilters?.length) return null;

  return (
    <Flex container containerJustifyContent="flex-end" containerDirection="row" containerAlignItems="center">
      <Flex item itemGutter>
        <Text smallest>
          <strong>Chart filters:</strong>
        </Text>
      </Flex>
      <Flex item className={styles.animalChartFilterDropdown}>
        <InputSelect
          multiple
          name="chart-filters"
          onChange={handleFilterChange}
          options={options}
          value={filters}
          placeholder="Select tags"
        />
      </Flex>
    </Flex>
  );
};
