import { useLocale } from "helpers/translations/src/useLocale";
import { useState, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AnimalSchema } from "../AnimalForm/validation";
import { InputLookup, InputLookupProps } from "./InputLookup";
import { Button as TwButton } from "twComponents/Button";
import _debounce from "lodash/debounce";
import { Icon } from "components/Common/Icon";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { AnimalFragment } from "generated/graphql";
import { CATTLE_ID } from "../AnimalForm";
import { useStateSpecies } from "hooks/useStateSpecies";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { AnimatePresence, motion } from "framer-motion";
import { useGetAnimalIdPreferences } from "hooks/useGetAnimalIdPreferences";
import React from "react";

type Props = {
  showAdditionalAnimalIdsInitialState: boolean;
  animal: AnimalFragment | undefined;
};

export const AnimalIdInputs = React.forwardRef<JSX.Element, Props>(
  ({ animal, showAdditionalAnimalIdsInitialState = false }, ref) => {
    const [showExtraAnimalIdInputs, setShowExtraAnimalIdInputs] = useState<boolean>(
      showAdditionalAnimalIdsInitialState,
    );
    const { activeSpecies } = useStateSpecies();
    const { terms: t } = useLocale();
    const { nameOfActiveIds } = useGetAnimalIdPreferences();
    const { control, setValue, trigger, watch } = useFormContext<AnimalSchema>();
    const { countryIsoCodeUpperCase, country } = useGetCurrentBusinessUnit();
    const visualIdPrefix = country?.visualIdPrefix || "UK";

    const isCattle = animal?.animalType?.id === CATTLE_ID || (!animal && activeSpecies?.id === CATTLE_ID);

    const validateId = useCallback(
      async (inputValue: string, fieldName: keyof AnimalSchema) => {
        if (inputValue.length > 0) {
          await trigger(fieldName);
        }
      },
      [trigger],
    );

    const debounceFn = useMemo(() => _debounce(validateId, 800), [validateId]);

    function onChange(event: React.ChangeEvent<HTMLInputElement>, fieldName: keyof AnimalSchema) {
      setValue(fieldName, event.target.value);

      // INFO: We have some special prefix logic for sheeps
      if (!isCattle && fieldName === "eId") {
        const updatedValue =
          countryIsoCodeUpperCase === CountryISOCode.US ? event.target.value : `${visualIdPrefix}${event.target.value}`;
        setValue("passportNumber", updatedValue);
      }

      debounceFn(event.target.value, fieldName);
    }

    const isMale = watch("animalSex") === "male";
    const isGB = countryIsoCodeUpperCase === CountryISOCode.GB;

    const idsToExcludeFromExtraAnimalIds = isGB
      ? ["breedrId", "passportNumber", "visualId"]
      : ["breedrId", "visualId", "eId"];
    const extraAnimalIds = nameOfActiveIds?.filter((id) => !idsToExcludeFromExtraAnimalIds.includes(id));

    const inputPropsLookup: InputLookupProps = {
      breedrId: undefined,
      visualId: { control },
      eId: {
        control,
        onChange,
        adornmentLeft:
          !isCattle && countryIsoCodeUpperCase === CountryISOCode.GB
            ? { label: visualIdPrefix, labelFor: "eId" }
            : undefined,
      },
      brucellosisId: {
        control,
        disabled: isMale,
      },
      pedigreeId: {
        control,
        label: capitaliseFirstLetter(t.pedigreeId),
      },
      tattoo: {
        control,
      },
      trichId: {
        control,
        disabled: !isMale,
      },
      tsuBarcode: {
        control,
      },
      herdDoggId: {
        control,
        forwardRef: ref,
      },
      passportNumber: {
        control,
        onChange,
        disabled: Boolean(animal),
        isGB,
        isCattle,
      },
      uhfId: {
        control,
      },
      alternativeId: {
        control,
      },
      name: {
        control,
      },
    };

    const animationVariants = {
      initial: {
        opacity: 0,
        scale: 0.95,
      },
      animate: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: 1.0,
          ease: [0.25, 1, 0.5, 1],
        },
      },
    };

    const renderMainIds = () => {
      if (isGB) {
        return (
          <>
            {InputLookup.passportNumber({
              ...inputPropsLookup.passportNumber,
            })}
            {InputLookup.visualId({ ...inputPropsLookup.visualId })}
          </>
        );
      }

      return (
        <>
          {InputLookup.visualId({ ...inputPropsLookup.visualId })}
          {InputLookup.eId({
            ...inputPropsLookup.eId,
            adornmentLeft:
              !isCattle && countryIsoCodeUpperCase === CountryISOCode.GB
                ? { label: visualIdPrefix, labelFor: "eId" }
                : undefined,
          })}
        </>
      );
    };

    return (
      <>
        {renderMainIds()}

        {extraAnimalIds?.length > 0 ? (
          <div className="col-span-full flex items-center">
            <div className="h-[1px] bg-gray-600 w-full" />
            <TwButton
              asChild
              type="button"
              variant="text"
              onClick={() => setShowExtraAnimalIdInputs((prevState) => !prevState)}
            >
              <span className="font-normal whitespace-nowrap mx-7">
                {`${showExtraAnimalIdInputs ? "Hide extra IDs" : "Show more IDs"}`}
                <Icon
                  className="ml-4"
                  name="chevron"
                  colourOverride="#838991"
                  rotate={showExtraAnimalIdInputs ? 270 : 90}
                  size="tiny"
                />
              </span>
            </TwButton>
            <div className="h-[1px] bg-gray-600 w-full" />
          </div>
        ) : null}

        {showExtraAnimalIdInputs ? (
          <AnimatePresence>
            <motion.div
              initial="initial"
              animate="animate"
              variants={animationVariants}
              className="col-span-full grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
            >
              {(extraAnimalIds || []).map((id) => {
                if (InputLookup[id]) {
                  return InputLookup[id]({
                    ...inputPropsLookup[id],
                  });
                }
              })}
            </motion.div>
          </AnimatePresence>
        ) : null}
      </>
    );
  },
);

AnimalIdInputs.displayName = "AnimalIdInputs";
