// @ts-nocheck
import React, { useState } from "react";
import { DateTime } from "luxon";
import { useGetCurrentBusinessUnit } from "hooks";
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Line,
  ReferenceLine,
  ReferenceArea,
  Legend,
} from "recharts";
// Components
import AnimalChartLabel from "./AnimalChartLabel";
import AnimalChartTooltip from "./AnimalChartTooltip";
// Resources
import "./styles.scss";

const LEGEND_NAMES = {
  ESTIMATE: "Estimate or prediction",
  GROWTH: "Growth target",
  MEASUREMENTS: "Actual measurement",
  REFERENCE: "Reference",
};

const AnimalChart: React.FC = ({
  dates,
  lowBand,
  avgBand,
  highBand,
  measurements,
  measurementsArea,
  performance,
  predictions,
  references,
  xAxisTicks,
  yAxisTicks,
  chartColours,
  targetWeights,
  isArchived = false,
}) => {
  const [measurementTooltip, setMeasurementTooltip] = useState(null);

  const chartHeight = 340;
  const horizontalReferences =
    references.filter((reference) => reference.value).sort((a, b) => a.value - b.value) || [];
  let horizontalOverlapLabel = undefined;
  let isHorizontalReferenceOverlap = false;

  if (horizontalReferences.length === 3) {
    horizontalOverlapLabel = horizontalReferences.splice(1, 1);

    const horizontalReferenceSpace =
      (horizontalReferences[1].value - horizontalReferences[0].value) / yAxisTicks[yAxisTicks.length - 1];

    const minHorizontalReferenceSpace = 21;
    const realChartHeight = chartHeight - 57; // 57 = height of spacing above and below
    isHorizontalReferenceOverlap = horizontalReferenceSpace < minHorizontalReferenceSpace / realChartHeight;
  }

  const rflReferences = references ? references.filter((reference) => reference.rfl) : [];

  const handleLineMouseOver = (event): void => {
    const styles = {
      top: `${Math.round(event.cy)}px`,
      left: `${Math.round(event.cx)}px`,
      transform: `translate(0, -50%)`,
    };

    const tooltip = <AnimalChartTooltip styles={styles} data={event.payload} basic={isArchived} />;

    setMeasurementTooltip(tooltip);
  };

  const handleLineMouseLeave = (): void => {
    setMeasurementTooltip(null);
  };

  const renderLegend = (props): JSX.Element => {
    const { payload } = props;
    const showReferenceItem = horizontalReferences.length > 0;

    return (
      <ul className="legend">
        {payload.map((entry, index) => {
          const prefix = entry.value === LEGEND_NAMES.ESTIMATE ? "---" : "\u2014";
          const style = {
            color: entry.color,
          };

          if (entry.type === "none") {
            return null;
          }

          return (
            <li key={`item-${index}`} className="legend__item" style={style}>
              <span className="legend__prefix">{prefix}</span> {entry.value}
            </li>
          );
        })}

        {showReferenceItem ? (
          <li className="legend__item legend__item--color-yellow">
            <span className="legend__prefix">{"\u2014"}</span> {LEGEND_NAMES.REFERENCE}
          </li>
        ) : null}
      </ul>
    );
  };

  const { weightUnits } = useGetCurrentBusinessUnit();
  return (
    <div className="animal_performance_chart">
      {measurements.length > 0 ? (
        <>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart
              margin={{
                top: 28,
              }}
            >
              <Legend content={renderLegend} />
              <CartesianGrid vertical={false} stroke="#DFDFDF" />
              {/* START axes */}
              <XAxis
                dataKey="timestamp"
                axisLine={false}
                scale="time"
                type="number"
                domain={["dataMin", "dataMax"]}
                ticks={xAxisTicks}
                tickSize={0}
                tickFormatter={(timestamp): void =>
                  DateTime.fromMillis(timestamp).toLocaleString({ month: "short", year: "2-digit" })
                }
                tickMargin={9}
                interval="preserveStartEnd"
              />
              <YAxis
                dataKey="value"
                axisLine={false}
                type="number"
                domain={[0, yAxisTicks[yAxisTicks.length - 1]]}
                ticks={yAxisTicks}
                tickSize={0}
                tickMargin={9}
                label={{
                  position: "insideLeft",
                  value: `Weight (${weightUnits}s)`,
                  angle: -90,
                  style: {
                    textAnchor: "middle",
                  },
                }}
              />
              {!!references
                ? references
                    .filter((reference) => !reference.rfl && reference.timestamp)
                    .map((reference) => (
                      <ReferenceLine
                        key={`line_${reference.timestamp}-${reference.label}`}
                        x={reference.timestamp}
                        stroke={chartColours.reference}
                        strokeWidth={2}
                        label={
                          Object.prototype.hasOwnProperty.call(reference, "label")
                            ? {
                                position: "top",
                                value: reference.label,
                                offset: 21,
                                content: (
                                  <AnimalChartLabel
                                    rectFill={chartColours.reference}
                                    textFill={chartColours.brand.blue}
                                  />
                                ),
                              }
                            : undefined
                        }
                      />
                    ))
                : null}
              <Area
                data={highBand}
                dataKey="value"
                type="linear"
                legendType="none"
                fill={chartColours.high[performance === "high" ? "fill_highlight" : "fill"]}
                fillOpacity={1}
                stroke="none"
                isAnimationActive={false}
                activeDot={false}
              />
              <Area
                data={avgBand}
                dataKey="value"
                type="linear"
                legendType="none"
                fill={chartColours.avg[performance === "avg" ? "fill_highlight" : "fill"]}
                fillOpacity={1}
                stroke="none"
                isAnimationActive={false}
                activeDot={false}
              />
              <Area
                data={lowBand}
                dataKey="value"
                type="linear"
                legendType="none"
                fill={chartColours.low[performance === "low" ? "fill_highlight" : "fill"]}
                fillOpacity={1}
                stroke="none"
                isAnimationActive={false}
                activeDot={false}
              />
              {measurementsArea ? (
                <Area
                  data={measurementsArea}
                  dataKey="value"
                  legendType="none"
                  fill={chartColours.avg.fill_highlight}
                  fillOpacity={1}
                  stroke="none"
                  isAnimationActive={false}
                  activeDot={false}
                />
              ) : null}
              {rflReferences.map((reference) => (
                <ReferenceArea
                  key={`endzone_${reference.timestamp}`}
                  x1={reference.timestamp}
                  x2={dates[dates.length - 1].timestamp}
                  fill={chartColours.filler}
                  fillOpacity={1}
                  stroke="none"
                />
              ))}
              {measurements ? (
                <Line
                  data={measurements}
                  dataKey="value"
                  name={LEGEND_NAMES.MEASUREMENTS}
                  stroke={chartColours[performance].stroke}
                  strokeWidth={2}
                  dot={{
                    r: 4,
                    fill: chartColours[performance].stroke,
                    strokeWidth: 8,
                    stroke: "transparent",
                    onMouseOver: handleLineMouseOver,
                    onMouseLeave: handleLineMouseLeave,
                  }}
                  activeDot={false}
                  isAnimationActive={false}
                />
              ) : null}
              {predictions && predictions.length >= 2 ? (
                <Line
                  data={predictions}
                  dataKey="value"
                  name={LEGEND_NAMES.ESTIMATE}
                  stroke={chartColours[performance].stroke}
                  strokeWidth={2}
                  strokeDasharray="6 6"
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
              ) : null}
              {targetWeights && targetWeights.length > 0 ? (
                <Line
                  data={targetWeights}
                  dataKey="value"
                  name={LEGEND_NAMES.GROWTH}
                  stroke={chartColours.growthLine}
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
              ) : null}
              {rflReferences.map((reference) => (
                <ReferenceLine
                  key={`line_${reference.timestamp}-${reference.label}`}
                  x={reference.timestamp}
                  stroke={chartColours.avg.stroke}
                  strokeWidth={2}
                  label={
                    Object.prototype.hasOwnProperty.call(reference, "label")
                      ? {
                          position: "top",
                          value: reference.label,
                          offset: 21,
                          content: <AnimalChartLabel rectFill={chartColours.brand.blue} textFill="#FFFFFF" />,
                        }
                      : undefined
                  }
                />
              ))}
              {horizontalReferences.map((reference) => (
                <ReferenceLine
                  key={`line_${reference.value}-${reference.label}`}
                  y={reference.value}
                  stroke={chartColours.brand.yellow}
                  strokeWidth={2}
                  label={
                    !isHorizontalReferenceOverlap && Object.prototype.hasOwnProperty.call(reference, "label")
                      ? {
                          position: "insideLeft",
                          value: `Group target ${reference.value} ${weightUnits}s`,
                          offset: 35,
                          content: (
                            <AnimalChartLabel rectFill={chartColours.brand.yellow} textFill={chartColours.brand.blue} />
                          ),
                        }
                      : undefined
                  }
                />
              ))}
              {isHorizontalReferenceOverlap && horizontalOverlapLabel ? (
                <ReferenceLine
                  key="line_overlap_label"
                  y={horizontalOverlapLabel[0].value}
                  strokeWidth={0}
                  label={{
                    position: "insideLeft",
                    value: horizontalOverlapLabel[0].label,
                    offset: 35,
                    content: (
                      <AnimalChartLabel rectFill={chartColours.brand.yellow} textFill={chartColours.brand.blue} />
                    ),
                  }}
                />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
          {measurementTooltip ? measurementTooltip : null}
        </>
      ) : null}
    </div>
  );
};

export default AnimalChart;
