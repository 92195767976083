import { ReactNode, useState } from "react";

type TooltipProps = {
  title: string;
  children?: ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  colour?: "primary" | "secondary" | "error" | "success" | "ghost";
  infoTooltip?: boolean;
};

export const Tooltip = ({
  title,
  children,
  position = "top",
  colour = "secondary",
  infoTooltip = false,
}: TooltipProps) => {
  const getPosition = () => {
    switch (position) {
      case "top":
        return "bottom-full";
      case "bottom":
        return "top-full";
      case "left":
        return "right-full";
      case "right":
        return "left-full";
      default:
        return "bottom-full";
    }
  };
  const colours = () => {
    switch (colour) {
      case "primary":
        return "bg-yellow-100";
      case "secondary":
        return "bg-gray-50";
      case "error":
        return "bg-red-100";
      case "success":
        return "bg-green-100";
      case "ghost":
        return "bg-white";
    }
  };

  const [show, setShow] = useState(false);
  return (
    <div className="relative flex flex-col items-center group">
      <span className="flex justify-center" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {infoTooltip ? (
          <div className={`w-4 h-4 ${colours()} rounded-lg text-sm text-center m-2 flex items-center justify-center`}>
            ?
          </div>
        ) : (
          children
        )}
      </span>
      {show ? (
        <div className={`absolute whitespace-nowrap ${getPosition()} max-w-[20px] items-center  group-hover`}>
          <span
            className={`relative z-10 p-2 text-xs break-all leading-none text-grey-800 whitespace-no-wrap ${colours()} shadow-lg rounded-md`}
          >
            {title}
          </span>
        </div>
      ) : null}
    </div>
  );
};
