// API
import { BuyingLots_buyingLots_edges_node } from "trading/api/Liveweight/types/BuyingLots";
import { MyLots_myLots_edges_node } from "trading/api/Liveweight/types/MyLots";
// Utils
import { isListingSold, isListingTradeAgreed } from "trading/util";

/**
 * Default values:
 *
 * For each of these keys the filtering will automatically attempt to find a custom filter (below) to run.
 * If no custom filter found it will attempt to find the key in the listing and do a basic primitive type based check.
 */

export interface CheckBoxState {
  [prop: string]: boolean;
}

export interface LiveweightFiltersState {
  hideAgreedSoldExpired: boolean;
  speciesSelect: string;
  distance: number;
  category: { allUnchecked: boolean; showAll: boolean; checkBoxCount: number; checkBoxes: CheckBoxState };
}

export const DEFAULT_LIVEWEIGHT_FILTERS = {
  hideAgreedSoldExpired: false,
  speciesSelect: "All Species",
  distance: Infinity,
  category: { allUnchecked: true, showAll: false, checkBoxCount: 0, checkBoxes: {} },
};

/**
 * Custom overrides:''
 *
 * A custom filter callback can be provided for any of the above filter keys.
 */

type CustomLiveweightFilters = {
  [P in keyof LiveweightFiltersState]?: (
    listing: BuyingLots_buyingLots_edges_node | MyLots_myLots_edges_node,
    filters: LiveweightFiltersState,
  ) => boolean;
};

export const CUSTOM_LIVEWEIGHT_FILTERS: CustomLiveweightFilters = {
  hideAgreedSoldExpired: (listing): boolean => {
    if (!listing.status) return false;

    if (isListingTradeAgreed(listing.status)) return false;

    if (isListingSold(listing.status)) return false;

    if (listing.isExpired) return false;

    return true;
  },

  speciesSelect: (listing, filters): boolean => {
    if (filters.speciesSelect === "All Species") {
      return true;
    }
    //If no animal type assume it should be classed as Cattle
    if (listing?.animalType === null && filters?.speciesSelect === "Cattle") {
      return true;
    }

    return filters?.speciesSelect === listing?.animalType?.name;
  },
  distance(listing, filters) {
    // distance field is not currently pulled through for
    // users own listings. Only listings for sale.
    if (listing.__typename === "OwnLot") {
      return true;
    }
    if (listing.distance == null) {
      return true;
    }
    if (listing.distance > filters.distance) {
      return false;
    }
    return true;
  },
  category(listing, filters) {
    if (listing.__typename === "OwnLot" || filters.category.allUnchecked) {
      return true;
    }

    const categoryId = listing?.categoryOfSale?.id;

    if (categoryId) {
      return filters.category.checkBoxes[categoryId];
    }

    return false;
  },
};
