import React from "react";
import cn from "classnames";
import { isEmpty } from "lodash";
import { Icon } from "components";
import fieldStyles from "./Field.module.scss";

export interface FieldProps {
  children?: React.ReactNode;
  error?: string;
  helper?: string;
  infoIcon?: () => void;
  label?: string;
  labelFor?: string;
  className?: string;
}

export const Field = ({ children, error, helper, infoIcon, label, labelFor, className }: FieldProps) => (
  <div className={cn(fieldStyles.field, className)}>
    {label ? (
      <label className={fieldStyles.field__label} htmlFor={labelFor}>
        {label}
        {infoIcon ? (
          <button className={fieldStyles.field__icon} onClick={infoIcon}>
            <Icon colour="grey_dark" name="info" size="tiny" />
          </button>
        ) : null}
      </label>
    ) : null}
    <div className={cn(fieldStyles.field__input)}>{children}</div>
    {error || helper ? (
      <p
        className={cn(fieldStyles.field__helper, {
          [fieldStyles["field__helper--error"]]: !isEmpty(error),
        })}
      >
        {error ? error : helper}
      </p>
    ) : null}
  </div>
);

Field.displayName = "Field";
