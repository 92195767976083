import { FC } from "react";
import { EmptyStates } from "components";
import { useHistory } from "react-router-dom";
import { SETTINGS, BUSINESS_SETTINGS } from "constants/Routes";

export const EmptyStatesBcms: FC = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(`${SETTINGS}${BUSINESS_SETTINGS}`);
  };

  return (
    <EmptyStates
      animation={"regulations"}
      title="You haven’t setup your BCMS integration."
      buttonCaption="Setup BCMS"
      onClickButton={handleButtonClick}
    >
      <p>
        Integrate with us today to seamlessly manage your farm.
        <br />
        Click the button below and go to ‘Connected systems’.
      </p>
    </EmptyStates>
  );
};
