import { Button, FieldSelect, InputCheckbox, Paper, Spacer } from "components";
import { CategoryFilters } from "./CategoryFilters";
import { useGetCategoryListings, useGetCurrentBusinessUnit, useListingFiltersServerSide } from "hooks";
import { ListingFiltersInstance } from "trading/hooks/useListingFilters";
import React, { ChangeEvent, useEffect } from "react";
import styles from "./ListingFilters.module.scss";

interface ListingFiltersProps {
  instance: ListingFiltersInstance;
}

export const ListingFiltersServerSide: React.FC<ListingFiltersProps> = ({ instance }) => {
  const { set, isDefault, reset: handleReset, values: filters } = useListingFiltersServerSide(instance);
  const distanceOptions = useDistanceOptions();
  const { animalTypes } = useGetCategoryListings();

  useEffect(() => {
    set({ categoryOfSale: filters.categoryOfSale });
  }, []);

  const handleChangeCheckbox = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>): void => {
    set({
      [name]: checked,
    });
  };

  const handleChangeCategoryCheckbox = ({ target: { name } }: ChangeEvent<HTMLInputElement>): void => {
    set({
      categoryOfSale: Number(name),
    });
  };

  const handleChangeDistance = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement>): void => {
    set({
      [name]: value,
    });
  };

  const handleChangeSpecies = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement>): void => {
    set({
      [name]: value,
      categoryOfSale: null,
    });
  };

  const speciesSelectOptions = animalTypes.reduce(
    (acc, animalType) => {
      acc.push({
        label: animalType.name,
        key: +animalType.id,
        value: animalType.id,
      });
      return acc;
    },
    [{ key: 0, label: "All Species", value: "All Species" }],
  );

  const speciesCategoryOptions =
    filters.animalType != null
      ? animalTypes.filter((animalType) => Number(animalType.id) === Number(filters.animalType))
      : animalTypes;

  return (
    <Paper className={styles.listing_filters}>
      <Spacer baselineHeight={2} />

      {instance === "buyer" ? (
        <FieldSelect
          label="Distance"
          inputProps={{
            labelKey: "label",
            name: "distance",
            onChange: handleChangeDistance,
            options: distanceOptions,
            value: filters.distance,
            valueKey: "value",
          }}
        />
      ) : null}

      <FieldSelect
        label="Species"
        inputProps={{
          onChange: handleChangeSpecies,
          name: "animalType",
          options: speciesSelectOptions,
          value: filters.animalType == null ? "All Species" : filters.animalType,
        }}
      />
      <Spacer baselineHeight={2} />

      {instance === "buyer" && animalTypes.length >= 0 ? (
        <>
          <Spacer border="top" baselineHeight={2} />
          <CategoryFilters
            filters={filters}
            animalTypes={speciesCategoryOptions}
            handleChangeCategoryCheckbox={handleChangeCategoryCheckbox}
          />
        </>
      ) : null}

      <Spacer border={instance === "buyer" ? "top" : undefined} baselineHeight={2} />

      <InputCheckbox
        checked={filters.showExpired}
        label="Hide Agreed/Sold/Expired"
        name="showExpired"
        onChange={handleChangeCheckbox}
      />

      <Spacer baselineHeight={2} />

      <Button caption="Reset filters" disabled={isDefault} onClick={handleReset} variant="ghost" />

      <Spacer baselineHeight={2} />
    </Paper>
  );
};

type DistanceOption = { label: string; value: number; eventName };

function useDistanceOptions() {
  const businessUnit = useGetCurrentBusinessUnit();

  return React.useMemo(() => {
    const DEFAULT_DISTANCE_OPTIONS: Array<DistanceOption> = [
      {
        label: "Any",
        value: Infinity,
        eventName: "listing-filter-any-distance",
      },
      {
        label: "50",
        value: 50,
        eventName: "listing-filter-small-distance",
      },
      {
        label: "100",
        value: 100,
        eventName: "listing-filter-med-distance",
      },
      {
        label: "200",
        value: 200,
        eventName: "listing-filter-large-distance",
      },
    ];

    if (businessUnit == null) {
      return DEFAULT_DISTANCE_OPTIONS;
    }

    const unitOfMeasurement = businessUnit?.unitsOfMeasurement?.find(
      (unit) => unit?.measurementType === "DISTANCE_LARGE",
    );

    if (unitOfMeasurement == null) {
      return DEFAULT_DISTANCE_OPTIONS;
    }

    const distanceOptionsWithLabel = [
      DEFAULT_DISTANCE_OPTIONS[0],
      ...DEFAULT_DISTANCE_OPTIONS.slice(1, 4).map((option) => ({
        ...option,
        label: `${option.label} ${unitOfMeasurement.code.toLocaleLowerCase()}`,
      })),
    ];

    return distanceOptionsWithLabel;
  }, [businessUnit]);
}
