import React, { CSSProperties } from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./InputCheckbox.module.scss";

interface InputCheckboxBaseProps {
  checked?: boolean;
  className?: string;
  indeterminate?: boolean; // react-table passes this for custom state handling
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  role?: string;
  style?: CSSProperties;
  title?: string;
  disabled?: boolean;
}

interface InputCheckboxWithLabelProps extends InputCheckboxBaseProps {
  label: string;
  name: string;
}

interface InputCheckBoxWithoutLabelProps extends InputCheckboxBaseProps {
  label?: never;
  name?: never;
}

type InputCheckboxProps = InputCheckboxWithLabelProps | InputCheckBoxWithoutLabelProps;

export const InputCheckbox: React.FC<InputCheckboxProps> = ({
  checked = false,
  className,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  indeterminate, // we extract this so that it doesn't get applied to <input /> with {...props}
  label,
  name = undefined,
  disabled = false,
  ...props
}) => {
  const DynamicTag = label ? "label" : "span";

  return (
    <DynamicTag
      className={cn(
        styles.input_checkbox,
        {
          [styles["input_checkbox--checked"]]: checked,
        },
        className,
      )}
      htmlFor={name}
    >
      <span className={styles.input_checkbox__input}>
        <span className={styles.input_checkbox__fake} />

        <input
          checked={checked}
          className={styles.input_checkbox__native}
          id={name}
          name={name}
          type="checkbox"
          disabled={disabled}
          {...props}
        />
      </span>

      {label ? <span className={styles.input_checkbox__label}>{label}</span> : null}
    </DynamicTag>
  );
};

export class Badge {}
