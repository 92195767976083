import { SyntheticEvent, FC, useState, useMemo, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ANIMALS, GROUP_VIEW, TRADING } from "constants/Routes";
import { Button, Modal } from "components";
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { BulkAddToListing } from "./BulkAddToListing";
import { groupAnimalsForListing } from "helpers/myLivestock/animals/groupAnimalsForListing";
import { EventName, logAnalyticsEvent } from "utils/analytics";

interface CreateListingFromSelectionProps {
  animals: GetAnimalsList_animalsExtended_animals[];
  isShowModal?: boolean;
  onClose?: () => void;
}

export const CreateListingFromSelection: FC<CreateListingFromSelectionProps> = ({
  animals,
  isShowModal = false,
  onClose,
}) => {
  const history = useHistory();

  const groupRoute = useRouteMatch(`${GROUP_VIEW}/:id`);
  const livestockRoute = useRouteMatch(ANIMALS);

  const { locationsWithSalableAnimals, nonSalableAnimals } = groupAnimalsForListing(animals);

  const [showModal, setShowModal] = useState(isShowModal);
  const [chosenAnimals, setChosenAnimals] = useState<GetAnimalsList_animalsExtended_animals[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>();

  const handleSettingChosenAnimals = (animals: GetAnimalsList_animalsExtended_animals[]) => {
    if (animals.length > 0) {
      setChosenAnimals(animals);
      setSelectedLocation(animals[0].location?.id);
    }
  };

  const handleClickToCreateListing = () => {
    if (groupRoute != null && groupRoute.isExact) {
      logAnalyticsEvent(EventName.listingStartedGroups, {});
    } else if (livestockRoute != null && livestockRoute.isExact) {
      logAnalyticsEvent(EventName.listingStartedMycattle);
    }

    // push selected location animals here
    if (chosenAnimals.length > 0) {
      history.push({
        pathname: `${TRADING}/selling/create-listing`,
        state: { animals: [...chosenAnimals] },
      });
    }
  };

  const sortedAnimals = useMemo(
    () => [
      { data: nonSalableAnimals, title: "" },
      ...Object.values(locationsWithSalableAnimals).map((animals) => {
        const location = animals[0].location;
        const locationName = location ? `${location?.unitName ?? ""} - ${location?.locationId ?? ""}` : "N/A";

        return { data: animals, title: locationName };
      }),
    ],
    [locationsWithSalableAnimals, nonSalableAnimals],
  );

  const rejectedAnimals = sortedAnimals.shift();
  const animalsByLocation = sortedAnimals;

  useEffect(() => {
    if (animalsByLocation.length === 1) {
      handleSettingChosenAnimals(animalsByLocation[0].data);
    }
  }, []);

  const handleModalToggle = () => {
    setShowModal(!showModal);
    if (onClose) {
      onClose();
    }
  };

  const handleCancelClick = (e: SyntheticEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    handleModalToggle();
  };

  const modalTitle = animals.length > 1 ? "Create listing with these animals?" : "Create listing with this animal?";

  return (
    <>
      <Button id={ELEMENTS_IDS.BULK_CREATE_LISTING_SELECTION} caption="Create Listing" onClick={handleModalToggle} />

      <Modal
        active={showModal}
        handleClose={handleModalToggle}
        title={modalTitle}
        actions={{
          primary: {
            caption: "Create Listing",
            disabled: !chosenAnimals.length,
            onClick: handleClickToCreateListing,
          },
          secondary: {
            caption: "Cancel",
            onClick: handleCancelClick,
          },
        }}
      >
        <BulkAddToListing
          allAnimalsCount={animals.length}
          rejectedAnimals={rejectedAnimals ? rejectedAnimals.data : []}
          animalsForListing={animalsByLocation}
          selectedLocation={selectedLocation}
          setChosenAnimals={handleSettingChosenAnimals}
        />
      </Modal>
    </>
  );
};
