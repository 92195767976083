import { TABLE_ROW_ACTIONS_COLUMN_ID, TABLE_ROW_SELECT_COLUMN_ID } from "components/Common/Table";

export const COLUMN_IDS = {
  SELECTION: TABLE_ROW_SELECT_COLUMN_ID,
  ANIMAL_ID: "passportNumber",
  E_ID: "eId",
  BREEDR_ID: "breedrId",
  BRUCELLOSIS_ID: "brucellosisId",
  TRICH_ID: "trichId",
  TSU_BARCODE: "tsuBarcode",
  HERDDOGG_ID: "herdDoggId",
  PEDIGREE_ID: "pedigreeId",
  NAME: "name",
  TATTOO_ID: "tattoo",
  UHF_ID: "uhfId",
  VISUAL_ID: "visualId",
  ALTERNATIVE_ID: "alternativeId",
  DATE_OF_BIRTH: "dob",
  AGE: "age",
  SEX: "isMale",
  SEX_CLASSIFICATION: "sexClassification",
  BREED: "animalBreeds",
  BREED_CODE: "animalBreedCodes",
  GROUP: "group",
  FIELD: "field",
  TAGS: "tags",
  CURRENT_WEIGHT: "currentWeight",
  LAST_WEIGHT: "lastWeight",
  DAYS_SINCE_LAST_WEIGHT: "daysSinceLastWeight",
  DLWG: "growthRate",
  SIRE: "sire",
  DAM: "dam",
  PREGNANCY_STATUS: "isPregnant",
  PREGNANCY_DUE_DATE: "pregnancyDueDate",
  DELIVERY_DATE: "deliveryDate",
  DATE_MOVED_TO_FARM: "dateMovedToFarm",
  DATE_ON_FARM: "dateOnFarm",
  DAYS_ON_FARM: "daysOnFarm",
  DATE_LEFT_FARM: "dateLeftFarm",
  DEAD_AT: "deadAt",
  KILL_WEIGHT: "killWeight",
  KILL_QUALITY: "killQuality",
  KILL_FAT_SCORE: "killFatScore",
  SERVICE_TYPE: "serviceType",
  CALVING_EASE_SCORE: "calvingEaseScore",
  PREVIOUS_KEEPER: "previousKeeper",
  UPDATED_AT: "updatedAt",
  LAST_SYNCED: "lastRegulatorySyncedDate",
  STATUS: "isBcmsSynced",
  ANIMAL_STATUS: "animalStatus",
  DNA_STATUS: "isDnaTested",
  ACTION_BUTTONS: TABLE_ROW_ACTIONS_COLUMN_ID,
};

export const DEFAULT_COLUMN_IDS = {
  SELECTION: TABLE_ROW_SELECT_COLUMN_ID,
  DATE_OF_BIRTH: "dob",
  AGE: "age",
  SEX_CLASSIFICATION: "sexClassification",
  BREED: "animalBreeds",
  BREED_CODE: "breedCode",
  GROUP: "group",
  FIELD: "field",
  TAGS: "tags",
  CURRENT_WEIGHT: "currentWeight",
  LAST_WEIGHT: "lastWeight",
  DAYS_SINCE_LAST_WEIGHT: "daysSinceLastWeight",
  DLWG: "growthRate",
  SIRE: "sire",
  DAM: "dam",
  PREGNANCY_STATUS: "isPregnant",
  PREGNANCY_DUE_DATE: "pregnancyDueDate",
  DELIVERY_DATE: "deliveryDate",
  DATE_MOVED_TO_FARM: "dateMovedToFarm",
  DATE_ON_FARM: "dateOnFarm",
  DAYS_ON_FARM: "daysOnFarm",
  DATE_LEFT_FARM: "dateLeftFarm",
  DEAD_AT: "deadAt",
  KILL_WEIGHT: "killWeight",
  KILL_QUALITY: "killQuality",
  KILL_FAT_SCORE: "killFatScore",
  PREVIOUS_KEEPER: "previousKeeper",
  UPDATED_AT: "updatedAt",
  LAST_SYNCED: "lastRegulatorySyncedDate",
  STATUS: "isBcmsSynced",
  DNA_STATUS: "isDnaTested",
  ACTION_BUTTONS: TABLE_ROW_ACTIONS_COLUMN_ID,
};

export const DEFAULT_COLUMN_IDS_US = {
  SELECTION: TABLE_ROW_SELECT_COLUMN_ID,
  DATE_OF_BIRTH: "dob",
  AGE: "age",
  SEX_CLASSIFICATION: "sexClassification",
  BREED: "animalBreeds",
  STATUS: "isBcmsSynced",
  ACTION_BUTTONS: TABLE_ROW_ACTIONS_COLUMN_ID,
};

export const DEFAULT_COLUMN_IDS_US_ARCHIVE = {
  SELECTION: TABLE_ROW_SELECT_COLUMN_ID,
  DATE_OF_BIRTH: "dob",
  AGE: "age",
  SEX: "isMale",
  BREED: "animalBreeds",
  TAGS: "tags",
  GROUP: "group",
  FIELD: "field",
  ACTION_BUTTONS: TABLE_ROW_ACTIONS_COLUMN_ID,
};

export const DEFAULT_COLUMN_IDS_CSV_ERRORS = {
  SELECTION: TABLE_ROW_SELECT_COLUMN_ID,
  E_ID: "eId",
  BREEDR_ID: "breedrId",
  VISUAL_ID: "visualId",
  DATE_OF_BIRTH: "dob",
  SEX_CLASSIFICATION: "sexClassification",
  BREED: "animalBreeds",
  ANIMAL_STATUS: "animalStatus",
  UPDATED_AT: "updatedAt",
};
