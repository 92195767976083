import { forwardRef } from "react";
// Utils
import { ICON_NAMES, ICON_COLOURS } from "constants/Icons";
// Components
import { Icon } from "../Icon";
import { Text } from "../Text";

export interface IconLabelProps {
  colour: keyof typeof ICON_COLOURS;
  icon: keyof typeof ICON_NAMES;
  id?: string;
  label: string;
  labelPosition?: "left" | "right";
}

export const IconLabel = forwardRef<HTMLSpanElement, IconLabelProps>(
  ({ colour, icon, id, label, labelPosition = "right", ...props }, forwardedRef): JSX.Element => {
    const ariaId = id || `icon_label`;

    return (
      <span {...props} className={"inline-flex items-center text-xs whitespace-nowrap"} ref={forwardedRef}>
        {labelPosition === "left" ? (
          <Text id={ariaId} smallest className="mr-2">
            {label}
          </Text>
        ) : null}
        <Icon className={"mr-2"} colour={colour} name={icon} size="tiny" ariaLabelledBy={ariaId} />
        {labelPosition === "right" ? (
          <Text id={ariaId} smallest>
            {label}
          </Text>
        ) : null}
      </span>
    );
  },
);
IconLabel.displayName = "IconLabel";
