import { gql } from "@apollo/client";
import { importActivityFragment } from "api/MyLivestock/Activity/fragment";
import { animalFragment, unprocessedAnimalFragment } from "api/MyLivestock/Animal/fragment";

export const taskDetailsFragment = gql`
  fragment taskDetailsFragment on FarmSystemTaskType {
    id
    action
    status
    totalNumberItems
    updatedNumberItems
    producedTask {
      id
      newNumberItems
    }
    producedFromTask {
      createdItems {
        ... on Animal {
          ...animalFragment
        }
        ... on ActivityLog {
          ...importActivityFragment
        }
      }
    }
    processedNumberItems
    newNumberItems
    createdItems {
      ... on Animal {
        ...animalFragment
      }
      ... on ActivityLog {
        ...importActivityFragment
      }
    }
    updatedItems {
      ... on Animal {
        ...animalFragment
      }
    }
    uncreatedItems {
      ... on UnprocessedAnimal {
        ...unprocessedAnimalFragment
      }
    }
  }
  ${animalFragment}
  ${importActivityFragment}
  ${unprocessedAnimalFragment}
`;
