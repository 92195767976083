import { useState } from "react";

interface UseInfinitePaginationReturn {
  visible: number;
  revealMore: () => void;
}

export const useInfinitePagination = (pageSize = 10): UseInfinitePaginationReturn => {
  const [visible, setVisible] = useState<number>(pageSize);

  const handleRevealMore = (): void => {
    setVisible(visible + pageSize);
  };

  return {
    visible,
    revealMore: handleRevealMore,
  };
};
