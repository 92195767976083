// React
import React from "react";
// Libraries
import cn from "classnames";
// Custom
import { ICON_NAMES } from "constants/Icons";
import { Icon, Text } from "components";
import styles from "./IconTextButton.module.scss";

export interface IconTextButtonProps {
  className?: string;
  colour?: "yellow" | "black";
  disabled?: boolean;
  iconName: keyof typeof ICON_NAMES;
  id?: string;
  onClick?: (event?: React.SyntheticEvent<HTMLButtonElement>) => void;
  text: string;
}
export const IconTextButton = ({
  className,
  colour = "yellow",
  text,
  iconName,
  ...props
}: IconTextButtonProps): JSX.Element => (
  <button
    className={cn(
      styles.icon_text_button,
      {
        [styles[`icon_text_button--colour__${colour}`]]: true,
      },
      className,
    )}
    type="button"
    {...props}
  >
    <Icon colour={colour === "yellow" ? "black" : "white"} name={iconName} />
    <Text>{text}</Text>
  </button>
);
