import * as React from "react";
import cn from "classnames";
import logo from "assets/img/Auth/trimmed-logo.png";
import styles from "./styles.module.scss";

interface Props {
  customStyles?: Record<string, unknown> | string;
}

const LoadingOverlay: React.FC<Props> = ({ customStyles }) => (
  <div className={cn(styles.loadingOverlay, customStyles)}>
    <img src={logo} alt="Logo spinner" className={styles.logoSpinner} />
  </div>
);

export default LoadingOverlay;
