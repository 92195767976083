import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Flex, Spacer, Title } from "components";
import styles from "./styles.module.scss";
import Lottie from "react-lottie";

export type LottieAnimation =
  | "searching"
  | "genealogy"
  | "tags"
  | "locations"
  | "users"
  | "medicine"
  | "regulations"
  | "lock"
  | "handshake";

type BaseProps = {
  animation?: LottieAnimation;
  title: string;
  children?: React.ReactNode;
  buttonCaption?: string;
  buttonCaptionTwo?: string;
  onClickButton?: () => void;
  onClickButtonTwo?: () => void;
};

type WithoutImage = {
  image?: never;
  imageAriaLabel?: never;
};

type WithImage = {
  image: string;
  imageAriaLabel: string;
};

type Props = BaseProps & (WithoutImage | WithImage);

export const EmptyStates: React.FC<Props> = ({
  animation,
  image,
  imageAriaLabel,
  title,
  children,
  buttonCaption,
  buttonCaptionTwo,
  onClickButton,
  onClickButtonTwo,
}) => {
  const [animationData, setAnimationData] = useState(null);
  const [dims, setDims] = useState({ width: 200, height: 100 });

  useEffect(() => {
    const loadAnimationData = async () => {
      try {
        const importedAnimationData = await import(`assets/lottieAnimations/${animation}.json`);
        setAnimationData(importedAnimationData);

        switch (animation) {
          case "searching":
          case "tags":
          case "genealogy":
            setDims({ width: 120, height: 100 });
            break;
          case "users":
            setDims({ width: 120, height: 120 });
            break;
          case "medicine":
            setDims({ width: 160, height: 120 });
            break;
          case "regulations":
            setDims({ width: 180, height: 120 });
            break;
          case "handshake":
            setDims({ width: 150, height: 100 });
            break;
          case "lock":
            setDims({ width: 250, height: 100 });
            break;
        }
      } catch (error) {
        console.error("Error importing animation:", error);
      }
    };

    loadAnimationData();
  }, [animation]);

  let defaultOptions;

  if (animationData) {
    defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  }

  return (
    <Flex className={styles.empty_states}>
      <Spacer baselineHeight={2} />
      {defaultOptions ? <Lottie width={dims.width} height={dims.height} options={defaultOptions} /> : null}
      {image ? <img src={image} alt={imageAriaLabel} style={{ margin: "0 auto" }} /> : null}
      <Title tertiary level={3}>
        {title}
      </Title>
      {children ? children : null}
      {buttonCaption && !buttonCaptionTwo ? (
        <Button caption={buttonCaption} onClick={onClickButton} />
      ) : buttonCaption && buttonCaptionTwo ? (
        <ButtonGroup>
          <Button caption={buttonCaption} colour="grey" variant="hollow" onClick={onClickButton} />
          <Button caption={buttonCaptionTwo} colour="grey" variant="hollow" onClick={onClickButtonTwo} />
        </ButtonGroup>
      ) : null}
      <Spacer baselineHeight={4} />
    </Flex>
  );
};
