import { FC } from "react";
import { getLiveStockSubTabs } from "helpers/myLivestock";
import { useStateSpecies, useLivestockTabs } from "hooks";
import { AnimalList as AnimalsListOld } from "components/MyLivestock";
import { AnimalsList } from "animals/AnimalsList";
import { Header, PageHeader, PageContent, Spacer, Tabs } from "components";
import { EmptyStatesMyCattleTab } from "components/EmptyStates/MyCattleTab";
import { TABLE_IDS } from "constants/Interface";
import { useShouldUseServerPaginatedAnimalsTable } from "hooks/useShouldUseServerPaginatedAnimalsTable";

export const Animals: FC = () => {
  const { activeSpecies } = useStateSpecies();
  const LIVESTOCK_TABS = useLivestockTabs();
  const speciesActiveName = activeSpecies?.name || "Livestock";
  const title = `My ${speciesActiveName}`;
  const subTitle = `View and manage your ${speciesActiveName.toLowerCase()}`;

  const { shouldUseServerPaginatedAnimalsTable } = useShouldUseServerPaginatedAnimalsTable();

  return (
    <>
      <PageHeader>
        <Header title={title} subTitle={subTitle} />
        <Tabs tabs={LIVESTOCK_TABS} selectedTab={0} />
        <Tabs tabs={getLiveStockSubTabs()} selectedTab={0} grayBackgroundTabs />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={1} />
        {shouldUseServerPaginatedAnimalsTable ? (
          <AnimalsList
            tableFilterIdentifier="animalsList"
            fromPage="livestock"
            showSelectColumn={true}
            showAnimalActionButtons={true}
            noResults={<EmptyStatesMyCattleTab />}
            tableId={TABLE_IDS["ANIMAL_LIST_SERVER"]}
          />
        ) : (
          <AnimalsListOld noResults={<EmptyStatesMyCattleTab />} />
        )}
      </PageContent>
    </>
  );
};
