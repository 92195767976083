import { EventName } from "./events";
export { EventName } from "./events";
import ReactGA from "react-ga4";

export const logAnalyticsEvent = async (eventName: EventName, params?: object | undefined) => {
  logEventForGA(eventName, params);
  logEventForPendo(eventName, params);
};

const logEventForPendo = async (event: EventName, params?: object | undefined) => {
  // Session must of been started for this to be successful
  // @ts-ignore
  pendo.track(event, params);
};

const logEventForGA = async (eventName: EventName, params?: object | undefined) => {
  await ReactGA.event(eventName.replace(/-/g, "_"), params);
};
