import LoadingOverlay from "components/Common/LoadingOverlay";
import { Table } from "components/Common/Table";
import { Flex } from "components/Structure/Flex";
import { TABLE_IDS } from "constants/Interface";
import {
  DeliveriesQueryVariables,
  DeliveryDirectionEnum,
  DeliveryFragment,
  DeliverySortingEnum,
  DeliveryStatusEnum,
  useDeliveriesLazyQuery,
} from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { getBusinessUnitIdFromLocalStorage } from "helpers/storage";
import { useStateSpecies } from "hooks/useStateSpecies";
import { useCallback, useEffect, useMemo } from "react";
import { DeliveryStatusLabel } from "../DeliveryStatusLabel";
import { useDeliveriesLabels } from "deliveries/hooks/useDeliveriesLabels";
import { Column } from "react-table";
import { useHistory } from "react-router-dom";
import { CREATE_MOVE_ON, CREATE_MOVE_OFF, DELIVERIES } from "constants/Routes";
import { EmptyStatesMovementsPage } from "components/EmptyStates/Movements";
import { getLoadingStateRows } from "utils/misc";
import { usePagination } from "twComponents/PaginationControl/helpers";
import { PaginationControl } from "twComponents/PaginationControl";

interface Props {
  direction: DeliveryDirectionEnum;
  status: DeliveryStatusEnum;
}

export const DeliveriesTable = ({ direction, status }: Props) => {
  const businessUnitId = getBusinessUnitIdFromLocalStorage();
  const { activeSpecies } = useStateSpecies();
  const history = useHistory();

  const { getDeliveryNameLabel, getLocationsLabel, getDeliveryDateLabel } = useDeliveriesLabels();

  const [getDeliveries, { data, loading }] = useDeliveriesLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const numberOfDeliveries = data?.deliveries?.count ?? 0;

  const {
    paginationQueryVariables: { first, before, after, last },
    ...paginationProps
  } = usePagination({
    totalNumberOfItems: numberOfDeliveries,
    pageInfo: data?.deliveries?.pageInfo,
    loading,
    tableId: TABLE_IDS.DELIVERIES_TABLE,
  });

  const {
    paginationInfo: { pageSize },
  } = paginationProps;

  useEffect(() => {
    const variables: DeliveriesQueryVariables = {
      businessUnit: businessUnitId,
      last,
      before,
      first,
      after,
      orderBy: [DeliverySortingEnum.UpdatedAtDesc],
      status,
      direction,
    };

    getDeliveries({ variables });
  }, [after, before, businessUnitId, direction, first, getDeliveries, last, status]);

  const deliveries = useMemo(
    () =>
      removeNothings(
        loading
          ? getLoadingStateRows<DeliveryFragment>(pageSize, () => ({
              id: "",
              name: "—",
              animalCount: 0,
              reason: { id: "", title: "" },
              sourceSummary: { locations: [] },
              destinationSummary: { locations: [] },
              sentAt: "—",
              status: DeliveryStatusEnum.Draft,
              createdAt: "",
              updatedAt: "",
              direction: DeliveryDirectionEnum.Outward,
            }))
          : data?.deliveries?.edges.map((deliveryEdge) => {
              return deliveryEdge?.node;
            }) || [],
      ),
    [data?.deliveries?.edges, loading, pageSize],
  );

  const onCellClick = useCallback(
    (delivery: DeliveryFragment) => {
      if (status !== DeliveryStatusEnum.Draft) {
        history.push(`${DELIVERIES}/${delivery?.id}`, { prevUrl: location.pathname });
      } else {
        history.push(
          `${DELIVERIES}${direction === DeliveryDirectionEnum.Inward ? CREATE_MOVE_ON : CREATE_MOVE_OFF}/${
            delivery?.id
          }`,
          { prevUrl: location.pathname },
        );
      }
    },
    [direction, history, status],
  );

  const columns: Column<DeliveryFragment>[] = useMemo(
    () => [
      {
        id: "name",
        Header: "name",
        accessor: (row) => {
          return loading ? "—" : getDeliveryNameLabel(row);
        },
        minWidth: 150,
        width: 150,
        disableSortBy: true,
        onCellClick,
      },
      {
        id: "animalCount",
        Header: "no. of livestock",
        accessor: "animalCount",
        disableSortBy: true,
        onCellClick,
      },
      {
        id: "reason",
        Header: "reason",
        accessor: ({ reason }) => reason?.title || "—",
        disableSortBy: true,
        onCellClick,
      },
      {
        id: "destination",
        Header: "destination",
        accessor: (delivery) => {
          return getLocationsLabel(delivery);
        },
        disableSortBy: true,
        onCellClick,
      },
      {
        id: "source",
        Header: "sourced from",
        accessor: (delivery) => {
          return getLocationsLabel(delivery);
        },
        disableSortBy: true,
        onCellClick,
      },
      {
        id: "dateMoved",
        Header: `date moved ${direction === DeliveryDirectionEnum.Inward ? "on" : "off"}`,
        accessor: (delivery) => getDeliveryDateLabel(delivery),
        disableSortBy: true,
        onCellClick,
      },
      {
        id: "status",
        Header: "status",
        accessor: "status",
        disableSortBy: true,
        onCellClick,
        Cell: function DeliveryStatus({ row }) {
          const isMoveOn = row.original.direction === DeliveryDirectionEnum.Inward;

          return (
            <DeliveryStatusLabel
              isDeliveryLocationSet={
                isMoveOn
                  ? (row.original.sourceSummary.locations || []).length > 0
                  : (row.original.destinationSummary.locations || []).length > 0
              }
              movedAt={isMoveOn ? row.original.arrivedAt : row.original.sentAt}
              reasonId={row.original.reason?.id}
              animalCount={row.original.animalCount}
            />
          );
        },
      },
    ],
    [direction, getDeliveryDateLabel, getDeliveryNameLabel, getLocationsLabel, loading, onCellClick],
  );

  const columnVisibilityRules = {
    ["destination"]: direction === DeliveryDirectionEnum.Outward,
    ["source"]: direction === DeliveryDirectionEnum.Inward,
    ["status"]: status === DeliveryStatusEnum.Draft,
  };

  const hiddenColumns = Object.keys(columnVisibilityRules).filter((key) => {
    return !columnVisibilityRules[key];
  });

  if (!activeSpecies) {
    return <LoadingOverlay />;
  }

  return (
    <Flex container item>
      {deliveries.length === 0 ? (
        <EmptyStatesMovementsPage direction={direction} isDraft={status === DeliveryStatusEnum.Draft} />
      ) : (
        <>
          <div className="mb-2 flex flex-1 justify-end">
            <PaginationControl paginationProps={paginationProps} />
          </div>

          {loading ? <LoadingOverlay /> : null}
          <Table<DeliveryFragment>
            loading={loading}
            columns={columns}
            data={deliveries}
            tableId={TABLE_IDS.DELIVERIES_TABLE}
            pagination
            initialPageSize={100}
            hiddenColumns={hiddenColumns}
          />
        </>
      )}
    </Flex>
  );
};
