// React
import React from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./Page.module.scss";

interface PageProps {
  menu?: React.ReactNode;
  children?: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({ menu, children }) => (
  <div
    className={cn(styles.page, {
      [styles["page--has_menu"]]: menu,
    })}
  >
    {menu}

    <div className={styles.page__inner}>{children}</div>
  </div>
);
Page.displayName = "Page";
