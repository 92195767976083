import { ChangeEvent, useState } from "react";
// Utils
import { useGetDistributionLists } from "hooks";
// Components
import { InputSelectMultipleHTMLTarget, InputSelectOption } from "components/Common/Field/Select";

interface UseSelectDistributionListParams {
  preselected?: string[];
}

interface UseSelectDistributionListsReturn {
  handleSelect: (event: ChangeEvent<InputSelectMultipleHTMLTarget>) => void;
  options?: InputSelectOption[];
  selected?: string[];
}

export const useSelectDistributionLists = ({
  preselected,
}: UseSelectDistributionListParams): UseSelectDistributionListsReturn => {
  const [selected, setSelected] = useState<string[]>(preselected || []);

  const { data } = useGetDistributionLists();
  const options = data?.distributionLists?.edges?.reduce((distributionListOptions, distributionList) => {
    const distributionListNode = distributionList?.node;

    if (distributionListNode) {
      distributionListOptions.push({
        key: distributionListNode.id,
        value: distributionListNode.id,
        label: distributionListNode.name,
      });
    }

    return distributionListOptions;
  }, [] as InputSelectOption[]);

  const handleSelect = (event: ChangeEvent<InputSelectMultipleHTMLTarget>): void => setSelected(event.target.value);

  return {
    handleSelect,
    options: options && options.length > 0 ? options : undefined,
    selected,
  };
};
