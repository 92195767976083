import { VFC } from "react";
// Libraries
import cn from "classnames";
// Types
import { IconProps } from "components/Common/Icon";
// Components
import { Icon } from "components/Common/Icon";
// Resources
import styles from "./Pill.module.scss";

enum PillWidths {
  Auto = "auto",
  Full = "full",
}

export enum PillColours {
  Blue = "blue",
  Green = "green",
  Grey = "grey",
  Navy = "navy",
  Red = "red",
  Yellow = "yellow",
  Cattle = "cattle",
  Sheep = "sheep",
  Pigs = "pigs",
}

export interface PillProps {
  caption: string;
  className?: string;
  colour?: `${PillColours}`;
  colourInverted?: boolean;
  icon?: IconProps["name"];
  role?: string;
  title?: string;
  width?: `${PillWidths}`;
  wrap?: boolean;
  onClick?: () => void;
}

export const Pill: VFC<PillProps> = ({
  caption,
  className,
  colour = "grey",
  colourInverted,
  icon,
  role,
  title,
  width = "auto",
  wrap,
  onClick,
}) => {
  return (
    <span
      onClick={onClick}
      className={cn(
        styles.pill,
        onClick ? styles.pill_button : "",
        {
          [styles[`pill--colour__${colour}`]]: true,
          [styles["pill--colour_inverted"]]: colourInverted,
          [styles[`pill--width__${width}`]]: true,
          [styles["pill--nowrap"]]: !wrap,
        },
        className,
      )}
      title={title}
      role={role}
    >
      {icon ? <Icon name={icon} size="tiny" /> : null}

      <span className={styles.pill__caption}>{caption}</span>
    </span>
  );
};
