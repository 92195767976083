import React, { useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { SIGN_UP as SIGN_UP_PAGE, SIGN_UP_SUCCESS, SIGN_UP_FAILURE } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { CountriesCodes } from "hooks/useGetCurrentBusinessUnit";
import { SIGN_UP } from "api/Auth/mutations";
import { SignUpInput as SignUpInputType } from "api/graphql-global-types";
import { SignUp, SignUpVariables } from "api/Auth/types/SignUp";
import { cphNumber } from "helpers/regex";
import { CommonContext, SetTempSignUpData } from "config/commonProvider";
import { SignUpInputProps } from "components/Auth/SignUp/SignUpInput";
import { SignUpInput } from "components/Auth";
import { Button } from "components";

import styles from "./styles.module.scss";
import { useLocale } from "helpers/translations/src/useLocale";
import { capitaliseFirstLetter } from "helpers/translations/src";

interface FormValues {
  farmName: string;
  holdingId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postcode: string;
  country: string;
  phone: string;
  howDidYouHearAboutBreedr: string;
  isOther: boolean;
  howDidYouHearAboutBreedrOther: string;
  isReferral: boolean;
  referralCode: string;
  businessSpecialization: string;
  animalsCount: string;
  countryCode: string;
}

export type FormProps = FormikProps<FormValues>;

type GetFieldProps = Pick<SignUpInputProps, "name" | "onChange" | "onBlur" | "error" | "value"> &
  Pick<FormProps, "setFieldValue">;

enum FieldNames {
  farmName,
  holdingId,
  addressLine1,
  addressLine2,
  city,
  postcode,
  country,
  phone,
  howDidYouHearAboutBreedr,
  howDidYouHearAboutBreedrOther,
  referralCode,
  businessSpecialization,
  animalsCount,
  countryCode,
}

type Field = keyof typeof FieldNames;

export const FarmDetailsForm: React.FC = () => {
  const {
    mainCommonState: { tempSignUpData },
    setTempSignUpData,
  } = useContext(CommonContext);
  const history = useHistory();
  const [signUp, { loading }] = useMutation<SignUp, SignUpVariables>(SIGN_UP);
  const { terms } = useLocale();

  const validationIfRequired = Yup.string().trim().required(" ");

  const signupSchema = Yup.object().shape({
    farmName: Yup.string().trim().max(60, " ").required(" "),
    holdingId: Yup.string().when("countryCode", {
      is: CountriesCodes.GB,
      then: validationIfRequired.matches(cphNumber, " "),
      otherwise: validationIfRequired,
    }),
    addressLine1: Yup.string().trim().max(300, " ").required(" "),
    addressLine2: Yup.string().trim().max(300, " "),
    city: Yup.string().trim().max(100, " ").required(" "),
    postcode: Yup.string().trim().max(20, " ").required(" "),
    country: Yup.string().trim().required(" "),
    phone: Yup.string()
      .trim()
      .matches(/^[0-9]*$/, "Field should contain only digits")
      .max(30, " ")
      .required(" "),
    howDidYouHearAboutBreedr: Yup.string().trim().max(50, " ").required(" "),
    howDidYouHearAboutBreedrOther: Yup.string().when("isOther", {
      is: true,
      then: Yup.string().trim().required(" "),
    }),
    referralCode: Yup.string().when("isReferral", {
      is: true,
      then: Yup.string().trim().required(" "),
    }),
    businessSpecialization: Yup.string().trim().required(" "),
    animalsCount: Yup.string().trim().required(" "),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!tempSignUpData) {
      history.push(SIGN_UP_PAGE);
    }
  }, [history, tempSignUpData]);

  const handleFormSubmit = async (values: FormValues): Promise<void> => {
    const {
      country,
      isOther,
      howDidYouHearAboutBreedr,
      howDidYouHearAboutBreedrOther,
      businessSpecialization,
      isReferral,
      referralCode,
      ...rest
    } = values;

    const dataToSend: SignUpInputType = {
      ...(tempSignUpData as SetTempSignUpData),
      country: +country,
      howDidYouHearAboutBreedrOther: isOther ? howDidYouHearAboutBreedrOther : null,
      howDidYouHearAboutBreedr: +howDidYouHearAboutBreedr,
      referralCode: isReferral ? referralCode : null,
      businessSpecialization: businessSpecialization.split(","),
      ...rest,
    };

    // @ts-ignore
    delete dataToSend.countryCode;

    try {
      const { data } = await signUp({
        variables: {
          input: dataToSend,
        },
      });

      const isSuccess = Boolean(data?.signUp?.success);

      if (isSuccess) {
        setTempSignUpData({
          ...(tempSignUpData as SetTempSignUpData),
          cphNumber: values.holdingId,
        });

        history.push(SIGN_UP_SUCCESS);
      } else {
        history.push({
          pathname: SIGN_UP_FAILURE,
          state: { message: data?.signUp?.errors?.[0]?.message },
        });
      }
    } catch (error) {
      history.push(SIGN_UP_FAILURE);
    }
  };

  const initialValues = {
    farmName: "",
    holdingId: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    country: "",
    phone: "",
    howDidYouHearAboutBreedr: "",
    isOther: false,
    howDidYouHearAboutBreedrOther: "",
    businessSpecialization: "",
    isReferral: false,
    referralCode: "",
    animalsCount: "",
    countryCode: "",
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={signupSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, handleChange, setFieldValue, handleBlur, handleSubmit }: FormProps): JSX.Element => {
        const getFieldProps = (fieldName: Field): GetFieldProps => {
          return {
            name: fieldName,
            onChange: handleChange,
            onBlur: handleBlur,
            error: errors[fieldName],
            value: values[fieldName],
            setFieldValue,
          };
        };

        return (
          <form onSubmit={handleSubmit} className={styles.form} autoComplete="off">
            <h2 className={styles.title}>Step 2 of 2</h2>
            <p className={styles.description}>Last step! Just a few more details to create your account.</p>
            <SignUpInput
              label={`${capitaliseFirstLetter(terms.farm)} Name *`}
              {...getFieldProps("farmName")}
              maxLength={60}
            />
            <SignUpInput label="Country *" {...getFieldProps("country")} type="country" />
            <SignUpInput
              label="County Parish Holding (CPH) Number *"
              {...getFieldProps("holdingId")}
              maxLength={50}
              type={values.countryCode.toLowerCase() === CountriesCodes.GB ? "cph" : "text"}
            />
            <SignUpInput label="Address Line 1 *" {...getFieldProps("addressLine1")} maxLength={300} />
            <SignUpInput label="Address Line 2" {...getFieldProps("addressLine2")} maxLength={300} />
            <SignUpInput label="City *" {...getFieldProps("city")} maxLength={100} />
            <SignUpInput label="Postcode *" {...getFieldProps("postcode")} maxLength={20} />
            <SignUpInput label="Phone number *" {...getFieldProps("phone")} maxLength={30} />
            <SignUpInput
              label={`What type of ${terms.farm} do you have? *`}
              {...getFieldProps("businessSpecialization")}
              type="businessSpecialization"
            />
            <SignUpInput
              label={`How many beef cows do you typically have on your ${terms.farm}? *`}
              {...getFieldProps("animalsCount")}
              type="animalsCount"
            />
            <SignUpInput
              label="How did you hear about Breedr? *"
              {...getFieldProps("howDidYouHearAboutBreedr")}
              maxLength={50}
              type="howDidYouHearAboutBreedr"
            />
            {values.isOther ? (
              <SignUpInput
                label=""
                {...getFieldProps("howDidYouHearAboutBreedrOther")}
                maxLength={30}
                type="howDidYouHearAboutBreedrOther"
              />
            ) : null}
            {values.isReferral ? (
              <SignUpInput label="Referral code *" {...getFieldProps("referralCode")} type="referralCode" />
            ) : null}

            <div className={styles.buttonWrapper}>
              <Button
                id={ELEMENTS_IDS.FARM_DETAILS_FORM_CREATE_ACCOUNT_BUTTON}
                caption="Create account"
                requesting={loading}
                disabled={loading}
                type="submit"
                className={styles.submitButton}
                height="medium"
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
