// React
import React, { useContext, useMemo } from "react";
// Libraries
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, FormikProps } from "formik";
// Utils
import {
  GetBusinessUnitUsersVariables,
  GetBusinessUnitUsers,
  GetBusinessUnitUsers_businessUnitUsers,
} from "api/BusinessUnits/types/GetBusinessUnitUsers";
import {
  GetPreferredBusinessUnitVariables,
  GetPreferredBusinessUnit,
} from "api/BusinessUnits/types/GetPreferredBusinessUnit";
import { UPDATE_PREFERRED_BUSINESS_UNIT } from "api/BusinessUnits/mutations";
import {
  UpdatePreferredBusinessUnitVariables,
  UpdatePreferredBusinessUnit,
} from "api/BusinessUnits/types/UpdatePreferredBusinessUnit";
import { GET_BUSINESS_UNIT_USERS, GET_PREFERRED_BUSINESS_UNIT } from "api/BusinessUnits/queries";
import { CommonContext } from "config/commonProvider";
import { SUPPLIER_MANAGEMENT } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { positiveNumberWithZero } from "helpers/regex";
import { getBUFromLocalStorage } from "helpers/storage";
import { getCurrencySubUnitName, handleErrorCheck } from "helpers/general";
import { useBreakpoints, useGetCurrentBusinessUnit } from "hooks";
// Components
import { Button, Flex, FieldSelect, FieldText, Spacer } from "components";
import { SUPPLIER_TYPES } from "components/Settings/SupplierManagement/PreferredSuppliersTabs";

interface FormValues {
  supplierName: string;
  fieldsman: string;
  priceAdjustment: string;
}

export type FormProps = FormikProps<FormValues>;

const TEST_IDS = {
  FIELDSMAN_SELECT: "fieldsman-select",
  SUPPLIER_NAME_INPUT: "supplier-name",
  BASE_PRICE_INPUT: "base-price",
  CANCEL_BUTTON: "cancel-button",
};

interface EditSupplierFormTestIds {
  TEST_IDS: typeof TEST_IDS;
}

const EditSupplierForm: React.FC & EditSupplierFormTestIds = () => {
  const { supplierId } = useParams<SettingsSupplierEditPageParams>();
  const history = useHistory();
  const { showNotification } = useContext(CommonContext);
  const { currencyCodeISO, weightUnits } = useGetCurrentBusinessUnit();
  const currencySubUnitName = getCurrencySubUnitName(currencyCodeISO);
  const currentBusinessUnitId: number = getBUFromLocalStorage();

  const { data } = useQuery<GetBusinessUnitUsers, GetBusinessUnitUsersVariables>(GET_BUSINESS_UNIT_USERS, {
    variables: {
      businessUnitId: currentBusinessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const { data: preferredBuData } = useQuery<GetPreferredBusinessUnit, GetPreferredBusinessUnitVariables>(
    GET_PREFERRED_BUSINESS_UNIT,
    {
      variables: {
        businessUnit: currentBusinessUnitId,
        id: Number(supplierId),
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    },
  );

  const { preferredBusinessUnit = null } = { ...preferredBuData };
  const { id = null, supplier = null, fieldsman = null, priceAdjustment = null } = { ...preferredBusinessUnit };
  const supplierName: string = supplier?.name || "";
  const fieldsmanId: string | undefined = `${fieldsman?.id}`;

  const { businessUnitUsers } = { ...data };

  const fieldsmanOptions: Array<{
    key: string;
    value: string;
    label: string;
  }> = useMemo(
    () =>
      businessUnitUsers
        ? (businessUnitUsers as GetBusinessUnitUsers_businessUnitUsers[])
            .filter((item) => item.status === "ACTIVE")
            .map((item) => ({
              key: item.user.id,
              value: item.user.id,
              label: `${item.user.firstName} ${item.user.lastName} (${item.user.email})`,
            }))
        : [],
    [businessUnitUsers],
  );

  const [updatePreferredBuMutation, { loading: updateLoading }] = useMutation<
    UpdatePreferredBusinessUnit,
    UpdatePreferredBusinessUnitVariables
  >(UPDATE_PREFERRED_BUSINESS_UNIT);

  const initialValues: FormValues = {
    supplierName: supplierName,
    fieldsman: fieldsmanId?.toString() || "",
    priceAdjustment: priceAdjustment ? `${priceAdjustment}` : "",
  };

  const goToSupplierManagement = (): void => {
    history.push(`${SUPPLIER_MANAGEMENT}/${SUPPLIER_TYPES.SELLERS}`);
  };

  const handleFormSubmit = async (values: FormValues): Promise<void> => {
    const { fieldsman, priceAdjustment } = values;

    try {
      const { data } = await updatePreferredBuMutation({
        variables: {
          input: {
            id: Number(id),
            businessUnit: currentBusinessUnitId,
            ...(fieldsman ? { fieldsman: Number(fieldsman) } : {}),
            ...(priceAdjustment ? { priceAdjustment } : {}),
          },
        },
      });

      if (data?.updatePreferredBusinessUnit?.errors) {
        showNotification({
          variant: "error",
          message: "Error updating supplier",
        });
      } else {
        showNotification({
          message: "Supplier successfully updated",
        });
        goToSupplierManagement();
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error updating supplier",
      });
    }
  };

  const handleCancelClick = (): void => {
    goToSupplierManagement();
  };

  const { isBreakpointL } = useBreakpoints();

  return (
    <Formik enableReinitialize onSubmit={handleFormSubmit} initialValues={initialValues}>
      {({ values, errors, handleChange, handleSubmit }: FormProps): JSX.Element => {
        return (
          <form onSubmit={handleSubmit}>
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldText
                  label="Supplier"
                  error={handleErrorCheck(errors.supplierName)}
                  inputProps={{
                    name: "supplierName",
                    readOnly: true,
                    disabled: true,
                    value: values.supplierName,
                    onChange: handleChange,
                    "data-testid": TEST_IDS.SUPPLIER_NAME_INPUT,
                  }}
                />
                {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                <FieldSelect
                  label="Fieldsman"
                  error={handleErrorCheck(errors.fieldsman)}
                  inputProps={{
                    name: "fieldsman",
                    placeholder: "Select Fieldsman",
                    isClearable: true,
                    value: values.fieldsman,
                    options: fieldsmanOptions,
                    onChange: handleChange,
                    "data-testid": `${TEST_IDS.FIELDSMAN_SELECT}-${fieldsmanOptions.length}`,
                  }}
                />
                {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                <FieldText
                  label={`Base price adjustment (${currencySubUnitName}/${weightUnits})`}
                  helper="Enter a base price adjustment that will be applied to this suppliers trades"
                  error={handleErrorCheck(errors.priceAdjustment)}
                  inputProps={{
                    name: "priceAdjustment",
                    value: values.priceAdjustment,
                    onChange: handleChange,
                    validation: positiveNumberWithZero,
                    "data-testid": TEST_IDS.BASE_PRICE_INPUT,
                  }}
                />
              </Flex>
            </Flex>
            <Spacer baselineHeight={!isBreakpointL ? 3 : 2} />
            <Flex container>
              <Flex item itemGutter>
                <Button
                  id={ELEMENTS_IDS.EDIT_SUPPLIER_FORM_CANCEL_BUTTON}
                  colour="grey"
                  variant="hollow"
                  caption="Cancel"
                  onClick={handleCancelClick}
                  data-testid={TEST_IDS.CANCEL_BUTTON}
                />
              </Flex>
              <Flex item itemGutter>
                <Button
                  id={ELEMENTS_IDS.EDIT_SUPPLIER_FORM_SAVE_BUTTON}
                  disabled={updateLoading}
                  requesting={updateLoading}
                  caption="Save changes"
                  type="submit"
                />
              </Flex>
            </Flex>
          </form>
        );
      }}
    </Formik>
  );
};

EditSupplierForm.TEST_IDS = TEST_IDS;

export default EditSupplierForm;
