import { Title } from "components";
// @ts-expect-error
import { IntercomAPI } from "react-intercom";
import { Spacer, Text, Alert, TextAnchor } from "components";
import { Button } from "twComponents/Button";
import { getBUFromLocalStorage } from "helpers/storage";
import { SubscriptionTierId, SubscriptionTierIdUS, SubscriptionTiers } from "../SubscriptionTiers";
import { getErrorMessage } from "helpers/general";

import {
  CheckoutPurposeEnumType,
  useCreateStripeCheckoutSessionMutation,
  useCreateStripePortalSessionMutation,
  useSubscriptionStatusQuery,
} from "generated/graphql";
import { useGetBusinessUnitUserRole, useGetCurrentBusinessUnit } from "hooks";
import { useState } from "react";
import { PAYMENT_SUCCESS } from "constants/Routes";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { useHubSpot } from "hooks/useHubSpot";

export const SubscribeToPro = ({ showInvoiceHistoryButton }: { showInvoiceHistoryButton: boolean }) => {
  const businessUnitId = getBUFromLocalStorage();
  const { isAdmin } = useGetBusinessUnitUserRole();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [createCheckoutSession] = useCreateStripeCheckoutSessionMutation();
  const { countryCode } = useGetCurrentBusinessUnit();
  const isUS = countryCode.toUpperCase() === CountryISOCode.US;
  const { isHubSpotChatEnabled, openHubSpotChat } = useHubSpot();

  const { data: subscriptionStatusData } = useSubscriptionStatusQuery({
    variables: { businessUnitId },
    fetchPolicy: "cache-and-network",
  });
  const [createStripePortalSession] = useCreateStripePortalSessionMutation();

  const activationTokenId = subscriptionStatusData?.subscriptionStatus?.activationTokens[0]?.id || undefined;

  // NOTE: Indicating whether the user might have a payment history in which case they should be able to access it.
  const hasStripeState = subscriptionStatusData?.subscriptionStatus?.hasStripeState || false;

  const handleOpenChat = (): void => {
    if (isHubSpotChatEnabled) {
      openHubSpotChat("?chat=true");
    } else {
      IntercomAPI("showNewMessage", "I have a question regarding my subscription?\n\n");
    }
  };

  const handleOpenStripe = async () => {
    try {
      if (!isAdmin) {
        setErrorMessage("You must be an admin to view subscription details.");
        return;
      }

      const { data } = await createStripePortalSession({
        variables: {
          input: {
            businessUnitId,
            returnUrl: window.location.href,
          },
        },
      });

      if (data && data?.createStripePortalSession && document.location.href) {
        document.location.href = data?.createStripePortalSession.sessionUrl;
      }
    } catch (error: unknown) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  const handleUpgrade = async (tierId?: SubscriptionTierId | SubscriptionTierIdUS) => {
    if (!isAdmin) {
      setErrorMessage("You must be an admin to upgrade your subscription.");
      return;
    }

    try {
      // INFO: New subscriptions requires a token and purpose Activate
      // in order to get a free trial.
      if (activationTokenId) {
        const { data: checkoutSessionData } = await createCheckoutSession({
          variables: {
            input: {
              activationTokenId,
              businessUnitId,
              cancelUrl: window.location.href,
              returnUrl: `${window.location.origin}${PAYMENT_SUCCESS}`,
              purpose: CheckoutPurposeEnumType.Activate,
              catalogOption: tierId || "tier1",
            },
          },
        });

        if (checkoutSessionData && checkoutSessionData?.createStripeCheckoutSession && window.location.href) {
          document.location.href = checkoutSessionData?.createStripeCheckoutSession.sessionUrl;
        }
      } else {
        // INFO: Re-subscribe. They won't get a free trial.
        const { data: checkoutSessionData } = await createCheckoutSession({
          variables: {
            input: {
              businessUnitId,
              cancelUrl: window.location.href,
              returnUrl: `${window.location.origin}${PAYMENT_SUCCESS}`,
              purpose: CheckoutPurposeEnumType.Pro,
              catalogOption: tierId || "tier1",
            },
          },
        });

        if (checkoutSessionData && checkoutSessionData?.createStripeCheckoutSession) {
          document.location.href = checkoutSessionData?.createStripeCheckoutSession.sessionUrl;
        }
      }
    } catch (error: unknown) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <div className="flex flex-row flex-wrap md:flex-nowrap">
      <div className="md:order-2 flex-1 md:w-2/4 px-2">
        <SubscriptionTiers onSubscribe={handleUpgrade} onContactUs={handleOpenChat} hasError={Boolean(errorMessage)} />
        {errorMessage ? <Alert text={errorMessage} color="RED" /> : null}
      </div>
      <div className="md:order-1 mt-4 md:mt-0 md:w-2/4 px-2">
        {isUS ? <USSubscriptionContent /> : <UKSubscriptionContent />}
        {isAdmin ? (
          <>
            <Button variant="outline" onClick={handleOpenChat}>
              Get in contact
            </Button>
            {hasStripeState && showInvoiceHistoryButton ? (
              <Button variant="ghost" onClick={handleOpenStripe} className="ml-4">
                View invoice history
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

const USSubscriptionContent = () => {
  return (
    <>
      <Title secondary>Use Breedr Today!</Title>
      <Spacer baselineHeight={2} />

      <Text>
        Subscribe to Breedr now and revolutionize your cattle management through comprehensive record-keeping that
        tracks and monitors the health, growth, and genetics of your cattle. Help optimize breeding decisions, improve
        animal health, and boost your bottom line. Join supply chains to unlock data about your animals and drive
        additional value for your herd. Some key features of Breedr include:
      </Text>
      <Spacer baselineHeight={2} />
      <Text>
        <strong>Chute Mode:</strong> Records data live during chute sessions like adding animals, live weight,
        administering meds and more.
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Breeding Tools:</strong> Track and manage reproductive health efficiently, including monitoring
        pregnancies, tracking AI events and success rates, and monitor and analyze breeding performance to facilitate
        informed decision making.
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Supply Chain Integration:</strong> Market direct to our network of feed yards and branded supply chains,
        use the Breedr app to prove the value of your stock with data and save money on transport and commission.
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Live Support:</strong> Personalized support with a team in Texas to help with questions and provide
        tailored training.
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Advanced Insights:</strong> Access advanced ranch reporting, performance benchmarking, monitor long-term
        health trends, and track growth.
      </Text>
      <Spacer baselineHeight={2} />
      <TextAnchor target="_blank" caption="Find out more" href="https://www.breedr.co/pricing" />
      <Spacer baselineHeight={2} />
    </>
  );
};

const UKSubscriptionContent = () => {
  return (
    <>
      <Title secondary>Upgrade To Pro</Title>
      <Spacer baselineHeight={2} />

      <Text>Subscribe to access our innovative livestock management app today!</Text>
      <Spacer baselineHeight={2} />
      <Text>Features include:</Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Multi-Species</strong> manage your sheep and cattle
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Quick Weighing</strong> weigh with ease in crush mode
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Complete Compliance</strong> manage movements and medicines
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Support</strong> 30 minute onboarding session and ongoing phone support
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Breeding tools</strong> maximise your herd&rsquo;s performance in the app
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        <strong>Advanced Insights</strong> make better decisions with our state of the art reporting tools
      </Text>
      <Spacer baselineHeight={2} />
      <Text>
        Individual animal analytics helps you discover key areas for improvement in DLWG, breeding and health. You can
        track progress in our easy to use app.
      </Text>
      <Spacer baselineHeight={1} />
      <Text>
        Breedr Pro is priced with you in mind, your subscription cost is based on the number of animals on your farm.
        Get 15% discount on a yearly subscription, email{" "}
        <a href="mailto: subscriptions@breedr.co">subscriptions@breedr.co</a>
      </Text>
      <Spacer baselineHeight={2} />
      <TextAnchor target="_blank" caption="Find out more" href="https://www.breedr.co/en/pricing" />
      <Spacer baselineHeight={2} />
    </>
  );
};
