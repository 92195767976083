import { FieldValues, Path, useFormContext } from "react-hook-form";
import { BaseInput, BaseInputProps } from "components/Common/Field/BaseInput";
import { Field } from "components";

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  label: string;
} & BaseInputProps;

export const FormInput = <TFieldValues extends FieldValues>({ name, label, ...rest }: Props<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  if (!methods) {
    throw new Error("FormInput must be used inside a <Form /> or <FormProvider />");
  }

  const errorMessage = methods?.formState.errors[name]?.message;

  return (
    <Field error={(errorMessage as string) || ""} label={label} labelFor={name}>
      <BaseInput
        type="text"
        hasError={!!errorMessage}
        id={name}
        {...(methods?.register && methods.register(name))}
        {...rest}
      />
    </Field>
  );
};
