import { createPortal } from "react-dom";
// Libraries
import DatePicker from "react-datepicker";
import cn from "classnames";
import pure from "recompose/pure";
// Components
import { Field, Input } from "components";
// Resources
import "react-datepicker/dist/react-datepicker.css";
import "./date.scss";
import inputStyles from "../Input/Input.module.scss";
import { useFormatDate } from "hooks";

export const InputDate = pure(
  ({ disabled, hasError = false, minDate, name, onChange, value, ...props }: InputDateProps) => {
    const { dateFormat } = useFormatDate();
    return (
      <Input
        forceDisabledForDate={disabled}
        hasError={hasError}
        icon={{
          labelFor: name,
          name: "calendar",
        }}
      >
        <DatePicker
          autoComplete="off"
          disabled={disabled}
          id={name}
          name={name}
          selected={value}
          minDate={minDate}
          onChange={onChange}
          onKeyDown={(event): void => event.preventDefault()}
          dateFormat={dateFormat}
          className={cn(inputStyles.input__native, inputStyles["input__native--text"])}
          // Despite warnings on ReactDOM docs the MaterialUI team use `document.body` for portals and have no bugs
          // Read more: https://github.com/mui-org/material-ui/issues/21626
          popperContainer={({ children }) => (children ? createPortal(children, document.body) : null)}
          {...props}
        />
      </Input>
    );
  },
);
InputDate.displayName = "InputDate";

export const FieldDate = pure(({ error, inputProps, disabled, ...props }: FieldDateProps) => (
  <Field error={error} labelFor={inputProps.name} {...props}>
    <InputDate hasError={!!error} disabled={disabled} {...inputProps} />
  </Field>
));
FieldDate.displayName = "FieldDate";
