import { FC, memo } from "react";
// Components
import { Flex, Text } from "components";

interface NoItemsFoundProps {
  title: string;
}

export const NoItemsFound: FC<NoItemsFoundProps> = memo(({ title }) => (
  <Flex container containerJustifyContent="center">
    <Text>{title}</Text>
  </Flex>
));
NoItemsFound.displayName = "NoItemsFound";
