import { ReactNode, VFC } from "react";
// Libraries
import cn from "classnames";
import SVG from "react-inlinesvg";
// Utils
import { ICON_COLOURS, ICON_NAMES, ICON_SIZES } from "constants/Icons";
// Resources
import styles from "./Icon.module.scss";

interface IconBase {
  ariaLabelledBy?: string;
  circled?: boolean;
  className?: string;
  colour?: keyof typeof ICON_COLOURS | string;
  colourOverride?: string;
  image?: ReactNode;
  rotate?: 0 | 90 | 180 | 270;
  size?: keyof typeof ICON_SIZES;
  title?: string;
}

interface IconByName extends IconBase {
  name: keyof typeof ICON_NAMES;
  src?: never;
}

interface IconBySource extends IconBase {
  name?: never;
  src: string;
}

export type IconProps = IconByName | IconBySource;

export const Icon: VFC<IconProps> = ({
  ariaLabelledBy,
  circled,
  className,
  colour,
  colourOverride,
  image,
  name,
  rotate = 0,
  size = "small",
  src,
  title,
  ...props
}) => {
  const svgSource = name ? ICON_NAMES[name] : src;

  const style = colourOverride ? { color: colourOverride } : undefined;

  return (
    <span
      aria-labelledby={ariaLabelledBy}
      className={cn(
        styles.icon,
        {
          [styles["icon--circled"]]: circled,
          [styles[`icon--colour__${colour || ""}`]]: colour,
          [styles[`icon--size__${size}`]]: true,
          [styles[`icon--rotate__${rotate}`]]: rotate,
        },
        className,
      )}
      role={ariaLabelledBy ? "img" : undefined}
      title={title}
      style={style}
      {...props}
    >
      {svgSource ? <SVG src={svgSource} className={cn(styles["test"])} /> : null}
      {!svgSource ? image : null}
    </span>
  );
};
