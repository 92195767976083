// Libraries
import React from "react";
// Components
import { Button, Flex, Spacer, Text, Title } from "components";

type Props = {
  closeModal: () => void;
  onNextStep: () => void;
};

export const FirstStep: React.FC<Props> = ({ closeModal, onNextStep }) => {
  return (
    <Flex container containerAlignContent="center" containerDirection="column">
      <Flex container containerAlignContent="center" containerDirection="column">
        <Flex item>
          <Title tertiary>STEP 1 - Request my CTS Online User ID and Password</Title>
          <Spacer baselineHeight={3} />
          <Text>Follow Steps 1 to 3 to set up your request.</Text>
          <Spacer baselineHeight={1} />
          <Text>On Step 3, you can submit your request directly via email.</Text>
          <Spacer baselineHeight={1} />
          <Text>Choose</Text> <strong>Next</strong> <Text>to proceed.</Text>
          <Spacer baselineHeight={1} />
        </Flex>
      </Flex>
      <Flex container xs={12} containerJustifyContent="center">
        <Spacer baselineHeight={3} />
        <Flex item itemGutter>
          <Button caption="Cancel" variant="hollow" colour="grey" onClick={closeModal} />
        </Flex>
        <Flex item itemGutter>
          <Button caption="Next" onClick={onNextStep} />
        </Flex>
      </Flex>
    </Flex>
  );
};
