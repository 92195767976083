// React
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
// Utils
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { handleErrorCheck } from "helpers/general";
import { getBUFromLocalStorage } from "helpers/storage";

import { TRANSFER_USER_OWNERSHIP } from "api/User/mutations";
import { TransferUserOwnership, TransferUserOwnershipVariables } from "api/User/types/TransferUserOwnership";
import { GetBusinessUnitUsers_businessUnitUsers } from "api/BusinessUnits/types/GetBusinessUnitUsers";

import { useGetBusinessUnitUsers, useGetCurrentUserInfo } from "hooks";
// Components
import { FieldSelect, Modal, Spacer, Text } from "components";

interface AssignOwnershipModalProps {
  showModal: boolean;
  onClose: () => void;
}

const getBusinessUnitUsersAdmins = (
  users: GetBusinessUnitUsers_businessUnitUsers[],
): Array<{
  key: string;
  value: string;
  label: string;
}> =>
  users
    .filter((user) => user.role.name === "Admin")
    .map((user) => ({
      key: user.id,
      value: user.id,
      label: user.user.email,
    }));

const validationSchemaTransferOwnership = Yup.object().shape({
  businessUnitUser: Yup.string().required("User field is required!"),
});

const AssignOwnershipModal: React.FC<AssignOwnershipModalProps> = ({ showModal, onClose }) => {
  const [profileUpdateError, changeProfileError] = useState<string>("");

  const { data: businessUnitUsersData, refetch: refetchBusinessUnitUsers } = useGetBusinessUnitUsers();
  const { refetch: refetchCurrentUserInfo } = useGetCurrentUserInfo();

  const businessUnitUsers = (businessUnitUsersData?.businessUnitUsers ||
    []) as GetBusinessUnitUsers_businessUnitUsers[];

  const adminUsers = getBusinessUnitUsersAdmins(businessUnitUsers);

  const [transferOwnershipMutation, { loading }] = useMutation<TransferUserOwnership, TransferUserOwnershipVariables>(
    TRANSFER_USER_OWNERSHIP,
  );

  const handleFormSubmit = async ({ businessUnitUser }): Promise<void> => {
    try {
      const { errors } = await transferOwnershipMutation({
        variables: {
          input: {
            businessUnitUser,
            businessUnit: getBUFromLocalStorage(),
          },
        },
      });

      if (errors && errors.length) {
        changeProfileError(errors[0].message);
      } else {
        changeProfileError("");
        refetchBusinessUnitUsers();
        refetchCurrentUserInfo();
        onClose();
      }
    } catch (e) {
      changeProfileError("Something went wrong! Please try again later.");
    }
  };

  const initialValues = {
    businessUnitUser: "",
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchemaTransferOwnership}
    >
      {({ values, errors, handleChange, handleSubmit }): JSX.Element => (
        <Modal
          actions={{
            primary: {
              id: ELEMENTS_IDS.ASSIGN_OWNERSHIP_MODAL_CONFIRM_BUTTON,
              disabled: loading,
              requesting: loading,
              caption: "Confirm",
              onClick: handleSubmit,
            },
            secondary: {
              id: ELEMENTS_IDS.ASSIGN_OWNERSHIP_MODAL_CANCEL_BUTTON,
              caption: "Cancel",
              colour: "grey",
              variant: "hollow",
              onClick: onClose,
            },
          }}
          active={showModal}
          handleClose={onClose}
          subTitle="Choose a user to transfer ownership to"
          title="Transfer ownership"
        >
          <FieldSelect
            error={handleErrorCheck(errors.businessUnitUser)}
            inputProps={{
              name: "businessUnitUser",
              value: values.businessUnitUser,
              options: adminUsers,
              onChange: handleChange,
            }}
          />

          {profileUpdateError ? (
            <>
              <Spacer baselineHeight={2} />

              <Text smallest warning>
                {profileUpdateError}
              </Text>
            </>
          ) : null}
        </Modal>
      )}
    </Formik>
  );
};

export default AssignOwnershipModal;
