import { Fragment, useEffect, useState, VFC } from "react";
// Containers
import { BuyerListingCard } from "./BuyerListingCard";
// Components
import { Button, Flex, Spacer, Spinner } from "components";
import { BuyingLotsQuery, useBuyingLotsLazyQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { useRecoilState } from "recoil";
import { liveweightSortServerSide } from "state";
import { liveweightFiltersServerSide } from "trading/state/liveweightFilters";
import { LiveweightFiltersState } from "trading/hooks/useListingFiltersServerSide/config";
import { EmptyStatesTradingBuying } from "components/EmptyStates/TradingBuying";
import { EmptyStatesNoResults } from "components/EmptyStates/NoResults";
export type BuyingLotNode = NonNullable<NonNullable<NonNullable<BuyingLotsQuery["buyingLots"]>["edges"][0]>["node"]>;

export const BuyerListings: VFC = () => {
  const numberToRetrieve = 10;
  const [sort] = useRecoilState(liveweightSortServerSide("buyer"));
  const [filters] = useRecoilState(liveweightFiltersServerSide("buyer"));
  const filteredFilters: Partial<LiveweightFiltersState> = Object.keys(filters)
    .filter((e) => filters[e] !== null)
    .reduce((o, e) => {
      o[e] = filters[e];
      return o;
    }, {});

  const [isInitialFetch, setIsInitialFetch] = useState(true);
  useEffect(() => {
    setIsInitialFetch(Object.keys(filteredFilters).length === 0);
  }, [filteredFilters]);

  const [buyingLots, { data, loading, fetchMore }] = useBuyingLotsLazyQuery({
    variables: {
      businessUnit: getBUFromLocalStorage(),
      first: numberToRetrieve,
      before: undefined,
      after: undefined,
      orderBy: sort,
      ...filteredFilters,
      status: filteredFilters.showExpired == true ? ["CREATED"] : undefined,
      // @ts-ignore
      animalType: filteredFilters.animalType == "All Species" ? null : filteredFilters.animalType,
      showExpired: filteredFilters.showExpired == true ? false : true,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    buyingLots();
  }, [buyingLots]);

  const listings: Array<BuyingLotNode> =
    data?.buyingLots?.edges?.map((edge) => edge?.node).filter((v): v is NonNullable<typeof v> => v != null) ?? [];

  const pageInfo = data?.buyingLots?.pageInfo;

  const revealMore = () => {
    const variables = {
      first: numberToRetrieve,
      after: pageInfo?.endCursor,
    };
    if (fetchMore) {
      fetchMore({
        variables,
      });
    }
  };

  if (loading) {
    return (
      <Flex container containerJustifyContent="center" containerAlignItems="center">
        <Spinner />
      </Flex>
    );
  }

  if (listings.length === 0) {
    return (
      <Flex container containerJustifyContent="center">
        {isInitialFetch ? <EmptyStatesTradingBuying /> : <EmptyStatesNoResults />}
      </Flex>
    );
  }

  return (
    <>
      {listings?.map((listing) => {
        if (!listing) return;

        return (
          <Fragment key={`listing_${listing.id}`}>
            <BuyerListingCard listing={listing} />

            <Spacer baselineHeight={1} />
          </Fragment>
        );
      })}
      {pageInfo?.hasNextPage ? (
        <>
          <Spacer baselineHeight={2} />
          <Flex container containerJustifyContent="center">
            <Button caption={"Load more"} onClick={revealMore} />
          </Flex>
        </>
      ) : null}
    </>
  );
};
