import { CommonContext } from "config/commonProvider";
import { ValidationError } from "generated/graphql";
import { getErrorMessage } from "helpers/general/all";
import { useContext } from "react";

export const useHandleMutationResult = () => {
  const { showNotification } = useContext(CommonContext);

  const handleMutationResult = ({
    errors,
    successMessage,
    onSuccess,
    onError,
  }: {
    errors: Pick<ValidationError, "message" | "field">[];
    successMessage: string;
    onSuccess?: () => void;
    onError?: () => void;
  }) => {
    if (errors.length > 0) {
      showNotification({
        message: errors.map((item) => item?.message).join("; "),
        variant: "error",
      });

      onError && onError();
    } else {
      showNotification({
        message: successMessage,
      });

      onSuccess && onSuccess();
    }
  };

  const handleError = (error: unknown, onCatchError?: () => void) => {
    showNotification({
      variant: "error",
      message: getErrorMessage(error),
    });

    onCatchError && onCatchError();
  };

  return { handleMutationResult, handleError, showNotification };
};
