import { Text, Button, FieldDate, FieldSelect, FieldText, LoadingOverlay, Modal, Spacer, Title } from "components";
import { DeliveryStatusLabel } from "deliveries/components/DeliveryStatusLabel";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { calculateMinimumValidSentAtDate, getMoveOffDeliveryValidationSchema } from "./validation";
import { DateTime } from "luxon";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { getBusinessUnitIdFromLocalStorage } from "helpers/storage";
import { useDeliveryPartners } from "deliveries/hooks/useDeliveryPartners";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import {
  AnimalFragment,
  ContactFragmentFragment,
  AnimalStatus,
  DeliveryDirectionEnum,
  UpdateDeliveryMutationVariables,
  useAddAnimalsToDeliveryMutation,
  useCommitDeliveryMutation,
  useCreateDeliveryMutation,
  useDeleteDeliveryMutation,
  useDraftDeliveryAnimalsQuery,
  useDraftDeliveryQuery,
  useRemoveAnimalsFromDeliveryMutation,
  useUpdateDeliveryMutation,
} from "generated/graphql";
import styles from "./MoveOffDeliveryForm.module.scss";
import { AnimalsList } from "animals/AnimalsList";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { TABLE_IDS } from "constants/Interface";
import { useHistory } from "react-router-dom";
import { CommonContext } from "config/commonProvider";
import { getErrorMessage, removeNothings } from "helpers/general";
import { useHasFeature } from "hooks/useHasFeature";
import { useLocale } from "helpers/translations/src/useLocale";
import { useDeliveryReasons } from "deliveries/hooks/useDeliveryReasons";
import { useStateSpecies } from "hooks/useStateSpecies";
import { AddressBookSelect } from "components/Settings/AddressBook/AddressBookSelect";
import { ContactForm } from "components/ContactForm";
import { EmptyStatesNoAnimalsAdded } from "components/EmptyStates/Movements/NoAnimals";
import { useDeliveriesLabels } from "deliveries/hooks/useDeliveriesLabels";
import { Button as TwButton } from "twComponents/Button";

type FormData = yup.InferType<ReturnType<typeof getMoveOffDeliveryValidationSchema>>;

const radioButtonClasses = { root: styles.root, checked: styles.checked };

const TABLE_ID = TABLE_IDS.CREATE_DELIVERY_ANIMALS_TABLE;

export const MoveOffDeliveryForm = ({ deliveryId: id }: { deliveryId?: string }) => {
  const [deliveryId, setDeliveryId] = useState<string | undefined>(id);
  const [showCreateContactModal, setShowCreateContactModal] = useState<boolean>(false);
  const { countryIsoCode } = useGetCurrentBusinessUnit();
  const upperCaseCountryIsoCode = countryIsoCode.toUpperCase();
  const businessUnitId = getBusinessUnitIdFromLocalStorage();
  const { partnerAddressOptions } = useDeliveryPartners();
  const { terms } = useLocale();

  const showNetworkOptions = useHasFeature("NETWORK_MOVEMENTS");

  const [destinationType, setDestinationType] = useState<"partner" | "contact">("contact");
  const [animalsTableType, setAnimalsTableType] = useState<"allAnimalsTable" | "deliveryAnimalsTable">(
    "deliveryAnimalsTable",
  );
  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);
  const tableOptions = getTableOptions<AnimalFragment>(TABLE_ID);
  const selectedRowIds = tableOptions?.selectedRowsIds;
  const [deleteDraftDelivery, { loading: deleteDraftDeliveryLoading }] = useDeleteDeliveryMutation();
  const [updateDraftDelivery] = useUpdateDeliveryMutation();
  const [createDraftDelivery] = useCreateDeliveryMutation();
  const [submitDeliveryLoading, setSubmitDeliveryLoading] = useState<boolean>(false);
  const [updateDraftDeliveryLoading, setUpdateDraftDeliveryLoading] = useState<boolean>(false);
  const [submitDelivery] = useCommitDeliveryMutation();
  const [addAnimalsToDelivery] = useAddAnimalsToDeliveryMutation();
  const [removeAnimalsFromDelivery] = useRemoveAnimalsFromDeliveryMutation();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const history = useHistory();
  const { showNotification } = useContext(CommonContext);
  const [isDirtyAnimalsList, setIsDirtyAnimalsList] = useState<boolean>(false);
  const [updatingDeliveryAnimalsLoading, setUpdatingDeliveryAnimalsLoading] = useState<boolean>(false);
  const [latestAddedAnimalPassportNumber, setLatestAddedAnimalPassportNumber] = useState<string | undefined>(undefined);
  const { activeSpecies, handleSetActiveSpecies, speciesList } = useStateSpecies();

  const { getDeliveryNameLabel } = useDeliveriesLabels();

  const maybeChangeSpecies = (animalTypeId: string | undefined) => {
    if (!animalTypeId) {
      return;
    }

    if (animalTypeId !== activeSpecies?.id) {
      const species = speciesList?.find((species) => species.id === animalTypeId);
      if (species) {
        handleSetActiveSpecies(species);
      }
    }
  };

  const { deliveryReasons } = useDeliveryReasons(DeliveryDirectionEnum.Outward);

  const { data: deliveryData } = useDraftDeliveryQuery({
    variables: { delivery: deliveryId || "", businessUnit: businessUnitId },
    skip: !deliveryId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const {
    data: animalsData,
    loading: animalsDataLoading,
    refetch: animalsDataRefetch,
  } = useDraftDeliveryAnimalsQuery({
    variables: { delivery: deliveryId || "", businessUnit: businessUnitId },
    skip: !deliveryId,
  });

  const deliveryAnimals = useMemo(
    () => animalsData?.delivery?.animals?.edges?.map((edge) => edge?.node) || [],
    [animalsData?.delivery?.animals?.edges],
  );

  const deliveryAnimalTypeId = animalsData?.delivery?.animals?.edges
    ? animalsData?.delivery?.animals?.edges[0]?.node?.animalType?.id
    : undefined;

  const animalIdsDelivery = useMemo(() => deliveryAnimals?.map((animal) => animal?.id) || [], [deliveryAnimals]);

  const validationSchema = useMemo(
    () => getMoveOffDeliveryValidationSchema(latestAddedAnimalPassportNumber, upperCaseCountryIsoCode, terms),
    [latestAddedAnimalPassportNumber, upperCaseCountryIsoCode, terms],
  );

  const delivery = deliveryData?.delivery?.delivery;

  const defaultFormValues: FormData = useMemo(() => {
    const defaultSentAt = delivery?.sentAt ? DateTime.fromISO(delivery.sentAt) : DateTime.local();

    return {
      minimumValidSentAtDate:
        calculateMinimumValidSentAtDate(removeNothings(deliveryAnimals) || [])?.minimumValidSentAtDate || undefined,
      sentAt: defaultSentAt.toJSDate(),
      name: getDeliveryNameLabel(deliveryData?.delivery?.delivery),
      animalCount: deliveryAnimals?.length || 0,
      reasonId: delivery?.reason?.id || "",
      destinationPartnerId: delivery?.defaultDestination?.partner?.id || "",
      destinationPartnerAddressId: delivery?.defaultDestination?.address?.id || "",
      destinationContactId: delivery?.defaultDestination?.contact?.id || "",
      isDestinationIdSet: false,
    };
  }, [
    delivery?.defaultDestination?.address?.id,
    delivery?.defaultDestination?.contact?.id,
    delivery?.defaultDestination?.partner?.id,
    delivery?.reason?.id,
    delivery?.sentAt,
    deliveryAnimals,
    deliveryData?.delivery?.delivery,
    getDeliveryNameLabel,
  ]);

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const animals = removeNothings(deliveryAnimals || []);
    const { minimumValidSentAtDate, passportNumber } = calculateMinimumValidSentAtDate(animals);

    methods.setValue("minimumValidSentAtDate", minimumValidSentAtDate);
    setLatestAddedAnimalPassportNumber(passportNumber || undefined);
  }, [deliveryAnimals, methods]);

  useEffect(() => {
    if (!deliveryId && isDirtyAnimalsList) {
      try {
        const createDelivery = async () => {
          const result = await createDraftDelivery({
            variables: {
              input: {
                businessUnit: businessUnitId,
                name: methods.watch("name"),
                direction: DeliveryDirectionEnum.Outward,
                sentAt: methods.watch("sentAt"),
                reason: methods.watch("reasonId") || undefined,
                destinationContact: methods.watch("destinationContactId") || undefined,
                destinationPartner: methods.watch("destinationPartnerId") || undefined,
                destinationPartnerAddress: methods.watch("destinationPartnerAddressId") || undefined,
              },
            },
          });
          const errors = result?.data?.createDelivery?.errors || [];

          if (errors.length > 0) {
            showNotification({
              variant: "error",
              message: errors.map((item) => item?.message).join("; "),
            });
          } else {
            setDeliveryId(result?.data?.createDelivery?.delivery?.id);
          }
        };

        createDelivery();
      } catch (error: unknown) {
        showNotification({
          variant: "error",
          message: getErrorMessage(error),
        });
      }
    }
  }, [businessUnitId, createDraftDelivery, deliveryId, isDirtyAnimalsList, methods, showNotification]);

  useEffect(() => {
    if (deliveryData?.delivery?.delivery.id) {
      setTableOptions({ id: TABLE_ID, selectedRowsIds: new Set(animalIdsDelivery) });

      setDestinationType(deliveryData.delivery.delivery?.defaultDestination?.partner?.id ? "partner" : "contact");

      methods.reset(defaultFormValues);
    }
  }, [
    methods,
    setTableOptions,
    defaultFormValues,
    animalIdsDelivery,
    deliveryData?.delivery?.delivery.id,
    deliveryData?.delivery?.delivery?.defaultDestination?.partner?.id,
  ]);

  const { handleSubmit, control } = methods;

  const onSubmit = async () => {
    try {
      setSubmitDeliveryLoading(true);
      if (deliveryId) {
        const variables: UpdateDeliveryMutationVariables = {
          input: {
            businessUnit: businessUnitId,
            delivery: deliveryId,
            sentAt: methods.watch("sentAt"),
            reason: methods.watch("reasonId") || undefined,
            name: methods.watch("name"),
            destinationContact: methods.watch("destinationContactId") || undefined,
            destinationPartner: methods.watch("destinationPartnerId") || undefined,
            destinationPartnerAddress: methods.watch("destinationPartnerAddressId") || undefined,
          },
        };

        const updateDeliveryResult = await updateDraftDelivery({
          variables,
        });

        const updateDeliveryErrors = updateDeliveryResult?.data?.updateDelivery?.errors || [];

        if (updateDeliveryErrors.length > 0) {
          showNotification({
            variant: "error",
            message: updateDeliveryErrors.map((item) => item?.message).join("; "),
          });
          setSubmitDeliveryLoading(false);
          return;
        }

        const result = await submitDelivery({
          variables: {
            input: {
              delivery: deliveryId,
              businessUnit: businessUnitId,
            },
          },
        });

        const errors = result?.data?.commitDelivery?.errors || [];

        if (errors.length > 0) {
          setSubmitDeliveryLoading(false);
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; ") || "Failed to submit movement",
          });
        } else {
          setSubmitDeliveryLoading(false);
          history.goBack();
          showNotification({
            message: "Movement successfully submitted.",
          });
        }
      } else {
        setSubmitDeliveryLoading(false);
        showNotification({
          variant: "error",
          message: "Error: Missing movement ID.",
        });
      }
    } catch (error: unknown) {
      setSubmitDeliveryLoading(false);
      showNotification({
        variant: "error",
        message: getErrorMessage(error) ?? "Failed to submit movement.",
      });
    }
  };

  const onDeleteDelivery = async () => {
    try {
      if (deliveryId) {
        const result = await deleteDraftDelivery({
          variables: { input: { businessUnit: businessUnitId, delivery: deliveryId } },
        });

        const errors = result?.data?.deleteDelivery?.errors || [];

        if (errors.length > 0) {
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; "),
          });
          return;
        }
      }

      history.goBack();

      showNotification({
        message: "Draft movement successfully deleted.",
      });
    } catch (error: unknown) {
      showNotification({
        variant: "error",
        message: getErrorMessage(error) || "Something went wrong",
      });
    }
  };

  const onSaveDraft = async () => {
    try {
      if (methods.watch("name") === "") {
        methods.setValue("name", "", { shouldTouch: true, shouldValidate: true });
        return;
      }

      setUpdateDraftDeliveryLoading(true);
      if (deliveryId) {
        const variables: UpdateDeliveryMutationVariables = {
          input: {
            businessUnit: businessUnitId,
            delivery: deliveryId,
            sentAt: methods.watch("sentAt"),
            reason: methods.watch("reasonId") || undefined,
            name: methods.watch("name"),
            destinationContact: methods.watch("destinationContactId") || undefined,
            destinationPartner: methods.watch("destinationPartnerId") || undefined,
            destinationPartnerAddress: methods.watch("destinationPartnerAddressId") || undefined,
          },
        };

        const result = await updateDraftDelivery({
          variables,
        });

        const errors = result?.data?.updateDelivery?.errors || [];

        if (errors.length > 0) {
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; "),
          });
          setUpdateDraftDeliveryLoading(false);
          return;
        }
      } else {
        const result = await createDraftDelivery({
          variables: {
            input: {
              businessUnit: businessUnitId,
              name: methods.watch("name"),
              direction: DeliveryDirectionEnum.Outward,
              sentAt: methods.watch("sentAt"),
              reason: methods.watch("reasonId") || undefined,
              destinationContact: methods.watch("destinationContactId") || undefined,
              destinationPartner: methods.watch("destinationPartnerId") || undefined,
              destinationPartnerAddress: methods.watch("destinationPartnerAddressId") || undefined,
            },
          },
        });
        const errors = result?.data?.createDelivery?.errors || [];

        if (errors.length > 0) {
          showNotification({
            variant: "error",
            message: errors.map((item) => item?.message).join("; "),
          });
        }
      }

      setUpdateDraftDeliveryLoading(false);
      history.goBack();
      showNotification({
        message: "Draft movement successfully saved.",
      });
    } catch (error: unknown) {
      setUpdateDraftDeliveryLoading(false);
      showNotification({
        variant: "error",
        message: getErrorMessage(error) || "Failed to save draft.",
      });
    }
  };

  const onRemoveAnimalsFromDelivery = async (selectedAnimalIds: string[]) => {
    try {
      if (!deliveryId) {
        return { success: false, message: "Delivery ID is missing." };
      }

      const animalIdsOfAnimalsToRemove = animalIdsDelivery.filter((id) => !selectedAnimalIds.includes(id));

      if (animalIdsOfAnimalsToRemove.length > 0) {
        const result = await removeAnimalsFromDelivery({
          variables: {
            input: {
              businessUnit: businessUnitId,
              delivery: deliveryId,
              animals: animalIdsOfAnimalsToRemove,
            },
          },
        });

        const errors = result?.data?.deleteDeliveryAnimals?.errors || [];

        if (errors.length > 0) {
          return { success: false, message: errors.map((item) => item?.message).join("; ") };
        } else {
          return { success: true };
        }
      }
      return { success: true };
    } catch (error: unknown) {
      return { success: false, message: getErrorMessage(error) || "Failed to remove animals from movement." };
    }
  };

  const onAddAnimalsToDelivery = async (selectedAnimalIds: string[]) => {
    try {
      if (!deliveryId) {
        return { success: false, message: "Delivery ID is missing." };
      }

      const animalIdsOfAnimalsToAdd = selectedAnimalIds.filter((id) => !animalIdsDelivery.includes(id));

      if (deliveryId && animalIdsOfAnimalsToAdd.length > 0) {
        const result = await addAnimalsToDelivery({
          variables: {
            input: {
              businessUnit: businessUnitId,
              delivery: deliveryId,
              animals: animalIdsOfAnimalsToAdd,
            },
          },
        });

        const errors = result?.data?.deliverAnimalsOut?.errors || [];

        if (errors.length > 0) {
          return { success: false, message: errors.map((item) => item?.message).join("; ") };
        } else {
          return { success: true };
        }
      }
      return { success: true };
    } catch (error: unknown) {
      return { success: false, message: getErrorMessage(error) || "Failed to add animals to delivery." };
    }
  };

  const onUpdateAnimalsOnDelivery = async () => {
    setAnimalsTableType("deliveryAnimalsTable");
    try {
      setUpdatingDeliveryAnimalsLoading(true);
      const selectedAnimalIds = selectedRowIds && selectedRowIds?.size > 0 ? Array.from(selectedRowIds) : [];

      const removeAnimalsResult = await onRemoveAnimalsFromDelivery(selectedAnimalIds);

      if (!removeAnimalsResult.success) {
        showNotification({
          variant: "error",
          message: removeAnimalsResult?.message || "Failed to update animals",
        });

        setUpdatingDeliveryAnimalsLoading(false);
        return;
      }

      const addAnimalsResult = await onAddAnimalsToDelivery(selectedAnimalIds);

      if (!addAnimalsResult.success) {
        showNotification({
          variant: "error",
          message: addAnimalsResult?.message || "Failed to update animals on delivery",
        });
        setUpdatingDeliveryAnimalsLoading(false);
        return;
      }

      animalsDataRefetch();

      showNotification({
        variant: "success",
        message: "Successfully updated animals on movement",
      });
      setUpdatingDeliveryAnimalsLoading(false);
    } catch (error: unknown) {
      setUpdatingDeliveryAnimalsLoading(false);
      showNotification({
        variant: "error",
        message: getErrorMessage(error) || "Failed to update animals on movement",
      });
    }
  };

  return (
    <div className="max-w-[100%]">
      <Modal
        active={showDeleteConfirmationModal}
        title="Delete Draft"
        subTitle="Are you sure you want to delete this draft?"
        actions={{
          primary: {
            caption: "Confirm",
            disabled: deleteDraftDeliveryLoading,
            requesting: deleteDraftDeliveryLoading,
            onClick: onDeleteDelivery,
          },
          secondary: {
            caption: "Cancel",
            onClick: () => setShowDeleteConfirmationModal(false),
          },
        }}
      />
      <Modal active={showCreateContactModal} title="Create New Contact">
        <ContactForm
          contactId={undefined}
          onCancel={() => setShowCreateContactModal(false)}
          onConfirm={() => setShowCreateContactModal(false)}
          isModal
        />
      </Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center px-3">
          <DeliveryStatusLabel
            movedAt={methods.watch("sentAt").toISOString()}
            reasonId={methods.watch("reasonId")}
            isDeliveryLocationSet={Boolean(
              methods.watch("destinationContactId") || methods.watch("destinationPartnerId"),
            )}
            animalCount={methods.watch("animalCount") || 0}
          />
          <div>
            <Button
              caption="Delete draft"
              type="button"
              variant="ghost"
              className="ml-5 text-red-500 max-w-[90px]"
              onClick={() => setShowDeleteConfirmationModal(true)}
              disabled={updateDraftDeliveryLoading || submitDeliveryLoading || deleteDraftDeliveryLoading}
            />
            <Button
              caption="Cancel"
              type="button"
              variant="hollow"
              colour="grey"
              className="ml-5 min-w-[90px]"
              onClick={() => {
                history.goBack();
              }}
              disabled={updateDraftDeliveryLoading || submitDeliveryLoading || deleteDraftDeliveryLoading}
            />
            <Button
              caption="Save draft"
              type="button"
              colour="green"
              className="ml-5 min-w-[90px]"
              onClick={onSaveDraft}
              requesting={updateDraftDeliveryLoading}
              disabled={updateDraftDeliveryLoading || deleteDraftDeliveryLoading || submitDeliveryLoading}
            />
            <Button
              caption="Submit"
              type="submit"
              className="ml-5 min-w-[90px]"
              disabled={updateDraftDeliveryLoading || deleteDraftDeliveryLoading || submitDeliveryLoading}
              requesting={submitDeliveryLoading}
            />
          </div>
        </div>
        <Spacer baselineHeight={2} />
        <div className="grid gap-6 grid-cols-1 px-3 md:grid-cols-2 xl:grid-cols-[1fr_1fr_1fr_2fr]">
          <Controller
            control={control}
            name="name"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldText
                  label="Name *"
                  error={errors.name?.message}
                  inputProps={{
                    name: "name",
                    placeholder: "Name",
                    value: value || "",
                    onChange,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="sentAt"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldDate
                  label="Date moved off *"
                  error={errors.sentAt?.message}
                  inputProps={{
                    name: "deliveryDate",
                    value: value,
                    onChange,
                  }}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="reasonId"
            render={({ formState: { errors }, field: { value, onChange } }) => {
              return (
                <FieldSelect
                  label="Reason *"
                  error={errors.reasonId?.message}
                  inputProps={{
                    placeholder: "Reason",
                    value,
                    options: deliveryReasons,
                    onChange,
                    isClearable: true,
                  }}
                />
              );
            }}
          />
          <div>
            <div className="pt-[8px] mb-[-8px]">
              <label>Destination *</label>
            </div>
            <div className="flex">
              {showNetworkOptions && partnerAddressOptions.length > 0 ? (
                <RadioGroup
                  className="flex-nowrap align-baseline max-h-14"
                  aria-label="Destination Type"
                  name="destinationType"
                  value={destinationType}
                  row
                >
                  <FormControlLabel
                    value={"contact"}
                    control={
                      <Radio
                        classes={radioButtonClasses}
                        onChange={() => {
                          setDestinationType("contact");
                          methods.setValue("destinationContactId", "");
                          methods.setValue("destinationPartnerId", "");
                          methods.setValue("destinationPartnerAddressId", "");
                        }}
                      />
                    }
                    label="Contact"
                  />
                  <FormControlLabel
                    value={"partner"}
                    control={
                      <Radio
                        classes={radioButtonClasses}
                        onChange={() => {
                          setDestinationType("partner");
                          methods.setValue("destinationContactId", "");
                          methods.setValue("destinationPartnerId", "");
                          methods.setValue("destinationPartnerAddressId", "");
                        }}
                      />
                    }
                    label="Network"
                  />
                </RadioGroup>
              ) : null}
              <>
                <Controller
                  control={control}
                  name="destinationPartnerAddressId"
                  render={({ formState: { errors }, field: { value, onChange } }) => {
                    return (
                      <FieldSelect
                        error={errors.isDestinationIdSet?.message}
                        className={`${destinationType == "contact" ? "hidden" : "block"}`}
                        inputProps={{
                          placeholder: "My Network",
                          value,
                          options: partnerAddressOptions,
                          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                            const partnerId = event.target.value.split(":")[0];
                            onChange(event);
                            methods.setValue("destinationPartnerId", partnerId);
                            if (event.target.value) {
                              methods.trigger("isDestinationIdSet");
                            }
                          },
                          isClearable: true,
                        }}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="destinationContactId"
                  render={({ formState: { errors }, field: { value } }) => {
                    return (
                      <AddressBookSelect
                        errorMessage={errors.isDestinationIdSet?.message}
                        value={value || ""}
                        className={`${destinationType == "contact" ? "block" : "hidden"}`}
                        onCreateNewContact={() => {
                          methods.setValue("destinationContactId", "");
                          methods.setValue("destinationPartnerId", "");
                          methods.setValue("destinationPartnerAddressId", "");
                          setShowCreateContactModal(true);
                        }}
                        onContactChange={(contact: ContactFragmentFragment | undefined) => {
                          methods.setValue("destinationContactId", contact?.id);
                          if (contact?.id) {
                            methods.trigger("isDestinationIdSet");
                          }
                        }}
                      />
                    );
                  }}
                />
              </>
            </div>
          </div>
        </div>
      </form>
      <Spacer baselineHeight={2} />
      {animalsTableType === "allAnimalsTable" ? (
        <>
          <div className="mx-[10px]">
            <Title secondary>Add animals to movement ({selectedRowIds?.size ?? 0} selected)</Title>
            <Spacer baselineHeight={2} />
            <Text>
              The movement will only display animals that have been selected. To exclude animals from the movement,
              simply unselect them and remember to save any changes you make.
            </Text>
            <Spacer baselineHeight={2} />
            <div>
              <TwButton
                type="button"
                variant="outline"
                onClick={() => {
                  setAnimalsTableType("deliveryAnimalsTable");
                  setTableOptions({
                    id: TABLE_ID,
                    selectedRowsIds: new Set(animalIdsDelivery),
                  });
                }}
              >
                Cancel
              </TwButton>
              <TwButton type="button" onClick={onUpdateAnimalsOnDelivery} className="mx-2">
                Save and update animals
              </TwButton>
            </div>
          </div>
          {methods.formState.errors.animalCount?.message ? (
            <div>
              <Spacer baselineHeight={2} />
              <p className={styles.animalsValidationErrorMessage}>{methods.formState?.errors?.animalCount?.message}</p>
            </div>
          ) : null}
          <AnimalsList
            tableId={TABLE_ID}
            showSelectColumn
            tableFilterIdentifier="animalsList"
            showAnimalActionButtons={false}
            showIndividualAnimalActionButtons={false}
            rowStyles={(row) => {
              return row.original.dateLeftFarm != null || row.original.deadAt != null ? { opacity: 0.5 } : {};
            }}
          />
        </>
      ) : (
        <>
          <div className="mx-[10px]">
            <Title secondary>Animals in this movement ({deliveryAnimals?.length ?? 0})</Title>
            {!animalIdsDelivery || animalIdsDelivery.length == 0 ? (
              <>
                <Spacer baselineHeight={2} />
                <Text>You don’t have any animals in this movement, tap the ‘Add animals’ button below to start.</Text>
                <Spacer baselineHeight={2} />
              </>
            ) : null}
            <div>
              {deliveryAnimals.length > 0 ? <Spacer baselineHeight={2} /> : null}
              <Button
                caption={deliveryAnimals.length > 0 ? "Add / Remove animals" : "Add animals"}
                type="button"
                variant={deliveryAnimals.length > 0 ? "hollow" : "solid"}
                colour={deliveryAnimals.length > 0 ? "grey" : "yellow"}
                onClick={() => {
                  maybeChangeSpecies(deliveryAnimalTypeId);
                  setIsDirtyAnimalsList(true);
                  setAnimalsTableType("allAnimalsTable");
                  setTableOptions({
                    id: TABLE_ID,
                    selectedRowsIds: new Set(animalIdsDelivery),
                  });
                }}
                disabled={
                  updateDraftDeliveryLoading ||
                  animalsDataLoading ||
                  deleteDraftDeliveryLoading ||
                  submitDeliveryLoading ||
                  updateDraftDeliveryLoading
                }
              />
            </div>
          </div>
          <div>
            {updatingDeliveryAnimalsLoading || animalsDataLoading ? <LoadingOverlay /> : null}
            <p className="text-status-warning text-xs px-[10px] py-2">
              {methods.formState?.errors?.animalCount?.message}
            </p>
            {animalIdsDelivery.length > 0 ? (
              <AnimalsList
                tableId={TABLE_ID}
                animalIds={animalIdsDelivery}
                showSelectColumn={false}
                tableFilterIdentifier="animalsList"
                showAnimalActionButtons={false}
                showIndividualAnimalActionButtons={false}
                rowStyles={(row) => {
                  return row.original.dateLeftFarm != null || row.original.deadAt ? { opacity: 0.5 } : {};
                }}
                animalStatus={AnimalStatus.Any}
                resetSelectedAnimalsOnUnmount={false}
                animalTypeIds={[2, 3]} // Get both sheep and cattle
              />
            ) : (
              <EmptyStatesNoAnimalsAdded />
            )}
          </div>
        </>
      )}
    </div>
  );
};
