import { Country, useCountriesQuery } from "generated/graphql";
import { removeNothings } from "helpers/general";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { sortBy } from "lodash";

export const useCountries = () => {
  const { data: countriesData } = useCountriesQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const INCLUDED_COUNTRY_NAMES_SIGNUP = [
    "Northern Ireland",
    "England",
    "Wales",
    "Scotland",
    "Australia",
    "United States",
    "Canada",
    "Mexico",
    "Israel",
    "Norway",
    "Italy",
    "France",
    "New Zealand",
  ];

  const countries = removeNothings(
    sortBy(
      (countriesData?.countries || []).filter((country) => INCLUDED_COUNTRY_NAMES_SIGNUP.includes(country?.name || "")),

      [(country) => country?.name],
    ) || [],
  );

  const getCountryIdToIsoAlpha2Map = () => {
    type CountryId = string;
    type IsoAlpha2 = string | undefined | null;

    const countryIdToIsoAlpha2Map = new Map<CountryId, IsoAlpha2>();

    countries.forEach((country) => {
      countryIdToIsoAlpha2Map.set(country.id, country.isoAlpha2);
    });

    return countryIdToIsoAlpha2Map;
  };

  const getCountryOptions = ({
    buCountryIsoCode,
    contactIsoCode,
  }: {
    contactIsoCode: string | undefined;
    buCountryIsoCode: string;
  }) => {
    const relevantCountries = countries.filter((country) =>
      filterToRelevantCountries({
        country: country,
        contactIsoCode: contactIsoCode,
        buCountryIsoCode,
      }),
    );
    return relevantCountries.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
  };

  const filterToRelevantCountries = ({
    country,
    contactIsoCode,
    buCountryIsoCode,
  }: {
    country: Pick<Country, "id" | "name" | "code" | "isoAlpha2"> | undefined;
    contactIsoCode: string | undefined;
    buCountryIsoCode: string;
  }) => {
    const isoAlpha2 = country?.isoAlpha2?.toUpperCase();

    const isCountryInAmerica =
      isoAlpha2 === CountryISOCode.US || isoAlpha2 === CountryISOCode.CA || isoAlpha2 === CountryISOCode.MX;

    switch (buCountryIsoCode?.toUpperCase()) {
      case CountryISOCode.GB:
        const isCountryUK = isoAlpha2 === CountryISOCode.GB;
        const isContactInUK = isoAlpha2 === contactIsoCode;
        return isCountryUK || isContactInUK;

      case CountryISOCode.US:
      case CountryISOCode.CA:
      case CountryISOCode.MX:
        return isCountryInAmerica || isoAlpha2 === contactIsoCode;

      case CountryISOCode.AU:
      case CountryISOCode.NZ:
        const isCountryAU = isoAlpha2 === CountryISOCode.AU;
        const isCountryNZ = isoAlpha2 === CountryISOCode.NZ;
        const isContactInAUorNZ = isoAlpha2 === contactIsoCode;
        return isCountryAU || isCountryNZ || isContactInAUorNZ;

      default:
        return true;
    }
  };

  return { getCountryIdToIsoAlpha2Map, countries, countriesData, getCountryOptions };
};
