import { useHistory } from "react-router-dom";
import { CUSTOM_IMPORT, IMPORT, SUPPORTED_SYSTEM_IMPORT } from "constants/Routes";
import { useGetSupportedSystems, useHasFeature } from "hooks";
import { Flex, Icon, Paper, Spacer, Text, Title } from "components";
import { ImportSystem } from "./ImportSystem";
import styles from "./ImportType.module.scss";
import { WizardOptions } from "import/components/WizardOptions";
import LogoAngus from "assets/img/Epds/logo-Angus.png";
import LogoNeogen from "assets/img/Epds/logo-Neogen.png";
import { FeedSystemsUploadButton } from "import/components/FeedSystemsUploadButton";

export const ImportTypes = () => {
  const history = useHistory();

  const handleClickCustom = () => history.push(`${IMPORT}${CUSTOM_IMPORT}`);
  const handleClickSystems = () => history.push(`${IMPORT}${SUPPORTED_SYSTEM_IMPORT}`);

  const useGenetics = useHasFeature("GENETICS");

  const { data } = useGetSupportedSystems();

  const breedingAssociationSystems = [
    {
      id: "1",
      name: "American Angus",
      logo: LogoAngus,
    },
    {
      id: "2",
      name: "Neogen",
      logo: LogoNeogen,
    },
  ];

  const integrationSystems = useGenetics ? breedingAssociationSystems : data;

  return (
    <Flex item itemGutter>
      <Paper className={styles.import_types}>
        <Spacer baselineHeight={5} />

        <Flex container containerJustifyContent="center">
          <Title tertiary>How do you want to upload?</Title>

          <Spacer baselineHeight={1} />

          {useGenetics ? (
            <Text smaller>Choose either Custom Import or one of our supported partner imports</Text>
          ) : (
            <Text smaller>We support Custom Import or supported systems</Text>
          )}
        </Flex>

        <Spacer baselineHeight={4} />

        <WizardOptions>
          <WizardOptions.Option name="Custom Import (.csv file)" onClick={handleClickCustom}>
            <Icon circled colour="grey100" name="fileCsv" size="large" />
          </WizardOptions.Option>

          {!!integrationSystems.length ? (
            <WizardOptions.Option
              name={useGenetics ? "Genetic Partners" : "Supported systems"}
              onClick={handleClickSystems}
            >
              <div className="flex flex-col flex-wrap justify-center px-2 md:flex-row">
                {integrationSystems.map((integrationSystem) => (
                  <div className="m-1" key={integrationSystem.id}>
                    {integrationSystem.logo ? (
                      <ImportSystem>
                        <img alt={`${integrationSystem.name} logo`} src={integrationSystem.logo} />
                      </ImportSystem>
                    ) : (
                      <ImportSystem name={integrationSystem.name} />
                    )}
                  </div>
                ))}
              </div>
            </WizardOptions.Option>
          ) : null}
          <FeedSystemsUploadButton />
        </WizardOptions>

        <Spacer baselineHeight={5} />
      </Paper>
    </Flex>
  );
};
