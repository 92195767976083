import React from "react";
import cn from "classnames";
import { ICON_NAMES } from "constants/Icons";
import { Icon, Text } from "components";
import styles from "./Input.module.scss";

interface InputSharedProps {
  forceDisabledForDate?: boolean;
  hasError?: boolean;
  children?: React.ReactNode;
}

interface InputWithIconProps extends InputSharedProps {
  adornment?: never;
  adornmentLeft?: never;
  icon?: {
    labelFor: string;
    name: keyof typeof ICON_NAMES;
  };
}

type InputProps = InputWithIconProps | (InputWithAdornment & InputSharedProps);

export const Input: React.FC<InputProps> = ({
  children,
  forceDisabledForDate,
  hasError,
  icon,
  adornment,
  adornmentLeft,
}) => (
  <span
    className={cn(styles.input, {
      [styles["input--error"]]: hasError,
      [styles["input--has_icon"]]: icon,
      "input--force_disabled_for_date": forceDisabledForDate, // not css-modules because using it in non-`.module.scss`
    })}
  >
    {icon ? (
      <label className={styles.input__icon} htmlFor={icon.labelFor}>
        <Icon colour="blue" name={icon.name} size="tiny" />
      </label>
    ) : null}
    {adornmentLeft ? (
      <label className={cn(styles.input__adornment, styles.input__adornment__left)} htmlFor={adornmentLeft.labelFor}>
        <Text isEllipsis smallest>
          {adornmentLeft.label}
        </Text>
      </label>
    ) : null}

    {children}

    {adornment ? (
      <label className={cn(styles.input__adornment)} htmlFor={adornment.labelFor}>
        <Text isEllipsis smallest>
          {adornment.label}
        </Text>
      </label>
    ) : null}

    <span className={styles.input__background} />
  </span>
);
Input.displayName = "Input";
