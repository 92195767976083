import { FC } from "react";
// @ts-ignore
import { IntercomAPI } from "react-intercom";
// Utils
import { TABLE_IDS } from "constants/Interface";
import { LOT_STATUSES } from "trading/constants";
import { getCurrencyCode, getFormattedPrice } from "helpers/general";
import { getBUFromLocalStorage } from "helpers/storage";
import { getListingBreedsString, getListingAddressPublic } from "trading/util";
// Types
import { LotTbRequirements } from "trading/components/containers/liveweight/CreateEditListingForm/types";
import { CarouselImages } from "components/Common/Carousel";
// Containers
import { BuyerListingDelivery } from "./BuyerListingDelivery";
import { BuyerListingOffer } from "./BuyerListingOffer";
import { BuyerListingPayment } from "./BuyerListingPayment";
// Components
import { AnimalsTable } from "components/MyLivestock";
import {
  Carousel,
  Button,
  ButtonGroup,
  Flex,
  Image,
  Paper,
  Spacer,
  TablePaginationTarget,
  Text,
  Title,
} from "components";
// Resources
import styles from "./BuyerListing.module.scss";
import tablePaginationStyles from "components/Common/Table/TablePagination/TablePagination.module.scss";
import { BuyingLotQuery, OfferStatus } from "generated/graphql";
import { getIntercomMessage } from "trading/components/StatusPill/helpers";
import { useFormatDate } from "hooks";
import { useHubSpot } from "hooks/useHubSpot";

interface BuyerListingProps {
  listing: BuyingLotQuery["buyingLot"];
  onUpdateListing: () => void;
}

export const BuyerListing: FC<BuyerListingProps> = ({ listing, onUpdateListing }) => {
  const { getFormatDate } = useFormatDate();
  const { isHubSpotChatEnabled, openHubSpotChat } = useHubSpot();

  if (!listing) return null;

  const {
    animals,
    categoryOfSale,
    description,
    id,
    images: listingImages,
    name,
    preMovementTestDate,
    price,
    pricePerKgOnCreation,
    offers = [],
    status,
    tbRequirements,
    currency,
    referenceCode,
    isExpired,
  } = listing;
  const listingId = Number(id);
  const listingName = name || "";

  const currencyCodeISO = getCurrencyCode(currency?.code);
  const breeds = getListingBreedsString(listing) || "\u2014";

  const quantity = animals?.length || 0;

  const area = listing.deliveryAddress
    ? getListingAddressPublic({
        countryName: listing.deliveryAddress.country?.name,
        postcode: listing.deliveryAddress.postcode,
      })
    : "\u2014";

  const formattedAskingPricePerHead = price ? getFormattedPrice(price, currencyCodeISO) : "\u2014";
  const totalPrice = price ? getFormattedPrice(price * quantity, currencyCodeISO) : "\u2014";
  const askingPerKg = pricePerKgOnCreation ? getFormattedPrice(pricePerKgOnCreation, currencyCodeISO) : "\u2014";

  const businessUnitID = getBUFromLocalStorage();

  const isOwnListing = Number(listing?.businessUnit?.id) === Number(businessUnitID);

  const hasOngoingOffer = !!offers?.find(
    (offer) =>
      offer?.status === OfferStatus.Created ||
      offer?.status === OfferStatus.Pending ||
      offer?.status === OfferStatus.Accepted ||
      offer?.status === OfferStatus.Paid,
  );
  const isAwaitingPayment = status === LOT_STATUSES.AWAITING_PAYMENT;
  const hasSellerSentAnimals = hasOngoingOffer && status === LOT_STATUSES.SELLER_SENT_ANIMALS;

  const acceptedOffer = offers?.find((offer) => offer?.status === OfferStatus.Accepted);
  const pendingOffer = offers?.find((offer) => offer?.status === OfferStatus.Pending);

  const canMakePayment = isAwaitingPayment && acceptedOffer;
  const canMakeOffer = !isOwnListing && status === LOT_STATUSES.CREATED && !hasOngoingOffer && !isExpired;

  const hasAction = canMakeOffer || pendingOffer || canMakePayment || hasSellerSentAnimals;

  const images =
    listingImages?.reduce((accumulator, image) => {
      if (image?.photo) {
        accumulator.push({
          src: image.photo,
        });
      }

      return accumulator;
    }, [] as CarouselImages) || [];
  const hasMultipleImages = images.length > 1;
  const hasSingleImage = images.length === 1;

  const formattedPreMovementTestDate = getFormatDate(preMovementTestDate);

  let tbRequirementsText = "These animals ";
  switch (tbRequirements) {
    case LotTbRequirements.HadPreMovement:
      tbRequirementsText += `had a pre-movement test${
        preMovementTestDate ? ` and must be moved by: ${formattedPreMovementTestDate}.` : "."
      }`;
      break;

    case LotTbRequirements.RequirePreMovement:
      tbRequirementsText += `require a pre-movement test${
        preMovementTestDate ? ` and must be moved by: ${formattedPreMovementTestDate}.` : ""
      }`;
      break;

    case LotTbRequirements.DoNotRequirePreMovement:
    default:
      tbRequirementsText += "do not require pre-movement testing.";
      break;
  }

  const handleOpenChat = (): void => {
    if (isHubSpotChatEnabled) {
      openHubSpotChat("?chat=true");
    } else {
      const message = getIntercomMessage({
        listingTitle: name,
        referenceCode,
        messageVariant: isOwnListing ? "seller" : "buyer",
      });
      IntercomAPI("showNewMessage", message);
    }
  };

  return (
    <>
      <Flex container>
        <Flex item itemGutter xs={12}>
          <Title tertiary>Lot information</Title>
        </Flex>

        <Spacer baselineHeight={1} />

        <Flex item itemGutter xs={5}>
          <Paper className={styles.buyer_listing__images}>
            <Spacer baselineHeight={2} />

            {hasMultipleImages ? (
              <Carousel images={images} />
            ) : hasSingleImage ? (
              <Image aspectRatio={{ horizontal: 16, vertical: 9 }} {...images[0]} />
            ) : (
              <Text>This listing has no images.</Text>
            )}

            <Spacer baselineHeight={2} />
          </Paper>
        </Flex>

        <Flex item itemGutter xs={7}>
          <Paper className={styles.buyer_listing__details}>
            <Spacer baselineHeight={2} />

            <Flex container containerResetXS={2}>
              <Flex item itemGutter xs={1}>
                <Text>Area</Text>

                <Title secondary>{area}</Title>
              </Flex>
            </Flex>

            <Spacer baselineHeight={2} />

            <Flex container containerResetXS={2}>
              <Flex item itemGutter xs={1}>
                <Text>Breed(s)</Text>

                <Title secondary>{breeds}</Title>
              </Flex>

              <Flex item itemGutter xs={1}>
                <Text>No of Animals</Text>

                <Title secondary>{quantity}</Title>
              </Flex>
            </Flex>

            <Spacer baselineHeight={2} />

            <Flex container containerResetXS={2}>
              <Flex item itemGutter xs={1}>
                <Text>Asking per head</Text>

                <Title secondary>{formattedAskingPricePerHead}</Title>
              </Flex>

              <Flex item itemGutter xs={1}>
                <Text>Asking per kg</Text>

                <Title secondary>{askingPerKg}</Title>
              </Flex>
            </Flex>

            <Spacer baselineHeight={2} />

            <Flex container containerResetXS={2}>
              <Flex item itemGutter xs={1}>
                <Text>Asking total price</Text>

                <Title secondary>{totalPrice}</Title>
              </Flex>

              {categoryOfSale ? (
                <Flex item itemGutter xs={1}>
                  <Text>Category</Text>
                  <Title secondary>{categoryOfSale?.category}</Title>
                </Flex>
              ) : null}
            </Flex>

            {hasAction ? (
              <>
                <Spacer baselineHeight={2} />

                <Flex container containerAlignItems="center" containerResetXS={2}>
                  <Flex item itemGutter xs={1}>
                    {hasSellerSentAnimals ? (
                      <BuyerListingDelivery listingId={listingId} onNoFurtherAction={onUpdateListing} />
                    ) : null}

                    {canMakePayment && acceptedOffer ? (
                      <BuyerListingPayment
                        listingReferenceCode={referenceCode as string}
                        offerId={acceptedOffer.id}
                        currencyCode={getCurrencyCode(acceptedOffer.currency?.code)}
                        offerPriceTotal={acceptedOffer.totalPriceOnCreation || 0}
                        onNoFurtherAction={onUpdateListing}
                        handleOpenChat={handleOpenChat}
                      />
                    ) : null}

                    {pendingOffer ? (
                      <Flex item>
                        <ButtonGroup>
                          <Button caption="Offer Pending" disabled />
                          <Button
                            id="ask-a-question"
                            caption="Ask a question"
                            variant="hollow"
                            colour="grey"
                            onClick={handleOpenChat}
                          />
                        </ButtonGroup>
                      </Flex>
                    ) : null}

                    {canMakeOffer ? (
                      <BuyerListingOffer
                        askingPricePerHead={price}
                        listingId={listingId}
                        pricePerKgOnCreation={pricePerKgOnCreation}
                        onNoFurtherAction={onUpdateListing}
                        quantity={quantity}
                        listingName={listingName}
                        handleOpenChat={handleOpenChat}
                      />
                    ) : null}
                  </Flex>
                </Flex>
              </>
            ) : (
              <>
                <Flex container containerAlignItems="center" containerResetXS={2}>
                  <Flex item itemGutter xs={2}>
                    <Spacer baselineHeight={2} />

                    <Button
                      id="ask-a-question"
                      caption="Ask a question"
                      variant="hollow"
                      colour="grey"
                      onClick={handleOpenChat}
                    />
                  </Flex>
                </Flex>
              </>
            )}

            <Spacer baselineHeight={2} />
          </Paper>

          <Spacer baselineHeight={2} />

          <Paper className={styles.buyer_listing__details}>
            <Spacer baselineHeight={2} />

            <Flex container>
              <Flex item itemGutter>
                <Text>
                  <strong>TB Status</strong>
                </Text>

                <Spacer baselineHeight={1} />

                <Text>{tbRequirementsText}</Text>
              </Flex>
            </Flex>

            <Spacer baselineHeight={2} />
          </Paper>
        </Flex>
      </Flex>

      {description ? (
        <>
          <Spacer baselineHeight={3} />

          <Flex container>
            <Flex item itemGutter xs={12}>
              <Title tertiary>Description</Title>
            </Flex>

            <Spacer baselineHeight={1} />

            <Flex item itemGutter xs={12}>
              <Paper className={styles.buyer_listing__details}>
                <Spacer baselineHeight={2} />

                <Flex item itemGutter>
                  <Text smaller>{description}</Text>
                </Flex>

                <Spacer baselineHeight={2} />
              </Paper>
            </Flex>
          </Flex>
        </>
      ) : null}

      <Spacer baselineHeight={3} />

      <Flex container>
        <Flex item itemGutter xs={12}>
          <Title tertiary>Animals in this lot</Title>

          <Spacer baselineHeight={1} />

          {animals && animals.length > 0 ? (
            <>
              <Flex
                container
                xs="fill"
                containerJustifyContent="flex-end"
                className={tablePaginationStyles.table_pagination_target}
              >
                <Flex item>
                  <TablePaginationTarget tableId={TABLE_IDS.LISTING_ANIMALS} />
                </Flex>
              </Flex>

              <Spacer baselineHeight={1} />

              <AnimalsTable
                // @ts-ignore
                animals={animals}
                fromPage={`listing-${id}`}
                isProcessorView
                isShowQuickView
                tableId={TABLE_IDS.LISTING_ANIMALS}
              />
            </>
          ) : (
            <Text>There are no animals in this listing yet.</Text>
          )}
        </Flex>
      </Flex>
    </>
  );
};
