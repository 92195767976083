import React from "react";
import { REGULATORY } from "constants/Routes";
import { useGetCurrentBusinessUnit } from "hooks";
import { Regulatory as RegulatoryComponents } from "components/Regulatory";
import { Header, Spacer, PageContent, PageHeader, Tabs } from "components";

export const Regulatory: React.FC = () => {
  const { regulatorySystem } = useGetCurrentBusinessUnit();

  const regulatoryTabs = [
    {
      key: 0,
      name: regulatorySystem?.name || "",
      route: REGULATORY,
    },
  ];

  return (
    <>
      <PageHeader>
        <Header title="Regulatory Dashboard" subTitle="Manage your regulatory reporting" />
        <Tabs tabs={regulatoryTabs} selectedTab={0} />
      </PageHeader>
      <PageContent>
        <Spacer baselineHeight={2} />
        <RegulatoryComponents />
      </PageContent>
    </>
  );
};

export default Regulatory;
