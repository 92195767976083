import React from "react";

const breedrColours = {
  blue: "bg-blue-100",
  green: "bg-green-100",
  red: "bg-red-100",
  yellow: "bg-yellow-100",
  grey: "bg-gray-100",
} as const;

interface PaperProps {
  className?: string;
  colour?: keyof typeof breedrColours;
  dark?: boolean;
  shadow?: boolean;
  square?: boolean;
  children: React.ReactNode;
}

export const Paper: React.FC<PaperProps> = ({ children, className, colour, dark, shadow, square }) => {
  const colourClassName = colour ? breedrColours[colour] : "";
  const darkClassName = dark ? "bg-gray-100" : "bg-white";
  const squareClassName = square ? "" : "rounded-md";
  const shadowClassName = shadow ? "shadow-md" : "";

  const classNames = `${colourClassName} ${darkClassName} ${squareClassName} ${shadowClassName} ${className}`;

  return <div className={classNames}>{children}</div>;
};
