import React, { SyntheticEvent } from "react";
import cn from "classnames";
import Popover from "@material-ui/core/Popover";
import { SIRE_TABLE_ACTIONS } from "constants/Sires";
import styles from "./styles.module.scss";

interface Props {
  open: boolean;
  anchorEl: string;
  id: string;
  tableTooltipPress: (e: SyntheticEvent<HTMLDivElement>, key: string) => void;
  placement: string;
  onClose: () => void;
}

const TableTooltip: React.FC<Props> = ({ open, anchorEl, id, tableTooltipPress, placement, onClose }) => {
  return (
    <Popover
      className={cn(styles.root, styles[`tooltip_${placement}`])}
      id={id}
      open={open}
      anchorEl={document.getElementById(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: 60,
      }}
    >
      <div className={styles[`menu_${placement}`]}>
        {placement === "bottom" ? <span className={styles.actionsLabel}>Actions:</span> : null}
        {SIRE_TABLE_ACTIONS.map((action) => {
          return (
            <div
              id={`action_${action.key}`}
              key={`action_${action.key}`}
              onClick={(e): void => tableTooltipPress(e, action.key)}
              className={styles.menuItem}
            >
              <span>{action.title}</span>
            </div>
          );
        })}
      </div>
    </Popover>
  );
};

export default React.memo(TableTooltip);
