import { FC } from "react";
import { DateTime } from "luxon";
import { getFormattedNumber, removeNothings } from "helpers/general";
import { GROUP_CATEGORY_OPTIONS, BATCH_GENDER_OPTIONS } from "constants/Groups";
import { useFormatDate, useGetCurrentBusinessUnit } from "hooks";
import { Flex, KeyValuePair, Spacer } from "components";
import styles from "./BatchInfoBlock.module.scss";
import { BreedPills } from "../BreedPills";
import { GroupQuery } from "generated/graphql";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";

interface BatchInfoBlockProps {
  batch: NonNullable<GroupQuery["group"]>;
  children?: React.ReactNode;
}

export const BatchInfoBlock: FC<BatchInfoBlockProps> = ({ children, batch }) => {
  const {
    timeOnFarm,
    identifiedAnimalsCount,
    averageDobInBatch,
    category,
    gendersInBatch,
    numberOfUnidentifiedAnimalsInBatch,
    targetWeight,
    targetDate,
    daysTillTarget,
    summaryMetrics,
    animalBreeds,
    animalType,
    isBatch,
    avgDlwg,
  } = batch;
  const { weightUnits } = useGetCurrentBusinessUnit();
  const { getFormatDate } = useFormatDate();
  const { terms } = useLocale();

  const categoryLabel = GROUP_CATEGORY_OPTIONS.find((item) => item.value === (category as string))?.label;
  const genderLabel = BATCH_GENDER_OPTIONS.find((item) => item.value === gendersInBatch)?.label;
  const totalAnimals =
    identifiedAnimalsCount && numberOfUnidentifiedAnimalsInBatch
      ? identifiedAnimalsCount + numberOfUnidentifiedAnimalsInBatch
      : identifiedAnimalsCount || numberOfUnidentifiedAnimalsInBatch || 0;

  const batchInfo = [
    {
      label: "Number of animals",
      value: totalAnimals,
    },
    {
      label: "Type",
      value: categoryLabel,
    },
    {
      label: "Genders in Batch",
      value: genderLabel,
    },
    {
      label: "Target weight",
      unit: weightUnits,
      value: targetWeight,
    },
    {
      label: "Target date",
      value: getFormatDate(targetDate),
    },
    {
      label: "Days till target date",
      unit: "days",
      value: daysTillTarget ? getFormattedNumber(daysTillTarget) : null,
    },
    {
      label: capitaliseFirstLetter(terms.avgDlwg),
      unit: weightUnits,
      value: avgDlwg ? getFormattedNumber(avgDlwg) : null,
    },
    {
      label: "DOB Estimate",
      value: getFormatDate(averageDobInBatch),
    },
    {
      label: `Average time on ${terms.farm}`,
      unit: "days",
      value: timeOnFarm
        ? getFormattedNumber(Math.trunc(DateTime.local().diff(DateTime.fromISO(timeOnFarm), ["days"]).days))
        : null,
    },
  ];

  const batchSummaryMetrics = removeNothings(summaryMetrics ?? []);

  return (
    <>
      <BreedPills
        summaryMetrics={batchSummaryMetrics}
        isBatch={isBatch}
        animalBreeds={animalBreeds}
        showAll={true}
        animalTypeName={animalType?.name}
        numberOfUnidentifiedAnimalsInBatch={numberOfUnidentifiedAnimalsInBatch}
      />
      <Spacer baselineHeight={2} />
      <Flex container>
        {batchInfo.map((item) => (
          <Flex item itemGutter className={styles.group_info_block__item} key={item.label} xs={6} s={4} l={3} xl={2}>
            <KeyValuePair {...item} boxed />
          </Flex>
        ))}
      </Flex>
      <Flex container containerDirection="row-reverse">
        <Flex item itemGutter>
          {children}
        </Flex>
      </Flex>
    </>
  );
};
