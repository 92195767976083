import { VFC } from "react";
// Components
import { Flex, Text } from "components";
// Resources
import styles from "./WizardSeparator.module.scss";

interface WizardSeparatorProps {
  children: string;
}

export const WizardSeparator: VFC<WizardSeparatorProps> = ({ children }) => (
  <Flex className={styles.wizard_separator} container containerJustifyContent="center">
    <Text className={styles.wizard_separator__text} colour="grey800" smallest>
      <strong>{children}</strong>
    </Text>
  </Flex>
);
