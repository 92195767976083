import { FC } from "react";
// Components
import { LoadingOverlay, Spacer, Title, Text } from "components";
// Resources
import styles from "./PageMenu.module.scss";

interface PageMenuProps {
  loading?: boolean;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export const PageMenu: FC<PageMenuProps> = ({ children, loading, title, subtitle }) => (
  <div className={styles.page_menu}>
    {loading ? <LoadingOverlay customStyles={styles.page_menu__loading} /> : null}

    {title ? (
      <>
        <Title theme="light">{title}</Title>
        {subtitle ? (
          <Text colour="white" smaller>
            {subtitle}
          </Text>
        ) : null}

        <Spacer baselineHeight={4} />
      </>
    ) : null}

    {children ? <nav className={styles.page_menu__navigation}>{children}</nav> : null}
  </div>
);
PageMenu.displayName = "PageMenu";
