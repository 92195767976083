const SIRE_EDIT = "sire_edit";
const SIRE_DELETE = "sire_delete";

export const SIRE_TABLE_ACTION_TYPES = {
  SIRE_EDIT,
  SIRE_DELETE,
};

export const SIRE_TABLE_ACTIONS = [
  {
    key: SIRE_EDIT,
    title: "Edit",
  },
  {
    key: SIRE_DELETE,
    title: "Delete",
  },
];
