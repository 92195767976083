import { gql } from "@apollo/client";
import {
  animalBreedsFragment,
  animalTypeFragment,
  animalFragment,
  singleAnimalFragment,
  animalFiltersFragment,
  animalOffspringFragment,
} from "api/MyLivestock/Animal/fragment";

export const GET_ANIMAL_BREEDS = gql`
  query GetAnimalBreeds {
    animalBreeds {
      ...animalBreedsFragment
    }
  }
  ${animalBreedsFragment}
`;

export const GET_BU_ANIMAL_BREEDS = gql`
  query GetBusinessUnitBreeds($businessUnitId: Int!, $animalTypeIds: [Int]) {
    businessUnitBreeds(businessUnitId: $businessUnitId, animalTypeIds: $animalTypeIds) {
      ...animalBreedsFragment
    }
  }
  ${animalBreedsFragment}
`;

export const GET_ANIMAL_TYPES = gql`
  query GetAnimalTypes {
    animalTypes {
      ...animalTypeFragment
    }
  }
  ${animalTypeFragment}
`;

export const GET_ANIMAL_CATEGORIES = gql`
  query GetAnimalCategories {
    animalTypes {
      id
      name
      categoriesOfSale {
        id
        category
        position
      }
    }
  }
`;

export const GET_MY_ANIMALS_LIST = gql`
  query GetAnimalsList(
    $animalTypeIds: [Int]
    $search: String
    $businessUnitId: Int
    $isDead: Boolean
    $isWasOwned: Boolean
    $group: UUID
    $updatedFrom: DateTime
    $isOnFarm: Boolean
  ) {
    animalsExtended(
      animalTypeIds: $animalTypeIds
      search: $search
      businessUnitId: $businessUnitId
      isDead: $isDead
      isWasOwned: $isWasOwned
      group: $group
      updatedFrom: $updatedFrom
      isOnFarm: $isOnFarm
    ) {
      animals {
        ...animalFragment
      }
      animalsCount
    }
  }
  ${animalFragment}
`;

export const GET_MY_ANIMALS_LIST_WITH_PREDICTED_DATA = gql`
  query GetAnimalsListWithPredictedData(
    $search: String
    $businessUnitId: Int
    $isDead: Boolean
    $isWasOwned: Boolean
    $group: UUID
    $updatedFrom: DateTime
    $date: Date!
  ) {
    animalsExtended(
      search: $search
      businessUnitId: $businessUnitId
      isDead: $isDead
      isWasOwned: $isWasOwned
      group: $group
      updatedFrom: $updatedFrom
    ) {
      animals {
        ...animalFragment
        estimatedAgeOn(date: $date)
        estimatedWeightOn(date: $date)
      }
      animalsCount
    }
  }
  ${animalFragment}
`;

export const GET_ANIMAL_BY_ID = gql`
  query GetAnimalById($id: UUID!) {
    animal(id: $id) {
      ...singleAnimalFragment
    }
  }
  ${singleAnimalFragment}
`;

export const GET_PERFORMANCE_CHART_DATA = gql`
  query GetPerformanceChartData($animal: UUID!) {
    performanceChartData(animal: $animal) {
      dates {
        timestamp
      }
      measurements {
        timestamp
        value
        high
        avg
        low
        age
        onTargetWeight
        dailyLiveWeightGain
        targetDailyLiveWeightGain
        diffTargetDailyLiveWeightGain
        daysToTarget
        daysToTargetWeight
      }
      predictions {
        timestamp
        value
      }
      low {
        timestamp
        value
      }
      avg {
        timestamp
        value
      }
      high {
        timestamp
        value
      }
      references {
        timestamp
        value
        label
        rfl
        context
      }
      targetWeights {
        timestamp
        value
      }
      contexts
    }
  }
`;

export const HAS_UNSYNCED_ANIMALS = gql`
  query HasUnsyncedAnimals($businessUnitId: Int!) {
    hasUnsyncedAnimals(businessUnitId: $businessUnitId)
  }
`;

export const ANIMAL_FILTERS = gql`
  query AnimalFilters($businessUnitId: Int!) {
    animalFilters(businessUnitId: $businessUnitId) {
      ...animalFiltersFragment
    }
  }
  ${animalFiltersFragment}
`;

export const ANIMAL_FILTER_ALERTS = gql`
  query AnimalFilterAlerts($businessUnitId: Int!) {
    animalFilterAlerts(businessUnitId: $businessUnitId) {
      count
      animalFilter {
        id
        name
        color
        animalType {
          id
          name
        }
      }
    }
  }
`;

export const GET_ANIMAL_SEX_CLASSIFICATIONS = gql`
  query AnimalSexClassifications($businessUnitId: Int!, $animalTypeIds: [Int]) {
    sexClassifications(businessUnitId: $businessUnitId, animalTypeIds: $animalTypeIds) {
      slug
      title
      animalType {
        id
      }
    }
  }
`;

export const GET_ANIMAL_OFFSPRING = gql`
  query GetAnimalOffspring($businessUnitId: Int!, $animalId: String!) {
    animalOffspring(businessUnitId: $businessUnitId, animalId: $animalId) {
      summary {
        animalsCount
        maleCount
        femaleCount
      }
      animals {
        ...animalOffspringFragment
      }
    }
  }
  ${animalOffspringFragment}
`;

export const GET_ANIMAL_COUNT = gql`
  query GetAnimalCount($businessUnit: Int!) {
    animalsCount(businessUnit: $businessUnit) {
      totalAnimals
    }
  }
`;
