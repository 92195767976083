import { InputSelectOption } from "components/Common/Field/Select";
import { useAnimalTagsQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { useCallback, useMemo } from "react";

export const useTags = () => {
  const activeBusinessUnitId = getBUFromLocalStorage();
  const { data, networkStatus, refetch, error, loading } = useAnimalTagsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { buId: activeBusinessUnitId },
    skip: !activeBusinessUnitId,
  });

  const animalTags = useMemo(() => data?.animalTags?.edges ?? [], [data?.animalTags?.edges]);

  const isLoading = loading;

  const getTagColor = (tagId: string) => {
    return animalTags.find((tag) => tag?.node?.id === tagId)?.node?.color;
  };

  const getTagName = (tagId: string) => {
    return animalTags.find((tag) => tag?.node?.id === tagId)?.node?.name ?? "Tag name not found";
  };

  const getTagById = useCallback(
    (tagId: string) => {
      return animalTags.find((tag) => tag?.node?.id === tagId);
    },
    [animalTags],
  );

  const animalTagOptions: InputSelectOption[] = useMemo(
    () =>
      animalTags.map((item) => {
        return { value: item?.node?.id || "", id: item?.node?.id, label: item?.node?.name, key: item?.node?.id || "" };
      }),
    [animalTags],
  );

  return {
    animalTagOptions,
    animalTags,
    isLoading,
    refetch,
    error,
    networkStatus,
    getTagColor,
    getTagName,
    getTagById,
  };
};
