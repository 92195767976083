// React
import React from "react";
// Libraries
import { Formik } from "formik";
// Utils
import { validationSchemaEmail, handleEnterPress, handleErrorCheck } from "helpers/general";
import { ELEMENTS_IDS } from "constants/ElementsIds";
// Components
import { Button, Flex, FieldText, Spacer, Text } from "components";
import { UserInfoFragmentFragment } from "generated/graphql";

interface ModifyEmailFormProps {
  onFormSubmit: ({ email }) => void;
  userInfo: UserInfoFragmentFragment | null | undefined;
  role: string;
}

export const ModifyEmailForm: React.FC<ModifyEmailFormProps> = ({ onFormSubmit, userInfo, role }) => (
  <Formik
    validateOnChange={false}
    initialValues={{
      email: userInfo ? userInfo.email : "",
    }}
    onSubmit={(props): void => onFormSubmit(props)}
    validationSchema={validationSchemaEmail}
  >
    {({ values, errors, handleChange, handleSubmit, resetForm }): JSX.Element => (
      <>
        <Flex container>
          <Flex item itemGutter xs={12} l={12} xl={12}>
            <Text smaller>
              After updating your email address here, we will send a confirmation email to the NEW address. You must
              click the confirmation link in the email for your update to take effect.
            </Text>
            <Spacer baselineHeight={2} />
          </Flex>
          <Flex item itemGutter xs={12} l={6} xl={4}>
            <FieldText
              label="Email"
              error={handleErrorCheck(errors.email)}
              inputProps={{
                name: "email",
                value: values.email,
                disabled: role !== "Admin",
                onChange: handleChange,
                onKeyPress: (event): void => handleEnterPress(event, handleSubmit),
              }}
            />
          </Flex>
        </Flex>
        <Spacer baselineHeight={2} />
        <Flex container>
          <Flex item itemGutter>
            <Button
              id={ELEMENTS_IDS.MODIFY_EMAIL_FORM_CANCEL_BUTTON}
              caption="Cancel"
              colour="grey"
              variant="hollow"
              onClick={(): void => resetForm()}
            />
          </Flex>
          <Flex item itemGutter>
            <Button
              id={ELEMENTS_IDS.MODIFY_EMAIL_FORM_SAVE_CHANGES_BUTTON}
              caption="Save changes"
              onClick={handleSubmit}
            />
          </Flex>
        </Flex>
      </>
    )}
  </Formik>
);
