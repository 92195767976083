import React, { ReactNode } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import cn from "classnames";
// Components
import { LogoImage } from "components/Auth";
// Resources
import styles from "./FormBlockWithLogo.module.scss";

interface FormBlockWithLogoProps {
  children?: (props: FormikProps<any>) => ReactNode;
  customStyle?: Record<string, unknown>;
  initialValues: Record<string, unknown>;
  onSubmit: (data: Record<string, unknown>) => void;
  validationSchema: Yup.AnyObjectSchema;
}

export const FormBlockWithLogo: React.FC<FormBlockWithLogoProps> = ({
  children,
  onSubmit,
  initialValues,
  customStyle,
  validationSchema,
}) => (
  <div className={styles.container}>
    <div className={cn(styles.viewForm, customStyle)}>
      <LogoImage />
      <Formik
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {children}
      </Formik>
    </div>
  </div>
);
