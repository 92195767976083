import React from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./TableHeaderSortIcon.module.scss";

export const TEST_ID = "table_header_sort_icon";

interface TableHeaderSortIconProps {
  className?: string;
  sorted?: boolean;
  sortedDesc?: boolean;
}

export const TableHeaderSortIcon: React.FC<TableHeaderSortIconProps> = ({ className, sorted, sortedDesc }) => (
  <span
    className={cn(
      styles.table__header_sort_icon,
      {
        [styles["table__header_sort_icon--sorted_desc"]]: sorted && sortedDesc,
        [styles["table__header_sort_icon--sorted_asc"]]: sorted && !sortedDesc,
      },
      className,
    )}
    data-testid={TEST_ID}
  />
);
