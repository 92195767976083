// React
import React from "react";
// Libraries
import pure from "recompose/pure";
import cn from "classnames";
// Resources
import styles from "./Badge.module.scss";

export const Badge = pure(({ className, text, size = "default", color = "red" }: BadgeProps) => (
  <span className={cn(styles.badge, styles[`badge--size__${size}`], styles[`badge--color__${color}`], className)}>
    {text ? <span className={styles.badge__text}>{text}</span> : null}
  </span>
));
Badge.displayName = "Badge";
