import { Header, LoadingOverlay, PageContent, PageHeader, Spacer, Tabs } from "components";
import { useAnimalTagsQuery } from "generated/graphql";
import { removeNothings } from "helpers/general";
import { useGetCurrentBusinessUnit, useLivestockTabs } from "hooks";
import { useEffect, useState } from "react";
import { CreateEditTagModal } from "tags/components/CreateLocalTagModal";
import TagTable from "tags/components/TagTable";
import { TagType } from "tags/types";

import styles from "./Tags.module.scss";
import { EmptyStatesTagsTab } from "components/EmptyStates/TagsTab";

const Tags: React.FC = () => {
  const { id } = useGetCurrentBusinessUnit();
  const { data: serverData, loading } = useAnimalTagsQuery({
    variables: { buId: Number(id) },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });
  const animalTags = serverData?.animalTags?.edges ?? [];
  const [tags, setTags] = useState<Array<TagType>>([]);
  const [showModal, setShowModal] = useState(false);

  const title = "Management tags";
  const subTitle = `View and manage your tags`;
  const isEmptyStateVisible = !loading && !animalTags.length;
  const LIVESTOCK_TABS = useLivestockTabs();

  const sortByAnimalCount = (tagsToSort: Array<TagType>) => {
    return tagsToSort.sort((a, b) => {
      return b.numAliveAnimals - a.numAliveAnimals;
    });
  };
  useEffect(() => {
    if (serverData != null) {
      const transformedTags =
        serverData?.animalTags?.edges.map((tag) => {
          return tag?.node;
        }) ?? [];
      setTags(sortByAnimalCount(removeNothings(transformedTags)));
    }
  }, [serverData, loading]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {loading && animalTags.length === 0 ? <LoadingOverlay customStyles={styles.loadingOverlay} /> : null}
      <CreateEditTagModal handleShowModal={handleCloseModal} showModal={showModal} />
      <PageHeader>
        <Header title={title} subTitle={subTitle} />
        <Tabs tabs={LIVESTOCK_TABS} selectedTab={3} />
      </PageHeader>
      <PageContent
        floatingIconTextButton={{
          id: "add-local-tag",
          iconName: "plus",
          text: "Create",
          onClick: (): void => setShowModal(true),
        }}
      >
        <Spacer baselineHeight={2} />
        {isEmptyStateVisible ? <EmptyStatesTagsTab /> : <TagTable tags={tags} />}
      </PageContent>
    </>
  );
};

export default Tags;
