import { atom } from "recoil";
import { OfferTypes } from "trading/constants";

const DEFAULT_OFFER_TYPE = OfferTypes.HEAD;

export const makeOfferType = atom({
  key: "makeOfferType",
  default: DEFAULT_OFFER_TYPE,
});

export const makeOfferCollectionDate = atom<string | null>({
  key: "makeOfferCollectionDate",
  default: null,
});

export const makeOfferPerKgTotal = atom<number | null>({
  key: "makeOfferPerKgTotal",
  default: null,
});

export const makeOfferPriceTotal = atom<number | null>({
  key: "makeOfferPriceTotal",
  default: null,
});

export const makeOfferPrice = atom<number>({
  key: "makeOfferPrice",
  default: 0,
});
