import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { GET_MY_SIRES_LIST } from "api/Sires/queries";
import { GetSiresList, GetSiresListVariables } from "api/Sires/types/GetSiresList";
import { SIRES } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { getBUFromLocalStorage } from "helpers/storage";
import { sortAnimals as sortSires } from "trading/util";
import { Flex, Header, PageContent, PageHeader, Spacer } from "components";
import SiresTable from "./SiresTable";
import { EmptyStatesSireInformation } from "components/EmptyStates/SireInformation";

const SiresContent: React.FC = () => {
  const history = useHistory();
  const [sortState, setSortState] = useState<{
    sortBy: string | null;
    sortDirection: string;
  }>({
    sortBy: null,
    sortDirection: "ASC",
  });
  const businessUnitId = getBUFromLocalStorage();

  const { data, loading } = useQuery<GetSiresList, GetSiresListVariables>(GET_MY_SIRES_LIST, {
    variables: {
      businessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const sires = data?.sires?.slice(); // copy or sort does not work as maniplutes array

  const handleSort = (sortBy: string, sortDirection: string): void => {
    setSortState({ sortBy, sortDirection });
  };

  return (
    <>
      <PageHeader>
        <Header title="Sire Information" subTitle="Manage your list of sires" />
      </PageHeader>

      <PageContent
        floatingIconButton={{
          id: ELEMENTS_IDS.CREATE_SIRE_PLUS_BUTTON,
          iconName: "plus",
          onClick: (): void => history.push(`${SIRES}/create`),
        }}
      >
        <Spacer baselineHeight={3} />

        <Flex container item itemGrow>
          <Flex item itemGutter xs={12}>
            {!loading && sires && sires.length > 0 ? (
              <SiresTable
                sort={handleSort}
                sortBy={sortState.sortBy}
                sortDirection={sortState.sortDirection}
                sires={sortSires(sires, sortState.sortBy, sortState.sortDirection === "ASC")}
              />
            ) : !loading && sires && sires.length === 0 ? (
              <EmptyStatesSireInformation />
            ) : null}
          </Flex>
        </Flex>
      </PageContent>
    </>
  );
};

export default SiresContent;
