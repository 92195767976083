import React, { useState } from "react";
import { Spacer, Flex, Panel, Text } from "components";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { useUnlinkHerdDoggAccountMutation } from "generated/graphql";

export const Integration: React.FC = () => {
  const { herdDoggIntegration, id, refetch } = useGetCurrentBusinessUnit();
  const isConnected = herdDoggIntegration?.isConnected;
  const [unlinkHerdDogAccountMutation, { loading: unlinkLoading }] = useUnlinkHerdDoggAccountMutation({});
  const [authenticating, setAuthenticating] = useState(false);

  const disconnect = async () => {
    await unlinkHerdDogAccountMutation({
      variables: { input: { businessUnit: id } },
    });
    refetch();
  };

  const getHerdDoggCode = () => {
    setAuthenticating(true);
    const windowFeatures = "left=300,top=300,width=500,height=500";
    const herddoggAuthPopup = window.open(
      `${process.env.REACT_APP_HERDDOGG_URL}/sso/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_HERDDOGG_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HERDDOGG_REDIRECT_URL}&scope=premise`,
      "herddoggAuthPopup",
      windowFeatures,
    );
    const checkPopup = setInterval(() => {
      if (herddoggAuthPopup?.window.location.href.includes("breedr.tech")) {
        setTimeout(() => {
          herddoggAuthPopup?.close();
          refetch();
          setAuthenticating(false);
        }, 2000); //It can take a few seconds for the BU to update
      }
      if (!herddoggAuthPopup || herddoggAuthPopup.closed) {
        clearInterval(checkPopup);
      }
    }, 1000);
  };

  return (
    <Flex container>
      <Flex item itemGutter xs="fill">
        <Panel
          title="HerdDogg"
          pillText={isConnected ? "Connected" : undefined}
          actions={[
            {
              colour: "grey",
              variant: "hollow",
              onClick: () => {
                window.open("https://www.herddogg.com/");
              },
              caption: "Visit HerdDogg",
            },
            isConnected
              ? {
                  colour: "red",
                  onClick: () => {
                    disconnect();
                  },
                  caption: "Disconnect",
                  requesting: unlinkLoading,
                }
              : {
                  colour: "yellow",
                  onClick: () => {
                    getHerdDoggCode();
                  },
                  caption: "Connect to HerdDogg",
                  requesting: authenticating,
                },
          ]}
        >
          <Text smaller>
            Link your HerdDogg account to Breedr to automatically add animals to your HerdDogg premise. This will allow
            the Breedr app to surface tag notifications in app.
          </Text>
          <Spacer baselineHeight={1} />
        </Panel>
      </Flex>
    </Flex>
  );
};
