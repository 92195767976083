import React from "react";
// Resources
import styles from "./ButtonGroup.module.scss";

interface ButtonGroupProps {
  children: React.ReactNode;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children }) => (
  <div className={styles.buttonGroup}>{children}</div>
);
