import { FC, ReactNode, useState } from "react";
// API
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
// Utilss
import { ADD_PREVIOUS_KEEPER_TEMPLATE, MOVE_OFF_FARM_TEMPLATE } from "constants/Animals";
// Components
import { Button, Modal } from "components";
import { FirstStep } from "components/MyLivestock/Activities/LogActivityModal/FirstStep";
import { SecondStep } from "components/MyLivestock/Activities/LogActivityModal/SecondStep";
import { useGetActivityTemplates } from "hooks";

interface ActivityModalProps {
  animals: GetAnimalsList_animalsExtended_animals[];
  isShowModal?: boolean;
  isSingleAnimal?: boolean;
  onClose?: () => void;
}

export enum logActivityMethodTypes {
  TO_ALL_SELECTED = "allSelected",
  TO_INDIVIDUAL = "individual",
}

export const LogActivityModal: FC<ActivityModalProps> = ({
  animals = [],
  isShowModal = false,
  isSingleAnimal = false,
  onClose,
}) => {
  const [showModal, setShowModal] = useState<boolean>(isShowModal);
  const [step, setStep] = useState<number>(0);
  const [selectedAnimalNumber, setSelectedAnimalNumber] = useState<number>(0);
  const [logActivityMethod, changeLogActivityMethod] = useState<string>(logActivityMethodTypes.TO_ALL_SELECTED);
  const [selectedTemplate, changeSelectedTemplate] = useState<string>("");

  const { data: activityTemplates } = useGetActivityTemplates();

  const filteredActivityTemplates = activityTemplates?.filter(
    (item) => item.slug !== MOVE_OFF_FARM_TEMPLATE && item.slug !== ADD_PREVIOUS_KEEPER_TEMPLATE,
  );

  const animalsIds = animals.map((item) => item.id);
  const isOneAnimal = animalsIds.length === 1;
  const animalsTitleInfo = isOneAnimal ? "1 animal" : `${animalsIds.length} animals`;
  const isIndividual = logActivityMethod === logActivityMethodTypes.TO_INDIVIDUAL;

  const selectedTemplateObject = filteredActivityTemplates.find((item) => item.id === selectedTemplate);

  const handleModalToggle = (): void => {
    setShowModal(!showModal);
    setStep(0);
    onClose && onClose();
  };

  const renderModalContent = (): ReactNode => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            activityTemplates={filteredActivityTemplates}
            onChangeSelectedTemplate={changeSelectedTemplate}
            onChangeLogActivityMethod={changeLogActivityMethod}
            onCloseModal={handleModalToggle}
            onChangeStep={setStep}
            selectedTemplate={selectedTemplate}
            logActivityMethod={logActivityMethod}
            isOneAnimal={isOneAnimal}
          />
        );
      case 1:
        return (
          <SecondStep
            isOneAnimal={isOneAnimal}
            animalsList={animals}
            isIndividual={isIndividual}
            onChangeStep={setStep}
            selectedTemplateObject={selectedTemplateObject}
            animalsIds={animalsIds}
            selectedAnimalNumber={selectedAnimalNumber}
            onChangeAnimalNumber={setSelectedAnimalNumber}
            selectedTemplate={selectedTemplate}
            onCloseModal={handleModalToggle}
          />
        );
      default:
        return null;
    }
  };

  const renderModalTitle = (): string => {
    switch (step) {
      case 0:
        return `Log an activity (${animalsTitleInfo})`;
      case 1:
        const animalsTitle = isOneAnimal
          ? "1 animal"
          : isIndividual
          ? `${selectedAnimalNumber + 1} of ${animalsIds.length} animals`
          : `${animalsIds.length} animals`;
        return `${selectedTemplateObject?.name || "Activity"} (${animalsTitle})`;
      default:
        return "";
    }
  };

  return (
    <>
      {!isSingleAnimal ? <Button caption="Log activity" onClick={handleModalToggle} /> : null}

      <Modal active={showModal} handleClose={handleModalToggle} title={renderModalTitle()}>
        {renderModalContent()}
      </Modal>
    </>
  );
};
