// @ts-nocheck
import React from "react";

import pure from "recompose/pure";

import { TableProps } from "./Table";
import { Table, TableBody, TableRow, TableCell } from "components/Import";

import styles from "./styles.module.css";

export interface SimpleTableProps extends TableProps {
  columns: TableColumn;
  data: Record<string, unknown>[];
  rowKey?: string;
  noHeader?: boolean;
}

const SimpleTable: React.FC<SimpleTableProps> = ({ columns, data, rowKey, children, noHeader, ...props }) => (
  <Table {...props}>
    <TableBody className={styles.body}>
      {data.map((d, rowIndex) => (
        <TableRow key={d[rowKey] || d.id || d[rowIndex]}>
          {columns.map(({ render }, colIndex) => {
            const key = `${rowIndex}${colIndex}`;
            return <TableCell key={key}>{render(d)}</TableCell>;
          })}
        </TableRow>
      ))}
    </TableBody>
    {!noHeader ? children : null}
  </Table>
);

export default pure(SimpleTable);
