import { VFC } from "react";
// API
import { MyLotQuery } from "generated/graphql";

// Types
import { SellerListingCallbackProps } from "trading/components/containers/liveweight/SellerListing";
// Containers
import { SellerListingOffersTable } from "./SellerListingOffersTable";
// Components
import { Spacer, Flex, Title } from "components";

type MyLot = MyLotQuery["myLot"];

export interface SellerListingOffersDrillProps extends SellerListingCallbackProps {
  lotConfigurableSellerMessage: NonNullable<MyLot>["lotConfigurableSellerMessage"];
}

export interface SellerListingOffersProps extends SellerListingOffersDrillProps {
  listingId: number;
  listingStatus: string;
  offers: NonNullable<MyLot>["lotOffers"];
}

export const SellerListingOffers: VFC<SellerListingOffersProps> = ({
  listingId,
  listingStatus,
  lotConfigurableSellerMessage,
  offers,
  onAfterMutateListing,
}) => {
  return (
    <Flex container>
      <Flex item itemGutter xs="fill">
        <Title tertiary>Offers</Title>

        <Spacer baselineHeight={2} />

        <SellerListingOffersTable
          listingId={listingId}
          listingStatus={listingStatus}
          lotConfigurableSellerMessage={lotConfigurableSellerMessage}
          offers={offers}
          onAfterMutateListing={onAfterMutateListing}
        />
      </Flex>
    </Flex>
  );
};
