import React from "react";
// Components
import { Button, Flex, Icon, Spacer, Text } from "components";
// Hooks
import { useHasFeature } from "hooks";
// Resources
import styles from "./styles.module.scss";

interface AnimalHistoryItemProps {
  activity: ActivitesByDate;
  onClickDelete?: () => void | null;
  readOnly?: boolean;
}

export const AnimalHistoryItem: React.FC<AnimalHistoryItemProps> = ({
  activity: { date, description, icon, isWeightActivity, name = "", value = "" },
  onClickDelete,
  readOnly,
}) => {
  const showDeleteActivity = useHasFeature("SHOW_DELETE_ACTIVITY");

  const isEditable = showDeleteActivity && !readOnly;

  return (
    <Flex container containerWrap="nowrap" className={styles.activity_history_item}>
      <Flex item itemGutter>
        {isWeightActivity ? (
          <span className={styles.activity_history_item__icon}>
            <Icon name="weight" size="medium" />
          </span>
        ) : (
          <img src={icon} alt={`${name} icon`} className={styles.activity_history_item__icon} />
        )}
      </Flex>
      <Flex item itemGutter>
        <Text>{name}</Text>
        <Spacer />
        <Text smallest>{`${date}${description ? ` - ${description}` : ""}`}</Text>
      </Flex>
      <Flex item itemGutter itemNoShrink className={styles.activity_history_item__value}>
        <Text>
          <strong>{value}</strong>
        </Text>
      </Flex>
      {isEditable ? (
        <Flex item>
          <Button
            icon={{ image: <Icon name="trash" size="small" />, position: "left" }}
            onClick={onClickDelete}
            variant="ghost"
            disabled={!onClickDelete}
          />
        </Flex>
      ) : null}
    </Flex>
  );
};
