import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "lodash";
import { CommonContext } from "config/commonProvider";
import { Flex, LoadingOverlay } from "components";
import { BaseInput } from "components/Common/Field/BaseInput";
import styles from "./SearchAnimalInput.module.scss";

export const SearchAnimalInput: FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { changeAnimalsFilter } = useContext(CommonContext);

  const debouncedChangeAnimalsFilter = useCallback(
    debounce((newSearchValue) => {
      changeAnimalsFilter({ search: newSearchValue });
      setIsLoading(false);
    }, 200),
    [],
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
    setIsLoading(true);
  };

  useEffect(() => debouncedChangeAnimalsFilter(searchValue), [searchValue]);

  return (
    <Flex className={styles.search_animal_input} item>
      <BaseInput
        name="animal_search"
        onChange={handleInputChange}
        placeholder="Search by ID"
        value={searchValue}
        type="search"
      />

      {isLoading ? <LoadingOverlay customStyles={styles.search_animal_input__spinner} /> : null}
    </Flex>
  );
};
