import { gql } from "@apollo/client";

import { sireFragment } from "./fragment";

export const GET_MY_SIRES_LIST = gql`
  query GetSiresList($businessUnitId: Int!) {
    sires(businessUnitId: $businessUnitId) {
      ...sireFragment
    }
  }
  ${sireFragment}
`;

export const GET_SIRE_BY_ID = gql`
  query GetSireById($id: UUID!, $businessUnitId: Int!) {
    sire(id: $id, businessUnitId: $businessUnitId) {
      ...sireFragment
    }
  }
  ${sireFragment}
`;
