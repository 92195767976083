import { useState, FC, useMemo } from "react";
import { UploadCsv_uploadCsv } from "api/Import/CustomImport/types/UploadCsv";
import { ACTIVITIES_IMPORT, ANIMALS_IMPORT } from "constants/Import";
import { useEffectSpeciesActive, useGetAnimalIdPreferences, useGetAnimalTypes, useStateSpecies } from "hooks";
import { useColumnFieldMap } from "./hooks";
import { Button, Flex, InputCheckbox, Paper, Spacer, Text, FieldSelect, InfoTooltip } from "components";
import { ImportWizardActivityTemplateSelect } from "./ImportWizardActivityTemplateSelect";
import { ImportWizardColumnsTable } from "./ImportWizardColumnsTable";
import styles from "./ImportWizardChooseColumns.module.scss";
import { CsvImportType } from "import/types";
import { useImportCsvMutation } from "generated/graphql";
import { BatchUploadFormat } from "hooks/useGetBatchUploadFormats";
import { Tooltip } from "twComponents/Tooltip";

interface Hacky_UploadCsv_uploadCsv_fields {
  field: string;
  isRequired: boolean;
  label: string;
}

export interface Hacky_UploadCsv_uploadCsv extends Omit<UploadCsv_uploadCsv, "fields"> {
  fields: Hacky_UploadCsv_uploadCsv_fields[];
}

interface ImportWizardChooseColumnsProps {
  file: Hacky_UploadCsv_uploadCsv;
  importType: CsvImportType;
  batchUploadFormat: BatchUploadFormat | undefined;
  onChangeBatchUploadFormat: (batchUploadFormat: BatchUploadFormat) => void;
  onClickBack: () => void;
  onRestart: () => void;
  onSuccess: (taskId: string) => void;
}

export const ImportWizardChooseColumns: FC<ImportWizardChooseColumnsProps> = ({
  batchUploadFormat,
  file,
  importType,
  onChangeBatchUploadFormat,
  onClickBack,
  onRestart,
  onSuccess,
}) => {
  const [isFirstRowColumnNames, setIsFirstRowColumnNames] = useState<boolean>(true);
  const handleToggleIsFirstRowColumnNames = () => setIsFirstRowColumnNames(!isFirstRowColumnNames);
  const fileRowsCount = file.totalRows || 0; // sadly the schema generates `number | null` so falling back to 0 here
  const rowsToImportCount = isFirstRowColumnNames ? fileRowsCount - 1 : fileRowsCount;
  const { primaryId } = useGetAnimalIdPreferences();
  const selectOptions = useMemo(
    () => batchUploadFormat?.options.filter((option) => option.dataType === "SELECT") || [],
    [batchUploadFormat],
  );
  const initialSelectState = selectOptions.reduce(
    (acc, option) => ({
      ...acc,
      [option.slug]:
        primaryId && option.choices.some((choice) => choice?.value === primaryId)
          ? primaryId
          : option.choices[0]?.value,
    }),
    {},
  );
  const [fieldSelectState, setFieldSelectState] = useState(initialSelectState);
  const booleanOptions = useMemo(
    () => batchUploadFormat?.options.filter((option) => option.dataType === "BOOLEAN") || [],
    [batchUploadFormat],
  );
  const initialBooleanState = booleanOptions.reduce(
    (acc, option, index) => ({
      ...acc,
      [option.slug]: index === 0,
    }),
    {},
  );
  const [checkedState, setCheckedState] = useState(initialBooleanState);

  const handleCheckboxChange = (slug) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [slug]: !prevState[slug],
    }));
  };

  const handleSelectChange = (event) => {
    setFieldSelectState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const renderCheckboxes = booleanOptions.map((option, index) => (
    <Flex key={index} container containerAlignItems="center">
      <InputCheckbox
        label={option.label}
        name={option.slug}
        checked={checkedState[option.slug]}
        onChange={() => handleCheckboxChange(option.slug)}
      />
      {option.helpText ? <InfoTooltip text={option.helpText} inline={true} /> : null}
    </Flex>
  ));

  const renderSelect = selectOptions
    .filter((option) => option.dataType === "SELECT")
    .map((option) => {
      return (
        <Flex key={option.slug} container containerAlignItems="center">
          <Flex>
            <FieldSelect
              label={option.label}
              className={styles.importWizardfieldSelect}
              inputProps={{
                name: option.slug,
                options: option.choices,
                value: fieldSelectState[option.slug],
                onChange: handleSelectChange,
              }}
            />
          </Flex>
          {option.helpText ? <Tooltip title={option.helpText} infoTooltip colour="ghost" /> : null}
        </Flex>
      );
    });

  // Column field mapping
  const {
    columnFieldMap,
    fieldOptions,
    isMatchColumnsDisabled,
    isRequiredFieldMissing,
    matchColumns: handleClickMatchColumns,
    setColumn: handleChangeColumnField,
  } = useColumnFieldMap({
    batchUploadFormat,
    file,
    isFirstRowColumnNames,
  });

  // Alphabetise fieldOptions
  fieldOptions.sort((a, b) => a.label.localeCompare(b.label));

  // Import query
  const { activeSpecies } = useStateSpecies();
  const { data: animalTypes } = useGetAnimalTypes();
  const speciesCattle = animalTypes?.animalTypes?.find((item) => item?.slug === "cattle");
  const speciesActiveId = activeSpecies ? activeSpecies.id : speciesCattle?.id;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [importCsvMutation] = useImportCsvMutation();

  const handleImportFile = async () => {
    if (!file.id) throw new Error("Has no ID");

    setIsLoading(true);

    try {
      const animalProperties =
        importType === ANIMALS_IMPORT
          ? {
              animalType: speciesActiveId ? Number(speciesActiveId) : undefined,
            }
          : {};

      const selectedOptions = Object.entries(fieldSelectState).map(([slug, value]) => ({
        slug,
        value,
      }));

      const checkedOptions = Object.entries(checkedState).map(([slug, value]) => ({
        slug,
        value,
      }));

      const { data } = await importCsvMutation({
        variables: {
          input: {
            id: +file.id,
            fields: columnFieldMap,
            options: [...selectedOptions, ...checkedOptions],
            isFirstRowColumnNames: isFirstRowColumnNames,
            formatSlug: batchUploadFormat?.slug,
            ...animalProperties,
          },
        },
      });

      if (data?.importCsv?.errors) {
        setError(data.importCsv.errors.map((error) => error?.message).join(". "));
      } else if (data?.importCsv?.taskId) {
        onSuccess(data.importCsv.taskId);
      }
    } catch (error: any) {
      if (error.networkError) {
        let newError = error.networkError.message;

        if (error.networkError.result.errors.length) {
          const resultErrors = error.networkError.result.errors.map((error) => error.message).join(". ");
          newError += `. ${resultErrors}`;
        }

        setError(newError);
      } else if (error.graphQLErrors.length) {
        setError(error.graphQLErrors[0].message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Navigation safety
  const handleEffectSpeciesActive = () => {
    if (importType !== ANIMALS_IMPORT) return;

    onRestart();
  };
  useEffectSpeciesActive(handleEffectSpeciesActive);

  return (
    <>
      <Flex container containerJustifyContent={"center"}>
        {importType !== ANIMALS_IMPORT ? (
          <Flex item>
            <Text>Change Selected Format:</Text>
            <ImportWizardActivityTemplateSelect
              batchUploadFormat={batchUploadFormat}
              onChangeBatchUploadFormat={onChangeBatchUploadFormat}
            />
          </Flex>
        ) : null}
      </Flex>

      <Spacer baselineHeight={2} />

      <Paper className={styles.import_wizard_choose_columns} dark>
        <Spacer baselineHeight={1} />

        <div className="flex">
          {booleanOptions?.length ? <div className="flex-1">{renderCheckboxes}</div> : null}
          {selectOptions?.length ? <div className="flex-1">{renderSelect}</div> : null}
        </div>

        <Spacer baselineHeight={1} />
      </Paper>

      <Spacer baselineHeight={2} />

      <Flex container containerJustifyContent="flex-end">
        <Flex item itemGutter>
          <InputCheckbox
            checked={isFirstRowColumnNames}
            label="First row is column names"
            name="isFirstRowColumnNames"
            onChange={handleToggleIsFirstRowColumnNames}
          />
        </Flex>
        <Button
          caption="Match columns"
          colour="grey"
          disabled={!isFirstRowColumnNames || isMatchColumnsDisabled}
          onClick={handleClickMatchColumns}
          variant="hollow"
        />
      </Flex>

      <Spacer baselineHeight={2} />

      <ImportWizardColumnsTable
        columnFieldMap={columnFieldMap}
        fieldOptions={fieldOptions}
        file={file}
        isFirstRowColumnNames={isFirstRowColumnNames}
        onChangeColumnField={handleChangeColumnField}
      />

      <Spacer baselineHeight={2} />

      <Flex container containerJustifyContent="space-between">
        <Text smaller>Total rows to be imported: {rowsToImportCount}</Text>

        {error ? (
          <Text smaller warning>
            {error}
          </Text>
        ) : null}
      </Flex>

      <Spacer baselineHeight={4} />

      <Flex container containerJustifyContent="space-between">
        <Button caption="Back" colour="grey" onClick={onClickBack} variant="hollow" />

        <Button
          caption="Finish upload"
          disabled={isLoading || (isRequiredFieldMissing && importType === ACTIVITIES_IMPORT)}
          onClick={handleImportFile}
          requesting={isLoading}
        />
      </Flex>
    </>
  );
};
