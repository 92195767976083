import { Button, FieldNumber, FieldNumberRange, FieldSelect, InputCheckbox, Spacer } from "components";
import React, { useEffect } from "react";
import { Epd, FamilyTreeFiltersProps, FamilyTreeFiltersState } from "../types";
import { epdNameLookup } from "../helpers";

export const epds: Epd[] = [
  "calvingEase",
  "birthWeight",
  "weaningWeight",
  "yearlingWeight",
  "dryMatterIntake",
  "yearlingHeight",
  "scrotalCircumference",
  "docility",
  "clawAngle",
  "footAngle",
  "heiferPregnancy",
  "calvingEaseMaternal",
  "milk",
  "matureWeight",
  "matureHeight",
  "carcassWeight",
  "carcassMarbling",
];

export const defaultEpdTargets = {
  calvingEase: [1, 3],
  birthWeight: [50, 100],
  weaningWeight: [200, 400],
  yearlingWeight: [300, 600],
  dryMatterIntake: [10, 20],
  yearlingHeight: [50, 70],
  scrotalCircumference: [30, 40],
  docility: [15, 30],
  clawAngle: [40, 60],
  footAngle: [40, 60],
  heiferPregnancy: [1, 3],
  calvingEaseMaternal: [1, 3],
  milk: [1, 3],
  matureWeight: [500, 1000],
  matureHeight: [100, 200],
  carcassWeight: [200, 400],
  carcassMarbling: [1, 3],
};

export const defaultFilterState: FamilyTreeFiltersState = {
  epd: "calvingEase",
  minEpdTarget: defaultEpdTargets.calvingEase[0],
  maxEpdTarget: defaultEpdTargets.calvingEase[1],
  generations: 3,
  highlightInbreeding: false,
};

export const FamilyTreeFilters: React.FC<FamilyTreeFiltersProps> = ({ filterState, setFilterState }) => {
  const { epd, minEpdTarget, maxEpdTarget, generations, highlightInbreeding } = filterState;

  useEffect(() => {
    setFilterState((prevState) => ({
      ...prevState,
      minEpdTarget: defaultEpdTargets[prevState.epd][0],
      maxEpdTarget: defaultEpdTargets[prevState.epd][1],
    }));
  }, [epd, setFilterState]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeCheckbox = ({ target: { checked, name } }: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetFilters = (): void => {
    setFilterState(defaultFilterState);
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg border border-solid border-1 border-gray-300 w-40">
      <FieldSelect
        label="EPD"
        inputProps={{
          labelKey: "label",
          name: "epd",
          onChange: handleSelectChange,
          options: epds.map((epd) => ({
            label: epdNameLookup[epd],
            value: epd,
          })),
          value: epd,
          valueKey: "value",
        }}
      />
      <FieldNumberRange
        label="EPD target range"
        inputProps={{
          from: {
            value: minEpdTarget,
            name: "minEpdTarget",
            placeholder: "Min EPD",
            onChange: handleInputChange,
          },
          to: {
            value: maxEpdTarget,
            name: "maxEpdTarget",
            placeholder: "Max EPD",
            onChange: handleInputChange,
          },
        }}
      />
      <FieldNumber
        label={`Generations (max 5)`}
        inputProps={{
          value: generations,
          name: "generations",
          placeholder: "Generations",
          onChange: handleInputChange,
          max: 5,
          min: 1,
        }}
      />
      <InputCheckbox
        checked={highlightInbreeding}
        label="Show inbreeding"
        name="highlightInbreeding"
        onChange={handleChangeCheckbox}
      />
      <Spacer baselineHeight={1} />
      <Button caption="Reset filters" onClick={handleResetFilters} variant="ghost" />
    </div>
  );
};
