import { gql } from "@apollo/client";

import { animalFragment, publicAnimalFragment } from "api/MyLivestock/Animal/fragment";

export const GET_BUYING_LOTS = gql`
  query BuyingLots(
    $after: String
    $before: String
    $businessUnit: Int!
    $first: Int
    $last: Int
    $orderBy: String
    $showExpired: Boolean
    $status: [String]
    $distance: Float
    $categoryOfSale: Float
    $animalType: Float
  ) {
    buyingLots(
      after: $after
      before: $before
      businessUnit: $businessUnit
      first: $first
      last: $last
      orderBy: $orderBy
      showExpired: $showExpired
      status: $status
      distance: $distance
      categoryOfSale: $categoryOfSale
      animalType: $animalType
    ) {
      edges {
        node {
          businessUnit {
            id
            name
            country {
              id
              code
            }
            unitsOfMeasurement {
              id
              code
              measurementType
            }
            county
          }
          id
          currency {
            id
            code
          }
          description
          distance(businessUnitId: $businessUnit)
          name
          price
          priceUnit
          totalPrice
          expiresAt
          status
          images {
            id
            photo
            thumbnailSmall
            position
          }
          offersPendingCount
          deliveryAddress {
            id
            country {
              id
              name
            }
            postcode
          }
          animalsAverageAge
          animalsAverageGrowthRate
          animalsAverageTimesWeighed
          animalsAverageCurrentWeight
          animalsMaxNumberMoves
          animalsNumberSires
          animalsNumberWithdrawals
          animalsCountBreedAndGender {
            breedName
            isMale
            count
          }
          isPublic
          startDate
          animalsCount
          isExpired
          animalType {
            id
            name
          }
          categoryOfSale {
            id
            category
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_BUYING_LOT = gql`
  query BuyingLot($id: Int!, $businessUnitId: Int!) {
    buyingLot(id: $id, businessUnit: $businessUnitId) {
      id
      description
      name
      categoryOfSale {
        id
        category
      }
      price
      status
      totalPrice
      pricePerKgOnCreation
      priceUnit
      currency {
        id
        code
      }
      expiresAt
      startDate
      animals {
        ...publicAnimalFragment
      }
      offers(businessUnitId: $businessUnitId) {
        id
        status
        businessUnit {
          id
        }
        price
        totalPriceOnCreation
        currency {
          id
          name
          code
        }
      }
      images {
        id
        photo
        position
      }
      deliveryAddress {
        id
        unitName
        addressLine1
        addressLine2
        city
        country {
          id
          code
          name
        }
        postcode
        primary
        locationType
        locationId
      }
      businessUnit {
        id
        name
        postcode
      }
      animalType {
        id
        name
      }
      tbRequirements
      preMovementTestDate
      referenceCode
      isAcceptedMyOffer(businessUnitId: $businessUnitId)
      isPublic
      isExpired
    }
  }
  ${publicAnimalFragment}
`;

export const GET_MY_LOTS = gql`
  query MyLots($businessUnit: Int!, $isActive: Boolean, $after: String, $first: Int) {
    myLots(businessUnit: $businessUnit, isActive: $isActive, after: $after, first: $first) {
      edges {
        node {
          id
          description
          name
          price
          priceUnit
          totalPrice
          createdAt
          expiresAt
          currency {
            id
            code
          }
          categoryOfSale {
            category
            id
          }
          status
          lotOffers {
            id
            price
          }
          images {
            id
            photo
            thumbnailSmall
            position
          }
          offersPendingCount
          deliveryAddress {
            id
            locationId
            unitName
          }
          animalsAverageAge
          animalsAverageGrowthRate
          animalsAverageTimesWeighed
          animalsAverageCurrentWeight
          animalsMaxNumberMoves
          animalsNumberSires
          animalsNumberWithdrawals
          animalsCountBreedAndGender {
            breedName
            isMale
            count
          }
          distributionLists {
            id
            name
          }
          isPublic
          startDate
          animalsCount
          isExpired
          animalType {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_MY_LOT = gql`
  query MyLot($id: Int!, $businessUnit: Int!) {
    myLot(id: $id, businessUnit: $businessUnit) {
      id
      categoryOfSale {
        id
        category
      }
      animalType {
        id
      }
      description
      name
      price
      status
      priceUnit
      pricePerKgOnCreation
      referenceCode
      createdAt
      startDate
      expiresAt
      isExpired
      animals {
        ...animalFragment
      }
      lotConfigurableSellerMessage
      tbRequirements
      preMovementTestDate
      images {
        id
        photo
        position
        thumbnailSmall
      }
      lotOffers {
        id
        price
        offeredNumber
        status
        createdAt
        priceUnit
        collectionDate
        totalPriceOnCreation
        currency {
          id
          code
        }
        businessUnit {
          id
          name
        }
        createdBy {
          id
          firstName
          lastName
        }
      }
      deliveryAddress {
        id
        locationId
        unitName
      }
      tradingType {
        id
        slug
      }
      distributionLists {
        id
        name
      }
      isPublic
    }
  }
  ${animalFragment}
`;

export const GET_TERMS_AND_CONDITIONS = gql`
  query TermsAndConditions($tacType: String!) {
    termsAndConditions(tacType: $tacType) {
      title
      description
      type
    }
  }
`;

export const GET_CATEGORIES_OF_SALE = gql`
  query CategoriesOfSale($animalType: Int!) {
    categoriesOfSale(animalType: $animalType) {
      edges {
        node {
          id
          category
        }
      }
    }
  }
`;
