import { maybeCropString } from "helpers/general/all";

export const InfoCard = ({ title, value }: { title: string; value: string }) => {
  return (
    <article className="rounded-lg grid grid-rows-2 p-4 bg-white border border-gray-300 border-solid gap-2 max-h-36">
      <h2 className="m-0 text-xs md:text-base">{title}</h2>
      <p className="text-base font-semibold overflow-hidden md:text-lg">{maybeCropString(value, 25)}</p>
    </article>
  );
};
