import React, { useMemo } from "react";
// Libraries
import { DateTime } from "luxon";
// API
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
// Utils
import { getFormattedNumber } from "helpers/general";
import { getAge, getAnimalBreedsAsString, getTimeOnFarm } from "helpers/myLivestock";
import { useFormatDate, useGetCurrentBusinessUnit, usePrevious } from "hooks";
// Components
import { AnimalsSexClassLabel } from "components/MyLivestock";
import { Flex, KeyValuePair, Modal } from "components";
// Types
import { KeyValuePairProps } from "components/Common/KeyValuePair";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";

interface AnimalsTablePreviewModalProps {
  animal: GetAnimalsList_animalsExtended_animals | null;
  onClose: () => void;
}

interface AnimalsTablePreviewItems extends KeyValuePairProps {
  isHidden?: boolean;
}

export const AnimalsTablePreviewModal: React.FC<AnimalsTablePreviewModalProps> = ({
  animal: activeAnimal,
  onClose,
}) => {
  const { weightUnits } = useGetCurrentBusinessUnit();
  const { getFormatDate } = useFormatDate();
  const { terms } = useLocale();
  // The modal flashes empty content after the user clicks to close
  // so we cache the animal data to show during the closing animation
  const cachedAnimal = usePrevious(activeAnimal as GetAnimalsList_animalsExtended_animals);
  const animal = activeAnimal || cachedAnimal;

  const animalPreviewItems: AnimalsTablePreviewItems[][] = useMemo(
    () => [
      [
        {
          label: "Age",
          value: animal?.dob ? getAge(DateTime.local(), animal.dob) : null,
        },
        {
          label: "Breed(s)",
          value: getAnimalBreedsAsString(animal?.animalBreeds),
        },
        {
          label: "Sex",
          value: (
            <AnimalsSexClassLabel
              isCastrated={!!animal?.isCastrated}
              isMale={!!animal?.isMale}
              label={animal?.sexClassification?.slug || null}
            />
          ),
        },
        {
          label: `Time on ${terms.farm}`,
          value: animal?.dateMovedToFarm ? getTimeOnFarm(animal.dateMovedToFarm) : null,
        },
        {
          label: `Number of ${terms.farm}s]`,
          value: animal?.cphCount || null,
        },
        {
          label: "Sire id",
          value: animal?.sirePassportNumber ? animal.sirePassportNumber : null,
        },
        {
          label: "Withdrawal",
          value: animal?.withdrawalEnd ? `Ends ${getFormatDate(animal.withdrawalEnd)}` : "No",
        },
      ],
      [
        {
          label: "Last weight",
          unit: weightUnits,
          value: animal?.lastWeight?.value ? getFormattedNumber(animal?.lastWeight?.value) : null,
        },
        {
          label: "Current estimated weight",
          unit: weightUnits,
          value: animal?.currentWeight ? getFormattedNumber(animal?.currentWeight) : null,
        },
        {
          label: capitaliseFirstLetter(terms.dlwg),
          unit: `${weightUnits}/day`,
          value: animal?.growthRate ? getFormattedNumber(animal?.growthRate) : null,
        },
      ],
    ],
    [animal],
  );

  return (
    <Modal
      showCloseButton
      isChildrenGutterManual
      active={!!activeAnimal}
      handleClose={onClose}
      title="Animal Details"
      size="medium"
    >
      <Flex container>
        {animalPreviewItems.map((itemList, index) => (
          <Flex item itemGutter xs={3} key={index}>
            {itemList.map((item) => (
              <KeyValuePair key={item.label} label={item.label} unit={item.unit} value={item.value} />
            ))}
          </Flex>
        ))}
      </Flex>
    </Modal>
  );
};
