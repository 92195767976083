import React, { useContext, useEffect, useRef, useState } from "react";
// Libraries
import cn from "classnames";
import { UsePaginationState, UsePaginationInstanceProps } from "react-table";
// Utils
import { TableOptionsContext } from "config/tableOptionsProvider";
import { useOutsideClick } from "hooks";
// Components
import { Flex, Icon } from "components";
// Resources
import styles from "./TablePagination.module.scss";
import { getTableSettingsFromLocalStorage, setTableSettingsToLocalStorage } from "helpers/storage";

export type TablePaginationSizes = typeof TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES[number];

export const TABLE_PAGINATION_DEFAULT_OPTIONS = {
  SIZES: [10, 15, 25, 50, 100] as const,
};

/**
 * Pagination Block
 *
 * This is how we neatly allow pagination to exist outside of Table component by saving the ref to Context
 */

interface TablePaginationTargetProps {
  tableId: string;
}

export const TablePaginationTarget: React.FC<TablePaginationTargetProps> = ({ tableId }) => {
  const { setTableOptions } = useContext(TableOptionsContext);
  const ref = useRef<HTMLDivElement | null>(null);
  const memoSetTableOptions = useRef(setTableOptions);

  useEffect(() => {
    if (tableId && ref) {
      memoSetTableOptions.current({
        id: tableId,
        paginationRef: ref.current as HTMLDivElement,
      });
    }
  }, [tableId, ref]);

  return <div className={styles.table_pagination} ref={ref} />;
};
TablePaginationTarget.displayName = "TablePaginationTarget";

/**
 * Pagination
 *
 * This is the bulk of the pagination logic
 */

type TablePaginationBaseProps = UsePaginationState<Record<string, never>> &
  Omit<UsePaginationInstanceProps<Record<string, never>>, "page" | "pageOptions">;

interface TablePaginationProps extends TablePaginationBaseProps {
  dataLength: number;
  showFirstLast?: boolean;
  tableId: string;
}

export const TablePagination: React.FC<TablePaginationProps> = ({
  pageIndex,
  pageSize,
  pageCount,
  canNextPage,
  canPreviousPage,
  previousPage,
  nextPage,
  gotoPage,
  setPageSize,
  dataLength,
  showFirstLast = true,
  tableId,
}) => {
  const [isMenuVisible, setMenuVisibility] = useState<boolean>(false);
  const menuRef = useRef(null);
  const localStorageTableSettings = getTableSettingsFromLocalStorage(tableId);
  const localStorageColumns = localStorageTableSettings?.columns;

  const toggleMenu = (): void => {
    setMenuVisibility((state) => !state);
  };

  const hideMenu = (): void => {
    setMenuVisibility(false);
  };

  useOutsideClick(menuRef, hideMenu);

  const handleChangePageSize = (
    pageSize: number,
    event: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLSpanElement>,
  ): void => {
    event.stopPropagation();
    setTableSettingsToLocalStorage(tableId, localStorageColumns, pageSize);
    setPageSize(Number(pageSize));
    hideMenu();
  };

  const handleClickPreviousPage = (): void => {
    previousPage();
  };

  const handleClickNextPage = (): void => {
    nextPage();
  };

  const handleClickFirstLast = (pageNum: number): void => {
    gotoPage(pageNum);
  };

  const isFirstPage = pageIndex === 0;
  const currentPageFrom = !dataLength ? 0 : isFirstPage ? 1 : pageIndex * pageSize + 1;
  const predictedCurrentPageTo = isFirstPage ? pageSize : currentPageFrom - 1 + pageSize;
  const currentPageTo = predictedCurrentPageTo > dataLength ? dataLength : predictedCurrentPageTo;

  return (
    <Flex container>
      <Flex item container>
        {showFirstLast ? (
          <button
            className={cn(styles.table_pagination__button, styles["table_pagination__button--first"])}
            disabled={!canPreviousPage}
            onClick={(): void => handleClickFirstLast(0)}
            title={`First page`}
          >
            <Icon name="doubleChevron" rotate={180} size="atom" />
          </button>
        ) : null}

        <button
          className={cn(styles.table_pagination__button, styles["table_pagination__button--prev"])}
          disabled={!canPreviousPage}
          onClick={handleClickPreviousPage}
          title="Previous page"
        >
          <Icon name="chevron" rotate={180} size="atom" />
        </button>
      </Flex>
      <Flex item>
        <>
          <div className={styles.table_pagination__per_page} ref={menuRef}>
            <button
              className={cn(styles.table_pagination__current_page, {
                [styles["table_pagination__current_page--active"]]: isMenuVisible,
              })}
              type="button"
              onClick={toggleMenu}
              title="Rows per page"
            >
              <Flex container>
                <Flex item itemGutter>
                  {currentPageFrom} to {currentPageTo}
                </Flex>
                <Flex item>
                  <Icon name="rectangle" size="atom" colour="grey_dark" />
                </Flex>
              </Flex>
            </button>

            {isMenuVisible ? (
              <div className={styles.table_pagination__page_sizes}>
                <span className={styles.table_pagination__page_sizes_title}>Rows:</span>

                {TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES.map((size) => (
                  <span
                    className={cn(styles.table_pagination__page_size, {
                      [styles["table_pagination__page_size--active"]]: size === pageSize,
                    })}
                    key={size}
                    onClick={(event): void => handleChangePageSize(size, event)}
                    onKeyPress={(event): void => handleChangePageSize(size, event)}
                    tabIndex={0}
                  >
                    {size}
                  </span>
                ))}
              </div>
            ) : null}
          </div>

          <span className={styles.table_pagination__total_items}>of {dataLength}</span>
        </>
      </Flex>
      <Flex item container>
        <button
          className={cn(styles.table_pagination__button, styles["table_pagination__button--next"])}
          disabled={!canNextPage}
          onClick={handleClickNextPage}
          title="Next page"
        >
          <Icon name="chevron" size="atom" />
        </button>

        {showFirstLast ? (
          <button
            className={cn(styles.table_pagination__button, styles["table_pagination__button--last"])}
            disabled={!canNextPage}
            onClick={(): void => handleClickFirstLast(pageCount - 1)}
            title={`Last page`}
          >
            <Icon name="doubleChevron" size="atom" />
          </button>
        ) : null}
      </Flex>
    </Flex>
  );
};
TablePagination.displayName = "TablePagination";
