import React from "react";
// Component
import { IconLabel, Tooltip } from "components";
// Hooks
import { useFormatDate } from "hooks";

interface AnimalPregnancyLabelProps {
  dueDate?: Date;
  id?: string;
  isMale?: boolean;
  isPregnant: boolean;
}

export const LABELS = {
  notApplicable: "n/a",
  notPregnant: "Not pregnant",
  pregnant: "Pregnant",
};

export const AnimalPregnancyLabel: React.FC<AnimalPregnancyLabelProps> = ({ dueDate, id, isMale, isPregnant }) => {
  if (isMale) return <>{LABELS.notApplicable}</>;
  if (!isPregnant) return <>{LABELS.notPregnant}</>;
  const { getFormatDate } = useFormatDate();
  return (
    <Tooltip title={`Due: ${getFormatDate(dueDate)}`}>
      <IconLabel icon="pregnant" colour="brown" id={id} label={LABELS.pregnant} />
    </Tooltip>
  );
};
