import { ColumnDef, Row } from "@tanstack/react-table";
import { IMPORT, SUPPORTED_SYSTEM_IMPORT } from "constants/Routes";
import React from "react";
import { Link } from "react-router-dom";
import { GeneticTestResultTableRow } from "../types";
import { Table } from "twComponents/Table";
import { TABLE_IDS } from "constants/Interface";
import RowToggleButton from "twComponents/Table/RowToggleButton";
import { Button } from "twComponents/Button";

type TableProps<TData> = {
  data: TData[];
  loading: boolean;
};

const renderTestMetricsRow = ({ row }: { row: Row<GeneticTestResultTableRow> }) => {
  return (
    <div className="p-2 bg-gray-50 flex justify-evenly overflow-x-auto max-w-full border-b-white border-solid shadow-lg">
      {row?.original?.metrics && row.original.metrics.length > 0
        ? row.original.metrics.map((metric) => (
            <div key={metric?.name} className="flex flex-col p-1 min-w-24">
              <p className="text-xs font-bold uppercase ">{metric?.name}:</p>
              <p className="text-xs">{metric?.value}</p>
            </div>
          ))
        : null}
    </div>
  );
};

const columns: ColumnDef<GeneticTestResultTableRow>[] = [
  {
    id: "expander",
    header: () => null,
    size: 4,
    maxSize: 4,
    cell: ({ row }) => {
      if (row.original?.metrics && row.original.metrics.length > 0) {
        return <RowToggleButton row={row} />;
      } else {
        return "-";
      }
    },
  },
  {
    accessorKey: "testName",
    header: "Test Name",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.date,
    id: "date",
    cell: (info) => info.getValue(),
    header: () => <span>Date uploaded</span>,
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.barcode,
    id: "barcode",
    cell: (info) => info.getValue(),
    header: () => <span>Barcode</span>,
    footer: (props) => props.column.id,
  },
];

const renderEmptyState = (
  <div className="pl-2 pt-2">
    No genetic test results found. Upload genetic partner test results
    <Button className="text-yellow-600 text-md" variant="text" size="xs">
      <Link to={`${IMPORT}${SUPPORTED_SYSTEM_IMPORT}`}>here</Link>
    </Button>
  </div>
);

export const GeneticTestResultsTable: React.FC<TableProps<GeneticTestResultTableRow>> = ({
  data,
  loading,
}): JSX.Element => {
  return (
    <>
      <div className="pb-4">
        <Table
          title="Genetic Test Results"
          loading={loading}
          tableId={TABLE_IDS.GENETIC_RESULTS}
          options={{ data, columns, enableSorting: false }}
          renderSubRow={renderTestMetricsRow}
          emptyStateComponent={renderEmptyState}
        />
      </div>
    </>
  );
};
