import { gql } from "@apollo/client";

import { pastureTypeFragment, fieldFragment } from "api/MyLivestock/Fields/fragment";

export const GET_FIELDS_LIST = gql`
  query GetFieldsList($businessUnitId: Int) {
    fields(businessUnitId: $businessUnitId) {
      ...fieldFragment
    }
  }
  ${fieldFragment}
`;

export const GET_PASTURE_TYPES = gql`
  query GetPastureTypes {
    pastureTypes {
      ...pastureTypeFragment
    }
  }
  ${pastureTypeFragment}
`;

export const GET_FIELD_BY_ID = gql`
  query GetField($id: UUID!) {
    field(id: $id) {
      ...fieldFragment
    }
  }
  ${fieldFragment}
`;

export const GET_ANIMALS_FROM_FIELD = gql`
  query GetAnimalsFromField($id: UUID!) {
    field(id: $id) {
      id
      animals {
        id
        visualId
        eId
        animalBreeds {
          id
          name
        }
        passportNumber
        sexClassification {
          slug
          title
        }
        dob
        dateMovedToFarm
        currentWeight
        lastWeight {
          date
          value
        }
        targetDailyLiveWeightGain
        isPregnant
        pregnancyDueDate
        rflId
        deliveryDate
        tags {
          tagId
          name
          color
          schema {
            ownership
          }
        }
        group {
          id
          name
          daysTillTarget
        }
        field {
          id
          name
          location {
            id
            unitName
          }
        }
        isRegulatorySynced
        lastRegulatorySyncedDate
        isMale
        isCastrated
      }
    }
  }
`;
