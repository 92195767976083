import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RowSelectionState } from "@tanstack/react-table";
import { TableId } from "constants/Interface";
import { RootState } from "./store";

const initialState: Partial<Record<TableId, RowSelectionState>> = {};

export const rowSelectionSlice = createSlice({
  name: "rowSelection",
  initialState,
  reducers: {
    toggleRowSelection: (state, action: PayloadAction<{ tableId: TableId; rowId: string }>) => {
      const { tableId, rowId } = action.payload;
      if (!state[tableId]) {
        state[tableId] = {};
      }
      (state[tableId] as RowSelectionState)[rowId] = !(state[tableId] as RowSelectionState)[rowId];
    },
    toggleAllRowsSelection: (state, action: PayloadAction<{ tableId: string }>) => {
      const { tableId } = action.payload;
      if (!state[tableId]) {
        state[tableId] = {};
      }
      const allRowsSelected = Object.values(state[tableId]).every((selected) => selected);

      Object.keys(state[tableId]).forEach((rowId) => {
        state[tableId][rowId] = !allRowsSelected;
      });
    },
    resetRowSelection: (state, action: PayloadAction<{ tableId: string }>) => {
      const { tableId } = action.payload;
      state[tableId] = {};
    },
  },
});

const rowSelectionSelector = (state: RootState, tableId: TableId) => state.rowSelection[tableId] || {};

const selectedAnimalIdsSelector = createSelector(
  [rowSelectionSelector, (_: RootState, tableId: TableId) => tableId],
  (rowSelection) => {
    return Object.keys(rowSelection).filter((rowId) => rowSelection[rowId]);
  },
);

export const { toggleRowSelection, toggleAllRowsSelection, resetRowSelection } = rowSelectionSlice.actions;
export const rowSelectionReducer = rowSelectionSlice.reducer;
export { selectedAnimalIdsSelector };
