import React, { useContext, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Column } from "react-table";
// Helpers
import { getBUFromLocalStorage } from "helpers/storage";
import { TableOptionsContext } from "config/tableOptionsProvider";
import {
  transactionTypeKeyToLabel,
  transactionColumnKeyToLabel,
  transactionColumnKeyToShortLabel,
} from "helpers/regulatory";
// Hooks
import { useFormatDate, useHasFeature } from "hooks";
// API
import {
  GET_REGULATORY_TRANSACTION_APPROVALS,
  GET_REGULATORY_TRANSACTION_APPROVALS_WITH_WARNINGS,
} from "api/Regulatory/queries";
// Components
import { ChangeDate, RejectApprove, ResendProblem, Abort } from "components/Regulatory";
import { AnimalsSexClassLabel } from "components/MyLivestock";
import { Button, LoadingOverlay, Flex, Spacer, Table, TablePaginationTarget, Text, Title } from "components";
// Utils
import { getRowSelectColumn } from "components/Common/Table";
// Types
import { SelectedCellProps, ColumnsType, TransactionType } from "components/Regulatory/Regulatory";
import {
  RegulatoryTransactionApprovals,
  RegulatoryTransactionApprovalsVariables,
  RegulatoryTransactionApprovals_regulatoryTransactionApprovals as TransactionApproval,
} from "api/Regulatory/types/RegulatoryTransactionApprovals";
import {
  RegulatoryTransactionApprovalsWithWarning,
  RegulatoryTransactionApprovalsWithWarningVariables,
} from "api/Regulatory/types/RegulatoryTransactionApprovalsWithWarning";
import { useHelp } from "hooks/useHelp";

const getTableTitle = ({
  transactionType,
  column,
}: {
  transactionType: TransactionType;
  column: ColumnsType;
}): string => {
  return `${transactionTypeKeyToLabel(transactionType)} - ${transactionColumnKeyToLabel(column)}`;
};

export const TransactionsTable: React.FC<SelectedCellProps> = ({
  selectedCell: { transactionType, statuses, column, refreshId },
  onSetSelectedCell,
}) => {
  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);
  const hasChangeDateFeature = useHasFeature("SHOW_REGULATORY_CHANGE_DATE_ACTION");
  const hasAbortFeature = useHasFeature("SHOW_REGULATORY_ABORT_ACTION");
  const businessUnitId = getBUFromLocalStorage();
  const { getFormatDate } = useFormatDate();
  const isWarnings = column === "";
  const { baseURL } = useHelp();

  const {
    data: approvalsData,
    loading: approvalsLoadinng,
    refetch: approvalsRefetch,
  } = useQuery<RegulatoryTransactionApprovals, RegulatoryTransactionApprovalsVariables>(
    GET_REGULATORY_TRANSACTION_APPROVALS,
    {
      variables: {
        businessUnitId,
        transactionType,
        statuses,
      },
      skip: !transactionType || isWarnings,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    },
  );

  const {
    data: approvalsWithWarningsData,
    loading: approvalsWithWarningsLoading,
    refetch: approvalsWithWarningsRefetch,
  } = useQuery<RegulatoryTransactionApprovalsWithWarning, RegulatoryTransactionApprovalsWithWarningVariables>(
    GET_REGULATORY_TRANSACTION_APPROVALS_WITH_WARNINGS,
    {
      variables: {
        businessUnitId,
        transactionType,
      },
      skip: !transactionType || !isWarnings,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    },
  );

  const transactions = ((isWarnings
    ? approvalsWithWarningsData?.regulatoryTransactionApprovalsWithWarning
    : approvalsData?.regulatoryTransactionApprovals) || []) as TransactionApproval[];

  const tableTitle: string = getTableTitle({ transactionType, column });

  const isRegistrationType: boolean = transactionType === "REGISTER_BIRTH";
  const isMoveOnType: boolean = transactionType === "MOVE_ON";
  const isMoveOffType: boolean = transactionType === "MOVE_OFF";
  const isDeathsType: boolean = transactionType === "REGISTER_DEATH";
  const isMoveType: boolean = isMoveOnType || isMoveOffType;
  const isReadyColumn: boolean = column === "pending";
  const isFailedColumn: boolean = column === "failed";
  const isLoading = approvalsLoadinng || approvalsWithWarningsLoading;

  const columnVisibilityRules = {
    dateOfDeath: isDeathsType,
    dam: isRegistrationType,
    destinationHoldingCphId: isMoveOnType || isMoveOffType,
    destinationHoldingName: isMoveOnType || isMoveOffType,
    moveDate: isMoveType,
    reasonOfFailure: !isReadyColumn,
    id: isReadyColumn || isFailedColumn,
  };

  const setRefreshId = (): void => {
    onSetSelectedCell((values) => ({
      ...values,
      refreshId: Math.random(),
    }));
  };

  const renderLocationInformation = (isMoveOnType: boolean, isId, destination, source, destinationLocation) => {
    const extractInfo = (location) => {
      if (location != null) {
        if (isId) {
          return location.regulatoryId;
        } else {
          return location.description;
        }
      } else {
        if (isId) {
          return destinationLocation?.locationId;
        } else {
          return destinationLocation?.unitName;
        }
      }
    };
    if (isMoveOnType) {
      return extractInfo(source);
    } else {
      return extractInfo(destination);
    }
  };

  const hiddenColumns = Object.keys(columnVisibilityRules).filter((key) => !columnVisibilityRules[key]);
  // having to create unique table ids due to issue table component getting confused about
  // data when switching between transaction types and columns
  const tableId = `regulatoryTransactionsTable_${transactionType}_${column}`;
  const tableOptions = getTableOptions<TransactionApproval>(tableId);
  const selectedRows: string[] = tableOptions?.selectedRows?.map(({ id }) => id) || [];
  const selectedRowsTotal = selectedRows?.length || 0;
  const memoizedTransactions = useMemo(() => transactions, [transactions]);
  //@ts-expect-error
  const columns: Column<TransactionApproval>[] = useMemo(
    () => [
      {
        ...getRowSelectColumn<TransactionApproval>({
          isRowSelectAll: true,
          tableId,
        }),
        id: "id",
        sticky: "left",
        minWidth: 50,
        width: 50,
      },
      {
        id: "createdDate",
        Header: "Created date",
        accessor: "createdAt",
        Cell: ({ value }): React.ReactNode => (value ? getFormatDate(value) : "\u2014"),
        minWidth: 100,
        width: 100,
        sticky: "left",
      },
      {
        id: "passportId",
        Header: "Passport Number",
        accessor: ({ sourceActivity }): string => sourceActivity?.animal?.passportNumber || "\u2014",
        minWidth: 150,
        width: 150,
        sticky: "left",
      },
      {
        id: "vid",
        Header: "VID",
        accessor: ({ sourceActivity }): string => sourceActivity?.animal?.visualId || "\u2014",
        minWidth: 100,
        width: 100,
      },
      {
        id: "dob",
        Header: "DOB",
        accessor: ({ sourceActivity }): string => sourceActivity?.animal?.dob,
        Cell: ({ value }): React.ReactNode => (value ? getFormatDate(value) : "\u2014"),
        minWidth: 100,
        width: 100,
      },
      {
        id: "sex",
        Header: "Sex",
        accessor: ({ sourceActivity }): string =>
          sourceActivity?.animal?.sexClassification?.slug || (sourceActivity?.animal?.isMale ? "M" : "F"),
        Cell: function renderSexLabel({ row }): React.ReactNode {
          const { animal = {} } = row?.original?.sourceActivity;

          return (
            <AnimalsSexClassLabel
              isCastrated={animal?.isCastrated}
              isMale={animal?.isMale}
              label={animal?.sexClassification?.title || null}
            />
          );
        },
        minWidth: 130,
        width: 130,
      },
      {
        id: "breed",
        Header: "Breed",
        accessor: ({ sourceActivity }): string => sourceActivity?.animal?.animalBreeds[0]?.name || "\u2014",
        minWidth: 130,
        width: 130,
      },
      {
        id: "dateOfDeath",
        Header: "Date of death",
        accessor: ({ sourceActivity }): string => sourceActivity?.date,
        Cell: ({ value }): React.ReactNode => (value ? getFormatDate(value) : "\u2014"),
        minWidth: 90,
        width: 90,
      },
      {
        id: "dam",
        Header: "DAM",
        accessor: ({ sourceActivity }): string => sourceActivity?.animal?.damPassportNumber || "\u2014",
        minWidth: 120,
        width: 120,
      },
      {
        id: "destinationHoldingCphId",
        Header: isMoveOnType ? "Origination holding CPH ID" : "Destination holding CPH ID",
        accessor: ({ destinationLocation, source, destination }): string =>
          renderLocationInformation(isMoveOnType, true, destination, source, destinationLocation) || "\u2014",
        minWidth: 120,
        width: 120,
      },
      {
        id: "destinationHoldingName",
        Header: isMoveOnType ? "Origination holding name" : "Destination holding name",
        accessor: ({ destinationLocation, source, destination }): string =>
          renderLocationInformation(isMoveOnType, false, destination, source, destinationLocation) || "\u2014",
        minWidth: 120,
        width: 120,
      },
      {
        id: "moveDate",
        Header: "Move date",
        accessor: ({ sourceActivity }): string => sourceActivity?.date,
        Cell: ({ value }): React.ReactNode => (value ? getFormatDate(value) : "\u2014"),
        minWidth: 90,
        width: 90,
      },
      {
        id: "reasonOfFailure",
        Header: "Error",
        accessor: ({ reasonOfFailure }): string => reasonOfFailure || "\u2014",
        Cell: function reasonOfFailure({ value }): JSX.Element {
          return (
            <Text smallest warning>
              {value}
            </Text>
          );
        },
      },
      {
        id: "actualStatus",
        Header: "Status",
        accessor: ({ actualStatus }): string => transactionColumnKeyToShortLabel(actualStatus) || "\u2014",
        minWidth: 90,
        width: 90,
        sticky: "right",
      },
    ],
    [tableId],
  );

  const handleHelpLinkClick = (): void => {
    window.open(baseURL, "_blank");
  };

  useEffect(() => {
    if (column || refreshId) {
      if (isWarnings) {
        approvalsWithWarningsRefetch();
      } else {
        approvalsRefetch();
      }
    }
  }, [column, refreshId, approvalsRefetch, approvalsWithWarningsRefetch, isWarnings]);

  useEffect(() => {
    setTableOptions({
      id: tableId,
      selectedRows: [],
      selectedRowsIds: null,
    });
  }, [transactionType, column, refreshId, approvalsRefetch, approvalsWithWarningsRefetch, isWarnings]);

  if (isLoading) return <LoadingOverlay />;

  if (!transactions.length && transactionType) {
    return (
      <Flex container>
        <Flex item itemGutter>
          <Text secondary>No pending registrations</Text>
        </Flex>
      </Flex>
    );
  }

  if (!transactions.length || !transactionType) return null;

  const showBulkActions = isReadyColumn || isFailedColumn;
  const showChangeDateBulkAction = hasChangeDateFeature && isReadyColumn && (isMoveOnType || isMoveOffType);
  const showAbortBulkAction = hasAbortFeature && (isReadyColumn || isFailedColumn);
  const showRejectApproveBulkAction = isReadyColumn;
  const showResendBulkAction = isFailedColumn;

  return (
    <>
      <Flex container containerJustifyContent="space-between">
        <Flex item itemGutter xs={6}>
          <Title secondary>{tableTitle}</Title>
        </Flex>
        <Flex container item itemGutter itemAlignSelf="flex-end">
          {!!selectedRowsTotal ? (
            <Flex item itemGutter>
              <Text smaller>Selected: {selectedRowsTotal}</Text>
            </Flex>
          ) : null}
          <Flex item itemGutter>
            <TablePaginationTarget tableId={tableId} />
          </Flex>
        </Flex>
      </Flex>

      <Spacer baselineHeight={1} />

      <Flex container>
        <Flex item itemGutter xs="fill">
          <Table<TransactionApproval>
            columns={columns}
            data={memoizedTransactions}
            hiddenColumns={hiddenColumns}
            pagination
            tableId={tableId}
          />
          <Spacer baselineHeight={2} />
        </Flex>
      </Flex>

      {showBulkActions ? (
        <>
          <Flex container xs="fill" containerJustifyContent="flex-end">
            <Flex item container itemGutter>
              {showChangeDateBulkAction ? (
                <Flex item itemGutter={showAbortBulkAction}>
                  <ChangeDate
                    selectedTransactions={selectedRows}
                    transactionType={transactionType}
                    onSuccessSubmit={setRefreshId}
                  />
                </Flex>
              ) : null}
              {showAbortBulkAction ? (
                <Flex item itemGutter={showResendBulkAction}>
                  <Abort
                    selectedTransactions={selectedRows}
                    transactionType={transactionType}
                    onSuccessSubmit={setRefreshId}
                  />
                </Flex>
              ) : null}
              {showRejectApproveBulkAction ? (
                <Flex item>
                  <RejectApprove
                    selectedTransactions={selectedRows}
                    transactionType={transactionType}
                    onSuccessSubmit={setRefreshId}
                  />
                </Flex>
              ) : null}
              {showResendBulkAction ? (
                <Flex item>
                  <ResendProblem
                    selectedTransactions={selectedRows}
                    transactionType={transactionType}
                    onSuccessSubmit={setRefreshId}
                  />
                </Flex>
              ) : null}
            </Flex>
          </Flex>
          <Spacer baselineHeight={1} />
          <Flex container xs="fill" containerDirection="row-reverse">
            <Flex item itemGutter>
              <Button variant="ghost" caption="Help with these options" onClick={handleHelpLinkClick} />
            </Flex>
          </Flex>
          <Spacer baselineHeight={6} />
        </>
      ) : null}
    </>
  );
};
