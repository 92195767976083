import React from "react";
// Libraries
import { Route, Switch, Redirect } from "react-router-dom";
// Utils
import {
  BU_ID,
  TRADING,
  DISTRIBUTION_LIST_CREATE,
  DISTRIBUTION_LIST_EDIT,
  DASHBOARD,
  CREATE_LISTING,
  EDIT_LISTING,
  VIEW_LISTING,
  VIEW_PUBLIC_LISTING,
  SUPPLY_CHAIN_FORM,
} from "constants/Routes";
import { useHasFeature } from "hooks";
// Pages

import CreateEditDistributionList from "trading/pages/DistributionLists/CreateEditDistributionList";
import Trading from "trading/pages";
import { CreateListing } from "trading/pages/Liveweight/CreateListing";
import { EditListing } from "trading/pages/Liveweight/EditListing";
import { ViewListing } from "trading/pages/Liveweight/ViewListing";
import { SupplyChainForm } from "supplyChains/SupplyChainForm";

const ROUTES_PATHS = {
  CreateDistributionList: `${TRADING}${DISTRIBUTION_LIST_CREATE}`,
  EditDistributionList: `${TRADING}${DISTRIBUTION_LIST_EDIT}`,
  Trading: `${BU_ID}${TRADING}/:tradingTabType(supply-chains|selling|buying|lists)?/:tradingSubTabType?`,
  CreateListing: `${BU_ID}${TRADING}${CREATE_LISTING}`,
  EditListing: `${BU_ID}${TRADING}${EDIT_LISTING}`,
  ViewListing: `${BU_ID}${TRADING}${VIEW_LISTING}`,
  ViewPublicListing: `${BU_ID}${TRADING}${VIEW_PUBLIC_LISTING}`,
  SupplyChainForm: `${SUPPLY_CHAIN_FORM}`,
} as const;

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

const TradingRoutes: React.FC = () => {
  const isShowDistributionLists: boolean = useHasFeature("SHOW_DISTRIBUTION_LISTS");

  const isDistributionListsEnable: boolean = isShowDistributionLists;

  const distributionListsRoutes = [
    <Route
      exact
      path={ROUTES_PATHS.CreateDistributionList}
      key={ROUTES_PATHS.CreateDistributionList}
      component={CreateEditDistributionList}
    />,
    <Route
      exact
      path={ROUTES_PATHS.EditDistributionList}
      key={ROUTES_PATHS.EditDistributionList}
      component={CreateEditDistributionList}
    />,
  ];

  const listingCreateEditRoutes = [
    <Route path={ROUTES_PATHS.CreateListing} key={ROUTES_PATHS.CreateListing} component={CreateListing} />,
    <Route path={ROUTES_PATHS.EditListing} key={ROUTES_PATHS.EditListing} component={EditListing} />,
  ];

  return (
    <Route exact path={PATHS}>
      <Switch>
        {isDistributionListsEnable ? distributionListsRoutes : null}
        {listingCreateEditRoutes}
        <Route path={ROUTES_PATHS.SupplyChainForm} key={ROUTES_PATHS.SupplyChainForm} component={SupplyChainForm} />,
        <Route path={[ROUTES_PATHS.ViewListing, ROUTES_PATHS.ViewPublicListing]} component={ViewListing} />
        <Route path={ROUTES_PATHS.Trading} component={Trading} />
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};

export default TradingRoutes;
