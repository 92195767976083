import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { BU_ID, DASHBOARD, SUPPLIER_MANAGEMENT, EDIT_SUPPLIER } from "constants/Routes";
import { BU_TYPES } from "trading/constants";
import { useHasFeature, useGetBusinessUnitUserRole } from "hooks";
import SupplierManagement from "pages/Settings/SupplierManagement";
import EditSupplier from "pages/Settings/SupplierManagement/EditSupplier";
import { getBUTypeFromLocalStorage } from "helpers/storage";

const ROUTES_PATHS = {
  SupplierManagement: `${BU_ID}${SUPPLIER_MANAGEMENT}/:supplierType?`,
  EditSupplier: `${BU_ID}${EDIT_SUPPLIER}/:supplierId`,
};

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

const SupplierRoutes: React.FC = () => {
  const { isAdmin } = useGetBusinessUnitUserRole();
  const selectedBUType = getBUTypeFromLocalStorage();

  const isShowSupplierManagement: boolean = useHasFeature("SUPPLIER_MANAGEMENT");
  const isEditFieldsmanButtonEnabled: boolean = useHasFeature("SUPPLIER_MANAGEMENT_EDIT_FIELDSMAN");
  const isShowSellersTabForFarmers: boolean = useHasFeature("SHOW_SELLERS_TAB_TO_FARMERS");

  const isProcessor: boolean = selectedBUType === BU_TYPES.processor;

  const isSupplierManagementEnable: boolean = isAdmin && isShowSupplierManagement;
  const isEditSupplierEnable: boolean =
    isAdmin && isEditFieldsmanButtonEnabled && (isProcessor || isShowSellersTabForFarmers);

  return (
    <Route exact path={PATHS}>
      <Switch>
        {isSupplierManagementEnable ? (
          <Route exact path={ROUTES_PATHS.SupplierManagement} component={SupplierManagement} />
        ) : null}
        {isEditSupplierEnable ? <Route exact path={ROUTES_PATHS.EditSupplier} component={EditSupplier} /> : null}
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};

export default SupplierRoutes;
