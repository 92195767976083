import { useQuery, QueryResult } from "@apollo/client";

import { GET_TERMS } from "api/Settings/queries";
import { GetTerms, GetTermsVariables } from "api/Settings/types/GetTerms";

export const useGetTermsConditions = (tacType = "BASIC"): QueryResult<GetTerms, GetTermsVariables> => {
  const { data, ...rest } = useQuery<GetTerms, GetTermsVariables>(GET_TERMS, {
    variables: { tacType },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  return {
    data,
    ...rest,
  };
};
