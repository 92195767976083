// Components
import { STAGE_STATUSES } from "../../constants";

export const getIsCurrentStage = ({ status, isLastStage }): boolean => {
  if (status === STAGE_STATUSES.ACTION) return true;

  if (status === STAGE_STATUSES.PENDING) return true;

  if (status === STAGE_STATUSES.FAILED) return true;

  return status === STAGE_STATUSES.COMPLETE && isLastStage;
};
