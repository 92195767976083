import { Field } from "components";
import { FieldProps } from "..";
import { BaseInput, BaseInputProps } from "../BaseInput";

type Props = { inputProps: BaseInputProps } & FieldProps;

export const InputText = (props: BaseInputProps) => <BaseInput {...props} type="text" />;

export const FieldText = ({ error, inputProps, ...props }: Props) => {
  return (
    <Field error={error} labelFor={props.labelFor || inputProps.name} {...props}>
      <BaseInput type="text" hasError={!!error} {...inputProps} />
    </Field>
  );
};
