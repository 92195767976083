import { VFC } from "react";
// Components
import { Icon, Spacer, Text } from "components";
// Assets
import styles from "./AnimalsBreedSummary.module.scss";

export interface AnimalsBreedSummaryProps {
  breedCode: string;
  speciesColour?: string;
  speciesIconUrl?: string;
  totalAnimals?: number;
  totalFemale?: number;
  totalMale?: number;
  isRegistered?: boolean;
}

export const AnimalsBreedSummary: VFC<AnimalsBreedSummaryProps> = ({
  breedCode,
  speciesColour,
  speciesIconUrl,
  totalAnimals,
  totalFemale,
  totalMale,
  isRegistered = true,
}) => {
  const animalsBreedSummaryStyle = speciesColour
    ? { ["--animal-breed-summary-colour" as string]: speciesColour }
    : undefined;

  return (
    <span className={styles.animals_breed_summary} style={animalsBreedSummaryStyle}>
      <span className={styles.animals_breed_summary__icon} title="Cattle">
        {speciesIconUrl ? (
          <Icon colour="white" size="medium" src={speciesIconUrl} />
        ) : (
          <Icon colour="white" name="cattle" size="medium" />
        )}
      </span>
      <Text colour="white">{isRegistered ? `${totalAnimals} ${breedCode}` : breedCode}</Text>
      <Spacer />
      {isRegistered && (totalFemale || totalMale) ? (
        <Text colour="white">
          {!!totalMale ? (
            <span className={styles.animals_breed_summary__sexcount} title="Male">
              {totalMale} <Icon name="animalSexMale" size="tiny" className={styles.animals_breed_summary__sexicon} />
            </span>
          ) : null}
          {!!totalFemale ? (
            <span className={styles.animals_breed_summary__sexcount} title="Female">
              {totalFemale}{" "}
              <Icon name="animalSexFemale" size="tiny" className={styles.animals_breed_summary__sexicon} />
            </span>
          ) : null}
        </Text>
      ) : null}
    </span>
  );
};
