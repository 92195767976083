import { Breadcrumbs } from "components/Common/Breadcrumbs";
import { Header } from "components/Common/Header";
import { PageContent } from "components/Structure/PageContent";
import { PageHeader } from "components/Structure/PageHeader";
import { AnimalStatus, DeliveryDirectionEnum, useDeliveryQuery, useUndoDeliveryMutation } from "generated/graphql";
import { getBusinessUnitIdFromLocalStorage } from "helpers/storage";
import { useHistory, useParams } from "react-router-dom";
import { getErrorMessage, removeNothings } from "helpers/general/all";
import { Spacer } from "components/Structure/Spacer";
import LoadingOverlay from "components/Common/LoadingOverlay";
import { useDeliveriesLabels } from "deliveries/hooks/useDeliveriesLabels";
import styles from "./Delivery.module.scss";
import { useLocale } from "helpers/translations/src/useLocale";
import { AnimalsList } from "animals/AnimalsList";
import { TABLE_IDS } from "constants/Interface";
import { useContext, useState } from "react";
import { Modal } from "components/Common/Modal";
import { CommonContext } from "config/commonProvider";
import { useHasFeature } from "hooks/useHasFeature";
import { Button } from "twComponents/Button";
import { InfoCard } from "twComponents/InfoCard";

export const Delivery = ({ location }) => {
  const { id } = useParams<DeliveryPageParams>();
  const businessUnitId = getBusinessUnitIdFromLocalStorage();
  const { getLocationsLabel, getDeliveryDateLabel, getDeliveryNameLabel } = useDeliveriesLabels();
  const { terms } = useLocale();
  const [showUndoConfirmationModal, setShowUndoConfirmationModal] = useState<boolean>(false);
  const [undoDelivery, { loading: loadingUndoDelivery }] = useUndoDeliveryMutation();
  const { showNotification } = useContext(CommonContext);
  const history = useHistory();

  const onUndoDelivery = async () => {
    try {
      const result = await undoDelivery({
        variables: {
          input: {
            delivery: id,
            businessUnit: businessUnitId,
          },
        },
      });

      const errors = result.data?.undoDelivery?.errors;

      if (errors) {
        showNotification({
          variant: "error",
          message: errors.map((item) => item?.message).join("; "),
        });
      } else {
        showNotification({
          message: "Movement successfully undone.",
        });

        history.goBack();
      }
    } catch (error: unknown) {
      showNotification({
        variant: "error",
        message: getErrorMessage(error),
      });
    }
  };

  const { data, loading } = useDeliveryQuery({
    variables: { businessUnit: businessUnitId, delivery: id },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const delivery = data?.delivery?.delivery;
  const animalCount = delivery?.animalCount || "—";
  const isMoveOff = delivery?.direction === DeliveryDirectionEnum.Outward;

  const consignments = data?.delivery?.consignments || undefined;

  const animalIds = removeNothings(
    consignments
      ? consignments.edges.flatMap((consignmentEdge) =>
          consignmentEdge?.node?.animals?.edges.map((animalEdge) => animalEdge?.node?.id),
        )
      : [],
  );

  const isUndoFoggleEnabled = useHasFeature("UNDO_MOVEMENT");
  const isNonNetworkMovement = delivery?.destinationSummary?.locations?.[0]?.contact?.id != undefined;
  const showUndoMovementButton = isUndoFoggleEnabled && isMoveOff && isNonNetworkMovement;

  return (
    <>
      <Modal
        active={showUndoConfirmationModal}
        title="Undo Movement"
        subTitle="This action will undo the entire movement and put the animals back on your farm."
        actions={{
          primary: {
            caption: "Confirm",
            disabled: loadingUndoDelivery,
            requesting: loadingUndoDelivery,
            onClick: onUndoDelivery,
          },
          secondary: {
            caption: "Cancel",
            onClick: () => setShowUndoConfirmationModal(false),
          },
        }}
      />
      <PageHeader>
        <Breadcrumbs
          rootPaths={[{ path: "Movement Dashboard", route: location?.state?.prevUrl || "" }]}
          currentPath={`Move ${isMoveOff ? "Off" : "On"}`}
        />
        <Header title={`Move ${isMoveOff ? "Off" : "On"}`} />
        <section className={styles.info_card_section}>
          <InfoCard title="Name" value={loading ? "—" : getDeliveryNameLabel(delivery) || ""} />
          <InfoCard
            title={`Date moved ${isMoveOff ? "off" : "on"} ${terms.farm}`}
            value={getDeliveryDateLabel(delivery)}
          />
          <InfoCard title={`${isMoveOff ? "Destination" : "Source"}`} value={getLocationsLabel(delivery)} />
          <InfoCard title="Total no. of livestock" value={animalCount.toString()} />
          <InfoCard title="Reason" value={delivery?.reason?.title || "—"} />
        </section>
        <Spacer baselineHeight={4} />
      </PageHeader>
      <Spacer baselineHeight={2} />
      <PageContent>
        {loading ? <LoadingOverlay /> : null}

        {showUndoMovementButton ? (
          <div className="ml-[10px]">
            <Button type="button" variant="destructive" onClick={() => setShowUndoConfirmationModal(true)}>
              Undo Movement
            </Button>
          </div>
        ) : null}
        <AnimalsList
          animalIds={animalIds}
          tableId={TABLE_IDS.DELIVERED_ANIMALS_TABLE}
          showSelectColumn={false}
          tableFilterIdentifier="animalsList"
          showAnimalActionButtons={false}
          showIndividualAnimalActionButtons={false}
          animalStatus={AnimalStatus.Any}
        />
      </PageContent>
    </>
  );
};
