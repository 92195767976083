import { FC, useState, useContext } from "react";
// Libraries
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { DateTime } from "luxon";
// API
import { ContactContactType } from "api/graphql-global-types";
import { LOG_ACTIVITY_BULK_MUTATION } from "api/MyLivestock/Activity/mutations";
// Utils
import { CommonContext } from "config/commonProvider";
import { ACTIVITY_TEMPLATE_PREVIOUS_KEEPER, ADD_PREVIOUS_KEEPER_TEMPLATE } from "constants/Animals";
import { getBUFromLocalStorage } from "helpers/storage";
// Components
import { Button, Modal, Spacer, Text } from "components";
import { AddressBookSelect } from "components/Settings/AddressBook/AddressBookSelect";
import { useGetActivityTemplates, useHasFeature } from "hooks";
import { formatAddressString } from "settings/components/ContactsTable";
import { ContactFragmentFragment } from "generated/graphql";
import { ContactForm } from "components/ContactForm";

interface Props {
  animalIds: Array<string>;
  isShowModal?: boolean;
  isSingleAnimal?: boolean;
  onClose?: () => void;
}

export type ModalContent = "previousKeeperForm" | "createNewContactForm";

export const ConfirmAddPreviousKeeperModal: FC<Props> = ({
  animalIds = [],
  isShowModal = false,
  isSingleAnimal = false,
  onClose,
}) => {
  const [showModal, setShowModal] = useState(isShowModal);
  const businessUnit = getBUFromLocalStorage();
  const { showNotification } = useContext(CommonContext);
  const [modalContent, setModalContent] = useState<ModalContent>("previousKeeperForm");
  const isCreateOnMovementEnabled = useHasFeature("CREATE_INWARD_MOVEMENTS");

  const previousKeeperLabel = isCreateOnMovementEnabled ? "source address" : "previous keeper";

  const { data: activityTemplates } = useGetActivityTemplates();

  const activityTemplate = activityTemplates?.find((el) => el.slug === ADD_PREVIOUS_KEEPER_TEMPLATE);

  const [logActivityBulk, { loading }] = useMutation(LOG_ACTIVITY_BULK_MUTATION);

  const handleModalToggle = (): void => {
    setShowModal(!showModal);
    onClose && onClose();
  };

  const handleConfirmClick = async (values): Promise<void> => {
    const fromBusinessUnitName = activityTemplate?.fields.find(
      (field) => field.slug === ACTIVITY_TEMPLATE_PREVIOUS_KEEPER.fromBUName,
    );
    const fromBusinessUnitAddress = activityTemplate?.fields.find(
      (field) => field.slug === ACTIVITY_TEMPLATE_PREVIOUS_KEEPER.fromBUAddress,
    );
    const sourceHoldingId = activityTemplate?.fields.find(
      (field) => field.slug === ACTIVITY_TEMPLATE_PREVIOUS_KEEPER.sourceHoldingId,
    );
    const address = formatAddressString(values);
    try {
      const { data } = await logActivityBulk({
        variables: {
          input: {
            date: DateTime,
            activityTemplate: Number(activityTemplate?.id),
            animals: animalIds,
            businessUnit,
            payload: {
              data: [
                { id: Number(fromBusinessUnitName?.id), value: values.name },
                { id: Number(fromBusinessUnitAddress?.id), value: address || null },
                { id: Number(sourceHoldingId?.id), value: values.farmId || null },
              ],
              name: "Add Previous Keeper",
            },
          },
        },
      });

      const errors = data?.logActivityBulk.errors;
      if (errors && errors.length > 0) {
        handleModalToggle();
        showNotification({
          variant: "error",
          message: errors[0].message,
        });
      } else {
        handleModalToggle();
        showNotification({
          message: `${previousKeeperLabel} successfully added.`,
        });
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Error adding Previous Keeper",
      });
    }
  };

  const initialValuesDefault = {
    contact: "",
    name: "",
    organisationName: "",
    addressLine1: "",
    addressLine2: "",
    farmId: "",
    city: "",
    country: "",
    postcode: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
    contactType: ContactContactType.FARM,
    notes: "",
  };

  return (
    <>
      {!isSingleAnimal ? <Button caption={`Add ${previousKeeperLabel}`} onClick={handleModalToggle} /> : null}

      <Modal
        buttonsLeftSide
        active={showModal}
        handleClose={handleModalToggle}
        title={`Add ${previousKeeperLabel}`}
        subTitle=""
        showCloseButton
      >
        <Formik
          enableReinitialize
          onSubmit={handleConfirmClick}
          initialValues={initialValuesDefault}
          validateOnChange={false}
        >
          {({ values, setValues, handleSubmit }): JSX.Element => {
            const handleContactChange = (contact: ContactFragmentFragment | undefined): void => {
              const newValues = {
                contact: contact?.id || "",
                name: contact?.name || "",
                organisationName: contact?.organisationName || "",
                addressLine1: contact?.addressLine1 || "",
                addressLine2: contact?.addressLine2 || "",
                farmId: contact?.farmId || "",
                city: contact?.city || "",
                country: contact?.country?.id || "",
                postcode: contact?.postcode || "",
                countryCode: contact?.country?.code || "",
                phoneNumber: contact?.phoneNumber || "",
                email: contact?.email || "",
              };
              const updatedValues = {
                ...values,
                ...newValues,
              };
              setValues(updatedValues);
            };
            return (
              <>
                {modalContent === "createNewContactForm" ? (
                  <ContactForm
                    contactId={undefined}
                    onCancel={() => setModalContent("previousKeeperForm")}
                    onConfirm={() => setModalContent("previousKeeperForm")}
                  />
                ) : (
                  <>
                    <Text smaller>
                      {`You are about to assign a ${previousKeeperLabel} to the selected animals.`}{" "}
                      <b>This cannot be undone.</b>
                    </Text>
                    <Spacer baselineHeight={1} />
                    <AddressBookSelect
                      value={values.contact}
                      onContactChange={handleContactChange}
                      label=""
                      contactType={ContactContactType.FARM}
                      onCreateNewContact={() => {
                        setModalContent("createNewContactForm");
                      }}
                    />

                    <div style={{ display: "flex" }}>
                      <Spacer baselineHeight={1} />
                      <Button
                        caption="Confirm"
                        onClick={handleSubmit}
                        type="submit"
                        disabled={!values.contact}
                        requesting={loading}
                      />
                    </div>
                  </>
                )}
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
