import { LOT_STATUSES } from "trading/constants";

export const isListingTradeAgreed = (status: string): boolean => {
  return [
    LOT_STATUSES.AWAITING_PAYMENT,
    LOT_STATUSES.AWAITING_ACCEPTANCE,
    LOT_STATUSES.BUYER_MADE_PAYMENT_TO_BREEDR,
    LOT_STATUSES.BREEDR_RECEIVED_PAYMENT,
    LOT_STATUSES.SELLER_SENT_ANIMALS,
  ].includes(status);
};
