import { useContext, VFC } from "react";
// Libraries
import cn from "classnames";
// Utils
import { STAGE_STATUSES, PRIVATE_STAGE_STATUSES, STAGE_STATUS_PILL_COLOURS } from "./constants";
import { ICON_NAMES, ICON_SIZES } from "constants/Icons";
import { getIsCurrentStage } from "./helpers";
// Components
import { Flex, Icon, Pill, Spacer, Text } from "components";
import { StagesContext } from "../";
// Resources
import styles from "../Stages.module.scss";

interface StageBase {
  children?: never;
  icon: keyof typeof ICON_NAMES;
  iconSize?: keyof typeof ICON_SIZES;
  title: string;
}

export interface StageWithStatus extends StageBase {
  status: ValuesOf<typeof STAGE_STATUSES>;
  statusLabel?: string;
}

export interface StageWithoutStatus extends StageBase {
  status?: never;
  statusLabel?: never;
}

export type StageProps = StageWithStatus | StageWithoutStatus;

export const Stage: VFC<StageProps> = ({
  icon,
  iconSize,
  status = PRIVATE_STAGE_STATUSES.NEUTRAL,
  statusLabel,
  title,
}) => {
  const lastStageTitle = useContext(StagesContext);

  const isAction = status === STAGE_STATUSES.ACTION;
  const isComplete = status === STAGE_STATUSES.COMPLETE;

  const isLastStage = lastStageTitle === title;
  const isCurrentStage = getIsCurrentStage({ status, isLastStage });

  const iconColour = isAction ? "green" : isComplete || isCurrentStage ? "white" : "grey800";
  const textColour = isCurrentStage ? "green" : isComplete ? "blue" : "grey";

  const titleId = `stage_${title}`;

  const isStatusLabelHidden = status === PRIVATE_STAGE_STATUSES.NEUTRAL;

  return (
    <Flex
      className={cn(styles.stage, {
        [styles[`stage--status__${status}`]]: true,
      })}
      container
      containerAlignItems="center"
      containerDirection="column"
      item
      key={title}
    >
      <figure className={styles.stage__icon}>
        <Icon ariaLabelledBy={titleId} colour={iconColour} size={iconSize} name={icon} />
      </figure>

      <Spacer allowDecimal baselineHeight={0.5} />

      <Text colour={textColour} id={titleId} smaller>
        {isCurrentStage ? <strong>{title}</strong> : title}
      </Text>

      <Spacer allowDecimal baselineHeight={0.5} />

      {statusLabel && !isStatusLabelHidden ? (
        <Pill caption={statusLabel as string} colour={STAGE_STATUS_PILL_COLOURS[status]} role="status" />
      ) : null}
    </Flex>
  );
};
