// @ts-nocheck
// React
import React from "react";
// Libraries
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Flex } from "components";
// Resources
import styles from "./styles.module.css";

interface StepLineProps {
  activeStep: number;
  classes: Record<string, unknown>;
  stepsCount?: number;
}

const stylesCustom = (theme): Record<string, unknown> => ({
  root: {
    width: "10%",
  },
  active: {
    color: "green",
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  connectorActive: {
    "& $connectorLine": {
      borderColor: "#F2C434",
    },
  },
  connectorLine: {
    transition: theme.transitions.create("border-color"),
    borderWidth: 3,
    borderColor: "#E6EAEC",
  },
});

const StepLine: React.FC<StepLineProps> = ({ activeStep, classes, stepsCount = 4 }) => {
  const connector = (
    <StepConnector
      classes={{
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  );

  return (
    <Flex container>
      <Flex item itemGutter itemGrow xs={12}>
        <Stepper activeStep={activeStep} alternativeLabel connector={connector}>
          {[...Array(stepsCount).keys()].map((label, idx) => (
            <Step
              key={label}
              className={styles.stepDot}
              classes={{
                root: styles.stepDot,
                completed: styles.stepDotActive,
              }}
            >
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: styles.stepDot,
                    active: styles.stepDotActive,
                    completed: styles.stepDotActive,
                  },
                }}
              >
                <Typography className={activeStep === idx ? styles.stepDotActive : styles.stepDot} variant="caption">
                  Step {label + 1}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Flex>
    </Flex>
  );
};

export default withStyles(stylesCustom)(StepLine);
