import { Spacer, Text, Alert, Title, Image } from "components";
import pro from "assets/img/Subscription/pro.png";
import { useCreateStripePortalSessionMutation } from "generated/graphql";
import { useState } from "react";
import { useGetBusinessUnitUserRole } from "hooks";
import { getErrorMessage } from "helpers/general/all";
import { Button } from "twComponents/Button";
import { useHistory } from "react-router-dom";
import { getBusinessUnitIdFromLocalStorage } from "helpers/storage";
import { DASHBOARD } from "constants/Routes";
import { isMobile } from "react-device-detect";

export const PaymentSuccess = () => {
  const { isAdmin } = useGetBusinessUnitUserRole();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const history = useHistory();
  const businessUnitId = getBusinessUnitIdFromLocalStorage();

  const [createStripePortalSession] = useCreateStripePortalSessionMutation();

  const handleOpenStripe = async () => {
    try {
      if (!isAdmin) {
        setErrorMessage("You must be an admin to view subscription details.");
        return;
      }

      const { data } = await createStripePortalSession({
        variables: {
          input: {
            businessUnitId: +businessUnitId,
            returnUrl: window.location.href,
          },
        },
      });

      if (data && data?.createStripePortalSession && document.location.href) {
        document.location.href = data?.createStripePortalSession.sessionUrl;
      }
    } catch (error: unknown) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  const supportEmail = "support@breedr.co";

  return (
    <main className="px-4 lg:px-12 bg-gray-100 h-screen flex justify-center">
      <div className="flex flex-col lg:flex-row-reverse pt-4">
        <div className="lg:w-1/2 px-2">
          <Image src={pro} style={{ maxHeight: "70vh" }} />
        </div>
        <div className="lg:w-1/2 mt-4 lg:mt-0 px-2">
          <div className="bg-white py-4 px-5 rounded-lg">
            <Title secondary>Payment Successful!</Title>
            <Spacer baselineHeight={2} />
            <Text>
              Thank you for subscribing to Breedr. Email our customer success team at{" "}
              <a href={`mailto:${supportEmail}`}>support@breedr.co</a> to book your onboarding session.
            </Text>
            <Spacer baselineHeight={2} />

            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 sm:max-w-fit lg:mt-4">
              {isMobile ? (
                <>
                  <Button asChild type="button" onClick={() => history.push(DASHBOARD)} size="lg">
                    <a href="https://breedr.page.link/?link=https://uk.breedr.co/link/home&apn=com.breedrmobile&isi=1435530409&ibi=co.breedr.breedrapp&efr=1">
                      Continue to mobile app
                    </a>
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={handleOpenStripe}
                    disabled={Boolean(errorMessage)}
                    size="lg"
                  >
                    View invoice history
                  </Button>
                </>
              ) : (
                <>
                  <Button type="button" onClick={() => history.push(DASHBOARD)}>
                    Continue to dashboard
                  </Button>
                  <Button type="button" variant="outline" onClick={handleOpenStripe} disabled={Boolean(errorMessage)}>
                    View invoice history
                  </Button>
                </>
              )}
            </div>
            {errorMessage ? <Alert text={errorMessage} color="RED" /> : null}
          </div>
        </div>
      </div>
    </main>
  );
};
