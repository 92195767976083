import { useQuery, QueryResult } from "@apollo/client";

import { GET_TRADING_TYPES } from "trading/api/queries";
import { GetTradingTypes } from "trading/api/types/GetTradingTypes";

export const useGetTradingTypes = (): QueryResult<GetTradingTypes> => {
  const data = useQuery<GetTradingTypes>(GET_TRADING_TYPES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  return data;
};
