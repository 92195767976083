function TagIcon({ fill }: { fill: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M.51 13.02A2 2 0 000 14.357V22a2 2 0 002 2h20a2 2 0 002-2v-7.644a2 2 0
        00-.51-1.335l-6.244-6.964c-.16-.179-.267-.4-.306-.637l-.383-2.324A3.259 3.259 0
        0014.033.441l-.675-.147a6.405 6.405 0 00-2.714 0l-.552.12A3.707 3.707 0
        007.184 3.71L7.03 5.429a1.07 1.07 0 01-.269.62L.511 13.02zM12 8.025a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M23.5 22v-7.644a1.5 1.5 0 00-.383-1.002l-6.244-6.963a1.76
        1.76 0 01-.426-.89l-.383-2.323A2.759 2.759 0 0013.927.929l-.675-.146a5.905
        5.905 0 00-2.504 0l-.55.12a3.208 3.208 0 00-2.516 2.851l-.153 1.719a1.57
        1.57 0 01-.394.909L.883 13.354A1.5 1.5 0 00.5 14.356V22A1.5 1.5
        0 002 23.5h20a1.5 1.5 0 001.5-1.5zM.51 13.02A2 2 0 000 14.357V22a2 2 0
        002 2h20a2 2 0 002-2v-7.644a2 2 0 00-.51-1.335l-6.244-6.964c-.16-.179-.267-.4-.306-.637l-.383-2.324A3.259
        3.259 0 0014.033.441l-.675-.147a6.405 6.405 0 00-2.714 0l-.552.12A3.707 3.707 0 007.184 3.71L7.03
        5.429a1.07 1.07 0 01-.269.62L.511 13.02z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 7.524a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 .5a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TagIcon;
