import { BU_ID, TRANSACTIONS, DASHBOARD, FINANCIAL_TRANSACTIONS, TRANSACTION_ID } from "constants/Routes";
import { FinancialTransactions } from "financial/pages/FinancialTransactions";
import { Transaction } from "financial/pages/Transaction";
import { Redirect, Route, Switch } from "react-router-dom";

const ROUTES_PATHS = {
  FinancialTransactions: `${FINANCIAL_TRANSACTIONS}`,
  CreateEditTransaction: `${BU_ID}${FINANCIAL_TRANSACTIONS}${TRANSACTIONS}${TRANSACTION_ID}`,
};

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

export const FinancialRoutes = () => {
  return (
    <Route exact path={PATHS}>
      <Switch>
        <Route exact path={ROUTES_PATHS.FinancialTransactions} component={FinancialTransactions} />
        <Route exact path={ROUTES_PATHS.CreateEditTransaction} component={Transaction} />,
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};
