import { SyntheticEvent, FC } from "react";
import { SETTINGS, USER_SETTINGS } from "constants/Routes";
import { DashboardAvatarButton } from "containers/dashboard";
import { DropdownMenu } from "components";
import { useAuth } from "auth/hooks/useAuth";

export const DashboardUser: FC = () => {
  const { logout } = useAuth();

  const onLogout = (event: SyntheticEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    logout();
  };

  const settingsURL = `${SETTINGS}${USER_SETTINGS}`;

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <DashboardAvatarButton />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        <DropdownMenu.Link text="Settings" to={settingsURL} />
        <DropdownMenu.Separator />
        <DropdownMenu.Action onClick={onLogout} text="Logout" />
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};
