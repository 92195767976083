// React
import React from "react";
// Utils
import { CONFIRMATION_MODALS } from "constants/Settings";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { handleEnterPress, handleErrorCheck, validationSchemaInviteUser } from "helpers/general";
import { useBreakpoints } from "hooks";
import { BusinessUnitUserStatus } from "api/graphql-global-types";
// Components
import FormBuilder from "components/Common/FormBuilder";
import { Button, Flex, FieldSelect, FieldText, Spacer, TextButton } from "components";
import { useRolesListQuery } from "generated/graphql";

export interface UserFormInput {
  businessUnitName: string;
  email: string;
  firstName: string;
  lastName: string;
  roleId: string;
  status: string;
  userId: string;
  businessUnitUserId: string;
}

interface InviteUserFormProps {
  onCloseForm: () => void;
  editMode: boolean;
  isLoading: boolean;
  onSubmit: (input: UserFormInput) => void;
  handleShowConfirmModal: (type: string) => void;
  formData: UserFormInput;
  isCurrentLoggedInUser?: boolean;
}

export const UserForm: React.FC<InviteUserFormProps> = ({
  onCloseForm,
  editMode,
  onSubmit,
  formData,
  isLoading,
  handleShowConfirmModal,
  isCurrentLoggedInUser = false,
}) => {
  const { isBreakpointL } = useBreakpoints();

  const { data } = useRolesListQuery();
  const roles = data?.roles;

  return (
    <FormBuilder
      validateOnChange={false}
      initialValues={formData}
      onSubmit={onSubmit}
      validationSchema={!editMode && validationSchemaInviteUser}
    >
      {({ values, errors, handleChange, handleSubmit }): JSX.Element => (
        <>
          <Flex container>
            <Flex item itemGutter xs={12} l={6} xl={4}>
              {!editMode ? (
                <>
                  <FieldText
                    label="First Name"
                    error={handleErrorCheck(errors.firstName)}
                    inputProps={{
                      name: "firstName",
                      value: values.firstName,
                      onChange: handleChange,
                      onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                      autoFocus: true,
                    }}
                  />

                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}

                  <FieldText
                    label="Last Name"
                    error={handleErrorCheck(errors.lastName)}
                    inputProps={{
                      name: "lastName",
                      value: values.lastName,
                      onChange: handleChange,
                      onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                    }}
                  />

                  {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}
                </>
              ) : null}

              <FieldText
                label="Email"
                error={handleErrorCheck(errors.email)}
                inputProps={{
                  name: "email",
                  value: values.email,
                  onChange: handleChange,
                  onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                  readOnly: editMode,
                  disabled: editMode,
                }}
              />

              {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}

              <FieldSelect
                label="User type"
                error={handleErrorCheck(errors.roleId)}
                inputProps={{
                  name: "roleId",
                  value: values.roleId,
                  options: roles
                    ? roles.map((r) => ({
                        key: r?.id,
                        label: r?.name,
                        value: r?.id,
                      }))
                    : [],
                  onChange: handleChange,
                }}
              />

              {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}

              <FieldText
                label="Access to"
                helper="This field is set by admin"
                error={handleErrorCheck(errors.accessTo)}
                inputProps={{
                  name: "businessUnitName",
                  value: values.businessUnitName,
                  disabled: true,
                  onChange: handleChange,
                  onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
                }}
              />
              {editMode ? (
                <FieldSelect
                  label="Account status"
                  error={handleErrorCheck(errors.status)}
                  inputProps={{
                    name: "status",
                    value: values.status,
                    disabled: isCurrentLoggedInUser || values.status === BusinessUnitUserStatus.INVITED,
                    options: [
                      {
                        key: BusinessUnitUserStatus.INACTIVE,
                        label: "Disabled",
                        value: BusinessUnitUserStatus.INACTIVE,
                      },
                      {
                        key: BusinessUnitUserStatus.ACTIVE,
                        label: "Enabled",
                        value: BusinessUnitUserStatus.ACTIVE,
                      },
                    ],
                    onChange: handleChange,
                  }}
                />
              ) : null}
            </Flex>
          </Flex>

          {editMode ? (
            <Flex container>
              <Flex item itemGutter>
                {!isBreakpointL ? <Spacer baselineHeight={1} /> : null}

                <TextButton
                  caption="Force password reset"
                  id={ELEMENTS_IDS.FORCE_PASSWORD_RESET_BUTTON}
                  onClick={(): void => handleShowConfirmModal(CONFIRMATION_MODALS.RESET_PASSWORD)}
                  secondary
                />
              </Flex>
            </Flex>
          ) : null}

          <Spacer baselineHeight={!isBreakpointL ? 3 : 2} />

          <Flex container>
            <Flex item itemGutter>
              <Button
                id={ELEMENTS_IDS.INVITE_USER_FORM_CANCEL_BUTTON}
                caption="Cancel"
                colour="grey"
                variant="hollow"
                onClick={onCloseForm}
              />
            </Flex>

            <Flex item itemGutter>
              <Button
                id={ELEMENTS_IDS.INVITE_USER_FORM_SAVE_CHANGES_BUTTON}
                disabled={isLoading}
                requesting={isLoading}
                caption="Save changes"
                onClick={handleSubmit}
              />
            </Flex>
          </Flex>
        </>
      )}
    </FormBuilder>
  );
};
