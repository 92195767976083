import { FC, useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import * as Sentry from "@sentry/react";
import { useParams, useLocation } from "react-router-dom";
import {
  setBUToLocalStorage,
  setBUTypeToLocalStorage,
  setLocationsCountToLocalStorage,
  getBUFromLocalStorage,
  setFeaturesListToLocalStorage,
} from "helpers/storage";
import { useGetCurrentBusinessUnit, useGetBusinessUnits, useGetCurrentUserInfo, useStateSpecies } from "hooks";
import { SnackBarNotification } from "components";
import { CommonContext } from "config/commonProvider";
import { useAuth } from "auth/hooks/useAuth";

const DashboardWatcher: FC = () => {
  const { logout } = useAuth();
  const [hasSentAppStartup, setHasSentAppStartup] = useState(false);
  const { changeMainCommonState, mainCommonState, changeAnimalsFilter, resetAnimalsFilter } = useContext(CommonContext);

  const { data: businessUnits, loading } = useGetBusinessUnits();
  const { data: userInfo } = useGetCurrentUserInfo();

  const { buId } = useParams<DashboardPageParams>();
  const { pathname } = useLocation();

  const selectedBU = getBUFromLocalStorage();

  const { locationsCount, foggles: buFeatures } = useGetCurrentBusinessUnit();

  const { autoHideDuration, isShow = false, variant = "", message = "" } = mainCommonState?.notification || {};

  useEffect(() => {
    if (buId && businessUnits && businessUnits.length > 0) {
      const selectedActual = businessUnits.find((item) => item?.id === buId);

      if (selectedActual) {
        setBUToLocalStorage(selectedActual.id);
        if (selectedActual?.businessType?.slug) {
          setBUTypeToLocalStorage(selectedActual.businessType.slug);
        }
        if (selectedActual.foggles) {
          setFeaturesListToLocalStorage(selectedActual.foggles.toString());
        }
      }
    }
  }, [buId, businessUnits, businessUnits.length]);

  useEffect(() => {
    if (locationsCount != null) {
      setLocationsCountToLocalStorage(`${locationsCount}`);
    }
  }, [locationsCount]);

  useEffect(() => {
    if (userInfo?.userInfo && selectedBU && businessUnits.length) {
      if (!hasSentAppStartup) {
        const { firstName, lastName, email } = userInfo.userInfo;
        const activeBusinessUnit = businessUnits.find((item) => item?.id === String(selectedBU));
        const farmAnimalTypes = activeBusinessUnit?.farmAnimalTypes.map((species) => species.name);
        const subscriptionPlan =
          activeBusinessUnit?.effectiveSubscriptionPlan != null
            ? activeBusinessUnit.effectiveSubscriptionPlan.name
            : null;

        // @ts-ignore
        pendo.initialize({
          visitor: {
            id: email,
            firstName,
            lastName,
            businessUnitName: activeBusinessUnit?.name,
            postcode: activeBusinessUnit?.postcode,
            country: activeBusinessUnit?.country?.name,
            farmAnimalTypes,
            subscriptionPlan,
          },
          account: {
            id: `${activeBusinessUnit?.id}-${activeBusinessUnit?.name}`,
          },
        });
        Sentry.setUser({ UserId: userInfo?.userInfo?.id });
        setHasSentAppStartup(true);
      }
    }
  }, [userInfo?.userInfo?.id, selectedBU, businessUnits.length]);

  useEffect(() => {
    if (
      !isEmpty(mainCommonState.animalsFilters) &&
      !pathname.includes("animal") &&
      !pathname.includes("group/") &&
      !pathname.includes("batch/") &&
      !pathname.includes("location/") &&
      !pathname.includes("offer/") &&
      !pathname.includes("listing") &&
      !pathname.includes("archive")
    ) {
      resetAnimalsFilter();
    }
  }, [pathname, mainCommonState, changeAnimalsFilter]);

  const { activeSpecies } = useStateSpecies();
  useEffect(() => {
    const isAnimalsFiltersEmpty = isEmpty(mainCommonState.animalsFilters);
    const isAnimalsFiltersValuesEmpty = Object.values(mainCommonState.animalsFilters).every(
      (value) => value === "" || value === null,
    );
    if (!isAnimalsFiltersEmpty && !isAnimalsFiltersValuesEmpty) {
      resetAnimalsFilter();
    }
  }, [activeSpecies]);

  useEffect(() => {
    if (buFeatures) {
      setFeaturesListToLocalStorage(buFeatures.toString());
    }
  }, [buFeatures]);

  if (!loading && businessUnits.length === 0) {
    logout();
  }

  if (!selectedBU && businessUnits && businessUnits.length > 0) {
    const isSelectedActual = !!businessUnits.find((item) => Number(item?.id) === selectedBU);

    if (!isSelectedActual) {
      setBUToLocalStorage(businessUnits[0]?.id || "");
      setBUTypeToLocalStorage(businessUnits[0]?.businessType?.slug || "");
      if (businessUnits[0]?.foggles) {
        setFeaturesListToLocalStorage(businessUnits[0].foggles.toString());
      }
    }
  }

  return (
    <SnackBarNotification
      autoHideDuration={autoHideDuration}
      open={isShow || false}
      variant={variant || "default"}
      message={message || ""}
      onClose={(): void =>
        changeMainCommonState({
          ...mainCommonState,
          notification: null,
        })
      }
    />
  );
};

export default DashboardWatcher;
