// React
import React, { forwardRef } from "react";
// Libraries
import cn from "classnames";
// Components
import { Icon } from "components";
import { IconProps } from "components/Common/Icon";
// Resources
import spinner from "assets/img/Auth/trimmed-logo.png";
import spinnerDark from "assets/img/Auth/trimmed-logo-dark.png";
import styles from "./Button.module.scss";

interface ButtonIconBaseProps {
  position: "left" | "right";
}

interface ButtonIconLegacyProps extends ButtonIconBaseProps {
  image: any;
  props?: never;
}

interface ButtonIconProps extends ButtonIconBaseProps {
  image?: never;
  props: IconProps;
}

export interface ButtonProps {
  id?: string;
  caption?: string;
  className?: string;
  "data-testid"?: string;
  width?: "auto" | "two-third" | "full";
  height?: "small" | "medium" | "large";
  colour?: "yellow" | "grey" | "red" | "green";
  variant?: "solid" | "hollow" | "ghost";
  icon?: ButtonIconLegacyProps | ButtonIconProps;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
  requesting?: boolean;
  // "any" because we have a lot of different kinds of events and return values
  onClick?: (event: React.SyntheticEvent<any>) => any;
}

const ButtonIcon: React.FC<Omit<ButtonIconLegacyProps | ButtonIconProps, "position">> = ({ image, props }) => (
  <span className={styles.button__button_icon}>
    <Icon {...(!props && image && { image })} {...props} />
  </span>
);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      caption,
      className,
      colour = "yellow",
      disabled = false,
      height = "small",
      icon,
      onClick,
      requesting = false,
      variant = "solid",
      width = "auto",
      type = "button",
      ...props
    }: ButtonProps,
    forwardedRef,
  ): JSX.Element => (
    <button
      type={type}
      className={cn(
        styles.button,
        {
          [styles[`button--colour__${colour}`]]: true,
          [styles[`button--height__${height}`]]: true,
          [styles[`button--variant__${variant}`]]: true,
          [styles[`button--width__${width}`]]: true,
        },
        className,
      )}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      ref={forwardedRef}
      {...props}
    >
      {disabled && requesting ? (
        <span className={cn(styles.button__button_icon, styles["button__button_icon--loading"])}>
          <img
            src={variant === "solid" ? spinnerDark : spinner}
            alt="Breedr logo spinner"
            className={styles.button__button_spinner}
          />
        </span>
      ) : null}

      {icon && icon.position === "left" ? <ButtonIcon image={icon?.image} props={icon?.props} /> : null}

      {caption ? <span className={styles.button__button_caption}>{caption}</span> : null}

      {icon ? icon.position === "right" && <ButtonIcon image={icon?.image} props={icon?.props} /> : null}
    </button>
  ),
);
Button.displayName = "Button";
