import { CustomColumn, TableSettings } from "components/Common/Table/types";
import { STORAGE_ITEMS } from "constants/Storage";
import { parseJsonSafely, stringifyJsonSafely } from "helpers/general";

// Set to Local storage

export const setTokenToLocalStorage = (token: string | null | undefined): void =>
  localStorage.setItem(STORAGE_ITEMS.TOKEN, token || "");

export const setBUToLocalStorage = (businessUnitId: string): void =>
  localStorage.setItem(STORAGE_ITEMS.SELECTED_BU, businessUnitId);

export const setBUTypeToLocalStorage = (businessUnitType: string): void =>
  localStorage.setItem(STORAGE_ITEMS.SELECTED_BU_TYPE, businessUnitType);

export const setUserIdToLocalStorage = (userId: string): void => localStorage.setItem(STORAGE_ITEMS.USER_ID, userId);

export const setAppVersionToLocalStorage = (version: string): void =>
  localStorage.setItem(STORAGE_ITEMS.PERSIST_VERSION, version);

export const setFeaturesListToLocalStorage = (featuresList: string): void =>
  localStorage.setItem(STORAGE_ITEMS.FEATURES_LIST, featuresList);

export const setTaskIdToLocalStorage = (taskId: string): void => localStorage.setItem(STORAGE_ITEMS.TASK_ID, taskId);

export const setLocationsCountToLocalStorage = (locationsCount: string): void => {
  localStorage.setItem(STORAGE_ITEMS.LOCATIONS_COUNT, locationsCount);
};

export const setTableSettingsToLocalStorage = (
  tableId: string,
  updatedColumns?: CustomColumn[],
  pageSize?: number,
): void => {
  const selectedBU = getBUFromLocalStorage();
  const currentLocalStorageState = parseJsonSafely(localStorage.getItem(STORAGE_ITEMS.TABLE_SETTINGS) || "{}");
  const updatedSettingsObject = {
    ...currentLocalStorageState,
    [selectedBU]: {
      ...(currentLocalStorageState[selectedBU] || {}),
      [tableId]: { columns: updatedColumns, pageSize: pageSize },
    },
  };
  localStorage.setItem(STORAGE_ITEMS.TABLE_SETTINGS, stringifyJsonSafely(updatedSettingsObject));
};
// Get from Local storage

export const getTokenFromLocalStorage = (): string | null => localStorage.getItem(STORAGE_ITEMS.TOKEN);

export const getBUFromLocalStorage = (): number => Number(localStorage.getItem(STORAGE_ITEMS.SELECTED_BU));

export const getBusinessUnitIdFromLocalStorage = () => localStorage.getItem(STORAGE_ITEMS.SELECTED_BU) || "";

export const getUserIdFromLocalStorage = () => localStorage.getItem(STORAGE_ITEMS.USER_ID);

export const getBUTypeFromLocalStorage = (): string | null => localStorage.getItem(STORAGE_ITEMS.SELECTED_BU_TYPE);

export const getAppVersionFromLocalStorage = (): string | null => localStorage.getItem(STORAGE_ITEMS.PERSIST_VERSION);

export const getFeaturesListFromLocalStorage = (): string | null => localStorage.getItem(STORAGE_ITEMS.FEATURES_LIST);

export const getTaskIdFromLocalStorage = (): string | null => localStorage.getItem(STORAGE_ITEMS.TASK_ID);

export const getLocationsCountToLocalStorage = (): string | null => localStorage.getItem(STORAGE_ITEMS.LOCATIONS_COUNT);

export const getTableSettingsFromLocalStorage = (tableId: string): TableSettings => {
  const buId = getBUFromLocalStorage();
  const allTableSettings = localStorage.getItem(STORAGE_ITEMS.TABLE_SETTINGS);
  const parsedAllTableSettings = allTableSettings ? parseJsonSafely(allTableSettings) : {};
  const buTableSettings = parsedAllTableSettings[buId] || {};
  const currentLocalStorageTableSettings = buTableSettings[tableId] || {};
  return currentLocalStorageTableSettings;
};
// Remove from Local storage

export const removeTaskIdFromLocalStorage = (): void => localStorage.removeItem(STORAGE_ITEMS.TASK_ID);

// Clear Local storage

export const clearLocalStorage = (): void => localStorage.clear();
