import React, { useState } from "react";
import { ActivitiesBlock } from "pages/MyLivestock/Activities/AnimalHistory/ActivitiesBlock";
import { AnimalHistoryFilters } from "components/MyLivestock";
import { Button, Flex, LoadingOverlay, Panel, Spacer, Title } from "components";

interface AnimalHistoryProps {
  animalId: string;
  showHeading?: boolean;
}

const AnimalHistory: React.FC<AnimalHistoryProps> = ({ showHeading }) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const handleSelectFilter = (filter: string): void => {
    const filtersList: string[] = selectedFilters.includes(filter)
      ? selectedFilters.filter((el) => el !== filter)
      : [...selectedFilters, filter];
    setSelectedFilters(filtersList);
  };

  const handleShowAll = (): void => {
    setSelectedFilters([]);
  };

  const handleActivitiesLoading = (activitiesLoading: boolean): void => {
    setLoading(activitiesLoading);
  };

  return (
    <Flex container>
      <Flex item xs="fill" xl={8}>
        {loading ? <LoadingOverlay /> : null}

        <Panel>
          <Flex container containerAlignItems="center">
            {showHeading ? (
              <Flex item itemAlignSelf="flex-start">
                <Spacer baselineHeight={1} />
                <Title tertiary>Animal History</Title>
              </Flex>
            ) : null}
            <Flex item itemGutter={showHeading} container containerAlignItems="center">
              <Flex item itemGutter={showHeading}>
                <AnimalHistoryFilters selected={selectedFilters} selectFilter={handleSelectFilter} />
                <Spacer baselineHeight={2} />
              </Flex>
              <Flex item>
                {!!selectedFilters.length ? (
                  <Button caption="show all" onClick={handleShowAll} variant="ghost" />
                ) : null}
              </Flex>
            </Flex>
          </Flex>
          <Spacer baselineHeight={2} />
          <ActivitiesBlock onLoadStatus={handleActivitiesLoading} selectedFilters={selectedFilters} />
        </Panel>
      </Flex>
    </Flex>
  );
};

export default AnimalHistory;
