import { FieldSelect, InputCheckbox, Panel, Spacer, Tooltip, Text } from "components";
import { CommonContext } from "config/commonProvider";
import {
  IdentifierEnumType,
  UpdateIdentifierPreferencesInput,
  useUpdateIdentifierPreferencesMutation,
} from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import styles from "./IdPreferences.module.scss";

import { convertSnakeToCamelCase, identifierNameLookup, upperCaseFirstLetter } from "./helpers";
import { AnimalPreferencesReturnType } from "hooks/useGetAnimalIdPreferences";
import { useGetCurrentBusinessUnit } from "hooks";
import { useLocale } from "helpers/translations/src";

interface IFormValues {
  primaryId: string;
  secondaryId: string;
  name: boolean;
  breedrId: boolean;
  visualId: boolean;
  eId: boolean;
  brucellosisId: boolean;
  pedigreeId: boolean;
  tattoo: boolean;
  trichId: boolean;
  tsuBarcode: boolean;
  herdDoggId: boolean;
  passportNumber: boolean;
  uhfId: boolean;
  alternativeId: boolean;
}

interface Props {
  animalIdPreferences: AnimalPreferencesReturnType;
}

export const IdPreferencesForm: React.FC<Props> = ({ animalIdPreferences }) => {
  const currentBusinessUnitId = getBUFromLocalStorage();
  const { showNotification } = useContext(CommonContext);
  const [updateIdPreferences, { loading: loadingUpdateIdPreferencesData }] = useUpdateIdentifierPreferencesMutation();
  const { activeIdPreferences, primaryId, secondaryId, activeIdPreferencesArray, hiddenIds } = animalIdPreferences;
  const { terms } = useLocale();
  const { countryIsoCode } = useGetCurrentBusinessUnit();
  const countryCode = { us: countryIsoCode === "us", gb: countryIsoCode === "gb", au: countryIsoCode === "au" };

  const initialFormValues = {
    primaryId: convertSnakeToCamelCase(primaryId),
    secondaryId: convertSnakeToCamelCase(secondaryId),
    breedrId: activeIdPreferences?.breedrId,
    brucellosisId: activeIdPreferences?.brucellosisId,
    eId: activeIdPreferences?.eId,
    herdDoggId: activeIdPreferences?.herdDoggId,
    name: activeIdPreferences?.name,
    passportNumber: activeIdPreferences?.passportNumber,
    pedigreeId: activeIdPreferences?.pedigreeId,
    tattoo: activeIdPreferences?.tattoo,
    trichId: activeIdPreferences?.trichId,
    tsuBarcode: activeIdPreferences?.tsuBarcode,
    uhfId: activeIdPreferences?.uhfId,
    visualId: activeIdPreferences?.visualId,
    alternativeId: activeIdPreferences?.alternativeId,
  };

  const excludedOptions = [...hiddenIds, "primaryId", "secondaryId", "__typename"];
  const optionsForPrimaryPicker = (currentValues: IFormValues) =>
    activeIdPreferences
      ? Object.keys(currentValues)
          .filter(
            (item) => currentValues[item] && item !== currentValues.secondaryId && !excludedOptions.includes(item),
          )
          .map((item) => ({ key: item, label: identifierNameLookup(terms, item), value: item }))
      : [];

  const optionsForSecondaryPicker = (currentValues: IFormValues) =>
    activeIdPreferences
      ? Object.keys(currentValues)
          .filter((item) => currentValues[item] && item !== currentValues.primaryId && !excludedOptions.includes(item))
          .map((item) => ({ key: item, label: identifierNameLookup(terms, item), value: item }))
      : [];
  const initialPrimaryOptions = optionsForPrimaryPicker(initialFormValues as IFormValues);
  const initialSecondaryOptions = optionsForSecondaryPicker(initialFormValues as IFormValues);
  const [primaryPickerOptions, setPrimaryPickerOptions] =
    useState<{ key: string; label: string; value: string }[]>(initialPrimaryOptions);
  const [secondaryPickerOptions, setSecondaryPickerOptions] =
    useState<{ key: string; label: string; value: string }[]>(initialSecondaryOptions);

  useEffect(() => {
    setPrimaryPickerOptions(initialPrimaryOptions);
    setSecondaryPickerOptions(initialSecondaryOptions);
  }, [activeIdPreferences]);
  const methods = useForm<IFormValues>({
    // @ts-ignore
    defaultValues: initialFormValues,
    mode: "all",
  });

  const updateIdentifiers = async (data: IFormValues) => {
    const input: UpdateIdentifierPreferencesInput = {
      businessUnit: Number(currentBusinessUnitId),
      primaryId: IdentifierEnumType[upperCaseFirstLetter(data.primaryId)],
      secondaryId: IdentifierEnumType[upperCaseFirstLetter(data.secondaryId)],
      name: data.name,
      breedrId: data.breedrId,
      visualId: data.visualId,
      eId: data.eId,
      brucellosisId: data.brucellosisId,
      pedigreeId: data.pedigreeId,
      tattoo: data.tattoo,
      trichId: data.trichId,
      tsuBarcode: data.tsuBarcode,
      herdDoggId: data.herdDoggId,
      passportNumber: data.passportNumber,
      uhfId: data.uhfId,
      alternativeId: data.alternativeId,
    };
    await updateIdPreferences({
      variables: {
        input,
      },
      refetchQueries: ["GetBusinessUnit"],
      update: async (_, { data: identifierData }) => {
        if (identifierData?.updateIdentifierPreferences?.errors) {
          // eslint-disable-next-line no-console
          console.log("ERROR", identifierData.updateIdentifierPreferences.errors);
          showNotification({
            variant: "error",
            message: `Error: ${identifierData.updateIdentifierPreferences.errors[0]?.message}`,
          });
        }
        if (identifierData?.updateIdentifierPreferences?.success) {
          showNotification({
            message: "Successfully saved animal ID preferences",
            variant: "success",
          });
        }
      },
    });
  };

  const onSubmit = async (data: IFormValues) => {
    if (!currentBusinessUnitId) {
      return;
    }
    //// THIS IS CODE FORM MOBILE THAT NEEDS TO BE ADDED TO BACK END IN ORDER TO WORK ON WEB
    // Let calculate % of animals with  primary id 3 ÷ 38
    // const totalAnimals = getAnimalCountByProperty(data.primaryId); // Fix me: allAnimals is not defined
    // const totalFiltersAnimalsPercentage = Math.floor((totalAnimals / allAnimals.length) * 100);
    // if (totalFiltersAnimalsPercentage < 50) {
    //   alert(
    //     "Are you sure?",
    //     `Only ${totalFiltersAnimalsPercentage}% of your animals have ${identifierNameLookup[data.primaryId]}.`,
    //   );
    // }
    await updateIdentifiers(data);
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
    watch,
  } = methods;
  const primaryIdValue = watch("primaryId");
  const secondaryIdValue = watch("secondaryId");

  useEffect(() => {
    const subscription = watch((values) => {
      setPrimaryPickerOptions(optionsForPrimaryPicker(values as IFormValues));
      setSecondaryPickerOptions(optionsForSecondaryPicker(values as IFormValues));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const renderTooltip = (key: string, isDisabled: boolean) => {
    switch (key) {
      case "visualId":
        return !countryCode.gb
          ? "To add an animal to Breedr, you will still need either an EID or VID."
          : "To add an animal to Breedr, you must have either a Passport Number or VID.";
      case "eId":
        return !countryCode.gb ? "To add an animal to Breedr, you will still need either an EID or VID." : "";
      case "passportNumber":
        return countryCode.gb ? "To add an animal to Breedr, you must have either a Passport Number or VID." : "";
      default:
        return isDisabled
          ? `${identifierNameLookup(terms, key)} cannot be disabled when selected as primary or secondary ID.`
          : "";
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Panel
          actions={[
            {
              colour: "grey",
              caption: "Cancel",
              onClick: () => {
                methods.reset(initialFormValues);
              },
              variant: "hollow",
              disabled: loadingUpdateIdPreferencesData || isSubmitting || !isDirty,
            },
            {
              colour: "yellow",
              caption: "Save changes",
              type: "submit",
              disabled: loadingUpdateIdPreferencesData || isSubmitting || !isDirty,
              requesting: loadingUpdateIdPreferencesData || isSubmitting,
            },
          ]}
        >
          {countryCode.us ? (
            <div>
              <Spacer baselineHeight={1} />
              <label>
                Primary ID
                <Controller
                  control={control}
                  name="primaryId"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <FieldSelect
                        inputProps={{
                          value: value || initialFormValues.primaryId,
                          options: primaryPickerOptions,
                          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                            const primaryId = event.target.value;
                            if (primaryId) {
                              methods.setValue("primaryId", primaryId);
                            }
                            onChange(event);
                          },
                          isClearable: false,
                        }}
                      />
                    );
                  }}
                />
              </label>
              <Text smallest>Set the preferred primary ID you want to display</Text>
              <Spacer baselineHeight={2} />
              <label>
                Secondary ID
                <Controller
                  control={control}
                  name="secondaryId"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <FieldSelect
                        inputProps={{
                          value: value || initialFormValues.secondaryId,
                          options: secondaryPickerOptions,
                          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                            const primaryId = event.target.value;
                            if (primaryId) {
                              methods.setValue("secondaryId", primaryId);
                            }
                            onChange(event);
                          },
                          isClearable: false,
                        }}
                      />
                    );
                  }}
                />
              </label>
              <Text smallest>Set the preferred secondary ID you want to display</Text>
              <Spacer baselineHeight={2} />
            </div>
          ) : null}
          <Spacer baselineHeight={1} />
          <div className={styles.divider} />
          <label>
            Active IDs
            <Spacer baselineHeight={1} />
            <Text smallest>Check the IDs you want to capture in the app</Text>
            <Spacer baselineHeight={1} />
            <div className={styles.innerContainer}>
              {activeIdPreferencesArray.map((item) => {
                const key = Object.keys(item)[0];
                const value = item[key];
                const isDisabled = key === primaryIdValue || key === secondaryIdValue;
                return (
                  <div key={key}>
                    <Controller
                      control={control}
                      name={key as keyof IFormValues}
                      defaultValue={value}
                      render={({ field: { value, onChange } }) => (
                        <Tooltip title={renderTooltip(key, isDisabled)} placement="right">
                          <InputCheckbox
                            checked={value as boolean}
                            name={key}
                            label={identifierNameLookup(terms, key)}
                            onChange={onChange}
                            disabled={isDisabled}
                          />
                        </Tooltip>
                      )}
                    />
                  </div>
                );
              })}
            </div>
          </label>
        </Panel>
      </FormProvider>
    </form>
  );
};
