import { useQuery, QueryResult } from "@apollo/client";

import { getBUFromLocalStorage } from "helpers/storage";

import { GET_BUSINESS_UNIT_USERS } from "api/BusinessUnits/queries";

import { GetBusinessUnitUsers, GetBusinessUnitUsersVariables } from "api/BusinessUnits/types/GetBusinessUnitUsers";

export const useGetCurrentUserInfo = (): QueryResult<GetBusinessUnitUsers, GetBusinessUnitUsersVariables> => {
  const { data, ...rest } = useQuery<GetBusinessUnitUsers, GetBusinessUnitUsersVariables>(GET_BUSINESS_UNIT_USERS, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  return {
    data,
    ...rest,
  };
};
