// @ts-expect-error
import { IntercomAPI } from "react-intercom";
import { PageContent, Spacer, Text, Alert, Title, Image } from "components";
import pro from "assets/img/Subscription/pro.png";

import { useCreateStripePortalSessionMutation } from "generated/graphql";
import { useEffect, useState } from "react";
import { useGetBusinessUnitUserRole } from "hooks";
import { getErrorMessage } from "helpers/general/all";
import { Button } from "twComponents/Button";
import { useHubSpot } from "hooks/useHubSpot";

interface Props {
  currentBU: number | null;
}

export const SubscriptionContent = ({ currentBU }: Props) => {
  const { isAdmin } = useGetBusinessUnitUserRole();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { isHubSpotChatEnabled, openHubSpotChat } = useHubSpot();

  const [createStripePortalSession] = useCreateStripePortalSessionMutation();

  useEffect(() => {
    if (Boolean(errorMessage)) {
      setErrorMessage(undefined);
    }
  }, [currentBU]);

  const handleOpenChat = (): void => {
    if (isHubSpotChatEnabled) {
      openHubSpotChat("?chat=true");
    } else {
      IntercomAPI("showNewMessage", "I have a question regarding my subscription?\n\n");
    }
  };

  const handleOpenStripe = async () => {
    try {
      if (!isAdmin || !currentBU) {
        setErrorMessage("You must be an admin to view subscription details.");
        return;
      }

      const { data } = await createStripePortalSession({
        variables: {
          input: {
            businessUnitId: currentBU,
            returnUrl: window.location.href,
          },
        },
      });

      if (data && data?.createStripePortalSession && document.location.href) {
        document.location.href = data?.createStripePortalSession.sessionUrl;
      }
    } catch (error: unknown) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  const supportEmail = "support@breedr.co";

  return (
    <PageContent>
      <Spacer baselineHeight={2} />
      <div className="flex flex-col lg:flex-row-reverse">
        <div className="lg:w-1/2 px-2">
          <Image src={pro} style={{ maxHeight: "70vh" }} />
        </div>
        <div className="lg:w-1/2 mt-4 lg:mt-0 px-2">
          <div className="bg-white py-4 px-5 rounded-lg">
            <Title secondary>Your Subscription</Title>
            <Spacer baselineHeight={2} />
            <Text>
              Thank you for subscribing to Breedr. Email our customer success team at{" "}
              <a href={`mailto:${supportEmail}`}>support@breedr.co</a> to book your onboarding session. Manage your
              subscription on the manage page.
            </Text>
            <Spacer baselineHeight={2} />

            <div className="grid gap-4 grid-cols-2 lg:max-w-fit lg:mt-4">
              <Button type="button" onClick={handleOpenStripe} disabled={Boolean(errorMessage)}>
                Manage
              </Button>
              <Button type="button" onClick={handleOpenChat} variant="outline">
                Get in Contact
              </Button>
            </div>
            {errorMessage ? <Alert text={errorMessage} color="RED" /> : null}
          </div>
        </div>
      </div>
    </PageContent>
  );
};
