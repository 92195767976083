import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesGroupsTab: FC = () => {
  return (
    <EmptyStates animation={"searching"} title="You haven't created any groups yet.">
      <p>
        Monitor group-level targets, weights, and administer medicines. <br /> Tap on the ‘+ Create’ button to start
        creating your groups.
      </p>
    </EmptyStates>
  );
};
