import React, { FormHTMLAttributes, useEffect } from "react";
import { FieldValues, UseFormProps, useForm, FormProvider, DefaultValues } from "react-hook-form";

type Props<TFieldValues extends FieldValues> = {
  children: React.ReactNode;
  onSubmit: (values: TFieldValues) => void;
  shouldReset?: boolean;
  defaultValues: DefaultValues<TFieldValues>;
} & UseFormProps<TFieldValues> &
  Pick<FormHTMLAttributes<HTMLFormElement>, "className">;

export const Form = <TFieldValues extends FieldValues>({
  children,
  onSubmit,
  shouldReset,
  defaultValues,
  className,
  ...useFormProps
}: Props<TFieldValues>) => {
  const methods = useForm({ ...useFormProps, defaultValues });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (shouldReset) {
      methods.reset();
    }
  }, [methods, shouldReset]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={className}>
        {children}
      </form>
    </FormProvider>
  );
};
