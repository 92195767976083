import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { DateTime } from "luxon";
import { REQUEST_FOR_CTS_GENERATE_PDF } from "api/Settings/mutations";
import {
  BcmsServiceRequestGeneratePdf,
  BcmsServiceRequestGeneratePdfVariables,
} from "api/Settings/types/BcmsServiceRequestGeneratePdf";
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";
import { FormRequestToCTS } from "./Steps/SecondStep";
import { BcmsRequestReasonType } from "api/graphql-global-types";
import { Spacer } from "components";
import { StepLine } from "components/Import";
import { FirstStep, SecondStep, SuccessStep, ThirdStep } from "components/Settings";

type Props = {
  closeModal: () => void;
};

export const RequestToCTSWorkflow: React.FC<Props> = ({ closeModal }) => {
  const businessUnitId = getBUFromLocalStorage();

  const [activeStep, changeActiveStep] = useState(0);
  const [url, setUrl] = useState<string>("");
  const [generateRequestPDF] = useMutation<BcmsServiceRequestGeneratePdf, BcmsServiceRequestGeneratePdfVariables>(
    REQUEST_FOR_CTS_GENERATE_PDF,
  );
  const { showNotification } = useContext(CommonContext);

  const handleNextStep = (): void => {
    changeActiveStep(activeStep + 1);
  };
  const handlePrevStep = (): void => {
    changeActiveStep(activeStep - 1);
  };
  const handleSaveMainForm = async (formData: FormRequestToCTS): Promise<void> => {
    try {
      const { data } = await generateRequestPDF({
        variables: {
          input: {
            ...formData,
            businessUnit: businessUnitId,
            submitDate: DateTime.local().toISODate(),
            requestReason: BcmsRequestReasonType.USER_ID_AND_PASSWORD,
          },
        },
      });
      const isMutationSuccess = !data?.bcmsServiceRequestGeneratePdf?.errors;
      if (isMutationSuccess) {
        setUrl(data?.bcmsServiceRequestGeneratePdf?.url || "");
        handleNextStep();
      } else {
        showNotification({
          variant: "error",
          message: "Something went wrong. Please, try again later.",
        });
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error with form",
      });
    }
  };

  const renderContent = (): JSX.Element => {
    switch (activeStep) {
      case 0:
        return <FirstStep closeModal={closeModal} onNextStep={handleNextStep} />;
      case 1:
        return <SecondStep onPrevStep={closeModal} onNextStep={handleSaveMainForm} />;
      case 2:
        return <ThirdStep onNextStep={handleNextStep} onPrevStep={handlePrevStep} url={url} />;
      case 3:
        return <SuccessStep />;
      default:
        return <div />;
    }
  };
  return (
    <>
      {activeStep !== 3 ? <StepLine activeStep={activeStep} stepsCount={3} /> : null}
      <Spacer baselineHeight={2} />
      {renderContent()}
      <Spacer baselineHeight={2} />
    </>
  );
};
