import { AnimalType } from "trading/hooks/useGetCategoryListings";
import { CheckBoxState } from "trading/hooks/useListingFilters/config";

interface CategoryFilterState {
  categoriesFalse: CheckBoxState;
  categoriesTrue: CheckBoxState;
}

export const getCategoryFilterStates = (animalTypes: AnimalType[]): CategoryFilterState => {
  return animalTypes.reduce(
    (acc: CategoryFilterState, cur) => {
      if (cur) {
        cur?.categoriesOfSale?.forEach((element) => {
          if (element && element.id) {
            acc.categoriesFalse[element.id] = false;
            acc.categoriesTrue[element.id] = true;
          }
        });
      }
      return acc;
    },
    { categoriesFalse: {}, categoriesTrue: {} },
  );
};
