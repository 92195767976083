import { InputSelectOption } from "components/Common/Field/Select";
import { GetGroupsListQuery, useAnimalTagsQuery } from "generated/graphql";
import { notNothing } from "helpers/general/all";
import { getBUFromLocalStorage } from "helpers/storage";
import { useMemo } from "react";
import { sortBy } from "lodash";

export type GroupType = NonNullable<
  NonNullable<NonNullable<NonNullable<GetGroupsListQuery>["groupsExtended"]>["groups"]>[0]
>;

export const useGetTags = () => {
  const { data, ...rest } = useAnimalTagsQuery({
    variables: { buId: getBUFromLocalStorage() },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const tags = useMemo(() => {
    return (
      data?.animalTags?.edges
        ?.map((edge) => {
          return edge?.node;
        })
        .filter(notNothing) || []
    );
  }, [data?.animalTags?.edges]);

  const tagOptions: InputSelectOption[] = useMemo(() => {
    const options = tags.map((tag) => {
      return {
        value: tag.id,
        label: tag.name,
        key: tag.id,
      };
    });

    return sortBy(options, [(option): string => option.label]);
  }, [tags]);

  return {
    tagOptions,
    tags,
    data,
    ...rest,
  };
};
