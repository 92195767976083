import React, { useContext, useEffect, useState, FormEvent } from "react";
import { useMutation } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { UPDATE_BUSINESS_UNIT_SETTINGS, TERMS_AND_CONDITIONS_ACCEPT } from "api/BusinessUnits/mutations";
import {
  UpdateBusinessUnitSettings,
  UpdateBusinessUnitSettingsVariables,
} from "api/BusinessUnits/types/UpdateBusinessUnitSettings";
import {
  TermsAndConditionsAccept,
  TermsAndConditionsAcceptVariables,
} from "api/BusinessUnits/types/TermsAndConditionsAccept";
import { GetBusinessUnitSettings } from "api/BusinessUnits/types/GetBusinessUnitSettings";
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";
import { useLocale } from "helpers/translations/src/useLocale";
import { useGetCurrentBusinessUnit, useGetTermsConditions, useHasFeature } from "hooks";
import { BUSINESS_UNIT_TB_STATUSES } from "constants/Settings";
import { CountriesCodes } from "hooks/useGetCurrentBusinessUnit";
import { FieldNumber, FieldSelect, Flex, Modal, Panel, Spacer, Text } from "components";
import Checkbox from "components/Common/Checkbox";
import styles from "./Options.module.scss";
import { capitaliseFirstLetter } from "helpers/translations/src";

interface FormValues {
  killOut: string;
  pushToBcms: boolean;
  tbStatus: string;
}

export type FormProps = FormikProps<FormValues>;

const validationSchema = Yup.object().shape({
  killOut: Yup.number()
    .integer("Average kill out is not valid")
    .positive("Average kill out must be positive")
    .min(1, "Average kill out must be more than 1")
    .max(100, "Average kill out must be less than 100")
    .required("Average kill out is required!"),
  tbStatus: Yup.string(),
});

const tbStatusOptions = Object.keys(BUSINESS_UNIT_TB_STATUSES).map((tbStatus) => ({
  key: tbStatus,
  value: tbStatus,
  label: BUSINESS_UNIT_TB_STATUSES[tbStatus],
}));

export interface OptionsProps {
  optionValues: GetBusinessUnitSettings | undefined;
}

const Options: React.FC<OptionsProps> = ({ optionValues }) => {
  const { terms } = useLocale();
  const { showNotification } = useContext(CommonContext);
  const isShowBcmsPushFoggle = useHasFeature("BCMS_PUSH");
  const isShowScotMovesPushFoggle = useHasFeature("SCOTMOVES");
  const { regulatorySystem, countryIsoCode } = useGetCurrentBusinessUnit();
  const isShowRegulatorPushOption: boolean =
    (isShowBcmsPushFoggle && regulatorySystem?.slug === "BCMS_INTEGRATION") ||
    (isShowScotMovesPushFoggle && regulatorySystem?.slug === "SCOTMOVES");

  let showTbStatus = true;

  if (countryIsoCode === CountriesCodes.US) {
    showTbStatus = false;
  }

  const [error, setError] = useState<string | null>(null);
  const [bcmsTermsVisible, showBcmsTerms] = useState<boolean>(false);
  const [prevBcmsPushValue, setPrevBcmsPushValue] = useState<boolean>(false);
  const businessUnit = getBUFromLocalStorage();

  const tcData = useGetTermsConditions("BCMS_PUSH");
  const termsAndConditions = tcData.data?.termsAndConditions;

  const [updateBuSettings, { loading }] = useMutation<UpdateBusinessUnitSettings, UpdateBusinessUnitSettingsVariables>(
    UPDATE_BUSINESS_UNIT_SETTINGS,
  );

  const [acceptTerms] = useMutation<TermsAndConditionsAccept, TermsAndConditionsAcceptVariables>(
    TERMS_AND_CONDITIONS_ACCEPT,
  );

  const handleFormSubmit = async ({ killOut, pushToBcms, tbStatus }: FormValues): Promise<void> => {
    if (killOut.length === 0) {
      setError("My average kill out needs to be filled.");
      return;
    }

    if (error) {
      setError(null);
    }

    try {
      const { data } = await updateBuSettings({
        variables: {
          input: {
            killOut: +killOut,
            businessUnit: getBUFromLocalStorage(),
            tbStatus,
            ...(isShowRegulatorPushOption ? { enableBcmsPushTransactions: pushToBcms } : {}),
          },
        },
      });

      const error: string | undefined = data?.updateBusinessUnitSettings?.errors
        ? data.updateBusinessUnitSettings.errors[0]?.message
        : "";

      if (error) {
        setError(error);
        showNotification({
          variant: "error",
          message: error,
        });
      } else {
        showNotification({
          message: "Options successfully saved",
        });
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Error saving options",
      });
      setError("Error saving options");
    }
  };

  const initialValues: FormValues = {
    killOut: optionValues?.businessUnit?.killOut ? `${optionValues.businessUnit.killOut}` : "",
    pushToBcms: !!optionValues?.businessUnit?.enableBcmsPushTransactions,
    tbStatus: optionValues?.businessUnit?.tbStatus || "",
  };

  useEffect(() => {
    if (optionValues?.businessUnit) {
      // set the initial value of bcms push optin on mount
      setPrevBcmsPushValue(optionValues?.businessUnit?.enableBcmsPushTransactions);
    }
  }, [optionValues]);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, setFieldValue, handleSubmit, resetForm }: FormProps): JSX.Element => {
        const handleCancelClick = (): void => {
          resetForm();
        };

        const handleCancelAcceptBcmsTerms = (): void => {
          setFieldValue("pushToBcms", false);
          showBcmsTerms(false);
        };

        const handleAcceptBcmsTerms = async (): Promise<void> => {
          const errorMsg = "Error saving terms and conditions acceptance";

          if (error) {
            setError(null);
          }

          if (!termsAndConditions) {
            setError(errorMsg);
            return;
          }

          try {
            const { data: acceptResponse } = await acceptTerms({
              variables: {
                input: {
                  id: +termsAndConditions?.id,
                  businessUnitId: +businessUnit,
                },
              },
            });

            const errors = acceptResponse?.termsAndConditionsAccept?.errors;

            if (errors && errors.length) {
              setError(errorMsg);
              showNotification({ variant: "error", message: errorMsg });
            }
          } catch (error) {
            showNotification({
              variant: "error",
              message: errorMsg,
            });
            setError(errorMsg);
          }

          setFieldValue("pushToBcms", true);
          showBcmsTerms(false);
        };

        const handlePushToBcmsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
          const { checked = false } = event.target;

          // if the user checked the box to activate BCMS push
          if (checked) {
            // if there are some BCMS t&c's in backend, show the modal to accept them
            if (termsAndConditions) {
              showBcmsTerms(true);
              return;
            } else {
              // don't show modal and just check the box
              setFieldValue("pushToBcms", true);
              return;
            }
          }

          // if user unchecked the box, just update the field
          setFieldValue("pushToBcms", false);
        };

        const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
          event.preventDefault();

          handleSubmit();

          // register bcms push optin event only if the value changes
          // to prevent inaccurate event calls
          if (values?.pushToBcms !== prevBcmsPushValue) {
            setPrevBcmsPushValue(values?.pushToBcms);
          }
        };

        return (
          <form onSubmit={handleFormSubmit}>
            <Panel
              title="Options"
              actions={[
                { colour: "grey", caption: "Cancel", onClick: handleCancelClick, variant: "hollow" },
                { colour: "yellow", caption: "Save changes", type: "submit", disabled: loading, requesting: loading },
              ]}
            >
              <Text smaller>These options help you get the most out of Breedr:</Text>
              <Spacer baselineHeight={2} />
              <Flex container containerDirection="column" containerResetXS={12}>
                <Flex item xs={6} l={3} xl={5}>
                  <FieldNumber
                    label="My average kill out %"
                    error={errors.killOut}
                    inputProps={{
                      name: "killOut",
                      onChange: handleChange,
                      value: values.killOut,
                    }}
                  />
                </Flex>
                {showTbStatus ? (
                  <Flex item xs={12} l={6} xl={8}>
                    <FieldSelect
                      label={`${capitaliseFirstLetter(terms.farm)} TB status`}
                      error={errors.tbStatus}
                      inputProps={{
                        name: "tbStatus",
                        onChange: handleChange,
                        options: tbStatusOptions,
                        value: values.tbStatus,
                      }}
                    />
                  </Flex>
                ) : null}
              </Flex>
              {isShowRegulatorPushOption ? (
                <Checkbox
                  checked={values.pushToBcms}
                  label={"Send registrations and movements to " + regulatorySystem?.name}
                  onChange={handlePushToBcmsChange}
                />
              ) : null}

              {error ? <Text warning>{error}</Text> : null}
            </Panel>

            {termsAndConditions ? (
              <Modal
                actions={{
                  primary: {
                    caption: "Accept",
                    onClick: (): Promise<void> => handleAcceptBcmsTerms(),
                  },
                  secondary: {
                    caption: "Cancel",
                    onClick: (): void => handleCancelAcceptBcmsTerms(),
                  },
                }}
                active={bcmsTermsVisible}
                title={termsAndConditions.title}
                buttonsLeftSide
              >
                <div
                  className={styles.termsContent}
                  dangerouslySetInnerHTML={{ __html: termsAndConditions.description }}
                />
              </Modal>
            ) : null}
          </form>
        );
      }}
    </Formik>
  );
};

export default Options;
