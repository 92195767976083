import { ImportHeader } from "import/containers";
import { ImportWeight } from "components/Import";
import { Header, PageContent, PageHeader, Spacer } from "components";
import { useHasFeature } from "hooks";

export const ImportExternal = () => {
  const useGenetics = useHasFeature("GENETICS");
  return (
    <>
      <PageHeader>
        <Header title="Import livestock data" />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />

        <ImportHeader text={`Import from ${useGenetics ? "genetic partners" : "supported systems"}`} />

        <ImportWeight />
      </PageContent>
    </>
  );
};
