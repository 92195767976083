import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesSireInformation: FC = () => {
  return (
    <EmptyStates animation={"genealogy"} title="You haven't added any sires.">
      <p>
        By adding sires, you can link offspring performance data, giving you access to animal lineage and EPD metrics.
        <br />
        <br />
        Tap on the ‘+ Add’ button to start add your first sire.
      </p>
    </EmptyStates>
  );
};
