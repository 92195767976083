// React
import React, { memo } from "react";
// Resources
import styles from "./PageMenuGroup.module.scss";

interface PageMenuGroupProps {
  title: string;
  children?: React.ReactNode;
}

export const PageMenuGroup: React.FC<PageMenuGroupProps> = memo(({ children, title }) => (
  <div className={styles.page_menu_group}>
    {title ? <h6 className={styles.page_menu_group__title}>{title}</h6> : null}

    <div className={styles.page_menu_group__links}>{children}</div>
  </div>
));
PageMenuGroup.displayName = "PageMenuGroup";
