import { FC } from "react";
import { Paper } from "components";
import { WizardOption } from "./WizardOption";
import { WizardSeparator } from "./WizardSeparator";
import styles from "./WizardOptions.module.scss";

interface WizardOptionsSubcomponents {
  Option: typeof WizardOption;
  Separator: typeof WizardSeparator;
}

interface IProps {
  children?: React.ReactNode;
}

export const WizardOptions: FC<IProps> & WizardOptionsSubcomponents = ({ children }) => (
  <Paper className={styles.wizard_options} dark>
    {children}
  </Paper>
);
WizardOptions.Option = WizardOption;
WizardOptions.Separator = WizardSeparator;
