// React
import React from "react";
// Libraries
import cn from "classnames";
// Components
import { IconButtonProps } from "components/Common/IconButton";
import { IconTextButtonProps } from "components/Common/IconTextButton";
import { Flex, IconButton, IconTextButton } from "components";
// Resources
import styles from "./PageContent.module.scss";

interface PageContentProps {
  floatingIconButton?: IconButtonProps;
  floatingIconTextButton?: IconTextButtonProps;
  children: React.ReactNode;
}

export const PageContent: React.FC<PageContentProps> = ({ children, floatingIconButton, floatingIconTextButton }) => (
  <Flex
    className={cn(styles.page_content, {
      [styles["page_content--has_fab"]]: floatingIconButton || floatingIconTextButton,
    })}
    container
    containerDirection="column"
    item
    itemGrow
  >
    {floatingIconButton ? (
      <Flex className={styles.page_content__fib} item itemAlignSelf="flex-end" itemGutter>
        <IconButton {...floatingIconButton} />
      </Flex>
    ) : null}

    {floatingIconTextButton ? (
      <Flex className={styles.page_content__fib} item itemAlignSelf="flex-end" itemGutter>
        <IconTextButton {...floatingIconTextButton} />
      </Flex>
    ) : null}

    {children}
  </Flex>
);
