export const ANIMALS_IMPORT = "animal";
export const ACTIVITIES_IMPORT = "activity";
export const MOVEMENT_IMPORT = "movement";

export const BCMS_SLUG = "BCMS";
export const TRU_TEST_SLUG = "TRU_TEST";
export const FARMPLAN_SLUG = "FARMPLAN";
export const APHIS_SLUG = "APHIS";
export const AA_SLUG = "cmd-aa-epd";
export const NEOGEN_SLUG = "cmd-neogen-epd";
export const BREEDSEEK_SLUG = "breedseek";
export const BEEF_X_DAIRY_SLUG = "beefxdairy";
