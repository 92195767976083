// Utils
import { isListingSold, isListingTradeAgreed } from "trading/util";
// Types
import { PillProps } from "components/Common/Pill";

export const getListingOfferStatus = (
  status: string,
  offersCount: number | undefined,
):
  | {
      caption: string;
      colour: PillProps["colour"];
    }
  | undefined => {
  const isAccepted = isListingTradeAgreed(status);
  const isSold = isListingSold(status);

  if (isSold) {
    return {
      caption: "SOLD",
      colour: "red",
    };
  }

  if (isAccepted) {
    return {
      caption: "Trade Agreed",
      colour: "green",
    };
  }

  if (offersCount) {
    return {
      caption: `${offersCount} ${offersCount > 1 ? "offers" : "offer"}`,
      colour: "blue",
    };
  }

  return;
};
