import React, { FC } from "react";

import pure from "recompose/pure";

import cn from "classnames";

import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { Search as SearchIcon, Close } from "constants/SvgIcons";

import styles from "./SearchInput.module.css";

interface Props {
  id?: string;
  value?: string;
  placeholder?: string;
  isDark?: boolean;
  onClearSearch: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSearch?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const SearchInput: FC<Props> = ({ isDark, onSearch, onClearSearch, placeholder, ...props }) => (
  <FormControl
    className={cn(styles.formControl, {
      [styles.darkSearchInput]: isDark,
    })}
  >
    <Input
      className={cn(styles.input, {
        [styles.whiteText]: isDark,
      })}
      placeholder={placeholder || ""}
      startAdornment={
        <InputAdornment position="start">
          <IconButton className={styles.btn} onClick={onSearch}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        props.value ? (
          <InputAdornment position="end">
            <IconButton className={styles.btn} onClick={onClearSearch}>
              <Close nativeColor="#E7EAEC" />
            </IconButton>
          </InputAdornment>
        ) : null
      }
      disableUnderline
      {...props}
    />
  </FormControl>
);

export default pure(SearchInput);
