import React from "react";
// Libraries
import cn from "classnames";
// Components
import { Flex, Spacer, Title } from "components";
// Resources
import styles from "./VerticalTabs.module.scss";

interface VerticalTabProps {
  active?: boolean;
  caption: string;
  id: string;
  onTabChange: (newTabId: string) => void;
}

const VerticalTab: React.FC<VerticalTabProps> = ({ active, caption, id, onTabChange }) => (
  <button
    className={cn(styles.vertical_tab, {
      [styles["vertical_tab--active"]]: active,
    })}
    onClick={(): void => onTabChange(id)}
    type="button"
  >
    <span className={styles.vertical_tab__caption}>{caption}</span>
  </button>
);

interface VerticalTabsProps {
  tabs: VerticalTabProps[];
  tabsTitle?: string;
  children?: React.ReactNode;
}

export const VerticalTabs: React.FC<VerticalTabsProps> = ({ children, tabs, tabsTitle }) => (
  <Flex container>
    <Flex
      className={cn(styles.vertical_tabs__nav, {
        [styles["vertical_tabs__nav--has_title"]]: tabsTitle,
      })}
      item
      xs={3}
    >
      {tabsTitle ? (
        <>
          <Spacer allowDecimal baselineHeight={2.5} />

          <Title className={styles.vertical_tabs__nav_title} tertiary>
            {tabsTitle}
          </Title>

          <Spacer allowDecimal baselineHeight={2.5} />
        </>
      ) : null}

      {tabs.map(({ id, ...props }) => (
        <VerticalTab key={`medicine_tab_${id}`} id={id} {...props} />
      ))}
    </Flex>

    <Flex container className={styles.vertical_tabs__content} item xs={9}>
      {children}
    </Flex>
  </Flex>
);
