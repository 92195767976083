import { VFC } from "react";
// Libraries
import cn from "classnames";
// Components
import { Icon } from "components";
// Resources
import styles from "./ActivityTemplateIcon.module.scss";

enum ActivityTemplateIconSizes {
  Medium = "medium",
  Large = "large",
}

interface ActivityTemplateIconBase {
  activityTemplateName: string;
  className?: string;
  size?: `${ActivityTemplateIconSizes}`;
}

interface ActivityTemplateIconSvg extends ActivityTemplateIconBase {
  src?: never;
  srcSvg: string;
}

interface ActivityTemplateIconSrc extends ActivityTemplateIconBase {
  src: string;
  srcSvg?: never;
}

type ActivityTemplateIconProps = ActivityTemplateIconSvg | ActivityTemplateIconSrc;

export const ActivityTemplateIcon: VFC<ActivityTemplateIconProps> = ({
  activityTemplateName,
  className,
  size = "large",
  src,
  srcSvg,
}) => {
  if (srcSvg) return <Icon src={srcSvg} size={size} />;

  return (
    <img
      alt={`Icon for ${activityTemplateName}`}
      className={cn(
        styles.activity_template_icon,
        { [styles[`activity_template_icon--size__${size}`]]: true },
        className,
      )}
      src={src}
    />
  );
};
