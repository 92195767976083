import React from "react";
// Components
import { Spacer, Text } from "components";
import { AnimalHistoryItem } from "components/MyLivestock";

interface AnimalsHistoryDateItemProps {
  // @todo This component needs proper API generated types! Disabling ESLint for now.
  // eslint-disable-next-line @typescript-eslint/ban-types
  items: object;
  children?: React.ReactNode;
}

export const AnimalsHistoryDateItem: React.FC<AnimalsHistoryDateItemProps> = ({ children, items }) => (
  <>
    {Object.keys(items).map((el) => (
      <div key={el}>
        <Spacer baselineHeight={1} />
        <Text>
          <strong>{el}</strong>
        </Text>
        <Spacer baselineHeight={2} />
        {items[el].map(
          (activity) =>
            !activity.dontShow && (
              <React.Fragment key={activity.id}>
                <AnimalHistoryItem key={activity.id} activity={activity} />
                <Spacer baselineHeight={1.5} allowDecimal />
              </React.Fragment>
            ),
        )}
        {children}
        <Spacer baselineHeight={1} />
      </div>
    ))}
  </>
);
