import React, { useContext, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import Popover from "@material-ui/core/Popover";

import { getBUFromLocalStorage } from "helpers/storage";
import { useHasFeature } from "hooks";
import { Dots } from "constants/SvgIcons";
import { PREFERRED_BU_COMMON_STATUSES } from "constants/PreferredBusinessUnits";
import { EDIT_SUPPLIER } from "constants/Routes";

import { UPDATE_PREFERRED_BUSINESS_UNIT_STATUS } from "api/BusinessUnits/mutations";

import { GetPreferredBusinessUnits_preferredBusinessUnits } from "api/BusinessUnits/types/GetPreferredBusinessUnits";
import {
  UpdatePreferredBusinessUnitStatus,
  UpdatePreferredBusinessUnitStatusVariables,
} from "api/BusinessUnits/types/UpdatePreferredBusinessUnitStatus";

import { useGetPreferredBusinessUnits } from "hooks";
import { CommonContext } from "config/commonProvider";

import { Modal, CommonTable } from "components";

import styles from "./styles.module.scss";
import { SUPPLIER_TYPES } from "../PreferredSuppliersTabs";
import { EmptyStatesSupplierBuyers } from "components/EmptyStates/SupplierBuyers";
import { EmptyStatesSupplierSellers } from "components/EmptyStates/SupplierSellers";

interface Cell {
  row: {
    original: GetPreferredBusinessUnits_preferredBusinessUnits;
  };
}

export const TEST_IDS = {
  POPOVER: "popover",
  ACCEPT_BUTTON: "accept-button",
  REJECT_BUTTON: "reject-button",
  EDIT_BUTTON: "edit-button",
  TERMS_MODAL: "terms-modal",
  NO_SUPPLIERS_TEXT: "no-suppliers-text",
};

const COLUMNS_IDS = {
  SUPPLIER_NAME: "supplierName",
  LOCATION: "location",
  FIELDSMAN: "fieldsman",
  BASE_PRICE: "basePrice",
};

export const SuppliersTable: React.FC = () => {
  const { supplierType } = useParams<SettingsSuppliersPageParams>();
  const { showNotification } = useContext(CommonContext);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedBu, setSelectedBu] = useState<GetPreferredBusinessUnits_preferredBusinessUnits | null>(null);
  const [termsModal, setTermsModalVisibility] = useState<boolean>(false);
  const { data, loading, refetch } = useGetPreferredBusinessUnits();
  const [updatePreferredBuMutation, { loading: updatePreferredBuLoading }] = useMutation<
    UpdatePreferredBusinessUnitStatus,
    UpdatePreferredBusinessUnitStatusVariables
  >(UPDATE_PREFERRED_BUSINESS_UNIT_STATUS);
  const history = useHistory();

  const isTermsAndConditionModalEnabled: boolean = useHasFeature("SUPPLIERS_TC");
  const isEditFieldsmanButtonEnabled: boolean = useHasFeature("SUPPLIER_MANAGEMENT_EDIT_FIELDSMAN");
  const currentBUId: number = getBUFromLocalStorage();
  const { id: buId, supplier, commonStatus, tacTitle = "", tacDescription = "" } = { ...selectedBu };
  const { id: supplierId } = { ...supplier };
  const isSellers = supplierType === SUPPLIER_TYPES.SELLERS;
  const showFieldsmanColumns: boolean = isEditFieldsmanButtonEnabled && isSellers;

  const handleClick =
    (bu: GetPreferredBusinessUnits_preferredBusinessUnits) =>
    (e: React.MouseEvent<HTMLDivElement>): void => {
      setSelectedBu(bu);
      setPopoverAnchorEl(e.currentTarget);
    };

  const handlePopoverClose = (): void => {
    setPopoverAnchorEl(null);
  };

  const handleModalClose = (): void => {
    setTermsModalVisibility(false);
  };

  const updatePreferredBu = async ({ status, successMessage, errorMessage }): Promise<void> => {
    try {
      const { data } = await updatePreferredBuMutation({
        variables: {
          input: {
            id: Number(buId),
            businessUnit: currentBUId,
            status,
          },
        },
      });

      const error = Boolean(data?.updatePreferredBusinessUnitStatus?.errors);

      if (error) {
        showNotification({
          message: errorMessage,
          variant: "error",
        });
      } else {
        await refetch();

        handlePopoverClose();

        showNotification({
          message: successMessage,
        });
      }
    } catch (error) {
      showNotification({
        message: errorMessage,
        variant: "error",
      });
    }
  };

  const handleAcceptClick = async (): Promise<void> => {
    if (isTermsAndConditionModalEnabled) {
      setTermsModalVisibility(true);
      handlePopoverClose();
    } else {
      await updatePreferredBu({
        status: PREFERRED_BU_COMMON_STATUSES.ACCEPTED,
        successMessage: "Supplier successfully accepted",
        errorMessage: "Error accepting supplier",
      });
    }
  };

  const handleAcceptTermsClick = async (): Promise<void> => {
    await updatePreferredBu({
      status: PREFERRED_BU_COMMON_STATUSES.ACCEPTED,
      successMessage: "Supplier successfully accepted",
      errorMessage: "Error accepting supplier",
    });
    handleModalClose();
  };

  const handleRejectClick = async (): Promise<void> => {
    await updatePreferredBu({
      status: PREFERRED_BU_COMMON_STATUSES.DECLINED,
      successMessage: "Supplier invitation rejected",
      errorMessage: "Error rejecting supplier",
    });
  };

  const handleEditClick = (): void => {
    history.push(`${EDIT_SUPPLIER}/${buId}`);
  };

  const open = Boolean(popoverAnchorEl);
  const selectedBuId: string = selectedBu ? selectedBu.id : "";
  const popoverId: string | undefined = open ? selectedBuId : undefined;

  const columns = useMemo(
    () => [
      {
        Header: "Supplier name",
        accessor: ({ supplier: { id: supplierId, name: supplierName }, buyer: { name: buyerName } }): string => {
          return +supplierId === currentBUId ? buyerName : supplierName;
        },
        id: COLUMNS_IDS.SUPPLIER_NAME,
        width: showFieldsmanColumns ? 150 : 300,
      },
      {
        Header: "Location",
        accessor: ({
          supplier: { id: supplierId, primaryLocation: supplierLocation },
          buyer: { primaryLocation: buyerLocation },
        }): string => {
          const { city: supplierCity = "" } = { ...supplierLocation };
          const { city: buyerCity = "" } = { ...buyerLocation };
          const supplierAddress = supplierCity || "\u2014";
          const buyerAddress = buyerCity || "\u2014";

          return (+supplierId === currentBUId ? buyerAddress : supplierAddress) || "\u2014";
        },
        id: COLUMNS_IDS.LOCATION,
        width: showFieldsmanColumns ? 150 : 200,
      },
      {
        Header: "Fieldsman",
        accessor: ({ fieldsman }): string => {
          const { firstName = "", lastName = "" } = { ...fieldsman };

          return firstName ? `${firstName} ${lastName}` : "\u2014";
        },
        id: "fieldsman",
        width: 150,
      },
      {
        Header: "Base price adjustments",
        accessor: ({ priceAdjustment }): string => {
          return priceAdjustment || "\u2014";
        },
        id: COLUMNS_IDS.BASE_PRICE,
        width: 200,
      },
      {
        Header: "Status",
        accessor: "commonStatus",
        width: 200,
      },
      {
        Header: "",
        accessor: "actions",
        columnSort: false,
        Cell: function actionsCell({ row: { original } }: Cell): JSX.Element | null {
          const { commonStatus, supplier } = original;
          const { id: supplierId } = { ...supplier };
          const isBUInvited: boolean = Number(supplierId) === currentBUId;
          const isActionsAvailable: boolean = commonStatus === PREFERRED_BU_COMMON_STATUSES.PENDING && isBUInvited;
          const isEditAvailable: boolean = !isBUInvited && isEditFieldsmanButtonEnabled && showFieldsmanColumns;

          if (isActionsAvailable || isEditAvailable) {
            return (
              <div aria-describedby={original.id} data-testid={original.id} onClick={handleClick(original)}>
                <Dots />
              </div>
            );
          }

          return null;
        },
      },
    ],
    [currentBUId, isEditFieldsmanButtonEnabled, showFieldsmanColumns],
  );

  const tableData: (GetPreferredBusinessUnits_preferredBusinessUnits | null)[] = useMemo(
    () =>
      data.filter((item) =>
        isSellers ? Number(item?.buyer.id) === currentBUId : Number(item?.buyer.id) !== currentBUId,
      ),
    [data, currentBUId, isSellers],
  );
  const emptyList: boolean = !loading && tableData.length === 0;
  const isBUInvited: boolean = Number(supplierId) === currentBUId;
  const isActionsAvailable: boolean = commonStatus === PREFERRED_BU_COMMON_STATUSES.PENDING && isBUInvited;
  const isEditAvailable: boolean = !isBUInvited && isEditFieldsmanButtonEnabled && showFieldsmanColumns;
  const initialHiddenColumns: string[] = useMemo(
    () => (showFieldsmanColumns ? [] : [COLUMNS_IDS.FIELDSMAN, COLUMNS_IDS.BASE_PRICE]),
    [showFieldsmanColumns],
  );

  return (
    <>
      {emptyList && supplierType === "buyers" ? (
        <EmptyStatesSupplierBuyers />
      ) : emptyList && supplierType === "sellers" ? (
        <EmptyStatesSupplierSellers />
      ) : (
        <>
          <CommonTable
            columns={columns}
            data={tableData}
            loading={loading}
            defaultSortBy="supplierName"
            initialHiddenColumns={initialHiddenColumns}
          />
          <Popover
            data-testid={TEST_IDS.POPOVER}
            id={popoverId}
            open={open}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: 70,
            }}
          >
            <div className={styles.actions}>
              {isActionsAvailable ? (
                <>
                  <p className={styles.actionButton} onClick={handleAcceptClick} data-testid={TEST_IDS.ACCEPT_BUTTON}>
                    Accept
                  </p>
                  <p className={styles.actionButton} onClick={handleRejectClick} data-testid={TEST_IDS.REJECT_BUTTON}>
                    Reject
                  </p>
                </>
              ) : null}
              {isEditAvailable ? (
                <p className={styles.actionButton} onClick={handleEditClick} data-testid={TEST_IDS.EDIT_BUTTON}>
                  Edit
                </p>
              ) : null}
            </div>
          </Popover>
          <Modal
            title={tacTitle as string}
            active={termsModal}
            handleClose={handleModalClose}
            showCloseButton
            testId={TEST_IDS.TERMS_MODAL}
            actions={{
              primary: {
                caption: "Accept",
                disabled: updatePreferredBuLoading,
                requesting: updatePreferredBuLoading,
                onClick: handleAcceptTermsClick,
              },
              secondary: {
                caption: "Cancel",
                onClick: handleModalClose,
              },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: tacDescription as string }} />
          </Modal>
        </>
      )}
    </>
  );
};
