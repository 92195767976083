// React
import React from "react";
// Libraries
import cn from "classnames";
// Components
import { Flex } from "components";
// Resources
import styles from "./RootMenuNav.module.scss";

interface RootMenuNavProps {
  secondary?: boolean;
  children?: React.ReactNode;
}

export const RootMenuNav: React.FC<RootMenuNavProps> = ({ children, secondary = false }) => (
  <Flex
    className={cn(styles.root_menu_navigation, {
      [styles["root_menu_navigation--secondary"]]: secondary,
    })}
    container
    containerDirection="column"
    containerAlignItems="center"
  >
    <nav>{children}</nav>
  </Flex>
);
RootMenuNav.displayName = "RootMenuNav";
