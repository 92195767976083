import { gql } from "@apollo/client";
import { errorFragment } from "api/User/fragment";

export const LOG_ACTIVITY_BULK_MUTATION = gql`
  mutation LogActivityBulkMutation($input: LogActivityBulkInput!) {
    logActivityBulk(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;

export const DELETE_ACTIVITY_BULK_MUTATION = gql`
  mutation DeleteActivityBulkMutation($input: DeleteActivityBulkInput!) {
    deleteActivityBulk(input: $input) {
      success
      errors {
        ...errorFragment
      }
    }
  }
  ${errorFragment}
`;
