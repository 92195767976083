import { FC, useContext, useMemo } from "react";
// Libraries
import { useRouteMatch } from "react-router-dom";
// Utils
import { CommonContext } from "config/commonProvider";
import { ANIMAL_FILTER_KEYS } from "constants/Animals";
import { FIELD_VIEW } from "constants/Routes";
import { getFilterLabel } from "./helpers";
import {
  useGetGroups,
  useGetFields,
  useGetBreeds,
  useGetCurrentBusinessUnit,
  useGetSexClassifications,
  useStateSpecies,
  useFormatDate,
} from "hooks";
// Components
import { Icon, Text, Tooltip } from "components";
// Styles
import styles from "./styles.module.scss";

interface InUseLabel {
  label: string;
  resetKeys: string[];
  withTooltip?: boolean;
}

export const FiltersInUse: FC = () => {
  const { speciesList } = useStateSpecies();

  const {
    mainCommonState: { animalsFilters },
    changeAnimalsFilter,
    setAnimalsFilterTemplateId,
  } = useContext(CommonContext);

  const { weightUnits } = useGetCurrentBusinessUnit();
  const { getFormatDate } = useFormatDate();
  const isFieldView = useRouteMatch(`${FIELD_VIEW}/:fieldId`);
  const canViewAllSpecies = !!isFieldView;

  const { breeds } = useGetBreeds({ forAllSpecies: canViewAllSpecies });
  const { fields } = useGetFields();
  const { groups } = useGetGroups({ forAllSpecies: canViewAllSpecies });
  const { data: sexClassificationData } = useGetSexClassifications({ forAllSpecies: canViewAllSpecies });

  const {
    [ANIMAL_FILTER_KEYS.ANIMAL_TYPE_ID]: filteredAnimalTypeId,
    [ANIMAL_FILTER_KEYS.BREEDS]: filteredBreeds,
    [ANIMAL_FILTER_KEYS.SEX]: filteredSex,
    [ANIMAL_FILTER_KEYS.SEX_CLASSIFICATIONS]: filteredSexClassification,
    [ANIMAL_FILTER_KEYS.MIN_AGE]: filteredMinAge,
    [ANIMAL_FILTER_KEYS.MAX_AGE]: filteredMaxAge,
    [ANIMAL_FILTER_KEYS.MIN_DELIVERY_AGE]: filteredMinDeliveryAge,
    [ANIMAL_FILTER_KEYS.MAX_DELIVERY_AGE]: filteredMaxDeliveryAge,
    [ANIMAL_FILTER_KEYS.MIN_CURRENT_EST_WEIGHT]: filteredMinCurrentEstWeight,
    [ANIMAL_FILTER_KEYS.MAX_CURRENT_EST_WEIGHT]: filteredMaxCurrentEstWeight,
    [ANIMAL_FILTER_KEYS.MIN_WEIGHT]: filteredMinWeight,
    [ANIMAL_FILTER_KEYS.MAX_WEIGHT]: filteredMaxWeight,
    [ANIMAL_FILTER_KEYS.DAYS_SINCE_LAST_WEIGHT_FROM]: daysSinceLastWeightFrom,
    [ANIMAL_FILTER_KEYS.DAYS_SINCE_LAST_WEIGHT_TO]: daysSinceLastWeightTo,
    [ANIMAL_FILTER_KEYS.GROWTH_RATE_FROM]: growthRateFrom,
    [ANIMAL_FILTER_KEYS.GROWTH_RATE_TO]: growthRateTo,
    [ANIMAL_FILTER_KEYS.MIN_DELIVERY_WEIGHT]: filteredMinDeliveryWeight,
    [ANIMAL_FILTER_KEYS.MAX_DELIVERY_WEIGHT]: filteredMaxDeliveryWeight,
    [ANIMAL_FILTER_KEYS.DELIVERY_DATE_FROM]: filteredDeliveryFrom,
    [ANIMAL_FILTER_KEYS.DELIVERY_DATE_TO]: filteredDeliveryTo,
    [ANIMAL_FILTER_KEYS.GROUPS]: filteredGroups,
    [ANIMAL_FILTER_KEYS.FIELDS]: filteredFields,
    [ANIMAL_FILTER_KEYS.LAST_SYNCED_DATE_FROM]: lastSyncedDateFrom,
    [ANIMAL_FILTER_KEYS.LAST_SYNCED_DATE_TO]: lastSyncedDateTo,
    [ANIMAL_FILTER_KEYS.IS_BCMS_SYNCED]: filteredIsBcmsSynced,
    [ANIMAL_FILTER_KEYS.IS_WITHDRAWAL]: filteredIsWithdrawal,
    [ANIMAL_FILTER_KEYS.MIN_DOB]: filteredDobMin,
    [ANIMAL_FILTER_KEYS.MAX_DOB]: filteredDobMax,
    [ANIMAL_FILTER_KEYS.MIN_DATE_MOVED_TO_FARM]: filteredDateMovedToFarmMin,
    [ANIMAL_FILTER_KEYS.MAX_DATE_MOVED_TO_FARM]: filteredDateMovedToFarmMax,
    [ANIMAL_FILTER_KEYS.MIN_KILL_WEIGHT]: filteredMinKillWeight,
    [ANIMAL_FILTER_KEYS.MAX_KILL_WEIGHT]: filteredMaxKillWeight,
    [ANIMAL_FILTER_KEYS.KILL_QUALITY]: filteredKillQuality,
    [ANIMAL_FILTER_KEYS.KILL_FAT_SCORE]: filteredKillFatScore,
    [ANIMAL_FILTER_KEYS.MIN_DATE_LEFT_FARM]: filteredMinDateLeftFarm,
    [ANIMAL_FILTER_KEYS.MAX_DATE_LEFT_FARM]: filteredMaxDateLeftFarm,
    [ANIMAL_FILTER_KEYS.MIN_DEAD_AT_DATE]: filteredMinDeadAtDate,
    [ANIMAL_FILTER_KEYS.MAX_DEAD_AT_DATE]: filteredMaxDeadAtDate,
    [ANIMAL_FILTER_KEYS.PREGNANCY_STATUS]: filteredPregnancyStatus,
    [ANIMAL_FILTER_KEYS.PREGNANCY_DUE_FROM]: filteredPregnancyDueDateFrom,
    [ANIMAL_FILTER_KEYS.PREGNANCY_DUE_TO]: filteredPregnancyDueDateTo,
  } = animalsFilters;

  const animalTypeIdLabel: InUseLabel = {
    label: getFilterLabel.animalTypeId(filteredAnimalTypeId, speciesList),
    resetKeys: [ANIMAL_FILTER_KEYS.ANIMAL_TYPE_ID],
  };
  const breedsLabel: InUseLabel = useMemo(
    () => ({
      label: getFilterLabel.breeds(filteredBreeds, breeds),
      resetKeys: [ANIMAL_FILTER_KEYS.BREEDS],
      withTooltip: true,
    }),
    [filteredBreeds, breeds],
  );
  const sexLabel: InUseLabel = {
    label: getFilterLabel.sex(filteredSex),
    resetKeys: [ANIMAL_FILTER_KEYS.SEX],
  };
  const sexClassificationLabel: InUseLabel = {
    label: getFilterLabel.sexClassification(filteredSexClassification, sexClassificationData),
    resetKeys: [ANIMAL_FILTER_KEYS.SEX_CLASSIFICATIONS],
  };
  const dobRangeLabel: InUseLabel = {
    label: getFilterLabel.dob(getFormatDate, filteredDobMin, filteredDobMax),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_DOB, ANIMAL_FILTER_KEYS.MAX_DOB],
  };
  const dateMovedToFarmRangeLabel: InUseLabel = {
    label: getFilterLabel.dateMovedToFarm(getFormatDate, filteredDateMovedToFarmMin, filteredDateMovedToFarmMax),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_DATE_MOVED_TO_FARM, ANIMAL_FILTER_KEYS.MAX_DATE_MOVED_TO_FARM],
  };
  const ageLabel: InUseLabel = {
    label: getFilterLabel.age(filteredMinAge, filteredMaxAge),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_AGE, ANIMAL_FILTER_KEYS.MAX_AGE],
  };
  const ageDeliveryLabel: InUseLabel = {
    label: getFilterLabel.deliveryAge(filteredMinDeliveryAge, filteredMaxDeliveryAge),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_DELIVERY_AGE, ANIMAL_FILTER_KEYS.MAX_DELIVERY_AGE],
  };
  const currentEstWeightLabel: InUseLabel = {
    label: getFilterLabel.currentEstWeight(weightUnits, filteredMinCurrentEstWeight, filteredMaxCurrentEstWeight),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_CURRENT_EST_WEIGHT, ANIMAL_FILTER_KEYS.MAX_CURRENT_EST_WEIGHT],
  };
  const weightLabel: InUseLabel = {
    label: getFilterLabel.weight(weightUnits, filteredMinWeight, filteredMaxWeight),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_WEIGHT, ANIMAL_FILTER_KEYS.MAX_WEIGHT],
  };
  const daysSinceLastWeightLabel: InUseLabel = {
    label: getFilterLabel.daysSinceLastWeight(daysSinceLastWeightFrom, daysSinceLastWeightTo),
    resetKeys: [ANIMAL_FILTER_KEYS.DAYS_SINCE_LAST_WEIGHT_FROM, ANIMAL_FILTER_KEYS.DAYS_SINCE_LAST_WEIGHT_TO],
  };
  const growthRateLabel: InUseLabel = {
    label: getFilterLabel.growthRate(weightUnits, growthRateFrom, growthRateTo),
    resetKeys: [ANIMAL_FILTER_KEYS.GROWTH_RATE_FROM, ANIMAL_FILTER_KEYS.GROWTH_RATE_TO],
  };
  const weightDeliveryLabel: InUseLabel = {
    label: getFilterLabel.deliveryWeight(weightUnits, filteredMinDeliveryWeight, filteredMaxDeliveryWeight),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_DELIVERY_WEIGHT, ANIMAL_FILTER_KEYS.MAX_DELIVERY_WEIGHT],
  };

  const deliveryDateLabel: InUseLabel = {
    label: getFilterLabel.deliveryDate(getFormatDate, filteredDeliveryFrom, filteredDeliveryTo),
    resetKeys: [ANIMAL_FILTER_KEYS.DELIVERY_DATE_FROM, ANIMAL_FILTER_KEYS.DELIVERY_DATE_TO],
  };
  const groupsLabel: InUseLabel = useMemo(
    () => ({
      label: getFilterLabel.groups(filteredGroups, groups),
      resetKeys: [ANIMAL_FILTER_KEYS.GROUPS],
      withTooltip: true,
    }),
    [filteredGroups, groups],
  );
  const fieldsLabel: InUseLabel = useMemo(
    () => ({
      label: getFilterLabel.fields(filteredFields, fields),
      resetKeys: [ANIMAL_FILTER_KEYS.FIELDS],
      withTooltip: true,
    }),
    [fields, filteredFields],
  );
  const lastSyncedDateLabel: InUseLabel = {
    label: getFilterLabel.lastSyncedDate(getFormatDate, lastSyncedDateFrom, lastSyncedDateTo),
    resetKeys: [ANIMAL_FILTER_KEYS.LAST_SYNCED_DATE_FROM, ANIMAL_FILTER_KEYS.LAST_SYNCED_DATE_TO],
  };
  const isBcmsSyncedLabel: InUseLabel = {
    label: getFilterLabel.isRegulatorySynced(filteredIsBcmsSynced),
    resetKeys: [ANIMAL_FILTER_KEYS.IS_BCMS_SYNCED],
  };
  const isWithdrawalLabel: InUseLabel = {
    label: getFilterLabel.isWithdrawal(filteredIsWithdrawal),
    resetKeys: [ANIMAL_FILTER_KEYS.IS_WITHDRAWAL],
  };
  const killWeightLabel: InUseLabel = {
    label: getFilterLabel.killWeight(weightUnits, filteredMinKillWeight, filteredMaxKillWeight),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_KILL_WEIGHT, ANIMAL_FILTER_KEYS.MAX_KILL_WEIGHT],
  };
  const killQualityLabel: InUseLabel = {
    label: getFilterLabel.killQuality(filteredKillQuality),
    resetKeys: [ANIMAL_FILTER_KEYS.KILL_QUALITY],
  };
  const killFatScoreLabel: InUseLabel = {
    label: getFilterLabel.killFatScore(filteredKillFatScore),
    resetKeys: [ANIMAL_FILTER_KEYS.KILL_FAT_SCORE],
  };
  const dateLeftFarmLabel: InUseLabel = {
    label: getFilterLabel.dateLeftFarm(getFormatDate, filteredMinDateLeftFarm, filteredMaxDateLeftFarm),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_DATE_LEFT_FARM, ANIMAL_FILTER_KEYS.MAX_DATE_LEFT_FARM],
  };
  const deadAtDateLabel: InUseLabel = {
    label: getFilterLabel.deadAt(getFormatDate, filteredMinDeadAtDate, filteredMaxDeadAtDate),
    resetKeys: [ANIMAL_FILTER_KEYS.MIN_DEAD_AT_DATE, ANIMAL_FILTER_KEYS.MAX_DEAD_AT_DATE],
  };
  const pregnancyStatusLabel: InUseLabel = {
    label: getFilterLabel.pregnancyStatus(filteredPregnancyStatus),
    resetKeys: [ANIMAL_FILTER_KEYS.PREGNANCY_STATUS],
  };
  const pregnancyDueDateLabel: InUseLabel = {
    label: getFilterLabel.pregnancyDueDate(getFormatDate, filteredPregnancyDueDateFrom, filteredPregnancyDueDateTo),
    resetKeys: [ANIMAL_FILTER_KEYS.PREGNANCY_DUE_FROM, ANIMAL_FILTER_KEYS.PREGNANCY_DUE_TO],
  };

  const handleFilterItemRemove = (resetKeys: string[]) => (): void => {
    resetKeys.forEach((key) => {
      changeAnimalsFilter({ [key]: null });
    });
    setAnimalsFilterTemplateId("");
  };

  const inUseLabels: InUseLabel[] = [
    animalTypeIdLabel,
    breedsLabel,
    sexLabel,
    sexClassificationLabel,
    ageLabel,
    ageDeliveryLabel,
    currentEstWeightLabel,
    weightLabel,
    daysSinceLastWeightLabel,
    growthRateLabel,
    weightDeliveryLabel,
    deliveryDateLabel,
    dateLeftFarmLabel,
    deadAtDateLabel,
    groupsLabel,
    fieldsLabel,
    lastSyncedDateLabel,
    isBcmsSyncedLabel,
    isWithdrawalLabel,
    dobRangeLabel,
    dateMovedToFarmRangeLabel,
    killWeightLabel,
    killQualityLabel,
    killFatScoreLabel,
    pregnancyStatusLabel,
    pregnancyDueDateLabel,
  ].filter((item) => item.label);

  return (
    <div className={styles.list}>
      <Text className={styles.title} smallest>
        <strong>Filters in use:</strong>
      </Text>
      {inUseLabels.map((item) => {
        return (
          <Tooltip
            title={item.label}
            key={item.label}
            placement="top"
            disableFocusListener={!item.withTooltip}
            disableHoverListener={!item.withTooltip}
            disableTouchListener={!item.withTooltip}
          >
            <div className={styles.listItem}>
              <p className={styles.listItemLabel}>{item.label}</p>
              <div
                className={styles.closeBtn}
                tabIndex={0}
                onClick={handleFilterItemRemove(item.resetKeys)}
                onKeyPress={handleFilterItemRemove(item.resetKeys)}
              >
                <Icon name="times" size="atom" />
              </div>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
