import { Pill, PillColours, PillProps } from "components/Common/Pill";
import { Flex } from "components/Structure/Flex";
import { AnimalBreed, SummaryMetrics } from "generated/graphql";
import { FC } from "react";
import styles from "./BreedPills.module.scss";

interface Props {
  summaryMetrics: Array<Pick<SummaryMetrics, "maleCount" | "femaleCount" | "breedrCode">>;
  isBatch: boolean | undefined | null;
  animalBreeds: Array<Pick<AnimalBreed, "id">>;
  numberOfUnidentifiedAnimalsInBatch: number | undefined | null;
  animalTypeName: string | undefined;
  showAll?: boolean;
}

export const BreedPills: FC<Props> = ({
  animalBreeds,
  numberOfUnidentifiedAnimalsInBatch = 0,
  isBatch,
  summaryMetrics,
  animalTypeName,
  showAll = false,
}) => {
  const pillColor = animalTypeName && PillColours[animalTypeName] ? PillColours[animalTypeName] : "navy";
  const hasUnregisteredBreeds = isBatch && animalBreeds && animalBreeds.length > 0;
  const pillsToDisplay = showAll && summaryMetrics ? summaryMetrics.length : hasUnregisteredBreeds ? 2 : 3;
  const pillData: PillProps[] = [];

  if (summaryMetrics) {
    for (let index = 0; index < pillsToDisplay; index++) {
      const current = summaryMetrics[index];
      if (
        (current?.femaleCount && current?.maleCount && pillData.length < pillsToDisplay) ||
        (current?.femaleCount && current?.maleCount && showAll)
      ) {
        pillData.push({
          caption: `${current.femaleCount + current.maleCount} ${current?.breedrCode || "N/A"}`,
          icon: "animalSexMixed",
          colour: pillColor,
        });
        continue;
      }
      if ((current?.femaleCount && pillData.length < pillsToDisplay) || (current?.femaleCount && showAll)) {
        pillData.push({
          caption: `${current.femaleCount} ${current?.breedrCode || "N/A"}`,
          icon: "animalSexFemale",
          colour: pillColor,
        });
      }

      if ((current?.maleCount && pillData.length < pillsToDisplay) || (current?.maleCount && showAll)) {
        pillData.push({
          caption: `${current.maleCount} ${current?.breedrCode || "N/A"}`,
          icon: "animalSexMale",
          colour: pillColor,
        });
      }
    }

    if (hasUnregisteredBreeds) {
      pillData?.unshift({
        caption: `${numberOfUnidentifiedAnimalsInBatch} Unidentified`,
        icon: "animalSexMixed",
        colour: pillColor,
      });
    }

    if (summaryMetrics.length > pillsToDisplay && !showAll) {
      const numberOfPills = summaryMetrics?.reduce((acc, cur) => {
        if (cur?.femaleCount || cur?.maleCount) acc += 1;
        return acc;
      }, 0);

      pillData?.push({
        caption: `+${numberOfPills - pillsToDisplay}`,
        colour: pillColor,
      });
    }
  }
  return (
    <Flex item itemGutter container>
      {pillData.map((data, index) => (
        <Flex item key={index} className={styles["breed_pill"]}>
          <Pill key={index} {...data} colourInverted />
        </Flex>
      ))}
    </Flex>
  );
};
