import { compose, withStateHandlers, withHandlers } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
// queries
import { DELETE_DELIVERY_ADDRESS } from "trading/api/mutations";
import { GET_BUSINESS_UNITS_LIST } from "api/BusinessUnits/queries";
import { GET_FARM_SYSTEMS } from "api/Settings/queries";

import { getBUFromLocalStorage } from "helpers/storage";
// components
import FarmSettings from "./FarmSettings";

const handleSetEditData =
  ({ changeEditData, toggleEditMode, toggleForm }) =>
  (data): void => {
    changeEditData(data);
    toggleEditMode();
    toggleForm();
  };

const onCloseForm =
  ({ editMode, toggleForm, toggleEditMode, changeEditData }) =>
  (refetchDeliveryAddress): void => {
    refetchDeliveryAddress();
    toggleForm();

    if (editMode) {
      toggleEditMode();
      changeEditData({});
    }
  };

const onRemoveDeliveryAddress =
  ({ setError, changeLoading, toggleModal, deleteDeliveryAddress, editData: { id }, changeEditData }) =>
  async (refetchDeliveryAddress): Promise<void> => {
    setError(null);
    changeLoading(true);

    try {
      const {
        data: {
          deleteDeliveryAddress: { errors },
        },
      } = await deleteDeliveryAddress({
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: [
          {
            query: GET_FARM_SYSTEMS,
            variables: {
              farm: getBUFromLocalStorage(),
            },
          },
          {
            query: GET_BUSINESS_UNITS_LIST,
          },
        ],
      });

      if (errors && errors.length > 0) {
        changeLoading(false);
        setError(errors[0].message);
      } else {
        refetchDeliveryAddress();
        toggleModal();
        changeLoading(false);
        changeEditData({});
      }
    } catch (e) {
      changeLoading(false);
    }
  };

const handleOpenRemoveModal =
  ({ changeEditData, toggleModal }) =>
  (data): void => {
    changeEditData(data);
    toggleModal();
  };

const handleCloseRemoveModal =
  ({ changeEditData, toggleModal, setError }) =>
  (): void => {
    setError(null);
    changeEditData({});
    toggleModal();
  };

export default compose(
  withStateHandlers(
    {
      showForm: false,
      showModal: false,
      loading: false,
      showSystemForm: false,
      editMode: false,
      error: null,
      editData: {},
    },
    {
      toggleForm:
        ({ showForm }) =>
        (): Record<string, unknown> => ({
          showForm: !showForm,
        }),
      toggleEditMode:
        ({ editMode }) =>
        (): Record<string, unknown> => ({
          editMode: !editMode,
        }),
      toggleModal:
        ({ showModal }) =>
        (): Record<string, unknown> => ({
          showModal: !showModal,
        }),
      changeLoading:
        () =>
        (loading): Record<string, unknown> => ({
          loading,
        }),
      setError:
        () =>
        (error): Record<string, unknown> => ({
          error,
        }),
      toggleSystemForm:
        ({ showSystemForm }) =>
        (): Record<string, unknown> => ({
          showSystemForm: !showSystemForm,
        }),
      changeEditData:
        () =>
        (editData): Record<string, unknown> => ({
          editData,
        }),
    },
  ),
  graphql(DELETE_DELIVERY_ADDRESS, {
    name: "deleteDeliveryAddress",
  }),
  withHandlers({
    handleOpenRemoveModal,
    handleCloseRemoveModal,
    onRemoveDeliveryAddress,
    handleSetEditData,
    onCloseForm,
  }),
)(FarmSettings);
