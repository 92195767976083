import React from "react";
// Resources
import styles from "../Table.module.scss";

interface TableHeaderCaptionsProps {
  subtitle?: string;
  title: string;
}

export const TableHeaderCaptions: React.FC<TableHeaderCaptionsProps> = ({ subtitle, title }) => (
  <>
    <span className={styles.table__header_title}>{title}</span>

    {subtitle ? <span className={styles.table__header_subtitle}>{subtitle}</span> : null}
  </>
);
