// React
import React, { useState } from "react";
// Utils
import { useGetCurrentUserInfo } from "hooks"; // Components
import ConfirmWithPasswordModal from "pages/Settings/EmailSettings/ConfirmWithPasswordModal";
import { ModifyEmailForm } from "components/Settings";
import { Header, PageContent, LoadingOverlay, PageHeader, Spacer } from "components";
// Resources
import styles from "./styles.module.scss";

interface EmailSettingsProps {
  role: string;
}

const EmailSettings: React.FC<EmailSettingsProps> = ({ role }) => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const { data, loading } = useGetCurrentUserInfo();

  const userInfo = data?.userInfo;

  const handleEmailFormSubmit = ({ email }: { email: string }): void => {
    setEmail(email);
    setModalVisibility(true);
  };

  const handleModalClose = (): void => {
    setModalVisibility(false);
  };

  return (
    <>
      {loading ? <LoadingOverlay customStyles={styles.loadingOverlay} /> : null}

      <PageHeader>
        <Header title="Email Settings" subTitle="Manage your email preferences" />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />

        <ModifyEmailForm role={role} onFormSubmit={handleEmailFormSubmit} userInfo={userInfo} />

        <ConfirmWithPasswordModal email={email} showModal={isModalVisible} onModalClose={handleModalClose} />
      </PageContent>
    </>
  );
};

export default EmailSettings;
