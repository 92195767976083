import React from "react";
import { getFormattedPrice } from "helpers/general";
import { useFormatDate, useGetCurrentBusinessUnit } from "hooks";
import { BatchDetail } from "medicines/components/MedicineTabsBlock/BatchDetails/BatchDetail";
import { Flex, Spacer } from "components";
import { LineItemFragment } from "generated/graphql";

interface BatchDetailsProps {
  batch?: LineItemFragment | null;
}

export const BatchDetails: React.FC<BatchDetailsProps> = ({ batch }) => {
  const remainingAmount = `${batch?.totalVolume}${batch?.volumeUnits}`;
  const totalAmount = `${Number(batch?.originalVolume) * Number(batch?.quantity)}${batch?.volumeUnits}`;
  const { currencyCodeISO } = useGetCurrentBusinessUnit();
  const { getFormatDate } = useFormatDate();

  if (!batch) return null;

  return (
    <Flex container containerDirection="column">
      {batch.isArchived ? (
        <span className="bg-red-100 flex justify-center text-red-600 font-semibold mb-1">Archived</span>
      ) : null}
      <BatchDetail label="GTIN" value={batch?.sku} />

      <Spacer baselineHeight={1} />

      <BatchDetail label="Batch number" value={batch?.batchNumber} />

      <Spacer baselineHeight={1} />

      <BatchDetail label="Bottles/packs in batch" value={batch?.quantity} />

      <Spacer baselineHeight={1} />

      <BatchDetail label="Bottle/pack size" value={`${batch?.originalVolume}${batch?.volumeUnits}`} />

      <Spacer baselineHeight={1} />

      <BatchDetail label="Amount" value={`${remainingAmount} of ${totalAmount} remaining`} />

      <Spacer baselineHeight={1} />

      <BatchDetail label="Batch expiry" value={getFormatDate(batch?.expiryDate)} />

      <Spacer baselineHeight={1} />

      <BatchDetail label="Price" value={batch?.cost ? getFormattedPrice(batch?.cost, currencyCodeISO) : "\u2014"} />

      <Spacer baselineHeight={1} />

      <BatchDetail label="Purchase date" value={getFormatDate(batch?.purchaseDate)} />

      <Spacer baselineHeight={1} />

      <BatchDetail label="Purchased from" value={batch?.supplierName} />
    </Flex>
  );
};
