import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import * as Yup from "yup";
// Utils
import { TRADING, DISTRIBUTION_LISTS } from "constants/Routes";
import { CommonContext } from "config/commonProvider";
import { getBUFromLocalStorage } from "helpers/storage";
import { UpdateDistributionList, UpdateDistributionListVariables } from "trading/api/types/UpdateDistributionList";
import { CREATE_DISTRIBUTION_LIST, UPDATE_DISTRIBUTION_LIST } from "trading/api/mutations";
import { CreateDistributionList, CreateDistributionListVariables } from "trading/api/types/CreateDistributionList";
import { GET_DISTRIBUTION_LIST_BY_ID } from "trading/api/queries";
import {
  GetDistributionListById,
  GetDistributionListById_distributionList_suppliers,
  GetDistributionListByIdVariables,
} from "trading/api/types/GetDistributionListById";
import { SearchPreferredBusinessUnits_searchBusinessUnits } from "api/BusinessUnits/types/SearchPreferredBusinessUnits";
// Components
import { LoadingOverlay, PageContent, Spacer } from "components";
import { CreateEditDistributionListForm, DistributionListHeader } from "trading/components";

import styles from "./styles.module.scss";

export interface FormValues {
  name: string;
  suppliers:
    | SearchPreferredBusinessUnits_searchBusinessUnits[]
    | (GetDistributionListById_distributionList_suppliers | null)[]
    | null;
}

export type FormProps = FormikProps<FormValues>;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("List name is required!"),
  suppliers: Yup.array().required("Suppliers field is required!").nullable(),
});

const CreateEditDistributionList: React.FC = () => {
  const { id } = useParams<DistributionListEditPageParams>();
  const history = useHistory();
  const { showNotification } = useContext(CommonContext);
  const currentBusinessUnitId: number = getBUFromLocalStorage();

  const [getListInfoById, { loading: getListByIdLoading, data: listById }] = useLazyQuery<
    GetDistributionListById,
    GetDistributionListByIdVariables
  >(GET_DISTRIBUTION_LIST_BY_ID);

  useEffect(() => {
    if (id) {
      getListInfoById({
        variables: {
          businessUnit: currentBusinessUnitId,
          id: Number(id),
        },
      });
    }
  }, [id, currentBusinessUnitId, getListInfoById]);

  const [updateDistributionListMutation, { loading: updateLoading }] = useMutation<
    UpdateDistributionList,
    UpdateDistributionListVariables
  >(UPDATE_DISTRIBUTION_LIST);

  const [createDistributionListMutation, { loading: createLoading }] = useMutation<
    CreateDistributionList,
    CreateDistributionListVariables
  >(CREATE_DISTRIBUTION_LIST);

  const loading: boolean = updateLoading || createLoading;

  const initialValues: FormValues = {
    name: listById?.distributionList?.name || "",
    suppliers: listById?.distributionList?.suppliers || null,
  };

  const goToDistributionLists = (): void => {
    history.push(`${TRADING}${DISTRIBUTION_LISTS}`);
  };

  const handleFormSubmit = async (values: FormValues): Promise<void> => {
    const { name, suppliers } = values;
    const newSuppliers = (suppliers || []).map((item) => Number(item?.id));

    try {
      if (id) {
        const { data } = await updateDistributionListMutation({
          variables: {
            input: {
              id: Number(id),
              businessUnit: currentBusinessUnitId,
              name,
              suppliers: newSuppliers || [],
            },
          },
        });

        if (data?.updateDistributionList?.errors) {
          showNotification({
            variant: "error",
            message: data?.updateDistributionList?.errors[0]?.message || "Something went wrong",
          });
        } else {
          showNotification({
            message: "List successfully updated",
          });
          goToDistributionLists();
        }
      } else {
        const { data } = await createDistributionListMutation({
          variables: {
            input: {
              businessUnit: currentBusinessUnitId,
              name,
              suppliers: newSuppliers || [],
            },
          },
        });

        if (data?.createDistributionList?.errors) {
          showNotification({
            variant: "error",
            message: data?.createDistributionList?.errors[0]?.message || "Something went wrong",
          });
        } else {
          showNotification({
            message: "List successfully created",
          });
          goToDistributionLists();
        }
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error",
      });
    }
  };

  return (
    <>
      {id && getListByIdLoading ? <LoadingOverlay /> : null}
      <Formik
        enableReinitialize
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }: FormProps): JSX.Element => {
          const isDisableSaveButton = !values.name || !values.suppliers || values.suppliers.length === 0;
          return (
            <>
              <DistributionListHeader
                loading={loading}
                handleSubmit={handleSubmit}
                name={listById?.distributionList?.name}
                isDisableSaveButton={isDisableSaveButton}
              />
              <PageContent>
                <Spacer baselineHeight={3} />
                <h3 className={styles.title}>List details</h3>
                <Spacer baselineHeight={2} />
                <CreateEditDistributionListForm
                  values={values}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </PageContent>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateEditDistributionList;
