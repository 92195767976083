import { createContext } from "react";

export type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type ColSpan = Cols | "fill";

type CSSFlexBase = "flex-start" | "flex-end" | "center";
type CSSFlexAlign = CSSFlexBase | "baseline" | "stretch";
type CSSFlexJustify = CSSFlexBase | "space-between" | "space-around" | "space-evenly";

interface FlexBaseProps {
  className?: string;
  role?: string;
  children?: React.ReactNode;
}

interface FlexContainerProps {
  container?: boolean;
  containerAlignContent?: CSSFlexJustify | "stretch";
  containerAlignItems?: CSSFlexAlign;
  containerDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  containerJustifyContent?: CSSFlexJustify;
  containerWrap?: "nowrap" | "wrap" | "wrap-reverse";
  containerResetL?: ColSpan;
  containerResetM?: ColSpan;
  containerResetS?: ColSpan;
  containerResetXL?: ColSpan;
  containerResetXS?: ColSpan;
}

interface FlexItemProps {
  item?: boolean;
  itemAlignSelf?: CSSFlexAlign;
  itemBasisFill?: boolean;
  itemGrow?: boolean;
  itemGutter?: boolean;
  itemOrder?: number;
  itemNoShrink?: boolean;
  l?: ColSpan;
  m?: ColSpan;
  s?: ColSpan;
  xl?: ColSpan;
  xs?: ColSpan;
}

export type FlexProps = FlexBaseProps & FlexContainerProps & FlexItemProps;

export const ColumnContext = createContext<ColSpan>(12);

export { Flex } from "./Flex";
