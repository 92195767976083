import * as React from "react";

import pure from "recompose/pure";

import styles from "./styles.module.css";

interface Props {
  percents: number;
}

const ProgressBar: React.FC<Props> = ({ percents }) => (
  <div className={styles.mainWrapper}>
    <div className={styles.backWrapper}>
      <div
        className={styles.filler}
        style={{
          width: `${percents}%`,
        }}
      />
    </div>
  </div>
);

export default pure(ProgressBar);
