import { FC, useContext } from "react";
// Libraries
import { CSSTransition } from "react-transition-group";
// Utils
import { ANIMATION_DURATION } from "constants/Interface";
import { DropdownMenuContext } from "../";
// Resources
import styles from "../DropdownMenu.module.scss";

interface IProps {
  children?: React.ReactNode;
}

export const DropdownMenuContent: FC<IProps> = ({ children }) => {
  const { isOpen } = useContext(DropdownMenuContext);

  return (
    <CSSTransition
      timeout={ANIMATION_DURATION}
      in={isOpen}
      mountOnEnter
      classNames={{
        appear: "",
        appearActive: "",
        enter: styles["dropdown_menu__content--enter"],
        enterActive: styles["dropdown_menu__content--enter_active"],
        enterDone: styles["dropdown_menu__content--enter_done"],
        exit: styles["dropdown_menu__content--exit"],
        exitActive: styles["dropdown_menu__content--exit_active"],
        exitDone: styles["dropdown_menu__content--exit_done"],
      }}
    >
      <div className={styles.dropdown_menu__content}>{children}</div>
    </CSSTransition>
  );
};
