import { FC, forwardRef } from "react";
// Libraries
import { NavLink } from "react-router-dom";
import cn from "classnames";
// Utils
import { ICON_NAMES } from "constants/Icons";
// Components
import { Badge, Icon } from "components";
// Resources
import styles from "./RootMenuLink.module.scss"; // Also styles RootMenuToggle

export interface RootMenuLinkProps {
  activePaths?: string[];
  badge?: BadgeProps;
  className?: string;
  icon: string;
  iconSmall?: boolean;
  onClick?: () => void;
  path: string;
}

const checkIsActive = (currentUrl: string, activePaths: string[]): boolean =>
  activePaths.reduce((currentStatus: boolean, pathname: string): boolean => {
    return currentUrl.includes(pathname);
  }, false);

export const RootMenuLink: FC<RootMenuLinkProps> = forwardRef<HTMLAnchorElement, RootMenuLinkProps>(
  ({ activePaths = [], badge, className, icon, iconSmall = true, path, ...props }, forwardedRef) => {
    const isLinkActive = (match, location): boolean => !!match || checkIsActive(location?.pathname, activePaths);

    const isNamedIcon = icon in ICON_NAMES;
    const iconProps = isNamedIcon ? { name: icon as keyof typeof ICON_NAMES } : { src: icon };

    return (
      <NavLink
        activeClassName={styles["root_menu_link--active"]}
        className={cn(styles.root_menu_link, className)}
        id={path}
        isActive={isLinkActive}
        ref={forwardedRef}
        to={path}
        {...props}
      >
        <Icon
          className={styles.root_menu_link__icon}
          colour="white"
          size={iconSmall ? "medium" : "large"}
          {...iconProps}
        />
        {badge ? <Badge className={cn(styles.root_menu_link__badge, badge.className)} text={badge.text} /> : null}
      </NavLink>
    );
  },
);

RootMenuLink.displayName = "RootMenuLink";
