import React from "react";

import pure from "recompose/pure";

import cn from "classnames";

import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import { ELEMENTS_IDS } from "constants/ElementsIds";

import styles from "./styles.module.css";

interface Props {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const CloseButton: React.FC<Props> = ({ className, onClick }) => (
  <IconButton
    id={ELEMENTS_IDS.ICON_CLOSE_BUTTON}
    onClick={onClick}
    className={cn(styles.iconBtn, styles.closeBtn, className)}
  >
    <Close
      classes={{
        root: styles.icon,
      }}
    />
  </IconButton>
);

export default pure(CloseButton);
