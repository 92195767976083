import { VFC } from "react";
// API
import { MyLotQuery } from "generated/graphql";
// Utils
import { LOT_STATUSES } from "trading/constants";
import { getFormattedListFromArray } from "helpers/strings";
import { getFormattedPrice } from "helpers/general";
import { useGetCurrentBusinessUnit } from "hooks";
// Containers
import { SellerListingAnimals } from "./SellerListingAnimals";
import { SellerListingOffers } from "./SellerListingOffers";
// Components

import { Flex, Paper, PhotoManager, Spacer, Text, Title } from "components";

export interface SellerListingCallbackProps {
  onAfterMutateListing: () => void;
}

interface SellerListingProps extends SellerListingCallbackProps {
  listing: MyLotQuery["myLot"];
}

export const SellerListing: VFC<SellerListingProps> = ({ listing, onAfterMutateListing }) => {
  if (!listing) {
    return null;
  }

  const {
    animals,
    description,
    price,
    images,
    lotOffers: offers,
    lotConfigurableSellerMessage,
    pricePerKgOnCreation,
  } = listing;

  const { currencyCodeISO } = useGetCurrentBusinessUnit();

  const initialPhotos =
    images?.map((item) => ({
      id: item?.id || "",
      photo: item?.thumbnailSmall || "",
      isSelected: false,
      isNew: false,
      position: item?.position || 0,
    })) || [];

  const photos = initialPhotos.sort((a, b) => a?.position - b?.position);

  const listingStatus = listing?.status || LOT_STATUSES.CREATED;

  const isPrivate = !listing.isPublic;
  const distributionListsNames = listing.distributionLists.map((distributionList) => distributionList.name);

  const distributionLists = getFormattedListFromArray(distributionListsNames, { oxfordComma: true });

  const totalPrice = price && animals ? price * animals?.length : 0;

  const priceFormatted = price && getFormattedPrice(price, currencyCodeISO);
  const pricePerKgFormatted = pricePerKgOnCreation && getFormattedPrice(pricePerKgOnCreation, currencyCodeISO);
  const totalPriceFormatted = getFormattedPrice(totalPrice, currencyCodeISO);

  return (
    <>
      {offers.length > 0 ? (
        <>
          <SellerListingOffers
            listingId={Number(listing.id)}
            listingStatus={listingStatus}
            lotConfigurableSellerMessage={lotConfigurableSellerMessage}
            offers={offers || []}
            onAfterMutateListing={onAfterMutateListing}
          />

          <Spacer baselineHeight={4} />
        </>
      ) : null}

      <Flex container containerDirection="column">
        <Flex item itemGutter>
          <Paper>
            <Spacer baselineHeight={4} />

            <Flex container containerJustifyContent="space-evenly">
              <Flex item>
                <Text>No of Animals</Text>

                <Title secondary>{animals?.length}</Title>
              </Flex>

              <Flex item>
                <Text>Asking per head</Text>

                <Title secondary>{priceFormatted}</Title>
              </Flex>

              <Flex item>
                <Text>Asking per kg</Text>

                <Title secondary>{pricePerKgFormatted}</Title>
              </Flex>

              <Flex item>
                <Text>Asking total price</Text>

                <Title secondary>{totalPriceFormatted}</Title>
              </Flex>
            </Flex>
            <Spacer baselineHeight={3} />
          </Paper>
        </Flex>
      </Flex>

      {description ? (
        <>
          <Spacer baselineHeight={4} />

          <Flex container containerDirection="column">
            <Flex item itemGutter>
              <Title tertiary>Description</Title>

              <Spacer baselineHeight={2} />

              <Text>{description}</Text>
            </Flex>
          </Flex>
        </>
      ) : null}

      <Spacer baselineHeight={4} />

      <SellerListingAnimals animals={animals} listingId={listing?.id} />

      {photos.length > 0 ? (
        <>
          <Spacer baselineHeight={4} />

          <Flex container>
            <Flex item itemGutter>
              <Title tertiary>Pictures</Title>
            </Flex>

            <Spacer baselineHeight={2} />

            <Flex item itemGutter xs={12}>
              <PhotoManager photos={photos} isSelected={false} />
            </Flex>
          </Flex>
        </>
      ) : null}

      {isPrivate && distributionLists ? (
        <>
          <Spacer baselineHeight={4} />

          <Flex container>
            <Flex item itemGutter>
              <Title tertiary>Shared privately</Title>
            </Flex>

            <Spacer baselineHeight={2} />

            <Flex item itemGutter xs={12}>
              <Text>This listing is shared privately with {distributionLists}.</Text>
            </Flex>
          </Flex>
        </>
      ) : null}
    </>
  );
};
