// Types
import { GetInventoryInfo_inventoryInfo } from "api/Medicine/types/GetInventoryInfo";
import { InputSelectOption } from "components/Common/Field/Select";

/**
 * @param batches array of batch objects
 * @returns array of batch list select options
 */
export const getBatchOptions = (batches: (GetInventoryInfo_inventoryInfo | null)[]): (InputSelectOption | null)[] => {
  if (!batches || !batches.length) return [];

  return batches?.map((batch) => ({
    key: `${batch?.id}-${batch?.stockItemId}`,
    label: `${batch?.id} - ${batch?.totalRemainingVolume}/${batch?.totalOriginalVolume} ${batch?.volumeUnits} remaining`,
    value: batch?.id,
  })) as InputSelectOption[];
};
