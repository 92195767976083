import { Button } from "twComponents/Button";
import { useRowSelection } from "hooks/useRowSelection";
import { useFormContext } from "react-hook-form";
import { TransactionValidationSchema as S } from "financial/components/TransactionForm/validation";
import { Exact, InputMaybe } from "generated/graphql";
import { TableType } from "../TransactionAnimalsTable";
import { useAnimalActions } from "../hooks/useAnimalActions";

interface Props {
  animalsTableType: TableType;
  setAnimalsTableType: React.Dispatch<React.SetStateAction<TableType>>;
  isEditMode: boolean;
  isNewTransaction: boolean;
  queryVariables: TransactionAnimalsQueryVariables;
  setQueryVariables: SetTransactionAnimalsQueryVariables;
}

export const AnimalTableActions = ({
  setQueryVariables,
  isNewTransaction,
  isEditMode,
  animalsTableType,
  setAnimalsTableType,
  queryVariables,
}: Props) => {
  const methods = useFormContext<S>();

  if (!methods) {
    throw new Error("AnimalTableActions must be used inside a <Form /> or <FormProvider />");
  }

  const { handleRemoveAnimals, isLoadingRemoveAnimals, handleAddAnimals, handleCancel } = useAnimalActions({
    setAnimalsTableType,
    setQueryVariables,
    queryVariables,
    isNewTransaction,
  });

  const { selectedAnimalIds: animalIdsToRemove } = useRowSelection({
    tableId: "financial_transaction_animals_table",
  });

  return (
    <div>
      {animalsTableType === "allAnimalsTable" ? (
        <>
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleAddAnimals} className="ml-2">
            Save & Add Animals
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={handleRemoveAnimals}
            disabled={animalIdsToRemove.length === 0 || !isEditMode}
            variant="destructive"
            loading={isLoadingRemoveAnimals}
          >
            Remove Animals
          </Button>
          <Button
            onClick={() => {
              setAnimalsTableType("allAnimalsTable");
            }}
            disabled={!isEditMode}
            className="ml-2"
          >
            Add Animals
          </Button>
        </>
      )}
    </div>
  );
};

export type TransactionAnimalsQueryVariables =
  | Exact<{
      businessUnit: number;
      id: string | undefined;
      before?: InputMaybe<string> | undefined;
      after?: InputMaybe<string> | undefined;
      first?: InputMaybe<number> | undefined;
      last?: InputMaybe<number> | undefined;
    }>
  | undefined;

export type SetTransactionAnimalsQueryVariables = React.Dispatch<
  React.SetStateAction<TransactionAnimalsQueryVariables>
>;
