import React from "react";
// Libraries
import { DateTime } from "luxon";
// @ts-ignore
import { IntercomAPI } from "react-intercom";
// Utils
import { getDaysRelativeToToday } from "helpers/general";
import { getIntercomMessage } from "./helpers";
// Types
import { PillProps } from "components/Common/Pill";
// Components
import { Pill, Spacer } from "components";
import { useFormatDate } from "hooks";
import { useHubSpot } from "hooks/useHubSpot";

interface ChatMessageProps {
  listingTitle: string | null | undefined;
  referenceCode: string | null | undefined;
  messageVariant: "buyer" | "seller";
}

interface StatusPillProps extends Pick<PillProps, "className" | "width" | "wrap"> {
  expiryDate: string | Date;
  startDate: string | Date;
  chatMessageProps?: ChatMessageProps;
}

export const StatusPill: React.FC<StatusPillProps> = ({
  expiryDate,
  startDate,
  chatMessageProps,
  width = "auto",
  ...props
}): JSX.Element | null => {
  const { getFormatDate } = useFormatDate();
  const expiryJSDate = expiryDate instanceof Date ? expiryDate : DateTime.fromISO(expiryDate).toJSDate();
  const startJSDate = startDate instanceof Date ? startDate : DateTime.fromISO(startDate).toJSDate();
  const { isHubSpotChatEnabled, openHubSpotChat } = useHubSpot();

  if (!DateTime.fromJSDate(startJSDate).isValid) return null;

  // The listing is new if it was created less than three days ago.
  const isNewListing = Math.abs(getDaysRelativeToToday(startJSDate)) < 3;

  if (isNewListing) {
    return <Pill caption="New" colour="navy" title="New" width={width} {...props} />;
  }

  if (!DateTime.fromJSDate(expiryJSDate).isValid) return null;

  const isExpiredListing = getDaysRelativeToToday(expiryJSDate) < 0;

  if (isExpiredListing) {
    const title = `Expired on ${getFormatDate(expiryDate)}`;

    if (chatMessageProps != undefined) {
      const handleOpenChat = (): void => {
        if (isHubSpotChatEnabled) {
          openHubSpotChat("?chat=true");
        } else {
          const message = getIntercomMessage(chatMessageProps);

          IntercomAPI("showNewMessage", message);
        }
      };

      return (
        <Pill
          caption="Contact Us"
          colour="green"
          title={title}
          width={width}
          onClick={handleOpenChat}
          role="button"
          {...props}
        />
      );
    }

    return <Pill caption="Contact Us" colour="green" title={title} width={width} {...props} />;
  }

  return <Spacer baselineHeight={3} />;
};
