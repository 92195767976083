import { InputSelectOption } from "components/Common/Field/Select";
import { TransactionRecordEnumType, useFinancialTransactionCategoriesQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";

export const useFinancialTransactionCategories = () => {
  const { id } = useGetCurrentBusinessUnit();

  const { data, ...rest } = useFinancialTransactionCategoriesQuery({
    variables: {
      businessUnit: id,
    },
  });

  const categories = removeNothings(data?.financialTransactionCategories || []);

  const categoryOptions: InputSelectOption[] = categories
    .filter((category) => category.recordType === TransactionRecordEnumType.Expense)
    .map(({ id, label }) => {
      return {
        value: id,
        label,
        key: id,
      };
    });

  return {
    categoryOptions,
    categories,
    data,
    ...rest,
  };
};
