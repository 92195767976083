// React
import React from "react";
// Utils
import { handleErrorCheck } from "helpers/general";
import { useGetPreferredBusinessUnits } from "hooks";
import { SearchPreferredBusinessUnits_searchBusinessUnits } from "api/BusinessUnits/types/SearchPreferredBusinessUnits";
// Components
import { Flex, FieldText, Spacer } from "components";
import { PreferredSuppliersSelect } from "components/Settings";
import { FormProps } from "trading/pages/DistributionLists/CreateEditDistributionList";

import styles from "./styles.module.scss";

type ListFormValues = Pick<FormProps, "values" | "errors" | "handleSubmit" | "setFieldValue" | "handleChange">;

export const CreateEditDistributionListForm: React.FC<ListFormValues> = ({
  values,
  errors,
  handleSubmit,
  handleChange,
  setFieldValue,
}) => {
  const { data: preferredBusinessUnits } = useGetPreferredBusinessUnits();

  const handleSetSupplier = (value: SearchPreferredBusinessUnits_searchBusinessUnits[] | null): void => {
    setFieldValue("suppliers", value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex container>
        <Flex item itemGutter xs={12} l={6} xl={4}>
          <FieldText
            label="List name"
            error={handleErrorCheck(errors.name)}
            inputProps={{
              name: "name",
              value: values.name,
              onChange: handleChange,
            }}
          />
          <Spacer baselineHeight={2} />
          <p>Search for suppliers</p>
          <Spacer baselineHeight={1} />
          <PreferredSuppliersSelect
            includePreferredSuppliers
            preferredBusinessUnits={preferredBusinessUnits}
            setSuppliers={handleSetSupplier}
            suppliers={values.suppliers}
          />
          <p className={styles.errorText}>{errors.suppliers}</p>
        </Flex>
      </Flex>
      <Spacer baselineHeight={3} />
    </form>
  );
};
