import { useEffect, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "auth/hooks/useAuth";
import { usePrevious } from "hooks";
import { LOGIN } from "constants/Routes";
import { LoadingOverlay } from "components";
import { selectActiveBusinessUnitId } from "feShared/businessUnit/reducer";
import { useSelector } from "react-redux";

interface IProps {
  children?: React.ReactNode;
  path?: string;
}

export const PrivateRoute: React.FC<IProps> = ({ children, ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const { exchangeTokenForJWT, isUserIdAlreadyLoggedIn, changeBusinessUnit, isAuthenticated, changeBUToDefault } =
    useAuth();
  const activeReduxBuId = useSelector(selectActiveBusinessUnitId);
  const [processingToken, setProcessingToken] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const previousToken = usePrevious(token);

  // If we add redux persist we wont need this.
  useEffect(() => {
    if (!activeReduxBuId) {
      changeBUToDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeReduxBuId]);

  /* Example URL Case:
  http://localhost:3001/chat?user_id=7&bu=362&token=<token>
  If we use /system routes then we get redirect by Graphql Error handler.... might be worth changing at some point
  */

  const processToken = async () => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    if (token && token !== previousToken) {
      setToken(token);
    } else {
      return;
    }
    setProcessingToken(true);
    const userId = query.get("user_id");
    const bu = query.get("bu");
    let isLoggedIn = await isUserIdAlreadyLoggedIn(userId);
    if (isLoggedIn) {
      await changeBusinessUnit(bu);
    } else {
      isLoggedIn = await exchangeTokenForJWT(token, bu ?? undefined);
    }
    query.delete("token");
    history.replace({
      search: query.toString(),
    });
    setProcessingToken(false);
  };

  if (location.search.includes("token") && !processingToken) {
    processToken();
  }

  if (processingToken) {
    return <LoadingOverlay />;
  }

  return <Route {...rest} render={() => (isAuthenticated() ? children : <Redirect to={LOGIN} />)} />;
};
