import React, { useEffect, useState } from "react";
// Libraries
import { useQuery } from "@apollo/client";
// Utils
import { FarmSystemTaskStatus } from "api/graphql-global-types";
import {
  GetTaskInfo_farmSystemTask,
  GetTaskInfo,
  GetTaskInfoVariables,
} from "api/Import/OtherSystems/types/GetTaskInfo";
import { GET_TASK_STATUS } from "api/Import/OtherSystems/queries";
import { getTaskIdFromLocalStorage } from "helpers/storage";
// Components
import { ImportResult, ImportResultProps } from "./ImportResult";
import { Flex, LoadingOverlay } from "components";
// Resources
import styles from "./ImportWeightResult.module.css";

interface ImportWeightResultProps extends Omit<ImportResultProps, "farmSystemTask" | "percents"> {
  setCreateAnimals: (task: GetTaskInfo_farmSystemTask | null) => void;
  isAphisIntegrationSystem?: boolean;
  isBcmsIntegrationSystem?: boolean;
}

export const ImportWeightResult: React.FC<ImportWeightResultProps> = ({
  changeTaskId,
  fileName,
  removeFile,
  setCreateAnimals,
  isAphisIntegrationSystem,
  isBcmsIntegrationSystem,
}) => {
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [isCreateNewAnimals, setIsCreateNewAnimals] = useState<boolean>(false);

  const { data, loading, stopPolling } = useQuery<GetTaskInfo, GetTaskInfoVariables>(GET_TASK_STATUS, {
    variables: {
      taskId: Number(getTaskIdFromLocalStorage()),
    },
    pollInterval: 300,
  });

  useEffect((): void => {
    if (data?.farmSystemTask) {
      const processedItems: number = data.farmSystemTask?.processedNumberItems || 0;
      const totalItems: number = data.farmSystemTask?.totalNumberItems || 0;
      setProgressPercent((processedItems / totalItems) * 100 || 0);

      if (
        data.farmSystemTask.status === FarmSystemTaskStatus.DONE ||
        data.farmSystemTask.status === FarmSystemTaskStatus.FAILED
      ) {
        stopPolling();
      }

      if (data.farmSystemTask.producedTask && data.farmSystemTask.status === "DONE") {
        setIsCreateNewAnimals(true);
      }
    }
  }, [data, stopPolling]);

  useEffect(() => {
    if (isCreateNewAnimals) {
      setCreateAnimals(data?.farmSystemTask || null);
    }
  }, [data, isCreateNewAnimals, setCreateAnimals]);

  return (
    <Flex container containerDirection="column" item itemGrow>
      {loading ? (
        <LoadingOverlay customStyles={styles.loadingOverlay} />
      ) : (
        <ImportResult
          changeTaskId={changeTaskId}
          farmSystemTask={data?.farmSystemTask as GetTaskInfo_farmSystemTask}
          fileName={fileName}
          percents={progressPercent}
          removeFile={removeFile}
          isAphisIntegrationSystem={isAphisIntegrationSystem}
          isBcmsIntegrationSystem={isBcmsIntegrationSystem}
        />
      )}
    </Flex>
  );
};
