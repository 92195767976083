// Libraries
import React from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
// Utils
import { handleErrorCheck } from "helpers/general";
import { useGetCurrentUserInfo, useGetCurrentBusinessUnit } from "hooks";
import { phone } from "helpers/regex";
// Components
import { Button, FieldDate, FieldText, Flex, Spacer, Text, Title } from "components";

export type FormRequestToCTS = {
  holdingId: string;
  businessName: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  postcode: string;
  phoneNumber: string;
  email: string;
  contactName: string;
};

type FormProps = FormikProps<FormRequestToCTS>;

type Props = {
  onPrevStep: () => void;
  onNextStep: (data: FormRequestToCTS) => void;
};

const validationSchema = Yup.object().shape({
  holdingId: Yup.string().required("CPH number is required!"),
  businessName: Yup.string().required("Business Name is required!"),
  addressOne: Yup.string().required("Address 1 is required!"),
  addressTwo: Yup.string().required("Address 2 is required!"),
  city: Yup.string().required("City is required!"),
  postcode: Yup.string().required("Postcode is required!"),
  phoneNumber: Yup.string().required("Phone number is required!"),
  email: Yup.string().required("Email is required!").email("Please enter a valid email address"),
  contactName: Yup.string().required("Contact Name is required!"),
});

export const SecondStep: React.FC<Props> = ({ onPrevStep, onNextStep }) => {
  const { primaryLocation, name } = useGetCurrentBusinessUnit();
  const { data: userInfoData } = useGetCurrentUserInfo();
  const userInfo = userInfoData?.userInfo;
  const initialValues = {
    holdingId: primaryLocation?.locationId || "",
    businessName: name || "",
    addressOne: primaryLocation?.addressLine1 || "",
    addressTwo: primaryLocation?.addressLine2 || "",
    city: primaryLocation?.city || "",
    postcode: primaryLocation?.postcode || "",
    phoneNumber: "",
    email: userInfo?.email || "",
    contactName: `${userInfo?.firstName} ${userInfo?.lastName}` || "",
  };

  return (
    <Flex container xs={7} containerJustifyContent="center">
      <Flex item itemGutter xs={10} s={8} m={6} l={4}>
        <Title tertiary>STEP 2 - Check or amend your farm details</Title>
      </Flex>
      <Spacer baselineHeight={2} />
      <Formik
        enableReinitialize
        onSubmit={onNextStep}
        initialValues={initialValues}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, handleSubmit }: FormProps): JSX.Element => {
          return (
            <Flex item itemGutter xs={12} s={8} m={6} l={4}>
              <FieldText
                label="CPH number"
                error={handleErrorCheck(errors.holdingId)}
                inputProps={{
                  name: "holdingId",
                  value: values.holdingId,
                  onChange: handleChange,
                }}
              />
              <FieldText
                label="Business Name"
                error={handleErrorCheck(errors.businessName)}
                inputProps={{
                  name: "businessName",
                  value: values.businessName,
                  onChange: handleChange,
                }}
              />
              <FieldText
                label="Address 1"
                error={handleErrorCheck(errors.addressOne)}
                inputProps={{
                  name: "addressOne",
                  value: values.addressOne,
                  onChange: handleChange,
                }}
              />
              <FieldText
                label="Address 2"
                error={handleErrorCheck(errors.addressTwo)}
                inputProps={{
                  name: "addressTwo",
                  value: values.addressTwo,
                  onChange: handleChange,
                }}
              />
              <FieldText
                label="City"
                error={handleErrorCheck(errors.city)}
                inputProps={{
                  name: "city",
                  value: values.city,
                  onChange: handleChange,
                }}
              />
              <FieldText
                label="Postcode"
                error={handleErrorCheck(errors.postcode)}
                inputProps={{
                  name: "postcode",
                  value: values.postcode,
                  onChange: handleChange,
                }}
              />
              <FieldText
                label="Telephone"
                error={handleErrorCheck(errors.phoneNumber)}
                inputProps={{
                  name: "phoneNumber",
                  value: values.phoneNumber,
                  onChange: handleChange,
                  validation: phone,
                }}
              />
              <FieldText
                label="Email address"
                error={handleErrorCheck(errors.email)}
                inputProps={{
                  name: "email",
                  value: values.email,
                  onChange: handleChange,
                }}
              />
              <FieldText
                label="Web service provide name"
                inputProps={{
                  name: "webServiceName",
                  value: "Breedr",
                  onChange: handleChange,
                  disabled: true,
                }}
              />
              <FieldText
                label="Contact Name"
                error={handleErrorCheck(errors.contactName)}
                inputProps={{
                  name: "contactName",
                  value: values.contactName,
                  onChange: handleChange,
                }}
              />
              <FieldDate
                label="Date"
                inputProps={{
                  name: "date",
                  value: new Date(),
                  disabled: true,
                }}
              />
              <Spacer baselineHeight={2} />
              <Text>Choose</Text> <strong>Next</strong> <Text>to proceed.</Text>
              <Spacer baselineHeight={4} />
              <Flex container containerJustifyContent="center">
                <Flex item itemGutter>
                  <Button caption="Cancel" variant="hollow" colour="grey" onClick={onPrevStep} />
                </Flex>
                <Flex item itemGutter>
                  <Button caption="Next" onClick={handleSubmit} />
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </Flex>
  );
};
