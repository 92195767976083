import React from "react";
import { MedicinesTable } from "medicines/components/MedicinesTable";
import { Header, PageContent, PageHeader, Spacer } from "components";

const MedicineCabinet: React.FC = () => {
  return (
    <>
      <PageHeader>
        <Header title="Medicine Cabinet" subTitle="View and manage your medicines." />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />

        <MedicinesTable />
      </PageContent>
    </>
  );
};

export default MedicineCabinet;
