import { gql } from "@apollo/client";

export const integrationSystemFragment = gql`
  fragment integrationSystemFragment on IntegrationSystemType {
    id
    name
    logo
    helpLink
    fields {
      id
      fieldLabel
      fieldName
      fieldType
      inputMask
      minLength
      maxLength
      placeholder
    }
    slug
  }
`;

export const farmSystemFragment = gql`
  fragment farmSystemFragment on FarmSystemType {
    id
    errorMessage
    credentials
    status
    integrationSystem {
      ...integrationSystemFragment
    }
    ... on BcmsConnectionType {
      location {
        id
        locationId
        unitName
      }
    }
  }
  ${integrationSystemFragment}
`;
