import React, { useState, useCallback } from "react";
import cn from "classnames";
import MaskedInput from "react-text-mask";
import { AnimalCountField as AnimalsCountSelectField } from "./AnimalCountField";
import { BusinessTypeField as BusinessTypeSelectField } from "./BusinessSpecializationField";
import { CountySelectField } from "./CountySelectField";
import { SourceOfInfoAboutBreedrSelectField } from "./SourceOfInfoAboutBreedrSelectField";
import { cphNumberMask } from "helpers/regex";

import closedEyeIcon from "assets/img/Auth/closed-eye.svg";
import openEyeIcon from "assets/img/Auth/open-password.svg";

import styles from "./styles.module.scss";

export type SignUpInputProps = {
  type?:
    | "text"
    | "password"
    | "country"
    | "howDidYouHearAboutBreedr"
    | "howDidYouHearAboutBreedrOther"
    | "cph"
    | "businessSpecialization"
    | "animalsCount"
    | "referralCode";
  name: string;
  value: string;
  error?: string;
  label?: string;
  size?: "small" | "medium";
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  setFieldValue: <T>(field: string, value: T) => void;
} & InputText;

export const HONEY_TRAP_FIELD_NAME = "repeatEmail";

export const PASSWORD_VALIDATION_RULES = {
  LENGTH: /^.{8,}$/,
  LETTER: /^.*[A-Za-z]{1,}.*$/,
  UPPERCASE: /^.*[A-Z]{1,}.*$/,
  LOWERCASE: /^.*[a-z]{1,}.*$/,
  SYMBOL: /^.*[^A-Za-z0-9 ]{1,}.*$/,
  NUMBER: /^.*[0-9]{1,}.*$/,
};

const VALIDATIONS = [
  {
    title: "Be at least 8 characters long",
    rule: PASSWORD_VALIDATION_RULES.LENGTH,
  },
  {
    title: "Include 1 letter [A-Z, a-z]",
    rule: PASSWORD_VALIDATION_RULES.LETTER,
  },
  {
    title: "Include 1 digit [0-9]",
    rule: PASSWORD_VALIDATION_RULES.NUMBER,
  },
];

const renderPasswordValidationList = (value: string): JSX.Element => {
  return (
    <ul>
      {VALIDATIONS.map((item) => {
        return (
          <li key={item.title} className={styles.validationRule}>
            <span
              className={cn(styles.indicator, {
                [styles.active]: item.rule.test(value),
              })}
            />
            {item.title}
          </li>
        );
      })}
    </ul>
  );
};

export const SignUpInput: React.FC<SignUpInputProps> = ({
  name,
  type = "text",
  error,
  value,
  label,
  onBlur,
  size,
  setFieldValue,
  ...rest
}) => {
  const [inputType, changeInputType] = useState<string>(type);
  const [showTooltip, setTooltipVisibility] = useState<boolean>(false);

  const isDefaultPasswordType: boolean = type === "password";
  const isPasswordType: boolean = inputType === "password";
  const isHoneyTrapField: boolean = name === HONEY_TRAP_FIELD_NAME;

  const handleInputOnFocus = (): void => {
    if (isDefaultPasswordType) {
      setTooltipVisibility(true);
    }
  };

  const handleSelectChange = <T extends ObjectOf<T>>(value: T, isOther?: boolean, isReferral?: boolean): void => {
    setFieldValue(name, value);
    if (isOther) {
      setFieldValue("isOther", true);
      setFieldValue("isReferral", false);
    } else if (isReferral) {
      setFieldValue("isOther", false);
      setFieldValue("isReferral", true);
    } else {
      setFieldValue("isOther", false);
      setFieldValue("isReferral", false);
    }
  };

  const handleSelectCountryChange = (values: { value: string; code: string }): void => {
    setFieldValue("country", values.value);
    setFieldValue("countryCode", values.code.toLowerCase());
    setFieldValue("holdingId", "");
  };

  const handleInputOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    onBlur(event);

    if (isDefaultPasswordType) {
      setTooltipVisibility(false);
    }
  };

  const handlePasswordVisibilityClick = useCallback((): void => {
    if (isPasswordType) {
      changeInputType("text");
    } else {
      changeInputType("password");
    }
  }, [isPasswordType]);

  const renderInputElement = (): JSX.Element => {
    switch (type) {
      case "country":
        return <CountySelectField onChange={handleSelectCountryChange} name={name} error={error} />;

      case "howDidYouHearAboutBreedr":
        return <SourceOfInfoAboutBreedrSelectField onChange={handleSelectChange} name={name} error={error} />;

      case "howDidYouHearAboutBreedrOther":
        return (
          <textarea
            onFocus={handleInputOnFocus}
            id={name}
            name={name}
            className={cn(styles.input, {
              [styles.error]: error,
            })}
            value={value}
            {...rest}
          />
        );

      case "referralCode":
        return (
          <input
            onFocus={handleInputOnFocus}
            onBlur={handleInputOnBlur}
            id={name}
            name={name}
            className={cn(styles.input, {
              [styles.error]: error,
            })}
            value={value}
            {...rest}
          />
        );

      case "cph":
        return (
          <MaskedInput
            label="Destination CPH number"
            mask={cphNumberMask}
            name={name}
            value={value}
            render={(ref, props): JSX.Element => (
              <input
                id={name}
                ref={(input): HTMLInputElement | null => ref(input)}
                name={name}
                className={cn(styles.input, {
                  [styles.error]: error,
                  [styles.password]: isDefaultPasswordType,
                })}
                type={inputType}
                value={value}
                {...props}
              />
            )}
            {...rest}
          />
        );

      case "businessSpecialization":
        return <BusinessTypeSelectField onChange={setFieldValue} name={name} error={error} />;

      case "animalsCount":
        return <AnimalsCountSelectField onChange={setFieldValue} name={name} error={error} />;

      default:
        return (
          <input
            onFocus={handleInputOnFocus}
            onBlur={handleInputOnBlur}
            id={name}
            name={name}
            className={cn(styles.input, {
              [styles.error]: error,
              [styles.password]: isDefaultPasswordType,
            })}
            type={inputType}
            value={value}
            autoComplete="new-password"
            {...rest}
          />
        );
    }
  };

  return (
    <div
      className={cn(styles.inputBlock, {
        [styles.emailRepeat]: isHoneyTrapField,
        [styles.medium]: size === "medium",
        [styles.small]: size === "small",
      })}
    >
      <div className={styles.labelWrapper}>
        {label ? (
          <label htmlFor={name} className={styles.inputLabel}>
            {label}
          </label>
        ) : null}
        {error ? <p className={styles.inputErrorLabel}>{error}</p> : null}
      </div>
      <div className={styles.inputWrapper}>
        {renderInputElement()}
        {isDefaultPasswordType ? (
          <img
            onClick={handlePasswordVisibilityClick}
            className={styles.toggleButton}
            src={isPasswordType ? closedEyeIcon : openEyeIcon}
            alt={isPasswordType ? "Show password" : "Hide password"}
          />
        ) : null}
        {showTooltip && isDefaultPasswordType ? (
          <div className={styles.tooltip}>
            <p className={styles.tooltipTitle}>Your password must:</p>
            {renderPasswordValidationList(value)}
          </div>
        ) : null}
      </div>
    </div>
  );
};
