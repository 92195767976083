import React, { createContext, FC, useCallback, useState, useEffect } from "react";
import { TABLE_IDS } from "constants/Interface";
import { usePrevious, useStateSpecies } from "hooks";
import { getBUFromLocalStorage } from "helpers/storage";

// Why `Record<string, never>` here? See explanation under 'If you want a type that means "empty object"' here:
// https://github.com/typescript-eslint/typescript-eslint/issues/2063#issuecomment-675156492
interface TableOptions<T extends ObjectOf<T> = Record<string, never>> {
  id: string;
  paginationRef?: HTMLDivElement;
  selectedRows?: T[];
  mockedSelectedRows?: T[];
  selectedRowsIds?: Set<string> | null;
  tableData?: T[];
  isSelectedView?: boolean;
  pageSize?: number;
  sortBy?: { desc: boolean; id: string }[];
  hiddenColumns?: T[];
}

interface ContextHandlers {
  setTableOptions: <T extends ObjectOf<T>>(tableOptions: TableOptions<T>) => void;
  getTableOptions: <T extends ObjectOf<T>>(tableId: string) => TableOptions<T> | undefined;
}

type TableOptionsContextState = Record<string, TableOptions>;

export const TableOptionsContext = createContext({} as ContextHandlers);

interface IProps {
  children?: React.ReactNode;
}

const TableOptionsProvider: FC<IProps> = ({ children }) => {
  const [options, setOptions] = useState<TableOptionsContextState>({});

  const setTableOptions = useCallback(
    <T extends ObjectOf<T>>({ id: tableId, ...tableOptions }: TableOptions<T>): void => {
      setOptions((previousState) => ({
        ...previousState,
        [tableId]: {
          ...previousState[tableId],
          ...tableOptions,
        },
      }));
    },
    [],
  );

  const getTableOptions = <T extends ObjectOf<T>>(tableId: string): TableOptions<T> =>
    options[tableId] as TableOptions<T>;

  const updaters = {
    setTableOptions,
    getTableOptions,
  };

  // Handle active business unit change
  const selectedBU = getBUFromLocalStorage();
  const previousSelectedBU = usePrevious(selectedBU);
  useEffect(() => {
    if (previousSelectedBU && previousSelectedBU !== selectedBU) {
      setOptions({});
    }
  }, [selectedBU, previousSelectedBU]);

  // Handle active species change
  const { activeSpecies } = useStateSpecies();
  useEffect(() => {
    setOptions((previousState) => ({
      ...previousState,
      [TABLE_IDS.ANIMALS]: {
        ...previousState[TABLE_IDS.ANIMALS],
        selectedRows: undefined,
        selectedRowsIds: undefined,
      },
    }));
  }, [activeSpecies]);

  return <TableOptionsContext.Provider value={{ ...options, ...updaters }}>{children}</TableOptionsContext.Provider>;
};

export default TableOptionsProvider;
