import React from "react";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { Redirect, useParams } from "react-router-dom";
import { History } from "history";
import { FIELDS } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { FieldInfoBlock } from "components/MyLivestock";
import { LoadingOverlay, Breadcrumbs, Button, Header, PageContent, PageHeader, Spacer } from "components";
import styles from "./Field.module.scss";
import { useFieldQuery } from "generated/graphql";
import { AnimalsList } from "animals/AnimalsList";
import { EmptyStatesLocationsPage } from "components/EmptyStates/LocationsPage";
import { TABLE_IDS } from "constants/Interface";

interface FieldViewProps {
  history: History;
}

export const Field: React.FC<FieldViewProps> = ({ history }) => {
  const { fieldId } = useParams<LivestockFieldPageParams>();

  const { data, loading, error } = useFieldQuery({ variables: { id: fieldId } });

  const { field } = { ...data };

  if (error) {
    return <Redirect to={FIELDS} />;
  }

  return (
    <>
      {loading ? (
        <LoadingOverlay customStyles={styles.loadingOverlay} />
      ) : (
        <>
          <PageHeader>
            <Breadcrumbs
              rootPaths={[
                {
                  path: "Locations",
                  route: FIELDS,
                },
              ]}
              currentPath={`${capitaliseFirstLetter("location")} Information`}
            />

            <Header backButton title={field?.name || ""} subTitle="" />

            <FieldInfoBlock field={field}>
              <Button
                id={ELEMENTS_IDS.EDIT_FIELD_BUTTON}
                caption={"Edit location"}
                onClick={(): void => history.push(`${FIELDS}/edit/${field?.id}`)}
              />
            </FieldInfoBlock>

            <Spacer baselineHeight={3} />
          </PageHeader>

          <PageContent>
            <Spacer baselineHeight={3} />
            <AnimalsList
              tableFilterIdentifier="fieldsList"
              excludedFilters={{ fieldId: true }}
              showAnimalActionButtons
              showSelectColumn
              fieldId={fieldId}
              fromPage="field"
              noResults={<EmptyStatesLocationsPage />}
              tableId={TABLE_IDS["FIELD_ANIMALS"]}
            />
          </PageContent>
        </>
      )}
    </>
  );
};
