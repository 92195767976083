import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesNoResults: FC = () => {
  return (
    <EmptyStates animation={"searching"} title="No results found.">
      <p>
        We couldn’t find any results that meet your search criteria. Adjust your search or filters to find what you’re
        looking for.
      </p>
    </EmptyStates>
  );
};
