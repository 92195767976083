// Libraries
import { MutationResult, useMutation } from "@apollo/client";
// API
import { MARK_LOT_OFFER_AS_PAID } from "trading/api/Liveweight/mutations";
import { MarkAsPaidLotOffer, MarkAsPaidLotOfferVariables } from "trading/api/Liveweight/types/MarkAsPaidLotOffer";
// Utils
import { getBUFromLocalStorage } from "helpers/storage";

interface UseMarkAsPaidLotOfferParams {
  offerId: number;
}

interface UseMarkAsPaidLotOfferMutation extends MutationResult {
  mutate: () => Promise<void>;
}

export const useMarkAsPaidLotOfferMutation = ({
  offerId,
}: UseMarkAsPaidLotOfferParams): UseMarkAsPaidLotOfferMutation => {
  const [markAsPaidLotOffer, result] = useMutation<MarkAsPaidLotOffer, MarkAsPaidLotOfferVariables>(
    MARK_LOT_OFFER_AS_PAID,
  );

  const mutate = async (): Promise<void> => {
    await markAsPaidLotOffer({
      variables: {
        input: {
          offer: offerId,
          businessUnitId: getBUFromLocalStorage(),
        },
      },
    });
  };

  return {
    mutate,
    ...result,
  };
};
