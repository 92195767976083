import { TransactionValidationSchema as S } from "../validation";
import {
  CreateFinancialTransactionInput,
  TransactionRecordEnumType,
  useCreateFinancialTransactionMutation,
  ValueAllocationMethodEnumType,
  FinancialTransactionState,
  useUpdateFinancialTransactionMutation,
  UpdateFinancialTransactionInput,
  useDeleteFinancialTransactionMutation,
} from "generated/graphql";
import { DateTime } from "luxon";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";

export const useTransactionActions = () => {
  const { id: businessUnitId, countryIsoCodeUpperCase } = useGetCurrentBusinessUnit();
  const [createFinancialTransaction, { loading: isCreatingTransaction }] = useCreateFinancialTransactionMutation();
  const [updateFinancialTransaction, { loading: isUpdatingTransaction }] = useUpdateFinancialTransactionMutation();
  const [deleteFinancialTransaction, { loading: isDeletingFinancialTransaction }] =
    useDeleteFinancialTransactionMutation();

  const handleCreateTransaction = async () => {
    const input: CreateFinancialTransactionInput = {
      label: DateTime.local().toFormat(
        countryIsoCodeUpperCase === CountryISOCode.US ? "MM/dd/yyyy HH:mm:ss" : "dd/MM/yyyy HH:mm:ss",
      ),
      state: FinancialTransactionState.Draft,
      date: DateTime.local().toISO(),
      amount: 0,
      valueAllocationMethod: ValueAllocationMethodEnumType.PerHead,
      businessUnit: parseInt(businessUnitId),
      recordType: TransactionRecordEnumType.Sale,
    };

    return createFinancialTransaction({
      variables: {
        input,
      },
    });
  };

  const handleUpdateFinancialTransaction = async (values: S) => {
    const isExpenseTransactionType = values.transactionType === TransactionRecordEnumType.Expense;

    const expenseTransactionTypeValues: Pick<UpdateFinancialTransactionInput, "endDate" | "category"> = {
      category: isExpenseTransactionType && values.transactionCategory ? values.transactionCategory : null,
      endDate:
        isExpenseTransactionType && values.dateType === "dateRange" && Boolean(values.endDate) ? values.endDate : null,
    };

    const nonExpenseTransactionTypeValues: Pick<
      UpdateFinancialTransactionInput,
      "contact" | "shrinkage" | "payWeight" | "grossWeight" | "unitPrice"
    > = {
      contact: !isExpenseTransactionType && values.contactId ? parseInt(values.contactId) : null,
      payWeight: !isExpenseTransactionType && values.payWeight ? values.payWeight : null,
      grossWeight: !isExpenseTransactionType && values.grossWeight ? values.grossWeight : null,
      unitPrice:
        !isExpenseTransactionType && values.perHeadAmount ? parseFloat(values.perHeadAmount.replace(/,/g, "")) : null,
      shrinkage: !isExpenseTransactionType && values.shrinkage ? parseFloat((values.shrinkage / 100).toFixed(4)) : null,
    };

    const input: UpdateFinancialTransactionInput = {
      id: values.transactionId,
      state: values.isSubmitted ? FinancialTransactionState.Submitted : FinancialTransactionState.Draft,
      date: values.date.toISOString(),
      amount: parseFloat(values.totalAmount.replace(/,/g, "")),
      valueAllocationMethod: ValueAllocationMethodEnumType.PerHead,
      label: values.name,
      businessUnit: parseInt(businessUnitId),
      recordType: values.transactionType,
      notes: values.description || "",
      upload: undefined,
      ...expenseTransactionTypeValues,
      ...nonExpenseTransactionTypeValues,
    };

    return updateFinancialTransaction({
      variables: {
        input,
      },
    });
  };

  const handleDeleteFinancialTransaction = async (transactionId: string | undefined) => {
    return deleteFinancialTransaction({
      variables: {
        input: {
          id: transactionId,
          businessUnit: +businessUnitId,
        },
      },
    });
  };

  return {
    isCreatingTransaction,
    isUpdatingTransaction,
    handleCreateTransaction,
    handleUpdateFinancialTransaction,
    isDeletingFinancialTransaction,
    handleDeleteFinancialTransaction,
  };
};
