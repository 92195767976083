import { Breadcrumbs, Flex, Header, PageContent, PageHeader, Spacer } from "components";
import { ADDRESS_BOOK, SETTINGS } from "constants/Routes";
import { useHistory, useParams } from "react-router-dom";
import { ContactForm } from "components/ContactForm";

export const ContactCreateEdit = () => {
  const { id: contactId } = useParams<SettingsAddressEditPageParams>();
  const addressBookRoute = `${SETTINGS}${ADDRESS_BOOK}`;
  const history = useHistory();

  const title = contactId === "create" ? "Add a contact" : "Edit a contact";

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Address book",
              route: addressBookRoute,
            },
          ]}
          currentPath={title}
        />
        <Header backButton title={title} />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />
        <Flex item>
          <ContactForm
            onConfirm={() => history.goBack()}
            contactId={contactId === "create" || !contactId ? undefined : contactId}
          />
        </Flex>
      </PageContent>
    </>
  );
};
