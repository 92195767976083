// React
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
// Utils
import { TAG_VIEW } from "constants/Routes";
import { TABLE_IDS } from "constants/Interface";
// Hooks
// Components
import { Flex } from "components/Structure/Flex";
import { Table } from "components/Common/Table";
import { Column } from "react-table";
import { Tag } from "../Tag";
// Types
import { TagType } from "tags/types";
import { Spacer, TablePaginationTarget } from "components";
import { BaseInput } from "components/Common/Field/BaseInput";

const COLUMN_IDS = {
  NAME: "name",
  ANIMALS_NUMBER: "animalsNumber",
};

interface TagsTableProps {
  tags: Array<TagType>;
  loading?: boolean;
}

const filterTagsData = (data: Array<TagType>, searchKey: string): Array<TagType> => {
  const isMatch = (value: string): boolean => value?.toLowerCase().includes(searchKey.toLowerCase());

  return data.filter((field) => isMatch(field.name));
};

export const TagTable: React.FC<TagsTableProps> = ({ tags, loading }) => {
  const history = useHistory();
  const tableId = TABLE_IDS.TAGS;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const filteredTags: Array<TagType> = useMemo(() => filterTagsData(tags, searchTerm), [tags, searchTerm]);
  const tableData: Array<TagType> = useMemo(() => filteredTags, [filteredTags]);

  const handleClickCell = useCallback(
    (tag: TagType) => {
      history.push(`${TAG_VIEW}/${tag?.id}`);
    },
    [history],
  );

  const columns: Column<TagType>[] = useMemo(
    () => [
      {
        id: COLUMN_IDS.NAME,
        Header: "TAG NAME",
        accessor: "name",
        Cell: ({ row }): React.ReactElement => {
          const icon = row.original;
          return (
            <Flex container containerAlignItems="center" containerJustifyContent="flex-start">
              <Tag
                tag={{
                  tagId: undefined,
                  color: icon.color || "",
                  name: icon.name,
                  ownership: icon.ownership,
                }}
              />
              <span style={{ marginLeft: 10 }}>{row.values[COLUMN_IDS.NAME]}</span>
            </Flex>
          );
        },
        onCellClick: handleClickCell,
        minWidth: 130,
        width: 140,
      },
      {
        id: COLUMN_IDS.ANIMALS_NUMBER,
        Header: "NO. OF LIVESTOCK",
        accessor: "numAliveAnimals",
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 100,
      },
    ],
    [handleClickCell],
  );

  return (
    <>
      <Flex container>
        <Flex item itemGutter xs={12} m={6} l={3}>
          <BaseInput
            name="tags_search"
            onChange={handleSearchInputChange}
            placeholder="Search by tag name"
            type="search"
            value={searchTerm}
          />
        </Flex>
        <div className="flex justify-end flex-1 flex-wrap mb-2">
          <Flex item itemGutter>
            <TablePaginationTarget tableId={tableId} />
          </Flex>
        </div>
      </Flex>
      <Spacer baselineHeight={1} />
      <Flex container containerDirection="column">
        <Flex item itemGutter>
          <Table
            loading={loading}
            columns={columns}
            data={tableData}
            tableId={tableId}
            pagination
            initialPageSize={10}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default TagTable;
