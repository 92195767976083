import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useFormatDate, useHasFeature } from "hooks";
import { getAnimalActivitiesFilteredByDate } from "helpers/myLivestock";
import { WEIGHT_ACTIVITY_SLUG } from "constants/Animals";
import { getBUFromLocalStorage } from "helpers/storage";
import { CommonContext } from "config/commonProvider";
import { Modal, Spacer, Text } from "components";
import { LoadMoreButton } from "trading/components";
import { AnimalHistoryItem } from "components/MyLivestock";
import { GET_ANIMAL_HISTORY_BY_ID, GET_ANIMAL_HISTORY_BY_ID_OLD } from "api/MyLivestock/Activity/queries";
import { DELETE_ACTIVITY_BULK_MUTATION } from "api/MyLivestock/Activity/mutations";
import {
  GetActivityLogVariables,
  GetActivityLog_activities,
  GetActivityLog,
} from "api/MyLivestock/Activity/types/GetActivityLog";
import {
  GetActivityLogOldVariables,
  GetActivityLogOld,
  GetActivityLogOld_activities,
} from "api/MyLivestock/Activity/types/GetActivityLogOld";
import {
  DeleteActivityBulkMutation,
  DeleteActivityBulkMutationVariables,
} from "api/MyLivestock/Activity/types/deleteActivityBulkMutation";

import styles from "./styles.module.scss";
import useUpdateEffect from "hooks/lifecycle/useUpdateEffect";
import { EmptyStatesHistoryTab } from "components/EmptyStates/HistoryTab";

interface Props {
  onLoadStatus?: (loading: boolean) => void;
  readOnly?: boolean;
  selectedFilters: string[];
}

export const ActivitiesBlock: React.FC<Props> = ({ onLoadStatus, readOnly, selectedFilters }) => {
  const { id: animalId } = useParams<LivestockAnimalPageParams>();
  const { showNotification } = useContext(CommonContext);
  const [activityIdForDeletion, setActivityIdForDeletion] = useState<string | null>(null);
  const isShowNewActivityDescription: boolean = useHasFeature("SHOW_NEW_ACTIVITY_DESCRIPTION");

  const businessUnitID = getBUFromLocalStorage();
  const activityTemplateSlugs: string[] = useMemo(
    () => selectedFilters.filter((item) => item === WEIGHT_ACTIVITY_SLUG),
    [selectedFilters],
  );
  const activityTypeSlugs: string[] = useMemo(
    () => selectedFilters.filter((item) => item !== WEIGHT_ACTIVITY_SLUG),
    [selectedFilters],
  );

  const refetchObject = useMemo(
    () => ({
      first: 5,
      after: "",
      animal: animalId,
      activityTemplateSlugs,
      activityTypeSlugs,
    }),
    [animalId, activityTemplateSlugs, activityTypeSlugs],
  );

  const query = isShowNewActivityDescription ? GET_ANIMAL_HISTORY_BY_ID : GET_ANIMAL_HISTORY_BY_ID_OLD;
  const { data, loading, refetch, fetchMore } = useQuery<
    GetActivityLog | GetActivityLogOld,
    GetActivityLogVariables | GetActivityLogOldVariables
  >(query, {
    variables: {
      first: 5,
      after: "",
      animal: animalId,
      activityTemplateSlugs,
      activityTypeSlugs,
    },
    fetchPolicy: "cache-and-network",
  });

  const [deleteActivity, { loading: deleteLoading }] = useMutation<
    DeleteActivityBulkMutation,
    DeleteActivityBulkMutationVariables
  >(DELETE_ACTIVITY_BULK_MUTATION);

  const activities: GetActivityLogOld_activities | GetActivityLog_activities | null | undefined = data?.activities;

  const loadMoreHistory = (): void => {
    if (activities?.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          first: 5,
          after: activities?.pageInfo.endCursor,
          animal: animalId,
          activityTemplateSlugs,
          activityTypeSlugs,
        },
      });
    }
  };

  const handleCloseModal = (): void => {
    setActivityIdForDeletion(null);
  };

  const handleDeleteActivity = async (): Promise<void> => {
    try {
      const response = await deleteActivity({
        variables: {
          input: {
            businessUnit: businessUnitID,
            activities: [activityIdForDeletion],
            animals: [animalId],
          },
        },
      });
      const errors = response.data?.deleteActivityBulk?.errors;

      if (!errors) {
        refetch(refetchObject);
        showNotification({
          message: "Activity deleted successfully",
        });
        setActivityIdForDeletion(null);
      } else {
        throw errors;
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error deleting activity, please try again later",
      });
    }
  };
  const { getFormatDate } = useFormatDate();

  const activityHistory = useMemo(
    () => getAnimalActivitiesFilteredByDate(activities?.edges, isShowNewActivityDescription, getFormatDate) || [],
    [activities],
  );

  useUpdateEffect(() => {
    refetch(refetchObject);
  }, [refetchObject]);

  useEffect(() => {
    if (onLoadStatus) {
      onLoadStatus(loading);
    }
  }, [loading]);
  return (
    <>
      <div className={styles.activity_block}>
        <div className={styles.activity_block__timeline}>
          {Object.keys(activityHistory).map((dateItem) => (
            <div key={dateItem}>
              <Text>
                <strong>{dateItem}</strong>
              </Text>
              <Spacer baselineHeight={2} />
              {activityHistory[dateItem].map((historyItem) => (
                <React.Fragment key={historyItem.id}>
                  <AnimalHistoryItem
                    activity={historyItem}
                    onClickDelete={
                      historyItem?.isDeletable ? (): void => setActivityIdForDeletion(historyItem.id) : undefined
                    }
                    readOnly={readOnly}
                  />
                  <Spacer baselineHeight={2} />
                </React.Fragment>
              ))}
              <Spacer baselineHeight={1} />
            </div>
          ))}

          {activities && activities.edges && activities.edges.length === 0 ? <EmptyStatesHistoryTab /> : null}
        </div>
        <Spacer baselineHeight={2} />
        {activities && activities.pageInfo.hasNextPage ? (
          <LoadMoreButton disabled={loading} requesting={loading} loadMore={loadMoreHistory} />
        ) : null}
      </div>

      <Modal
        showCloseButton
        active={!!activityIdForDeletion}
        handleClose={handleCloseModal}
        title="Are you sure you want to delete this activity?"
        size="small"
        actions={{
          primary: {
            caption: "Yes",
            onClick: handleDeleteActivity,
            requesting: deleteLoading,
          },
          secondary: {
            caption: "No",
            onClick: handleCloseModal,
          },
        }}
      >
        <Text>
          <strong>This cannot be undone.</strong>
        </Text>
      </Modal>
    </>
  );
};
