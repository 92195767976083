import React from "react";
// Utils
import { GetTaskInfo_farmSystemTask } from "api/Import/OtherSystems/types/GetTaskInfo";
import { ELEMENTS_IDS } from "constants/ElementsIds";
// Components
import { Button, Flex, Spacer } from "components";
// Resources
import styles from "./ImportWeightCreateAnimals.module.css";

interface ImportWeightCreateAnimalsProps {
  farmSystemTask: GetTaskInfo_farmSystemTask;
  fileName: string;
  clearAll: () => void;
  createAnimals: (id: number) => void;
}

export const ImportWeightCreateAnimals: React.FC<ImportWeightCreateAnimalsProps> = ({
  farmSystemTask,
  fileName,
  clearAll,
  createAnimals,
}) => {
  const newAnimalsCount: number = farmSystemTask?.uncreatedItems?.length || 0;

  const handleCreateAnimals = (): void => {
    const producedTaskId = Number(farmSystemTask?.producedTask?.id);

    if (producedTaskId) {
      createAnimals(producedTaskId);
    }
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.warningBlockWrapper}>
        <div className={styles.warningBlockInfo}>
          <div className={styles.fileNameWrapper}>
            <span className={styles.fileNameWarning}>{fileName}</span>
            <span className={styles.linkToStart} onClick={clearAll}>
              Not the right one? Click here to update
            </span>
          </div>
        </div>
      </div>
      <Flex item itemGutter xs={12}>
        <div className={styles.importBlockWrapper}>
          <span className={styles.importResults}>Import results</span>
          <span className={styles.matchedAnimals}>
            {`${farmSystemTask.updatedNumberItems + farmSystemTask?.newNumberItems} weights matched to animals `}
          </span>
          <span className={styles.newAnimals}>
            {`${newAnimalsCount} new animal${newAnimalsCount > 1 ? "s" : ""} found.`}
          </span>
          <span className={styles.doYouWantToCreate}>Do you want to create animals? (You can update BCMS later)</span>
          <Spacer baselineHeight={3} />
          <Flex container>
            <Flex item itemGutter>
              <Button
                id={ELEMENTS_IDS.IMPORT_CREATE_ANIMAL_BLOCK_ADD_LATER_BUTTON}
                caption="Add later"
                colour="grey"
                variant="hollow"
                onClick={clearAll}
              />
            </Flex>
            <Flex item itemGutter>
              <Button
                id={ELEMENTS_IDS.IMPORT_CREATE_ANIMAL_BLOCK_CREATE_BUTTON}
                caption="Create"
                onClick={handleCreateAnimals}
              />
            </Flex>
          </Flex>
        </div>
      </Flex>
    </div>
  );
};
