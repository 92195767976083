import React from "react";
// Resources
import styles from "./TextAnchor.module.scss";

interface TextAnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  caption: string;
}

export const TextAnchor: React.FC<TextAnchorProps> = ({ caption, href, ...props }) => (
  <a className={styles.text_anchor} href={href} {...props}>
    {caption}
  </a>
);
