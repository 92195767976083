import React from "react";
// Components
import { Flex, Text, Title, Spacer } from "components";
//Libraries
import { useRecoilValue } from "recoil";
// Resources
import styles from "../BuyerListingOfferModal.module.scss";
import { OfferTypes } from "trading/constants";
import { makeOfferType } from "state";
interface OfferCompleteProps {
  quantity: number;
  formattedOfferPrice: string;
  formattedOfferPriceTotal: string;
}

export const OfferComplete: React.FC<OfferCompleteProps> = ({
  quantity,
  formattedOfferPrice,
  formattedOfferPriceTotal,
}) => {
  const offerType = useRecoilValue(makeOfferType);
  const offerTypeLabel = offerType === OfferTypes.HEAD ? "per head" : "per kg";

  return (
    <>
      <Flex item itemGutter xs={2}>
        <Text className={styles.full_width_title}>Offer {offerTypeLabel}</Text>
        <Title secondary>{formattedOfferPrice}</Title>
      </Flex>

      <Flex item itemGutter xs={2}>
        <Text>No of Animals:</Text>
        <Title secondary>{quantity}</Title>
      </Flex>

      <Spacer baselineHeight={3} />

      <Flex item itemGutter xs={4}>
        <Text>Total offer value:</Text>
        <Title secondary>{formattedOfferPriceTotal}</Title>
      </Flex>
    </>
  );
};
