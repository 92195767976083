/* eslint-disable */
import React, { PureComponent } from "react";
import { ComponentType } from "react";

import Header from "./Header";
import { SimpleTableProps } from "./SimpleTable";

const withHeader = (Table: ComponentType<SimpleTableProps>) =>
  class extends PureComponent<SimpleTableProps> {
    public render() {
      const { children, ...props } = this.props;
      return (
        <Table {...props}>
          {children}
          <Header {...props} />
        </Table>
      );
    }
  };

export default withHeader;
