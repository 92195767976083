// React
import React from "react";
// Libraries
import pure from "recompose/pure";
import cn from "classnames";
// Utils
import { isLowSecure, isHeightSecure, isMedSecure } from "helpers/settings";
// Resources
import styles from "./PasswordStrength.module.scss";

interface PasswordStrengthProps {
  className?: string;
  password: string;
}

const PasswordStrength: React.FC<PasswordStrengthProps> = ({ className, password }) => (
  <div className={cn(styles.passwordStrength, className)}>
    <p className={styles.passwordStrengthText}>Password strength</p>
    <div className={styles.passwordStrengthIndicatorsView}>
      <div
        className={cn(styles.passwordStrengthIndicator, {
          [styles.lowSecure]: isLowSecure(password) || isMedSecure(password),
        })}
      />
      <div
        className={cn(styles.passwordStrengthIndicator, {
          [styles.medSecure]: isMedSecure(password),
        })}
      />
      <div
        className={cn(styles.passwordStrengthIndicator, {
          [styles.heightSecure]: isHeightSecure(password),
        })}
      />
    </div>
  </div>
);

export default pure(PasswordStrength);
