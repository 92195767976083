// Libraries
import { useMediaQuery } from "react-responsive";
// Constants
import { BREAKPOINT_XL, BREAKPOINT_L, BREAKPOINT_M, BREAKPOINT_S, BREAKPOINT_XS } from "constants/Interface";

interface Breakpoints {
  // Desktop
  isBreakpointXL: boolean;
  // Tablet
  isBreakpointL: boolean;
  isBreakpointM: boolean;
  // Mobile
  isBreakpointS: boolean;
  isBreakpointXS: boolean;
}

export const useBreakpoints = (): Breakpoints => ({
  // Desktop
  isBreakpointXL: useMediaQuery({ minWidth: BREAKPOINT_XL }),
  // Tablets
  isBreakpointL: useMediaQuery({ minWidth: BREAKPOINT_L }),
  isBreakpointM: useMediaQuery({ minWidth: BREAKPOINT_M }),
  // Mobile
  isBreakpointS: useMediaQuery({ minWidth: BREAKPOINT_S }),
  isBreakpointXS: useMediaQuery({ minWidth: BREAKPOINT_XS }),
});
