// API
import {
  GetAnimalsList_animalsExtended_animals,
  GetAnimalsListVariables,
} from "api/MyLivestock/Animal/types/GetAnimalsList";
import { GetAnimalsFromField_field_animals } from "api/MyLivestock/Fields/types/GetAnimalsFromField";
// Hooks
import { useAnimals, AnimalsList } from "../useAnimals";
import { useStateSpecies } from "../useStateSpecies";

export const useAnimalsSpeciesActive = (
  defaultAnimals?:
    | (GetAnimalsList_animalsExtended_animals | GetAnimalsFromField_field_animals | null)[]
    | undefined
    | null,
  variables?: Omit<GetAnimalsListVariables, "animalTypeIds">,
): AnimalsList => {
  const { activeSpecies } = useStateSpecies();

  return useAnimals(defaultAnimals, {
    ...variables,
    animalTypeIds: activeSpecies?.id ? [Number(activeSpecies?.id)] : undefined,
  });
};
