import { useMemo } from "react";
// Libraries
import { useRecoilState } from "recoil";
// State
import { liveweightSort } from "state";
// Types
import { LIVEWEIGHT_SORT_OPTIONS, LiveweightSortOption, LiveweightSortValue } from "./constants";

export type ListingSortInstance = "buyer" | "seller";

interface UseListingSortReturn<ListingType extends ObjectOf<ListingType>> {
  apply: (listingA: ListingType, listingB: ListingType) => number;
  set: (newValue: LiveweightSortValue) => void;
  value: LiveweightSortValue;
}

export const useListingSort = <ListingType extends ObjectOf<ListingType>>(
  instance: ListingSortInstance,
): UseListingSortReturn<ListingType> => {
  const [sort, setSort] = useRecoilState(liveweightSort(instance));

  // we can `as LiveweightSortOption` because we know it will always find the option
  const { order, property } = LIVEWEIGHT_SORT_OPTIONS.find((option) => option.value === sort) as LiveweightSortOption;

  const handleApplySort = (listingA: ListingType, listingB: ListingType): number => {
    if (order === "DESC") {
      return listingA[property] < listingB[property] ? 1 : -1;
    }

    if (listingA[property] === null && property === "distance") return 1;

    if (listingB[property] === null && property === "distance") return -1;

    return listingA[property] - listingB[property];
  };

  const handleSetSort = (newValue: LiveweightSortValue): void => setSort(newValue);

  return useMemo(
    () => ({
      apply: handleApplySort,
      set: handleSetSort,
      value: sort,
    }),
    [sort],
  );
};
