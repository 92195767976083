import { useContext } from "react";
import { removeNothings } from "helpers/general";
import { Modal } from "components";
import { useAssignAnimalsToGroupMutation } from "generated/graphql";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { TABLE_IDS } from "constants/Interface";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect } from "components/FormHookComponents/FormSelect";
import { FormDatePicker } from "components/FormHookComponents/FormDatePicker";
import { Button } from "twComponents/Button";
import { Form } from "components/FormHookComponents/Form";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";
import { useGetGroups } from "hooks/useGetGroups";
import { DateTime } from "luxon";
import { useLocale } from "helpers/translations/src/useLocale";
import { capitaliseFirstLetter } from "helpers/translations/src/format";
import { InputSelectOption } from "components/Common/Field/Select";

type AnimalId = string;

interface Props {
  animalIds: Array<AnimalId>;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const REMOVE_GROUP_VALUE = "removeGroupMembership";

export const AssignToGroupModal = ({ animalIds, showModal, setShowModal }: Props) => {
  const { setTableOptions } = useContext(TableOptionsContext);
  const { handleError, handleMutationResult } = useHandleMutationResult();
  const { terms } = useLocale();

  const { groupOptions, loading: isLoadingGroupOptions } = useGetGroups();

  const groupOptionsWithRemove: InputSelectOption[] = [
    {
      value: REMOVE_GROUP_VALUE,
      label: capitaliseFirstLetter(terms.removeGroupMembership),
      key: REMOVE_GROUP_VALUE,
    },
    ...groupOptions,
  ];

  const [assignAnimalsToGroup, { loading: isSubmitting }] = useAssignAnimalsToGroupMutation();

  const onSubmit = async ({ date, groupId }: Schema) => {
    try {
      const result = await assignAnimalsToGroup({
        variables: {
          input: {
            animals: animalIds,
            group: groupId === REMOVE_GROUP_VALUE ? null : groupId,
            date,
          },
        },
        refetchQueries: ["GetAnimalsPage", "GetAnimalById"],
      });

      const errors = result?.data?.moveAnimalsToGroup?.errors;

      handleMutationResult({
        successMessage: "Animal(s) successfully assigned to group",
        errors: removeNothings(errors || []),
        onSuccess: () => {
          setTableOptions({
            id: TABLE_IDS.ANIMAL_LIST_SERVER,
            selectedRows: [],
            selectedRowsIds: new Set(),
          });

          setShowModal(false);
        },
      });
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <>
      <Modal
        active={showModal}
        handleClose={() => setShowModal(false)}
        subTitle={`Please select a group to assign these ${animalIds.length} animal(s) to`}
        title="Add to group"
      >
        <Form
          defaultValues={{ groupId: "", date: DateTime.local().toJSDate() }}
          resolver={yupResolver(validationSchema)}
          onSubmit={onSubmit}
        >
          <FormSelect<Schema>
            label="Group *"
            options={groupOptionsWithRemove}
            name="groupId"
            placeholder="Select Group"
          />
          <FormDatePicker<Schema> label="Date *" name="date" maxDate={new Date()} />
          <div className="flex justify-end mt-5">
            <Button type="button" disabled={isSubmitting} onClick={() => setShowModal(false)} variant="outline">
              Cancel
            </Button>
            <Button type="submit" loading={isSubmitting || isLoadingGroupOptions} className="ml-2">
              Confirm
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

type Schema = yup.InferType<typeof validationSchema>;

const validationSchema = yup.object({
  groupId: yup.string().required("Group is a required."),
  date: yup.date().required("Date is a required."),
});
