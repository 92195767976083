import { useQuery } from "@apollo/client";
import { GET_SUPPORTED_SYSTEMS } from "api/Import/OtherSystems/queries";
import {
  GetSupportedSystems,
  GetSupportedSystems_integrationSystems,
  GetSupportedSystemsVariables,
} from "api/Import/OtherSystems/types/GetSupportedSystems";
import { getBUFromLocalStorage } from "helpers/storage";

export const useGetSupportedSystems = () => {
  const { data, ...rest } = useQuery<GetSupportedSystems, GetSupportedSystemsVariables>(GET_SUPPORTED_SYSTEMS, {
    variables: {
      farm: getBUFromLocalStorage(),
      systemType: "FARM",
      isAutomatedSystem: false,
    },
    fetchPolicy: "cache-and-network",
  });

  const integrationSystems = (data?.integrationSystems || []) as GetSupportedSystems_integrationSystems[];

  return {
    data: integrationSystems,
    ...rest,
  };
};
