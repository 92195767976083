import { useCallback, useMemo } from "react";
// Libraries
import { get, isEqual } from "lodash";
import { useRecoilState, useResetRecoilState } from "recoil";
// Utils
import { CUSTOM_LIVEWEIGHT_FILTERS, DEFAULT_LIVEWEIGHT_FILTERS, LiveweightFiltersState } from "./config";
import { liveweightFilters } from "state";

export type ListingFiltersInstance = "buyer" | "seller";

interface UseListingFiltersReturn<ListingsType> {
  apply: (listings: ListingsType) => boolean;
  isDefault: boolean;
  reset: () => void;
  set: (newFilters: Partial<LiveweightFiltersState>) => void;
  values: LiveweightFiltersState;
}

export const useListingFilters = <ListingsType extends ObjectOf<ListingsType>>(
  instance: ListingFiltersInstance,
): UseListingFiltersReturn<ListingsType> => {
  const [filters, setFilters] = useRecoilState(liveweightFilters(instance));
  const isDefault = isEqual(filters, DEFAULT_LIVEWEIGHT_FILTERS);
  const resetFilters = useResetRecoilState(liveweightFilters(instance));

  const handleApplyFilters = useCallback(
    (listing: ListingsType): boolean => {
      if (!listing) return false;

      for (const [filterKey, filterValue] of Object.entries(filters)) {
        if (filterValue) {
          const customFilter = get(CUSTOM_LIVEWEIGHT_FILTERS, filterKey, false);

          if (customFilter) {
            //@ts-ignore
            if (!customFilter(listing, filters)) return false;
          } else {
            // if not checked we show all listings by default, when checked we show just those with true for given key
            if (typeof filterValue === "boolean" && !listing[filterKey]) return false;

            // other type checks for new filters here
          }
        }
      }

      return true;
    },
    [filters],
  );

  const handleSetFilters = useCallback(
    (newFilters: Partial<LiveweightFiltersState>) => {
      setFilters({
        ...filters,
        ...newFilters,
      });
    },
    [filters],
  );

  return useMemo(
    () => ({
      apply: handleApplyFilters,
      isDefault,
      reset: resetFilters,
      set: handleSetFilters,
      values: filters,
    }),
    [filters],
  );
};

export { DEFAULT_LIVEWEIGHT_FILTERS };
