import { useContext, VFC } from "react";
// Libraries
import { NavLink } from "react-router-dom";
// Utils
import { DropdownMenuContext } from "../";
// Resources
import styles from "../DropdownMenu.module.scss";

interface DropdownMenuLinkProps {
  text: string;
  to: string;
}

export const DropdownMenuLink: VFC<DropdownMenuLinkProps> = ({ text, to }) => {
  const { handleClose } = useContext(DropdownMenuContext);

  return (
    <NavLink className={styles.dropdown_menu__item} onClick={handleClose} to={to}>
      <span className={styles.dropdown_menu__item_caption}>{text}</span>
    </NavLink>
  );
};
