import React, { FC, useMemo, useState } from "react";
import { useFormatDate } from "hooks";
import { useHistory } from "react-router-dom";
import { LineItemFragment, useLineItemsQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { Spacer, Icon, InputCheckbox } from "components";
import { MEDICINE_DETAILS } from "constants/Routes";
import { DateTime } from "luxon";
import { EmptyStatesMedicineCabinet } from "components/EmptyStates/MedicineCabinet";
import { BaseInput } from "components/Common/Field/BaseInput";
import { Button } from "twComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { setArchivedFilter, setEmptyFilter, setExpiredFilter } from "medicines/state/reducer";
import { createColumnHelper, getSortedRowModel, SortingState } from "@tanstack/react-table";
import { removeNothings } from "helpers/general/all";
import { Table } from "twComponents/Table";

export const MedicinesTable: FC = ({}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const showArchived = useSelector((state: RootState) => state.medicines.showArchived);
  const showExpired = useSelector((state: RootState) => state.medicines.showExpired);
  const showEmpty = useSelector((state: RootState) => state.medicines.showEmpty);
  const history = useHistory();

  const onArchivePress = () => {
    dispatch(setArchivedFilter(!showArchived));
  };

  const onExpirePress = () => {
    dispatch(setExpiredFilter(!showExpired));
  };

  const onEmptyPress = () => {
    dispatch(setEmptyFilter(!showEmpty));
  };

  const handleClickCell = React.useCallback(
    ({ stockItem }) => {
      history.push(`${MEDICINE_DETAILS}/${stockItem.id}`);
    },
    [history],
  );

  const businessUnitId: number = getBUFromLocalStorage();

  const { data, loading } = useLineItemsQuery({
    variables: {
      businessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const { getFormatDate } = useFormatDate();
  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const columnHelper = createColumnHelper<LineItemFragment>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => "Medicine Name",
      size: 300,
    }),
    columnHelper.accessor("batchNumber", {
      cell: (info) => info.getValue() || "\u2014",
      header: () => "Batch number",
      size: 200,
    }),
    columnHelper.accessor("purchaseDate", {
      cell: (info) => getFormatDate(info.getValue()),
      header: () => "Purchase Date",
      size: 150,
    }),
    columnHelper.accessor("expiryDate", {
      cell: (info) => getFormatDate(info.getValue()),
      header: () => "Expiry Date",
      size: 150,
    }),
    columnHelper.accessor("totalVolume", {
      cell: (info) => {
        const currentQty = info.row.original.totalVolume || "-";
        const originalBatchQty =
          info.row.original.quantity && info.row.original.originalVolume
            ? info.row.original.quantity * info.row.original.originalVolume
            : "-";
        const qtyUnits = info.row.original.quantityUnits || "";
        return `${currentQty}/${originalBatchQty} ${qtyUnits}`;
      },
      header: () => "Amount",
      enableSorting: false,
      size: 150,
    }),
    columnHelper.accessor("sku", {
      cell: (info) => info.getValue() || "\u2014",
      header: () => "GTIN",
    }),
  ];

  const getActualDateStatus = (d?: string | null): boolean => {
    return d ? DateTime.fromISO(d).valueOf() >= DateTime.local().startOf("day").valueOf() : true;
  };

  const batches = useMemo(() => {
    return removeNothings(
      data?.lineItems?.filter((lineItem) => {
        if (lineItem === undefined || lineItem === null) {
          return false;
        }
        const isActualDate = getActualDateStatus(lineItem?.expiryDate);
        const totalVolume = lineItem?.totalVolume ?? 0;
        if (
          (showArchived || !lineItem?.isArchived) &&
          (isActualDate || showExpired) &&
          (showEmpty || totalVolume > 0) &&
          (lineItem?.stockItem.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            lineItem?.batchNumber?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            lineItem?.sku?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        ) {
          return true;
        }
        return false;
      }) ?? [],
    );
  }, [data?.lineItems, showArchived, showExpired, showEmpty, searchTerm]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="w-full md:w-1/2 lg:w-1/3">
          <BaseInput
            name="medicines_search"
            onChange={handleSearchInputChange}
            placeholder="Search for a medicine"
            type="search"
            value={searchTerm}
          />
        </div>

        <Button title="Filters" className="ml-2" onClick={() => setShowFilters(!showFilters)} variant="outline">
          <span className="px-1">Filters</span>
          <Icon name="filter" size="small" />
        </Button>
      </div>
      <div
        className={`bg-white flex flex-row justify-between flex-wrap ${
          showFilters ? null : "hidden"
        } p-2.5 my-1 rounded`}
      >
        <InputCheckbox checked={showEmpty} label="Show Empty" name="showEmpty" onChange={onEmptyPress} />
        <InputCheckbox checked={showArchived} label="Show Archived" name="showArchived" onChange={onArchivePress} />
        <InputCheckbox checked={showExpired} label="Show Expired" name="showExpired" onChange={onExpirePress} />
      </div>
      <Spacer baselineHeight={1} />
      <Table
        tableId="medicines_table"
        emptyStateComponent={<EmptyStatesMedicineCabinet />}
        options={{
          columns,
          data: batches,
          state: { sorting },
          onSortingChange: setSorting,
          getSortedRowModel: getSortedRowModel(),
        }}
        onRowClick={handleClickCell}
        loading={loading}
      />
    </>
  );
};
