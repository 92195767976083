import { VFC } from "react";
// Libraries
import { NavLink } from "react-router-dom";
// Utils
import { ELEMENTS_IDS } from "constants/ElementsIds";
// Components
import { Flex, Spacer, Title } from "components";
// Resources
import styles from "./ImportHeader.module.scss";

interface ImportHeaderProps {
  text: string;
  onSkip?: () => void;
}

export const ImportHeader: VFC<ImportHeaderProps> = ({ text, onSkip }) => (
  <Flex container>
    <Flex item itemGutter>
      <Title secondary>{text}</Title>

      <Spacer baselineHeight={2} />

      {onSkip ? (
        <NavLink id={ELEMENTS_IDS.IMPORT_HEADER_SKIP_STEP_LINK} to="" className={styles.linkToSkip} onClick={onSkip}>
          Skip this step
        </NavLink>
      ) : null}
    </Flex>
  </Flex>
);
