import { ApolloClient, ApolloLink, defaultDataIdFromObject, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import api from "api/config";
import { createUploadLink } from "apollo-upload-client";
import { persistCache } from "apollo3-cache-persist";
import { LOGIN } from "constants/Routes";
import { StrictTypedTypePolicies } from "generated/graphql";
import { getTokenFromLocalStorage } from "helpers/storage";
import localforage from "localforage";
import possibleTypes from "possibleTypes.json";

const routesWithToken = ["system"];

const httpUploadLink = createUploadLink({
  uri: ({ operationName }) => {
    return `${api.secureProtocol}${api.host}${api.endpoints.graphQL}/${operationName}`;
  },
});

const onAuthError = (): void => {
  localforage.clear();
  window.location.replace(LOGIN);
};

const authLink = new ApolloLink((operation, forward) => {
  const firstLocParam: string = window.location.pathname.split("/")[1];
  const isRouteWithToken = routesWithToken.indexOf(firstLocParam) > -1;
  const token = getTokenFromLocalStorage();
  if (token || !isRouteWithToken) {
    operation.setContext(({ headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    });
    return forward(operation);
  } else {
    onAuthError();
  }
  return null;
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      // eslint-disable-next-line
      console.log(`[GraphQL error]: Message: ${message}`);
    });
  }

  if (networkError) {
    // eslint-disable-next-line
    console.log(`[Network error]: ${networkError}`);
  }
});

const nonNormalizedEntityTypes = ["InventoryInfoType"];

const typePolicies: StrictTypedTypePolicies = {
  // eslint-disable-next-line
  Query: {
    fields: {
      activities: relayStylePagination([
        "activityTemplateSlug",
        "activityTemplateSlugs",
        "activityTypeSlugs",
        "animal",
        "businessUnit",
        "field",
        "group",
        "isChartable",
        "isSystemOnly",
        "lot",
        "publicOnly",
        "updatedFrom",
      ]),
      buyingLots: relayStylePagination(),
    },
  },
};

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
  dataIdFromObject: (obj): string | false | undefined => {
    const typeName = obj.__typename || "";
    // if dataIdFromObject will return null then object won't be normalized
    if (nonNormalizedEntityTypes.includes(typeName)) {
      return false;
    }

    return defaultDataIdFromObject(obj);
  },
});

localforage.config({
  name: "Breedr",
});

persistCache({
  cache,
  key: api.apolloCacheKey,
  storage: localforage,
  maxSize: false,
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpUploadLink] as ApolloLink[]),
  cache,
});

export const clearApolloStorage = (): void => {
  apolloClient.resetStore();
  localforage.clear();
};

export default apolloClient;
