import { ChangeEvent, KeyboardEventHandler, useEffect, useMemo, useState, FC } from "react";
// Components
import { FieldNumber, FieldSelect, FieldText } from "components";
import { MedicinePreviewModal } from "medicines/components/MedicinePreviewModal";
// Utils
import { useAdministerMedicine, useStateSpecies } from "hooks";
import {
  getBatchOptions,
  getCalculatedDose,
  getConversion,
  getDefaultUnit,
  getUnitOptions,
  validateDosage,
} from "./helpers";
// Types
import { InputSelectOption } from "components/Common/Field/Select";
// Resources
import styles from "./AdministerMedicineForm.module.scss";

export interface AdministerMedicineFields {
  activeIngredients: string;
  batchId: string;
  calculatedDosage: string;
  calculatedUnit: string;
  dosage: string;
  medicine: string;
  notes: string;
  reason: string;
  units: string;
  withdrawalDays: string | number;
}

interface AdministerMedicineFormProps {
  onSubmitMedicine: (() => void) | null;
  errors?: {
    [key: string]: string;
  };
  numAnimals: string | number;
  onChange: (fields: AdministerMedicineFields) => void;
  reasonOptions: (InputSelectOption | null)[];
}

const initialFieldValues: AdministerMedicineFields = {
  activeIngredients: "",
  batchId: "",
  calculatedDosage: "",
  calculatedUnit: "",
  dosage: "",
  medicine: "",
  notes: "",
  reason: "",
  units: "",
  withdrawalDays: "",
};

export const AdministerMedicineForm: FC<AdministerMedicineFormProps> = ({
  onSubmitMedicine,
  errors,
  numAnimals = 1,
  onChange,
  reasonOptions = [],
}) => {
  const { activeSpecies } = useStateSpecies();

  const [fields, updateFields] = useState<AdministerMedicineFields>(initialFieldValues);
  const [showMedicineDetails, setShowMedicineDetails] = useState<boolean>(false);
  const [doseError, updateDoseError] = useState<string | null>(null);
  const { batch, batches, medicines, medicineDetails, refreshMedicines, setBatch, setMedicine } =
    useAdministerMedicine();

  const medicineWithdrawalForActiveSpecies = medicineDetails?.withdrawal?.find((withdrawal) => {
    const isForMeat = withdrawal?.withdrawalType === "MEAT";

    if (activeSpecies?.id) {
      const isForActiveSpecies = withdrawal?.animalType?.id && withdrawal.animalType.id === activeSpecies.id;
      return isForActiveSpecies && isForMeat;
    }

    // Since we don't set speciesActive/speciesList state when foggle is disabled we need to a way to find a fallback
    const hacky_isCattle = withdrawal?.animalType?.name === "Cattle";
    return hacky_isCattle && isForMeat;
  });

  // handle user changing a field input
  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value = "" } = event?.target;

    // validate dosage does not exceed total remaining
    // there's a serverside check too
    if (name === "dosage") {
      const isDosageValid = validateDosage({
        defaultUnits: batch?.volumeUnits || "",
        dosage: value,
        dosageUnits: fields.units,
        numOfAnimals: numAnimals || 1,
        remaining: batch?.totalRemainingVolume || 0,
      });
      updateDoseError(!isDosageValid ? "Dosage exceeds the amount remaining" : null);
    }

    updateFields({ ...fields, [name]: value });
  };

  // if user submits medicine form (externall), we need to clear and refresh form
  const handleMedicineSubmit = (): void => {
    if (onSubmitMedicine) {
      refreshMedicines();
      updateFields(initialFieldValues);
      onSubmitMedicine();
    }
  };

  const handleMedicineInfoClick = (): void => {
    setShowMedicineDetails(true);
  };

  useEffect(() => {
    setMedicine(fields.medicine);
  }, [fields.medicine]);

  // this is a bit of a hack to reset form and refresh medicines after administering to an animal
  useEffect(() => {
    handleMedicineSubmit();
  }, [onSubmitMedicine]);

  // set dosage units when batch selected
  useEffect(() => {
    if (!batch) return;

    const units = getDefaultUnit(batch);

    updateFields({ ...fields, units });
  }, [batch]);

  // set medicine when user selects medicine
  useEffect(() => {
    setBatch(fields.batchId);
  }, [fields.batchId]);

  // reset dose field if doseunit changed
  useEffect(() => {
    updateFields({ ...fields, dosage: "" });
  }, [fields.units]);

  // set batchId if only one batch
  useEffect(() => {
    if (!batches) return;

    updateFields({ ...fields, batchId: batches.length === 1 && batches[0]?.id ? batches[0]?.id : "" });
  }, [batches]);

  // set fields when batch selected
  useEffect(() => {
    if (!batch) return;

    const units = getDefaultUnit(batch);

    updateFields({
      ...fields,
      activeIngredients: batch.activeIngredients || "",
      dosage: "",
      units,
      withdrawalDays: medicineWithdrawalForActiveSpecies?.withdrawalPeriodDays || 0,
    });
  }, [batch]);

  useEffect(() => {
    if (!batch || !medicineDetails) return;

    updateFields({
      ...fields,
      withdrawalDays: medicineWithdrawalForActiveSpecies?.withdrawalPeriodDays || 0,
    });
  }, [medicineDetails]);

  // when form data updated, send data via callback
  useEffect(() => {
    onChange(fields);
  }, [fields]);

  // when dose state updates work out the actual dose taking into account units
  useEffect(() => {
    const [dosage, unit] = getCalculatedDose(batch?.volumeUnits || "", fields.units, fields.dosage);

    updateFields({ ...fields, calculatedDosage: dosage.toString(), calculatedUnit: unit });
  }, [fields.dosage]);

  // get dosage unit options if batch is selected - there could be none
  const dosageUnitOptions = useMemo(() => (batch ? getUnitOptions(batch) : null), [batch]);

  // build conversion help string
  const dosageConversion = useMemo((): string | null => {
    const { dosage = 1, units } = fields;

    if (!units) return null;

    const conversion = getConversion(units, dosage || 1);

    return conversion
      ? `${conversion?.fromQty} ${conversion?.fromUnit} = ${conversion?.toQty} ${conversion?.toUnit}`
      : null;
  }, [fields.dosage, fields.units]);

  // build medicine field option list
  const medicineOptions = useMemo((): InputSelectOption[] => {
    return medicines.map((item) => ({ key: item, label: item, value: item }));
  }, [medicines]);

  // build batch field option list
  const batchOptions = useMemo(() => {
    return getBatchOptions(batches);
  }, [batches]);

  const handleKeyPressWithdrawalDays: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === ".") event.preventDefault();
  };

  return (
    <div className={styles.administer_medicine_form}>
      <FieldSelect
        error={errors?.medicine}
        label="Medicine"
        infoIcon={!!medicineDetails && handleMedicineInfoClick}
        inputProps={{
          name: "medicine",
          onChange: handleFieldChange,
          options: medicineOptions,
          placeholder: "Select...",
          value: fields.medicine,
        }}
      />
      {!!batches.length ? (
        <FieldSelect
          error={errors?.batchId}
          label="Batch"
          inputProps={{
            name: "batchId",
            onChange: handleFieldChange,
            options: batchOptions,
            placeholder: "Select...",
            value: fields.batchId,
          }}
        />
      ) : null}
      {batch ? (
        <>
          {dosageUnitOptions ? (
            <FieldSelect
              error={errors?.units}
              label="Dosage Units"
              inputProps={{
                name: "units",
                onChange: handleFieldChange,
                options: dosageUnitOptions,
                placeholder: "Select...",
                value: fields.units,
              }}
            />
          ) : null}
          <FieldNumber
            inputProps={{
              min: 0,
              name: "dosage",
              onChange: handleFieldChange,
              value: fields.dosage,
            }}
            error={errors?.dosage || doseError || undefined}
            helper={dosageUnitOptions && dosageConversion ? dosageConversion : undefined}
            label={`Dosage per animal ${!dosageUnitOptions && fields.units ? `(${fields.units})` : ""}`}
          />
          <FieldNumber
            inputProps={{
              min: 0,
              name: "withdrawalDays",
              onChange: handleFieldChange,
              onKeyPress: handleKeyPressWithdrawalDays,
              value: fields.withdrawalDays,
            }}
            error={batch && errors?.withdrawalDays}
            label="Withdrawal days"
          />
          <FieldSelect
            error={errors?.reason}
            label="Reason"
            inputProps={{
              name: "reason",
              onChange: handleFieldChange,
              options: reasonOptions || [],
              placeholder: "Select...",
              value: fields.reason,
            }}
          />
          <FieldText
            inputProps={{
              name: "notes",
              onChange: handleFieldChange,
              value: fields.notes,
            }}
            error={errors?.notes}
            label="Notes"
          />
        </>
      ) : null}

      <MedicinePreviewModal
        isActive={showMedicineDetails}
        medicine={medicineDetails}
        onClose={(): void => setShowMedicineDetails(false)}
      />
    </div>
  );
};
