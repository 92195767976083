import {
  ContactContactType,
  CreateContactInput,
  UpdateContactInput,
  useContactQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
} from "generated/graphql";
import { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import MaskedInput from "react-text-mask";
import { BaseInput } from "components/Common/Field/BaseInput";
import { capitaliseFirstLetter, useHoldingInfo } from "helpers/translations/src";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { CommonContext } from "config/commonProvider";
import { useGetCurrentBusinessUnit } from "hooks";
import { Button, Field, FieldSelect, FieldText, Flex, Spacer } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./ContactForm.module.scss";
import { getBUFromLocalStorage } from "helpers/storage";
import { useHistory } from "react-router";
import { contactValidationSchema } from "./validation";
import * as yup from "yup";
import { FormDivider } from "./FormDivider";
import { AU_STATE_OPTIONS, US_STATE_OPTIONS } from "feShared/countryStatesOptions";
import { useCountries } from "hooks/useCountries";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import cn from "classnames";
import { useLocale } from "helpers/translations/src/useLocale";
import { formatPhoneNumber } from "helpers/general";
import { CONTACTS } from "settings/api/queries";

type FormData = yup.InferType<typeof contactValidationSchema>;

const LOQATE_FIND_URL = "https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws";
const LOQATE_RETRIEVE_URL = "https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws";

// Hand written type from API result
export interface LoqateAddress {
  Id: string;
  Type: string;
  Text: string;
  Highlight: string;
  Description: string;
}

interface Props {
  contactId: string | undefined;
  onConfirm?: () => void;
  onCancel?: () => void;
  isModal?: boolean;
}

const UK_COUNTRY_ID = "11";

export const ContactForm = ({ contactId, onConfirm, onCancel, isModal = false }: Props) => {
  const history = useHistory();
  const currentBusinessUnitId: number = getBUFromLocalStorage();

  const { countryIsoCode, country } = useGetCurrentBusinessUnit();
  const { showNotification } = useContext(CommonContext);
  const [createContact, { loading: loadingCreateContact }] = useCreateContactMutation();
  const [updateContact, { loading: loadingUpdateContact }] = useUpdateContactMutation();
  const radioButtonClasses = { root: styles.root, checked: styles.checked };
  const [addressList, setAddressList] = useState([]);
  const { terms } = useLocale();

  const { data: contactData, loading: loadingContactData } = useContactQuery({
    variables: {
      businessUnitId: getBUFromLocalStorage(),
      id: +(contactId || 0),
    },
    skip: !contactId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const contact = contactData?.contact;

  const [showRemainingFields, setShowRemainingFields] = useState(contactId ? true : false);

  const { getCountryOptions, getCountryIdToIsoAlpha2Map } = useCountries();

  const countryOptions = getCountryOptions({
    buCountryIsoCode: countryIsoCode,
    contactIsoCode: contact?.country?.isoAlpha2 || undefined,
  });

  const onSubmit = async (formData: FormData) => {
    try {
      if (contactId) {
        const input: UpdateContactInput = {
          id: parseInt(contactId),
          businessUnit: currentBusinessUnitId,
          contactType: formData.contactType,
          organisationName: formData.businessName,
          farmId: formData.farmId || undefined,
          addressLine1: formData.addressLine1 || undefined,
          addressLine2: formData.addressLine2 || undefined,
          city: formData.city || undefined,
          postcode: formData.postcode || undefined,
          state: formData.state || formData.county || undefined,
          country: formData?.countryId ? parseInt(formData.countryId) : undefined,
          name: formData.contactName || formData.businessName,
          phoneNumber: formatPhoneNumber({ phoneNumber: formData.phoneNumber, countryCode: formData.countryCode }),
          email: formData.email || undefined,
          code: formData.code || undefined,
        };

        const result = await updateContact({ variables: { input }, refetchQueries: ["Contacts"] });

        if (result.data?.updateContact?.errors) {
          showNotification({
            variant: "error",
            message: "Error creating updating contact.",
          });
        } else {
          showNotification({
            message: "Successfully updated contact",
          });
          if (onConfirm) {
            onConfirm();
          }
        }
      } else {
        const input: CreateContactInput = {
          businessUnit: currentBusinessUnitId,
          contactType: formData.contactType,
          organisationName: formData.businessName,
          farmId: formData.farmId || undefined,
          addressLine1: formData.addressLine1 || undefined,
          addressLine2: formData.addressLine2 || undefined,
          city: formData.city || undefined,
          postcode: formData.postcode || undefined,
          state: formData.state || formData.county || undefined,
          country: formData?.countryId ? parseInt(formData.countryId) : undefined,
          name: formData.contactName || formData.businessName,
          phoneNumber: formatPhoneNumber({ phoneNumber: formData.phoneNumber, countryCode: formData.countryCode }),
          email: formData.email || undefined,
          code: formData.code || undefined,
        };

        const result = await createContact({ variables: { input }, refetchQueries: [CONTACTS] });

        if (result.data?.createContact?.errors) {
          showNotification({
            variant: "error",
            message: "Error creating new contact.",
          });
        } else {
          showNotification({
            message: "Successfully created new contact",
          });
          if (onConfirm) {
            onConfirm();
          }
        }
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: contactId ? "Error updating contact" : "Error creating contact",
      });
    }
  };

  const defaultFormValues: FormData = useMemo(
    () => ({
      countryCode: contact?.country?.isoAlpha2?.toUpperCase() || countryIsoCode.toUpperCase(),
      contactType: contact?.contactType || ContactContactType.Farm,
      businessName: contact?.organisationName || "",
      farmId: contact?.farmId || "",
      addressLine1: contact?.addressLine1 || "",
      addressLine2: contact?.addressLine2 || "",
      city: contact?.city || "",
      postcode: contact?.postcode || "",
      county: contact?.state || "",
      state: contact?.state || "",
      countryId: contact?.country?.id ? contact?.country?.id : country?.id || UK_COUNTRY_ID,
      contactName: contact?.name || "",
      phoneNumber: contact?.phoneNumber || "",
      email: contact?.email || "",
      code: contact?.code || "",
    }),
    [
      contact?.addressLine1,
      contact?.addressLine2,
      contact?.city,
      contact?.contactType,
      contact?.country?.id,
      contact?.country?.isoAlpha2,
      contact?.email,
      contact?.farmId,
      contact?.name,
      contact?.organisationName,
      contact?.phoneNumber,
      contact?.postcode,
      contact?.state,
      country?.id,
      countryIsoCode,
      contact?.code,
    ],
  );

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(contactValidationSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
    reset,
  } = methods;

  useEffect(() => {
    if (contact?.id) {
      reset(defaultFormValues);
    }
  }, [contact?.id, defaultFormValues, reset]);

  const watchCountryCode = watch("countryCode");

  const limit = 6;

  const [isAddressOptionsVisible, setIsAddressOptionsVisible] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const modal = ref.current;

    const handleClick = (event: MouseEvent) => {
      if (!modal?.contains(event.target as Node)) {
        setIsAddressOptionsVisible(false);
      }
    };

    document.addEventListener("click", handleClick, { capture: true });
    return () => {
      document.removeEventListener("click", handleClick, { capture: true });
    };
  }, []);

  const handleFindAddress = async (searchTerm: string) => {
    const countryCode = (watchCountryCode ?? countryIsoCode).toUpperCase();
    try {
      const container = await axios.post(
        `${LOQATE_FIND_URL}?key=${process.env.REACT_APP_LOQATE_API_KEY}&text=${searchTerm}&countries=${countryCode}&limit=${limit}`,
      );
      setAddressList(container?.data?.Items?.filter((addr: LoqateAddress) => addr.Type === "Address"));
    } catch (error: unknown) {
      showNotification({
        variant: "error",
        message: "Error finding address. Please enter the address manually.",
      });
    }
  };

  const onAddressChange = (searchTerm: string) => {
    if (searchTerm.length >= 5) {
      setIsAddressOptionsVisible(true);
      handleFindAddress(searchTerm);
    } else {
      setIsAddressOptionsVisible(false);
      setAddressList([]);
    }
  };

  const handleAddressSelect = async (id: string) => {
    try {
      const address = await axios.post(`${LOQATE_RETRIEVE_URL}?key=${process.env.REACT_APP_LOQATE_API_KEY}&id=${id}`);
      if (address.data.Items[0]) {
        methods.setValue("addressLine1", address.data.Items[0]?.Line1);
        methods.setValue("addressLine2", address.data.Items[0]?.Line2);
        methods.setValue("city", address.data.Items[0]?.City);

        methods.setValue("postcode", address.data.Items[0]?.PostalCode);

        if (watchCountryCode === CountryISOCode.US || watchCountryCode === CountryISOCode.AU) {
          methods.setValue("state", address.data.Items[0]?.ProvinceCode);
        }

        setIsAddressOptionsVisible(false);
        setAddressList([]);
        setShowRemainingFields(true);
      }
    } catch (error: unknown) {
      showNotification({
        variant: "error",
        message: "Error selecting address. Please enter the address manually.",
      });
    }
  };

  useEffect(() => {
    if (!methods.formState.isValid && methods.formState.isSubmitted) {
      setShowRemainingFields(true);
    }
  }, [methods.formState.isSubmitted, methods.formState.isValid]);

  const animationVariants = {
    initial: {
      opacity: 0,
      scale: 0.95,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Controller
          control={control}
          name="contactType"
          render={({ field: { value, onChange }, formState: { errors } }) => {
            return (
              <>
                <Field error={errors.contactType?.message} labelFor="contactType" label="Contact Type">
                  <RadioGroup aria-label="Contact type" name="contactType" value={value} onChange={onChange} row>
                    <FormControlLabel
                      value={ContactContactType.Farm}
                      control={<Radio classes={radioButtonClasses} />}
                      label={capitaliseFirstLetter(terms.farm)}
                    />
                    <FormControlLabel
                      value={ContactContactType.Other}
                      control={<Radio classes={radioButtonClasses} />}
                      label="Other"
                    />
                  </RadioGroup>
                </Field>
              </>
            );
          }}
        />

        <div className={cn(styles.formGrid, { [styles["formGrid--is_modal"]]: isModal })}>
          <div>
            <FormDivider label="Address Details" />

            <Spacer baselineHeight={1} />

            <Controller
              control={control}
              name="businessName"
              render={({ formState: { errors }, field: { value, onChange } }) => {
                return (
                  <FieldText
                    label="Business Name"
                    error={errors.businessName?.message}
                    inputProps={{
                      name: "organisationName",
                      placeholder: "Business Name",
                      value: value || "",
                      onChange,
                    }}
                  />
                );
              }}
            />
            <Spacer baselineHeight={1} />
            <label>
              Country
              <Controller
                control={control}
                name="countryId"
                render={({ formState: { errors }, field: { value, onChange } }) => {
                  return (
                    <FieldSelect
                      error={errors?.countryId?.message}
                      inputProps={{
                        value,
                        options: countryOptions,
                        onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                          const isoAlpha2 = getCountryIdToIsoAlpha2Map().get(event.target.value);
                          if (isoAlpha2) {
                            methods.setValue("countryCode", isoAlpha2);
                          }
                          onChange(event);
                        },
                        isClearable: false,
                      }}
                    />
                  );
                }}
              />
            </label>
            <Spacer baselineHeight={1} />
            <label>
              Address
              {!showRemainingFields ? (
                <div className={styles.addressLookupContainer}>
                  <div className={styles.addressLookupContainerInner}>
                    <FieldText
                      inputProps={{
                        name: "addressLookup",
                        autoComplete: "off",
                        placeholder: "Search for address",
                        onChange: (event: ChangeEvent<HTMLInputElement>) => {
                          onAddressChange(event.target.value);
                        },
                      }}
                    />
                    <div className={styles.spacer} />
                    <Button
                      caption="Enter address manually"
                      type="button"
                      onClick={() => setShowRemainingFields(true)}
                      variant="hollow"
                      colour="grey"
                    />
                  </div>
                  <div ref={ref} className={styles.addressOptions}>
                    {isAddressOptionsVisible
                      ? addressList.map((address: LoqateAddress) => {
                          return (
                            <option
                              className={styles.addressOption}
                              key={address.Id}
                              value={address.Id}
                              onClick={() => handleAddressSelect(address.Id)}
                            >
                              {address.Text}
                            </option>
                          );
                        })
                      : null}
                  </div>
                </div>
              ) : null}
              {showRemainingFields ? (
                <AnimatePresence>
                  <motion.div
                    key="farmIdInput"
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={animationVariants}
                  >
                    <Controller
                      control={control}
                      name="addressLine1"
                      render={({ formState: { errors }, field: { value, onChange } }) => {
                        return (
                          <FieldText
                            error={errors.addressLine1?.message}
                            inputProps={{
                              name: "addressLine1",
                              placeholder: "Address Line 1",
                              value: value || "",
                              onChange,
                              disabled: loadingContactData,
                            }}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="addressLine2"
                      render={({ formState: { errors }, field: { value, onChange } }) => {
                        return (
                          <FieldText
                            error={errors.addressLine2?.message}
                            inputProps={{
                              name: "addressLine2",
                              placeholder: "Address Line 2",
                              value: value || "",
                              onChange,
                              disabled: loadingContactData,
                            }}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="postcode"
                      render={({ formState: { errors }, field: { value, onChange } }) => {
                        return (
                          <FieldText
                            error={errors.postcode?.message}
                            inputProps={{
                              name: "postcode",
                              placeholder: "Postcode / ZIP Code",
                              value: value || "",
                              onChange,
                              disabled: loadingContactData,
                            }}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="city"
                      render={({ formState: { errors }, field: { value, onChange } }) => {
                        return (
                          <FieldText
                            error={errors.city?.message}
                            inputProps={{
                              name: "city",
                              placeholder: "City",
                              value: value || "",
                              onChange,
                              disabled: loadingContactData,
                            }}
                          />
                        );
                      }}
                    />
                    {watchCountryCode === CountryISOCode.GB ? (
                      <Controller
                        control={control}
                        name="county"
                        render={({ formState: { errors }, field: { value, onChange } }) => {
                          return (
                            <FieldText
                              error={errors.city?.message}
                              inputProps={{
                                name: "county",
                                placeholder: "County",
                                value: value || "",
                                onChange,
                                disabled: loadingContactData,
                              }}
                            />
                          );
                        }}
                      />
                    ) : null}
                    {watchCountryCode === CountryISOCode.US || watchCountryCode === CountryISOCode.AU ? (
                      <Controller
                        control={control}
                        name="state"
                        render={({ formState: { errors }, field: { value, onChange } }) => {
                          return (
                            <FieldSelect
                              error={errors?.state?.message}
                              inputProps={{
                                value,
                                placeholder: "State",
                                options: watchCountryCode === CountryISOCode.AU ? AU_STATE_OPTIONS : US_STATE_OPTIONS,
                                onChange,
                                isClearable: true,
                                disabled: loadingContactData,
                              }}
                            />
                          );
                        }}
                      />
                    ) : null}
                  </motion.div>
                </AnimatePresence>
              ) : null}
            </label>
            <FarmIdInput countryCode={watchCountryCode} />
            <Spacer baselineHeight={1} />
          </div>
          <div className={styles.divider} />
          <div>
            <FormDivider label="Contact Details" />

            <Spacer baselineHeight={1} />

            <Controller
              control={control}
              name="contactName"
              render={({ field, formState: { errors } }) => {
                return (
                  <FieldText
                    label="Contact Name"
                    error={errors.contactName?.message}
                    inputProps={{
                      name: "name",
                      placeholder: "Enter name",
                      value: field.value,
                      onChange: field.onChange,
                      disabled: loadingContactData,
                    }}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="phoneNumber"
              render={({ field, formState: { errors } }) => {
                return (
                  <FieldText
                    label="Phone"
                    error={errors.phoneNumber?.message}
                    inputProps={{
                      name: "phoneNumber",
                      value: field.value,
                      onChange: field.onChange,
                      placeholder: "Phone",
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="email"
              render={({ formState: { errors }, field: { value, onChange } }) => {
                return (
                  <FieldText
                    label="Email"
                    error={errors.email?.message}
                    inputProps={{
                      name: "email",
                      placeholder: "Email Address",
                      value: value || "",
                      onChange,
                      disabled: loadingContactData,
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="code"
              render={({ formState: { errors }, field: { value, onChange } }) => {
                return (
                  <FieldText
                    label="Contact Code"
                    error={errors.email?.message}
                    inputProps={{
                      name: "code",
                      placeholder: "Contact Code",
                      value: value || "",
                      onChange,
                      disabled: loadingContactData,
                    }}
                  />
                );
              }}
            />
          </div>
        </div>

        <Spacer baselineHeight={1} />
        <Flex container containerJustifyContent="flex-end">
          <Flex item itemGutter>
            <Button
              caption="Cancel"
              type="button"
              colour="grey"
              onClick={() => {
                onCancel ? onCancel() : history.goBack();
              }}
              disabled={loadingCreateContact || loadingUpdateContact || isSubmitting}
            />
          </Flex>
          <Button
            caption="Confirm"
            type="submit"
            disabled={loadingCreateContact || loadingUpdateContact || isSubmitting}
            requesting={loadingCreateContact || loadingUpdateContact || isSubmitting}
          />
        </Flex>
      </FormProvider>
    </form>
  );
};

export const FarmIdInput = ({ countryCode }: { countryCode: string | undefined }) => {
  const { control, watch } = useFormContext<FormData>();
  const { HoldingIDNumberMaskWeb } = useHoldingInfo(countryCode as CountryISOCode);
  const { terms } = useLocale();

  const contactType = watch("contactType");

  if (countryCode === CountryISOCode.AU || countryCode === CountryISOCode.US) {
    return (
      <Controller
        control={control}
        name="farmId"
        render={({ field: { value, onChange }, fieldState }) => {
          return (
            <FieldText
              label={terms.farmId}
              error={fieldState.error?.message}
              inputProps={{
                name: "farmId",
                placeholder: terms.farmId,
                value: value || "",
                onChange,
                disabled: contactType === ContactContactType.Other,
              }}
            />
          );
        }}
      />
    );
  }
  if (countryCode === CountryISOCode.GB) {
    return (
      <Controller
        control={control}
        name="farmId"
        render={({ field: { value, onChange }, fieldState }) => {
          return (
            <MaskedInput
              label="County Parish Holding (CPH) Number"
              mask={HoldingIDNumberMaskWeb}
              name="farmId"
              value={value}
              onChange={onChange}
              render={(ref, props): JSX.Element => (
                <Field error={fieldState.error?.message} labelFor="farmId" {...props}>
                  <BaseInput
                    hasError={!!fieldState.error?.message}
                    ref={ref}
                    disabled={contactType === ContactContactType.Other}
                    {...props}
                  />
                </Field>
              )}
            />
          );
        }}
      />
    );
  }
  return null;
};
