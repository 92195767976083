import { useState, useContext, FC } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { CommonContext } from "config/commonProvider";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { MOVE_OFF_FARM_TEMPLATE } from "constants/Animals";
import { useBulkMarkAnimalsOffFarmMutation, useGetCurrentBusinessUnit, useGetActivityTemplates } from "hooks";
import { handleErrorCheck } from "helpers/general";
import { Button, FieldDate, FieldText, Spacer, Flex, Title } from "components";
import { BUWithLocationSelect } from "components/MyLivestock";
import { AddressBookSelect } from "components/Settings/AddressBook/AddressBookSelect";
import { formatAddressString } from "settings/components/ContactsTable";
import { ContactFragmentFragment } from "generated/graphql";
import { useLocale } from "helpers/translations/src/useLocale";

export const TEST_IDS = {
  MARK_OFF_FARM_BUTTON_TEST_ID: "mark-off-farm-button",
  MODAL_CANCEL_BUTTON_TEST_ID: "mark-off-farm-cancel-btn",
  MODAL_CONFIRM_BUTTON_TEST_ID: "mark-off-farm-confirm-btn",
};

interface Props {
  animalIds: Array<string>;
  groupId?: string;
  isShowModal?: boolean;
  onClose?: () => void;
  tableId: string;
  handleCancelClick: (e) => void;
  handleModalToggle: () => void;
  changeModalContent: (modalContent: "createNewContactForm" | "markOffFarmForm") => void;
}

export type MarkOffFarmValues = {
  contact?: string;
  deliveryDate?: Date;
  destinationCPHNumber?: string;
  destinationName?: string;
  destinationId?: string;
  destinationPostcode?: string;
  destinationAddress?: string;
  destinationEmail?: string;
  notes?: string;
  location?: string;
};

export const initialValues = {
  contact: "",
  deliveryDate: new Date(),
  destinationCPHNumber: "",
  destinationName: "",
  destinationId: "",
  destinationPostcode: "",
  destinationAddress: "",
  destinationEmail: "",
  notes: "",
  location: "",
};

export const moveOffFarmSchema = Yup.object().shape({
  destinationEmail: Yup.string().email("please enter a valid email address"),
});

export const MarkOffFarmForm: FC<Props> = ({
  handleCancelClick,
  handleModalToggle,
  changeModalContent,
  animalIds = [],
  groupId,
  tableId,
}) => {
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const { showNotification } = useContext(CommonContext);
  const { setTableOptions } = useContext(TableOptionsContext);
  const { terms } = useLocale();

  const { data: activityTemplates } = useGetActivityTemplates();

  const activityTemplate = activityTemplates?.find((el) => el.slug === MOVE_OFF_FARM_TEMPLATE);

  const isAllAnimalsCanNotBeRemoved = animalIds.length === 0;
  const isDisableAllExceptForm = showAddContactForm || isAllAnimalsCanNotBeRemoved;

  const { regulator } = useGetCurrentBusinessUnit();
  const isScotMoves = regulator?.slug === "SCOTMOVES";

  const { mutate: markAnimalsOffFarm, loading: requesting } = useBulkMarkAnimalsOffFarmMutation({
    animalIds,
    activityTemplate: activityTemplate ? activityTemplate : null,
    groupId,
  });

  const handleConfirmClick = async (values): Promise<void> => {
    try {
      await markAnimalsOffFarm(values);
      setTableOptions({
        id: tableId,
        selectedRows: [],
        selectedRowsIds: null,
      });
      handleModalToggle();
      showNotification({
        message: `Animals were successfully moved off-${terms.farm}`,
      });
      setShowAddContactForm(false);
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Error moving animals",
      });
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={handleConfirmClick}
        initialValues={initialValues}
        validateOnChange={false}
        validationSchema={moveOffFarmSchema}
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit, setValues }): JSX.Element => {
          const handleLocationChange = (newValues: MarkOffFarmValues) => {
            const updatedValues = {
              ...values,
              ...newValues,
              contact: "",
            };
            setValues(updatedValues);
          };

          const handleContactChange = (contact: ContactFragmentFragment | undefined): void => {
            const newValues = {
              contact: contact?.id || "",
              location: "",
              destinationName: contact?.name || "",
              destinationCPHNumber: contact?.farmId || "",
              destinationPostcode: contact?.postcode || "",
              destinationEmail: contact?.email || "",
              destinationAddress: contact ? formatAddressString(contact) : "",
            };
            const newOldValues = {
              ...values,
              ...newValues,
            };
            setValues(newOldValues);
          };

          const hasValue = !!values.contact || !!values.location;
          const isSubmitDisabled = isScotMoves ? isAllAnimalsCanNotBeRemoved || !hasValue : isAllAnimalsCanNotBeRemoved;

          return (
            <>
              <FieldDate
                label={`Date moved off-${terms.farm}`}
                error={handleErrorCheck(errors.deliveryDate)}
                inputProps={{
                  name: "deliveryDate",
                  value: values.deliveryDate,
                  onChange: (date): void => setFieldValue("deliveryDate", date),
                  disabled: isAllAnimalsCanNotBeRemoved,
                  maxDate: new Date(),
                }}
              />
              <Flex container containerDirection="column" containerAlignItems="center" xs={12}>
                <Spacer baselineHeight={1} border="top" />
                <Flex item itemAlignSelf="center">
                  <h3>Where are you moving your animal(s) to?</h3>
                </Flex>
              </Flex>

              <Spacer baselineHeight={1} />
              <>
                <BUWithLocationSelect
                  disabled={isDisableAllExceptForm}
                  onLocationChange={handleLocationChange}
                  value={values.location}
                />
                <Flex container containerJustifyContent="center" xs="fill">
                  <Title tertiary>or</Title>
                </Flex>
              </>
              <AddressBookSelect
                value={values.contact}
                onContactChange={handleContactChange}
                onCreateNewContact={() => {
                  changeModalContent("createNewContactForm");
                }}
                label="Move to Destination out of your Business"
                disabled={isDisableAllExceptForm}
              />
              <Flex container containerJustifyContent="center" xs="fill">
                <Title tertiary>or</Title>
              </Flex>
              <Spacer baselineHeight={1} />
              <FieldText
                label="Notes (Optional)"
                error={handleErrorCheck(errors.notes)}
                inputProps={{
                  name: "notes",
                  placeholder: "Enter Notes",
                  disabled: isAllAnimalsCanNotBeRemoved,
                  value: values.notes,
                  onChange: handleChange,
                }}
              />
              <Flex container containerJustifyContent="flex-end">
                <Flex item>
                  <Button
                    caption="Cancel"
                    colour="grey"
                    variant="hollow"
                    onClick={handleCancelClick}
                    data-testid={TEST_IDS.MODAL_CANCEL_BUTTON_TEST_ID}
                  />
                </Flex>
                <Flex item itemGutter>
                  <Button
                    caption="Confirm"
                    disabled={requesting || isSubmitDisabled}
                    requesting={requesting}
                    onClick={handleSubmit}
                    data-testid={TEST_IDS.MODAL_CONFIRM_BUTTON_TEST_ID}
                  />
                </Flex>
              </Flex>
            </>
          );
        }}
      </Formik>
    </>
  );
};
