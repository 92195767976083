import { animal } from "animals/api/fragments";
import gql from "graphql-tag";
import { DELIVERY_FRAGMENT, PAGE_INFO_FRAGMENT } from "./fragments";

export const DELIVERY_PARTNER_ADDRESSES = gql`
  query DeliveryPartnerAddresses($businessUnit: ID!, $orderBy: [DeliveryPartnerAddressSortingEnum!]) {
    deliveryPartnerAddresses(businessUnit: $businessUnit, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_DELIVERIES = gql`
  query Deliveries(
    $businessUnit: ID!
    $status: DeliveryStatusEnum
    $direction: DeliveryDirectionEnum
    $orderBy: [DeliverySortingEnum]
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    ${DELIVERY_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    deliveries(
      businessUnit: $businessUnit
      status: $status
      direction: $direction
      orderBy: $orderBy
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        ...PageInfo
      }
      count
      edges {
        node {
          ...Delivery
        }
      }
    }
  }
`;

export const GET_DELIVERY = gql`
  query Delivery($businessUnit: ID!, $delivery: ID!) {
    delivery(businessUnit: $businessUnit, delivery: $delivery) {
      delivery {
        ...Delivery
      }
      consignments {
        edges {
          node {
            animalCount
            animals {
              edges {
                node {
                  id
                }
              }
            }
            destination {
              details {
                name
                address
                email
                postcode
              }
            }
          }
        }
      }
    }
  }
  ${DELIVERY_FRAGMENT}
`;

export const DELIVERY_REASONS = gql`
  query DeliveryReasons($businessUnit: ID!, $isActive: Boolean, $directions: [DeliveryDirectionEnum!]) {
    deliveryReasons(businessUnit: $businessUnit, isActive: $isActive, directions: $directions) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const DRAFT_DELIVERY = gql`
  query DraftDelivery($businessUnit: ID!, $delivery: ID!) {
    delivery(businessUnit: $businessUnit, delivery: $delivery) {
      delivery {
        ...Delivery
      }
    }
  }
  ${DELIVERY_FRAGMENT}
`;

export const DRAFT_DELIVERY_ANIMALS = gql`
  query DraftDeliveryAnimals($businessUnit: ID!, $delivery: ID!) {
    delivery(businessUnit: $businessUnit, delivery: $delivery) {
      delivery {
        id
      }
      animals {
        edges {
          cursor
          node {
            ...animal
          }
        }
      }
    }
  }
  ${animal}
`;

export const CHECK_IF_ANIMAL_EXISTS = gql`
  query CheckIfAnimalExists(
    $businessUnit: Int!
    $visualId: String
    $eId: String
    $passportNumber: String
    $first: Int
    $search: String
    $searchIds: [IdentifierType!]
  ) {
    animals(
      businessUnitId: $businessUnit
      visualId: $visualId
      eId: $eId
      passportNumber: $passportNumber
      first: $first
      search: $search
      searchIds: $searchIds
    ) {
      edges {
        node {
          id
          brucellosisId
          eId
          herdDoggId
          pedigreeId
          tattoo
          trichId
          tsuBarcode
          uhfId
          visualId
        }
      }
    }
  }
`;
