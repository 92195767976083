import { gql } from "@apollo/client";
import { deliveryAddressFragment } from "trading/api/fragment";

export const GET_DELIVERY_ADDRESSES = gql`
  query deliveryAddresses($businessUnit: Int!) {
    deliveryAddresses(businessUnit: $businessUnit) {
      ...deliveryAddressFragment
    }
  }
  ${deliveryAddressFragment}
`;

export const GET_COUNTRIES = gql`
  query Countries {
    countries {
      id
      code
      name
      isoAlpha2
    }
  }
`;

export const GET_HOW_DID_YOU_HEAR_ABOUT_BREEDR_CHOICES = gql`
  query HowDidYouHearAboutBreedrChoices {
    howDidYouHearAboutBreedrChoices {
      id
      choice
      slug
    }
  }
`;

export const GET_DISCOUNT_GRIDS = gql`
  query GetDiscountGrids($businessUnit: Int!) {
    discountGrids(businessUnit: $businessUnit) {
      id
      name
    }
  }
`;

export const GET_DISTRIBUTION_LISTS_COUNT = gql`
  query GetDistributionListsCount($businessUnit: Int!) {
    distributionLists(businessUnit: $businessUnit) {
      count
    }
  }
`;

export const GET_DISTRIBUTION_LISTS = gql`
  query GetDistributionLists($businessUnit: Int!) {
    distributionLists(businessUnit: $businessUnit) {
      edges {
        node {
          id
          name
          suppliers {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_DISTRIBUTION_LIST_BY_ID = gql`
  query GetDistributionListById($businessUnit: Int!, $id: Int!) {
    distributionList(businessUnit: $businessUnit, id: $id) {
      id
      name
      suppliers {
        id
        name
        location {
          city
          unitName
        }
      }
    }
  }
`;

export const GET_TRADING_TYPES = gql`
  query GetTradingTypes {
    tradingTypes {
      id
      slug
    }
  }
`;

export const GET_COUNT_OF_BUS = gql`
  query GetCountBusinessUnits($postcode: String!, $radius: Int!, $includePreferredSuppliers: Boolean) {
    countBusinessUnits(postcode: $postcode, radius: $radius, includePreferredSuppliers: $includePreferredSuppliers) {
      count
      errors {
        message
      }
    }
  }
`;

export const GET_CURRENCIES_LIST = gql`
  query GetCurrenciesList {
    currencies {
      id
      name
      code
    }
  }
`;

export const GET_ESTIMATED_FUTURE_PRICE_BASED_ON_WEIGHT = gql`
  query GetEstimatedFuturePriceBasedOnWeight($offerPricePerWeightUnit: Float!, $collectionDate: Date!, $lot: Int!) {
    estimatedFuturePriceBasedOnWeight(
      offerPricePerWeightUnit: $offerPricePerWeightUnit
      collectionDate: $collectionDate
      lot: $lot
    ) {
      futurePrice
      errors {
        field
        code
        message
      }
    }
  }
`;
