// React
import { FC, ReactNode } from "react";
// Libraries
import cn from "classnames";
import { isString } from "lodash";
import { Helmet } from "react-helmet";
// Utils
import { useBreakpoints } from "hooks";
// Components
import { BackButton, Flex, Spacer, Text, Title } from "components";
// Resources
import styles from "./Header.module.scss";

interface HeaderProps {
  backButton?: boolean;
  children?: React.ReactNode;
  customStyles?: string;
  id?: string | null;
  firstSubtitle?: string | ReactNode;
  subTitle?: string | ReactNode | false;
  title: string;
  titleIcon?: React.ReactElement;
}

export const Header: FC<HeaderProps> = ({
  backButton,
  children,
  customStyles,
  id,
  firstSubtitle,
  subTitle,
  title,
  titleIcon,
}) => {
  const { isBreakpointL } = useBreakpoints();

  return (
    <Flex container containerAlignItems="flex-end" className={cn(styles.header, customStyles)}>
      <Flex className={styles.header__details} item itemGutter xs={12} l={6}>
        <Helmet>
          <title>Breedr | {title}</title>
        </Helmet>
        <Title>
          {backButton ? <BackButton /> : null}

          <Flex container>
            {title}
            {titleIcon ? (
              <>
                <div className="w-4" />
                {titleIcon}
              </>
            ) : null}
          </Flex>
        </Title>

        {firstSubtitle || id || subTitle ? (
          <div className={styles.header__meta}>
            {firstSubtitle ? <Text>{firstSubtitle}</Text> : null}

            {id ? (
              <Text>
                ID: <strong>{id}</strong>
              </Text>
            ) : null}

            {!!subTitle ? isString(subTitle) ? <Text>{subTitle}</Text> : subTitle : null}
          </div>
        ) : (
          children && isBreakpointL && <Spacer baselineHeight={4} />
        )}
      </Flex>

      {children ? (
        <Flex className={styles.header__actions} item itemGutter xs={12} l={6}>
          <Flex container containerJustifyContent="flex-end" className={styles.header__buttons}>
            {children}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};
Header.displayName = "Header";
