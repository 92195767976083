import { gql } from "@apollo/client";
import { CONTACT_FRAGMENT } from "./fragments";

export const CONTACTS = gql`
  query Contacts($businessUnit: Int!, $contactType: String) {
    contacts(businessUnit: $businessUnit, contactType: $contactType) {
      ...ContactFragment
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const CONTACT = gql`
  query Contact($id: Int!, $businessUnitId: Int!) {
    contact(id: $id, businessUnitId: $businessUnitId) {
      ...ContactFragment
    }
  }
  ${CONTACT_FRAGMENT}
`;
