import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as en from "./translations/en.json"; // English (UK)
import * as us from "./translations/us.json"; // English (US)
import * as au from "./translations/au.json"; // English (Australian)
import * as es from "./translations/es.json"; // Spanish
import * as fr from "./translations/fr.json"; // French
import * as no from "./translations/no.json"; // Norwegian
import * as it from "./translations/it.json"; // Italian
import * as ro from "./translations/ro.json"; // Romanian
import * as gr from "./translations/gr.json"; // Greek
import { CountryISOCode } from "./useHoldingInfo";

// Investigate if you can add properties to this to reduce places this gets defined
const resources = {
  en: { translation: en }, // England - English (UK)
  us: { translation: us }, // America - English (US)
  ca: { translation: us }, // Canada - English (US)
  au: { translation: au }, // Australia - English (Australian)
  nz: { translation: au }, // New Zealand - English (Australian)
  es: { translation: es }, // Spain - Spanish
  mx: { translation: es }, // Mexico - Spanish
  fr: { translation: fr }, // France - French
  no: { translation: no }, // Norway - Norwegian
  it: { translation: it }, // Italy - Italian
  ro: { translation: ro }, // Romania - Romanian
  gr: { translation: gr }, // Greece - Greek
};

export type languageResources = keyof typeof resources;

export const mapCountryToLanguage = (countryCode: CountryISOCode): languageResources => {
  switch (countryCode) {
    case CountryISOCode.AU:
    case CountryISOCode.NZ:
      return "au";
    case CountryISOCode.GB:
    case CountryISOCode.IE:
      return "en";
    case CountryISOCode.US:
    case CountryISOCode.CA:
      return "us";
    case CountryISOCode.IL:
      return "en";
    case CountryISOCode.IT:
      return "it";
    case CountryISOCode.NO:
      return "no";
    case CountryISOCode.MX:
    case CountryISOCode.ES:
      return "es";
    case CountryISOCode.RO:
      return "ro";
    case CountryISOCode.FR:
      return "fr";
    case CountryISOCode.GR:
      return "gr";
    default:
      return "en"; // Default to English if no matching language found
  }
}

export interface LanguageCode {
  country: string;
  languageLabel: string;
  languageCode: string;
}

export const languageCodes: LanguageCode[] = [
  {
    country: "United Kingdom",
    languageLabel: "English (UK)",
    languageCode: "en",
  },
  {
    country: "United States",
    languageLabel: "English (US)",
    languageCode: "us",
  },
  {
    country: "Australia",
    languageLabel: "English (Australian)",
    languageCode: "au",
  },
  {
    country: "Spain",
    languageLabel: "Español",
    languageCode: "es",
  },
  {
    country: "France",
    languageLabel: "Français",
    languageCode: "fr",
  },
  {
    country: "Greece",
    languageLabel: "Ελληνικά",
    languageCode: "gr",
  },
  {
    country: "Italy",
    languageLabel: "Italiano",
    languageCode: "it",
  },
  {
    country: "Norsk",
    languageLabel: "Norwegian",
    languageCode: "no",
  },
  {
    country: "Romania",
    languageLabel: "Română",
    languageCode: "ro",
  },
];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    // Have a look to see if you can get rid of lng here (see if it breaks!)
    // Ask Todd about settings screen

    debug: false, // Set to false when done
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
