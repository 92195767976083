import React, { useEffect } from "react";
// Libraries
import { FormikProps } from "formik";
import { useRecoilState, useSetRecoilState } from "recoil";
// Helpers
import { useGetCurrentBusinessUnit } from "hooks";
// Components
import { Flex, InputRadio, Spacer, Text, Title } from "components";
import { OfferTypes } from "trading/constants";
//Utils
import { makeOfferCollectionDate, makeOfferType } from "state";
import { formattedTotalPricePerHead, formattedPricePerKgOnCreation, formattedPrice } from "trading/util/trading";

export interface FormValues {
  offerPrice: number | string;
}

export type FormProps = FormikProps<FormValues>;

interface TypeModalPropTypes {
  askingPricePerHead?: number | null;
  pricePerKgOnCreation: number | null;
  quantity: number;
  setFieldValue: <T>(field: string, value: T, shouldValidate?: boolean) => void;
}
export const Type = ({
  askingPricePerHead,
  pricePerKgOnCreation,
  quantity = 0,
  setFieldValue,
}: TypeModalPropTypes): JSX.Element => {
  const { currencyCodeISO } = useGetCurrentBusinessUnit();

  const [offerType, setOfferType] = useRecoilState(makeOfferType);

  const askingPerHead = askingPricePerHead ? formattedPrice(+askingPricePerHead, currencyCodeISO) : "\u2014";

  const setCollectionDate = useSetRecoilState(makeOfferCollectionDate);

  const totalOffer = askingPricePerHead
    ? formattedTotalPricePerHead(+askingPricePerHead, quantity, currencyCodeISO)
    : "\u2014";

  const pricePerKg = pricePerKgOnCreation
    ? formattedPricePerKgOnCreation(+pricePerKgOnCreation, currencyCodeISO)
    : "\u2014";

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event?.target?.value;
    setOfferType(OfferTypes[name]);
  };

  useEffect(() => {
    setFieldValue("offerPrice", "");
    setFieldValue("collectionDate", null);
    setCollectionDate(null);
  }, [offerType]);

  return (
    <>
      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>Asking per head</Text>
        <Title secondary>{askingPerHead}</Title>
      </Flex>

      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>No of Animals:</Text>
        <Title secondary>{quantity}</Title>
      </Flex>

      <Spacer baselineHeight={3} />

      <Flex item itemGutter xs={2}>
        <Spacer baselineHeight={1} />
        <Text>or per kg</Text>
        <Title secondary>{pricePerKg}</Title>
      </Flex>

      <Flex item itemGutter xs={2}>
        <Text>Total asking price</Text>
        <Title secondary>{totalOffer}</Title>
      </Flex>

      <Spacer baselineHeight={3} />

      <Flex item itemGutter xs={4}>
        <Text>Choose offer type</Text>
        <Flex item>
          <InputRadio
            name="offer"
            value={OfferTypes.HEAD}
            label="Per head"
            checked={offerType === OfferTypes.HEAD}
            onChange={handleChangeRadio}
          />
        </Flex>
        <Flex item>
          <InputRadio
            name="offer"
            value={OfferTypes.KG}
            label="Per kg"
            checked={offerType === OfferTypes.KG}
            onChange={handleChangeRadio}
          />
        </Flex>
      </Flex>

      <Spacer baselineHeight={1} />

      <Flex item itemGutter xs={4}>
        <Text smaller>Edit values on next screen</Text>
      </Flex>
    </>
  );
};
