import React from "react";
// Libraries
import cn from "classnames";
// Components
import { Flex } from "components";
import { Stage } from "./Stage";
// Resources
import styles from "./Stages.module.scss";

export const StagesContext = React.createContext("");

interface StagesProps {
  children: React.ReactElement[];
}

interface StagesSubcomponents {
  Stage: typeof Stage;
}

export const Stages: React.FC<StagesProps> & StagesSubcomponents = ({ children }) => {
  const totalStages = children.length;

  const lastStageTitle = children[totalStages - 1].props.title;

  return (
    <Flex
      container
      className={cn(styles.stages, {
        [styles[`stages--has_${totalStages}_stages`]]: children,
      })}
      role="progressbar"
    >
      <StagesContext.Provider value={lastStageTitle}>{children}</StagesContext.Provider>
    </Flex>
  );
};
Stage.displayName = "Stages.Stage";
Stages.Stage = Stage;
