import React, { CSSProperties } from "react";
// api
import { GetPerformanceChartData_performanceChartData_measurements } from "api/MyLivestock/Animal/types/GetPerformanceChartData";
// helpers
import { useFormatDate, useGetCurrentBusinessUnit } from "hooks";
// styles
import "../styles.scss";
import { useLocale } from "helpers/translations/src/useLocale";
import { capitaliseFirstLetter } from "helpers/translations/src";

interface Props {
  basic?: boolean;
  styles: CSSProperties;
  data: GetPerformanceChartData_performanceChartData_measurements & {
    isGroupTargetVisible: boolean;
  };
}

const AnimalChartTooltip: React.FC<Props> = ({ styles, data, basic = false }) => {
  const { weightUnits } = useGetCurrentBusinessUnit();
  const { getFormatDate } = useFormatDate();
  const { terms } = useLocale();

  const dateFromTimestamp = data.timestamp ? new Date(data.timestamp) : null;
  return (
    <div className="animal_chart__tooltip" style={styles}>
      <div className="animal_chart__tooltip_content">
        {dateFromTimestamp ? (
          <p className="animal_chart__tooltip_detail">
            <strong>{getFormatDate(dateFromTimestamp)}</strong>
          </p>
        ) : null}
        <p className="animal_chart__tooltip_detail">
          <strong>Age: </strong>
          <span>{data.age}</span>
        </p>
        <p className="animal_chart__tooltip_detail">
          <strong>Weight: </strong>
          <span>{data.value}</span> {weightUnits}
        </p>
        {!basic ? (
          <p className="animal_chart__tooltip_detail">
            <strong>Days to target weight: </strong>
            <span>{data.daysToTargetWeight ? data.daysToTargetWeight : "\u2014"}</span>
          </p>
        ) : null}
        {!basic && data.isGroupTargetVisible ? (
          <>
            <p className="animal_chart__tooltip_detail">
              <strong>On-target weight: </strong>
              <span>{data.onTargetWeight ? `${data.onTargetWeight.toFixed(2)} ${weightUnits}` : "\u2014"}</span>
            </p>
            <p className="animal_chart__tooltip_detail">
              <strong>Days to target: </strong>
              <span>{data.daysToTarget ? data.daysToTarget : "\u2014"}</span>
            </p>
          </>
        ) : null}
        {data.dailyLiveWeightGain ? (
          <p className="animal_chart__tooltip_detail">
            <strong>{capitaliseFirstLetter(terms.avgDlwg)}: </strong>
            <span>{data.dailyLiveWeightGain.toFixed(2)}</span> {weightUnits}
          </p>
        ) : null}
        {!basic && data.isGroupTargetVisible ? (
          <>
            <p className="animal_chart__tooltip_detail">
              <strong>{`Target ${capitaliseFirstLetter(terms.avgDlwg)}`}: </strong>
              <span>
                {data.targetDailyLiveWeightGain
                  ? `${data.targetDailyLiveWeightGain.toFixed(2)} ${weightUnits}`
                  : "\u2014"}
              </span>
            </p>
            <p className="animal_chart__tooltip_detail">
              <strong>DIFF: </strong>
              <span>
                {data.diffTargetDailyLiveWeightGain
                  ? `${data.diffTargetDailyLiveWeightGain.toFixed(2)} ${weightUnits}`
                  : "\u2014"}
              </span>
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AnimalChartTooltip;
