import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesGroupDetailPage: FC = () => {
  return (
    <EmptyStates animation={"searching"} title="Add animals to this group.">
      <p>
        Log group-level activities, administer medicines, move animals. <br />
        An animal can only be a member of 1 group.
        <br />
        Click ‘Add Animals’, select the checkbox next to the animals in the table and click ‘Add to group’.
      </p>
    </EmptyStates>
  );
};
