import { hasFeature } from "helpers/general";
import { FEATURES } from "constants/Features";
import { getFeaturesListFromLocalStorage } from "helpers/storage";

export const useHasFeature = (feature: keyof typeof FEATURES): boolean => {
  const featuresList = getFeaturesListFromLocalStorage()?.split(",") ?? [];
  const isHasFeature: boolean = hasFeature(FEATURES[feature], featuresList);

  // if (__DEV__) {
  //   return true;
  // }

  return isHasFeature;
};
