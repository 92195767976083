import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesOffspringTab: FC = () => {
  return (
    <EmptyStates animation={"genealogy"} title="No offspring found.">
      <p>
        When you log a birth against an animal their offspring will be linked here.
        <br />
        Open the mobile app to ‘Log a birth’ against a DAM.
      </p>
    </EmptyStates>
  );
};
