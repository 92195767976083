// React
import React from "react";
import { useHistory } from "react-router";
// Components
import { Icon } from "components";
// Resources
import styles from "./BackButton.module.scss";

interface Props {
  style?: Record<string, unknown>;
}

export const BackButton: React.FC<Props> = ({ style }) => {
  const history = useHistory();

  return (
    <button
      data-testid="back-button"
      className={styles.back_button}
      onClick={(): void => history.goBack()}
      style={style}
      type="button"
    >
      <Icon name="chevron" rotate={180} size="atom" />
    </button>
  );
};
