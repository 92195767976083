import gql from "graphql-tag";

export const PAGE_INFO_FRAGMENT = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const DELIVERY_LOCATION_FRAGMENT = gql`
  fragment DeliveryLocation on DeliveryLocationType {
    details {
      name
      address
      email
      postcode
    }
    address {
      id
      name
    }
    contact {
      id
    }
    partner {
      id
      addresses {
        id
        name
      }
    }
  }
`;

export const DELIVERY_FRAGMENT = gql`
  fragment Delivery on DeliveryType {
    id
    name
    status
    animalCount
    direction
    sentAt
    arrivedAt
    createdAt
    updatedAt
    actualSources {
      contact {
        id
        name
      }
    }
    animalTypes {
      id
      name
    }
    reason {
      id
      title
    }
    destinationSummary {
      locations {
        ...DeliveryLocation
      }
    }
    sourceSummary {
      locations {
        ...DeliveryLocation
      }
    }
    defaultDestination {
      ...DeliveryLocation
    }
    defaultSource {
      ...DeliveryLocation
    }
  }
  ${DELIVERY_LOCATION_FRAGMENT}
`;
