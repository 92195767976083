// React
import React from "react";
// Utils
import { handleEnterPress, handleErrorCheck, validationSchemaConfirmDelete } from "helpers/general";
// Components
import FormBuilder from "components/Common/FormBuilder";
import { FieldText, Modal, Spacer, Text } from "components";

interface Props {
  errorMessage?: string;
  isLoading?: boolean;
  showModal: boolean;
  onClose: () => void;
  onSubmit: () => void;
  entityName: string;
  subtitle?: string;
}

export const ConfirmDeletionModal: React.FC<Props> = ({
  errorMessage,
  isLoading,
  showModal,
  onClose,
  entityName,
  subtitle,
  onSubmit,
}) => (
  <FormBuilder
    validateOnChange={false}
    initialValues={{
      confirmation: "",
    }}
    onSubmit={onSubmit}
    validationSchema={validationSchemaConfirmDelete}
  >
    {({ values, errors, handleChange, handleSubmit }): React.ReactNode => (
      <Modal
        actions={{
          primary: {
            disabled: isLoading,
            "data-testid": "1",
            requesting: isLoading,
            caption: "Confirm",
            onClick: handleSubmit,
          },
          secondary: {
            caption: "Cancel",
            colour: "grey",
            variant: "hollow",
            onClick: onClose,
          },
        }}
        active={showModal}
        handleClose={onClose}
        title={`Delete ${entityName}?`}
      >
        <FieldText
          error={handleErrorCheck(errors.confirmation)}
          helper={subtitle || `Type 'DELETE' to confirm removal`}
          inputProps={{
            name: "confirmation",
            defaultValue: values.confirmation,
            autoFocus: true,
            onChange: handleChange,
            onKeyPress: (e): void => handleEnterPress(e, handleSubmit),
          }}
        />

        {Boolean(errorMessage) ? (
          <>
            <Spacer baselineHeight={2} />

            <Text smallest warning>
              {errorMessage}
            </Text>
          </>
        ) : null}
      </Modal>
    )}
  </FormBuilder>
);
