import { ChangeEvent, CSSProperties, ReactNode, useReducer, useContext, useEffect, useMemo, useState, FC } from "react";
// Libraries
import cn from "classnames";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Column } from "react-table";
// API
import { ANIMAL_FILTERS } from "api/MyLivestock/Animal/queries";
import { UPDATE_ANIMAL_FILTER, DELETE_ANIMAL_FILTER } from "api/MyLivestock/Animal/mutations";
import {
  AnimalFilters,
  AnimalFiltersVariables,
  AnimalFilters_animalFilters,
} from "api/MyLivestock/Animal/types/AnimalFilters";
import { UpdateAnimalFilter, UpdateAnimalFilterVariables } from "api/MyLivestock/Animal/types/UpdateAnimalFilter";
import { DeleteAnimalFilter, DeleteAnimalFilterVariables } from "api/MyLivestock/Animal/types/DeleteAnimalFilter";
import { FilterFormData } from "components/MyLivestock/Animals/AnimalFiltersBlock/CreateEditFilterForm";
// Utils
import { CommonContext } from "config/commonProvider";
import { ANIMAL_FILTER_KEYS, ANIMAL_FILTER_VALUES } from "constants/Animals";
import { FIELD_VIEW, EDIT_FIELD } from "constants/Routes";
import { getBUFromLocalStorage } from "helpers/storage";
import { useStateSpecies } from "hooks";
// Components
import { CreateEditFilterForm } from "components/MyLivestock";
import { getActionsColumn } from "components/Common/Table";
import { EllipsisMenu, Icon, InputSelect, Modal, Table } from "components";
import { AnimalsFilterProps } from "pages/MyLivestock/Animals/AnimalsFilter";
// Resources
import styles from "./styles.module.scss";

const computeSex = (isMale: boolean): string => {
  return isMale ? ANIMAL_FILTER_VALUES.SEX.M : ANIMAL_FILTER_VALUES.SEX.F;
};

const computeIsBcmsSynced = (isRegulatorySynced: boolean): string => {
  return isRegulatorySynced
    ? ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.SYNCED
    : ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.NOT_SYNCED;
};

const computeInWithdrawal = (inWithdrawal: boolean): string => {
  return inWithdrawal ? ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.DRAWAL : ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.NOT_DRAWAL;
};

const computePregnancyStatus = (status: boolean | null): string => {
  if (status === true) return ANIMAL_FILTER_VALUES.IS_PREGNANT.PREGNANT;
  if (status === false) return ANIMAL_FILTER_VALUES.IS_PREGNANT.NOT_PREGNANT;

  return "";
};

const computeFilterValues = (
  filterData: AnimalFilters_animalFilters,
  excludedFields: AnimalsFilterProps["excludedFields"] = {},
): ComputeFilterValues => {
  const animalTypeId: string | null = filterData?.animalType?.id || null;
  const breedsIds: string[] = filterData?.breeds.map((item) => item.id) || null;
  const sex: string | null = filterData.isMale !== null ? computeSex(filterData.isMale) : null;
  const sexClassifications: string[] | null = filterData.sexClassifications.map((item) => item.slug) || null;
  const ageFrom: string | null = filterData.ageFrom !== null ? `${filterData.ageFrom}` : null;
  const ageTo: string | null = filterData.ageTo !== null ? `${filterData.ageTo}` : null;
  const minCurrentEstWeight: string | null =
    filterData.currentWeightFrom !== null ? `${filterData.currentWeightFrom}` : null;
  const maxCurrentEstWeight: string | null =
    filterData.currentWeightTo !== null ? `${filterData.currentWeightTo}` : null;
  const minWeight: string | null = filterData.lastWeightFrom !== null ? `${filterData.lastWeightFrom}` : null;
  const maxWeigth: string | null = filterData.lastWeightTo !== null ? `${filterData.lastWeightTo}` : null;
  const daysSinceLastWeightFrom: string | null =
    filterData.daysSinceLastWeightFrom !== null ? `${filterData.daysSinceLastWeightFrom}` : null;
  const daysSinceLastWeightTo: string | null =
    filterData.daysSinceLastWeightTo !== null ? `${filterData.daysSinceLastWeightTo}` : null;
  const growthRateFrom: string | null = filterData.growthRateFrom !== null ? `${filterData.growthRateFrom}` : null;
  const growthRateTo: string | null = filterData.growthRateTo !== null ? `${filterData.growthRateTo}` : null;
  const deliveryFrom: Date | null = filterData.deliveryFrom ? new Date(filterData.deliveryFrom) : null;
  const deliveryTo: Date | null = filterData.deliveryTo ? new Date(filterData.deliveryTo) : null;
  const groupsIds: string[] = filterData?.groups.map((item) => item.id) || null;
  const fieldsIds: string[] = filterData?.fields.map((item) => item.id) || null;
  const lastSyncedDateFrom: Date | null = filterData.lastRegulatorySyncedDateFrom
    ? new Date(filterData.lastRegulatorySyncedDateFrom)
    : null;
  const lastSyncedDateTo: Date | null = filterData.lastRegulatorySyncedDateTo
    ? new Date(filterData.lastRegulatorySyncedDateTo)
    : null;
  const isRegulatorySynced: string | null =
    filterData.isRegulatorySynced !== null ? computeIsBcmsSynced(filterData.isRegulatorySynced) : null;
  const inWithdrawal: string | null =
    filterData.inWithdrawal !== null ? computeInWithdrawal(filterData.inWithdrawal) : null;
  const minDob: Date | null = filterData.dobFrom !== null ? new Date(filterData.dobFrom) : null;
  const maxDob: Date | null = filterData.dobTo !== null ? new Date(filterData.dobTo) : null;
  const minDateMovedToFarm: Date | null = filterData.dofFrom !== null ? new Date(filterData.dofFrom) : null;
  const maxDateMovedToFarm: Date | null = filterData.dofTo !== null ? new Date(filterData.dofTo) : null;
  const pregnancyStatus: string = computePregnancyStatus(filterData.isPregnant);
  const pregnancyDueDateFrom: Date | null =
    filterData.pregnancyDueDateFrom !== null ? new Date(filterData.pregnancyDueDateFrom) : null;
  const pregnancyDueDateTo: Date | null =
    filterData.pregnancyDueDateTo !== null ? new Date(filterData.pregnancyDueDateTo) : null;

  const computedFilters = {
    [ANIMAL_FILTER_KEYS.ANIMAL_TYPE_ID]: animalTypeId,
    [ANIMAL_FILTER_KEYS.BREEDS]: breedsIds,
    [ANIMAL_FILTER_KEYS.SEX]: sex,
    [ANIMAL_FILTER_KEYS.SEX_CLASSIFICATIONS]: sexClassifications,
    [ANIMAL_FILTER_KEYS.MIN_AGE]: ageFrom,
    [ANIMAL_FILTER_KEYS.MAX_AGE]: ageTo,
    [ANIMAL_FILTER_KEYS.MIN_CURRENT_EST_WEIGHT]: minCurrentEstWeight,
    [ANIMAL_FILTER_KEYS.MAX_CURRENT_EST_WEIGHT]: maxCurrentEstWeight,
    [ANIMAL_FILTER_KEYS.MIN_WEIGHT]: minWeight,
    [ANIMAL_FILTER_KEYS.MAX_WEIGHT]: maxWeigth,
    [ANIMAL_FILTER_KEYS.DAYS_SINCE_LAST_WEIGHT_FROM]: daysSinceLastWeightFrom,
    [ANIMAL_FILTER_KEYS.DAYS_SINCE_LAST_WEIGHT_TO]: daysSinceLastWeightTo,
    [ANIMAL_FILTER_KEYS.GROWTH_RATE_FROM]: growthRateFrom,
    [ANIMAL_FILTER_KEYS.GROWTH_RATE_TO]: growthRateTo,
    [ANIMAL_FILTER_KEYS.DELIVERY_DATE_FROM]: deliveryFrom,
    [ANIMAL_FILTER_KEYS.DELIVERY_DATE_TO]: deliveryTo,
    [ANIMAL_FILTER_KEYS.GROUPS]: groupsIds,
    [ANIMAL_FILTER_KEYS.FIELDS]: fieldsIds,
    [ANIMAL_FILTER_KEYS.LAST_SYNCED_DATE_FROM]: lastSyncedDateFrom,
    [ANIMAL_FILTER_KEYS.LAST_SYNCED_DATE_TO]: lastSyncedDateTo,
    [ANIMAL_FILTER_KEYS.IS_BCMS_SYNCED]: isRegulatorySynced,
    [ANIMAL_FILTER_KEYS.IS_WITHDRAWAL]: inWithdrawal,
    [ANIMAL_FILTER_KEYS.MIN_DOB]: minDob,
    [ANIMAL_FILTER_KEYS.MAX_DOB]: maxDob,
    [ANIMAL_FILTER_KEYS.MIN_DATE_MOVED_TO_FARM]: minDateMovedToFarm,
    [ANIMAL_FILTER_KEYS.MAX_DATE_MOVED_TO_FARM]: maxDateMovedToFarm,
    [ANIMAL_FILTER_KEYS.PREGNANCY_STATUS]: pregnancyStatus,
    [ANIMAL_FILTER_KEYS.PREGNANCY_DUE_FROM]: pregnancyDueDateFrom,
    [ANIMAL_FILTER_KEYS.PREGNANCY_DUE_TO]: pregnancyDueDateTo,
  };

  Object.entries(excludedFields).forEach(([key, value]) => {
    if (value) delete computedFilters[key];
  });

  return computedFilters;
};

interface ComputeFilterValues {
  [x: string]: string | string[] | null | Date;
}

type State = {
  template: string;
  selectOpenState: boolean;
  filterEditID: string;
  isModalVisible: boolean;
};

type Action =
  | { type: "setTemplate"; payload: string }
  | { type: "setSelectOpenState"; payload: boolean }
  | { type: "setFilterEditID"; payload: string }
  | { type: "setModalVisibility"; payload: boolean };

const initialState = {
  template: "",
  filterEditID: "",
  selectOpenState: false,
  isModalVisible: false,
};

const reducer = (state: Readonly<State>, action: Action): State => {
  switch (action.type) {
    case "setTemplate":
      return { ...state, template: action.payload };
    case "setSelectOpenState":
      return { ...state, selectOpenState: action.payload };
    case "setFilterEditID":
      return { ...state, filterEditID: action.payload };
    case "setModalVisibility":
      return { ...state, isModalVisible: action.payload };
    default:
      return state;
  }
};

interface SavedFiltersSelectLocationState {
  animalsFilterTemplateId?: string;
}

interface SavedFiltersSelectProps {
  excludedFields: AnimalsFilterProps["excludedFields"];
}

export const SavedFiltersSelect: FC<SavedFiltersSelectProps> = ({ excludedFields = {} }) => {
  const isFieldView = useRouteMatch(`${FIELD_VIEW}/:fieldId`) || useRouteMatch(`${EDIT_FIELD}`);
  const { activeSpecies } = useStateSpecies();

  const {
    mainCommonState: { animalsFilterTemplateId },
    changeAnimalsFilter,
    resetAnimalsFilter,
    showNotification,
    setAnimalsFilterTemplateId,
  } = useContext(CommonContext);

  const { state } = useLocation<SavedFiltersSelectLocationState | undefined>();
  const history = useHistory<SavedFiltersSelectLocationState>();
  useEffect(() => {
    if (state?.animalsFilterTemplateId) {
      setAnimalsFilterTemplateId(state.animalsFilterTemplateId);
      // We clear the state when filters are changed so that the filter set doesn't reapply when a user:
      // 1. navigates to another page and returns to this page using browser "back" button
      // 2. refreshes the page
      history.replace({
        state: {
          ...history.location.state,
          animalsFilterTemplateId: undefined,
        },
      });
    }
  }, []);

  const [{ template, filterEditID, selectOpenState, isModalVisible }, dispatch] = useReducer(reducer, initialState);
  const [showDeleteModal, toggleDeleteModal] = useState<string | null>(null);

  const businessUnitId: number = getBUFromLocalStorage();

  const { data } = useQuery<AnimalFilters, AnimalFiltersVariables>(ANIMAL_FILTERS, {
    variables: {
      businessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const [updateAnimalFilter, { loading }] = useMutation<UpdateAnimalFilter, UpdateAnimalFilterVariables>(
    UPDATE_ANIMAL_FILTER,
  );
  const [deleteAnimalFilter] = useMutation<DeleteAnimalFilter, DeleteAnimalFilterVariables>(DELETE_ANIMAL_FILTER);

  const filterTemplates = (data?.animalFilters || []) as AnimalFilters_animalFilters[];
  const filterTemplatesForCurrentSpecies = useMemo(
    () =>
      activeSpecies && !isFieldView
        ? filterTemplates.filter((template) => template.animalType?.id === activeSpecies.id)
        : filterTemplates,
    [activeSpecies, isFieldView, filterTemplates],
  );
  const filterTemplatesOptions = filterTemplatesForCurrentSpecies.map((filterTemplate) => ({
    key: filterTemplate.id,
    label:
      activeSpecies && isFieldView && filterTemplate.animalType?.image ? (
        <>
          <Icon
            className={styles.saved_filters_select_option__species_icon}
            colourOverride={filterTemplate.animalType.colour}
            src={filterTemplate.animalType.image}
          />

          {filterTemplate.name}
        </>
      ) : (
        filterTemplate.name
      ),
    value: filterTemplate.id,
  }));

  const showEditTemplatesMenu = !!filterTemplates.length;
  const editedTemplate = filterTemplates.find((filter) => filter.id === filterEditID);

  useEffect(() => {
    if (!animalsFilterTemplateId) {
      dispatch({ type: "setTemplate", payload: "" });
    }
  }, [animalsFilterTemplateId]);

  useEffect(() => {
    const filterData: AnimalFilters_animalFilters | undefined = filterTemplates.find(
      (item) => item.id === animalsFilterTemplateId,
    );
    const isNewFilterId: boolean = template !== animalsFilterTemplateId;

    if (animalsFilterTemplateId && filterData && isNewFilterId) {
      const filterValues: ComputeFilterValues = computeFilterValues(filterData, excludedFields);

      changeAnimalsFilter(filterValues);
      dispatch({ type: "setTemplate", payload: animalsFilterTemplateId });
    }
  }, [animalsFilterTemplateId, template, filterTemplates, changeAnimalsFilter]);

  const deleteFilterTemplate = async (id: string): Promise<void> => {
    try {
      const { data } = await deleteAnimalFilter({
        variables: {
          input: {
            id: +id,
            businessUnit: businessUnitId,
          },
        },
        update(cache, result) {
          const { errors } = { ...result };

          if (!errors) {
            const cacheData = cache.readQuery<AnimalFilters, AnimalFiltersVariables>({
              query: ANIMAL_FILTERS,
              variables: { businessUnitId },
            });

            if (cacheData?.animalFilters) {
              const updatedFilters = cacheData.animalFilters.filter((item) => item?.id !== id);

              cache.writeQuery({
                query: ANIMAL_FILTERS,
                variables: {
                  businessUnitId,
                },
                data: { animalFilters: updatedFilters },
              });
            }
          }
        },
      });

      const errors = data?.deleteAnimalFilter?.errors;

      if (errors) {
        showNotification({
          variant: "error",
          message: "Error deleting filter",
        });
      } else {
        if (id === animalsFilterTemplateId) {
          resetAnimalsFilter();
          setAnimalsFilterTemplateId("");
        }

        showNotification({
          message: "Filter successfully deleted",
        });
      }
      toggleDeleteModal(null);
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error deleting filter",
      });
    }
  };

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const templateId: string = event.target.value;
    const filterData: AnimalFilters_animalFilters | undefined = filterTemplates.find((item) => item.id === templateId);

    dispatch({ type: "setTemplate", payload: templateId });

    if (filterData) {
      const filterValues: ComputeFilterValues = computeFilterValues(filterData, excludedFields);

      changeAnimalsFilter(filterValues);
      setAnimalsFilterTemplateId(templateId);
    } else {
      resetAnimalsFilter();
    }
  };

  const handleModalOpen = (): void => {
    dispatch({ type: "setSelectOpenState", payload: false });
    dispatch({ type: "setModalVisibility", payload: true });
  };

  const handleSelectOpen = (): void => {
    dispatch({ type: "setSelectOpenState", payload: true });
  };

  const handleSelectClose = (): void => {
    dispatch({ type: "setSelectOpenState", payload: false });
  };

  const handleModalClose = (): void => {
    dispatch({ type: "setModalVisibility", payload: false });
    dispatch({ type: "setFilterEditID", payload: "" });
  };

  const handleEditTemplate = (id: string): void => {
    dispatch({ type: "setFilterEditID", payload: id });
  };

  const handleDeleteClick = (filter: string): void => {
    toggleDeleteModal(filter);
  };

  const handleDeleteCancelClick = (): void => {
    toggleDeleteModal(null);
  };

  const handleDeleteTemplate = (): void => {
    if (!showDeleteModal) return;

    deleteFilterTemplate(showDeleteModal);
  };

  const handleFormCancel = (): void => {
    dispatch({ type: "setFilterEditID", payload: "" });
  };

  const handleRenameTemplate = async ({
    id,
    name,
    isHomepageAlert,
    homepageAlertColor,
  }: FilterFormData): Promise<void> => {
    if (!id) {
      return;
    }

    try {
      const newValues = {
        name,
        isAlert: isHomepageAlert,
        ...(isHomepageAlert ? { color: homepageAlertColor } : {}),
      };

      const { data } = await updateAnimalFilter({
        variables: {
          input: {
            id,
            businessUnit: businessUnitId,
            ...newValues,
          },
        },
        update(cache, result) {
          const { errors } = { ...result.data?.updateAnimalFilter };

          // we only want to update apollo cache if we actually managed to save the change in BE
          if (!errors) {
            const cacheData = cache.readQuery<AnimalFilters, AnimalFiltersVariables>({
              query: ANIMAL_FILTERS,
              variables: { businessUnitId },
            });

            if (cacheData?.animalFilters) {
              const updatedFilters = cacheData.animalFilters.map((item) => {
                if (item?.id === `${id}`) {
                  return {
                    ...item,
                    ...newValues,
                  };
                }

                return item;
              });

              cache.writeQuery({
                query: ANIMAL_FILTERS,
                variables: {
                  businessUnitId,
                },
                data: { animalFilters: updatedFilters },
              });
            }
          }
        },
      });

      const errors = data?.updateAnimalFilter?.errors;

      if (errors) {
        showNotification({
          variant: "error",
          message: errors[0]?.message || "Error updating filter",
        });
      } else {
        showNotification({
          message: "Filter successfully updated",
        });
        handleFormCancel();
      }
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error updating filter",
      });
    }
  };

  const BottomMenuItem: FC<{ inheritedStyles: CSSProperties }> = ({ inheritedStyles }) => {
    return (
      <div
        //@ts-expect-error
        className={cn(inheritedStyles, styles.bottomMenu)}
        onClick={handleModalOpen}
        onKeyPress={handleModalOpen}
        tabIndex={0}
      >
        <span>Manage saved filters</span>
        <Icon name="pencil" size="tiny" colour="grey_dark" />
      </div>
    );
  };

  const tableId = "animalSavedFilters";
  //@ts-expect-error
  const columns: Column<AnimalFilters_animalFilters>[] = useMemo(
    () => [
      {
        id: "name",
        Header: "Filter name",
        accessor: "name",
        disableSortBy: true,
      },
      {
        ...getActionsColumn<AnimalFilters_animalFilters>(),
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original } }): ReactNode => (
          <EllipsisMenu
            actions={[
              { caption: "Edit", onClick: (): void => handleEditTemplate(original.id) },
              { caption: "Delete", onClick: (): void => handleDeleteClick(original.id) },
            ]}
          />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <InputSelect
        components={{
          BottomMenuItem: showEditTemplatesMenu ? BottomMenuItem : null,
        }}
        open={selectOpenState}
        onClose={handleSelectClose}
        onOpen={handleSelectOpen}
        name="template"
        isClearable={true}
        value={template}
        options={filterTemplatesOptions}
        onChange={handleSelectChange}
        placeholder="Saved filters"
      />
      <Modal
        showCloseButton
        active={isModalVisible}
        handleClose={handleModalClose}
        isChildrenGutterManual
        title={`Manage ${filterEditID ? `${editedTemplate?.name}` : "saved filters"}`}
      >
        {filterEditID ? (
          <CreateEditFilterForm
            filterTemplate={editedTemplate}
            onCancel={handleFormCancel}
            onSubmit={handleRenameTemplate}
            isLoading={loading}
          />
        ) : (
          <Table<AnimalFilters_animalFilters>
            columns={columns}
            data={filterTemplatesForCurrentSpecies}
            tableId={tableId}
          />
        )}
      </Modal>
      <Modal
        title="Are you sure you want to delete this filter?"
        active={!!showDeleteModal}
        actions={{
          primary: { caption: "Confirm", onClick: handleDeleteTemplate },
          secondary: { caption: "Cancel", onClick: handleDeleteCancelClick },
        }}
      />
    </>
  );
};
