import { Header, PageContent, PageHeader, Spacer } from "components";
import { TRANSACTIONS, FINANCIAL_TRANSACTIONS } from "constants/Routes";
import { FinancialTransactionsTable } from "financial/components/FinancialTransactionsTable";
import { useHistory } from "react-router-dom";

export const FinancialTransactions = () => {
  const history = useHistory();

  return (
    <>
      <PageHeader>
        <Header title="Financial Transactions" subTitle="View and manage your financial transactions." />
      </PageHeader>

      <PageContent
        floatingIconTextButton={{
          iconName: "plus",
          onClick: () => {
            history.push(`${FINANCIAL_TRANSACTIONS}${TRANSACTIONS}`);
          },
          text: "Add Transaction",
        }}
      >
        <Spacer baselineHeight={3} />
        <FinancialTransactionsTable />
      </PageContent>
    </>
  );
};
