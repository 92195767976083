import { FC } from "react";
// Libraries
import cn from "classnames";
// Resources
import styles from "./Text.module.scss";

enum TextColours {
  Black = "black",
  Blue = "blue",
  Green = "green",
  Green900 = "green900",
  Grey = "grey",
  Grey800 = "grey800",
  Red = "red",
  Red900 = "red900",
  Yellow = "yellow",
  White = "white",
  Warning = "orange900",
}

interface TextProps {
  className?: string;
  colour?: `${TextColours}`;
  id?: string;
  isEllipsis?: boolean;
  isSingleLine?: boolean;
  secondary?: boolean;
  smaller?: boolean;
  smallest?: boolean;
  warning?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties | undefined;
}

export const Text: FC<TextProps> = ({
  children,
  className,
  colour = "black",
  id,
  isEllipsis,
  isSingleLine,
  secondary,
  smaller,
  smallest,
  warning,
  style,
}) => (
  <span
    className={cn(
      styles.text,
      {
        [styles[`text--colour__${colour}`]]: colour,
        [styles["text--ellipsis"]]: isEllipsis,
        [styles["text--single_line"]]: isSingleLine,
        [styles["text--secondary"]]: secondary,
        [styles["text--medium"]]: !smaller && !smallest,
        [styles["text--smaller"]]: smaller,
        [styles["text--smallest"]]: smallest,
        [styles["text--warning"]]: warning,
      },
      className,
    )}
    id={id}
    style={style}
  >
    {children}
  </span>
);
