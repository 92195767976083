import { CSSProperties, SyntheticEvent, VFC } from "react";
// Libraries
import SVG from "react-inlinesvg";
// Resources
import styles from "./ImageButton.module.scss";

interface ImageButtonBase {
  backgroundColour?: string;
  fallbackText: string;
  onClick?: (event?: SyntheticEvent<HTMLButtonElement>) => void;
}

interface ImageButtonWithIcon extends ImageButtonBase {
  icon: string;
  image?: never;
}

interface ImageButtonWithImage extends ImageButtonBase {
  icon?: never;
  // `image` is optional because the source could be a query that is still loading
  image?: string;
}

type ImageButtonProps = ImageButtonWithIcon | ImageButtonWithImage;

// BRD-3696
// Setting the background colour here because the Tailwind CSS
// resets overwrote the background colour declared in ImageButton.module.scss.
// The longterm plan is to rebuild this component in Tailwind. This
// is just a quick fix to ensure the release isn't blocked.
const BREEDR_BLUE = "#23354b";

export const ImageButton: VFC<ImageButtonProps> = ({ backgroundColour, fallbackText, icon, image, onClick }) => {
  const buttonStyle: CSSProperties = {};
  buttonStyle.backgroundColor = backgroundColour || BREEDR_BLUE;
  if (image) buttonStyle.backgroundImage = `url(${image})`;

  const firstLetter = fallbackText.charAt(0);

  const canShowFallbackText = !icon && !image;

  return (
    <button className={styles.image_button} onClick={onClick} style={buttonStyle} type="button">
      {icon ? <SVG className={styles.image_button__icon} src={icon} /> : null}

      {canShowFallbackText ? <span className={styles.image_button__caption}>{firstLetter}</span> : null}
    </button>
  );
};
