import { FC, useContext, useState } from "react";
import { Header, PageHeader, PageContent, Spacer, Breadcrumbs, Button, Flex, ConfirmDeletionModal } from "components";
// import { AnimalsListCursorPaginated } from "tags/components/AnimalsListCursorPaginated";
import { useHistory, useParams } from "react-router-dom";
import { TAGS } from "constants/Routes";
import { MergeAnimalTagsInput, useMergeAnimalTagsMutation } from "generated/graphql";
import {
  AnimalFragment,
  AnimalTagsQuery,
  AnimalTagsQueryVariables,
  useAnimalTagQuery,
  useDeleteTagMutation,
} from "generated/graphql";
import { useGetCurrentBusinessUnit, useStateSpecies } from "hooks";
import { ANIMAL_TAGS } from "tags/api/queries";
import { CommonContext } from "config/commonProvider";
import { CreateEditTagModal } from "tags/components/CreateLocalTagModal";
import { AnimalsList } from "animals/AnimalsList";
import { TABLE_IDS } from "constants/Interface";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { GET_MY_ANIMALS_LIST } from "api/MyLivestock/Animal/queries";
import { Tag } from "tags/components/Tag";
import { EmptyStatesTagsPage } from "components/EmptyStates/TagsPage";

const getTagsQuery = (variables: AnimalTagsQueryVariables) => ({
  query: ANIMAL_TAGS,
  variables,
});

const tableId = TABLE_IDS["ANIMAL_LIST_TAGS"];

export const TagView: FC = () => {
  const [mergeAnimalTags] = useMergeAnimalTagsMutation();
  const { activeSpecies } = useStateSpecies();
  const { tagId } = useParams<LivestockTagPageParams>();
  const { getTableOptions } = useContext(TableOptionsContext);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { id } = useGetCurrentBusinessUnit();
  const { showNotification } = useContext(CommonContext);
  const { data, loading } = useAnimalTagQuery({
    variables: { buId: Number(id), tagIds: [tagId] },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });
  const tag = data?.animalTags?.edges[0];
  const history = useHistory();
  const [showAddAnimalsList, setShowAddAnimalsList] = useState(false);
  const [deleteTag] = useDeleteTagMutation();
  const tableOptions = getTableOptions<AnimalFragment>(tableId);
  const selectedRowIds = tableOptions?.selectedRowsIds;

  if (data == null || loading) {
    return null;
  }

  const onDelete = async () => {
    if (!tagId) {
      return;
    }
    await deleteTag({
      variables: { input: { tag: tagId } },
      refetchQueries: [{ query: ANIMAL_TAGS, variables: { buId: id } }],
      update: (cache, { data: deleteLocalTagData }, { variables }) => {
        if (deleteLocalTagData?.deleteTag?.errors == null) {
          const tagId = variables?.input.tag;

          if (tagId && id) {
            const query = getTagsQuery({ buId: Number(id) });
            const data = cache.readQuery<AnimalTagsQuery>(query);

            if (!data) {
              throw new Error("No data reading tagsQuery from appolo-cache");
            }

            const edges = data?.animalTags?.edges.filter((tag) => tag?.node?.id !== tagId) ?? [];

            cache.writeQuery({
              ...query,
              data: { animalTags: { __typename: "AnimalTagOverviewConnection", edges } },
            });

            showNotification({
              message: "Tag deleted successfully",
            });
            history.goBack();
          } else {
            showNotification({
              variant: "error",
              message: "There was an issue deleting the tag",
            });
          }
        }
      },
    });
  };

  const assignRemoveTagToAnimal = async (add = true) => {
    if (!tagId || !selectedRowIds) {
      return;
    }
    try {
      const input: MergeAnimalTagsInput = {
        animals: Array.from(selectedRowIds),
        tagValues: [{ tag: tagId, isPresent: add }],
      };

      const previousVariables = {
        businessUnitId: id,
        animalTypeIds: activeSpecies?.id ? [Number(activeSpecies?.id)] : undefined,
      };

      await mergeAnimalTags({
        variables: { input },
        refetchQueries: [
          { query: ANIMAL_TAGS, variables: { buId: id } },
          { query: GET_MY_ANIMALS_LIST, variables: { ...previousVariables } },
        ],
        update: (_, { data: mergeAnimalTagsData }) => {
          if (mergeAnimalTagsData?.mergeAnimalTags?.errors == null) {
            showNotification({
              message: "Tag added successfully",
            });

            setShowAddAnimalsList(false);
          } else {
            const errorMessages = mergeAnimalTagsData?.mergeAnimalTags?.errors.reduce<string[]>(
              (accumulator, error) => {
                if (error?.message) accumulator.push(error.message);
                return accumulator;
              },
              [],
            );

            showNotification({
              variant: "error",
              message: errorMessages.join("; "),
            });
          }
        },
      });
    } catch (error) {
      showNotification({
        variant: "error",
        message: "Error adding tag",
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <ConfirmDeletionModal
        showModal={showDeleteModal}
        onClose={handleCloseDeleteModal}
        onSubmit={onDelete}
        entityName={`${tag?.node?.name} tag`}
      />
      <CreateEditTagModal handleShowModal={handleCloseModal} showModal={showModal} tagId={tagId} />
      <PageHeader>
        <Breadcrumbs
          rootPaths={[
            {
              path: "Tags",
              route: TAGS,
            },
          ]}
          currentPath="Tag Information"
        />

        <Header
          backButton
          title={tag?.node?.name || "—"}
          titleIcon={
            <div className="flex items-end">
              <Tag
                key={tag?.node?.id}
                tag={{
                  name: tag?.node?.name || "—",
                  color: tag?.node?.color || "#eee",
                  tagId: tag?.node?.id || undefined,
                  ownership: tag?.node?.ownership,
                }}
              />
            </div>
          }
        />
        <Flex container containerJustifyContent="flex-end">
          {tag?.node?.ownership == "BUSINESS_UNIT" && !showAddAnimalsList ? (
            <>
              <Flex item itemGutter>
                <Button caption="Manage tag" onClick={() => setShowAddAnimalsList(true)} />
              </Flex>
              <Flex item itemGutter>
                <Button caption="Edit Tag" onClick={() => setShowModal(true)} />
              </Flex>
              <Flex item itemGutter>
                <Button caption="Delete Tag" onClick={() => setShowDeleteModal(true)} />
              </Flex>
              <Spacer baselineHeight={1} />
            </>
          ) : (
            <>
              {showAddAnimalsList ? (
                <>
                  <Flex item itemGutter>
                    <Button variant="hollow" caption="Cancel" onClick={() => setShowAddAnimalsList(false)} />
                  </Flex>
                  <Flex item itemGutter>
                    <Button caption="Add Tag" onClick={() => assignRemoveTagToAnimal(true)} />
                  </Flex>
                </>
              ) : (
                <Flex item itemGutter>
                  <Button caption="Manage tag" onClick={() => setShowAddAnimalsList(true)} />
                </Flex>
              )}
            </>
          )}
          <Spacer baselineHeight={1} />
        </Flex>
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={1} />

        {showAddAnimalsList ? (
          <>
            <Spacer baselineHeight={4} />
            <AnimalsList
              tableFilterIdentifier="tagsList"
              excludedFilters={{ fieldId: true }}
              showAnimalActionButtons={false}
              showSelectColumn
              tableId={tableId}
              noResults={<EmptyStatesTagsPage />}
            />
          </>
        ) : (
          <AnimalsList
            tagId={tagId}
            tableFilterIdentifier="tagsList"
            excludedFilters={{ tagId: true }}
            fromPage="tags"
            showSelectColumn
            showAnimalActionButtons
            tableId={tableId}
            noResults={<EmptyStatesTagsPage />}
          />
        )}
      </PageContent>
    </>
  );
};
