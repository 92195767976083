import React from "react";
// Components
import { KeyValuePair, Modal, Spacer } from "components";
// Types
import { GetStockItem_stockItem } from "api/Medicine/types/GetStockItem";

interface MedicinePreviewModalProps {
  isActive: boolean;
  medicine: GetStockItem_stockItem | null;
  onClose: () => void;
}

export const MedicinePreviewModal: React.FC<MedicinePreviewModalProps> = ({ isActive, medicine, onClose }) => {
  const withdrawalPeriod = medicine?.withdrawal
    ?.map(
      (item) =>
        `${item?.animalType?.name} ${item?.withdrawalType ? `(${item?.withdrawalType})` : ""}: ${
          item?.withdrawalPeriodDays
        } days`,
    )
    .join(", ");

  return (
    <Modal
      actions={{
        primary: {
          caption: "Close",
          onClick: onClose,
        },
      }}
      active={isActive}
      size="medium"
      title={medicine?.name || "Medicine details"}
    >
      <KeyValuePair label="Manufacturer name" value={medicine?.manufacturerName} />
      <KeyValuePair label="Common name" value={medicine?.name} />
      <KeyValuePair label="Active ingredient" value={medicine?.activeIngredient} />
      <KeyValuePair label="Manufacturer" value={medicine?.manufacturer} />

      <Spacer baselineHeight={2} border="middle" />

      <KeyValuePair label="For" value={medicine?.species.map((item) => item.name).join(", ")} />
      <KeyValuePair label="Via" value={medicine?.applicationMethod} />
      <KeyValuePair label="Withdrawal period" value={withdrawalPeriod} />
    </Modal>
  );
};
