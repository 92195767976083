import React, { CSSProperties, memo, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import useSyncScroll from "react-use-sync-scroll";
// Libraries
import cn from "classnames";
import { isString } from "lodash";
import { Column, TableOptions, useFlexLayout, useSortBy, useTable, usePagination, Row } from "react-table";
import { useSticky } from "react-table-sticky";
// Utils
import { TableOptionsContext } from "config/tableOptionsProvider";
// Components
import { InputCheckbox } from "components";
import { TableHeaderSortIcon } from "./TableHeaderSortIcon";
import { TableHeaderCaptions } from "./TableHeaderCaptions";
import { TablePagination, TABLE_PAGINATION_DEFAULT_OPTIONS } from "./TablePagination";
// Resources
import styles from "./Table.module.scss";
import { EmptyStatesNoResults } from "components/EmptyStates/NoResults";
import { getTableSettingsFromLocalStorage } from "helpers/storage";

export const TEST_IDS = {
  BODY_CELL: "body_cell",
  BODY_CHECKBOX: "body_checkbox",
  BODY_ROW: "body_row",
  HEADER_CELL: "header_cell",
  HEADER_CHECKBOX: "header_checkbox",
};

export const sortExcludeFalsy = (rowA, rowB, columnAccessor, desc): number => {
  const aValue = rowA.values[columnAccessor];
  const bValue = rowB.values[columnAccessor];

  if (aValue === bValue) return 0;

  // default sort treats falsy values as 0 and we only want to show rows with actual values at the start
  // aValue is falsy so push it to end
  if (!aValue) return desc ? -1 : 1;
  // bValue is falsy so push it to end
  if (!bValue) return desc ? 1 : -1;

  return aValue > bValue ? 1 : -1;
};

/*
 * Helper to enable row selection
 */

interface GetRowSelectColumnOptions {
  isRowSelectAll?: boolean;
  tableId: string;
}

export const TABLE_ROW_SELECT_COLUMN_ID = "table__row_select";

// eslint-disable-next-line react/display-name
export const Checkbox: React.FC<{ tableId: string; id: string }> = memo(({ tableId, id, ...rest }) => {
  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);

  const tableOptions = getTableOptions(tableId);
  const selectedRowsIds = new Set(tableOptions?.selectedRowsIds || []);
  const tableData = tableOptions?.tableData || [];

  const checked = selectedRowsIds.has(id);

  const handleCheckboxChange = (): void => {
    if (checked) {
      selectedRowsIds.delete(id);
    } else {
      selectedRowsIds.add(id);
    }

    const selectedRows = tableData.filter((item) => selectedRowsIds.has(item["id"])) || [];

    setTableOptions({
      id: tableId,
      selectedRowsIds,
      selectedRows,
    });
  };

  return <InputCheckbox checked={checked} onChange={handleCheckboxChange} {...rest} />;
});

interface SelectAllCheckboxProps<T extends ObjectOf<T> = Record<string, never>> {
  tableId: string;
  data: T[];
}

const SelectAllCheckbox: React.FC<SelectAllCheckboxProps> = ({ tableId, data, ...rest }) => {
  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);

  const tableOptions = getTableOptions(tableId);
  const selectedRows = tableOptions?.selectedRows;
  const selectedRowsIds = tableOptions?.selectedRowsIds;

  // JC: extended this logic slightly to allow for selected animals that are not in the current filtered results
  // on animals table specifically
  const checked =
    !!selectedRows?.length && (data.length === selectedRows.length || data.length === selectedRowsIds?.size);

  const handleCheckboxChange = (): void => {
    if (checked) {
      setTableOptions({
        id: tableId,
        selectedRowsIds: null,
        selectedRows: [],
      });
    } else {
      const ids = new Set<string>(selectedRowsIds);

      data.forEach((item) => {
        ids.add(item["id"]);
      });

      setTableOptions({
        id: tableId,
        selectedRowsIds: ids,
        selectedRows: data,
      });
    }
  };

  return <InputCheckbox checked={checked} onChange={handleCheckboxChange} {...rest} />;
};

export const getRowSelectColumn = <T extends ObjectOf<T> = Record<string, never>>({
  isRowSelectAll,
  tableId,
}: //@ts-expect-error
GetRowSelectColumnOptions): Column<T> => ({
  Cell: function SelectColumn(props): React.ReactNode {
    const id = props.cell.row.original.id;

    return <Checkbox tableId={tableId} data-testid={TEST_IDS.BODY_CHECKBOX} id={id} />;
  },
  Header: function SelectAllCheckBox(props): React.ReactNode {
    const data = props.data;

    return isRowSelectAll ? (
      <SelectAllCheckbox tableId={tableId} data-testid={TEST_IDS.HEADER_CHECKBOX} data={data} />
    ) : null;
  },
  id: TABLE_ROW_SELECT_COLUMN_ID,
  minWidth: 40,
  width: 40,
});

/*
 * Types/helpers to enable actions column
 */

export const TABLE_ROW_ACTIONS_COLUMN_ID = "table__row_actions";

export const getActionsColumn = <T extends ObjectOf<T> = Record<string, never>>(isEditAnimal?: boolean): Column<T> => ({
  id: TABLE_ROW_ACTIONS_COLUMN_ID,
  Header: "",
  disableSortBy: true,
  minWidth: isEditAnimal ? 70 : 40,
  width: isEditAnimal ? 70 : 40,
});

/*
 * The table
 */

type PaginationOptions =
  | {
      pagination?: never;
      initialPageSize?: never;
    }
  | {
      pagination: true;
      initialPageSize?: typeof TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES[number];
    };

export type TableProps<T extends ObjectOf<T> = Record<string, never>> = {
  initialSortBy?: { desc?: boolean; id: string }[];
  isAnimals?: boolean;
  hiddenColumns?: Array<string>;
  selectedRowIds?: Set<string> | null;
  tableId: string;
  rowStyles?: (row: Row<T>) => CSSProperties;
} & TableOptions<T> &
  PaginationOptions;

export const Table = <T extends ObjectOf<T> = Record<string, never>>({
  rowStyles,
  columns,
  data,
  hiddenColumns,
  initialPageSize,
  initialSortBy = [],
  isAnimals,
  pagination,
  manualPagination,
  selectedRowIds,
  tableId,
  manualSortBy,
}: TableProps<T>): JSX.Element => {
  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const syncScrollRef = useRef([headerRef, bodyRef]);
  useSyncScroll(syncScrollRef, { horizontal: true, vertical: false });

  const defaultColumn = useMemo(
    () => ({
      minWidth: 200,
      width: 200,
    }),
    [],
  );

  const localStorageTableSettings = getTableSettingsFromLocalStorage(tableId);
  const localStoragePageSize = localStorageTableSettings?.pageSize;
  const tableOptions = getTableOptions(tableId);
  const tablePageSize = localStoragePageSize || tableOptions?.pageSize || initialPageSize;
  const isSelectedView = tableOptions?.isSelectedView;
  const mockedSelectedRows = (tableOptions?.mockedSelectedRows as T[]) || [];
  const memoMockedSelectedRows = useMemo(() => mockedSelectedRows, [isSelectedView, mockedSelectedRows.length]);
  const currentTableData = isSelectedView ? memoMockedSelectedRows : data;
  const defaultPageSize = pagination ? TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES[0] : currentTableData.length;
  const currentPageSize = initialPageSize ? tablePageSize : defaultPageSize;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize, sortBy },
    setHiddenColumns,
    gotoPage,
  } = useTable<T>(
    {
      columns,
      data: currentTableData,
      manualSortBy: manualSortBy || false,
      manualPagination: manualPagination || false,
      defaultColumn,
      initialState: {
        pageSize: currentPageSize,
        sortBy: tableOptions?.sortBy || initialSortBy,
      },
    },
    useFlexLayout,
    useSortBy,
    useSticky,
    usePagination,
  );

  const paginationRef = tableOptions?.paginationRef;
  const selectedRowsIds = tableOptions?.selectedRowsIds;
  const tableData = tableOptions?.tableData;
  const memoSetTableOptions = useCallback((data) => setTableOptions(data), []);

  useEffect(() => {
    memoSetTableOptions({
      id: tableId,
      sortBy,
    });
  }, [memoSetTableOptions, sortBy]);

  useEffect(() => {
    /* Chris 8 Feb 2021:
     *
     * Commented out because it was only updating tableData in context if the new data has more records than the
     * previous data. This was implemented in September by Alex as part of an old ticket to add the green
     * "View selected rows" button, so honestly I think this may cause an unforeseen bug in this feature
     *
     * I made this change today for this ticket: https://breedr.atlassian.net/browse/BRDRCL-4789
     *
    const shouldUpdate = tableData && data.length > tableData.length;

    if (!tableData || shouldUpdate) {
      memoSetTableOptions({
        id: tableId,
        tableData: data,
      });
    }
     */

    memoSetTableOptions({
      id: tableId,
      tableData: data,
    });
  }, [tableData, memoSetTableOptions, data, tableId]);

  useEffect(() => {
    if (selectedRowIds) {
      const selectedRows = tableData?.filter((item) => selectedRowIds.has(item["id"])) || [];

      memoSetTableOptions({
        id: tableId,
        selectedRowsIds: selectedRowIds,
        selectedRows: selectedRows,
      });
    }
  }, [tableId, memoSetTableOptions, tableData, selectedRowIds]);

  useEffect(() => {
    memoSetTableOptions({
      id: tableId,
      pageSize,
    });
  }, [tableId, memoSetTableOptions, pageSize]);

  useEffect(() => {
    if (hiddenColumns?.length) {
      setHiddenColumns(hiddenColumns);
    }
  }, [setHiddenColumns, hiddenColumns]);

  const { className: tableClassName, ...tableProps } = getTableProps();
  const { className: tableBodyClassName, ...tableBodyProps } = getTableBodyProps();

  const renderPagination = (): JSX.Element | null => {
    const paginationProps = {
      pageSize,
      pageIndex,
      canNextPage,
      canPreviousPage,
      nextPage,
      previousPage,
      setPageSize,
      gotoPage,
      pageCount,
      dataLength: currentTableData.length,
      tableId,
    };

    if (paginationRef) {
      return createPortal(<TablePagination {...paginationProps} />, paginationRef);
    }

    return null;
  };

  return (
    <div className={cn(styles.table, { [styles["table--animals"]]: isAnimals }, tableClassName)} {...tableProps}>
      {pagination ? renderPagination() : null}

      <div className={styles.table__header} ref={headerRef}>
        {headerGroups.map((headerGroup, headerGroupIndex) => {
          const {
            className: headerGroupClassName,
            key: headerGroupKey,
            ...headerGroupProps
          } = headerGroup.getHeaderGroupProps();

          const isSubHeaderGroup = headerGroupIndex > 0;

          return (
            <div
              className={cn(styles.table__header_row, headerGroupClassName, {
                [styles["table__header_row--subheader"]]: isSubHeaderGroup,
              })}
              key={headerGroupKey}
              {...headerGroupProps}
            >
              {headerGroup.headers.map((column) => {
                const {
                  className: columnHeaderClassName,
                  key: columnHeaderKey,
                  ...columnHeaderProps
                } = column.getHeaderProps(column.getSortByToggleProps());

                return (
                  <div
                    className={cn(styles.table__header_cell, columnHeaderClassName, {
                      [styles["table__header_cell--row_select"]]: column.id === TABLE_ROW_SELECT_COLUMN_ID,
                      [styles["table__header_cell--row_actions"]]: column.id === TABLE_ROW_ACTIONS_COLUMN_ID,
                      [styles["table__header_cell--sortable"]]: column.canSort,
                      [styles["table__header_cell--sorted"]]: column.isSorted,
                    })}
                    data-testid={TEST_IDS.HEADER_CELL}
                    key={columnHeaderKey}
                    {...columnHeaderProps}
                  >
                    {isString(column.Header) ? <TableHeaderCaptions title={column.Header} /> : column.render("Header")}

                    {column.canSort ? (
                      <TableHeaderSortIcon
                        className={styles.table__header_sort}
                        sorted={column.isSorted}
                        sortedDesc={column.isSortedDesc}
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className={cn(styles.table__body, tableBodyClassName)} {...tableBodyProps} ref={bodyRef}>
        {page.length == 0 ? (
          <div className={cn(styles.table__body_empty)}>
            <EmptyStatesNoResults />
          </div>
        ) : null}
        {page.map((row) => {
          prepareRow(row);

          const { className: rowClassName, style, key: rowKey, ...rowProps } = row.getRowProps();
          const isSelected = selectedRowsIds?.has(row.original["id"]);
          // @ts-ignore
          const isClickable = !!row.cells?.find(({ column }) => !!column?.onCellClick);

          return (
            <div
              className={cn(
                styles.table__body_row,
                {
                  [styles["table__body_row--is_selected"]]: isSelected,
                  [styles["table__body_row--is_clickable"]]: !!isClickable,
                },
                rowClassName,
              )}
              data-testid={TEST_IDS.BODY_ROW}
              key={rowKey}
              style={rowStyles ? { ...style, ...rowStyles(row) } : style}
              {...rowProps}
            >
              {row.cells.map((cell) => {
                const { className: cellClassName, key: cellKey, ...cellProps } = cell.getCellProps();
                const handleCellClick = (): void => {
                  // @ts-ignore
                  const onCellClickFunction = cell.column?.onCellClick;

                  if (onCellClickFunction) {
                    onCellClickFunction(cell.row.original);
                  }
                };

                return (
                  <div
                    className={cn(
                      styles.table__body_cell,
                      {
                        [styles["table__body_cell--row_select"]]: cell.column.id === TABLE_ROW_SELECT_COLUMN_ID,
                        [styles["table__body_cell--row_actions"]]: cell.column.id === TABLE_ROW_ACTIONS_COLUMN_ID,
                      },
                      cellClassName,
                    )}
                    data-testid={TEST_IDS.BODY_CELL}
                    key={cellKey}
                    onClick={handleCellClick}
                    {...cellProps}
                  >
                    {cell.render("Cell")}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { TableHeaderCaptions };
export { TablePaginationTarget } from "./TablePagination";
