import { FC } from "react";
import { EmptyStates } from "components";
import { useHistory } from "react-router-dom";
import { IMPORT, SUPPORTED_SYSTEM_IMPORT } from "constants/Routes";

export const EmptyStatesGenetics: FC = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(`${IMPORT}${SUPPORTED_SYSTEM_IMPORT}`);
  };
  return (
    <EmptyStates
      title="No genetic data found."
      animation="searching"
      onClickButton={handleButtonClick}
      buttonCaption="Genetic Partners Uploads"
    >
      <p>Upload genetic partner test results to view them here</p>
    </EmptyStates>
  );
};
