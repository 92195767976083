import React from "react";
// API
import { GetActivityTemplates_activityTemplates } from "api/MyLivestock/Activity/types/GetActivityTemplates";
// Utils
import { VACCINATION } from "constants/Animals";
import { useHasFeature } from "hooks";
// Types
import { InputSelectOptionWithTitles } from "components/Common/Field/Select";
// Components
import { logActivityMethodTypes } from "components/MyLivestock/Animals/AnimalBulkActions/LogActivityModal";
import { Button, FieldSelect, Flex, Spacer, Text } from "components";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
// Styles
import styles from "./styles.module.scss";

export const radioButtonClasses = { root: styles.root, checked: styles["root--checked"] };

type Props = {
  activityTemplates: (GetActivityTemplates_activityTemplates | null)[];
  onChangeSelectedTemplate: (value: string) => void;
  onChangeLogActivityMethod: (value: string) => void;
  onCloseModal: () => void;
  onChangeStep: (value: number) => void;
  selectedTemplate: string;
  logActivityMethod: string;
  isOneAnimal: boolean;
};

export const FirstStep: React.FC<Props> = ({
  activityTemplates = [],
  onChangeSelectedTemplate,
  onChangeLogActivityMethod,
  onCloseModal,
  onChangeStep,
  selectedTemplate,
  logActivityMethod,
  isOneAnimal,
}) => {
  const hasAdministerMedicineFeature = useHasFeature("SHOW_ADMINISTER_MEDICINE");
  const resultOptions = activityTemplates.reduce((result, template) => {
    const activityTypeName = template?.activityType?.name || "";

    if (template?.activityType?.slug === VACCINATION && !hasAdministerMedicineFeature) {
      return result;
    }

    if (activityTypeName) {
      return {
        ...result,
        [activityTypeName]: result && result[activityTypeName] ? [...result[activityTypeName], template] : [template],
      };
    }
    return result;
  }, {});

  const options = [] as InputSelectOptionWithTitles[];
  Object.keys(resultOptions).forEach((item) => {
    // Filter out birth and death activity template. We instead use a better validated custom form.
    // See: https://breedr.atlassian.net/browse/BRD-1447
    if (item && item !== "Birth & Death") {
      options.push({
        key: item || "activity_type_key",
        label: item || "",
      });
      resultOptions[item].forEach((option) => {
        if (option) {
          options.push({
            key: option?.id || "key",
            value: option?.id || "",
            label: option?.name || "",
          });
        }
      });
    }
  });

  const handleTemplateSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChangeSelectedTemplate(e.target.value);
  };

  const handleChangeLogActivityMethod = (e: React.ChangeEvent<unknown>, value: string): void => {
    onChangeLogActivityMethod(value);
  };

  const handleModalConfirm = (): void => {
    onChangeStep(1);
  };

  return (
    <Flex container containerDirection="column">
      <Flex item>
        <Spacer baselineHeight={1} />
        <FieldSelect
          label="Activity type"
          inputProps={{
            name: "activityType",
            placeholder: "Select activity",
            value: selectedTemplate,
            options: options,
            onChange: handleTemplateSelect,
            isClearable: true,
          }}
        />
      </Flex>
      {!isOneAnimal ? (
        <Flex item xs={6} l={6} xl={4}>
          <Spacer baselineHeight={2} />
          <Text>How would you like to log the activity?</Text>
          <RadioGroup
            aria-label="How would you like to log the activity?"
            value={logActivityMethod}
            onChange={handleChangeLogActivityMethod}
            row
          >
            <FormControlLabel
              value={logActivityMethodTypes.TO_ALL_SELECTED}
              control={<Radio classes={radioButtonClasses} />}
              label="To all selected animals"
            />
            <FormControlLabel
              value={logActivityMethodTypes.TO_INDIVIDUAL}
              control={<Radio classes={radioButtonClasses} />}
              label="To individual animals"
            />
          </RadioGroup>
        </Flex>
      ) : null}
      <Spacer baselineHeight={1} />
      <Flex container xs={12} containerJustifyContent="flex-end">
        <Spacer baselineHeight={3} />
        <Flex item itemGutter>
          <Button caption="Cancel" variant="hollow" colour="grey" onClick={onCloseModal} />
        </Flex>
        <Flex item itemGutter>
          <Button caption="Confirm" onClick={handleModalConfirm} disabled={!selectedTemplate} />
        </Flex>
      </Flex>
    </Flex>
  );
};
