import React, { useState } from "react";
import { Column } from "react-virtualized";
import { VirtualizedTable } from "components";
// Components
import SiresTableActionColumn from "./SiresTableActionColumn";
// Resources
import { SortAsc, SortDesc } from "constants/SvgIcons";
import styles from "./SiresTable.module.scss";

const renderHeader = (sortBy: string, label: string, dataKey: string, sortDirection: string): React.ReactNode => (
  <div className={styles.headerRow}>
    <span className={sortBy === dataKey ? styles.sorted : styles.headerLabelText}>{label}</span>
    <div className={styles.viewSortIcons}>
      <SortAsc nativeColor={sortDirection === "ASC" && sortBy === dataKey ? "#f2c434" : undefined} />
      <SortDesc nativeColor={sortDirection === "DESC" && sortBy === dataKey ? "#f2c434" : undefined} />
    </div>
  </div>
);

const cellRenderer = ({ cellData }): string => {
  return cellData || "\u2014";
};

const cellRendererYesNo = ({ cellData }): string => {
  return cellData ? "yes" : "no";
};

interface Props {
  sires: Sire[];
  sortBy: string | null;
  sortDirection: string;
  sort: (sortBy: string, sortDirection: string) => void;
}

const SiresTable: React.FC<Props> = ({ sires, sortBy, sortDirection, sort }) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const columnDefaults = {
    width: 3500,
    headerRenderer: ({ dataKey, label, sortBy: headerSortBy }): React.ReactNode =>
      renderHeader(headerSortBy, label, dataKey, sortDirection),
    cellRenderer,
  };

  return (
    <div className={styles.sires_table}>
      <VirtualizedTable sortBy={sortBy} sort={sort} data={sires} sortDirection={sortDirection}>
        <Column {...columnDefaults} label="Sire Name" dataKey="name" />
        <Column {...columnDefaults} label="Passport Number" dataKey="passportNumber" />
        <Column {...columnDefaults} label="Pedigree" dataKey="pedigree" cellRenderer={cellRendererYesNo} />
        <Column {...columnDefaults} label="Registration Number" dataKey="registrationNumber" />
        <Column {...columnDefaults} label="Sire" dataKey="sire" />
        <Column
          label=""
          dataKey="id"
          width={1000}
          cellRenderer={({ rowData }): React.ReactNode => (
            <SiresTableActionColumn sire={rowData} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
          )}
        />
      </VirtualizedTable>
    </div>
  );
};

export default SiresTable;
