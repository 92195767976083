import { FC } from "react";
import { EmptyStates } from "components";

export const EmptyStatesFamilyTree: FC = () => {
  return (
    <EmptyStates animation={"genealogy"} title="No ancestor data found.">
      {/* <p>Empty family tree message tbc</p> */}
    </EmptyStates>
  );
};
